import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import {Card, Col, Progress, Table, Row, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
// import './index.css'
import remove_underscore_wtih_first_cap from '../../utils/remove_underscore_wtih_first_cap';
import DragDropComponent from '../../components/DragDropCompoment';
import {CSVLink} from "react-csv";
import { Link } from 'react-router-dom';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { UserContext } from '../../components/Contexts/UserContext';
import debug_log from '../../utils/debug_log';
import { AllBuContext } from '../../components/Contexts/AllBuContext';
import { handleError } from '../../utils/error';



const DesignIntelligence = ({submit_for_delete_confirm, set_active_tab, send_analytics_event, check_route_fn, set_page_loader, store_details, get_store_details, set_active_division_id_sidebar, tags}) => {
	const styles = {
		add_brand_style:{
			backgroundColor: '#007EF4',
			boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
			borderRadius: '3px',
			height:'28px',
			padding:'0px 18px',
			border:'0px',
			color:'white',
            position: 'absolute',
            top:'43.5px',
            right:'12px'
		},
		table_header: {
			fontWeight: "bold",
			fontSize: 'small'
		},
    };
	//console.log('BRANDPAGE====>CALLED')
	// const [rules, set_rules ] = useState([]);
	// const [show_rule_Dialog_Box, set_show_rule_Dialog_Box] = useState(false);
	// const [active_rule, set_active_rule] = useState('');
	// const [search_string , set_search_string] = useState('');
	// const [display_rules , set_display_rules] = useState([]);
	// const [key , set_key] = useState('');

	// const [page_size , set_page_size] = useState(30)
	// const [fetch_progress , set_fetch_progress] = useState(40)
	// const [current_page , set_current_page] = useState(1)
	// const [initialized , set_initialized] = useState(false) 
 	// const [all_pages , set_all_pages] = useState([])
	// const alert = useAlert()
	// const [ start_index , set_start_index ] = useState('');
	// const [ end_index , set_end_index ] = useState('');
	// const [tags , set_tags] = useState([]);
	// const [ last_item_order , set_last_item_order ] = useState(0);
	// const [ sales_channels, set_sales_channels ] = useState([]);
    const [sales_channel_details, set_sales_channel_details] = useState(null);
    const [mf_rules, set_mf_rules] = useState([]);
    const [hw_rules, set_hw_rules] = useState([]);
    const [da_rules, set_da_rules] = useState([]);
    const [dc_rules, set_dc_rules] = useState([]);
    const [sku_level_mf_rules, set_sku_level_mf_rules] = useState([]);
    const [sku_level_hw_rules, set_sku_level_hw_rules] = useState([]);
    const [sku_level_da_rules, set_sku_level_da_rules] = useState([]);
    const [sku_level_dc_rules, set_sku_level_dc_rules] = useState([]);
    const [dump_json_dc, set_dump_json_dc] = useState([]);
    const [dump_json_da, set_dump_json_da] = useState([]);
    const [dump_json_hw, set_dump_json_hw] = useState([]);
    const [dump_json_mf, set_dump_json_mf] = useState([]);
    const [sku_level_dump_json_dc, set_sku_level_dump_json_dc] = useState([]);
    const [sku_level_dump_json_da, set_sku_level_dump_json_da] = useState([]);
    const [sku_level_dump_json_hw, set_sku_level_dump_json_hw] = useState([]);
    const [sku_level_dump_json_mf, set_sku_level_dump_json_mf] = useState([]);
    const [	tabs , set_tabs ] = useState(1)

    const org_bu_view = useContext(OrgBuContext)
    const alert = useAlert();
    const user = useContext(UserContext)
    const all_bu_in_store = useContext(AllBuContext)

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};
	
	useEffect(() => {
		set_active_tab('DesignIntelligence')
		set_active_division_id_sidebar('Design_intelligence')
		// check_route_fn('DC_rules')
		send_analytics_event('auto', 'DesignIntelligence', 'DesignIntelligence', 1, window.location.href)
	}, []);

	// const Rules_Filler = () => (
	// 	<Table className="align-items-center table-flush text-center" responsive>
	// 		<thead className="thead-light text-center">
	// 			<tr>
	// 				{ initialized ? (
	// 					<th scope="col"><span style={styles.table_header}><b>No rules to list  </b></span><br/> Try adjusting filters...<br /> &nbsp;</th>
	// 				):(
	// 					<th scope="col"><span style={styles.table_header}><b>Fetching all rules </b></span><br/> <br /> Please wait...<br /> <br />
	// 					<Progress max="100" value={fetch_progress} color="info" />&nbsp;</th>
	// 				)}
	// 			</tr>
	// 		</thead>
	// 	</Table>
	// );

	

	// const Single_table_row = ({user}) => {
		
	// 	return (
	// 		// {
	// 		// display_rules && display_rules.length && display_rules.map((rule, index) => (
	// 		<tr key={index} style={{backgroundColor:'white', display:(index+1>=start_index && index<end_index)?'flex':'none'}} className='tr_style display_onhover'>
	// 			<td className='td_style flex_property' style={{flex:1}}>
	// 					<div className='drag_option' style={{display:'flex', alignItems:'center', color:'#808080'}}>
	// 						<i style={{marginRight:'1px'}} className='fa fa-ellipsis-v'/>
	// 						<i className='fa fa-ellipsis-v'/>
	// 						<span style={{marginLeft:'10px'}}>{index+1} .</span>
	// 					</div>
	// 				</td>
	// 			<td className='td_style flex_property' style={{flex:5}}><span className='table_text_overflow'>{item.name}</span></td>
	// 			<td className='td_style flex_property' style={{flex:5}}><span className='table_text_overflow'>{item.description?item.description:'-'}</span></td>
	// 			<td className='td_style flex_property' style={{flex:5}}>{item.rule_type?remove_underscore_wtih_first_cap(item.rule_type):'-'}</td>
	// 			<td className='td_style flex_property' style={{flex:5}}>{item.active==1?'True':'False'}</td>
	// 			{/* <td className='td_style flex_property' style={{flex:5}}><span className='table_text_overflow'>{item.created_at}</span></td> */}
				
	// 			<td className='td_style flex_property x1' style={{ justifyContent:'flex-end', flex:2, paddingRight:'20px'}}>
	// 				{
	// 					user.store_id ==item.store_id ?(
	// 						<span className='hide'>
	// 							<i style={{marginRight:'15px'}} onClick ={() => open_rule_update_dialog({ item, mode: 'view' })} className='fa fa-eye'/>
	// 							<i style={{marginRight:'15px'}} onClick ={() => open_rule_update_dialog({ item, mode: 'edit' })} className='fa fa-edit'/>
	// 							<i 
	// 								onClick={()=>submit_for_delete_confirm(()=>onclick_delete_rule(item.id), ()=>{}, 'Are you sure you want to delete this Rule? Rule Name - '+ item.name)}
	// 								className='fa fa-trash'
	// 							/>
	// 						</span>
	// 					):''
	// 				}
	// 			</td>
	// 			{/* <td className='td_style flex_property x1' style={{ justifyContent:'flex-end', flex:'1', paddingRight:"20px"}}>
	// 				{
	// 					user.store_id ==item.store_id ?(
	// 						<span className='hide'>
								
	// 						</span>
	// 					):''
	// 				}
	// 			</td> */}
	// 		</tr>
	// 	// ))}
	// )
	// }

    const dc_rule_summary_fn = async(rules_details, temp_dump_json_dc, set_temp_dump_json_dc) => {
        //debug_log('printing rule details', rules_details);
        let temp_dump_json = [...temp_dump_json_dc]
            let component_applicability, subcomponent_applicability, property_applicability, operator_applicability, value_applicability, rule_logic, component_source, subcomponent_source, property_source, operator_source , value_source, component_dest, subcomponent_dest , property_dest, operator_dest, value_dest
            let tag_source
            let sku_array = []
            if(rules_details.length){
                rules_details.map(item =>{
                    try {
                        let applicability_preview = !item.sku_linked_rule ? "Applicable to \n" : ''
                        let condition_preview = "\nSource Conditions: \n"
                        let destination_preview = "\nDestination Conditions: \n"

                        let temp_json = {}
                        temp_json['Name'] = item.name
                        if(item.sku_linked_rule) temp_json['SKU Name'] = item.sku?.name
                        temp_json['Description'] = item.description
                        temp_json['Design Constraint Rule Type'] = item.rule_type
                        //debug_log('dc item', item)
                        if(!item.sku_linked_rule){
                            item['applicability'].map((x, index) => {
                                //debug_log("x", x)
                                subcomponent_applicability = x['source_filter']['sub_component']
                                property_applicability = x['source_filter']['property']
                                component_applicability = x['source_filter']['component']
                                operator_applicability = x['operator']
                                value_applicability = x['value']
                                let tags_preview = ''
                                if(x['tag'] && x['tag'].length){
                                    x['tag'].map((t, index) => {
                                        if(index != 0){
                                            tags_preview = tags_preview + ' ' + x['value_combination_logic'] + ' '
                                        }
                                        tags_preview = tags_preview + tags.find(i => i.id == t).tag
                                    })
                                }
    
                                let sales_channel_preview = ''
                                if(x['sales_channel'] && x['sales_channel'].length){
                                    x['sales_channel'].map((t, index) => {
                                        if(index != 0){
                                            sales_channel_preview = sales_channel_preview + ' ' + x['value_combination_logic'] + ' '
                                        }
                                        //debug_log("sales_channel brand", sales_channel_details)
                                        sales_channel_preview = sales_channel_preview + sales_channel_details.find(i => i.id == t).name
                                    })
                                }
    
                                let sku_preview = ''
                                if(x['sku'] && x['sku'].length && x['type'] == 'absolute'){
                                    sku_preview += 'sku_id!)*&($@:' + x['sku'][0]
                                    sku_array.push(x['sku'][0])
                                }else if(x['sku'] && x['sku'].length && x['type'] == 'relative'){
                                    sku_preview += `component: ${x['destination_filter']['component']} with subcomponent: ${x['destination_filter']['sub_component']} and property: ${x['destination_filter']['property']}`
                                }
                                // let tag = x['tag'] && x['tag'].length && ? x['tag'].map(t => tags.find(i => i.id == t)): ''
                                // let tag = x['tag'] && x['tag'].length && tags.find(i => i.id == x['tag'][0]).tag ? tags.find(i => i.id == x['tag'][0]).tag: ''
                                if(index != 0){
                                    applicability_preview = applicability_preview + item['applicability_combination_logic'] + ' '
                                }
                                if(tags_preview){
                                    applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to: ${tags_preview} \n`
                                }
                                else if(sku_preview){
                                    applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to: ${sku_preview} \n`
                                }
                                else if(sales_channel_preview){
                                    applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to: ${sales_channel_preview} \n`
                                }
                                else if(property_applicability == 'no_condition'){
                                    applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability}\n`
                                }
                                else if(operator_applicability == 'less' || operator_applicability == 'greater'){
                                    applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} than value: ${value_applicability} \n`
                                }
                                else{
                                    applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to value: ${value_applicability} \n`
                                }
                            })
                        }

                        // subcomponent_applicability = item['applicability'][0]['source_filter']['sub_component']
                        // property_applicability = item['applicability'][0]['source_filter']['property']
                        // component_applicability = item['applicability'][0]['source_filter']['component']
                        // operator_applicability = item['applicability'][0]['operator']
                        // value_applicability = item['applicability'][0]['value']

                        rule_logic = item['rule_logic']

                        item['conditions'].map((x,index)=> {
                            component_source = x['source_filter']['component']
                            subcomponent_source = x['source_filter']['sub_component']
                            property_source = x['source_filter']['property']
                            value_source = x['value']
                            operator_source = x['operator']
                            // tag_source = x['tag'] && item['conditions'][0]['tag'].length && item['conditions'][0]['tag'][0]
                            let tags_preview = ''
                            if(x['tag'] && x['tag'].length){
                                x['tag'].map((t, index) => {
                                    if(index != 0){
                                        tags_preview = tags_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    tags_preview = tags_preview + tags.find(i => i.id == t).tag
                                })
                            }
                            let sales_channel_preview = ''
                            if(x['sales_channel'] && x['sales_channel'].length){
                                x['sales_channel'].map((t, index) => {
                                    if(index != 0){
                                        sales_channel_preview = sales_channel_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    //debug_log("sales_channel brand", sales_channel_details)
                                    sales_channel_preview = sales_channel_preview + sales_channel_details.find(i => i.id == t).name
                                })
                            }

                            let sku_preview = ''
                            if(x['sku'] && x['sku'].length && x['type'] == 'absolute'){
                                // sku_preview += 'sku_id!)*&($@:' + x['sku'][0]
                                x['sku'].map((t, index) => {
                                    if(index != 0){
                                        sku_preview = sku_preview + ' or '
                                    }
                                    //debug_log("sku brand", sku_preview)
                                    sku_array.push(x['sku'][index])
                                    sku_preview = sku_preview + 'sku_id!)*&($@:' + x['sku'][index]
                                })
                            }else if(x['sku'] && x['sku'].length && x['type'] == 'relative'){
                                sku_preview += `component: ${x['destination_filter']['component']} with subcomponent: ${x['destination_filter']['sub_component']} and property: ${x['destination_filter']['property']}`
                            }

                            if(index != 0){
                                condition_preview = condition_preview + item['condition_combination_logic'] + ' '
                            }
                            if(tags_preview){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to: ${tags_preview} \n`
                            }
                            else if(sku_preview){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to: ${sku_preview} \n`
                            }
                            else if(sales_channel_preview){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to: ${sales_channel_preview} \n`
                            }
                            else if(property_source == 'no_condition'){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source}\n`
                            }
                            else if(operator_source == 'less' || operator_source == 'greater'){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} than value: ${value_source} \n`
                            }
                            else{
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to value: ${value_source} \n`
                            }
                        })
                        // component_source = item['conditions'][0]['source_filter']['component']
                        // subcomponent_source = item['conditions'][0]['source_filter']['sub_component']
                        // property_source = item['conditions'][0]['source_filter']['property']
                        // value_source = item['conditions'][0]['value']
                        // operator_source = item['conditions'][0]['operator']
                        // tag_source = item['conditions'][0]['tag'] && item['conditions'][0]['tag'].length && item['conditions'][0]['tag'][0]
                        
                        item['actions'].map((x, index) => {
                            component_dest = x['source_filter']['component']
                            subcomponent_dest = x['source_filter']['sub_component']
                            property_dest = x['source_filter']['property']
                            value_dest = x['value']
                            operator_dest = x['operator']
                            let tags_preview = ''
                            if(x['tag'] && x['tag'].length){
                                x['tag'].map((t, index) => {
                                    if(index != 0){
                                        tags_preview = tags_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    tags_preview = tags_preview + tags.find(i => i.id == t).tag
                                })
                            }
                            let sales_channel_preview = ''
                            if(x['sales_channel'] && x['sales_channel'].length){
                                x['sales_channel'].map((t, index) => {
                                    if(index != 0){
                                        sales_channel_preview = sales_channel_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    //debug_log("sales_channel brand", sales_channel_details)
                                    sales_channel_preview = sales_channel_preview + sales_channel_details.find(i => i.id == t).name
                                })
                            }

                            let sku_preview = ''
                            if(x['sku'] && x['sku'].length && x['type'] == 'absolute'){
                                // sku_preview += 'sku_id!)*&($@:' + x['sku'][0]
                                x['sku'].map((t, index) => {
                                    if(index != 0){
                                        sku_preview = sku_preview + ' or '
                                    }
                                    //debug_log("sku brand", sku_preview)
                                    sku_array.push(x['sku'][index])
                                    sku_preview = sku_preview + 'sku_id!)*&($@:' + x['sku'][index]
                                })
                            }else if(x['sku'] && x['sku'].length && x['type'] == 'relative'){
                                sku_preview += `component: ${x['destination_filter']['component']} with subcomponent: ${x['destination_filter']['sub_component']} and property: ${x['destination_filter']['property']}`
                            }

                            if(index != 0){
                                destination_preview = destination_preview + item['action_combination_logic'] + ' '
                            }
                            if(tags_preview){
                                destination_preview = destination_preview + `Component: ${component_dest} with subcomponent: ${subcomponent_dest} having property: ${property_dest} ${operator_dest} to: ${tags_preview} \n`
                            }
                            else if(sku_preview){
                                destination_preview = destination_preview + `Component: ${component_dest} with subcomponent: ${subcomponent_dest} having property: ${property_dest} ${operator_dest} to: ${sku_preview} \n`
                            }
                            else if(sales_channel_preview){
                                destination_preview = destination_preview + `Component: ${component_dest} with subcomponent: ${subcomponent_dest} having property: ${property_dest} ${operator_dest} to: ${sales_channel_preview} \n`
                            }
                            else if(property_dest == 'no_condition'){
                                destination_preview = destination_preview + `Component: ${component_dest} with subcomponent: ${subcomponent_dest}\n`
                            }
                            else if(operator_dest == 'less' || operator_dest == 'greater'){
                                destination_preview = destination_preview + `Component: ${component_dest} with subcomponent: ${subcomponent_dest} having property: ${property_dest} ${operator_dest} than value: ${value_dest} \n`
                            }
                            else{
                                destination_preview = destination_preview + `Component: ${component_dest} with subcomponent: ${subcomponent_dest} having property: ${property_dest} ${operator_dest} to value: ${value_dest} \n`
                            }
                        })

                        // component_dest = item['actions'][0]['source_filter']['component']
                        // subcomponent_dest = item['actions'][0]['source_filter']['sub_component']
                        // property_dest = item['actions'][0]['source_filter']['property']
                        // value_dest = item['actions'][0]['value']
                        // operator_dest = item['actions'][0]['operator']

                        temp_json['Rule Preview'] = applicability_preview + condition_preview + `\nIf the source conditions are met ${rule_logic} the following destination conditions  \n` + destination_preview

                        //debug_log("da rules preview", temp_dump_json)
                        temp_dump_json.push(temp_json)
                    }
                    catch(err) {
                        console.log("Error in DC Rule ", item.name, err)
                    }
                })
            }
            let unique_sku_array = [... new Set(sku_array)]
            //debug_log('da sku unique', unique_sku_array);
            
            var identifiers = JSON.stringify({ id : unique_sku_array})
            var business_unit_id = user.current_business_unit_id
            var body = {identifiers, business_unit_id}
            var response = await general_fetch({ url: 'sku/get' , body });
            //debug_log('da sku unique', response);
            response.map(i => {
                let sku_preview_final = `division: ${i['sku_group']['sku_sub_category']['sku_category']['sku_division_id']}, category: ${i['sku_group']['sku_sub_category']['sku_category']['name']}, subcategory: ${i['sku_group']['sku_sub_category']['name']}, group: ${i['sku_group']['name']}, name: ${i['name']}`
                let re = 'sku_id!)*&($@:'+i['id']
                //debug_log('da sku unique', sku_preview_final, re);
                temp_dump_json.map(j => {
                    let rule_preview_modified = `${j['Rule Preview']}`
                    //debug_log('da sku unique 1', rule_preview_modified);
                    // rule_preview_modified.split(re).join(sku_preview_final)
                    let ans = rule_preview_modified.replaceAll(re, sku_preview_final)
                    //debug_log('da sku unique 2', ans);
                    j['Rule Preview'] = ans
                })
                
            })

            set_temp_dump_json_dc(temp_dump_json)
    }

    const fetch_dc_rules_details = async () => {
        try {
            var resp = await general_fetch({ url: 'automation_rule/get_by_type', body:{type:'design_constraint', business_unit_id: user.current_business_unit_id}});
            // set_initialized(true);
			// var rules_details= resp && resp.length ? resp.sort(name_sort):[]
			var rules_details= resp && resp.length ? resp : []
            let design_level_rules_details = rules_details && rules_details.length ? rules_details.filter(x => !x.sku_linked_rule) : []
            let sku_level_rules_details = rules_details && rules_details.length ? rules_details.filter(x => x.sku_linked_rule) : []
            set_dc_rules(design_level_rules_details)
            set_sku_level_dc_rules(sku_level_rules_details)
            // set_display_rules(rules_details);
			// set_last_item_order(rules_details.length?rules_details[rules_details.length-1].order:0);
			//debug_log('brand======>',rules_details);

            dc_rule_summary_fn(design_level_rules_details, dump_json_dc, set_dump_json_dc)
            dc_rule_summary_fn(sku_level_rules_details, sku_level_dump_json_dc, set_sku_level_dump_json_dc)
        } catch(err) {
            console.error(err);
			alert.error('Error in Design Constraint Rules! Please contact admin')
        }
    }

    const fetch_mf_rules_details = async () => {
        try {
            var resp = await general_fetch({ url: 'automation_rule/get_by_type', body:{type:'manufacturing_rule', business_unit_id: user.current_business_unit_id}});
            // set_initialized(true);
			// var rules_details= resp && resp.length ? resp.sort(name_sort):[]
			var rules_details= resp && resp.length ? resp : []
            set_mf_rules(rules_details && rules_details.length ? rules_details.filter(x => !x.sku_linked_rule) : [])
            set_sku_level_mf_rules(rules_details && rules_details.length ? rules_details.filter(x => x.sku_linked_rule) : [])
            // set_display_rules(rules_details);
			// set_last_item_order(rules_details.length?rules_details[rules_details.length-1].order:0);
			//debug_log('brand======>',rules_details);
            let sku_array = []
            let temp_dump_json = [...dump_json_mf]
            let component_applicability, subcomponent_applicability, property_applicability, operator_applicability, value_applicability, subunit, subcomponent_source, property_source, operator_source , value_source, property_dest, operator_dest, value_dest
            //debug_log("preveiw len", mf_rules.length)
            if(rules_details.length){
                rules_details.map(item =>{
                    try {
                        let temp_json = {}
                        temp_json['Name'] = item.name
                        temp_json['Description'] = item.description
                        temp_json['Design Constraint Rule Type'] = item.rule_type
                        //debug_log('mfitem', item)

                        let applicability_preview = "Applicable to \n"
                        let condition_preview = "\nIf the following criteria is satisfied: \n"
                        let destination_preview = "\nThen do the following action: \n"

                        item['applicability'].map((x, index) => {
                            subcomponent_applicability = x['source_filter']['sub_component']
                            property_applicability = x['source_filter']['property']
                            component_applicability = x['source_filter']['component']
                            operator_applicability = x['operator']
                            value_applicability = x['value']
                            //debug_log("op mf", operator_applicability)
                            let tags_preview = ''
                            if(x['tag'] && x['tag'].length){
                                x['tag'].map((t, index) => {
                                    if(index != 0){
                                        tags_preview = tags_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    tags_preview = tags_preview + tags.find(i => i.id == t).tag
                                })
                            }
                            let sales_channel_preview = ''
                            if(x['sales_channel'] && x['sales_channel'].length){
                                x['sales_channel'].map((t, index) => {
                                    if(index != 0){
                                        sales_channel_preview = sales_channel_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    //debug_log("sales_channel brand", sales_channel_details)
                                    sales_channel_preview = sales_channel_preview + sales_channel_details.find(i => i.id == t).name
                                })
                            }

                            let sku_preview = ''
                            if(x['sku'] && x['sku'].length && x['type'] == 'absolute'){
                                sku_preview += 'sku_id!)*&($@:' + x['sku'][0]
                                sku_array.push(x['sku'][0])
                            }else if(x['sku'] && x['sku'].length && x['type'] == 'relative'){
                                sku_preview += `component: ${x['destination_filter']['component']} with subcomponent: ${x['destination_filter']['sub_component']} and property: ${x['destination_filter']['property']}`
                            }

                            // let tag = x['tag'] && x['tag'].length && ? x['tag'].map(t => tags.find(i => i.id == t)): ''
                            // let tag = x['tag'] && x['tag'].length && tags.find(i => i.id == x['tag'][0]).tag ? tags.find(i => i.id == x['tag'][0]).tag: ''
                            if(index != 0){
                                applicability_preview = applicability_preview + item['applicability_combination_logic'] + ' '
                            }
                            if(tags_preview){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to: ${tags_preview} \n`
                            }
                            else if(sku_preview){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to: ${sku_preview} \n`
                            }
                            else if(sales_channel_preview){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to: ${sales_channel_preview} \n`
                            }
                            else if(property_applicability == 'no_condition'){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability}\n`
                            }
                            else if(operator_applicability == 'less' || operator_applicability == 'greater'){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} than value: ${value_applicability} \n`
                            }
                            else{
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to value: ${value_applicability} \n`
                            }
                            //debug_log("applicability passed")

                        })
                        // subcomponent_applicability = item['applicability'][0]['source_filter']['sub_component']
                        // property_applicability = item['applicability'][0]['source_filter']['property']
                        // component_applicability = item['applicability'][0]['source_filter']['component']
                        // operator_applicability = item['applicability'][0]['operator']
                        // value_applicability = item['applicability'][0]['value']

                        subunit = item['conditions'][0]['source_filter']['component']

                        item['conditions'].map((x, index) => {
                            subcomponent_source = x['source_filter']['sub_component']
                            property_source = x['source_filter']['property']
                            value_source = x['value']
                            operator_source = x['operator']

                            let tags_preview = ''
                            if(x['tag'] && x['tag'].length){
                                x['tag'].map((t, index) => {
                                    if(index != 0){
                                        tags_preview = tags_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    tags_preview = tags_preview + tags.find(i => i.id == t).tag
                                })
                            }
                            let sales_channel_preview = ''
                            if(x['sales_channel'] && x['sales_channel'].length){
                                x['sales_channel'].map((t, index) => {
                                    if(index != 0){
                                        sales_channel_preview = sales_channel_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    //debug_log("sales_channel brand", sales_channel_details)
                                    sales_channel_preview = sales_channel_preview + sales_channel_details.find(i => i.id == t).name
                                })
                            }

                            let sku_preview = ''
                            if(x['sku'] && x['sku'].length && x['type'] == 'absolute'){
                                sku_preview += 'sku_id!)*&($@:' + x['sku'][0]
                                sku_array.push(x['sku'][0])
                            }else if(x['sku'] && x['sku'].length && x['type'] == 'relative'){
                                sku_preview += `component: ${x['destination_filter']['component']} with subcomponent: ${x['destination_filter']['sub_component']} and property: ${x['destination_filter']['property']}`
                            }

                            if(index != 0){
                                condition_preview = condition_preview + item['condition_combination_logic'] + ' '
                            }
                            if(tags_preview){
                                condition_preview = condition_preview + `subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to: ${tags_preview} \n`
                            }
                            else if(sku_preview){
                                condition_preview = condition_preview + `subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to: ${sku_preview} \n`
                            }
                            else if(sales_channel_preview){
                                condition_preview = condition_preview + `subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to: ${sales_channel_preview} \n`
                            }
                            else if(property_source == 'no_condition'){
                                condition_preview = condition_preview + `subcomponent: ${subcomponent_source}\n`
                            }
                            else if(operator_source == 'less' || operator_source == 'greater'){
                                condition_preview = condition_preview + `subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} than value: ${value_source} \n`
                            }
                            else{
                                condition_preview = condition_preview + `subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to value: ${value_source} \n`
                            }
                            //debug_log("consdition passed")

                        })
                        // subcomponent_source = item['conditions'][0]['source_filter']['sub_component']
                        // property_source = item['conditions'][0]['source_filter']['property']
                        // value_source = item['conditions'][0]['value']
                        // operator_source = item['conditions'][0]['operator']

                        item['actions'].map((x, index) => {
                            property_dest = x['source_filter']['property']
                            value_dest = x['value']
                            operator_dest = x['operator']

                            //debug_log("dest 1")
                            let tags_preview = ''
                            if(x['tag'] && x['tag'].length){
                                x['tag'].map((t, index) => {
                                    if(index != 0){
                                        tags_preview = tags_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    tags_preview = tags_preview + tags.find(i => i.id == t).tag
                                })
                            }
                            let sales_channel_preview = ''
                            if(x['sales_channel'] && x['sales_channel'].length){
                                x['sales_channel'].map((t, index) => {
                                    if(index != 0){
                                        sales_channel_preview = sales_channel_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    //debug_log("sales_channel brand", sales_channel_details)
                                    sales_channel_preview = sales_channel_preview + sales_channel_details.find(i => i.id == t).name
                                })
                            }

                            let sku_preview = ''
                            if(x['sku'] && x['sku'].length && x['type'] == 'absolute'){
                                sku_preview += 'sku_id!)*&($@:' + x['sku'][0]
                                sku_array.push(x['sku'][0])
                            }else if(x['sku'] && x['sku'].length && x['type'] == 'relative'){
                                sku_preview += `component: ${x['destination_filter']['component']} with subcomponent: ${x['destination_filter']['sub_component']} and property: ${x['destination_filter']['property']}`
                            }

                            if(index != 0){
                                //debug_log("dest 1")
                                destination_preview = destination_preview + item['action_combination_logic'] + ' '
                            }

                            if(tags_preview){
                                //debug_log("dest 2")
                                destination_preview = destination_preview + `Set property: ${property_dest} ${operator_dest} to: ${tags_preview} \n`
                                //debug_log("dest 2.1")
                            }
                            else if(sku_preview){
                                destination_preview = destination_preview + `Set property: ${property_dest} ${operator_dest} to: ${sku_preview} \n`
                            }
                            else if(sales_channel_preview){
                                destination_preview = destination_preview + `Set property: ${property_dest} ${operator_dest} to: ${sales_channel_preview} \n`
                            }
                            else if(property_dest == 'no_condition'){
                                //debug_log("dest 3")
                                // destination_preview = destination_preview + `Component: ${component_dest} with subcomponent: ${subcomponent_dest}\n`
                                //debug_log("dest 3.1")
                            }
                            else if(operator_dest == 'less' || operator_dest == 'greater'){
                                //debug_log("dest 4")
                                destination_preview = destination_preview + `Set property: ${property_dest} ${operator_dest} than value: ${value_dest} \n`
                                //debug_log("dest 4.1")
                            }
                            else{
                                //debug_log("dest 5")
                                destination_preview = destination_preview + `Set property: ${property_dest} ${operator_dest} to value: ${value_dest} \n`
                                //debug_log("dest 5.1")
                            }
                            //debug_log("dest passed")

                        })
                        // property_dest = item['actions'][0]['source_filter']['property']
                        // value_dest = item['actions'][0]['value']
                        // operator_dest = item['actions'][0]['operator']

                        temp_json['Rule Preview'] = applicability_preview + `\nHaving subunit ${subunit} \n` + condition_preview + destination_preview
                        // temp_json['Rule Preview'] = `Applicable to \nComponent: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to value: ${value_applicability} \nHaving subunit ${subunit} \nIf the following criteria is satisfied: \nsubcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to value: ${value_source} \nThen do the following action: \nSet property: ${property_dest} ${operator_dest} to value: ${value_dest}`



                        //debug_log("mf rules preview", temp_json)
                        temp_dump_json.push(temp_json)
                    }
                    catch(err) {
                        console.log("Error in MF Rule ", item.name, err)
                    }
                })
            }
            let unique_sku_array = [... new Set(sku_array)]
            //debug_log('da sku unique', unique_sku_array);
            
            var identifiers = JSON.stringify({ id : unique_sku_array})
            var business_unit_id = user.current_business_unit_id
            var body = {identifiers, business_unit_id}
            var response = await general_fetch({ url: 'sku/get' , body });
            //debug_log('da sku unique', response);
            response.map(i => {
                let sku_preview_final = `division: ${i['sku_group']['sku_sub_category']['sku_category']['sku_division_id']}, category: ${i['sku_group']['sku_sub_category']['sku_category']['name']}, subcategory: ${i['sku_group']['sku_sub_category']['name']}, group: ${i['sku_group']['name']}, name: ${i['name']}`
                let re = 'sku_id!)*&($@:'+i['id']
                //debug_log('da sku unique', sku_preview_final, re);
                temp_dump_json.map(j => {
                    let rule_preview_modified = `${j['Rule Preview']}`
                    //debug_log('da sku unique 1', rule_preview_modified);
                    // rule_preview_modified.split(re).join(sku_preview_final)
                    let ans = rule_preview_modified.replaceAll(re, sku_preview_final)
                    //debug_log('da sku unique 2', ans);
                    j['Rule Preview'] = ans
                })
                
            })
    
            set_dump_json_mf(temp_dump_json)
        } catch(err) {
            console.error('err', err);
			alert.error('Error in Manufacturing Rules! Please contact admin')
        }
    }

    const fetch_hw_rules_details = async () => {
        try {
            var resp = await general_fetch({ url: 'automation_rule/get_by_type', body:{type:'hardware_rule', business_unit_id: user.current_business_unit_id}});
            // set_initialized(true);
			// var rules_details= resp && resp.length ? resp.sort(name_sort):[]
			var rules_details= resp && resp.length ? resp : []
            set_hw_rules(rules_details && rules_details.length ? rules_details.filter(x => !x.sku_linked_rule) : [])
            set_sku_level_hw_rules(rules_details && rules_details.length ? rules_details.filter(x => x.sku_linked_rule) : [])
            // set_display_rules(rules_details);
			// set_last_item_order(rules_details.length?rules_details[rules_details.length-1].order:0);
			//debug_log('brand======>',rules_details);
            let sku_array = []

            let component_applicability, subcomponent_applicability, property_applicability, operator_applicability, value_applicability, component_source, subcomponent_source, property_source, operator_source , value_source, component_dest, subcomponent_dest , property_dest, operator_dest, value_dest
            let temp_dump_json = [...dump_json_hw]
            //debug_log("preveiw len", hw_rules.length)
            if(rules_details.length){
                rules_details.map(item =>{
                    try {
                        let temp_json = {}
                        temp_json['Name'] = item.name
                        temp_json['Description'] = item.description
                        temp_json['Design Constraint Rule Type'] = item.rule_type

                        let applicability_preview = "Applicable to \n"
                        let condition_preview = "\nIf the following criteria is satisfied: \n"
                        let destination_preview = "\nHardware to be added \n"

                        item['applicability'].map((x, index) => {
                            subcomponent_applicability = x['source_filter']['sub_component']
                            property_applicability = x['source_filter']['property']
                            component_applicability = x['source_filter']['component']
                            operator_applicability = x['operator']
                            value_applicability = x['value']

                            let tags_preview = ''
                            if(x['tag'] && x['tag'].length){
                                x['tag'].map((t, index) => {
                                    if(index != 0){
                                        tags_preview = tags_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    tags_preview = tags_preview + tags.find(i => i.id == t).tag
                                })
                            }
                            let sales_channel_preview = ''
                            if(x['sales_channel'] && x['sales_channel'].length){
                                x['sales_channel'].map((t, index) => {
                                    if(index != 0){
                                        sales_channel_preview = sales_channel_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    //debug_log("sales_channel brand", sales_channel_details)
                                    sales_channel_preview = sales_channel_preview + sales_channel_details.find(i => i.id == t).name
                                })
                            }

                            let sku_preview = ''
                            if(x['sku'] && x['sku'].length && x['type'] == 'absolute'){
                                sku_preview += 'sku_id!)*&($@:' + x['sku'][0]
                                sku_array.push(x['sku'][0])
                            }else if(x['sku'] && x['sku'].length && x['type'] == 'relative'){
                                sku_preview += `component: ${x['destination_filter']['component']} with subcomponent: ${x['destination_filter']['sub_component']} and property: ${x['destination_filter']['property']}`
                            }

                            // let tag = x['tag'] && x['tag'].length && ? x['tag'].map(t => tags.find(i => i.id == t)): ''
                            // let tag = x['tag'] && x['tag'].length && tags.find(i => i.id == x['tag'][0]).tag ? tags.find(i => i.id == x['tag'][0]).tag: ''
                            if(index != 0){
                                applicability_preview = applicability_preview + item['applicability_combination_logic'] + ' '
                            }
                            if(tags_preview){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to: ${tags_preview} \n`
                            }
                            else if(sales_channel_preview){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to: ${sales_channel_preview} \n`
                            }
                            else if(sku_preview){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to: ${sku_preview} \n`
                            }
                            else if(property_applicability == 'no_condition'){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability}\n`
                            }
                            else if(operator_applicability == 'less' || operator_applicability == 'greater'){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} than value: ${value_applicability} \n`
                            }
                            else{
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to value: ${value_applicability} \n`
                            }
                            //debug_log("applicability passed")

                        })
                        // subcomponent_applicability = item['applicability'][0]['source_filter']['sub_component']
                        // property_applicability = item['applicability'][0]['source_filter']['property']
                        // component_applicability = item['applicability'][0]['source_filter']['component']
                        // operator_applicability = item['applicability'][0]['operator']
                        // value_applicability = item['applicability'][0]['value']

                        item['conditions'].map((x,index)=> {
                            component_source = x['source_filter']['component']
                            subcomponent_source = x['source_filter']['sub_component']
                            property_source = x['source_filter']['property']
                            value_source = x['value']
                            operator_source = x['operator']
                            // tag_source = x['tag'] && item['conditions'][0]['tag'].length && item['conditions'][0]['tag'][0]
                            let tags_preview = ''
                            if(x['tag'] && x['tag'].length){
                                x['tag'].map((t, index) => {
                                    if(index != 0){
                                        tags_preview = tags_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    tags_preview = tags_preview + tags.find(i => i.id == t).tag
                                })
                            }
                            let sales_channel_preview = ''
                            if(x['sales_channel'] && x['sales_channel'].length){
                                x['sales_channel'].map((t, index) => {
                                    if(index != 0){
                                        sales_channel_preview = sales_channel_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    //debug_log("sales_channel brand", sales_channel_details)
                                    sales_channel_preview = sales_channel_preview + sales_channel_details.find(i => i.id == t).name
                                })
                            }

                            let sku_preview = ''
                            if(x['sku'] && x['sku'].length && x['type'] == 'absolute'){
                                sku_preview += 'sku_id!)*&($@:' + x['sku'][0]
                                sku_array.push(x['sku'][0])
                            }else if(x['sku'] && x['sku'].length && x['type'] == 'relative'){
                                sku_preview += `component: ${x['destination_filter']['component']} with subcomponent: ${x['destination_filter']['sub_component']} and property: ${x['destination_filter']['property']}`
                            }

                            if(index != 0){
                                condition_preview = condition_preview + item['condition_combination_logic'] + ' '
                            }
                            if(tags_preview){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to: ${tags_preview} \n`
                            }
                            else if(sales_channel_preview){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to: ${sales_channel_preview} \n`
                            }
                            else if(sku_preview){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to: ${sku_preview} \n`
                            }
                            else if(property_source == 'no_condition'){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source}\n`
                            }
                            else if(operator_source == 'less' || operator_source == 'greater'){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} than value: ${value_source} \n`
                            }
                            else{
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to value: ${value_source} \n`
                            }
                        })
                        
                        // component_source = item['conditions'][0]['source_filter']['component']
                        // subcomponent_source = item['conditions'][0]['source_filter']['sub_component']
                        // property_source = item['conditions'][0]['source_filter']['property']
                        // value_source = item['conditions'][0]['value']
                        // operator_source = item['conditions'][0]['operator']

                        item['actions'].map((x, index) => {
                            // component_dest = x['source_filter']['component']
                            // subcomponent_dest = x['source_filter']['sub_component']
                            // property_dest = x['source_filter']['property']
                            value_dest = x['value']
                            // operator_dest = x['operator']
                            let tags_preview = ''
                            if(x['tag'] && x['tag'].length){
                                x['tag'].map((t, index) => {
                                    if(index != 0){
                                        tags_preview = tags_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    tags_preview = tags_preview + tags.find(i => i.id == t).tag
                                })
                            }
                            let sales_channel_preview = ''
                            if(x['sales_channel'] && x['sales_channel'].length){
                                x['sales_channel'].map((t, index) => {
                                    if(index != 0){
                                        sales_channel_preview = sales_channel_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    //debug_log("sales_channel brand", sales_channel_details)
                                    sales_channel_preview = sales_channel_preview + sales_channel_details.find(i => i.id == t).name
                                })
                            }

                            let sku_dest = x['sku'] && x['sku'].length ? 'sku_id!)*&($@:' + x['sku'][0] : ''
                            if(sku_dest){
                                sku_array.push(x['sku'][0])
                            }
                            let index_dest = x['index_value'] ? x['index_value'] : 'per_set'

                            let sku_preview = ''
                            // if(x['sku'] && x['sku'].length && x['type'] == 'absolute'){
                            //     sku_preview += 'sku_id!)*&($@:' + x['sku'][0]
                            //     sku_array.push(x['sku'][0])
                            // }else 
                            
                            if(x['sku'] && x['sku'].length && x['type'] == 'relative'){
                                sku_preview += `component: ${x['destination_filter']['component']} with subcomponent: ${x['destination_filter']['sub_component']} and property: ${x['destination_filter']['property']}`
                            }

                            if(index != 0){
                                destination_preview = destination_preview + item['action_combination_logic'] + ' '
                            }
                            
                            if(sku_preview){
                                destination_preview = destination_preview + `${value_dest} units of sku with ${sku_dest} relative to ${sku_preview} ${index_dest}`
                            }
                            else{
                                destination_preview = destination_preview + `${value_dest} units of sku with ${sku_dest}`
                            }
                        })

                        // component_dest = item['actions'][0]['source_filter']['component']
                        // subcomponent_dest = item['actions'][0]['source_filter']['sub_component']
                        // property_dest = item['actions'][0]['source_filter']['property']
                        // value_dest = item['actions'][0]['value']
                        // operator_dest = item['actions'][0]['operator']

                        temp_json['Rule Preview'] = applicability_preview + condition_preview + destination_preview
                        // temp_json['Rule Preview'] = `Applicable to \nComponent: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to value: ${value_applicability} \nIf the following criteria is satisfied: \nComponent: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to value: ${value_source} \nHardware to be added: `

        
                        //debug_log("hw rules preview", temp_json)
                        temp_dump_json.push(temp_json)
                    }
                    catch(err) {
                        console.log("Error in HW Rule ", item.name, err)
                    }
                })
            }
            let unique_sku_array = [... new Set(sku_array)]
            //debug_log('da sku unique', unique_sku_array);
            
            var identifiers = JSON.stringify({ id : unique_sku_array})
            var business_unit_id = user.current_business_unit_id
            var body = {identifiers, business_unit_id}
            var response = await general_fetch({ url: 'sku/get' , body });
            //debug_log('da sku unique', response);
            response.map(i => {
                let sku_preview_final = `division: ${i['sku_group']['sku_sub_category']['sku_category']['sku_division_id']}, category: ${i['sku_group']['sku_sub_category']['sku_category']['name']}, subcategory: ${i['sku_group']['sku_sub_category']['name']}, group: ${i['sku_group']['name']}, name: ${i['name']}`
                let re = 'sku_id!)*&($@:'+i['id']
                //debug_log('da sku unique', sku_preview_final, re);
                temp_dump_json.map(j => {
                    let rule_preview_modified = `${j['Rule Preview']}`
                    //debug_log('da sku unique 1', rule_preview_modified);
                    // rule_preview_modified.split(re).join(sku_preview_final)
                    let ans = rule_preview_modified.replaceAll(re, sku_preview_final)
                    //debug_log('da sku unique 2', ans);
                    j['Rule Preview'] = ans
                })
                
            })
    
            set_dump_json_hw(temp_dump_json)
        } catch(err) {
            console.error(err);
			alert.error('Error in Hardware Rules! Please contact admin')
        }
    }

    const fetch_da_rules_details = async () => {
        try {

            var resp = await general_fetch({ url: 'automation_rule/get_by_type', body:{type:'design_automation', business_unit_id: user.current_business_unit_id}});
            // set_initialized(true);
			// var rules_details= resp && resp.length ? resp.sort(name_sort):[]
			var rules_details= resp && resp.length ? resp : []
            set_da_rules(rules_details && rules_details.length ? rules_details.filter(x => !x.sku_linked_rule) : [])
            set_sku_level_da_rules(rules_details && rules_details.length ? rules_details.filter(x => x.sku_linked_rule) : [])
            // set_display_rules(rules_details);
			// set_last_item_order(rules_details.length?rules_details[rules_details.length-1].order:0);
			//debug_log('brand======>',rules_details);

            let component_applicability, subcomponent_applicability, property_applicability, operator_applicability, value_applicability, component_source, subcomponent_source, property_source, operator_source , value_source, component_dest, subcomponent_dest , property_dest, operator_dest, value_dest
            let temp_dump_json = [...dump_json_da]
            let sku_array = []
            //debug_log("preveiw len", da_rules.length)
            if(rules_details.length){
                rules_details.map(item =>{
                    try {
                        let applicability_preview = "Applicable to \n"
                        let condition_preview = "\nIf the following criteria is satisfied: \n"
                        let destination_preview = "\nThen do the following action: \n"

                        let temp_json = {}
                        temp_json['Name'] = item.name
                        temp_json['Description'] = item.description
                        temp_json['Design Constraint Rule Type'] = item.rule_type
                        //debug_log('dc item', item)
                        
                        item['applicability'].map((x, index) => {
                            subcomponent_applicability = x['source_filter']['sub_component']
                            property_applicability = x['source_filter']['property']
                            component_applicability = x['source_filter']['component']
                            operator_applicability = x['operator']
                            value_applicability = x['value']

                            let tags_preview = ''
                            if(x['tag'] && x['tag'].length){
                                x['tag'].map((t, index) => {
                                    if(index != 0){
                                        tags_preview = tags_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    tags_preview = tags_preview + tags.find(i => i.id == t).tag
                                })
                            }
                            let sales_channel_preview = ''
                            if(x['sales_channel'] && x['sales_channel'].length){
                                x['sales_channel'].map((t, index) => {
                                    if(index != 0){
                                        sales_channel_preview = sales_channel_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    //debug_log("sales_channel brand", sales_channel_details)
                                    sales_channel_preview = sales_channel_preview + sales_channel_details.find(i => i.id == t).name
                                })
                            }

                            // let sku_preview = ''
                            // if(x['sku'] && x['sku'].length && x['type'] == 'absolute'){
                            //     // x['sku'].map(async (t, index) => {
                            //         // if(index != 0){
                            //         //     sku_preview = sku_preview + ' ' + x['value_combination_logic'] + ' '
                            //         // }
                            //         let response = await general_fetch({ url: 'inventory/get_sku_ancestry' , body: { sku_id: x['sku'][0] } });
                            //         var identifiers = JSON.stringify({ id : x['sku'][0]})
                            //         var body = {identifiers}
                            //         var response1 = await general_fetch({ url: 'sku/get' , body });
                            //         // //debug_log("sku ances",response, response1)
                            //         sku_preview = sku_preview + `division: ${response[0]['sku_division_id']}, category: ${response[0]['sku_category_data'][0]['name']}, subcategory: ${response[0]['sku_sub_category_data'][0]['name']}, group: ${response[0]['sku_group_data'][0]['name']}`
                            //         // //debug_log("sku ances",sku_preview)
                            //     // })
                            // }

                            // //debug_log("sku ances",sku_preview)

                            // let tag = x['tag'] && x['tag'].length && ? x['tag'].map(t => tags.find(i => i.id == t)): ''
                            // let tag = x['tag'] && x['tag'].length && tags.find(i => i.id == x['tag'][0]).tag ? tags.find(i => i.id == x['tag'][0]).tag: ''

                            let sku_preview = ''
                            if(x['sku'] && x['sku'].length && x['type'] == 'absolute'){
                                sku_preview += 'sku_id!)*&($@:' + x['sku'][0]
                                sku_array.push(x['sku'][0])
                            }else if(x['sku'] && x['sku'].length && x['type'] == 'relative'){
                                sku_preview += `component: ${x['destination_filter']['component']} with subcomponent: ${x['destination_filter']['sub_component']} and property: ${x['destination_filter']['property']}`
                            }

                            if(index != 0){
                                applicability_preview = applicability_preview + item['applicability_combination_logic'] + ' '
                            }
                            if(tags_preview){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to: ${tags_preview} \n`
                            }
                            else if(sales_channel_preview){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to: ${sales_channel_preview} \n`
                            }
                            else if(sku_preview){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to: ${sku_preview} \n`
                            }
                            else if(property_applicability == 'no_condition'){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability}\n`
                            }
                            else if(operator_applicability == 'less' || operator_applicability == 'greater'){
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} than value: ${value_applicability} \n`
                            }
                            else{
                                applicability_preview = applicability_preview + `Component: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to value: ${value_applicability} \n`
                            }
                            //debug_log("applicability passed")

                        })

                        // subcomponent_applicability = item['applicability'][0]['source_filter']['sub_component']
                        // property_applicability = item['applicability'][0]['source_filter']['property']
                        // component_applicability = item['applicability'][0]['source_filter']['component']
                        // operator_applicability = item['applicability'][0]['operator']
                        // value_applicability = item['applicability'][0]['value']

                        item['conditions'].map((x,index)=> {
                            component_source = x['source_filter']['component']
                            subcomponent_source = x['source_filter']['sub_component']
                            property_source = x['source_filter']['property']
                            value_source = x['value']
                            operator_source = x['operator']
                            // tag_source = x['tag'] && item['conditions'][0]['tag'].length && item['conditions'][0]['tag'][0]
                            let tags_preview = ''
                            if(x['tag'] && x['tag'].length){
                                x['tag'].map((t, index) => {
                                    if(index != 0){
                                        tags_preview = tags_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    tags_preview = tags_preview + tags.find(i => i.id == t).tag
                                })
                            }
                            let sales_channel_preview = ''
                            if(x['sales_channel'] && x['sales_channel'].length){
                                x['sales_channel'].map((t, index) => {
                                    if(index != 0){
                                        sales_channel_preview = sales_channel_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    //debug_log("sales_channel brand", sales_channel_details)
                                    sales_channel_preview = sales_channel_preview + sales_channel_details.find(i => i.id == t).name
                                })
                            }

                            let sku_preview = ''
                            if(x['sku'] && x['sku'].length && x['type'] == 'absolute'){
                                sku_preview += 'sku_id!)*&($@:' + x['sku'][0]
                                sku_array.push(x['sku'][0])
                            }else if(x['sku'] && x['sku'].length && x['type'] == 'relative'){
                                sku_preview += `component: ${x['destination_filter']['component']} with subcomponent: ${x['destination_filter']['sub_component']} and property: ${x['destination_filter']['property']}`
                            }

                            if(index != 0){
                                condition_preview = condition_preview + item['condition_combination_logic'] + ' '
                            }
                            if(tags_preview){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to: ${tags_preview} \n`
                            }
                            else if(sales_channel_preview){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to: ${sales_channel_preview} \n`
                            }
                            else if(sku_preview){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to: ${sku_preview} \n`
                            }
                            else if(property_source == 'no_condition'){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source}\n`
                            }
                            else if(operator_source == 'less' || operator_source == 'greater'){
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} than value: ${value_source} \n`
                            }
                            else{
                                condition_preview = condition_preview + `Component: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to value: ${value_source} \n`
                            }
                        })

                        // component_source = item['conditions'][0]['source_filter']['component']
                        // subcomponent_source = item['conditions'][0]['source_filter']['sub_component']
                        // property_source = item['conditions'][0]['source_filter']['property']
                        // value_source = item['conditions'][0]['value']
                        // operator_source = item['conditions'][0]['operator']

                        item['actions'].map((x, index) => {
                            component_dest = x['source_filter']['component']
                            subcomponent_dest = x['source_filter']['sub_component']
                            property_dest = x['source_filter']['property']
                            value_dest = x['value']
                            operator_dest = x['operator']
                            let tags_preview = ''
                            if(x['tag'] && x['tag'].length){
                                x['tag'].map((t, index) => {
                                    if(index != 0){
                                        tags_preview = tags_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    tags_preview = tags_preview + tags.find(i => i.id == t).tag
                                })
                            }
                            let sales_channel_preview = ''
                            if(x['sales_channel'] && x['sales_channel'].length){
                                x['sales_channel'].map((t, index) => {
                                    if(index != 0){
                                        sales_channel_preview = sales_channel_preview + ' ' + x['value_combination_logic'] + ' '
                                    }
                                    //debug_log("sales_channel brand", sales_channel_details)
                                    sales_channel_preview = sales_channel_preview + sales_channel_details.find(i => i.id == t).name
                                })
                            }

                            let sku_preview = ''
                            if(x['sku'] && x['sku'].length && x['type'] == 'absolute'){
                                sku_preview += 'sku_id!)*&($@:' + x['sku'][0]
                                sku_array.push(x['sku'][0])
                            }else if(x['sku'] && x['sku'].length && x['type'] == 'relative'){
                                sku_preview += `component: ${x['destination_filter']['component']} with subcomponent: ${x['destination_filter']['sub_component']} and property: ${x['destination_filter']['property']}`
                            }

                            if(index != 0){
                                destination_preview = destination_preview + item['action_combination_logic'] + ' '
                            }
                            if(tags_preview){
                                destination_preview = destination_preview + `Component: ${component_dest} with subcomponent: ${subcomponent_dest} having property: ${property_dest} ${operator_dest} to: ${tags_preview} \n`
                            }
                            else if(sales_channel_preview){
                                destination_preview = destination_preview + `Component: ${component_dest} with subcomponent: ${subcomponent_dest} having property: ${property_dest} ${operator_dest} to: ${sales_channel_preview} \n`
                            }
                            else if(sku_preview){
                                destination_preview = destination_preview + `Component: ${component_dest} with subcomponent: ${subcomponent_dest} having property: ${property_dest} ${operator_dest} to: ${sku_preview} \n`
                            }
                            else if(property_dest == 'no_condition'){
                                destination_preview = destination_preview + `Component: ${component_dest} with subcomponent: ${subcomponent_dest}\n`
                            }
                            else if(operator_dest == 'less' || operator_dest == 'greater'){
                                destination_preview = destination_preview + `Component: ${component_dest} with subcomponent: ${subcomponent_dest} having property: ${property_dest} ${operator_dest} than value: ${value_dest} \n`
                            }
                            else{
                                destination_preview = destination_preview + `Component: ${component_dest} with subcomponent: ${subcomponent_dest} having property: ${property_dest} ${operator_dest} to value: ${value_dest} \n`
                            }
                        })

                        // component_dest = item['actions'][0]['source_filter']['component']
                        // subcomponent_dest = item['actions'][0]['source_filter']['sub_component']
                        // property_dest = item['actions'][0]['source_filter']['property']
                        // value_dest = item['actions'][0]['value']
                        // operator_dest = item['actions'][0]['operator']

                        temp_json['Rule Preview'] = applicability_preview + condition_preview + destination_preview
                        // temp_json['Rule Preview'] = `Applicable to \nComponent: ${component_applicability} with subcomponent: ${subcomponent_applicability} having property: ${property_applicability} ${operator_applicability} to value: ${value_applicability} \nIf the following criteria is satisfied: \nComponent: ${component_source} with subcomponent: ${subcomponent_source} having property: ${property_source} ${operator_source} to value: ${value_source} \nThen do the following action: \nComponent: ${component_dest} with subcomponent: ${subcomponent_dest} having property: ${property_dest} ${operator_dest} to value: ${value_dest}`

        
                        //debug_log("da rules preview", temp_json)
                        temp_dump_json.push(temp_json)
                    }
                    catch(err) {
                        console.log("Error in DA Rule ", item.name, err)
                    }
                })
            }
            let unique_sku_array = [... new Set(sku_array)]
            //debug_log('da sku unique', unique_sku_array);
            
            var identifiers = JSON.stringify({ id : unique_sku_array})
            var business_unit_id = user.current_business_unit_id
            var body = {identifiers, business_unit_id}
            var response = await general_fetch({ url: 'sku/get' , body });
            //debug_log('da sku unique', response);
            response.map(i => {
                let sku_preview_final = `division: ${i['sku_group']['sku_sub_category']['sku_category']['sku_division_id']}, category: ${i['sku_group']['sku_sub_category']['sku_category']['name']}, subcategory: ${i['sku_group']['sku_sub_category']['name']}, group: ${i['sku_group']['name']}, name: ${i['name']}`
                let re = 'sku_id!)*&($@:'+i['id']
                //debug_log('da sku unique', sku_preview_final, re);
                temp_dump_json.map(j => {
                    let rule_preview_modified = `${j['Rule Preview']}`
                    //debug_log('da sku unique 1', rule_preview_modified);
                    // rule_preview_modified.split(re).join(sku_preview_final)
                    let ans = rule_preview_modified.replaceAll(re, sku_preview_final)
                    //debug_log('da sku unique 2', ans);
                    j['Rule Preview'] = ans
                })
                
            })
            set_dump_json_da(temp_dump_json)
        } catch(err) {
            console.error(err);
			alert.error('Error in Design Automantion Rules! Please contact admin')
        }
    }

    const da_rules_to_preview = () => {
        let temp_dump_json = [...dump_json_da]
        console.log("preveiw len", da_rules.length)
        if(da_rules.length){
            da_rules.map(item =>{
                let temp_json = {}
                temp_json['Name'] = item.name
                temp_json['Description'] = item.description
                temp_json['Design Constraint Rule Type'] = item.rule_type

                console.log("da rules preview", temp_json)
                temp_dump_json.push(temp_json)
            })
        }

        set_dump_json_da(temp_dump_json)
    }

    const dc_rules_to_preview = () => {
        let temp_dump_json = [...dump_json_dc]
        if(dc_rules.length){
            dc_rules.map(item =>{
                let temp_json = {}
                temp_json['Name'] = item.name
                temp_json['Description'] = item.description
                temp_json['Design Constraint Rule Type'] = item.rule_type


                temp_dump_json.push(temp_json)
            })
        }
        set_dump_json_dc(temp_dump_json)
    }

    const mf_rules_to_preview = () => {
        let temp_dump_json = [...dump_json_mf]
        if(mf_rules.length){
            mf_rules.map(item =>{
                let temp_json = {}
                temp_json['Name'] = item.name
                temp_json['Description'] = item.description
                temp_json['Design Constraint Rule Type'] = item.rule_type


                temp_dump_json.push(temp_json)
            })
        }
        set_dump_json_mf(temp_dump_json)
    }

    const hw_rules_to_preview = () => {
        let temp_dump_json = [...dump_json_hw]
        if(hw_rules.length){
            hw_rules.map(item =>{
                let temp_json = {}
                temp_json['Name'] = item.name
                temp_json['Description'] = item.description
                temp_json['Design Constraint Rule Type'] = item.rule_type


                temp_dump_json.push(temp_json)
            })
        }
        set_dump_json_hw(temp_dump_json)
    }

	const Rules_Table = ({level}) => (
		<table className="text-left flex_property table_style">
			<thead style={{width:'100%'}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',width:'100%'}}>
				<th className='th_style flex_property' style={{flex:3}}><span>Rule Type</span></th>
				{/* <th className='th_style flex_property' style={{flex:5}}><span>Rule Description</span></th> */}
				<th className='th_style flex_property' style={{flex:2}}><span>No of Rules</span></th>
                <th className='th_style flex_property' style={{flex:3}}><span></span></th>
				{/* <th className='th_style flex_property' style={{flex:5}}><span>Active</span></th> */}
				{/* <th className='th_style flex_property' style={{border:0, flex:5}}><span>Created at</span></th> */}
				<th className='th_style' style={{border:0, flex:2, paddingRight:"20px"}}><span></span></th>
				<th className='th_style' style={{border:0, flex:2, paddingRight:"20px"}}><span></span></th>
                <CSVLink data = {tabs ==1 ? [...dump_json_dc, ...dump_json_mf, ...dump_json_da, ...dump_json_hw] : [...sku_level_dump_json_dc, ...sku_level_dump_json_mf, ...sku_level_dump_json_da, ...sku_level_dump_json_hw]} filename={tabs == 1 ? "Design_level_rules_CSV" : "Sku_level_rules_CSV"} style={{alignItems:'center'}} >
	        		<button style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center'}}>Download all CSV</span></button>
	        	</CSVLink>
			</tr>
			</thead>
			<tbody style={{display:"block",width:'100%'}}>
                <tr className='tr_style display_onhover' style={{borderTop:'1px solid #f2f4f6',width:'100%'}}>
                    <td className='td_style flex_property' style={{flex:3}}><span>Design Constraints</span></td>
                    {/* <td className='td_style flex_property' style={{flex:5}}><span>Rule Description</span></td> */}
                    <td className='td_style flex_property' style={{flex:2}}><span>{level === 'design' ? (!dc_rules ? 0 : dc_rules.length) : (!sku_level_dc_rules ? 0 : sku_level_dc_rules.length)}</span></td>
                    <td className='td_style flex_property x1' style={{flex:3}}>
                    {
                        level === 'design' ?<Link className='hide' to={'/design_intelligence/dc_rules/'}>Go to Rules Page</Link>:''
                    }
                    </td>
                    <td className='td_style flex_property x1' style={{border:0, flex:2, paddingRight:"20px"}}><span className='hide'><CSVLink data = {level === 'design' ? [...dump_json_dc] : [...sku_level_dump_json_dc]} filename={level === 'design' ? "Design_level_Dc_rules_CSV" : "Sku_level_Dc_rules_CSV"} style={{alignItems:'center'}} >
	        	    <i className='fa fa-download'></i></CSVLink></span></td>
                    <td className='td_style' style={{border:0, flex:2, paddingRight:"20px"}}><span></span></td>
                </tr>
                <tr className='tr_style display_onhover' style={{borderTop:'1px solid #f2f4f6',width:'100%'}}>
                    <td className='td_style flex_property' style={{flex:3}}><span>Manufacturing Rules</span></td>
                    {/* <td className='td_style flex_property' style={{flex:5}}><span>Rule Description</span></td> */}
                    <td className='td_style flex_property' style={{flex:2}}><span>{level === 'design' ? (!mf_rules ? 0 : mf_rules.length) : (!sku_level_mf_rules ? 0 : sku_level_mf_rules.length)}</span></td>
                    <td className='td_style flex_property x1' style={{flex:3}}>
                    {
                        level === 'design' ?<Link className='hide' to={'/design_intelligence/mf_rules/'}>Go to Rules Page</Link>:''
                    }
                    </td>
                    <td className='td_style flex_property x1' style={{border:0, flex:2, paddingRight:"20px"}}><span className='hide'><CSVLink data = {level === 'design' ? [...dump_json_mf] : [...sku_level_dump_json_mf]} filename={level === 'design' ? "Design_level_Mf_rules_CSV" : "Sku_level_Mf_rules_CSV"} style={{alignItems:'center'}} >
	        	    <i className='fa fa-download'></i></CSVLink></span></td>
                    <td className='td_style' style={{border:0, flex:2, paddingRight:"20px"}}><span></span></td>
                </tr>
                <tr className='tr_style display_onhover' style={{borderTop:'1px solid #f2f4f6',width:'100%'}}>
                    <td className='td_style flex_property' style={{flex:3}}><span>Hardware Rules</span></td>
                    {/* <td className='td_style flex_property' style={{flex:5}}><span>Rule Description</span></td> */}
                    <td className='td_style flex_property' style={{flex:2}}><span>{level === 'design' ? (!hw_rules ? 0 : hw_rules.length) : (!sku_level_hw_rules ? 0 : sku_level_hw_rules.length)}</span></td>
                    <td className='td_style flex_property x1' style={{flex:3}}>
                    {
                        level === 'design' ? <Link className='hide' to={'/design_intelligence/hw_rules/'}>Go to Rules Page</Link> : ''
                    }
                    </td>
                    <td className='td_style flex_property x1' style={{border:0, flex:2, paddingRight:"20px"}}><span className='hide'><CSVLink data = {level === 'design' ? [...dump_json_hw] : [...sku_level_dump_json_hw]} filename={level === 'design' ? "Design_level_Hw_rules_CSV" : "Sku_level_Hw_rules_CSV"} style={{alignItems:'center'}} >
	        	    <i className='fa fa-download'></i></CSVLink></span></td>
                    <td className='td_style' style={{border:0, flex:2, paddingRight:"20px"}}><span></span></td>
                </tr>
                <tr className='tr_style display_onhover' style={{borderTop:'1px solid #f2f4f6',width:'100%'}}>
                    <td className='td_style flex_property' style={{flex:3}}><span>Design Automation</span></td>
                    {/* <td className='td_style flex_property' style={{flex:5}}><span>Rule Description</span></td> */}
                    <td className='td_style flex_property' style={{flex:2}}><span>{level === 'design' ? (!da_rules ? 0 : da_rules.length) : (!sku_level_da_rules ? 0 : sku_level_da_rules.length)}</span></td>
                    <td className='td_style flex_property x1' style={{flex:3}}>
                    {
                        level === 'design' ? <Link className='hide' to={'/design_intelligence/am_rules/'}>Go to Rules Page</Link> : ''
                    }
                    </td>
                    <td className='td_style flex_property x1' style={{border:0, flex:2, paddingRight:"20px"}}><span className='hide'><CSVLink data = {level === 'design' ? [...dump_json_da] : [...sku_level_dump_json_da]} filename={level === 'design' ? "Design_level_Am_rules_CSV" : "Sku_level_Am_rules_CSV"} style={{alignItems:'center'}} >
	        	    <i className='fa fa-download'></i></CSVLink></span></td>
                    <td className='td_style' style={{border:0, flex:2, paddingRight:"20px"}}><span></span></td>
                </tr>
            </tbody>
		</table>
	);

    const fetch_sales_channel_details = async() => {
        try{
            let resp, sales_channel_details = []
			let body = {
				"include_sales_channel_profile_info": true, 
				"include_bu_mapping": true, 
				"include_bu_info": false, 
				"include_price_type_mapping": true, 
				"include_price_type_info": true, 
				"include_user_mapping": true, 
				"include_user_info": false
			}

			if(org_bu_view === 'bu'){
				if(user && user.current_business_unit_id){
					body['business_unit_ids'] = [user.current_business_unit_id]
					resp = await general_fetch({ url: 'sales_channel/get_by_business_unit' , body})
					let owned_sc = resp && resp.sales_channels_by_business_unit && resp.sales_channels_by_business_unit[user.current_business_unit_id] ? resp.sales_channels_by_business_unit[user.current_business_unit_id].owned : []
					// let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x)) sales_channel_details.push(resp.sales_channels[x])}) : []
					let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x) && !(all_bu_in_store && all_bu_in_store.length === 1 && resp.sales_channels[x].type === 'bu_feeder')) sales_channel_details.push(resp.sales_channels[x])}) : []
				}
			}else{
				resp = await general_fetch({ url: 'sales_channel/get_of_store' , body})
				sales_channel_details = resp && resp.sales_channels && resp.sales_channels.owned ? resp.sales_channels.owned.filter(x => !x.business_unit_id) : []
			}
            sales_channel_details = sales_channel_details.map(single_sales_channel => {
                let price_types = single_sales_channel.price_type_ids && single_sales_channel.price_type_ids.length ? single_sales_channel.price_type_ids.map(x => resp.price_types[x]) : []
                return {...single_sales_channel, price_types}
            })
            set_sales_channel_details(sales_channel_details)  
        }catch(err){
            console.log(err);
            handleError(err)
        }
    }

    useEffect(() => {
		fetch_sales_channel_details()
    }, [user]);

    useEffect(() => {
        if(sales_channel_details && tags && tags.length){
            if(!dc_rules.length){
                fetch_dc_rules_details()
            }
            if(!da_rules.length){
                fetch_da_rules_details()
            }
            if(!mf_rules.length){
                fetch_mf_rules_details()
            }
            if(!hw_rules.length){
                fetch_hw_rules_details()
            }
        }
    }, [sales_channel_details, tags]);

    useEffect(() => {
        console.log("tags", tags);
    }, []);
	return (
		<Main_Grid active_tab="design_intelligence" user={user} store_details={store_details} get_store_details={get_store_details}>
			<Row style={{padding:'10px 0px', color:'#22272e'}}>
	        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
	        		 Design Intelligence
	        	</Col>
	        	<Col xs={6} style={{textAlign:'right'}}>
				
                </Col>
        	</Row>
	        <Row style={{height:'calc(100% - 90px)'}}>
		        <Col style={{height:'100%'}}>
		        	<Card style={{borderRadius: 0, height:'100%'}}>
		        		{/* <div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Rule Name, Description, Type' /></div> */}
                        <div className="nav-wrapper" style={{padding:'0px'}}>
                        <Nav 
                            style={{display:'flex', alignItems:'center', fontSize:'14px'}}
                            className="flex-md-row"
                            id="tabs-icons-text" 
                            pills
                            role="tablist"
                        >
                            <NavItem>
                                <NavLink
                                    aria-selected={tabs === 1}
                                    style={{marginLeft:'10px'}}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: tabs === 1
                                    })}
                                    onClick={e => toggleNavs(e, 1)}
                                    href="#pablo"
                                    role="tab"
                                >
                                    <span>Design Level Rules</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    aria-selected={tabs === 2}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: tabs === 2
                                    })}
                                    onClick={e => toggleNavs(e, 2)}
                                    href="#pablo"
                                    role="tab"
                                >
                                    <span>Sku Level Rules</span>
                                </NavLink>
                            </NavItem>                            
                        </Nav>

                        <TabContent activeTab={"tabs" + tabs}>
                            <TabPane tabId="tabs1" style={{fontSize:'12px'}}>
                                <div id='design-paginator' style={{height:'100%'}}> <Rules_Table level = 'design'/></div>
                            </TabPane>
                            <TabPane tabId="tabs2" style={{fontSize:'12px'}}>
                                <div id='design-paginator' style={{height:'100%'}}> <Rules_Table level = 'sku'/></div>
                            </TabPane>
                        </TabContent>
                        </div>
					</Card>
		        </Col>
	        </Row>
	        <Row style={{height:'3px'}}>
		        <Col>
		        </Col>
	        </Row>
	        {/* <Row>
		        <Col>	
					<Pegasus_Paginator 
						id='design-paginator'
						end_index={end_index}
						set_end_index={set_end_index}
						start_index={start_index}
						set_start_index={set_start_index}
						all_projects={display_rules && display_rules.length ? display_rules.length : '0'}
					/>
		        </Col>
	        </Row> */}
		</Main_Grid>
	);
}	


export default DesignIntelligence;

