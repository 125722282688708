import React, { useState , useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {FormGroup, Modal, Button, Form, Input, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import general_fetch from '../../../utils/fetch';
import name_sort from '../../../utils/name_sort';
import custom_sort from '../../../utils/custom_sort';
import GroupInventoryCard from './GroupInventoryCard';
import Group_ListView from './Group_ListView';
import AddNewSKU from './AddNewSKU'
import EditSkuPropertiesTab from "./EditSkuPropertiesTab.js"
import Main_Grid from '../../../components/Main_Grid';
import { useAlert } from 'react-alert'
import EditGroupProperties from './EditGroupProperties.js'
import AddNewGroup from './AddNewGroup';
import AFW_modal from './AFW';
//import AlertBox from "../../../components/AlertBox/";
import ExtendedDropdown from "../../../components/ExtendedDropdown/";
import Category_edit_confirm_modal from "../../../components/Category_edit_confirm_modal/";
import AddSubCategory from './AddSubCategory';
import './sku_groups.css'
import LazyLoad from 'react-lazyload';
import classnames from "classnames";
import BulkCreateModal from './BulkCreateModal';
import UploadFile from '../../../components/UploadFile';
import SkuDraftsModal from './SkuDraftsModal';
import SkuPublishHistory from './SkuPublishHistoryModal';
import { act } from 'react-dom/test-utils';
import { OrgBuContext } from '../../../components/Contexts/OrgBuContext';
import { UserContext } from '../../../components/Contexts/UserContext';
import build_path from '../../../utils/path.js';
import includes_search_string from '../../../utils/includes_search_string.js';
import { handleError } from '../../../utils/error.js';



const crypto = require('crypto');
const format = require('biguint-format');


const ImportSkuJsonModal = ({open, handleClose, sku_json_file, set_sku_json_file, get_unique_sku_id, sku_array, set_sku_array, set_show_bulk_create_modal, active_category, set_active_division_id, fetch_categories, create_new_attempt}) => {
    
    const [json_text, set_json_text] = useState('') 
    const [improper_file_tag, set_improper_file_tag] = useState(false)
    const alert = useAlert()

    const onclick_next = () => {
        set_json_text('')
        if(!improper_file_tag && sku_json_file){
            create_new_attempt(active_category)
            set_show_bulk_create_modal(true)
            handleClose()
            set_improper_file_tag(false)
            set_sku_json_file(null)
        }
    }

    // const fetch_ancestry = async(id) => {
    //     let resp = await general_fetch({url:'sku_sub_category/get_ancestry', body:{ids:[id]}})
    // }

    const check_and_set_sku_array = (sku_json) => {
        let temp_array = sku_json.data
        let temp_sku_array = []
        
        if(temp_array && temp_array.length){
            for(let i=0;i<temp_array.length;i++){
                if(temp_array[i]['sub_category'] || temp_array[i]['sku_group_id'] || temp_array[i]['name'] || temp_array[i]['placement_id'] || temp_array[i]['material_id'] || temp_array[i]['height'] || temp_array[i]['sales_channels']){
                    temp_sku_array.push({id:get_unique_sku_id(), 
                        sub_category:temp_array[i]['sub_category']?temp_array[i]['sub_category']:null, 
                        sku_group_id:temp_array[i]['sku_group_id']?temp_array[i]['sku_group_id']:null, 
                        name:temp_array[i]['name']?temp_array[i]['name']:null, 
                        placement_id:temp_array[i]['placement_id']?temp_array[i]['placement_id']:null, 
                        // low_model_3d_id:temp_array[i]['low_model_3d_id']?temp_array[i]['low_model_3d_id']:'',
                        // display_pic_id: temp_array[i]['display_pic_id'] ? temp_array[i]['display_pic_id'] :'',
                        material_id: temp_array[i]['material_id'] ? temp_array[i]['material_id'] :null,
                        height : temp_array[i]['height'] ? temp_array[i]['height'] :null,
                        // width : temp_array[i]['width'] ? temp_array[i]['width'] :null,
                        depth : temp_array[i]['depth'] ? temp_array[i]['depth'] :null,
                        sales_channels : temp_array[i]['sales_channels'] ? temp_array[i]['sales_channels'] :null,
                        additional_properties: temp_array[i]['additional_properties'] ? temp_array[i]['additional_properties'] :null,
                        linked_skus: temp_array[i]['linked_skus'] ? temp_array[i]['linked_skus'] :null,
                        model_no: temp_array[i]['model_no'] ? temp_array[i]['model_no'] :null,
                       })
                }
            }
        }

        if(temp_sku_array && temp_sku_array.length && set_sku_array){
            set_improper_file_tag(false)
            set_sku_array(temp_sku_array)
        }else{
            set_improper_file_tag(true)
        }
    }

    useEffect(() => {
        if(sku_json_file && json_text){
            try{
                let sku_json = JSON.parse(json_text)
                console.log("printing sku json file", sku_json)
                check_and_set_sku_array(sku_json)
            }catch(err){
                console.error("Parsing the Uploaded file Failed - ", err);
                alert.error("Please Upload a JSON file.")
            }
        }
    },[sku_json_file ,json_text])
    
    return(
        <Modal style={{marginTop:'20vh', marginLeft:'36vw', width:'550px'}} size="md" isOpen={open} toggle={handleClose}>
            <Card style = {{height:'250px'}}>
                <CardHeader className='global_modal_header'>
					<span>Import SKUs using JSON file {' (Category: ' } {active_category?active_category.name:''} {')'}</span>
				</CardHeader>

                <CardBody style={{backgroundColor:'#F5F6F8'}}>
                    <div style={{marginTop:'3%'}}></div>
                    <span style={{display:'flex'}}>
                    <UploadFile accept=".json" id="id_upload_json_file" button_name='Import JSON' set_file={set_sku_json_file} set_text={set_json_text} style={{ backgroundColor: '#f0ad4e', borderColor: '#f0ad4e', color:'white', fontSize:'15px'}} />
                    <span style={{marginLeft:'2%', marginTop:'1%', fontSize:'14px', opacity:sku_json_file?'1':'0.5'}}><i class="fa fa-paperclip" aria-hidden="true" style={{color:'grey'}} />{sku_json_file && sku_json_file.name ? ' '+sku_json_file.name:' No File Chosen'}</span>
                    </span>
                    {
                        improper_file_tag ?
                        <div style={{marginTop:'3%', fontSize:'15px', color:'red'}}>File Format Mismatch, JSON doesn't contain SKU details</div>
                        :
                        (
                            sku_json_file
                            ?''
                            :<div style={{marginTop:'3%', fontSize:'15px'}}>Import the JSON file containing the details of SKUs to be published</div>
                        )
                    }
                </CardBody>

                <CardFooter className='global_modal_footer'>
                    <Button className='blue_button' type="button" onClick = {() => {onclick_next()}}>
                        Next
                    </Button>
                </CardFooter>
            </Card>
        </Modal>
    )
}


const ExistingDraftsWarning = ({open, handleClose, set_show_bulk_create_modal, create_new_attempt , active_category, edit_draft, sku_draft_data, attempt_id}) => {
    return (
        <div>
            <Modal style ={{marginTop:'17%', height:'150px', width:'360px'}} size="sm" isOpen={open}>
                <Card>
                    {/* <CardHeader className='global_modal_header'>
                        Warning!
                    </CardHeader> */}
                    <div style = {{backgroundColor:'#F5F6F8', overFlow:'auto', padding:'5%'}}> 
                        <div style = {{paddingLeft:'0%', paddingDown:'10%', textAlign:'center'}}>You already have existing items in the draft, please use the same draft to add more.</div>
                        <div style={{paddingLeft:'21%', paddingTop:'5%'}}>
                            <Button className='white_button' type="button" onClick={() => handleClose()}>Cancel</Button>
                            <Button className='blue_button' type="button" onClick={() => {edit_draft(sku_draft_data, attempt_id, 'draft');handleClose()}}>Edit existing draft</Button>
                        </div>
                    </div>
                    {/* <CardFooter className='global_modal_footer'>
                        <Button className='white_button' type="button" onClick={() => handleClose()}>Cancel</Button>
                        <Button className='blue_button' type="button" onClick={() => {set_discard_changes(true);handleClose()}}>Discard Changes</Button>
                    </CardFooter> */}
                </Card>
            </Modal>
        </div>
    )
}

const BulkCreateMenu = ({ confirmation_alert, get_store_details, store_details, set_page_loader, submit_for_delete_confirm, set_active_tab, send_analytics_event}) => {

    const [sku_categories, set_sku_categories] = useState([])
    const [tabs , set_tabs ] = useState(1)
    const [show_bulk_create_modal, set_show_bulk_create_modal] = useState(false)
    const [active_category, set_active_category] = useState(null)
    const [active_division_id, set_active_division_id] = useState(null)
    const [sku_array, set_sku_array] = useState([])
    const [show_import_sku_json_modal, set_show_import_sku_json_modal] = useState(false)
    const [sku_json_file, set_sku_json_file] = useState(null)
    const [using_import_file_method, set_using_import_file_method] = useState(false)
    const [using_edit_draft_method, set_using_edit_draft_method] = useState(false)
    const [show_sku_drafts_modal, set_show_sku_drafts_modal] = useState(false)
    const [show_publish_history_modal, set_show_publish_history_modal] = useState(false)
    const [active_attempt_id, set_active_attempt_id] = useState(null)
    const [array_of_latest_attempts, set_array_of_latest_attempts] = useState([])
    const [json_of_attempts_history, set_json_of_attempts_history] = useState([])
    const [all_users, set_all_users] = useState({})
    const [show_existing_drafts_warning, set_show_existing_drafts_warning] = useState(false);
    const [existing_draft_data,set_existing_draft_data] = useState(null)
    const [existing_draft_attempt_id, set_existing_draft_attempt_id] = useState(null)
    const [viewing_published_skus, set_viewing_published_skus] = useState(false)
    const [search_string, set_search_string] = useState('');
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)

    const history = useHistory();
    const alert = useAlert();

    const toggleNavs = (e, index) => {
        console.log("page loader")
        e.preventDefault();
		set_tabs(index)
	}

    const fetch_categories = async (sku_division_id) => {
		try {
            let body = {sku_division_id : sku_division_id}
			if(org_bu_view === 'bu'){
				body['business_unit_id'] = user.current_business_unit_id
			}
            set_page_loader({
                show:true,
                text:'Please wait, fetching categories...'
            })
			var response = await general_fetch({ url: 'inventory/get_categories', body });
			// set_initialized(true)
			var resp = response && response.length?response.sort(name_sort):[];
            console.log("categories", resp)
			set_sku_categories(resp)
            set_page_loader({
                show:false,
                text:''
            })
		} catch(err) {
			console.log(err);
		}
	}

    // function uuidv4() {
    //     return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    //       (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(36)
    //     );
    // }
    const get_unique_sku_id = () => {
        const id = crypto.randomBytes(10);
        return format(id, 'hex');
    }
      

    const add_new_rows = () => {
        let a = [...sku_array]
        for(let i=0;i<10;i++){
			let b = {id:get_unique_sku_id()}
			if(active_division_id != 'finish') b['placement_id'] = 'floor'
            a.push(b)
        }
        set_sku_array(a)
    }

    const delete_sku_row = (idx) => {
        let a = [...sku_array]
        a.splice(idx,1)
        set_sku_array(a)
    } 

    const update_sku_array = (index, key, value, validateValue) => {
        if(validateValue){
            if(!validateValue(value)){
                return
            }
        }
        console.log("update sku array ------->", index, key, value)
        let a = [...sku_array]
        console.log("update sku array ------->", index, key, value, a)
        if(key == 'sales_channels'){
            let b = a[index]['sales_channels']
            if(b){
                for(let i=0;i<value.length;i++){
                    let c = b.find(o => o.id == value[i].id)
                    if(!c) b.push(value[i])
                }
            }else{
                a[index][key] = value
            }

            if(b){
                for(let i=0;i<b.length;i++){
                    let d = value.find(o => o.id == b[i].id)
                    if(!d){
                        b.splice(i,1)
                        break
                    }
                }
            }
        }else{
            a[index][key] = value
        }
        set_sku_array(a)
    }

    const update_sku_array_sales_channel = (index, sales_channel_id, price_type_id, key, value) => {
        let a = [...sku_array]
        let b = a[index]['sales_channels']
        console.log("update sales channel b", b, a)
        let c = b ? b.find(o => o.id == sales_channel_id) :''
        console.log("update sales channel c", c)
        if(c){
            let d = c['price_types']
            console.log("update sales channel d", d)
            let e = d.find(o => o.id == price_type_id)
            console.log("update sales channel e", e)
            e[key] = value
        }
        set_sku_array(a)
    }

    const can_add_new_rows = () => {
        if(sku_array && sku_array.length){
            if(sku_array[sku_array.length-1].sub_category != null) return true 
            return false
        }else{
            return true
        }
    }

    const create_new_attempt = async(sku_category) => {
        try{
            if(sku_category){
                let body = {sku_category_id: sku_category.id, type:'upload'}
                if(org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
                let resp = await general_fetch({url:'sku_bulk_operation/create_attempt', body})
                console.log("create attempt", resp)
                if(resp){
                    set_active_attempt_id(resp ? resp.id :null)
                }
            }
        }catch(err){
            console.log(err)
        }
    }

    const get_latest_drafts = async(sku_category, clicked_on_plus=false) => {
        try{
            set_page_loader({
                show:true,
                text:'please wait...'
            })
            let body = {sku_category_id:sku_category.id, type:'upload'}
            if(org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
            let resp = await general_fetch({url:'sku_bulk_operation/get_latest_attempt', body})
            console.log("latest attempt", resp)
            if(resp === -1) resp = []
            set_page_loader({
                show:false,
                text:''
            })
            if(resp && resp!=-1){
                set_array_of_latest_attempts(resp)
                if(clicked_on_plus){
                    let temp_attempt = resp.find(o => o.user_id == user.id)
                    console.log("clicked on plus", temp_attempt)
                    if(temp_attempt && (temp_attempt.draft)){
                        set_existing_draft_data(temp_attempt.draft.data)
                        set_existing_draft_attempt_id(temp_attempt.id)
                        set_show_existing_drafts_warning(true)
                    }else{
                        set_show_bulk_create_modal(true)
                        create_new_attempt(sku_category)
                    }
                }
            }
            // else{
            //     alert.error('error')
            //     set_show_bulk_create_modal(true)
            //     create_new_attempt(sku_category)
            // }
        }catch(err){
            console.log(err)
            if(clicked_on_plus){
                console.log("entered catch")
                set_show_bulk_create_modal(true)
                create_new_attempt(sku_category)
            }
        }
    }

    const get_publish_history = async() => {
        try{
            let cat_ids = sku_categories && sku_categories.length ? sku_categories.map(x => x.id):[]
            let body = {sku_category_ids:cat_ids, type:'upload'}
            if(org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
            let resp = await general_fetch({url:'sku_bulk_operation/get_succesful_attempts', body})
            console.log("publish history", resp)
            if(resp){
                set_json_of_attempts_history(resp)
            }
        }catch(err){
            console.log(err)
        }
        
    }

    const fetch_all_users = async() => {
        try{
            let resp = await general_fetch({url:'user/get_all'})
            console.log("latest attempt", resp)
            let temp_users = {}
            let t =  resp && resp.length ? resp.map(x => {temp_users[x.id] = x.name;return 1}):''
            set_all_users(temp_users)
        }catch(err){
            console.log(err)
        }
    }

    const edit_draft = async(data, attempt_id, value, data_file_path) => {
        try{
            set_using_edit_draft_method(value)
            if(data){
                set_sku_array(data)
            }else if(data_file_path){
                let resp = await general_fetch({full_url: build_path(global.config.static_path, data_file_path), method: 'GET', returnRawResponse: true, credentials: 'omit'})
                if(resp){
                    set_sku_array(resp)
                }else{
                    set_sku_array([])
                }
            }else{
                set_sku_array([])
            }
            if(value == 'published_history'){
                create_new_attempt(active_category)
            }else{
                set_active_attempt_id(attempt_id)
            }
            set_show_bulk_create_modal(true)
        }catch(err){
            handleError(err)
        }
    }

    const SkuDataTable = ({sku_division_id}) => {
        return(
            <table style={{height:'100%'}} className="text-left flex_property table_style">
                <thead style={{width:"100%",position:'sticky',top:'40px',backgroundColor:'white'}}>
                    <tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
                        <th className='th_style flex_property' style={{flex:'2', fontSize:'14px', fontWeight:'600'}}><span>Category Name</span></th>
                        {/* <th className='th_style flex_property' style={{flex:'1', fontSize:'14px', fontWeight:'600'}}><span>In Draft</span></th>
                        <th className='th_style flex_property' style={{flex:'1', fontSize:'14px', fontWeight:'600'}}><span>Published</span></th>
                        <th className='th_style flex_property' style={{flex:'3', fontSize:'14px', fontWeight:'600'}}><span>Total SKUs</span></th> */}
                    </tr>
                </thead>
                <tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
                {
                    sku_categories && sku_categories.length ? sku_categories.map(sku_category => (
                        <tr className={'tr_style display_onhover'} style={{display: includes_search_string(sku_category.name, search_string) ? 'flex' : 'none'}}>
                            <td className='td_style flex_property' style={{flex:'1'}}><span className='table_text_overflow'>{sku_category.name?sku_category.name:''}</span></td>   
                            <td className='td_style flex_property x1' style={{ justifyContent:'flex-end',overflow:'hidden', flex:'4',marginRight:'10px'}}>
                                <span className='hide' style={{marginRight:'28px', cursor:'pointer'}} onClick ={() => {set_active_category(sku_category);open_import_sku_json_modal()}}>Import File</span>
                                <span className='hide' style={{marginRight:'28px', cursor:'pointer', alignItems:'center'}} onClick={() => {set_active_category(sku_category);open_sku_drafts_modal();get_latest_drafts(sku_category)}}><i style={{paddingRight:'5px'}} className='fa fa-pencil-alt' aria-hidden="true"/>Edit Draft</span>
                                <span className='hide' style={{marginRight:'20px', cursor:'pointer'}}><i onClick ={() => {set_active_category(sku_category); get_latest_drafts(sku_category,true)}} className='fa fa-plus' aria-hidden="true"/></span>
                            </td>
                        </tr>
                    )):''
                }
                </tbody>
            </table>
        )
    }

    const handle_close = () => {
        set_show_bulk_create_modal(false)
        set_sku_array([])
        set_active_category(null)
        set_using_edit_draft_method(false)
        set_viewing_published_skus(false)
        // set_active_division_id(null)
    }

    const open_import_sku_json_modal = () => {
        set_show_import_sku_json_modal(true)
        set_using_import_file_method(true)
    }

    const close_import_sku_json_modal = () => {
        set_show_import_sku_json_modal(false)
        set_using_import_file_method(false)
    }

    const open_sku_drafts_modal = () => {
        set_show_sku_drafts_modal(true)
    }

    const close_sku_drafts_modal = () => {
        set_show_sku_drafts_modal(false)
        set_array_of_latest_attempts(null)
    }

    const open_publish_history_modal = () => {
        set_show_publish_history_modal(true)
    }

    const close_publish_history_modal = () => {
        set_show_publish_history_modal(false)
    }

    const close_existing_drafts_warning = () => {
        set_show_existing_drafts_warning(false)
    }

    useEffect(() => {
        if(tabs == 1){
            fetch_categories('furniture')
            set_active_division_id('furniture')
        }else if(tabs == 2){
            fetch_categories('accessory')
            set_active_division_id('accessory')
        }else if(tabs == 3){
            fetch_categories('hardware')
            set_active_division_id('hardware')
        }else if(tabs == 4){
            fetch_categories('building')
            set_active_division_id('building')
        }else if(tabs == 5){
            fetch_categories('finish')
            set_active_division_id('finish')
        }else if(tabs == 6){
            fetch_categories('template')
            set_active_division_id('template')
        }
    },[tabs])

    useEffect(() => {
        let temp_json = {}

    }, [sku_categories]);

    useEffect(() => {
        fetch_all_users()
    },[])

    return(
        <Main_Grid active_tab='catalogue' user={user} store_details={store_details} get_store_details={get_store_details}>
            <BulkCreateModal 
                open = {show_bulk_create_modal}
                user = {user}
                handleClose = {handle_close} 
                active_category = {active_category} 
                active_division_id = {active_division_id} 
                sku_array = {sku_array} 
                set_sku_array = {set_sku_array}
                add_new_rows = {add_new_rows}
                delete_sku_row = {delete_sku_row}
                update_sku_array = {update_sku_array}     
                can_add_new_rows = {can_add_new_rows}
                update_sku_array_sales_channel = {update_sku_array_sales_channel}
                set_page_loader={set_page_loader}
                using_import_file_method = {using_import_file_method}
                using_edit_draft_method = {using_edit_draft_method}
                get_unique_sku_id = {get_unique_sku_id}
                create_new_attempt = {create_new_attempt}
                active_attempt_id = {active_attempt_id}
                viewing_published_skus = {viewing_published_skus}
                store_details={store_details}
            />

            <ImportSkuJsonModal 
                open={show_import_sku_json_modal} 
                handleClose={close_import_sku_json_modal} 
                sku_json_file = {sku_json_file}
                set_sku_json_file = {set_sku_json_file}
                set_show_bulk_create_modal = {set_show_bulk_create_modal}
                active_category = {active_category}
                set_active_division_id = {set_active_division_id}
                fetch_categories = {fetch_categories}
                get_unique_sku_id = {get_unique_sku_id}
                sku_array = {sku_array}
                set_sku_array = {set_sku_array}
                create_new_attempt = {create_new_attempt}
            />

            <SkuDraftsModal 
                open = {show_sku_drafts_modal}
                handleClose = {close_sku_drafts_modal}
                user = {user}
                array_of_latest_attempts = {array_of_latest_attempts}
                active_division_id = {active_division_id}
                active_category = {active_category}
                all_users = {all_users}
                set_sku_array = {set_sku_array}
                set_show_bulk_create_modal = {set_show_bulk_create_modal}
                edit_draft = {edit_draft}
                get_latest_drafts = {get_latest_drafts}
                set_page_loader = {set_page_loader}
                submit_for_delete_confirm = {submit_for_delete_confirm}
                create_new_attempt = {create_new_attempt}
            />

            <SkuPublishHistory 
                open = {show_publish_history_modal}
                handleClose = {close_publish_history_modal}
                user = {user}
                all_users = {all_users}
                json_of_attempts_history = {json_of_attempts_history}
                active_division_id = {active_division_id}
                active_category = {active_category}
                sku_categories = {sku_categories}
                edit_draft = {edit_draft}
                set_active_category = {set_active_category}
                viewing_published_skus = {viewing_published_skus}
                set_viewing_published_skus = {set_viewing_published_skus}
                get_unique_sku_id = {get_unique_sku_id}
                set_page_loader = {set_page_loader}
            />

            <ExistingDraftsWarning
                open = {show_existing_drafts_warning}
                handleClose = {close_existing_drafts_warning}
                set_show_bulk_create_modal = {set_show_bulk_create_modal}
                create_new_attempt = {create_new_attempt}
                active_category = {active_category}
                edit_draft = {edit_draft}
                sku_draft_data = {existing_draft_data}
                attempt_id = {existing_draft_attempt_id}
            />
            <Row style={{padding:'15px 15px 5px 15px', color:'#22272e', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <div className='flex_property'>
                    <span className='bulk_selection_exit_button' onClick={() => {history.goBack()}}><i className='fa fa-caret-left' /></span>
                    <span style={{fontSize:'18px', fontWeight:'900'}}>Bulk Create SKUs</span>
                </div>
                <button style={{cursor:'pointer', height:'32px', border: '1px solid #b8bfca', backgroundColor:'white', borderRadius:'4px', padding:'8px 10px', display:'flex', alignItems:'center'}} onClick={() => {open_publish_history_modal();get_publish_history()}}><span style={{fontSize:'13px', color:'#4C5D6E'}}>Publish History</span></button>
            </Row>

                <Nav 
                    style={{display:'flex', alignItems:'center'}}
                    className="flex-md-row"
                    id="tabs-icons-text" 
                    pills
                    role="tablist"
                >
                    <NavItem>
                        <NavLink
                            aria-selected={tabs === 1}
                            style = {{}}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: tabs === 1
                            })}
                            onClick={e => toggleNavs(e, 1)}
                            href="#pablo"
                            role="tab"
                        >
                            <span id='bulk_create_menu_furniture'>Furniture</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            aria-selected={tabs === 2}
                            style = {{marginLeft:'10px'}}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: tabs === 2
                            })}
                            onClick={e => toggleNavs(e, 2)}
                            href="#pablo"
                            role="tab"
                        >
                            <span id='bulk_create_menu_accessories' >Accessories</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            aria-selected={tabs === 3}
                            style = {{marginLeft:'10px'}}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: tabs === 3
                            })}
                            onClick={e => toggleNavs(e, 3)}
                            href="#pablo"
                            role="tab"
                        >
                            <span id='bulk_create_menu_hardware'>Hardware</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            aria-selected={tabs === 4}
                            style = {{marginLeft:'10px'}}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: tabs === 4
                            })}
                            onClick={e => toggleNavs(e, 4)}
                            href="#pablo"
                            role="tab"
                        >
                            <span id='bulk_create_menu_building'>Building</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            aria-selected={tabs === 5}
                            style = {{marginLeft:'10px'}}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: tabs === 5
                            })}
                            onClick={e => toggleNavs(e, 5)}
                            href="#pablo"
                            role="tab"
                        >
                            <span id='bulk_create_menu_finishes'>Finishes</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            aria-selected={tabs === 6}
                            style = {{marginLeft:'10px'}}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: tabs === 6
                            })}
                            onClick={e => toggleNavs(e, 6)}
                            href="#pablo"
                            role="tab"
                        >
                            <span id='bulk_create_menu_templates'>Templates</span>
                        </NavLink>
                    </NavItem>
                </Nav>
            <Card style={{borderRadius: 0, height:'calc(100% - 140px)', overflowY:'scroll', marginTop:'10px'}}>
                {/* {console.log("re-rendering")} */}
                <TabContent activeTab={"tabs" + tabs}>
                    <TabPane tabId={'tabs1'} style={{fontSize:'12px'}}>  
						<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px',position:'sticky',top:'0',backgroundColor:'white'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string} onChange={(e) =>{set_search_string(e.target.value)}} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Category Name' /></div>
                        <div id='design-paginator' style={{height:'calc(100% - 40px)'}}><SkuDataTable sku_division_id='furniture' /></div>
                    </TabPane>                          
                </TabContent>
                <TabContent activeTab={"tabs" + tabs}>
                    <TabPane tabId={'tabs2'} style={{fontSize:'12px'}}>  
						<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px',position:'sticky',top:'0',backgroundColor:'white'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string} onChange={(e) =>{set_search_string(e.target.value)}} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Category Name' /></div>
                        <div id='design-paginator' style={{height:'calc(100% - 40px)'}}><SkuDataTable sku_division_id='accessory' /></div>
                    </TabPane>                          
                </TabContent>
                <TabContent activeTab={"tabs" + tabs}>
                    <TabPane tabId={'tabs3'} style={{fontSize:'12px'}}>  
						<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px',position:'sticky',top:'0',backgroundColor:'white'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string} onChange={(e) =>{set_search_string(e.target.value)}} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Category Name' /></div>
                        <div id='design-paginator' style={{height:'calc(100% - 40px)'}}><SkuDataTable sku_division_id='hardware' /></div>
                    </TabPane>                          
                </TabContent>
                <TabContent activeTab={"tabs" + tabs}>
                    <TabPane tabId={'tabs4'} style={{fontSize:'12px'}}>  
						<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px',position:'sticky',top:'0',backgroundColor:'white'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string} onChange={(e) =>{set_search_string(e.target.value)}} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Category Name' /></div>
                        <div id='design-paginator' style={{height:'calc(100% - 40px)'}}><SkuDataTable sku_division_id='building' /></div>
                    </TabPane>                          
                </TabContent>
                <TabContent activeTab={"tabs" + tabs}>
                    <TabPane tabId={'tabs5'} style={{fontSize:'12px'}}>  
						<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px',position:'sticky',top:'0',backgroundColor:'white'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string} onChange={(e) =>{set_search_string(e.target.value)}} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Category Name' /></div>
                        <div id='design-paginator' style={{height:'calc(100% - 40px)'}}><SkuDataTable sku_division_id='finish' /></div>
                    </TabPane>                          
                </TabContent>
                <TabContent activeTab={"tabs" + tabs}>
                    <TabPane tabId={'tabs6'} style={{fontSize:'12px'}}>  
						<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px',position:'sticky',top:'0',backgroundColor:'white'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string} onChange={(e) =>{set_search_string(e.target.value)}} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Category Name' /></div>
                        <div id='design-paginator' style={{height:'calc(100% - 40px)'}}><SkuDataTable sku_division_id='template' /></div>
                    </TabPane>                          
                </TabContent>
            </Card>

        </Main_Grid>
    )
}

export default BulkCreateMenu;