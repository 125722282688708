import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Multiselect from "../../components/Multiselect/";
import { primary_menu_options } from '../../components/Sidebar/menu_options';
import { useAlert } from 'react-alert'
import ImportWarning from './ImportWarning';
import * as Sentry from "@sentry/react";
import { FetchError, handleError } from '../../utils/error';

const RoomTypeDialogueBox = ({open, fetch_roomTypes_details, handleClose, mode, room_types, active_room_type, sku_categories, default_room_types, toggleNavs, user}) => {
    const [room_type, set_room_type]  = useState((null));
    const [selected_cat_array, set_selected_cat_array] = useState([]);
    const [imported_roomtype, set_imported_roomtype] = useState(null);
    const [open_import_warning, set_open_import_warning] = useState(false);
    const [global_prop, set_global_prop] = useState(false);

    const alert = useAlert();

    

    useEffect(() => {
        console.log("sku cat", sku_categories)
    },[sku_categories])

    useEffect(() => {
        if(active_room_type && sku_categories)
        {
            console.log("active_room", active_room_type)
            set_room_type(active_room_type.room_type)
            let selected_cat_array_temp = []
            active_room_type.category_maps && active_room_type.category_maps.length && active_room_type.category_maps.map(x => {
                console.log("selected valsf", x)
                selected_cat_array_temp.push(sku_categories.find(o=>o.id == x.sku_category_id))
            })
            console.log("Selected cat array --->> ", selected_cat_array_temp)
            set_selected_cat_array(selected_cat_array_temp.filter(x => x))
        }
    },[active_room_type, sku_categories])

    useEffect(() => {
        console.log("room_type_changed", room_type)
    },[room_type])

    useEffect(() => {
        console.log("default", imported_roomtype, "ff", default_room_types)
    },[imported_roomtype, default_room_types])
    
    useEffect(() => {
        console.log("impt", open_import_warning)
    },[open_import_warning])

    useEffect(() => {
        console.log("selected vals", selected_cat_array)
    },[selected_cat_array])

    const add_roomType = async() => {
        if(room_type){
            try{
                var sku_category_ids = []
                sku_category_ids = selected_cat_array && selected_cat_array.length ? selected_cat_array.map(x => x.id):[]
                if((!sku_category_ids || sku_category_ids.length==0) && imported_roomtype) {console.log("impp");set_open_import_warning(true);return}
                console.log("imp", imported_roomtype, "ii", sku_category_ids.length, "oo", open_import_warning)
                console.log("imps", imported_roomtype, "ii", sku_category_ids)
                let temp = default_room_types && default_room_types.find(o => o.room_type==imported_roomtype)
                let t = temp && temp.category_maps && temp.category_maps.length ? temp.category_maps.map(x => {console.log("cat id",x.sku_category_id);if(sku_categories.find(o=>o.sku_category_id == x.sku_category_id))sku_category_ids.push(x.sku_category_id);return 1}):''
                console.log("cat ids",sku_category_ids, imported_roomtype)
                sku_category_ids = [...new Set(sku_category_ids)]
                if(sku_category_ids && sku_category_ids.length){
                    var body 
                    if(global_prop){ 
                        body = {name: room_type, sku_category_id: sku_category_ids, global:1}
                    }else{
                        body = {name: room_type, sku_category_id: sku_category_ids}
                    }
                    var response = await general_fetch({url: 'room_type/add', body})
                    alert.success("Room Type added successfully")
                    fetch_roomTypes_details();
                    onclick_handleClose();
                }else{
                    alert.error("Select or import categories")
                }
                
            }catch(err) {
                alert.error("Room Type addition failed!")
				console.log(err);
            }
        }else {
            alert.error("Room type required")
        }
    }

    const edit_roomType = async() => {
        if(active_room_type){
            try{
                var sku_category_ids = selected_cat_array && selected_cat_array.length ? selected_cat_array.map(x => x.id):[]
                var body = {id: active_room_type.id ,name: active_room_type.room_type, sku_category_id: sku_category_ids}
                console.log("body", body,"bbb", room_type)
                if(sku_category_ids && sku_category_ids.length){
                    var response = await general_fetch({url: 'room_type/update', body})
                    alert.success("Room Type updated successfully")
                    fetch_roomTypes_details();
                    onclick_handleClose();
                }else{
                    alert.error("Select categories")
                }
            }catch(err) {
                handleError(err)
                if(!(err instanceof FetchError && err.response.action === "TOAST_ERROR")){
                    alert.error("Room Type updation failed!")
                }
            }
        }else {
            alert.error("Room type required")
        }
    }

    const onclick_handleClose = () => {
        set_room_type('');
        set_selected_cat_array([]);
        set_imported_roomtype(null);
        set_global_prop(null);
        handleClose();
    }

    const close_warning =  () => {
        set_open_import_warning(false)
    }

    

    const onclick_item = (value) => {
        set_selected_cat_array(value);
        console.log("selected_cat_array",selected_cat_array)
    }
    
    return (
        <div>
            <Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={onclick_handleClose}>
                <ImportWarning open={open_import_warning} onclick_handleClose = {close_warning}/>
                <Card style={{height:'350px'}}>
                    <CardHeader className='global_modal_header'>
                        <span>
                            <span>{ mode=='add'
                                    ? "Enter Room Type" 
                                    : "Update Room Type"}
                            </span>
                        </span>
                        <i onClick={onclick_handleClose} style={{cursor: 'pointer'}} className='fa fa-times'/>
                    </CardHeader>

                    <CardBody className='global_modal_body' style={{ overflow: 'visible' }}> 
                        <Row style={{marginBottom:'15px'}}>
                        {
                                <Col>
                                    <div>
                                        <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Room Type*</div>
                                        {console.log("room_type", room_type)}
                                        <Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', opacity: mode==='add'?1:.4, borderRadius: '3px'}} placeholder="Room Type" type="text" value={room_type} onChange={e => set_room_type(e.target.value)} disabled = {mode=='add'?false:true} />
                                    </div>
                                </Col>
                        }
                        </Row>
                        {/* <Row style={{marginBottom:'15px'}}>
                        {
                            mode == 'add'?
                            <Col>
                                <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Import Categories from existing room types</div>
                                <Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Select a Room Type" type="select" value={imported_roomtype?imported_roomtype:''} onChange = {(e)=>set_imported_roomtype(e.target.value)}>
                                    <option value='none'>Select a Room Type</option>
                                {
                                    default_room_types && default_room_types.length? default_room_types.map(x => 
                                        <option value={x.room_type?x.room_type:''} >{x.room_type}</option>
                                    ):''
                                }
                                </Input>
                            </Col>
                            :''
                        }
                        </Row> */}
                        {/* <Row style={{marginBottom:'15px'}}>
                        {
                            <Col>
                                <div>
                                    <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select Categories to add</div>
                                    <Multiselect 
                                        style={{backgroundColor:'white'}}
                                        options = {sku_categories && sku_categories.length? JSON.parse(JSON.stringify(sku_categories)):[]}
                                        selectedValues = {selected_cat_array}
                                        onSelect={(selectedList) => onclick_item(selectedList)}
                                        onRemove={(selectedList) => onclick_item(selectedList)}
                                        displayValue = {'sku_category_name'}
                                        placeholder = {'Select Categories'}
                                    />
                                </div>
                            </Col>
                        }
                        </Row> */}
                        <Row style={{marginBottom:'15px'}}>
                        {
                            mode == 'add'?
                            <Col>
                                <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Import Categories from existing room types</div>
                                <Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Select a Room Type" type="select" value={imported_roomtype?imported_roomtype:''} onChange = {(e)=>set_imported_roomtype(e.target.value)}>
                                    <option value='none'>Select a Room Type</option>
                                {
                                    default_room_types && default_room_types.length? default_room_types.map(x => 
                                        <option value={x.room_type?x.room_type:''} >{x.room_type}</option>
                                    ):''
                                }
                                </Input>
                            </Col>
                            :''
                        }
                        </Row>
                        <Row style={{marginBottom:'15px'}}>
                            {/* {console.log("selected vals", selected_cat_array)} */}
                        {
                            <Col>
                                <div>
                                    <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select Categories to add</div>
                                    <Multiselect 
                                        style={{backgroundColor:'white'}}
                                        options = {sku_categories && sku_categories.length? JSON.parse(JSON.stringify(sku_categories)):[]}
                                        selectedValues = {selected_cat_array && selected_cat_array.length ? selected_cat_array:''}
                                        onSelect={(selectedList) => onclick_item(selectedList)}
                                        onRemove={(selectedList) => onclick_item(selectedList)}
                                        displayValue = {'name'}
                                        placeholder = {'Select Categories'}
                                    />
                                </div>
                            </Col>	
                        }
                        </Row>
                    </CardBody>

                    <CardFooter className='global_modal_footer'>
                        {
                            global.config.global_roomtypes_allowed_users.includes(String(user.id))?
                            <>
                                <Input 
                                    checked={global_prop} 
                                    onClick ={() => set_global_prop(!global_prop)}
                                    style={{position:'unset', margin:'0px 5px 0px 0px'}} 
                                    type='checkbox'
                                >
                                </Input>
                                <span style={{position:'unset', margin:'0px 255px 1px 0px'}} >Global</span>
                            </>
                            :''
                        }

                        <Button className='white_button' type="button" onClick={onclick_handleClose}> Cancel </Button>
                        {
                            mode == 'add'
                            ?
                            <Button className='blue_button' type="button" onClick={(e) => {add_roomType();toggleNavs(e,1)}}>Add</Button>
                            :
                            <Button className='blue_button' type="button" onClick={() => edit_roomType()}>Update</Button>
                        }
                    </CardFooter>
                </Card>
            </Modal>
        </div>
    )
}

export default RoomTypeDialogueBox;