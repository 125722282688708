import React , { useState, useEffect } from 'react';
import {Card, Col, Progress, Table, Row, ModalHeader, ModalFooter, Button,Modal, ModalBody } from "reactstrap";
import { IModalBody, IModalFooter, IModalHeader } from '../IModal';
import { Checkbox } from 'antd';


export const RulesFilter = ({is_filter_open, handle_close, onChangeCallback}) => {
    const [filter_options, set_filter_options] = useState({active: true, inactive: true})
    const [prev_filters, set_prev_filters] = useState({active: true, inactive: true})

    useEffect(() => {
        if(is_filter_open){
            set_prev_filters(JSON.parse(JSON.stringify(filter_options)))
        } else set_prev_filters({})
    }, [is_filter_open])

    const close_without_save = () => {
        set_filter_options(JSON.parse(JSON.stringify(prev_filters)));
        handle_close()
    }

    return (
        <Modal isOpen={is_filter_open} size='sm' toggle={close_without_save} className='modal-dialog-centered'>
            <IModalHeader toggle={close_without_save}>
                Filter
            </IModalHeader>
            <IModalBody>
                <div style={{display: 'flex', gap: '8px', alignItems: 'center', cursor: 'pointer', marginBottom: '8px'}} onClick={() => {filter_options.active = filter_options.active ? false : true; set_filter_options(JSON.parse(JSON.stringify(filter_options)))}} >
                    <Checkbox style={{cursor: 'pointer'}} checked = {filter_options.active ? true : false} />
                    <span>Only Active</span>
                </div>

                <div style={{display: 'flex', gap: '8px', alignItems: 'center', cursor: 'pointer'}} onClick={() => {filter_options.inactive = filter_options.inactive ? false : true; set_filter_options(JSON.parse(JSON.stringify(filter_options)))}} >
                    <Checkbox style={{cursor: 'pointer'}} checked = {filter_options.inactive ? true : false} />
                    <span> Only Inactive</span>
                </div>
            </IModalBody>
            <IModalFooter>
                <Button className='white_button' onClick={() => {let init_options = {active: true, inactive: true}; set_filter_options(init_options); onChangeCallback(init_options); handle_close();}}>
                    Reset Filter
                </Button>

                <Button className='blue_button' onClick={() => {onChangeCallback(filter_options); handle_close();}}>
                    Apply Filter
                </Button>
            </IModalFooter>

        </Modal>
    )
}

const Filter = ({is_filter_open, set_is_filter_open, set_sort_value}) => {
    const [sort, set_sort] = useState(0);

    return (
        <Modal isOpen={is_filter_open} size='sm' toggle={() => set_is_filter_open(!is_filter_open)} className='modal-dialog-centered'>
            <ModalHeader toggle={() => set_is_filter_open(!is_filter_open)}>
                Filter
            </ModalHeader>
            <ModalBody>
                <div onClick={() => set_sort(1)} >
                    <input type="radio" checked = {sort == 1 ? true : false} />
                    <span style={{padding : '6px 12px', marginLeft:'16px', cursor: 'pointer'}}>Only Active</span>
                </div>

                <div onClick={() => set_sort(2)} >
                    <input type="radio" checked = {sort == 2 ? true : false} />
                    <span style={{padding : '6px 12px', marginLeft:'16px', cursor:'pointer'}}>Only Inactive</span>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className='white_button' onClick={() => {set_sort_value(0); set_sort(0); set_is_filter_open(false)}}>
                    Reset Filter
                </Button>

                <Button className='blue_button' onClick={() => {set_sort_value(sort); set_is_filter_open(false)}}>
                    Apply Filter
                </Button>
            </ModalFooter>

        </Modal>
    )
}

export default Filter;