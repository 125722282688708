import React , { useState , useEffect, useMemo, useContext }from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import general_fetch from '../../utils/fetch';
import build_path from '../../utils/path';
import { useAlert } from 'react-alert'
import KeyValuePair from '../../components/KeyValuePair';
import is_valid_add_key from '../../utils/is_valid_add_key';
import * as Sentry from "@sentry/react";
import {Modal, Input, CardImg, Button, Card, CardBody, CardHeader, CardFooter, Col, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Table, Row, Alert, UncontrolledAlert} from "reactstrap";
import SingleSelect from '../../components/SingleSelect';
import { IModalBody, IModalFooter, IModalHeader } from '../../components/IModal';
import { ITD, ITable, ITableBody, ITableRow } from '../../components/ITable';
import Select from 'react-select';
import { fileCommands } from './commands';
import { UserContext } from '../../components/Contexts/UserContext';
import { handleError } from '../../utils/error';
import TriggerTooltip from '../../components/TriggerTooltip';
import { Tooltip } from 'antd';


const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
};

const all_allowed_key = {
    custom_sku_code:{
        id:'custom_sku_code',
        name:'Custom Sku Code',
        type:'text'
    },
	order_id:{
        id:'order_id',
        name:'Order ID',
        type:'text'
    }
}

const Filler = ({ initialized }) => (
	<Table className="align-items-center table-flush text-center" responsive>
		<thead className="thead-light text-center">
			<tr>
				{ initialized ? (
					<th scope="col">
						<span style={styles.table_header}><b>No Designs to list  </b></span><br/> Create New Designs from Design portal<br /> &nbsp;
					</th>
				):(
					<th scope="col">
						<Row>
							<Col xs={12} className="text-center">
								<img src="/load2.gif" />
							</Col>
							<Col xs={12} className="text-center">
								<span style={styles.table_header}><b>Fetching your designs  </b></span><br/> <br /> Please wait...<br /> <br />
							</Col>
						</Row>
					</th>
				)}
			</tr>
		</thead>
	</Table>	
);

const Design_Lock_Warning = ({open, handleClose, download_design_outputs, selected_commands, chosen_file, close_download_output_modal}) => {
	return (
        <div>
            <Modal style ={{marginTop:'19%', height:'200px', width:'400px'}} size="sm" isOpen={open}>
                <Card>
                    <div style = {{backgroundColor:'#F5F6F8', overFlow:'auto', padding:'5%'}}> 
                        <div style = {{paddingDown:'10%', textAlign:'center'}}><div style={{fontWeight:'900'}}>The Design is not locked yet.</div>Are you sure you want to proceed?</div>
                        <div style={{textAlign:'center', paddingTop:'5%'}}>
                            <Button className='white_button' type="button" onClick={() => handleClose()}>Cancel</Button>
                            <Button className='blue_button' type="button" onClick={() => {download_design_outputs(selected_commands.map(item => item.value));close_download_output_modal();handleClose()}}>OK</Button>
                        </div>
                    </div>
                </Card>
				{/* <UncontrolledAlert style={{position:'fixed', bottom:0, right:0}} color="info">
					I am an alert and I can be dismissed without animating!
				</UncontrolledAlert> */}
            </Modal>
        </div>
    )
}

const Design_Changed_Warning = ({open, handleClose, instant_file, close_download_output_modal}) => {
	return (
        <div>
            <Modal style ={{marginTop:'19%', height:'200px', width:'400px'}} size="sm" isOpen={open}>
                <Card>
                    <div style = {{backgroundColor:'#F5F6F8', overFlow:'auto', padding:'5%'}}> 
                        <div style = {{paddingDown:'10%', textAlign:'center'}}><div style={{fontWeight:'900'}}>The Design has changed since the last output generation.</div>Are you sure you want to proceed?</div>
                        <div style={{textAlign:'center', paddingTop:'5%'}}>
                            <Button className='white_button' type="button" onClick={() => handleClose()}>Cancel</Button>
                            <Button className='blue_button' type="button" onClick={() => {window.open(global.config.server_path+instant_file.output_file_path, "_blank");close_download_output_modal();handleClose()}}>OK</Button>
                        </div>
                    </div>
                </Card>
				{/* <UncontrolledAlert style={{position:'fixed', bottom:0, right:0}} color="info">
					I am an alert and I can be dismissed without animating!
				</UncontrolledAlert> */}
            </Modal>
        </div>
    )
}

const Download_Outputs_Modal = ({open, handleClose, design, design_branch, store_details, get_store_details, eligible_commands, download_design_outputs, instant_all_outputs_download, set_instant_all_outputs_download, instant_pricing_quotation_download, set_instant_pricing_quotation_download, instant_custom_outputs_download, set_instant_custom_outputs_download}) => {

	const [chosen_file, set_chosen_file] = useState({name:'All Output files',id:'all_outputs'});
	const [show_design_lock_warning, set_show_design_lock_warning] = useState(false);
	const [instant_file, set_instant_file] = useState(instant_all_outputs_download)
	const [show_design_changed_warning, set_show_design_changed_warning] = useState();
	const [selected_commands, set_selected_commands] = useState([]);
	const [user_addons, set_user_addons] = useState([])
	const [valid_commands, set_valid_commands] = useState([])

	const options1 = [{name:'All Output files',id:'all_outputs'},{name:'Only Org Custom Outputs', id:'only_custom_outputs'},{name:'Pricing quotation', id:'pricing_quotation'}]
	const options2 = [{name:'All Output files',id:'all_outputs'},{name:'Pricing quotation', id:'pricing_quotation'}]
	// const [instant_file_available, set_instant_file_available] = useState(false)
	const command_options = [{label: 'Pricing Quotation JSON', value: 'GetPricingQuotationDetailsJSON'}, {label: 'Pricing Quotation PDF', value: 'GetPricingQuotationPdf'}]

	const alert = useAlert()

	useEffect(() => {
		if(store_details && store_details.add_ons && store_details.add_ons.active){
			set_user_addons(store_details.add_ons.active.map(item => item.id))
		}
	}, [store_details])

	useEffect(() => {
		// console.log("KPS", fileCommands,  fileCommands.filter(item => item.addons && item.addons.find(item => user_addons.includes(item))).map(item => {return {value: item.value, label: item.label}}))
		set_valid_commands(fileCommands.filter(item => (item.addons && item.addons.find(item1 => user_addons.includes(item1))) || (item.store_id && item.store_id.includes(store_details.id))).sort((a, b) => {
			return a.label.localeCompare(b.label);
		}))
	}, [user_addons])

	const handle_submit = () => {
		if(selected_commands && selected_commands.length == 0){
			alert.error('Please select atleast one command')
			return;
		}
		if(design && design.locked){
			download_design_outputs(selected_commands.map(item => item.value));
			set_instant_all_outputs_download('')
			set_instant_custom_outputs_download('')
			set_instant_pricing_quotation_download('')
			handleClose()
		}else{
			set_show_design_lock_warning(true)
		}
		
	}

	const instant_file_available_check = () => {
		if((chosen_file.id === 'all_outputs' && instant_all_outputs_download) || (chosen_file.id === 'only_custom_outputs' && instant_custom_outputs_download) || (chosen_file.id === 'pricing_quotation' && instant_pricing_quotation_download))
			return true
		return false
	}

	const download_link = () => {
		if(instant_file && design){
			if(new Date(design_branch.updated_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'}) > new Date(instant_file.updated_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'})){
				set_show_design_changed_warning(true)
			}else{
				window.open(global.config.server_path+instant_file.output_file_path, "_blank");
			}
		}
	}

	useEffect(() => {
		console.log("design branch", design, design_branch)
	}, [design, design_branch]);

	useEffect(() => {
		if(chosen_file.id === 'all_outputs' && instant_all_outputs_download){
			set_instant_file(instant_all_outputs_download)
		}else if(chosen_file.id === 'only_custom_outputs' && instant_custom_outputs_download){
			set_instant_file(instant_custom_outputs_download)
		}else if(chosen_file.id === 'pricing_quotation' && instant_pricing_quotation_download){
			set_instant_file(instant_pricing_quotation_download)
		}
	}, [chosen_file, instant_all_outputs_download, instant_custom_outputs_download, instant_pricing_quotation_download]);

	// useEffect(() => {
	// 	if(chosen_file.id === 'all_outputs' && instant_all_outputs_download){
	// 		set_instant_file_available(true)
	// 		console.log("instant_file_availablessssss")
	// 	}else if(chosen_file.id === 'only_custom_outputs' && instant_custom_outputs_download){
	// 		set_instant_file_available(true)
	// 	}else if(chosen_file.id === 'pricing_quotation' && instant_pricing_quotation_download){
	// 		set_instant_file_available(true)
	// 	}else{
	// 		set_instant_file_available(false)
	// 	}
	// }, [chosen_file, instant_all_outputs_download, instant_custom_outputs_download, instant_pricing_quotation_download]);

	// useEffect(() => {
	// 	console.log("instant_file_available", instant_all_outputs_download, "ll",instant_custom_outputs_download, instant_pricing_quotation_download)
	// }, [instant_file_available]);

	return (
		<Modal isOpen={open} toggle={handleClose} style={{marginTop:instant_file_available_check()?'180px':'230px', height:instant_file_available_check() ? '700px' : '500px', overflow: 'visible'}}>
			<Design_Lock_Warning open={show_design_lock_warning} handleClose={() => set_show_design_lock_warning(false)} download_design_outputs = {download_design_outputs} selected_commands={selected_commands} chosen_file = {chosen_file.id} close_download_output_modal={handleClose}/>
			<Design_Changed_Warning open={show_design_changed_warning} handleClose={() => set_show_design_changed_warning(false)} instant_file={instant_file} close_download_output_modal={handleClose}/>
			<Card>
				<CardHeader className='global_modal_header'>
						<span>Download File</span>
						<i style={{cursor:'pointer'}} onClick={() => {handleClose()}} className='fa fa-times' />
				</CardHeader>
				<CardBody style={{backgroundColor:'#F5F6F8', overflow: 'visible'}}>
					<div style={{margin:'10px', backgroundColor:'white', padding:'15px'}}>
						<div style={{fontSize:'15px', display:'flex', textAlign:'left', marginBottom:'5px'}}>
							<span style={{marginRight:'20px', flex:'1'}}>File Name:</span>
							<span style={{fontWeight:'900', flex:'2'}}>{design.name+ ' - ' + design_branch.name}</span>
						</div>
						<div style={{fontSize:'15px',display:'flex', textAlign:'left', marginBottom:'5px'}}>
							<span style={{marginRight:'20px', flex:'1'}}>Status:</span>
							<span style={{flex:'2'}}><i style={{marginRight:'5px', color:'grey'}} className={design.locked ? 'fa fa-lock' :' fa fa-unlock'} />{design.locked ? 'Locked' : 'Unlocked'}</span>
						</div>
						<div style={{fontSize:'15px',display:'flex', textAlign:'left', marginBottom:'7px'}}>
							<span style={{marginRight:'20px', flex:'1'}}>Lock time:</span>
							<span style={{flex:'2'}}>{design.locked && design.locking_date ? new Date(design.locking_date).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'}) : '--'}</span>
						</div>
						<div style={{fontSize:'15px', textAlign:'left', marginBottom:'5px'}}>
							<span>Choose File:</span>
						</div>
						{/* <Input type='select' style={{fontSize:'15px', textAlign:'left', width:'100%'}} value={chosen_file} onChange={(e) => set_chosen_file(e.target.value)}>
							<option value='all_outputs'>All Output files</option>
							{eligible_commands && eligible_commands.custom && eligible_commands.custom.length ? <option value='only_custom_outputs'>Only Org Custom Outputs</option> : ''}
							<option value= 'pricing_quotation'>Pricing quotation</option>
						</Input> */}
						{/* <SingleSelect
							options={eligible_commands && eligible_commands.custom && eligible_commands.custom.length ? options1 : options2}
							selectedValue = {chosen_file}
							placeholder = {'Select file type'}
							onItemClick = {set_chosen_file}
							displayValue = {'name'}
						/> */}

						<Select
							isMulti
							name="commands"
							options={valid_commands}
							onChange={(selectedList) => {set_selected_commands(selectedList)}}
							getOptionLabel={(option) => option.label}
							placeholder='Select files to download'
							className="basic-multi-select"
							classNamePrefix="select"
							closeMenuOnSelect={false}
						/>
					</div>
					
					{
						instant_file_available_check() ? 
						<>
							<div className='flex_property' style={{justifyContent:'space-between', padding:'10px'}}>
								<div>
									<div style={{fontSize:'15px'}}>Recently downloaded file</div>
									<div style={{color:'#A7A8B3', fontSize:'13px'}}>{new Date(instant_file.updated_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'})}</div>
								</div>
								<div onClick={() => {download_link()}} className='flex_property' style={{height:'32px', width:'164px', backgroundColor:'#FFFFFF', border:'1px solid #C5C7CF', borderRadius:'2px', justifyContent:'center',cursor:'pointer'}}>
									<i style={{marginRight:'5px'}} className="fa fa-download" aria-hidden="true"/><span style={{fontSize:'14px'}}>Instant download</span>
								</div>
							</div>
							<hr style={{margin:'10px 10px 10px 10px'}}/>
							<div className='flex_property' style={{justifyContent:'space-between', padding:'10px 10px 10px 10px', marginBottom:'10px'}}>
								<div>
									<div style={{fontSize:'15px'}}>Download new file</div>
									<div style={{color:'#A7A8B3', fontSize:'13px'}}>Might take few minutes</div>
								</div>
								<div onClick={() => {handle_submit()}} className='flex_property' style={{height:'32px', width:'164px', backgroundColor:'#FFFFFF', border:'1px solid #C5C7CF', borderRadius:'2px', justifyContent:'center', cursor:'pointer'}}>
									<i style={{marginRight:'5px'}} className="fa fa-download" aria-hidden="true"/><span style={{fontSize:'14px'}}>New download</span>
								</div>
							</div>
						</>
						:
						<>
							<span style={{marginTop:'20px', marginLeft:'12px', fontSize:'14px'}}>Download might take <span style={{fontWeight:'900'}}>3 to 15 minutes</span> depending on the file size.</span>
						</>
					}
						<div style={{marginTop:'10px', marginLeft:'12px', fontSize:'14px', display:'flex', alignItems:'center', color:'grey'}}><span >(You will be notified via email when the download is complete)</span></div>
					
				</CardBody>
				<CardFooter className='global_modal_footer'>
					<Button onClick={() => {handleClose()}} className='white_button' style={{marginRight:instant_file_available_check()?'0px':'12px'}}>Cancel</Button>
					{!instant_file_available_check() ? <Button disabled={chosen_file?false:true} className='blue_button' onClick={() => {handle_submit()}}>Download</Button> :''}
					</CardFooter>
			</Card>
		</Modal>
	)
}

const AdditionalPropertiesModal = ({open, handleClose, design, get_designs, additional_json_item_default, additional_json_item_custom}) => {
	const [editing_default_props, set_editing_default_props] = useState(false)
	const [editing_custom_props, set_editing_custom_props] = useState(false)
	const [new_additional_json_item_default, set_new_additional_json_item_default] = useState([]);
	const [new_additional_json_item_custom, set_new_additional_json_item_custom] = useState([]);
	const alert = useAlert()

	const onClick_handleClose = () => {
		set_editing_default_props(false)
		set_editing_custom_props(false)
		set_new_additional_json_item_custom([])
		set_new_additional_json_item_default([])
		handleClose()
	}

	useEffect(() => {
		set_new_additional_json_item_default(additional_json_item_default)
	}, [additional_json_item_default]);

	useEffect(() => {
		set_new_additional_json_item_custom(additional_json_item_custom)
	}, [additional_json_item_custom]);

	const update_additional_props = async() => {
		if(design){
			try{
				if(new_additional_json_item_default && new_additional_json_item_default.length>1 && !is_valid_add_key(new_additional_json_item_default)){
					alert.error("Empty or Duplicate key values not allowed in default properties.")
				}else if(new_additional_json_item_custom && new_additional_json_item_custom.length>1 && !is_valid_add_key(new_additional_json_item_custom)){
					alert.error("Empty or Duplicate key values not allowed in custom properties.")
				}else{
					var x_addi_prop = [];
					new_additional_json_item_default.map(x => {
						if(new_additional_json_item_default[0].key){
							x_addi_prop.push(x)
						}
					})
					new_additional_json_item_custom.map(x => {
						if (new_additional_json_item_custom[0].key) {
							x_addi_prop.push(x)
						}
					}) 
					let resp = await general_fetch({url:'design/update', body:{design_id:design.id, additional_properties:JSON.stringify(x_addi_prop)}})
					if(resp) {
						set_editing_default_props(false)
						set_editing_custom_props(false)
						alert.success('Additional Properties Updation Successfull !')
						get_designs()
					}
				}
			}catch(err){
				console.log(err)
				alert.error("Additional Properties Updation Failed !")
				handleError(err)
			}
		}
	}

	return(
		<Modal className="Margin_64px_top" isOpen={open} size="lg" toggle={onClick_handleClose} >
			<Card className='global_modal_height_for_mdlgxl'>
				<CardHeader className='global_modal_header'>
					<span style={{ fontWeight:'900'}}>{design.name} - Additional Properties</span>
					<i style={{cursor:'pointer'}} onClick={handleClose} className='fa fa-times'/>
				</CardHeader>
				<CardBody className='global_modal_body'>
					<div >
						<div className='flex_property' style={{ fontSize:'14px', fontWeight:600, justifyContent:'space-between', marginRight:'4px'}}>
							<span>Default Properties</span>
							{
								!editing_default_props?
								<span onClick = {() => !editing_custom_props?set_editing_default_props(true):''} style={{color:'#0078FF', cursor:'pointer', opacity:editing_custom_props?'0.5':'1'}}><i className='fa fa-pencil-alt' style={{marginRight:'4px'}} />  {additional_json_item_default && additional_json_item_default.length ==1 && additional_json_item_default[0].key=='' & additional_json_item_default[0].value=='' ?'Add':'Edit'}</span>:''
							}
						</div>
						<div style={{pointerEvents: editing_default_props?'auto':'none'}}>
							<KeyValuePair item_json={new_additional_json_item_default} set_item_json={set_new_additional_json_item_default} mode='default' all_allowed_key={all_allowed_key} disable_input={!editing_default_props} design_level={true}/>
						</div>
						{
							editing_default_props?
							<div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', backgroundColor:'white', paddingBottom:'10px'}}>
								<span onClick={() => {set_new_additional_json_item_default(additional_json_item_default)}} className='flex_property' style={{width:'100px', height:'32px', cursor:'pointer', border:'1px solid #C5C7CF', borderRadius:'4px', justifyContent:'center', fontSize:'13px', marginRight:'10px'}}>Reset Changes</span>
								<span onClick={() => {update_additional_props()}} className='flex_property' style={{width:'100px', height:'32px', cursor:'pointer',  border:'1px solid #C5C7CF', borderRadius:'4px', backgroundColor:'#0078FF',justifyContent:'center', fontSize:'13px', color:'white', marginRight:'10px'}}>Save Changes</span>
							</div>:''
						}
					</div>
					<div>
						<div className='flex_property' style={{ fontSize:'14px', fontWeight:600, justifyContent:'space-between', marginTop:'20px', marginRight:'4px'}}>
							<span>Custom Properties</span>
							{
								!editing_custom_props?
								<span onClick = {() => !editing_default_props?set_editing_custom_props(true):''} style={{color:'#0078FF', cursor:'pointer', opacity:editing_default_props?'0.5':'1'}}><i className='fa fa-pencil-alt' style={{marginRight:'4px'}} /> {additional_json_item_custom && additional_json_item_custom.length ==1 && additional_json_item_custom[0].key=='' & additional_json_item_custom[0].value=='' ?'Add':'Edit'}</span>:''
							}
						</div>
						<div style={{pointerEvents: editing_custom_props?'auto':'none'}}>
							<KeyValuePair item_json={new_additional_json_item_custom} set_item_json={set_new_additional_json_item_custom} mode='custom' all_allowed_key={all_allowed_key} disable_input={!editing_custom_props} design_level={true}/>
						</div>
						{
							editing_custom_props?
							<div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', backgroundColor:'white', paddingBottom:'10px'}}>
								<span onClick={() => {set_new_additional_json_item_custom(additional_json_item_custom)}} className='flex_property' style={{width:'100px', height:'32px', cursor:'pointer', border:'1px solid #C5C7CF', borderRadius:'4px', justifyContent:'center', fontSize:'13px', marginRight:'10px'}}>Reset Changes</span>
								<span onClick={() => {update_additional_props()}} className='flex_property' style={{width:'100px', height:'32px', cursor:'pointer',  border:'1px solid #C5C7CF', borderRadius:'4px', backgroundColor:'#0078FF',justifyContent:'center', fontSize:'13px', color:'white', marginRight:'10px'}}>Save Changes</span>
							</div>:''
						}
					</div>
				</CardBody>
				<CardFooter className='global_modal_footer'>
					<Button className='white_button' style={{marginRight:'5px'}} onClick={onClick_handleClose}>Close</Button>
				</CardFooter>
			</Card>
		</Modal>
	)
}


const Share_invite_modal = ({ open, users, handle_close, invite_users_onclick }) => {
	const [selected_users, set_selected_users] = useState([])
	const [role, set_role] = useState("write")
	const alert = useAlert();

	useEffect(() => {
		if (open) {
			set_selected_users([]);
		}
	}, [open])

	const submit_values = () => {
		var user_data = []
		for (var i = 0; i < selected_users.length; i++) {
			user_data.push({
				user_id: selected_users[i].value,
				role: role,
				level: "design_branch"
				// is_selected: true,
				// label: selected_users[i].label
			})
		}

		invite_users_onclick(user_data);
	}

	const handleOnChange = (selectedOptions) => {
		set_selected_users(selectedOptions);
	};

	return (
		<Modal className="modal-dialog-centered" isOpen={open} toggle={handle_close}>
			<IModalHeader toggle={handle_close}>Invite Designer</IModalHeader>
			<IModalBody>
				<div className='flex_property mb-3'>
					<div className='mr-3'>Email</div>
					{/* <Typeahead
						multiple
						id="share_branch_typeahead"
						onChange={(selected) => {
							set_selected_users(selected);
						}}
						options={users}
						selected={selected_users}
					// style={{...styles_modals.input_styles}}
					/> */}
					<Select
						isMulti
						id="share_branch_select"
						onChange={handleOnChange}
						options={users}
						value={selected_users}
					/>
				</div>
				<div className='flex_property justify-content-between'>
					<div className='flex_property'>
						<div className='mr-3'>Access</div>
						<Input style={{ fontSize: '12px', padding: '4px', width: 'fit-content' }} value={role} onChange={(e) => { set_role(e.target.value) }} type="select">
							<option value={"read"}>View</option>
							<option value={"write"}>Edit</option>
						</Input>
					</div>
					<Button onClick={submit_values} className="blue_button" type="button">Invite</Button>
				</div>
			</IModalBody>
		</Modal>
	)
}


const UserAccessModal = ({ open, handleClose, design_branch, all_user_details, design }) => {
	const [access_info, set_access_info] = useState([]);
	const [owner, set_owner] = useState("");
	const [search_string, set_search_string] = useState("");
	const [users_with_access_map, set_users_with_access_map] = useState({});
	const [is_invite_modal_open, set_is_invite_modal_open] = useState(false);
	const open_invite_modal = () => set_is_invite_modal_open(true)
	const close_invite_modal = () => set_is_invite_modal_open(false)
	
	// //TODO Move the below function to api folder in utils
	
	const update_design_brach_user_access = async(design_branch_id, user_access) => {
		let body = {}
		body["ids"] = [design_branch_id]
		body["level"] = "design_branch"
		body["info"] = user_access

		let response = await general_fetch({url: 'design_branch/update_sharing', body: {share_info: [body]}})

		return response
	}

	const submit = (access_info) => {
		let user_access;
		let write = []
		let read = []
		access_info.map(x => {
			if(x.role === 'write'){
				write.push(x.user_id)
			}else if(x.role === 'read'){
				read.push(x.user_id)
			}
		})

		user_access = [{role: "read", user_ids: read}, {role: 'write', user_ids: write}]

		update_design_brach_user_access(design_branch.id, user_access)
	}

	const removeAccess = (index) => {
		if(access_info && access_info.length){
			access_info.splice(index, 1)
			set_access_info_fn(JSON.parse(JSON.stringify(access_info)))
			submit(access_info)
		}
	}

	const inviteUsers = (users) => {
		if(access_info && access_info.length){
			let final_users = access_info.concat(users)
			set_access_info_fn(final_users)
			submit(final_users)
		}else{
			set_access_info_fn(users)
			submit(users)
		}
		close_invite_modal()
	}

	// const fetch_by_design_branch_id = async(design_branch_id) => {
	// 	try{
	// 		let body = {}
	// 		body["design_branch_ids"] = [design_branch_id]
	// 		let resp = await general_fetch({url: 'design/get_by_design_branch'})
	// 		return resp
	// 	}catch(err){
	// 		console.error("Error in fetch by design branch id", err)
	// 	}
	// 	return null
	// }

	// const get_design_branch_data = (id) => {

	// }

	const set_access_info_fn = (access_info) => {
		let temp = {}
		let access_info_clean = []
		access_info.map(x => {
			if (!temp[x.user_id]) {
				temp[x.user_id] = true
				access_info_clean.push(x)
			}
		})
		set_users_with_access_map(temp)
		set_access_info(access_info_clean)
	}

	useEffect(() => {
		if (design_branch && design_branch.access_info && design_branch.access_info.user) {
			let access_info_unclean = (design_branch.access_info.user.filter(x => x.level === "design_branch"))
			set_access_info_fn(access_info_unclean)
			set_owner(design_branch.user_id)
		}
	}, [design_branch]);


	// useEffect(() => {
	// 	console.log("unmapped users", users_with_access_map, all_user_details && all_user_details.length ? all_user_details.filter(x => !users_with_access_map[x.id]).map(x => {return {label: x.first_name, value: x.id}}) : [])
	// }, [all_user_details, users_with_access_map]);
	
	useEffect(() => {
		if(!open){
			set_search_string("")
			set_users_with_access_map({})
		}
	}, [open]);

	return (
		<Modal className='modal-dialog-centered' isOpen={open} toggle={handleClose}>
			<Share_invite_modal open={is_invite_modal_open} handle_close={close_invite_modal} users={all_user_details && Object.keys(all_user_details).length ? Object.keys(all_user_details).filter(x => !users_with_access_map[x]).map(x => {return {label: all_user_details[x].email, value: x}}) : []} invite_users_onclick={inviteUsers}/>
			<IModalHeader toggle={handleClose}>User Access List</IModalHeader>
			<IModalBody style={{ height: '500px', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
				<div className='flex_property justify-content-between mb-2'>
					<div>List of users having access to: <br></br> Design Branch: <b>{design_branch && design_branch.name ? design_branch.name : '-'}</b> of Design:<b>{design && design.name ? design.name : '-'}</b></div>
					<div style={{color: '#0078ff', cursor: 'pointer', fontWeight: 700, whiteSpace: 'nowrap'}} onClick={open_invite_modal}>+Add users</div>
				</div>
				<div style={{ flex: 1, display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
					<div className='flex_property px-2' style={{borderBottom: '1px solid var(--light-border-color)'}}>
						{
							// TODO Search bar
							<div className='flex_property px-2' style={{height:"40px", backgroundColor:'white', flex: 1, borderRight: '1px solid var(--light-border-color)'}}><i style={{fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search mr-2'/><input value={search_string} onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search User' /></div>
						}
						<div className='px-2'>Total {access_info && access_info.length} Users</div>
					</div>
					<ITable hover={true} style_container={{ flex: 1, overFlow: 'auto', minHeight: "40px" }}>
						<colgroup>
							<col style={{ width: "60%" }}></col>
							<col style={{ width: "25%" }}></col>
							<col style={{ width: "15%" }}></col>
						</colgroup>
						<ITableBody>
							{
								access_info && access_info.length ? access_info.map((user, idx) => (
									<ITableRow style={{display: all_user_details[user.user_id] && all_user_details[user.user_id].first_name && all_user_details[user.user_id].first_name.toLowerCase().includes(search_string.toLowerCase()) ? '' : 'none' }}>
										<ITD>
											<div className='px-2'>{all_user_details[user.user_id] ? all_user_details[user.user_id].first_name : '-'}</div>
										</ITD>
										<ITD style={{color: '#A7A8B3'}}>{user.role === 'write' ? "Edit" : user.role === "read" ? "View" : ""}</ITD>
										<ITD style={{color: '#A7A8B3'}}>
											<div className='px-2 flex_property justify-content-end'>
												{
													user.user_id === owner ?
														"Owner"
														:
														<img className='cp' src='/assets/icons/remove.svg' height={"16px"} onClick={() => removeAccess(idx)} />
												}
											</div>
										</ITD>
									</ITableRow>
								))
									: ''
							}
						</ITableBody>
					</ITable>
				</div>
			</IModalBody>
			<IModalFooter>
				<Button className='white_button' onClick={handleClose}>Close</Button>
			</IModalFooter>
		</Modal>
	)
}


const Design_option_dropdown = ({project, design, get_designs, fetch_project_details, store_details, get_store_details, design_branch, is_template_json, update_is_template_json, update_project_branch_level_data,eligible_commands,set_download_started,set_download_ended, set_downloading_project, set_downloading_design, set_downloading_design_branch, all_user_details}) => {
	const history = useHistory();
	const alert = useAlert();
	const [dropdown_open, set_dropdown_open] = useState(false);	
	const [force_refresh_value, set_force_refresh_value] = useState(false);	
	const [initialized, set_initialized] = useState();
	const [show_share_users_modal, set_show_share_users_modal] = useState(false);
	const [show_additional_props_modal, set_show_additional_props_modal] = useState();
	const [show_download_outputs_modal, set_show_download_outputs_modal] = useState();
	const [instant_pricing_quotation_download, set_instant_pricing_quotation_download] = useState('')
	const [instant_all_outputs_download, set_instant_all_outputs_download] = useState('')
	const [instant_custom_outputs_download, set_instant_custom_outputs_download] = useState('')
	const [final_output_file_path,set_final_output_file_path] = useState('')
	const user = useContext(UserContext)

	const get_props = (key) => {
		if(design && design.additional_properties){
			let additional_properties = JSON.parse(JSON.stringify(design.additional_properties))
			let temp_array = additional_properties && additional_properties.length ? additional_properties.filter(x => key==='default' ? all_allowed_key[x.key] : !all_allowed_key[x.key] ) : []
			return temp_array
		}
		return []
	}

	const additional_json_item_default = useMemo(() => get_props('default'), [design])
	const additional_json_item_custom = useMemo(() => get_props('custom'), [design])
	
	useEffect(() => {
		if(design){
			console.log("designnnn", design)
			set_force_refresh_value(design.additional_properties && typeof design.additional_properties != 'string' && design.additional_properties.length && design.additional_properties.filter(x => x.key == 'force_refresh_cache').length ? true : false)
		}
	},[design])

	// useEffect(() => {
	// 	if(design_branch && !initialized){
	// 		get_all_of_design_branch(design_branch.id)
	// 		set_initialized(true)
	// 	}
	// }, [design_branch]);

	const onclick_view_design = (design) => {
		var url = '';
		console.log(design)
		if(global.config && global.config.website_url && design){
			var url = '';
			let check_enterprise_white_labelled_url = (window.location.host.split('.')[1] == 'infurnia') ? false : true;
			if (check_enterprise_white_labelled_url) {
				let [ subdomain, domain, extension ] = window.location.host.split('.');
				url = `https://design.${domain}.${extension}/share/${design.id}`;
			} else {
				url = global.config.website_url+'share/'+ design.id;
			}
			// history.push(url)
			window.open(url,'_blank')
		}else{
			alert.error('Request Failed')
		}
	}

	const onclick_unlock_lock_design = async(event_type, design) => {
		if(design){
			try{
				console.log('Unlock design branch', design)
				var resp = await general_fetch({url:'design/update_lock', body: {design_id:design.id, status: event_type=='lock'?'lock':'unlock'}})
				// var resp = await general_fetch({url:'design/update_lock', body: {design_branch_id:design.id, status: event_type=='lock'?'lock':'unlock'}})
				if(event_type==='lock'){
					alert.success('Design locked successfully')
				}
				if(event_type==='unlock'){
					alert.success('Design unlocked successfully')
				}
				fetch_project_details();
			}catch(err){
				console.log(err)
			}
		}
	}

	const toggle_branch_approval = async(event_type, design_branch_id) => {
		if(design_branch_id){
			try{
				var resp = await general_fetch({ url: 'design_branch/update_approval', body: { design_branch_id, status: event_type } });
				if(event_type==='grant'){
					alert.success('Approval granted and design locked')
				}
				if(event_type==='revoke'){
					alert.success('Approval revoked and design unlocked')
				}
				fetch_project_details();
			}catch(err){
				console.log(err)
			}
		}
	}


	const poll_for_output_completion = async ids => {
		try {
			var body = { ids };
			var resp = await general_fetch({ url: 'production_detail/get_status', body });
			if (resp[0].status == 'completed' || resp[0].status == 'partially_completed') {
				update_project_branch_level_data(design_branch.id, 'in_queue', false)
				update_project_branch_level_data(design_branch.id, 'downloading_outputs', false)
				return resp[0].output_file_path;
			}else if(resp[0].status == 'failed'){
				return -1;
			}else if(resp[0].status == 'initialized'){
				update_project_branch_level_data(design_branch.id, 'in_queue', true)
				update_project_branch_level_data(design_branch.id, 'downloading_outputs', false)
				return 0;
			}else if(resp[0].status == 'ongoing'){
				update_project_branch_level_data(design_branch.id, 'in_queue', false)
				update_project_branch_level_data(design_branch.id, 'downloading_outputs', true)
				return 0;
			} else {
				return 0;
			}
		} catch (err) {
			console.log(err);
		}
	}


	// const download_pricing_output = async() => {
	// 	try {
	// 		if(!design_branch) {
	// 			return;
	// 		}
			
	// 		let design_branch_id = design_branch.id;

	// 		update_project_branch_level_data(design_branch_id, 'downloading_pricing_quotation', true)
	// 		let commands = [ "GetPricingQuotationXlsx"];
	// 		console.log('generating outputs with  --> ', commands, design_branch_id);
	// 		let resp = await general_fetch({ url: 'production_detail/get_output', body: { design_branch_id, commands } });
	// 		console.log('resp --> ', resp);
	// 		let request_id = resp.request_batch_id;
	// 		console.log("request_id --> ", request_id);
	// 		let poll_interval = setInterval(async () => {
	// 			let output_file_path = await poll_for_output_completion([ request_id ]);
	// 			if (output_file_path) {
	// 				clearInterval(poll_interval);
	// 				window.open(global.config.server_path+output_file_path, "_blank");
	// 				update_project_branch_level_data(design_branch.id, 'downloading_pricing_quotation', false)
	// 			}
	// 		}	, 1000);
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// }

	// const download_custom_outputs = async() => {
	// 	try{
	// 		if(!design_branch || !eligible_commands) {
	// 			return;
	// 		}
	// 		let design_branch_id = design_branch.id;

	// 		update_project_branch_level_data(design_branch.id, 'downloading_custom_outputs', true)
	// 		let commands = eligible_commands && eligible_commands.length ? eligible_commands.filter(x => x.command != 'GetPricingQuotationXlsx' && x.command != 'GetPricingQuotationCsv' && x.command != 'GetCabinetCompositeBoq'):[]
	// 		commands = commands.map(x => x.command)
	// 		console.log('generating custom outputs with  --> ', commands, design_branch_id);
	// 		let resp = await general_fetch({ url: 'production_detail/get_output', body: { design_branch_id, commands } });
	// 		let request_id = resp.request_batch_id;
			// let poll_interval = setInterval(async () => {
			// 	let output_file_path = await poll_for_output_completion([ request_id ]);
			// 	if (output_file_path) {
			// 		clearInterval(poll_interval);
			// 		window.open(global.config.server_path+output_file_path, "_blank");
			// 		update_project_branch_level_data(design_branch.id, 'downloading_custom_outputs', false)
			// 	}
			// }	, 1000);
	// 	}catch (err) {
	// 		console.log(err);
	// 	}
	// }

	const download_design_outputs = async(commands) => {
		try{
			if(!design_branch || !commands) {
				return;
			}
			let design_branch_id = design_branch.id;
			// let commands = []
			// update_project_branch_level_data(design_branch.id, 'in_queue', true)
			// if(key === 'all_outputs'){
			// 	commands = [...eligible_commands.standard, ...eligible_commands.custom]
			// 	commands = commands.map(x => x.command)
			// 	console.log("eligible commands all",commands)
			// }else if(key === 'only_custom_outputs'){
			// 	commands = [...eligible_commands.custom]
			// 	commands = commands.map(x => x.command)
			// }else if(key === 'pricing_quotation'){
			// 	commands = ["GetPricingQuotationXlsx"]
			// }
			let resp = await general_fetch({ url: 'production_detail/get_output', body: { design_branch_id, commands, business_unit_id: user.current_business_unit_id } });		
			let request_id = resp.request_batch_id;
			if(request_id){
				console.log("set_download_started")
				set_download_started(true)
				set_download_ended(false)
				set_downloading_project(project.name)
				set_downloading_design(design.name)
				set_downloading_design_branch(design_branch.name)
			}
			get_all_of_design_branch(design_branch_id)
			let poll_interval = setInterval(async () => {
				let output_file_path = await poll_for_output_completion([ request_id ]);
				if(output_file_path == -1){
					alert.error("Download Failed!")
					clearInterval(poll_interval);
					set_download_started(false)
					update_project_branch_level_data(design_branch.id, 'downloading_outputs', false)
				}else if (output_file_path) {
					clearInterval(poll_interval);
					window.open(global.config.server_path+output_file_path, "_blank");
					set_final_output_file_path(output_file_path)
					set_downloading_project(project.name)
					set_downloading_design(design.name)
					set_downloading_design_branch(design_branch.name)
					set_download_started(false)
					set_download_ended(true)
				}
			}	, 10000);
		}catch(err){
			console.log(err)
		}
	}


	const onclick_force_refresh = async(design) => {
		if(design){
			try{
				set_force_refresh_value(true)
				console.log('hasdlkfadslkf', (design.additional_properties && design.additional_properties.length), design.additional_properties)
				var body = { design_id: design.id, additional_properties:JSON.stringify([ ...((design.additional_properties && design.additional_properties.length) ? design.additional_properties : []), { key: 'force_refresh_sku_cache', value: 'true' } ])};
				var resp = await general_fetch({url:'design/update', body})
				alert.success('Design Updated successfully')
				fetch_project_details();
			}catch(err){
				console.log(err)
			}
		}
	} 

	const mark_unmark_as_template = async(value) => {
		if(design_branch){
			try{
				let resp = await general_fetch({url:'design_branch/update_template_status', body:{design_branch_id: design_branch.id, org_template: value}})
				if(value){
					alert.success("Marked as Template")
					update_is_template_json(design_branch.id, true)
				}else{
					alert.success("unmarked as Template")
					update_is_template_json(design_branch.id, false)
				}
			}catch(err){
				if(value){
					alert.error("Marking as Template Failed")
				}else{
					alert.error("Unmarking as Template Failed")
				}
				console.log(err)
			}
		}else{
			alert.error("Design Branch ID is empty")
		}
	}

	const exempt_branch = async(design_branch_id, value) => {
		try{
			let resp = await general_fetch({url:'design_branch/update_exemption', body:{design_branch_id:design_branch_id, status: value?'grant':'revoke'}})
			get_designs()
			if(value){
				alert.success("The selected design branch is exempted from requirement to clear violations")
			}else{
				alert.success("The selected design branch is not exempted from requirement to clear violations")
			}
		}catch(err){
			alert.error("Something went wrong")
		}
	}


	const get_all_of_design_branch = async(design_branch_id) => {
		try{
			let resp = await general_fetch({url:'production_detail/get_all_of_design_branch', body:{design_branch_id:design_branch_id}})
			console.log("Get all of design", resp)
			if(resp && resp.length){
				let val1='', val2='', val3=''
				for(let i=0;i<resp.length;i++){
					if(resp[i].status === 'completed' && resp[i].output_file_path){
						let temp_array = resp[i].commands.split(" ")
						console.log("instant_files", temp_array)
						if(temp_array.length == 1 && temp_array[0] == 'GetPricingQuotationXlsx'){
							
							if(!val1){
								console.log("instant_file", resp[i])
								val1 = (resp[i])
							}
						}else if(temp_array.length == 3){
							if(!val2) val2 = (resp[i])
						}else if(temp_array.length > 3){
							if(!val3) val3 = (resp[i])
						}

						if(instant_all_outputs_download && instant_pricing_quotation_download && ((eligible_commands && eligible_commands.custom.length==0)||instant_custom_outputs_download)) break;
					}
				}
				set_instant_pricing_quotation_download(val1)
				set_instant_all_outputs_download(val2)
				set_instant_custom_outputs_download(val3)
			}
		}catch(err){
			console.log(err)
		}
	}

	try {
		return (
			<>

				<AdditionalPropertiesModal 
					open = {show_additional_props_modal}
					handleClose = {() => {set_show_additional_props_modal(false)}}
					design = {design}
					get_designs = {get_designs}
					additional_json_item_default = {additional_json_item_default}
					additional_json_item_custom = {additional_json_item_custom}
					// set_design_additional_props = {set_design_additional_props}
				/>

				{/* <Download_Outputs_Modal 
					open = {show_download_outputs_modal}
					handleClose={() => set_show_download_outputs_modal(false)} 
					design={design} design_branch={design_branch} 
					store_details={store_details}
					get_store_details={get_store_details}
					eligible_commands={eligible_commands} 
					download_design_outputs={download_design_outputs}
					instant_all_outputs_download = {instant_all_outputs_download}
					set_instant_all_outputs_download = {set_instant_all_outputs_download}
					instant_pricing_quotation_download = {instant_pricing_quotation_download}
					set_instant_pricing_quotation_download = {set_instant_pricing_quotation_download}
					instant_custom_outputs_download = {instant_custom_outputs_download}
					set_instant_custom_outputs_download = {set_instant_custom_outputs_download}

				/> */}

				<UserAccessModal
					open = {show_share_users_modal}
					handleClose = {() => {set_show_share_users_modal(false)}}
					design_branch={design_branch}
					all_user_details={all_user_details}
					design={design}
				/>
				

				<Dropdown isOpen={dropdown_open} toggle={() => set_dropdown_open(!dropdown_open)}>
					<DropdownToggle title='Design Options' style={{backgroundColor:'white', border:'none'}} ><i className="fa fa-cog" style={{color:'#007EF4'}} /></DropdownToggle>
					<DropdownMenu right className='sku_dropdown_list'>
						<DropdownItem className='sku_dropdown_list_item' onClick={() => onclick_view_design(design_branch)}>View Design</DropdownItem>
						<DropdownItem className='sku_dropdown_list_item' onClick={() => {set_show_share_users_modal(true)}}>User Access</DropdownItem>
						<DropdownItem className='sku_dropdown_list_item' onClick={design_branch && design_branch.locked ?() => onclick_unlock_lock_design('unlock',design):() => onclick_unlock_lock_design('lock',design)}>{design_branch && design_branch.locked ?'Unlock Design':'Lock Design'}</DropdownItem>
						<DropdownItem className='sku_dropdown_list_item' onClick={design_branch && design_branch.approved ?() => toggle_branch_approval('revoke',design_branch.id):() => toggle_branch_approval('grant',design_branch.id)}>{(design_branch && design_branch.approved) ?'Revoke approval and unlock' : 'Approve and lock'}</DropdownItem>
						<DropdownItem className='sku_dropdown_list_item' onClick={()=>{mark_unmark_as_template(!is_template_json[design_branch.id])}} >{is_template_json[design_branch.id]?'Unmark as Template':'Mark as Template'}</DropdownItem>
						<DropdownItem className='sku_dropdown_list_item' disabled={force_refresh_value?true:false} onClick={force_refresh_value?null:() => onclick_force_refresh(design)} >Force refresh sku cache</DropdownItem>

						<DropdownItem className='sku_dropdown_list_item' onClick={() => {set_show_additional_props_modal(true)}}>Additional Properties</DropdownItem>
						{/* <DropdownItem className='sku_dropdown_list_item' onClick={() => {if(!initialized){get_all_of_design_branch(design_branch.id); set_initialized(true)} set_show_download_outputs_modal(true)}} >Download Output Reports</DropdownItem> */}
						<DropdownItem className='sku_dropdown_list_item' onClick={() => {exempt_branch(design_branch.id, !design_branch.exempt)}}>{design_branch.exempt ? "Don't Exempt Violations" : "Exempt Violations"}</DropdownItem>

					</DropdownMenu>
				</Dropdown>	
			</>
		)
	} catch(err) {

	}
}


const Design_Table = ({project ,designs, get_designs, fetch_project_details, store_details, get_store_details, design_templates_ids, is_template_json, update_is_template_json, update_project_branch_level_data, eligible_commands, set_download_started,set_download_ended, set_downloading_project, set_downloading_design, set_downloading_design_branch, all_user_details}) => {
	const user = useContext(UserContext)
	const get_work_orders = (work_orders) => {
		
		if(!work_orders){
			work_orders = []
		}
		if(!user.internal){
			work_orders = work_orders.filter(x => !x.hidden)
		}

		return (
			<>
				<div>{work_orders && work_orders.length ? work_orders[0].name : '-'}</div> { work_orders.length > 1 ? <Tooltip placement='right' title={<div>{work_orders.map((w, idx) => idx > 0 ? <div>{w.name}</div> : '')}</div>} ><div className='ml-2' style={{color: '#0078ff', textDecoration: 'underline', fontStyle: 'italic'}}>{`${work_orders.length-1} more`}</div></Tooltip> : ''}
			</>
		)
	}

	try {
		return (
			<table style={{ height:'100%',width:'100%', overflow: 'visible' }} className="text-left flex_property table_style">
				<thead style={{width:'100%'}}>
				<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',width:'100%'}}>
					<th className='th_style flex_property' style={{flex:'8'}}><span>Design Name</span></th>
					<th className='th_style flex_property' style={{flex:'6'}}><span>Branch Name</span></th>
					<th className='th_style flex_property' style={{flex:'6'}}><span>Locking Status</span></th>
					<th className='th_style flex_property' style={{flex:'6'}}><span>Approval Status</span></th>
					<th className='th_style flex_property' style={{flex:'6'}}><span>Last Updated</span></th>
					{store_details && store_details.add_ons && store_details.add_ons.active && store_details.add_ons.active.find(x => ["1","4","6","7"].includes(x.id)) ? <th className='th_style flex_property' style={{flex:'6'}}><span>Work Orders</span></th> : ''}
					<th className='th_style flex_property' style={{flex:'6'}}><span></span></th>
					<th className='th_style' style={{border:0, width: '64px', flex: 'unset'}}><span></span></th>
				</tr>
				</thead>
				<tbody style={{ display:'block', height:'calc(100% - 40px)', overflow:'auto', width:'100%', overflow: 'visible' }}>
					{
						designs && designs.length && designs.map((design, index) => {return(
							design.design_branches && design.design_branches.length && design.design_branches.filter(x => !x.mes_branch).map((design_branch, branch_index) => {
							console.log('Design_option_dropdown', design_branch, design)
							return(
								<tr key={index} style={{display:'flex'}} className='tr_style display_onhover'>
									<td className='td_style flex_property' style={{flex:'8'}} rowSpan={design.design_branches?.length??0}><span className='table_text_overflow'>{branch_index == 0 ? (design.name?design.name:'-') : ''}</span></td>
									<td className='td_style flex_property' style={{flex:'6'}}>{design_branch.name ? design_branch.name :'-'}</td>
									<td className='td_style flex_property' style={{flex:'6'}}>{design_branch.locked ? 'Locked' :'Unlocked'}</td>
									<td className='td_style flex_property' style={{flex:'6'}}>{design_branch.approved ?  "Approved" : 'Not Approved'}</td>
									<td className='td_style flex_property' style={{flex:'6'}}><Moment format="DD/MM/YYYY">{design_branch.updated_at}</Moment></td>
									{store_details && store_details.add_ons && store_details.add_ons.active && store_details.add_ons.active.find(x => ["1","4","6","7"].includes(x.id)) ? <td className='td_style flex_property x1' style={{flex:'6'}}>{get_work_orders(design_branch?.work_orders)}</td> : ''}
									<td className='td_style flex_property x1' style={{flex:'6'}}>{is_template_json[design_branch.id]?<div className='template_box'><span className='template'>Template</span></div>:''}</td>
									<td className='td_style flex_property x1' style={{ justifyContent:'flex-end', overflow: 'visible', width: '64px', flex: 'unset'}}>
										<Design_option_dropdown
											project = {project}
											design={design}
											get_designs={get_designs}
											design_branch={design_branch}
											fetch_project_details={fetch_project_details}
											store_details={store_details}
											get_store_details={get_store_details}
											is_template_json = {is_template_json}
											update_is_template_json = {update_is_template_json}
											update_project_branch_level_data = {update_project_branch_level_data}
											eligible_commands = {eligible_commands}
											set_download_started = {set_download_started}
											set_download_ended = {set_download_ended}
											set_downloading_project = {set_downloading_project}
											set_downloading_design = {set_downloading_design}
											set_downloading_design_branch = {set_downloading_design_branch}
											all_user_details={all_user_details}
										/>
									</td>
								</tr>
							)})
						)})
					}
				</tbody>
			</table>
		);
	} catch(err) {
		return 'Error in displaying designs'
	}
}

const DesignViewModal = ({ open, handleClose , project ,user , fetch_project_details, project_branch_level_data, store_details, get_store_details, update_project_branch_level_data, eligible_commands, set_download_started,set_download_ended, set_downloading_project, set_downloading_design, set_downloading_design_branch, all_user_details}) => {
	const [ designs , set_designs ] = useState([])
	const [ design_branch_map , set_design_branch_map ] = useState(null)
	const [ initialized , set_initialized ] = useState(false)
	const [ is_template_json, set_is_template_json ] = useState({})
	const [design_templates_ids, set_design_templates_ids] = useState([]);

	const alert = useAlert()

	const update_is_template_json = (design_branch_id, value) => {
		let a = JSON.parse(JSON.stringify(is_template_json))
		a[design_branch_id] = value
		set_is_template_json(a)
	}

	const get_designs = async () => {
		try {
			var body = {
				"project_ids": [project.id],
				"info_level": "design_branch",
				"include_project_status": true, 
				"include_last_activity_time": true,
				"include_access_info": true
			};
			let design_data = await general_fetch({ url: 'project/get_by_id', body });	
			// let design_branch_data = await general_fetch({ url: 'design/get_all_branches', body: { design_id: design_data.map(x => x.id) } });
			if (!initialized) {
				set_initialized(true);
			}
			// console.log(design_data);
			// design_branch_data = design_branch_data.reduce((final, elem) => ({ ...final, [elem.design_id]: [ ...(final?.[elem.design_id] ?? []), elem ] }), {});
			// console.log('branch data ---> ', design_branch_data);
			// set_design_branch_map(design_branch_data);
			set_designs(design_data.projects[0]?.designs);
		} catch(err) {
			console.log(err);
			handleError(err)
		}
	}

	const fetch_design_templates = async() => {
        try{
            let response = await general_fetch({url:'design_branch/get_templates'})
            let template_branch_ids_array = response && response.org_templates && response.org_templates.length ? response.org_templates.map(x => x.id) :''
			set_design_templates_ids(template_branch_ids_array)
        }catch(err){
            alert.error("Request Failed")
        }
    }

	useEffect(() => {
		if (open && project) {
			get_designs();
			fetch_design_templates()
			set_download_ended(false)
		}
	},[ open, project ]);

	useEffect(() => {
		if(designs && designs.length && design_templates_ids.length){
			let is_template_json_temp = {}
			let temp = designs && designs.length && designs.map((design, index) => {
				design.design_branches && design.design_branches.length && design.design_branches.map((design_branch, branch_index) => {
					if(design_templates_ids.includes(design_branch.id)) is_template_json_temp[design_branch.id] = true
				})
			})
			set_is_template_json(is_template_json_temp)
		}
	},[designs, design_templates_ids])

	return (
		<Modal className="Margin_64px_top" isOpen={open} size="xl" toggle={handleClose}>
			<Card className='global_modal_height_for_mdlgxl'>
				<CardHeader className='global_modal_header'>
					<span>All Designs - {project && (project.name ? project.name : project.id)}</span>
					<i style={{cursor:'pointer'}} onClick={handleClose} className='fa fa-times'/>
				</CardHeader>
				<CardBody className='global_modal_body'>
					<Row>
						{ 
							designs && designs.length ?	
							(	
								<Design_Table
									project = {project}
									designs={designs}
									get_designs={get_designs}
									design_branch_map={design_branch_map}
									fetch_project_details={fetch_project_details}
									design_templates_ids = {design_templates_ids}
									is_template_json = {is_template_json}
									update_is_template_json = {update_is_template_json}
									update_project_branch_level_data = {update_project_branch_level_data}
									eligible_commands = {eligible_commands}
									set_download_started = {set_download_started}
									set_download_ended = {set_download_ended}
									set_downloading_project = {set_downloading_project}
									set_downloading_design = {set_downloading_design}
									set_downloading_design_branch = {set_downloading_design_branch}
									all_user_details={all_user_details}
									store_details={store_details}
									get_store_details={get_store_details}
								/>
							):(
								<Filler initialized ={initialized} />
							)
						}
					</Row>			
				</CardBody>
				<CardFooter className='global_modal_footer'>
					<Button className='white_button' style={{margin:0}} type="button" onClick={handleClose}> Close </Button>
				</CardFooter>
			</Card>
		</Modal>
	);
}

export default DesignViewModal ;