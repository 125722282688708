import React , { useState , useEffect, useContext }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import { useAlert } from 'react-alert'
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { UserContext } from '../../components/Contexts/UserContext';
import remove_underscore_wtih_first_cap from '../../utils/remove_underscore_wtih_first_cap';

const FeatureFlagModal = ({ open, handleClose, feature_flag, fetch_feature_flags }) => {

	const [ feature_flag_id , set_feature_flag_id ] = useState(null);
	const [ feature_flag_status , set_feature_flag_status ] = useState(null);
	const [ default_status, set_default_status] = useState('');
	const[open_alert_box , set_open_alert_box] = useState(false);
	const[current_state_alert , set_current_state_alert] = useState(null);
	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)
	const alert = useAlert()


	const update_flagged_feature_status = async () => {
		if (feature_flag_id) {
			try {
				let body = { flagged_feature_ids: [feature_flag_id], type: feature_flag_status };
				if(org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
				if (feature_flag_status == 'default') {
					await general_fetch({ url: 'flagged_feature/restore_feature_defaults', body });
				} else {
					await general_fetch({ url: 'flagged_feature/update_feature_status', body });
				}
				alert.success('Feature availability updated')
				fetch_feature_flags();
				handleClose()
			} catch(err) { 
				alert.error('Error in updating feature availability')
				console.log(err);
			}
		} else {
			alert.error('Something went wrong - please select feature flag');
		}	
	}
	
	
	useEffect (() => {
		if(feature_flag) {
			set_feature_flag_id(feature_flag.id)
			// set_feature_flag_status(feature_flag.store_status)
			set_feature_flag_status(org_bu_view === 'bu' ? feature_flag.business_unit_status : feature_flag.store_status)
			set_default_status(feature_flag.default_status)
		}
	}, [ feature_flag ]);


	return (
		<Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={handleClose}>
			<Card className='global_modal_height_for_xs'>
				<CardHeader className='global_modal_header'>
							<span>Manage Feature Availability</span>
							<i onClick={handleClose} style={{cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body'>
					<Row>
						<Col>
							<h6 style={{ paddingLeft: 14, 'text-transform': 'capitalize' }}>Feature Name - {feature_flag ? feature_flag.name : ''}</h6>
							<h6 style={{ paddingLeft: 14 }}>Feature Category - { feature_flag ? feature_flag.feature_category : '' }</h6>
							<h6 style={{ paddingLeft: 14 }}>Description - { feature_flag ? feature_flag.description : '' }</h6>
							<br/>
							<div>
								<Col>
									<FormGroup >
										<label className="label_margin_5px">Feature Availability</label>
										<Input
											className='edit_properties_dropdown' 
											type='select'
											variant="outlined"
											value={feature_flag_status}
											onChange={e => set_feature_flag_status(e.target.value)}
										>
											<option value='enabled'>Enabled</option>
											<option value='disabled'>Disabled</option>
											<option value='disabled_for_designers'>Disabled For Designers</option>
											<option value='default'>Default ({default_status ? remove_underscore_wtih_first_cap(default_status) : ''})</option>
										</Input>
									</FormGroup>
								</Col>
							</div>
						</Col>	
					</Row>
				</CardBody>

				<CardFooter className='global_modal_footer'>
					<Button className='blue_button' type="button" style={{ marginRight: 10 }} onClick={update_flagged_feature_status}> Update </Button>
					<Button className='white_button' type="button" onClick={handleClose}> Cancel </Button>
				</CardFooter>
			</Card>  
		</Modal>
	);
}

export default FeatureFlagModal ; 
