import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useMemo } from 'react';
import general_fetch from '../../utils/fetch';
import {FormGroup, Modal, Alert, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, ModalBody, } from "reactstrap";
import { Link } from "react-router-dom";
//import AlertBox from "../../components/AlertBox/";
//import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import Moment from 'react-moment';
import {CSVLink} from "react-csv";
// import useRazorpay from "react-razorpay";
// import ShowInfurniaPlans from './ShowInfurniaPlans';
import { Tooltip } from 'antd';
import { COLORS } from '../../utils/colors';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const PaymentConfirmationModal = ({open, handleClose, order_status, first_order, plan_selected}) => {
	const alert = useAlert()

	useEffect(() => {
		if(order_status === 'paid'){
			alert.success('Payment was successful ', {timeout:10000000})
		}
	}, [order_status]);

	return(
		<Modal isOpen={open} size='xs' className='modal-dialog-centered'>
			<CardBody className='flex_center' style={{minHeight:'260px', backgroundColor:'white', fontSize:'18px'}}>
			{
				order_status === 'paid' ?
				<div className='flex_center' style={{flexDirection:'column'}}>
					{/* <i className='fa fa-check-circle payment_success_check' style={{color:'green', fontSize:'64px', marginBottom:'32px'}}/> */}
					<img /*className='payment_success_check'*/ src={'/assets/billing/payment_successful.svg'} style={{height:'76px', width:'76px', margin:'32px 0px 0px 0px'}} />
					<div style={{padding:'28px 0px', color:'#23C16B', fontWeight:'700'}}>Payment Successful !</div>
					{
						first_order ? <div style={{marginBottom: '28px', fontSize:'16px'}}>&#127881;{'\xa0\xa0'}Your organisation is now subscribed to Infurnia</div>:""
					}
					<Button className='white_button' style={{margin:'0px', fontSize:'14px', marginBottom:'14px'}} onClick={() => {window.location.href = global.config.admin_url}}>Continue</Button>
				</div>
				:
				(order_status === 'timeout' ? 
					<div className='flex_center' style={{flexDirection:'column'}}>
						<div style={{padding:'28px 0px 0px 0px'}}>It is taking longer than usual !</div>
						<div style={{padding:'8px 0px 28px 0px'}}>Please refresh the page and check </div>
						<i className='fa fa-exclamation' style={{color:'orangered', fontSize:'64px', marginBottom:'32px'}}/>
						<Button className='white_button' style={{margin:'0px'}} onClick={handleClose}>Close</Button>
					</div>:
					<div className='flex_center' style={{flexDirection:'column', fontSize:'16px'}}>
						<div class="dot-spinner">
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
						</div>
						<div style={{padding:'28px 0px 0px 0px'}}>Please wait while we confirm your payment</div>
						<div style={{padding:'18px 0px 0px 0px'}}>Please do not close or refresh this tab</div>
					</div>
				)
			}
			</CardBody>
		</Modal>
	)
}

const Billing_usage = ({user, store_details, set_active_tab, set_active_division_id_sidebar, set_page_loader, get_store_details}) => {
	
	let now = new Date();
	const [tabs, set_tabs] = useState(1);
	const [old_selected_date, set_old_selected_date] = useState(now.getMonth() == 0 ? (months[11] + ', ' + (now.getFullYear() -1)) : (months[now.getMonth()-1] + ', ' + now.getFullYear()));
	const [selected_date, set_selected_date] = useState(now.getMonth() == 0 ? (months[11] + ', ' + (now.getFullYear() -1)) : (months[now.getMonth()-1] + ', ' + now.getFullYear()));
	const [unbilled_usage, set_unbilled_usage] = useState({});
	const [billing_history, set_billing_history] = useState({});
	const [selected_month_usage, set_selected_month_usage] = useState({});
	const [bill_id, set_bill_id] = useState();
	const [dues, set_dues] = useState();
	const [show_payment_confirmation_modal, set_show_payment_confirmation_modal] = useState(false);
	const [order_status, set_order_status] = useState('');

	const [time_out_output, set_time_out_output] = useState();
	const [first_order, set_first_order] = useState(false);
	const [plan_selected, set_plan_selected] = useState();
	const alert = useAlert()
	// const Razorpay = useRazorpay()
	const [new_window, set_new_window] = useState();
	const [phonepeUrl, set_phonepeUrl] = useState('https://mercury-uat.phonepe.com/transact/simulator?token=RM4AjUGHymvJUwyVtDarOR4xPbaZRLTkNMJ3e4DR4Kh');

	const [usage_csv_data, set_usage_csv_data] = useState([
		["Billing details", "",],
		["Ahmed", "Tomi"],
		["Raed", "Labes"],
		["S.no", "email", "roles", 'usage start date', 'usage end date'],
		["Yezzi", "Min l3b", "ymin@cocococo.com", "extra"]
	  ]);
	  
	const cost_per_credit = useMemo(() => store_details && store_details.ccr_details ? store_details.ccr_details.cost_per_credit : '1' , [store_details])
	const currency = useMemo(() => store_details && store_details.ccr_details ? store_details.ccr_details.currency : 'USD' , [store_details])

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	useEffect(() => {
		console.log('new_window', new_window)
	}, [new_window]);

	const fetch_unbilled_usage = async() => {
		try{
			if(store_details && store_details.id){
				let resp = ''
				set_page_loader({show:true, text:'fetching unbilled usage...'})
				try{
					resp = await general_fetch({url:'billing/find_unbilled_statement', body:{customer_store_id:store_details.id}})
				}
				catch(err){
					console.log(err);
					alert.error("Cant find Unbilled statement")
				}
				set_unbilled_usage(resp)
				set_page_loader({show:false})
			}
		}catch(err){
			console.log(err)
		}
	}

	const fetch_billing_history = async() => {
		try{
			if(store_details && store_details.id && selected_date){
				set_page_loader({show:true, text:'fetching billing history...'})
				let resp = await general_fetch({url:'billing/get_bills', body:{customer_store_id:store_details.id, year: selected_date.slice(-4)}})
				set_page_loader({show:false})
				if(resp && resp.length){
					let t = selected_date
					let temp_month_usage = resp.find(o => {console.log('temp_month_usage 1', t,  months[new Date(o.end_date).getMonth()], t.substring(0, t.length-6)) ;return months[new Date(o.end_date).getMonth()] === t.substring(0, t.length-6)})
					set_selected_month_usage(temp_month_usage)
					set_bill_id(temp_month_usage ? temp_month_usage.id : '')
				}
				set_billing_history(resp)
				
			}
		}catch(err){
			console.log(err)
		}
	}

	const fetch_dues = async() => {
		try{
			set_page_loader({
				show:true,
				text:'fetching dues...'
			})
			let resp = await general_fetch({url:'billing/order/find_dues'})
			set_page_loader({
				show:false
			})
			set_dues(resp)
		}catch(err){
			console.log(err)
			set_page_loader({
				show:false
			})
		}
	}

	// const check_for_payment_gateway_close = (id) => {
	// 	let checkExist = setInterval(function() {
	// 		let temp = 1
	// 		if(document.getElementsByClassName('selected-language')){
	// 			console.log('found Infurnia Technologies second time');
	// 			temp = 0
	// 		}

	// 		if(temp){
	// 			start_pinging_status(id)
	// 			set_show_payment_confirmation_modal(true)
	// 			clearInterval(checkExist)
	// 		}
	// 	},100)
	// }

	const get_order_status = async(id) =>{
		if(id){
			try{
				let resp = await general_fetch({url:'billing/order/get', body:{id}})
				return resp && resp.status
			}catch(err){
				console.log(err)
			}
		}
	}

	const start_pinging_status = async(id) => {
		let poll_interval = setInterval(async() => {
			let temp_order_status = await get_order_status(id)
			if(temp_order_status === 'paid'){
				clearInterval(poll_interval);
				console.log(poll_interval)
				set_order_status('paid')
				fetch_dues()
			}
		}, 1000)

		let time_out = setTimeout(function() {clearInterval(poll_interval);if(order_status != 'paid'){
			// console.log('order status updated 1', String(order_status))
			set_order_status('timeout');
			fetch_dues()
		}}, 5*60*1000)

		set_time_out_output(time_out)

	}

	useEffect(() => {
		// console.log('order status updated 2', order_status)
		if(order_status === 'paid'){ console.log('order status updated clearing '); clearTimeout(time_out_output); if(new_window) new_window.close() }
	}, [order_status]);

	const payment_function = async(single_due) => {
		try{
			// if(single_due.total && single_due.payment_gateway_order_id){
			// 	let options = {
			// 		amount: single_due.total,
			// 		currency: 'INR', order_id: single_due.payment_gateway_order_id,
			// 		handler: (res) => { console.log('Checkout form closed', res); set_show_payment_confirmation_modal(true); start_pinging_status(single_due.id) },
			// 		modal: {
			// 			"ondismiss": function () {
			// 				console.log('Checkout form closed');
			// 				set_show_payment_confirmation_modal(true)
			// 				start_pinging_status(single_due.id)
			// 			}
			// 		}
			// 	}
			// 	let rzp1 = new Razorpay(options)
			// 	rzp1.on("payment.failed", function (response) {
			// 		// alert(response.error.code);
			// 		alert(response.error.description);
			// 		// alert.error(response.error.description)
			// 		// alert(response.error.source);
			// 		// alert(response.error.step);
			// 		// alert(response.error.reason);
			// 		// alert(response.error.metadata.order_id);
			// 		// alert(response.error.metadata.payment_id);
			// 	});
				
			// 	rzp1.open()
			// }
			let resp = await general_fetch({url:'prepaid_signup_flow/generate_payment_link', body:{order_id: single_due.id, is_renewal_payment: true}})
			let newWindow = window.open(resp.data.instrumentResponse.redirectInfo.url, '_blank', 'noreferrer')
			set_new_window(newWindow)
			set_show_payment_confirmation_modal(true)
			start_pinging_status(single_due.id)
		}catch(err){
			alert.error("Something went wrong, Please try again")
			console.error(err)
		}
	}

	useEffect(() => {
		set_active_division_id_sidebar('usage')
	}, []);

	useEffect(() => {
		console.log('tabssssss', tabs)
		if(tabs == 3 && store_details && store_details.id){
			fetch_unbilled_usage()
		}else if(tabs == 2 && store_details && store_details.id){
			fetch_billing_history()
		}
	}, [tabs, store_details]);

	useEffect(() => {
		if(tabs == 1) fetch_dues()
	}, [tabs]);

	useEffect(() => {
		if(old_selected_date.slice(-4) != selected_date.slice(-4)){
			fetch_billing_history()
		}

		if(old_selected_date.substring(0, old_selected_date.length-6) != selected_date.substring(0, selected_date.length-6) && old_selected_date.slice(-4) === selected_date.slice(-4)){
			let t = selected_date
			let temp_month_usage = billing_history.find(o => {console.log('temp_month_usage 1', t,  months[new Date(o.end_date).getMonth()], t.substring(0, t.length-6)) ;return months[new Date(o.end_date).getMonth()] === t.substring(0, t.length-6)})
			set_selected_month_usage(temp_month_usage)
			set_bill_id(temp_month_usage ? temp_month_usage.id : '')
		}

		set_old_selected_date(selected_date)
	}, [selected_date]);

	useEffect(() => {
		if(selected_month_usage && selected_month_usage.user_activity_summary){
			let temp_csv_data = []
			temp_csv_data.push(['Billing Details'])
			temp_csv_data.push(['Organization name', store_details ? store_details.name : ''])
			temp_csv_data.push(['Billing Period', (new Date(selected_month_usage.start_date).toString().substring(4, 15)) + ' - ' + (new Date(selected_month_usage.end_date).toString().substring(4, 15))])
			// temp_csv_data.push(['Plan Cost (credits)', selected_month_usage.plan_credits ? selected_month_usage.plan_credits : '0'])
			// temp_csv_data.push(['Add-on Cost (credits)', selected_month_usage.add_on_credits ? selected_month_usage.add_on_credits : '0'])
			// temp_csv_data.push(['Extra Users Cost (credits)', selected_month_usage.excess_usage_credits ? selected_month_usage.excess_usage_credits : '0'])
			// temp_csv_data.push(['Render Cost (credits)', selected_month_usage.render_credits ? selected_month_usage.render_credits : '0'])
			// temp_csv_data.push(['Total Cost (credits)', selected_month_usage.total_credits_after_discount ? selected_month_usage.total_credits_after_discount : '0'])
			temp_csv_data.push([`Plan Cost (in ${currency})`, selected_month_usage.plan_credits ? (selected_month_usage.plan_credits*cost_per_credit).toFixed(2) : '0'])
			temp_csv_data.push([`Add-on Cost (in ${currency})`, selected_month_usage.add_on_credits ? (selected_month_usage.add_on_credits*cost_per_credit).toFixed(2) : '0'])
			temp_csv_data.push([`Extra Users Cost (in ${currency})`, selected_month_usage.excess_usage_credits ? (selected_month_usage.excess_usage_credits*cost_per_credit).toFixed(2) : '0'])
			temp_csv_data.push([`Render Cost (in ${currency})`, selected_month_usage.render_credits ? (selected_month_usage.render_credits*cost_per_credit).toFixed(2) : '0'])
			temp_csv_data.push([`Total Cost (in ${currency})`, selected_month_usage.total_credits_after_discount ? (selected_month_usage.total_credits_after_discount*cost_per_credit).toFixed(2) : '0'])
			
			temp_csv_data.push([])
			// temp_csv_data.push(['S.No', "Email", 'Roles', "Usage Start Date", 'Usage End Date', 'HD Renders', 'Full HD Renders', '4K Renders', 'Credits Used'])
			temp_csv_data.push(['S.No', "User Id", "Email", 'Roles', "Created Date", "Is Deprecated", "Deprecated Date", "Usage Start Date", 'Usage End Date', 'HD Renders', 'Full HD Renders', '4K Renders', '4K 360 Renders', '6K 360 Renders', '8K 360 Renders', '3D Renders', "Platform User", "Internal User"])
			let t = selected_month_usage.user_activity_summary && selected_month_usage.user_activity_summary.length ? selected_month_usage.user_activity_summary.map((user_usage, idx) => {
			let user_roles_str = ''
			let t = user_usage.role_usage && user_usage.role_usage.length ? user_usage.role_usage.map((role_usage, id) => { if (id > 0) user_roles_str += ', '; user_roles_str += role_usage.role_id }) : ''
			
			if(!user_usage.internal){
				temp_csv_data.push([idx + 1,
					user_usage.user_id,
					user_usage.original_email,
					user_roles_str,
					user_usage.user_created_at ? user_usage.user_created_at.substring(0, 10).split('-').reverse().join('/') : '',
					user_usage.deprecated,
					user_usage.deprecated && user_usage.user_deprecated_at ? user_usage.user_deprecated_at.substring(0, 10).split('-').reverse().join('/') : '',
					new Date(user_usage.active_entries && user_usage.active_entries.length && user_usage.active_entries[0].usage_start_date).toString().substring(4, 15),
					new Date(user_usage.active_entries && user_usage.active_entries.length && user_usage.active_entries[0].usage_end_date).toString().substring(4, 15),
					user_usage.render_usage && user_usage.render_usage.render && user_usage.render_usage.render.HD ? user_usage.render_usage.render.HD : 0,
					user_usage.render_usage && user_usage.render_usage.render && user_usage.render_usage.render.Full_HD ? user_usage.render_usage.render.Full_HD : 0,
					user_usage.render_usage && user_usage.render_usage.render && user_usage.render_usage.render['4K'] ? user_usage.render_usage.render['4K'] : 0,
					user_usage.render_usage.render && user_usage.render_usage.render['C_4K'] ? user_usage.render_usage.render['C_4K'] : 0,
					user_usage.render_usage.render && user_usage.render_usage.render['C_6K'] ? user_usage.render_usage.render['C_6K'] : 0,
					user_usage.render_usage.render && user_usage.render_usage.render['C_8K'] ? user_usage.render_usage.render['C_8K'] : 0,
					user_usage.render_usage.bake && user_usage.render_usage.bake['HD'] ? user_usage.render_usage.bake['HD'] : 0,
					// user_usage.role_credits_used,
					user_usage.platform ? "True" : "False",
					user_usage.internal ? "True" : "False"])
			}
			}):""
			set_usage_csv_data(temp_csv_data)
		}
	}, [selected_month_usage]);

	useEffect(() => {
		if(unbilled_usage && unbilled_usage.estimate_charge){
			let temp_csv_data = []
			let str = 'Current Usage' /*+ (new Date(unbilled_usage.next_bill_start_date).toString().substring(4, 15)) + ' - ' + (new Date(unbilled_usage.unbilled_till).toString().substring(4, 15)) + (store_details ? ('for ' + store_details.name) : '')*/
			temp_csv_data.push([str])
			temp_csv_data.push(['Organization name', store_details ? store_details.name : ''])
			temp_csv_data.push(['Billing Period', (new Date(unbilled_usage && unbilled_usage.next_bill_start_date ? unbilled_usage.next_bill_start_date : '').toString().substring(4, 15)) + ' - ' + (new Date(unbilled_usage && unbilled_usage.next_bill_end_date ? unbilled_usage.next_bill_end_date : '').toString().substring(4, 15))])
			// temp_csv_data.push(['Plan Cost (credits)', unbilled_usage && unbilled_usage.estimate_charge && unbilled_usage.estimate_charge.total_plan_credits ? unbilled_usage.estimate_charge.total_plan_credits : 0])
			// temp_csv_data.push(['Add-on Cost (credits)', unbilled_usage && unbilled_usage.estimate_charge && unbilled_usage.estimate_charge.total_add_on_credits ? unbilled_usage.estimate_charge.total_add_on_credits : 0])
			// temp_csv_data.push(['Extra Usage Cost (credits)', unbilled_usage && unbilled_usage.estimate_charge && unbilled_usage.estimate_charge.extra_usage_credit_cost ? unbilled_usage.estimate_charge.extra_usage_credit_cost : 0])
			// temp_csv_data.push(['Render Cost (credits)', unbilled_usage && unbilled_usage.estimate_charge && unbilled_usage.estimate_charge.total_render_credit_cost ? unbilled_usage.estimate_charge.total_render_credit_cost : 0])
			// temp_csv_data.push(['Total Cost (credits)', unbilled_usage && unbilled_usage.estimate_charge && unbilled_usage.estimate_charge.total_credit_cost ? unbilled_usage.estimate_charge.total_credit_cost : 0])
			temp_csv_data.push([`Plan Cost (in ${currency})`, unbilled_usage && unbilled_usage.estimate_charge && unbilled_usage.estimate_charge.total_plan_credits ? (unbilled_usage.estimate_charge.total_plan_credits*cost_per_credit).toFixed(2) : 0])
			temp_csv_data.push([`Add-on Cost (in ${currency})`, unbilled_usage && unbilled_usage.estimate_charge && unbilled_usage.estimate_charge.total_add_on_credits ? (unbilled_usage.estimate_charge.total_add_on_credits*cost_per_credit).toFixed(2) : 0])
			temp_csv_data.push([`Extra Usage Cost (in ${currency})`, unbilled_usage && unbilled_usage.estimate_charge && unbilled_usage.estimate_charge.extra_usage_credit_cost ? (unbilled_usage.estimate_charge.extra_usage_credit_cost*cost_per_credit).toFixed(2) : 0])
			temp_csv_data.push([`Render Cost (in ${currency})`, unbilled_usage && unbilled_usage.estimate_charge && unbilled_usage.estimate_charge.total_render_credit_cost ? (unbilled_usage.estimate_charge.total_render_credit_cost*cost_per_credit).toFixed(2) : 0])
			temp_csv_data.push([`Total Cost (in ${currency})`, unbilled_usage && unbilled_usage.estimate_charge && unbilled_usage.estimate_charge.total_credit_cost ? (unbilled_usage.estimate_charge.total_credit_cost*cost_per_credit).toFixed(2) : 0])
			temp_csv_data.push([])
			// temp_csv_data.push(['S.No', "Email", 'Roles', "Usage Start Date", 'Usage End Date', 'HD Renders', 'Full HD Renders', '4K Renders', 'Credits Used'])
			temp_csv_data.push(['S.No', "User Id", "Email", 'Roles', "Created Date", "Is Deprecated", "Deprecated Date", "Usage Start Date", 'Usage End Date', 'HD Renders', 'Full HD Renders', '4K Renders', '4K 360 Renders', '6K 360 Renders', '8K 360 Renders', '3D Renders', "Platform User", "Internal User"])
			let t = unbilled_usage.user_credits_summary && unbilled_usage.user_credits_summary.length ? unbilled_usage.user_credits_summary.map((user_usage, idx) => {
				let user_roles_str = ''
				let t = user_usage.role_usage && user_usage.role_usage.length ? user_usage.role_usage.map((role_usage, id) => {if(id > 0) user_roles_str += ', ' ;user_roles_str += role_usage.role_id}):''

				// temp_csv_data.push([idx+1, user_usage.email, user_roles_str, new Date(user_usage.usage_start_date).toString().substring(4, 15), new Date(user_usage.usage_end_date).toString().substring(4, 15), user_usage.render_usage.render?user_usage.render_usage.render.HD?.count:0, user_usage.render_usage.render?user_usage.render_usage.render.Full_HD?.count:0, user_usage.render_usage.render?user_usage.render_usage.render['4K']?.count:0, user_usage.role_credits_used])
				if(!user_usage.internal){
					temp_csv_data.push([idx+1,
						user_usage.user_id,
						user_usage.original_email,
						user_roles_str,
						user_usage.user_created_at ? user_usage.user_created_at.substring(0, 10).split('-').reverse().join('/') : '', 
						user_usage.deprecated, 
						user_usage.deprecated && user_usage.user_deprecated_at  ? user_usage.user_deprecated_at.substring(0, 10).split('-').reverse().join('/') : '', 
						user_usage.active_entries && user_usage.active_entries.length && user_usage.active_entries[0].usage_start_date ? user_usage.active_entries && user_usage.active_entries.length && user_usage.active_entries[0].usage_start_date.substring(0, 10).split('-').reverse().join('/') : '', 
						user_usage.active_entries && user_usage.active_entries.length && user_usage.active_entries[0].usage_end_date ? user_usage.active_entries && user_usage.active_entries.length && user_usage.active_entries[0].usage_end_date.substring(0, 10).split('-').reverse().join('/') : '', 
						user_usage.render_usage && user_usage.render_usage.render && user_usage.render_usage.render.HD && user_usage.render_usage.render.HD.count ? user_usage.render_usage.render.HD.count : 0, 
						user_usage.render_usage && user_usage.render_usage.render && user_usage.render_usage.render.Full_HD && user_usage.render_usage.render.Full_HD.count ? user_usage.render_usage.render.Full_HD.count : 0, 
						user_usage.render_usage && user_usage.render_usage.render && user_usage.render_usage.render['4K'] && user_usage.render_usage.render['4K'].count ? user_usage.render_usage.render['4K'].count : 0, 
						user_usage.render_usage.render && user_usage.render_usage.render['C_4K'] ? user_usage.render_usage.render['C_4K'] : 0,
						user_usage.render_usage.render && user_usage.render_usage.render['C_6K'] ? user_usage.render_usage.render['C_6K'] : 0,
						user_usage.render_usage.render && user_usage.render_usage.render['C_8K'] ? user_usage.render_usage.render['C_8K'] : 0,
						user_usage.render_usage.bake && user_usage.render_usage.bake['HD'] ? user_usage.render_usage.bake['HD'] : 0,
						// user_usage.role_credits_used, 
						user_usage.platform ? "True" : "False", 
						user_usage.internal ? "True" : "False"])
				}
			}):''
			console.log('temp_csv_data',temp_csv_data)
			set_usage_csv_data(temp_csv_data)
		}
	}, [unbilled_usage]);

	useEffect(() => {
		if(dues && dues.length == 0) set_tabs(2)
	}, [dues]);

	const Billing_history_table = () => (
		<>
			<div className='flex_property' style={{justifyContent:'space-between', marginTop:'8px', backgroundColor:'#f2f4f6'}}>
				<div style={{textAlign:'left', fontSize:'15px', padding:'8px', marginLeft:'8px',fontWeight:'900'}}>Usage Summary  <span style={{color:'gray'}}>{'(' + (selected_month_usage ? new Date(selected_month_usage.start_date).toString().substring(4, 15) : '') + ' - ' + (selected_month_usage ? new Date(selected_month_usage.end_date).toString().substring(4, 15) : '') + ')'}</span></div>
				<ReactDatetime className='rdt1' style={{cursor:'pointer'}} closeOnSelect onChange={d => {set_selected_date(months[d.month()] + ', ' + d.year())}} value={selected_date} inputProps={{placeholder: "Month Wise Usage"}} dateFormat="YYYY-MM" maxDate={new Date("2023-10")} timeFormat={false}/>
			</div>
			<table style={{height:'100%'}} className="text-left flex_property table_style">
				<thead style={{width:"100%"}}>
					<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Type</span></th>
						{/* <th className='th_style flex_property' style={{flex:'1'}}><span>Credit Cost</span></th> */}
						<th className='th_style flex_property' style={{flex:'1'}}><span>Amount ({currency})</span></th>
					</tr>	
				</thead>
				
				{
					selected_month_usage ?
					<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Plan Cost</span></td>
							{/* <td className='td_style flex_property' style={{flex:'1'}}>
								<span>{selected_month_usage.plan_credits ? selected_month_usage.plan_credits : '0'}</span>
								<Tooltip title={(selected_month_usage.plan_credits_past ? selected_month_usage.plan_credits_past : '0') + ' (Current) + ' + (selected_month_usage.plan_credits_future ? selected_month_usage.plan_credits_future : '0') + ' (Future)'} placement='right' mouseEnterDelay={0}>
									<div className="flex_property">
										<i class="fa fa-info-circle ml-2 cp" style={{fontSize:'18px'}}/>
									</div>
								</Tooltip>
							</td> */}
							<td className='td_style flex_property' style={{flex:'1'}}>
								<span>{selected_month_usage.plan_credits ? (selected_month_usage.plan_credits*cost_per_credit).toFixed(2) : '0'}</span>
								<Tooltip title={(selected_month_usage.plan_credits_past ? (selected_month_usage.plan_credits_past*cost_per_credit).toFixed(2) : '0') + ' (Current) + ' + (selected_month_usage.plan_credits_future ? (selected_month_usage.plan_credits_future*cost_per_credit).toFixed(2) : '0') + ' (Future)'} placement='right' mouseEnterDelay={0}>
									<div className="flex_property">
										<i class="fa fa-info-circle ml-2 cp" style={{fontSize:'18px'}}/>
									</div>
								</Tooltip>
							</td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Add-on Cost</span></td>
							{/* <td className='td_style flex_property'>
								<div className="flex_property">
									{selected_month_usage.add_on_credits ? selected_month_usage.add_on_credits : '0'}
									<Tooltip title={(selected_month_usage.add_on_credits_past ? selected_month_usage.add_on_credits_past : '0') + ' (Current) + ' + (selected_month_usage.add_on_credits_future ? selected_month_usage.add_on_credits_future : '0') + ' (Future)'} placement='right' mouseEnterDelay={0}>
										<div className="flex_property">
											<i class="fa fa-info-circle ml-2 cp" style={{fontSize:'18px'}}/>
										</div>
									</Tooltip>
								</div>
							</td> */}
							<td className='td_style flex_property'>
								<div className="flex_property">
									{selected_month_usage.add_on_credits ? (selected_month_usage.add_on_credits*cost_per_credit).toFixed(2) : '0'}
									<Tooltip title={(selected_month_usage.add_on_credits_past ? (selected_month_usage.add_on_credits_past*cost_per_credit).toFixed(2) : '0') + ' (Current) + ' + (selected_month_usage.add_on_credits_future ? (selected_month_usage.add_on_credits_future*cost_per_credit).toFixed(2) : '0') + ' (Future)'} placement='right' mouseEnterDelay={0}>
										<div className="flex_property">
											<i class="fa fa-info-circle ml-2 cp" style={{fontSize:'18px'}}/>
										</div>
									</Tooltip>
								</div>
							</td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Extra Users Cost</span></td>
							{/* <td className='td_style flex_property' style={{flex:'1'}}>
								<span>{selected_month_usage.excess_usage_credits ? selected_month_usage.excess_usage_credits : '0'}</span>
								<Tooltip title={(selected_month_usage.excess_usage_credits_past ? selected_month_usage.excess_usage_credits_past : '0') + ' (Current) + ' + (selected_month_usage.excess_usage_credits_future ? selected_month_usage.excess_usage_credits_future : '0') + ' (Future)'} placement='right' mouseEnterDelay={0}>
									<div className="flex_property">
										<i class="fa fa-info-circle ml-2 cp" style={{fontSize:'18px'}}/>
									</div>
								</Tooltip>
							</td> */}
							<td className='td_style flex_property' style={{flex:'1'}}>
								<span>{selected_month_usage.excess_usage_credits ? (selected_month_usage.excess_usage_credits*cost_per_credit).toFixed(2) : '0'}</span>
								<Tooltip title={(selected_month_usage.excess_usage_credits_past ? (selected_month_usage.excess_usage_credits_past*cost_per_credit).toFixed(2) : '0') + ' (Current) + ' + (selected_month_usage.excess_usage_credits_future ? (selected_month_usage.excess_usage_credits_future*cost_per_credit).toFixed(2) : '0') + ' (Future)'} placement='right' mouseEnterDelay={0}>
									<div className="flex_property">
										<i class="fa fa-info-circle ml-2 cp" style={{fontSize:'18px'}}/>
									</div>
								</Tooltip>
							</td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Render Cost</span></td>
							{/* <td className='td_style flex_property' style={{flex:'1'}}><span>{selected_month_usage.render_credits ? selected_month_usage.render_credits : '0'}</span></td> */}
							<td className='td_style flex_property' style={{flex:'1'}}><span>{selected_month_usage.render_credits ? (selected_month_usage.render_credits*cost_per_credit).toFixed(2) : '0'}</span></td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Total Cost</span></td>
							{/* <td className='td_style flex_property' style={{flex:'1'}}><span>{selected_month_usage.total_credits_after_discount ? selected_month_usage.total_credits_after_discount : '0'}</span></td> */}
							<td className='td_style flex_property' style={{flex:'1'}}><span>{selected_month_usage.total_credits_after_discount ? (selected_month_usage.total_credits_after_discount*cost_per_credit).toFixed(2) : '0'}</span></td>
						</tr>
					</tbody>:<div className='p-3' style={{fontSize:'14px'}}>No usage found for the selected month</div>
				}
			</table>
			<div style={{textAlign:'left', fontSize:'15px', padding:'8px', fontWeight:'900', backgroundColor:'#f2f4f6'}}><div style={{marginLeft:'8px'}}>Monthly Usage </div></div>
			<table style={{height:'100%'}} className="text-left flex_property table_style">
				<thead style={{width:"100%"}}>
					<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
						<th className='th_style flex_property' style={{flex:'2'}}><span>Email</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Start Date</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>End Date</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>HD Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Full HD Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>4K Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>4K 360 Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>6K 360 Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>8K 360 Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>3D Renders</span></th>
						{/* <th className='th_style flex_property' style={{flex:'1'}}><span>Credits used</span></th> */}
					</tr>	
				</thead>
				
				{
					selected_month_usage ?
					<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
					{
						selected_month_usage && selected_month_usage.user_activity_summary && selected_month_usage.user_activity_summary.length ? selected_month_usage.user_activity_summary.map((user_usage, idx) => (
							user_usage.internal ? '':
							<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
								<td className='td_style flex_property' style={{flex:'2'}}><span>{user_usage.original_email}</span></td>
								<td className='td_style flex_property' style={{flex:'1'}}><Moment format="DD/MM/YYYY">{user_usage.active_entries && user_usage.active_entries.length && user_usage.active_entries[0].usage_start_date}</Moment></td>
								<td className='td_style flex_property' style={{flex:'1'}}><Moment format="DD/MM/YYYY">{user_usage.active_entries && user_usage.active_entries.length && user_usage.active_entries[0].usage_end_date}</Moment></td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render && user_usage.render_usage.render.HD ? user_usage.render_usage.render.HD : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render && user_usage.render_usage.render.Full_HD ? user_usage.render_usage.render.Full_HD : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render && user_usage.render_usage.render['4K'] ? user_usage.render_usage.render['4K'] : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render && user_usage.render_usage.render['C_4K'] ? user_usage.render_usage.render['C_4K'] : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render && user_usage.render_usage.render['C_6K'] ? user_usage.render_usage.render['C_6K'] : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render && user_usage.render_usage.render['C_8K'] ? user_usage.render_usage.render['C_8K'] : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.bake && user_usage.render_usage.bake['HD'] ? user_usage.render_usage.bake['HD'] : '0'}</td>
								{/* <td className='td_style flex_property' style={{flex:'1'}}>{user_usage.role_credits_used}</td> */}
								{/* <td className='td_style flex_property' style={{flex:'1'}}>{user_usage.extra_usage_INR_cost}</td> */}
							</tr>
						)):""
					}
					{
						selected_month_usage && selected_month_usage.user_activity_summary && selected_month_usage.user_activity_summary.length && selected_month_usage.user_activity_summary.filter((user_usage, idx) => !user_usage.internal).length ? '' : <div className='p-3 flex_center' style={{fontSize:'14px'}}>No overage charges</div>
					}
					</tbody>:<div className='p-3' style={{fontSize:'14px'}}>No usage found for the selected month</div>
				}
			</table>
		</>
	)

	const Unbilled_usage_table = () => (
		<>
			<div style={{backgroundColor:'#f2f4f6'}}>
				<div style={{textAlign:'left', fontSize:'15px', padding:'8px', marginLeft:'8px', marginTop:'8px', fontWeight:'900'}}>Estimated Cost  <span style={{color:'gray'}}>{('(' + (unbilled_usage ? new Date(unbilled_usage.next_bill_start_date).toString().substring(4, 15) : '')) + ' - ' + (unbilled_usage ? new Date(unbilled_usage.unbilled_till).toString().substring(4, 15) : '') + ')'}</span></div>
			</div>
			<table style={{height:'100%'}} className="text-left flex_property table_style">
				<thead style={{width:"100%"}}>
					<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Type</span></th>
						{/* <th className='th_style flex_property' style={{flex:'1'}}><span>Credit Cost</span></th> */}
						<th className='th_style flex_property' style={{flex:'1'}}><span>Amount ({currency})</span></th>
					</tr>	
				</thead>
				
				{
					unbilled_usage && unbilled_usage.estimate_charge ?
					<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Plan Cost</span></td>
							{/* <td className='td_style flex_property' style={{flex:'1'}}>
								<span>{unbilled_usage.estimate_charge.total_plan_credits ? unbilled_usage.estimate_charge.total_plan_credits : '0'}</span>
								<Tooltip title={(unbilled_usage.estimate_charge.plan_credits_past ? unbilled_usage.estimate_charge.plan_credits_past : '0') + ' (Current) + ' + (unbilled_usage.estimate_charge.plan_credits_future ? unbilled_usage.estimate_charge.plan_credits_future : '0') + ' (Future)'} placement='right' mouseEnterDelay={0}>
									<div className="flex_property">
										<i class="fa fa-info-circle ml-2 cp" style={{fontSize:'18px'}}/>
									</div>
								</Tooltip>
							</td> */}
							<td className='td_style flex_property' style={{flex:'1'}}>
								<span>{unbilled_usage.estimate_charge.total_plan_credits ? (unbilled_usage.estimate_charge.total_plan_credits*cost_per_credit).toFixed(2) : '0'}</span>
								<Tooltip title={(unbilled_usage.estimate_charge.plan_credits_past ? (unbilled_usage.estimate_charge.plan_credits_past*cost_per_credit).toFixed(2) : '0') + ' (Current) + ' + (unbilled_usage.estimate_charge.plan_credits_future ? (unbilled_usage.estimate_charge.plan_credits_future*cost_per_credit).toFixed(2) : '0') + ' (Future)'} placement='right' mouseEnterDelay={0}>
									<div className="flex_property">
										<i class="fa fa-info-circle ml-2 cp" style={{fontSize:'18px'}}/>
									</div>
								</Tooltip>
							</td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Add-on Cost</span></td>
							{/* <td className='td_style flex_property'>
								<div className="flex_property">
									{unbilled_usage.estimate_charge.total_add_on_credits ? unbilled_usage.estimate_charge.total_add_on_credits : '0'}
									<Tooltip title={(unbilled_usage.estimate_charge.add_on_credits_past ? unbilled_usage.estimate_charge.add_on_credits_past : '0') + ' (Current) + ' + (unbilled_usage.estimate_charge.add_on_credits_future ? unbilled_usage.estimate_charge.add_on_credits_future : '0') + ' (Future)'} placement='right' mouseEnterDelay={0}>
										<div className="flex_property">
											<i class="fa fa-info-circle ml-2 cp" style={{fontSize:'18px'}}/>
										</div>
									</Tooltip>
								</div>
							</td> */}
							<td className='td_style flex_property'>
								<div className="flex_property">
									{unbilled_usage.estimate_charge.total_add_on_credits ? (unbilled_usage.estimate_charge.total_add_on_credits*cost_per_credit).toFixed(2) : '0'}
									<Tooltip title={(unbilled_usage.estimate_charge.add_on_credits_past ? (unbilled_usage.estimate_charge.add_on_credits_past*cost_per_credit).toFixed(2) : '0') + ' (Current) + ' + (unbilled_usage.estimate_charge.add_on_credits_future ? (unbilled_usage.estimate_charge.add_on_credits_future*cost_per_credit).toFixed(2) : '0') + ' (Future)'} placement='right' mouseEnterDelay={0}>
										<div className="flex_property">
											<i class="fa fa-info-circle ml-2 cp" style={{fontSize:'18px'}}/>
										</div>
									</Tooltip>
								</div>
							</td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Extra Users Cost</span></td>
							{/* <td className='td_style flex_property' style={{flex:'1'}}>
								<span>{unbilled_usage.estimate_charge.extra_usage_credit_cost ? unbilled_usage.estimate_charge.extra_usage_credit_cost : '0'}</span>
								<Tooltip title={(unbilled_usage.estimate_charge.past_overage_credit_cost ? unbilled_usage.estimate_charge.past_overage_credit_cost : '0') + ' (Current) + ' + (unbilled_usage.estimate_charge.future_usage_credit_cost ? unbilled_usage.estimate_charge.future_usage_credit_cost : '0') + ' (Future)'} placement='right' mouseEnterDelay={0}>
									<div className="flex_property">
										<i class="fa fa-info-circle ml-2 cp" style={{fontSize:'18px'}}/>
									</div>
								</Tooltip>
							</td> */}
							<td className='td_style flex_property' style={{flex:'1'}}>
								<span>{unbilled_usage.estimate_charge.extra_usage_credit_cost ? (unbilled_usage.estimate_charge.extra_usage_credit_cost*cost_per_credit).toFixed(2) : '0'}</span>
								<Tooltip title={(unbilled_usage.estimate_charge.past_overage_credit_cost ? (unbilled_usage.estimate_charge.past_overage_credit_cost*cost_per_credit).toFixed(2) : '0') + ' (Current) + ' + (unbilled_usage.estimate_charge.future_usage_credit_cost ? (unbilled_usage.estimate_charge.future_usage_credit_cost*cost_per_credit).toFixed(2) : '0') + ' (Future)'} placement='right' mouseEnterDelay={0}>
									<div className="flex_property">
										<i class="fa fa-info-circle ml-2 cp" style={{fontSize:'18px'}}/>
									</div>
								</Tooltip>
							</td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Render Cost</span></td>
							{/* <td className='td_style flex_property' style={{flex:'1'}}><span>{unbilled_usage.estimate_charge.total_render_credit_cost}</span></td> */}
							<td className='td_style flex_property' style={{flex:'1'}}><span>{(unbilled_usage.estimate_charge.total_render_credit_cost*cost_per_credit).toFixed(2)}</span></td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Total Cost</span></td>
							{/* <td className='td_style flex_property' style={{flex:'1'}}><span>{unbilled_usage.estimate_charge.total_credit_cost}</span></td> */}
							<td className='td_style flex_property' style={{flex:'1'}}><span>{(unbilled_usage.estimate_charge.total_credit_cost*cost_per_credit).toFixed(2)}</span></td>
						</tr>
					</tbody>:<div className='p-3' style={{fontSize:'14px'}}>No usage found </div>
				}
				
			</table>
			<div style={{textAlign:'left', fontSize:'15px', padding:'8px', fontWeight:'900', backgroundColor:'#f2f4f6'}}><div style={{marginLeft:'8px'}}>Current Usage</div></div>
			<table style={{height:'100%'}} className="text-left flex_property table_style">
				<thead style={{width:"100%"}}>
					<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
						<th className='th_style flex_property' style={{flex:'2'}}><span>Email</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Start Date</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>End Date</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>HD Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Full HD Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>4K Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>4K 360 Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>6K 360 Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>8K 360 Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>3D Renders</span></th>
						{/* <th className='th_style flex_property' style={{flex:'1'}}><span>Credits used</span></th> */}
						{/* <th className='th_style flex_property' style={{flex:'1'}}><span>Amount</span></th> */}
					</tr>	
				</thead>
				
				{
					unbilled_usage ?
					<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
					{
						unbilled_usage && unbilled_usage.user_credits_summary && unbilled_usage.user_credits_summary.length ? unbilled_usage.user_credits_summary.map((user_usage, idx) => (
							user_usage.internal ? '':
							<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
								<td className='td_style flex_property' style={{flex:'2'}}><span>{user_usage.original_email}</span></td>
								<td className='td_style flex_property' style={{flex:'1'}}><Moment format="DD/MM/YYYY">{user_usage.active_entries && user_usage.active_entries.length && user_usage.active_entries[0].usage_start_date}</Moment></td>
								<td className='td_style flex_property' style={{flex:'1'}}><Moment format="DD/MM/YYYY">{user_usage.active_entries && user_usage.active_entries.length && user_usage.active_entries[0].usage_end_date}</Moment></td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render && user_usage.render_usage.render.HD ? user_usage.render_usage.render.HD : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render && user_usage.render_usage.render.Full_HD ? user_usage.render_usage.render.Full_HD:  '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render && user_usage.render_usage.render['4K'] ? user_usage.render_usage.render['4K'] : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render && user_usage.render_usage.render['C_4K'] ? user_usage.render_usage.render['C_4K'] : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render && user_usage.render_usage.render['C_6K'] ? user_usage.render_usage.render['C_6K'] : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render && user_usage.render_usage.render['C_8K'] ? user_usage.render_usage.render['C_8K'] : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.bake && user_usage.render_usage.bake['HD'] ? user_usage.render_usage.bake['HD'] : '0'}</td>
								{/* <td className='td_style flex_property' style={{flex:'1'}}>{user_usage.role_credits_used}</td> */}
								{/* <td className='td_style flex_property' style={{flex:'1'}}>{user_usage.extra_usage_currency_cost}</td> */}
							</tr>
						)):""
					}
					{
						unbilled_usage && unbilled_usage.user_credits_summary && unbilled_usage.user_credits_summary.length && unbilled_usage.user_credits_summary.filter((user_usage, idx) => !user_usage.internal).length ? '' : 'No overage charges' 
					}
					</tbody>:<div className='p-3' style={{fontSize:'14px'}}>No usage found </div>
				}
			</table>
		</>
	)

	const Dues_table = () => (
		<table style={{height:'100%'}} className="text-left flex_property table_style">
			<thead style={{width:"100%"}}>
				<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
					<th className='th_style flex_property' style={{flex:'1'}}><span>Due Date</span></th>
					{/* <th className='th_style flex_property' style={{flex:'1'}}><span>Status</span></th> */}
					<th className='th_style flex_property' style={{flex:'1'}}><span>Price</span></th>
					<th className='th_style flex_property' style={{flex:'1'}}><span>Tax</span></th>
					<th className='th_style flex_property' style={{flex:'1'}}><span>Total</span></th>
					<th className='th_style flex_property' style={{flex:'1'}}><span>Payment Link</span></th>
				</tr>
			</thead>
			<tbody style={{width:"100%"}}>
			{
				dues && dues.length ? dues.map((single_due, idx) => (
					<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
						<td className='td_style flex_property' style={{flex:'1'}}><span>{single_due.due_date ? new Date(single_due.due_date).toString().substring(4,15) : ''}</span></td>
						{/* <td className='td_style flex_property' style={{flex:'1'}}><span>{single_due.status}</span></td> */}
						<td className='td_style flex_property' style={{flex:'1'}}><span>{single_due.price_after_discount_before_tax}</span></td>
						<td className='td_style flex_property' style={{flex:'1'}}><span>{single_due.tax}</span></td>
						<td className='td_style flex_property' style={{flex:'1'}}><span>{single_due.total}</span></td>
						<td className='td_style flex_property' style={{flex:'1'}}><div style={{marginBottom:'2px'}}><Button className='blue_button flex_center' onClick={() => payment_function(single_due)} style={{fontSize:'14px', minHeight:'28px', height:'28px'}}>Pay</Button></div></td>
					</tr>
				)):''
			}
			</tbody>
		</table>
	)

	return (
		<Main_Grid active_tab="billing" active_division_id='usage' user={user} store_details={store_details} get_store_details={get_store_details}>
		{
			store_details && !store_details.premium_customer ? 
			// <ShowInfurniaPlans 
			// 	payment_function={payment_function} 
			// 	set_page_loader = {set_page_loader} 
			// 	set_first_order = {set_first_order}
			// 	plan_selected = {plan_selected}
			// 	set_plan_selected = {set_plan_selected}	
			// 	store_details={store_details}
			// 	start_pinging_status = {start_pinging_status}
			// 	set_show_payment_confirmation_modal = {set_show_payment_confirmation_modal}
			// 	set_new_window={set_new_window}
			// />
			<div style={{display:'flex', justifyContent:'center', alignItems:'center', color:'red', zIndex:1000, height:'calc(100vh - 64px)'}}>
				<div>
					<h3 className='mb-4' style={{}}>{"You do not have an active Infurnia subscription."}</h3>
					<h4 style={{color: COLORS.gray4}}>Contact your Customer Success Executive to get your subscription active</h4>
				</div>
			</div>
			:
			<>
				<Row style={{padding:'10px 10px', color:'#22272e'}}>
					<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'14px'}}>
							{tabs==1? 'Viewing Unpaid Orders' :(tabs == 2 ? 'Viewing Billing History' : 'Viewing Unbilled Usage')}
					</Col>

					<Col xs={6} style={{textAlign:'right'}}>
					{
						tabs != 1 ? 
						<CSVLink data = {usage_csv_data} filename={"Usage_CSV"} style={{alignItems:'center'}} >
							<button onClick = {() => {}} style={{all: 'unset', cursor:'pointer'}}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center', backgroundColor:'#007ef4', boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',borderRadius: '3px',height:'32px',border:'0px', color:'white', padding:'0px 24px', fontSize:'14px'}}>Download CSV</span></button>
						</CSVLink>:""
					}
					</Col>
				</Row>

				<Row style={{height:'calc(100% - 50px)'}}>
					<Col style={{height:'100%'}}>
						<Card style={{borderRadius: 0, height:'100%', overflowY:'scroll'}}>
							<Nav 
								style={{display:'flex', alignItems:'center'}}
								className="flex-md-row"
								id="tabs-icons-text" 
								pills
								role="tablist"
							>
								{
									dues && dues.length ?
									<NavItem>
										<NavLink
											aria-selected={tabs === 1}
											style = {{marginLeft:'10px'}}
											className={classnames("mb-sm-3 mb-md-0", {
												active: tabs === 1
											})}
											onClick={e => toggleNavs(e, 1)}
											href="#pablo"
											role="tab"
										>
											<span style={{fontSize:'14px'}}>{'Unpaid Orders'}</span>
										</NavLink>
									</NavItem>:""
								}
								<NavItem>
									<NavLink
										aria-selected={tabs === 2}
										style = {{marginLeft:dues && dues.length ? '' : '10px'}}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 2
										})}
										onClick={e => toggleNavs(e, 2)}
										href="#pablo"
										role="tab"
									>
										<span style={{fontSize:'14px'}}>{'Billing History'}</span>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										aria-selected={tabs === 3}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 3
										})}
										onClick={e => toggleNavs(e, 3)}
										href="#pablo"
										role="tab"
									>
										<span style={{fontSize:'14px'}}>{'Current Usage'}</span>
									</NavLink>
								</NavItem>
							</Nav>

							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={'tabs1'} style={{fontSize:'12px'}}>
								{
									dues && dues.length ? <Dues_table/> : <div style={{fontSize:"18px"}}>There are no dues</div>
								}
								</TabPane>
							</TabContent>

							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={'tabs2'} style={{fontSize:'12px'}}>
								{/* <InputGroup className="input-group-alternative" style={{border:'1px solid #5E72E4', width:'250px'}}>
									<InputGroupAddon addonType="prepend" >
									<InputGroupText>
										<i className="ni ni-calendar-grid-58" style={{color:'#5E72E4'}} />
									</InputGroupText>
									</InputGroupAddon> */}
								{/* </InputGroup> */}
									<Billing_history_table/>
								</TabPane>
							</TabContent>

							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={'tabs3'} style={{fontSize:'12px'}}>
									{/* <div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{display_custom_roomtypes.length ? (<Unbilled_usage_table display_roomtypes={display_custom_roomtypes} tab={custom_mode}/>) : (<Filler initialized={initialized_custom}/>)}</div> */}
									<Unbilled_usage_table />
								</TabPane>
							</TabContent>
						</Card>
					</Col>	
				</Row>
			</>
		}
		<PaymentConfirmationModal open={show_payment_confirmation_modal} handleClose={() => {set_show_payment_confirmation_modal(false); set_order_status('')}} order_status={order_status} first_order={first_order} plan_selected = {plan_selected}/>
		</Main_Grid>
	);
}	


export default Billing_usage;

