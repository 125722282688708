import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, Dropdown, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, ModalBody, } from "reactstrap";
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import LazyLoad from 'react-lazyload';
import split_cat_type from '../../utils/split_cat_type';
import { confirmAlert } from 'react-confirm-alert';
import classnames from "classnames";
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { UserContext } from '../../components/Contexts/UserContext';
import { ITD, ITH, ITable, ITableBody, ITableHeader, ITableRow } from '../../components/ITable';

const styles_modals= {
	input_styles:{
		padding: 0,
		// height: '28px',
		fontSize: '12px',
		textAlign: 'left',
		borderRadius:'0',
		width:'100%'
	},

	input_styles_none:{
		padding: 0,
		// height: '24px',
		fontSize: '12px',
		textAlign: 'left',
		borderRadius:'0',
		width:'100%',
		borderRadius:'2px',
		border:'none',
		boxShadow:'none',
		// height:'auto'
		// fontSize:'14px'
	}

}

const ServiceChargeSingleRow = ({services_list, charge_types_list, units_list, index, service, set_service_charges_list, service_charges_list, calculate_total, set_table_key, total_product_cost, quantity_list}) => {
	const [selected_charge_type, set_selected_charge_type] = useState('');
	const [selected_service, set_selected_service] = useState('');
	const [selected_unit, set_selected_unit] = useState('');
	const [quantity, set_quantity] = useState(0);
	const [rate_per_unit, set_rate_per_unit] = useState(0);
	const [final_charge, set_final_charge] = useState(0);
	const [tax, set_tax] = useState(0);
	const [custom_service_name, set_custom_service_name] = useState('');
	const [dropdownOpen, set_dropdownOpen] = useState(false);
	const [quantity_option, set_quantity_option] = useState('total_product_cost');

	const styles_modals= {
	
		input_styles_none_2:{
			padding: '0px 8px',
			// height: '24px',
			fontSize: '12px',
			textAlign: 'left',
			borderRadius:'0',
			width:'100%',
			borderRadius:'2px',
			border:'none',
			boxShadow:'none',
			// height:'auto'
			// fontSize:'14px'
		}
	
	}

	const toggle = () => {set_dropdownOpen(!dropdownOpen)}

	const service_name_by_id = (service_id) => {
		if(services_list && services_list.length){
			let s = services_list.find(x => x.id == service_id)
			// console.log(services_list, s, service_id)
			// if(s.length){
			return s && s.name ? s.name : '';
			// }
		}
		return '';
	}

	const delete_row = () => {
		let t = JSON.parse(JSON.stringify(service_charges_list))
		t.splice(index, 1)
		set_service_charges_list(t)
		calculate_total()
		set_table_key(Date.now() + Math.random())
	}

	const duplicate_row = () => {
		let t = JSON.parse(JSON.stringify(service_charges_list))
		let duplicate_charge = JSON.parse(JSON.stringify(service))
		t.splice(index, 0, duplicate_charge)
		set_service_charges_list(t)
		calculate_total()
		set_table_key(Date.now() + Math.random())
	}

	// useEffect(() => {
		
	// }, [selected_charge_type]);


	useEffect(() => {
		if(service.service){
			set_selected_service(service.service)
		}else if(services_list && services_list.length){
			set_selected_service(services_list[0].id)
		}

		if(service.charge_type){
			// if(service.unit){
			// 	set_selected_unit(service.unit)
			// }
			set_selected_charge_type(service.charge_type)
		}else if(charge_types_list && charge_types_list.length){
			set_selected_charge_type(charge_types_list[0].id)
		}

		// if(service.unit){
		// 	set_selected_unit(service.unit)
		// }else if(units_list && units_list.length){
		// 	set_selected_unit(units_list[0].id)
		// }

		if(service.rate_per_unit){
			set_rate_per_unit(service.rate_per_unit)
		}

		if(service.quantity){
			set_quantity(service.quantity)
		}

		if(service.final_charge){
			set_final_charge(service.final_charge)
		}

		if(service.custom_service_name){
			set_custom_service_name(service.custom_service_name)
		}
		if(service.tax){
			set_tax(service.tax)
		}
		if(service.quantity_type){
			set_quantity_option(service.quantity_type)
		}else if(quantity_list && quantity_list.length){
			set_quantity_option(quantity_list[0].id)
		}

		// console.log("inside constructor")

	}, []);

	useEffect(() => {
		if(selected_charge_type){
			if(service.charge_type == 'absolute' && selected_charge_type != 'absolute'){
				set_final_charge(rate_per_unit * quantity)
			}
            if(service.charge_type == 'percentage' && selected_charge_type != 'percentage'){
				set_quantity(0)
			}
			if(selected_charge_type == 'percentage'){
				set_quantity(total_product_cost)
			}
			if(selected_charge_type == 'area_basis' && !['custom', 'total_countertop_area'].includes(quantity_option)){
				set_quantity_option('custom')
			}
			// service.service = selected_service;
			service.charge_type = selected_charge_type;
			let filtered_unit_list = units_list.filter(x => x.charge_type_id === selected_charge_type);

			let unit = filtered_unit_list.find(x => x.id == service.unit)
			if(unit){
				set_selected_unit(service.unit)
			}else{
				set_selected_unit(filtered_unit_list.length ? filtered_unit_list[0].id : '')
			}

			// service.unit = selected_unit;
			
			// service.service_charge = service_charge;
			console.log(service, "service1")
			set_service_charges_list(service_charges_list)
		}		
	}, [selected_charge_type]);

	useEffect(() => {
		// if(selected_charge_type == 'percentage' && quantity_option){
		if(quantity_option){
			service.quantity_type = quantity_option
			// set_quantity(quantity_cost && quantity_option && quantity_cost[quantity_option])
		}

		if(selected_charge_type == 'area_basis' && quantity_option !== 'custom'){
			set_quantity(0)
		}
	}, [quantity_option]);

	useEffect(() => {
		if(selected_service){
			service.service = selected_service;
			// service.charge_type = selected_charge_type;
			// service.unit = selected_unit;
			// service.rate_per_unit = rate_per_unit;
			// service.quantity = quantity;
			// service.final_charge = final_charge;
			console.log(service, "service2")
			// set_service_charges_list(service_charges_list)
		}
		if(selected_unit){
			// service.service = selected_service;
			// service.charge_type = selected_charge_type;
			service.unit = selected_unit;
			// service.rate_per_unit = rate_per_unit;
			// service.quantity = quantity;
			// service.final_charge = final_charge;
			console.log(service, "service2.1")
			// set_service_charges_list(service_charges_list)
		}

		if(selected_service || selected_unit){
			set_service_charges_list(service_charges_list)
		}
		
	}, [selected_service, selected_unit]);


	useEffect(() => {
		// if(selected_service && selected_charge_type && selected_unit){
			// service.service = selected_service;
			// service.charge_type = selected_charge_type;
			// service.unit = selected_unit;
			service.rate_per_unit = rate_per_unit;
			service.quantity = quantity;
			if(service.charge_type != 'absolute' && service.charge_type != 'percentage'){
				set_final_charge(rate_per_unit * quantity)
			}

            if(service.charge_type == 'percentage'){
				set_final_charge((rate_per_unit * quantity)/100)
			}
			// service.service_charge = rate_per_unit * quantity;
			console.log(service, "service3")
			set_service_charges_list(service_charges_list)
		// }
		
	}, [rate_per_unit, quantity]);

	useEffect(() => {
		// if(selected_service && selected_charge_type && selected_unit){
			// service.service = selected_service;
			// service.charge_type = selected_charge_type;
			// service.unit = selected_unit;
			service.tax = tax;
		// }
		
	}, [tax]);


	useEffect(() => {
		// if(selected_service && selected_charge_type && selected_unit){
			// service.service = selected_service;
			// service.charge_type = selected_charge_type;
			// service.unit = selected_unit;
			// service.rate_per_unit = rate_per_unit;
			// service.quantity = quantity;
			service.final_charge = final_charge;
			console.log(service, "service4")
			set_service_charges_list(service_charges_list)
			calculate_total()
		// }
		
	}, [final_charge]);

	useEffect(() => {
		// if(selected_service && selected_charge_type && selected_unit){
			// service.service = selected_service;
			// service.charge_type = selected_charge_type;
			// service.unit = selected_unit;
			// service.rate_per_unit = rate_per_unit;
			// service.quantity = quantity;
			service.custom_service_name = custom_service_name;
			console.log(service, "service5")
			set_service_charges_list(service_charges_list)
		// }
		
	}, [custom_service_name]);
	

	return(
		// <tr className='tr_style'>
		// 	<td className='td_style flex_property' style={{flex:'1'}}>{index + 1}</td>
		// 	<td className='td_style flex_property' style={{flex:'5', justifyContent:'space-between', overflow:'visible', cursor:'pointer'}} onClick={toggle}>
		// 		<div className='flex_property' style={{width:'100%'}}>
		// 			<div>
		// 				{service_name_by_id(selected_service)}
		// 			</div>
		// 			{selected_service === 'custom' ? <Input type='text' value={custom_service_name} style={{...styles_modals.input_styles, margin:'0px 8px', padding:'0px 8px'}} onClick={(e) => e.stopPropagation()} onChange={(e) => {set_custom_service_name(e.target.value)}}/> : ''}
		// 		</div>
		// 		<Dropdown isOpen={dropdownOpen} toggle={(e) => {toggle(); e.stopPropagation()}} style={{}}>
		// 			<DropdownToggle style={{ all: 'unset', padding:'4px', cursor:'pointer'}}>
		// 				<i className='fa fa-caret-down'></i>
		// 			</DropdownToggle>
		// 			<DropdownMenu right style={{padding:'4px'}}>
		// 				{
		// 					services_list.map((service_item, idx) => (
		// 						<DropdownItem key={idx} onClick={() => {set_selected_service(service_item.id)}}>{service_item.name}</DropdownItem>
		// 					))
		// 				}
		// 			</DropdownMenu>
		// 		</Dropdown>
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'3'}}>
		// 		<Input type='select' value={selected_charge_type} style={{...styles_modals.input_styles_none}} onChange={(e) => {set_selected_charge_type(e.target.value)}}>
		// 			{
		// 				charge_types_list && charge_types_list.length ? charge_types_list.map((charge_type, idx_ct) => (
		// 					<option key={idx_ct} value={charge_type.id}>{charge_type.name}</option>
		// 				)) : ''
		// 			}
		// 		</Input>
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'3'}}>
		// 		{
		// 			selected_charge_type !== 'absolute' && selected_charge_type !== 'percentage' ?
		// 				<Input type='select' value={selected_unit} style={{...styles_modals.input_styles_none}} onChange={(e) => {set_selected_unit(e.target.value)}}>
		// 					{
		// 						units_list && units_list.length ? units_list.filter(x => (x.charge_type_id == selected_charge_type)).map((unit, idx_u) => (
		// 							<option key={idx_u} value={unit.id}>{unit.name}</option>
		// 						)) : ''
		// 					}
		// 				</Input>
						
		// 			:''
		// 		}
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'3', position:'relative'}}>
		// 		{selected_charge_type !== 'absolute' ? 
		// 			<React.Fragment>
        //                 <Input type='number' disabled={selected_charge_type === 'absolute'} min={0} value={rate_per_unit} style={{...styles_modals.input_styles_none, paddingRight:'24px'}} onChange={(e) => {set_rate_per_unit(Number(e.target.value ? e.target.value : 0))}}/>
        //                 {selected_charge_type === 'percentage' ? <span className="percentage-symbol">%</span>:"" }
        //             </React.Fragment> : ''}
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'2'}}>
		// 		{selected_charge_type !== 'absolute' && selected_charge_type !== 'percentage' ? <Input type='number' disabled={selected_charge_type === 'absolute'} min={0} value={quantity} style={{ ...styles_modals.input_styles_none }} onChange={(e) => { set_quantity(Number(e.target.value ? e.target.value : 0)) }} /> : selected_charge_type == 'percentage' ?
		// 			<Input type='select' value={quantity_option} style={{ ...styles_modals.input_styles_none, fontSize: '10px' }} onChange={(e) => { set_quantity_option(e.target.value) }}>
		// 				{
		// 					quantity_list && quantity_list.length ? quantity_list.map((quantity, idx_q) => (
		// 						<option key={idx_q} value={quantity.id}>{quantity.name}</option>
		// 					)) : ''
		// 				}
		// 			</Input>
		// 			: ''}
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'3'}}>
		// 		{selected_charge_type !== 'absolute' ? <Input type='number' min={0} value={rate_per_unit * quantity} style={{...styles_modals.input_styles_none, paddingLeft:'8px'}} readOnly/> : <Input type='number' min={0} value={final_charge} onChange={(e) => {set_final_charge(Number(e.target.value ? e.target.value : ''))}} style={{...styles_modals.input_styles_none}}/> }
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'3'}}>
		// 		{<Input type='number' min={0} value={tax} onChange={(e) => {set_tax(Number(e.target.value ? e.target.value : ''))}} style={{...styles_modals.input_styles_none}}/> }
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'1', justifyContent:'center', fontWeight:700, fontSize:16, overflow:'visible'}}>
		// 		<UncontrolledDropdown style={{ cursor:'pointer'}}>
		// 			<DropdownToggle style={{all:'unset'}}>
		// 				...
		// 			</DropdownToggle>
		// 			<DropdownMenu right style={{padding:'4px', right:0}}>
		// 				<DropdownItem onClick={duplicate_row}>Clone Row</DropdownItem>
		// 				<DropdownItem onClick={delete_row}>Delete Row</DropdownItem>
		// 			</DropdownMenu>
		// 		</UncontrolledDropdown>
		// 	</td>
		// </tr>
		<ITableRow>
			<ITD>{index + 1}</ITD>
			<ITD style={{justifyContent:'space-between', overflow:'visible', cursor:'pointer'}} onClick={toggle}>
				<div className='flex_property'>
					<div className='flex_property' style={{flex: 1, minWidth: '0px'}}>
						<div onClick={toggle} style={{flex: 1}}>
							{service_name_by_id(selected_service)}
						</div>
						{selected_service === 'custom' ? <Input type='text' value={custom_service_name} style={{...styles_modals.input_styles, margin:'0px 8px', padding:'0px 8px'}} onClick={(e) => e.stopPropagation()} onChange={(e) => {set_custom_service_name(e.target.value)}}/> : ''}
					</div>
					<Dropdown isOpen={dropdownOpen} toggle={(e) => {toggle(); e.stopPropagation()}} style={{position:'unset'}}>
						<DropdownToggle style={{all:'unset', padding:'4px', cursor:'pointer'}}>
							<i className='fa fa-caret-down'></i>
						</DropdownToggle>
						<DropdownMenu right style={{padding:'4px'}}>
							{
								services_list.map((service_item, idx) => (
									<DropdownItem key={idx} onClick={() => {set_selected_service(service_item.id)}}>{service_item.name}</DropdownItem>
								))
							}
						</DropdownMenu>
					</Dropdown>
				</div>
			</ITD>
			<ITD>
				<Input type='select' value={selected_charge_type} style={{...styles_modals.input_styles_none_2}} onChange={(e) => {set_selected_charge_type(e.target.value)}}>
					{
						charge_types_list && charge_types_list.length ? charge_types_list.map((charge_type, idx_ct) => (
							<option key={idx_ct} value={charge_type.id}>{charge_type.name}</option>
						)) : ''
					}
				</Input>
			</ITD>
			<ITD>
				{
					selected_charge_type !== 'absolute' && selected_charge_type !== 'percentage' ?
						<Input type='select' value={selected_unit} style={{...styles_modals.input_styles_none_2}} onChange={(e) => {set_selected_unit(e.target.value)}}>
							{
								units_list && units_list.length ? units_list.filter(x => (x.charge_type_id == selected_charge_type)).map((unit, idx_u) => (
									<option key={idx_u} value={unit.id}>{unit.name}</option>
								)) : ''
							}
						</Input>
						
					:''
				}
			</ITD>
			<ITD style={{position:'relative'}}>
				{selected_charge_type !== 'absolute' ? 
					<React.Fragment>
						<Input type='number' disabled={selected_charge_type === 'absolute'} min={0} value={String(rate_per_unit)} style={{...styles_modals.input_styles_none_2, paddingRight:'24px'}} onChange={(e) => {set_rate_per_unit(Number(e.target.value ? e.target.value : 0))}}/>
						{selected_charge_type === 'percentage'?<span className="percentage-symbol">%</span> :''}
					</React.Fragment>
					: ''}
			</ITD>
			<ITD>
				{
					selected_charge_type === 'area_basis'?
						<div className='flex_property'>
							<Input type='select' disabled={selected_charge_type === 'absolute'} value={String(quantity_option)} style={{ ...styles_modals.input_styles_none_2, fontSize: '10px' }} onChange={(e) => { set_quantity_option(e.target.value) }}>
								{/* {
									quantity_list && quantity_list.length ? quantity_list.map((quantity, idx_q) => (
										<option key={idx_q} value={quantity.id}>{quantity.name}</option>
									)) : ''
								} */}
								<option value={'custom'}>{'Custom'}</option>
								<option value={'total_countertop_area'}>{'Countertop Area'}</option>
							</Input>
							{quantity_option === 'custom' ? <Input type='number' disabled={selected_charge_type === 'absolute' || quantity_option === 'countertop_area'} min={0} value={String(quantity)} style={{ ...styles_modals.input_styles_none_2 }} onChange={(e) => { set_quantity(Number(e.target.value ? e.target.value : 0)) }} /> : ''}
						</div>
						: selected_charge_type == 'percentage' ?
							<Input type='select' value={quantity_option} style={{ ...styles_modals.input_styles_none_2, fontSize: '10px' }} onChange={(e) => { set_quantity_option(e.target.value) }}>
								{
									quantity_list && quantity_list.length ? quantity_list.map((quantity, idx_q) => (
										<option key={idx_q} value={quantity.id}>{quantity.name}</option>
									)) : ''
								}
							</Input>
							: selected_charge_type !== 'absolute' ? 
							<Input type='number' disabled={selected_charge_type === 'absolute' || quantity_option === 'countertop_area'} min={0} value={String(quantity)} style={{ ...styles_modals.input_styles_none_2 }} onChange={(e) => { set_quantity(Number(e.target.value ? e.target.value : 0)) }} />
							: ''
				}
			</ITD>
			<ITD>
				{selected_charge_type !== 'absolute' ? <Input type='number' min={0} value={final_charge} style={{...styles_modals.input_styles_none_2}} readOnly/> : <Input type='number' min={0} value={String(final_charge)} onChange={(e) => {set_final_charge(Number(e.target.value ? e.target.value : ''))}} style={{...styles_modals.input_styles_none_2}}/> }
			</ITD>
			<ITD>
				{<Input type='number' min={0} value={String(tax)} onChange={(e) => {set_tax(Number(e.target.value ? e.target.value : ''))}} style={{...styles_modals.input_styles_none_2}}/> }
			</ITD>
			<ITD style={{ fontWeight:700, fontSize:16, textAlign: 'center'}}>
				<UncontrolledDropdown style={{position:'unset'}}>
					<DropdownToggle style={{background:'unset', boxShadow: 'unset', border: 'unset', position: 'unset', cursor: 'pointer', padding: 'unset', color: 'black'}}>
						...
					</DropdownToggle>
					<DropdownMenu style={{padding:'4px'}}>
						<DropdownItem onClick={duplicate_row}>Clone Row</DropdownItem>
						<DropdownItem onClick={delete_row}>Delete Row</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			</ITD>
		</ITableRow>
	)
}

const Service_Charge = ({store_details, get_store_details, set_page_loader}) => {

	// const [service_charge_json, set_service_charge_json] = useState([]);
	const [services_list, set_services_list] = useState([
        { id: 'civil_services', name: 'Civil Services' },
        { id: 'countertop_services', name: 'Countertop Services' },
        { id: 'electrical_and_lighting services', name: 'Electrical and Lighting Services' },
        { id: 'false_ceiling_services', name: 'False Ceiling Services' },
        { id: 'gas_pipeline_services', name: 'Gas Pipeline Services' },
        { id: 'home_automation_services', name: 'Home Automation Services' },
        { id: 'painting_services', name: 'Painting Services' },
        { id: 'plumbing_services', name: 'Plumbing Services' },
        { id: 'renovation_services', name: 'Renovation Services' },
        { id: 'tiling_services', name: 'Tiling Services' },
        { id: 'transportation_services', name: 'Transportation Services' },
        { id: 'custom', name: 'Custom' }
      ]);
	const [charge_types_list, set_charge_types_list] = useState([
        { id: 'length_basis', name: 'Length Basis' },
        { id: 'area_basis', name: 'Area Basis' },
        { id: 'volume_basis', name: 'Volume Basis' },
        { id: 'per_unit_basis', name: 'Per Unit Basis' },
        { id: 'absolute', name: 'Absolute' },
        { id: 'percentage', name: 'Percentage' }
      ]);
	const [units_list, set_units_list] = useState([
		{ charge_type_id: 'length_basis', id: 'mm', name: 'mm' },
		{ charge_type_id: 'length_basis', id: 'feet', name: 'feet' },
		{ charge_type_id: 'length_basis', id: 'metre', name: 'metre' },
		{ charge_type_id: 'area_basis', id: 'mm2', name: 'mm²' },
		{ charge_type_id: 'area_basis', id: 'feet2', name: 'feet²' },
		{ charge_type_id: 'area_basis', id: 'metre2', name: 'metre²' },
		{ charge_type_id: 'volume_basis', id: 'mm3', name: 'mm³' },
		{ charge_type_id: 'volume_basis', id: 'feet3', name: 'feet³' },
		{ charge_type_id: 'volume_basis', id: 'metre3', name: 'metre³' },
		{ charge_type_id: 'per_unit_basis', id: 'per_unit', name: 'Per Unit' }
	]);
	const [quantity_list, set_quantity_list] = useState([
		{
			id: 'total_product_cost',
			name: 'Total Product Cost',
		},
		{
			id: 'woodwork_price',
			name: 'Woodwork Price'
		},
		{
			id: 'discounted_woodwork_price',
			name: 'Discounted Woodwork Price'
		},
	]);
	const [service_charges_list, set_service_charges_list] = useState([]);
	const [total, set_total] = useState(0);
	const [table_key, set_table_key] = useState('init_table_key');
    const [total_product_cost, set_total_product_cost] = useState(0);
    const alert = useAlert()

	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)



	const onclick_handle_close = () => {
		set_service_charges_list([])
		set_charge_types_list([])
		set_units_list([])
		set_services_list([])
		set_total(0)
		set_table_key('init_table_key')
		// set_service_charge_json([])
	}

	const calculate_total = () => {
		let total_temp = 0;
		let t = service_charges_list && service_charges_list.length ? service_charges_list.map(x => {total_temp = total_temp + x.final_charge}) : ''
		console.log(total_temp, "total")
		set_total(total_temp)
	}

	const add_row = () => { 
		// service_charges_list.push({})
		let t = [...service_charges_list]
		t.push({})
		set_service_charges_list(t)
		// console.log(service_charges_list)
	}

	const update_services_list = async() => {
		console.log("Updated services", service_charges_list);
		set_page_loader({
			show: true,
			text: 'Please wait'
		});

        try{
            var updates = JSON.stringify({service_charges: service_charges_list})
			let body = {updates:updates}
            // let resp = await general_fetch({url:'store/update_info', body})
			if (org_bu_view == 'bu') {
				body.business_unit_id = user.current_business_unit_id
				let resp = await general_fetch({ url: 'business_unit/update_info', body })
			} else {
				let resp = await general_fetch({ url: 'store/update_info', body })
			}
            get_store_details()
            alert.success('Service charges updated successfully')
        }catch(err){
            console.log(err)
        }
        
        set_page_loader({
            show: false,
            text: 'Please wait'
        });	
	}

    useEffect(() => {
        if(store_details && store_details.service_charges){
			if(store_details.service_charges.length) set_service_charges_list(JSON.parse(JSON.stringify(store_details.service_charges)))
            // if(store_details.service_charges.total_product_cost) set_total_product_cost(store_details.service_charges.total_product_cost)
			calculate_total()
        }
    }, [store_details]);

	// useEffect(() => {
	// 	let total_temp = 0;
	// 	service_charges_list && service_charges_list.length ? service_charges_list.map(x => {total_temp = total_temp + x.service_charge}) : ''
	// 	console.log(total_temp, "total")
	// 	set_total(total_temp)
	// }, [service_charges_list]);

	return(
		<>
            <ModalBody style={{ position:'relative', textAlign: 'left', fontSize: '14px'}}>
				{/* <table  style={{maxHeight:'calc(100% - 52px)', overflow:'visible', marginBottom:'12px', position:'unset'}} className="text-left flex_property table_style">
					<thead style={{width:"100%"}}>
						<tr className='tr_style'>
							<th className='th_style flex_property' style={{flex:'1'}}>S.No</th>
							<th className='th_style flex_property' style={{flex:'5'}}>Service</th>
							<th className='th_style flex_property' style={{flex:'3'}}>Charge Type</th>
							<th className='th_style flex_property' style={{flex:'3'}}>Measurement Unit</th>
							<th className='th_style flex_property' style={{flex:'3'}}>Rate</th>
							<th className='th_style flex_property' style={{flex:'2'}}>Quantity</th>
							<th className='th_style flex_property' style={{flex:'3'}}>Service Charges</th>
							<th className='th_style flex_property' style={{flex:'3'}}>Tax%</th>
							<th className='th_style flex_property' style={{flex:'1'}}>Options</th>
						</tr>
					</thead>
					<tbody style={{overflow:"visible",width:"100%", height:"calc(100% - 40px)", position:'unset'}} key={table_key}>
						{
							service_charges_list && service_charges_list.length ? service_charges_list.map((single_charge, idx) => (
								<ServiceChargeSingleRow key={idx} services_list={services_list} charge_types_list={charge_types_list} units_list={units_list} index={idx} service={single_charge} set_service_charges_list={set_service_charges_list} service_charges_list={service_charges_list} calculate_total={calculate_total} set_table_key={set_table_key} total_product_cost={total_product_cost} quantity_list={quantity_list}/>
							))
							:''
						}
					</tbody>
				</table> */}
				<ITable rowSeparated={true} striped={true} style_container={{maxHeight:'calc(600px - 100px)', overflow:'auto', marginBottom:'12px', background: 'white'}} className="">
					<colgroup>
						<col style={{ width: "5%" }}></col>
						<col style={{ width: "20%" }}></col>
						<col style={{ width: "10%" }}></col>
						<col style={{ width: "15%" }}></col>
						<col style={{ width: "10%" }}></col>
						<col style={{ width: "15%" }}></col>
						<col style={{ width: "10%" }}></col>
						<col style={{ width: "10%" }}></col>
						<col style={{ width: "5%" }}></col>
					</colgroup>
					<ITableHeader style={{background: 'white', position: 'sticky', top: '0px', zIndex:1}}>
						<ITH>S.No</ITH>
						<ITH>Service</ITH>
						<ITH>Charge Type</ITH>
						<ITH>Measurement Unit</ITH>
						<ITH>Rate</ITH>
						<ITH>Quantity</ITH>
						<ITH>Service Charges</ITH>
						<ITH>Tax%</ITH>
						<ITH>Options</ITH>
					</ITableHeader>
					<ITableBody key={table_key}>
						{
							service_charges_list && service_charges_list.length ? service_charges_list.map((single_charge, idx) => (
								<ServiceChargeSingleRow key={idx} services_list={services_list} charge_types_list={charge_types_list} units_list={units_list} index={idx} service={single_charge} set_service_charges_list={set_service_charges_list} service_charges_list={service_charges_list} calculate_total={calculate_total} set_table_key={set_table_key} total_product_cost={total_product_cost} quantity_list={quantity_list}/>
							))
							:''
						}
					</ITableBody>
				</ITable>
				<div style={{height: '40px'}} className='flex_property justify-content-between'>
					<div onClick={add_row} className='flex_center ml-4' style={{color:'#2688F7', fontWeight:'700', fontSize:'14px', cursor:'pointer'}}>
						+ Add Row
					</div>
					{/* <div className='flex_property' style={{fontSize: '12px', border: '1px solid #c5c7cf', width:'272px'}}>
						<div style={{flex:2, textAlign:'center', fontWeight:700, borderRight: '1px solid #c5c7cf', padding:'8px'}}>Total: </div>
						<div style={{flex: 4, fontWeight:700, padding:'8px'}}>{total}</div>
					</div> */}
				</div>
			</ModalBody>
            <div className='flex_property' style={{width:'100%', justifyContent:'flex-end'}}>
                <Button className='blue_button' type="button" style={{marginRight:'20px'}} onClick={update_services_list}>Update</Button>
            </div>
        </>
	)
}

export default Service_Charge