import React, { useState , useEffect, isValidElement, useRef, useContext } from 'react';
import {Modal, FormGroup, Button, Form, Input, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, Fade, } from "reactstrap";
import general_fetch from '../../../utils/fetch';
import name_sort from '../../../utils/name_sort';
import custom_sort from '../../../utils/custom_sort';
import GroupInventoryCard from './GroupInventoryCard';
import Group_ListView from './Group_ListView';
import { useAlert } from 'react-alert'
import './sku_groups.css'
import LazyLoad from 'react-lazyload';
import classnames from "classnames";
import Multiselect from '../../../components/Multiselect';
import { act } from 'react-dom/test-utils';
import * as Sentry from "@sentry/react";
import { OrgBuContext } from '../../../components/Contexts/OrgBuContext';
import { UserContext } from '../../../components/Contexts/UserContext';

const ReferenceSkuModal = ({open, handleClose, sku_name, sku_category_type, all_division_tree_data, reference_sku_id, bulk_sku_array, bulk_sku_array_idx, set_bulk_sku_array, set_page_loader}) => {
    const [sku_categories, set_sku_categories] = useState([]);
    const [sku_sub_categories, set_sku_sub_categories] = useState([]);
    const [sku_groups, set_sku_groups] = useState([]);
    const [skus, set_skus] = useState([]);
    const [display_sku_categories, set_display_sku_categories] = useState([]);
    const [display_sku_sub_categories, set_display_sku_sub_categories] = useState([]);
    const [display_sku_groups, set_display_sku_groups] = useState([]);
    const [display_skus, set_display_skus] = useState([]);
    const [selected_sku_category_id, set_selected_sku_category_id] = useState('')
    const [selected_sku_sub_category_id, set_selected_sku_sub_category_id] = useState('')
    const [selected_sku_group_id, set_selected_sku_group_id] = useState('')
    const [selected_sku_id, set_selected_sku_id] = useState('')
    const [category_search_string, set_category_search_string] = useState('');
    const [sub_category_search_string, set_sub_category_search_string] = useState('');
    const [group_search_string, set_group_search_string] = useState('');
    const [sku_search_string, set_sku_search_string] = useState('');
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)

    const fetch_groups = async(id) => {
        try{
            let body = { sku_sub_category_id:id }
            if(org_bu_view === 'bu'){
                body['business_unit_id'] = user.current_business_unit_id;
            }
            set_page_loader({
                show:true,
                text:'fetching groups...'
            })
            let response = await general_fetch({url:'inventory/get_groups', body})
            let resp = response && response.length ?response.filter(x => x.depricated !=1):[]
            set_sku_groups(resp)
            set_page_loader({
                show:false
            })
        }catch(err){
            console.log(err)
        }
    }
 
    const fetch_sku_ancestry = async(sku_id) => {
        try{
            let response = await general_fetch({
                url: 'inventory/get_sku_ancestry',
                body: { sku_id: [ sku_id ] }
            });
            let t = response && response.length ? response.map(single_item => {
                if(single_item.sku_data && !(typeof(single_item.sku_data.length) == 'number')){
                    single_item.sku_data = [single_item.sku_data]
                }
                if(single_item.sku_category_data && !(typeof(single_item.sku_category_data.length) == 'number')){
                    single_item.sku_category_data = [single_item.sku_category_data]
                }
                if(single_item.sku_sub_category_data && !(typeof(single_item.sku_sub_category_data.length) == 'number')){
                    single_item.sku_sub_category_data = [single_item.sku_sub_category_data]
                }
                if(single_item.sku_group_data && !(typeof(single_item.sku_group_data.length) == 'number')){
                    single_item.sku_group_data = [single_item.sku_group_data]
                }
            }) : ''
            set_selected_sku_category_id(response && response.length && response[0].sku_category_data && response[0].sku_category_data.length ? response[0].sku_category_data[0].id : '')
            set_selected_sku_sub_category_id(response && response.length && response[0].sku_sub_category_data && response[0].sku_sub_category_data.length ? response[0].sku_sub_category_data[0].id : '')
            set_selected_sku_group_id(response && response.length && response[0].sku_group_data && response[0].sku_group_data.length ? response[0].sku_group_data[0].id : '')
        }catch(err){
            console.log(err)
        }
    }

    const onClick_submit = async() => {
        console.log('selected_sku_id', selected_sku_id, bulk_sku_array_idx)
        if(selected_sku_id && bulk_sku_array_idx>=0){
            try{
                let identifiers = {id : selected_sku_id}
                set_page_loader({
                    show:true,
                    text:'Please wait...'
                })
                let resp = await general_fetch({url:'sku/get', body:{identifiers : JSON.stringify(identifiers), business_unit_id:user.current_business_unit_id}})
                let component_file = resp[0].component_file
                let a = [...bulk_sku_array]
                a[bulk_sku_array_idx]['component_file'] = component_file
                a[bulk_sku_array_idx]['reference_sku_id'] = selected_sku_id
                set_bulk_sku_array(a)
                onClick_handleClose()
                set_page_loader({
                    show:false,
                })
            }catch(err){
                console.log(err)
            }
        }
    }

    const filter_function = (items, set_items, search_string) => {
        let filtered_items = [...items]
        var lower_search_string = search_string ? search_string.toLowerCase():'';
        if(lower_search_string){
            filtered_items = filtered_items && filtered_items.length ? filtered_items.filter(x => x.name.toLowerCase().includes(lower_search_string)):[]
        }
        set_items(filtered_items)
    }

    const disable_submit = () => {
        if(selected_sku_sub_category_id && sku_sub_categories.find(o => o.id==selected_sku_sub_category_id) 
            && selected_sku_group_id && sku_groups.find(o => o.id==selected_sku_group_id) 
            && selected_sku_id && skus.find(o => o.id==selected_sku_id)) return false

        return true
    }

    const onClick_handleClose = () => {
        handleClose()
        set_sku_categories([])
        set_sku_sub_categories([])
        set_sku_groups([])
        set_skus([])
        set_display_sku_categories([])
        set_display_sku_sub_categories([])
        set_display_sku_groups([])
        set_display_skus([])
        set_selected_sku_category_id('')
        set_selected_sku_sub_category_id('')
        set_selected_sku_group_id('')
        set_selected_sku_id('')
        set_category_search_string('')
        set_sub_category_search_string('')
        set_group_search_string('')
        set_sku_search_string('')
    }

    useEffect(() => {
        if(reference_sku_id && open){
            set_selected_sku_id(reference_sku_id)
            fetch_sku_ancestry(reference_sku_id)
        }
    }, [reference_sku_id, open]);

    useEffect(() => {
        if(sku_category_type && all_division_tree_data && all_division_tree_data.length && open){
            let list_of_categories = all_division_tree_data.find(o => o.id == 'furniture') ? all_division_tree_data.find(o => o.id == 'furniture').sku_category : []
            list_of_categories = list_of_categories.filter(x => x.sku_category_type_id == sku_category_type)
            list_of_categories = list_of_categories.filter(x => x.deprecated != 1)
            set_sku_categories(list_of_categories)
        }
    }, [sku_category_type, all_division_tree_data, open]);

    useEffect(() => {
        console.log('all_division_tree_data', all_division_tree_data)
        if(selected_sku_category_id && all_division_tree_data && open ){
            let selected_cat = sku_categories && sku_categories.length ? sku_categories.find(o => o.id == selected_sku_category_id):''
            let selected_sub_cats = selected_cat && selected_cat.sku_sub_category && selected_cat.sku_sub_category.length ? selected_cat.sku_sub_category.filter(x => x.deprecated !=1) : [] 
            set_sku_sub_categories(selected_sub_cats)
        }
    }, [selected_sku_category_id, all_division_tree_data, open]);

    useEffect(() => {
        if(selected_sku_sub_category_id) fetch_groups(selected_sku_sub_category_id)
    }, [selected_sku_sub_category_id]);

    useEffect(() => {
        if(selected_sku_group_id && sku_groups){
            let selected_grp = sku_groups && sku_groups.length ? sku_groups.find(o => o.id == selected_sku_group_id) : ''
            set_skus(selected_grp ? selected_grp.sku : [])
        }
    }, [selected_sku_group_id, sku_groups]);

    useEffect(() => {
        set_display_sku_categories(sku_categories)
    }, [sku_categories]);

    useEffect(() => {
        set_display_sku_sub_categories(sku_sub_categories)
    }, [sku_sub_categories]);

    useEffect(() => {
        set_display_sku_groups(sku_groups)
    }, [sku_groups]);

    useEffect(() => {
        set_display_skus(skus)
    }, [skus]);

    useEffect(() => {
        console.log('search_string changed', category_search_string)
        filter_function(sku_categories, set_display_sku_categories,  category_search_string)
    }, [category_search_string]);

    useEffect(() => {
        filter_function(sku_sub_categories, set_display_sku_sub_categories, sub_category_search_string)
    }, [sub_category_search_string]);

    useEffect(() => {
        filter_function(sku_groups, set_display_sku_groups, group_search_string)
    }, [group_search_string]);

    useEffect(() => {
        filter_function(skus, set_display_skus, sku_search_string)
    }, [sku_search_string]);
    
    return (
        <Modal className='Margin_64px_top' size='xl' isOpen = {open} toggle = {onClick_handleClose}>
            <CardHeader className = 'global_modal_header' >
                <span style={{ fontWeight:'900'}}>Select the SKU from which you want to clone from</span>
                <i style={{cursor:'pointer'}} onClick={onClick_handleClose} className='fa fa-times'/>
            </CardHeader>
            <CardBody style={{height:'600px', backgroundColor:'#F5F6F8'}}>
                <Row>
                    <Col >
                        <div style={{fontWeight:'900', marginBottom:'12px'}}>Select Category</div>
                        <Input style={{height:'32px', marginBottom:'12px', fontSize:'14px'}} type='text' value={category_search_string} onChange = {(e) => set_category_search_string(e.target.value)} placeholder='Search Categories'/>
                        {
                            display_sku_categories && display_sku_categories.length ? display_sku_categories.map((single_sku_category,idx) => (
                                <div style={{display:'flex', marginBottom:'10px'}}>
                                    <Input id = {'reference_sku_category'+idx} checked={selected_sku_category_id===single_sku_category.id?true:false} onClick={()=>{set_selected_sku_category_id(single_sku_category.id);set_sku_groups([]);set_skus([])}} key={idx} style={{ position:'unset', margin:0, marginRight:'10px', cursor:'pointer'}} type='radio' />
                                    <span style={{fontSize:'15px'}}>{single_sku_category ? single_sku_category.name : ''}</span>
                                </div>
                            )):''
                        }
                    </Col>
                    <Col >
                        <div style={{fontWeight:'900', marginBottom:'12px'}}>Select Sub-Category</div>
                        {
                            display_sku_sub_categories && display_sku_sub_categories.length ?
                            <Input style={{height:'32px', marginBottom:'12px', fontSize:'14px'}} type='text' value={sub_category_search_string} onChange = {(e) => set_sub_category_search_string(e.target.value)} placeholder='Search Sub-Categories'/>
                            :''
                        }
                        {
                            display_sku_sub_categories && display_sku_sub_categories.length ? display_sku_sub_categories.map((single_sku_sub_category,idx) => (
                                <div style={{display:'flex', marginBottom:'10px'}}>
                                    <Input id = {'reference_sku_sub_category'+idx} checked={selected_sku_sub_category_id===single_sku_sub_category.id?true:false} onClick={()=>{set_selected_sku_sub_category_id(single_sku_sub_category.id);set_skus([])}} key={idx} style={{ position:'unset', margin:0, marginRight:'10px', cursor:'pointer'}} type='radio' />
                                    <span style={{fontSize:'15px'}}>{single_sku_sub_category ? single_sku_sub_category.name : ''}</span>
                                </div>
                            )):''
                        }
                    </Col>
                    <Col >
                        <div style={{fontWeight:'900', marginBottom:'12px'}}>Select Sku Group</div>
                        {
                            display_sku_groups && display_sku_groups.length ?
                            <Input style={{height:'32px', marginBottom:'12px', fontSize:'14px'}} type='text' value={group_search_string} onChange = {(e) => set_group_search_string(e.target.value)} placeholder='Search Groups'/>
                            :''
                        }
                        {
                            display_sku_groups && display_sku_groups.length ? display_sku_groups.map((single_sku_group,idx) => (
                                <div style={{display:'flex', marginBottom:'10px'}}>
                                    <Input id = {'reference_sku_group'+idx} checked={selected_sku_group_id===single_sku_group.id?true:false} onClick={()=>set_selected_sku_group_id(single_sku_group.id)} key={idx} style={{ position:'unset', margin:0, marginRight:'10px', cursor:'pointer'}} type='radio' />
                                    <span style={{fontSize:'15px'}}>{single_sku_group ? single_sku_group.name : ''}</span>
                                </div>
                            )):''
                        }
                    </Col>
                    <Col >
                        <div style={{fontWeight:'900', marginBottom:'12px'}}>Select SKU</div>
                        {
                            display_skus && display_skus.length ?
                            <Input style={{height:'32px', marginBottom:'12px', fontSize:'14px'}} type='text' value={sku_search_string} onChange = {(e) => set_sku_search_string(e.target.value)} placeholder='Search SKUs'/>
                            :''
                        }
                        {
                            display_skus && display_skus.length ? display_skus.map((single_sku,idx) => (
                                <div style={{display:'flex', marginBottom:'10px'}}>
                                    <Input id = {'reference_sku'+idx} checked={selected_sku_id===single_sku.id?true:false} onClick={()=>set_selected_sku_id(single_sku.id)} key={idx} style={{ position:'unset', margin:0, marginRight:'10px', cursor:'pointer'}} type='radio' />
                                    <span style={{fontSize:'15px'}}>{single_sku ? single_sku.name : ''}</span>
                                </div>
                            )):''
                        }
                    </Col>
                </Row>
            </CardBody>
            <CardFooter className='global_modal_footer'>
                <Button id='reference_sku_submit' disabled={disable_submit()?true:false} className='blue_button' onClick={()=>{console.log("kk");handleClose();onClick_submit()}} >Done</Button>
            </CardFooter>
        </Modal>
    )
}

export default ReferenceSkuModal;

