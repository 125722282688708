import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import DCRulesDialogBox from'./DCRulesDialogBox'
import {Card, Col, Progress, Table, Row, } from "reactstrap";
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import './index.css'
import remove_underscore_wtih_first_cap from '../../utils/remove_underscore_wtih_first_cap';
import DragDropComponent from '../../components/DragDropCompoment';
import Filter, { RulesFilter } from '../../components/RuleFilterModal';
import { COLORS } from '../../utils/colors';
import RuleNameCloneModal from '../../components/RuleNameCloneModal';
import { rule_type_json_dc_rules, component_tags_json_dc_rules } from '../../components/DCRulesKeys';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { UserContext } from '../../components/Contexts/UserContext';
import { AllBuContext } from '../../components/Contexts/AllBuContext';
import { handleError } from '../../utils/error';

const DC_rules = ({user, submit_for_delete_confirm, set_active_tab, send_analytics_event, check_route_fn, set_page_loader, store_details, get_store_details, set_active_division_id_sidebar}) => {
	const styles = {
		add_brand_style:{
			backgroundColor: '#007EF4',
			boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
			borderRadius: '3px',
			height:'32px',
			padding:'0px 24px',
			border:'0px',
			color:'white',
		},
		table_header: {
			fontWeight: "bold",
			fontSize: 'small'
		},
    };
	//console.log('BRANDPAGE====>CALLED')
	const [rules, set_rules ] = useState([]);
	const [show_rule_Dialog_Box, set_show_rule_Dialog_Box] = useState(false);
	const [active_rule, set_active_rule] = useState('');
	const [is_active_rule_valid, set_is_active_rule_valid] = useState(false);
	const [search_string , set_search_string] = useState('');
	const [display_rules , set_display_rules] = useState([]);
	const [key , set_key] = useState('');

	const [filtered_display_rules, set_filtered_display_rules] = useState([]);
	const [sort_value, set_sort_value] = useState(3);

	const [page_size , set_page_size] = useState(30)
	const [fetch_progress , set_fetch_progress] = useState(40)
	const [current_page , set_current_page] = useState(1)
	const [initialized , set_initialized] = useState(false) 
 	const [all_pages , set_all_pages] = useState([])
	const alert = useAlert()
	const [ start_index , set_start_index ] = useState('');
	const [ end_index , set_end_index ] = useState('');
	const [tags , set_tags] = useState([]);
	const [ last_item_order , set_last_item_order ] = useState(0);
	const [ sales_channels, set_sales_channels ] = useState([]);

	const [is_filter_open, set_is_filter_open] = useState(false);
	const org_bu_view = useContext(OrgBuContext)
	const all_bu_in_store = useContext(AllBuContext)
	
	const fetch_rules_details = async () => {
        try {
            var resp = await general_fetch({ url: 'automation_rule/get_by_type', body:{type:'design_constraint', business_unit_id: user.current_business_unit_id}});
            set_initialized(true);
			let rules_details = resp && resp.length ? resp.filter(x => !x.sku_linked_rule) : []
            set_rules(rules_details);
            set_display_rules(rules_details);
			set_last_item_order(rules_details.length?rules_details[rules_details.length-1].order:0);
			//console.log('brand======>',rules);
        } catch(err) {
            console.log(err);
			alert.error('Error! Please contact admin')
        }
    }

	const fetch_tags_details = async () => {
		try {
			let tags_details
			if (org_bu_view === 'org') {
				tags_details = await general_fetch({ url: 'tag/get' });
			} else {
				tags_details = await general_fetch({ url: 'tag/get', body: { business_unit_id: user.current_business_unit_id } });
			}
			console.log("tag DesignConstraint")
			set_tags(tags_details);
		} catch (err) {
			console.log(err);
		}
	}

	const fetch_sales_channel_details = async () => {
		try {
			let resp, sales_channel_details = []
			let body = {
				"include_sales_channel_profile_info": true, 
				"include_bu_mapping": true, 
				"include_bu_info": false, 
				"include_price_type_mapping": true, 
				"include_price_type_info": true, 
				"include_user_mapping": true, 
				"include_user_info": false
			}

			if(org_bu_view === 'bu'){
				if(user && user.current_business_unit_id){
					body['business_unit_ids'] = [user.current_business_unit_id]
					resp = await general_fetch({ url: 'sales_channel/get_by_business_unit' , body})
					let owned_sc = resp && resp.sales_channels_by_business_unit && resp.sales_channels_by_business_unit[user.current_business_unit_id] ? resp.sales_channels_by_business_unit[user.current_business_unit_id].owned : []
					// let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x)) sales_channel_details.push(resp.sales_channels[x])}) : []
					let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x) && !(all_bu_in_store && all_bu_in_store.length === 1 && resp.sales_channels[x].type === 'bu_feeder')) sales_channel_details.push(resp.sales_channels[x])}) : []
				}
			}else{
				resp = await general_fetch({ url: 'sales_channel/get_of_store' , body})
				sales_channel_details = resp && resp.sales_channels && resp.sales_channels.owned ? resp.sales_channels.owned.filter(x => !x.business_unit_id) : []
			}
			sales_channel_details = sales_channel_details.map(single_sales_channel => {
                let price_types = single_sales_channel.price_type_ids && single_sales_channel.price_type_ids.length ? single_sales_channel.price_type_ids.map(x => resp.price_types[x]) : []
                return {...single_sales_channel, price_types}
            })
			set_sales_channels(sales_channel_details);
		} catch(err) {
			console.log(err);
			handleError(err)
		}
	}

	const onclick_delete_rule = async(id) => {
		try {
			var body={id:id}
			var response = await general_fetch({ url: 'automation_rule/remove', body})
			alert.success('Rule Deleted sucessfully')
			fetch_rules_details()
		} catch(err) {
			console.log(err);
			alert.error('Please check input format.')
		}
	}
	// useEffect(() => {
	// 	console.log('brand======>',rules);
	// }
	// , [rules]);

	useEffect(() => {
		if(!rules.length){
			fetch_rules_details();
		}
		if(!tags.length){
			fetch_tags_details();
		}
		if(!sales_channels.length){
			fetch_sales_channel_details();
		}
	}
	, [user]);

	const open_rule_update_dialog = ({ item, mode, is_active_rule_valid}) => {
		console.log("item=====>", item)
		set_show_rule_Dialog_Box(true);
		set_active_rule(item);
		set_key(mode);
		set_is_active_rule_valid(is_active_rule_valid)
	}

	const close_rule_Dialog_Box = () => {
		set_show_rule_Dialog_Box(false);
		set_active_rule('');
		set_key('');
	}

	const open_rule_add_dialog = () => {
		set_show_rule_Dialog_Box(true);
		set_key('add');
	}
	
	const filter_rule = () => 
	{
		var filtered_brands = [...rules];
		var lower_search_string = search_string ? search_string.toLowerCase():'';
		console.log(filtered_brands)
		if(lower_search_string){
			filtered_brands = filtered_brands.filter( brand => (brand.name.toLowerCase().includes(lower_search_string)|| (brand.description ? brand.description.toLowerCase().includes(lower_search_string):false) || (rule_type_json_dc_rules[brand.rule_type] ? rule_type_json_dc_rules[brand.rule_type].name.toLowerCase().includes(lower_search_string):false)))
		}
		set_display_rules(filtered_brands)
	}

	const onclick_sorting_arrows = () => {
		if(sort_value == 1){
			set_filtered_display_rules(display_rules.filter(x => x.active))
		}
		else if(sort_value == 2){
			set_filtered_display_rules(display_rules.filter(x => !x.active))
		} else if(sort_value == 3){
			set_filtered_display_rules(display_rules)
		}else{
			set_filtered_display_rules([])
		}
	}


	useEffect(() => {
		onclick_sorting_arrows()
	}, [sort_value, display_rules]);

	useEffect(() => {
			filter_rule();
	}, [search_string, rules]);

	useEffect(() => {
		set_active_tab('DesignIntelligence')
		set_active_division_id_sidebar('DC_rules')
		// check_route_fn('DC_rules')
		send_analytics_event('auto', 'DC_rules', 'DC_rules', 1, window.location.href)
	}, []);

	useEffect(() => {
		if (initialized) {
			var pages = Number(Math.ceil(display_rules.length / page_size));
			//console.log('all pages --> ', pages, 'projects length --<>', display_rules.length)
			var tmp = Array.apply('', {length: pages + 1}).map(Number.call, Number)
			tmp.splice(0, 1);
			//console.log('tmp --<>', tmp)
			set_all_pages(tmp);
			//console.log('tmp spliced--<>', tmp)
			set_current_page(1);
		}
	}, [ page_size, display_rules ])

	const Rules_Filler = () => (
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
				<tr>
					{ initialized ? (
						<th scope="col"><span style={styles.table_header}><b>No rules to list  </b></span><br/> Try adjusting filters...<br /> &nbsp;</th>
					):(
						<th scope="col"><span style={styles.table_header}><b>Fetching all rules </b></span><br/> <br /> Please wait...<br /> <br />
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;</th>
					)}
				</tr>
			</thead>
		</Table>
	);

	const onclick_suffle_rule = async(active_rule, new_order) => {
		try {
			var body = {
				rule_type: 'design_constraint',
				data:[{id:active_rule.id,order:new_order}]
			};
			var response = await general_fetch({ url: 'automation_rule/update_order', body});	
			alert.success('Rule Updated Successfully !')
			fetch_rules_details()
		} catch(err) {
			console.log(err);
		}
	}

	const is_valid_add_key = (item_json, type) => {
		if(item_json && item_json.length){
			for(var i=0;i<item_json.length;i++){
				if(!(item_json[i].property && item_json[i].property.length)){
					return false;
				}
			}
			return true;
		}else{
			return true;
		}
	}

	const transform_to_usable = (item_array, array_name) => {
		if(item_array && item_array.length){
			let x_temp = item_array.map(x_item =>{
				console.log('inside tf usable -->> ', x_item);
				return({
					id:x_item && x_item.id?x_item.id:null,
					value:(x_item.value && (Array.isArray(x_item.value) && x_item.value.length))? (x_item.value.length? JSON.stringify(x_item.value.map(x => x.id)):''): (x_item.value ? (x_item.property[0].type == 'singleselect' ? x_item.value : Number(x_item.value)):''),
					min_value: (x_item.min_value || x_item.min_value == 0)?Number(x_item.min_value):'',
					max_value: (x_item.max_value || x_item.max_value == 0)?Number(x_item.max_value):'',
					type: x_item.condition_type?x_item.condition_type:'absolute', 
					operator:x_item.operator?x_item.operator:'', 
					value_combination_logic:x_item.value_combination_logic?x_item.value_combination_logic:'and', 
					sku:x_item.property && x_item.property.length && x_item.property[0].id == 'sku' && x_item.sku && x_item.sku.length ?x_item.sku.map(x => x.id):null,
					tag:x_item.property && x_item.property.length && x_item.property[0].id == 'tag' && x_item.tag && x_item.tag.length? x_item.tag.map(x => x.id):null,
					sales_channel:x_item.sales_channel && x_item.sales_channel.length? x_item.sales_channel.map(x => x.id):null,
					source_filter:x_item.component? ({
						id:x_item.source_filter && x_item.source_filter.id?x_item.source_filter.id:null,
						type:'source',
						component:array_name==='applicable'?(x_item.component && x_item.component.length? x_item.component[0].id:[]) : x_item.component?x_item.component:'' ,
						sub_component:array_name==='applicable'?(x_item.subcomponent && x_item.subcomponent.length? x_item.subcomponent[0].id:[]) : x_item.subcomponent? x_item.subcomponent:'',
						property:x_item.property && x_item.property.length? x_item.property[0].id:'',
					}) : null,
					destination_filter: (x_item.relativecomponent && x_item.relativecomponent.length) ? ({
						type: 'destination',
						component:null,
						sub_component:null,
						property:null
					}) : null,
				})
			})

			for(let i = 0; i < x_temp.length; i++){
				if(x_temp[i].source_filter){
					if(x_temp[i].source_filter.property == 'tag'){
						console.log('transform_to_usablee tag')
						if(!(x_temp[i].tag && x_temp[i].tag.length && typeof x_temp[i].tag[0] === 'string')) return -1
					}else if(x_temp[i].source_filter.property == 'sku' && x_temp[i].type == 'absolute'){
						console.log('transform_to_usablee sku', x_temp[i].sku)
						if(x_temp[i].sku && x_temp[i].sku.length){
							for(let p=0; p<x_temp[i].sku.length;p++){
								if(!(x_temp[i].sku[p] && typeof x_temp[i].sku[p] === 'string')){
									return -1
								}
							}
						}
						// if(!(x_temp[i].sku && x_temp[i].sku.length && typeof x_temp[i].sku[0] === 'string')) return -1
					}else if(x_temp[i].source_filter.property == 'height' || x_temp[i].source_filter.property == 'width' || x_temp[i].source_filter.property == 'depth'){
						console.log('transform_to_usablee height')
						if(!(x_temp[i].value >=0 && typeof x_temp[i].value === 'number')) return -1
					}else if(x_temp[i].source_filter.property == 'sales_channel'){
						console.log('transform_to_usablee sales_channel')
						if(!(x_temp[i].sales_channel && x_temp[i].sales_channel.length && typeof x_temp[i].sales_channel[0] === 'string')) return -1
					}
				}
			} 

			console.log('transform_to_usable', item_array, x_temp)
			return x_temp;
		}else{
			return []
		}
	} 

	const validate_rule = (active_rule) => {
		let item_json_applicable, item_json_source, item_json_dest, rule_name, rule_description, rule_activation, rule_logic, rule_type;
		if (active_rule) {
			// if(active_rule.bu_level_linked_skus && active_rule.bu_level_linked_skus.length){
			// 	set_sku_selection_map(active_rule.bu_level_linked_skus.map(x => x.id))
			// }
			item_json_applicable = active_rule.applicability&& active_rule.applicability.length?active_rule.applicability.map(x_item => {return{
				...x_item, 
				component: x_item.source_filter ? x_item.source_filter.component : [], 
				subcomponent:x_item.source_filter ?x_item.source_filter.sub_component:null, 
				property: x_item.source_filter ? x_item.source_filter.property : []
			}}):[]

			item_json_source = active_rule.conditions && active_rule.conditions.length ? active_rule.conditions.map(x_item => {
				return {
					...x_item, 
					component: x_item.source_filter ? x_item.source_filter.component : [], 
					subcomponent:x_item.source_filter ?x_item.source_filter.sub_component:null, 
					property: x_item.source_filter ? x_item.source_filter.property : []
				}
			}):[]

			item_json_dest = active_rule.actions&& active_rule.actions.length?active_rule.actions.map(x_item => {
				return {
					...x_item, 
					component:x_item.source_filter ?x_item.source_filter.component:[], 
					subcomponent:x_item.source_filter ?x_item.source_filter.sub_component:null, 
					property:x_item.source_filter ?x_item.source_filter.property:[]
				}
			}):[]

			rule_name = active_rule.name;
			rule_description = active_rule.description;
			rule_activation = active_rule.active ? true : false;
			rule_logic = active_rule.rule_logic;
			rule_type = active_rule.rule_type;
		}
		// if(active_rule.name == 'kiwi'){
		// 	// console.log("Kpppppp", item_json_applicable, item_json_source)
		// }

		if(!(is_valid_add_key(item_json_applicable, 'applicable') && is_valid_add_key(item_json_source,'source') && is_valid_add_key(item_json_dest,'dest'))){
			return false;
		}else{
			// if(active_rule.name == 'kiwi')
			// console.log("kppppp here", rule_name, rule_description, item_json_source, item_json_dest, item_json_applicable)
			if(rule_name && rule_description && item_json_source && item_json_source.length && item_json_source[0].property && item_json_source[0].property.length && item_json_applicable && item_json_applicable.length && item_json_applicable[0].property && item_json_applicable[0].property.length && item_json_dest && item_json_dest.length && item_json_dest[0].property && item_json_dest[0].property.length){
					let applicability = transform_to_usable(item_json_applicable)
					let conditions = transform_to_usable(item_json_source)
					let actions = transform_to_usable(item_json_dest)
					// if(active_rule.name == 'kiwi'){
					// 	console.log("Kpppppp appp", applicability)
					// }
					if(applicability == -1 || conditions == -1 || actions == -1) return false;
					return true;
			}else{
				// if(active_rule.name == 'kiwi')
				// console.log("kppppppppp entered here")
				return false;
			}
		}
	}

	const Single_table_row = ({item, index, start_index, end_index, open_rule_update_dialog, onclick_delete_rule, submit_for_delete_confirm, fetch_rules}) => {
		const [show_name_clone_modal, set_show_name_clone_modal] = useState(false);
		const [active_rule, set_active_rule] = useState('');
		const [is_rule_valid, set_is_rule_valid] = useState(true);
		const alert = useAlert();
		const user = useContext(UserContext)

		useEffect(() => {
			set_is_rule_valid(validate_rule(JSON.parse(JSON.stringify(item))));
		}, [item])

		const clone_rule = async(rule_json, new_name) => {
			try{
				let body = {
					sku_linked_rule_type: 'bu_level',
					bu_level_linked_rule_sku_ids: rule_json.bu_level_linked_skus && rule_json.bu_level_linked_skus.length ? rule_json.bu_level_linked_skus.map(x => x.id) : [],
					name: new_name,
					description: rule_json.description,
					order: rule_json.order+1,
					type: rule_json.type,
					rule_type: rule_json.rule_type,
					rule_logic: rule_json.rule_logic,
					active: false,
					applicability_combination_logic: rule_json.applicability_combination_logic,
					condition_combination_logic: rule_json.condition_combination_logic,
					action_combination_logic: rule_json.action_combination_logic,
					applicability:rule_json.applicability,
					conditions: rule_json.conditions,
					actions: rule_json.actions,
					business_unit_id:user.current_business_unit_id
				}

				let resp = await general_fetch({url:'automation_rule/create', body})
				fetch_rules()
				alert.success('Rule cloned Successfully !')
			}catch(err){
				alert.error("Rule clone failed !")
				console.log(err)
			}
		}

		const open_name_clone_modal = (rule_json) => {
			set_active_rule(rule_json)
			set_show_name_clone_modal(true)		
		}

		const close_name_clone_modal = () => {
			set_active_rule('')
			set_show_name_clone_modal(false)
		}

		return (
			// {
			// display_rules && display_rules.length && display_rules.map((rule, index) => (
			<tr key={index} style={{backgroundColor: is_rule_valid ? 'white' : '#FCEDEF', display:(index+1>=start_index && index<end_index)?'flex':'none'}} className={'tr_style' +  (is_rule_valid ? ' display_onhover' : ' display_onhover_red')}>
				<RuleNameCloneModal open={show_name_clone_modal} handleClose={close_name_clone_modal} rule_json={active_rule} clone_rule={clone_rule} />
				<td className='td_style_no_scroll flex_property' style={{flex:1}}>
						<div className='drag_option' style={{display:'flex', alignItems:'center', color:'#808080'}}>
							<i style={{marginRight:'1px'}} className='fa fa-ellipsis-v'/>
							<i className='fa fa-ellipsis-v'/>
							<span style={{marginLeft:'10px'}}>{index+1} .</span>
						</div>
					</td>
				<td className='td_style_no_scroll flex_property' style={{flex:1}}>{item.active==1?<span><i className='fas fa-circle' style={{color: '#23C16B', marginRight:'8px'}}></i> <span>Active</span></span>  : <span><i className='fas fa-circle' style={{color: '#D9D9D9', marginRight:'8px'}}></i> <span>Inactive</span></span>}</td>
				<td className='td_style_no_scroll flex_property' style={{flex:1.5}}><span className='table_text_overflow'>{item.name}</span></td>
				<td className='td_style_no_scroll flex_property' style={{flex:3.5}}><span className='table_text_overflow'>{item.description?item.description:'-'}</span></td>
				<td className='td_style_no_scroll flex_property' style={{flex:2}}>{item.rule_type?remove_underscore_wtih_first_cap(item.rule_type):'-'}</td>
				{/* <td className='td_style_no_scroll flex_property' style={{flex:5}}><span className='table_text_overflow'>{item.created_at}</span></td> */}

				<td className='td_style_no_scroll flex_property' style={{flex: 2.5, color: '#FF5247'}}>{is_rule_valid ? '' : 'Some of the fields in this rule might be empty'}</td>
				
				<td className='td_style_no_scroll flex_property x1' style={{ justifyContent:'flex-end', flex:1, paddingRight:'20px'}}>
					{
						user.store_id ==item.store_id ?(
							<span className='hide'>
								<i style={{marginRight:'15px'}} onClick ={() => open_rule_update_dialog({ item, mode: 'view', is_active_rule_valid: is_rule_valid })} className='fa fa-eye'/>
								<i style={{marginRight:'15px'}} onClick ={() => open_rule_update_dialog({ item, mode: 'edit', is_active_rule_valid: is_rule_valid })} className='fa fa-edit'/>
								<img src={'/assets/img/icons/clone_icon.svg'} onClick={() => {open_name_clone_modal(item)}} width='14px' height='14px' style={{marginRight:'15px'}}/>
								<i 
									onClick={()=>submit_for_delete_confirm(()=>onclick_delete_rule(item.id), ()=>{}, 'Are you sure you want to delete this Rule? Rule Name - '+ item.name)}
									className='fa fa-trash'
								/>
							</span>
						):''
					}
				</td>
				{/* <td className='td_style flex_property x1' style={{ justifyContent:'flex-end', flex:'1', paddingRight:"20px"}}>
					{
						user.store_id ==item.store_id ?(
							<span className='hide'>
								
							</span>
						):''
					}
				</td> */}
			</tr>
		// ))}
	)
	}

	const Rules_Table = () => (
		<table className="text-left flex_property table_style" style={{height:"100%", width:'100%'}}>
			<thead style={{width:'100%'}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',width:'100%', height:'40px'}}>
				<th className='th_style flex_property' style={{flex:1}}><span>Rule Order</span></th>
				<th className='th_style flex_property' style={{flex:1}}>
					<span style={{paddingRight:'8px'}}>Active</span>
				</th>
				<th className='th_style flex_property' style={{flex:1.5}}><span>Rule Name</span></th>
				<th className='th_style flex_property' style={{flex:3.5}}><span>Description</span></th>
				<th className='th_style flex_property' style={{flex:2}}><span>Rule type</span></th>
				<th className='th_style flex_property' style={{flex: 2.5}}>
					Violations
				</th>
				{/* <th className='th_style flex_property' style={{border:0, flex:5}}><span>Created at</span></th> */}
				<th className='th_style' style={{border:0, flex:1, paddingRight:"20px"}}><span></span></th>
			</tr>
			</thead>
			<tbody style={{height: "calc(100% - 40px)",width:'100%', overflow:'auto'}}>
				<DragDropComponent items_from_backened={filtered_display_rules} onclick_suffle_rule={onclick_suffle_rule} set_items_from_backened={set_display_rules} Single_component={Single_table_row} start_index={start_index} end_index={end_index} user={user} open_rule_update_dialog={open_rule_update_dialog} submit_for_delete_confirm={submit_for_delete_confirm} onclick_delete_rule={onclick_delete_rule} fetch_rules={fetch_rules_details}/>
			</tbody>
		</table>
	);

	const on_filter_apply = (filter_options) => {
		let new_sort_value = 0;
		if(filter_options.active) new_sort_value += 1;
		if(filter_options.inactive) new_sort_value += 2;
		set_sort_value(new_sort_value)
	}

	return (
		<Main_Grid active_tab="dc_rules" user={user} store_details={store_details} get_store_details={get_store_details}>
			{/* <Filter is_filter_open={is_filter_open} set_is_filter_open={set_is_filter_open} set_sort_value={set_sort_value}></Filter> */}
			<RulesFilter is_filter_open={is_filter_open} handle_close={() => set_is_filter_open(!is_filter_open)} onChangeCallback={filter_options => on_filter_apply(filter_options)} />
			<Row style={{padding:'10px 0px', color:'#22272e'}}>
	        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
	        		Viewing {filtered_display_rules && filtered_display_rules.length ?filtered_display_rules.length:'0'} Design Constraint Rules
	        	</Col>
	        	<Col xs={6} style={{display: 'flex', justifyContent: 'end', gap: '16px'}}>
					<button onClick={() => set_is_filter_open(true)} className='add_from_warehouse_button' style={{color: (sort_value != 3) ? '#0078ff' : '', borderColor: (sort_value != 3) ? '#0078ff' : '#b8bfca' }}>
						<div className='flex_property' style={{ fontSize: '12px'}}>
							<div className='mr-2'>Filter</div>
							<i className='fa fa-solid fa-filter' style={{}} />
						</div>
					</button>
	        		<button onClick={open_rule_add_dialog} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Design Constraint Rule</span></button>
	        	</Col>
        	</Row>
	        <Row style={{height:'calc(100% - 90px)'}}>
		        <Col style={{height:'100%'}}>
		        	<Card style={{borderRadius: 0, height:'100%'}}>
		        		<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Rule Name, Description, Type' /></div>
						<div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{filtered_display_rules && filtered_display_rules.length ? (<Rules_Table />) : (<Rules_Filler />)}</div>
					</Card>
		        </Col>
	        </Row>
	        <Row style={{height:'3px'}}>
		        <Col>
		        </Col>
	        </Row>
	        <Row>
		        <Col>	
					<Pegasus_Paginator 
						id='design-paginator'
						end_index={end_index}
						set_end_index={set_end_index}
						start_index={start_index}
						set_start_index={set_start_index}
						all_projects={filtered_display_rules && filtered_display_rules.length ? filtered_display_rules.length : '0'}
					/>
		        </Col>
	        </Row>
			<DCRulesDialogBox 
				open={show_rule_Dialog_Box} 
				component_options_json={component_tags_json_dc_rules} 
				rule_type_json={rule_type_json_dc_rules} 
				handleClose={close_rule_Dialog_Box} 
				active_rule={active_rule} 
				is_active_rule_valid={is_active_rule_valid}
				user={user} 
				fetch_rules_details={fetch_rules_details} 
				mode={key} 
				set_page_loader={set_page_loader} 
				tags={tags}
				sales_channels={sales_channels}
				last_item_order={last_item_order}	
			/>
		</Main_Grid>
	);
}	


export default DC_rules;

