export const api_fallback_material_template_get = [
    {
        "id": "1",
        "name": "Laminate",
        "type": null,
        "blend_material": "laminate",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.2",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "max": "100",
                "min": "-100",
                "name": "brightness",
                "node": "Bright/Contrast",
                "type": "number",
                "default": "-0.1",
                "property": "Bright",
                "overridable": true
            },
            {
                "max": "100",
                "min": "-100",
                "name": "contrast",
                "node": "Bright/Contrast",
                "type": "number",
                "default": "0.2",
                "property": "Contrast",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2017-10-30T06:55:22.000Z",
        "updated_at": "2024-02-05T07:18:30.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "7",
        "shard_id": 1
    },
    {
        "id": "10f20f81c07e1709",
        "name": "Transluscent",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T11:57:21.000Z",
        "updated_at": "2024-02-07T11:57:21.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "150b8667fb680eee",
        "name": "Fur",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:05:22.000Z",
        "updated_at": "2024-02-07T12:05:22.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "1518169612794",
        "name": "Wood",
        "type": null,
        "blend_material": "wood",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.3",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "name": "normal map",
                "node": "Image Texture.001",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "max": "10",
                "min": "0",
                "name": "normal map strength",
                "node": "Normal Map",
                "type": "number",
                "default": "0",
                "property": "Strength",
                "overridable": true
            },
            {
                "max": "10",
                "min": "0",
                "name": "shininess",
                "node": "Fresnel",
                "type": "number",
                "default": "3.5",
                "property": "IOR",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.3",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-09T09:46:52.000Z",
        "updated_at": "2024-02-05T07:18:49.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "7",
        "shard_id": 1
    },
    {
        "id": "1518270837693",
        "name": "Granite",
        "type": null,
        "blend_material": "granite",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.1",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "name": "normal map",
                "node": "Image Texture.001",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "max": "10",
                "min": "0",
                "name": "normal map strength",
                "node": "Normal Map",
                "type": "number",
                "default": "0",
                "property": "Strength",
                "overridable": true
            },
            {
                "max": "10",
                "min": "0",
                "name": "shininess",
                "node": "Mix Shader",
                "type": "number",
                "default": "0.1",
                "property": "Fac",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-10T13:53:57.000Z",
        "updated_at": "2024-02-22T04:41:27.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518270899312",
        "name": "Glass",
        "type": null,
        "blend_material": "glass",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glass BSDF",
                "type": "number",
                "default": "0",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "color",
                "node": "Glass BSDF",
                "type": "color",
                "property": "Color",
                "overridable": true
            },
            {
                "max": "1000",
                "min": "0",
                "name": "refraction",
                "node": "Glass BSDF",
                "type": "number",
                "default": "1.45",
                "property": "IOR",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0",
                "overridable": true
            },
            {
                "name": "color",
                "type": "color",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1.45,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-10T13:54:59.000Z",
        "updated_at": "2024-02-05T07:20:57.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518270961307",
        "name": "Plastic",
        "type": null,
        "blend_material": "plastic",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.005",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "main color",
                "node": "Diffuse BSDF",
                "type": "color",
                "property": "Color",
                "overridable": true
            },
            {
                "name": "boundary color",
                "node": "Diffuse BSDF.001",
                "type": "color",
                "property": "Color",
                "overridable": true
            },
            {
                "max": "1000",
                "min": "0",
                "name": "shininess",
                "node": "Fresnel",
                "type": "number",
                "default": "1.45",
                "property": "IOR",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0",
                "overridable": true
            },
            {
                "name": "color",
                "type": "color",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.9,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-10T13:56:01.000Z",
        "updated_at": "2024-02-22T04:42:51.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518270986180",
        "name": "Fabric",
        "type": null,
        "blend_material": "fabric",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.2",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "name": "normal map",
                "node": "Image Texture.001",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "max": "10",
                "min": "0",
                "name": "normal map strength",
                "node": "Normal Map",
                "type": "number",
                "default": "0",
                "property": "Strength",
                "overridable": true
            },
            {
                "max": "10",
                "min": "0",
                "name": "shininess",
                "node": "Fresnel",
                "type": "number",
                "default": "1.45",
                "property": "IOR",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.2",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-10T13:56:26.000Z",
        "updated_at": "2024-02-05T07:21:57.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518271008521",
        "name": "Leather",
        "type": null,
        "blend_material": "leather",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.2",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "color",
                "node": "Diffuse BSDF",
                "type": "color",
                "property": "Color",
                "overridable": true
            },
            {
                "max": "1000",
                "min": "-1000",
                "name": "leather pattern",
                "node": "Voronoi Texture",
                "type": "number",
                "default": "60",
                "property": "Scale",
                "overridable": true
            },
            {
                "max": "1",
                "min": "0",
                "name": "depth",
                "node": "Math",
                "type": "number",
                "default": "0.1",
                "property": "Value",
                "overridable": true
            },
            {
                "max": "10",
                "min": "0",
                "name": "shininess",
                "node": "Fresnel",
                "type": "number",
                "default": "1.45",
                "property": "IOR",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.2",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.2,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-10T13:56:48.000Z",
        "updated_at": "2024-02-05T07:22:30.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518271153513",
        "name": "Steel",
        "type": null,
        "blend_material": "steel",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.1",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "color",
                "node": "Glossy BSDF",
                "type": "color",
                "property": "Color",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-10T13:59:13.000Z",
        "updated_at": "2024-02-05T07:46:15.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518607696602",
        "name": "Paint",
        "type": null,
        "blend_material": "paint",
        "blender_properties": [
            {
                "name": "color",
                "node": "Diffuse BSDF",
                "type": "color",
                "property": "Color",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "color",
                "type": "color",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.4,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:28:16.000Z",
        "updated_at": "2024-02-05T07:46:43.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518607723279",
        "name": "Mirror",
        "type": null,
        "blend_material": "mirror",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "color",
                "node": "Glossy BSDF",
                "type": "color",
                "property": "Color",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0",
                "overridable": true
            },
            {
                "name": "color",
                "type": "color",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:28:43.000Z",
        "updated_at": "2024-02-05T07:47:12.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518607798371",
        "name": "Marble",
        "type": null,
        "blend_material": "marble",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.2",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "max": "1",
                "min": "0",
                "name": "glossiness",
                "node": "Layer Weight",
                "type": "number",
                "default": "0.5",
                "property": "Blend",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.4,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:29:58.000Z",
        "updated_at": "2024-02-22T04:46:23.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518608022521",
        "name": "Glossy",
        "type": null,
        "blend_material": "glossy",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.07",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "max": "1",
                "min": "0",
                "name": "glossiness",
                "node": "Fresnel",
                "type": "number",
                "default": "0.1",
                "property": "IOR",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:33:42.000Z",
        "updated_at": "2024-02-22T04:44:56.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518608042208",
        "name": "Matte",
        "type": null,
        "blend_material": "matte",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.6",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "max": "1",
                "min": "0",
                "name": "glossiness",
                "node": "Fresnel",
                "type": "number",
                "default": "0.0",
                "property": "IOR",
                "overridable": true
            },
            {
                "max": "1",
                "min": "0",
                "name": "brightness",
                "node": "Mix Shader.001",
                "type": "number",
                "default": "0.245",
                "property": "Fac",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:34:02.000Z",
        "updated_at": "2024-02-05T07:48:31.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518608121077",
        "name": "Textured Paint",
        "type": null,
        "blend_material": "textured_paint",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.3",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "name": "normal map",
                "node": "Image Texture.001",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "max": "1",
                "min": "0",
                "name": "brightness",
                "node": "Mix Shader",
                "type": "number",
                "default": "0.1",
                "property": "Fac",
                "overridable": true
            },
            {
                "max": "1",
                "min": "0",
                "name": "normal map strength",
                "node": "Normal Map",
                "type": "number",
                "default": "0",
                "property": "Strength",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:35:21.000Z",
        "updated_at": "2024-02-05T07:49:01.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518608156821",
        "name": "Brick Wall",
        "type": null,
        "blend_material": "brick_wall",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.1",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "name": "normal map",
                "node": "Image Texture.001",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "max": "1",
                "min": "0",
                "name": "brightness",
                "node": "Mix Shader",
                "type": "number",
                "default": "0.2",
                "property": "Fac",
                "overridable": true
            },
            {
                "max": "1",
                "min": "0",
                "name": "normal map strength",
                "node": "Normal Map",
                "type": "number",
                "default": "0",
                "property": "Strength",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:35:56.000Z",
        "updated_at": "2024-02-05T07:49:26.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518608183775",
        "name": "Ceramic Tile",
        "type": null,
        "blend_material": "ceramic_tile",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:36:23.000Z",
        "updated_at": "2024-02-05T07:50:10.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518608237936",
        "name": "Chrome",
        "type": null,
        "blend_material": "chrome",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0",
                "property": "Roughness",
                "overridable": true
            },
            {
                "max": "1",
                "min": "0",
                "name": "brightness",
                "node": "Layer Weight",
                "type": "number",
                "default": "0.7",
                "property": "Blend",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": null,
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:37:17.000Z",
        "updated_at": "2024-02-22T04:47:28.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518608260880",
        "name": "Copper",
        "type": null,
        "blend_material": "copper",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.1",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "color",
                "node": "Glossy BSDF",
                "type": "color",
                "property": "Color",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "color",
                "type": "color",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:37:40.000Z",
        "updated_at": "2024-02-05T07:51:07.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518608279271",
        "name": "Silver",
        "type": null,
        "blend_material": "silver",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.1",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "color",
                "node": "Glossy BSDF",
                "type": "color",
                "property": "Color",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "color",
                "type": "color",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:37:59.000Z",
        "updated_at": "2024-02-05T07:51:24.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518608303021",
        "name": "Gold",
        "type": null,
        "blend_material": "gold",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.1",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "color",
                "node": "Glossy BSDF",
                "type": "color",
                "property": "Color",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "color",
                "type": "color",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.4,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:38:23.000Z",
        "updated_at": "2024-02-05T07:51:47.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518608328254",
        "name": "Etched Glass",
        "type": null,
        "blend_material": "etched_glass",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glass BSDF",
                "type": "number",
                "default": "0",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "color",
                "node": "Glass BSDF",
                "type": "color",
                "property": "Color",
                "overridable": true
            },
            {
                "max": "1000",
                "min": "0",
                "name": "refraction",
                "node": "Glass BSDF",
                "type": "number",
                "default": "1.45",
                "property": "IOR",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1.45,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:38:48.000Z",
        "updated_at": "2024-02-05T07:52:23.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518608402334",
        "name": "PU",
        "type": null,
        "blend_material": "pu",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.02",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "max": "1",
                "min": "0",
                "name": "glossiness",
                "node": "Fresnel",
                "type": "number",
                "default": "1.45",
                "property": "IOR",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:40:02.000Z",
        "updated_at": "2024-02-22T04:47:44.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518608421950",
        "name": "Veneer",
        "type": null,
        "blend_material": "veneer",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.02",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "max": "1",
                "min": "0",
                "name": "glossiness",
                "node": "Fresnel",
                "type": "number",
                "default": "1.45",
                "property": "IOR",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:40:21.000Z",
        "updated_at": "2024-02-05T07:53:19.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1518608492442",
        "name": "Lacquered Finish",
        "type": null,
        "blend_material": "lacquered_finish",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glossy BSDF",
                "type": "number",
                "default": "0.02",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "texture",
                "node": "Image Texture",
                "type": "texture",
                "property": "image",
                "overridable": true
            },
            {
                "max": "1",
                "min": "0",
                "name": "glossiness",
                "node": "Fresnel",
                "type": "number",
                "default": "1.45",
                "property": "IOR",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0.1",
                "overridable": true
            },
            {
                "name": "texture",
                "type": "texture",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-14T11:41:32.000Z",
        "updated_at": "2024-02-22T04:49:00.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1",
        "shard_id": 1
    },
    {
        "id": "1519459767883",
        "name": "Acrylic",
        "type": null,
        "blend_material": "acrylic",
        "blender_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "roughness",
                "node": "Glass BSDF",
                "type": "number",
                "default": "0",
                "property": "Roughness",
                "overridable": true
            },
            {
                "name": "color",
                "node": "Diffuse BSDF",
                "type": "color",
                "property": "Color",
                "overridable": true
            },
            {
                "max": "10",
                "min": "0",
                "name": "refraction",
                "node": "Glass BSDF",
                "type": "number",
                "default": "1.49",
                "property": "IOR",
                "overridable": true
            }
        ],
        "portal_properties": [
            {
                "max": "1",
                "min": "0",
                "name": "shininess",
                "type": "number",
                "default": "0",
                "overridable": true
            },
            {
                "name": "color",
                "type": "color",
                "overridable": true
            }
        ],
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2018-02-24T08:09:27.000Z",
        "updated_at": "2024-02-22T04:40:51.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "7",
        "shard_id": 1
    },
    {
        "id": "1c8f4d57d4a788aa",
        "name": "Danish Oil Varnish",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:11:29.000Z",
        "updated_at": "2024-02-07T12:11:29.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "1fc1c02ef26f8204",
        "name": "Polyester",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.4,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:04:11.000Z",
        "updated_at": "2024-02-07T12:04:11.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "23e0f224c7275fb6",
        "name": "Semi Gloss Paint",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:16:54.000Z",
        "updated_at": "2024-02-22T04:50:30.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "27e0f75ed3667245",
        "name": "French Polish",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.3,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:08:31.000Z",
        "updated_at": "2024-02-07T12:08:31.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "2eafc87d461d6a46",
        "name": "Medium Gloss",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T11:39:20.000Z",
        "updated_at": "2024-02-07T11:40:17.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "3771131d4aeed296",
        "name": "Silk",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:01:57.000Z",
        "updated_at": "2024-02-07T12:01:57.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "3a285eb5e3864933",
        "name": "Unglazed Finish Tile",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:15:43.000Z",
        "updated_at": "2024-02-07T12:15:43.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "3cebe142ed891e8f",
        "name": "Shiny Plastic",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.2,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:12:30.000Z",
        "updated_at": "2024-02-07T12:12:30.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "3e98c7c886125470",
        "name": "Low Lustrous Metal",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T11:45:55.000Z",
        "updated_at": "2024-02-07T11:45:55.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "41993e80d3b16ee0",
        "name": "Polished Finish Tile",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.2,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:16:08.000Z",
        "updated_at": "2024-02-07T12:16:08.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "422d8130c5369129",
        "name": "Lenseed Oil Varnish",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:10:32.000Z",
        "updated_at": "2024-02-07T12:10:32.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "482fa6d34a727732",
        "name": "Gloss Tile",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.2,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:14:18.000Z",
        "updated_at": "2024-02-07T12:14:18.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "536b79190e772600",
        "name": "Crepe",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:04:49.000Z",
        "updated_at": "2024-02-07T12:04:49.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "5646f33d98e23d12",
        "name": "High Lustrous Metal",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.2,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T11:47:18.000Z",
        "updated_at": "2024-02-07T11:47:18.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "5fcec3814f407f58",
        "name": "Wool",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:00:31.000Z",
        "updated_at": "2024-02-07T12:00:31.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "62476c28ba115031",
        "name": "Ceder Oil Varnish",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:07:26.000Z",
        "updated_at": "2024-02-07T12:07:26.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "6320d8ebd97235c3",
        "name": "Oil Paint",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.3,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:20:43.000Z",
        "updated_at": "2024-02-07T12:20:43.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "646abf5db7050b9",
        "name": "Tung Oil Varnish",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:10:04.000Z",
        "updated_at": "2024-02-07T12:10:04.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "671dddc7e4d4fef1",
        "name": "Matte Tile",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:14:43.000Z",
        "updated_at": "2024-02-07T12:14:43.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "7a3742a6cab6ed3f",
        "name": "Semi Gloss Plastic",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.4,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:13:00.000Z",
        "updated_at": "2024-02-07T12:13:00.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "7eb55e55083e45e5",
        "name": "Basalt",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:22:21.000Z",
        "updated_at": "2024-02-07T12:22:21.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "7f42e86d72a3b568",
        "name": "Enamel Paint",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:21:02.000Z",
        "updated_at": "2024-02-07T12:21:02.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "82c6a0f291f18552",
        "name": "Emissive",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1000,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 10,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2022-10-12T18:28:36.000Z",
        "updated_at": "2024-02-05T07:54:17.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "89c7101242ca4965",
        "name": "Suede Leather",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:27:08.000Z",
        "updated_at": "2024-02-07T12:27:08.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "96100b0de31fbd2b",
        "name": "Ceder Oil Varnish",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:11:55.000Z",
        "updated_at": "2024-02-07T12:11:55.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "9e0fbeff0cc5aa93",
        "name": "Concrete",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:22:45.000Z",
        "updated_at": "2024-02-07T12:22:45.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "9f99bbd41009f954",
        "name": "Bituminous Paint",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.4,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:21:59.000Z",
        "updated_at": "2024-02-07T12:21:59.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "a211b168a94bd068",
        "name": "Low Gloss",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T11:37:55.000Z",
        "updated_at": "2024-02-07T11:37:55.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "a3fdfb04b9318d02",
        "name": "Clear Glass with partial reflection",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1.45,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T11:55:22.000Z",
        "updated_at": "2024-02-07T11:55:22.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "ade820483da110f8",
        "name": "Medium Lustrous Metal",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.4,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T11:46:44.000Z",
        "updated_at": "2024-02-07T11:46:44.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "b037674b533658e",
        "name": "Cotton",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:03:04.000Z",
        "updated_at": "2024-02-07T12:03:04.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "b24ebff020870c3c",
        "name": "Satin",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:01:20.000Z",
        "updated_at": "2024-02-07T12:01:20.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "ba9d56dee3967ac0",
        "name": "Clear Glass with full reflection",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1.45,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T11:56:18.000Z",
        "updated_at": "2024-02-07T11:56:18.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "be73a2033ce7f7ca",
        "name": "Wax",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:07:56.000Z",
        "updated_at": "2024-02-07T12:07:56.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "c1dbdce0c1c9f727",
        "name": "Eggshell",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.4,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:20:20.000Z",
        "updated_at": "2024-02-07T12:20:20.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "c88c5837815971a9",
        "name": "Matte Plastic",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.4,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:13:29.000Z",
        "updated_at": "2024-02-07T12:13:29.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "ccf7a0fa381626f9",
        "name": "Generic Fabric",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T11:59:29.000Z",
        "updated_at": "2024-02-07T11:59:29.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "d24fd6f2e6741e2f",
        "name": "Dull Plastic",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:13:55.000Z",
        "updated_at": "2024-02-07T12:13:55.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "d3e94bac82f76d12",
        "name": "Aniline Leather",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:24:41.000Z",
        "updated_at": "2024-02-07T12:24:41.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "d9a2a59a91ebf9f9",
        "name": "Denim",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:02:29.000Z",
        "updated_at": "2024-02-07T12:02:29.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "db7b51c74b40557",
        "name": "Shellac",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.4,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:05:48.000Z",
        "updated_at": "2024-02-07T12:05:48.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "ddf12a01fc58f41d",
        "name": "Water Based Finish",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:09:29.000Z",
        "updated_at": "2024-02-07T12:09:29.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "e11400072dea48fe",
        "name": "Semi-Aniline Leather",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:24:18.000Z",
        "updated_at": "2024-02-07T12:24:18.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "e5436b5dea97853f",
        "name": "Clear Glass with no reflection",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1.45,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T11:52:26.000Z",
        "updated_at": "2024-02-07T11:56:30.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "e8ec248eb051e98a",
        "name": "High Gloss",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T11:40:58.000Z",
        "updated_at": "2024-02-22T04:49:23.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "f2b696b80a8ed07",
        "name": "Emulsion Paint",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.5,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.8,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:21:27.000Z",
        "updated_at": "2024-02-07T12:21:27.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "f43b518226b57f29",
        "name": "Honed Finish Tile",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.7,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:15:16.000Z",
        "updated_at": "2024-02-07T12:15:16.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "f88746a58672826b",
        "name": "Velvet",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 0.9,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.6,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:03:32.000Z",
        "updated_at": "2024-02-07T12:03:32.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    },
    {
        "id": "fc6810863c75c9c",
        "name": "High Gloss Paint",
        "type": null,
        "blend_material": null,
        "blender_properties": null,
        "portal_properties": null,
        "properties": [
            {
                "label": "texture_wrapping",
                "value": {
                    "type": "string_enum",
                    "label": "Texture Wrapping",
                    "value": "repeat",
                    "exposed": true,
                    "purpose": "",
                    "string_enum": [
                        {
                            "label": "Repeat",
                            "value": "repeat"
                        },
                        {
                            "label": "Mirrored Repeat",
                            "value": "mirrored_repeat"
                        }
                    ]
                },
                "delete": false
            },
            {
                "label": "texture_width",
                "value": {
                    "type": "double",
                    "label": "Texture Width",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "texture_height",
                "value": {
                    "type": "double",
                    "label": "Texture Height",
                    "value": 1,
                    "exposed": true,
                    "purpose": "distance",
                    "min_value": 1
                },
                "delete": false
            },
            {
                "label": "color",
                "value": {
                    "type": "color",
                    "label": "Base color",
                    "value": {
                        "b": 1,
                        "g": 1,
                        "r": 1
                    },
                    "exposed": true,
                    "purpose": ""
                },
                "delete": false
            },
            {
                "label": "opacity",
                "value": {
                    "type": "double",
                    "label": "Opacity",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "specular",
                "value": {
                    "type": "double",
                    "label": "Specular",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "roughness",
                "value": {
                    "type": "double",
                    "label": "Roughness",
                    "value": 0.3,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "metalness",
                "value": {
                    "type": "double",
                    "label": "Metalness",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "refractive_index",
                "value": {
                    "type": "double",
                    "label": "Refractive index",
                    "value": 1,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "clearcoat",
                "value": {
                    "type": "double",
                    "label": "Clearcoat",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "transmission",
                "value": {
                    "type": "double",
                    "label": "Transmission",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 1,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "bump_scale",
                "value": {
                    "type": "double",
                    "label": "Bump map scale",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "normal_map_strength",
                "value": {
                    "type": "double",
                    "label": "Normal map strength",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 10000,
                    "min_value": 0
                },
                "delete": false
            },
            {
                "label": "map",
                "value": {
                    "type": "string",
                    "label": "Base texture",
                    "value": null,
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "ao_map",
                "value": {
                    "type": "string",
                    "label": "Ambient occlusion map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "bump_map",
                "value": {
                    "type": "string",
                    "label": "Bump map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "normal_map",
                "value": {
                    "type": "string",
                    "label": "Normal map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "metalness_map",
                "value": {
                    "type": "string",
                    "label": "Metalness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "roughness_map",
                "value": {
                    "type": "string",
                    "label": "Roughness map",
                    "value": "",
                    "exposed": true,
                    "purpose": "image"
                },
                "delete": false
            },
            {
                "label": "emissive_intensity",
                "value": {
                    "type": "double",
                    "label": "Emissive Intensity",
                    "value": 0,
                    "exposed": true,
                    "purpose": "number",
                    "max_value": 100,
                    "min_value": 0
                },
                "delete": false
            }
        ],
        "created_at": "2024-02-07T12:16:29.000Z",
        "updated_at": "2024-02-22T04:50:12.000Z",
        "deleted_at": null,
        "user_id": "48",
        "store_id": "1504672400687",
        "shard_id": 1
    }
]