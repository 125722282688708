import React, { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import custom_sort from '../../utils/custom_sort';
import split_cat_type from '../../utils/split_cat_type';
import { Modal, CardImg, Alert, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import { useAlert } from 'react-alert'
import Multiselect from "../../components/Multiselect/";
import HWKeyValuePair_range from '../../components/HWKeyValuePair_range';
import HWLinked_sku_structure from '../../components/HWLinked_sku_structure copy';
import EscapeWarning from '../../components/EscapeWarning';
import { UserContext } from '../../components/Contexts/UserContext';
import SkuSelectionModal from '../../components/SkuSelectionModal';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { Tooltip } from 'antd';
import debug_log from '../../utils/debug_log';
import { IModalBody, IModalFooter, IModalHeader } from '../../components/IModal';
import IButton from '../../components/IButton';

// import AlertBox from "../../components/AlertBox/";


//generate a 20 character id alphanumerically lesser than the one provided
const generate_id_greater_than = (id) => {
    debug_log('id generated in comparison to  ==>> ', id);
    const id_length = 13;
    const char_list = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let text = "";
    let randomise = false;
    for (let i = 0; i < id_length; i++) {
        debug_log('Generated ID char list ', char_list, randomise)
        let valid_char_list = char_list;
        if (randomise) {
            text += valid_char_list.charAt(Math.floor(Math.random() * valid_char_list.length));
            continue;
        }
        if (id) {
            valid_char_list = char_list.split('').filter(x => x > id[i] || x == id[i]).reduce((a, b) => a + b, '');
            debug_log('Generated ID VALID CHAR LIST', valid_char_list);
        } else {
            valid_char_list = '0123456789';
        }
        if (valid_char_list.length > 1) {
            text += valid_char_list[1];
        } else {
            text += valid_char_list[0];
        }
        if (id < text) {
            randomise = true;
        }
    }
    debug_log("GENERATED ID", text);
    return text;
}

const shelf_type_values = [{ name: 'Fixed Shelf', id: 'fixed' }, { name: 'Adjustable Shelf', id: 'adjustable' }]
const shutter_mechanism_values = [
    { name: 'Single Swing', id: 'single_swing', option_type: 'cabinet' },
    { name: 'Double Swing', id: 'double_swing', option_type: 'cabinet' },
    { name: 'Single Lift Up', id: 'single_lift_up', option_type: 'cabinet' },
    { name: 'Double Lift Up', id: 'double_lift_up', option_type: 'cabinet' },
    { name: 'Flap Down', id: 'flap_down', option_type: 'cabinet' },
    // {name:'Bi-fold', id:'bi_fold', option_type:'cabinet'},
    { name: 'Left Bi-fold', id: 'left_bifold', option_type: 'cabinet' },
    { name: 'Right Bi-fold', id: 'right_bifold', option_type: 'cabinet' },
    { name: 'Bi-corner Unit Double Swing', id: 'corner_unit_double_swing', option_type: 'cabinet' },
    { name: 'Rolling', id: 'rolling_shutter', option_type: 'cabinet' },
    { name: 'None', id: 'single_none', option_type: 'cabinet' },
    { name: 'Single Swing', id: 'single_swing', option_type: 'wardrobe' },
    { name: 'Double Swing', id: 'double_swing', option_type: 'wardrobe' },
    { name: 'Rolling', id: 'rolling_shutter', option_type: 'wardrobe' },
    { name: 'None', id: 'single_none', option_type: 'wardrobe' },
    { name: '2 door Sliding Shutter', id: '2_door_sliding_shutter', option_type: 'wardrobe' },
    { name: '3 door Sliding Shutter', id: '3_door_sliding_shutter', option_type: 'wardrobe' },
    { name: '4 door Sliding Shutter', id: '4_door_sliding_shutter', option_type: 'wardrobe' },
    { name: '5 door Sliding Shutter', id: '5_door_sliding_shutter', option_type: 'wardrobe' },
    { name: '6 door Sliding Shutter', id: '6_door_sliding_shutter', option_type: 'wardrobe' }
]

const HWRulesModal = ({ open, handleClose, active_rule, is_active_rule_valid, designer_inputs, all_brands, fetch_hw_rules_details, mode, set_page_loader, all_division_tree_data, component_options_json, component_tags_json, tags, all_category_type, last_item_order, sales_channels }) => {

    const [rule_name, set_rule_name] = useState("");
    const [rule_description, set_rule_description] = useState("");
    const [rule_activation, set_rule_activation] = useState(false);

    const [condition_combination_logic, set_condition_combination_logic] = useState("and");
    const [applicability_combination_logic, set_applicability_combination_logic] = useState("and");

    // Actions
    const [linked_item_json, set_linked_item_json] = useState([]);
    // Applicabilty
    const [item_json_source, set_item_json_source] = useState([]);
    // Conditions
    const [item_json_dest, set_item_json_dest] = useState([]);
    
    // const [active_rule_copy, set_active_rule_copy] = useState('');
    const [item_json_dest_component, set_item_json_dest_component] = useState([]);
    const [item_json_dest_subcomponent, set_item_json_dest_subcomponent] = useState([])
    const [all_eligible_cat_type, set_all_eligible_cat_type] = useState([]);
    const [all_eligible_designer_inputs, set_all_eligible_designer_inputs] = useState([]);

    // Designer Input
    const [valid_inputs, set_valid_inputs] = useState(null);

    const [rule_input_values, set_rule_input_values] = useState([]);
    const [selected_category_type, set_selected_category_type] = useState([]);
    // const [discard_changes, set_discard_changes] = useState(false)
    const [show_escape_warning, set_show_escape_warning] = useState(false)

    // Designer Input Initialized
    const [initialized, set_initialized] = useState(0);

    // SKU Applicability
    const [is_sku_selection_modal_open, set_is_sku_selection_modal_open] = useState(false);
    const open_sku_selection_modal = () => set_is_sku_selection_modal_open(true)
    const close_sku_selection_modal = () => set_is_sku_selection_modal_open(false)
    const [sku_selection_map, set_sku_selection_map] = useState([]);

    const [trigger_red_marker, set_trigger_red_marker] = useState(false);
    const [value_red_marker, set_value_red_marker] = useState(false);

    const alert = useAlert()
    const user = useContext(UserContext)
    const org_bu_view = useContext(OrgBuContext)

    useEffect(() => {
		if(mode != 'add') set_trigger_red_marker(!is_active_rule_valid);
        else set_trigger_red_marker(false)
	}, [mode])

    // -------- Approved
    useEffect(() => {
        if(valid_inputs){
            debug_log("Designer Input Updated to - ", valid_inputs);
            if(initialized){
                set_rule_input_values([]);
                set_linked_item_json([])
            }else{
                set_initialized(true)
            }
            if(valid_inputs.length && selected_category_type !== valid_inputs[0].sku_category_type_id){
                set_selected_category_type(valid_inputs[0].sku_category_type_id)
            }
        }
    }, [valid_inputs])

    useEffect(() => {
        debug_log("Updated rule input values", rule_input_values)
    }, [rule_input_values]);

    useEffect(() => {
        debug_log("Updated Actions ", linked_item_json)
    }, [linked_item_json]);

    useEffect(() => {
        debug_log("Updated Conditions ", item_json_dest)
    }, [item_json_dest]);

    useEffect(() => {
        debug_log("Updated Applicability ", item_json_source)
    }, [item_json_source]);

    // -------- Approved
    // Adding New Designer Input Condition creates new Rule Input Value Id
    const add_new_input_value = () => {
        if (valid_inputs && valid_inputs.length) {
            // let rule_input_value_id = generate_id(rule_input_values.length?rule_input_values[rule_input_values.length-1].id:"");
            let rule_input_value_id = generate_id_greater_than(rule_input_values.length ? rule_input_values[rule_input_values.length - 1].id : "");
            debug_log("New Rule Input Value Created with ID - ", rule_input_value_id);
            
            set_rule_input_values([
                ...rule_input_values,
                {
                    id: rule_input_value_id,
                    value: valid_inputs.map(valid_input => ({
                        rule_input_id: valid_input.id,
                        value: valid_input.valid_values && valid_input.valid_values.length ? valid_input.valid_values[0] : '',
                    }))
                }
            ]);
            return rule_input_value_id;
        }

        return
    }

    // -------- Approved
    // Updating Designer Input rule_input_id for Designer Input Condition rule_input_value_id to value
    const update_input_value = (rule_input_value_id, rule_input_id, value) => {
        set_rule_input_values(rule_input_values.map(rule_input_value => {
            if (rule_input_value.id === rule_input_value_id) {
                rule_input_value.value = rule_input_value.value.map(rule_input_value_item => {
                    if (rule_input_value_item.rule_input_id === rule_input_id) {
                        rule_input_value_item.value = value;
                    }
                    return rule_input_value_item;
                });
            }
            return rule_input_value;
        }));
    }

    const get_value = (x_item) => {
        let value;
        if (x_item.value && (Array.isArray(x_item.value) && x_item.value.length)) {
            if (x_item.subcomponent && x_item.subcomponent[0] && (x_item.subcomponent[0].id == 'panel1' || x_item.subcomponent[0].id == 'panel2') && x_item.property && x_item.property[0] && x_item.property[0].id == 'shutter_mechanism') {
                value = (x_item.value.length ? JSON.stringify(x_item.value.map(x => x.id)) : [])
            } else {
                value = (x_item.value.length ? (x_item.value[0].id) : [])
            }
        } else {
            value = (isNaN(x_item.value) ? x_item.value : Number(x_item.value))
        }

        return value;
    }

    // const invalid_rule = () => {
    //     let value
    // }

    const transform_to_usable = (item_array, key) => {
        if (item_array && item_array.length) {
            var x_temp = item_array.map(x_item => {
                return ({
                    id: x_item && x_item.id ? x_item.id : null,
                    value: get_value(x_item),
                    min_value: (x_item.min_value || x_item.min_value == 0) ? Number(x_item.min_value) : '',
                    max_value: (x_item.max_value || x_item.max_value == 0) ? Number(x_item.max_value) : '',
                    type: x_item.condition_type ? x_item.condition_type : 'absolute',
                    index_value: x_item.index_value,
                    operator: x_item.operator,
                    value_combination_logic: x_item.value_combination_logic,

                    sku: ((key != 'actions' && x_item.property && x_item.property.length && x_item.property[0].id == 'sku') || (key == 'actions')) && x_item.sku && x_item.sku.length ? x_item.sku.map(x => x.id) : [],
                    tag: x_item.property && x_item.property.length && x_item.property[0].id == 'tag' && x_item.tag && x_item.tag.length ? x_item.tag.map(x => x.id) : null,
                    sales_channel: x_item.property && x_item.property.length && x_item.property[0].id == 'sales_channel' && x_item.sales_channel && x_item.sales_channel.length ? x_item.sales_channel.map(x => x.id) : null,
                    
                    source_filter: (x_item.component && x_item.component.length) ? ({
                        id: x_item.source_filter && x_item.source_filter.id ? x_item.source_filter.id : null,
                        type: 'source',
                        component: x_item.component && x_item.component.length ? x_item.component[0].id : [],
                        sub_component: x_item.subcomponent && x_item.subcomponent.length ? x_item.subcomponent[0].id : [],
                        property: x_item.property && x_item.property.length ? x_item.property[0].id : [],
                    }) : null,
                    
                    destination_filter: (x_item.relativecomponent && x_item.relativecomponent.length) ? ({
                        type: 'destination',
                        component: x_item.relativecomponent && x_item.relativecomponent.length ? x_item.relativecomponent[0].id : [],
                        sub_component: x_item.relativesubcomponent && x_item.relativesubcomponent.length ? x_item.relativesubcomponent[0].id : [],
                        property: x_item.relativeproperty && x_item.relativeproperty.length ? x_item.relativeproperty[0].id : [],
                    }) : null,
                    
                    additional_sku_metadata: x_item.additional_sku_metadata,
                    ...(x_item.rule_input_value_id ? { rule_input_value_id: x_item.rule_input_value_id } : {}),
                    ...(x_item.default_action ? { default_action: x_item.default_action } : {}),
                })
            })
            // console.log("kppp x_temp", x_temp)
            for (let i = 0; i < x_temp.length; i++) {
                if (x_temp[i].source_filter) {
                    if (x_temp[i].source_filter.property == 'tag') {
                        console.log('transform_to_usablee tag')
                        if (!(x_temp[i].tag && x_temp[i].tag.length >= 1 && typeof x_temp[i].tag[0] === 'string')) {set_value_red_marker('tag'); return -1}
                    } else if(['panel_type', 'prelam_type'].includes(x_temp[i].source_filter.property)){
						if(!(x_temp[i].value && x_temp[i].value.length && typeof x_temp[i].value[0] === 'string')) return -1
					} else if (x_temp[i].source_filter.property == 'sku' && x_temp[i].type == 'absolute') {
                        console.log('transform_to_usablee sku', x_temp)
                        if (!(x_temp[i].sku && x_temp[i].sku.length == 1 && typeof x_temp[i].sku[0] === 'string')) {set_value_red_marker('sku'); return -1}
                    } else if (x_temp[i].source_filter.property == 'height' || x_temp[i].source_filter.property == 'width' || x_temp[i].source_filter.property == 'depth') {
                        console.log('transform_to_usablee height')
                        if (!(x_temp[i].value >= 0 && typeof x_temp[i].value === 'number')) {set_value_red_marker('number'); return -1;}
                    } else if (x_temp[i].source_filter.property == 'sales_channel') {
                        console.log('transform_to_usablee sales_channel')
                        if (!(x_temp[i].sales_channel && x_temp[i].sales_channel.length == 1 && typeof x_temp[i].sales_channel[0] === 'string')) {set_value_red_marker('sales_channel'); return -1;}
                    } else {
                        if(!x_temp[i].value && ![0, false].includes(x_temp[i].value)) {
                            // console.log("Kppp issue is", x_temp[i])
                            set_value_red_marker('empty')
                            return -1
                        }
                    }
                }
            }

            debug_log(item_array, 'transforms to ', x_temp)
            return x_temp;
        } else {
            return []
        }
    }

    const check_fn_for_floor = () => {
        if (item_json_dest && item_json_dest.length && item_json_source && item_json_source.length) {
            if (item_json_source[0].component[0].id === 'floor' && item_json_dest[0].component[0].id != 'floor') {
                alert.error("If Applicable component is floor then Condition component must be floor")
                return true
            }
            if (item_json_source[0].component[0].id === 'cabinet' && item_json_dest[0].component[0].id === 'floor') {
                alert.error("If Applicable component is cabinet then Condition component cannot be floor")
                return true
            }
            return false
        }
    }

    const check_sku_metadata = (actions) => {
        for (let i = 0; i < actions.length; i++) {
            if (actions[i].additional_sku_metadata) {
                console.log('additional_sku_metadata', actions[i].additional_sku_metadata)
                let sku_id = actions[i].sku[0]
                let metadata_array = actions[i].additional_sku_metadata[sku_id]
                let not_set_exists1 = 0, value_exists1 = 0, not_set_exists2 = 0, value_exists2 = 0, check_panel_face = 0
                for (let j = 0; j < metadata_array.length; j++) {
                    if (metadata_array[j].hasOwnProperty('distance1')) check_panel_face = 1
                    if (!metadata_array[j].distance) {
                        console.log('additional_sku_metadata99999999')
                        not_set_exists1 = 1
                    } else {
                        console.log('additional_sku_metadata88888888')
                        value_exists1 = 1
                    }

                    if (metadata_array[j].from === 'not_set') {
                        console.log('additional_sku_metadata777777')
                        not_set_exists1 = 1
                    } else {
                        console.log('additional_sku_metadata6666666')
                        value_exists1 = 1
                    }

                    if (!metadata_array[j].distance1) {
                        console.log('additional_sku_metadata555555')
                        not_set_exists2 = 1
                    } else {
                        console.log('additional_sku_metadata44444')
                        value_exists2 = 1
                    }

                    if (metadata_array[j].from1 === 'not_set') {
                        console.log('additional_sku_metadata333333')
                        not_set_exists2 = 1
                    } else {
                        console.log('additional_sku_metadata22222')
                        value_exists2 = 1
                    }

                    if (metadata_array[j].hasOwnProperty('panel_face') && (!metadata_array[j].panel_face || metadata_array[j].panel_face === 'not_set')) {
                        alert.error('Panel face is mandatory')
                        return 0
                    }

                    // if(metadata_array[j].distance) metadata_array[j].distance = Number(metadata_array[j].distance)
                    // if(metadata_array[j].distance1)metadata_array[j].distance1 = Number(metadata_array[j].distance1)
                }

                if ((not_set_exists1 && value_exists1) || (check_panel_face && not_set_exists2 && value_exists2)) {
                    alert.error('Enter all "distance" and "from" fields for a given SKU or set all to not_set')
                    return 0
                }

            }
        }
        return 1
    }

    const onclick_add_new_rule = async () => {
        if (!(is_valid_add_key(item_json_source, 'applicable') && is_valid_add_key(item_json_dest, 'condition') && is_valid_add_key(linked_item_json, 'link_sku'))) {
            set_trigger_red_marker(true)
            alert.error("Empty or Duplicate key values not allowed.")
            return
        } else if (check_fn_for_floor()) {
            return
        } else {
            if (rule_name && rule_description) {
                try {
                    var temp_order = last_item_order + 1024;

                    let applicability = transform_to_usable(item_json_source)
                    let conditions = transform_to_usable(item_json_dest)
                    let actions = transform_to_usable(linked_item_json, 'actions')

                    if (applicability == -1 || conditions == -1 || actions == -1) {
                        set_trigger_red_marker(true)
                        alert.error('Please fill all required details: name, description, applicable and action fields ')
                        return
                    }

                    if (!check_sku_metadata(actions)) return

                    var body = {
                        sku_linked_rule_type: 'bu_level',
                        bu_level_linked_rule_sku_ids: sku_selection_map && sku_selection_map.length ? sku_selection_map : [],
                        applicability_combination_logic,
                        applicability: applicability,
                        conditions: conditions,
                        actions: actions,
                        condition_combination_logic: condition_combination_logic,
                        name: rule_name,
                        description: rule_description,
                        active: rule_activation,
                        type: 'hardware_rule',
                        action_combination_logic: 'and',
                        valid_rule_inputs: valid_inputs && valid_inputs.length ? valid_inputs.map(x => x.id) : [],
                        rule_input_values: rule_input_values.filter(x => x.value && x.value.length && x.id && actions.some(action => action.rule_input_value_id === x.id)),
                        order: last_item_order + 1024,
                        // business_unit_id:user.current_business_unit_id
                    };
                    if (org_bu_view == 'bu') {
                        body["business_unit_id"] = user.current_business_unit_id
                    }
                    console.log("firing api", body);
                    var response = await general_fetch({ url: 'automation_rule/create', body });
                    onclick_handleclose()
                    alert.success('Rule created Successfully !')
                    fetch_hw_rules_details()
                } catch (err) {
                    console.log(err);
                    alert.error("Please check input values or API call failed")
                }
            } else {
                set_trigger_red_marker(true);
                alert.error('Please fill all required details: name, description, applicable and action fields ')
            }
        }
    }

    const onclick_edit_rule = async () => {
        if (!(is_valid_add_key(item_json_source, 'applicable') && is_valid_add_key(item_json_dest, 'condition') && is_valid_add_key(linked_item_json, 'link_sku'))) {
            set_trigger_red_marker(true)
            alert.error("Empty or Duplicate key values not allowed.")
        } else if (check_fn_for_floor()) {

        } else {
            if (rule_name && active_rule && rule_description) {
                try {

                    let applicability = transform_to_usable(item_json_source)
                    let conditions = transform_to_usable(item_json_dest)
                    let actions = transform_to_usable(linked_item_json, 'actions')
                    if (applicability == -1 || conditions == -1 || actions == -1) {
                        set_trigger_red_marker(true);
                        // console.log("kpp", applicability, conditions, actions)
                        alert.error('Please fill all required details: name, description, applicable and action fields')
                        return
                    }
                    if (!check_sku_metadata(actions)) return
                    var body = {
                        id: active_rule.id ? active_rule.id : null,
                        sku_linked_rule_type: 'bu_level',
                        bu_level_linked_rule_sku_ids: sku_selection_map && sku_selection_map.length ? sku_selection_map : [],
                        applicability_combination_logic,
                        applicability: applicability,
                        conditions: conditions,
                        actions: actions,
                        condition_combination_logic: condition_combination_logic,
                        name: rule_name,
                        description: rule_description,
                        active: rule_activation,
                        type: 'hardware_rule',
                        action_combination_logic: 'and',
                        valid_rule_inputs: valid_inputs.map(x => x.id),
                        rule_input_values: rule_input_values.filter(x => x.value && x.value.length)
                    };
                    if (org_bu_view == 'bu') {
                        body["business_unit_id"] = user.current_business_unit_id
                    }
                    console.log("firing api", body);
                    var response = await general_fetch({ url: 'automation_rule/update', body });
                    onclick_handleclose()
                    alert.success('Rule Updated Successfully !')
                    fetch_hw_rules_details()
                } catch (err) {
                    console.log(err);
                    alert.error("Please check input values or API call failed")
                }
            } else {
                set_trigger_red_marker(true)
                alert.error('Please fill all required details: name, description, applicable and action fields')
            }
        }
    }

    const is_valid_add_key = (item_json, type) => {
        if (item_json && item_json.length) {
            for (var i = 0; i < item_json.length; i++) {
                if ((type === 'condition' || type === 'applicable') && !(item_json[i].property && item_json[i].property.length)) {
                    return false;
                } else if (type === 'link_sku' && !(linked_item_json[i].sku && linked_item_json[i].sku.length)) {
                    return false;
                }
            }
            return true;
        } else {
            return true;
        }
    }

    

    const add_new_item_json = (x_item_json, type) => {
        if (is_valid_add_key(x_item_json, type)) {
            let a = [...x_item_json];
            let curr_time = Date.now();
            // a.push({condition_type:'absolute',component:[], subcomponent:[], property:[] ,relativecomponent:[], relativesubcomponent:[], relativeproperty:[], operator:'equal', division:[], category:[], sub_cat:[], group:[], sku:[], range_max:'', range_min:'', value:[], tag:[], value_combination_logic:'and'})
            if (type === 'condition') {
                if (x_item_json && x_item_json.length > 0 && x_item_json[0].subcomponent && x_item_json[0].subcomponent.length) {
                    a.push({temp_key: curr_time, condition_type: 'absolute', component: item_json_dest_component ? item_json_dest_component : [], subcomponent: item_json_dest_subcomponent ? item_json_dest_subcomponent : [], property: [], relativecomponent: [], relativesubcomponent: [], relativeproperty: [], operator: 'equal', division: [], category: [], sub_cat: [], group: [], sku: [], range_max: '', range_min: '', value: [], tag: [], value_combination_logic: 'and', index_value: 'reset' })
                } else {
                    a.push({temp_key: curr_time, condition_type: 'absolute', component: [], subcomponent: [], property: [], relativecomponent: [], relativesubcomponent: [], relativeproperty: [], operator: 'equal', division: [], category: [], sub_cat: [], group: [], sku: [], range_max: '', range_min: '', value: [], tag: [], value_combination_logic: 'and', index_value: 'reset' })
                }
                set_item_json_dest(a)
            } else {
                a.push({temp_key: curr_time, condition_type: 'absolute', component: [], subcomponent: [], property: [], relativecomponent: [], relativesubcomponent: [], relativeproperty: [], operator: 'equal', division: [], category: [], sub_cat: [], group: [], sku: [], range_max: '', range_min: '', value: [], tag: [], value_combination_logic: 'and' })
                set_item_json_source(a)
            }
        } else {
            alert.error("Empty or Duplicate key values not allowed.")
        }
    }

    // Add Applicability when Empty
    useEffect(() => {
        if (item_json_source && !item_json_source.length) {
            add_new_item_json(item_json_source, 'applicable')
        }
    }, [item_json_source])

    // Add Condition when Empty
    useEffect(() => {
        if (item_json_dest && !item_json_dest.length) {
            add_new_item_json(item_json_dest, 'condition')
        } else {
            set_item_json_dest_component(item_json_dest[0].component ? item_json_dest[0].component : [])
            set_item_json_dest_subcomponent(item_json_dest[0].subcomponent ? item_json_dest[0].subcomponent : [])
        }
    }, [item_json_dest])

    
    // -------- Approved
    const onclick_handleclose = () => {
        // fetch_hw_rules_details()
        handleClose()

        set_rule_name()
        set_rule_description('')
        set_rule_activation(false)

        set_item_json_source([])
        set_item_json_dest([])
        set_linked_item_json([])

        // set_all_eligible_cat_type([])
        set_all_eligible_designer_inputs([])
        set_selected_category_type([])
        set_valid_inputs(null)
        set_rule_input_values([])

        set_sku_selection_map([])
        set_trigger_red_marker(false)
        set_value_red_marker(false)

        set_initialized(0)
    }

    // -------- Approved
    const show_escape_warning_dialog = () => {
        set_show_escape_warning(true)
    }

    // -------- Approved
    const close_escape_warning_dialog = () => {
        set_show_escape_warning(false)
    }

    // -------- Approved - Can Improve Actions Though
    useEffect(() => {
        // Initializing for Active Rule
        if (active_rule) {
            debug_log('Initializing Active Hw Rule - ', active_rule);

            // Setting Linked Skus for the Rule
            if (active_rule.bu_level_linked_skus && active_rule.bu_level_linked_skus.length) {
                set_sku_selection_map(active_rule.bu_level_linked_skus.map(x => x.id))
            }

            // Setting meta data - Name, Description, Rule Activation
            set_rule_name(active_rule.name)
            set_rule_description(active_rule.description)
            set_rule_activation(active_rule.active ? true : false)

            // Setting Combination Logic - Applicabilty, Condition
            set_condition_combination_logic(active_rule.condition_combination_logic)
            set_applicability_combination_logic(active_rule.applicability_combination_logic)

            // Setting Applicabity
            let x_source_list
            if (active_rule.applicability && active_rule.applicability.length) {
                x_source_list = JSON.parse(JSON.stringify(active_rule.applicability)).map((x_item, idx) => {
                    // component_tags_json is the Struct used for Defining HW Rules Applicability Conditions
                    // todo - Can Handle the below better with proper checks
                    let x_component = component_tags_json ? component_tags_json.filter(x => x.id === x_item.source_filter.component) : []
                    let x_sub_component = x_component && x_component.length && x_component[0].sub_component.length ? x_component[0].sub_component.filter(x => x.id === x_item.source_filter.sub_component) : []
                    let x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length ? x_sub_component[0].properties.filter(x => x.id === x_item.source_filter.property) : []

                    if (x_selected_property && x_selected_property.length && x_selected_property[0].id === 'tag') {
                        // todo - Deleted tags are automatically removed in the below piece of code. Can ahow information to user
                        let x_selected_value_list = tags && tags.length ? tags.filter(x => x_item.tag.includes(x.id)) : []
                        x_item.tag = x_selected_value_list
                    }

                    if (x_selected_property && x_selected_property.length && x_selected_property[0].id === 'sales_channel') {
                        let x_selected_value_list = sales_channels && sales_channels.length ? sales_channels.filter(x => x_item.sales_channel.includes(x.id)) : []
                        x_item.sales_channel = x_selected_value_list
                    }

                    x_item.component = x_component
                    x_item.subcomponent = x_sub_component
                    x_item.property = x_selected_property

                    debug_log(`Applicability ${idx} extracted from Active Rule - `, x_item)

                    return x_item

                })
            } else {
                // todo - Invalid Rule Handling
            }
            
            // Setting Conditions

            let x_dest_list
            if (active_rule.conditions && active_rule.conditions.length) {
                x_dest_list = JSON.parse(JSON.stringify(active_rule.conditions)).map((x_item, idx) => {
                    // component_options_json is the Struct used for Defining HW Rules Conditions
                    // todo - Can Handle the below better with proper checks
                    let x_component = component_options_json ? component_options_json.filter(x => x.id === x_item.source_filter.component) : []
                    let x_sub_component = x_component && x_component.length && x_component[0].sub_component.length ? x_component[0].sub_component.filter(x => x.id === x_item.source_filter.sub_component) : []
                    let x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length ? x_sub_component[0].properties.filter(x => x.id === x_item.source_filter.property) : []
                    
                    let x_selected_value_list = [];
                    if (x_selected_property && x_selected_property.length && x_selected_property[0].id === 'tag') {
                        x_selected_value_list = tags && tags.length ? tags.filter(x => x_item.tag.includes(x.id)) : []
                        x_item.tag = x_selected_value_list
                    }
                    if (x_selected_property && x_selected_property.length && x_selected_property[0].id === 'shelf_type') {
                        x_selected_value_list = shelf_type_values.filter(x => x_item.value.includes(x.id))
                        x_item.value = x_selected_value_list
                    }
                    if (x_selected_property && x_selected_property.length && x_selected_property[0].id === 'shutter_mechanism') {
                        if (x_sub_component && x_sub_component.length && (x_sub_component[0].id === 'panel1' || x_sub_component[0].id === 'panel2')) {
                            let temp_array = JSON.parse(x_item.value)
                            x_selected_value_list = temp_array.map(x => { return { id: x } })
                        } else {
                            x_selected_value_list = shutter_mechanism_values.filter(x => x_item.value == x.id)
                        }
                        x_item.value = x_selected_value_list
                    }

                    x_item.component = x_component
                    x_item.subcomponent = x_sub_component
                    x_item.property = x_selected_property

                    debug_log(`Condition ${idx} extracted from Active Rule - `, x_item)

                    return x_item

                })
            } else {
                // todo - Invalid Rule Handling
            }

            let deleted_designer_input_values = false


            // Settings Designer Inputs
            if(active_rule.valid_rule_inputs && active_rule.valid_rule_inputs.length){
                // designer_inpts is list of all Designer Inputs
                // todo - Deleted designer inputs are implicitly filtered right now. Can show a message indicating the same
                let valid_rule_inputs = (JSON.parse(JSON.stringify(designer_inputs)).filter(x => active_rule.valid_rule_inputs.includes(x.id)));
                debug_log(`Designer Inputs extracted from Active Rule - `, valid_rule_inputs)
                set_valid_inputs(valid_rule_inputs);
                
                // Settings Rule Input Values
                if (active_rule.rule_input_values && active_rule.rule_input_values.length) {
                    // let valid_rule_input_values = (active_rule.rule_input_values.filter(x =>  x.value && valid_rule_inputs.includes(x.value.rule_input_id)));
                    let valid_rule_input_values = active_rule.rule_input_values
                    // let designer_inputs_ids = designer_inputs.map(x => x.id)
                    // valid_rule_input_values.map(x => {
                    //     if (!(designer_inputs_ids.includes(x))) {
                    //         deleted_designer_input_values = true;
                    //     }
                    // })
                    // todo - Handle when Designer Input is deleted.
                    // todo - Handle when designer input value assigned a give rule input is deleted.
                    if(!deleted_designer_input_values){
                        debug_log(`Rule Inputs extracted from Active Rule - `, valid_rule_input_values)
                        // Removing Duplicate Values
                        let filtered_valid_rule_input_values = valid_rule_input_values.map(x => {
                            let rule_input_id_map = {}
                            x.value = x.value.filter(v => {
                                if(rule_input_id_map[v.rule_input_id]){
                                    return false
                                }else{
                                    rule_input_id_map[v.rule_input_id] = true;
                                    return true
                                }
                            })
                            return x
                        })
                        set_rule_input_values(filtered_valid_rule_input_values)
                    }
                }
            }else{
                set_valid_inputs([])
            }


            let linked_sku_actions;
            let rule_input_value_map = {};
            if (active_rule.actions && active_rule.actions.length) {
                linked_sku_actions = JSON.parse(JSON.stringify(active_rule.actions)).sort((a, b) => (a.rule_input_value_id > b.rule_input_value_id || a.rule_input_value_id == b.rule_input_value_id || !b.rule_input_value_id) ? 1 : -1).map((item, idx) => {

                    // The Below are eligible in case of relative action type
                    var x_component = component_options_json ? component_options_json.filter(x => item.destination_filter && x.id === item.destination_filter.component) : []
                    var x_sub_component = x_component && x_component.length && x_component[0].sub_component.length ? x_component[0].sub_component.filter(x => x.id === item.destination_filter.sub_component) : []
                    var x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length ? x_sub_component[0].properties.filter(x => x.id === item.destination_filter.property) : []

                    let show_conditional_elements = false;
                    if (!rule_input_value_map[item.rule_input_value_id]) {
                        rule_input_value_map[item.rule_input_value_id] = true;
                        show_conditional_elements = true;
                    }

                    let final_action_item = {
                        sku_id: item.sku ? item.sku[0] : '',
                        show_conditional_elements,
                        value: Number(item.value),
                        relativecomponent: x_component ? x_component : [],
                        relativesubcomponent: x_sub_component ? x_sub_component : [],
                        relativeproperty: item.destination_filter && item.destination_filter.property && item.destination_filter.property == 'sku' ? [{ id: 'sku', name: 'SKU' }] : x_selected_property ? x_selected_property : [],
                        index_value: item ? 'per_set' : '',
                        // condition_type:item?'relative':'',
                        condition_type: item && item.type ? item.type : 'absolute',
                        rule_input_value_id: item.rule_input_value_id,
                        additional_sku_metadata: item.additional_sku_metadata,
                        default_action: item.default_action,
                        id: item.id
                    }

                    debug_log(`Action ${idx} extracted from Active Rule - `, final_action_item)

                    return final_action_item
                })
            } else {
                // todo - Invalid rule handling
            }

            if (linked_sku_actions.length) {
                linked_sku_actions = linked_sku_actions.map((x, idx) => ({ ...x, show_addition_element: linked_sku_actions[idx + 1] && linked_sku_actions[idx + 1].rule_input_value_id == x.rule_input_value_id ? false : true, idx: idx }));
            }


            set_item_json_source(x_source_list)
            set_item_json_dest(x_dest_list)
            if(!deleted_designer_input_values){
                set_linked_item_json(linked_sku_actions)
            }
        }

    }, [active_rule])

    // -------- Approved
    useEffect(() => {
        // Calculating Categories for which Designer Input Exist
        var x_list = []
        if (all_category_type && all_category_type.length && designer_inputs && designer_inputs.length) {
            var input_list_cat_ids = designer_inputs.map(x => x.sku_category_type_id)
            x_list = all_category_type.filter(x => input_list_cat_ids.includes(x.id))
        }
        set_all_eligible_cat_type(x_list);
    }, [all_category_type, designer_inputs])

    // -------- Approved
    useEffect(() => {
        // Setting valid Designer Inputs based on Selected Category
        var x_list = []
        if (selected_category_type && designer_inputs && designer_inputs.length) {
            x_list = designer_inputs.filter(x => selected_category_type === x.sku_category_type_id)
        }
        set_all_eligible_designer_inputs(x_list)
    }, [selected_category_type])

    return (
        <Modal centered style={{maxWidth: '95%'}} isOpen={open} toggle={() => { mode == 'view' ? onclick_handleclose() : show_escape_warning_dialog() }}>
            <EscapeWarning open={show_escape_warning} set_discard_changes={() => onclick_handleclose()} handleClose={close_escape_warning_dialog} />
                <IModalHeader style_children={{flex: 1, minWidth: '120px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} toggle={() => {mode=='view'?onclick_handleclose():show_escape_warning_dialog()}}>{mode == 'add' ? "Create a Rule" : `Hardware Rule - ${active_rule && active_rule.name ? active_rule.name : ''}`}</IModalHeader>

                <IModalBody style={{ height: 'calc(100vh - 160px)', overflow: 'auto' }}>

                    <Row style={{ marginBottom: '20px', ...(mode == 'view' ? { pointerEvents: 'none' } : {}) }}>
                        <Col xs={1}></Col>
                        <Col xs={10}>
                            {/* Rule Name, Description, Activation */}
                            {/* ------------------------------------------------------------------------ */}
                            <Row style={{ marginBottom: '20px' }}>
                                <Col xs={5}>
                                    <div>
                                        <div style={{ fontSize: '12px', color: '#425A70', letterSpacing: '-0.04px', lineHeight: '22px' }}>Rule Name *</div>
                                        <Input id='hw_rule_name' style={{ width: '100%', height: '32px', fontSize: '12px', color: '#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px', borderColor: trigger_red_marker && (!rule_name || !rule_name.length) ? 'red' : '' }} placeholder="Rule Name" type="text" value={rule_name} onChange={e => set_rule_name(e.target.value)} />
                                    </div>
                                </Col>
                                <Col xs={5}>
                                    <div>
                                        <div style={{ fontSize: '12px', color: '#425A70', letterSpacing: '-0.04px', lineHeight: '22px' }}>Rule Description*</div>
                                        <Input id='hw_rule_description' style={{ width: '100%', height: '32px', fontSize: '12px', color: '#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px', borderColor: trigger_red_marker && (!rule_description || !rule_description.length) ? 'red' : '' }} placeholder="Rule Description" type="text" value={rule_description} onChange={e => set_rule_description(e.target.value)} />
                                    </div>
                                </Col>
                                <Col xs={2}>
                                    <div>
                                        <div style={{ fontSize: '12px', letterSpacing: '-0.04px', lineHeight: '22px' }}>Rule Activation*</div>
                                        <Input style={{ height: '100%', overflowY: 'auto', fontSize: '12px', }} type="select"
                                            value={rule_activation}
                                            onChange={e => set_rule_activation(e.target.value == 'true')}
                                        >
                                            <option style={{ listStyle: 'none', textTransform: 'capitalize', color: 'black' }} value='true'>True</option>
                                            <option style={{ listStyle: 'none', textTransform: 'capitalize', color: 'black' }} value='false'>False</option>
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                            {/* ------------------------------------------------------------------------ */}

                            {/* Applicability */}
                            {/* ------------------------------------------------------------------------ */}

                            <Row>
                                <Col>
                                    <div style={{ fontSize: '18px', color: '#212529', marginBottom: '10px' }}>Applicable to</div>
                                </Col>
                            </Row>
                            {/* Sku Applicability */}
                            <div className='flex_property p-3 mb-2' style={{ background: 'white' }}>SKU Applicability
                                <Tooltip title={"This rule will be only applied to the list of SKUs selected"}>
                                    <img className='ml-2' src='/assets/icons/info.svg' height={'12px'} />
                                </Tooltip>
                                <div className='ml-4' style={{ fontSize: '14px' }}>{sku_selection_map && sku_selection_map.length ? `Applicable to ${sku_selection_map.length} SKU` : 'All Sku'}</div><div className='ml-auto' title={item_json_source && item_json_source.filter(x => x.property && x.property[0] && x.property[0].id === 'tag').length ? 'Applicability Conditions should not have tag property to have SKU Applicability' : ''} onClick={item_json_source && item_json_source.filter(x => x.property && x.property[0] && x.property[0].id === 'tag').length ? null : open_sku_selection_modal} style={{ color: '#0078ff', cursor: 'pointer', fontSize: '14px', opacity: item_json_source && item_json_source.filter(x => x.property && x.property[0] && x.property[0].id === 'tag').length ? '0.5' : 1 }}>View/Modify SKU</div>
                            </div>
                            {/* Applicability Conditions */}
                            <div className='pl-4'>
                                {
                                    item_json_source ? item_json_source.map((single_item, idx) =>
                                        <HWKeyValuePair_range
                                            trigger_red_marker={trigger_red_marker}
                                            open={open}
                                            key={single_item.id ? single_item.id : single_item.temp_key}
                                            item_json={item_json_source}
                                            idx={idx}
                                            single_item_json={single_item}
                                            set_item_json={set_item_json_source}
                                            component_options_json={component_tags_json}
                                            combination_logic={applicability_combination_logic}
                                            set_combination_logic={set_applicability_combination_logic}
                                            mode={mode}
                                            tags={tags}
                                            position_type='applicable'
                                            shelf_type_values={shelf_type_values}
                                            shutter_mechanism_values={shutter_mechanism_values}
                                            sales_channels={sales_channels}
                                            has_skus_mapped={sku_selection_map && sku_selection_map.length}
                                            value_red_marker={value_red_marker}
                                        />
                                    ) : ''
                                }
                                <Row style={{ margin: 0 }}>
                                    {
                                        mode == 'view' ? '' :
                                            <Col style={{ paddingLeft: 0 }}>
                                                <span onClick={() => add_new_item_json(item_json_source, 'applicable')} style={{ fontSize: '13px', color: '#007BFF', cursor: 'pointer', fontWeight: 600 }}>Add Applicability +</span>
                                            </Col>
                                    }
                                </Row>
                            </div>
                            {/* ------------------------------------------------------------------------ */}
                            
                            
                            {/* Conditions */}
                            {/* ------------------------------------------------------------------------ */}
                            <Row style={{ margin: '20px 0px 5px 0px' }}>
                                <Col xs={4} style={{ padding: 0 }}>
                                    <div style={{ fontSize: '18px', color: '#212529' }}>If the following criteria is satisfied</div>
                                </Col>
                            </Row>
                            {
                                item_json_dest ? item_json_dest.map((single_item, idx) =>
                                    <HWKeyValuePair_range value_red_marker={value_red_marker} trigger_red_marker={trigger_red_marker} open={open} key={single_item.id ? single_item.id : single_item.temp_key} item_json={item_json_dest} idx={idx} single_item_json={single_item} set_item_json={set_item_json_dest} mode={mode} tags={tags} component_options_json={component_options_json} combination_logic={condition_combination_logic} set_combination_logic={set_condition_combination_logic} position_type='condition' shelf_type_values={shelf_type_values} shutter_mechanism_values={shutter_mechanism_values} item_json_dest_component={item_json_dest_component} item_json_dest_subcomponent={item_json_dest_subcomponent} applicable_array={item_json_source} />
                                ) : ''
                            }
                            <Row style={{ margin: 0 }}>
                                {
                                    mode == 'view' ? '' :
                                        <Col style={{ paddingLeft: 0 }}>
                                            <span id='add_condition_in_source' onClick={() => add_new_item_json(item_json_dest, 'condition')} style={{ fontSize: '13px', color: '#007BFF', cursor: 'pointer', fontWeight: 600 }}>Add Condition +</span>
                                        </Col>
                                }
                            </Row>
                            {/* ------------------------------------------------------------------------ */}
                            
                            {/* Designer Input */}
                            {/* ------------------------------------------------------------------------ */}
                            <Row style={{ margin: '20px 0px 0px' }}>
                                <Col xs={4} style={{ padding: 0 }}>
                                    <div style={{ fontSize: '18px', color: '#212529' }}>Designer Inputs (Optional)</div>
                                </Col>
                            </Row>

                            <Row style={{ margin: '10px 0px 0px', backgroundColor: 'white', padding: '20px' }}>
                                <Col style={{ paddingLeft: 0 }}>
                                    <div>
                                        <div style={{ fontSize: '12px', letterSpacing: '-0.04px', lineHeight: '22px' }}>Category Type</div>
                                        <Input id='hwrules_designer_input_category_type' style={{ height: '100%', overflowY: 'auto', fontSize: '12px', }} type="select"
                                            value={selected_category_type}
                                            onChange={e => { set_selected_category_type(e.target.value); set_valid_inputs([]) }}
                                        >
                                            <option style={{ listStyle: 'none', textTransform: 'capitalize', color: 'black' }} value={null}>Select Category Type</option>
                                            {
                                                all_eligible_cat_type && all_eligible_cat_type.length ? all_eligible_cat_type.map((x, idx) =>
                                                    <option key={idx} style={{ listStyle: 'none', textTransform: 'capitalize', color: 'black' }} value={x.id}>{x.display_name}</option>
                                                )
                                                    : ''
                                            }
                                        </Input>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ paddingBottom: '3px', fontSize: '12px', color: '#425A70', letterSpacing: '-0.04px', lineHeight: '20px' }}>Select Designer Input</div>
                                    <Multiselect
                                        style={{ backgroundColor: 'white', width: '100%' }}
                                        options={all_eligible_designer_inputs && all_eligible_designer_inputs.length ? JSON.parse(JSON.stringify(all_eligible_designer_inputs)) : []}
                                        selectedValues={valid_inputs}
                                        onSelect={set_valid_inputs}
                                        onRemove={set_valid_inputs}
                                        displayValue='name'// Property name 
                                        id='select_valid_inputs'
                                        placeholder='Select Valid Inputs'
                                    />
                                </Col>
                            </Row>
                            {/* ------------------------------------------------------------------------ */}

                            {/* Actions */}
                            {/* ------------------------------------------------------------------------ */}
                            
                            <Row>
                                <Col>
                                    <div style={{ fontSize: '18px', marginTop: '20px', marginBottom: '10px', color: '#212529' }}>Hardware to be added</div>
                                </Col>
                            </Row>

                            <HWLinked_sku_structure
                                trigger_red_marker={trigger_red_marker}
                                add_new_input_value={add_new_input_value}
                                update_input_value={update_input_value}
                                rule_input_values={rule_input_values}
                                valid_inputs={valid_inputs}
                                designer_inputs={designer_inputs}
                                all_brands={all_brands}
                                item_json={linked_item_json}
                                set_item_json={set_linked_item_json}
                                mode={mode}
                                all_division_tree_data={all_division_tree_data}
                                set_item_json_condition={set_item_json_dest}
                                item_json_condition={item_json_dest}
                                component_options_json={component_options_json}
                                open={open}
                                set_page_loader={set_page_loader}
                            />
                        </Col>
                        {/* ------------------------------------------------------------------------ */}
                        <Col xs={1}></Col>
                    </Row>
                </IModalBody>

                <IModalFooter>
                    {
                        mode == 'view' ?
                            <IButton color={"secondary"} type="button" onClick={onclick_handleclose}> Close </IButton>
                            :
                            <IButton id='add_edit_hw_rule_button' type="button" onClick={mode === 'add' ? onclick_add_new_rule : mode === 'edit' ? onclick_edit_rule : onclick_handleclose}> Done </IButton>
                    }
                </IModalFooter>
            <SkuSelectionModal key={active_rule ? active_rule.id : "New Rule"} open={is_sku_selection_modal_open} handleclose={close_sku_selection_modal} submitSelectedSku={(selectedSku) => { set_sku_selection_map(selectedSku) }} initialSelection={sku_selection_map} set_page_loader={set_page_loader} />
        </Modal>
    );
}

export default HWRulesModal; 