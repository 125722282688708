import React, { useEffect, useState } from 'react';

const SingleItemTab = ({item, idx, open_additional_option_fn, sku_sub_category_id, onclick_tab, set_occupied_width, occupied_width, sec_fn, border_animation_sub_cat, set_border_animation_sub_cat, bulk_selection_mode, no_of_bulk_selected_skus_in_subcat, no_of_skus_json}) => {

    useEffect(() => {
        if(item && idx>=0){
            var sec_width = document.getElementById('secondary_div'+idx).offsetWidth
            // console.log('occupied_width', occupied_width)
            console.log('occupied_wsec_widsec_widththidth', sec_width)
            set_occupied_width(occupied_width => occupied_width + sec_width)
        }
    },[item,idx])

    return(
        <span id={'secondary_div'+idx} onClick={() => {onclick_tab(item);set_border_animation_sub_cat(null)}} className={sku_sub_category_id==item.id?'sub_category_active':(border_animation_sub_cat ==  item.id ? 'animate_box_shadow_small':'')} style={{ whiteSpace:'nowrap', fontSize:'14px', height:'28px', maxHeight:'28px', display:'flex', alignItems:'center', borderRadius:'4px', color:'#435A6F'}}>
            <span title={item.name?item.name:''} style={{ height:'28px', maxHeight:'28px', display:'flex', alignItems:'center', justifyContent:'center', padding:bulk_selection_mode?'0px 5px 0px 20px':'0px 20px'}}><span style={{cursor:sku_sub_category_id==item.id?'default':'pointer'}}>{item.name?(item.name.length > 15 ? item.name.substring(0,15)+'...': item.name):''}</span></span>
            {open_additional_option_fn && !bulk_selection_mode && sku_sub_category_id==item.id ?
                <span className={sku_sub_category_id==item.id?'':'sub_category_display'} style={{height:'28px', maxHeight:'28px', display:'flex', alignItems:'center', justifyContent:'center', padding:'0px 20px 0px 0px'}}>
                    <i title='Edit Sub-Category' style={{cursor:'pointer'}} onClick={() =>open_additional_option_fn('edit')} id='edit_sub_category' className='fa fa-cog' />
                </span>
                :""
            }
            {
                bulk_selection_mode?
                <span style={{marginRight:'20px'}}>
                    {'('+(no_of_bulk_selected_skus_in_subcat[item.id] ? no_of_bulk_selected_skus_in_subcat[item.id] : '0')+'/'+(no_of_skus_json[item.id] ? no_of_skus_json[item.id] : '0')+')'}
                </span>:''
            }
        </span>
    )
}

export default SingleItemTab;

