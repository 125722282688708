import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, ModalFooter, FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, ModalHeader, ModalBody, } from "reactstrap";
import Pegasus_Paginator from '../../components/Pegasus_Paginator';
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import LazyLoad from 'react-lazyload';
import split_cat_type from '../../utils/split_cat_type';
import { confirmAlert } from 'react-confirm-alert';
import classnames from "classnames";
import DetectableOverflow from 'react-detectable-overflow';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { UserContext } from '../../components/Contexts/UserContext';
import Add_new_material_template from '../../components/Add_new_material_template/index.js';

const Material_template_modal = ({show_mat_modal, set_show_mat_modal, material_mode, set_material_mode, curr_material_template, set_curr_material_template, user, store_details, set_active_tab, submit_for_delete_confirm, set_active_division_id_sidebar, send_analytics_event, set_page_loader}) => {
    const [return_material_details, set_return_material_details] = useState([])
    const [base_texture_url, set_base_texture_url] = useState('')
    const [texture_json, set_texture_json] = useState('')

    const close_add_new_material = () => {
        set_show_mat_modal(false)
        set_material_mode('')
        set_curr_material_template('')
        set_base_texture_url('')
        set_return_material_details('')
    }

    useEffect(()=>{
        console.log("show mat modal in child is", show_mat_modal)
    }, [show_mat_modal])
    // return (<Modal isOpen={show_mat_modal} toggle={()=>{console.log("Entered in"); set_show_mat_modal(!show_mat_modal)}}>
    //     <ModalHeader><i className='fa fa-times' style={{cursor: 'pointer'}} onClick={()=>set_show_mat_modal(false)}></i></ModalHeader>
    //     <ModalBody>HELLo</ModalBody>
    // </Modal>)
    return (<Add_new_material_template 
        set_return_material_details={set_return_material_details}
        return_material_details={return_material_details}
        active_material_template={return_material_details}
        set_active_material_template={set_return_material_details}
        open={show_mat_modal}
        handle_close={close_add_new_material}
        curr_material_template={curr_material_template}
        set_page_loader={set_page_loader}
        material_mode={material_mode}
        set_material_mode={set_material_mode}
        show_restore_button={true}
        base_texture_url={base_texture_url} 
        set_base_texture_url={set_base_texture_url}
    />)
}

const Material_template = ({user, store_details, get_store_details, set_active_tab, submit_for_delete_confirm, set_active_division_id_sidebar, send_analytics_event, set_page_loader}) => {
    const [material_templates, set_material_templates] = useState([])
    const [display_material_templates, set_display_material_templates] = useState([])
    const [default_material_template, set_default_material_template] = useState('')
    const [show_mat_modal, set_show_mat_modal] = useState(false)
    const [material_mode, set_material_mode] = useState('')
    const [curr_material_template, set_curr_material_template] = useState('')
    const [search_string, set_search_string] = useState('')

    useEffect(() => {
		set_active_division_id_sidebar('material_template')
    }, [])

    useEffect(() => {
        const fetch_material_templates = async () => {
            let resp = await general_fetch({url: 'material_template/get'})
            set_material_templates(resp)
            let temp = JSON.parse(JSON.stringify(resp[0]))
            let new_properties = temp.properties.map(item => {
                if(typeof item.value != 'undefined' && typeof item.value.min_value != 'undefined' && typeof item.value.value != 'undefined')
                    item.value.value = item.value.min_value
                return item
            })
            set_default_material_template(temp)
        }
        fetch_material_templates()
    }, [show_mat_modal])

    useEffect(() => {
        let temp = JSON.parse(JSON.stringify(material_templates))
        let lower_search_string = search_string?search_string.toLowerCase():''
        if(lower_search_string)
            temp = temp.filter(item => item["name"].toLowerCase().includes(lower_search_string))
        set_display_material_templates(temp)
    }, [material_templates, search_string])

    return (<Main_Grid active_tab="FeatureFlagAddonMap" store_details={store_details} get_store_details={get_store_details}>
            <Row style={{ padding: '20px 10px', color: '#22272e' }}>
                <Col xs={6} className='flex_property' style={{ fontFamily: 'Source_Sans_Pro-SemiBold', paddingLeft: '20px', fontSize: '14px' }}>
                    Manage Material Templates
                </Col>
            </Row>
            <Row style={{ height: 'calc(100% - 90px)' }}>
                <Col style={{ height: '100%' }}>
                    <Card style={{ borderRadius: 0, height: '100%', overflowY: 'scroll', padding: '20px'}}>
                        <React.Fragment>
                            <Material_template_modal curr_material_template={curr_material_template} set_curr_material_template={set_curr_material_template} material_mode={material_mode} set_material_mode={set_material_mode} show_mat_modal={show_mat_modal} set_show_mat_modal={set_show_mat_modal}></Material_template_modal>
                        {/* <button onClick={() => {set_curr_material_template(material_templates[0]); set_material_mode('add'); set_show_mat_modal(true)}}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'14px', display:'flex', alignItems:'center', alignContent:'center'}}>Add a material template</span></button> */}
                        <span style={{ display:'flex', alignItems:'center', justifyContent:'flex-end', marginRight:'15px'}}>
                            <button onClick={() => {set_curr_material_template(default_material_template); set_material_mode('add'); set_show_mat_modal(true)}} style={{backgroundColor: '#007EF4', boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)', borderRadius: '3px', height:'32px', padding:'0px 24px', border:'0px', color:'white',}}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'14px', display:'flex', alignItems:'center', alignContent:'center'}}>Create Material Template</span></button>
                        </span>
                        <div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Material Template name' /></div>
                            <table style={{height:"100%", width:'100%'}} className="text-left flex_property table_style">
                                <thead style={{width:"100%"}}>
                                    <tr className='tr_style' style={{borderTop:'1px solid #f2f4f6', height:"40px"}}>
                                        <th className='th_style flex_property' style={{flex:'7'}}><span>Name</span></th>
                                        <th className='th_style flex_property' style={{flex:'1', border:0, marginRight:'15px'}}><span>Actions</span></th>
                                    </tr>
                                </thead>
                                <tbody style={{ height:"calc(100% - 40px)", width:'100%',overflow:'auto'}}>
                                    {display_material_templates.map(item => (
                                    <tr style={{display:'flex', margin:0}} className='tr_style display_onhover'>
                                        <td className='td_style flex_property' style={{ flex:'7'}}><span style={{overflowX:'auto',textTransform:'capitalize', whiteSpace:'nowrap'}}>{item.name}</span></td>
                                        <td className='td_style flex_property x1' style={{ flex:'1', justifyContent:'center'}}>
                                        {/* <div color="grey" className="btn-circle"><span onClick={() => {set_curr_material_template(item); set_material_mode('edit'); set_show_mat_modal(true)}} style={{cursor: 'pointer', alignSelf: 'center'}} className="fa fa-pen" /></div> */}
                                        <span className='hide'><i onClick ={() => {set_curr_material_template(item); set_material_mode('edit'); set_show_mat_modal(true)}} className='fa fa-edit'/></span>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </React.Fragment>
                    </Card>
                </Col>
            </Row>

        </Main_Grid>)
}

export default Material_template;