const get_top_level_pref_value = (value) => {
    let user = window.get_user();
    let current_business_unit_id = user.current_business_unit_id;
    let org_bu_view = window.sessionStorage.getItem('org_bu_view') || 'bu'
    if(org_bu_view === 'bu' && value.business_unit && value.business_unit[current_business_unit_id] !== undefined ){
        return value.business_unit[current_business_unit_id]
    }else if(value.store !== undefined){
        return value.store
    }

    return value.master
}

export default get_top_level_pref_value;