import React , { useState , useEffect, useRef, useContext }from 'react';
import {Modal, Alert,FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, ModalBody, ModalHeader, ModalFooter, } from "reactstrap";
// import UploadFile from '../UploadFile';
import general_fetch from '../../utils/fetch';
import Property_Tab from '../Add_new_material/PropertyTab';
import SingleSelect from '../SingleSelect';
import { UncontrolledCollapse } from '../ICollpsable';
import { WasmLoadedContext } from '../../components/Contexts/WasmLoadedContext';
import { COLORS } from '../../utils/colors';
import build_path from "../../utils/path";
import { useAlert } from 'react-alert'
import { IModalFooter, IModalHeader } from '../IModal';


const Add_new_material_template = ({open , curr_material_template, return_material_details, set_return_material_details, active_material_template, set_active_material_template, handle_close, final_update_fn, show_restore_button, material_template, fetch_material_template , materials_json, texture_json, material_mode, set_material_mode, set_page_loader, update_sku_array, sku_array_idx, is_allow_material, base_texture_url, set_base_texture_url}) => {

	const [ return_confirm_low_resp_data , set_return_confirm_low_resp_data ] = useState('');
    const [updated_template , set_updated_template] = useState('');
	const [ is_ap_open, set_is_ap_open ] = useState(false);
	const [properties, set_properties] = useState(); 
    const [og_properties, set_og_properties] = useState();
	const [editing_current_material, set_editing_current_material] = useState(false)
    const [updated_color, set_updated_color] = useState('')

	const [parsed_properties, set_parsed_properties] = useState({});
	const [header_properties, set_header_properties] = useState({});
	const [is_color, set_is_color] = useState(false);
    const [show_advanced_properties, set_show_advanced_properties] = useState(true)
    const [input_name, set_input_name] = useState('')


	const [roughness, set_roughness] = useState(-1);
	const [metalness, set_metalness] = useState(-1);
	const [specular, set_specular] = useState(-1);
	const [color, set_color] = useState('');

	const [material_preview_src, set_material_preview_src] = useState('');
	const wasm_loaded = useContext(WasmLoadedContext)

	const alert = useAlert()
	const myRef = useRef(null)

	const grouped_properties = {
		"Base" : [
			"map",
			"texture_wrapping",
			"texture_width",
			"texture_height",
			"color"
		],
        "Other maps" : [
			"displacement_map",
			"normal_map"
		],
		"Reflection" : [
			"specular",
			"roughness",
			"metalness"
		],
		"Refraction" : [
			"refractive_index",
			"transmission",
		],
		"Opacity" : [
			"opacity",
		],
		"Emmissivity" : [
			"emissive_intensity",
		]
	}

	const keysGroupedObject = Object.keys(grouped_properties)

    // useEffect(() => {
    //     if(curr_material_template && curr_material_template.length && open && material_mode=='add'){
    //         set_updated_template(curr_material_template)
    //         console.log("Entered HERE", curr_material_template)
    //     }
    // },[open])
    useEffect(() => {
        if(material_mode == 'edit')
            set_input_name(curr_material_template.name)
        else
            set_input_name('')
    }, [open])

    useEffect(() => {
        if(curr_material_template && material_mode==='edit' || material_mode==='update_template'){
            set_updated_template(curr_material_template)
        }
    },[curr_material_template, material_mode])

    useEffect(() => {
        if(curr_material_template && material_mode==='add'){
            set_updated_template(curr_material_template)
        }
    },[curr_material_template, material_mode])

    useEffect(() => {
        if(updated_template){
            if (material_mode==='edit') {
                var x=updated_template ? updated_template:{};
                // let parsed_property_jso = JSON.parse(JSON.stringify(curr_material_template.properties))
                let parsed_property_json = {}
                let temp = curr_material_template.properties.map(item => {
                    parsed_property_json[item.label] = item.value.value;
                    return item;
                })
                x.properties = x.properties && x.properties.length ? x.properties.map( (property, idx) => {
                    return {...property, final_value:updated_template.properties?updated_template.properties[idx].value.value:null, wrong_value_flag:false}
                }):[]
                set_properties(x.properties)
                set_parsed_properties(parsed_property_json)
            }
            else{   
                var x=updated_template ? updated_template:{};
                let parsed_property_json = {}
                let temp = curr_material_template.properties.map(item => {
                    parsed_property_json[item.label] = item.value.value;
                    return item;
                })
                x.properties = x.properties && x.properties.length ? x.properties.map( (property, idx) => {
                    // console.log("updated temp kppp", updated_template.properties[idx])
                    return {...property, final_value:updated_template.properties?updated_template.properties[idx].value.value:null, wrong_value_flag:false}
                }):[]
                set_properties(x.properties)
                set_parsed_properties(parsed_property_json)
            }
        }
    },[updated_template])

    // const get_return_material_details = async(id) => {
	// 	try{
	// 		//loader
	// 		var identifiers = JSON.stringify({id:id})
	// 		var attributes = JSON.stringify({})
	// 		var response = await general_fetch({url :'material_template/get' , body: {identifiers, attributes} }) 
	// 		set_return_material_details(response && response.length ?response[0]:[])
    //         // onclick_update_for_update_sku(response && response.length ?response[0]:[])
	// 	}catch(err){
	// 		console.log(err)
	// 	}
	// }

    const onClick_add_material_template = async () => {
        if(!input_name){
            alert.error('Material Template name cannot be empty')
        }
		else if( updated_template){
			try {
                var bp = properties && properties.length && properties.reduce((final, elem)=> {
                    let kp = JSON.parse(JSON.stringify(curr_material_template.properties))
                    let kp1 = kp.find(item => item.label == elem.label)
                    if(typeof kp1.value != 'undefined' && typeof kp1.value.value != 'undefined' && typeof elem.final_value != 'object')
                        kp1.value.value = elem.final_value
                    delete kp1.final_value
                    delete kp1.wrong_value_flag
                    return {
                        ...final, [elem.label]: kp1
                    }
                }, {});
                
                bp = Object.values(bp)
                var body={
                    properties:JSON.stringify(bp), 
                }
                let material_id = null;
                if (material_mode==='edit') {
                    body['name'] = input_name
                    body['id'] = updated_template.id
                    let resp = await general_fetch({ url: 'material_template/update', body });
                } 
                else {
                    body['name'] = input_name
                    let resp = await general_fetch({ url: 'material_template/add', body });
                }
                // get_return_material_details(material_id)
                // if(update_sku_array && sku_array_idx>=0) update_sku_array(sku_array_idx, 'material_id', material_id)
				onclick_handleclose();
				
			}catch(err) {
				alert.error('Error , Unable to add Material')
			}
		}else{
			alert.error('Please fill all required details')
		}
	}

    // const get_current_material_details = async() => {
	// 	try{
	// 		//loader
	// 		var identifiers = JSON.stringify({id:updated_template.id})
	// 		var attributes = JSON.stringify({})
	// 		var response = await general_fetch({url :'material/get' , body: {identifiers, attributes} }) 
	// 		set_active_material_template(response && response.length ?response[0]:'')
	// 		console.log("get material", response)
	// 		let texture_id = response[0].properties.map
			
	// 		var texture_get = await general_fetch({url:'texture/get', body:{identifiers:JSON.stringify({id:[texture_id]})}})
    //     	if(texture_get) set_base_texture_url(texture_get[0] && texture_get[0].Image ? texture_get[0].Image.image :'')
	// 	}catch(err){
	// 		console.log(err)
	// 	}
	// }
	
	const onclick_restore_template_values = () => {
        // get_current_material_details()
        set_material_mode('edit')
    }

	// useEffect(() =>{
	// 	if(return_confirm_low_resp_data){
	// 		alert.success('Model files loaded successfully')
	// 	}
	// },[return_confirm_low_resp_data])

	const downloadFile = (url, fileName) => {
		const anchor = document.createElement('a');
		anchor.href = global.config.server_path + url;
		anchor.download = fileName;

		// Trigger a click on the anchor element to start the download
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
	};

    const onclick_handleclose = () => {
        handle_close()
        // set_input_name('')
        set_updated_template('')
        set_updated_color('')
        set_properties('')
        set_show_advanced_properties(true)
        
    }

    // const onchange_material_template = (id) => {
    //     if(id){
    //         var selected_template = material_template && material_template.find( x => x.id===id )
    //         console.log('setting updated template ---> ', id, selected_template);
	// 		console.log('updated template', updated_template)
	// 		console.log('parsed properties', parsed_properties)
	// 		let props = selected_template.properties
	// 		console.log("properties are", props)
	// 		let temp_props = new Array(props.length)
	// 		props && props.length && props.map((prop, idx) => {
	// 			if(prop.label !== 'map' && prop.label != 'color' && prop.label != 'bump_map' && prop.label != 'normal_map'){
	// 				temp_props[idx] = JSON.parse(JSON.stringify({...prop, final_value: prop.value.value}));
	// 			}else{
	// 				temp_props[idx] = (parsed_properties && parsed_properties[prop.label]) ? updated_template.properties.find(item => item.label == prop.label) : JSON.parse(JSON.stringify({...prop, final_value: prop.value.value}))
	// 			}
	// 		});
	// 		console.log("temp_props are", temp_props)
    //         if(material_mode == 'edit') set_updated_template(selected_template? JSON.parse(JSON.stringify({ ...updated_template, properties: temp_props, material_template: selected_template })) : JSON.parse(JSON.stringify({ ...updated_template, properties: temp_props, material_template: null })));
    //         if(material_mode == 'add') set_updated_template(selected_template? JSON.parse(JSON.stringify({...selected_template, properties: temp_props})) : null);
    //     }
    // }

	const parse_property = (property) => {
		let properties_list = grouped_properties[property];
		let preview = ''
		let defaultState = false
		let initialized = false
		properties_list.map(x => {
			preview = preview + `${x.replace('_', ' ')}: ${typeof(parsed_properties[x]) == 'number' ? Math.round(parsed_properties[x]*100)/100 : parsed_properties[x]} `
			if(parsed_properties[x]){
				defaultState = true
			}
		})
		preview = `(${preview})`

		let header = <div className='flex_property'>{property} &nbsp; <div style={{textTransform:'capitalize', color: COLORS.gray5}}>{preview}</div></div>

		return {header, defaultState, initialized}


	}

	const delete_texture = () => {
		let property = properties.find(o => o.label === 'map')
        property.final_value = '';
		set_base_texture_url('');
        set_properties(properties)
        if(parsed_properties && set_parsed_properties){
            parsed_properties[property.label] = ''
            set_parsed_properties(JSON.parse(JSON.stringify(parsed_properties)))
        }
	}

	// useEffect(() => {
	// 	if(is_ap_open){
	// 		let t = myRef.current?.scrollIntoView({behavior: 'smooth'})
	// 	}
	// }, [is_ap_open]);

	function hexToRgb(hex) {
		// Remove the leading #
		hex = hex.replace('#', '');
		
		// Parse the hex values for red, green, and blue
		const r = parseInt(hex.substring(0, 2), 16);
		const g = parseInt(hex.substring(2, 4), 16);
		const b = parseInt(hex.substring(4, 6), 16);
		
		// Return the RGB values as an object
		return {
		  r: r,
		  g: g,
		  b: b
		};
	  }

	useEffect(() => {
		if (window.enable_wasm && wasm_loaded === 1) {
			try {
				let colorRGB = hexToRgb(color)
				// console.log(global.config.admin_url, base_texture_url ? build_path(global.config.server_path, base_texture_url) : '', colorRGB.r / 256, colorRGB.g / 256, colorRGB.b / 256, metalness, roughness, specular)
				// window.Module.init_material_preview_texture(global.config.admin_url, base_texture_url ? build_path(global.config.server_path, base_texture_url) : '', colorRGB.r / 256, colorRGB.g / 256, colorRGB.b / 256, metalness, roughness, specular)
				// window.Module.init_sphere_texture(base_texture_url, 0, 0, 0, properties.find(x => x.label == 'roughness') ? properties.find(x => x.label == 'roughness').final_value : 0, properties.find(x => x.label == 'metalness') ? properties.find(x => x.label == 'metalness').final_value : 0)
				window.Module.prepare_texture(base_texture_url ? build_path(global.config.server_path, base_texture_url) : '');
				window.Module.draw_material_preview(metalness, roughness, specular, colorRGB.r / 256, colorRGB.g / 256, colorRGB.b / 256)
				window.Module.draw_material_preview(metalness, roughness, specular, colorRGB.r / 256, colorRGB.g / 256, colorRGB.b / 256)

			}
			catch (err) {
				console.error("Material init preview failed", err)
			}

			if (document.getElementById('webgl_sphere_canvas')) {
				set_material_preview_src(document.getElementById('webgl_sphere_canvas').toDataURL())
			}
		}

	}, [base_texture_url]);

    // useEffect(() => {
	// 	if(curr_material_template){
	// 		var x=curr_material_template ? curr_material_template:{};
	// 		let parsed_property_json = JSON.parse(JSON.stringify(curr_material_template.properties))
	// 		x.properties = x.properties && x.properties.length ? x.properties.map( (property, idx) => {
	// 			return {...property, final_value:curr_material_template.properties?curr_material_template.properties[idx]:null, wrong_value_flag:false}
	// 		}):[]
	// 		set_properties(x.properties)
	// 		set_parsed_properties(parsed_property_json)
    //         console.log("parsed_property_json", parsed_property_json)
	// 	}
	// }, [curr_material_template]);


	useEffect(() => {
		if (roughness != -1 && metalness != -1 && specular != -1 && color && window.enable_wasm && wasm_loaded === 1) {
			console.log("draw sprere being called")
			let colorRGB = hexToRgb(color)
			window.Module.draw_material_preview(metalness, roughness, specular, colorRGB.r / 256, colorRGB.g / 256, colorRGB.b / 256)
			// window.Module.init_sphere_texture(base_texture_url, 0, 0, 0, properties.find(x => x.label == 'roughness') ? properties.find(x => x.label == 'roughness').final_value : 0, properties.find(x => x.label == 'metalness') ? properties.find(x => x.label == 'metalness').final_value : 0)
		}
		else {
			console.log("draw sphere not triggered")
		}

		if (document.getElementById('webgl_sphere_canvas')) {
			set_material_preview_src(document.getElementById('webgl_sphere_canvas').toDataURL())
		}

	}, [roughness, metalness, color, specular]);

	useEffect(() => {
		// console.log(parsed_properties, "parsed_properties material")
		if(parsed_properties){
			let temp_default_property_state = JSON.parse(JSON.stringify(header_properties))
			let temp = keysGroupedObject && keysGroupedObject.length ? keysGroupedObject.map((property, idx) => {
				let parsed_property = parse_property(property)
				// let header = parsed_property.header
				let defaultState = parsed_property.header
				temp_default_property_state[property] = defaultState
			}) : ''
			// console.log(temp_default_property_state, "default state when modal gets opened")
			set_header_properties(temp_default_property_state)
			if(parsed_properties.map){
				set_is_color(false)
			}else{
				// set_is_color(true)
			}
		}
	}, [parsed_properties]);

	
	return(
         <Modal isOpen={open} size='xl'>
        <IModalHeader toggle={() => {onclick_handleclose(); set_active_material_template(''); set_base_texture_url(''); set_return_material_details('')}}>
       Material Template Properties
        </IModalHeader>
					<ModalBody className='global_modal_body'>
					{
						(	
							<>
								<div className='flex_property' style={{justifyContent:'space-between'}}>
									{/* <div style={{fontSize:'16px'}}>Material Template Properties</div> */}
									{/* {
										editing_current_material?'':
										<div onClick={() => set_editing_current_material(true)} className='flex_property div_box' style={{cursor:'pointer', fontSize:'14px', width:'180px', height:'30px'}}>
											<i style={{marginRight:'10px'}} className='fa fa-pencil-alt'/><span>Edit current material</span>
										</div>
									} */}
								</div>
								<div  style={{width:'1160px', backgroundColor: 'white', marginTop:'10px', borderBottom:editing_current_material?'1px solid white':'1px solid #C5C7CF'}}>
									{/* <div style={{flex:'1', display:'flex', flexDirection:'column', padding:'5px 20px 5px 5px'}}>
										<div style={{fontSize:'14px', marginBottom:'5px'}}>Material Preview</div>
										{
											!(window.enable_wasm && (wasm_loaded === 1))?
												base_texture_url
													?
													<img width='265px' height='265px' style={{ marginBottom: '20px', backgroundColor: typeof (parsed_properties.color) == 'string' ? parsed_properties.color : '#000000', color: typeof (parsed_properties.color) == 'string' ? parsed_properties.color : '#000000' }} alt='Base Color' src={base_texture_url ? build_path(global.config.server_path, base_texture_url) : ''} />
													:
													<div style={{ marginBottom: '20px',width:'265px', height:'265px', backgroundColor: typeof(parsed_properties.color) == 'string' ? parsed_properties.color : '#000000', color: typeof(parsed_properties.color) == 'string' ? parsed_properties.color : '#000000'}}/>
												// <img width='265px' height='265px' style={{ marginBottom: '20px', backgroundColor: parsed_properties.color }} src={base_texture_url ? build_path(global.config.server_path, base_texture_url) : '/assets/img/icons/empty_image.png'} />
												:
												<React.Fragment>
													<img width='265px' height='265px' style={{}} src={material_preview_src} />
													<div style={{fontSize:'12px', marginTop:'5px', color: COLORS.gray6, textAlign: 'center'}}>(Preview based on Base Texture, Base Color, Metalness, Roughness and specular)</div>
												</React.Fragment>
										}

										{/* <div style={{fontSize:'14px', marginBottom:'5px'}}>Material Name</div>
										<Input  type='text' style={{height:'32px',pointerEvents:editing_current_material?'auto':'none'}}/> */}

									<div style={{flex:'3', fontWeight: '600', padding:'10px 20px 20px 20px', overflow:'auto'}}>
										<div>
                                    <div style={{paddingBottom: '15px'}}>
                                        <div style={{fontSize: '13px', paddingBottom: '5px'}}>Material Template name</div>
                                      <Input
                                        style={{minWidth: '20%', height: '32px'}}
                                        type="text"
                                        value={input_name}
                                        onChange={(e) => set_input_name(e.target.value)}
                                        placeholder="Enter Material Template name"
                                        />
                                    </div>
										{
                                // !advanced_parameters_collapse ?
                                    // : ''
                                // : ''
                        }

											{/* <div style={{ fontSize: '14px', marginBottom: '5px' }}><b>Select a type of Material Template &nbsp;</b><span style={{color: '#A7A8B3'}}>(Selected template value will be overridden in the below fields)</span></div>
											<div className='mb-3' style={{ width: '50%',  /*pointerEvents:editing_current_material?'auto':'none' */ }
                                                {/* {material_mode == 'add'?
												<SingleSelect 
												options={material_template ? material_template :[]}
												selectedValue = {updated_template ? updated_template :null}
												onItemClick = {(selectedValue) => {onchange_material_template(selectedValue.id)}}
												placeholder = {"Select Material Type"}
												displayValue = {'name'}/>
                                                : */}
                                                {/* <SingleSelect 
												options={material_template ? material_template :[]}
												selectedValue = {updated_template && updated_template.material_template ? updated_template.material_template :null}
												onItemClick = {(selectedValue) => {onchange_material_template(selectedValue.id)}}
												placeholder = {"Select Material Type"}
												displayValue = {'name'}/>}
											</div> */}

{
											// !advanced_parameters_collapse ?
												keysGroupedObject && keysGroupedObject.length ? keysGroupedObject.filter(key => key != 'Base' && key != 'Other maps').map((label, idx) => {
													return (
														<UncontrolledCollapse defaultState={true} key={idx} header={label != 'Base' && header_properties && header_properties[label] ? header_properties[label] : label} className='mb-2'>
															<div>
																<div className={label == 'Base' ? 'flex_property' : ''}>
																{
																	label == 'Base' ? 
																		<>
																			<div className='mr-3'>From</div>
																			<Input type='select' value={is_color ? 'color' : 'texture'} onChange={(e) => { set_is_color(e.target.value == 'color'); if(e.target.value == 'color'){delete_texture()} }} style={{ width: 'fit-content', fontSize: '12px', marginRight: '32px' }}>
																				<option value={'color'}>Color</option>
																				<option value={'texture'}>Texture</option>
																			</Input>
																		</>
																	:''
																}
																{
																	label == 'Base' && !is_color && properties && properties.length && properties.find(o => o.label === 'map') ?
																		<Property_Tab
																			key={'map'}
																			property={properties.find(o => o.label === 'map')}
																			properties={properties}
																			set_properties={set_properties}
																			set_page_loader={set_page_loader}
																			texture_json={texture_json}
																			input_size='small'
																			set_base_texture_url={set_base_texture_url}
																			// disabled_input={!editing_current_material}
																			parsed_properties={parsed_properties}
																			set_parsed_properties={set_parsed_properties}
																			compact_style={true}
																			set_roughness={set_roughness}
																			set_metalness={set_metalness}
																			set_color={set_color}
																			set_specular={set_specular}

																		// final_update_fn={final_update_fn}
																		/> : ''
																}
																{
																	label == 'Base' && is_color && properties && properties.length && properties.find(o => o.label === 'color') ?
																		<Property_Tab
																			key={'color'}
																			property={properties.find(o => o.label === 'color')}
																			properties={properties}
																			set_properties={set_properties}
																			set_page_loader={set_page_loader}
																			texture_json={texture_json}
																			input_size='small'
																			set_base_texture_url={set_base_texture_url}
																			// disabled_input={!editing_current_material}
																			parsed_properties={parsed_properties}
																			set_parsed_properties={set_parsed_properties}
																			compact_style={true}
																			set_roughness={set_roughness}
																			set_metalness={set_metalness}
																			set_color={set_color}
																			set_specular={set_specular}

																		// final_update_fn={final_update_fn}
																		/> : ''
																}
																</div>
																<div className={label == 'Base' ? 'flex_property' : ''} style={{gap: '16px'}}>
																{
																	properties && properties.length ? properties.filter(x => grouped_properties[label].includes(x.label)).map((property, idx) => {
																		if (!((label == 'Base' && is_color && property.label !='color') || (label == 'Base' && !is_color && property.label =='color')) && property.label !='map' && property.label != 'color') {
																			return (
																				<Property_Tab
																					key={idx}
																					property={property}
																					properties={properties}
																					set_properties={set_properties}
																					set_page_loader={set_page_loader}
																					texture_json={texture_json}
																					input_size='small'
																					set_base_texture_url={set_base_texture_url}
																					parsed_properties={parsed_properties}
																					set_parsed_properties={set_parsed_properties}
																					compact_style={true}
																					set_roughness={set_roughness}
																					set_metalness={set_metalness}
																					set_color={set_color}
																					set_specular={set_specular}

																				// final_update_fn={final_update_fn}
																				/>
																			)
																		}
																	}
																	)
																		: ''
																}
																</div>
															</div>
														</UncontrolledCollapse>
													)
												})
													: ''
												// : ''
										}
										</div>
									</div>
								</div>
								{
									// editing_current_material?
									// <div className='flex_property' style={{width:'1160px', backgroundColor:'white', height:'48px', border:'1px solid #C5C7CF', borderTop:' 1px solid #E6E9EE', justifyContent:'flex-end'}}>
									// 	{/* <hr style={{selfAlign:'center', width:'80%'}} /> */}
									// 	<div style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
									// 		{/* <span onClick={() => {onclick_restore_template_values()}} className='flex_property' style={{width:'100px', height:'32px', cursor:'pointer', border:'1px solid #C5C7CF', borderRadius:'4px', justifyContent:'center', fontSize:'13px', marginRight:'10px'}}>Reset Changes</span> */}
									// 		<span onClick={() => {onClick_add_material_template()}} className='flex_property' style={{width:'100px', height:'32px', cursor:'pointer',  border:'1px solid #C5C7CF', borderRadius:'4px', backgroundColor:'#0078FF',justifyContent:'center', fontSize:'13px', color:'white', marginRight:'10px'}}>Save Changes</span>
									// 	</div>
									// </div>
									// :''
								}
							</>
						)
					}
        </ModalBody>

        <IModalFooter>
            <Button onClick={() => {onClick_add_material_template()}} className='blue_button'>Save Changes</Button>
        </IModalFooter>
        </Modal>
	);
}

export default Add_new_material_template;