export const all_allowed_key = {
    back_panel_height:{
        id:'back_panel_height',
        name:'Back Panel Height',
        type:'text',
		
    },
	shutter_max_width: {
		id:'shutter_max_width',
        name:'Shutter Max Width',
        type:'text',
	},
	shutter_min_width: {
		id:'shutter_min_width',
        name:'Shutter Min Width',
        type:'text',
	},
	shutter_min_thickness:{
		id:'shutter_min_thickness',
		name:'Shutter Min Thickness',
		type:'text'
	},
	shutter_max_thickness:{
		id:'shutter_max_thickness',
		name:'Shutter Max Thickness',
		type:'text'
	},
    bottom_panel_depth:{
        id:'bottom_panel_depth',
        name:'Bottom Panel Depth',
        type:'text'
    },
    side_gap:{
        id:'side_gap',
        name:'Side Gap',
        type:'text'
    },
    minimum_carcass_depth:{
        id:'minimum_carcass_depth',
        name:'Minimum Carcass Depth',
        type:'text'
    },
    minimum_drawer_height:{
        id:'minimum_drawer_height',
        name:'Minimum Drawer Height',
        type:'text'
    },
    minimum_partition_width_external:{
        id:'minimum_partition_width_external',
        name:'Minimum Partition Width External',
        type:'text'
    },
    maximum_partition_width_external:{
        id:'maximum_partition_width_external',
        name:'Maximum Partition Width External',
        type:'text'
    },
    lamination_type:{
        id:'lamination_type',
        name:'Lamination Type',
        type:'select',
        option_type:'prelam'
    },
    cms_id:{
        id:'cms_id',
        name:'CMS ID',
        type:'text'
    },
    material_type:{
        id:'material_type',
        name:'Material Type',
        type:'text'
    },
    article_code:{
        id:'article_code',
        name:'Article Code',
        type:'text'
    },
    shutter_height_reduction:{
        id:'shutter_height_reduction',
        name:'Shutter Height Reduction',
        type:'text'
    },
    cabinet_type:{
        id:'cabinet_type',
        name:'Cabinet Type',
        type:'select',
		applicability: ['cabinet_furniture', 'wardrobe_furniture']
    },
	ply_finish_display:{
        id:'ply_finish_display',
        name:'Ply Finish Display',
        type:'select',
		applicability: ['shutter_hardware']
    },
	set_size:{
        id:'set_size',
        name:'Set Size',
        type:'text'
    },
    fgp_top_panel_gap:{
        id:'fgp_top_panel_gap',
        name:'FGP Top Panel Gap',
        type:'text'
    },
    fgp_height:{
        id:'fgp_height',
        name:'FGP Height',
        type:'text'
    },
	// standard_shutter_height_reduction:{
    //     id:'standard_shutter_height_reduction',
    //     name:'Standard Shutter Height Reduction',
    //     type:'text'
    // },
	corner_unit_type:{
		id:'corner_unit_type',
        name:'Corner Unit Type',
        type:'select',
        option_type:'',
		applicability:['cabinet_furniture']
	},
	minimum_carcass_width:{
		id:'minimum_carcass_width',
        name:'Minimum Carcass Width',
        type:'text'
	},
	use_original_dimensions_for_pricing:{
		id:'use_original_dimensions_for_pricing',
        name:'Use Original Dimensions For Pricing',
        type:'bool',
		applicability: ['cabinet_furniture', 'wardrobe_furniture']
	},
	order_id:{
		id:'order_id',
        name:'Order ID',
        type:'text'
	},
	profile_width:{
		id:'profile_width',
        name:'Profile Width',
        type:'text'
	},
	profile_height:{
		id:'profile_height',
        name:'Profile Height',
        type:'text'
	},
	custom_sku_code:{
		id:'custom_sku_code',
		name:'Custom Sku Code',
		type:'text'
	},
	force_refresh_sku_cache:{
		id:'force_refresh_sku_cache',
		name:'Force Refresh Sku Cache',
		type:'bool'
	},
	handle_type:{
		id:'handle_type',
		name:'Handle Type',
		type:'select'
	},
	drawer_feature:{
		id:'drawer_feature',
		name:'Drawer Feature',
		type:'select'
	},
	shelf_feature:{
		id:'shelf_feature',
		name:'Shelf Feature',
		type:'select'
	},
	drawer_overridable:{
		id:'drawer_overridable',
		name:'Drawer Overridable',
		type:'bool'
	},
	color_code:{
		id:'color_code',
		name:'Color Code',
		type:'text'
	},
	erp_code:{
		id:'erp_code',
		name:'ERP Code',
		type:'text'
	},
	channel_width:{
		id:'channel_width',
		name:'Channel Width',
		type:'text'
	},
	channel_depth:{
		id:'channel_depth',
		name:'Channel Depth',
		type:'text'
	},
	side_panel_depth_reduction:{
		id:'side_panel_depth_reduction',
		name:'Side Panel Depth Reduction',
		type:'text'
	},
	partition_width_internal_min:{
		id:'partition_width_internal_min',
		name:'Partition Width Internal Min',
		type:'text'
	},
	partition_width_internal_max:{
		id:'partition_width_internal_max',
		name:'Partition Width Internal Max',
		type:'text'
	},
	partition_depth_internal_min:{
		id:'partition_depth_internal_min',
		name:'Partition Depth Internal Min',
		type:'text'
	},
	partition_depth_internal_max:{
		id:'partition_depth_internal_max',
		name:'Partition Depth Internal Max',
		type:'text'
	},
	partition_height_internal_min:{
		id:'partition_height_internal_min',
		name:'Partition Height Internal Min',
		type:'text'
	},
	partition_height_internal_max:{
		id:'partition_height_internal_max',
		name:'Partition Height Internal Max',
		type:'text'
	},
	hide_light_holder:{
		id:'hide_light_holder',
		name:'Hide Light Holder',
		type:'bool'
	},
	additional_length:{
		id:'additional_length',
		name:'Additional Length',
		type:'text'
	},
	scaling_factor:{
		id:'scaling_factor',
		name:'Scaling Factor',
		type:'text'
	},
	hsn_code:{
		id:'hsn_code',
		name:'Hsn Code',
		type:'text'
	},
	carcass_design:{
		id:'carcass_design',
		name:'Carcass Design',
		type:'text'
	},
	shutter_bottom_reduction:{
		id:'shutter_bottom_reduction',
		name:'Shutter Bottom Reduction',
		type:'text'
	},
	panel_type: {
		id:'panel_type',
		name:'Panel Type',
		type:'select',
		applicability: ['custom_panels_hardware']
	},
	blind_unit_cover_panel_height_reduction: {
		id:'blind_unit_cover_panel_height_reduction',
		name:'Blind Unit Cover Panel Height Reduction',
		type:'text',
		applicability: ['handle_hardware']
	},
	handle_placement: {
		id: 'handle_placement',
		name: 'Handle placement',
		type:'select',
	},
	procurement_unit: {
		id:'procurement_unit',
		name:'Procurement Unit',
		type:'select',
	},
	procurement_set_size: {
		id:'procurement_set_size',
		name:'Procurement Set Size',
		type:'text',
	},
	procurement_per_length: {
		id:'procurement_per_length',
		name:'Procurement per Length',
		type:'text',
	},
	procurement_per_area: {
		id:'procurement_per_area',
		name:'Procurement per Area',
		type:'text',
	},
	has_grain: {
		id:'has_grain',
		name: 'Has Grain',
		type: 'select'
	},
	drawer_height_reduction: {
		id: 'drawer_height_reduction',
		name: 'Drawer Height Reduction',
		type:'text'
	},
	manufacturing_details: {
		id:'manufacturing_details',
		name:'Manufacturing Details',
		type:'text'
	},
	gola_top_panel_gap: {
		id:'gola_top_panel_gap',
		name:'Gola Top Panel Gap',
		type:'text'
	},
	groove_depth: {
		id: 'groove_depth',
		name: 'Groove Depth',
		type: 'text',
		applicability: ['strip_light_accessory']
	}
}