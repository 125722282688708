export const fileCommands = [
    {
        value: 'GetPricingQuotationDetailsJSON',

        label: 'Pricing Quotation JSON', 
        
        addons: ["1", "2", "3", "4", "5", "6", "7"]
    },
    
    {
        value: 'GetPricingQuotationPdf',
        
        label: 'Pricing Quotation PDF',
        
        addons: ["1", "2", "3", "4", "5", "6", "7"]
    },
    
    {
        value: 'GetPricingQuotationCsv',
        
        label: 'Pricing Quotation CSV',
        
        addons: ["1", "2", "3", "4", "5", "6", "7"]
    },
    
    {
        value: 'GetPricingQuotationXlsx',
        
        label: 'Pricing Quotation XLSX', 
        
        addons: ["1", "2", "3", "4", "5", "6", "7"]
    },
    
    {
        value: 'GetCabinetCompositeBoq',
        
        label: 'Composite Cabinet BOQ',
        
        addons: ["1", "4", "6", "7"]
    },
    
    {
        value: 'GetStandardWoodenRmOutput',
        
        label: 'Wooden Raw Material List',
        
        addons: ["1", "4", "5", "6", "7"]
    },
    
    {
        value: 'PrintElevationViews',
        
        label: 'Elevation Views',
        
        addons: ["1", "2", "3", "4", "5", "6", "7"]
    },
    
    {
        value: 'GetAllPresentationSheetsPdf',
        
        label: 'Presentation PDF',
        
        addons: ["1", "2", "3", "4", "5", "6", "7"]
    },
    
    {
        value: 'GetCNCMachineOutputPdf',
        
        label: 'CAM Panel Drawings PDF',
        
        addons: ["1", "7"]
    },
    
    {
        value: 'GetCNCMachineOutputXcs',
        
        label: 'CAM XCS',
        
        addons: ["1", "7"]
    },
    
    {
        value: 'GetCNCMachineOutputMpr',
        
        label: 'CAM MPR',
        
        addons: ["1", "7"]
    },
    
    {
        value: 'GetCNCMachineOutputCix',
        
        label: 'CAM CIX',
        
        addons: ["1", "7"]
    },
    
    {
        value: 'GetFulfillmentTags',
        
        label: 'Panel Label PDF',
        
        addons: ["1", "4", "6", "7"]
    },
    
    {
        value: 'GetFloorplanRoomViews',
        
        label: 'Floorplan PDF',
        
        addons: ["1", "2", "3", "4", "5", "6", "7"]
    },
    
    {
        value: 'GetStandardHardwareRmOutput',
        
        label: 'Hardware Raw Material List',
        
        addons: ["1", "4", "5", "6", "7"]
    },
    
    {
        value: 'GetCutlistCsv',
        
        label: 'Cutlist: General',
        
        addons: ["1", "4", "6", "7"]
    },
    
    {
        value: 'GetManufacturingCutlistCsv',
        
        label: 'Cutlist: Biesse',
        
        addons: ["1", "4", "6", "7"]
    },
    
    {
        value: 'GetKDTCutlistXml',
        
        label: 'Cutlist: KDT',
        
        addons: ["1", "4", "6", "7"]
    },
    
    {
        value: 'GetAllBoardTypeAllBoards',
        
        label: 'Board Layout',
        
        addons: ["1", "4", "6", "7"]
    },
    
    {
        value: 'GetBoardLayoutCount',
        
        label: 'Consolidated Board List',
        
        addons: ["1", "4", "6", "7"]
    },
    
    {
        value: 'GetBoardLayoutFinishCount',
        
        label: 'Consolidated Finish List',
        
        addons: ["1", "4", "6", "7"]
    },
    
    {
        value: 'GetCabinetCarcassImos',
        
        label: 'Carcass Manufacturing Document',
        
        store_id: ['ed8b867fb42fde50']
    },
    
    {
        value: 'GetCabinetShutterImos',
        
        label: 'Shutter Manufacturing Document',
        
        store_id: ['ed8b867fb42fde50']
    },
    
    {
        value: 'GetCabinetWoodemRmBoq',
        
        label: 'Cabinet Woodem RM BOQ',
        
        store_id: ['ed8b867fb42fde50']
    },
    
    {
        value: 'GetCabinetHardwareRmBoq',
        
        label: 'Cabinet Hardware RM BOQ',
        
        store_id: ['ed8b867fb42fde50']
    },
    
    {
        value: 'GetCabinetPurchaseOrderSheet',
        
        label: 'Cabinet Purchase Order Sheet',
        
        store_id: ['ed8b867fb42fde50']
    },
    
    {
        value: 'CasantroCustomOutput',

        label: 'Cassantro Custom Output',

        store_id: ['ed8b867fb42fde50']

    }]