import React , { useState , useEffect, useRef, useContext }from 'react';
import build_path from '../../../utils/path';
import './sku_groups.css'
import { Input } from 'reactstrap';
import SingleSelect from '../../../components/SingleSelect';
import { OrgBuContext } from '../../../components/Contexts/OrgBuContext';
import { UserContext } from '../../../components/Contexts/UserContext';

const styles = {
	text_elipsis : {
		whiteSpace: "nowrap", 
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	padding_dropdown : {
		padding : '0px !important'
	},
	owned_style: {
		display: "unset",
		position: "absolute", 
		backgroundColor:"#000033", 
		color:'white',
		borderRadius:"3px",
		zIndex:10,
		top:'6px', 
		right:'6px',
		fontSize:'12px'
	},
	sku_checkbox_style: {
		display: "unset",
		position: "absolute", 
		backgroundColor:"#000033", 
		color:'white',
		borderRadius:"3px",
		margin:'0px',
		zIndex:10,
		top:'6px', 
		left:'6px',
		fontSize:'12px',
		cursor:'pointer'
	}
};

const GroupInventoryCard = ({ group_store_id, fetch_sku_groups, sku_division_id, sku_sub_category_id, sku, title, link, Header_Element, edit_plus_action, inventory_item_id, SKU_group_name, SKU_Dropdown, Group_Dropdown, sku_group, border_animation_group, set_border_animation_group, pre_selected_sku, set_pre_selected_sku, bulk_selection_mode, set_bulk_selection_mode, bulk_selected_skus, set_bulk_selected_skus, bulk_selected_grps, update_bulk_selected_skus, update_bulk_selected_grps, no_of_bulk_selected_skus_in_grp, update_no_of_bulk_selected_skus_in_grp, no_of_bulk_selected_skus_in_subcat, update_no_of_bulk_selected_skus_in_subcat, showing_bulk_edit_preview, group_business_unit_id}) => {
	
	const [ selected_sku_id , set_selected_sku_id ] = useState(null);
	const [ selected_sku , set_selected_sku ] = useState("");
	const [dropdown_open, set_dropdown_open] = useState(false);
	const [group_dropdown_open, set_group_dropdown_open] = useState(false);
	const [number_of_owned_skus, set_number_of_owned_skus] = useState(0);
	const [setting_indeterminate_state, set_setting_indeterminate_state] = useState();
	// const [bulk_selected_skus_in_this_grp, set_bulk_selected_skus_in_this_grp] = useState({});
	const myRef = useRef(null)
	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)

 	// const toggle = () => setDropdownOpen(prevState => !prevState);

	const header_plus_action = e => {
		console.log('clicked plus')
		e.stopPropagation();
		edit_plus_action(inventory_item_id);
	}

	// const update_bulk_selected_skus_in_this_grp = (sku_id, value) => {
	// 	let a = {...bulk_selected_skus_in_this_grp}
	// 	a[sku_id] = value
	// 	set_bulk_selected_skus_in_this_grp(a)
	// }

	const check_grp_skus = (value) => {
		
		update_no_of_bulk_selected_skus_in_grp(sku_group.id, value ? sku.length : 0)

		let temp=0
		if(sku && sku.length){
			for(let i = 0; i < sku.length; i++){
				if(bulk_selected_skus[sku[i].id]) temp++
			}
		}
		if(value) update_no_of_bulk_selected_skus_in_subcat(sku_sub_category_id, no_of_bulk_selected_skus_in_subcat[sku_sub_category_id] ? no_of_bulk_selected_skus_in_subcat[sku_sub_category_id]+(sku.length-temp) : (sku.length-temp) )
		if(!value) update_no_of_bulk_selected_skus_in_subcat(sku_sub_category_id, no_of_bulk_selected_skus_in_subcat[sku_sub_category_id]-temp)
		let a = {...bulk_selected_skus}
		if(sku && sku.length){
			for(let i = 0; i < sku.length; i++){
				a[sku[i].id] = value
			}
		}
		set_bulk_selected_skus(a)

		// let b = [...bulk_selected_skus_in_subcat]
		// if(value == true){
		// 	if(sku && sku.length){
		// 		for(let i = 0; i < sku.length; i++){
		// 			if(!b.find(o => o==sku[i].id)) b.push(sku[i].id)
		// 		}
		// 	}
		// }else if(value == false){
		// 	if(sku && sku.length){
		// 		for(let i = 0; i < sku.length; i++){
		// 			let id = b.indexOf(sku[i].id)
		// 			b.splice(id,1)
		// 		}
		// 	}
		// }
		// set_bulk_selected_skus_in_subcat(b)
		
	}

	useEffect(() => {
		if(sku && sku.length && sku_group && sku_group.id != border_animation_group){
			set_selected_sku(sku[0])
			set_selected_sku_id(sku[0].id)
		}else if(sku && sku.length && sku_group && sku_group.id == border_animation_group && pre_selected_sku){
			set_selected_sku_id(pre_selected_sku)
		}

		// if(sku && sku.length && sku_group){
		// 	if(pre_selected_sku && sku.find(o => o.id == pre_selected_sku)){
		// 		set_selected_sku_id(pre_selected_sku)
		// 	}else{
		// 		set_selected_sku(sku[0])
		// 		set_selected_sku_id(sku[0].id)
		// 	}
		// }
	},[sku, sku_group])

	useEffect(() => {
		if(selected_sku_id && sku ){
			
			var selected_sku_details = sku.filter((x) => x.id == selected_sku_id )[0]
			set_selected_sku(selected_sku_details);
		}
	},[selected_sku_id])

	useEffect(() => {
		if(border_animation_group && sku_group && border_animation_group==sku_group.id){

			let t = myRef.current?.scrollIntoView({behavior: 'smooth'})
			// window.scrollTo({
			// 	top:0,
			// 	left:0,
			// 	behavior:'smooth'
			// })
		}
	}, [border_animation_group, sku_group]);

	useEffect(() => {
		let temp = 0
		let t = sku && sku.length ? sku.map(single_sku => single_sku.business_unit_id === user.current_business_unit_id ? temp++ : '') : ''
		// let t = sku && sku.length ? sku.map(single_sku => single_sku.store_id === user.store_id ? temp++ : '') : ''
		set_number_of_owned_skus(temp)
	}, [sku]);

	useEffect(() => {
		let temp = 0
		if(sku && sku.length && bulk_selected_skus){
			for(let i = 0; i < sku.length; i++){
				// console.log('update_bulk_selected_grps 2222', bulk_selected_skus, sku[i].id)
				if(!bulk_selected_skus[sku[i].id]) temp = 1
			}
			console.log('update_bulk_selected_grps 22221', sku_group.id, temp)
			if(!temp && !bulk_selected_grps[sku_group.id]) update_bulk_selected_grps(sku_group.id, true)
			if(temp && bulk_selected_grps[sku_group.id]) update_bulk_selected_grps(sku_group.id, false)
		}
		// console.log('update_bulk_selected_grps 2222', bulk_selected_skus, sku, sku.length)
	}, [bulk_selected_skus, sku]);

	useEffect(() => {
		var checkExist = setInterval(function() {
			if(document.getElementById("grp_selection_checkbox"+sku_group.id)) {
				// console.log("Exists!", no_of_bulk_selected_skus_in_grp && sku && sku.length && document.getElementById("grp_selection_checkbox"+sku_group.id));
				clearInterval(checkExist);
				set_setting_indeterminate_state(true)
			}
        }, 100); 
	}, [no_of_bulk_selected_skus_in_grp])

	useEffect(() => {
		if(setting_indeterminate_state && no_of_bulk_selected_skus_in_grp && sku && sku.length && document.getElementById("grp_selection_checkbox"+sku_group.id)){
			if(no_of_bulk_selected_skus_in_grp[sku_group.id] > 0 && no_of_bulk_selected_skus_in_grp[sku_group.id] < sku.length){
				document.getElementById("grp_selection_checkbox"+sku_group.id).indeterminate = true;
			}else if(!no_of_bulk_selected_skus_in_grp[sku_group.id]){
				document.getElementById("grp_selection_checkbox"+sku_group.id).checked = false;
				document.getElementById("grp_selection_checkbox"+sku_group.id).indeterminate = false;
			}else if(no_of_bulk_selected_skus_in_grp[sku_group.id] == sku.length){
				document.getElementById("grp_selection_checkbox"+sku_group.id).checked = true;
				document.getElementById("grp_selection_checkbox"+sku_group.id).indeterminate = false;
			}
			set_setting_indeterminate_state(false)
		}
	}, [setting_indeterminate_state]);

	return (
		<div ref={myRef} style={{padding:'5px'}} >
			<div id={sku_group.id} onClick={() => {if(set_border_animation_group)set_border_animation_group(null)}} style={{backgroundColor: '#F8F9FA'}} className={sku_group && sku_group.id==border_animation_group?'card_style on_hover_card_border animate_box_shadow':'card_style on_hover_card_border'}>
				<div className='flex_property hover_1' style={{height:'28px', width:'100%', backgroundColor: '#F8F9FA',borderRadius: '4px 4px 0 0', padding:'0px 12px', marginTop:'2px', justifyContent:'space-between'}}>
					<div className='flex_property group_name cp' style={{maxWidth:'75%'}} 
						onClick = {() => {
											if(bulk_selected_grps){
												let temp = bulk_selected_grps[sku_group.id];
												update_bulk_selected_grps(sku_group.id, temp ? false : true);
												if(temp) check_grp_skus(false);
												if(!temp) check_grp_skus(true)
											}
									}}>
						{
							selected_sku_id && !showing_bulk_edit_preview ?
							<input id={'grp_selection_checkbox'+sku_group.id} type='checkbox' className='hover_2 grp_selection_checkbox' style={{display:bulk_selection_mode ? 'block' : 'none', marginRight:'6px', }}
								checked = {bulk_selected_grps[sku_group.id] ? true : false} 
							/>:''
						}
						<div title={title} style = {{...styles.text_elipsis, width:'100%', textAlign:'left', fontSize:'14px'}} >
							{title}
						</div>
					</div>
					{
						!showing_bulk_edit_preview?
						<div className='flex_center'>
							<div className='hover_2' style = {{display:'flex', justifyContent:'flex-end' , marginRight:'15px', height:'15px',width:'15px'}}>
								<Header_Element on_click={header_plus_action} />
							</div>
							
							<div className='hover_2' style = {{display:'none'}}>
								<Group_Dropdown
									active_sku_group={inventory_item_id}
									group_dropdown_open={group_dropdown_open}
									set_group_dropdown_open={set_group_dropdown_open}
									group_store_id={group_store_id}
									sku_group={sku_group}
									is_owned={group_business_unit_id == user.current_business_unit_id}
								/>
							</div>
						</div>:''
					}
						
				</div>
				<div className='flex_property' style={{height:'16px', padding:'0px 15px',justifyContent:'space-between', fontSize:'14px', color:'#C5C7CF', marginBottom:'4px'}}>
					{
						sku && sku.length ?
						<span>{selected_sku_id && sku.find(o => o.id == selected_sku_id) ? sku.indexOf(sku.find(o => o.id == selected_sku_id))+1 : ''}/{sku ? sku.length : ''} sku</span>
						:
						<span>0 skus</span>
					}
					<span>{number_of_owned_skus} owned</span>
				</div>
				<div style={{height:'162px', width:'94%', marginLeft:'3%', marginBottom:'3%', backgroundColor:'white'}}>
					<div className='flex_property hover_1 cp' style={{height:'132px', width:'100%', backgroundColor:'rgba(255,255,255,0.10)', borderRadius: '4px', justifyContent:'center', position:'relative'}}
						onClick = {() => {
							if(bulk_selected_skus){
								let temp = bulk_selected_skus[selected_sku_id];
								update_bulk_selected_skus(selected_sku_id, bulk_selected_skus[selected_sku_id] ? false : true);
								if(temp){update_no_of_bulk_selected_skus_in_subcat(sku_sub_category_id, no_of_bulk_selected_skus_in_subcat[sku_sub_category_id]-1); update_no_of_bulk_selected_skus_in_grp(sku_group.id, no_of_bulk_selected_skus_in_grp[sku_group.id]-1)};
								if(!temp){update_no_of_bulk_selected_skus_in_subcat(sku_sub_category_id, no_of_bulk_selected_skus_in_subcat[sku_sub_category_id] ? no_of_bulk_selected_skus_in_subcat[sku_sub_category_id]+1 : 1);update_no_of_bulk_selected_skus_in_grp(sku_group.id, no_of_bulk_selected_skus_in_grp[sku_group.id] ? no_of_bulk_selected_skus_in_grp[sku_group.id]+1 : 1)}
							}
							}} 
					>
						{
							selected_sku_id && !showing_bulk_edit_preview?
							<Input className='hover_2' checked = {bulk_selected_skus[selected_sku_id] ? true : false} 
							   	   style={bulk_selection_mode ? styles.sku_checkbox_style : {...styles.sku_checkbox_style, display:'none'}} 
							       type='checkbox' 
							/>:''
						}
						{	
							user && selected_sku && ((org_bu_view=='bu' && selected_sku.business_unit_id === user.current_business_unit_id) || (org_bu_view=='org' && selected_sku.store_id === user.store_id))?
							(
								<label style = {styles.owned_style} className="label ng-scope" > OWNED </label>
							):("")
						}
						<img style={{maxHeight:'100%', maxWidth:'100%'}}  src=
							{
								selected_sku && selected_sku.display_pic && selected_sku.display_pic.image ? 
								(
									build_path(global.config.static_path , selected_sku.display_pic.image.replace('.png', '_thumbnail.png').replace('.jpg', '_thumbnail.jpg').replace('.jpeg', '_thumbnail.jpeg'))
								):( 	
									selected_sku ? global.config.sku_image_not_found : global.config.no_skus_found_in_group
								)
							}  
						/>
					</div>
					{/* <div style={{border: '1px solid #F2F4F6'}}></div> */}
					<div className='flex_property hover_1' style={{height:'28px', width:'100%', padding:'0px 15px 4px 15px'}}>
					
							<div style = {{width:'100%'}}>
								<Input id='select_sku_in_group' disabled={sku && sku.length?false:true} style={{color:'#435A6F'}} className='card_select' type="select" value = {selected_sku && selected_sku.id ? selected_sku.id :'' } onChange={sku && sku.length?e => {console.log("sku id ,, ", sku_group.id, 'e', e);set_selected_sku_id(e.target.value)}:null}>
									{
										sku && sku.length && sku.map((list_value, idx)=>
											<option key={idx} value={list_value.id}>{list_value.name}</option>
										)	
									}
								</Input>
							</div>
					{
						!showing_bulk_edit_preview?
						<>
							<div className = 'hover_2' >
								{
									sku && sku.length>0 && selected_sku?
									( 
										<SKU_Dropdown 
											active_sku={selected_sku}
											dropdown_open={dropdown_open}
											set_dropdown_open={set_dropdown_open}
											sku_group_id={inventory_item_id}
											SKU_group_name={SKU_group_name}
											sku_division_id={sku_division_id}
										/>
									):(
										null
									)	
								}	
							</div>
						</>:''
					}
					</div>
				</div>
			</div>
		</div>
	);
	
};

GroupInventoryCard.defaultProps = {
	Footer_Element: () => (<></>)
}

export default GroupInventoryCard;


// {	
// 	group_store_id === user.store_id?
// 	(
// 		<div className={group_store_id === user.store_id?'hover_2':''} style = {{flex:1, display:'none', paddingRight:'10px'}}>
// 			<Group_Dropdown
// 				active_sku_group={inventory_item_id}
// 				group_dropdown_open={group_dropdown_open}
// 				set_group_dropdown_open={set_group_dropdown_open}
// 				group_store_id={group_store_id}
// 			/>
// 		</div>
// 	):("")
// }