import React, { useState } from 'react';

const KeyValuePairs = ({ pairs, setPairs, key_pair = 'key', value = 'value' }) => {
    //   const [pairs, setPairs] = useState([{ key: '', value: 0 }]);

    const handleAddPair = () => {
        const lastPair = pairs[pairs.length - 1];
        if (pairs.filter(x => x[key_pair] == '').length == 0) {
            // setPairs([...pairs, { key: '', value: 0 }]);
            let pairsCopy = JSON.parse(JSON.stringify(pairs))
            let newPair = {}
            newPair[key_pair] = ''
            newPair[value] = 0
            pairsCopy.push(newPair)
            setPairs(pairsCopy)
        }
        // setPairs([...pairs, { key: '', value: 0 }]);
    };

    const handleDeletePair = (index) => {
        const updatedPairs = [...pairs];
        updatedPairs.splice(index, 1);
        setPairs(updatedPairs);
    };

    const handleChangeKey = (index, newKey) => {
        const updatedPairs = [...pairs];
        updatedPairs[index][key_pair] = newKey;
        setPairs(updatedPairs);
    };

    const handleChangeValue = (index, newValue) => {
        const updatedPairs = [...pairs];
        updatedPairs[index][value] = newValue;
        setPairs(updatedPairs);
    };

    return (
        <div>
            {/* <h2>Key-Value Pair UI</h2> */}
            {/* <div style={{ textAlign: 'right' }}>
                <button className='white_button mb-2' onClick={handleAddPair}>Add Row</button>
            </div> */}
            {pairs.map((pair, index) => (
                <div key={index} className='mb-2'>
                    <input
                        type="text"
                        placeholder="Tool Name"
                        value={pair[key_pair]}
                        onChange={(e) => handleChangeKey(index, e.target.value)}
                        className='mr-2'
                    />
                    <input
                        type="number"
                        placeholder="Tool Diameter"
                        value={pair[value]}
                        onChange={(e) => handleChangeValue(index, Number(e.target.value))}
                        className='mr-2'
                    />
                    <i className='fa fa-trash cp' style={{ color: 'red' }} onClick={() => handleDeletePair(index)}></i>
                </div>
            ))}
            <div style={{ textAlign: 'left' }}>
                {/* <button className='white_button mb-2' onClick={handleAddPair}>Add Row</button> */}
                <span className='mb-2' style={{ color: '#2688F7', fontSize: '14px', cursor: 'pointer', fontWeight: '700' }} onClick={handleAddPair}>+ Add Row</span>
            </div>
        </div>
    );
};

export default KeyValuePairs;
