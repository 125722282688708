import React, { useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import useStateCallback from './utils/useStateCallback.js';
import AdminHome from './views/AdminHome/index.js';
import Designs from "./views/Designs/";
import Error from "./views/Error/";
import SKU_Groups from "./views/Catalogue/SKU_Groups/";
import './App.css';
import './overridden_styles.css'
// import './assets/css/font-awesome-5/all.min.css';
import Material_template from './views/Material_template/index.js';
import general_fetch from './utils/fetch';
import Brand from './views/Brand/';
import ArchiveCatalogue from './views/ArchiveCatalogue/';
import UsageMetrics from './views/UsageMetrics/';
import DC_rules from './views/DesignConstraint/index.js';
import PanelPricingRule from './views/PanelPricingRule/';
import CategoryType from './views/CategoryType/';
import Tags from './views/Tags/';
import ProjectStatuses from './views/ProjectStatuses';
import SalesChannel from './views/SalesChannel/'
import Billing from './views/Billing/'
import DeleteNotification from "./components/DeleteNotification/"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Sidebar from './components/Sidebar';
import name_sort from './utils/name_sort';
import { useAlert } from 'react-alert'
import MF_rules from './views/MfRules/index.js';
import HW_rules from './views/HwRules/index.js';
import AM_rules from './views/AmRules/index.js';
import FeatureFlags from './views/FeatureFlag/index.js';
import Design_Preferences from './views/Design_Preferences/index.js';
import Store_Preferences from './views/Store_Preferences/index.js';
import DesignerInput from './views/DesignerInput/index.js';
import DesignTemplates from './views/DesignTemplates/index.js';
import BulkCreateMenu from './views/Catalogue/SKU_Groups/BulkCreateMenu.js';
import { Unauthorised_toast } from './components/Unauthorised_toast/index.js';
import RoomType from './views/RoomType';
import BusinessUnits from './views/BusinessUnits/index.js';
import io from 'socket.io-client';
import * as Sentry from "@sentry/react";
import path from 'path';
import DesignIntelligence from './views/DesignIntelligence/index.js';
import CustomReport from './views/CustomReport/index.js';
import { AllBuContext } from './components/Contexts/AllBuContext.js';
import { OrgBuContext } from './components/Contexts/OrgBuContext.js';
import { UserContext } from './components/Contexts/UserContext.js';
import { AllUsersContext } from './components/Contexts/AllUsersContext.js';
import Billing_usage from './views/Billing_usage/index.js';
import PricingSettings from './views/PricingSettings/index.js';
import AdditionalParameters from './views/AdditionalParameters/index.js';
import FeatureFlagAddonMap from './views/FeatureFlag_addon_map/index.js';
import RedirectTab from './components/RedirectTab/index.js';
import { WasmLoadedContext } from './components/Contexts/WasmLoadedContext.js';
import { app_loader_atom } from './AppRecoil/atoms.js';
import {useRecoilState} from 'recoil';
import ToastError from './components/ToastError/index.js';
import debug_log from './utils/debug_log.js';
import GSTModal from './components/GSTModal/index.js';
import { gt } from 'semver';
import { AdminVersionContext } from './components/Contexts/AdminVersionContext.js';
import { IModalBody, IModalHeader } from './components/IModal/index.js';
import { Button, Modal } from 'reactstrap';
import { BuDetailsContext } from './components/Contexts/BuDetailsContext.js';
import RuleWizard from './views/RuleWizard/index.js';
import Construction_style from './views/construction_style/index.js';
import BulkEditSkuStructureParent from './views/BulkEditSkuStructureParent/index.js';
// let analytics = require('./react-segment');

// var analytics = function() {
//     var analytics = window.analytics = window.analytics || [];
//     if (!analytics.initialize)
//         if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
//         else {
//             analytics.invoked = !0;
//             analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"];
//             analytics.factory = function(t) {
//                 return function() {
//                     var e = Array.prototype.slice.call(arguments);
//                     e.unshift(t);
//                     analytics.push(e);
//                     return analytics
//                 }
//             };
//             for (var t = 0; t < analytics.methods.length; t++) {
//                 var e = analytics.methods[t];
//                 analytics[e] = analytics.factory(e)
//             }
//             analytics.load = function(t) {
//                 var e = document.createElement("script");
//                 e.type = "text/javascript";
//                 e.async = !0;
//                 e.src = ("https:" === document.location.protocol ? "https://" : "http://") + "stagesegmentcdn.infurnia.com/analytics.js/v1/" + t + "/analytics.min.js";
//                 var n = document.getElementsByTagName("script")[0];
//                 n.parentNode.insertBefore(e, n)
//             };
//             analytics.SNIPPET_VERSION = "4.0.0";
//         }
//   return analytics;
// }();

const exclude_list = ["localhost", "admin_local_dev", "devadmin", "testadmin", "testpreprodadmin","stage2admin","stage1admin","stage4admin","stageadmin", "localadmin"];

const Access_component = ({children, user,set_active_tab, active_tab, store_details, valid_tab, active_division_id_sidebar, set_active_division_id_sidebar, valid_division_id_sidebar}) => {

    const [display_tab, set_display_tab] =useState(false)
    const org_bu_view = useContext(OrgBuContext)

    const check_route_fn = () => {
        var allow = false;
        if (!active_tab || active_tab != valid_tab) {
            return
        }
        const org_routes = ['billing', 'preferences_settings']
        console.log('Route working inside fn', active_tab)
        let roles
        // if (org_bu_view == 'org') {
            roles = user && user.role && user.role.store && user.role.store.length ? user.role.store.map(x => x) : []
        // } else 
        if (org_bu_view == 'bu') {
            roles = roles.concat(user && user.role && user.role.business_unit ? user.role.business_unit[user.current_business_unit_id] : [])
        }

        if (['catalogue', 'Sales', 'Brands', 'Tags'].includes(active_tab)) {
            allow = roles && roles.includes('catalog_manager') ? true : false;
        }else if(active_division_id_sidebar === 'rule_wizard'){
            allow = [31, 48].includes(user.id)
        } else if(active_tab === 'home') {
            allow = true;
        } else if (active_tab === 'billing') {
            allow = roles && roles.includes('accounts_manager') ? true : false;
        } else if (active_tab === 'design_hub') {
            allow = roles && roles.includes('project_manager') ? true : false;
        } else if (active_tab === 'project_hub') {
            allow = roles && roles.includes('project_manager') ? true : false;
        } else if (active_tab === 'category_type') {
            allow = global.config.cat_type_allowed_store.filter(x => user.id == x).length ? true : false;
        } else if (['Usage_Metrics'].includes(active_tab)) {
            allow = store_details.enterprise && roles && roles.includes('catalog_manager') ? true : false;
        } else if (['preferences_settings'].includes(active_tab) && ['designer_input'].includes(active_division_id_sidebar)) {
            allow = (store_details.enterprise || store_details.type == 'business') && roles && roles.includes('catalog_manager') ? true : false;
        } else if (['DesignIntelligence'].includes(active_tab)) {
            allow = store_details && (store_details.enterprise || store_details.type == 'business') && roles && roles.includes('catalog_manager') ? true : false;
        } else if (active_tab === 'price_detail') {
            allow = global.config.pp_rules_allowed_store.filter(x => user.store_id === x).length ? true : false;
        } else if (active_tab === 'material_template') {
            allow = global.config.material_template_allowed_users.filter(x => user.id === x).length ? true : false;
        } else if (active_tab === 'design_preferences') {
            // console.log(user)
            allow = global.config.master_preferences_allowed_users.filter(x => user.id == x).length ? true : false;
        }else if(active_tab==='FeatureFlag_addon_map'){
            console.log(user)
            allow = global.config.addon_featureflag_mapping_allowed_users.filter(x=> user.id==x).length?true : false;
		} else if (active_tab === 'ArchiveCatalogue') {
            allow = store_details && store_details.catalog_dump_enabled ? true : false;
            // console.log('allow',allow)
        } else if (active_tab === 'CustomReport') {
            allow = store_details && store_details.ar_violation_dump_enabled ? true : false;
            // console.log('allow',allow)
        } else {
            // console.log('not allowing')
            allow = !roles ? false : true;
        }
        if (!allow && global) {
            // window.location.href ='/noaccess';
            //alert.info('Please contact your admin for access')
        }
        
        if(org_bu_view === 'org'){
            allow = allow && (active_tab == 'home' || org_routes.includes(active_tab))
        }
        set_display_tab(allow)
    }

    useEffect(() => {
        console.log('setting active tab', valid_tab)
        set_active_tab(valid_tab) 
    },[valid_tab])

    useEffect(() => {
        if(valid_division_id_sidebar) set_active_division_id_sidebar(valid_division_id_sidebar)
    }, [valid_division_id_sidebar]);

    useEffect(() => {
        console.log('Route working inside useeffect')
        check_route_fn()
    },[active_tab, user, store_details])
    useEffect(() => {
        console.log('display tab change, display_tab', display_tab)
    },[display_tab])

    return(
        <>{display_tab?<>{children}</>:<Error user={user} store_details={store_details}/>}</>
    )
}


const Single_Toast = ({ message, type }) => {
    try {
        return (
            <div 
                style={{ position: 'fixed', bottom: 5, right: 5, zIndex: 9999 }}
            > 
                Demo toast {message}
            </div>
        )
    } catch(err) {
        console.error('error', err);
        return "";
    }
}


const Toaster_List = ({ active_toasts, set_active_toasts }) => {
    try {
        return (
            <>
                {active_toasts.map((toast, index) => 
                        <Single_Toast key={index} message={toast.message} type={toast.type} />
                )}
            </>
        )
    } catch(err) {
        console.error("something went wrong in the toaster list comp -==>>> ", err);
        return "";
    }
}

// function loadWebAssembly() {
//     return new Promise((resolve, reject) => {
//       const script = document.getElementById('wasm-graphics');
//       script.onload = () => {
//         resolve();
//       };
//       script.onerror = (error) => {
//         reject(error);
//       };
//     });
//   }
  

const App = () => {
  // try {
    const alert = useAlert()
    const history = useHistory();
    const [ initialized, set_initialized ] = useState(false);
    const [ user, set_user ] = useState(null);
    const [all_users, set_all_users] = useState(null);
    const [wasm_loaded, set_wasm_loaded] = useState(0);
    const [socket, setSocket] = useState(null);
    const [ store_details, set_store_details ] = useState({});
    const [bu_details, set_bu_details] = useState({});
    const [current_business_unit_id, set_current_business_unit_id] = useState('');
    const [all_bu_in_store, set_all_bu_in_store] = useState([]);
    const [ is_authenticating, set_is_authenticating ] = useState(true);
    const [ orignal_tags , set_orignal_tags] = useState([]);
    const [ active_tab , set_active_tab] = useState('');
    const [ active_division_id_sidebar , set_active_division_id_sidebar] = useState('');
    const [org_bu_view, set_org_bu_view] = useState(window.sessionStorage.getItem('org_bu_view') ? window.sessionStorage.getItem('org_bu_view') : 'bu');

    const [design_templates, set_design_templates] = useState(null);
    const [downtime_var, set_downtime_var] = useState(false);
    const [ is_unautherised, set_is_unautherised ] = useState(false);
    const [page_loader,set_page_loader] = useStateCallback({
        show: false,
        text: "Please wait ..."
    });
	const [app_loader, set_app_loader] = useRecoilState(app_loader_atom)

    const [ active_toasts, set_active_toasts ] = useState([]);
	const [show_gst_modal, set_show_gst_modal] = useState(false);

    const [version_outdated, set_version_outdated] = useState(false);
    const [version_outdated_warninng, set_version_outdated_warninng] = useState(false);
    const [version_outdated_modal_closed, set_version_outdated_modal_closed] = useState(false);

    const toggle_version_outdated_warning = () => {set_version_outdated_warninng(!version_outdated_warninng); set_version_outdated_modal_closed(true)}

    global.set_org_bu_view = set_org_bu_view
    global.set_downtime_var = set_downtime_var
    window.set_is_unautherised = set_is_unautherised


    const create_new_toast = toast_data => {
        console.log("creating new toast", toast_data);
        set_active_toasts([...active_toasts, toast_data]);
    }

    window.trigger_alert = (message, options) => {
        alert.show(message, options)
    }

    const get_user = () => {
        return user
    }

    global.get_user = get_user


    // useEffect(() => {
    //     console.log('initializes called', initialized)
    // }, [initialized]);

    // useEffect(() => {
    //     console.log('is_authenticating called', is_authenticating)
    // }, [ is_authenticating ]);

    // useEffect(() => {
    //     console.log('orignal_tags called', orignal_tags)
    // }, [ orignal_tags ]);

    // useEffect(() => {
    //     console.log('initializes called',page_loader.show)
    // }, [ page_loader ]);

    const authenticate = async () => {
        try {
            if (process.env && process.env.REACT_APP_ADMIN_VERSION) {
                console.log('admin version', process.env.REACT_APP_ADMIN_VERSION)
            } else {
                console.log('admin version', 'not set')
            }
            var user_details = await general_fetch({ url: 'user/get_details', dataField: 'user' });
            if(user_details.user_details && !user_details.user_details.current_business_unit_id){
                user_details.user_details.current_business_unit_id = user_details.user_details.business_unit_ids[0]
            }
			if(!user_details || !user_details.user_details || !user_details.user_details.role || !user_details.user_details.role.business_unit || !user_details.user_details.current_business_unit_id || !user_details.user_details.role.business_unit[user_details.user_details.current_business_unit_id]){
				if(window.location.pathname !== '/redirect_to_infurnia/') window.location.href = global.config.auth_redirect_path
			}
            user_details && set_user(user_details.user_details);
            return 1;
            // console.log(user_details)
        } catch(err) {
            set_is_authenticating(false);
        }
    }

    const submit = (yes_del, no_del, message, nonowned,button_name, Custom_modal, theme) => {
        confirmAlert({
          title: 'Confirm to submit',
          message: message,
          customUI: ({ onClose }) => {
            return (
                Custom_modal ? 
                <div>
                    <Custom_modal yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} nonowned={nonowned} button_name={button_name} />
                </div>
                :
                <div className='custom-ui'>
                    <DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} nonowned={nonowned} button_name={button_name} theme={theme}/>
                </div>
            );
          }
        });
    };

    const send_analytics_event = (event, category, label, value, url, property, event_name) =>{
        if(window['analytics']){
            try {
                if(event == 'auto'){
                    window['analytics'].page({
                        "company_id": store_details ? store_details.id : ''
                    })
                }else if(event == 'track'){
                    window['analytics'].track('auto', {
                        "sku_name": label,
                        "sku_id": value,
                        "category_type": url,
                        "property_edited": property,
                        "category": event_name,
                        "company_id": store_details ? store_details.id : ''
                    })
                }
            }
            catch(err) {
                console.log("Error in analytics",err)
            }
        }
    }

    // const check_route_fn = (active_tab) => {
	// 	var allow = false;
	// 	var roles = user && user.role && user.role.length ?user.role.map(x=>x.id):''
	// 	if(active_tab==='catalogue'){
	// 		allow = roles && roles.includes('catalog_manager') ?true : false;
	// 	}else if(active_tab==='billing'){
	// 		allow = roles && roles.includes('accounts_manager') ?true : false;
	// 	}else if(active_tab==='design_hub'){
	// 		allow = roles && roles.includes('project_manager') ?true : false;
	// 	}else if(active_tab==='category_type'){
	// 		allow = global.config.cat_type_allowed_store.filter(x=> user.id===x).length?true : false;
	// 	}else if(['MF_rules','DC_rules','designer_input','AM_rules','HW_rules'].includes(active_tab)){
	// 		allow =  store_details.enterprise ?true : false;
	// 	}else if(active_tab==='price_detail'){
	// 		allow = global.config.pp_rules_allowed_store.filter(x=> user.store_id===x).length?true : false;
    //     }else if(active_tab==='design_preferences'){
    //         console.log(user)
    //         allow = global.config.master_preferences_allowed_users.filter(x=> user.id==x).length?true : false;
	// 	}else{
	// 		allow = !roles ?false : true;
	// 	}
    //     if(!allow && global){
    //         // window.location.href ='/noaccess';
    //         //alert.info('Please contact your admin for access')
    //     }
	// }

    const fetch_tags_details = async () => {
        try {
            let tags_details
			if(org_bu_view === 'org'){
				tags_details = await general_fetch({ url: 'tag/get'});
			}else{
				tags_details = await general_fetch({ url: 'tag/get', body: {business_unit_id: user.current_business_unit_id}});
			}
            debug_log("tag app.js")
            set_orignal_tags(tags_details);
        } catch(err) {
            console.log(err);
        }
    }

    const get_store_details = async () => {
        try {
            set_page_loader({
                text:'fetching store info, please wait...',
                show:true
            })
            var resp = await general_fetch({ url: 'store/get_info', body: {include_add_ons: true}});
			if(resp && resp.country === 'IN' && resp.premium_customer && !resp.internal && !(resp.gst_number && resp.location && resp.location.state)){
				set_show_gst_modal(true);
			}
            set_store_details(resp? resp:{});
            set_page_loader({
                show:false
            })
        } catch(err) {
            console.log(err);
        }
    }

    const get_all_users = async () => {
        try {
            var resp = await general_fetch({url: 'user/get_all'});
            set_all_users(resp)
        } catch(err) {
            console.log(err);
        }
    }

    window.get_store_subscription_info = () => {
        return store_details ? {
            add_ons: store_details.add_ons,
            ccr_details: store_details.ccr_details,
            churned: store_details.churned,
            suspended: store_details.suspended,
            premium_customer: store_details.premium_customer
        } : null
    }
    
    const get_store_details_and_set_current_bu_id = async() => {
        await get_store_details()
        set_current_business_unit_id(user.current_business_unit_id)
    }

    global.get_bu_aux_details = async () => {
        try {
            set_page_loader({
                text:'fetching info, please wait...',
                show:true
            })
            var resp = await general_fetch({ url: 'business_unit/get', body: {business_unit_id: current_business_unit_id}});
            debug_log("aux info", resp)
            debug_log("store details during bu details",store_details)
            if(store_details){
                resp.country = store_details.country;
                resp.type = store_details.type;
                resp.default = current_business_unit_id === store_details.default_business_unit_id
            }
            set_bu_details(resp ? resp : {});
            set_page_loader({
                show:false
            })
        } catch(err) {
            console.log(err);
        }
    }

    const get_all_bu_in_store = async() => {
        try{
            set_page_loader({
                text:'fetching Business units, please wait...',
                show:true
            })
            var resp = await general_fetch({ url: 'business_unit/get_all'});
            set_all_bu_in_store(resp && resp.length ? resp : []);
            set_page_loader({
                show:false
            })
        }catch(err){
            console.log(err);
        }
    }

    // const get_projects = async() => {
    //     try{
    //         let resp 
    //         let body = {
	// 			"info_level": "project",
	// 			"include_project_status": true, 
	// 			"include_last_activity_time": true,
	// 			"include_access_info": false
	// 		}
    //         if(org_bu_view == 'org'){
	// 			resp = await general_fetch({url:'project/get_of_store', body})
	// 		}else{
	// 			if(user && user.current_business_unit_id){
	// 				body['business_unit_ids'] = [user.current_business_unit_id]
	// 				resp = await general_fetch({url:'project/get_by_business_unit', body})
	// 			}
	// 		}
    //         let projects = resp && resp.projects && resp.projects.length ? resp.projects : []

    //         console.log("templateProjects", projects)
    //         let designer_id_mapping_temp = {}
    //         let temp = projects && projects.length && projects.map(x => {designer_id_mapping_temp[x.designer_id] = x.designer.name})
    //         set_designer_id_mapping(designer_id_mapping_temp)
    //         console.log("templatettt", designer_id_mapping_temp)
    //     }catch(err){
    //         console.error(err)
    //     }
    // }

    useEffect(() => {
        // loadWebAssembly().then(() => {
            //     Module.create_webgl_context_sphere()
            // })
        if (!initialized) {
            authenticate();
        }

        // if (window.enable_wasm) {
        //     window.wasm_loaded_promise.then(() => {
        //         // if (!initialized) {
        //         //     authenticate();
        //         // }
        //         setTimeout(async() => {
        //             try {
        //                 if (window.Module.create_material_preview_context("webgl_sphere_canvas")) {
        //                     window.Module.init_material_preview_texture(window.location.origin);
        //                     set_wasm_loaded(1)
        //                 }
        //             } catch (err) {
        //                 console.error("Context could not be created")
        //                 set_wasm_loaded(-1)
        //             }
        //         }, 0)
        //         // window.Module.create_webgl_context_sphere()
        //     })
        // }
        
    }, []);
    

    useEffect(() => {
        console.log('set_downtime_var is', downtime_var)
    }, [downtime_var]);
    // useEffect(() => {
    //     console.log("printing project statuses");
    //     console.log(project_statuses);
    // }, [project_statuses]);

    useEffect(() => {
        if (user) {
            try{

                set_is_authenticating(false);
                fetch_tags_details();
                get_store_details_and_set_current_bu_id()
                set_initialized(true);
                get_all_bu_in_store()
                get_all_users()
                // window['analytics']._writeKey=global.config.segment_id;
                // window['analytics'].load(global.config.segment_id);
                // console.log('segment loaded',analytics, global.config)

                const newSocket = io(global.config.server_path,{transports:['websocket']});
                window.$globalSocket = newSocket

                newSocket.on('please_logout', function(data){
                    general_fetch({ url: 'user/logout'});
                    newSocket.emit('log_out');
                    // window.location.href=global.config.auth_redirect_path+'?e=multilogin'
                    let check_enterprise_white_labelled_url = (window.location.host.split('.')[1] == 'infurnia') ? false : true;
                    let path = window.location.pathname;
                    if (check_enterprise_white_labelled_url) {
                        let [ subdomain, domain, extension ] = window.location.host.split('.');
                        window.location.href = `https://login.${domain}.${extension}/?target=admin&path=${path}&e=multilogin`;
                    } else {
                        window.location.href=global.config.auth_redirect_path+`&path=${path}` + '&e=multilogin';
                    }
                    newSocket.close()
                    // window.alert('This session is being logged out because another session of the same user has been detected');
                });

                newSocket.on('please_refresh', function(data){
                    window.location.reload();
                });

                newSocket.on('version_info', function(data){
                    if(data && data.data && data.data.admin_frontend){
                        let socket_version = data.data.admin_frontend.split('v').pop()
                        let backend_version = process.env.REACT_APP_ADMIN_VERSION
                        backend_version = backend_version ? backend_version.split('v').pop() : ''
                        if(!exclude_list.some(x => window.location.origin.includes(x)) && socket_version && backend_version && gt(socket_version, backend_version)){
                            if(!version_outdated){
                                set_version_outdated_warninng(true)
                            }
                            set_version_outdated(true)
                        }
                    }
                });

                if(window.socketVersionLoop){
                    clearInterval(window.socketVersionLoop)
                }
        
                window.socketVersionLoop = setInterval(function(){
                    if(newSocket){
                        newSocket.emit('get_remote_version');
                    }
                },5000);

            }catch(err){
                Sentry.captureException(err)
            }
        }
    }, [user]);

    useEffect(() => {
        if (store_details && user) {
            document.title = store_details.type == 'business' ? 'Admin' + (store_details.name ? ' - '+store_details.name : '') : 'Admin - Infurnia'
            if(!window.location.origin.includes('infurnia.com')){
                var link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.head.appendChild(link);
                }
                link.href = global.config.static_path + '/' + store_details.logo_path;
            }
            (() => {
                var analytics = window.analytics = window.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
                    analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"]; analytics.factory = function (e) { return function () { var t = Array.prototype.slice.call(arguments); t.unshift(e); analytics.push(t); return analytics } }; for (var e = 0; e < analytics.methods.length; e++) { var key = analytics.methods[e]; analytics[key] = analytics.factory(key) } analytics.load = function (key, e) { var t = document.createElement("script"); t.type = "text/javascript"; t.async = !0; t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js"; var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(t, n); analytics._loadOptions = e }; window['analytics']._writeKey = global.config.segment_id;; analytics.SNIPPET_VERSION = "4.15.3";
                    analytics.load(global.config.segment_id);
                    // analytics.page();
                }
            })();
            if(window['analytics']){
                try{
                    window['analytics'].identify(user.id, {
                        name: user.name,
                        email: user.email,
                        company: {
                            id: user.store_id,
                            name:store_details.name,
                        },
                        createdAt: user.created_at
                    });
                }catch(err){
                    console.log(err);
                }
            }

            Sentry.configureScope(function(scope) {
                // scope.setTag("my-tag", "my value");
                scope.setUser({
                    id: user.id,
                    email: user.email
                });
                
                    scope.setTag("org_name", store_details.name);
                    scope.setTag("org_id", store_details.id);
                    // scope.setTag("react_version", react_version);
                    // scope.setTag("core_version", window.Module.get_infurnia_version_core());
            });
        }
    }, [ store_details]);

    useEffect(() => {
        if(current_business_unit_id){
            global.get_bu_aux_details()
        }
    }, [current_business_unit_id]);

    useEffect(() => {
        console.log('active_division_id_sidebar', active_tab, 'dd', active_division_id_sidebar)
    }, [active_tab, active_division_id_sidebar]);

	useEffect(() => {
		if (!is_authenticating && !user) {
			console.log('login error');
			// window.location.href = global.config.auth_redirect_path;
			let check_enterprise_white_labelled_url = (window.location.host.split('.')[1] == 'infurnia') ? false : true;
			let path = window.location.pathname;
			if(path != '/redirect_to_infurnia/'){
				if (check_enterprise_white_labelled_url) {
					let [ subdomain, domain, extension ] = window.location.host.split('.');
					window.location.href = `https://login.${domain}.${extension}/?target=admin&path=${path}`
				} else {
					window.location.href = global.config.auth_redirect_path+`&path=${path}`;
				}
			}
		} 
	}, [is_authenticating, user]);
    
    if (is_authenticating) {
		if(window.location.pathname === '/redirect_to_infurnia/') return (<RedirectTab/>)
        return 'Please wait';
    } else if (user) {
        if(user && user.id && user.current_business_unit_id && store_details && store_details.id){
        return (
        <div className="App">
            {/* <canvas width='265px' height='265px' style={{display:'none'}} id="webgl_sphere_canvas"></canvas> */}
                {
                    downtime_var ?
                    <div className='downtime'>
                        <div className='downtime_child'>
                            Our website is currently undergoing maintenance and will be back online at 3 AM (IST). We apologize for any inconvenience caused. Thank you for your patience.
                        </div>
                    </div>:''
                }
                {
                    is_unautherised ? 
                    <Modal size='xs' isOpen={is_unautherised} className='modal-dialog-centered'>
                        {/* <IModalHeader toggle={toggle_version_outdated_warning} style={{border: 'none', boxShadow: 'none'}}>Version Outdated</IModalHeader> */}
                        <IModalBody style={{padding: '48px 32px', textAlign: 'center', backgroundColor: 'white', borderRadius: '8px', fontFamily: 'DM Sans', fontSize: '16px', fontWeight: '400', color: '#000000', lineHeight: '19.23px'}}>
                            <div className='mb-4'>
                                <div className='mb-4'>
                                    <div>Oops! There seems to be an authentication problem. <br/> With your Access Level</div>
                                </div>
                                <div style={{color: '#A7A8B2', fontSize: '14px', lineHeight: '16.62px'}}>
                                    <div>Please try reloading your tab.<br/> Check your access level if the issue persists.</div>
                                    {/* <div>Click on 'Refresh Later' to update at your own convenience."</div> */}
                                </div>
                            </div>
                            <div>
                            {/* <Button style={{ all: 'unset', padding: '8px 16px', backgroundColor: '#FEFFFF', color: '#323338', border: '1px solid #C5C7CF', borderRadius: '4px', cursor: 'pointer', marginRight: '16px'}} onClick={toggle_version_outdated_warning}>Refresh later</Button> */}
                            <Button style={{ all: 'unset', padding: '8px 16px', backgroundColor: '#0078ff', color: 'white', border: '1px solid #0078ff', borderRadius: '4px', cursor: 'pointer' }} onClick={() => { window.location.reload(true) }}>Refresh</Button>
                            </div>
                        </IModalBody>
                    </Modal>
                    :''
                }
            {(page_loader && page_loader.show) || (app_loader && app_loader.show)?(
                <div style={{position:'fixed',backgroundColor:'rgba(20,20,20,0.7',left:0,top:0,width:'100%',height:'100%',zIndex:10000}}>
                    <div style={{position:'absolute',width:'350px', minHeight: '40px', padding: '12px', left:'calc(50% - 175px)',top:'calc(50%)', transform: 'translateY(-50%)', backgroundColor:'#314456',color:'#ffffff',display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}}>
                        <i className = "fa fa-spinner fa-spin"></i> &nbsp; &nbsp; <span>{page_loader && page_loader.show ? page_loader.text : app_loader.text}</span>
                    </div>
                </div>
            ):''}
                {/* {version_outdated ? (
                    // <div style={{ position: 'fixed', backgroundColor: 'rgba(20,20,20,0.5', left: 0, top: 0, width: '100%', height: '100%', zIndex: 10000 }}>
                    //     <div className='DMSans' style={{ position: 'absolute', width: '480px', height: '180px', left: 'calc(50% - 240px)', top: 'calc(50% - 90px)', backgroundColor: 'white', color: COLORS.black, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', padding: '16px', flexDirection: 'column' }}>
                    //         <div style={{ paddingBottom: '24px' }}>Our software on your browser is not up to date, Please refresh the page to get the latest update  </div>
                    //         <Button style={{ all: 'unset', padding: '8px 16px', backgroundColor: COLORS.blue6, color: 'white', borderRadius: '4px', cursor: 'pointer' }} onClick={() => { window.location.reload(true) }}>Refresh</Button>
                    //     </div>
                    // </div> */}
                    <Modal isOpen={version_outdated_warninng && version_outdated && !version_outdated_modal_closed} className='modal-dialog-centered'>
                        {/* <IModalHeader toggle={toggle_version_outdated_warning} style={{border: 'none', boxShadow: 'none'}}>Version Outdated</IModalHeader> */}
                        <IModalBody style={{padding: '32px', textAlign: 'center', backgroundColor: 'white', borderRadius: '8px', fontFamily: 'DM Sans', fontSize: '16px', fontWeight: '400', color: '#000000', lineHeight: '19.23px'}}>
                            <div className='mb-4'>
                                <div className='mb-4'>
                                    <div>Our software on your browser is not up to date </div>
                                    <div>Refresh to get update.</div>
                                </div>
                                <div style={{color: '#A7A8B2', fontSize: '14px', lineHeight: '16.62px'}}>
                                    <div>"Refreshing might exit you out of your current workflow.</div>
                                    <div>Click on 'Refresh Later' to update at your own convenience."</div>
                                </div>
                            </div>
                            <div>
                            <Button style={{ all: 'unset', padding: '8px 16px', backgroundColor: '#FEFFFF', color: '#323338', border: '1px solid #C5C7CF', borderRadius: '4px', cursor: 'pointer', marginRight: '16px'}} onClick={toggle_version_outdated_warning}>Refresh later</Button>
                            <Button style={{ all: 'unset', padding: '8px 16px', backgroundColor: '#0078ff', color: 'white', border: '1px solid #0078ff', borderRadius: '4px', cursor: 'pointer' }} onClick={() => { window.location.reload(true) }}>Refresh</Button>
                            </div>
                        </IModalBody>
                    </Modal>
                {/* ) : ('')} */}
            <ToastError />
            <div>
                    <BrowserRouter>
                        <BuDetailsContext.Provider value = {bu_details}>
                        <AdminVersionContext.Provider value={version_outdated}>
                        <AllBuContext.Provider value={all_bu_in_store}>
                        <OrgBuContext.Provider value={org_bu_view}>
                        <AllUsersContext.Provider value={all_users}>
                        <UserContext.Provider value={user}>
                        <WasmLoadedContext.Provider value={{wasm_loaded, set_wasm_loaded}}>
                        {/* <Unauthorised_toast/> */}
                        <div style={{ position:'absolute', top:'64px', left:'0px', width:'256px', backgroundColor:'#001529', height:'calc(100% - 64px)'}}>
                            <div style={{textAlign:'left',width:'100%', height:'calc(100% - 10px)'}}>
                                <Sidebar user={user} set_page_loader={set_page_loader} active_tab={active_tab} active_division_id={active_division_id_sidebar} set_active_division_id={set_active_division_id_sidebar} store_details={store_details} submit_for_delete_confirm={submit} />
                            </div>
                            <div style={{height:'10px',backgroundColor:'#002140', width:'100%', margin:'0px'}}>
                            </div>
                        </div>
                        <GSTModal
                            open={show_gst_modal}
                            toggle={() => set_show_gst_modal(false)}
                            store_details = {store_details}
                            fetch_store_info = {get_store_details}
                        />
                        <Switch>
                            <Route exact path="/" strict render= {() => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'home'} active_tab={active_tab} store_details={store_details}><AdminHome get_store_details={get_store_details} store_details={store_details} user = {user} /></Access_component>)}/>
                            <Route exact path="/:url*" strict render={props => <Redirect to={`${props.location.pathname}/`}/>}/>
                            <Route 
                                exact 
                                path = {["/catalogue/:sku_division/:sku_category/","/catalogue/:sku_division/:sku_category/:sku_sub_category_id/"]} 
                                render={ ({ match }) => {
                                    console.log("paath", match)
                                    let sku_sub_category_id = match.url.split('/')
                                    console.log("paath", sku_sub_category_id)
                                    return (
                                        <Access_component 
                                            user={user} 
                                            set_active_tab={set_active_tab} 
                                            valid_tab={'catalogue'}
                                            active_tab={active_tab} 
                                            store_details={store_details}
                                        >
                                            <SKU_Groups 
                                                orignal_tags={orignal_tags} 
                                                match={match} 
                                                user = {user} 
                                                confirmation_alert={submit}
                                                get_store_details={get_store_details} 
                                                store_details={store_details}  
                                                set_page_loader={set_page_loader} 
                                                submit_for_delete_confirm={submit} 
                                                set_active_tab={set_active_tab} 
                                                set_active_division_id_sidebar={set_active_division_id_sidebar} 
                                                send_analytics_event={send_analytics_event} 
                                                sku_sub_category_id_first = {sku_sub_category_id[4]}
                                            />
                                        </Access_component> 
                                    )
                                }}
                            />
                            <Route 
                                exact path = "/bulk_create_menu"
                                render = {({match}) => (
                                    <Access_component 
                                            user={user} 
                                            set_active_tab={set_active_tab} 
                                            valid_tab={'catalogue'}
                                            active_tab={active_tab} 
                                            store_details={store_details}
                                    >
                                        <BulkCreateMenu 
                                            match={match} 
                                            user = {user} 
                                            confirmation_alert={submit}
                                            get_store_details={get_store_details} 
                                            store_details={store_details}  
                                            set_page_loader={set_page_loader} 
                                            submit_for_delete_confirm={submit} 
                                            set_active_tab={set_active_tab} 
                                            send_analytics_event={send_analytics_event} 
                                        />
                                    </Access_component>
                                )}
                            />
                            <Route exact path="/billing/usage/" render={({ match }) => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'billing'} active_tab={active_tab} store_details={store_details}><Billing_usage user = {user} get_store_details={get_store_details} store_details={store_details} submit_for_delete_confirm={submit} set_active_tab={set_active_tab} set_active_division_id_sidebar={set_active_division_id_sidebar} send_analytics_event={send_analytics_event}  get_users={authenticate} set_page_loader = {set_page_loader} /></Access_component>)}/>
                            <Route exact path="/billing/users/" render={({ match }) => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'billing'} active_tab={active_tab} store_details={store_details}><Billing user = {user} get_store_details={get_store_details} store_details={store_details} submit_for_delete_confirm={submit} set_active_tab={set_active_tab} set_active_division_id_sidebar={set_active_division_id_sidebar} send_analytics_event={send_analytics_event}  get_users={authenticate} set_page_loader={set_page_loader} /></Access_component>)}/>
                            {/* <Route exact path="/billing/business_units/" render={({ match }) => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'billing'} active_tab={active_tab} store_details={store_details}><BusinessUnits user = {user} get_store_details={get_store_details} store_details={store_details} submit_for_delete_confirm={submit} set_active_tab={set_active_tab} set_active_division_id_sidebar={set_active_division_id_sidebar} send_analytics_event={send_analytics_event}  get_users={authenticate} /></Access_component>)}/> */}

                            <Route exact path= "/project_hub/design_hub/" render={() => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'project_hub'} active_tab={active_tab} store_details={store_details}><Designs user = {user} get_store_details={get_store_details} set_page_loader={set_page_loader} store_details={store_details} set_active_tab={set_active_tab} send_analytics_event={send_analytics_event} set_active_division_id_sidebar = {set_active_division_id_sidebar} /></Access_component>)}/>
                            <Route exact path = "/project_hub/design_templates/" render = {() => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'project_hub'} active_tab={active_tab} store_details={store_details}><DesignTemplates user = {user} get_store_details={get_store_details} store_details={store_details} set_active_tab={set_active_tab} send_analytics_event={send_analytics_event} set_active_division_id_sidebar = {set_active_division_id_sidebar} /></Access_component>)}/>
                            <Route exact path= "/sales/" render={() => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'Sales'} active_tab={active_tab} store_details={store_details}><SalesChannel user = {user} get_store_details={get_store_details} store_details={store_details} submit_for_delete_confirm={submit} set_active_tab={set_active_tab} send_analytics_event={send_analytics_event} set_page_loader={set_page_loader}/></Access_component>)}/>
                            <Route exact path= "/brands/" render={() => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'Brands'} active_tab={active_tab} store_details={store_details}><Brand user = {user} get_store_details={get_store_details} store_details={store_details} submit_for_delete_confirm={submit} set_active_tab={set_active_tab} send_analytics_event={send_analytics_event} /></Access_component>)}/>
                            
                            <Route exact path= "/design_intelligence/design_intelligence/" render={() => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'DesignIntelligence'} active_tab={active_tab} store_details={store_details}><DesignIntelligence user = {user} get_store_details={get_store_details} store_details={store_details} submit_for_delete_confirm={submit} set_active_tab={set_active_tab} send_analytics_event={send_analytics_event} set_active_division_id_sidebar={set_active_division_id_sidebar} tags={orignal_tags}/></Access_component>)}/>
                            <Route exact path= "/design_intelligence/dc_rules/" render={() => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'DesignIntelligence'} active_tab={active_tab} store_details={store_details}><DC_rules user = {user} get_store_details={get_store_details} store_details={store_details} submit_for_delete_confirm={submit} set_active_tab={set_active_tab} send_analytics_event={send_analytics_event}  set_page_loader={set_page_loader} set_active_division_id_sidebar={set_active_division_id_sidebar} /></Access_component>)}/>
                            <Route exact path= "/design_intelligence/mf_rules/" render={() => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'DesignIntelligence'} active_tab={active_tab} store_details={store_details}><MF_rules user = {user} get_store_details={get_store_details} store_details={store_details} submit_for_delete_confirm={submit} set_active_tab={set_active_tab} send_analytics_event={send_analytics_event}  set_page_loader={set_page_loader} set_active_division_id_sidebar={set_active_division_id_sidebar}/></Access_component>)}/>
                            <Route exact path= "/design_intelligence/hw_rules/" render={() => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'DesignIntelligence'} active_tab={active_tab} store_details={store_details}><HW_rules user = {user} get_store_details={get_store_details} store_details={store_details} submit_for_delete_confirm={submit} set_active_tab={set_active_tab} send_analytics_event={send_analytics_event}  set_page_loader={set_page_loader} set_active_division_id_sidebar={set_active_division_id_sidebar}/></Access_component>)}/>
                            <Route exact path= "/design_intelligence/am_rules/" render={() => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'DesignIntelligence'} active_tab={active_tab} store_details={store_details}><AM_rules user = {user} get_store_details={get_store_details} store_details={store_details} submit_for_delete_confirm={submit} set_active_tab={set_active_tab} send_analytics_event={send_analytics_event}  set_page_loader={set_page_loader} set_active_division_id_sidebar={set_active_division_id_sidebar}/></Access_component>)}/>
                            <Route exact path="/design_intelligence/rule_wizard/" render={() => (
                                (global.config.rule_wizard_allowed_users.includes(String(user.id))) ?
                                    <RuleWizard user={user} get_store_details={get_store_details} store_details={store_details} submit_for_delete_confirm={submit} set_active_tab={set_active_tab} send_analytics_event={send_analytics_event} set_page_loader={set_page_loader} set_active_division_id_sidebar={set_active_division_id_sidebar} />
                                    :
                                    <Error user={user} store_details={store_details} />
                            )} />
                            
                            <Route exact path= "/category_type/" render={() => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'category_type'} active_tab={active_tab} store_details={store_details}><CategoryType user = {user} get_store_details={get_store_details} store_details={store_details} submit_for_delete_confirm={submit} set_active_tab={set_active_tab} send_analytics_event={send_analytics_event} /></Access_component>)}/> 
                            <Route exact path= "/tags/" render={() => (
                                <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'Tags'} active_tab={active_tab} store_details={store_details}>
                                    <Tags 
                                        user = {user} 
                                        get_store_details={get_store_details} 
                                        store_details={store_details} 
                                        tags={orignal_tags} 
                                        fetch_tags_details={fetch_tags_details} 
                                        submit_for_delete_confirm={submit} 
                                        set_active_tab={set_active_tab} 
                                        send_analytics_event={send_analytics_event} 
                                    />
                                </Access_component>)}/>
                            <Route 
                                exact path= "/preferences_settings/designer_input/" 
                                render={() => (
                                    <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'preferences_settings'} active_tab={active_tab} store_details={store_details} active_division_id_sidebar={active_division_id_sidebar} set_active_division_id_sidebar={set_active_division_id_sidebar} valid_division_id_sidebar={'designer_input'}>
                                        <DesignerInput 
                                            user = {user}
                                            submit_for_delete_confirm={submit} 
                                            set_active_tab={set_active_tab} 
                                            send_analytics_event={send_analytics_event} 
                                            
                                            set_page_loader={set_page_loader}
                                            store_details={store_details} 
                                            get_store_details={get_store_details}
                                            set_active_division_id_sidebar={set_active_division_id_sidebar}
                                        />
                                    </Access_component>
                                )}
                            />
                            <Route 
                                exact path="/preferences_settings/feature_flags/" 
                                render={() => (
                                    <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'preferences_settings'} active_tab={active_tab} store_details={store_details}>   
                                        <FeatureFlags 
                                            user = {user} 
                                            set_active_tab = {set_active_tab} 
                                            send_analytics_event = {send_analytics_event} 
                                            get_store_details={get_store_details} 
                                            store_details={store_details}
                                            set_active_division_id_sidebar={set_active_division_id_sidebar}
                                        />
                                    </Access_component>
                                )}
                            />
                            <Route 
                                exact path="/preferences_settings/design_preferences/"
                                render={() => (
                                    <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'preferences_settings'} active_tab={active_tab} store_details={store_details}>   
                                        <Design_Preferences 
                                            user = {user} 
                                            set_active_tab = {set_active_tab} 
                                            submit_for_delete_confirm={submit} 
                                            send_analytics_event = {send_analytics_event} 
                                            store_details={store_details} 
                                            get_store_details={get_store_details}
                                            set_active_division_id_sidebar={set_active_division_id_sidebar}
                                        />
                                    </Access_component>
                                )}
                            />
                            <Route 
                                exact path= "/preferences_settings/org_preference/"
                                render={() => (
                                    <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'preferences_settings'} active_tab={active_tab} store_details={store_details}>    
                                        <Store_Preferences 
                                            user = {user} 
                                            set_active_tab = {set_active_tab} 
                                            send_analytics_event = {send_analytics_event} 
                                            store_details={store_details} 
                                            get_store_details={get_store_details}
                                            set_active_division_id_sidebar={set_active_division_id_sidebar}
                                            set_page_loader = {set_page_loader}
                                        />
                                    </Access_component>
                                )}
                            />
                            <Route 
                                exact path = "/preferences_settings/project_statuses"
                                render = {() => (
                                    <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'preferences_settings'} active_tab={active_tab} store_details={store_details}>    
                                        <ProjectStatuses 
                                            user = {user}
                                            store_details = {store_details} 
                                            get_store_details = {get_store_details} 
                                            set_active_tab = {set_active_tab}
                                            submit_for_delete_confirm={submit} 
                                            set_active_division_id_sidebar={set_active_division_id_sidebar}
                                            send_analytics_event={send_analytics_event}
                                        />
                                    </Access_component>
                                )}
                            />
                            <Route 
                                exact path = "/preferences_settings/room_type"
                                render = {() => (
                                    <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'preferences_settings'} active_tab={active_tab} store_details={store_details}>    
                                        <RoomType 
                                            user = {user}
                                            store_details = {store_details} 
                                            get_store_details = {get_store_details} 
                                            set_active_tab = {set_active_tab}
                                            submit_for_delete_confirm={submit} 
                                            set_active_division_id_sidebar={set_active_division_id_sidebar}
                                            send_analytics_event={send_analytics_event}
                                        />
                                    </Access_component>
                                )}
                            />
                            <Route 
                                exact path = "/preferences_settings/pricing_settings"
                                render = {() => (
                                    <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'preferences_settings'} active_tab={active_tab} store_details={store_details}>    
                                        <PricingSettings 
                                            store_details = {org_bu_view == 'bu' ? bu_details : store_details} 
                                            get_store_details = {org_bu_view == 'bu' ? global.get_bu_aux_details : get_store_details}
                                            org_details = {store_details}
                                            get_org_details = {get_store_details}
                                            set_active_tab = {set_active_tab}
                                            submit_for_delete_confirm={submit} 
                                            set_active_division_id_sidebar={set_active_division_id_sidebar}
                                            send_analytics_event={send_analytics_event}
                                            set_page_loader={set_page_loader}
                                        />
                                    </Access_component>
                                )}
                            />
                            <Route
                                exact path="/preferences_settings/material_template"
                                render = {() => (
                                    <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'material_template'} active_tab={active_tab} store_details={store_details}>    
                                        <Material_template
                                            user = {user}
                                            store_details = {store_details} 
                                            get_store_details = {get_store_details} 
                                            set_active_tab = {set_active_tab}
                                            submit_for_delete_confirm={submit} 
                                            set_active_division_id_sidebar={set_active_division_id_sidebar}
                                            send_analytics_event={send_analytics_event}
                                            set_page_loader={set_page_loader}
                                        />
                                    </Access_component>
                                )}
                            />
                            <Route 
                                exact path = "/preferences_settings/additional_parameters"
                                render = {() => (
                                    <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'preferences_settings'} active_tab={active_tab} store_details={store_details}>    
                                        <AdditionalParameters
                                            store_details = {store_details} 
                                            get_store_details = {get_store_details} 
                                            set_active_tab = {set_active_tab}
                                            submit_for_delete_confirm={submit} 
                                            set_active_division_id_sidebar={set_active_division_id_sidebar}
                                            send_analytics_event={send_analytics_event}
                                            set_page_loader={set_page_loader}
                                        />
                                    </Access_component>
                                )}
                            />
                            <Route 
                                exact path = "/preferences_settings/feature_flag_addon_map"
                                render = {() => (
                                    <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'FeatureFlag_addon_map'} active_tab={active_tab} store_details={store_details}>
                                        <FeatureFlagAddonMap
                                            store_details={store_details}
                                            get_store_details={get_store_details}
                                            set_active_tab={set_active_tab}
                                            submit_for_delete_confirm={submit}
                                            set_active_division_id_sidebar={set_active_division_id_sidebar}
                                            send_analytics_event={send_analytics_event}
                                            set_page_loader={set_page_loader}
                                        />
                                    </Access_component>
                                )} />
                            <Route 
                                exact path = "/preferences_settings/construction_style"
                                render = {() => (
                                    <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'preferences_settings'} active_tab={active_tab} store_details={store_details}>    
                                        <Construction_style 
                                            orignal_tags={orignal_tags}
                                            user = {user}
                                            store_details = {store_details} 
                                            get_store_details = {get_store_details} 
                                            set_active_tab = {set_active_tab}
                                            submit_for_delete_confirm={submit} 
                                            set_active_division_id_sidebar={set_active_division_id_sidebar}
                                            send_analytics_event={send_analytics_event}
                                            set_page_loader={set_page_loader}
                                        />
                                    </Access_component>
                                )}
                            />
                            <Route exact path= "/archive_catalogue/" render={() => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'ArchiveCatalogue'} active_tab={active_tab} store_details={store_details}><ArchiveCatalogue user = {user} get_store_details={get_store_details} store_details={store_details} submit_for_delete_confirm={submit} set_active_tab={set_active_tab} send_analytics_event={send_analytics_event}/></Access_component>)}/>
                            <Route exact path= "/custom_reports/" render={() => (<Access_component user={user} set_active_tab={set_active_tab} valid_tab={'CustomReport'} active_tab={active_tab} store_details={store_details}><CustomReport user = {user} get_store_details={get_store_details} store_details={store_details} set_active_tab={set_active_tab} send_analytics_event={send_analytics_event} submit_for_delete_confirm={submit}/></Access_component>)}/>
                            <Route 
                                exact 
                                path= "/usage_metrics/" 
                                render={() => (
                                    <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'Usage_Metrics'} active_tab={active_tab} store_details={store_details}>
                                        <UsageMetrics 
                                            user = {user} 
                                            get_store_details={get_store_details} 
                                            store_details={store_details} 
                                            submit_for_delete_confirm={submit} 
                                            set_active_tab={set_active_tab} 
                                            send_analytics_event={send_analytics_event}
                                        />
                                    </Access_component>)
                                }/>
                            {/* <Route 
                                exact 
                                path= "/preferences_settings/additional_properties/" 
                                render={() => (
                                    <Access_component user={user} set_active_tab={set_active_tab} valid_tab={'preferences_settings'} active_tab={active_tab} store_details={store_details}>
                                        <AdditionalProperties 
                                            user = {user} 
                                            get_store_details={get_store_details} 
                                            store_details={store_details} 
                                            submit_for_delete_confirm={submit} 
                                            set_active_tab={set_active_tab} 
                                            send_analytics_event={send_analytics_event}
                                            set_active_division_id_sidebar={set_active_division_id_sidebar}
                                        />
                                    </Access_component>)
                            }/> */}
                            {/* <Route exact path="/plugin/" render={() => (<Plugin user = {user} />)}/> */}
                            {/* <Route exact path="/preferences/" render={() => (<Preferences user = {user} orignal_tags={orignal_tags} />)}/> */}
                            {/* <Route exact path="/billing/" render={() => (<Billing user = {user} />)}/> */}
                            {/* <Route exact path="/usage/" render={() => (<Usage user = {user} />)}/> */}
                            <Route exact path="/redirect_to_infurnia" render={() => <RedirectTab/>}/>
							<Route exact path="/bulk_edit_sku_structure" render={() => <BulkEditSkuStructureParent set_page_loader={set_page_loader}/>}/>
                            <Route path="/:path_string" render={({match}) => (<Error user={user} path_string={match.params.path_string} active_tab={active_tab} store_details={store_details}/>)} />
                        </Switch>
                        </WasmLoadedContext.Provider>
                        </UserContext.Provider>
                        </AllUsersContext.Provider>
                        </OrgBuContext.Provider>
                        </AllBuContext.Provider>
                        </AdminVersionContext.Provider>
                        </BuDetailsContext.Provider>
                </BrowserRouter>
            </div>
        </div>
        );
        }else{
            return "Fetching your store info";
        }
    }else if(window.location.pathname === '/redirect_to_infurnia/'){
		return (<RedirectTab/>)
	}else {
        return '';
    }

  // } catch (err) {
  //   return 'Error in App'
  // }
}

export default App;

// <Route path="/">
//                               <Redirect to="/home" />
//                           </Route>