import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, TabContent, TabPane } from "reactstrap";
// import {Card, Col, Progress, Table, Row,  TabPane , TabContent, Nav, NavItem, NavLink} from "reactstrap";

import TagsDialogBox from'./TagsDialogBox'
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import ManageDialogBox from'./ManageDialogBox'
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import LazyLoad from 'react-lazyload';
import split_cat_type from '../../utils/split_cat_type';
import classnames from "classnames";
import Moment from 'react-moment';
import TableSortComponent from '../../components/TableSortComponent';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';


const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	add_brand_style:{
		backgroundColor: '#007EF4',
		boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
		borderRadius: '3px',
		height:'32px',
		padding:'0px 24px',
		border:'0px',
		color:'white',
	},
};

const Filler = ({ initialized }) => (
	<Table className="align-items-center table-flush text-center" responsive>
		<thead className="thead-light text-center">
			<tr>
				{ initialized ? (
					<th scope="col">
						<span style={styles.table_header}><b>No Inventory to list  </b></span><br/> Try adjusting filters or consider adding from warehouse<br /> &nbsp;
					</th>
				):(
					<th scope="col">
						<Row>
							<Col xs={12} className="text-center">
								<img src="/load2.gif" />
							</Col>
							<Col xs={12} className="text-center">
								<span style={styles.table_header}><b>Fetching your inventory  </b></span><br/> <br /> Please wait...<br /> <br />
							</Col>
						</Row>
					</th>
				)}
			</tr>
		</thead>
	</Table>	
);

const Tags = ({ user, tags, fetch_tags_details ,submit_for_delete_confirm, set_active_tab, send_analytics_event, check_route_fn, store_details, get_store_details}) => {
	

	
	const [users , set_users] = useState([]);
	const [open_tags_Dialog_Box, set_open_tags_Dialog_Box] = useState(false);
	const [active_tags , set_active_tags] = useState({});
	const [search_string , set_search_string] = useState(null);
	const [display_tags , set_display_tags] = useState([]);
	const [key , set_key] = useState(null);
	const [open_manage_Dialog_Box, set_open_manage_Dialog_Box] = useState(false);
	const [active_manage_tags , set_active_manage_tags] = useState({});

	// const [page_size , set_page_size] = useState(30)
	// const [fetch_progress , set_fetch_progress] = useState(40)
	// const [current_page , set_current_page] = useState(1)
	const [initialized , set_initialized] = useState(false) 
 	// const [all_pages , set_all_pages] = useState([])
	const alert = useAlert()
	const [ start_index , set_start_index ] = useState('');
	const [ end_index , set_end_index ] = useState('');
	const [ all_category_type_data , set_all_category_type_data ] = useState([]);
	const [deleted_tags, set_deleted_tags] = useState([]);
	const [display_deleted_tags, set_display_deleted_tags] = useState([]);
	const [tabs , set_tabs ] = useState(1)
	const [asc_order, set_asc_order] = useState(false);
	const [asc_order_deleted_tags, set_asc_order_deleted_tags] = useState(false);

	const org_bu_view = useContext(OrgBuContext)

	const fetch_deleted_tags_details = async () => {
        try {
			let tags_details
			if (org_bu_view === 'org') {
				tags_details = await general_fetch({ url: 'tag/get_deleted' });
			} else {
				tags_details = await general_fetch({ url: 'tag/get_deleted', body: { business_unit_id: user.current_business_unit_id } });
			}
            // console.log("tag app.js")
            set_deleted_tags(tags_details);
            set_display_deleted_tags(tags_details);
        } catch(err) {
            console.log(err);
        }
    }	

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	const remove_tags = async (id) => {
		try {
			var body ={ids:[id]}
			let response
            if (org_bu_view === 'org') {
                response = await general_fetch({ url: 'tag/remove', body });
            } else {
                body['business_unit_id'] = user.current_business_unit_id
                response = await general_fetch({ url: 'tag/remove', body });
            }
			alert.success("Tag Deleted successfully !")
			fetch_tags_details();
			fetch_deleted_tags_details();
		} catch(err) { 
			alert.error("Request Fail")
			console.log(err);
		}
	}

	const fetch_categories_type = async() => {
		try {
			var identifiers = JSON.stringify({});
			var response = await general_fetch({ url: 'sku_category_type/get', body:{identifiers}});
			var resp = split_cat_type(response).sort(name_sort)
			set_all_category_type_data(resp);
		} catch(err) {
			console.log(err);
		}
	}

	useEffect(() => {
		set_display_tags(tags);
	}, [tags]);

	useEffect(() => {
		if (!initialized && display_tags && display_tags.length) {
			set_initialized(true);
		}
	}, [display_tags]);

	useEffect(() => {
		fetch_deleted_tags_details();
	}, []);

	
	const show_tags_update_dialog = (tags) => {
		set_open_tags_Dialog_Box(true);
		set_active_tags(tags);
		set_key('edit');
	}

	const close_tags_Dialog_Box = () => {
		set_open_tags_Dialog_Box(false);
		set_active_tags({});
		set_key(null);
	}

	const show_tags_add_dialog = () => {
		set_open_tags_Dialog_Box(true);
		set_key('add');
	}


	const show_manage_Dialog_Box = (tags) => {
		set_active_manage_tags(tags)
		set_open_manage_Dialog_Box(true)
		
	}

	const close_manage_Dialog_Box = () => {
		set_open_manage_Dialog_Box(false)
		set_active_manage_tags({});
	}


	const filter_tags = () => {
		var filtered_tags =[...tags]
		var filtered_deleted_tags =[...deleted_tags]
		var lower_search_string = search_string ? search_string.toLowerCase():'';
		if(lower_search_string){
			filtered_tags = filtered_tags.filter( tags => tags.tag.toLowerCase().includes(lower_search_string))
			filtered_deleted_tags = filtered_deleted_tags.filter( tags => tags.tag.toLowerCase().includes(lower_search_string))
		}
		set_display_tags(filtered_tags)
		set_display_deleted_tags(filtered_deleted_tags)
	}


	const sort_tags = () => {
		var temp_tags =[...tags]

	}

	useEffect(() => {
			filter_tags();
	}, [search_string]);

	useEffect(() => {
		set_active_tab('Tags')
		// check_route_fn('Tags')
		send_analytics_event('auto', 'Tags', 'Tags', 1, window.location.href)
		if(all_category_type_data && !all_category_type_data.length){
			fetch_categories_type()
		}
	}, []);

	const Tag_Table = ({display_tags, set_display_tags, is_deleted}) => (
		<table style={{height:'100%'}} className="text-left flex_property table_style">
			<thead style={{width:"100%"}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
				<th className='th_style flex_property' style={{flex:'6'}}><span>Tag Name <TableSortComponent items={display_tags} asc_order={asc_order} set_asc_order={set_asc_order} set_items={set_display_tags} sort_by_property={'tag'} /> </span></th>
				{
					!is_deleted ?
						<React.Fragment>
							<th className='th_style flex_property' style={{flex:'2'}}><span>Categories</span></th>
							<th className='th_style flex_property' style={{flex:'3'}}><span>Sub-Categories</span></th>
							<th className='th_style flex_property' style={{flex:'2'}}><span>Groups</span></th>
							<th className='th_style flex_property' style={{border:0, flex:'2', }}><span>SKU's</span></th>
							<th className='th_style ' style={{border:0, flex:'4'}}><span></span></th>
						</React.Fragment>
					:
						<React.Fragment>
							<th className='th_style flex_property' style={{flex:'16'}}><span></span></th>
							<th className='th_style flex_property' style={{flex:'4'}}><span>Deleted At <TableSortComponent items={display_tags} asc_order={asc_order} set_asc_order={set_asc_order} set_items={set_display_tags} sort_by_property={'deleted_at'} property_type={'date'} /></span></th>
						</React.Fragment>
				}
			</tr>
			</thead>
			<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
			{
				display_tags && display_tags.length && display_tags.map((single_tag, idx) => (
				<tr key={idx} className='tr_style display_onhover'>
					
					<td className='td_style flex_property' style={{flex:'6'}}><span className='table_text_overflow'>{single_tag.tag}</span></td>
					{
						!is_deleted ?
							<React.Fragment>
								<td className='td_style flex_property' style={{flex:'2'}}>{single_tag.sku_categories && single_tag.sku_categories.length ? single_tag.sku_categories.length:0}</td>
								<td className='td_style flex_property' style={{flex:'3'}}>{single_tag.sku_sub_categories && single_tag.sku_sub_categories.length ? single_tag.sku_sub_categories.length:0}</td>
								<td className='td_style flex_property' style={{flex:'2'}}>{single_tag.sku_groups && single_tag.sku_groups.length?single_tag.sku_groups.length:0}</td>
								<td className='td_style flex_property' style={{flex:'2'}}>{single_tag.skus && single_tag.skus.length?single_tag.skus.length:0}</td>
								<td className='td_style flex_property x1' style={{ justifyContent:'flex-end',overflow:'hidden', flex:'4'}}>
									{
										user.store_id ==single_tag.store_id && ! single_tag.deleted_at ?(
											<span className='td_style flex_property x1' style={{ justifyContent:'flex-end'}}>
												{/* <span className='hide' style={{marginRight:'20px', cursor:'pointer', cursor:'pointer'}}><i onClick = {() => show_manage_Dialog_Box(single_tag)} className='fa fa-cog'/></span> */}
												<span className='hide' style={{marginRight:'20px', cursor:'pointer'}}><i onClick ={() => show_tags_update_dialog(single_tag)} className='fa fa-edit' aria-hidden="true"/></span>
												<span className='hide' style={{marginRight:'20px', cursor:'pointer'}}><i onClick ={() => submit_for_delete_confirm(()=>remove_tags(single_tag.id), ()=>{}, 'Tag will be deleted permanently.')} className='fa fa-trash'/></span>
											</span>
										):''
									}
								</td>
							</React.Fragment>
						:
							<React.Fragment>
								<td className='td_style flex_property' style={{flex:'16'}}><span></span></td>
								<td className='td_style flex_property' style={{flex:'4'}}><span><Moment format="DD/MM/YYYY">{single_tag.deleted_at}</Moment></span></td>
							</React.Fragment>
					}
				</tr>
			))}
			</tbody>
		</table>
		
	);

	return (
		<Main_Grid active_tab="Tags" user={user} store_details={store_details} get_store_details={get_store_details}>
			<TagsDialogBox open = {open_tags_Dialog_Box} handleClose = {close_tags_Dialog_Box} tags={active_tags} user= {user} fetch_tags_details = {fetch_tags_details} mode = {key} all_category_type_data={all_category_type_data} all_tags={tags}/>
			<ManageDialogBox open = {open_manage_Dialog_Box} handleClose = {close_manage_Dialog_Box} tag={active_manage_tags} user= {user} fetch_tags_details = {fetch_tags_details} />
			<Row style={{padding:'10px 0px', color:'#22272e'}}>
	        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
	        		Viewing {display_tags && display_tags.length ?display_tags.length:'0'} Tags
	        	</Col>
	        	<Col xs={6} style={{textAlign:'right'}}>
	        		<button onClick ={() => show_tags_add_dialog()} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Tag</span></button>
	        	</Col>
        	</Row>
	        <Row style={{height:'calc(100% - 90px)'}}>
		        <Col style={{height:'100%'}}>
		        	<Card style={{borderRadius: 0, height:'100%', overflow:'auto'}}>
						<Nav 
							style={{display:'flex', alignItems:'center'}}
							className="flex-md-row"
							id="tabs-icons-text" 
							pills
							role="tablist"
						>
							<NavItem>
								<NavLink
									aria-selected={tabs === 1}
									style = {{marginLeft:'10px'}}
									className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 1
									})}
									onClick={e => {toggleNavs(e, 1)/*; set_active_tab(0)*/}}
									href="#pablo"
									role="tab"
								>
									<span style={{fontSize:'14px'}}>Tags</span>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									aria-selected={tabs === 2}
									className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 2
									})}
									onClick={e => {toggleNavs(e, 2)/*; set_active_tab(1)*/}}
									href="#pablo"
									role="tab"
								>
									<span style={{fontSize:'14px'}}>Deleted Tags</span>
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={"tabs" + tabs} id='design-paginator' style={{height:'calc(100% - 40px)', display: tabs==1 ? 'block' : 'none'}}>
							<TabPane tabId={'tabs1'} style={{fontSize:'12px'}}>
								<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string} onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Tag Name' /></div>
								<div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{display_tags.length ? (<Tag_Table display_tags={display_tags} set_display_tags={set_display_tags} asc_order={asc_order} set_asc_order={set_asc_order} />) : (<Filler initialized={initialized}/>)}</div>
							</TabPane>
						</TabContent>
						<TabContent activeTab={"tabs" + tabs} id='design-paginator' style={{height:'calc(100% - 40px)', display: tabs==2 ? 'block' : 'none'}}>
							<TabPane tabId={'tabs2'} style={{fontSize:'12px'}}>
								<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string} onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Tag Name' /></div>
								<div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{display_deleted_tags.length ? (<Tag_Table display_tags={display_deleted_tags} set_display_tags={set_display_deleted_tags} asc_order={asc_order_deleted_tags} set_asc_order={set_asc_order_deleted_tags} is_deleted/>) : (<Filler initialized={initialized}/>)}</div>
							</TabPane>
						</TabContent>
					</Card>
		        </Col>
	        </Row>
	        <Row style={{height:'3px'}}>
		        <Col>
		        </Col>
	        </Row>
	        {/* <Row>
		        <Col>
					<Pegasus_Paginator 
						id='design-paginator'
						end_index={end_index}
						set_end_index={set_end_index}
						start_index={start_index}
						set_start_index={set_start_index}
						all_projects={display_tags && display_tags.length ? display_tags.length : '0'}
					/>
		        </Col>
	        </Row> */}
		</Main_Grid>
	)

}
export default Tags;

//<AlertBox message ="Brand succesfull added" open_copy = {open_alert_box} current_state = {current_state_alert} handleClose ={close_alert_box} />