import React , { useState , useEffect }from 'react';
import {Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import { useAlert } from 'react-alert'
import { truncate } from '@sentry/utils';
import general_fetch from '../../utils/fetch';
import { handleError } from '../../utils/error';

let test_fn = () => {
    return 0;
}

const Unauthorised_toast = ({allow_visible}) => {

	const[visible , set_visible] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        test_fn = set_visible;
    }, [])

    useEffect(() => {
        try{
            if (visible) {
                alert.error('You have been logged out, redirecting to login page.')
                setTimeout(()=>{
                    let resp = general_fetch({ url: 'user/logout'}).then(() => {
                        let check_enterprise_white_labelled_url = (window.location.host.split('.')[1] == 'infurnia') ? false : true;
                            let path = window.location.pathname;
                            if (check_enterprise_white_labelled_url) {
                            let [ subdomain, domain, extension ] = window.location.host.split('.');
                            window.location.href = `https://login.${domain}.${extension}/?target=admin&path=${path}`
                        } else {
                            window.location.href = global.config.auth_redirect_path+`?path=${path}`;
                        }
                    })
                },[2000])
            } 
        }catch(err){
            handleError(err)
        }
    },[visible])

  	return (
          <React.Fragment></React.Fragment>
        // <div style={{display:visible?'block':'none',position:'fixed', height:'100vh', width:'100vw', backgroundColor:'red'}}>
        //     <div style={{position:'absolute', top:'40px'}}>You have been logged out, redirecting to login page.</div>
        // </div>
  	);
}
export {    
    Unauthorised_toast,
    test_fn
}
