import React , { useState , useEffect, useRef }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import AlertBox from "../../components/AlertBox/";
import Multiselect from '../../components/Multiselect';
import filter_duplicate from '../../utils/filter_duplicate';
import SingleSelect from '../../components/SingleSelect';

const FilterModal = ({ open, handle_close, selected_designers, set_selected_designers,selected_designers_by_email, set_selected_designers_by_email, projects, filter_projects, selected_status, set_selected_status, status_options}) => {

	const apply_onclick = () => {
		filter_projects()
		handle_close()
	}
	const reset_onclick = () => {
		set_selected_designers([])
		set_selected_designers_by_email([])
		set_selected_status([])
		filter_projects(true)
		handle_close()
	}

	const make_designer_array = (input_array,type) => {
		// if(window.debug_mode){
			// console.log("make_designer_array", input_array)
		// }
		var x_input_array = []
		var map1 = new Map();
		// x_input_array = [...new Set(input_array[type])];
		input_array.map((x_item, idx) => {
			if(x_item[type] && !map1.has(x_item.user_id)){
				map1.set(x_item.user_id, idx);
				x_input_array.push(x_item[type])
				// x_input_array.push({...x_item[type], id: x_item.user_id})
			}	
		})
		// console.log("filtered", x_input_array)
		return x_input_array;
	}

	return (	
		<div style={{display: open?'block':'none', height:'360px', width:'340px', zIndex:'1', backgroundColor:'white', boxShadow: '2px 2px rgba(0, 0, 0, 0.2)', fontSize:'14px', textAlign:'left', borderRadius:'4px', border:'1px solid #f2f2f2'}}>
			<i style={{color:'white', position:'absolute', top:'-6px', right:'27px'}} className='fa fa-sort-asc' />
			<Row style={{margin:0}}>
				<Col className='flex_property' style={{justifyContent:'space-between', padding:"0px 20px", height:'50px', color:'#22272e' }}>
					<span style={{fontFamily:'Source_Sans_Pro-SemiBold'}}>Filters</span>
					<span><i style={{cursor:'pointer'}} onClick={handle_close} className='fa fa-times'/></span>
				</Col>
			</Row>
			<div style={{borderTop:'1px solid #f2f2f2'}}></div>
			<Row style={{margin:0}}>
				<Col style={{padding:"20px", width:'50px', display:'flex', flexDirection:'column'}}>
					<label style={{fontSize:'12px', marginBottom:"2px", color:"#627386"}}>Designer Name</label>
					<Multiselect
						style={{backgroundColor:'white'}}
						options={projects && projects.length? make_designer_array(projects,'owner'):[]} 
						selectedValues={selected_designers}
						onSelect={set_selected_designers}
						onRemove={set_selected_designers}
						displayValue="name" // Property name
						id='select_designer'
						placeholder= 'Select Designer'
					/>

					{/* <SingleSelect
					options={projects && projects.length? make_designer_array(projects,'designer'):[]} 
					selectedValue={selected_designers}
					onItemClick={set_selected_designers}
					displayValue="name" // Property name
					placeholder= 'Select Designer'
					/> */}
				</Col>
			</Row>
			<Row style={{margin:0}}>
				<Col style={{padding:"0px 20px", width:'50px', display:'flex', flexDirection:'column'}}>
					<label style={{fontSize:'12px', marginBottom:"2px", color:"#627386"}}>Designer E-mail</label>
					<Multiselect
						style={{backgroundColor:'white'}}
						options={projects && projects.length? make_designer_array(projects,'owner'):[]} 
						selectedValues={selected_designers_by_email}
						onSelect={set_selected_designers_by_email}
						onRemove={set_selected_designers_by_email}
						displayValue="email" // Property name
						id='select_designer_by_email'
						placeholder= 'Select Email'
					/>
				</Col>
			</Row>
			<Row style={{margin:0}}>
				<Col style={{padding:"20px", width:'50px', display:'flex', flexDirection:'column'}}>
					<label style={{fontSize:'12px', marginBottom:"2px", color:"#627386"}}>Status</label>
					<Multiselect
						style={{backgroundColor:'white'}}
						options={status_options && status_options.length? JSON.parse(JSON.stringify(status_options)):[]} 
						selectedValues={selected_status}
						onSelect={set_selected_status}
						onRemove={set_selected_status}
						displayValue="status" // Property name 
						id={'select_status'}
						placeholder= 'Select Status'
					/> 
				</Col>
			</Row>
			<div style={{borderTop:'1px solid #f2f2f2'}}></div>
			<Row style={{margin:0}}>
				<Col style={{padding:"24px 20px 26px", textAlign:"center"}}>
				<button onClick={reset_onclick} style={{ border:0, height:'32px', width:"134px", backgroundColor:'#007ef4', borderRadius:'4px', textAlign:"center", color:'white', marginRight:'10px'}}><span style={{fontSize:'14px', fontFamily:'Roboto-Medium'}}>Reset</span></button>
					<button onClick={apply_onclick} style={{ border:0, height:'32px', width:"134px", backgroundColor:'#007ef4', borderRadius:'4px', textAlign:"center", color:'white'}}><span style={{fontSize:'14px', fontFamily:'Roboto-Medium'}}>Apply</span></button>
				</Col>
			</Row>
		</div>	
	);
}

export default FilterModal ; 

// <AlertBox message ="Brand succesfull added" open_copy = {open_alert_box} current_state = {current_state_alert} handleClose ={close_alert_box} />