import React , { useState, useEffect } from 'react';
import { Card, Col, Progress, Table, Row, Modal, CardHeader, CardBody, CardFooter, Button, Input } from "reactstrap";
import { useAlert } from 'react-alert'

import Main_Grid from '../../components/Main_Grid/';
// import Multiselect from "../../components/Multiselect/";
import Multiselect from 'multiselect-react-dropdown';

import general_fetch from '../../utils/fetch';
import name_sort from '../../utils/name_sort';
import split_cat_type from '../../utils/split_cat_type';



//generate a random 20 charecter id
const generate_id = () => {
	const id_length = 20;
	const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let text = "";
	for (let i = 0; i < id_length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
}


const KeyValuePair = ({ item_json, set_item_json, object_key }) => {

    const add_new_item_json = () => {
        let a = [...item_json];
        if (object_key) {
            a.push({ id: generate_id(), [object_key]: '' });
        } else {
            a.push('')
        }
        set_item_json(a);        
	}

    useEffect(() =>{
		if(item_json && !item_json.length){
			add_new_item_json()
		}
	},[item_json])

	const delete_item_json = (index) => {
		let a = [...item_json];
		a.splice(index,1)
		set_item_json(a);
	}

    const update_item_json = (index, value) =>{
		let a = [...item_json];
        if (object_key) {
            a[index][object_key] = value;
        } else {
            a[index] = value;
        }
		set_item_json(a);
	}

    return(
        <div>
            {
                item_json && item_json.length ? item_json.map((x,idx) => (
                    <div key={idx} style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:idx===0?0:'10px'}}>
                            <Input id={'box1'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px', marginRight:'60px'}} type="text" 
                                value={object_key ? item_json[idx][object_key] : item_json[idx]}
                                onChange={(e) =>update_item_json(idx, e.target.value) }
                            />
                            <span style={{cursor:'pointer'}} onClick={() =>delete_item_json(idx)}><i className='fa fa-trash'/></span>
                    </div>
                )):''
            }
            <Row style={{margin:0}}>
                <Col style={{textAlign:'left', padding:'5px 10px 10px 10px', paddingLeft:0}}>
                    <span onClick={add_new_item_json}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add Value +</span>
                </Col>
            </Row>
        </div>
    )
}


const Add_Hierarchy_Modal = ({ add_hierarchy_to_preference_json, open, set_open, hierarchy }) => {
    const [ name, set_name ] = useState('');

    const handle_close = () => {
        set_open(false);
        set_name('');
    }

    const handle_submit = () => {
        if(!name){
            alert('Please enter a name')
            return;
        }
        console.log("add_hierarchy_to_preference_json", add_hierarchy_to_preference_json);
        add_hierarchy_to_preference_json(hierarchy, name);
        handle_close();
    }

    
    return (
        <div>
            <Modal className="Margin_64px_top" isOpen={open} size="lg" toggle={handle_close}>
				<Card className='global_modal_height_for_mdlgxl'>
					<CardHeader className='global_modal_header'>
						<span>
							Manage Preference - {hierarchy.reduce((final, elem) => final + elem + ' > ', '')} - Add Hierarchy
						</span>
						<i style={{cursor:'pointer'}} onClick={handle_close} className='fa fa-times'/>
					</CardHeader>
					<CardBody className='global_modal_body'>
						<Row>
                            <Col>
								Name
							</Col>
							<Col>
								<input type="text" className="form-control" placeholder="Name" value={name} onChange={e => set_name(e.target.value)}/>    
							</Col>
						</Row>
					</CardBody>
					<CardFooter className='global_modal_footer'>
						<Button className='blue_button' style={{margin:0}} type="button" onClick={handle_submit}> Create Hierarchy </Button>
					</CardFooter>
				</Card>
			</Modal>
		</div>
    ) 
}


const Leaf_Edit_Modal = ({ current_key, leaf_data, hierarchy, open, handle_close, refresh_data, category_types }) => {

    const [ id, set_id ] = useState('');
    const [ name, set_name ] = useState('');
    const [display_name, set_display_name] = useState('');
    const [ value, set_value ] = useState('');
    const [ value_type, set_value_type ] = useState('');
    const [ overridable, set_overridable ] = useState(false);
    const [ visible, set_visible ] = useState(false);
    const [ value_options, set_value_options ] = useState([]);
    const [ category_type_map, set_category_type_map ] = useState({});
    const [ max_level, set_max_level] = useState('design')


    const update_preference = async () => {
        try {
            let temp_value = value;
            if (value_type == 'list') {
                temp_value = JSON.stringify(value.map(x => x.display_name));
            } else if (value_type == 'sku_category_type_id') {
                temp_value = JSON.stringify(value.map(x => x.id));
            }

            let temp_value_options = value_options;
            if (value_type == 'list') {
                temp_value_options = value_options.map(x => x.display_name);
            }
            
            let resp = await general_fetch({
                url: 'designing_preference/update_master',
                body: { 
                    id, 
                    new_key: [ ...hierarchy, name ], 
                    new_display_name: display_name,
                    new_value: temp_value, 
                    // new_overridable: overridable, 
                    new_visible: visible, 
                    new_value_type: value_type, 
                    new_max_level: max_level,
                    ...(value_options && value_options.length ? { new_value_options: temp_value_options } : {}) 
                }
            }); 
            refresh_data();
            handle_close();
            clear_preferences();
            console.log(resp);
        } catch(err) {
            console.log(err);
        }
    }


    const add_preference = async () => {
        try {
            let temp_value = value;
            if (value_type == 'list') {
                temp_value = JSON.stringify(value.map(x => x.display_name));
            } else if (value_type == 'sku_category_type_id') {
                temp_value = JSON.stringify(value.map(x => x.id));
            }

            let temp_value_options = value_options;
            if (value_type == 'list') {
                temp_value_options = value_options.map(x => x.display_name);
            }
            
            let resp = await general_fetch({
                url: 'designing_preference/create_master',
                body: {
                    key: [ ...hierarchy, name ],
                    display_name: display_name,
                    value: temp_value,
                    // overridable: overridable,
                    visible: visible,
                    value_type: value_type,
                    max_level: max_level,
                    ...(value_options && value_options.length ? { value_options: temp_value_options } : {})
                }
            });
            refresh_data();
            handle_close();
            clear_preferences();
            console.log(resp);
        } catch(err) {
            console.log(err);
        }
    }

    const clear_preferences = () => {
        set_name('')
        set_value('')
        set_value_type('')
        set_visible(false)
        set_max_level('design')
        set_display_name('')
    }

    
    const handle_submit = () => {
        if(!name){
            alert('Please enter a name')
            return;
        }
        if(!value){
            alert('Please enter a value')
            return;
        }
        if(!value_type){
            alert('Please enter a value type')
            return;
        }
        if(value_type === 'enum' && !value_options.length){
            alert('Please enter at least one value option')
            return;
        }
        if(id){
            update_preference();
        } else {
            add_preference();
        }
    }


    useEffect(() => {
        console.log('ssss')
        if(leaf_data && category_type_map && Object.keys(category_type_map).length) {
            console.log('ssss2', category_type_map);
            set_id(leaf_data.id);
            set_name(current_key);
            set_display_name(leaf_data.display_name);
            let temp_value_options = leaf_data.value_options;
            let temp_value_options_map = {};

            if (leaf_data.value_type === 'sku_category_type_id') {
                console.log('ssss3', leaf_data.value, JSON.parse(leaf_data.value).map(x => category_type_map[x]));
                set_value(JSON.parse(leaf_data.value).map(x => category_type_map[x]));
            } else if (leaf_data.value_type === 'list') {
                temp_value_options = temp_value_options.map(x => ({ id: generate_id(), display_name: x }));
                temp_value_options_map = temp_value_options.reduce((final, elem) => ({...final, [elem.display_name]: elem }), {});
                set_value(JSON.parse(leaf_data.value).map(x => temp_value_options_map[x]));
            } else {
                set_value(leaf_data.value);
            }
            set_overridable(leaf_data.overridable);
            set_visible(leaf_data.visible);
            set_value_type(leaf_data.value_type);
            set_max_level(leaf_data.max_level)
            if (leaf_data.value_type === 'list') {
                set_value_options(temp_value_options);
            } else {
                set_value_options(leaf_data?.value_options ?? []);
            }
        }
    }, [ leaf_data, category_type_map ]);


    useEffect(() => {
        if (category_types && category_types.length) {
            set_category_type_map(category_types.reduce((final, elem) => ({...final, [elem.id]: elem }), {}));
        }
    }, [ category_types ]);


    useEffect(() => {
        if (value_options && value_options.length) {
            console.log('value_options --> ', value_options);
        }
    }, [ value_options ]);


    return (
        <div>
		  	<Modal className="Margin_64px_top" isOpen={open} size="lg" toggle={handle_close}>
				<Card className='global_modal_height_for_mdlgxl'>
					<CardHeader className='global_modal_header'>
						<span>
							Manage Preference - {hierarchy.reduce((final, elem) => final + elem + ' > ', '')} {name}
						</span>
						<i style={{cursor:'pointer'}} onClick={handle_close} className='fa fa-times'/>
					</CardHeader>
					<CardBody className='global_modal_body' syle={{ overflow: 'unset' }}>
						<Row>
                            <Col>
								Name
							</Col>
							<Col>
								<input type="text" className="form-control" placeholder="Name" value={name} onChange={(e) => set_name(e.target.value)}/>    
							</Col>
						</Row>
                        <Row>
                            <Col>
								Display Name
							</Col>
							<Col>
								<input type="text" className="form-control" placeholder="Name" value={display_name} onChange={(e) => set_display_name(e.target.value)}/>    
							</Col>
						</Row>
                        <Row>
                            <Col>
                                Value Type
                            </Col>
                            <Col>
                                <select className="form-control" value={value_type} onChange={(e) => set_value_type(e.target.value)}>
                                    <option value="">Select A Type</option>
                                    <option value="string">String</option>
                                    <option value="integer">Integer</option>
                                    <option value="enum">Enum</option>
                                    <option value="json">JSON</option>
                                    <option value="boolean">Boolean</option>
                                    <option value="sku_id">SKU ID</option>
                                    <option value="sales_channel_id">Sales Channel ID</option>
                                    <option value="sku_category_type_id">Category Type ID</option>
                                    <option value="list">List</option>
                                </select>
                            </Col>
                        </Row>
                        {value_type === 'enum' || value_type == 'list' ? (
                            <Row>
                                <hr/>
                                <Col>
                                    Possible Values
                                </Col>
                                <Col>
                                    <KeyValuePair 
                                        item_json={value_options} 
                                        set_item_json={set_value_options} 
                                        object_key={value_type == 'list' ? 'display_name' : null}
                                    />
                                </Col>
                                <hr/>
                            </Row>
                        ) : ''}
                        <Row style={{ overflowY: 'visible' }}>
                            <Col>
                                Value   
                            </Col>
                            <Col>	
                                {value_type === 'sku_category_type_id' || value_type == 'list' ? (
                                    // <Multiselect
                                    //     style={{ backgroundColor:'white', width:'147px' }}
                                    //     options={value_type == 'list' ? value_options : category_types}
                                    //     selectedValues={value ? value : []}
                                    //     onSelect={x => { console.log('setting selected value 000  >>>>', x);set_value(x); }}
                                    //     onRemove={set_value}
                                    //     displayValue={'display_name'}
                                    //     id={'select_value' + value_type}
                                    //     placeholder='Choose Default Values'
                                    // />
                                    <Multiselect
                                        style={{ backgroundColor:'white', width:'147px' }}
                                        options={value_type == 'list' ? value_options : category_types}
                                        selectedValues={value ? value : []}
                                        onSelect={x => { console.log('setting selected value 000  >>>>', x);set_value(x); }}
                                        onRemove={set_value}
                                        displayValue={'display_name'}
                                        closeOnSelect={false}
                                        avoidHighlightFirstOption={true}
                                        showCheckbox
                                    />
                                ) : (
                                    <input type="text" className="form-control" placeholder="Value" value={value} onChange={(e) => set_value(e.target.value)}/>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>Max Level</Col>
                            <Col>
                                <select className='form-control' value={max_level} onChange={(e) => set_max_level(e.target.value)}>
                                    <option value='master'>Master</option>
                                    <option value='store'>Store</option>
                                    <option value='sales_channel'>Sales Channel</option>
                                    <option value='designer'>Designer</option>
                                    <option value='design'>Design</option>
                                </select>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                Overridable
                            </Col>
                            <Col>
                                <input type="checkbox"  checked={overridable} onChange={(e) => set_overridable(e.target.checked)}/>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col>
                                Visible
                            </Col>
                            <Col>
                                <input type="checkbox"  checked={visible} onChange={(e) => set_visible(e.target.checked)}/>
                            </Col>
                        </Row>
					</CardBody>
					<CardFooter className='global_modal_footer'>
						<Button className='blue_button' style={{margin:0}} type="button" onClick={handle_submit}> {id ? 'Update' : 'Add'} </Button>
					</CardFooter>
				</Card>
			</Modal>
		</div>
    );
}
    

const traverse_json = (json, hierarchy) => {
    if (hierarchy.length == 0) {
        return json;
    }
    var key = hierarchy.shift();
    if (json[key]) {
        return traverse_json(json[key], hierarchy);
    }
    return null;
}


const Leaf_Editor = ({ current_key, preference_json, hierarchy, refresh_data, submit_for_delete_confirm, delete_preference, category_types }) => {
    
    const [ current_json, set_current_json ] = useState({});
    const [ update_leaf_modal_show, set_update_leaf_modal_show ] = useState(false);

    
    useEffect(() => {
        set_current_json(traverse_json(preference_json, [ ...hierarchy, current_key ]));
    }, [preference_json, hierarchy]);

    
    return current_json ? (
        <div style={{ padding: 5, backgroundColor: "linen", margin: 5, borderRadius: 5, color: "#1070CA" }}>
            <Row style={{ color: 'black', fontSize: 14 }}>
                <Col>{current_json.display_name}</Col>
                <Col>{current_json.overridable ? 'Overridable' : 'Non Overridable'}</Col>
                <Col>{current_json.visible ? 'Visible' : 'Invisible'}</Col>
                <Col>{current_json.value_type}</Col>
                <Col>{current_json.value}</Col>
                <Col>
                    <span style={{cursor:'pointer', color: "#1070CA" }} onClick={() => set_update_leaf_modal_show(true)} className='fa fa-edit'/>
                </Col>
                <Col>
                   <i 
                        style={{cursor:'pointer', color: "#1070CA" }}
                        onClick={()=>submit_for_delete_confirm(()=>delete_preference([ current_json ]), ()=>{}, 'Are you sure you want to delete this Preference? Pref Name - '+ current_json.display_name)}
                        className='fa fa-trash'
                    />
                </Col>
            </Row>
            <Leaf_Edit_Modal
                leaf_data={current_json}
                open={update_leaf_modal_show}
                hierarchy={hierarchy}
                handle_close={() => set_update_leaf_modal_show(false)}
                refresh_data={refresh_data}
                category_types={category_types}
                current_key={current_key}
            />
        </div>
    ) : '';
}


const Parent_Editor = ({ current_key, preference_json, hierarchy, refresh_data, add_hierarchy_to_preference_json, submit_for_delete_confirm, delete_preference, category_types }) => {

    const [ current_json, set_current_json ] = useState({});
    const [ collapsed, set_collapsed ] = useState(true);
    const [ show_add_hierarchy_modal, set_show_add_hierarchy_modal ] = useState(false);
    const [ show_add_preference_modal, set_show_add_preference_modal ] = useState(false);


    useEffect(() => {
        set_current_json(traverse_json(preference_json, [ ...hierarchy, current_key ]));
    }, [preference_json, hierarchy]);


    return (
        <div style={{ width: "100%" }}>                    
            <div style={{ backgroundColor: "rgb(231 236 241)", color: "#1070CA", height: 35, borderRadius:5, margin: 5, padding: 5, cursor: 'pointer', paddingLeft: 15, paddingRight: 15 }} onClick={() => set_collapsed(!collapsed)}>
               <span style={{ float: 'left'}}> {current_key} </span>  
               <span style={{ float: 'right', marginTop: 4}} className={collapsed ? 'fa fa-plus' : 'fa fa-minus'} ></span>
            </div>
            <div style={{ margin:"10px", display: collapsed ? "none" : "block", borderLeft: "2px solid lightgrey", borderRight: "2px solid lightgrey", borderBottom: "2px solid lightgrey" }}>
                {!collapsed && current_json && Object.keys(current_json).map((key, index) => (
                    current_json[key].type == 'leaf' && current_json[key].id ? (
                        <div>
                            <Leaf_Editor
                                current_key={key}
                                hierarchy={[ ...hierarchy, current_key ]}
                                preference_json={preference_json}
                                refresh_data={refresh_data}
                                submit_for_delete_confirm={submit_for_delete_confirm}
                                category_types={category_types}
                                delete_preference={delete_preference}
                            />
                        </div>
                    ) : (
                        <div>
                            <Parent_Editor
                                current_key={key}
                                hierarchy={[ ...hierarchy, current_key ]}
                                preference_json={preference_json}
                                add_hierarchy_to_preference_json={add_hierarchy_to_preference_json} 
                                submit_for_delete_confirm={submit_for_delete_confirm}
                                delete_preference={delete_preference}
                                category_types={category_types}
                                refresh_data={refresh_data}
                            />
                        </div>
                    )
                ))}
                <Row style={{ padding: 10 }}>
                    <Col style={{ textAlign: 'right' }}>
                        <Button className='blue_button' style={{margin:0}} type="button" onClick={() => set_show_add_hierarchy_modal(true)}><span className='fa fa-plus'/> &nbsp; &nbsp; <b>Add Hierarchy</b></Button>
                    </Col>
                    <Col style={{ textAlign: 'left' }}>
                        <Button 
                            className='blue_button' 
                            style={{margin:0}} 
                            type="button" 
                            onClick={() => set_show_add_preference_modal(true)} 
                        > 
                            <span className='fa fa-plus'/> &nbsp; &nbsp; <b>Add Preference</b> 
                        </Button>
                    </Col>
                </Row>
                <Add_Hierarchy_Modal
                    key={current_key}   
                    add_hierarchy_to_preference_json={add_hierarchy_to_preference_json} 
                    open={show_add_hierarchy_modal}
                    set_open={set_show_add_hierarchy_modal} 
                    hierarchy={[ ...hierarchy, current_key ]}
                />
                <Leaf_Edit_Modal
                    hierarchy={[ ...hierarchy, current_key ]}
                    open={show_add_preference_modal}
                    handle_close={() => set_show_add_preference_modal(false)}
                    refresh_data={refresh_data}
                    category_types={category_types}
                    key={current_key}
                    current_key={current_key}
                />
            </div>
        </div>
    );
}

const Design_Preferences = ({ user, set_active_tab, send_analytics_event, check_route_fn, submit_for_delete_confirm ,store_details, get_store_details, set_active_division_id_sidebar}) => {
    const alert = useAlert()

    const [ initialized, set_initialized ] = useState(false);
	const [ preference_json, set_preference_json ] = useState({});
    const [ category_types, set_category_types ] = useState([]);
    const [ show_add_hierarchy_modal, set_show_add_hierarchy_modal ] = useState(false);


    const fetch_category_type_details = async() => {
        try {
            let response = await general_fetch({ url: 'sku_category_type/get'});
            response = split_cat_type(response, 'with_division').sort(name_sort);
            // response = split_cat_type(response).sort(name_sort);
            console.log('cat type --->> ', response);
            set_category_types(response);
        } catch(err) {
            console.log(err);
        }
	}


	const fetch_master_preferences = async () => {
		try {
			var resp = await general_fetch({ url: 'designing_preference/get_master', body: { nested_output: true } });
			set_initialized(true);
            set_preference_json(resp);
		} catch(err) {
			console.log(err);
		}
	}


    const delete_preference = async (items) => {
		try {
			var body = { id: items.map(x => x.id) }; 
			var response = await general_fetch({ url: 'designing_preference/delete_master', body });
			alert.success("successfull")
			fetch_master_preferences();
		} catch(err) { 
			alert.success("API Failure")
			console.log(err);
		}
    }


    const add_hierarchy_to_preference_json = (hierarchy, value) => {
        var new_preference_json = { ...preference_json };
        var current_json = traverse_json(new_preference_json, hierarchy);
        console.log("current_json", current_json);
        current_json[value] = {}
        // current_json[value.id] = value;
        set_preference_json(new_preference_json);
    }


	useEffect(() => {
		if (!initialized) {
			fetch_master_preferences();
            fetch_category_type_details();
		}
		set_active_tab('preferences_settings');
        set_active_division_id_sidebar('design_preferences')
		send_analytics_event('auto', 'Design Preferences', 'Design Preferences', 1, window.location.href)
		// check_route_fn('design_preferences');
	}, []);


	try {
		return (
			<Main_Grid  active_tab="design_preference" user={user} store_details={store_details} get_store_details={get_store_details}>
                <div style={{ height: '100%', overflow: 'scroll', padding: 30, margin: 0 }}>
                    <Row style={{padding:'10px', color:'#22272e', fontFamily: 'Source_Sans_Pro-SemiBold'}}>
                        Manage Global Preferences
                    </Row>
                    <Row style={{padding:'10px 0px', color:'#22272e'}}>
                        {preference_json && Object.keys(preference_json).map((key, index) => 
                            (preference_json[key].type == 'leaf' && preference_json[key].id) ? (
                                <div style={{width: '100%'}}>
                                    <Leaf_Editor
                                        current_key={key}
                                        hierarchy={[]}
                                        preference_json={preference_json}
                                        refresh_data = {fetch_master_preferences}
                                        submit_for_delete_confirm={submit_for_delete_confirm}
                                        delete_preference={delete_preference}
                                        category_types={category_types}
                                    />
                                </div>
                            ) : (
                                <div style={{width: '100%'}}>
                                    <Parent_Editor
                                        current_key={key}
                                        hierarchy={[]}
                                        preference_json={preference_json}
                                        refresh_data = {fetch_master_preferences}
                                        add_hierarchy_to_preference_json={add_hierarchy_to_preference_json}
                                        submit_for_delete_confirm={submit_for_delete_confirm}
                                        delete_preference={delete_preference}
                                        category_types={category_types}
                                    />
                                </div>
                            )
                        )}
                    </Row>
                    <Row style={{ padding: 10 }}>
                        <Col style={{ textAlign: 'right' }}>
                            <Button className='blue_button' style={{margin:0}} type="button" onClick={() => set_show_add_hierarchy_modal(true)}><span className='fa fa-plus'/> &nbsp; &nbsp; <b>Add Hierarchy</b></Button>
                        </Col>
                        {/* <Col style={{ textAlign: 'left' }}>
                            <Button 
                                className='blue_button' 
                                style={{margin:0}} 
                                type="button" 
                                onClick={() => set_show_add_preference_modal(true)} 
                            > 
                                <span className='fa fa-plus'/> &nbsp; &nbsp; <b>Add Preference</b> 
                            </Button>
                        </Col> */}
                    </Row>
                    <Add_Hierarchy_Modal  
                        add_hierarchy_to_preference_json={add_hierarchy_to_preference_json} 
                        open={show_add_hierarchy_modal}
                        set_open={set_show_add_hierarchy_modal} 
                        hierarchy={[]}
                    />
                </div>
			</Main_Grid>
		);
	} catch(err) {
		console.error("Error in feature flags component ---> ", err);
	}

}

export default Design_Preferences;