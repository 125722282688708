import React, { useContext } from "react"
import { Checkbox } from "antd"

import './style.css'
import build_path from "../../utils/path"
import { UserContext } from "../Contexts/UserContext"

const GroupListCard = ({sku_group, is_selected, bulk_selected_skus}) => {
    const user = useContext(UserContext)
    return (
        <div className="p-2 groupListCardContainer flex_property">
            <div className="groupListCard pr-2" style={{width: '50%', display: 'flex', flexDirection: 'column'}}>
                <div className="flex_property justify-content-between mb-2 groupNameContainer">
                    <div className="flex_property" style={{width: 'calc(100% - 100px)'}}>
                        {/* <Checkbox checked={is_selected} className="mr-2"></Checkbox> */}
                        <i className={`mr-2 ${!is_selected ? "far fa-square" : "fa fa-check-square"}`} style={{color: '#0078ff'}}/>
                        <div title={sku_group.name} className="lines1_elipsis">{sku_group && sku_group.name ? sku_group.name : ''}</div>
                    </div>
                    {/* <div style={{color: '#C4C6CD'}}>{sku_group && sku_group.sku && sku_group.sku.length &&  sku_group.sku.filter(single_sku => single_sku.store_id === user.store_id).length} owned sku</div> */}
                    <div style={{color: '#C4C6CD'}}>{sku_group && sku_group.sku && sku_group.sku.length &&  sku_group.sku.filter(single_sku => single_sku.business_unit_id === user.current_business_unit_id).length} owned sku</div>
                </div>
                <div className="bulkskuBackgroundPlaceholder flex_center" style={{flex: 1}}>
                    <img src={
                        sku_group.sku && sku_group.sku.length && sku_group.sku.find(single_sku => bulk_selected_skus[single_sku.id]) && sku_group.sku.find(single_sku => bulk_selected_skus[single_sku.id]).display_pic ?
                        build_path(global.config.static_path, sku_group.sku.find(single_sku => bulk_selected_skus[single_sku.id]).display_pic.image.replace('.png', '_thumbnail.png').replace('.jpg', '_thumbnail.jpg').replace('.jpeg', '_thumbnail.jpeg'))
                        :
                        global.config.sku_image_not_found
                    } height={'100px'} style={{maxWidth: '150px', objectFit: 'cover'}}></img>
                </div>
            </div>
            <div style={{width: '50%', height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'white', borderRadius: '4px'}}>
                <div className="lines1_elipsis py-1 px-2" style={{fontSize: '14px', color: '#C4C6CD'}}>SKU List:  {sku_group && sku_group.sku ? sku_group.sku.filter(single_sku => bulk_selected_skus[single_sku.id]).length : '0'}/{sku_group && sku_group.sku ? sku_group.sku.length : '0'} selected</div>
                <div className="skuListContainer" style={{overflow: 'auto', flex: 1, minHeight: 0}}>
                    {
                        sku_group && sku_group.sku ? sku_group.sku.map((sku, idx) => (
                            <div className="flex_property skuListItem">
                                {/* <Checkbox checked={bulk_selected_skus[sku.id]} className="mr-2"/> */}
                                <i className={`mr-2 ${!bulk_selected_skus[sku.id] ? "far fa-square" : "fa fa-check-square"}`} style={{ color: bulk_selected_skus[sku.id] ? '#0078ff' : '#C5C7CF' }} />
                                <div title={sku.name} className="lines1_elipsis">{sku.name}</div>
                            </div>
                        ))
                            : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default GroupListCard