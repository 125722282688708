import React, { useState } from 'react';
import { Tooltip, Row, Col } from 'reactstrap';

const Info_tooltip = ({text_message}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
        <span onClick={toggle} style={{textDecoration: "underline", color:"blue"}} href="#" id="TooltipExample"><i style={{color:'rgba(0, 0, 0, 0.125)'}} className='fa fa-info-circle' /></span>
        <Tooltip placement="right" style={{Width:'400px', maxHeight:'200px', overflowY:'auto'}} isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}>
            {text_message?text_message:''}
        </Tooltip>
    </div>
  );
}

export default Info_tooltip;