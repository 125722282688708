import general_fetch from "../fetch"

export const get_all_inventory_templates = async () => {
    try {
        let user = global.get_user()
        let org_bu_view = window.sessionStorage.getItem('org_bu_view') || 'bu'

        let body = {}

        if (org_bu_view === 'bu') {
            body["business_unit_id"] = user.current_business_unit_id
        }
        
        let response = await general_fetch({ url: 'inventory_template/get', body });
        return response;

    } catch (error) {
        console.error("Error getting inventory templates", error);
        return Promise.reject(error)
    }
}


export const create_inventory_template = async (data) => {
    try {
        let user = global.get_user()
        let org_bu_view = window.sessionStorage.getItem('org_bu_view') || 'bu'

        let body = data

        if (org_bu_view === 'bu') {
            body["business_unit_id"] = user.current_business_unit_id
        }
        
        let response = await general_fetch({ url: 'inventory_template/create', body });
        return response;
    } catch (error) {
        console.error("Error in create_inventory_template", error);
        return Promise.reject(error)
    }
}