import general_fetch from "../fetch"

export const fetch_all_level_preferences = async (sales_channel_ids = [], nested_output = true, json_with_leaf_values = true) => {
    try {
        let user = global.get_user()
        // let org_bu_view = window.sessionStorage.getItem('org_bu_view') || 'bu'
        let body = {
            sales_channel_id: sales_channel_ids,
            nested_output: nested_output,
            json_with_leaf_values: json_with_leaf_values,
            business_unit_id: user.current_business_unit_id
        }

        let response = await general_fetch({ url: 'designing_preference/get_all_level_preferences', body })

        return response
    } catch (error) {
        console.error("Error in fetching all level preferences", error)
        return Promise.reject(error)
    }
}

export const set_preferences = async (design_preference_id_and_values) => {
    try {
        let user = global.get_user()
        let org_bu_view = window.sessionStorage.getItem('org_bu_view') || 'bu'

        let body = { design_preference_id_and_values }

        if (org_bu_view === 'bu') {
            body["business_unit_id"] = user.current_business_unit_id
            let response = await general_fetch({ url: 'designing_preference/set_business_unit_preference', body });
            return response
        }

        let response = await general_fetch({ url: 'designing_preference/set_store_preference', body });
        return response

    } catch (error) {
        console.error("Error setting preferences", error);
        return Promise.reject(error)
    }
}