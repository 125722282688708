import React , { useState, useEffect } from 'react';
import {FormGroup, Button, Form,Modal, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";

const DeleteNotification = ({yes_del, no_del, text, onClose, nonowned, button_name, theme = 'default'}) => {

	return(
		theme === 'default' ? 
		  	<div className='custom-ui'>
				<div style={{border:"1px solid #dee2e6", width:'600px', backgroundColor:'#F1F2F3', zIndex:10000}}>
					<div className="modal-header">
						<h5 className="modal-title" id="modal-title-notification">
							<span style={{color:'#627386'}}>Your attention is required</span>
						</h5>
						<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={()=>{no_del(); onClose()}}>
							<span aria-hidden={true}>×</span>
						</button>
					</div>
					<div className="modal-body">
						<div className="py-3 text-center">
							<h4 style={{color:'#627386', fontSize:'18px'}} className="heading">{text?text:'Press Delete to Confirm'}</h4>
						</div>
					</div>
					<div className="modal-footer">
						<Button className="white_button" type="button" onClick={()=>{no_del(); onClose()}}>
							Close
						</Button>
						<Button id='delete_notification_button' className="red_button" type="button" onClick = {()=>{yes_del(); onClose()}}>
							{button_name?button_name:(nonowned==='non-owned'?'Remove':'Delete')}
						</Button>
					</div>
				</div>
            </div>
			:
			<div className='DMSans'>
				<div style={{boxShadow: '0px 0px 8px 0px #0000004D', width:'400px', backgroundColor:'white', zIndex:10000, padding: '2rem 4rem', position: 'relative', borderRadius: '4px'}}>
					{/* <img onClick={toggle} style={{cursor:'pointer', position: 'absolute', right: '2rem', top: '2rem'}} height={'16px'} width={'16px'} src={'/assets/icons/modal_cross.svg'}></img> */}
					<div className='mb-4' style={{textAlign: 'center'}}>
						{text ? text : 'Plese Delete to confirm.'}
					</div>
					<div className='flex_center'>
						<Button className="white_button" type="button" onClick={()=>{no_del(); onClose()}}>
							Close
						</Button>
						<Button id='delete_notification_button' className="red_button" type="button" onClick = {()=>{yes_del(); onClose()}}>
							{button_name?button_name:(nonowned==='non-owned'?'Remove':'Delete')}
						</Button>
					</div>
				</div>
            </div>

	); 
}

export default DeleteNotification ;