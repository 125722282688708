import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import LazyLoad from 'react-lazyload';
import split_cat_type from '../../utils/split_cat_type';
import { confirmAlert } from 'react-confirm-alert';
import classnames from "classnames";
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { UserContext } from '../../components/Contexts/UserContext';
import PricingTermsConditions from '../../components/PricingTermsConditions';
import Service_Charge from './ServiceCharge';
import { COLORS } from '../../utils/colors';
import { UncontrolledCollapse } from '../../components/ICollpsable';
import debug_log from '../../utils/debug_log';
import './style.css'
import default_cam_structure from '../../models/cam_structure';
import Discounts from './Discounts';
import CamSettings from './CamSettings';

const Edit_price_settings_modal = ({ open , handleclose, store_details, get_store_details, tandc_array, set_tandc_array}) => {

	const [tandc_text, set_tandc_text] = useState('');
	// const [tandc_array, set_tandc_array] = useState([]);
    const [make_bold, set_make_bold] = useState(null)

	const [show_escape_warning, set_show_escape_warning] = useState(false);
	const [discard_changes, set_discard_changes] = useState(false);
	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)
	const alert = useAlert();

	const onclick_update_pricing_details = async () => {
		if((tandc_array && tandc_array.length==1) || is_valid_add_key(tandc_array)){
			try {
				// console.log("tandc", JSON.stringify(tandc_array))
				var updates = JSON.stringify({ boq_terms_and_conditions: JSON.stringify(tandc_array)})
				var body = {updates:updates}
				// var response = await general_fetch({ url: 'store/update_info' , body });
				if (org_bu_view == 'bu') {
					body.business_unit_id = user.current_business_unit_id
					let resp = await general_fetch({ url: 'business_unit/update_info', body })
				} else {
					let resp = await general_fetch({ url: 'store/update_info', body })
				}
				alert.success('Store Pricing T & C text successfully updated')	
				onclick_handleclose();	
				get_store_details()	
			} catch(err) {
				alert.error('API call failed')
				console.log(err);
			}
		}else{
			alert.error("Empty values are not allowed !")
		}
	}

	const is_valid_add_key = () => {
		if(tandc_array && tandc_array.length > 0){
			for(let i=0;i<tandc_array.length;i++){
				// console.log("printing tandc", tandc_array[i][0])
				if(tandc_array[i][0].text==='' || tandc_array[i][1].text==='')
					return false
			}
			return true
		}else{
			// console.log("printing tandc true")
			return true
		}
	}

	const is_valid_add_key_1 = () => {
		if(tandc_array && tandc_array.length > 0){
			// console.log("printing tandc", tandc_array[0][0])
			if(tandc_array[0][0].text==='' || tandc_array[0][1].text==='')
				return false
			return true
		}else{
			// console.log("printing tandc true")
			return true
		}
	}

	const onclick_handleclose =() => { 
		// handleclose()
		set_make_bold(null)
		set_tandc_text('')
	}

	const add_tandc_row = () => {
		if(is_valid_add_key()){
			let a = [...tandc_array]
			let temp_row = [{text:'',bold:false},{text:'',bold:false}]
			a.push(temp_row)
			set_tandc_array(a)
		}else{
			alert.error("Empty values are not allowed !")
		}
	}

	const update_tandc_row = (idx1, idx2, key, value) => {
		let a = [...tandc_array]
		if(key=='text')
		{
			a[idx1][idx2].text = value
		}
		if(key=='bold'){
			a[idx1][idx2].bold = value
		}
		set_tandc_array(a)
	}

	const delete_tandc_row = (idx) => {
		let a = [...tandc_array]
		a.splice(idx,1)
		set_tandc_array(a)
	}

	useEffect(() => {
		if(tandc_array && tandc_array.length==0){
			add_tandc_row()
		}
	},[tandc_array])

	useEffect(() => {
		if(store_details){
			// set_tandc_array(store_details?store_details.boq_terms_and_conditions:'')	
			let temp_array = store_details.boq_terms_and_conditions;
			if (temp_array) {
				try {
					temp_array = JSON.parse(temp_array);
					if (!temp_array || !temp_array.length) {
						temp_array=[];
					}
				} catch (err) {
					console.error("BOQ not json")
					temp_array = [];
				}
			} else {
				temp_array = [];
			}
			set_tandc_array(temp_array)
		}
	},[store_details, open])

	useEffect(() => {
		// console.log("discard", discard_changes)
		if(discard_changes == true){
			onclick_handleclose()
			set_discard_changes(false)
		}
	},[discard_changes])


	return(
		// <Modal className="Margin_64px_top" size="am" isOpen={open} toggle={() => set_show_escape_warning(true)}>
		// 	<EscapeWarning open = {show_escape_warning} set_discard_changes={set_discard_changes} handleClose={() => set_show_escape_warning(false)}/>
		// 	<Card className='global_modal_height_for_pricing_settings'>
		// 		<CardHeader className='global_modal_header'>
		// 			<span>Manage Store Pricing Settings</span>
		// 			<i onClick={onclick_handleclose} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
		// 		</CardHeader>

		// 		<CardBody className='global_modal_body'>
		// 			<Row style={{marginBottom:"10px"}}>
		// 				<Col>
		// 					<div>
		// 						<div style={{paddingBottom:'3px',fontSize:'16px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}><span>{tandc_array && tandc_array.length > 0 && is_valid_add_key_1(tandc_array)?'Edit the':'Add'}</span><span style={{fontWeight:800}}> Terms </span><span>&</span><span style={{fontWeight:800}}> Conditions </span><span>to be printed in the pricing quotations</span></div>
		// 						<div style={{marginTop:'20px'}}></div>
		// 						<div style={{display:'flex'}}>
		// 							<span style={{flex:1, marginLeft:'5%', fontSize:'14px'}}>Store Terms</span>
		// 							<span style={{flex:3, marginLeft:'8%', fontSize:'14px'}}>Store Conditions</span>
		// 						{/* <button style={{flex:1, fontSize:'14px', color:'#2688F7'}}>+ Add new Column</button> */}
		// 						</div>
		// 						{/* <textarea style={{width:'100%',height:'150px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Text..." type="text" value={tandc_text} onChange={e => set_tandc_text(e.target.value)}/> */}
		// 						{
		// 							tandc_array && tandc_array.length ? tandc_array.map((tandc_row,idx) => {
		// 								return(
		// 									<PricingTermsConditions tandc_row = {tandc_row} row_index={idx} update_tandc_row = {update_tandc_row} delete_tandc_row = {delete_tandc_row} make_bold={make_bold} set_make_bold={set_make_bold}/>
		// 								)
		// 							}):''
		// 						}
		// 					</div>
		// 				</Col>
						
		// 			</Row>
		// 			<Row>
		// 				<span style={{color:'#2688F7', fontSize:'14px', cursor:'pointer', fontWeight:'700', marginTop:'1%', marginLeft:'3%'}} onClick = {() => add_tandc_row()}>+ Add more terms</span>
		// 			</Row>
		// 		</CardBody>

		// 		<CardFooter className='global_modal_footer'>
		// 			<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>
		// 			<Button className='blue_button' type="button" onClick={onclick_update_pricing_details}>Update</Button>
		// 		</CardFooter>
		// 	</Card>  
		// </Modal>

		<React.Fragment>
			<Row style={{marginBottom:"10px"}}>
				<Col>
					<div>
						<div style={{paddingBottom:'3px',fontSize:'16px', color:'#425A70', textAlign:'initial', marginLeft:'32px'}}><span>{tandc_array && tandc_array.length > 0 && is_valid_add_key_1(tandc_array)?'Edit the':'Add'}</span><span style={{fontWeight:800}}> Terms </span><span>&</span><span style={{fontWeight:800}}> Conditions </span><span>to be printed in the pricing quotations</span></div>
						<div style={{marginTop:'20px'}}></div>
						<div style={{}}>
							<Row>
								<Col xs={1}></Col>
								<Col xs={4}>
									<span style={{flex:1, fontSize:'14px'}}>Store Terms</span>
								</Col>
								<Col xs={6}>
									<span style={{flex:3, fontSize:'14px'}}>Store Conditions</span>
								</Col>
							</Row>
						{/* <button style={{flex:1, fontSize:'14px', color:'#2688F7'}}>+ Add new Column</button> */}
						</div>
						{/* <textarea style={{width:'100%',height:'150px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Text..." type="text" value={tandc_text} onChange={e => set_tandc_text(e.target.value)}/> */}
						{
							tandc_array && tandc_array.length ? tandc_array.map((tandc_row,idx) => {
								return(
									<PricingTermsConditions tandc_row = {tandc_row} row_index={idx} update_tandc_row = {update_tandc_row} delete_tandc_row = {delete_tandc_row} make_bold={make_bold} set_make_bold={set_make_bold}/>
								)
							}):''
						}
					</div>
				</Col>
				
			</Row>
			<Row>
				<span style={{color:'#2688F7', fontSize:'14px', cursor:'pointer', fontWeight:'700', marginTop:'1%', marginLeft:'3%'}} onClick = {() => add_tandc_row()}>+ Add more terms</span>
			</Row>
            <div className='flex_property' style={{width:'100%', justifyContent:'flex-end'}}>
                <Button className='blue_button' type="button" style={{}} onClick={onclick_update_pricing_details}>Update</Button>
            </div>
		</React.Fragment>
	)
}

const PricingSettings = ({store_details, get_store_details, set_active_tab, submit_for_delete_confirm, set_active_division_id_sidebar, send_analytics_event, set_page_loader, org_details, get_org_details}) => {
    const [tabs , set_tabs ] = useState(1)
    const [tandc_array, set_tandc_array] = useState([]);
    const org_bu_view = useContext(OrgBuContext)
	const [payment_schedule, set_payment_schedule] = useState([]);
	const [editing_index, set_editing_index] = useState(-1);
	const user = useContext(UserContext)
	const alert = useAlert()


	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	const add_row = () => {
		if(payment_schedule && payment_schedule.length){
			for(let i = 0; i < payment_schedule.length; i++){
				if(!payment_schedule[i].name){
					alert.error('Name cannot be empty')
					return
				}
			}
		}
		let a = JSON.parse(JSON.stringify(payment_schedule))
		a.push({name: 'Installment'+(payment_schedule.length+1), percentage:0})
		set_editing_index(payment_schedule.length)
		set_payment_schedule(a)
	}

	const delete_row = (idx) => {
		let a = JSON.parse(JSON.stringify(payment_schedule))
		a.splice(idx,1)
		set_payment_schedule(a)
		update_store_info(a)
	}

	const reset_payment_schedule = () => {
		if(store_details && store_details.payment_schedule && store_details.payment_schedule.length){
			set_payment_schedule(JSON.parse(JSON.stringify(store_details.payment_schedule)))
        }else{
			set_payment_schedule([])
		}
	}

	const update_payment_schedule = (idx, key, value) => {
		let a = JSON.parse(JSON.stringify(payment_schedule))
		a[idx][key] = value
		set_payment_schedule(a)
	}

	const update_store_info = async(payment_schedule) => {
		try{
			let temp_payment_schedule = payment_schedule && payment_schedule.length ? payment_schedule.map(x => {x.percentage = Number(x.percentage); return x}) : []
			var updates = JSON.stringify({payment_schedule: temp_payment_schedule})
			let body = {updates:updates}
			set_page_loader({
				show: true,
				text: 'Please wait'
			});
			if (org_bu_view == 'bu') {
				body.business_unit_id = user.current_business_unit_id
				let resp = await general_fetch({ url: 'business_unit/update_info', body })
			} else {
				let resp = await general_fetch({ url: 'store/update_info', body })
			}
			set_page_loader({
				show: false,
			});	
			get_store_details()
			alert.success('Payment Schedule updated successfully')
		}catch(err){
			alert.success('Could not update Payment Schedule')
			set_page_loader({
				show: false,
			});	
			console.log(err)
		}
	}

    useEffect(() => {
    set_active_tab('preferences_settings');
    set_active_division_id_sidebar('pricing_settings');
    }, []);

	useEffect(() => {
        if(store_details && store_details.payment_schedule && store_details.payment_schedule.length){
			set_payment_schedule(JSON.parse(JSON.stringify(store_details.payment_schedule)))
        }
    }, [store_details]);

    return(
        <Main_Grid active_tab="RoomType" store_details={org_details} get_store_details={get_org_details}>
            <Row style={{padding:'20px 10px', color:'#22272e'}}>
                <Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
						{tabs==1 ? 'Terms and Conditions' : (tabs===2 ? 'Service Charge' : tabs===3 ? 'Payment Schedule' : tabs===4 ? 'Discount' : 'CAM')}
				</Col>
            </Row>
            <Row style={{height:'calc(100% - 90px)'}}>
                <Col style={{height:'100%'}}>
                    <Card style={{borderRadius: 0, height:'100%', overflowY:'scroll'}}>
                        <Nav 
                            style={{display:'flex', alignItems:'center'}}
                            className="flex-md-row"
                            id="tabs-icons-text" 
                            pills
                            role="tablist"
                        >
                            <NavItem>
                                <NavLink
                                    aria-selected={tabs === 1}
                                    style = {{marginLeft:'10px'}}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: tabs === 1
                                    })}
                                    onClick={e => toggleNavs(e, 1)}
                                    href="#pablo"
                                    role="tab"
                                >
                                    <span style={{fontSize:'14px'}}>Terms and Conditions</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    aria-selected={tabs === 2}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: tabs === 2
                                    })}
                                    onClick={e => toggleNavs(e, 2)}
                                    href="#pablo"
                                    role="tab"
                                >
                                    <span style={{fontSize:'14px'}}>Service Charge</span>
                                </NavLink>
                            </NavItem>
							<NavItem>
                                <NavLink
                                    aria-selected={tabs === 3}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: tabs === 3
                                    })}
                                    onClick={e => toggleNavs(e, 3)}
                                    href="#pablo"
                                    role="tab"
                                >
                                    <span style={{fontSize:'14px'}}>Payment Schedule</span>
                                </NavLink>
                            </NavItem>
							<NavItem>
                                <NavLink
                                    aria-selected={tabs === 4}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: tabs === 4
                                    })}
                                    onClick={e => toggleNavs(e, 4)}
                                    href="#pablo"
                                    role="tab"
                                >
                                    <span style={{fontSize:'14px'}}>Discount</span>
                                </NavLink>
                            </NavItem>
							<NavItem>
                                <NavLink
                                    aria-selected={tabs === 5}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: tabs === 5
                                    })}
                                    onClick={e => toggleNavs(e, 5)}
                                    href="#pablo"
                                    role="tab"
                                >
                                    <span style={{fontSize:'14px'}}>CAM</span>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={"tabs" + tabs} style={{overflow: 'auto'}}>
                            <TabPane tabId={'tabs1'} style={{fontSize:'12px', padding:'20px 40px 10px 0px'}}>
                                <Edit_price_settings_modal store_details={store_details} get_store_details={get_store_details} tandc_array={tandc_array} set_tandc_array={set_tandc_array}>

                                </Edit_price_settings_modal>
                            </TabPane>
                            <TabPane tabId={'tabs2'} style={{fontSize:'12px', padding:'0px'}}>
                                <Service_Charge store_details={store_details} get_store_details={get_store_details} set_page_loader={set_page_loader}/>
                            </TabPane>
							<TabPane tabId={'tabs3'} style={{fontSize:'12px', padding:'0px'}}>
								<table  style={{overflow:'auto'}} className="text-left flex_property table_style">
									<thead style={{width:"100%"}}>
										<tr className='tr_style'>
											<th className='th_style flex_property' style={{flex:'1'}}>Installment Name</th>
											<th className='th_style flex_property' style={{flex:'1'}}>Installment percentage (%)</th>
											<th className='th_style flex_property' style={{flex:'1'}}>Action</th>
										</tr>
									</thead>
									<tbody style={{overflow:"auto",width:"100%"}}>
									{
										payment_schedule && payment_schedule.length ? payment_schedule.map((single_installment, idx) => (
											<tr className='tr_style display_onhover' key={idx}>
												<td className='td_style flex_property' style={{flex:'1'}}>
												{
													editing_index === idx ? 
													<Input className='standard_input' value={single_installment.name ? single_installment.name : ''} onChange={(e) => update_payment_schedule(idx, 'name', e.target.value)} />
													:<div>{single_installment.name ? single_installment.name : ''}</div>
												}
												</td>
												<td className='td_style flex_property' style={{flex:'1'}}>
												{
													editing_index === idx ? 
													<Input className='standard_input' type='number' value={(single_installment.percentage || single_installment.percentage===0) ? single_installment.percentage : ''} onChange={(e) => (Number(e.target.value)<0 || Number(e.target.value)>100) ? alert.error('Please enter a number from 0 to 100') : update_payment_schedule(idx, 'percentage', e.target.value)} />
													:<div>{single_installment.percentage ? single_installment.percentage : '0'} %</div>
												}
												</td>
												<td className='td_style flex_property x1' style={{flex:'1'}}>
												{
													editing_index === idx?
													<div>
														<i className='fas fa-check mr-3 cp' style={{color:'#23C16B'}} onClick={() => {update_store_info(payment_schedule); set_editing_index(-1)}}/>
														<i className='fa fa-times cp' style={{color:'#FF5247'}} onClick={() => {reset_payment_schedule(); set_editing_index(-1)}}/>
													</div>
													:(
														editing_index === -1?
														<>
															<i className='fa fa-edit cp invisible1 mr-3' style={{}} onClick={() => set_editing_index(idx)}/>
															<i className='fa fa-trash cp invisible1' style={{color:'red'}} onClick={() => delete_row(idx)}/>
														</>
														:''
													)
												}
												</td>
											</tr>
										)):""
									}
									</tbody>
								</table>
								<div onClick={editing_index===-1 ? add_row : ''} className='mt-4 ml-3' style={{color:'#2688F7', fontWeight:'700', fontSize:'14px', cursor:'pointer', textAlign:'left', opacity:editing_index===-1?'1':'0.3'}}>
									+ Add Row
								</div>
                            </TabPane>
							<TabPane tabId={'tabs4'} className='DMSans' style={{fontSize:'12px', padding:'0px', height: '100%'}}>
								<Discounts store_details={store_details} get_store_details={get_store_details} set_page_loader={set_page_loader}/>
                            </TabPane>
							<TabPane tabId={'tabs5'} className='DMSans' style={{fontSize:'12px', padding:'0px', height: '100%'}}>
								{/* <CamPreferences store_details={store_details} get_store_details={get_store_details} set_page_loader={set_page_loader}/> */}
								<CamSettings store_details={store_details} get_store_details={get_store_details} set_page_loader={set_page_loader}/>
                            </TabPane>
                        </TabContent>
                    </Card>
                </Col>
            </Row>
        </Main_Grid>
    )
}

export default PricingSettings

