import React , { useState , useEffect }from 'react';
import {Modal, Alert,FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import UploadFile from '../UploadFile';
import general_fetch from '../../utils/fetch';
import Choose_existing_texture from '../Choose_existing_material';
import { useAlert } from 'react-alert';
import SingleSelect from '../SingleSelect';
import UploadImage from '../UploadFile/UploadImage';
import { handleError } from '../../utils/error';
import { rgbToHex } from '../../utils/colors';
import { COLORS } from '../../utils/colors';
import TriggerTooltip from '../TriggerTooltip';
import { is_valid_file_type } from '../../utils/is_valid_file';

const custom_data = {
    "refractive_index": {
        name: "Refraction Index (IOR)",
        tooltip:
            <div>
                <div className='mb-2'><b>IOR: Index of Refraction</b></div>
                <div style={{color: "#A7A8B2"}}>
                    Defines how much internal reflection the transparent material has.<br />
                    EG: diamond has more IOR, than glass.
                </div>
            </div>
    }
}

const Texture_structure = ({text_name, property, default_val, properties, set_properties, set_page_loader, texture_json, disabled_input, input_size, set_base_texture_url, parsed_properties, set_parsed_properties, compact_style, title, add_id='own'}) => {
    
    const alert = useAlert();

    const [add_texture_file, set_add_texture_file] =useState('')
    const [return_texture_details, set_return_texture_details] =useState('')
    const [texture_url, set_texture_url] = useState('')
    const [image_name, set_image_name] = useState('')

    const uplaod_file = async () => {
        try{
            if(!is_valid_file_type(add_texture_file, ['jpeg','jpg', 'png'])){
				alert.error("Invalid file format! Please upload a file with a valid format(jpeg, jpg, png).")
				return
			}
            var fd = new FormData();	
            add_texture_file && fd.append('file', add_texture_file)
            console.log('add texture file', add_texture_file)
            fd.append('name', add_texture_file.name)
            set_page_loader({
                show:true,
                text:'Please wait, uploading texture...'
            })
            var resp = await general_fetch({ url: 'texture/add', body:fd, is_form_data:true });
            resp && resp.id && set_return_texture_details(resp.id)
            
            if(resp && resp.image) {
                set_texture_url(resp.image)
                if(resp.name)
                    set_image_name(resp.name)

            }
            set_page_loader({
                show:false,
                text:''
            })
        }catch(err){
            console.log(err)
        }
    }

    const get_texture_url = async(return_texture_details) => {
        try{
            var texture_get = await general_fetch({url:'texture/get', body:{identifiers:JSON.stringify({id:[return_texture_details]})}})
            if(texture_get){
                set_texture_url(texture_get[0] && texture_get[0].Image ? texture_get[0].Image.image :'')
                set_image_name(texture_get[0] ? texture_get[0].name :'')
                if(set_base_texture_url) set_base_texture_url(texture_get[0] && texture_get[0].Image ? texture_get[0].Image.image :'')
            }
        }catch(error){
            handleError(error);
        }
    }

    const delete_texture = () => {
        set_add_texture_file(null);
        set_texture_url('');
        set_image_name('')
        set_return_texture_details('');
        if (set_base_texture_url){
            set_base_texture_url('');
        }
        // console.log("kppp propertyyy", property)
        property.final_value = '';
        if(property.value) property.value.value = ''
        set_properties(JSON.parse(JSON.stringify(properties)))
        if(parsed_properties && set_parsed_properties){
            parsed_properties[property.label] = ''
            set_parsed_properties(JSON.parse(JSON.stringify(parsed_properties)))
        }
    }

    // useEffect(() => {
    //     console.log("Kpppp bump_map texture_url", texture_url)
    // }, [texture_url])

    // useEffect(() => {
    //     console.log("Kpppp bump_map return_texture_details", return_texture_details)
    // }, [return_texture_details])

    useEffect(() => {
        if(add_texture_file){
            // console.log("KKppppp bump_map return textuer culprit 1")
            uplaod_file()
        }
    },[add_texture_file])

    useEffect(() => {
        if(property && !return_texture_details){set_return_texture_details(property.final_value ? property.final_value:'')}
        // console.log("set_return_texture_details 1")
    },[default_val, property])

    useEffect(() => {
        if(return_texture_details){
            property.final_value=return_texture_details
            if(property && property.value) property.value.value = return_texture_details;
            get_texture_url(return_texture_details)
            set_properties(properties)
            if(parsed_properties && set_parsed_properties){
                parsed_properties[property.label] = return_texture_details
                set_parsed_properties(JSON.parse(JSON.stringify(parsed_properties)))
            }
        }
        
    },[return_texture_details])

    return(
        <div >
            <Row style={{display:"flex", alignItems:'center', justifyContent:'space-between', marginTop:compact_style ? 0 : "10px", pointerEvents:disabled_input?'none':'auto'}}>
                {compact_style ? '' : <Col xs={input_size=='small'?5:3}><label className="label_margin_5px" style={{textTransform:"capitalize", fontSize:'12px'}}>{text_name?text_name:''}</label></Col>}
                <Col xs={compact_style ? 12 : input_size=='small'?7:9} style={{textAlign:"left", fontSize:'14px',marginTop:compact_style ? 0 : '10px'}}>
                    <UploadImage selectedImage={add_texture_file} setSelectedImage={set_add_texture_file} inputId={'image_preview'+property.value.label+add_id} isVisible={false}/>
                    {/* <Input id={"image_preview"} type="file" onChange={(e) => {set_add_texture_file(e.target.files[0]);e.stopPropagation();}} style={{display:"none"}} accept='.jpg,.jpeg,.png'/> */}
                    {/* {return_texture_details ?  */}
                    <div className='flex_property'>
                        {
                                <div>
                                    <Row style={{alignItems: 'center'}}>
                                    <img width={title?'64px':'32px'} height={title?'64px':'32px'} style = {{display:'flex', alignItems: 'center'}} src={return_texture_details && texture_url ? global.config.server_path.slice(0,-1)+texture_url :'/assets/img/icons/no_image.svg'} />
                                    {/* {return_texture_details?'':
                                    <div onClick={() => {document.getElementById("image_preview"+property.value.label).click()}} className='div_box' style={{cursor:'pointer',width:'100px', height:'32px', marginLeft:return_texture_details ? '5px' : '0px', alignItems:'center', borderRadius:'4px'}}>
                                            <i className='fa fa-upload' style={{ marginRight:'5px'}} /><span>Edit</span> 
                                    </div>} */}
                                    {
                                    <Col style={{paddingTop: '2px'}}>
                                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                                    {title?<span style={{fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 600, lineHeight: '16px', letterSpacing: '0em', color: '#676878'}}>{title}</span>:''}
                                        {(title || return_texture_details)?<div className='lines1_elipsis_limit_chars' style={{color:'#676878', fontFamily: 'DM Sans',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            lineHeight: '16px',
                                            letterSpacing: '0em',
                                            textAlign: 'left',
                                            marginTop: '11px'}}>{image_name}</div>:''}
                                        {
                                            (disabled_input || return_texture_details)?'':
                                            <div style={{paddingLeft: '4px'}}className='flex_property'>
                                                <div onClick={() => {set_add_texture_file(null); set_texture_url(''); set_image_name(''); set_return_texture_details(''); document.getElementById("image_preview"+property.value.label+add_id).click();}} className='div_box' style={{display: 'flex', cursor:'pointer',width:'85px', height:'24px', marginLeft:return_texture_details ? '5px' : '0px', alignItems:'center'}}>
                                                    <img src='/assets/icons/upload_icon.svg' style={{cursor: 'pointer', marginRight:'5px'}} />
                                                </div>
                                                {title?
                                                <div style={{fontFamily: 'DM Sans', fontSize: '11px', fontWeight: 400, lineHeight: '11px', paddingLeft: '6px', paddingTop: '2px', letterSpacing: '0em', textAlign: 'left', color: '#A7A8B3'}}>
                                                    .jpg, .png (size same as texture image)
                                                </div>:''}
                                            </div>
                                        }
                                    
                                    {/* {(disabled_input || return_texture_details)?'':
                                    <Col>
                                    <div style={{display: 'flex', fontFamily: 'DM Sans', fontSize: '9px', fontWeight: 400, lineHeight: '11px', paddingTop: '2px', letterSpacing: '0em', textAlign: 'left', color: '#C5C7CF'}}>
                                                    Requires .jpg, .png (Upload image with same size as texture image)
                                    </div>
                                    </Col>} */}
                                    </div></Col>}
                                    <div style={{display: 'flex', position:'absolute', right: title?'20px':'400px', marginTop: title?'30px':'10px'}}>
                                    {return_texture_details?
                                            <img src='/assets/icons/change.svg' className='mr-2' style={{cursor: 'pointer'}} onClick={() => {document.getElementById("image_preview"+property.value.label+add_id).click()}}/>
                                     :''}
                                     {return_texture_details?
                                            <img src='/assets/icons/remove.svg' className='mr-2' style={{cursor: 'pointer'}} onClick={() => {delete_texture()}}/>
                                    :''}
                                    </div>
                                    </Row>
                                </div>
                        }
                    </div>
                    {/* : (
                            // <FormGroup>
                            //     <UploadFile id="new_texture_file" set_file={set_add_texture_file} accept='.jpeg, .jpg,.png'/>
                            // </FormGroup>
                            
                            <div className='flex_property'>
                                {
                                    disabled_input?'':
                                    <div onClick={() => {document.getElementById("image_preview").click()}} className='div_box' style={{cursor:'pointer',width:'100px', height:'32px', alignItems:'center', borderRadius:'4px'}}>
                                        <i className='fa fa-upload' style={{ marginRight:'5px'}} /><span>Upload</span> 
                                    </div>
                                }
                            </div>

                        )} */}
                </Col>
                {/* <Col xs={4}>
                    {return_texture_details ? (
                        <label style={{fontSize:'14px'}}> 
                            { return_texture_details ? (
                                <div style={{display:"flex", justifyContent:"center", alignItems:"center", fontSize:'14px'}}>
                                    <div style={{padding:'3px 5px 3px 0px', fontSize:'14px'}}>
                                        Attached Texture ID : {return_texture_details}
                                    </div>
                                    <div>
                                        <Button onClick={() =>set_return_texture_details('')} style={{minWidth:"auto"}} className='red_button'>
                                            <i style={{color:"white", fontSize:"10px"}} className='fa fa-times'/>
                                        </Button>
                                    </div>
                                </div>
                            ) : "" }
                        </label>
                    ):''}
                </Col> */}
            </Row>
        </div>
    )
}


const Select_Structure = ({default_val, text_name, property, type, properties, set_properties, options, disabled_input, input_size, parsed_properties, set_parsed_properties, compact_style}) => {

    const [input_value , set_input_value] = useState(null);

    const validate_string = (value) =>{
        property.final_value=value;
        // if(property.useby==='blender'){
        set_input_value(value);
        // console.log("properties =====> ", properties)
        set_properties(properties);
        if(parsed_properties && set_parsed_properties){
            parsed_properties[property.label] = value
            set_parsed_properties(JSON.parse(JSON.stringify(parsed_properties)))
        }
        // }else{
        //     set_portal_properties(portal_properties)
        // }
    }

    useEffect(() => {
        set_input_value(property.final_value)
    },[default_val])

    return(
        <Row style={{display:"flex", alignItems:'center', marginTop:"10px"}}>
            <Col xs={compact_style ? 12 : input_size=='small'?5:3} style={{textTransform:"capitalize", fontSize:'12px'}}>{ text_name ? property.value.purpose === "distance" ? text_name + " (in mm)" : text_name : ""}</Col>
            <Col xs={compact_style ? 12 : input_size=='small'?7:9}>
                <Input type="select" style={{height:'32px', fontSize:'14px', width:input_size=='small'?'100%':'75%',pointerEvents:disabled_input?'none':'auto'}} value={input_value} onChange={e => validate_string(e.target.value)}>
                    {options.map((option, index) => (
                        <option key={index} value={option.value} selected={option.value === input_value}>{option.label}</option>
                    ))}
                </Input> 
                {/* <SingleSelect
                    options={options}
                    selectedValue = {input_value}
                    onItemClick = {(selectedValue) => validate_string(selectedValue.value)}
                    placeholder = {"Select"}
                    displayValue = {'label'}
                /> */}
            </Col>
        </Row>
    )
}


const Input_structure = ({default_val, text_name, property, type, properties, set_properties, portal_properties, set_portal_properties, disabled_input, input_size, set_roughness, set_metalness, set_color, set_specular, parsed_properties, set_parsed_properties, compact_style, ui_styling, select_options}) => {

    const [input_value , set_input_value] = useState(null);
    const [wrong_value_flag , set_wrong_value_flag] = useState(false);
    const [ value_less_than_min , set_value_less_than_min ] = useState(false);
    const [ value_exceeds_max , set_value_exceeds_max ] = useState(false);
    const alert = useAlert()

    const validate_string = (value) =>{
        if (type==='number'){
            value = Number(value)
        }
        set_input_value(value)
        property.final_value=value;
        if(property.label == 'roughness' && set_roughness){
            set_roughness(value)
        }
        if(property.label == 'metalness' && set_metalness){
            set_metalness(value)
        }
        if(property.label == 'color' && set_color){
            set_color(value)
        }
        if(property.label == 'specular' && set_specular){
            set_specular(value)
        }
        console.log("properties =====> ", properties)
        set_properties(properties)
        if(parsed_properties && set_parsed_properties){
            parsed_properties[property.label] = value
            set_parsed_properties(JSON.parse(JSON.stringify(parsed_properties)))
        }
    }

    useEffect(() => {
        if(type==='number'){
            if (input_value && property.value.min_value && (input_value < Number(property.value.min_value))){
                console.log('validation case 1 --> ', input_value, property.value.min_value)
                set_value_less_than_min(true)
            }else if (input_value && property.value.max_value && (input_value > Number(property.value.max_value))) {
                set_value_exceeds_max(true)
            }else if (property.value.max_value && property.value.min_value) {
                set_wrong_value_flag(true);
            }
        }else {
            set_wrong_value_flag(true)
        }
    },[input_value])

    useEffect(() => {
        console.log("property default", property)
        if(type==='number' && default_val!=null){
            set_input_value(property.final_value)
            set_wrong_value_flag(false)
        }
        if(type==='color'){
            set_input_value(property.final_value)
            set_wrong_value_flag(false)
        }
        if(property.label == 'roughness' && set_roughness){
            set_roughness(property.final_value)
        }
        if(property.label == 'metalness' && set_metalness){
            set_metalness(property.final_value)
        }
        if(property.label == 'specular' && set_specular){
            set_specular(property.final_value)
        }
        if(property.label == 'color' && typeof(property.final_value) == 'string' && set_color){
            set_color(property.final_value)
        }else if(property.label == 'color' && set_color){
            set_color('#000000')
        }
    },[default_val])

    return(
        <Row style={{marginTop:property.label == 'color' && compact_style? 0 : "10px"}}>
            {
                property.label == 'color' && compact_style ? '' : 
                
                <Col className='flex_property' xs={compact_style && (property.value.purpose === "distance" || property.label == 'color') ? 12 : input_size == 'small' ? 5 : 3} style={{ textTransform: "capitalize", fontSize: '12px' }}>{custom_data[property.label] && custom_data[property.label].name ? custom_data[property.label].name : text_name ? property.value.purpose === "distance" ? text_name + " (in mm)" : text_name : ""}
                    {
                        custom_data[property.label] && custom_data[property.label].tooltip ?
                            <TriggerTooltip title={custom_data[property.label].tooltip} />
                            : ""
                    }
                </Col>
            }
            
            <Col xs={compact_style && (property.value.purpose === "distance" || property.label == 'color') ? 12 : input_size=='small'?7:9}>
                {
                    type=='number'? 
                    property.label === "refractive_index" && parsed_properties.transmission === 0 ? <div style={{color: COLORS.gray5}}>{"To enable,  transmission must be > 0."}</div> :
                    <div className='flex_property' style={{width:input_size=='small'?'100%':'75%',}}>
                        {
                            ui_styling === "number_with_select_preview" ?
                            <Input type='select' value={input_value!=null ? Math.round(input_value * 1000) / 1000 : '' } style={{flex:5, fontSize: '12px', marginRight:'15px'}} onChange={(e) => set_input_value(Number(e.target.value))}>
                                {/* <option value={''}>Custom</option>
                                <option value={2}>Crystal</option>
                                <option value={1.5}>Glass</option>
                                <option value={1.309}>Ice</option>
                                <option value={1.325}>Water</option> */}
                                {
                                    select_options && select_options.length ? select_options.map((option, idx) => (
                                        <option value={option.value} key={idx}>{option.name}</option>
                                    ))
                                    :''
                                }
                            </Input>
                            : 
                            property && property.value && (property.value.min_value || property.value.min_value==0) && property.value.max_value ?
                            <div className='flex_property' style={{flexDirection:'column', flex:'5',marginRight:'15px', marginTop:'15px'}}>
                                <div className='flex_property' style={{width:'100%'}}>
                                    <div style={{borderRight:'2px solid #C5C7CF', height:'6px', borderRadius:'4px'}}/>
                                    <input 
                                        className='slider' type='range'
                                        min={type=='number'?property.value.min_value:null} 
                                        max={type=='number'?property.value.max_value:null} 
                                        step = {(type=='number' && property.value.max_value==1) ? '0.1' : '1'}
                                        value={input_value!=null?input_value:''} 
                                        onChange={e => {validate_string(e.target.value)}}
                                        style={{pointerEvents:disabled_input?'none':'auto'}}
                                    />
                                    <div style={{borderRight:'2px solid #C5C7CF', height:'6px', borderRadius:'4px'}}/>
                                </div>
                                <div style={{ fontSize:'12px', display:'flex', alignItems:'end', justifyContent:'space-between', width:'100%', marginTop:'4px'}}>
                                    <span>{property.value.min_value}</span>
                                    <span>{property.value.max_value}</span>
                                </div>
                            </div>:''
                        }
                        <Input 
                            disabled={disabled_input?true:false}
                            min={type=='number'?property.value.min_value:null} 
                            max={type=='number'?property.value.max_value:null} 
                            step = {(type=='number' && property.value.max_value==1) ? '0.1' : '1'}
                            type={type?type:'text'} value={input_value!=null?input_value:''} 
                            style={{fontSize:"12px", backgroundColor:"white", borderRadius:"4px", height:'32px', width: '120px',pointerEvents:disabled_input?'none':'auto'}} 
                            onChange={e => {(property && property.value && (property.value.min_value || property.value.min_value==0) && property.value.max_value)?(Number(e.target.value)<= property.value.max_value && Number(e.target.value) >= property.value.min_value ? validate_string(e.target.value) : alert.error("Please enter a number in the range")):validate_string(e.target.value)}}
                        />
                    </div>:
                    <Input 
                        disabled={disabled_input?true:false}
                        type={type?type:'text'} value={input_value!=null? (type === 'color' && typeof(input_value) === "object") ? rgbToHex(input_value) : input_value:''} 
                        style={{fontSize:"13px", backgroundColor:"white", borderRadius:"4px", height:'32px',width:input_size=='small'?'100%':'75%',pointerEvents:disabled_input?'none':'auto', minWidth: '80px'}} 
                        onChange={e => {validate_string(e.target.value)}}
                    />
                }
                {type==='number' && wrong_value_flag?<label style={{color:'red'}}>Please set value between  {property.value.min_value!=null?property.value.min_value:''} to  {property.value.max_value!=null?property.value.max_value:''} </label>:''}
                {type==='number' && value_less_than_min?<label style={{color:'red'}}>Value must exceed  {property.value.min_value!=null?property.value.min_value:''} </label>:''}
                {type==='number' && value_exceeds_max?<label style={{color:'red'}}>Value must be less than  {property.value.max_value!=null?property.value.max_value:''} </label>:''}
            </Col>
        </Row>
    )
}

const select_options_material_properties = {
    "refractive_index": [{name: 'Custom', value: ''},{name: 'Crystal', value: '2'},{name: 'Glass', value: '1.5'},{name: 'Ice', value: '1.309'},{name: 'Water', value: '1.325'}]
}

const Property_Tab = ({property, final_update_fn, properties, set_properties, portal_properties, set_portal_properties, set_page_loader, texture_json, disabled_input, input_size, set_base_texture_url, set_roughness, set_metalness, set_color, set_specular, parsed_properties, set_parsed_properties, compact_style, title, add_id='own'}) => {
    return(
        <div style={{flexGrow : compact_style && property.label == 'map' ? 1 : 0}}>
        {
            property.value.purpose === 'number' || property.value.purpose === 'distance' ? (
                <Row>
                    <Col>
                        <Input_structure 
                            default_val={property.final_value!=null?((typeof property.final_value == 'object') ? property.final_value.value.value : property.final_value) :""} 
                            text_name={property.value.label} 
                            property={property} 
                            type='number'
                            properties={properties} 
                            set_properties={set_properties}
                            portal_properties={portal_properties} 
                            set_portal_properties={set_portal_properties}
                            disabled_input = {disabled_input}
                            input_size = {input_size}
                            set_roughness={set_roughness}
                            set_metalness={set_metalness}
                            set_specular={set_specular}
                            parsed_properties={parsed_properties}
                            set_parsed_properties={set_parsed_properties}
                            compact_style={compact_style}
                            ui_styling={property.label === "refractive_index" ? "number_with_select_preview" : ""}
                            select_options={select_options_material_properties[property.label] ? select_options_material_properties[property.label] : []}
                        />
                    </Col>
                </Row>
            ) : ''
        }
        {
            property.value.purpose === 'image' && (property.label === 'map' || property.label === 'bump_map' || property.label === 'normal_map') ? (
                <Row>
                    <Col>
                        <Texture_structure 
                            add_id={add_id}
                            text_name={property.value.label} 
                            property={property} 
                            properties={properties} 
                            set_properties={set_properties}
                            portal_properties={portal_properties} 
                            set_portal_properties={set_portal_properties} 
                            set_page_loader={set_page_loader}
                            texture_json={texture_json}
                            final_update_fn={final_update_fn}
                            default_val={property.final_value!=null?(typeof property.final_value == 'object' ? property.final_value.value.value : property.final_value):""} 
                            disabled_input = {disabled_input}
                            input_size = {input_size}
                            set_base_texture_url = {set_base_texture_url}
                            parsed_properties={parsed_properties}
                            set_parsed_properties={set_parsed_properties}
                            compact_style={compact_style}
                            title={title}
                        />
                    </Col>
                </Row>
            ) : ''
        }
        {
            property.value.type === 'color' ? (
                <Row>
                    <Col>
                        <Input_structure 
                            default_val={property.value.value && typeof(property.value.value) === "string" ? property.value.value : ""} 
                            text_name={property.value.label} 
                            type='color' 
                            property={property} 
                            properties={properties} 
                            set_properties={set_properties}
                            portal_properties={portal_properties} 
                            set_portal_properties={set_portal_properties}
                            disabled_input = {disabled_input}
                            input_size = {input_size}
                            set_color={set_color}
                            parsed_properties={parsed_properties}
                            set_parsed_properties={set_parsed_properties}
                            compact_style={compact_style}
                        />
                    </Col>
                </Row>
            ) : ''
        }
        {
            property.value.type === 'string_enum' ? (
                <Row>
                    <Col>
                        <Select_Structure 
                            default_val={property.value.value?property.value.value:""} 
                            text_name={property.value.label} 
                            property={property} 
                            properties={properties} 
                            set_properties={set_properties}
                            portal_properties={portal_properties} 
                            set_portal_properties={set_portal_properties}
                            options={property.value.string_enum}
                            disabled_input = {disabled_input}
                            input_size = {input_size}
                            parsed_properties={parsed_properties}
                            set_parsed_properties={set_parsed_properties}
                            compact_style={compact_style}
                        />
                    </Col>
                </Row>
            ) : ''
        }
        </div>
    )
}

export default Property_Tab