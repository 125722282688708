import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Select from 'react-select';

const SelectFfAddons = ({store_details, get_store_details, set_active_tab, addon_id, options, add_on_feature_flag, set_add_on_feature_flag, updated}) => {
    const [selected_ff, set_selected_ff] = useState([])
    
    useEffect(() => {
        if(add_on_feature_flag)
            set_selected_ff(add_on_feature_flag[addon_id])
    }, [updated])

    useEffect(() => {
        add_on_feature_flag[addon_id] = selected_ff
    }, [selected_ff])

    return (
        <Select
            options={options}
            isMulti={true}
            value={selected_ff}
            onChange={(selectedList) => {set_selected_ff(selectedList)}}
            getOptionLabel={(option) => option.label}
            placeholder='Select feature flags'
        />
    )

}


const FeatureFlagAddonMap = ({store_details, get_store_details, set_active_tab, submit_for_delete_confirm, set_active_division_id_sidebar, send_analytics_event, set_page_loader}) => {
    const [add_on_feature_flag, set_add_on_feature_flag] = useState([])
    const [add_on, set_add_on] = useState({})
    const [all_ff, set_all_ff] = useState([])
    const [updated, set_updated] = useState(false)

    const tableStyle = {
        width: '100%',
        borderCollapse: 'collapse',
    };

    const thStyle = {
        backgroundColor: '#f2f2f2',
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'left',
    };
    
    const tdStyle = {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'left',
    };

    
    useEffect(() => {
        const getMapping = async () => {
            let resp = await general_fetch({ url: 'flagged_feature/get_all_add_on_map'})
            let addOns = resp.add_ons, mapping = resp.flaggged_feature_add_on_map
            set_add_on(addOns)
            const flaggedFeatures = Object.fromEntries(Object.entries(resp.flagged_features_map).filter(([key, value]) => value.type == 'infurnia_controlled'));
            // console.log("kp look here", typeof addOns, typeof flaggedFeatures, Array.isArray(mapping))
            let feature_flags = Object.keys(flaggedFeatures).map(item => flaggedFeatures[item])
            set_all_ff(feature_flags.map(item => {return {"value": item.id, "label": item.name} }))
            let addon_ff_map = {}
            Object.keys(addOns).forEach(item => addon_ff_map[item] = [])
            let temp = mapping.map(item => {
                let feat = flaggedFeatures[Object.keys(flaggedFeatures).find(x => flaggedFeatures[x].id == item.flagged_feature_id)];
                addon_ff_map[item.add_on_id].push({"value": feat.id, "label": feat.name})
                return item
            })
            set_add_on_feature_flag(addon_ff_map)
            // console.log("HEYYY")
            // console.log("kp look here", addon_ff_map)
        }

        getMapping()
    }, [updated])

    const update_addon = async (addon_id, ff_ids) => {
        let body = {'add_on_id': addon_id, 'flagged_feature_ids': ff_ids}
        let resp = await general_fetch({url: 'flagged_feature/update_add_to_flagged_feature_map', body: body})
    }

    const onclick_update = async () => {
        set_page_loader({
            show:true,
            text:'Saving changes'
        })
        for(let item in add_on_feature_flag){
            await update_addon(item, add_on_feature_flag[item].map(item => item.value))
        }
        set_updated(!updated)
        set_page_loader({
            show:false,
            text:''
        })
    }

    return (
        <Main_Grid active_tab="FeatureFlagAddonMap" store_details={store_details} get_store_details={get_store_details}>
            <Row style={{ padding: '20px 10px', color: '#22272e' }}>
                <Col xs={6} className='flex_property' style={{ fontFamily: 'Source_Sans_Pro-SemiBold', paddingLeft: '20px', fontSize: '14px' }}>
                    Addon/Feature flag mapping
                </Col>
            </Row>
            <Row style={{ height: 'calc(100% - 90px)' }}>
                <Col style={{ height: '100%' }}>
                    <Card style={{ borderRadius: 0, height: '100%', overflowY: 'scroll', padding: '20px'}}>
                        <React.Fragment>
                            <table style={tableStyle}>
                            <thead>
                                <tr>
                                <th style={thStyle}>Add-On</th>
                                <th style={thStyle}>Feature Flag</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(add_on_feature_flag).filter(item => add_on_feature_flag[item] && add_on_feature_flag[item].length > 0).map(item => (
                                        <tr>
                                            <td style={tdStyle}>{add_on[item]}</td>
                                            <td style={tdStyle}>
                                            <SelectFfAddons options={all_ff} addon_id={item} add_on_feature_flag={add_on_feature_flag} set_add_on_feature_flag={set_add_on_feature_flag} updated={updated}></SelectFfAddons>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            </table>
                            <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
								<span onClick={()=>{onclick_update()}} className='flex_property' style={{width:'100px', height:'32px', cursor:'pointer',  border:'1px solid #C5C7CF', borderRadius:'4px', backgroundColor:'#0078FF',justifyContent:'center', fontSize:'13px', color:'white', marginRight:'10px'}}>Save Changes</span>
							</div>
                        </React.Fragment>
                    </Card>
                </Col>
            </Row>

        </Main_Grid>
    )
}

export default FeatureFlagAddonMap;