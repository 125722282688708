import React , { useState , useEffect }from 'react';
import { Link } from 'react-router-dom';
import build_path from '../../../utils/path';
import general_fetch from '../../../utils/fetch';
import './sku_groups.css'
import { Input, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardTitle, CardText, CardHeader, CardFooter, CardImg, CardImgOverlay, Row, Col } from 'reactstrap';

const styles = {
	text_elipsis : {
		whiteSpace: "nowrap", 
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	padding_dropdown : {
		padding : '0px !important'
	},
	owned_style: {
		display: "unset",
		position: "absolute", 
		backgroundColor:"#000033", 
		color:'white',
		borderRadius:"3px",
		zIndex:250,
		top:65, 
		right:'20px',
		fontSize:'12px'
	},
};

const Group_ListView = ({ index, group_store_id, fetch_sku_groups, sku, title, link, Header_Element, edit_plus_action, inventory_item_id, SKU_group_name, user, SKU_Dropdown, Group_Dropdown, sku_group, group_business_unit_id }) => {
	
	const [ selected_sku_id , set_selected_sku_id ] = useState("");
	const [ selected_sku , set_selected_sku ] = useState("");
	const [dropdown_open, set_dropdown_open] = useState(false);
	const [group_dropdown_open, set_group_dropdown_open] = useState(false);

 	// const toggle = () => setDropdownOpen(prevState => !prevState);

	const header_plus_action = e => {
		console.log('clicked plus')
		e.stopPropagation();
		edit_plus_action(inventory_item_id);
	}

	useEffect(() => {
		set_selected_sku(sku[0]?sku[0]:'')
		set_selected_sku_id(sku[0]?sku[0].id:'')
	},[sku])

	useEffect(() => {
		if(selected_sku_id && sku ){
			var selected_sku_details = sku.filter((x) => x.id == selected_sku_id )[0]
			set_selected_sku(selected_sku_details);
		}
	},[selected_sku_id])

	return (
		<tr className='list_table_tr on_hover_card_border_list'>

			<td style={{flex:1, justifyContent: 'flex-start', paddingLeft:'20px'}} className='list_table_td'>
				<span style={{paddingRight:'20px'}}><i style={{color:'#B8BFCA', fontSize:"10px"}} className="fa fa-th" aria-hidden="true"/></span>
				<span style={{fontSize:'14px', color:'#22272E'}}>{index?index:''}.</span>
			</td>

			<td style={{flex:7}} className='list_table_td'>
				<span style={{flex:1,width:'100%', height:'31px', backgroundColor:'white'}}>	
					<img className='list_image_hover' style={{maxWidth:'100%', maxHeight:'100%'}} src=
						{
							selected_sku && selected_sku.display_pic && selected_sku.display_pic.image ? 
							(
								build_path(global.config.static_path , selected_sku.display_pic.image.replace('.png', '_thumbnail.png').replace('.jpg', '_thumbnail.jpg').replace('.jpeg', '_thumbnail.jpeg'))
							):( 	
								global.config.image_not_found
							)
						}
					/>
				</span>
				<span style={{fontSize:'14px', color:'#22272E', paddingLeft:'20px',textAlign:'left', flex:5}}>{title?title:''}</span>
			</td>

			<td style={{flex:6, justifyContent: 'flex-end', display:'flex', alignItems:'center'}} className='hover_1'>	
				<span style = {{flex:4}}>
					<Input style={{cursor:'pointer', color:'#435A6F'}} className='card_select' type="select" value = {selected_sku && selected_sku.id ? selected_sku.id :'' } onChange={e => set_selected_sku_id(e.target.value)}>
                        {
							sku && sku.length && sku.map((list_value, idx)=>
								<option key={idx} value={list_value.id}>{list_value.name}</option>
							)	
						}
                    </Input>
				</span>
				<div className = 'hover_2' style = {{flex:1}}>
					{
						sku && sku.length>0 ?
						( 
							<SKU_Dropdown 
								active_sku={selected_sku}
								dropdown_open={dropdown_open}
								set_dropdown_open={set_dropdown_open}
								sku_group_id={inventory_item_id}
								SKU_group_name={SKU_group_name}
							/>
						):(
							null
						)	
					}	
				</div>
				<div style = {{flex:2, width:'100%'}}>
				</div>
			</td>

			<td style={{flex:2, justifyContent: 'flex-end', display:'flex', alignItems:'center'}} className='hover_1'>
				<div  style = {{flex:2, width:'100%'}}>
				</div>
				<div className='hover_2' style = {{flex:1, paddingRight:'20px',textAlign:'right'}}>
					<Header_Element on_click={header_plus_action} />
				</div>
					
					
				<div className='hover_2' style = {{flex:1, display:'flex', justifyContent:'flex-end', paddingRight:'20px',}}>
					<Group_Dropdown
						active_sku_group={inventory_item_id}
						group_dropdown_open={group_dropdown_open}
						set_group_dropdown_open={set_group_dropdown_open}
						group_store_id={group_store_id}
						sku_group={sku_group}
						is_owned={group_business_unit_id == user.current_business_unit_id}
					/>
				</div>
					
						
			</td>
		</tr>
	);
	
};

Group_ListView.defaultProps = {
	Footer_Element: () => (<></>)
}

export default Group_ListView;

				
// {	
// 	group_store_id === user.store_id?(
// 		<div className='hover_2' style = {{flex:1, display:'flex', justifyContent:'flex-end', paddingRight:'20px',}}>
// 			<Group_Dropdown
// 				active_sku_group={inventory_item_id}
// 				group_dropdown_open={group_dropdown_open}
// 				set_group_dropdown_open={set_group_dropdown_open}
// 				group_store_id={group_store_id}
// 			/>
// 		</div>
// 	):("")
// }