import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import general_fetch from '../../utils/fetch';
import { Input, Card, CardTitle, CardText, CardHeader, CardFooter, CardImg, CardImgOverlay, Row, Col } from 'reactstrap';

const styles = {
	owned_style: {
		display: "unset",
		position: "absolute", 
		backgroundColor:"#000033", 
		color:'white',
		borderRadius:"3px",
		zIndex:250,
		top:10, 
		right:'20px',
		fontSize:'12px'
	},
	text_elipsis : {
		whiteSpace: "nowrap", 
		overflow: "hidden",
		textOverflow: "ellipsis",
		paddingTop: 10
	},
};
const CheckBox_Inventory_Card = ({ onclick_checked, item_store_id, user, image_src, title, inventory_item_id, inventory_type, onclick_afw_image_function,  item_business_unit_id}) => {

	const [ checked_value, set_checked_value ] = useState(false);
	const [ checked_value_flag, set_checked_value_flag ] = useState(false);

	const onclick_checkbox = () => {
		set_checked_value_flag(true)
		set_checked_value(!checked_value)
	}

	useEffect(() => {
		if(checked_value_flag){
			onclick_checked(inventory_item_id, inventory_type, checked_value)
			set_checked_value_flag(false)	
		}
	},[checked_value])

	try {
		const onclick_image_action = e => {
			console.log('clicked !!!')
			e.stopPropagation();
			if(inventory_type == "afw_categories"){
				onclick_afw_image_function(inventory_item_id,"afw_sub_categories");
			}
			if(inventory_type=="afw_sub_categories"){
				onclick_afw_image_function(inventory_item_id,"afw_groups");
			}
			if(inventory_type=="afw_groups"){
				onclick_afw_image_function(inventory_item_id,"afw_sku");
			}
		}

		return (
			<div>
				<Card className="shadow mb-4 inventory-card" >
						<div className="scale bg-secondary text-center" >
							{	
								// item_store_id == user.store_id?
								item_business_unit_id == user && user.current_business_unit_id ?
								(
									<label style = {styles.owned_style} className="label ng-scope" > OWNED </label>
								):("")
							}	
							<CardImg className="inventory-img " src={image_src} onClick = {(e) => onclick_image_action(e)} />
						</div>
					<CardFooter className="text-uppercase h4 inventory-footer">
						<Row> 
							<Col xs={9} style = {styles.text_elipsis}>
								{title}
							</Col>
				   			<Col xs={3}>
								<Input
									type='checkbox'
									color="primary"
									inputProps={{ 'aria-label': 'secondary checkbox' }}
									value={checked_value}
									onChange={onclick_checkbox}
					   			/>
							</Col>
						</Row>
					</CardFooter>
				</Card>
			</div>
		);
	} catch(err) {
		console.log('error in inventory card --->>>>>>>>>', err);
		return '';
	}
};


export default CheckBox_Inventory_Card;