import React , { useState, useEffect } from 'react';
import generic_sort from '../../utils/generic_sort';

const TableSortComponent = ({items, set_items, asc_order, set_asc_order, sort_by_property, property_type}) => {
	// const [asc_order, set_asc_order] = useState(false);

	const sort_items = (order) => {
		let temp_items = JSON.parse(JSON.stringify(items))
		temp_items.sort((a, b) => generic_sort(a, b, sort_by_property, order ? 1 : -1, property_type))
		set_items(temp_items)
		set_asc_order(order)
	}	

	// useEffect(() => {
	// 	console.log('asc_order', asc_order)
	// }, [asc_order]);

	return (
		<i className='fa fa-sort ml-1 cp' onClick={() => {sort_items(!asc_order)}}/>
	)
}

export default TableSortComponent