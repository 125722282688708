import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Multiselect from "../../components/Multiselect/";
import { primary_menu_options } from '../../components/Sidebar/menu_options';
import { useAlert } from 'react-alert'

const SubscribeDialogBox = ({open, room_types, fetch_roomTypes_details, handleClose}) => {
    
    const [selected_room_type, set_selected_room_type] = useState(null);
    const alert = useAlert()

    const onclick_handleClose = () => {
        set_selected_room_type(null);
        fetch_roomTypes_details();
        handleClose();
    }



    const subscribe_room_type = async() => {
        if(selected_room_type && selected_room_type != 'none'){
            try{
                console.log("entered", selected_room_type)
                var resp = await general_fetch({url: 'room_type/subscribe', body : {id: selected_room_type}})
                alert.success("successfully subscribed")
                onclick_handleClose();
            } catch(err) {
                alert.error("Request Failed")
                console.log(err)
            }
        }else {
            alert.error("Choose a Room Type to Subscribe")
        }
	}

    useEffect(() => {
        console.log("open subs", open)
    },[open])

    return (
        <div>
            <Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={onclick_handleClose}>
                <Card className='global_modal_height_for_xs'>
                    <CardHeader className='global_modal_header'>
                        <span>
                            Select a Room Type to Subscribe
                        </span>
                        <i onClick={onclick_handleClose} style={{cursor: 'pointer'}} className='fa fa-times'/>
                    </CardHeader>

                    <CardBody className='global_modal_body'> 
                        <Row style={{marginBottom:'15px'}}>
                            <Col>
                                <div>
                                    <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Room Type*</div>
                                    <Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Room Type" type="select" onChange={e => set_selected_room_type(e.target.value)}>
                                    <option value='none'>Choose a Room Type</option>
                                    {
                                        room_types && room_types.length
                                        ? room_types.map((single_room_type,idx) => single_room_type.global && !single_room_type.subscribed ? (<option key = {idx} value = {single_room_type.id}>{single_room_type.room_type}</option>) :'')
                                        :''
                                    }
                                    </Input>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className='global_modal_footer'>
                        <Button className='white_button' type="button" onClick={() => onclick_handleClose()}> Cancel </Button>
                        <Button className='blue_button' type="button" onClick={() => subscribe_room_type()}>Subscribe</Button>
                    </CardFooter>
                </Card>
            </Modal>
        </div>
    )
}

export default SubscribeDialogBox;