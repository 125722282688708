import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import { useAlert } from 'react-alert'

const ProjectEditModal = ({ open, handleClose, project, user, fetch_project_details }) => {

	const [ project_id , set_project_id ] = useState(null);
	const [ project_name , set_project_name ] = useState(null);
	const[open_alert_box , set_open_alert_box] = useState(false);
	const[current_state_alert , set_current_state_alert] = useState(null);
	const alert = useAlert()

	const validate_project_name = (project_name) => {
        var re = /^[\p{L}\s\d!@#$%^&*()_+={}\[\]|:;<>?,.~-]{1,200}$/u;
        return project_name.trim() && re.test(project_name)
    }

	const update_project_name = async () => {
		if(validate_project_name(project_name)){
			try {
				var identifiers = JSON.stringify({store_id : user.store_id , id:project_id})
				var updates = JSON.stringify({name : project_name})
				var body = {project_id: project_id, name:project_name}
				var response = await general_fetch({ url: 'project/update', body});
				alert.success('Project successfully updated')
				console.log(response)
				fetch_project_details();
				handleClose();
			} catch(err) { 
				alert.error('Error in updating project')
				console.log(err);
			}
		}else{
			if(!project_name){
				alert.error('Project name required')
			}else{
				alert.error('Enter a Valid Project Name')
			}
		}	
	}
	
	useEffect (() => {
		if(project) {
			set_project_id(project.id)
			set_project_name(project.name)
		}
	}, [ project ]);

	const onclick_handleclose = () => {
		handleClose()
		set_project_name('')
	}

	return (
		<Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={onclick_handleclose}>
			<Card className='global_modal_height_for_xs'>
				<CardHeader className='global_modal_header'>
							<span>Update Project Details - {project ? project.name : ''}</span>
							<i onClick={onclick_handleclose} style={{cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body'>
					<Row>
						<Col>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Project Name</div>
								<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Brand Name" type="text" value={project_name} onChange={e => set_project_name(e.target.value)}/>
							</div>
						</Col>	
					</Row>
				</CardBody>

				<CardFooter className='global_modal_footer'>
					<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>
					<Button className='blue_button' type="button" onClick={update_project_name}>Update</Button>
				</CardFooter>
			</Card>  
		</Modal>
	);
}

export default ProjectEditModal ; 
