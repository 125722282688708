import React, { useState, useRef } from 'react';
import { useAlert } from 'react-alert';
import { Button } from 'reactstrap';

const UploadImage = ({selectedImage, setSelectedImage, sizeLimit, pixelLimtX, pixelLimtY, showPreview, inputId, isVisible = true, isCustom = true, customName, customClass}) => {
//   const [selectedImage, setSelectedImage] = useState(null);
    const [input_key, set_input_key] = useState('upload_image');
    const alert = useAlert()

	const fileInputRef = useRef(null);
    
    const handleImageUpload = () => {
        const file = fileInputRef.current.files[0];
        // Check image file size)
        if(file){
			let limit = 5;
			if(sizeLimit){
				limit = sizeLimit
			}
			if (file.size > limit * 1024 * 1024) {
				alert.error(`Image size exceeds ${limit}MB. Please choose a smaller image.`);
				set_input_key(Date.now())
				return;
			}

			// Create an image object to get the dimensions
			const image = new Image();
			image.src = URL.createObjectURL(file);
			const maxWidth = 1400
    		const maxHeight = 1400;
			image.onload = () => {
				// Check image dimensions
				if (image.width > 1400 || image.height > 1400) {
					// alert.error('Image dimensions exceed 2000x2000. Please choose a smaller image.');
					// set_input_key(Date.now())
					// return;
					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');
					const aspectRatio = image.width / image.height;
			
					let newWidth = image.width;
					let newHeight = image.height;
			
					if (newWidth > maxWidth) {
					newWidth = maxWidth;
					newHeight = newWidth / aspectRatio;
					}
			
					if (newHeight > maxHeight) {
					newHeight = maxHeight;
					newWidth = newHeight * aspectRatio;
					}
			
					canvas.width = newWidth;
					canvas.height = newHeight;
					ctx.drawImage(image, 0, 0, newWidth, newHeight);
			
					canvas.toBlob((blob) => {
					const resizedFile = new File([blob], file.name, { type: 'image/jpeg' });
					console.log(file, resizedFile, image.width, image.height, newWidth, newHeight)
					setSelectedImage(resizedFile);
					}, 'image/jpeg');
				}else{
					setSelectedImage(file)
				}
				fileInputRef.current.value = null;
			};

			// Read the selected image file
			// const reader = new FileReader();
			// reader.onload = () => {
			// 	image.src = reader.result;
			// };
			// reader.readAsDataURL(file);
		}
    };

    const onclick_upload_button = () => {
		document.getElementById(inputId).click();
		// document.getElementById(id).value = null
		// console.log("filessss",document.getElementById(id).files)
	}

    return (
        <div style={{display: isVisible ? 'block' : 'none'}}>
            <div style={{display: !isCustom ? 'flex' : 'none'}}>
                <input key={input_key} id={inputId ? inputId : ''} type="file" ref={fileInputRef} accept=".jpeg, .jpg,.png" onChange={handleImageUpload} />
                {selectedImage && showPreview && (
                <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected Image"
                    style={{ width: '30px', marginTop: '10px' }}
                />
                )}
            </div>
            {
                isCustom ?
                    <Button className={ customClass ? customClass : "blue_button" } onClick = {onclick_upload_button}>{customName?customName:'Select file'}</Button>
                :''
            }
        </div>
    );
};

export default UploadImage;
