import React, { useState, useEffect, useContext } from 'react';
import { Button, CardFooter, CardHeader, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { UserContext } from '../../components/Contexts/UserContext';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { useAlert } from 'react-alert'


import general_fetch from '../../utils/fetch';
import { get_sku_ancestry } from '../../utils/api/inventory';
import { handleError } from '../../utils/error';

const SkuSelector = ({ possible_category_types, all_division_tree_data, is_open, toggle, set_page_loader, set_value, set_display_name, initial_sku}) => {
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)
    const [categories, set_categories] = useState([]);
    const [sub_categories, set_sub_categories] = useState([]);
    const [groups, set_groups] = useState([]);
    const [skus, set_skus] = useState([]);
    const [selected_category, set_selected_category] = useState();
    const [selected_sub_category, set_selected_sub_category] = useState();
    const [selected_group, set_selected_group] = useState();
    const [selected_sku, set_selected_sku] = useState();
    const [selected_division, set_selected_division] = useState();
    const [init, set_init] = useState(false);
    const alert = useAlert()

    // useEffect(() => {
    //     console.log("kpp selected sub cat is", selected_sub_category, sub_categories);
    // }, [selected_sub_category])

    // useEffect(() => {
    //     console.log("Kpp here ", all_division_tree_data)
    // }, [all_division_tree_data])

    useEffect(() => {
        // console.log("hi kppp", initial_sku)
        if(!init && all_division_tree_data && all_division_tree_data.length){
            // console.log("kpp, you are in", initial_sku)
            set_init(true);
            initializeSkuSelector()
        }
    }, [is_open, all_division_tree_data])

    // useEffect(() => {
    //     console.log("Kpp cats", categories, sub_categories, groups)
    // }, [categories, sub_categories, groups])

    const initializeSkuSelector = async() => {
        // console.log("kpp initial_sku is", initial_sku)
            if(initial_sku){
                let resp = await get_sku_ancestry(initial_sku);
                // console.log("Kppp resp is", resp)
                if(resp && resp.length){
                    resp = resp[0]
                    set_selected_division(resp && resp.sku_division_id)
                    let division = all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.find(o => o.id == resp.sku_division_id) : ''
                    set_categories(division ? division.sku_category : [])
                    set_selected_category(resp.sku_category_data && resp.sku_category_data.id)

                    // console.log("kpp division is", all_division_tree_data, "11", resp && resp.sku_division_id, division, "11",  resp)
                    let category = division && division.sku_category && division.sku_category.length && division.sku_category.find(o => o.id == (resp.sku_category_data && !resp.sku_category_data.deprecated && resp.sku_category_data.id))
                    // console.log("kpp while setting", category)
                    set_sub_categories(category ? category.sku_sub_category : [])
                    set_selected_sub_category(resp.sku_sub_category_data && resp.sku_sub_category_data.id)


                    let groups = await get_groups(resp.sku_sub_category_data && resp.sku_sub_category_data.id)
                    set_selected_group(resp.sku_group_data && resp.sku_group_data.id)

                    let group = groups && groups.length ? groups.find(o => o.id === (resp.sku_group_data && resp.sku_group_data.id)) : ''
                    set_skus(group && group.sku ? group.sku : [])
                    set_selected_sku(initial_sku)
                    set_display_name(group && group.sku && group.sku.length && group.sku.find(x => x.id == initial_sku) && group.sku.find(x => x.id == initial_sku).name)
                }
            }
    }

    const submit = () => {
        if (selected_sku) {
            set_value(selected_sku)
            let t = skus.find(x => x.id == selected_sku)
            set_display_name(t ? t.name : '')
            toggle()
        } else {
            alert.error("select a valid sku")
            console.error("select a valid sku")
        }
    }

    function getLastPartOfString(str) {
        const parts = str.split('_');
        return parts.pop();
      }
      

    const set_division_categories_fn = (category_id) => {
        let division_id = getLastPartOfString(category_id)
        let division = all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.find(o => o.id == division_id) : ''
        let category = division && division.sku_category && division.sku_category.length ? division.sku_category.find(o => o.sku_category_type_id == category_id) : ''
        set_sub_categories(category ? category.sku_sub_category : [])
        set_groups([])
        set_skus([])

    }

    const set_categories_fn = (division_id, exact=false) => {
        let division = all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.find(o => o.id == division_id) : ''
        set_categories(division ? division.sku_category : [])
        if(!exact){
            set_sub_categories([])
            set_groups([])
            set_skus([])
        }
    }

    const set_sub_categories_fn = (category_id, exact=false) => {
        // if(possible_category_types){
        //     // set_division_categories_fn(category_id)
        // }
        // else{
            let category = categories && categories.length ? categories.find(o => o.id == category_id) : ''
            set_sub_categories(category ? category.sku_sub_category : [])
            if(!exact){
                set_groups([])
                set_skus([])
            }
        // }
    }

    const get_groups = async (subcat_id) => {
        if (subcat_id) {
            try {
                set_page_loader({
                    show: true,
                    text: 'fetching groups...'
                })
                let body = { sku_sub_category_id: subcat_id }
                if (org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
                let resp = await general_fetch({ url: 'inventory/get_groups', body })
                set_groups(resp)
                set_page_loader({
                    show: false
                })
                return resp
            } catch (err) {
                set_page_loader({
                    show: false
                })
                console.log(err)
            }
        } else {
            set_groups([])
            set_skus([])
            return []
        }
    }

    const set_skus_fn = async(group_id) => {
        let group = groups && groups.length ? groups.find(o => o.id === group_id) : ''
        console.log('skus set', group)
        set_skus(group && group.sku ? group.sku : [])
    }

    const convert_to_readable = (str) => {
        //snake case
        if (!str) return '';
        if (str.includes('_')) {
            return str.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
        }
        //camel case
        let words = str.match(/[A-Za-z][a-z]*/g) || [];
        return words.map(word => (word.charAt(0).toUpperCase() + word.substring(1))).join(" ");
    }

    const handle_close = () => {
        toggle();
        set_init(false)
        set_categories([])
        set_selected_category('')
        set_sub_categories([])
        set_selected_sub_category('')
        set_groups([])
        set_selected_group('')
        set_skus([])
        set_selected_sku('')
    }


    useEffect(() => {
        // console.log(possible_category_types, "possible category types")
        if (possible_category_types && is_open && init) {
            let categories_list  = []
            possible_category_types.map((category_type) => {
                let division_id = getLastPartOfString(category_type)
                let division = all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.find(o => o.id == division_id) : ''
                let category_list = division && division.sku_category && division.sku_category.length ? division.sku_category.filter(o => o.sku_category_type_id == category_type) : []
                categories_list = [...categories_list, ...category_list]
            })
            set_categories(categories_list)
        }
    }, [possible_category_types, all_division_tree_data, is_open, init]);

    return (
        <Modal isOpen={is_open} toggle={handle_close} className='modal-dialog-centered' size='lg'>
            {/* <ModalHeader toggle={handle_close}>Select Sku</ModalHeader> */}
            <CardHeader className='global_modal_header'>
				Select Sku
				<i style={{cursor:'pointer'}} className='fa fa-times' onClick={handle_close} />
			</CardHeader>
            <ModalBody>
                <div style={{ fontSize: '14px' }}>
                    <div className='flex_property' style={{}}>
                        {possible_category_types ? '' : <div style={{ flex: '3' }}>Division</div>}
                        <div style={{ flex: '3' }}>Category</div>
                        <div style={{ flex: '3' }}>Sub Category</div>
                        <div style={{ flex: '3' }}>Group</div>
                        <div style={{ flex: '3' }}>Sku</div>
                        {/* <div style={{flex:'2'}}></div> */}
                    </div>
                    <div className='flex_property' style={{}}>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '12px', width: '100%' }}>
                            {
                                !possible_category_types ?
                                    <div style={{ flex: '3' }}>
                                        <Input type='select' value={selected_division} onChange={(e) => { set_selected_division(e.target.value); set_categories_fn(e.target.value); set_selected_category(''); set_selected_sub_category(''); set_selected_group(''); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                            <option value=''>Select Division</option>
                                            {
                                                all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.map((division, idx) => (
                                                    <option value={division.id}>{convert_to_readable(division.id)}</option>
                                                )) : ''
                                            }
                                        </Input>
                                    </div>
                                    : ''
                            }
                            <div style={{ flex: '3' }}>
                                <Input type='select' value={selected_category} onChange={(e) => { set_selected_category(e.target.value); set_sub_categories_fn(e.target.value); set_selected_sub_category(''); set_selected_group(''); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                    <option value=''>Select Category</option>
                                    {/* {
                                        !possible_category_types ? categories && categories.length ? categories.map((single_category, idx) => (
                                            <option value={single_category.id}>{single_category.name}</option>
                                        )) : ""
                                        :
                                        possible_category_types && possible_category_types.length ? possible_category_types.map((single_category, idx) => (
                                            <option value={single_category.id}>{single_category.name}</option>
                                        )) : ""
                                    } */}
                                    {
                                        categories && categories.length ? categories.map((single_category, idx) => (
                                            <option value={single_category.id}>{single_category.name}</option>
                                        ))
                                        :''
                                    }
                                </Input>
                            </div>
                            <div style={{ flex: '3' }}>
                                <Input type='select' value={selected_sub_category} onChange={(e) => { set_selected_sub_category(e.target.value); get_groups(e.target.value); set_selected_group(''); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                    <option value=''>Select Sub Category</option>
                                    {
                                        sub_categories && sub_categories.length ? sub_categories.map((single_sub_category, idx) => (
                                            <option value={single_sub_category.id}>{single_sub_category.name}</option>
                                        )) : ""
                                    }
                                </Input>
                            </div>
                            <div style={{ flex: '3' }}>
                                <Input type='select' value={selected_group} onChange={(e) => { set_selected_group(e.target.value); set_skus_fn(e.target.value); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                    <option value=''>Select Group</option>
                                    {
                                        groups && groups.length ? groups.map((single_group, idx) => (
                                            <option value={single_group.id}>{single_group.name}</option>
                                        )) : ""
                                    }
                                </Input>
                            </div>
                            <div style={{ flex: '3' }}>
                                <Input type='select' value={selected_sku} onChange={(e) => set_selected_sku(e.target.value)} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                    <option value=''>Select Sku</option>
                                    {
                                        skus && skus.length ? skus.map((single_sku, idx) => (
                                            <option value={single_sku.id}>{single_sku.name}</option>
                                        )) : ""
                                    }
                                </Input>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
            {/* <ModalFooter>
                <Button onClick={submit}>Submit</Button>
            </ModalFooter> */}
            <CardFooter className='global_modal_footer'>
				<Button className='blue_button' onClick={submit} >Submit</Button>
			</CardFooter>
        </Modal>
    )
}

export default SkuSelector