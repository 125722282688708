import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Multiselect from "../../components/Multiselect/";
import { primary_menu_options } from '../../components/Sidebar/menu_options';
import { useAlert } from 'react-alert'
import * as Sentry from "@sentry/react";
import { act } from 'react-dom/test-utils';
import BusinessUnits from '.';
import { UncontrolledCollapse } from '../../components/ICollpsable';
import { is_valid_file_type } from '../../utils/is_valid_file';
// import Select from 'react-select';

const BusinessUnitWarning = ({open, handleClose, add_business_unit, update_business_unit, mode, close_business_unit_modal}) => {
    
    const onClick_ok = () => {
        if(mode == 'add'){
            add_business_unit()
        }else if(mode == 'edit'){
            update_business_unit()
        }
        handleClose()
    }
    
    return (
        <div>
            <Modal style ={{marginTop:'150px', height:'150px', width:'360px'}} size="sm" isOpen={open}>
                <Card>
                    <div style = {{backgroundColor:'#F5F6F8', overFlow:'auto', padding:'5%'}}> 
                        <div style = {{paddingLeft:'11%', paddingDown:'10%'}}>Are you sure you want to continue?</div>
                        <div style={{paddingLeft:'21%', paddingTop:'5%'}}>
                            <Button className='white_button' type="button" onClick={() => handleClose()}>Cancel</Button>
                            <Button className='blue_button' type="button" onClick={() => {onClick_ok()}}>OK</Button>
                        </div>
                    </div>
                </Card>
            </Modal>
        </div>
    )
} 

const BusinessUnitDialogBox = ({open, fetch_business_units, handleClose, mode, business_units, idx, submit_for_delete_confirm, active_business_unit, set_active_business_unit, all_users, user_json}) => {

    const [business_unit_name, set_business_unit_name] = useState('');
    const [business_unit_id, set_business_unit_id] = useState('');
    const [business_unit_logo, set_business_unit_logo] = useState(null);
    const [business_unit_logo_url, set_business_unit_logo_url] = useState('');
    const [mapped_users_designers, set_mapped_users_designers] = useState([])
    const [mapped_users_catalog_managers, set_mapped_users_catalog_managers] = useState([])
    const [mapped_users_accounts_managers, set_mapped_users_accounts_managers] = useState([])
    const [mapped_users_project_managers, set_mapped_users_project_managers] = useState([])
    const [show_warning, set_show_warning] = useState();
    const alert = useAlert()

    const add_business_unit = async() => {
        try{
            let formdata = new FormData();
            formdata.append('name', business_unit_name)
            console.log('formdata', formdata, business_unit_name)
            formdata.append('upl', business_unit_logo)
            // let user_ids = mapped_users && mapped_users.length ? mapped_users.map(x => x.id):""
            let user_role_map = {} 
            all_users && all_users.length && all_users.map(user => {user_role_map[user.id] = {user_id: user.id, role_ids: []}})
            let d = mapped_users_designers && mapped_users_designers.length ? mapped_users_designers.map(user => {
                if(user_role_map && user_role_map[user.id]){
                    user_role_map[user.id].role_ids.push('designer')
                }
            }) : ''
            let p = mapped_users_project_managers && mapped_users_project_managers.length ? mapped_users_project_managers.map(user => {
                if(user_role_map && user_role_map[user.id]){
                    user_role_map[user.id].role_ids.push('project_manager')
                }
            }) : ''
            let c = mapped_users_catalog_managers && mapped_users_catalog_managers.length ? mapped_users_catalog_managers.map(user => {
                if(user_role_map && user_role_map[user.id]){
                    user_role_map[user.id].role_ids.push('catalog_manager')
                }
            }) : ''
            let a = mapped_users_accounts_managers && mapped_users_accounts_managers.length ? mapped_users_accounts_managers.map(user => {
                if(user_role_map && user_role_map[user.id]){
                    user_role_map[user.id].role_ids.push('accounts_manager')
                }
            }) : ''
            let user_details = []
            let u = user_role_map && Object.keys(user_role_map) && Object.keys(user_role_map).length ? Object.keys(user_role_map).map(user_id => {
                if(user_role_map[user_id].role_ids && user_role_map[user_id].role_ids.length){
                    user_details.push(user_role_map[user_id])
                }
            }) : ''
            console.log(user_details, 'user details')
            formdata.append('user_id_and_roles', JSON.stringify(user_details))
            
            let resp = await general_fetch({url:'business_unit/create', body: formdata, is_form_data:true})
            fetch_business_units()
            onclick_handleClose()
            alert.success("Business Unit Added Successfully !")
        }catch(err){
            Sentry.captureException(err)
            console.log(err)
            alert.error("Business Unit Addition Failed !")
        }
    }   
    
    const update_business_unit = async() => {
        try{
            let formdata = new FormData();
            formdata.append('id', business_unit_id)
            formdata.append('name', business_unit_name)
            console.log('formdata', formdata, business_unit_name)
            if(business_unit_logo && !is_valid_file_type(business_unit_logo, ['jpeg','jpg', 'png'])){
				alert.error("Invalid file format! Please upload a file with a valid format(jpeg, jpg, png).")
				return
			}
            if(business_unit_logo) formdata.append('upl', business_unit_logo)
            let user_role_map = {} 
            all_users && all_users.length && all_users.map(user => {user_role_map[user.id] = {user_id: user.id, role_ids: []}})
            let d = mapped_users_designers && mapped_users_designers.length ? mapped_users_designers.map(user => {
                if(user_role_map && user_role_map[user.id]){
                    user_role_map[user.id].role_ids.push('designer')
                }
            }) : ''
            let p = mapped_users_project_managers && mapped_users_project_managers.length ? mapped_users_project_managers.map(user => {
                if(user_role_map && user_role_map[user.id]){
                    user_role_map[user.id].role_ids.push('project_manager')
                }
            }) : ''
            let c = mapped_users_catalog_managers && mapped_users_catalog_managers.length ? mapped_users_catalog_managers.map(user => {
                if(user_role_map && user_role_map[user.id]){
                    user_role_map[user.id].role_ids.push('catalog_manager')
                }
            }) : ''
            let a = mapped_users_accounts_managers && mapped_users_accounts_managers.length ? mapped_users_accounts_managers.map(user => {
                if(user_role_map && user_role_map[user.id]){
                    user_role_map[user.id].role_ids.push('accounts_manager')
                }
            }) : ''
            let user_details = []
            let u = user_role_map && Object.keys(user_role_map) && Object.keys(user_role_map).length ? Object.keys(user_role_map).map(user_id => {
                if(user_role_map[user_id].role_ids && user_role_map[user_id].role_ids.length){
                    user_details.push(user_role_map[user_id])
                }
            }) : ''
            console.log(user_details, 'user details')
            formdata.append('user_id_and_roles', JSON.stringify(user_details))
            // let user_ids = mapped_users && mapped_users.length ? mapped_users.map(x => x.id):""
            // formdata.append('user_ids', JSON.stringify(user_ids))
            
            let resp = await general_fetch({url:'business_unit/update', body: formdata, is_form_data:true})
            fetch_business_units()
            onclick_handleClose()
            alert.success("Business Unit Updated Successfully !")
        }catch(err){
            alert.error("Business Unit Updation Failed !")
            Sentry.captureException(err)
            console.log(err)
        }
    }

    const check_all_fields = () => {
        if(mode == 'add' && business_unit_name){
            set_show_warning(true)
        }else if(mode == 'edit' && business_unit_name){
            set_show_warning(true)
        }else{
            alert.info("Please fill all the required details ")
        }
    }

    const onclick_handleClose = () => {
        set_business_unit_name('')
        set_business_unit_id('')
        set_business_unit_logo(null)
        set_business_unit_logo_url('')
        set_mapped_users_designers([])
        set_mapped_users_project_managers([])
        set_mapped_users_catalog_managers([])
        set_mapped_users_accounts_managers([])
        set_active_business_unit('')
        handleClose()
    }

    useEffect(() => {
        if(open && active_business_unit && user_json){
            set_business_unit_name(active_business_unit.name)
            set_business_unit_id(active_business_unit.id)
            set_business_unit_logo_url(active_business_unit.logo_path)
            let business_unit_user_details = active_business_unit.user_details
            if(business_unit_user_details && business_unit_user_details.length){
                let temp_designers = [], temp_project_managers = [], temp_catalog_managers = [], temp_accounts_managers = []
                business_unit_user_details.map(user_map => {
                    if(user_map && user_map.role_ids && user_map.role_ids.length){
                        if(user_map.role_ids.includes('designer')){
                            temp_designers.push({id: user_map.user_id})
                        }
                        if(user_map.role_ids.includes('project_manager')){
                            temp_project_managers.push({id: user_map.user_id})
                        }
                        if(user_map.role_ids.includes('catalog_manager')){
                            temp_catalog_managers.push({id: user_map.user_id})
                        }
                        if(user_map.role_ids.includes('accounts_manager')){
                            temp_accounts_managers.push({id: user_map.user_id})
                        }
                    }
                })
                console.log('temp ', temp_designers, temp_accounts_managers, temp_catalog_managers, temp_project_managers, business_unit_user_details)
                set_mapped_users_designers(temp_designers)
                set_mapped_users_project_managers(temp_project_managers)
                set_mapped_users_catalog_managers(temp_catalog_managers)
                set_mapped_users_accounts_managers(temp_accounts_managers)
            }
        }
    }, [open, active_business_unit, user_json]);
    
    
    return(
        <Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onclick_handleClose}>
            <BusinessUnitWarning open={show_warning} handleClose={() => {set_show_warning(false)}} add_business_unit={add_business_unit} update_business_unit={update_business_unit} mode={mode} close_business_unit_modal={onclick_handleClose}/>
            <Card>
                <CardHeader className='global_modal_header'>
                    <div style={{width:'100%', display:'flex'}}>
                        <div>{mode=='add'?'Enter Business Unit Details':(mode=='view'? 'Viewing Business Unit -'+'\xa0': 'Editing Business Unit -'+'\xa0')}</div>
                        <div title={mode=='add' ? '' : active_business_unit ?active_business_unit.name:''} className='text_ellipsis' style={{width:'calc(100% - 200px)'}}>{mode=='add' ? '' : active_business_unit ?active_business_unit.name:''}</div>
                    </div>
                    <i onClick={onclick_handleClose} style={{cursor: 'pointer'}} className='fa fa-times'/>
                </CardHeader>
                <CardBody style={{height: '600px', overflow: 'auto'}}>
                    <Col>
                        <div>
                            <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Business Unit Name*</div>
                            <Input disabled={mode=='view'?true:false} style={{width:'100%',height:'32px', opacity:mode=='view'?'0.5':'1', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '2px', marginBottom:'10px'}} placeholder="Name" type="text" value={business_unit_name} onChange={e => set_business_unit_name(e.target.value)}/>
                        </div>
                    </Col>	
                    <Col>
                        <UncontrolledCollapse className='mb-3' header={"Desinger"}>
                            <div>
                                <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Business Unit Users*</div>
                                <Multiselect
                                    options={all_users}
                                    selectedValues={mapped_users_designers}
                                    onSelect={(selectedList) => set_mapped_users_designers(selectedList)}
                                    onRemove={(selectedList) => set_mapped_users_designers(selectedList)}
                                    displayValue="name" // Property name 
                                    placeholder='Select User'
                                    disable={mode=='view'?true:false}
                                    style_options_container={{position: 'relative'}}
                                />
                            </div>
                        </UncontrolledCollapse>
                        <UncontrolledCollapse className='mb-3' header={"Project Manager"}>
                            <div>
                                <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Business Unit Users*</div>
                                <Multiselect
                                    options={all_users}
                                    selectedValues={mapped_users_project_managers}
                                    onSelect={(selectedList) => set_mapped_users_project_managers(selectedList)}
                                    onRemove={(selectedList) => set_mapped_users_project_managers(selectedList)}
                                    displayValue="name" // Property name 
                                    placeholder='Select User'
                                    disable={mode=='view'?true:false}
                                    style_options_container={{position: 'relative'}}
                                />
                            </div>
                        </UncontrolledCollapse>
                        <UncontrolledCollapse className='mb-3' header={"Catalogue Manager"}>
                            <div>
                                <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Business Unit Users*</div>
                                <Multiselect
                                    options={all_users}
                                    selectedValues={mapped_users_catalog_managers}
                                    onSelect={(selectedList) => set_mapped_users_catalog_managers(selectedList)}
                                    onRemove={(selectedList) => set_mapped_users_catalog_managers(selectedList)}
                                    displayValue="name" // Property name 
                                    placeholder='Select User'
                                    disable={mode=='view'?true:false}
                                    style_options_container={{position: 'relative'}}
                                />
                            </div>
                        </UncontrolledCollapse>
                        <UncontrolledCollapse className='mb-3' header={"Account Manager"}>
                            <div>
                                <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Business Unit Users*</div>
                                <Multiselect
                                    options={all_users}
                                    selectedValues={mapped_users_accounts_managers}
                                    onSelect={(selectedList) => set_mapped_users_accounts_managers(selectedList)}
                                    onRemove={(selectedList) => set_mapped_users_accounts_managers(selectedList)}
                                    displayValue="name" // Property name 
                                    placeholder='Select User'
                                    disable={mode=='view'?true:false}
                                    style_options_container={{position: 'relative'}}
                                />
                            </div>
                        </UncontrolledCollapse>
                    </Col>	
                    <Col>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'20px', flexDirection:'column'}}>
                            <Input disabled={mode=='view'?true:false} id={"image_preview"} type="file" onChange={(e) => {set_business_unit_logo(e.target.files[0]);e.stopPropagation();}} style={{display:"none"}} accept='.jpg,.jpeg,.png'/>
                            {
                                business_unit_logo?
                                <img src={URL.createObjectURL(business_unit_logo)} width='150px' height='100px' onClick={() => {document.getElementById("image_preview").click()}} style={{cursor:'pointer'}}></img>
                                :
                                ((mode=='edit' || mode=='view') && business_unit_logo_url?
                                <img src={global.config.server_path.slice(0,-1)+business_unit_logo_url} width='150px' height='100px' onClick={() => {document.getElementById("image_preview").click()}} style={{cursor:'pointer'}}/>
                                :
                                <div onClick={() => {document.getElementById("image_preview").click()}} style={{width:'150px', height:'100px', backgroundColor:'white', borderRadius:'4px', border:'1px dashed #A7A8B3', padding:'0px', display:'flex',flexDirection:'column', alignItems:'center', justifyContent:'center',cursor:'pointer', textAlign:'center'}}>
                                    <span style={{fontSize:'25px'}}>+</span>
                                    <span style={{fontSize:'13px'}}>JPG/PNG</span>
                                </div>)
                            }
                            <div style={{width:'150px', textAlign:'center', marginTop:'5px', fontSize:'13px'}}>Business Unit Logo*</div>
                        </div>
                    </Col>
                </CardBody>
                <CardFooter className='global_modal_footer'>
                    <Button className='white_button' type="button" style={{marginRight:mode=='view'?'5px':'12px'}} onClick={onclick_handleClose}>{mode=='view'?'Close':'Cancel'}</Button>  
                    {
                        mode == 'edit' ?
                        <Button className='blue_button' type="button" onClick={() => check_all_fields()}>Update</Button> : 
                        mode=='add'?<Button className='blue_button' type="button" onClick={() => check_all_fields(true)}>Add</Button>:''
                    }
                </CardFooter>
            </Card>
        </Modal>
    )

}

export default BusinessUnitDialogBox