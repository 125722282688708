
import React , { useState , useEffect, useContext }from 'react';
import {Input, CardBody, Col, Row, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useAlert } from 'react-alert'; 
import '../../views/Catalogue/SKU_Groups/sku_groups.css';
import Multiselect from '../Multiselect';
import custom_sort from '../../utils/custom_sort';
import general_fetch from '../../utils/fetch';
import { OrgBuContext } from '../Contexts/OrgBuContext';
import { UserContext } from '../Contexts/UserContext';
import { fetch_skus, fetch_sku_categories, fetch_sku_sub_categories} from '../../utils/api/inventory';
import debug_log from '../../utils/debug_log';
import { Tooltip } from 'antd';
import './style.css'
import { IModalBody, IModalHeader } from '../IModal/index.js';
import AddNewSKU from '../../views/Catalogue/SKU_Groups/AddNewSKU.js';
import { all_allowed_key } from '../AdditionalPropKeys/index.js';
import default_skus_json from '../../assets/core_jsons/default_skus.json';
import { handleError } from '../../utils/error.js';

const convertToJSON = (arrayJsons) => {
	const singleJson = {};
	for (const json of arrayJsons) {
		const { display_name, ...value } = json;
		singleJson[json.display_name] = value;
	}
	return singleJson
}

var sku_types = convertToJSON(default_skus_json)
// var sku_types = convertToJSON(JSON.parse(default_skus_json))

const sku_types_with_obj = JSON.parse(JSON.stringify(sku_types))

const all_finish_type = [
	{
		id:'internal_finish',
		name:'Internal Finish'
	},
	{
		id:'external_finish',
		name:'External Finish'
	},
	{
		id:'back_panel',
		name:'Back Panel Core Material'
	},
]

const all_type_shutter_hardware = [
	{
		id:'profile_with_handle',
		name:'Profile with handle'
	},
	{
		id:'profile',
		name:'Profile without handle '
	},
	{
		id:'glass',
		name:'Glass'
	},
]

const all_smart_cube_links = [
	{
		id:'3_sided_corner_joint',
		name:'3 sided corner joint',
		cat_type: 'fittings_hardware'
	},
	{
		id:'4_sided_corner_joint',
		name:'4 sided corner joint',
		cat_type: 'fittings_hardware',
	},
	{
		id:'profile_with_support_for_shelf',
		name:'Profile with support for shelf',
		cat_type: 'hanger_rod_hardware'
	},
	{
		id:'normal_profile',
		name:'Normal Profile',
		cat_type: 'hanger_rod_hardware'
	},
]

const smart_cube_category_type_validity = [ 'hanger_rod_hardware', 'fittings_hardware' ];

const new_types = [
	{
		id:'cam_subcomponent',
		name: 'Cam Subcomponent'
	},{
		id:'mounting_plate',
		name:'Mounting Plate'
	}
]

const edge_band_types = [
	{
		id: 'visible',
		name: 'Visible'
	},
	{
		id: 'invisible',
		name: 'Invisible'
	}
]

const all_linked_sku_types = [...all_smart_cube_links, ...new_types, ...all_type_shutter_hardware, ...all_finish_type, ...edge_band_types]

const AddNewOption = ({mode, material_template, selected_div_array, selected_cat_array, selected_sub_cat_array, selected_group_array, created_option, is_create_open, toggle_create, set_refresh_cat, set_refresh_grp, set_refresh_sub, set_refresh_sku, fetch_sku_groups, set_page_loader}) => {
	const [name, set_name] = useState('');
	const user = useContext(UserContext);
	const org_bu_view = useContext(OrgBuContext);
	const alert = useAlert();
	const [pre_selected_sku, set_pre_selected_sku] = useState('')
	const [border_animation_group, set_border_animation_group] = useState('')
	const add_new_component = async () => {
		if(name != ''){
			if(mode == 'category'){
				try {	
					let body = {'name': name, 'sku_category_type_id': 'edge_band_hardware'}
					if(org_bu_view == 'bu'){
						body['business_unit_id'] = user.current_business_unit_id
					}
					let resp = await general_fetch({ url: 'sku_category/create', body:body});
					alert.success('Category was successfully created')
					// created_option['name'] = 'cat'
					// created_option['id'] = resp.id;
					set_refresh_cat(resp)
				} catch(err) {
					alert.error('Failed to create Category')
					console.log(err);
				}
			}
			else if(mode == 'sub category'){
				try {
					let body = {'name':name, 'order':0, 'sku_category_id':selected_cat_array && selected_cat_array.length && selected_cat_array[0].id, 'list_view':false}
					if(org_bu_view == 'bu'){
						body['business_unit_id'] = user.current_business_unit_id;
					}
					let resp = await general_fetch({ url: 'sku_sub_category/create', body});
					alert.success('Sub Category Added Successfully')
					// created_option['name'] = 'sub'
					// created_option['id'] = resp.id;
					set_refresh_sub(resp)
				} catch(err) {
					alert.error('Failed to create Sub Category')
					console.log(err);
				}
			}
			else if(mode == 'group'){
				try {
					if(name.length > 200){
						console.log('Group name shouldnt exceed 200 charac')
						alert.error("Group name shouldn't exceed 200 characters")
						return
					}
					let body = { 'name': name, 'sku_sub_category_id':selected_sub_cat_array && selected_sub_cat_array.length && selected_sub_cat_array[0].id, order:0}
					if(org_bu_view == 'bu'){
						body['business_unit_id'] = user.current_business_unit_id
					}
					let resp = await general_fetch({ url: 'sku_group/create', body })
					alert.success('Group Added Successfully')
					// created_option['name'] = 'group'
					// created_option['id'] = resp.id;
					set_refresh_grp(resp)
				} catch(err) {
					alert.error('Failed to add Group')
					console.log(err);
				}
			}
			set_name('');
			toggle_create();
		}else{
			alert.error("Please Enter valid Name");
		}
	}

	const get_eligible_component_file_options = (sku_types_1 = sku_types) => {
		return Object.keys(sku_types_1).filter(x => ((sku_types_1[x].supported_category_types && sku_types_1[x].supported_category_types.includes('edge_band_hardware')) || (sku_types_1[x].unsupported_category_types && !sku_types_1[x].unsupported_category_types.includes('edge_band_hardware'))))
	}
	
	return(
		<React.Fragment>
			<Modal className='modal-dialog-centered' isOpen={is_create_open && mode!='sku'} size='md'>
				<ModalHeader style={{height:'60px', textTransform:'capitalize'}} toggle={toggle_create}>Add New {mode}</ModalHeader>
				<ModalBody style={{fontSize:'14px'}}>
					<div className='flex_property' style={{justifyContent: 'space-between'}}>
						<span style={{width:'120px' , display:'flex', alignItems:'center', textTransform:'capitalize'}}>{mode} Name</span>
						<Input style={{width:'280px'}} type='text' value={name} placeholder={mode + ' name'} onChange={(e) => set_name(e.target.value)} />
					</div>
				</ModalBody>
				<ModalFooter style={{justifyContent:'end', border:'0px'}}> 
					<Button style={{color:'white', border:'0px', padding:'8px 24px', borderRadius:'6px', fontSize:'14px', textTransform:'capitalize'}} onClick={add_new_component} className='blue_button'>Add</Button>
				</ModalFooter>
			</Modal>
			<AddNewSKU 
				open={is_create_open && mode=='sku'} 
				handleClose={toggle_create} 
				user={user}
				active_inventory_item={selected_group_array && selected_group_array.length && selected_group_array[0].id}
				fetch_sku_groups={fetch_sku_groups}
				active_division_id={selected_div_array && selected_div_array.length && selected_div_array[0].id}
				material_template={material_template}
				brands={[]} // needed
				set_page_loader={set_page_loader}
				sku_category_type={'edge_band_hardware'}
				all_allowed_key = {all_allowed_key}
				set_border_animation_group = {set_border_animation_group}
				set_pre_selected_sku = {set_pre_selected_sku}
				show_component_selection_dropdown={false}
				component_file_options = {get_eligible_component_file_options(sku_types_with_obj)}
				sku_types={sku_types_with_obj}
				set_refresh_sku={set_refresh_sku}
				add_id={'linked'}
			/>
		</React.Fragment>
	)
}

const Single_item = ({set_page_loader, styles, material_template, item, handle_close, handle_close_edit, handle_save, item_mode='select', set_item_mode, add_modal_open=false, idx, all_division_tree_data, item_json, set_item_json, set_remove_loader, already_selected_sku_groups,active_division_id, active_category_name,use_cat_type, is_valid_add_key}) => {

	// const [all_divisions ,set_all_divisions ] =useState([]);
	const styles_modals= {
		input_styles_none:{
			fontSize: '12px',
			textAlign: 'left',
			borderRadius:'0',
			width:'100%',
			borderRadius:'2px',
			border:'none',
			boxShadow:'none'
		}

	}

	const alert = useAlert()
	const [all_division ,set_all_division ] =useState([]);
	const [all_category ,set_all_category ] =useState([]);
	const [all_category_new, set_all_category_new] = useState([]); 
	const [all_sub_category ,set_all_sub_category ] =useState([]);
	const [all_sub_category_new ,set_all_sub_category_new ] =useState([]);
	const [all_groups ,set_all_groups ] =useState([]);
	const [all_skus ,set_all_skus ] =useState([]);
	const [refresh_cat, set_refresh_cat] = useState(false);
	const [refresh_sub, set_refresh_sub] = useState(false);
	const [refresh_grp, set_refresh_grp] = useState(false);
	const [refresh_sku, set_refresh_sku] = useState(false);
	const [selected_div_array ,set_selected_div_array ] =useState([]);
	const [selected_cat_array ,set_selected_cat_array ] =useState([]);
	const [selected_sub_cat_array ,set_selected_sub_cat_array ] =useState([]);
	const [selected_group_array ,set_selected_group_array ] =useState([]);
	const [selected_sku_array ,set_selected_sku_array ] =useState([]);
	const [selected_type_type_array ,set_selected_type_type_array ] =useState([]);	
	const [valid_types, set_valid_types] = useState(all_smart_cube_links);
	const [initialized ,set_initialized ] =useState(false);

	const [allow_edit ,set_allow_edit ] = useState(false);
	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)

	const [is_create_open, set_is_create_open] = useState(false);
	const [mode, set_mode] = useState('category');
	const [edit_mode, set_edit_mode] = useState(false);
	const [reset_json, set_reset_json] = useState({div: [], cat: [], sub: [], grp: [], sku: [], type: []})
	// const [created_option, set_created_option] = useState({name: 'none', id: 'none'})
	// useEffect(() => {
	// 	console.log("kpp all category", all_category_new);
	// 	console.log("kpp all sub-cat",all_sub_category_new);
	// 	console.log("kpp all group", all_groups);
	// })

	useEffect(() => {
        if(all_division_tree_data && all_division_tree_data.length && use_cat_type){
            console.log('all_division_tree_data======>',all_division_tree_data)
			if(use_cat_type==='panel_core_hardware'){
				set_all_division(all_division_tree_data.filter(x => ['finish','hardware'].includes(x.id)))
			}
			if(use_cat_type==='shutter_hardware' || use_cat_type == 'drawer_system_collection_hardware' || use_cat_type == 'basket_collection_hardware' || use_cat_type == 'smart_cube'){
				set_all_division(all_division_tree_data.filter(x => x.id==='hardware'))
			}
        }else if(all_division_tree_data){
			set_all_division(all_division_tree_data.filter(x => x.id==='hardware'))
		}
    },[all_division_tree_data, use_cat_type])

	const fetch_sku_groups = async (id) => {
		try {

			let body = { sku_sub_category_id:id }
            if(org_bu_view === 'bu'){
                body['business_unit_id'] = user.current_business_unit_id;
            }
			var response = await general_fetch({ url: 'inventory/get_groups' , body });
			var depricated_filter_output =response && response.length ?response.filter(x => x.depricated !=1):[]
			var resp = depricated_filter_output.length?depricated_filter_output.sort(custom_sort):[]
			set_all_groups(resp)
		} catch(err) {
			handleError(err)
		}
	}

	const setSkuCategories = async (id, cat_types) => {
		try{
			if(!id){
				set_all_category_new([])
				return;
			}
			let resp = await fetch_sku_categories(id);
			let filter_resp = resp && resp.length ? resp.filter(x => x.depricated != 1) : []
			set_all_category_new(filter_resp && filter_resp.length ? filter_resp.filter(item => !cat_types || (cat_types && cat_types.length && cat_types.includes(item.sku_category_type_id))) : [])
		} catch(err){
			handleError(err)
		}
	}

	const setSkuSubCategories = async(cat_id) => {
		try{
			if(!cat_id){
				set_all_sub_category_new([]);
				return;
			}
			let resp = await fetch_sku_sub_categories(cat_id);
			let filter_resp = resp && resp.length ? resp.filter(x => x.depricated != 1) : []
			set_all_sub_category_new(filter_resp)
		} catch(err) {
            handleError(err)
		}
	}

	useEffect(() => {
		if(item){
			// console.log("KPP set item is", item, all_skus, selected_sku_array);
			set_selected_div_array(item.division)
			set_selected_cat_array(item.category)
			set_selected_sub_cat_array(item.sub_cat)
			set_selected_group_array(item.group)
			set_selected_sku_array(item.sku)
			set_allow_edit(item.sku && item.sku.length?false:true)
			if(use_cat_type==='panel_core_hardware'){
				var x_item_list = all_finish_type.filter(x => (x.id===item.type) || (item.type && item.type.length && Array.isArray(item.type) && item.type.find(xitem => (xitem.id == x.id) && xitem.checked)))
				set_selected_type_type_array(x_item_list)
			}
			if(use_cat_type==='shutter_hardware'){
				var x_item_list = all_type_shutter_hardware.filter(x => (x.id===item.type) || (item.type && item.type.length && Array.isArray(item.type) && item.type.find(xitem => (xitem.id == x.id) && xitem.checked)))
				set_selected_type_type_array(x_item_list)
			}
			if (use_cat_type === 'smart_cube') {
				var x_item_list = all_smart_cube_links.filter(x => (x.id===item.type) || (item.type && item.type.length && Array.isArray(item.type) && item.type.find(xitem => (xitem.id == x.id) && xitem.checked)))
				set_selected_type_type_array(x_item_list)
			}
			if (!use_cat_type && active_division_id !== 'finish') {
				var x_item_list = new_types.filter(x => (x.id===item.type) || (item.type && item.type.length && Array.isArray(item.type) && item.type.find(xitem => (xitem.id == x.id) && xitem.checked)))
				set_selected_type_type_array(x_item_list)
			}

			if (!use_cat_type && active_division_id === 'finish') {
				// console.log("Kpppppppp item", idx, item)
				var x_item_list = edge_band_types.filter(x => (x.id===item.type) || (item.type && item.type.length && Array.isArray(item.type) && item.type.find(xitem => (xitem.id == x.id) && xitem.checked)))
				set_selected_type_type_array(x_item_list)
			}
		}        
    },[item])

	// useEffect(() => {
	// 	if(created_option.name != 'none'){
	// 		try{
	// 			let selectedList = []
	// 			if(created_option.name == 'cat'){
	// 				selectedList = all_category_new && all_category_new.length && all_category_new.filter(item => item.id == created_option.id)
	// 				onselect_multi_options('category', selectedList)
	// 			}
	// 			else if(created_option.name == 'sub'){
	// 				selectedList = all_sub_category_new && all_sub_category_new.length && all_sub_category_new.filter(item => item.id == created_option.id)
	// 				onselect_multi_options('sub_cat', selectedList)
	// 			}
	// 			else if(created_option.name == 'group'){
	// 				selectedList = all_groups && all_groups.length && all_groups.filter(item => item.id == created_option.id)
	// 				onselect_multi_options('group', selectedList)
	// 			}
	// 			else if(created_option.name == 'sku'){
	// 				selectedList = all_skus && all_skus.length && all_skus.filter(item => item.id == created_option.id)
	// 				onselect_multi_options('sku', selectedList)
	// 			}
	// 			if(!selectedList || selectedList.length == 0){
	// 				created_option.name = 'none';
	// 				created_option.id = 'none';
	// 			}
	// 		} catch(err){
	// 			handleError(err);
	// 			created_option.name = 'none';
	// 			created_option.id = 'none';
	// 		}
	// 	}
	// }, [created_option, all_category_new, all_sub_category_new, all_groups, all_skus])

	useEffect(() => {
		if(all_division && all_division.length && selected_div_array && selected_div_array.length ){
			console.log('Division execution started', use_cat_type)
			update_item_json(idx, 'division', selected_div_array)
			var x_id = selected_div_array.map(x => x.id)[0]
			if(active_division_id==='finish'){
				// let x_catlist = all_division && all_division.length?all_division.find(x => x.id===x_id).sku_category.filter(x => x.sku_category_type_id==="edge_band_hardware"):[]
				// x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				// set_all_category(x_catlist)
				let div_id = all_division && all_division.length?all_division.find(x => x.id===x_id).id:''
				// console.log("KPP reached get_categories call", div_id)
				setSkuCategories(div_id, ['edge_band_hardware'])

			}else if(use_cat_type==='shutter_hardware'){
				let x_catlist = all_division && all_division.length?all_division.find(x => x.id===x_id).sku_category.filter(x => ['handle_hardware', 'panel_core_hardware'].includes(x.sku_category_type_id)):[]
				x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				set_all_category(x_catlist)
			}else if(use_cat_type==='panel_core_hardware' && x_id==='hardware'){
				let x_catlist = all_division && all_division.length?all_division.find(x => x.id===x_id).sku_category.filter(x => ['panel_core_hardware'].includes(x.sku_category_type_id)):[]
				x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				set_all_category(x_catlist)
			}else if(use_cat_type==='drawer_system_collection_hardware' && x_id==='hardware'){
				let x_catlist = all_division && all_division.length?all_division[0].sku_category.filter(x => x.sku_category_type_id==="drawer_systems_hardware"):[]
				x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				console.log(all_category,all_division, x_catlist);
				set_all_category(x_catlist)
			}else if(use_cat_type==='basket_collection_hardware' && x_id==='hardware'){
				let x_catlist = all_division && all_division.length?all_division[0].sku_category.filter(x => x.sku_category_type_id==="basket_hardware"):[]
				x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				set_all_category(x_catlist)
			}else if(use_cat_type==='smart_cube' && x_id==='hardware'){
				let x_catlist = all_division && all_division.length?all_division[0].sku_category.filter(x => smart_cube_category_type_validity.includes(x.sku_category_type_id)):[]
				x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				set_all_category(x_catlist)
			}else{
				let x_catlist = all_division.find(x => x.id===x_id)?all_division.find(x => x.id===x_id).sku_category:[]
				x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				set_all_category(x_catlist)
			}
			console.log('Division execution ended')
		}
    },[all_division,selected_div_array,refresh_cat])

	useEffect(() => {
		if( all_category && all_category.length && selected_cat_array && selected_cat_array.length){
			console.log('Cat execution started')
			console.log('smart_cube', all_category, selected_cat_array, x_id)
			update_item_json(idx, 'category', selected_cat_array)
			var x_id = selected_cat_array.map(x => x.id)[0]

			let sub_cat_list = all_category.find(x => x.id===x_id) ?all_category.find(x => x.id===x_id).sku_sub_category:[]
			sub_cat_list = sub_cat_list && sub_cat_list.length ? sub_cat_list.filter(x => x.deprecated != 1) : []
			set_all_sub_category(sub_cat_list)
			if (use_cat_type == 'smart_cube' && all_category.find(x => x.id===x_id)) {
				set_valid_types(all_smart_cube_links.filter(x => x.cat_type ===  all_category.find(x => x.id===x_id).sku_category_type_id));
			}
		}      
    },[all_category,selected_cat_array])

	useEffect(() => {
		if(all_category_new && all_category_new.length && selected_cat_array && selected_cat_array.length){
			update_item_json(idx, 'category', selected_cat_array)
			var x_id = selected_cat_array.map(x => x.id)[0]
			setSkuSubCategories(x_id);
		}   
	}, [all_category_new, selected_cat_array, refresh_sub])

	useEffect(() => {
		if (initialized) {
			if(all_sub_category && all_sub_category.length && selected_sub_cat_array && selected_sub_cat_array.length){
				update_item_json(idx, 'sub_cat', selected_sub_cat_array)
				var x_id = selected_sub_cat_array.map(x => x.id)[0]
				if(x_id){
					fetch_sku_groups(x_id)
				}else{
					set_all_groups([])
				}
			} 
			else if(all_sub_category_new && all_sub_category_new.length && selected_sub_cat_array && selected_sub_cat_array.length){
				update_item_json(idx, 'sub_cat', selected_sub_cat_array)
				var x_id = selected_sub_cat_array.map(x => x.id)[0]
				if(x_id){
					fetch_sku_groups(x_id)
				}else{
					set_all_groups([])
				}
			}  
		} else {
			if (already_selected_sku_groups && selected_sub_cat_array && selected_sub_cat_array.length) {
				// var x_groups_list = Object.keys(already_selected_sku_groups) && Object.keys(already_selected_sku_groups).length ? Object.keys(already_selected_sku_groups).filter(x => {
				// 	if(x===selected_sub_cat_array[0].id){
				// 		console.log("MAJOR CONDITION SAT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
				// 		return already_selected_sku_groups[x]
				// 	}
				// }) :[] 
				let x_groups_list = already_selected_sku_groups?.[selected_sub_cat_array[0].id]??[];
				console.log("MAJOR LOG ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~", x_groups_list);
				set_all_groups(x_groups_list);
			}
		}
    },[already_selected_sku_groups, all_sub_category_new, all_sub_category, selected_sub_cat_array, refresh_grp, refresh_sku])

	useEffect(() => {
		if(all_groups && all_groups.length && selected_group_array && selected_group_array.length){
			update_item_json(idx, 'group', selected_group_array)
			var x_id = selected_group_array.map(x => x.id)[0]
			set_all_skus(all_groups.find(x => x.id===x_id)?all_groups.find(x => x.id===x_id).sku:[])
		}        
    },[all_groups, selected_group_array, refresh_sku])

	useEffect(() => {
		if(all_skus && all_skus.length && selected_sku_array && selected_sku_array.length){
			update_item_json(idx, 'sku', selected_sku_array)
			console.log('skuexecuted')
		}
		if(idx===item_json.length-1){
			if(selected_sku_array && selected_sku_array.length){
				set_remove_loader(true)
			}
		}
    },[selected_sku_array])

	const onselect_multi_options = (property, selectedList_1, no_reset=false) => {
		// update_item_json(idx,property, selectedList_1)
        if(property==='division'){
			set_selected_div_array(selectedList_1)
			update_item_json(idx, 'division', selectedList_1)
			if(!no_reset)
			reset_for_div('division')
		}else if(property==='category'){
			set_selected_cat_array(selectedList_1)
			update_item_json(idx, 'category', selectedList_1)
			if(!no_reset)
			reset_for_div('category')
		}else if(property==='sub_cat'){
			set_selected_sub_cat_array(selectedList_1)
			set_initialized(true);
			update_item_json(idx, 'sub_cat', selectedList_1)
			if(!no_reset)
			reset_for_div('sub_cat')
		}else if(property==='group'){
			set_selected_group_array(selectedList_1)
			update_item_json(idx, 'group', selectedList_1)
			if(!no_reset)
			reset_for_div('group')
		}else if(property==='sku'){
			set_selected_sku_array(selectedList_1)
			update_item_json(idx, 'sku', selectedList_1)
		}else if(property==='type'){
			set_selected_type_type_array(selectedList_1)
			update_item_json(idx, property, selectedList_1)
		}
	}

	const reset_for_div = (property) => {
		if(property==='division'){
			set_all_category([]);
			set_all_category_new([]);
			set_selected_cat_array([]);
			update_item_json(idx, 'category', [])
		}
		if(property==='category' || property==='division'){	
			set_all_sub_category([]);
			set_all_sub_category_new([]);
			set_selected_sub_cat_array([]);
			set_initialized(true);
			update_item_json(idx, 'sub_cat', [])
		}
		if(property==='sub_cat' || property==='category' || property==='division'){	
			set_all_groups([]);
			set_selected_group_array([]);
			update_item_json(idx, 'group', [])
		}
		if(property==='group' || property==='sub_cat' || property==='category' || property==='division'){	
			set_all_skus([]);
			set_selected_sku_array([]);
			update_item_json(idx, 'sku', [])
		}
	}

	const delete_item_json = (index) => {
		let a = [...item_json];
		a.splice(index,1)
		set_item_json(a);
	}

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
    }

	const get_options_based_on_cat_type = () => {
		if(use_cat_type === 'panel_core_hardware'){
			return JSON.parse(JSON.stringify(all_finish_type));
		}else if(use_cat_type === 'shutter_hardware'){
			return JSON.parse(JSON.stringify(all_type_shutter_hardware));
		}else if(use_cat_type === 'smart_cube'){
			return JSON.parse(JSON.stringify(valid_types));
		}else if(!use_cat_type && active_division_id != 'finish'){
			return JSON.parse(JSON.stringify(new_types));
		}else if(!use_cat_type && active_division_id === 'finish'){
			return JSON.parse(JSON.stringify(edge_band_types))
		}

		return []
	}

	// useEffect(() => {
	// 	console.log('outputs', item_json, item)       
    // },[item_json, item])

	const toggle_create = () => {set_is_create_open(!is_create_open)}

	// useEffect(() => {
	// 	if(!add_modal_open && edit_mode){
	// 		set_edit_mode(false)
	// 	}
	// 	if(!add_modal_open)
	// 		set_reset_init(false)
	// }, [add_modal_open])

	const init_reset_json = () => {
		reset_json['cat'] = selected_cat_array;
		reset_json['sub'] = selected_sub_cat_array;
		reset_json['div'] = selected_div_array;
		reset_json['grp'] = selected_group_array;
		reset_json['sku'] = selected_sku_array;
		reset_json['type'] = selected_type_type_array
		// console.log('initialized......kpp', reset_json)
	}

	// useEffect(() => {
	// 	if(edit_mode && add_modal_open && !reset_init){
	// 		set_reset_init(true);
			// reset_json['cat'] = selected_cat_array;
			// reset_json['sub'] = selected_sub_cat_array;
			// reset_json['div'] = selected_div_array;
			// reset_json['grp'] = selected_group_array;
			// reset_json['sku'] = selected_sku_array;
			// reset_json['type'] = selected_type_type_array
			// console.log('initialized......kpp', reset_json)
	// 	}
	// }, [edit_mode])

	const toggle_close_modal = () => {
		// console.log("kpp resetting values", reset_json, item_json)
		onselect_multi_options('category', reset_json['cat'], true)
		onselect_multi_options('sub_cat', reset_json['sub'], true)
		onselect_multi_options('division', reset_json['div'], true)
		onselect_multi_options('group', reset_json['grp'], true)
		onselect_multi_options('sku', reset_json['sku'], true)
		onselect_multi_options('type', reset_json['type'], true)
		// set_reset_init(true)
		// submit_add_modal();
		submit_add_modal();
		set_edit_mode(false);
		handle_close_edit();
	}

	// useEffect(() => {
	// 	if(edit_mode && reset_init){
	// 		submit_add_modal();
	// 		set_edit_mode(false);
	// 		set_reset_init(false);
	// 	}
	// }, [selected_sku_array, selected_type_type_array])

	// useEffect(() => {
	// 	console.log("KPPPP item_json is", item_json)
	// }, [item_json])

	const submit_add_modal = () => {
		if(is_valid_add_key(item_json)){
			handle_save();
			if(edit_mode)
				set_edit_mode(false)
		}
		else
			alert.error("Empty or Duplicate key values not allowed.")

	}

	return(
		<React.Fragment>
		<AddNewOption 
			mode={mode} 
			material_template={material_template}
			selected_div_array={selected_div_array}
			selected_cat_array={selected_cat_array} 
			selected_sub_cat_array={selected_sub_cat_array} 
			selected_group_array={selected_group_array} 
			is_create_open={is_create_open} 
			toggle_create={toggle_create} 
			set_refresh_cat={set_refresh_cat} 
			set_refresh_sub={set_refresh_sub} 
			set_refresh_grp={set_refresh_grp}
			set_refresh_sku={set_refresh_sku}
			fetch_sku_groups={fetch_sku_groups}
			set_page_loader={set_page_loader}
			// created_option={created_option}
		/>

		<Row key={idx} style={{margin:0,marginTop:'10px', display: (item_mode == 'add' && idx == item_json.length - 1) ? 'none' : 'flex', ...styles}}>
			{/* <Col xs={1} style={{display:'flex', alignItems:'center'}}>
				{idx+1}
			</Col> */}
			<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
				<Multiselect
					style={{backgroundColor:'white'}}
					options={all_division && all_division.length? JSON.parse(JSON.stringify(all_division)):[]} 
					selectedValues={selected_div_array}
					onSelect={(selectedList)=>onselect_multi_options('division', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('division', selectedList)} 
					displayValue="id" // Property name 
					selectionLimit={1}
					id={'select_div'+idx}
					placeholder='Select'
					disable={active_division_id!='finish' && allow_edit?false:true}
				/> 
			</div>
			<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
				<Multiselect
					style={{backgroundColor:'white'}}
					options={all_category && all_category.length? JSON.parse(JSON.stringify(all_category)): all_category_new && all_category_new.length ? JSON.parse(JSON.stringify(all_category_new)) : []} 
					selectedValues={selected_cat_array}
					onSelect={(selectedList)=>onselect_multi_options('category', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('category', selectedList)} 
					displayValue="name" // Property name 
					selectionLimit={1}
					id={'select_cat'+ idx}
					placeholder='Select'
					disable={active_division_id!='finish' && allow_edit?false:true}
				/> 
			</div>
			<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
				<Multiselect
					style={{backgroundColor:'white'}}
					options={all_sub_category && all_sub_category.length? JSON.parse(JSON.stringify(all_sub_category)): all_sub_category_new && all_sub_category_new.length ? JSON.parse(JSON.stringify(all_sub_category_new)) : []} 
					selectedValues={selected_sub_cat_array}
					onSelect={(selectedList)=>onselect_multi_options('sub_cat', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('sub_cat', selectedList)} 
					displayValue="name" // Property name 
					selectionLimit={1}
					id={'select_sub_cat'+idx}
					placeholder='Select'
					disable={active_division_id!='finish' && allow_edit?false:true}
				/> 
			</div>
			<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
				<Multiselect
					style={{backgroundColor:'white'}}
					options={all_groups && all_groups.length? JSON.parse(JSON.stringify(all_groups)):[]} 
					selectedValues={selected_group_array}
					onSelect={(selectedList)=>onselect_multi_options('group', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('group', selectedList)} 
					displayValue="name" // Property name 
					selectionLimit={1}
					id={'select_group'+ idx}
					placeholder='Select'
					disable={active_division_id!='finish' && allow_edit?false:true}
				/> 
			</div>
			<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
				<Multiselect
					style={{backgroundColor:'white'}}
					options={all_skus && all_skus.length? JSON.parse(JSON.stringify(all_skus)):[]} 
					selectedValues={selected_sku_array}
					onSelect={(selectedList)=>onselect_multi_options('sku', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('sku', selectedList)} 
					displayValue="name" // Property name 
					selectionLimit={1}
					id={'select_sku'+ idx}
					placeholder='Select'
					disable={active_division_id!='finish' && allow_edit?false:true}
				/> 
			</div>
			<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
				{
					get_options_based_on_cat_type().length ?
					<Multiselect
						style={{backgroundColor:'white'}}
						options={get_options_based_on_cat_type()} 
						selectedValues={selected_type_type_array}
						onSelect={(selectedList)=>onselect_multi_options('type', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('type', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'select_quan'+idx}
						placeholder='Select'
						disable={active_division_id!='finish' && allow_edit?false:true}
					/>
					:''
				}
				{
					(use_cat_type === 'drawer_system_collection_hardware' || use_cat_type === 'basket_collection_hardware')?
					<Input
						style={{backgroundColor:'white',fontSize:'12px'}}
						className="opacity_div"
						type="text"
						id={'select_quan'+idx}
						value={idx==0?String(idx)+' (Top)':idx}
						placeholder='Quantity'
						disable={true}
					/>
					:''
				}
				{/* {
					use_cat_type==='shutter_hardware'?
						<Multiselect
						style={{backgroundColor:'white'}}
						options={all_type_shutter_hardware && all_type_shutter_hardware.length? JSON.parse(JSON.stringify(all_type_shutter_hardware)):[]} 
						selectedValues={selected_type_type_array}
						onSelect={(selectedList)=>onselect_multi_options('type', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('type', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'select_quan'+idx}
						placeholder='Select'
						disable={allow_edit?false:true}
					/>
					:''
				}
				{
					use_cat_type==='smart_cube'?
						<Multiselect
						style={{backgroundColor:'white'}}
						options={valid_types && valid_types.length? JSON.parse(JSON.stringify(valid_types)):[]} 
						selectedValues={selected_type_type_array}
						onSelect={(selectedList)=>onselect_multi_options('type', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('type', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'select_quan'+idx}
						placeholder='Select'
						disable={allow_edit?false:true}
					/>
					:''
				}
				{
					!use_cat_type && active_division_id!='finish' ? 
					<Multiselect
						style={{backgroundColor:'white'}}
						options={new_types && new_types.length? JSON.parse(JSON.stringify(new_types)):[]} 
						selectedValues={selected_type_type_array}
						onSelect={(selectedList)=>onselect_multi_options('type', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('type', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'select_quan'+idx}
						placeholder='Select'
						disable={allow_edit?false:true}
					/>
					:''
				} */}
			</div>
			{	
				!use_cat_type && active_division_id!='finish'?	
				<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
					<Input 
						style={{fontSize:'12px'}}
						className={allow_edit?'':'opacity_div'} type="number" placeolder='0' 
						value={item_json && item_json[idx]?item_json[idx].quantity:''} 
						onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'quantity',Number(e.target.value)):alert.error('Negative Number not allowed')}
					/>
				</div>:''	
			}
			{
			active_division_id == 'finish' ?
			<div style={{display:'flex', flex:'1', alignItems:'center', justifyContent:'flex-end'}}>
				<i style={{cursor:'pointer', marginRight:'10px'}} onClick={()=> {if(set_item_mode) {init_reset_json(); set_edit_mode(true);}}} className='fa fa-edit' />
				<span><i style={{cursor:'pointer'}} onClick={() =>delete_item_json(idx)} className='fa fa-trash'/></span>
			</div>:
			<div style={{display:'flex', flex:'1', alignItems:'center', justifyContent:'flex-end'}}>
				<i style={{cursor:'pointer', marginRight:'10px'}} onClick={()=> set_allow_edit(!allow_edit)} className='fa fa-edit' />
				<span className={allow_edit?'':'opacity_div'}><i style={{cursor:'pointer'}} onClick={allow_edit?() =>delete_item_json(idx):null} className='fa fa-trash'/></span>
			</div>}
		</Row>
		<Modal className="modal-dialog-centered" size="md" isOpen={add_modal_open || edit_mode}>
			<IModalHeader toggle={() => {edit_mode?toggle_close_modal(): handle_close()}}>Add a linked Edgeband</IModalHeader>
			<IModalBody className="DMSans px-5">
				{/* <Radio.Group onChange={(e) => { set_sku_mode(e.target.value) }} value={sku_mode} className='infurnia-radio mb-3' style={{ width: '100%', display: 'flex' }}>
					<Radio.Button value="new">Add new {modal_json && modal_json.default_sku_name ? modal_json.default_sku_name : 'Template'}</Radio.Button>
					<Radio.Button value="existing">Update existing {modal_json && modal_json.default_sku_name ? modal_json.default_sku_name : 'Template'}</Radio.Button>
				</Radio.Group> */}
				<div className='mb-3'>
					<div>Division</div>
					<div className='flex_property input_container_publish_sheet'>
						{/* <Input type='select' style={{ ...styles_modals.input_styles_none, height: "30px" }}  >
							{all_division.map((o, idx1) => (
								<option key={idx1} value={o.id}>{o.id}</option>
							))}
						</Input> */}
						<Multiselect
						style_badge_container={{height: '100%', width: '100%', border: 'none'}}
						style_options_container={{width: '100%'}}
						style={{backgroundColor:'white', ...styles_modals.input_styles_none}}
						options={all_division && all_division.length? JSON.parse(JSON.stringify(all_division)):[]} 
						selectedValues={selected_div_array}
						onSelect={(selectedList)=>onselect_multi_options('division', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('division', selectedList)} 
						displayValue="id" // Property name 
						selectionLimit={1}
						id={'select_div'+idx}
						placeholder='Select'
				/> 
						{/* <div className='add_option_container_publish'>
							<div className='add_option_publish'>
								<i style={{ fontSize: '12px', color: '#1070CA' }} className='fa fa-plus' />
							</div>
						</div> */}
					</div>
				</div>
				<div className='mb-3'>
					<div>Category</div>
					<div className='flex_property input_container_publish_sheet'>
						<Multiselect
						style_badge_container={{height: '100%', width: '100%', border: 'none'}}
						style_options_container={{width: '100%'}}
						style={{backgroundColor:'white'}}
						options={all_category && all_category.length? JSON.parse(JSON.stringify(all_category)): all_category_new && all_category_new.length ? JSON.parse(JSON.stringify(all_category_new)) : []} 
						selectedValues={selected_cat_array}
						onSelect={(selectedList)=>onselect_multi_options('category', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('category', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'select_cat'+ idx}
						placeholder='Select'
					/> 
						<div className='add_option_container_publish'>
							<div className='add_option_publish' onClick={selected_div_array && selected_div_array.length > 0 ? () => { set_mode('category'); toggle_create() } : () => {alert.error('In order to add category, please select the above fields first')}}>
								<i style={{ fontSize: '12px', color: '#1070CA' }} className='fa fa-plus' />
							</div>
						</div>
					</div>
				</div>
				<div className='mb-3'>
					<div>Sub Category</div>
					<div className='flex_property input_container_publish_sheet'>
						<Multiselect
						style_badge_container={{height: '100%', width: '100%', border: 'none'}}
						style_options_container={{width: '100%'}}
						style={{backgroundColor:'white'}}
						options={all_sub_category && all_sub_category.length? JSON.parse(JSON.stringify(all_sub_category)): all_sub_category_new && all_sub_category_new.length ? JSON.parse(JSON.stringify(all_sub_category_new)) : []} 
						selectedValues={selected_sub_cat_array}
						onSelect={(selectedList)=>onselect_multi_options('sub_cat', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('sub_cat', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'select_sub_cat'+idx}
						placeholder='Select'
					/> 
						<div className='add_option_container_publish'>
							<div className='add_option_publish' onClick={selected_cat_array && selected_cat_array.length > 0 ? () => {set_mode('sub category'); toggle_create() } : () => {alert.error('In order to add subcategory, please select the above fields first')}}>
								<i style={{ fontSize: '12px', color: '#1070CA' }} className='fa fa-plus' />
							</div>
						</div>
					</div>
				</div>
				<div className='mb-3'>
					<div>Group</div>
					<div className='flex_property input_container_publish_sheet'>
						<Multiselect
						style_badge_container={{height: '100%', width: '100%', border: 'none'}}
						style_options_container={{width: '100%'}}
						style={{backgroundColor:'white'}}
						options={all_groups && all_groups.length? JSON.parse(JSON.stringify(all_groups)):[]} 
						selectedValues={selected_group_array}
						onSelect={(selectedList)=>onselect_multi_options('group', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('group', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'select_group'+ idx}
						placeholder='Select'
					/> 
						<div className='add_option_container_publish'>
							<div className='add_option_publish' onClick={selected_sub_cat_array && selected_sub_cat_array.length > 0 ? () => { set_mode('group'); toggle_create() } : () => {alert.error('In order to add group, please select the above fields first')}}>
								<i style={{ fontSize: '12px', color: '#1070CA' }} className='fa fa-plus' />
							</div>
						</div>
					</div>
				</div>
				<div className='mb-3'>
					<div>SKU</div>
					<div className='flex_property input_container_publish_sheet'>
						<Multiselect
						style_badge_container={{height: '100%', width: '100%', border: 'none'}}
						style_options_container={{width: '100%'}}
						style={{backgroundColor:'white'}}
						options={all_skus && all_skus.length? JSON.parse(JSON.stringify(all_skus)):[]} 
						selectedValues={selected_sku_array}
						onSelect={(selectedList)=>onselect_multi_options('sku', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('sku', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'select_sku'+ idx}
						placeholder='Select'
					/> 
						<div className='add_option_container_publish'>
							<div className='add_option_publish' onClick={selected_group_array && selected_group_array.length > 0 ? () => {set_mode('sku'); toggle_create()} : () => {alert.error('In order to add sku, please select the above fields first')}}>
								<i style={{ fontSize: '12px', color: '#1070CA' }} className='fa fa-plus' />
							</div>
						</div>
					</div>
				</div>
				{get_options_based_on_cat_type().length ?
				<div className='mb-3'>
					<div>Type</div>
					<div className='flex_property input_container_publish_sheet'>
					<Multiselect
						style_badge_container={{height: '100%', width: '100%', border: 'none'}}
						style_options_container={{width: '100%'}}
						style={{backgroundColor:'white'}}
						options={get_options_based_on_cat_type()} 
						selectedValues={selected_type_type_array}
						onSelect={(selectedList)=>onselect_multi_options('type', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('type', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'select_quan'+idx}
						placeholder='Select'
					/>
						{/* <div className='add_option_container_publish'>
							<div className='add_option_publish'>
								<i style={{ fontSize: '12px', color: '#1070CA' }} className='fa fa-plus' />
							</div>
						</div> */}
					</div>
				</div> : ''}

				<div className='flex_center mb-3'>
					<Button onClick={submit_add_modal} className='blue_button'>Submit</Button>
				</div>
			</IModalBody>
		</Modal>
	</React.Fragment>
		
	)
}

const Linked_sku_structure = ({set_page_loader, item_json, set_item_json, unowned_linked_sku, set_unowned_linked_sku, selected_sku_properties, all_division_tree_data, active_division_id,sku_category_type, material_template}) => {

	const [skus_ansistry ,set_skus_ansistry ] =useState([]);
	const [remove_loader ,set_remove_loader ] =useState(false);
	const [sku_group_initial_options ,set_sku_group_initial_options ] = useState([]);
	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)
	const alert = useAlert()
	const [use_cat_type,set_use_cat_type ] =useState('');
	const [item_mode, set_item_mode] = useState('select');
	const [popped_item, set_popped_item] = useState(false);
	// const [unowned_linked_sku, set_unowned_linked_sku] = useState([]);

	// useEffect(() => {
    //     if(selected_sku_properties && selected_sku_properties.linked_skus && selected_sku_properties.linked_skus.length){
    //         set_item_json([selected_sku_properties.linked_skus])
    //     }
    // },[selected_sku_properties])

	// useEffect(() => {
	// 	console.log("KPP item_json is", item_json, sku_category_type, all_division_tree_data, item_mode)
	// })

	useEffect(() =>{
		if (sku_category_type==='panel_core_hardware') {
			set_use_cat_type('panel_core_hardware')
		} else if (sku_category_type==='shutter_hardware') {
			set_use_cat_type('shutter_hardware')
		} else if (sku_category_type === 'drawer_system_collection_hardware') {
			set_use_cat_type('drawer_system_collection_hardware')
		} else if (sku_category_type === 'basket_collection_hardware') {
			set_use_cat_type('basket_collection_hardware')
		} else if (selected_sku_properties.additional_properties && selected_sku_properties.additional_properties.length && selected_sku_properties.additional_properties.filter(x => x.key == 'cabinet_type' && x.value == 'smart_cube').length) {
			set_use_cat_type('smart_cube')
		}

	},[sku_category_type])

	const is_valid_add_key = (item_json) => {
		if(item_json && item_json.length){
			for(var i=0;i<item_json.length;i++){
				if(item_json[i].sku && item_json[i].sku.length<1 ){
					return false;
				}
			}
			return true;
		}else{
			return true;
		}
	}

	const is_valid_item = (item) => {
		return !(item.sku && item.sku.length < 1)
	}

	// useEffect(() => {
	// 	console.log("kpppppp item_json", item_json)
	// }, [item_json])

    const add_new_item_json = (mode = 'select') => {
		if(mode == 'add'){
			let a = [...item_json];
			if(!is_valid_add_key(item_json)){
				set_popped_item(a[a.length - 1])
				a.pop();
			}
			a.push({division:[], category:[], sub_cat:[], group:[], sku:[], quantity:0, type:''})
			set_item_json(a);
			set_item_mode('add');
		}
		else if(is_valid_add_key(item_json)){
            let a = [...item_json];
			a.push({division:[], category:[], sub_cat:[], group:[], sku:[], quantity:0, type:''})
			set_item_json(a);
			set_item_mode('select')
        }else{
            alert.error("Empty or Duplicate key values not allowed.")
        }
	}

	const delete_unowned_linked_sku = (idx) => {
		unowned_linked_sku.splice(idx, 1)
		set_unowned_linked_sku(JSON.parse(JSON.stringify(unowned_linked_sku)))
	}

    useEffect(() =>{
		if(item_json && !item_json.length && unowned_linked_sku && !unowned_linked_sku.length){
			add_new_item_json(item_mode)
		}
	},[item_json])    

	const onclick_handleclose =() => {

	}

	const fetch_ansisters = async(sku_ids) => {
		// console.log('LL linked list=================================> ',sku_ids)
		var response =[]
		if(sku_ids && sku_ids.length && sku_ids[0]){
			response = await general_fetch({ url: 'inventory/get_sku_ancestry' , body: { sku_id:sku_ids } });
			let t = response && response.length ? response.map(single_item => {
				if(single_item.sku_data && !(typeof(single_item.sku_data.length) == 'number')){
					single_item.sku_data = [single_item.sku_data]
				}
				if(single_item.sku_category_data && !(typeof(single_item.sku_category_data.length) == 'number')){
					single_item.sku_category_data = [single_item.sku_category_data]
				}
				if(single_item.sku_sub_category_data && !(typeof(single_item.sku_sub_category_data.length) == 'number')){
					single_item.sku_sub_category_data = [single_item.sku_sub_category_data]
				}
				if(single_item.sku_group_data && !(typeof(single_item.sku_group_data.length) == 'number')){
					single_item.sku_group_data = [single_item.sku_group_data]
				}
			}) : ''
		}
		set_skus_ansistry(response && response.length?response:[]);
		return response;
	}

	const fetch_group_and_skus = async sku_sub_category_id => {
		try{
			let body = { sku_sub_category_id }
			if(org_bu_view === 'bu'){
                body['business_unit_id'] = user.current_business_unit_id;
            }
			var response = await general_fetch({ url: 'inventory/get_groups_for_multiple_sub_categories' , body});
			set_sku_group_initial_options(response)
		}catch(err){
			console.log(err)
		}
	}

	const fetch_sku_details = async(skus) => {
		let sku_ids = []
		let sku_to_data_map = {}
		for(let i in skus){
			let sku = skus[i]
			sku_ids.push(sku.id)
			sku_to_data_map[sku.id] = sku
		}

		let sku_details = await fetch_skus(sku_ids);

		let formatted_sku_data = sku_details.map(sku => {
			let sku_name = sku.name;
			let sku_group = sku.sku_group;
			let sku_sub_category = sku_group ? sku_group.sku_sub_category : ''
			let sku_category = sku_sub_category ? sku_sub_category.sku_category : ''

			return(
				{
					division: sku_category && sku_category.sku_division_id ? sku_category.sku_division_id : '',
					category: sku_category && sku_category.name ? sku_category.name : '',
					sub_category: sku_sub_category && sku_sub_category.name ? sku_sub_category.name : '',
					group: sku_group && sku_group.name ? sku_group.name : '',
					sku: sku_name,
					sku_id: sku.id,
					quantity: sku_to_data_map[sku.id] ? sku_to_data_map[sku.id].quantity : '',
					type: sku_to_data_map[sku.id] ? sku_to_data_map[sku.id].type : '',
				}
			)
		})
		debug_log("unowned linked sku", formatted_sku_data)
		set_unowned_linked_sku(formatted_sku_data);
	}

	useEffect(() => {
		if (selected_sku_properties) {
			(
				async () => {
					debug_log('selected_sku_properties', selected_sku_properties)

					var x_linked_sku_ids = selected_sku_properties.linked_skus && selected_sku_properties.linked_skus.length ? selected_sku_properties.linked_skus : [];

					// let unowned_linked_sku = x_linked_sku_ids.filter(sku => sku.business_unit_id != user.current_business_unit_id)
					// let owned_linked_sku = x_linked_sku_ids.filter(sku => sku.business_unit_id == user.current_business_unit_id)
					// console.log('selected_sku_properties 11', x_linked_sku_ids)
					// if(owned_linked_sku && owned_linked_sku.length){
					let ancestors = await fetch_ansisters(x_linked_sku_ids.map(x => x.id));
					// }
					// if(unowned_linked_sku && unowned_linked_sku.length){
					// 	fetch_sku_details(unowned_linked_sku)
					// }
					let unowned_sku = x_linked_sku_ids.filter(x => !ancestors.find(y => y.id == x.id))
					if (unowned_sku && unowned_sku.length) {
						await fetch_sku_details(unowned_sku)
					}
				}
			)()
		}
	}, [selected_sku_properties])

	useEffect(() => {
		if (skus_ansistry && skus_ansistry.length && selected_sku_properties && selected_sku_properties.linked_skus && selected_sku_properties.linked_skus.length) {
			debug_log("sku ancestory", skus_ansistry)
			let sku_sub_category_ids = skus_ansistry.map(x => x.sku_sub_category_data[0].id);
			fetch_group_and_skus(sku_sub_category_ids)
			var x_single_item = selected_sku_properties.linked_skus.map((x_lskus, idx) => {
				var x = skus_ansistry && skus_ansistry.length ? skus_ansistry.find(x => x.id === x_lskus.id) : null
				if (x) {
					return ({
						division: x ? [{ id: x.sku_division_id }] : [],
						category: x ? x.sku_category_data : [],
						sub_cat: x ? x.sku_sub_category_data : [],
						group: x ? x.sku_group_data : [],
						sku: x ? x.sku_data : [],
						quantity: selected_sku_properties.linked_skus[idx] ? selected_sku_properties.linked_skus[idx].quantity : '',
						type: selected_sku_properties.linked_skus[idx] ? selected_sku_properties.linked_skus[idx].type : '',
					})
				}
				return null
			});
			set_item_json(x_single_item.filter(x => x));
		}        
    },[skus_ansistry])

	// useEffect(() => {
	// 	console.log("KPPP material_template", material_template);
	// })

	return(
		<div>
			<div>
				{active_division_id == 'finish' && item_json && item_json.length == 1 && !is_valid_add_key(item_json) ? '' :
				<Row style={{margin:0,marginTop:'10px', fontSize:'14px', display:'flex'}}>
					{/* <Col xs={1} style={{display:'flex', alignItems:'center'}}>
						Sl no.
					</Col> */}
					<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
						Division 
					</div>
					<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
						Category 
					</div>
					<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
						Sub Category
					</div>
					<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
						Group 
					</div>
						<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
							SKU
						</div>
					{
						// active_division_id!='finish'?
						<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
							Type
						</div>
						// :""
					}
					{
						!use_cat_type && active_division_id!='finish'?
						<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>Quantity</div>:''
					}
					<div style={{display:'flex', flex:'1', alignItems:'center', justifyContent:'flex-end'}}>	
					</div>
				</Row>}
			</div>
			{
				item_json && item_json.length?item_json.map((x,idx) => 
					<Single_item 
					styles = {{display: (active_division_id == 'finish' && !is_valid_item(x)) ? 'none' : ''}}
					material_template={material_template}
					set_page_loader={set_page_loader}
					idx = {idx} 
					item_mode = {item_mode}
					set_item_mode = {set_item_mode}
					add_modal_open={item_mode == 'add'}
					item={x}
					key = {idx}
					set_remove_loader={set_remove_loader} 
					item_json={item_json} 
					set_item_json={set_item_json} 
					all_division_tree_data={all_division_tree_data}
					already_selected_sku_groups={sku_group_initial_options}
					active_division_id={active_division_id}
					sku_category_type={sku_category_type}
					use_cat_type={use_cat_type}
					is_valid_add_key={is_valid_add_key}
					handle_close={() => {
						set_item_mode('select');
						let a = [...item_json];
						a.pop();
						if(popped_item){
							a.push(popped_item);
							set_popped_item(false);
						}
						set_item_json(a);
					}}
					handle_close_edit={() => {
						set_item_mode('select');
					}}
					handle_save={() => {
						// console.log("kpp item_json during save is", item_json);
						set_item_mode('select');
						if(popped_item)
							set_popped_item(false);
					}}
				/>
				):''
			}
			
			{/* {
				remove_loader?''
				:
				<div style={{position:'fixed',backgroundColor:'rgba(20,20,20,0.7',left:0,top:0,width:'100%',height:'100%',zIndex:10000}}>
					<div style={{position:'absolute',width:'350px',height:'40px',left:'calc(50% - 175px)',top:'calc(50% - 20px)',backgroundColor:'#314456',color:'#ffffff',display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}}>
						<i className = "fa fa-spinner fa-spin"></i> &nbsp; &nbsp; <span>Please Wat</span>
					</div>
				</div>
			} */}

			{
				unowned_linked_sku && unowned_linked_sku.length ? unowned_linked_sku.map((sku, idx) => (
					<div style={{marginTop:'10px', fontSize:'12px', display:'flex'}}>
						<div style={{ display: 'flex', flex: '2', minWidth: '40px', alignItems: "center", maxWidth: '13%', marginRight: '5px' }}>
							<div className='linked_sku_unowned_container'><div className='single_badge_linked_sku' title={sku.division}>{sku.division}</div></div>
						</div>
						<div style={{ display: 'flex', flex: '2', minWidth: '40px', alignItems: "center", maxWidth: '13%', marginRight: '5px' }}>
							<div className='linked_sku_unowned_container'><div className='single_badge_linked_sku' title={sku.category}>{sku.category}</div></div>
						</div>
						<div style={{ display: 'flex', flex: '2', minWidth: '40px', alignItems: "center", maxWidth: '13%', marginRight: '5px' }}>
							<div className='linked_sku_unowned_container'><div className='single_badge_linked_sku' title={sku.sub_category}>{sku.sub_category}</div></div>
						</div>
						<div style={{ display: 'flex', flex: '2', minWidth: '40px', alignItems: "center", maxWidth: '13%', marginRight: '5px' }}>
							<div className='linked_sku_unowned_container'><div className='single_badge_linked_sku' title={sku.group}>{sku.group}</div></div>
						</div>
						<div style={{ display: 'flex', flex: '2', minWidth: '40px', alignItems: "center", maxWidth: '13%', marginRight: '5px' }}>
							<div className='linked_sku_unowned_container'><div className='single_badge_linked_sku' title={sku.sku}>{sku.sku}</div></div>
						</div>
						{
							active_division_id != 'finish' ?
								<div style={{ display: 'flex', flex: '2', minWidth: '40px', alignItems: "center", maxWidth: '13%', marginRight: '5px' }}>
									<div className='linked_sku_unowned_container'><div className='single_badge_linked_sku' title={(sku.type && all_linked_sku_types.find(x => x.id === sku.type) && all_linked_sku_types.find(x => x.id === sku.type).name) || sku.type}>{(sku.type && all_linked_sku_types.find(x => x.id === sku.type) && all_linked_sku_types.find(x => x.id === sku.type).name) || sku.type}</div></div>
								</div> : ""
						}
						{
							!use_cat_type && active_division_id != 'finish' ?
								<div style={{ display: 'flex', flex: '2', alignItems: "center", maxWidth: '13%', marginRight: '5px' }}><div className='linked_sku_unowned_container'><div className=''>{sku.quantity}</div></div></div> : ''
						}
						<div style={{ display: 'flex', flex: '1', minWidth: '20px', alignItems: 'center', justifyContent: 'flex-end', fontSize: '16px' }}>
							<Tooltip title={"The Linked SKU is not owned by your Organization. You can only delete it"}>
								<i style={{ cursor: 'pointer', marginRight: '10px', opacity: '0.5' }} disabled className='fa fa-edit' />
							</Tooltip>
							<span><i style={{cursor:'pointer'}} onClick={() =>delete_unowned_linked_sku(idx)} className='fa fa-trash'/></span>
						</div>
					</div>
				))
				:''
			}
			{active_division_id != 'finish' ?
			<Row style={{margin:'10px 0px 0px 0px'}}>
				<Col style={{textAlign:'left', paddingLeft:0}}>
					<span onClick={add_new_item_json}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add +</span>
				</Col>
			</Row>
			:
			<Row style={{margin:'10px 0px 0px 0px'}}>
				<Col style={{textAlign:'left', paddingBottom:'10px', paddingLeft:0}}>
					<span onClick={() => add_new_item_json('add')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add a linked edgeband +</span>
				</Col>
			</Row>}

			{/* <div>
				Unowned SKU
			</div> */}

			
		</div>
	)
} 

export default Linked_sku_structure;