import React , { useState , useEffect, useRef }from 'react';
import {FormGroup, Button, Form, Input, InputGroupAddon, Modal, InputGroupText, InputGroup, Badge, Card, CardBody, CardText, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent ,CardImg ,Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import './styles.css'
import { useAlert } from 'react-alert';
import cloneFiles, { areFilesSame } from '../../utils/clone_files';
import Icon from '../Icon';

// const MTLFile = require('mtl-file-parser');

export const IUploadFileItem = ({idx, accept, header, size_limit = 100, custom_upload_src, custom_error_mssg, custom_paperclip_mssg, isBulkUpload, fixedFileName, id, bulk_files, set_bulk_files, onFilesUploadCallback, unconstrained = false}) => {

	const [file, set_file] = useState('');
	const [unconstrained_id, set_unconstrained_id] = useState('')
	const file_input_ref = useRef(null);
	const [error, set_error] = useState('');
	const alert = useAlert();

	useEffect(() => {
		// console.log("kpp bulk files are", bulk_files)
		if(isBulkUpload && bulk_files && (bulk_files.length > idx) && bulk_files[idx]){
			if(bulk_files[idx].errorMessage){
				set_error(bulk_files[idx].errorMessage)
			}
			else if(bulk_files[idx].file){
				set_error('')
				set_unconstrained_id(JSON.parse(JSON.stringify(bulk_files[idx].id)))
				// console.log("kpp bulk_files idx", JSON.parse(JSON.stringify(bulk_files)), idx)
				set_file(bulk_files[idx].file)
			}
		}
	}, [bulk_files])

	useEffect(() => {
		// console.log("kpp the file is bulkk", file, error)
		bulk_files[idx] = {file: error ? '' : file, error: error, id: unconstrained_id}
		if(onFilesUploadCallback)
			onFilesUploadCallback(bulk_files)
	}, [file, error])

	const onchange_filename = (e) => {
		var file = e.target.files[0];
		if(isBulkUpload && (file.name != fixedFileName) && !unconstrained){
			set_file('')
			file_input_ref.current.value = '';
			alert.info(`Uploaded file is discarded as its name doesn't match the required texture name`);
			return;
		}
		if(file && file.size > size_limit * 1024 * 1024){
			set_error(custom_error_mssg ? custom_error_mssg : `File size exceeded. please select a file less than ${size_limit} mb`)
			set_file('')
			file_input_ref.current.value = '';
			return;
		}
		set_error('');
		set_file(file)
		var reader = new FileReader();
		reader.onload = function(event) {
			// The file's text will be printed here
			// console.log("set text jkpp", event.target.result)
			if(!isBulkUpload && onFilesUploadCallback)
				onFilesUploadCallback(bulk_files, event.target.result)
		};
		if((accept == '.mtl' || accept == '.json') && file) reader.readAsText(file)
		e.stopPropagation();
	}

	const onclick_upload_button = () => {
		// console.log("kpp input ", document.getElementById(id));
		if(document.getElementById(id))
			document.getElementById(id).click();
	}

	const onclick_remove_button = () => {
		if (file_input_ref.current) {
			file_input_ref.current.value = '';
		  }
		set_file('');
		set_error('');
		if(unconstrained){
			let new_bulk_files = bulk_files.filter((item, index) => index != idx);
			// console.log("kpp new bulk_files are", new_bulk_files)
			set_bulk_files(new_bulk_files)
		}
	}

	return (
		<div className={'upload-file-item' + (file || isBulkUpload ? ' uploaded-file-item' : '')} style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', height: '28px', padding: file || isBulkUpload  ? '8px 12px 8px 12px' : '8px 0 8px 0'}}>
			<div style={{display: 'flex', gap: '16px', alignItems: 'center'}}>
					<Input innerRef={file_input_ref} id={id} type="file" onChange={(e) => onchange_filename(e)} style={{display:"none"}} accept={accept}/>
					<img src={custom_upload_src ? custom_upload_src : '/assets/icons/upload_icon.svg'} style={{cursor: 'pointer', display: file || isBulkUpload ? 'none' : '', height: '26px'}} onClick={onclick_upload_button} />

					<div style={{fontSize: '12px'}}>
						<div style={{ display: 'flex', gap: '8px', opacity: (file || (error && !isBulkUpload)) ? 1 : 0.5}}> 
							<Icon name={'paper_clip'} style={{color: (error && !isBulkUpload) ? '#FF5247' : '#323338', paddingTop: '2px'}}/>
							<div style={{color: (error && !isBulkUpload) ? '#FF5247' : '#323338'}}>{file ? file.name : (isBulkUpload && fixedFileName) ? fixedFileName : error ? error : custom_paperclip_mssg ? custom_paperclip_mssg : 'No file attached'}</div>
						</div>
					</div>
			</div>

			<div style={{display: (error && isBulkUpload) ? '' : 'none', color: '#FF5247'}}>
				{error}
			</div>

			<div className='upload-delete-options' style={{display: file || isBulkUpload ? '' : 'none', gap: '16px'}}>
				<Icon className='inf-upload-icon' name='upload_icon' style={{display: 'flex', cursor: 'pointer'}} onClick={onclick_upload_button}/>
				<Icon name='trash_icon' style={{display: file ? 'flex' : 'none', cursor: 'pointer', color: '#FF5247'}} onClick={onclick_remove_button}/>
			</div>
			<div className='uploaded-mark' style={{display: file ? '' : 'none', gap: '8px'}}>
				<img src='/assets/icons/svg_tick.svg' />
			</div>
		</div>
	)
}

/**
 * 
 * @param { array upload_files_arr array of single_upload_item_json. single_upload_item_json has following fields - accept, size_limit = 100, custom_upload_src, custom_error_mssg, id}
 * @param {	html header html or string }
 * @returns 
 */
export const IUploadFile = ({id, upload_files_arr, header, bulkJson = {}, onFilesUploadCallback, styles}) => {

	const filesRef = useRef(null);
	const [bulk_files, set_bulk_files] = useState(new Array(upload_files_arr && upload_files_arr.length ? upload_files_arr.length : 0));
	const [dynamic_files, set_dynamic_files] = useState([])
	const alert = useAlert();

	// useEffect(() => {
	// 	if(bulkJson.isBulkUpload) console.log("kpp mtl Iupload file", upload_files_arr)	
	// })

	const onBulkUpload = async(e) => {
		if(!bulkJson.BulkSizeLimit) bulkJson.BulkSizeLimit = 100;
		let uploadedFiles = Array.from(e.target.files);
		// console.log("Kpps bulk upfiles", JSON.parse(JSON.stringify(uploadedFiles), e.target.files, e.target.files[0]))
		let newUploadedFiles = Array.from(bulk_files)
		let reqFileNames = {};
		let validFiles = {}, errorMessages = {}, uploadedFilesMap = {}, notMatching = 0
		// console.log("kpp upload_files_arr", JSON.parse(JSON.stringify(upload_files_arr)));
		upload_files_arr && upload_files_arr.length && upload_files_arr.map((item, idx) => {if(item.fileName) reqFileNames[item.fileName] = idx + 1});
		// console.log("kpp uploaded files", uploadedFiles, reqFileNames)
		for(let i=0; i<uploadedFiles.length; i++){
			if(bulkJson.unconstrained){
				let newFile = {};
				if((uploadedFiles[i].size <= bulkJson.BulkSizeLimit * 1024 * 1024)){
					newFile.file = new File([uploadedFiles[i]], uploadedFiles[i].name, {
						type: uploadedFiles[i].type,
						lastModified: uploadedFiles[i].lastModified,
					});
			
					newFile.fileName = uploadedFiles[i].name
					newFile.id = id + '_' + i + '_' + Date.now();
					newFile.isValid = true;
				} else{
					newFile.id = id + '_' + i + '_' + Date.now();
					newFile.fileName = uploadedFiles[i].name
					newFile.isValid = false;
					newFile.errorMessage = `File size exceeded, please select a file less than ${bulkJson.BulkSizeLimit} mb`;
				}
				let foundSameFile = newUploadedFiles.find(item => areFilesSame(item.file, newFile.file))
				if(!foundSameFile)
					newUploadedFiles.push(newFile);
			}
			else{
				if(reqFileNames[uploadedFiles[i].name] && (uploadedFiles[i].size <= bulkJson.BulkSizeLimit * 1024 * 1024)){
					let idx = reqFileNames[uploadedFiles[i].name] - 1;
					validFiles[idx] = true;
					uploadedFilesMap[idx] = uploadedFiles[i];
				} else{
					// validFiles[i] = false;
					if(!reqFileNames[uploadedFiles[i].name]) notMatching++;
					else {
						let idx = reqFileNames[uploadedFiles[i].name] - 1;
						errorMessages[idx] = `File size exceeded, please select a file less than ${bulkJson.BulkSizeLimit} mb`
					}
				}
			}
		}
		if(!bulkJson.unconstrained){
			let finalFilesArr = upload_files_arr && upload_files_arr.length && upload_files_arr.map((item, idx) => {return {...item, isValid: validFiles[idx], errorMessage: errorMessages[idx], file: uploadedFilesMap[idx]}})
			// console.log("kpp finalfilesarr", JSON.parse(JSON.stringify(finalFilesArr)), validFiles, JSON.parse(JSON.stringify(uploadedFilesMap)))
			set_bulk_files(finalFilesArr)
		} else{
			// console.log("kpp new uploaded files", newUploadedFiles)
			set_bulk_files(newUploadedFiles)
		}
		if(!bulkJson.unconstrained && notMatching > 0) alert.info(`${notMatching} ${notMatching > 1 ? 'files' : 'file'} not matching with the required texture names ${notMatching > 1 ? 'are' : 'is'} discarded`);
		filesRef.current.value = '';
	}

	const onclick_upload_button = () => {
		if(document.getElementById('bulk_upload_input'+id)){
			document.getElementById('bulk_upload_input'+id).click();
		}
	}


	return (
		<CardBody style={{backgroundColor:'#F5F6F8', ...styles}}>
			<div style={{backgroundColor: 'white', fontSize: '14px'}}>
				<div className='upload-file-inf-header'>
					{header}
				</div>
				<div className='upload-file-inf-body'>
					<div style={{display: bulkJson.isBulkUpload ? 'flex' : 'none', gap: '16px', alignItems: 'center', marginBottom: '16px'}}>
						<input ref={filesRef} id={'bulk_upload_input'+id} type="file" onChange={(e) => {onBulkUpload(e); }} style={{display:"none"}} accept={bulkJson.bulkAccept} multiple/>
						<img src={'/assets/icons/upload_icon.svg'} style={{cursor: 'pointer', height: '26px'}} onClick={onclick_upload_button} />
						<div style={{color: '#676878'}}>{bulkJson.BulkUploadMssg ? bulkJson.BulkUploadMssg : 'Please upload all the below mentioned texture files'}</div>
					</div>
					{!bulkJson.unconstrained && upload_files_arr && upload_files_arr.length ? upload_files_arr.map((item, idx) => 
					<IUploadFileItem 
						key={idx}
						idx={idx}
						accept={bulkJson.bulkAccept ? bulkJson.bulkAccept : item.accept}
						size_limit={bulkJson.isBulkUpload ? (bulkJson.BulkSizeLimit ? bulkJson.BulkSizeLimit : 100) : item.size_limit}
						custom_upload_src={item.custom_upload_src}
						custom_error_mssg={item.custom_error_mssg}
						custom_paperclip_mssg={bulkJson.isBulkUpload && bulkJson.areFilesFixed ? item.fileName : item.custom_paperclip_mssg}
						id={item.id}
						isBulkUpload={bulkJson.isBulkUpload}
						fixedFileName={item.fileName}
						bulk_files={bulk_files}
						set_bulk_files={set_bulk_files}
						onFilesUploadCallback={onFilesUploadCallback}
					/>) : ''
					}
					{bulkJson.unconstrained && bulk_files && bulk_files.length ? bulk_files.map((item, idx) => 
					<IUploadFileItem 
						key={item.id}
						idx={idx}
						accept={bulkJson.bulkAccept ? bulkJson.bulkAccept : item.accept}
						size_limit={bulkJson.isBulkUpload ? (bulkJson.BulkSizeLimit ? bulkJson.BulkSizeLimit : 100) : item.size_limit}
						custom_upload_src={item.custom_upload_src}
						custom_error_mssg={item.custom_error_mssg}
						custom_paperclip_mssg={bulkJson.isBulkUpload && bulkJson.areFilesFixed ? item.fileName : item.custom_paperclip_mssg}
						id={item.id}
						isBulkUpload={bulkJson.isBulkUpload}
						fixedFileName={item.fileName}
						bulk_files={bulk_files}
						set_bulk_files={set_bulk_files}
						onFilesUploadCallback={onFilesUploadCallback}
						unconstrained={true}
					/>) : ''
					}
				</div>
			</div>
		</CardBody>
	)
}

const UploadFile = ({ id, set_file, file_size, styles, custom_button, button_name, accept, update_additional_file, additional_files_idx, set_text, additional_files, onFileUpload}) => {

	const onchange_filename = (e) => {
		if(update_additional_file){
			console.log('inside upload comp',additional_files_idx, e.target.files[0])
			update_additional_file(additional_files_idx, e.target.files[0]) 
		}else{
			var file = e.target.files[0];
			if(onFileUpload) onFileUpload();
			else
			set_file(file)
			var reader = new FileReader();
			reader.onload = function(event) {
				// The file's text will be printed here
				console.log(event.target.result)
				set_text(event.target.result)
			};
			if((accept == '.mtl' || accept == '.json') && file) reader.readAsText(file)
			e.stopPropagation();
		}
	}

	// useEffect(()=>{
	// 	console.log("From useEffect ---> ", additional_files_idx)
	// },[additional_files_idx])

	const onclick_upload_button = () => {
		document.getElementById(id).click();
		// document.getElementById(id).value = null
		console.log("filessss",document.getElementById(id).files)
	}

	useEffect(() => {
		if(additional_files && !additional_files[additional_files_idx]){
			document.getElementById(id).value = null
		}
	},[additional_files])

	return(
		<div>
			{custom_button ?
			<img src={custom_button} style={{cursor: 'pointer'}} onClick={onclick_upload_button} />:
			<Button className="blue_button" style={styles} onClick={onclick_upload_button}>{button_name?button_name:'Select file'}</Button>}

			<Input id={id} type="file" onChange={(e) => onchange_filename(e)} style={{display:"none"}} accept={accept}/>
		</div>	
	)
}

export default UploadFile ;