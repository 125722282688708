import React, { useState, useEffect, useContext } from 'react';
import { Button, Input, Row, Col, DropdownItem, DropdownToggle, Dropdown, DropdownMenu} from "reactstrap";
import general_fetch from '../../utils/fetch';
import classnames from "classnames";
import name_sort from '../../utils/name_sort';
import Multiselect from "../../components/Multiselect/";
// import Multiselect from 'multiselect-react-dropdown';
import { useAlert } from 'react-alert';
import './index.css';
import CardBody from 'reactstrap/lib/CardBody';
import CardFooter from 'reactstrap/lib/CardFooter';
import { UserContext } from '../Contexts/UserContext';

const multisel_style_json = {
	multiselectContainer: { // To change css for multiselect (Width,height,etc..)
		backgroundColor: 'white',
		fontSize:"12px"
	},
	searchBox: { // To change search box element look	
		
	},
	inputField: { // To change input field position or margin
		margin:0,
	},
	chips: { // To change css chips(Selected options)
		backgroundColor: '#6c757d',
		marginBottom:'5px',
		maxWidth:'180px',
		overflow :'auto',
	},
	optionContainer: { // To change css for option container 
        maxHeight:'200px',
        overflow:'auto'
	},
	option: { // To change css for dropdown options
		maxWidth:'100%',
		whiteSpace: "nowrap", 
		overflow: "hidden",
		textOverflow: "ellipsis", 
	},
	groupHeading: { // To chanage group heading style
	}
}  

const Common_tag_component = ({active_item, all_tags, assigned_tags, type, all_inherited_tags, seperated_inherited_tags, attach_tag_fn, handleclose}) => {

    const[all_tags_display , set_all_tags_display] = useState([]);
    const[selected_tag_array , set_selected_tag_array] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
	const alert = useAlert()
    const user = useContext(UserContext)

    const toggle = () => setDropdownOpen(prevState => !prevState);

    useEffect(() => {
		if(active_item){
            
		}
	},[active_item])

    // useEffect(() => {
	// 	if(all_tags && all_tags.length && all_inherited_tags && all_inherited_tags.length){
    //         var x_inherited = all_inherited_tags.map(x => x.id)
    //         var x_display = all_tags.filter(x => !(x_inherited.includes(x.id)))
    //         set_all_tags_display(x_display)
	// 	}else{
    //         set_all_tags_display(all_tags)
    //     }
	// },[all_tags, all_inherited_tags])

    const onclick_update_tags = () => {
        if(selected_tag_array ){
            // var previous_assigned_ids = assigned_tags && assigned_tags.length?assigned_tags.map(x=>x.id):[];
            // var new_assigned_ids = selected_tag_array && selected_tag_array.length?selected_tag_array.map(x=>x.id):[];
            // if(previous_assigned_ids && previous_assigned_ids.length){
            //     var attact_item, detatch_item = [];
            //     attact_item = selected_tag_array.length ? selected_tag_array.filter(x => !(previous_assigned_ids.includes(x.id))):[]
            //     detatch_item = assigned_tags.filter(x => !(new_assigned_ids.includes(x.id)))
            //     if(attact_item.length){
            attach_tag_fn(selected_tag_array)
            set_selected_tag_array([])
            //     }
            //     if(detatch_item.length){
            //         detatch_tag_fn(detatch_item)
            //     }
            // }else{
            //     attach_tag_fn(selected_tag_array)
            // }
        }else{
            alert.info('Please Select Tag')
        }
    }

    // useEffect(() => {
	// 	if(unassigned_tags && unassigned_tags.length){
    //         console.log(unassigned_tags)
	// 	}
	// },[unassigned_tags])

    useEffect(() => {
		if(seperated_inherited_tags){
            console.log(seperated_inherited_tags)
		}
	},[seperated_inherited_tags])

    useEffect(() => {
		if(assigned_tags && assigned_tags.length){
            set_selected_tag_array(assigned_tags)
		}
	},[assigned_tags])

    const onselect_multi_source_tags = (selectedList_1) => {
		// console.log('1 caalllll', selectedList_1)
		set_selected_tag_array(selectedList_1)
	}
	
	const onclick_handleclose = () => {
        handleclose()
	}



  	return (
        <React.Fragment>
            <CardBody className='global_modal_body'>        
                {
                    all_tags && all_tags.length>0 ?
                    (
                        <Row style ={{width:"100%", margin:0, marginBottom:'20px'}}>
                            <Col xs = "3" style={{padding:0}}>
                                <label>Manage Tags : </label>	
                            </Col>
                            <Col xs = "9" style={{padding:0}}>
                                <Multiselect
                                    style={{backgroundColor:'white'}}
                                    options={active_item && active_item.store_id === user.store_id ? all_tags : all_tags.filter(x => x.store_id === user.store_id)}
                                    selectedValues={selected_tag_array} 
                                    onSelect={onselect_multi_source_tags} 
                                    onRemove={onselect_multi_source_tags}
                                    displayValue="tag" // Property name 
                                    showCheckbox
                                    id='manage_sku_tags_input'
                                    isObject='false'
                                    closeOnSelect={false}
                                    showArrow={false}
                                    style={multisel_style_json}
                                    avoidHighlightFirstOption={true}
                                    placeholder='Select Tag'
                                />
                            </Col>
                        </Row>	
                    ):
                    'All Tags are inherited. No Tags to assign.'
                }
                
                {
                    active_item && seperated_inherited_tags && seperated_inherited_tags[active_item.id]?
                    <Row style={{margin:0}}>
                        <Col xs={3} style={{padding:0}}>
                            <label>Inherited Tags : </label>	
                        </Col>
                        <Col xs={6} style={{padding:0, display:'flex', alignItems:'center'}}>
                            <Dropdown direction="down" nav isOpen={dropdownOpen} style={{listStyle: 'none', width:'100%'}} toggle={toggle}>
                                <DropdownToggle  style={{display:'flex',flexFlow:'row', backgroundColor:'white',cursor:'pointer', color:'black', fontSize:'13px', padding:'4px 10px', border:0, width:'100%'}}>
                                    <span>Inherited Tags</span>
                                </DropdownToggle>
                                <DropdownMenu className='dropdown_menu_for_commomtag'>
                                    {
                                        type==='sku_sub_category' || type==='sku_group' ||type==='sku'?
                                        <React.Fragment>
                                            <DropdownItem style={{fontSize:'12px', color:'#777'}}>Category Tags</DropdownItem>
                                            {
                                                seperated_inherited_tags[active_item.id].sku_category_tags && seperated_inherited_tags[active_item.id].sku_category_tags.length ? 
                                                seperated_inherited_tags[active_item.id].sku_category_tags.map((tag_item,idx) => (
                                                    <DropdownItem style={{fontSize:'12px',listStyle: 'none'}} key={idx}>
                                                        {tag_item.tag}
                                                    </DropdownItem>
                                                ))
                                                :
                                                <div style={{padding:'10px', fontSize:'12px', textAlign:'center'}}>No inherited tags</div>
                                            }
                                        </React.Fragment>
                                        :''
                                    }
                                    {
                                        type==='sku_group' ||type==='sku'?
                                        <React.Fragment>
                                            <DropdownItem style={{listStyle: 'none'}} divider />
                                            <DropdownItem style={{fontSize:'12px', color:'#777'}}>Sub Category Tags</DropdownItem>
                                            {
                                                seperated_inherited_tags[active_item.id].sku_sub_category_tags && seperated_inherited_tags[active_item.id].sku_sub_category_tags.length ? 
                                                seperated_inherited_tags[active_item.id].sku_sub_category_tags.map((tag_item,idx) => (
                                                    <DropdownItem style={{fontSize:'12px',listStyle: 'none'}} key={idx}>
                                                        {tag_item.tag}
                                                    </DropdownItem>
                                                ))
                                                :
                                                <div style={{padding:'10px', fontSize:'12px', textAlign:'center'}}>No inherited tags</div>
                                            }
                                        </React.Fragment>
                                        :''
                                    }
                                    {
                                        type==='sku'?
                                        <React.Fragment>
                                            <DropdownItem style={{listStyle: 'none'}} divider />
                                            <DropdownItem style={{fontSize:'12px', color:'#777'}}>Group Tags</DropdownItem>
                                            {
                                                seperated_inherited_tags[active_item.id].sku_group_tags && seperated_inherited_tags[active_item.id].sku_group_tags.length ? 
                                                seperated_inherited_tags[active_item.id].sku_group_tags.map((tag_item,idx) => (
                                                    <DropdownItem style={{fontSize:'12px',listStyle: 'none'}} key={idx}>
                                                        {tag_item.tag}
                                                    </DropdownItem>
                                                ))
                                                :
                                                <div style={{padding:'10px', fontSize:'12px', textAlign:'center'}}>No inherited tags</div>
                                            }
                                        </React.Fragment>
                                        :''   
                                    }
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Row>
                    :
                    ''
                    // <div>
                    //     {
                    //         type!='sku_category'?
                    //             <div style={{padding:'10px 0px', fontSize:'14px'}}>No Inherited tags</div>
                    //         :''
                    //     }
                    // </div>
                }
            </CardBody>	
            <CardFooter className='global_modal_footer'>
                <Button className='blue_button' onClick = {onclick_update_tags}>Update Tags</Button>
            </CardFooter>
        </React.Fragment>
  	);
}

export default Common_tag_component;