import { logToSentry } from "./error"

const comp_file_top_level_elem = (sku_properties) => {
	let component_file = sku_properties && sku_properties.component_file
	let top_level_elements = []
	if(component_file && component_file.length){
		for(let i = 0; i < component_file.length; i++) {
			let container_component_id = component_file[i].container_component_id
			if(!container_component_id || !component_file.find(o => o.id === container_component_id)){
				top_level_elements.push(component_file[i])
			}
		}
	}

	if(top_level_elements && top_level_elements.length){
		
		if(top_level_elements.length === 1) return top_level_elements[0]

		let x = top_level_elements.find(o => o.type === 'cabinet')
		if(x) return x

		x = top_level_elements.find(o => o.type === 'corner_unit')
		if(x) return x

		x = top_level_elements.find(o => o.type === 'obj_unit')
		if(x) return x

		x = top_level_elements.find(o => o.type === 'panel')
		if(x) return x

		// return false
	}

	if(component_file && component_file.length){
		console.log(`Invalid component file detected - ${JSON.stringify(sku_properties)}`)
		logToSentry(`Invalid component file detected ------ sku_details -> ${JSON.stringify(sku_properties)}`)
	}

	return false
}

export default comp_file_top_level_elem