import React , { useState , useEffect, useContext }from 'react';
import {Modal, Button, Input,  Card, CardBody, CardHeader, CardFooter, Col, Row } from "reactstrap";
import Multiselect from "../../components/Multiselect/";

import general_fetch from '../../utils/fetch';
import is_valid_add_key from '../../utils/is_valid_add_key';
import { useAlert } from 'react-alert'
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { UserContext } from '../../components/Contexts/UserContext';

const KeyValuePair = ({item_json, set_item_json, mode, all_allowed_key}) => {
    const alert = useAlert();
    const [all_key_options, set_all_key_options] = useState(all_allowed_key);

    const add_new_item_json = () => {
        if(is_valid_add_key(item_json)){
            let a = [...item_json];
            a.push({key:''})
            set_item_json(a);
        }else{
            alert.error("Empty or Duplicate key values not allowed.")
        }
	}

    useEffect(() =>{
		if(item_json && !item_json.length){
			add_new_item_json()
		}
		// console.log('item_json=======>',item_json)
	},[item_json])

	const delete_item_json = (index) => {
		let a = [...item_json];
		// console.log('item_json=======>',item_json,a)
		a.splice(index,1)
		// console.log('item_json=======>',item_json, a)
		set_item_json(a);
	}

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
	}

    return(
            <div>
                {
					item_json && item_json.length ? item_json.map((x,idx) => (
						<div key={idx} style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:idx===0?0:'10px'}}>
								<Input id={'box1'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px', marginRight:'60px'}} type="text" 
									value={item_json[idx].key}
									onChange={(e) =>update_item_json(idx,'key', e.target.value) }
								/>
								<span style={{cursor:'pointer'}} onClick={() =>delete_item_json(idx)}><i className='fa fa-trash'/></span>
						</div>
                	)):''
				}
                <Row style={{margin:0}}>
                    <Col style={{textAlign:'left', padding:'5px 10px 10px 10px', paddingLeft:0}}>
                        <span onClick={add_new_item_json}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add Value +</span>
                    </Col>
                </Row>
            </div>
    )
}

const DesignerInputDialogBox = ({open , handleClose , active_designer_input , fetch_designer_input_details , mode, all_category_type, all_brands}) => {

	const[designer_input_name , set_designer_input_name] = useState('');
	const[selected_category_type , set_selected_category_type] = useState('');
	const[selected_di_type , set_selected_di_type] = useState('brand');
	const[selected_brand_array , set_selected_brand_array] = useState([]);
	const[custom_value_list , set_custom_value_list] = useState([]);
	const alert = useAlert();
	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)

	useEffect(() => {
		if(selected_di_type==='brand'){
			set_designer_input_name('Brand')
		}else if(selected_di_type==='custom'){
			if(active_designer_input){
				set_designer_input_name(active_designer_input.name)
			}else{
				set_designer_input_name('')
			}
		}else if(selected_di_type === 'Label'){
			set_designer_input_name('Label')
		}
	},[selected_di_type])

	useEffect (() => {
		if(active_designer_input){
			console.log('dibox=========',active_designer_input)
			set_selected_di_type(active_designer_input.type)
			if(active_designer_input.sku_category_type_id){
				set_selected_category_type(active_designer_input.sku_category_type_id)
			}
			else{
				set_selected_category_type(all_category_type && all_category_type.length ? all_category_type[0].id:'')
			}
			if(active_designer_input.type === 'custom' || active_designer_input.type === 'Label'){
				var x_sel_val = active_designer_input.valid_values && active_designer_input.valid_values.length? active_designer_input.valid_values.map(x => {
					return {'key': x }
				}):[]
				set_custom_value_list(x_sel_val)
			}else if (active_designer_input.type === 'brand'){
				var x_sel_brands = active_designer_input.valid_values && active_designer_input.valid_values.length && all_brands && all_brands.length? all_brands.filter(x => active_designer_input.valid_values.includes(x.id)):[];
				console.log("setting selected brands ---> ", x_sel_brands, all_brands);
				set_selected_brand_array(x_sel_brands)
			}
		}else{
			set_selected_category_type(all_category_type && all_category_type.length ? all_category_type[0].id:'')
		}
	},[active_designer_input]);

	// useEffect(() => { 
	// 	if(!selected_category_type && all_category_type && all_category_type.length && open){
	// 		console.log('set_selected_category_type', all_category_type[0].id)
	// 		set_selected_category_type(all_category_type[0].id)
	// 	}
	// },[all_category_type,open])

	useEffect(() => { 
		if(!selected_category_type && all_category_type && all_category_type.length && open && !active_designer_input){
			console.log('set_selected_category_type', all_category_type[0].id)
			set_selected_category_type(all_category_type[0].id)
		}
	},[all_category_type,open])


	const add_name = async () => {
		if(selected_brand_array.length || custom_value_list.length){
			try {
				var value_list = custom_value_list.map(x => x.key)
				var brand_list = selected_brand_array.map(x => x.id)
				var body = {
					name:designer_input_name,
					sku_category_type_id:selected_category_type,
					valid_values: selected_di_type==='brand'?brand_list:value_list,
					type:selected_di_type
				}
				if(org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
				var response = await general_fetch({ url: 'automation_rule/create_rule_input', body});
				alert.success('Designer Input added successfully')
				fetch_designer_input_details();
				onclick_handleclose();
			} catch(err) { 
				console.log(err);
				alert.error('Api Failed')
			}
		}else{
		
		}	
	}

	const update_name = async () => {
		if(selected_brand_array.length || custom_value_list.length){
			try {
				let value_list = custom_value_list.map(x => x.key)
				let brand_list = selected_brand_array.map(x => x.id)
				var body = {
					rule_input_id:active_designer_input.id,
					name:designer_input_name,
					sku_category_type_id:selected_category_type,
					valid_values: selected_di_type==='brand'?brand_list:value_list,
					// valid_values: selected_di_type==='brand'?selected_brand_array.map(x => x.id):custom_value_list,
					type:selected_di_type
				}	
				var response = await general_fetch({ url: 'automation_rule/update_rule_input', body});
				alert.success('Designer Input updated successfully')
				fetch_designer_input_details();
				onclick_handleclose();

			} catch(err) {	
				alert.error('Api Failed')
				console.log(err);
			}
		}else{
			
		}
	}

	const onclick_item = (list) => {
		set_selected_brand_array(list)
	}

	const onclick_handleclose = () => {
		handleClose()
		set_designer_input_name('Brand');
		set_selected_category_type('');
		set_selected_di_type('brand');
		set_selected_brand_array([]);
		set_custom_value_list([]);

	}

  	return (
  		<div>
  			<Modal className="Margin_64px_top" size="md" isOpen={open} toggle={onclick_handleclose}>
				<Card className='global_modal_height_for_mdlgxl'>
					<CardHeader className='global_modal_header'>
							<span>
								<span>{(mode=='add') ? "Add Designer Input" : "Update Designer Input - "}</span>
								<span>{mode=='add' ? null : active_designer_input ? active_designer_input.name: ''}</span>
							</span>
							<i onClick={onclick_handleclose} style={{cursor: 'pointer'}} className='fa fa-times'/>
					</CardHeader>

					<CardBody className='global_modal_body'>
                        <Row>
							{mode == 'add' ? (
								<Col>
									<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select Category Type*</div>
									<Input 
										style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} 
										placeholder="Tag Name" 
										type="select" 
										value={selected_category_type} 
										onChange={e => set_selected_category_type(e.target.value)}
									>
										{
											all_category_type && all_category_type.length ? all_category_type.map((item ,idx) => (
												<option key={idx} value={item.id}>{item.display_name}</option>
											))
											:''
										}
									</Input>	
								</Col>	
							): (
								<Col style={{ fontSize:'12px', color:'#435A6F'}} >
									Category Type - {selected_category_type ? all_category_type.filter(x => x.id == selected_category_type)[0]?.display_name ?? '' : ''}
								</Col>
							)}
                        </Row>
						<Row style={{marginTop:'10px'}}>
                        	<Col>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select Designer Input Type*</div>
								<Input 
									style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} 
									placeholder="Tag Name" type="select" 
									value={selected_di_type} 
									onChange={e => set_selected_di_type(e.target.value)}
								>
									<option value='brand'>Brand</option>
									<option value='Label'>Label</option>
									<option value='custom'>Custom</option>
								</Input>	
                        	</Col>	
                        </Row>
						
						<Row style={{marginTop:'10px'}}>
							<Col>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Designer Input Name*</div>
								<Input 
									style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} 
									placeholder="Name" type="text" 
									value={designer_input_name} 
									onChange={selected_di_type!='custom'?null:e => set_designer_input_name(e.target.value)}
									disabled={selected_di_type!='custom'?true:false}
								/>
							</Col>	
						</Row>
							
						{
							selected_di_type==='brand'?
							<Row style={{marginTop:'10px'}}>
								<Col>
									<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select Brand*</div>
									<Multiselect
										style={{backgroundColor:'white', width:'100%'}}
										options={all_brands && all_brands.length? JSON.parse(JSON.stringify(all_brands)):[]} 
										selectedValues={selected_brand_array}
										onSelect={onclick_item}
										onRemove={onclick_item}
										displayValue='name'// Property name 
										id='select_brand'
										placeholder='Select Brand'
									/>	
								</Col>	
							</Row>
							:
							<Row style={{marginTop:'10px'}}>
								<Col>
									<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Enter Custom value(s) *</div>
									<KeyValuePair item_json={custom_value_list} set_item_json={set_custom_value_list} mode ={mode} />
								</Col>	
							</Row>
						}
					</CardBody>

					<CardFooter className='global_modal_footer'>
						<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>
						{ 
							mode == 'edit' ?
							<Button className='blue_button' type="button" onClick={update_name}>Update</Button> : 
							<Button className='blue_button' type="button" onClick={add_name}>Add</Button>
						}		
					</CardFooter>
				</Card>  
			</Modal>
		</div>	
  	);
}

export default DesignerInputDialogBox ; 
