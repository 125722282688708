import React, { useContext, useEffect, useState } from "react";
import BulkEditSkuStructure from "../Catalogue/BulkEditModal/BulkEditSkuStructure"
import { Button } from "reactstrap";
import general_fetch from "../../utils/fetch";
import { OrgBuContext } from "../../components/Contexts/OrgBuContext";
import { UserContext } from "../../components/Contexts/UserContext";

const BulkEditSkuStructureParent = ({set_page_loader}) => {
	const [edited_sku_structure, set_edited_sku_structure] = useState({});
	const [edited_sku_structure_partition_array, set_edited_sku_structure_partition_array] = useState([]);
    const [edited_sku_structure_panel_array, set_edited_sku_structure_panel_array] = useState([]);
    const [all_division_tree_data, set_all_division_tree_data] = useState([]);
    const [show_only_edited_in_sku_structure, set_show_only_edited_in_sku_structure] = useState(false);
	const [common_props_confirmation_mode, set_common_props_confirmation_mode] = useState();
	const [corner_units, set_corner_units] = useState([]);
	const [final_edited_sku_structure, set_final_edited_sku_structure] = useState();
	const [show_sku_structure_in_confirmation, set_show_sku_structure_in_confirmation] = useState();
	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)

	function removeEmptyValues(obj) {
        if (typeof obj !== 'object' || obj === null) {
          return obj;
        }
      
        if (Array.isArray(obj)) {
          let resultArray = obj.map(removeEmptyValues).filter(x => {
            if(typeof x !== 'object'){
                if (x !== undefined && x !== null && x !== '') {
                    return true
                }
            }else{
                return removeEmptyValues(x)
            }
          });
          
          if(resultArray.length) return resultArray

          return null
        }
      
        const result = {};
      
        for (const key in obj) {
          const value = removeEmptyValues(obj[key]);
      
          if (value !== undefined && value !== null && value !== '') {
            result[key] = value;
          }
        }
      
        // Remove empty objects
        if (Object.keys(result).length === 0) {
          return undefined;
        }
      
        return result;
    }

	const fetch_all_subcategories = async() => {
        try{
            let body = {}
			if(org_bu_view == 'bu'){
				body['business_unit_id'] = user.current_business_unit_id
			}
            var response = await general_fetch({ url: 'inventory/get_all_sub_categories', body});	
			set_all_division_tree_data(response)
        }catch(err){
            console.log(err)
        }
    }

	const bulk_edit_fn = () => {
		set_common_props_confirmation_mode(true)
		let non_empty_edited_sku_structure = removeEmptyValues(edited_sku_structure)
		if(!non_empty_edited_sku_structure){
			non_empty_edited_sku_structure = {}
		}
		let non_empty_edited_sku_structure_partition = edited_sku_structure_partition_array.map(item => removeEmptyValues(item)).filter(x => x)
		let non_empty_edited_sku_structure_panel = edited_sku_structure_panel_array.map(item => removeEmptyValues(item)).filter(x => x)
		if(non_empty_edited_sku_structure_partition.length) non_empty_edited_sku_structure["partitions"] = non_empty_edited_sku_structure_partition
		if(non_empty_edited_sku_structure_panel.length) non_empty_edited_sku_structure["panels"] = non_empty_edited_sku_structure_panel
		console.log("non empty edited sku structure final", non_empty_edited_sku_structure)
		let temp_final_sku_structure = JSON.stringify(non_empty_edited_sku_structure)
		// set_final_edited_sku_structure_for_cypress(temp_final_sku_structure)
		if(temp_final_sku_structure != JSON.stringify({})){
			set_final_edited_sku_structure([non_empty_edited_sku_structure])
			set_show_sku_structure_in_confirmation(true)
		}
	}

	const publish_changes_and_exit = () => {
		window.parent.postMessage(JSON.stringify(final_edited_sku_structure), '*')
    }

	useEffect(() => {
		if(org_bu_view && user) fetch_all_subcategories()
	}, [user, org_bu_view]);

	useEffect(() => {
		let currentURL = new URL(document.URL)
		if(currentURL.searchParams.get('element_type') == 'corner_unit'){
			set_corner_units(['corner_unit'])
		}
	}, []);

	return(
		<div style={{height:'100vh', width:'100vw', position:'absolute', zIndex:'100', backgroundColor:'white'}}>
			<div style={{height:'calc(100% - 56px)', overflow:'scroll'}}>
				<BulkEditSkuStructure 
					set_show_only_edited_in_sku_structure = {set_show_only_edited_in_sku_structure}
					show_only_edited_in_sku_structure = {show_only_edited_in_sku_structure}
					common_props_confirmation_mode = {common_props_confirmation_mode}
					edited_sku_structure = {edited_sku_structure}
					set_edited_sku_structure = {set_edited_sku_structure}
					edited_sku_structure_partition_array = {edited_sku_structure_partition_array}
					set_edited_sku_structure_partition_array = {set_edited_sku_structure_partition_array}
					edited_sku_structure_panel_array = {edited_sku_structure_panel_array}
					set_edited_sku_structure_panel_array = {set_edited_sku_structure_panel_array}
					corner_units = {corner_units}
					all_division_tree_data={all_division_tree_data}
					set_page_loader={set_page_loader}
				/>
			</div>
			<div className="flex_property" style={{justifyContent:'end', height:'56px', position:'sticky', bottom:'0px', marginRight:'16px'}}>
				{
					common_props_confirmation_mode?
					<Button className='white_button' onClick={() => {set_common_props_confirmation_mode(false);set_show_only_edited_in_sku_structure(false)}}>Go back</Button>
					:''	
				}
				{
                    common_props_confirmation_mode?
                    <Button className='blue_button' id='bulk-edit-sku-struct-publish-changes' disabled={!show_sku_structure_in_confirmation} onClick={() => {publish_changes_and_exit()}}>Publish Changes and exit</Button>
                    :
                    <Button className='blue_button' id='bulk-edit-sku-struct-proceed-to-confirmation' onClick={() => {set_show_only_edited_in_sku_structure(true); bulk_edit_fn()}}>Proceed to confirmation</Button>
                }
			</div>
		</div>
	)
}

export default BulkEditSkuStructureParent