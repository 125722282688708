import React from 'react';
import './style.css'
const FillerEmptyState = ({ message }) => {
    return (
        <div className='filler_empty_state'>
            {message}
        </div>
    )
}

export default FillerEmptyState