import React, { useContext } from 'react';
import general_fetch from '../fetch';
import { UserContext, useUser } from '../../components/Contexts/UserContext';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';

export const fetch_sku_categories = async (division_ids) => {
    try {
        let user = global.get_user()
        let org_bu_view = window.sessionStorage.getItem('org_bu_view') || 'bu'

        let body = {
            sku_division_id: division_ids
        }
        if (org_bu_view === 'bu') {
            body['business_unit_id'] = user.current_business_unit_id
        }
        let response = await general_fetch({ url: 'inventory/get_categories', body });
        response = (response && response.length) ? response : [];
        return response;
    } catch (error) {
        console.error("Error in fetching sku categories", error);
        return Promise.reject(error);
    }
}

export const fetch_sku_groups = async (id) => {
    try {
        if(id){
            let user = global.get_user()
            let org_bu_view = window.sessionStorage.getItem('org_bu_view') || 'bu'
            let body = { sku_sub_category_id:id }
            if(org_bu_view === 'bu'){
                body['business_unit_id'] = user.current_business_unit_id;
            }
            var response = await general_fetch({ url: 'inventory/get_groups' , body });
            var resp =response && response.length ?response.filter(x => x.depricated !=1):[]
            return resp;
        }
    } catch(err) {
        console.error("Error in fetching groups", err);
        return Promise.reject(err);
    }
}

export const remove_sku = async (sku_id) => {
    try{
        let user = global.get_user()
        let org_bu_view = window.sessionStorage.getItem('org_bu_view') || 'bu'
        if (sku_id) {
            let body = {
                sku_ids: [sku_id]
            }
            if(org_bu_view == 'bu'){
                body['business_unit_id'] = user.current_business_unit_id
                var reponse = await general_fetch({url :'sku/remove_from_business_unit' , body })
            }else if(org_bu_view == 'org'){
                var reponse = await general_fetch({url :'sku/remove_from_store' , body })
            }
        }
    }catch(err){
        console.error("Error in deleting sku", err)
        return Promise.reject(err);
    }
}

export const get_sku_ancestry = async(sku_ids) => {
    try{
        if(sku_ids && sku_ids.length){
            let resp = await general_fetch({url:'inventory/get_sku_ancestry', body:{sku_id: sku_ids}})
            return resp && resp.length ? resp : []
        }
    }catch(err){
        console.error("Error in fetching sku ancestry tree", err);
        return Promise.reject(err);
    }
}

export const fetch_sku_sub_categories = async (sku_category_id) => {
    try {
        if (sku_category_id) {
        let user = global.get_user()
        let org_bu_view = window.sessionStorage.getItem('org_bu_view') || 'bu'
        let body = { sku_category_id }
            if (org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
            var response = await general_fetch({ url: 'inventory/get_sub_categories', body });
            var depricated_filter_output = response && response.length ? response.filter(x => x.deprecated != 1) : []
            return depricated_filter_output
        }
    } catch (error) {
        console.error("Error in fetching sku subcategories", error);
        return Promise.reject(error);
    }
}

export const fetch_all_subcategories = async() => {
    try{
        let user = global.get_user()
        let org_bu_view = window.sessionStorage.getItem('org_bu_view') || 'bu'
        let body = {}
        if(org_bu_view == 'bu'){
            body['business_unit_id'] = user.current_business_unit_id
        }
        var response = await general_fetch({ url: 'inventory/get_all_sub_categories', body});	
        return response;
    }catch(err){
        console.log(err)
        return Promise.reject(err);
    }
}

export const fetch_skus = async (skus, return_depricated = false) => {
    try {
        if (skus) {
            let user = global.get_user()
            let org_bu_view = window.sessionStorage.getItem('org_bu_view') || 'bu'

            let body = {}

            let unique_sku_array = [... new Set(skus)]
            let identifiers = JSON.stringify({ id: unique_sku_array })

            body = { identifiers }

            if(org_bu_view === 'bu'){
                body['business_unit_id'] = user.current_business_unit_id;
            }

            let response = await general_fetch({ url: 'sku/get', body });

            if(return_depricated){
                return response
            }

            let depricated_filter_output = response && response.length ? response.filter(x => x.deprecated != 1) : []
            return depricated_filter_output
        }
    } catch (error) {
        console.error("Error in fetching sku details", error);
        return Promise.reject(error)
    }
}