const rule_type_json_dc_rules = {
	ply_to_finish: {
		id:'ply_to_finish',
		name:'Ply to Finish',
		source:{
			component_id:'cabinet',
			component_name:'Cabinet',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		source_options: [{
			component_id:'cabinet',
			component_name:'Cabinet',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'wardrobe',
			component_name:'Wardrobe',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},{
			component_id:'custom_panel_standalone',
			component_name:'Custom Panel (standalone)',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'panel',
			component_name:'Panel',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'panel',
			component_name:'Panel',
			subcomponent_id:'panel',
			subcomponent_name:'Panel',
			property:[
				{
					id:'panel_type',
					name:'Panel Type',
					type:'singleselect',
					options: [{
						name: 'Exposed Panel',
						id: 'exposed_panel',
					}, {
						name: 'Shelf',
						id: 'shelf',
					}, {
						name: 'Carcass Panel',
						id: 'carcass_panel',
					}, {
						name: 'Vertical Partition Panel',
						id: 'vertical_partition_panel',
					}, {
						name: 'Skirting Panel',
						id: 'skirting_panel',
					}]
				}
			]
		}],
		dest:{
			component_id:'cabinet',
			component_name:'Cabinet',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		destination_options: [{
			component_id:'cabinet',
			component_name:'Cabinet',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'wardrobe',
			component_name:'Wardrobe',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},{
			component_id:'custom_panel_standalone',
			component_name:'Custom Panel (standalone)',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'panel',
			component_name:'Panel',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}]	
	},
	shutter_design_to_cabinet: {
		id:'shutter_design_to_cabinet',
		name:'Shutter Design to Cabinet',
		source:{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		source_options: [{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}],
		dest:{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'cabinet',
			subcomponent_name:'Cabinet',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		destination_options: [{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'cabinet',
			subcomponent_name:'Cabinet',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'wardrobe',
			subcomponent_name:'Wardrobe',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'cabinet',
			subcomponent_name:'Cabinet',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'wardrobe',
			subcomponent_name:'Wardrobe',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}]	
	},
	'3d_model_to_finish': {
		id:'3d_model_to_finish',
		name:'3D Model to Finish',
		source:{
			component_id:'3d_model',
			component_name:'3D Model',
			subcomponent_id:'3d_model',
			subcomponent_name:'3D Model',
			property:[
				{
					id:'no_condition',
					name:'No Condition',
				}
			]
		},
		dest:{
			component_id:'3d_model',
			component_name:'3D Model',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}	
	},
	finish_to_ply: {
		id:'finish_to_ply',
		name:'Finish to Ply',
		source:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		source_options: [{
			component_id:'cabinet',
			component_name:'Cabinet',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'wardrobe',
			component_name:'Wardrobe',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},{
			component_id:'custom_panel_standalone',
			component_name:'Custom Panel (standalone)',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'panel',
			component_name:'Panel',
			subcomponent_id:'panel',
			subcomponent_name:'Panel',
			property:[
				{
					id:'panel_type',
					name:'Panel Type',
					type:'singleselect',
					options: [{
						name: 'Exposed Panel',
						id: 'exposed_panel',
					}, {
						name: 'Shelf',
						id: 'shelf',
					}, {
						name: 'Carcass Panel',
						id: 'carcass_panel',
					}, {
						name: 'Vertical Partition Panel',
						id: 'vertical_partition_panel',
					}, {
						name: 'Skirting Panel',
						id: 'skirting_panel',
					}, {
						name: 'End Panel',
						id: 'end_panel',
					}]
				}
			]
		}, {
			component_id:'panel',
			component_name:'Panel',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}],
		dest:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		destination_options: [{
			component_id:'cabinet',
			component_name:'Cabinet',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'wardrobe',
			component_name:'Wardrobe',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},{
			component_id:'custom_panel_standalone',
			component_name:'Custom Panel (standalone)',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'panel',
			component_name:'Panel',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}]	
	},
	shutter_design_to_finish:{
		id:'shutter_design_to_finish',
		name:'Shutter Design to Finish',
		source:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		source_options: [{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}],
		dest:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		destination_options: [{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}]
	},
	shutter_design_to_handle:{
		id:'shutter_design_to_handle',
		name:'Shutter Design to Handle',
		source:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				},
				{
					id:'no_condition',
					name:'No Condition',
					type:'no_condition',
				}
			]
		},
		dest:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'handle',
			subcomponent_name:'Handle',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		source_options: [{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				},
				{
					id:'no_condition',
					name:'No Condition',
					type:'no_condition',
				}
			]
		}, {
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				},
				{
					id:'no_condition',
					name:'No Condition',
					type:'no_condition',
				}
			]
		}],
		destination_options: [{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'handle',
			subcomponent_name:'Handle',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'handle',
			subcomponent_name:'Handle',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}]
	},
	shutter_to_shutter_design:{
		id:'shutter_to_shutter_design',
		name:'Shutter to Shutter Design',
		source:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter',
			subcomponent_name:'Shutter',
			property:[
				{
					id:'height',
					name:'Height',
					type:'number',
				},
				{
					id:'width',
					name:'Width',
					type:'number',
				}
			]
		},
		dest:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	},
	wardrobe_to_shutter_design:{
		id:'wardrobe_to_shutter_design',
		name:'Wardrobe to Shutter Design',
		source:{
			component_id:'wardrobe',
			component_name:'Wardrobe',
			subcomponent_id:'wardrobe',
			subcomponent_name:'Wardrobe',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		dest:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		destination_options: [{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}]
	},
	wardrobe_to_shutter_system_configuration:{
		id:'wardrobe_to_shutter_system_configuration',
		name:'Wardrobe to Shutter System Configuration',
		source:{
			component_id:'wardrobe',
			component_name:'Wardrobe',
			subcomponent_id:'default_partition',
			subcomponent_name:'Default Partition',
			property:[
				{
					id: 'no_condition',
					name: 'No Condition',
				}
			]
		},
		dest:{
			component_id:'shutter_system',
			component_name:'Shutter System',
			subcomponent_id:'shutter_system_configuration',
			subcomponent_name:'Shutter System Configuration',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	},
	drawer_to_drawer_fascia_design:{
		id:'drawer_to_drawer_fascia_design',
		name:'Drawer to Drawer Fascia Design',
		source:{
			component_id:'partition',
			component_name:'Partition',
			subcomponent_id:'drawer',
			subcomponent_name:'Drawer',
			property:[
				{
					id:'height',
					name:'Height',
					type:'number',
				},
				{
					id:'width',
					name:'Width',
					type:'number',
				}
			]
		},
		dest:{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'drawer_fascia_design',
			subcomponent_name:'Drawer Fascia Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	},
	carcass_to_finish:{
		id:'carcass_to_finish',
		name:'Carcass to Finish',
		source:{
			component_id:'carcass_excluding_back_panel',
			component_name:'Carcass Excluding Back Panel',
			subcomponent_id:'carcass_excluding_back_panel',
			subcomponent_name:'Carcass Excluding Back Panel',
			property:[{id:'no_condition',name:'No Condition'}]
		},
		dest:{
			component_id:'carcass_excluding_back_panel',
			component_name:'Carcass Excluding Back Panel',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	},carcass_to_ply:{
		id:'carcass_to_ply',
		name:'Carcass to Ply',
		source:{
			component_id:'carcass_excluding_back_panel',
			component_name:'Carcass Excluding Back Panel',
			subcomponent_id:'carcass_excluding_back_panel',
			subcomponent_name:'Carcass Excluding Back Panel',
			property:[{id:'no_condition',name:'No Condition'}]
		},
		dest:{
			component_id:'carcass_excluding_back_panel',
			component_name:'Carcass Excluding Back Panel',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	},
	shutter_system_to_finish:{
		id:'shutter_system_to_finish',
		name:'Shutter System to Finish',
		source:{
			component_id:'shutter_system',
			component_name:'Shutter System',
			subcomponent_id:'shutter',
			subcomponent_name:'Shutter',
			property:[{id:'no_condition',name:'No Condition'}]
		},
		dest:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		source_options:[
			{
				component_id:'shutter_system',
				component_name:'Shutter System',
				subcomponent_id:'shutter',
				subcomponent_name:'Shutter',
				property:[{id:'no_condition',name:'No Condition'}]
			},
			{
				component_id:'shutter_system',
				component_name:'Shutter System',
				subcomponent_id:'shutter_system_configuration',
				subcomponent_name:'Shutter System Configuration',
				property:[
					{
						id:'tag',
						name:'Tag',
						type:'multiselect',
					},
					{
						id:'no_condition',
						name:'No Condition'
					}
				]
			}
		]
	},
	shutter_system_to_ply:{
		id:'shutter_system_to_ply',
		name:'Shutter System to Ply',
		source:{
			component_id:'shutter_system',
			component_name:'Shutter System',
			subcomponent_id:'shutter',
			subcomponent_name:'Shutter',
			property:[{id:'no_condition',name:'No Condition'}]
		},
		dest:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		source_options:[
			{
				component_id:'shutter_system',
				component_name:'Shutter System',
				subcomponent_id:'shutter',
				subcomponent_name:'Shutter',
				property:[{id:'no_condition',name:'No Condition'}]
			},
			{
				component_id:'shutter_system',
				component_name:'Shutter System',
				subcomponent_id:'shutter_system_configuration',
				subcomponent_name:'Shutter System Configuration',
				property:[
					{
						id:'tag',
						name:'Tag',
						type:'multiselect',
					},
					{
						id:'no_condition',
						name:'No Condition'
					}
				]
			}
		]
	},
	shutter_design_and_finish_to_ply:{
		id:'shutter_design_and_finish_to_ply',
		name:'Shutter Design and Finish to Ply',
		source_options: [{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[{
				id:'tag',
				name:'Tag',
				type:'multiselect',
			}]
		}, {
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[{
				id:'tag',
				name:'Tag',
				type:'multiselect',
			}]
		}, {
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[{
				id:'tag',
				name:'Tag',
				type:'multiselect',
			}]
		}, {
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[{
				id:'tag',
				name:'Tag',
				type:'multiselect',
			}]
		}],
		destination_options: [{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[{
				id:'tag',
				name:'Tag',
				type:'multiselect',
			}]
		}, {
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[{
				id:'tag',
				name:'Tag',
				type:'multiselect',
			}]
		}],
		source:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[{
				id:'tag',
				name:'Tag',
				type:'multiselect',
			}]
		},
		dest:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	},
	cabinet_to_countertop:{
		id:'cabinet_to_countertop',
		name:'Cabinet to Countertop',
		source:{
			component_id:'cabinet',
			component_name:'Cabinet',
			subcomponent_id:'cabinet',
			subcomponent_name:'Cabinet',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				},
				{
					id:'no_condition',
					name:'No Condition',
				}
			]
		},
		dest: {
			component_id:'countertop',
			component_name:'Countertop',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	},
	cabinet_to_shutter_design:{
		id:'cabinet_to_shutter_design',
		name:'Cabinet to Shutter Design',
		source:{
			component_id:'cabinet',
			component_name:'Cabinet',
			subcomponent_id:'cabinet',
			subcomponent_name:'Cabinet',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		dest:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		destination_options: [{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}]
	},
	cabinet_to_shutter_system_configuration:{
		id:'cabinet_to_shutter_system_configuration',
		name:'Cabinet to Shutter System Configuration',
		source:{
			component_id:'cabinet',
			component_name:'Cabinet',
			subcomponent_id:'default_partition',
			subcomponent_name:'Default Partition',
			property:[
				{
					id: 'no_condition',
					name: 'No Condition',
				}
			]
		},
		dest:{
			component_id:'shutter_system',
			component_name:'Shutter System',
			subcomponent_id:'shutter_system_configuration',
			subcomponent_name:'Shutter System Configuration',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	},
	cabinet_to_accessory: {
		id:'cabinet_to_accessory',
		name:'Cabinet to Accessory',
		source:{
			component_id:'cabinet',
			component_name:'Cabinet',
			subcomponent_id:'cabinet',
			subcomponent_name:'Cabinet',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		dest:{
			component_id:'cabinet',
			component_name:'Cabinet',
			subcomponent_id:'accessory',
			subcomponent_name:'Accessory',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	},
	wardrobe_to_accessory: {
		id:'wardrobe_to_accessory',
		name:'Wardrobe to Accessory',
		source:{
			component_id:'wardrobe',
			component_name:'Wardrobe',
			subcomponent_id:'wardrobe',
			subcomponent_name:'Wardrobe',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		dest:{
			component_id:'wardrobe',
			component_name:'Wardrobe',
			subcomponent_id:'accessory',
			subcomponent_name:'Accessory',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	},
	shutter_design_to_shutter_ply:{
		id:'shutter_design_to_shutter_ply',
		name:'Shutter Design to Ply',
		source:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		dest:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		source_options: [{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}],
		destination_options: [{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'ply',	
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}]
	},
	finish_to_shutter_design:{
		id:'finish_to_shutter_design',
		name:'Finish to Shutter Design',
		source:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		dest:{
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		source_options: [{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}],
		destination_options: [{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}, {
			component_id:'shutter',
			component_name:'Shutter',
			subcomponent_id:'shutter_design',
			subcomponent_name:'Shutter Design',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}]
	},
	drawer_fascia_to_finish: {
		id:'drawer_fascia_to_finish',
		name:'Drawer Fascia to Finish',
		source:{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'drawer_fascia',
			subcomponent_name:'Drawer Fascia',
			property:[
				{
					id:'no_condition',
					name:'No Condition',
					type:'no_condition',
				}
			]
		},
		dest:{
			component_id:'drawer_fascia',	
			component_name:'Drawer Fascia',
			subcomponent_id:'finish',
			subcomponent_name:'Finish',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	},
	drawer_fascia_to_ply: {
		id:'drawer_fascia_to_ply',
		name:'Drawer Fascia to Ply',
		source:{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'drawer_fascia',
			subcomponent_name:'Drawer Fascia',
			property:[
				{
					id:'no_condition',
					name:'No Condition',
					type:'no_condition',
				}
			]
		},
		dest:{
			component_id:'drawer_fascia',
			component_name:'Drawer Fascia',
			subcomponent_id:'ply',
			subcomponent_name:'Ply',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	},
	panel_to_accessory: {
		id:'panel_to_accessory',
		name:'Panel to Accessory',
		source:{
			component_id:'panel',
			component_name:'Panel',
			subcomponent_id:'panel',
			subcomponent_name:'Panel',
			property:[
				{
					id:'panel_type',
					name:'Panel Type',
					type:'singleselect',
					options: [
					
					{
						name:'Bottom Panel',
						id:'bottom_panel',
					},{
						name:'Left Panel',
						id:'left_panel',
					},{
						name:'Right Panel',
						id:'right_panel',
					},{
						name:'Top Panel',
						id:'top_panel',
					},{
						name:'Back Panel',
						id:'back_panel',
					},{
						name: 'Exposed Panel',
						id: 'exposed_panel',
					},{
						name:'End Panel',
						id:'end_panel',
					},{
						name:'Finger Groove Panel',
						id:'finger_groove_panel'
					},{
						name: 'Carcass Panel',
						id: 'carcass_panel',
					},{
						name: 'Skirting Panel',
						id: 'skirting_panel',
					},{
						name: 'Vertical Partition Panel',
						id: 'vertical_partition_panel',
					},{
						name: 'Shelf',
						id: 'shelf',
					},{
						name: 'Fixed Shelf',
						id: 'fixed_shelf',
					},{
						name: 'Adjustable Shelf',
						id: 'adjustable_shelf',
					}]
				}
			]
		},
		dest:{
			component_id:'accessory',
			component_name:'Accessory',
			subcomponent_id:'accessory',
			subcomponent_name:'Accessory',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	},
	partition_to_partition_configuration:{
		id:'partition_to_partition_configuration',
		name:'Partition to Partition Configuration',
		source:{
			component_id:'partition',
			component_name:'Partition',
			subcomponent_id:'partition',
			subcomponent_name:'Partition',
			property:[
				{
					id:'no_condition',
					name:'No Condition',
					type:'no_condition',
				}
			]
		},
		dest:{
			component_id:'partition',
			component_name:'Partition',
			subcomponent_id:'partition_configuration',
			subcomponent_name:'Partition Configuration',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		}
	}
}

const component_tags_json_dc_rules = [
	{
		name:'Cabinet',
		id:'cabinet',
		sub_component_options:[
			{
				name:'Cabinet',
				id:'cabinet',
				property:[
					{name:'Tag',id:'tag',type:'multiselect'},
    				{id:'height', name:'Height',type:'number'},
					{id:'width', name:'Width',type:'number'},
					{id:'depth', name:'Depth',type:'number'},
					{id:'no_condition', name:'No Condition',type:'no_condition'}
				],

			},
		]
	},
	{
		name:'3D Model',
		id:'3d_model',
		sub_component_options:[
			{
				name:'3D Model',
				id:'3d_model',
				property:[
					{id:'height', name:'Height',type:'number'},
					{id:'width', name:'Width',type:'number'},
					{id:'depth', name:'Depth',type:'number'},
					{name:'Tag',id:'tag',type:'multiselect'},
					{id:'no_condition', name:'No Condition',type:'no_condition'}
				],

			},
		]
	},
	{
		name:'Wardrobe',
		id:'wardrobe',
		sub_component_options:[
			{
				name:'Wardrobe',
				id:'wardrobe',
				property:[
					{name:'Tag',id:'tag',type:'multiselect'},
    				{id:'height', name:'Height',type:'number'},
					{id:'width', name:'Width',type:'number'},
					{id:'depth', name:'Depth',type:'number'},
					{id:'no_condition', name:'No Condition',type:'select'}
				],

			},
		]
	},
	{
		name:'Custom Panel (standalone)',
		id:'custom_panel_standalone',
		sub_component_options:[
			{
				name:'Custom Panel (standalone)',
				id:'custom_panel_standalone',
				property:[
					{name:'Tag',id:'tag',type:'multiselect'},
					{id:'no_condition', name:'No Condition',type:'select'}
				],
			},
		]
	},
	{
		name:'Design',
		id:'design',
		sub_component_options:[
			{
				name:'Design',
				id:'design',
				property:[
					{name:'Sales Channel',id:'sales_channel',type:'multiselect'},
					{id:'no_condition', name:'No Condition',type:'select'}
				],
			},
		]
	}
]

export {rule_type_json_dc_rules, component_tags_json_dc_rules}