import Main_Grid from '../../components/Main_Grid';
import React, { useState, useEffect, useContext } from 'react';
import { get_all_automation_rules } from '../../utils/api/automation_rule';
import { useAlert } from 'react-alert';
import debug_log from '../../utils/debug_log';
import { Button, Card, Col, Input, Modal, Row } from 'reactstrap';
import { create_inventory_template, get_all_inventory_templates } from '../../utils/api/inventory_template';
import { ITD, ITH, ITable, ITableBody, ITableHeader, ITableRow } from '../../components/ITable';
import { IModalBody, IModalFooter, IModalHeader } from '../../components/IModal';
import { Checkbox } from 'antd';
import includes_search_string from '../../utils/includes_search_string';

const RulesSelectionTableSingleRow = ({ rule, rule_checked_map, search_string, mode = 'edit' }) => {
    const [checked, set_checked] = useState(false);

    const onCheckChange = (e) => {
        if (mode === 'edit') {
            e.stopPropagation();
            set_checked(!checked);
        }
    }

    useEffect(() => {
        if (mode === 'edit') {
            rule_checked_map[rule.id] = checked;
        }
    }, [checked]);

    return (
        <ITableRow style={{ cursor: 'pointer', display: includes_search_string(rule.name, search_string) || includes_search_string(rule.description, search_string) ? '' : 'none' }} onClick={onCheckChange}>
            {mode === 'edit' ? <ITD><Checkbox checked={checked} onChange={onCheckChange} /></ITD> : <ITD />}
            <ITD>{rule.name}</ITD>
            <ITD>{rule.description}</ITD>
        </ITableRow>
    )
}

const RulesSelectionTable = ({ rules, rule_checked_map, visibility, search_string, mode = 'edit' }) => {
    return (
        <ITable rowSeparated={true} hover={true} bordered={true} style_container={{ display: visibility ? 'block' : 'none' }}>
            <ITableHeader>
                <ITH></ITH>
                <ITH style={{ whiteSpace: 'nowrap' }}>Rule Name</ITH>
                <ITH>Rule Description</ITH>
            </ITableHeader>
            <ITableBody>
                {
                    rules && Array.isArray(rules) ? rules.map(rule => (
                        <RulesSelectionTableSingleRow key={rule.id} rule={rule} rule_checked_map={rule_checked_map} search_string={search_string} mode={mode} />
                    ))
                        : ''
                }
            </ITableBody>
        </ITable>
    )
}

const InventoryTemplatesTable = ({ templates, search_string, all_rules }) => {
    const [active_template_id, set_active_template_id] = useState('');

    const onClickViewRule = (template_id) => {
        set_active_template_id(template_id)
    }

    const closeViewRuleModal = () => {
        set_active_template_id('')
    }

    return (
        <React.Fragment>
            {/* all_rules && Array.isArray(all_rules) && active_template_id && templates[active_template_id] && templates[active_template_id].automation_rules ? all_rules.filter(x => templates[active_template_id].automation_rules.find(y => y.id === x.id)) : [] */}
            <CreateNewTemplateModal rules={active_template_id && templates[active_template_id] && templates[active_template_id].automation_rules ? templates[active_template_id].automation_rules : []} open={active_template_id} handle_close={closeViewRuleModal} mode='view' />
            <ITable rowSeparated={true} hover={true} style_container={{borderTop: '1px solid  var(--light-border-color', borderBottom: '1px solid  var(--light-border-color'}}>
                <ITableHeader>
                    <ITH>S.No</ITH>
                    <ITH style={{ whiteSpace: 'nowrap' }}>Template Name</ITH>
                </ITableHeader>
                <ITableBody>
                    {
                        templates && Object.keys(templates) ? Object.keys(templates).map((template_id, idx) => (
                            <ITableRow key={template_id} style={{ cursor: 'pointer', display: includes_search_string(templates[template_id].name, search_string) ? '' : 'none' }} onClick={() => onClickViewRule(template_id)}>
                                <ITD>{idx + 1}</ITD>
                                <ITD>{templates[template_id].name}</ITD>
                            </ITableRow>
                        ))
                            : ''
                    }
                </ITableBody>
            </ITable>
        </React.Fragment>

    )
}

const rule_type_struct = [
    {
        name: 'Automation Rules',
        id: 'design_automation'
    },
    {
        name: 'Manufacturing Rules',
        id: 'manufacturing_rule'
    },
    {
        name: 'Hardware Rules',
        id: 'hardware_rule'
    },
    {
        name: 'Design Constraint Rules',
        id: 'design_constraint'
    }
]

const CreateNewTemplateModal = ({ rules, open, handle_close, fetch_all_inventory_templates, mode = 'edit' }) => {
    const [rule_checked_map, set_rule_checked_map] = useState({});
    const [active_rule_type, set_active_rule_type] = useState('design_automation');
    const [search_string, set_search_string] = useState('');
    const [name, set_name] = useState('');

    const alert = useAlert();
    // ['am, hw, dc, mf]

    const on_click_handle_close = () => {
        set_rule_checked_map({});
        set_active_rule_type('design_automation')
        set_name('')
        handle_close()
    }

    const dataValidation = () => {
        if (!name) {
            alert.error("Inventory Template name cannot be empty")
            return false
        }

        if (!Object.keys(rule_checked_map).filter(id => rule_checked_map[id]).length) {
            alert.error("Select atleast 1 rule to create Inventory Template")
            return false
        }

        return true
    }

    const createInventoryTemplate = async () => {
        if (dataValidation()) {
            try {
                let automation_rule_ids = Object.keys(rule_checked_map).filter(id => rule_checked_map[id])
                let data = { name, automation_rule_ids }
                console.log("Rule Data on Inventory Template Create ", data)
                await create_inventory_template(data);
                alert.success("Inventory Template created successfully")
                on_click_handle_close()
                fetch_all_inventory_templates()
            } catch (err) {
                alert.error("Could not create inventory template. Please try again later.")
            }
        }
    }

    return (
        <Modal centered size='xl' isOpen={open} toggle={on_click_handle_close}>
            <IModalHeader toggle={on_click_handle_close}>Create New Inventory Template</IModalHeader>
            <IModalBody style={{ height: 'calc(100vh - 160px)', overflow: 'auto' }}>
                {
                    mode === 'edit' ?
                        <div className='mb-3'>
                            <div className='mb-1'>Inventory Template Name</div>
                            <Input value={name} onChange={(e) => set_name(e.target.value)} />
                        </div>
                        : ''
                }
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }} className='nav nav-pills mb-3'>
                    {
                        rule_type_struct.map(rule => (
                            <div key={rule.id} onClick={() => { set_active_rule_type(rule.id) }} className='nav-item' style={{ cursor: 'pointer' }}><a className={active_rule_type === rule.id ? 'nav-link active' : 'nav-link'} style={{ height: 'unset' }}> {rule.name} </a></div>
                        ))
                    }
                </div>
                <RulesSelectionTable rules={rules && rules.filter(x => x.type === 'design_automation')} rule_checked_map={rule_checked_map} visibility={active_rule_type === 'design_automation'} search_string={search_string} mode={mode} />
                <RulesSelectionTable rules={rules && rules.filter(x => x.type === 'manufacturing_rule')} rule_checked_map={rule_checked_map} visibility={active_rule_type === 'manufacturing_rule'} search_string={search_string} mode={mode} />
                <RulesSelectionTable rules={rules && rules.filter(x => x.type === 'hardware_rule')} rule_checked_map={rule_checked_map} visibility={active_rule_type === 'hardware_rule'} search_string={search_string} mode={mode} />
                <RulesSelectionTable rules={rules && rules.filter(x => x.type === 'design_constraint')} rule_checked_map={rule_checked_map} visibility={active_rule_type === 'design_constraint'} search_string={search_string} mode={mode} />
            </IModalBody>

            <IModalFooter>
                <Button className='white_button' onClick={on_click_handle_close}>Close</Button>
                {mode === 'edit' ? <Button className='blue_button' onClick={createInventoryTemplate}>Create</Button> : ''}
            </IModalFooter>
        </Modal>
    )
}

const TriggerCreateNewTemplateModal = ({ rules, ModalToggle, fetch_all_inventory_templates }) => {
    const [open, set_open] = useState(false);
    const toggle = () => set_open(!open)

    return (
        <React.Fragment>
            <CreateNewTemplateModal rules={rules} open={open} handle_close={toggle} fetch_all_inventory_templates={fetch_all_inventory_templates} />
            <div onClick={toggle}>
                {ModalToggle}
            </div>
        </React.Fragment>
    )
}

const RuleWizard = ({ store_details, get_store_details, set_active_tab, submit_for_delete_confirm, set_active_division_id_sidebar, send_analytics_event, set_page_loader }) => {
    const [all_rules, set_all_rules] = useState([]);
    const [all_inventory_templates, set_all_inventory_templates] = useState({});
    const [initialized, set_initialized] = useState(false);
    const [search_string, set_search_string] = useState('');

    const alert = useAlert();

    const fetch_all_automation_rules = async () => {
        try {
            let automation_rules = await get_all_automation_rules();
            debug_log("Automation Rules - ", automation_rules);
            set_all_rules(automation_rules)
        } catch (err) {
            alert.error("Error fetching automation rules. Try again in sometime")
        }
    }

    const fetch_all_inventory_templates = async () => {
        try {
            let inventory_templates = await get_all_inventory_templates();
            debug_log("Inventory Templates - ", inventory_templates);
            set_all_inventory_templates(inventory_templates)
        } catch (err) {
            alert.error("Error fetching inventory templates. Try again in sometime")
        }
    }

    useEffect(() => {
        set_active_division_id_sidebar('rule_wizard');
        (async () => {
            let p1 = fetch_all_automation_rules();
            let p2 = fetch_all_inventory_templates();

            await Promise.all([p1, p2]);
            set_initialized(true);
        })()
    }, []);
    return (
        <Main_Grid active_tab="RuleWizard" store_details={store_details} get_store_details={get_store_details}>
            <Row style={{ padding: '10px 0px', color: '#22272e' }}>
                <Col xs={6} className='flex_property' style={{ fontFamily: 'Source_Sans_Pro-SemiBold', paddingLeft: '20px', fontSize: '14px' }}>
                    Rule Wizard
                </Col>
                <Col xs={6} style={{ textAlign: 'right' }}>
                    <TriggerCreateNewTemplateModal ModalToggle={<Button className='blue_button'>Add New Inventory Template</Button>} rules={all_rules} fetch_all_inventory_templates={fetch_all_inventory_templates} />
                </Col>
            </Row>
            <Row style={{ height: 'calc(100% - 90px)' }}>
                <Col style={{ height: '100%' }}>
                    {
                        !initialized ?
                            "Fetch all the required details"
                            :
                            <Card style={{ borderRadius: 0, height: '100%' }}>
                                <div className='flex_property' style={{ height: "40px", backgroundColor: 'white', width: '100%', padding: '0px 20px' }}><i style={{ paddingRight: '11px', fontSize: '12px', color: '#9aa5b5' }} className='fa fa-search' /><input onChange={(e) => set_search_string(e.target.value)} style={{ fontSize: '14px', width: "100%", color: '#9aa5b5', backgroundColor: 'white', border: 0, paddingLeft: 0 }} type='text' placeholder='Search by Template Name' /></div>
                                <div style={{ height: 'calc(100% - 40px)', overflow: 'auto', textAlign: 'left' }}>
                                    {
                                        all_inventory_templates && Object.keys(all_inventory_templates).length ?
                                            <InventoryTemplatesTable templates={all_inventory_templates} search_string={search_string} all_rules={all_rules} />
                                            : 
                                            <div className='flex_center'>No Templates Found</div>
                                    }
                                </div>
                            </Card>
                    }
                </Col>
            </Row>
        </Main_Grid>
    )
}

export default RuleWizard

