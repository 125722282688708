import Main_Grid from "../../components/Main_Grid"
import React , { useState , useEffect, useRef, useContext, act }from 'react';
import general_fetch from "../../utils/fetch";
import { Row, Col, Card, Table, Input, Nav, NavItem, NavLink, TabContent, TabPane, Collapse, Modal, Button} from "reactstrap";
import { OrgBuContext } from "../../components/Contexts/OrgBuContext";
import { useAlert } from "react-alert";
import StringifiedComponentStructure from '../../assets/core_jsons/bulk_create_cabinet_options.json'
import classnames from "classnames";
import SkuSelector from "../../components/Sku_Selector";
import Multiselect from "../../components/Multiselect";
import { IModalBody, IModalFooter, IModalHeader } from "../../components/IModal";
import { handleError } from "../../utils/error";
import { uuid } from "uuidv4";
import { fetch_all_subcategories, fetch_sku_categories, fetch_sku_categories_by_id, fetch_sku_groups, fetch_sku_sub_categories, fetch_skus, remove_sku } from "../../utils/api/inventory";
import { ConfirmationModal } from "../../components/CommonModals";
import { Checkbox } from "antd";
import capitalizeAndReplaceUnderscores from "../../utils/format_name";

const crypto = require('crypto');
const format = require('biguint-format');

// const ComponentStructure = JSON.parse(StringifiedComponentStructure);
const ComponentStructure = StringifiedComponentStructure;

const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	add_brand_style:{
		backgroundColor: '#007EF4',
		boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
		borderRadius: '3px',
		height:'32px',
		padding:'0px 24px',
		border:'0px',
		color:'white',
	},
	input_style : {
        fontSize: '12px',
        padding: '0px 8px',
        height: '32px',
        borderRadius: '4px',
		maxWidth: '60%'
    },
    input_style_none : {
        fontSize: '12px',
        padding: '0px 8px',
        height: '32px',
        borderRadius: '2px',
        border: 'none',
        boxShadow: 'none'
    }
};

const Filler = ({ initialized=true }) => (
	<Table className="align-items-center table-flush text-center" responsive>
		<thead className="thead-light text-center">
			<tr>
				{ initialized ? (
					<th scope="col">
						<span style={styles.table_header}><b>No Construction Styles to list  </b></span><br/> Try adding a new one <br /> &nbsp;
					</th>
				):(
					<th scope="col">
						<Row>
							<Col xs={12} className="text-center">
								<img src="/load2.gif" />
							</Col>
							<Col xs={12} className="text-center">
								<span style={styles.table_header}><b>Fetching construction styles </b></span><br/> <br /> Please wait...<br /> <br />
							</Col>
						</Row>
					</th>
				)}
			</tr>
		</thead>
	</Table>	
);

const SingleUnitComponent = ({partition, active_style, initial_item, sku_partition_array, set_sku_partition_array, all_division_tree_data, set_page_loader, edited_sku_structure_partition_array, set_edited_sku_structure_partition_array, index, type, search_string_sku_structure, show_only_edited_in_sku_structure, common_props_confirmation_mode, is_cabinet, componentStructure, cabinet_corner_json_arr}) => {
    const [edited_sku_structure_partition, set_edited_sku_structure_partition] = useState({});
    // const [value, set_value] = useState('');
    const [is_open, set_is_open] = useState(true);
    const [single_unit_key, set_single_unit_key] = useState('single_unit_init');
    const toggle = () => set_is_open(!is_open)

    // useEffect(() => {
    //     console.log("Kppp initial_item", initial_item,  (componentStructure ? componentStructure : type === 'panel' ? (cabinet_corner_json_arr[2]) : (cabinet_corner_json_arr[1])))
    // }, [initial_item])

    const delete_item = () => {
        sku_partition_array.splice(index, 1)
        set_sku_partition_array([...sku_partition_array])

        edited_sku_structure_partition_array.splice(index, 1)
        set_edited_sku_structure_partition_array(edited_sku_structure_partition_array)
    }

    const reset_item = () => {
        set_edited_sku_structure_partition({})
        generate_random_key()
    }

    useEffect(() => {
            edited_sku_structure_partition_array[index] = edited_sku_structure_partition;
            set_edited_sku_structure_partition_array(edited_sku_structure_partition_array)
    }, [edited_sku_structure_partition]);

    const generate_random_key = () => {
        set_single_unit_key(Date.now())
    }

    return (
        <React.Fragment key={single_unit_key}>
            <Row onClick={toggle} className='parent_sku_edit_structure'>
                <Col className='flex_property justify-content-between'>
                    <div style={{textTransform: 'capitalize'}}>{(type) + ' ' + (index+1)}</div>
                    <div className='flex_property'>
                        {
                            common_props_confirmation_mode ? '' :
                            <React.Fragment>
                                <div style={{marginRight: '32px', fontSize: '14px'}} className='reset_changes' onClick={(e) => {e.stopPropagation(); reset_item()}}>Reset Changes</div>
                                <div style={{marginRight: '32px', fontSize: '14px', color: '#c5c7cf'}} onClick={(e) => {e.stopPropagation(); delete_item()}}>Delete</div>
                            </React.Fragment>
                        }
                        <i style={{color: '#A7A8B2', fontSize: '16px'}} className={is_open ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i>
                    </div>
                </Col>
            </Row>
            <Collapse isOpen={is_open} style={{borderLeft: '4px solid #a7a8b2'}}>
                {
                    (componentStructure ? componentStructure : type === 'panel' ? (cabinet_corner_json_arr[2]) : (cabinet_corner_json_arr[1])).map((item, idx) => (
                        <SingleRowComponent key={idx} initial_item={initial_item && initial_item[item.id]} item={item} edited_sku_structure={edited_sku_structure_partition} set_edited_sku_structure={set_edited_sku_structure_partition} parent={edited_sku_structure_partition} all_division_tree_data={all_division_tree_data} set_page_loader={set_page_loader} search_string_sku_structure={search_string_sku_structure} show_only_edited_in_sku_structure={show_only_edited_in_sku_structure} common_props_confirmation_mode={common_props_confirmation_mode} cabinet_corner_json_arr={cabinet_corner_json_arr}/>
                    ))
                }
            </Collapse>
        </React.Fragment>
    )
}

const SingleRowComponent = ({item, initial_item, active_style, edited_sku_structure, set_edited_sku_structure, parent, all_division_tree_data, set_page_loader, search_string_sku_structure, show_only_edited_in_sku_structure, parent_id, common_props_confirmation_mode, cabinet_corner_json_arr}) => {
    const [is_open, set_is_open] = useState(true);
    const toggle = () => set_is_open(!is_open)
    const [value, set_value] = useState(null);
    const [selected_value, set_selected_value] = useState([]);
    const [is_edited, setis_edited] = useState();
    const [key_node, set_key_node] = useState('init');
    const [is_open_modal, set_is_open_modal] = useState(false);
    const toggle_modal = () => set_is_open_modal(!is_open_modal)
    const [display_name, set_display_name] = useState('');
    const [property_array, set_property_array] = useState([]);
    const [init_value, set_init_value] = useState('')

    const initialiseValues = (init = false) => {
        if(initial_item || [false, 0].includes(initial_item)){
            if(item.type == 'text' || item.type == 'number'){
                set_value(item.type == 'number' && initial_item != '' ? Number(initial_item) : initial_item)
                if(init) set_init_value(item.type == 'number' && initial_item != '' ? Number(initial_item) : initial_item)
            }
            else if(item.type == 'bool'){
                // console.log("kpp bool item is", item)
                if([0, false].includes(initial_item) || initial_item){
                    set_value(initial_item ? true : false)
                    if(init) set_init_value(initial_item ? true : false)
                }
                else{
                    set_value('')
                    if(init) set_init_value('')
                }
            }
            else if(item.type == 'select'){
                set_value(initial_item)
                if(init) set_init_value(initial_item)
            }
            else if(item.type == 'multiselect'){
                set_selected_value(item.options && item.options.length && item.options.filter(x => {initial_item && initial_item.length && initial_item.includes(x.id)}))
                if(init) set_init_value(item.options && item.options.length && item.options.filter(x => {initial_item && initial_item.length && initial_item.includes(x.id)}))
            }
            else if(item.type == 'array'){
                // console.log("kppp array item", initial_item, active_style)
                set_value(initial_item ? initial_item : [])
                if(init) set_init_value(initial_item ? initial_item : [])
                set_property_array(initial_item ? initial_item : [])
            }
            else if(item.type == 'sku'){
                // console.log("kpp sku initial value", initial_item, item, init)
                if(init) set_init_value(initial_item)
                set_value(initial_item)
            }
        } 
        else if(init == false) {
            set_value('')
            set_selected_value([])
            if(init) set_init_value('')
        }
    }

    useEffect(() => {
        // console.log("Kpp active_style is", initial_item, item)
        initialiseValues(true)
    }, [initial_item])

    // useEffect(() => {
    //     if(item && item.type == 'array' && active_style && active_style.partitions && active_style.partitions.length){
    //         set_property_array(active_style.partitions);
    //         set_value(JSON.parse(JSON.stringify(active_style.partitions)));
    //     } else {
    //         set_value([]);
    //     }
    // }, [active_style])

    // useEffect(() => {
    //     console.log("property array is", property_array)
    // }, [property_array])

    useEffect(() => {
        if(parent){
            if(item.type == 'parent'){
                parent[item.id] = {}
            }else{
                parent[item.id] = value
            }
            set_edited_sku_structure(edited_sku_structure)
        }
    }, [value, parent]);

    useEffect(() => {
        if(item.type != parent && item.value){
            set_value(item.value)
        }
    }, [item]);

    useEffect(() => {
        generate_random_key()
    }, [parent]);

    const reset_child = () => {
        // set_value(null)
        initialiseValues()
        generate_random_key()
    }

    const reset_parent = () => {
        parent[item.id] = {}
        set_edited_sku_structure(edited_sku_structure)
        generate_random_key()
    }
    
    const generate_random_key = () => {
        set_key_node(Date.now())
    }

    useEffect(() => {
        if(item.type == 'multiselect' && selected_value && selected_value.length){
            set_value(selected_value.map(x => x.id))
        }else if(value !== null){
            set_value(null)
        }
    }, [selected_value]);

    const add_partition = () => {
        let temp = [...property_array]
        temp.push(Date.now())
        set_property_array(temp)

        let temp_2
        if(value && value.length){
            temp_2 = [...value]
        }else{
            temp_2 = []
        }
        temp_2.push({})
        set_value(temp_2)
    }


    return(
        <React.Fragment key={key_node}>
            <SkuSelector set_page_loader={set_page_loader} is_open={is_open_modal} toggle={toggle_modal} all_division_tree_data={all_division_tree_data} set_value={set_value} set_display_name={set_display_name} possible_category_types={item.possible_category_types} initial_sku={initial_item}/>
            {
                item.type == 'parent' ? 
                <React.Fragment>
                <div style={{display: item.display?'':'none'}}>
                    <Row onClick={toggle} className='parent_sku_edit_structure'>
                        <Col className='flex_property justify-content-between'>
                            {item.name}
                            <div className='flex_property'>
                                {
                                    common_props_confirmation_mode ? '' :
                                        <div style={{marginRight: '32px', fontSize: '14px'}} className='reset_changes' onClick={(e) => {e.stopPropagation(); reset_parent()}}>Reset Changes</div>
                                }
                                <i style={{color: '#A7A8B2', fontSize: '16px'}} className={is_open ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i>
                            </div>
                        </Col>
                    </Row>
                    <Collapse isOpen={is_open} style={{borderLeft: '4px solid #a7a8b2'}}>
                        {
                            item.children && item.children.map((child, idx) => (
                                <SingleRowComponent active_style={active_style} key={idx} item={child} initial_item={initial_item && initial_item[child.id]} parent={parent && parent[item.id]} set_edited_sku_structure={set_edited_sku_structure} edited_sku_structure={edited_sku_structure} all_division_tree_data={all_division_tree_data} set_page_loader={set_page_loader} search_string_sku_structure={search_string_sku_structure} show_only_edited_in_sku_structure={show_only_edited_in_sku_structure} parent_id={item.id + (parent_id ? '_' + parent_id : '') } common_props_confirmation_mode={common_props_confirmation_mode} cabinet_corner_json_arr={cabinet_corner_json_arr}/>
                            ))
                        }
                    </Collapse>
                </div>
                </React.Fragment>
                : item.type == 'array' ?
                <div style={{borderBottom: '1px solid #C5C7CF'}}>
                    {
                        property_array && property_array.length ? property_array.map((partition, index) => (
                            <SingleUnitComponent active_style={active_style} initial_item={partition} type={item.name} key={partition} partition={partition} sku_partition_array={property_array} set_sku_partition_array={set_property_array} all_division_tree_data={all_division_tree_data} set_page_loader={set_page_loader} edited_sku_structure_partition_array={value} set_edited_sku_structure_partition_array={set_value} index={index} search_string_sku_structure={search_string_sku_structure} show_only_edited_in_sku_structure={show_only_edited_in_sku_structure} common_props_confirmation_mode={common_props_confirmation_mode} componentStructure={item.children} cabinet_corner_json_arr={cabinet_corner_json_arr}/>
                        ))
                            : ''
                    }
                    {common_props_confirmation_mode ? '' : <div className='blue_text_edit_sku_structure flex_property pb-2 pt-2' onClick={add_partition}>+ Add {item.name}</div>}
                </div>
                :
                <React.Fragment>
                    <Row className='leaf_sku_edit_structure' style={{display: (search_string_sku_structure && !item.display) || (show_only_edited_in_sku_structure && !(value || value === false))  ? 'none' : 'flex'}}>
                        <Col>
                            {item.name}
                        </Col>
                        <Col style={{fontSize:'12px'}} title={item.description ? item.description : ''}>{item.description ? item.description : ''}</Col>
                        <Col className={value || value === false ? 'edited_sku_structure_cell' : ''} style={{pointerEvents: common_props_confirmation_mode ? 'none' : 'auto'}}>
                            {
                                item.type == 'sku' ?
                                <div className='flex_property' style={{width:'var(--width_input_sku_structure)'}}>
                                    {value ? <div className='mr-3'>{ display_name ? display_name : value}</div> : ''}
                                    <div onClick={toggle_modal} className='blue_text_edit_sku_structure'>{value ? 'Edit Sku' : 'Add Sku'}</div>
                                </div>
                                : item.type == 'text' || item.type == 'number' ?
                                <Input id={item.id + (parent_id ? '_' + parent_id : '')} style={{...styles.input_style}} type={item.type} value={value} onChange={(e) => set_value(item.type == 'number' && e.target.value != '' ? Number(e.target.value) : e.target.value)}></Input>
                                : item.type == 'bool' || item.type == 'select' ?
                                <Input id={item.id + (parent_id ? '_' + parent_id : '')} style={{...styles.input_style}} type={'select'} value={value} onChange={(e) => set_value(item.type == 'bool' && e.target.value == 'true' ? true : item.type == 'bool' && e.target.value == 'false' ? false : e.target.value )}>
                                    {
                                        item.type == 'select' && item.options && item.options.length ? item.options.map((option, idx) => (
                                            <option key={idx} value={option.id}>{option.name}</option>
                                        ))
                                        :
                                        item.type == 'bool' ? 
                                        <React.Fragment>
                                            <option value={''}>No Changes</option>
                                            <option value={true}>True</option>
                                            <option value={false}>False</option>
                                        </React.Fragment>
                                        :''
                                    }
                                </Input>
                                : item.type == 'multiselect' ?
                                    <Multiselect
                                        style={{backgroundColor:'white', ...styles.input_style, padding:'0px'}}
                                        options={item.options} 
                                        selectedValues={selected_value?selected_value:[]}
                                        onSelect={set_selected_value} 
                                        onRemove={set_selected_value} 
                                        displayValue="name" // Property name 
                                        placeholder='Select'
                                        // disable={allow_edit?false:true}
                                    />
                                :''
                            }
                            {value || value === false ? <div style={{marginLeft: '16px', fontSize: '14px', cursor:'pointer'}} className='reset_changes' onClick={reset_child}>{(item.type == 'multiselect' ? (init_value != selected_value) : (init_value != value)) ? 'Reset Changes' : ''}</div> : ''}
                            
                        </Col>
                    </Row>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

const BulkEditSKUComponent = ({open, user, set_page_loader, handle_close, active_style, active_style_id, comp_file_type, common_props_confirmation_mode = false}) => {
	const [	tabs , set_tabs ] = useState(1)
    const [	tabs_sku , set_tabs_sku ] = useState(1)
    
    const [edited_sku_structure, set_edited_sku_structure] = useState({});

    const [edited_sku_structure_partition_array, set_edited_sku_structure_partition_array] = useState([]);
    const [edited_sku_structure_panel_array, set_edited_sku_structure_panel_array] = useState([]);

    const [key_sku_structure, set_key_sku_structure] = useState('sku_structure_init');
    const [key_sku_structure_partition, set_key_sku_structure_partition] = useState('sku_structure_partition_init');
    const [key_sku_structure_panel, set_key_sku_structure_panel] = useState('sku_structure_panel_init');
    
    const [all_division_tree_data, set_all_division_tree_data] = useState([]);

    const [search_string_sku_structure, set_search_string_sku_structure] = useState('');
    const [show_only_edited_in_sku_structure, set_show_only_edited_in_sku_structure] = useState(false);

    const [sku_partition_array, set_sku_partition_array] = useState([]);
    const [sku_panel_array, set_sku_panel_array] = useState([]);

    const [cabinet_corner_json_arr, set_cabinet_corner_json_arr] = useState([[], [], []])
    const [total_results, set_total_results] = useState([0, 0, 0])
	
	const [style_name, set_style_name] = useState('');
    const [corner_units, set_corner_units] = useState([]);
	const [style_description, set_style_description] = useState('');

    const org_bu_view = useContext(OrgBuContext)
    const alert = useAlert()

    useEffect(() => {
        if(active_style){
            set_style_name(active_style.style_name);
            set_style_description(active_style.style_desc)
        }
    }, [active_style])

    useEffect(() => {
        if(comp_file_type == 'corner_unit')
            set_corner_units(['corner_unit'])
        else
            set_corner_units([])
    }, [comp_file_type])

    useEffect(() => {
        if(active_style.partitions && active_style.partitions.length)
            set_sku_partition_array(active_style.partitions)
        else
            set_sku_partition_array([])
        if(active_style.panels && active_style.panels.length)
            set_sku_panel_array(active_style.panels)
        else
            set_sku_panel_array([])
    }, [active_style])

    // useEffect(() => {
    //     console.log("kpp all_division_tree_data", all_division_tree_data)
    // }, [all_division_tree_data])

	const onclick_handle_close = () => {
		handle_close();
        set_style_name('')
        set_style_description('')
        set_total_results([0, 0, 0])
        set_cabinet_corner_json_arr([[], [], []])
        set_corner_units([])
        set_all_division_tree_data([])
        set_edited_sku_structure({})
        set_sku_panel_array([])
        set_sku_partition_array([])
        set_search_string_sku_structure('')
        set_tabs_sku(1)
	}

    function removeEmptyValues(obj) {
        if (typeof obj !== 'object' || obj === null) {
          return obj;
        }
      
        if (Array.isArray(obj)) {
          let resultArray = obj.map(removeEmptyValues).filter(x => {
            if(typeof x !== 'object'){
                if (x !== undefined && x !== null && x !== '') {
                    return true
                }
            }else{
                return removeEmptyValues(x)
            }
          });
          
          if(resultArray.length) return resultArray

          return null
        }
      
        const result = {};
      
        for (const key in obj) {
          const value = removeEmptyValues(obj[key]);
      
          if (value !== undefined && value !== null && value !== '') {
            result[key] = value;
          }
        }
      
        // Remove empty objects
        if (Object.keys(result).length === 0) {
          return undefined;
        }
      
        return result;
      }

    const get_final_construction_style = async() => {
        let non_empty_edited_sku_structure = removeEmptyValues(edited_sku_structure)
        if(!non_empty_edited_sku_structure){
            non_empty_edited_sku_structure = {}
        }
        let non_empty_edited_sku_structure_partition = edited_sku_structure_partition_array.map(item => removeEmptyValues(item)).filter(x => x)
        let non_empty_edited_sku_structure_panel = edited_sku_structure_panel_array.map(item => removeEmptyValues(item)).filter(x => x)
        if(non_empty_edited_sku_structure_partition.length) non_empty_edited_sku_structure["partitions"] = non_empty_edited_sku_structure_partition
        if(non_empty_edited_sku_structure_panel.length) non_empty_edited_sku_structure["panels"] = non_empty_edited_sku_structure_panel
        // console.log("kppp", non_empty_edited_sku_structure)
        let temp_final_sku_structure = JSON.stringify(non_empty_edited_sku_structure)
        if(temp_final_sku_structure != JSON.stringify({})){
            return non_empty_edited_sku_structure
        }
        return {}
    }

	function filterBulkEditSkuStructureRows(obj, tab, par=false) {
        if(obj.type != 'parent' && obj.type != 'array'){
            if(obj.name.toLowerCase().includes(search_string_sku_structure.toLowerCase()))
                obj.display = true;
            else
                obj.display = par;
            if(obj.display && obj.flag == undefined && !obj.flag){
                obj.flag = true;
                total_results[tab-1]++
            }
            return obj
        }

        else {
            if(obj.type == 'parent' || obj.type == 'array'){
                if(par){
                    obj.display = true;
                    obj.children.map(x => filterBulkEditSkuStructureRows(x, tab, true))
                }
                else {
                    if(obj.name.toLowerCase().includes(search_string_sku_structure.toLowerCase())){
                        obj.display = true
                        obj.children.map(x => filterBulkEditSkuStructureRows(x, tab, true))
                    }
                    else{
                        obj.children.forEach(x => {
                            filterBulkEditSkuStructureRows(x, tab)
                        });
                        var garb = obj.children.map(x => {
                            obj.display = obj.display || filterBulkEditSkuStructureRows(x, tab).display;
                        });
                    }
                }
            }
            return obj
        }
        
    }

    useEffect(() => {
        total_results[0] = 0
        total_results[1] = 0
        total_results[2] = 0
        if(corner_units.length == 0){
            set_cabinet_corner_json_arr([ComponentStructure.cabinet.cabinet.map(x => filterBulkEditSkuStructureRows(JSON.parse(JSON.stringify(x)), 1)), ComponentStructure.cabinet.partitions.map(x => filterBulkEditSkuStructureRows(JSON.parse(JSON.stringify(x)), 2)), ComponentStructure.cabinet.panels.map(x => filterBulkEditSkuStructureRows(JSON.parse(JSON.stringify(x)), 3))])
        }
        else{
            set_cabinet_corner_json_arr([ComponentStructure.corner_unit.cabinet.map(x => filterBulkEditSkuStructureRows(JSON.parse(JSON.stringify(x)), 1)), ComponentStructure.corner_unit.partitions.map(x => filterBulkEditSkuStructureRows(JSON.parse(JSON.stringify(x)), 2)), ComponentStructure.corner_unit.panels.map(x => filterBulkEditSkuStructureRows(JSON.parse(JSON.stringify(x)), 3))])
        }
        set_total_results(JSON.parse(JSON.stringify(total_results)))
    }, [search_string_sku_structure, corner_units]);

    const toggleNavsSku = (e, index) => {
		e.preventDefault();
		set_tabs_sku(index)
	};

    const fetch_all_subcategories = async() => {
        try{
            let body = {}
			if(org_bu_view == 'bu'){
				body['business_unit_id'] = user.current_business_unit_id
			}
            var response = await general_fetch({ url: 'inventory/get_all_sub_categories', body});	
			set_all_division_tree_data(response)
        }catch(err){
            console.log(err)
        }
    }

    const reset_sku_structure_tab = () => {
        // console.log('edit sku structure', edited_sku_structure)
        if(tabs_sku == 1){
            set_edited_sku_structure({})
            set_key_sku_structure(Date.now())
        }else if(tabs_sku == 2){
            // set_sku_partition_array([])
            set_edited_sku_structure_partition_array([])
            set_key_sku_structure_partition(Date.now())
        }else if(tabs_sku == 3){
            // set_sku_panel_array([])
            set_edited_sku_structure_panel_array([])
            set_key_sku_structure_panel(Date.now())
        }
    }

    const add_partition = () => {
        let temp = [...sku_partition_array]
        temp.push(Date.now())
        set_sku_partition_array(temp)

        let temp_2 = [...edited_sku_structure_partition_array]
        temp_2.push({})
        set_edited_sku_structure_partition_array(temp_2)
    }

    const add_panel = () => {
        let temp = [...sku_panel_array]
        temp.push(Date.now())
        set_sku_panel_array(temp)

        let temp_2 = [...edited_sku_structure_panel_array]
        temp_2.push({})
        set_edited_sku_structure_panel_array(temp_2)
    }

    const get_unique_sku_id = () => {
        const id = crypto.randomBytes(10);
        return format(id, 'hex');
    }

    const get_category_id = async() => {
        try{
            let resp = await fetch_sku_categories(['template'])
            let sku_category_arr = resp && resp.length && resp.filter(item => item.sku_category_type_id == 'construction_style_template');
            let sku_category = sku_category_arr && sku_category_arr.length && sku_category_arr[0].id;
            if(!sku_category){
                let body = {'name': 'Construction_Style_Category', 'sku_category_type_id': 'construction_style_template'}
                if(org_bu_view == 'bu')
                    body['business_unit_id'] = user.current_business_unit_id
                let resp = await general_fetch({ url: 'sku_category/create', body:body});
                sku_category = resp && resp.id;
                return [sku_category, 1];
            }
            return [sku_category, 0];
        } catch(err){
            Promise.reject(err)
        }
    }

    const get_sub_category_id = async(cat_id, create_new = false) => {
        try{
            let sub_cat_id;
            if(!create_new){
                let resp = await fetch_sku_sub_categories(cat_id)
                sub_cat_id = resp && resp.length && resp[0].id;
            }
            if(!sub_cat_id){
                let body = {'name':'Construction_Style_Subcategory', 'order':0, 'sku_category_id':cat_id, 'list_view':false}
                if(org_bu_view == 'bu')
                    body['business_unit_id'] = user.current_business_unit_id;
                let resp = await general_fetch({ url: 'sku_sub_category/create', body});
                sub_cat_id = resp && resp.id;
                return [sub_cat_id, 1];
            }
            return [sub_cat_id, 0];
        } catch(err){
            Promise.reject(err)
        }
    }

    const get_group_id = async(sub_cat_id, create_new = false) => {
        try{
            let group_id;
            if(!create_new){
                let resp = await fetch_sku_groups(sub_cat_id);
                group_id = resp && resp.length && resp[0].id;
            }
            if(!group_id){
                let body = { 'name': 'Construction_Style_Group', 'sku_sub_category_id':sub_cat_id, 'order':0}
                if(org_bu_view == 'bu')
                    body['business_unit_id'] = user.current_business_unit_id
                let resp = await general_fetch({ url: 'sku_group/create', body });
                group_id = resp && resp.id;
                return [group_id, 1];
            }
            return [group_id, 0];
        } catch(err){
            Promise.reject(err)
        }
    }

	const onclick_submit = async() => {
        try{
            if(!(style_name && style_name.length)){
                alert.error("Style Name cannot be empty")
                return;
            }
            set_page_loader({
                text:'Creating the style, please wait...',
                show:true
            })
            let cat_id_arr = await get_category_id();
            let cat_id = cat_id_arr && cat_id_arr.length && cat_id_arr[0];
            let create_new = cat_id_arr && (cat_id_arr.length>1) && cat_id_arr[1];
            
            let sub_cat_arr = await get_sub_category_id(cat_id, create_new)
            let sub_cat_id = sub_cat_arr && sub_cat_arr.length && sub_cat_arr[0];
            create_new = sub_cat_arr && (sub_cat_arr.length>1) && sub_cat_arr[1];

            let group_arr = await get_group_id(sub_cat_id, create_new)
            let group_id = group_arr && group_arr.length && group_arr[0];
            create_new = group_arr && (group_arr.length>1) && group_arr[1];

            var fd = new FormData();
            if(org_bu_view == 'bu'){
                fd.append('business_unit_id', user.current_business_unit_id)
            }
            fd.append('name', style_name)
			fd.append('description', style_description)
            let final_style = await get_final_construction_style();
            // console.log("kppp final_style", final_style)
            let sku_id = get_unique_sku_id()
            final_style.sku_id = sku_id
            let component_file = [{
                container_component_id: null,
                type: corner_units.length ? "corner_unit" : "cabinet",
                data: final_style
            }]
            fd.append('component_file', JSON.stringify(component_file))
			fd.append('sku_id', sku_id)
            fd.append('sku_group_id', group_id)
            fd.append('store_id', user.store_id)
            fd.append('order', 0)
            fd.append('global', false)
            let resp = await general_fetch({ url: 'sku/create', body:fd, is_form_data:true });
            alert.success('New construction style created!')
        } catch(err){
            handleError(err)
            alert.error("Couldn't create new style. Please try again")
        }
        set_page_loader({
            show: false
        })
        onclick_handle_close()
	}

    const onclick_submit_update = async() => {
        try{
            if(!(style_name && style_name.length)){
                alert.error("Style Name cannot be empty")
                return;
            }
            set_page_loader({
                text:'Updating the style, please wait...',
                show:true
            })
            let final_style = await get_final_construction_style();
            final_style.sku_id = active_style && active_style.id
            let component_file = [{
                container_component_id: null,
                type: corner_units.length ? "corner_unit" : "cabinet",
                data: final_style
            }]
            let body = {sku_id: active_style_id, name: style_name, order: 0, description: style_description, component_file: JSON.stringify(component_file) }
			let resp = await general_fetch({ url: 'sku/update', body })
            alert.success("Style Updated successfully")
        } catch(err){
            handleError(err)
            alert.error("Couldn't update the style. Please try again")
        }
        set_page_loader({
            show: false
        })
        onclick_handle_close()
    }

    useEffect(() => {
        if(open)
        fetch_all_subcategories()
    }, [open]);

    // useEffect(() => {
    //     console.log("KPP cabinet corner json", cabinet_corner_json_arr[0])
    // }, [cabinet_corner_json_arr])


	return (
		<Modal size="xl" isOpen={open} centered toggle={onclick_handle_close}>
			<IModalHeader toggle={onclick_handle_close}>
				Create New Construction Style
			</IModalHeader>
			<IModalBody style={{ maxHeight: '600px', overflow: 'auto'}}>
			<div style={{display: 'flex', gap: '16px', alignItems: 'center', background: 'white', marginBottom: '16px', padding: '16px'}}>
				<div style={{display: 'flex', alignItems: 'center'}}>
					<div style={{paddingRight: '8px'}}>Style name</div>
					<Input style={{...styles.input_style}} type="text" value={style_name} onChange={(e) => set_style_name(e.target.value)} />
				</div>
				<div style={{display: 'flex', alignItems: 'center'}}>
					<div style={{paddingRight: '8px'}}>Style Type</div>
					<Input disabled={active_style_id?true:false} style={{...styles.input_style, maxWidth: '58%', opacity: active_style_id?0.5:1}} type="select" value={corner_units.length ? 'corner_unit' : 'cabinet'} onChange={(e) => {if(e.target.value == 'corner_unit') set_corner_units(['corner_unit']); else set_corner_units([])}}>
						<option value='cabinet'>Cabinet</option>
						<option value='corner_unit'>Corner Unit</option>
					</Input>
				</div>
				<div style={{display: 'flex', alignItems: 'center', flex: 1}}>
					<div style={{paddingRight: '8px'}}>Style Description</div>
					<Input style={{...styles.input_style, maxWidth: 'unset', flex: 1}} type="text" value={style_description} onChange={(e) => set_style_description(e.target.value)} />
				</div>
			</div>
			<div style={{paddingBottom:'20px', backgroundColor: 'white'}}>
				<div className='flex_property bulk_edit_of_property'>
					<span style={{fontWeight:'900'}}>Bulk Edit of Components</span>
					<div style={{display: 'flex', gap: '8px'}}>
					{/* {<span className='reset_changes' onClick={() => {set_show_import_modal(true)}}>Import from Styles</span>} */}
					{<span className='reset_changes' onClick={() => {reset_sku_structure_tab()}}>Reset All Changes</span>}
					</div>
				</div>
				<div className='flex_property mt-3'>
					<div className='flex_property search_component_container_ideal'  style={{width: '60%'}}>
						<i className="fas fa-search pr-2 pl-2" style={{fontSize:'12px'}}></i>
						<Input style={{...styles.input_style_none, flex: '1'}} type='text' value={search_string_sku_structure} onChange={(e) => {set_search_string_sku_structure(e.target.value)}} placeholder='Search for any property'></Input>
						<button style={{backgroundColor: 'transparent', border: 'none', color: '#0078FF', fontSize: '12px', fontWeight: '400'}} onClick={() => set_search_string_sku_structure('')}>clear</button>
						<div style={{display: tabs_sku == 1 && search_string_sku_structure?'':'none', width: '1px', height: '32px', backgroundColor: '#C4C6CD', marginRight: '5px'}}></div>
						<span style={{display: tabs_sku == 1 && search_string_sku_structure?'':'none', padding: '0 4px', fontSize: '10px'}}>Found total of {total_results[0]} results</span>
					</div>
					<div className='flex_property ml-4'>
                        <Checkbox className='mr-3' checked={show_only_edited_in_sku_structure} onClick={() => set_show_only_edited_in_sku_structure(!show_only_edited_in_sku_structure)}/>
						Show only modified fields
					</div>
				</div>
			</div>
			<div className="nav-wrapper" style={{padding:'2px 8px', backgroundColor:'white', fontSize:'15px'}}>
				<Nav 
					style={{display:'flex', alignItems:'center'}}
					className="flex-md-row"
					id="tabs-icons-text" 
					pills
					role="tablist"
				>   
					{
						<NavItem>
							<NavLink
								aria-selected={tabs_sku === 1}
								className={classnames("mb-sm-3 mb-md-0", {
									active: tabs_sku === 1
								})}
								onClick={e => toggleNavsSku(e, 1)}
								href="#pablo"
								role="tab"
							>
								<span>{corner_units.length === 0 ? "Cabinet" : "Corner Unit"}</span>
							</NavLink>
						</NavItem>
					}
					{
						<NavItem>
							<NavLink
								aria-selected={tabs_sku === 2}
								className={classnames("mb-sm-3 mb-md-0", {
									active: tabs_sku === 2
								})}
								onClick={e => toggleNavsSku(e, 2)}
								href="#pablo"
								role="tab"
							>
								<span>Partition</span>
							</NavLink>
						</NavItem>
					}
					{
						<NavItem>
							<NavLink
								aria-selected={tabs_sku === 3}
								className={classnames("mb-sm-3 mb-md-0", {
									active: tabs_sku === 3
								})}
								onClick={e => toggleNavsSku(e, 3)}
								href="#pablo"
								role="tab"
							>
								<span>Panel</span>
							</NavLink>
						</NavItem>
					}
				</Nav>
				<TabContent activeTab={"tabs_sku" + tabs_sku}>
					<TabPane tabId="tabs_sku1" style={{display: tabs_sku!=1 ? 'none' : 'block'}}>
						<Row className='header_row_sku_structure' style={{margin:0}}>
							<Col className='header_sku_structure'>Property</Col>
							<Col className='header_sku_structure'>Description</Col>
							<Col className='header_sku_structure'>Modified</Col>
						</Row>
						<div style={{border: '1px solid #c5c7cf'}} key={key_sku_structure}>
						{
							(cabinet_corner_json_arr[0]).map((item, idx) => (
								<SingleRowComponent active_style={active_style} key={item.name} item={item} initial_item={active_style && active_style[item.id]} edited_sku_structure={edited_sku_structure} set_edited_sku_structure={set_edited_sku_structure} parent={edited_sku_structure} all_division_tree_data={all_division_tree_data} set_page_loader={set_page_loader} search_string_sku_structure={search_string_sku_structure} show_only_edited_in_sku_structure={show_only_edited_in_sku_structure} common_props_confirmation_mode={false} cabinet_corner_json_arr={cabinet_corner_json_arr}/>
							))
						}
						</div>
					</TabPane>
					<TabPane tabId="tabs_sku2" style={{display: tabs_sku!=2 ? 'none' : 'block'}}>
						<Row className='header_row_sku_structure' style={{margin:0}}>
							<Col className='header_sku_structure'>Property</Col>
							<Col className='header_sku_structure'>Description</Col>
							<Col className='header_sku_structure'>Modified</Col>
						</Row>
						<div style={{border: '1px solid #c5c7cf'}} key={key_sku_structure_partition}>
							{
								sku_partition_array && sku_partition_array.length ? sku_partition_array.map((partition, index) => (
									<SingleUnitComponent active_style={active_style} initial_item={partition} type={'partition'} key={partition} partition={partition} sku_partition_array={sku_partition_array} set_sku_partition_array={set_sku_partition_array} all_division_tree_data={all_division_tree_data} set_page_loader={set_page_loader} edited_sku_structure_partition_array={edited_sku_structure_partition_array} set_edited_sku_structure_partition_array={set_edited_sku_structure_partition_array} index={index}  search_string_sku_structure={search_string_sku_structure} show_only_edited_in_sku_structure={show_only_edited_in_sku_structure} common_props_confirmation_mode={false} is_cabinet={corner_units.length === 0} cabinet_corner_json_arr={cabinet_corner_json_arr}/>
								))
								:''
							}
							{common_props_confirmation_mode ? '' : <div className='blue_text_edit_sku_structure flex_property pb-2 pt-2' onClick={add_partition}>+ Add Partition</div>}
						</div>
						
					</TabPane>

					<TabPane tabId="tabs_sku3" style={{display: tabs_sku!=3 ? 'none' : 'block'}}>
						<Row className='header_row_sku_structure' style={{margin:0}}>
							<Col className='header_sku_structure'>Property</Col>
							<Col className='header_sku_structure'>Description</Col>
							<Col className='header_sku_structure'>Modified</Col>
						</Row>
					
						<div style={{border: '1px solid #c5c7cf'}} key={key_sku_structure_panel}>
							{
								sku_panel_array && sku_panel_array.length ? sku_panel_array.map((panel, index) => (
									<SingleUnitComponent active_style={active_style} initial_item={panel} type={'panel'} key={panel} partition={panel} sku_partition_array={sku_panel_array} set_sku_partition_array={set_sku_panel_array} all_division_tree_data={all_division_tree_data} set_page_loader={set_page_loader} edited_sku_structure_partition_array={edited_sku_structure_panel_array} set_edited_sku_structure_partition_array={set_edited_sku_structure_panel_array} index={index}  search_string_sku_structure={search_string_sku_structure} show_only_edited_in_sku_structure={show_only_edited_in_sku_structure} common_props_confirmation_mode={common_props_confirmation_mode} is_cabinet={corner_units.length === 0} cabinet_corner_json_arr={cabinet_corner_json_arr}/>
								))
								:''
							}
							{ common_props_confirmation_mode ? '' : <div className='blue_text_edit_sku_structure flex_property pb-2 pt-2' onClick={add_panel}>+ Add Panel</div> }
						</div>
					</TabPane>
				</TabContent>
			</div>
			</IModalBody>
			<IModalFooter>
				<Button className="blue_button" onClick={active_style?onclick_submit_update:onclick_submit}>{active_style ? 'Update Style' : 'Create Style'}</Button>
			</IModalFooter>
		</Modal>)
}

const Construction_styles_table = ({construction_styles, on_click_edit_callback, on_click_delete_callback,}) => {
	
	return(
		<table style={{height:"100%", width:'100%'}} className="text-left flex_property table_style">
			<thead style={{width:"100%"}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6', height:"40px"}}>
				<th className='th_style flex_property' style={{flex:'6'}}><span>Styles</span></th>
				<th className='th_style flex_property' style={{flex:'12'}}><span>Description</span></th>
                <th className='th_style flex_property' style={{flex:'4'}}><span>SKU Type</span></th>
				<th className='th_style flex_property' style={{flex:'3', border:0}}><span>Actions</span></th>
			</tr>
			</thead>
			<tbody style={{ height:"calc(100% - 40px)", width:'100%',overflow:'auto'}}>
			{
				construction_styles && construction_styles.length ? construction_styles.map((cstyle, idx) =>
					<tr key={idx} style={{display:'flex', margin:0}} className='tr_style display_onhover'>
						<td className='td_style flex_property' style={{ flex:'6'}}><span style={{overflowX:'auto',textTransform:'capitalize', whiteSpace:'nowrap'}}>{cstyle.name}</span></td>
						<td className='td_style flex_property' style={{ flex:'12'}}><span style={{overflowX:'auto',textTransform:'none', whiteSpace:'nowrap'}}>{cstyle.description}</span></td>
                        <td className='td_style flex_property' style={{ flex:'4'}}><span style={{overflowX:'auto',textTransform:'none', whiteSpace:'nowrap'}}>{capitalizeAndReplaceUnderscores(cstyle.sku_type)}</span></td>
                        <td className='td_style flex_property x1' style={{flex: '3'}}>
                                <span className="hide" style={{flex: 1, justifyContent: 'space-between', paddingRight: '24px'}}>
                                    <span onClick={() => {on_click_edit_callback(cstyle)}} style={{color: '#0078FF', cursor: 'pointer'}}>{'Edit'}</span>
                                    <span onClick={() => on_click_delete_callback(cstyle && cstyle.id)} style={{color: '#FF5247', cursor: 'pointer'}}>{'Delete'}</span>
                                </span>
                        </td>
						
					</tr>
				)
				: 
				(
					''

				)
			}
			</tbody>
		</table>
	)
}


const Construction_style = ({user, orignal_tags, store_details, get_store_details, set_active_tab, set_active_division_id_sidebar, submit_for_delete_confirm, construction_styles_list, set_page_loader}) => {

	const [construction_styles, set_construction_styles] = useState([])
	const [show_bulk_edit_modal, set_show_bulk_edit_modal] = useState(false)
    const [active_style, set_active_style] = useState('')
    const [active_style_id, set_active_style_id] = useState('');
    const [display_construction_styles, set_display_construction_styles] = useState([])
    const [cs_search_string, set_cs_search_string] = useState('')
    const [comp_file_type, set_comp_file_type] = useState('')

    const alert = useAlert();

    // useEffect(() => {
    //     console.log("Kpp the active style is", active_style)
    // }, [active_style])

    // useEffect(() => {
    //     console.log("Kpp the construction styles are", construction_styles)
    // }, [construction_styles])

    const check_property = (item, property) => {
        return item[property] && item[property].toLowerCase() && (item[property].toLowerCase().includes(cs_search_string) || capitalizeAndReplaceUnderscores(item[property]).toLowerCase().includes(cs_search_string))
    }

    useEffect(() => {
        set_display_construction_styles(construction_styles.filter(item => check_property(item, 'name') || check_property(item, 'description') || check_property(item, 'sku_type')))
    }, [construction_styles, cs_search_string])

	useEffect(() => {
		set_active_tab('preferences_settings');
		set_active_division_id_sidebar('construction_style');
    }, []);

    const fetch_all_construction_styles = async() => {
        try{
            let resp = await fetch_all_subcategories();
            let template = resp && resp.length && resp.find(item => item.id == 'template')
            let style_cat = template && template.sku_category && template.sku_category.length && template.sku_category.find(item => item.sku_category_type_id == "construction_style_template")
            let style_subcat = style_cat && style_cat.sku_sub_category && style_cat.sku_sub_category.length && style_cat.sku_sub_category[0].id;
            let groups = await fetch_sku_groups(style_subcat);
            let style_group = groups && groups.length && groups[0];
            // console.log("Kppp cs styles",style_group && style_group.sku && style_group.sku.length ? style_group.sku : [] )
            set_construction_styles(style_group && style_group.sku && style_group.sku.length ? style_group.sku : [])
        } catch(err){
            handleError(err)
        }
    }

    const on_click_edit_callback = async(style) => {
        // console.log("style.id kpppp", style.id)
        let resp = await fetch_skus([style.id])
        let comp_file = resp && resp.length && resp[0].component_file
        let active_style_temp = comp_file && comp_file.length && comp_file[0].data;
        active_style_temp.style_name = resp && resp.length && resp[0].name;
        active_style_temp.style_desc = resp && resp.length && resp[0].description;
        set_comp_file_type(comp_file && comp_file.length && comp_file[0].type);
        set_active_style_id(style.id)
        set_active_style(active_style_temp)
        set_show_bulk_edit_modal(true);
    }

    const on_click_delete_callback = async(style_id) => {
        submit_for_delete_confirm(()=>on_confirm_delete_style(style_id), ()=>{}, 'Are you sure you want to delete this Style?')
    }

    const on_confirm_delete_style = async(style_id) => {
        try{
            set_page_loader({
                text:'Deleting the style, please wait...',
                show:true
            })
            let resp = await remove_sku(style_id);
        } catch(err){
            handleError(err);
            alert.error('Deleting the style failed. Please try again')
        }
        set_page_loader({
            show: false
        })
        handle_close();
    }
    
    const handle_close = () => {
        set_show_bulk_edit_modal(false); 
        set_active_style(''); 
        set_active_style_id('')
        set_comp_file_type('')
        fetch_all_construction_styles();
    }

    useEffect(() => {
        fetch_all_construction_styles()
    }, [])
    
    return (
        <Main_Grid active_tab={'construction_style'}>
			<BulkEditSKUComponent
				open = {show_bulk_edit_modal}
				handle_close = {handle_close}
				set_page_loader = {set_page_loader}
				user = {user}
                active_style={active_style}
                active_style_id={active_style_id}
                comp_file_type={comp_file_type}
			/>
            <Row style={{padding:'10px 0px', color:'#22272e'}}>
	        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
	        		Viewing {display_construction_styles.length} Construction Styles
	        	</Col>
                <Col xs={6} style={{textAlign:'right'}}>
	        		<button onClick ={() => {set_show_bulk_edit_modal(true)}} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Construction Style</span></button>
	        	</Col>
        	</Row>
	        <Row style={{height:'calc(100% - 90px)'}}>
		        <Col style={{height:'100%'}}>
		        	<Card style={{borderRadius: 0, height:'100%'}}>
		        		<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => {set_cs_search_string(e.target.value)}} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Name, Description or SKU Type' /></div>
		        		<div style={{height:'calc(100% - 40px)'}}>{construction_styles && construction_styles.length ? (<Construction_styles_table construction_styles={display_construction_styles} on_click_edit_callback={on_click_edit_callback} on_click_delete_callback={on_click_delete_callback}/>) : (<Filler />)}</div>
					</Card>
		        </Col>
	        </Row>
        </Main_Grid>
    )
}

export default Construction_style;