import React , { useState , useEffect, useContext, useMemo }from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Card, CardHeader, CardBody, Input, CardFooter, Button, Modal, ModalHeader, ModalBody, Nav, Navbar, NavItem, NavLink, TabContent, TabPane, Tooltip, UncontrolledTooltip } from 'reactstrap';
import './header.css';
import * as Sentry from "@sentry/react";
import general_fetch from '../../utils/fetch';
import { useAlert } from 'react-alert'
import UploadFile from "../../components/UploadFile";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DeleteNotification from "../DeleteNotification";
import useStateCallback from '../../utils/useStateCallback.js';
import Country_list from '../../utils/country';
import PricingTermsConditions from "../PricingTermsConditions";
import io from 'socket.io-client';
import EscapeWarning from '../../components/EscapeWarning';
import remove_underscore_wtih_first_cap from "../../utils/remove_underscore_wtih_first_cap";
import classnames from "classnames";
import { AllBuContext } from "../Contexts/AllBuContext";
import { OrgBuContext } from "../Contexts/OrgBuContext";
import { UserContext } from "../Contexts/UserContext";
import { app_loader_atom } from "../../AppRecoil/atoms";
import {useSetRecoilState} from 'recoil';
import { AdminVersionContext } from "../Contexts/AdminVersionContext";
import { BuDetailsContext } from "../Contexts/BuDetailsContext.js";
import name_sort from "../../utils/name_sort";
import { IModalBody } from "../IModal/index.js";
import { COLORS } from "../../utils/colors.js";
import { handleError } from "../../utils/error.js";
import { is_valid_file_type } from "../../utils/is_valid_file.js";

const styles =  ({
        nav_bar: {
            background: 'white',
            // background: COLORS.primary,
            // color: COLORS.white,
            width:'100%',
            color: 'black',
            height:'52px'
        },
        nav_item: {
            display:'flex',
            alignItems:'center'
        },
        infurnia_logo:{
            maxWidth: '100px',
            maxHeight: '34px',
            width: '100%',
            objectFit: 'contain'
        },
        nav_bar_icons: {
            marginBottom: '0px',
            padding:'8px',
            display:'flex',
            alignItems:'center',
            height:'40px'
        },
        vertical_nav_bar_icons: {
            lineHeight: '20px',
            marginBottom: '0px',
            padding:'8px',
        },
});

const View_User_Profile = ({open, handleclose}) => {

	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)

	const is_bu_role_not_empty = () => {
		if(user.role && user.role.business_unit && user.role.business_unit[user.current_business_unit_id]){
			return true
		}
		let err = new Error('Error, roles of user in current business_unit are undefined, user= ' + JSON.stringify(user))
		Sentry.captureException(err)
		return false
	}

	//let roles = [{id: 'designer', role: 'Designer'} , {id: 'accounts_manager', role: 'Account Manager'}, {id: 'catalog_manager', role: 'Catalog Manager'}, {id: 'project_manager', role: 'Project Manager'}, {id: 'sales_admin', role: 'Sales Admin'}]

	return (
		<Modal isOpen={open} toggle={handleclose} className='modal-dialog-centered'>
			<ModalHeader toggle={handleclose}>User Profile</ModalHeader>
			<ModalBody>
				<div style={{display:'flex', alignItems:'center', padding:'16px'}}>
					<span style={{flex:'2', display:'flex', alignItems:'center'}}>User Name</span>
					<div style={{ flex:'3', border:'1px solid rgba(197, 199, 207)', borderRadius:'4px', padding:'8px 16px', color:'rgba(197, 199, 207)'}}>{user ? ((user.first_name?user.first_name:'-')+' '+(user.last_name?user.last_name:'')) : "Name not Found"}</div>
				</div>

				<div style={{display:'flex', alignItems:'center', padding:'16px'}}>
					<span style={{flex:'2', display:'flex', alignItems:'center'}}>Email ID</span>
					<div style={{ flex:'3', border:'1px solid rgba(197, 199, 207)', borderRadius:'4px', padding:'8px 16px', color:'rgba(197, 199, 207)'}}>{user ? user.email : "Email Not Found"}</div>
				</div>

				<div style={{display:'flex', alignItems:'center', padding:'16px'}}>
					<span style={{flex:'2', display:'flex', alignItems:'center'}}>Access Level</span>
					<div style={{ flex:'3', border:'1px solid rgba(197, 199, 207)', borderRadius:'4px', padding:'8px 16px', color:'rgba(197, 199, 207)'}}>
					{
						org_bu_view === 'bu'?
						(
							user && is_bu_role_not_empty() ? user.role.business_unit[user.current_business_unit_id].map(item => (
								<span>{remove_underscore_wtih_first_cap(item)}, </span>
							)): ''
						):
						(
							user ? user.role && user.role.store && user.role.store.length && user.role.store.map(item => (
								<span>{remove_underscore_wtih_first_cap(item)}, </span>
							)): ''
						)
					}
					</div>
				</div>

			</ModalBody>
		</Modal>
	)
}

const Edit_store_profile = ({user, open , handleclose, onclick_logout, store_details, get_store_details}) => {

	const [file, set_file] = useState('');
	const [store_name, set_store_name] = useState('');
	const [store_type, set_store_type] = useState('');
	const [image_path, set_image_path] = useState('');

	const [gst_number , set_gst_number] = useState('')
	const [address_line1 , set_address_line1] = useState('')
	const [address_line2 , set_address_line2] = useState('')
	const [city , set_city] = useState('')
	const [address_state , set_address_state] = useState('')
	const [country , set_country] = useState('')
	const [zipcode , set_zipcode] = useState('')
	
	const [show_escape_warning, set_show_escape_warning] = useState(false);
	const [discard_changes, set_discard_changes] = useState(false);
	const [tabs, set_tabs] = useState(1);

	const [tandc_array, set_tandc_array] = useState([]);

	const all_bu_in_store = useContext(AllBuContext)
	const org_bu_view = useContext(OrgBuContext)

	
	const alert = useAlert()
	const [page_loader,set_page_loader] = useStateCallback({
        show: false,
        text: "Please wait ..."
    });

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	}

	const onclick_update_store_logo = async () => {
		if(file){
			try {
				if(!is_valid_file_type(file, ['jpeg','jpg', 'png'])){
					alert.error("Invalid file format! Please upload a file with a valid format(jpeg, jpg, png).")
					return
				}
				var fd = new FormData();
				fd.append('upl', file, file.name)
				if(org_bu_view === 'bu'){
					fd.append('business_unit_id', user.current_business_unit_id)
					let resp_bu = await general_fetch({ url: 'business_unit/update_logo', body:fd, is_form_data:true});
				}else{
					let resp = await general_fetch({ url: 'store/upload_logo', body:fd, is_form_data:true});
					if(all_bu_in_store && all_bu_in_store.length === 1){
						fd.append('business_unit_id', user.current_business_unit_id)
						let resp_bu = await general_fetch({ url: 'business_unit/update_logo', body:fd, is_form_data:true});
					}
				}
				alert.success('Logo successfully uploaded')	
			} catch(err) {
				alert.error('Error while uploading logo')
				console.error(err)
				throw new Error
			}
		}else{
			alert.error('Please upload store logo')
		}
	}

	const onclick_update_pricing_details = async () => {
		if((tandc_array && tandc_array.length==1) || is_valid_add_key(tandc_array)){
			try {
				console.log("tandc", JSON.stringify(tandc_array))
				var updates = JSON.stringify({ boq_terms_and_conditions: JSON.stringify(tandc_array)})
				var body = {updates:updates}
				var response = await general_fetch({ url: 'store/update_info' , body });
				alert.success('Store Pricing T & C text successfully updated')	
				onclick_handleclose();	
				get_store_details()	
			} catch(err) {
				alert.error('API call failed')
				console.log(err);
			}
		}else{
			alert.error("Empty values are not allowed !")
		}
	}

	const is_valid_add_key = () => {
		if(tandc_array && tandc_array.length > 0){
			for(let i=0;i<tandc_array.length;i++){
				// console.log("printing tandc", tandc_array[i][0])
				if(tandc_array[i][0].text==='' || tandc_array[i][1].text==='')
					return false
			}
			return true
		}else{
			// console.log("printing tandc true")
			return true
		}
	}

	const onclick_update_name = async () => {
		try {
			let location = {
				...(city ? { city } : {}),
				...(address_line1 ? { line_1: address_line1 } : {}), 
				...(address_line2 ? { line_2: address_line2 } : {}),
				...(address_state ? { state: address_state } : {}),
				...(country ? { country } : {}),
				...(zipcode ? { pin_code: zipcode } : {}),
			}
			let updates = {
				...(store_name ? { name: store_name } : {}),
				...(gst_number ? { gst_number } : {}),
			}
			location = Object.keys(location).length > 0 ? JSON.stringify(location) : null;
			updates = Object.keys(updates).length > 0 ? JSON.stringify(updates) : null;
			if (!location && !updates) {
				alert.error('Please enter atleast one field to update')
				return;
			}
			let body = {location,updates}
			let response
			if(org_bu_view === 'bu'){
				body.business_unit_id = user.current_business_unit_id
				response = await general_fetch({ url: 'business_unit/update_info' , body });
			}else{

				response = await general_fetch({ url: 'store/update_info' , body });
			}
			if(response){
				window['analytics'].group(store_details.id, {
					name: store_details.name,
					company_id: store_details.id,
					plan: store_details.plan_type
				})
			}
			alert.success('Store Details successfully updated')	
		} catch(err) {
			alert.error('failed to update store details')
			console.error(err);
			throw new Error
		}
	}

	const onclick_handleclose =() => { 
		handleclose()
		set_store_name('')
		set_image_path('')
		set_store_type('')
		set_file()

		set_gst_number('')
		set_address_line1('')
		set_address_line2('')
		set_city('')
		set_address_state('')
		set_country('')
		set_zipcode('')
		//set_show_escape_warning(false)
	}

	useEffect(() => {
		if(file){
			console.log(file)
			set_image_path('')
		}
	},[file])

	useEffect(() => {
		console.log("country", country)
	}, [country]);

	useEffect(() => {
		if(store_details){
			set_store_name(store_details?store_details.name:'')
			set_store_type(store_details?store_details.type:'')
			set_image_path(store_details && store_details.logo_path?(global.config.server_path+store_details.logo_path):'')

			set_gst_number(store_details.gst_number);
			set_address_line1(store_details.location ? store_details.location.line_1: '');
			set_address_line2(store_details.location ? store_details.location.line_2: '');
			set_city(store_details.location ? store_details.location.city: '');
			set_address_state(store_details.location ? store_details.location.state: '');
			set_zipcode(store_details.location ? store_details.location.pin_code: '');
			set_country(store_details.country ? store_details.country: '');
		}
	},[store_details, open])

	useEffect(() => {
		console.log("discard", discard_changes)
		if(discard_changes == true){
			onclick_handleclose()
			set_discard_changes(false)
		}
	},[discard_changes])

	const submit = (yes_del, no_del, message, nonowned) => {
        confirmAlert({
          title: 'Confirm to submit',
          message: message,
          customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                    <DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} nonowned={nonowned}/>
                </div>
            );
          }
        });
    };

	const delete_account = async () => {
		try {
			var resp = await general_fetch({ url: 'user/delete_own_account'});
			alert.success('User successfully Deleted')
			onclick_logout()
			window.location.href=global.config.auth_redirect_path
		} catch(err) {
			alert.error('Cannot Delete Your account')
			console.log(err);
		}
	}

	const onclick_update_store = async() => {
		// if(file){
		// 	onclick_update_store_logo()
		// }
		if(file && file.size <= 2500000){
			try{
				await onclick_update_name()
				await onclick_update_store_logo()
				onclick_handleclose()
				if(org_bu_view === 'bu'){
					global.get_bu_aux_details()
				}else{
					get_store_details()
				}
			}catch(err){
				
			}
			// fd.append('upl', file, file.name)
		}else if(file){
			alert.error('Please select a file of size less than 2.5MB')
			return
		}else{
			try{
				await onclick_update_name()
				onclick_handleclose()
				if(org_bu_view === 'bu'){
					global.get_bu_aux_details()
				}else{
					get_store_details()
				}
			}catch(err){
				
			}
		}
	}

	return(
		<Modal className="Margin_64px_top" size="md" isOpen={open} toggle={() => set_show_escape_warning(true)}>
			<EscapeWarning open = {show_escape_warning} set_discard_changes={set_discard_changes} handleClose={() => set_show_escape_warning(false)}/>
			<Card className='global_modal_height_for_mdlgxl'>
				<CardHeader className='global_modal_header'>
					<span>Account Settings</span>
					<i onClick={onclick_handleclose} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body' style={{padding:0}}>
					<Nav 
						style={{display:'flex', alignItems:'center', fontSize:'14px', backgroundColor:'white', padding:'2px 0px 2px 15px', position:'sticky', top:'0', zIndex:'1'}}
						className="flex-md-row"
						id="tabs-icons-text" 
						pills
						role="tablist"
					>
						<NavItem>
							<NavLink
								aria-selected={tabs === 1}
								style = {{}}
								className={classnames("mb-sm-3 mb-md-0", {
									active: tabs === 1
								})}
								onClick={e => toggleNavs(e, 1)}
								href="#pablo"
								role="tab"
							>
								<span>Profile</span>
							</NavLink>
						</NavItem>
						{/* <NavItem>
							<NavLink
								aria-selected={tabs === 2}
								style = {{}}
								className={classnames("mb-sm-3 mb-md-0", {
									active: tabs === 2
								})}
								onClick={e => toggleNavs(e, 2)}
								href="#pablo"
								role="tab"
							>
								<span>Pricing Settings</span>
							</NavLink>
						</NavItem> */}
					</Nav>
					<TabContent activeTab={"tabs" + tabs}>
						<TabPane tabId={'tabs1'} style={{fontSize:'12px', padding:'30px 80px'}}>
							<Row style={{marginBottom:"10px"}}>
							<Col xs={6}>
								<div>
									<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>{(org_bu_view == 'bu' && all_bu_in_store && all_bu_in_store.length > 1)?'BU Name':'Organization Name'} *</div>
									<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Store Name" type="text" value={store_name} onChange={e => set_store_name(e.target.value)}/>
								</div>
							</Col>
							{
								['india','IN'].includes(country)? (
									<Col xs={6}>
										<div>
											<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>GST no. / Vat no. / Sales no.</div>
											<Input value={gst_number} onChange={(e) => set_gst_number(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="GST no. / Vat no. / Sales no." type="text" />
										</div>
									</Col>
								):''
							}		
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Organization Type</div>
										<Input disabled style={{textTransform:'capitalize',width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#f2f2f2', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Store Name" type="text" value={store_type}/>
									</div>
								</Col>	
							</Row>
							<Row style={{margin:"20px 0px 10px"}}>
								<Col style={{padding:0}}>
									<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
										
									</div>
								</Col>
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col style={{fontSize:'16px'}}>
									Organization Address
								</Col>
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Address Line 1 *</div>
										<Input value={address_line1} onChange={(e) => set_address_line1(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Line 1" type="text" />
									</div>
								</Col>
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Address Line 2</div>
										<Input value={address_line2} onChange={(e) => set_address_line2(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Line 2" type="text" />
									</div>
								</Col>
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col xs={6}>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>City *</div>
										<Input value ={city} onChange={(e) => set_city(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="City" type="text" />
									</div>
								</Col>
								<Col xs={6}>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Province / State*</div>
										<Input value ={address_state} onChange={(e) => set_address_state(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder={country=='india'?'State':'Province'} type="text" />
									</div>
								</Col>		
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col xs={6}>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Zip code / Pin code *</div>
										<Input value ={zipcode} onChange={(e) => set_zipcode(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder={country=='india'?'Zip':'Pin'} type="text" />
									</div>
								</Col>
								<Col xs={6}>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Country *</div>
										<Input disabled value ={country} /*onChange={(e) => set_country(e.target.value)} */ style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#f2f2f2', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Country" type="text">
											{/* {
												Country_list.map((country, idx) => (
													<option key={idx} value={country.code}>{country.name}</option>
												))
											} */}
										</Input>
									</div>
								</Col>		
							</Row>

							<Row style={{margin:"20px 0px 10px"}}>
								<Col style={{padding:0}}>
									<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
										
									</div>
								</Col>
							</Row>

							<Row style={{marginBottom:"5px"}}>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'16px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '30px'}}>Upload Store Logo</div>
										<UploadFile id="id_upload_file" set_file={set_file} accept='.jpg,.png,.jpeg, .gif'></UploadFile>	
										{
											file && file.size>2500000?
											<div style={{paddingTop:'7px',fontSize:'12px', color:'red', letterSpacing: '-0.04px', lineHeight: '20px'}}>File size exceeded, Please select a file of size less than 2.5 MB</div>
											:
											!file?<div style={{paddingTop:'7px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Please select a file of size less than 2.5 MB</div>:''
										}
									</div>
								</Col>	
							</Row>
							<Row>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>{file && file.name?file.name:''}</div>
									</div>
								</Col>	
							</Row>

							{
								image_path?
								<Row style={{marginBottom:"5px"}}>
									<Col>
										<div>
											<img style={{height:'50px', width:'50px'}} src={image_path}/>
										</div>
									</Col>	
								</Row>
							:''}

							<Row style={{margin:"20px 0px 10px"}}>
								<Col style={{padding:0}}>
									<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
										
									</div>
								</Col>
							</Row>

							{/* {
								store_details && store_details.type==='professional'?
								<Row style={{margin:"10px 0px 8px"}}>
									<Col style={{padding:"0px"}}>
										<div>
											<span 
												onClick ={() => {onclick_handleclose();submit(()=>delete_account(), ()=>{},'Are you sure you want to delete your account? If you click on Delete, you will permanently lose access to all your data.')}}
												title='Clicking on Delete will permanently delete your account and lose access to all your data.' 
												style={{fontSize:'16px', color:'red', lineHeight: '20px', cursor:'pointer',color:'rgb(245, 89, 78)'}}
											>Delete Account</span>
										</div>
									</Col>	
								</Row>
								:''
							} */}
						</TabPane>
						<TabPane tabId={'tabs2'} style={{fontSize:'12px', padding:'30px 80px'}}>
							{/* <Edit_price_settings_modal store_details={store_details} get_store_details={get_store_details} tandc_array={tandc_array} set_tandc_array={set_tandc_array}>

							</Edit_price_settings_modal> */}
						</TabPane>
					</TabContent>
					
				</CardBody>

				<CardFooter className='global_modal_footer'>
					<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>
					<Button className='blue_button' type="button" onClick={onclick_update_store}>Update</Button>
				</CardFooter>
			</Card>  
		</Modal>
	)
}


const infurnia_addons = {
	5: { name: 'Advanced Pricing', priceInCredits: '125', features: ['Component Based Pricing', 'Raw Material Based Pricing', 'Standardised Construction Logic'] },
	4: { name: 'Panel Cut-List', priceInCredits: '125', features: ['Panel Cut-List & Bill of Quantity', 'Board Layout Optimizer', 'Panel Labels/Tags'] },
	6: { name: 'MES Lite', priceInCredits: '125', features: ['Panel Cut-List & Bill of Quantity', 'Board Layout Optimizer', 'Panel Labels/Tags', "Work Order Management & Tracking"] },
	1: { name: 'Manufacturing', priceInCredits: '250', features: ['Everything in Panel Cut-List Plan +', 'Drilling & routing outputs for Biesse, Homag & Felder machines'] },
	7: { name: 'MES Pro', priceInCredits: '250', features: ['Everything in MES Lite +', 'Drilling & routing outputs for Biesse, Homag & Felder machines', "Packaging and Assembly"] },
	2: { name: 'API Integration', priceInCredits: '250', features: ['CRM / ERP Integration', 'Cabinet Creation API', 'Custom APIs to suit your workflow'] },
	3: { name: 'White Labeling', priceInCredits: '250', features: ['Custom URL', 'Self-Branded Landing Page', 'Self-Branded Emails']},
}

const infurnia_base_plan = { name: 'Base Subscription', priceInCredits: '125', features: ['All Design Features including Architecture, interior & modular kitchen design', 'Working Drawings', 'Custom Catalog', 'Cloud-based rendering'] }


const AddonCard = ({addon, is_subscribed, pricingDetails}) => {
	return (
		<div style={{display: addon && [1,4].includes(addon.id) && !is_subscribed ? 'none' : ''}} className={`addon_card ${is_subscribed ? 'addon_subscibed' : ''}`}>
			<div className={`addon_name`}>
				{/* Addon Name */}
				{addon.name}
			</div>
			<div className='addon_pricing'>
				{/* Subscription status/Cost */}
				{is_subscribed ? 'Subscribed' : `${pricingDetails.currency === 'INR' ? '₹' : '$'} ${addon.priceInCredits * (pricingDetails.cost_per_credit ? pricingDetails.cost_per_credit : 1)} / mo`}
			</div>
			<div className='addon_features'>
				{/* Addon Details */}
				<ul style={{margin: '8px 0px'}}>
				{
					addon.features ? addon.features.map(feature => (
						<li>{feature}</li>
					))
					:''
				}
				</ul>
			</div>
		</div>
	)
}

export const InfurniaPlansModal = ({open, toggle}) => {

	const [infurniaAddons, setInfurniaAddons] = useState({});
	const [infurniaBasePlan, setInfurniaBasePlan] = useState({});
	const [pricingDetails, setPricingDetails] = useState({});


	useEffect(() => {
		if(open){
			let info = (window.get_store_subscription_info())
			if(info && info.add_ons && info.add_ons.subscribed){
				for(let i in info.add_ons.subscribed){
					let addon = info.add_ons.subscribed[i]
					infurnia_addons[addon.id].priceInCredits = addon.credit_cost/3
					infurnia_addons[addon.id].subscribed = true
				}
			}

			if(info && info.add_ons && info.add_ons.subscribable){
				for(let i in info.add_ons.subscribable){
					let addon = info.add_ons.subscribable[i]
					infurnia_addons[addon.id].priceInCredits = addon.credit_cost/3
					infurnia_addons[addon.id].subscribed = false
				}
			}
			setInfurniaAddons(infurnia_addons)
			if(info){
				infurnia_base_plan.subscribed = info.premium_customer && !info.churned && !info.suspended
				if(info.ccr_details){
					setPricingDetails(info.ccr_details)
				}
			}
			setInfurniaBasePlan(infurnia_base_plan)
		}
	}, [open]);

	return(
		<Modal isOpen={open} toggle={toggle} className='modal-dialog-centered' style={{maxWidth: 'min(90%, 1400px)'}}>
			<IModalBody style={{padding: '2rem 4rem', borderRadius: '6px', position: 'relative'}}>
				<img onClick={toggle} style={{cursor:'pointer', position: 'absolute', right: '2rem', top: '2rem'}} height={'16px'} width={'16px'} src={'/assets/icons/modal_cross.svg'}></img>
				<div className='flex_center mb-4 mt-3'>
					<div style={{borderRadius: '6px', background: COLORS.gray3, fontSize: '24px', color: COLORS.gray6, padding: '8px 36px'}}>
						Current Subscription
					</div>
				</div>
				<div className='flex_center mb-4'>
					<div style={{color: COLORS.gray7, fontSize: '16px', fontWeight: 600}}>
						Contact your customer success executive for Add-on updation.
					</div>
				</div>
				<div className='flex_center' style={{gap: '48px'}}>
					<div style={{width: '300px'}}>
						{/* Base Plan */}
						<AddonCard addon={infurniaBasePlan} is_subscribed={infurniaBasePlan.subscribed} pricingDetails={pricingDetails}/>
					</div>
					{/* Addons Grid below */}
					<div className='p-4' style={{flex: 1, display: 'grid', gridTemplateColumns: 'repeat(auto-fit,minmax(240px,1fr))', gap: '36px', background: 'white', borderRadius: '10px', border: '1px solid var(--light-border-color)'}}>
						{
							Object.keys(infurniaAddons).map(addon => (
								<AddonCard addon={infurniaAddons[addon]} is_subscribed={infurniaAddons[addon].subscribed} pricingDetails={pricingDetails}/>
							))
						}
					</div>
				</div>
			</IModalBody>
		</Modal>
	)
}

const Switch_Org_BU = ({open, handleClose, store_details, user, set_page_loader}) => {

	const [all_store_options, set_all_store_options] = useState([]);
	const [display_store_options, set_display_store_options] = useState([]);
	const [selected_bu_json, set_selected_bu_json] = useState({});
	const [all_business_units, set_all_business_units] = useState();
	const [search_string, set_search_string] = useState('');
	const set_app_loader = useSetRecoilState(app_loader_atom)
	const alert = useAlert()

	const fetch_attached_stores = async() => {
		try{
			set_app_loader({show: true, text: 'Fetching Orgs...'})
			let resp = await general_fetch({ url: 'user/get_attached_stores'})
			set_app_loader({show: false})
			set_all_store_options(resp.stores.sort(name_sort))
			set_all_business_units(resp.business_units)
			let a = {}
			if(resp.stores && resp.stores.length){
				resp.stores.forEach(single_store => {
					if(single_store.business_unit_ids && single_store.business_unit_ids.length == 1){
						a[single_store.id] = single_store.business_unit_ids[0]
					}
				})
				set_selected_bu_json(a)
			}
		}catch(err){
			console.log(err)
		}
	}

	const update_selected_bu_json = (store_id, bu_id) => {
		let a = JSON.parse(JSON.stringify(selected_bu_json))
		a[store_id] = bu_id
		set_selected_bu_json(a)
	}

	const switch_store_or_bu = async(store_id) => {
		if(store_id && selected_bu_json[store_id]){
			try{
				set_page_loader({show:true, text: 'Switching Org'})
				window.sessionStorage.setItem('org_bu_view', 'bu')
				let resp1 = await general_fetch({url:'user/set_active_store', body:{selected_store_id:store_id}})
				let resp2 = await general_fetch({url:'user/set_current_business_unit', body:{business_unit_id: selected_bu_json[store_id]}})
				set_page_loader({show:false})
				window.location.href = global.config.admin_url
			}catch(err){
				set_page_loader({show:false})
				alert.error('Error while switching Business Unit')
				console.log(err)
			}
		}else{
			alert.error('Please select a Business Unit')
		}
	}

	const onclick_handleclose = () => {
		handleClose()
		set_all_store_options([])
		set_selected_bu_json({})
		set_all_business_units({})
	}

	const filter_stores = () => {
		let lower_search_string = search_string.toLowerCase()
		let filtered_stores = all_store_options && all_store_options.length ? JSON.parse(JSON.stringify(all_store_options)) : []
		filtered_stores = filtered_stores.filter(o => o.name && o.name.toLowerCase() ? o.name.toLowerCase().includes(lower_search_string) : false)
		set_display_store_options(filtered_stores)
	}

	useEffect(() => {
		if(open && store_details && user){
			fetch_attached_stores()
			update_selected_bu_json(store_details.id, user.current_business_unit_id)
		}
	}, [open, store_details, user]);

	useEffect(() => {
		set_display_store_options(all_store_options)
	}, [all_store_options]);

	useEffect(() => {
		filter_stores()
	}, [search_string]);

	return(
		<Modal className="Margin_64px_top" size="md" isOpen={open}>
			<Card >
				<CardHeader className='global_modal_header'>
					<span>Switch Org</span>
					<i onClick={onclick_handleclose} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>
				<CardBody className='global_modal_body' style={{height:'300px'}}>
					<Row className='search_component_container_ideal mb-3'>
                        <span><i style={{paddingLeft:'12px', paddingBottom:'3px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/></span>
                        <input className={'search_component_inner_container'} placeholder= {'Type to Search'} type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)} style={{width:'90%'}}></input>
                    </Row>
					<table style={{width: '100%'}} className="text-left flex_property table_style">
						<thead style={{width: '100%'}}>
							<tr className="tr_style">
								<th className='th_style' style={{flex:'4'}}>Organization</th>
								{/* <th className='th_style' style={{flex:'5'}}>Business Unit</th> */}
								{/* <th className='th_style' style={{flex:'2'}}>Switch</th> */}
							</tr>
						</thead>
						<tbody style={{width: '100%'}}>
						{
							display_store_options && display_store_options.length ? display_store_options.map((single_store, idx) => (
								<tr className='tr_style'>
									<td className='td_style' style={{flex:'4'}}>{single_store.name}</td>
									<td className='td_style' style={{flex:'5'}}>
									{
										all_business_units && single_store && single_store.business_unit_ids && single_store.business_unit_ids.length > 1 ?
										<Input type='select' value={selected_bu_json[single_store.id]} onChange={(e) => update_selected_bu_json(single_store.id, e.target.value)} style={{height:'28px', width:'150px', fontSize:'14px', padding:'0px 4px'}}>
											<option value=''>Select BU</option>
										{
											all_business_units && single_store && single_store.business_unit_ids && single_store.business_unit_ids.length ? single_store.business_unit_ids.map((business_unit) => (
												<option value={business_unit}>{all_business_units[business_unit] ? all_business_units[business_unit].name : ''}</option>
											)):''
										}
										</Input>:''
									}
									</td>
									{
										store_details && store_details.id === single_store.id && user && user.current_business_unit_id == selected_bu_json[single_store.id]?
										<td className="td_style" style={{flex:'2', color:'#008000', cursor:'pointer'}}>Active</td>
										:
										<td className="td_style" onClick={() => {switch_store_or_bu(single_store.id)}} style={{flex:'2', color:'#0078ff', cursor:'pointer'}}>Switch here</td>
									}
								</tr>
							)):''
						}
						</tbody>
					</table>
				</CardBody>
				<CardFooter className='global_modal_footer'>
					<Button className='white_button' style={{marginRight:'0px'}} onClick={onclick_handleclose}>Close</Button>
				</CardFooter>
			</Card>
		</Modal>
	)
}

const Header = ({ store_details, get_store_details}) => {

	const alert = useAlert()
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [show_edit_store_profile, set_show_edit_store_profile] = useState(false);
	const [show_current_subscription, set_show_current_subscription] = useState(false);
	const [bu_dropdown_open, set_bu_dropdown_open] = useState(false);
	const [design_link, set_design_link ] = useState('/design.infurnia.com/')
	// const [store_details, set_store_details] = useState('');	
	const [show_price_setting_modal, set_show_price_setting_modal] = useState(false);
	const [show_user_profile, set_show_user_profile] = useState(false);
	const [initialised, set_initialised] = useState(false);
	const [show_switch_org_modal, set_show_switch_org_modal] = useState(false);
	const [page_loader,set_page_loader] = useStateCallback({
        show: false,
        text: "Please wait ..."
    });
  	const toggle = () => setDropdownOpen(prevState => !prevState);

	const user = useContext(UserContext)
	const org_bu_view = useContext(OrgBuContext)
	const all_bu_in_store = useContext(AllBuContext)
	const version_outdated = useContext(AdminVersionContext)
	const bu_details = useContext(BuDetailsContext)
	// console.log('current_bu 2', all_bu_in_store, user)
	const [bu_name_map, set_bu_name_map] = useState({});

	const get_current_bu = () => {
		if(user && user.current_business_unit_id){
			return all_bu_in_store.find(o => o.id === user.current_business_unit_id) ? all_bu_in_store.find(o => o.id === user.current_business_unit_id) : all_bu_in_store[0]
		}else{
			return all_bu_in_store[0]
		}
		return null
	}

	const is_org_level_admin = (org_roles) => {
		const admin_roles = ['project_manager', 'accounts_manager', 'catalog_manager']
		for(let i = 0; i < org_roles.length; i++){
			if(admin_roles.includes(org_roles[i])){
				return true
			}
		}

		return false
	}

	const current_bu = bu_details

	// const get_store_details = async () => {
    //     try {
	// 		set_page_loader({
	// 			show: true,
	// 			text: "Fetching store details ..."
	// 		})
    //         var resp = await general_fetch({ url: 'store/get_info'});
    //         // set_store_details(resp? resp:{});
	// 		set_page_loader({
	// 			show: false,
	// 			text: ""
	// 		})
    //     } catch(err) {
    //         console.log(err);
    //     }
    // }

	const onclick_logout = async () => {
		try {		
            let resp = await general_fetch({ url: 'user/logout'});

			let check_enterprise_white_labelled_url = (window.location.host.split('.')[1] == 'infurnia') ? false : true;
			if (check_enterprise_white_labelled_url) {
				let [ subdomain, domain, extension ] = window.location.host.split('.');
				window.location.href = `https://login.${domain}.${extension}/?target=admin`;
			} else {
				window.location.href=global.config.auth_redirect_path;
			}

			alert.success('Logout successfull')	
			window.$globalSocket.emit('log_out');
			window.$globalSocket.close()
            // window.alert('This session is being logged out because another session of the same user has been detected');
		} catch(err) {
			alert.error('Error while logout')
			console.log(err);
			handleError(err)
		}
	}

	useEffect(()=>{
		if(global){
			let check_enterprise_white_labelled_url = (window.location.host.split('.')[1] == 'infurnia') ? false : true;
			if (check_enterprise_white_labelled_url) {
				let [ subdomain, domain, extension ] = window.location.host.split('.');
				set_design_link(`https://design.${domain}.${extension}/`);
			} else {
				set_design_link(global.config.website_url)
			}
		}
		// get_store_details()
	},[])

	useEffect(() =>{
        if(store_details){
            set_initialised(true)
        }
    },[store_details])

	const close_edit_store_profile = () => {
		set_show_edit_store_profile(false)
	}
	const close_current_subscription = () => {
		set_show_current_subscription(false)
	}
	const close_price_setting_modal = () => {
		set_show_price_setting_modal(false)
	}
	const close_user_profile_modal = () => {
		set_show_user_profile(false)
	}

	const checkout_for_acc_role = () => {
		if(org_bu_view == 'org'){
			var roles = user && user.role.store && user.role.store.length ?user.role.store:''
		}else{
			var roles = user && user.role.business_unit && user.role.business_unit[user.current_business_unit_id] ?user.role.business_unit[user.current_business_unit_id]:''
		}
		if(roles && roles.length){
			return roles.includes('accounts_manager')
		}else{
			return 0
		}
	}
	const open_edit_store_profile = () => {	
		if(checkout_for_acc_role()){
			set_show_edit_store_profile(true)
		}else{
			alert.error('Only Account Manager can access Account settings.')
		}
	}

	const open_current_subscription = () => {	
		// if(checkout_for_acc_role()){
			set_show_current_subscription(true)
		// }else{
		// 	alert.error('Only Account Manager can access Account settings.')
		// }
	}

	const open_price_setting = () => {
		if(checkout_for_acc_role()){
			set_show_price_setting_modal(true)
		}else{
			alert.error('Only Account Manager can access Store pricing settings.')
		}
	}

	const switch_bu = async(single_bu) => {
		try{
			if(single_bu){
				let resp = await general_fetch({url:'user/set_current_business_unit', body:{business_unit_id: single_bu}})
				window.location.href = global.config.admin_url
			}
		}catch(err){
			console.log(err)
			alert.error('Could not switch Business Unit!')
		}
	}

	useEffect(() => {
		if(all_bu_in_store && all_bu_in_store.length){
			let temp_bu_map = {}
			all_bu_in_store.map(bu => {
				temp_bu_map[bu.id] = bu.name
			})
			set_bu_name_map(temp_bu_map)
		}
	}, [all_bu_in_store]);

	return (
		<React.Fragment>
			<InfurniaPlansModal open={show_current_subscription} toggle={close_current_subscription}/>
			<Edit_store_profile alert={alert} user={user} open={show_edit_store_profile} handleclose={close_edit_store_profile} onclick_logout={onclick_logout} store_details={org_bu_view === 'bu' ? bu_details : store_details} get_store_details={get_store_details}/>
			{/* <Edit_price_settings_modal user={user} open={show_price_setting_modal} handleclose={close_price_setting_modal} store_details={store_details} get_store_details={get_store_details}/> */}
			<View_User_Profile open={show_user_profile} handleclose={close_user_profile_modal}/>
			<Switch_Org_BU open={show_switch_org_modal} handleClose={() => set_show_switch_org_modal(false)} store_details={store_details} user={user} set_page_loader={set_page_loader}/>
			<Card style={{height:'100%', width:'100%', borderRadius:0}}>
				
				<div style={{height:'100%', width:'100%',display:'flex', alignItems:'center',justifyContent:'space-between'}}>
					<span className="flex_property" style={{height:'40px', width:'auto', marginLeft:'16px'}}>
                    {
                        initialised?
                        <img height='100%' width='auto' alt="Infurnia" style={{borderRight: '2px solid #E6E9EE',paddingRight:'16px'}}
                            src={!store_details.white_labelling ? "../../../assets/img/brand/blackLogo.png" : org_bu_view === 'bu' ? (current_bu && current_bu.logo_path ? global.config.server_path + current_bu.logo_path : store_details && store_details.logo_path?global.config.server_path+store_details.logo_path :"../../../assets/img/brand/blackLogo.png") : store_details && store_details.logo_path?global.config.server_path+store_details.logo_path : "../../../assets/img/brand/blackLogo.png"}
                        />
                        :''
                    }
					<div className="flex_property" style={{height:'30px', borderTopRightRadius:'15px', borderBottomRightRadius:'15px', backgroundColor:'#F5F6F8', marginLeft:'16px'}}>
						<div className="flex_center" style={{width:'80px', height:'30px', borderTopRightRadius:'15px', borderBottomRightRadius:'15px', backgroundColor:'#E6E9EE'}}>
							Admin
						</div>
						{
							org_bu_view === 'bu' && user && ((user.business_unit_ids && user.business_unit_ids.length > 1) || (user.role && user.role.store && is_org_level_admin(user.role.store) && all_bu_in_store && all_bu_in_store.length > 1)) ?
							<div style={{}}>
								<Dropdown className='bu_dropdown' isOpen={bu_dropdown_open} toggle={() => set_bu_dropdown_open(!bu_dropdown_open)}>
									<DropdownToggle  className='flex_property' style={{backgroundColor:'#F5F6F8', color:'black', border:0, marginRight:'15px', height:'30px', border:0, borderTopRightRadius:'15px', borderBottomRightRadius:'15px', boxShadow:'none'}} caret>
										{current_bu && current_bu.name ? current_bu.name : ''}
									</DropdownToggle>
									<DropdownMenu right>
									{
										
										user && user.role && is_org_level_admin(user.role.store) ? all_bu_in_store && all_bu_in_store.length ? all_bu_in_store.map((single_bu) => (
											(single_bu.id === (current_bu && current_bu.id)) ? '':
											<DropdownItem style={{height:'32px'}} onClick={() => switch_bu(single_bu.id)}>{single_bu.name}</DropdownItem>	
										)) : ''
										:
										user.business_unit_ids && user.business_unit_ids.length ? user.business_unit_ids.map((single_bu) => (
											(single_bu === (current_bu && current_bu.id)) ? '':
											<DropdownItem style={{height:'32px'}} onClick={() => switch_bu(single_bu)}>{bu_name_map && bu_name_map[single_bu] ? bu_name_map[single_bu] : single_bu}</DropdownItem>	
										)) : ''
									}
									</DropdownMenu>
								</Dropdown>
							</div>:""
						}
					</div>
                    </span>
					<div style={{display:'flex', alignItems:'center'}}>
						{
							version_outdated ?
							<React.Fragment>
								<UncontrolledTooltip target={'infurnia-notification-container'} placement="bottom">
									Our software on your browser is not up to date, Please refresh the page to get the latest update.
								</UncontrolledTooltip>
								<div id="infurnia-notification-container" className="mr-2 px-2 bell-icon-container cp">
									<i class="far fa-bell"></i>
									{/* <span style={{position: 'relative', height: '3px', width: '3px', top: '2px', right: '2px'}}>
										<span class="animate-ping" style={{position: 'absolute', display: 'inline-flex', height: '100%', width: '100%', borderRadius: '50%', backgroundColor: '#0078ff', opacity: '0.75'}}></span>
										<span style={{position: 'relative', display: 'inline-flex', height: '3px', width: '3px', borderRadius: '50%', backgroundColor: '#0078ff'}}></span>
									</span> */}
									<div className="notification-dot"></div>
									<div className="notification-dot-animate"></div>
								</div>
								</React.Fragment>
								: ''
						}
						{/* <div style={{marginRight:'16px', cursor:'pointer'}} className='design_onhover' onClick={() => {}}>Switch Org/BU</div> */}
						<a target='_blank' style={{ cursor: 'pointer', marginRight:'10px', width: 60 }} className='design_onhover'></a>
						{
							user && user.role.store && user.role.store.length && user.role.store.includes('accounts_manager') && all_bu_in_store && all_bu_in_store.length > 1?
							(org_bu_view === 'bu' ?
							<Button onClick={() => {global.set_org_bu_view('org'); window.sessionStorage.setItem('org_bu_view', 'org'); window.location.href = global.config.admin_url}} className='white_button' style={{marginRight:'16px', height:'30px', boxShadow:'none'}}>Switch to Org View</Button>:
							<Button onClick={() => {global.set_org_bu_view('bu'); window.sessionStorage.setItem('org_bu_view', 'bu'); window.location.href = global.config.admin_url}} className='white_button' style={{marginRight:'16px', height:'30px', boxShadow:'none'}}>Switch to BU View</Button>):''
						}
						<a href={design_link} target='_blank' style={{marginRight:'44px', paddingRight:'16px', borderRight:'2px solid #E6E9EE'}} className='design_onhover'><div className='flex_property'><img src='/assets/icons/newtab.svg' width={'16px'} className='mr-1'/>Go To Design Portal</div></a>
						<Dropdown isOpen={dropdownOpen} toggle={toggle}>
							<DropdownToggle className='admin_onhover' style={{backgroundColor:'white', color:'black', border:0, display:'flex', alignItems:'center', marginRight:'12px', padding:'4px'}} caret>
								{/* <span style={{display:'flex', alignItems:'center',justifyContent:'flex-end'}}> */}
									{/* <span style={{marginRight:'20px'}}><i style={{cursor:'pointer', fontWeight: 1100}} className='fa fa-bell'/></span> */}
									<span  style={{cursor:'pointer', display:'flex', alignItems:'center', marginRight:'0px'}}>
										<div
											className="circle"
											// alt="..."
											// src="/assets/img/icons/avatar.png"
											style={{height:'24px', width:'24px'}}>
												<p className="circle-inner">{user && user.first_name ? user.first_name.trim()[0] : ''}</p>
											</div>
										{/* <span className="mb-0 text-sm" style={{fontSize:'14px',color:'black'}}>
											Admin
										</span> */}
									</span>
								{/* </span> */}
							</DropdownToggle>
							<DropdownMenu right>
								<DropdownItem onClick={open_edit_store_profile}>Account Settings</DropdownItem>
								<DropdownItem onClick={open_current_subscription}>Current Subscription</DropdownItem>
								{/* <DropdownItem onClick={open_price_setting}>Org Pricing Terms</DropdownItem> */}
								<DropdownItem onClick={() => set_show_user_profile(true)}> User Profile </DropdownItem>
								<DropdownItem onClick={() => set_show_switch_org_modal(true)}> Switch Org </DropdownItem>
								<DropdownItem onClick={onclick_logout}>Logout</DropdownItem>
							</DropdownMenu>
						</Dropdown>
    				</div>
	        	</div>
	        	
			</Card>
		</React.Fragment>
	);
}

export default Header;