import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { IModalBody, IModalFooter, IModalHeader } from "../IModal"
import general_fetch from "../../utils/fetch";
import { Button, Input, Modal } from "reactstrap";
import { useState } from "react";

const GSTModal = ({open, toggle, store_details, fetch_store_info}) => {

	const [gst_number, set_gst_number] = useState('');
	const [state, set_state] = useState('');
	const [address_line1 , set_address_line1] = useState('')
	const [address_line2 , set_address_line2] = useState('')
	const [city , set_city] = useState('')
	const [zipcode , set_zipcode] = useState('')
	const alert = useAlert()

	const onclick_handle_close = () => {
		set_gst_number('')
		toggle()
	}

	useEffect(() => {
		if(store_details){
			set_gst_number(store_details.gst_number ? store_details.gst_number : '')
			if(store_details.location){
				set_address_line1(store_details.location.line_1);
				set_address_line2(store_details.location.line_2);
				set_city(store_details.location.city);
				set_state(store_details.location.state);
				set_zipcode(store_details.location.pin_code);
			}
		}
	}, [store_details]);

	const onclick_submit = async() => {
		try{
			var location = JSON.stringify({city : city , state : state , line_1:address_line1 , line_2:address_line2 , pin_code:zipcode  })
			var updates = JSON.stringify({gst_number})
			let body = {location, updates}
			let resp = await general_fetch({url:"store/update_info", body})
			alert.success('Successfully updated GST information')
			fetch_store_info()
			onclick_handle_close()
		}catch(err){
			console.log(err)
			alert.error('Something went wrong, please try again')
		}
	}

	return(
		<Modal isOpen={open} className="modal-dialog-centered" toggle={onclick_handle_close} size="sm">
			<IModalHeader toggle={onclick_handle_close}>Alert</IModalHeader>
			<IModalBody className='p-4'>
				<div className="mb-3">GST Number and State for your organization have not been updated yet. Please update this at the earliest to get the bills generated with your GST information. To edit this information later, go to Account Settings.</div>
				<b>GST Number</b>
				<Input className='standard_input mb-3' value={gst_number} onChange={(e) => set_gst_number(e.target.value)} placeholder="Enter GST Number" />
				<b>State</b>
				<Input className='standard_input' value={state} onChange={(e) => set_state(e.target.value)} placeholder="Enter State" />
			</IModalBody>
			<IModalFooter>
				<Button className="white_button" onClick={onclick_handle_close}>Close</Button>
				<Button className="blue_button" disabled={gst_number && state ? false : true} onClick={onclick_submit}>Submit</Button>
			</IModalFooter>
		</Modal>
	)
}

export default GSTModal