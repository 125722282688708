export const COLORS = {

  gray1: '#FFFFFF',
  gray2: '#F5F6F8',
  gray3: '#E6E9EE',
  gray4: '#C5C7CF',
  gray5: '#A7A8B3',
  gray6: '#676878',
  gray7: '#323338',
  gray8: '#202126',

  blue1: '#E2EFFD',
  blue2: '#C2D6F3',
  blue3: '#D3EDFD',
  blue4: '#3686F7',
  blue5: '#3A63D4',
  blue6: '#0078FF',

}

function componentToHex(c) {
  if(Number(c) === NaN) return '00';
  
  const hex = Math.round(c * 255).toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

export function rgbToHex({r, g, b}) {
  let val = '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
  return val;
}

export function hexToRgb(hex) {
  // Remove the leading #
  if(typeof(hex) !== "string"){
    return hex
  }
  
  hex = hex.replace('#', '');
  
  // Parse the hex values for red, green, and blue
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  
  // Return the RGB values as an object
  return {
    r: r,
    g: g,
    b: b
  };
  }