import React, { useState, useEffect, useContext } from 'react';
import classnames from "classnames";
import { Button, Col, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { UncontrolledCollapse } from '../../components/ICollpsable';
import { useAlert } from 'react-alert';
import { UserContext } from '../../components/Contexts/UserContext';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import general_fetch from '../../utils/fetch';
import isEqual from 'lodash/isEqual'
import { ConfirmationModal } from '../../components/CommonModals';

const AdvancedDiscountTable = (advanced_discount_values, set_advanced_discount_values) => {
    const alert = useAlert()
    
    const add_row = (variable, set_variable) => {
        if (variable && variable.length) {
            for (let i = 0; i < variable.length; i++) {
                if (!variable[i].name) {
                    alert.error('Name must not be empty')
                    return
                }
            }
        }

        let a = JSON.parse(JSON.stringify(variable))
        a.push({ name: 'Discount' + (variable.length + 1), type: (a && a.length && a[0].type ? a[0].type : 'absolute'), value: 0 })
        set_variable(a)
    }

    const update_advanced_discount_values = (advanced_discount_values, set_advanced_discount_values, idx, key, value) => {
        let a = JSON.parse(JSON.stringify(advanced_discount_values))
        a[idx][key] = value
        set_advanced_discount_values(a)
    }

    const update_discount_type = (advanced_discount_values, set_advanced_discount_values, type, value) => {
        let a = JSON.parse(JSON.stringify(advanced_discount_values))
        a.map(x => x[type] = value)
        set_advanced_discount_values(a)
    }

    const delete_row = (variable, set_variable, idx) => {
        let a = JSON.parse(JSON.stringify(variable))
        a.splice(idx, 1)
        set_variable(a)
    }

    return (
        <table style={{ width: '100%' }}>
            <thead style={{ width: '100%' }}>
                <tr className='tr_style' style={{ width: '100%' }}>
                    <th className='th_style flex_property' style={{ flex: 2 }}><div>Discount Name</div></th>
                    <th className='th_style flex_property' style={{ flex: 2 }}><div>Discount Type</div></th>
                    <th className='th_style flex_property' style={{ flex: 3 }}><div>Discount Value</div></th>
                    <th className='th_style flex_property' style={{ flex: 1 }}><div></div></th>
                </tr>
            </thead>
            <tbody style={{ width: '100%' }}>
                {
                    advanced_discount_values && advanced_discount_values.length ? advanced_discount_values.map((single_item, idx) => (
                        <tr className='tr_style display_onhover' key={idx} style={{ width: '100%' }}>
                            <td className='td_style flex_center' style={{ flex: 2 }}>
                                <Input className='standard_input' style={{ fontSize: '12px', border: 0 }} type='text' value={single_item.name} onChange={(e) => update_advanced_discount_values(advanced_discount_values, set_advanced_discount_values, idx, 'name', e.target.value)} />
                            </td>
                            <td className='td_style flex_center' style={{ flex: 2 }}>
                                {
                                    idx == 0 ?
                                        <Input className='standard_input' style={{ fontSize: '12px', border: 0 }} type='select' value={single_item.type} onChange={(e) => update_discount_type(advanced_discount_values, set_advanced_discount_values, 'type', e.target.value)}>
                                            <option value={'absolute'}>Absolute</option>
                                            <option value={'percentage'}>Percentage (%)</option>
                                        </Input>
                                        :
                                        <Input className='standard_input' style={{ fontSize: '12px', border: 0 }} type='text' value={single_item.type == 'percentage' ? 'Percentage (%)' : 'Absolute'} readOnly={idx != 0}></Input>
                                }
                            </td>
                            <td className='td_style flex_center' style={{ flex: 3 }}>
                                <Input type='number' className='standard_input' style={{ fontSize: '12px', border: 0 }} value={String(single_item.value)} onChange={(e) => (Number(e.target.value) < 0 || (single_item.type === 'percentage' && Number(e.target.value) > 100)) ? alert.error('Please enter a value from 0 to 100') : update_advanced_discount_values(advanced_discount_values, set_advanced_discount_values, idx, 'value', Number(e.target.value))} />
                            </td>
                            <td className='td_style flex_center x1' style={{ flex: 1 }}>
                                <i className='fa fa-trash invisible1 cp' style={{ fontSize: '14px', color: 'red' }} onClick={() => delete_row(advanced_discount_values, set_advanced_discount_values, idx)} />
                            </td>
                        </tr>
                    )) : ''
                }
                <div onClick={() => add_row(advanced_discount_values, set_advanced_discount_values)} className='flex_property m-3' style={{ color: '#2688F7', fontWeight: '700', fontSize: '12px', cursor: 'pointer' }}>
                    + Add Row
                </div>
            </tbody>
        </table>
    )
}

const Discounts = ({ store_details, get_store_details, set_page_loader }) => {
    const [tabs, set_tabs] = useState(0);
    const [discount_type, set_discount_type] = useState('percentage');
    const [discount_value, set_discount_value] = useState(0);
    const [pricing_quoatation_template, set_pricing_quoatation_template] = useState('default');
    const [advanced_discount_wood_works, set_advanced_discount_wood_works] = useState([]);
    const [advanced_discount_others, set_advanced_discount_others] = useState([]);
    const [is_confirmation_modal_open, set_is_confirmation_modal_open] = useState(false);
    const toggle_confirmation_modal = () => set_is_confirmation_modal_open(!is_confirmation_modal_open)
    const [proceed_to_quoatation_template, set_proceed_to_quoatation_template] = useState('default');

    const user = useContext(UserContext)
    const org_bu_view = useContext(OrgBuContext)
    const alert = useAlert()

    const toggleNavs = (e, index) => {
        e.preventDefault();
        set_tabs(index)
    };

    const isDefaultDiscountChanged = () => {
        if(store_details && store_details.discounting_details && store_details.discounting_details.default){
            return store_details.discounting_details.default.discount_type != discount_type || store_details.discounting_details.default.discount_value != discount_value
        }

        return discount_type != 'percentage' || discount_value != 0
    }

    const isAdvancedDiscountChanged = () => {
        if(store_details && store_details.discounting_details && store_details.discounting_details.woodwork){
            return !isEqual(store_details.discounting_details.woodwork.wood_works ? store_details.discounting_details.woodwork.wood_works : [],  advanced_discount_wood_works) || !isEqual(store_details.discounting_details.woodwork.others ? store_details.discounting_details.woodwork.others : [], advanced_discount_others)
        }
        // return !isEqual([],  advanced_discount_wood_works) || !isEqual([] != advanced_discount_others)
        return false

    }
    // Change Pricing Quoatation Template
    const changePQT = (e) => {

        let current_pricing_quoatation_template = pricing_quoatation_template
        if(current_pricing_quoatation_template === 'default'){
            if(isDefaultDiscountChanged()){
                toggle_confirmation_modal()
                set_proceed_to_quoatation_template(e.target.value)
            }else{
                set_pricing_quoatation_template(e.target.value)
            }
        }else if(current_pricing_quoatation_template === 'woodwork'){
            if(isAdvancedDiscountChanged()){
                toggle_confirmation_modal()
                set_proceed_to_quoatation_template(e.target.value)
            }else{
                set_pricing_quoatation_template(e.target.value)
            }
        }
    }

    const saveCurrentProgressAndProceed = async() => {
        await updateDiscount();
        set_pricing_quoatation_template(proceed_to_quoatation_template)
        toggle_confirmation_modal()
    }

    const discardCurrentProgressAndProceed = () => {
        setDiscountValuesFromStore()
        set_pricing_quoatation_template(proceed_to_quoatation_template)
        toggle_confirmation_modal()
    }

    const setDiscountValuesFromStore = () => {
        if(store_details && store_details.id){
            if(store_details.discounting_details){
                if(store_details.discounting_details.default){
                    if(store_details.discounting_details.default.discount_type){
                        set_discount_type(store_details.discounting_details.default.discount_type)
                    }else{
                        set_discount_type('percentage')
                    }

                    if(store_details.discounting_details.default.discount_value){
                        set_discount_value(store_details.discounting_details.default.discount_value)
                    }else{
                        set_discount_value(0)
                    }
                }

                if(store_details.discounting_details.woodwork){
                    if(store_details.discounting_details.woodwork.wood_works){
                        set_advanced_discount_wood_works(JSON.parse(JSON.stringify(store_details.discounting_details.woodwork.wood_works)))
                    }else{
                        set_advanced_discount_wood_works([])
                    }

                    if(store_details.discounting_details.woodwork.others){
                        set_advanced_discount_others(JSON.parse(JSON.stringify(store_details.discounting_details.woodwork.others)))
                    }else{
                        set_advanced_discount_others([])
                    }
                }
            }
        }
    }

    const updateDiscount = async() => {
        try {
            let discounting_details = {
                default: {
                    discount_type,
                    discount_value
                },
                woodwork: {
                    wood_works: advanced_discount_wood_works,
                    others: advanced_discount_others
                }
            }
            var updates = JSON.stringify({ discounting_details })
            let body = { updates: updates }

            if (org_bu_view == 'bu') {
                body.business_unit_id = user.current_business_unit_id
                let resp = await general_fetch({ url: 'business_unit/update_info', body })
            } else {
                let resp = await general_fetch({ url: 'store/update_info', body })
            }

            get_store_details()

            alert.success('Discount details updated successfully')
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        setDiscountValuesFromStore()
    }, [store_details]);

    return (
        <React.Fragment>
            <ConfirmationModal isOpen={is_confirmation_modal_open} content={"You have some unsaved changes. How would you like to proceed?"} buttons_array={[{type: 'secondary', name: "Discard Changes", route: discardCurrentProgressAndProceed},{type: 'primary', name: "Apply Changes", route: saveCurrentProgressAndProceed}]}></ConfirmationModal>
            <div style={{ maxHeight: 'calc( 100% - 80px )', overflow: 'auto', padding: '16px' }}>
                <div className='flex_property mb-3'>
                    <div>Pricing Quotation Template</div>
                    <div className='ml-3' style={{ flex: 1, minWidth: '40px' }}>
                        <Input style={{ fontSize: '12px' }} type='select' value={pricing_quoatation_template} onChange={changePQT}>
                            <option value={'default'}>Default/Roomwise Segregation</option>
                            <option value={'woodwork'}>Show Woodwork Separately</option>
                        </Input>
                    </div>
                </div>
                {
                    <>
                    {/* pricing_quoatation_template === 'default' ? */}
                        <div style={{ display: pricing_quoatation_template === 'default' ? 'block' : 'none' }}>
                            <div className='flex_property mb-3'>
                                <div style={{ fontWeight: 600, minWidth: '160px', textAlign: 'left' }}>Select Discount Type</div>
                                <div className='ml-3' style={{ flex: 1, minWidth: '40px' }}>
                                    <Input style={{ fontSize: '12px' }}
                                        onChange={e => set_discount_type(e.target.value)}
                                        value={discount_type}
                                        type='select'
                                    >
                                        <option value='percentage'>Percentage</option>
                                        {<option value='absolute'>Absolute</option>}
                                    </Input>
                                </div>
                            </div>
                            <div className='flex_property mb-3'>
                                <div style={{ fontWeight: 600, minWidth: '160px', textAlign: 'left' }}>Discount Charge</div>
                                <div className='ml-3' style={{ flex: 1, minWidth: '40px' }}>
                                    <Input style={{ fontSize: '12px' }}
                                        onChange={e => set_discount_value(Number(e.target.value))}
                                        value={String(discount_value)}
                                        type='number'
                                    >
                                    </Input>
                                </div>
                            </div>
                        </div>
                        {/* : */}
                        <div style={{ border: '1px solid rgba(0,0,0,.1)', display: pricing_quoatation_template === 'woodwork' ? 'block' : 'none' }}>
                            <Nav
                                style={{ display: 'flex', alignItems: 'center', fontSize: '12px', backgroundColor: 'white', padding: '2px 0px 2px 0px', position: 'sticky', top: '0', zIndex: '1' }}
                                className="flex-md-row m-1 ml-2"
                                id="tabs-icons-text"
                                pills
                                role="tablist"
                            >
                                <NavItem>
                                    <NavLink
                                        aria-selected={tabs === 0}
                                        style={{ color: tabs === 0 ? '#1070CA' : '#435A6F', backgroundColor: tabs === 0 ? 'rgba(16, 112, 202, 0.09)' : 'transparent', marginLeft: tabs === 1 ? '' : '' }}
                                        className={classnames("mb-sm-3 mb-md-0", {
                                            active: tabs === 0
                                        })}
                                        onClick={e => toggleNavs(e, 0)}
                                        href="#pablo"
                                        role="tab"
                                    >
                                        <span>For Woodworks</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        aria-selected={tabs === 1}
                                        style={{ color: tabs === 1 ? '#1070CA' : '#435A6F', backgroundColor: tabs === 1 ? 'rgba(16, 112, 202, 0.09)' : 'transparent' }}
                                        className={classnames("mb-sm-3 mb-md-0", {
                                            active: tabs === 1
                                        })}
                                        onClick={e => toggleNavs(e, 1)}
                                        href="#pablo"
                                        role="tab"
                                    >
                                        <span>For other items</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <hr style={{ margin: 0 }} />
                            <TabContent activeTab={"tabs" + tabs}>
                                <TabPane tabId={'tabs0'} style={{ maxHeight: '300px', overflow: 'auto' }}>
                                    {AdvancedDiscountTable(advanced_discount_wood_works, set_advanced_discount_wood_works)}
                                </TabPane>
                                <TabPane tabId={'tabs1'} style={{ maxHeight: '300px', overflow: 'auto' }}>
                                    {AdvancedDiscountTable(advanced_discount_others, set_advanced_discount_others)}
                                </TabPane>
                            </TabContent>
                        </div>
                        </>
                }


            </div>
            <div className='flex_property mr-4' style={{ height: '80px', justifyContent: 'end' }}>
                <Button className='blue_button' onClick={updateDiscount}>Update</Button>
            </div>
        </React.Fragment>
    );
}

export default Discounts;