import React, { useState, useEffect, useMemo, useContext } from 'react';
import Sidebar from '../../components/Sidebar/';
import Header from '../../components/Header/';
import useStateCallback from '../../utils/useStateCallback.js';
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import { UserContext } from '../Contexts/UserContext';

const base_style = {
	height : '100vh',
	display : 'inline-block', 
	position: 'fixed',
	top: 0,
	left: 0,
	overflow: 'auto'
}
const home_style = {
	width: '100vw',
	height: '100vh',
	backgroundColor: 'white',
}

const Main_Grid = ({ active_tab, children, active_division_id ,store_details, get_store_details}) => {

	const user = useContext(UserContext)
	const [page_loader,set_page_loader] = useStateCallback({
        show: false,
        text: "Please wait ..."
    });

	const [trial_days_left, set_trial_days_left] = useState(6);

	const show_warning_header = useMemo(() => {
		if(store_details && ((!store_details.premium_customer && trial_days_left <= 5) || store_details.suspended) && window.location.pathname != '/billing/usage/'){
			return true
		}else{
			return false
		}
	}, [store_details, trial_days_left])

	useEffect(() => {
		if(store_details && store_details.last_trial_date){
			const targetDate = new Date(store_details.last_trial_date);
			const today = new Date();
			const timeDiff = targetDate.getTime() - today.getTime();
			const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
			set_trial_days_left(daysLeft);
		}
	}, [store_details]);
	
	return (
		<React.Fragment>
			{page_loader && page_loader.show?(
                  <div style={{position:'fixed',backgroundColor:'rgba(20,20,20,0.7',left:0,top:0,width:'100%',height:'100%',zIndex:10000}}>
                      <div style={{position:'absolute',width:'350px',height:'40px',left:'calc(50% - 175px)',top:'calc(50% - 20px)',backgroundColor:'#314456',color:'#ffffff',display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}}>
                          <i className = "fa fa-spinner fa-spin"></i> &nbsp; &nbsp; <span>{page_loader.text}</span>
                      </div>
                  </div>
              ):''}

	        <div style={{backgroundColor:'#F2F4F6', position:'absolute', top: false && show_warning_header ? '96px' : '64px', left:'256px', width:'calc(100% - 256px)', height: false && show_warning_header ? 'calc(100% - 96px)' : 'calc(100% - 64px)', padding:'0px 20px 15px' }}>
		        {children}
	        </div>
			<div style={{position:'absolute', top:'0px', left:'0px', width:'100%', height:'64px', boxShadow: '0 1px 4px 0 rgba(0,21,41,0.12)'}}>
				<Header user={user} store_details={store_details} get_store_details={get_store_details}/>
			</div>
			{
				false && show_warning_header ?
				<div className='flex_center' style={{position:'absolute', top:'64px', left:'256px', fontSize:'14px', width:'calc(100% - 256px)', height:'32px', backgroundColor: store_details.suspended ? '#F9DADE' : '#F5F6F8'}}>
				{
					store_details.suspended ? (
						store_details.premium_customer ? 
						<>
							<div style={{color:'#FF5247', fontWeight:'900'}}>Your account has been suspended.{'\xa0\xa0'}</div>
							<a href={global.config.admin_url + 'billing/usage'} style={{color:'black', fontWeight:'900'}}>Click here to pay your dues</a>
						</>
						:
						<>
							<div style={{color:'#FF5247', fontWeight:'900'}}>Your free trial has expired, you can only view items.{'\xa0\xa0'}</div>	
							<a href={global.config.admin_url + 'billing/usage'} style={{color:'black', fontWeight:'900'}}>Click here to Upgrade</a>
						</>
					):(
						!store_details.premium_customer  && trial_days_left <= 5 ? 
						<>
							<div style={{color:'#676878'}}>Hurry! Your free trial is expiring soon.{'\xa0\xa0'}</div>
							<a href={global.config.admin_url + 'billing/usage'} style={{color:'#0078FF'}}>Click here to Upgrade</a>
						</>:""
					)
				}
				</div>:""
			}
			{
				false && store_details && !store_details.premium_customer && window.location.pathname === '/billing/usage/'?
				<div className='flex_center' style={{position:'absolute', top:'64px', left:'256px', fontSize:'14px', width:'calc(100% - 256px)', height:'32px', backgroundColor:'#CCF4E3'}}>
					<div className='flex_center' style={{color:'#19894C'}}>&#128513;{'\xa0\xa0'}Hurray.. Choose a plan to continue with Infurnia</div>
				</div>:''
			}
		</React.Fragment>
	);
}

export default Main_Grid;

