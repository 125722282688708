import React , { useState, useEffect, useContext } from 'react';
import Moment from 'react-moment';
import general_fetch from '../../utils/fetch';
import FeatureFlagModal from './feature_flag_modal';
import { Card, Col, Progress, Table, Row, CardHeader, CardBody, CardFooter, Modal, Button, Input } from "reactstrap";
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import Main_Grid from '../../components/Main_Grid/';
import name_sort from '../../utils/name_sort';
import date_sort from '../../utils/date_sort';
import desc_name_sort from '../../utils/desc_name_sort';
import desc_date_sort from '../../utils/desc_date_sort';
import FilterModal from './FilterModal';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { UserContext } from '../../components/Contexts/UserContext';
// import './index.css'
import remove_underscore_wtih_first_cap from '../../utils/remove_underscore_wtih_first_cap';
import { useAlert } from 'react-alert';
import generic_sort from '../../utils/generic_sort';
import './style.css'
import { Checkbox } from 'antd';

const BulkActionModal = ({open, handleClose, selected_feature_flags_json, display_feature_flags, fetch_feature_flags, no_of_selected_flags}) => {

	const [ feature_flag_store_status , set_feature_flag_store_status ] = useState('enabled')
	const [feature_flag_ids, set_feature_flag_ids] = useState([]);
	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)
	const alert = useAlert()

	const onclick_submit = async () => {
		if (feature_flag_ids && feature_flag_ids.length) {
			try {
				let body = { flagged_feature_ids: feature_flag_ids, type: feature_flag_store_status };
				if(org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
				if (feature_flag_store_status == 'default') {
					await general_fetch({ url: 'flagged_feature/restore_feature_defaults', body });
				} else {
					await general_fetch({ url: 'flagged_feature/update_feature_status', body });
				}
				alert.success('Features availability updated')
				onclick_handleclose()
				fetch_feature_flags();
			} catch(err) { 
				alert.error('Error in updating feature availability')
				console.log(err);
			}
		} else {
			alert.error('Something went wrong - please select feature flags');
		}	
	}

	const onclick_handleclose = () => {
		set_feature_flag_store_status('enabled');
		set_feature_flag_ids([])
		handleClose()
	}

	useEffect(() => {
			if(open){
				let selected_ids = []
				let t = selected_feature_flags_json && Object.keys(selected_feature_flags_json) && Object.keys(selected_feature_flags_json).length ? Object.keys(selected_feature_flags_json).map(x => {if(selected_feature_flags_json[x]){selected_ids.push(x)}}):''
				set_feature_flag_ids(selected_ids)
				console.log(selected_ids, selected_feature_flags_json)
			}
	}, [selected_feature_flags_json, open]);

	useEffect(() => {
		console.log('feature_flag_ids', feature_flag_ids)
	}, [feature_flag_ids]);

	return(
		<Modal className='Margin_64px_top' size ='xs' isOpen={open} toggle={onclick_handleclose}>
			<CardHeader className='global_modal_header'>
				<span>Change Selected Features Availability</span>
				<i style={{cursor:'pointer'}} className='fa fa-times' onClick={onclick_handleclose} />
			</CardHeader>
			<CardBody style={{maxHeight:'200px', display:'flex', justifyContent:'center', flexDirection:'column'}}>
				<div style={{marginBottom:'10px'}}>Number of feature flags selected : {no_of_selected_flags} </div>
				<div style={{marginBottom:'4px'}}>Choose Status</div>
				<Input type='select' style={{fontSize:'14px', height:'32px'}} onChange={e => set_feature_flag_store_status(e.target.value)}>
					<option value='enabled'>Enabled</option>
					<option value='disabled'>Disabled</option>
					<option value='disabled_for_designers'>Disabled For Designers</option>
					<option value='default'>Default</option>
				</Input>
			</CardBody>
			<CardFooter className='global_modal_footer'>
				<Button className='blue_button' onClick={onclick_submit}>Submit</Button>
			</CardFooter>
		</Modal>
	)
}

const FeatureFlags = ({set_active_tab, send_analytics_event, check_route_fn,store_details, get_store_details, set_active_division_id_sidebar}) => {
    const [ initialized, set_initialized ] = useState(false);
	const [ fetch_progress, set_fetch_progress ] = useState("40");
	
	const [ feature_flags, set_feature_flags ] = useState([]);
	const [ display_feature_flags, set_display_feature_flags ] = useState([]);
	const [sorted_feature_flags, set_sorted_feature_flags] = useState([]);

	const [all_feature_categories, set_all_feature_categories] = useState([]);

	const [ feature_flag_modal_open, set_feature_flag_modal_open ] = useState(false);
	const [ active_feature_flag, set_active_feature_flag ] = useState({});
	
	const [ start_index , set_start_index ] = useState('');
	const [ end_index , set_end_index ] = useState('');

	const [search_string , set_search_string] = useState('');
	const [show_filter_modal , set_show_filter_modal] = useState('');
	const [filter_name , set_filter_name] = useState('');
	const [filter_category , set_filter_category] = useState([]);
	const [current_filter_status , set_current_filter_status] = useState('');
	const [default_filter_status , set_default_filter_status] = useState('');
	const [applied_filter_category, set_applied_filter_category] = useState({});
	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)
	const [selected_feature_flags_json, set_selected_feature_flags_json] = useState({});
	// const [selected_all_display_feature_flags, set_selected_all_display_feature_flags] = useState(false);
	// const [selected_all_visible_feature_flags, set_selected_all_visible_feature_flags] = useState(false);
	// const [enable_bulk_action, set_enable_bulk_action] = useState(false)
	const [no_of_selected_flags, set_no_of_selected_flags] = useState(0);
	const [show_bulk_action_modal, set_show_bulk_action_modal] = useState();
	const alert = useAlert()

	const fetch_feature_flags = async () => {
		try {
			// var loader_timer = setInterval(() => {
			// 	var x = Number(fetch_progress);
			// 	console.log('xxx ---> ', x,x + Math.floor((100 - x)/2))
			// 	set_fetch_progress(String(x + Math.floor((100 - x)/2)))  
			// }, 200);
			let body = {}
			if(org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
			var resp = await general_fetch({ url: 'flagged_feature/get_all', body});
			set_initialized(true);
			resp = resp.sort((a, b) => (a.feature_category > b.feature_category ? 1 : -1));
			for(let i in resp){
				if(resp[i]["feature_category"] && resp[i]["type"] !== 'infurnia_controlled'){
					resp[i]["feature_category"] = resp[i]["feature_category"].split(', ')
				}else{
					resp[i]["feature_category"] = []
				}
			}
			set_feature_flags(resp);
			set_sorted_feature_flags(resp);
			onclick_apply_filter(resp)
			// set_display_feature_flags(resp);
			// clearInterval(loader_timer);
		} catch(err) {
			console.log("flagged features 11---> ", err)
			console.log(err);
		}
	}


	const show_feature_flag_modal = (feature_flag) => {
		set_feature_flag_modal_open(true);
		set_active_feature_flag(feature_flag);
	}

	const close_feature_flag_modal = () => {
		set_feature_flag_modal_open(false);
		set_active_feature_flag({});
	}

	const open_filter_modal = () => {
		set_show_filter_modal(true);
	}

	const close_filter_modal = () => {
		set_show_filter_modal(false)
	}

	// useEffect(() => {
	// 	debugger
	// 	var array;
	// 	for(var i=start_index; i<=end_index ; i++){
	// 		array=display_projects[i];
	// 	}
	// 	set_display_projects(array)	
	// }, [start_index, end_index]);

	// useEffect(() => {
	// 	filter_projects();	
	// }, [search_string]);

	// const filter_feature_flag = () => {
	// 	var filtered_brands = [...feature_flags];
	// 	var lower_search_string = search_string ? search_string.toLowerCase():'';
	// 	if(lower_search_string){
	// 		filtered_brands = filtered_brands.filter( brand => brand.name.toLowerCase().includes(lower_search_string) || brand.description.toLowerCase().includes(lower_search_string) || brand.feature_category.toLowerCase().includes(lower_search_string))
	// 	}
	// 	if(filter_name){
	// 		filtered_brands = filtered_brands.filter( brand => brand.name.toLowerCase().includes(filter_name))
	// 	}
	// 	set_display_feature_flags(filtered_brands)
	// }

	const onclick_apply_filter = (values) => {

		var filtered_brands;
		if(values){
			filtered_brands = [...values]
		}else{
			filtered_brands = [...sorted_feature_flags]
		}

		var lower_search_string = search_string ? search_string.toLowerCase():'';
		if(lower_search_string){
			filtered_brands = filtered_brands.filter( brand => brand.name.toLowerCase().includes(lower_search_string) || brand.description.toLowerCase().includes(lower_search_string))
			// filtered_brands = filtered_brands.filter( brand => brand.name.toLowerCase().includes(lower_search_string) || brand.description.toLowerCase().includes(lower_search_string) || brand.feature_category.toLowerCase().includes(lower_search_string))
		}
		if(applied_filter_category && Object.keys(applied_filter_category).filter(x=>applied_filter_category[x]).length){
			var filter_category_ids = Object.keys(applied_filter_category).filter(x=>applied_filter_category[x])
			filtered_brands = filtered_brands.filter( brand => {
				for (let i in brand["feature_category"]) {
					if (filter_category_ids.includes(brand["feature_category"][i])) return true;
				}
				return false
			})
		}
		if(current_filter_status){
			filtered_brands = filtered_brands.filter( brand => (brand.store_status===current_filter_status))
			// filtered_brands = filtered_brands.filter( brand => ((org_bu_view==='bu' && brand.business_unit_status===current_filter_status) || (org_bu_view==='org' && brand.store_status===current_filter_status)))
		}
		if(default_filter_status){
			filtered_brands = filtered_brands.filter( brand => brand.default_status===default_filter_status)
		}
		set_display_feature_flags(filtered_brands.filter(feature_flag => feature_flag["type"] !== 'infurnia_controlled'))
	}

	const apply_filters_from_filter_modal = (resetOption = false) => {
		let temp_applied_filter_category = {}
		var filter_category_val = resetOption?[]:filter_category
		for(let i in filter_category_val){
			temp_applied_filter_category[filter_category_val[i].id] = true
		}
		set_applied_filter_category(temp_applied_filter_category)
	}

	const onclick_sorting_arrows = (button_name, type) => {
		var test;
		var x_display_list = JSON.parse(JSON.stringify(display_feature_flags))
		var x = x_display_list.sort((a,b) => generic_sort(a,b,button_name,type==='asc'?1:-1))
		// if(button_name==='owner' && type==='asc'){
		// 	var x = x_display_list.sort(name_sort)
		// }else if(button_name==='owner' && type==='desc'){
		// 	var x = x_display_list.sort(desc_name_sort)
		// }else if(button_name==='updated_at' && type==='asc'){
		// 	var x = x_display_list.sort(date_sort)
		// }else if(button_name==='updated_at' && type==='desc'){
		// 	var x = x_display_list.sort(desc_date_sort)	
		// }
		set_sorted_feature_flags(x)
		set_display_feature_flags(x.filter(feature_flag => feature_flag["type"] !== 'infurnia_controlled'));
		// var projects_csv_list = x && x.length ? x.map(y => {return {Name: y.name, Order: y.order_id, Status: y.project_status, Owner: y.designer.name, Last_Updated: y.updated_at}}):[];
		// set_projects_csv(projects_csv_list);
	}

	const update_selected_feature_flags_json = (id, value) => {
		let a = JSON.parse(JSON.stringify(selected_feature_flags_json))
		a[id] = value
		set_selected_feature_flags_json(a)

		// let temp = 1
		// if(display_feature_flags && display_feature_flags.length){
		// 	for(let i=start_index-1;i<end_index;i++){
		// 		let single_feature_flag_id = display_feature_flags[i].id
		// 		if(!a[single_feature_flag_id]){
		// 			temp = 0;
		// 			break;
		// 		}
		// 	}
		// }
		// if(temp){
		// 	set_selected_all_visible_feature_flags(true)
		// }
	}

	const check_all_display_feature_flags = (value) => {
		let a = JSON.parse(JSON.stringify(selected_feature_flags_json))
		// a = {}
		let count = 0
		if(display_feature_flags && display_feature_flags.length){
			// display_feature_flags.forEach(x => {
			// 	a[x.id] = value
			// })
			for(let i = start_index-1; i < end_index; i++){
				let x = display_feature_flags[i]
				if(value && !a[x.id]){
					a[x.id] = value
					count++
				}
				if(!value && a[x.id]){
					a[x.id] = value
					count++
				}
			}
		}
		set_selected_feature_flags_json(a)
		
		if(value){
			set_no_of_selected_flags(no_of_selected_flags+count)
		}else{
			set_no_of_selected_flags(no_of_selected_flags-count)
		}
	}

	const check_single_flag = (id, value) => {
		update_selected_feature_flags_json(id, value)
		// if(!value){
		// 	set_selected_all_display_feature_flags(false)
		// }else{
			// let temp1 = 1, temp2 = 1
			// if(display_feature_flags && display_feature_flags.length){
			// 	for(let i=start_index-1;i<end_index;i++){
			// 		let single_feature_flag_id = display_feature_flags[i].id
			// 		if(single_feature_flag_id != id && !selected_feature_flags_json[single_feature_flag_id]){
			// 			temp1 = 0;
			// 		}else if(single_feature_flag_id != id && selected_feature_flags_json[single_feature_flag_id]){
			// 			temp2 = 0
			// 		}
			// 	}
			// }
			
		// }
		if(value){
			// if(temp1){
			// 	// set_selected_all_visible_feature_flags(true)
			// 	document.getElementById("selected_all_visible_feature_flags").indeterminate = false;
			// 	document.getElementById("selected_all_visible_feature_flags").checked = true;
			// }else{
			// 	console.log("entered here for loop 3333334", value)
			// 	document.getElementById("selected_all_visible_feature_flags").checked = false;
			// 	document.getElementById("selected_all_visible_feature_flags").indeterminate = true;
			// }
			set_no_of_selected_flags(no_of_selected_flags+1)
		}else{
			// if(temp2){
			// 	document.getElementById("selected_all_visible_feature_flags").indeterminate = false;
			// 	document.getElementById("selected_all_visible_feature_flags").checked = false;
			// }else{
			// 	console.log("entered here for loop 3333335", value)
			// 	document.getElementById("selected_all_visible_feature_flags").checked = false;
			// 	document.getElementById("selected_all_visible_feature_flags").indeterminate = true;
			// }
			set_no_of_selected_flags(no_of_selected_flags-1)
		}
	}

	const recalculate_selected_all_visible_feature_flags = () => {
		if(display_feature_flags && display_feature_flags.length > end_index - start_index && start_index > 0){
			let temp1 = 1, temp2 = 1
			for(let i=start_index-1; i<end_index; i++){
				console.log('start index 1', start_index, end_index, selected_feature_flags_json[display_feature_flags[i].id])
				let x = display_feature_flags[i]
				if(!selected_feature_flags_json[x.id]){
					temp1 = 0 
				}else{
					temp2 = 0
				}
			}
			if(temp1 && !temp2 ){
				document.getElementById("selected_all_visible_feature_flags").indeterminate = false
				document.getElementById("selected_all_visible_feature_flags").checked = true
				// set_selected_all_visible_feature_flags(true)
				console.log('for loop 1')
			}else if(temp2 && !temp1 ){
				document.getElementById("selected_all_visible_feature_flags").indeterminate = false
				document.getElementById("selected_all_visible_feature_flags").checked = false
				console.log('for loop 2')
				// set_selected_all_visible_feature_flags(false)
			}else{
				document.getElementById("selected_all_visible_feature_flags").checked = false
				document.getElementById("selected_all_visible_feature_flags").indeterminate = true;
				// set_selected_all_visible_feature_flags(false)
				console.log('for loop 3333334')
			}
		}
	}

	const select_all_display_feature_flags = () => {
		let a = JSON.parse(JSON.stringify(selected_feature_flags_json))
		if(display_feature_flags && display_feature_flags.length){
			display_feature_flags.map(x => a[x.id] = true)
		}
		set_selected_feature_flags_json(a)
		set_no_of_selected_flags(display_feature_flags ? display_feature_flags.length : 0)
	}

	const clear_selection = () => {
		set_no_of_selected_flags(0)
		// set_selected_all_visible_feature_flags(false)
		if(document.getElementById("selected_all_visible_feature_flags")){
			document.getElementById("selected_all_visible_feature_flags").indeterminate = false
			document.getElementById("selected_all_visible_feature_flags").checked = false
		}
		
		set_selected_feature_flags_json({})
	}

	const isIndeterminateFeatureCategories = () => {
		let selected_filter_length = 0;
		if(applied_filter_category){
			selected_filter_length = Object.keys(applied_filter_category).filter(x=>applied_filter_category[x]).length
		}
		let total_length = all_feature_categories && all_feature_categories.length

		return selected_filter_length > 0 && selected_filter_length < total_length
	}

	const isCheckedFeatureCategories = () => {
		let selected_filter_length = 0;
		if(applied_filter_category){
			selected_filter_length = Object.keys(applied_filter_category).filter(x=>applied_filter_category[x]).length
		}
		let total_length = all_feature_categories && all_feature_categories.length

		return selected_filter_length > 0 && selected_filter_length === total_length
	}

	const onChangeCheckAllCategories = (e) => {
		let temp_applied_filter_category = {}
		if(e.target.checked){
			for(let i in all_feature_categories){
				temp_applied_filter_category[all_feature_categories[i]] = true
			}
		}

		set_applied_filter_category(temp_applied_filter_category)
	}

	useEffect(() => {
		onclick_apply_filter();
	}, [search_string, filter_name, feature_flags]);

	useEffect(() => {
		if(feature_flags && feature_flags.length){
			const _all_feature_categories = new Set();
			for(let i in feature_flags){
				let unique_feature_categories = feature_flags[i]["feature_category"]
				for(let j in unique_feature_categories){
					_all_feature_categories.add(unique_feature_categories[j])
				}
			}
			set_all_feature_categories(Array.from(_all_feature_categories))
		}
	}, [feature_flags]);

	useEffect(() => {
		if (!initialized) {
			fetch_feature_flags();
		}
		set_active_tab('preferences_settings')
		set_active_division_id_sidebar('feature_flags')
		send_analytics_event('auto', 'Feature Flags', 'Feature Flags', 1, window.location.href)
		// check_route_fn('feature_flags')
	}, []);

	useEffect(() => {
		if(selected_feature_flags_json) recalculate_selected_all_visible_feature_flags()
	}, [selected_feature_flags_json]);


	useEffect(() => {
		if (active_feature_flag && active_feature_flag.id) {
			set_active_feature_flag(feature_flags.filter( x => x.id == active_feature_flag.id )[0])
		}
	}, [ feature_flags ]); 

	useEffect(() => {
		if(no_of_selected_flags){
			clear_selection()
			alert.info('You previous selection has been cleared !')
		}
		// console.log("fefl", display_feature_flags)
	}, [display_feature_flags]);

	useEffect(() => {
		// console.log('start index', start_index, end_index)
		if(end_index > 0){
			recalculate_selected_all_visible_feature_flags()
		}
	}, [start_index, end_index]);

	useEffect(() => {
		if(applied_filter_category && Object.keys(applied_filter_category)){
			set_filter_category(Object.keys(applied_filter_category).filter(x => applied_filter_category[x]).map(x => ({id: x, name: x})))
		}
		onclick_apply_filter()
	}, [applied_filter_category]);

	const Feature_Flag_Table = () => (
		<table style={{height:'100%',width:'100%'}} className="text-left flex_property table_style">
			<thead style={{width:'100%'}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',width:'100%'}}>
				<th className='th_style flex_property cp' style={{flex:'1'}} onClick={() => document.getElementById('selected_all_visible_feature_flags').click()}><input type='checkbox' checked={document.getElementById('selected_all_visible_feature_flags') ? document.getElementById('selected_all_visible_feature_flags').checked : false} id='selected_all_visible_feature_flags' onClick={(e) => {e.stopPropagation() ;let t = document.getElementById('selected_all_visible_feature_flags');console.log('selected_all_visible_feature_flags', document.getElementById('selected_all_visible_feature_flags').checked); document.getElementById('selected_all_visible_feature_flags').indeterminate = false; check_all_display_feature_flags(t.checked)}} style={{cursor:'pointer'}}/></th>
				<th className='th_style flex_property' style={{flex:'8'}}><span style={{paddingRight:'8px'}}>Name</span>
					<span style={{display:'flex', flexDirection:'column', fontSize:'14px'}}>
						<i title='Latest date on top' onClick={() => onclick_sorting_arrows('name', 'asc')} style={{height:'10px'}} className='fa fa-caret-up arrow_onhover_3'/>
						<i title='Latest date on bottom' onClick={() => onclick_sorting_arrows('name', 'desc')} className='fa fa-caret-down arrow_onhover_4'/>
					</span>
				</th>
				<th className='th_style flex_property' style={{flex:'12'}}><span style={{paddingRight:'8px'}}>Description</span>
					<span style={{display:'flex', flexDirection:'column', fontSize:'14px'}}>
						<i title='Latest date on top' onClick={() => onclick_sorting_arrows('description', 'asc')} style={{height:'10px'}} className='fa fa-caret-up arrow_onhover_3'/>
						<i title='Latest date on bottom' onClick={() => onclick_sorting_arrows('description', 'desc')} className='fa fa-caret-down arrow_onhover_4'/>
					</span>
				</th>
				<th className='th_style flex_property' style={{flex:'6'}}><span style={{paddingRight:'8px'}}>Feature Category</span>
					<span style={{display:'flex', flexDirection:'column', fontSize:'14px'}}>
						<i title='Latest date on top' onClick={() => onclick_sorting_arrows('feature_category', 'asc')} style={{height:'10px'}} className='fa fa-caret-up arrow_onhover_3'/>
						<i title='Latest date on bottom' onClick={() => onclick_sorting_arrows('feature_category', 'desc')} className='fa fa-caret-down arrow_onhover_4'/>
					</span>
				</th>
				{/* <th className='th_style flex_property' style={{flex:'6'}}><span>Default Status</span></th> */}
				<th className='th_style flex_property' style={{flex:'6'}}><span style={{paddingRight:'8px'}}>Current Status</span>
					<span style={{display:'flex', flexDirection:'column', fontSize:'14px'}}>
						<i title='Latest date on top' onClick={() => onclick_sorting_arrows('store_status', 'asc')} style={{height:'10px'}} className='fa fa-caret-up arrow_onhover_3'/>
						<i title='Latest date on bottom' onClick={() => onclick_sorting_arrows('store_status', 'desc')} className='fa fa-caret-down arrow_onhover_4'/>
					</span>
				</th>
				<th className='th_style flex_property' style={{flex:'2', paddingRight:'20px'}}><span></span></th>
			</tr>
			</thead>
			<tbody style={{display:'block', height:'calc(100% - 40px)', overflow:'auto', width:'100%'}}>
				{
					display_feature_flags && display_feature_flags.length && display_feature_flags.map((feature_flag, index) => (
						<tr key={index} style={{display:(index+1>=start_index && index<end_index)?'flex':'none'}} className='tr_style display_onhover'>
							<td className='td_style_no_scroll flex_property cp' style={{flex:'1'}} onClick={() => check_single_flag(feature_flag.id,!selected_feature_flags_json[feature_flag.id])}><input type='checkbox' checked={selected_feature_flags_json[feature_flag.id]} style={{cursor:'pointer'}}/></td>
							<td className='td_style_no_scroll flex_property' style={{flex:'8'}}><span className='table_text_overflow'>{feature_flag.name?feature_flag.name:'N/A'}</span></td>
							<td className='td_style_no_scroll flex_property' style={{flex:'12'}}><span>{feature_flag.description?feature_flag.description:'N/A'}</span></td>
							<td className='td_style_no_scroll flex_property' style={{flex:'6'}}><span className='table_text_overflow'>{feature_flag.feature_category?feature_flag.feature_category.join(", "):'N/A'}</span></td>
							{/* <td className='td_style flex_property' style={{flex:'6'}}>{feature_flag.default_status ? feature_flag.default_status :'N/A'}</td> */}
							<td className='td_style_no_scroll flex_property' style={{flex:'6', textTransform: 'capitalize'}}>{org_bu_view==='bu' ? (feature_flag.business_unit_status ?  feature_flag.business_unit_status === "default" ? `${feature_flag.business_unit_status.replaceAll('_', " ")} ( ${feature_flag.default_status.replaceAll("_", " ")} )` : feature_flag.business_unit_status.replaceAll('_', " ") : 'N/A') : (feature_flag.store_status ? feature_flag.store_status === "default" ? `${feature_flag.store_status.replaceAll('_', " ")} ( ${feature_flag.default_status.replaceAll("_", " ")} )` : feature_flag.store_status.replaceAll('_', " ") : 'N/A')}</td>
							<td className='td_style_no_scroll flex_property x1' style={{ flex: '2',justifyContent:'flex-end', paddingRight:'20px'}}>
								<span className='hide'><i onClick ={() => show_feature_flag_modal(feature_flag)} className='fa fa-edit'/></span>
							</td>
						</tr>
					))
				}
			</tbody>
		</table>
	);

	const Feature_Flag_Filler = () => (
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
				<tr>
					{ initialized ? (
						<th scope="col"><span ><b>No Feature Flags  </b></span><br/>  Try changing the filters or search criteria. <br /> &nbsp;</th>
					):(
						<th scope="col"><span ><b>Fetching feature flags  </b></span><br/> <br /> Please wait...<br /> <br />
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;</th>
					)}
				</tr>
			</thead>
		</Table>	
    );


	try {
		return (
			<Main_Grid  active_tab="design_hub" user={user} store_details={store_details} get_store_details={get_store_details}>
				<Row style={{padding:'10px 0px', color:'#22272e'}}>
		        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
		        		Viewing {display_feature_flags && display_feature_flags.length ?display_feature_flags.length:'0'} Feature Flags
		        	</Col>
		        	<Col xs={6} style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
						<button disabled = {no_of_selected_flags <= 0} onClick={() => set_show_bulk_action_modal(true)} style={{ height:'32px', border: '1px solid #b8bfca', backgroundColor:'white', borderRadius:'4px', padding:'0px 12px 0px 12px', marginRight:'8px'}}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'14px'}}>Bulk Action</span></button>
						<button onClick={open_filter_modal} style={{ height:'32px', border: '1px solid #b8bfca', backgroundColor:'white', borderRadius:'4px', padding:'0px 16px 0px 12px'}}><span style={{display:'flex', alignItems:'center'}}><i style={{fontSize:'10px', paddingRight:'10px'}} className='fa fa-filter'/><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'14px'}}>Filters</span></span></button>
						<span style={{ zIndex:'1', position:'absolute', right:'15px', top:'38px'}}>
							<FilterModal 
								open = {show_filter_modal} 
								handle_close={close_filter_modal} 
								filter_name={filter_name} 
								set_filter_name={set_filter_name} 
								filter_category={filter_category} 
								set_filter_category={set_filter_category} 
								current_filter_status={current_filter_status} 
								set_current_filter_status={set_current_filter_status} 
								default_filter_status={default_filter_status} 
								set_default_filter_status={set_default_filter_status} 
								all_feature_flag={display_feature_flags}
								apply_onclick={apply_filters_from_filter_modal}
								all_feature_categories={all_feature_categories}	
							/>
						</span>
		        	</Col>
	        	</Row>
		        <Row style={{height:'calc(100% - 90px)'}}>
			        <Col style={{height:'100%'}}>
			        	<Card style={{borderRadius: 0, height:'40px'}}>
							<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}>
								<div className='flex_property' style={{height:"40px", flex:8}}>
									<i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/>
									<input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Name, Description' />
								</div>
							</div>
						</Card>
						{
							no_of_selected_flags > 0?
							<div style={{display:'flex', justifyContent:'space-between', backgroundColor:'#F3F4F6', height:'40px', padding:'0px 20px'}}>
								<div className='flex_property' style={{color:'#A7A8B2', fontSize:'14px'}}>
									<span style={{fontWeight:'600', marginRight:'16px'}}>Selected Items: {'\xa0'+no_of_selected_flags} / {display_feature_flags ? display_feature_flags.length : ''}</span>
									{/* <span style={{marginRight:'16px'}}>{no_of_bulk_selected_skus_in_subcat ? no_of_bulk_selected_skus_in_subcat[sku_sub_category_id] : 0} SKUs Selected / {skus_in_subcat ? skus_in_subcat.length : 0}</span> */}
								</div>
								<div style={{fontSize:'14px'}} className='flex_property'>
									{display_feature_flags && display_feature_flags.length && no_of_selected_flags < display_feature_flags.length ? <div onClick={() => select_all_display_feature_flags()} style={{marginRight:'10px', color:'#007bff', cursor:'pointer'}}>Select all {display_feature_flags && display_feature_flags.length > 1 ? display_feature_flags.length : ''} feature flags</div>:''}
									<div className='underline_onhover' onClick={() => clear_selection()} style={{marginLeft:'8px'}} >Clear Selection</div>
								</div>
							</div>:<div style={{display:'flex', justifyContent:'space-between', backgroundColor:'rgb(242, 244, 246)', height:'40px', padding:'0px 20px'}}></div>
						}
						<Card style={{borderRadius: 0, height:"calc(100% - 80px)", display: 'flex', flexDirection: 'row', background: 'transparent'}}>
							<div className='mr-4' style={{height: '100%', width: '225px', background: 'white', display: 'flex', flexDirection: 'column'}}>
								<div className='feature_category_item' style={{fontWeight: 700, fontSize: '14px'}}>
									<Checkbox className='mr-2' indeterminate={isIndeterminateFeatureCategories()} checked={isCheckedFeatureCategories()} onClick={onChangeCheckAllCategories}></Checkbox>
									Feature Categories
								</div>
								<div style={{flex: 1, minHeight: "40px", overflow: 'auto'}}>
									{
										all_feature_categories && all_feature_categories.length ? all_feature_categories.map((feature_category) => (
											<div className='feature_category_item'>
												<Checkbox checked={applied_filter_category[feature_category]} onClick={() => {applied_filter_category[feature_category] = !applied_filter_category[feature_category]; set_applied_filter_category(JSON.parse(JSON.stringify(applied_filter_category)))}}>
													<div key={feature_category}>{feature_category}</div>
												</Checkbox>
											</div>
										))
										:
										''
									}
								</div>
							</div>
							<div id='design-paginator' style={{height:'100%', flex: 1, minWidth: '200px', background: 'white'}}>
								{/* {console.log('display_feature_flags', display_feature_flags)} */}
								{display_feature_flags && display_feature_flags.length ? (<Feature_Flag_Table />) : (<Feature_Flag_Filler />)}
							</div>
						</Card>
			        </Col>
		        </Row>
		        <Row style={{height:'3px'}}>
			        <Col>
			        </Col>
		        </Row>
		        <Row>
			        <Col>	
						<Pegasus_Paginator 
							id='feature-flag-paginator'
							all_projects={display_feature_flags && display_feature_flags.length ? display_feature_flags.length : '0'}
							end_index={end_index}
							set_end_index={set_end_index}
							start_index={start_index}
							set_start_index={set_start_index}
						/>
			        </Col>
		        </Row>
				<FeatureFlagModal 
					open = {feature_flag_modal_open} 
					handleClose = {close_feature_flag_modal} 
					feature_flag = {active_feature_flag} 
					user = {user} 
					fetch_feature_flags = {fetch_feature_flags}
				/>
				<BulkActionModal 
					open = {show_bulk_action_modal}
					handleClose = {() => set_show_bulk_action_modal(false)}
					selected_feature_flags_json = {selected_feature_flags_json}
					display_feature_flags = {display_feature_flags}
					fetch_feature_flags = {fetch_feature_flags}
					no_of_selected_flags = {no_of_selected_flags}
				/>
			</Main_Grid>
		);
	} catch(err) {
		console.error("Error in feature flags component ---> ", err);
	}

}

export default FeatureFlags;