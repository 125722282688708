import { all_allowed_key as all_allowed_key_default } from "../components/AdditionalPropKeys"

const get_default_additional_property_keys = (sku_category_type, all_allowed_key = all_allowed_key_default) => {
    let valid_default_additional_prop_keys = []
    for(let key in all_allowed_key){
        if(all_allowed_key[key].applicability && all_allowed_key[key].applicability.length){
            if(sku_category_type && all_allowed_key[key].applicability.includes(sku_category_type)){
                valid_default_additional_prop_keys.push(key)
            }
        }else{
            valid_default_additional_prop_keys.push(key)
        }
    }

    return valid_default_additional_prop_keys
}

export default get_default_additional_property_keys;