export const cutlist_preference_display_name = {
    "cutlist_preferences_general_fields": {
        "area": "Area",
        "barcode_1": "Barcode1",
        "barcode_2": "Barcode2",
        "bottom_finish_display_pic": "Panel Bottom Finish",
        "brand": "Brand",
        "cabinet_code": "Cabinet Code ",
        "cabinet_sku_name": "Cabinet SKU Name ",
        "category": "Category",
        "category_name": "Category Name",
        "category_type": "Category Type",
        "client_name": "Client Name",
        "composite_name": "Composite Name",
        "core_material_wakefit": "Core_Material",
        "core_panel_sku_id": "Core SKU ID",
        "cost": "Cost",
        "cutting_l": "Cutting_L",
        "cutting_size": "Cutting Sizes",
        "cutting_t": "Cutting_T",
        "cutting_w": "Cutting_W",
        "depth": "Depth",
        "description": "Description",
        "diameter": "Diameter",
        "display_pic": "Display Picture",
        "eb_bot": "EB_Bot",
        "eb_bot_thickness": "EB_Bot_Thickness",
        "eb_left": "EB_Left",
        "eb_left_thickness": "EB_Left_Thickness",
        "eb_length_1": "EB_Length",
        "eb_length_2": "EB_Length",
        "eb_right": "EB_Right",
        "eb_right_thickness": "EB_Right_Thickness",
        "eb_thickness": "EB_Thickness",
        "eb_top": "EB_Top",
        "eb_top_thickness": "EB_Top_Thickness",
        "eb_width_1": "EB_Width",
        "eb_width_2": "EB_Width",
        "finish": "Finish",
        "finishing_size": "Finishing Size",
        "grain": "Grain",
        "grain_desc": "Grain_Desc",
        "grain_prod": "Grain_Prod",
        "height": "Height",
        "item": "Item",
        "level": "Level",
        "machine_file_bottom": "Machine File Bottom",
        "machine_file_top": "Machine File Top",
        "machining_type": "Machining Type",
        "material_ids": "Material IDs",
        "model_no": "Model No.",
        "sku_name": "SKU Name",
        "object_sku_id": "Object SKU ID",
        "operations": "Operations",
        "panel_bottom_finish_name": "Panel internal finish name",
        "panel_code": "Panel Code ",
        "panel_label": "Panel Label ",
        "panel_part": "Panel Part",
        "panel_ply_name": "Panel ply name",
        "panel_side": "Panel Side",
        "panel_top_finish_name": "Panel external finish name",
        "parent_cabinet_name": "Parent cabinet name",
        "parent_connector": "Parent Hardware Name",
        "part_code": "Part Code ",
        "pocket_radius": "Pocket Radius",
        "points": "Points",
        "position": "Position",
        "postprocessing": "Post Processing",
        "premilling": "Premilling",
        "price": "Price",
        "price_archi": "Price/Unit",
        "price_detail": "Price Details",
        "product_code": "Product Code",
        "quantity": "Quantity",
        "quantity_unit": "Unit",
        "reference": "Reference",
        "repeaters": "Repeaters",
        "room_name": "Room name",
        "select": "Select",
        "sku_group_name": "Sku group name",
        "sku_wakefit": "SKU",
        "slot_length": "Slot Length",
        "slot_width": "Slot Width",
        "sr_num": "Sr No.",
        "surface_1": "Surface_1",
        "surface_2": "Surface_2",
        "tape_width": "Tape Width",
        "thickness": "Thickness (in mm)",
        "tool_name": "Tool Name",
        "tooling_side": "Tooling Side",
        "top_finish_display_pic": "Panel Bottom Finish",
        "total_length": "Total Length",
        "unit_price": "Unit Price",
        "units": "Units",
        "volume": "Volume",
        "width": "Width",
        "unique_identifier": "Unique Identifier"
    },
    "cutlist_preferences_biesse_fields": {
        "part_code": "Panel_Name",
        "panel_code": "Panel_Code",
        "cabinet_code": "Cabinet_Code",
        "cutting_l": "Length",
        "cutting_w": "Width",
        "cutting_t": "Thickness",
        "panel_ply_name": "Panel_Material",
        "panel_top_finish_name": "Top_Material",
        "panel_bottom_finish_name": "Bottom_Material",
        "quantity": "Quantity",
        "grain": "Grain",
        "eb_top": "EB_Top_Material",
        "eb_top_thickness": "EB_Top_Thickness",
        "eb_right": "EB_Right_Material",
        "eb_right_thickness": "EB_Right_Thickness",
        "eb_bot": "EB_Bottom_Material",
        "eb_bot_thickness": "EB_Bottom_Thickness",
        "eb_left": "EB_Left_Material",
        "eb_left_thickness": "EB_Left_Thickness",
        "machine_file_top": "Machine_File_Top",
        "machine_file_bottom": "Machine_File_Bottom",
        "client_name": "Client_Name",
        "cabinet_sku_name": "Cabinet_Sku_Name",
        "panel_label": "Panel_Label",
        "width": "Finished_Length",
        "depth": "Finished_Width",
        "height": "Finished_Thickness",
        "premilling": "Premilling",
        "unique_identifier": "Unique_Identifier",
        "project_name": "Project_Name"
    }
}