import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import name_sort from '../../utils/name_sort';
import split_cat_type from '../../utils/split_cat_type';
import {Modal, CardImg, Alert, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import { useAlert } from 'react-alert'
import remove_underscore_wtih_first_cap from '../../utils/remove_underscore_wtih_first_cap.js';
import {primary_menu_options} from '../../components/Sidebar/menu_options.js'
//import AlertBox from "../../components/AlertBox/";

const styles = {
    error_style: {
        color: "red",
        fontSize: 'small'
    },

};

var pricing_system_json = {
    'per_unit_length':[
        {id:'metre', text:'Metre'},{id:'millimetre', text:'Millimetre'} ,{id:'foot', text:'Foot'}
    ],
    'per_unit_area' :[
        {id:'sq_metre', text:'Square Metre'},{id:'sq_millimetre', text:'Square Millimetre'} ,{id:'sq_foot', text:'Square Foot'}
    ],
    'per_unit_volume' :[
        {id:'cu_metre', text:'Cubic Metre'},{id:'cu_millimetre', text:'Cubic Millimetre'} ,{id:'cu_foot', text:'Cubic Foot'}
    ],
    // 'per_unit_weight' [
    //  {id:'kilogram', text:'kilogram'},{id:'gram', text:'Gram'} ,{id:'pound', text:'Pound'}
    // ];
    'per_unit' : [
        {id:'per_unit', text:'Per Unit'}
    ],
}

const CategoryTypeDialogBox = ({open , handleClose , active_category_type ,user , fetch_category_type_details , mode}) => {

    const[display_name, set_display_name] = useState("");
    const[division_json, set_division_json] = useState([]);
    const[selected_sku_division_id, set_selected_sku_division_id] = useState("furniture");
    const[pricing_dimension, set_pricing_dimension] = useState('none');
    const[pricing_unit, set_pricing_unit] = useState("none");
    const alert = useAlert()

    const onclick_add_new_category_type = async() => {
        if(pricing_dimension!='none' && pricing_unit==='none'){
            alert.info('Please select Pricing unit')
        }else if(display_name && selected_sku_division_id){
            try {
                var concat_name= concat_name_division(display_name,selected_sku_division_id) 
                var body = {id:concat_name, 'display_name':concat_name, sku_division_id:selected_sku_division_id, pricing_dimension, pricing_unit};
                var response = await general_fetch({ url: 'sku_category_type/add', body});  
                onclick_handleclose()
                alert.success('Category Type added Successfully !')
                fetch_category_type_details()
            } catch(err) {
                console.log(err);
                alert.error('Cannot refetch ! Please Contact admin')
            }
        }else{
            alert.info('Please fill all required details')
        }   
    }

    const onclick_update_category_type = async() => {
        if(pricing_dimension!='none' && pricing_unit==='none'){
            alert.info('Please select Pricing unit')
        }else if(active_category_type && display_name && selected_sku_division_id){
            try {
                var concat_name= concat_name_division(display_name,selected_sku_division_id) 
                var body = {id: active_category_type.id,display_name:concat_name, sku_division_id:selected_sku_division_id, pricing_dimension, pricing_unit};
                var response = await general_fetch({ url: 'sku_category_type/update', body});   
                onclick_handleclose()
                alert.success('Category Type updated Successfully !')
                fetch_category_type_details()
            } catch(err) {
                console.log(err);
                alert.error('Error! Please Contact admin')
            }
        }else{
            alert.info('Please fill all required details')
        }   
    }

    const concat_name_division = (display_name,selected_sku_division_id) => {
        var concat_name_1 = display_name+'_'+selected_sku_division_id
        return(concat_name_1.replace(/ /gi, "_"))
    }
    
    useEffect(() => {
        if(active_category_type){
            set_display_name(active_category_type.display_name)
            set_selected_sku_division_id(active_category_type.sku_division_id)
            set_pricing_dimension(active_category_type.pricing_dimension)
            set_pricing_unit(active_category_type.pricing_unit)
        }else{
            set_selected_sku_division_id('furniture')
            set_pricing_dimension('none')
        }
    },[active_category_type])

    // useEffect(() => {
    //     mode==='edit'?'':set_pricing_unit('none')
    // },[pricing_dimension])
    
    useEffect(() => {
        if(division_json && !division_json.length && primary_menu_options && primary_menu_options.length) {
            var x_catalogue = primary_menu_options.find(x => x.text==='Catalogue').sub_menu;
            set_division_json(x_catalogue)
        }
    },[ primary_menu_options ])

    const onclick_handleclose = () => {
        handleClose()
        set_display_name('')
        set_selected_sku_division_id('')
    }

    return (
        <Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={onclick_handleclose}>
            <Card className='global_modal_height_for_xs'>
                <CardHeader className='global_modal_header'>
                        <span>
                            <span>{(mode=='add') ? "Create a Category Type" : "Edit Category Type - "}</span>
                            <span>{mode=='add' ? '' : active_category_type ?active_category_type.display_name:''}</span>
                        </span>
                        <i onClick={onclick_handleclose} style={{ cursor: 'pointer'}} className='fa fa-times'/>
                </CardHeader>

                <CardBody className='global_modal_body'>
                    <Row style={{marginBottom:'20px'}}>
                        <Col>
                            <div>
                                <div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>Category Type Name *</div>
                                <Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder="Category Type Name" type="text" value={display_name} onChange={e => set_display_name(e.target.value)}/>
                            </div>
                        </Col>  
                    </Row>
                    <Row style={{marginBottom:'20px'}}>
                        <Col>
                            <div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>Select Division *</div>
                            <Input style={{width:'100%',height:'32px', fontSize:'14px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} type="select" value={selected_sku_division_id} onChange={e => set_selected_sku_division_id(e.target.value)}>
                                {
                                    division_json && division_json.length?division_json.map((item, idx) => 
                                        <option key={idx} value={item.id}>{item.text}</option>
                                    )
                                    :''
                                }
                            </Input>
                        </Col>  
                    </Row>
                    <Row style={{marginBottom:'20px'}}>
                        <Col>
                            <div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>Price Dimension</div>
                            <Input style={{width:'100%',height:'32px', fontSize:'14px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} type="select" value={pricing_dimension} onChange={e => {set_pricing_dimension(e.target.value);set_pricing_unit('none')}}>
                                <option value='none'>None</option>
                                {
                                    pricing_system_json && Object.keys(pricing_system_json).length? Object.keys(pricing_system_json).map((item, idx) => 
                                        <option key={idx} value={item}>{remove_underscore_wtih_first_cap(item)}</option>
                                    )
                                    :''
                                }
                            </Input>
                        </Col>  
                        
                            <Col>
                                <div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>Price Unit</div>
                                <Input style={{width:'100%',height:'32px', fontSize:'14px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} type="select" value={pricing_unit} onChange={e => set_pricing_unit(e.target.value)}>
									{
										pricing_dimension==='none'?<option value='none'>None</option> :''
									}
                                    {
										pricing_dimension!='none' && pricing_dimension && pricing_system_json[pricing_dimension].length?<option value='none'>Choose a value</option> :''
									}
                                    {
                                        pricing_dimension!='none' && pricing_dimension && pricing_system_json[pricing_dimension].length?pricing_system_json[pricing_dimension].map((item, idx) => 
                                            <option key={idx} value={item.id}>{item.text}</option>
                                        )
                                        :''
                                    }
                                
                                </Input>
                            </Col> 
                    </Row>
                </CardBody>

                <CardFooter className='global_modal_footer'>
                        <Button className='blue_button' style={{fontSize:'14px'}} type="button" onClick={mode==='add'?onclick_add_new_category_type:onclick_update_category_type}> Done </Button>
                </CardFooter>
            </Card>  
        </Modal>
    );
}

export default CategoryTypeDialogBox ; 
