import React , { useState , useEffect, useContext }from 'react';
import general_fetch from '../../utils/fetch';
import Main_Grid from '../../components/Main_Grid';
import {Dropdown, Tooltip, FormGroup, Button, Form, Input, InputGroupAddon, Modal, InputGroupText, InputGroup, Badge, Card, CardBody, CardText, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent ,CardImg ,Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Toolbar from '../../components/Toolbar/';
import Info_tooltip from '../../components/Info_tooltip/';
import classnames from "classnames";
import { Link, Redirect, useHistory } from "react-router-dom";
// import { Elements } from "@stripe/react-stripe-js";
// import CheckoutForm from "./CheckoutForm";
// import { loadStripe } from "@stripe/stripe-js";
//import BillingDetailsDialog from './BillingDetailsDialog'
//import PurchaseInfurniaCreditsDialog from './PurchaseInfurniaCreditsDialog'
//import OrderDetailsModal from './OrderDetailsModal'
//import TransferPluginCreditsDialog from './TransferPluginCreditsDialog'
// import StripePostpaid from './StripePostpaid';	

import { useAlert } from 'react-alert'
import Country_list from '../../utils/country.js';
// import Multiselect from 'multiselect-react-dropdown';
import Multiselect from '../../components/Multiselect/';
import {CSVLink} from "react-csv";
import SingleSelect from '../../components/SingleSelect';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import beautify_user_get_all from '../../utils/beautify_user_get_all';
import Moment from 'react-moment';
import TableSortComponent from '../../components/TableSortComponent';
import { useSetRecoilState } from 'recoil';
import { app_loader_atom } from '../../AppRecoil/atoms';
import { AllBuContext } from '../../components/Contexts/AllBuContext';
import { UserContext } from '../../components/Contexts/UserContext';
import { IModalBody, IModalFooter, IModalHeader } from '../../components/IModal/index.js';
import { COLORS } from '../../utils/colors.js';
import debug_log from '../../utils/debug_log.js';
import { FetchError, handleError } from '../../utils/error.js';


// const multisel_style_json = {
// 	multiselectContainer: { // To change css for multiselect (Width,height,etc..)
// 		backgroundColor: 'white',
// 		fontSize:"12px"
// 	},
// 	searchBox: { // To change search box element look	
		
// 	},
// 	inputField: { // To change input field position or margin

// 	},
// 	chips: { // To change css chips(Selected options)
// 		backgroundColor: '#6c757d'
// 	},
// 	optionContainer: { // To change css for option container 

// 	},
// 	option: { // To change css for dropdown options
		
// 	},
// 	groupHeading: { // To chanage group heading style
// 	}
// }  

const styles = {
	bluebutton_style:{
		backgroundColor: '#007EF4',
		boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
		borderRadius: '3px',
		height:'auto',
		minHeight:'32px',
		padding:'0px 24px',
		border:'0px',
		color:'white',
	},
	add_brand_style:{
		backgroundColor: '#007EF4',
		boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
		borderRadius: '3px',
		height:'32px',
		padding:'0px 24px',
		border:'0px',
		color:'white',
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
};

let roles_options = [{name: 'Designer', id: 'designer'},{name: 'Project Manager', id: 'project_manager'},{name: 'Account Manager', id: 'accounts_manager'},{name: 'Catalogue Manager', id: 'catalog_manager'},{name: 'MES User', id: 'mes_user'},{name: 'MES Editor', id: 'mes_editor'}]		
let roles_options_supported = [{name: 'Designer', id: 'designer'},{name: 'Project Manager', id: 'project_manager'},{name: 'Account Manager', id: 'accounts_manager'},{name: 'Catalogue Manager', id: 'catalog_manager'},{name: 'MES User', id: 'mes_user'},{name: 'MES Editor', id: 'mes_editor'},{name: 'Infurnia PM', id: 'infurnia_product_manager'},{name: 'Infurnia Catalog Admin', id: 'infurnia_catalog_admin'},{name: 'Customer Success Admin', id: 'customer_success_admin'},,{name: 'CS Rep', id: 'cs_rep'},{name: 'BD Rep', id: 'bd_rep'},{name: 'Content Manager', id: 'content_manager'}]		

// The below modals are not in use
// const Recharge_wallet_modal = ({user, get_info_billing_address, address_info, open, handleclose, transaction_type, country, set_country, alert1}) => {

// 	const [billing_step, set_billing_step] = useState('1');

// 	const onclick_handleclose = () => {
// 		set_billing_step('1')
// 		handleclose()
// 	}

// 	useEffect(() => {
// 		console.log('address_info-2step=========>',address_info);
// 		console.log('address_info-2step=========>',transaction_type);
// 	},[address_info])
	
// 	return(
// 		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onclick_handleclose}>
// 			<Card style={{height:'auto', boxShadow: '0 0 1px 0 rgba(67,90,111,0.47)', borderRadius: '5px'}}>
// 				<CardHeader style={{height:'56px', backgroundColor:'#FFFFFF'}}>
// 					<div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
// 						<span style={{fontSize:'18px', color:'#234361'}}>
// 							<b>{transaction_type=='prepaid'?'Purchase Infurnia Credits':'Add Credit Card'}</b>
// 						</span>
// 						<span onClick={onclick_handleclose} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
// 							<i style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
// 						</span>
// 					</div>
// 				</CardHeader>

// 				<CardBody style={{height:'auto', backgroundColor:'#FFFFFF', padding:'14px 20px'}}>
// 					<Row style={{marginBottom:"10px"}}>
// 						<Col>
// 							<div style={{height:'40px', borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
// 								<Row>
// 									<Col xs={4}>
// 										<div style={{ color:'#425A70', display:"flex",alignItems:'center', justifyContent:'center'}} onClick={() => set_billing_step('1')}>
// 											<span style={{backgroundColor: billing_step=='1'?'#1070CA':'rgba(0, 0, 0, 0.125)', color:billing_step=='1'?'white':'black', justifyContent:"center",height:'25px', width:"25px", borderRadius:'50%', display:'flex', alignItems:'center', marginRight:'3px'}}>1</span>
// 											<span>Country Details</span>
// 										</div>
// 									</Col>
// 									<Col xs={4}>
// 										<div style={{ color:'#425A70', display:"flex",alignItems:'center', justifyContent:'center'}} onClick={() => set_billing_step('2')}>
// 											<span style={{backgroundColor: billing_step=='2'?'#1070CA':'rgba(0, 0, 0, 0.125)', color:billing_step=='2'?'white':'black', justifyContent:"center",height:'25px', width:"25px", borderRadius:'50%', display:'flex', alignItems:'center', marginRight:'3px'}}>2</span>
// 											<span>Billing Details</span>
// 										</div>
// 									</Col>
// 									<Col xs={4}>
// 										<div style={{ color:'#425A70', display:"flex",alignItems:'center', justifyContent:'center'}} onClick={() => set_billing_step('3')}>
// 											<span style={{backgroundColor: billing_step=='3'?'#1070CA':'rgba(0, 0, 0, 0.125)', color:billing_step=='3'?'white':'black', justifyContent:"center",height:'25px', width:"25px", borderRadius:'50%', display:'flex', alignItems:'center', marginRight:'3px'}}>3</span>
// 											<span>{transaction_type=='prepaid'?'Payment Info':'Card Details'}</span>
// 										</div>
// 									</Col>		
// 								</Row>
// 							</div>
// 						</Col>
// 					</Row>
// 					<Row style={{marginBottom:"10px"}}>
// 						<Col>
// 							<Billing_country_Details_modal billing_step={billing_step} set_billing_step={set_billing_step} handleclose={onclick_handleclose} country={country} set_country={set_country} address_info={address_info}/>
// 							<Billing_Details_modal billing_step={billing_step} set_billing_step={set_billing_step} handleclose={onclick_handleclose} country={country} set_country={set_country} address_info={address_info} get_info_billing_address={get_info_billing_address}/>
// 							{transaction_type=='prepaid'?<Payment_info_modal user={user} billing_step={billing_step} set_billing_step={set_billing_step} handleclose={onclick_handleclose} country={country} set_country={set_country} address_info={address_info} get_info_billing_address={get_info_billing_address} alert1={alert1}/>:<Postpaid_add_credit_card billing_step={billing_step} country={country} set_country={set_country}/>}
// 						</Col>
// 					</Row>
// 				</CardBody>
// 			</Card>  
// 		</Modal>
// 	);
// }

// const Billing_country_Details_modal = ({address_info, billing_step, handleclose, set_billing_step, country, set_country}) => {

// 	const [dummy_country, set_dummy_country] = useState('')

// 	const onclick_handleclose = () => {
// 		handleclose();
// 	}

// 	const onclick_adding_country = () => {
// 		set_billing_step('2')
// 		set_country(dummy_country)
// 	}

// 	useEffect(() => {
// 		if(country){
// 			set_dummy_country(country)
// 		}
// 	},[country])

// 	return(
// 		<div style={{display:billing_step=='1'?'block':'none'}}>
// 			<Row style={{marginBottom:"10px"}}>
// 				<Col>
// 					<div style={{paddingBottom:'3px',fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '20px'}}><b>In which country your organisation is billed at?</b></div>
// 					<Input value ={dummy_country} onChange={(e) => set_dummy_country(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Country" type="select">
// 						{
// 							Country_list.map((country, idx) => (
// 								<option key={idx} value={country.code}>{country.name}</option>
// 							))
// 						}
// 					</Input>
// 				</Col>
// 			</Row>
// 			<Row style={{height:'64px', backgroundColor:'#FFFFFF'}}>
// 				<Col style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'flex-end'}} >
// 					<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>
// 					<Button className='blue_button' type="button" onClick={onclick_adding_country} >Next</Button>
// 				</Col>
// 			</Row>
// 		</div>
// 	);
// }


// const Billing_Details_modal = ({address_info, billing_step, handleclose, set_billing_step, country, set_country, get_info_billing_address}) => {
		
// 	const [name , set_name] = useState('')
// 	const [gst_number , set_gst_number] = useState('')
// 	const [address_line1 , set_address_line1] = useState('')
// 	const [address_line2 , set_address_line2] = useState('')
// 	const [city , set_city] = useState('')
// 	const [address_state , set_address_state] = useState('')
// 	const [zipcode , set_zipcode] = useState('')

// 	const onclick_handleclose = () => {
// 		handleclose();
// 	}

// 	const onclick_go_to_country_tab = () => {
// 		set_billing_step('1')
// 	}

// 	useEffect(() => {
// 		if(address_info && address_info.location){
			
// 			set_country(address_info.location.country?address_info.location.country:'')
// 			set_address_line1(address_info.location.line_1?address_info.location.line_1:'')
// 			set_address_line2(address_info.location.line_2?address_info.location.line_2:'')
// 			set_city(address_info.location.city?address_info.location.city:'')
// 			set_address_state(address_info.location.state?address_info.location.state:'')
// 			set_zipcode(address_info.location.pin_code?address_info.location.pin_code:'')
// 		}
// 		if(address_info){
// 			set_gst_number(address_info.gst_number?address_info.gst_number:'')
// 			set_name(address_info.name?address_info.name:'')
// 		}
// 	},[address_info])

// 	const update_info = async () => {
// 		if(name && city && address_line1 && country && zipcode && address_state){
// 			try {
// 				var location = JSON.stringify({city : city , state : address_state , line_1:address_line1 , line_2:address_line2  , country:country , pin_code:zipcode  })
// 				var updates = JSON.stringify({name : name , website : null , gst_number:gst_number})
// 				var body = {location,updates}
// 				var reponse = await general_fetch({ url: 'store/update_info' , body });
// 				get_info_billing_address();
// 				set_billing_step('3')
// 			} catch(err) {
// 				console.log(err);
// 			}
// 		}else{
// 		}
// 	}

// 	return(
// 		<div style={{display:billing_step=='2'?'block':'none'}}>
// 			<Row style={{marginBottom:"10px"}}>
// 				<Col xs={6}>
// 					<div>
// 						<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Name *</div>
// 						<Input value={name} onChange={(e) => set_name(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Name" type="text" />
// 					</div>
// 				</Col>
// 				{
// 					country=='india'? (
// 						<Col xs={6}>
// 							<div>
// 								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>GST Number *</div>
// 								<Input value={gst_number} onChange={(e) => set_gst_number(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="GST No." type="text" />
// 							</div>
// 						</Col>
// 					):''
// 				}		
// 			</Row>
// 			<Row style={{margin:"20px 0px"}}>
// 				<Col>
// 					<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
						
// 					</div>
// 				</Col>
// 			</Row>
// 			<Row style={{marginBottom:"10px"}}>
// 				<Col>
// 					<div>
// 						<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Address Line 1 *</div>
// 						<Input value={address_line1} onChange={(e) => set_address_line1(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Line 1" type="text" />
// 					</div>
// 				</Col>
// 			</Row>
// 			<Row style={{marginBottom:"10px"}}>
// 				<Col>
// 					<div>
// 						<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Address Line 2</div>
// 						<Input value={address_line2} onChange={(e) => set_address_line2(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Line 2" type="text" />
// 					</div>
// 				</Col>
// 			</Row>
// 			<Row style={{marginBottom:"10px"}}>
// 				<Col xs={6}>
// 					<div>
// 						<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>City *</div>
// 						<Input value ={city} onChange={(e) => set_city(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="City" type="text" />
// 					</div>
// 				</Col>
// 				<Col xs={6}>
// 					<div>
// 						<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>{country=='india'?'State *':'Provience *'}</div>
// 						<Input value ={address_state} onChange={(e) => set_address_state(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder={country=='india'?'State':'Provience'} type="text" />
// 					</div>
// 				</Col>		
// 			</Row>
// 			<Row style={{marginBottom:"10px"}}>
// 				<Col xs={6}>
// 					<div>
// 						<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>{country=='india'?'Zip ':'Pin '}code *</div>
// 						<Input value ={zipcode} onChange={(e) => set_zipcode(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder={country=='india'?'Zip':'Pin'} type="text" />
// 					</div>
// 				</Col>
// 				<Col xs={6}>
// 					<div>
// 						<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Country *</div>
// 						<Input value ={country} onChange={(e) => set_country(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Country" type="select">
// 							{
// 								Country_list.map((country, idx) => (
// 									<option key={idx} value={country.code}>{country.name}</option>
// 								))
// 							}
// 						</Input>
// 					</div>
// 				</Col>		
// 			</Row>
// 			<Row style={{margin:"20px 0px 0px"}}>
// 				<Col>
// 					<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
						
// 					</div>
// 				</Col>
// 			</Row>
// 			<Row style={{height:'64px', backgroundColor:'#FFFFFF'}}>
// 				<Col style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'flex-end'}} >
// 				<Button className='white_button' type="button" onClick={onclick_go_to_country_tab}> Back </Button>
// 					<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>
// 					<Button className='blue_button' type="button" onClick={update_info} >Save & Proceed</Button>
// 				</Col>
// 			</Row>
// 		</div>  
		
// 	);
// }

// const Postpaid_add_credit_card = ({address_info, billing_step, country, set_country}) => {
	
// 	return(
// 		<h2 style={{display:billing_step=='3'?'block':'none'}}>Add Credit Card : <h4>Check for credit card wether Indian or International</h4></h2>
// 	);
// }

// const Payment_info_modal = ({set_show_StripePaymentForm,user, get_info_billing_address, address_info, billing_step, handleclose, set_billing_step, country, set_country, alert1}) => {

// 	const [show_estimation_div, set_show_estimation_div] = useState(false);
// 	const [total_no_of_credits, set_total_no_of_credits] = useState(0);
// 	const [total_billing_cost, set_total_billing_cost] = useState(0);

// 	const [proceedtopay_response , set_proceedtopay_response] = useState('')
	
// 	const [gst , set_gst] = useState('')

	
// 	const onclick_handleclose = () => {
// 		handleclose();
// 	}

// 	const onclick_go_to_billing_tab = () => {
// 		set_billing_step('2')
// 	}

// 	useEffect(() => {
// 		if(address_info){
// 			console.log('address_info-3step=========>',address_info);
// 			set_gst(address_info.gst_number?address_info.gst_number:'')
// 		}
// 	},[address_info])

// 	const onclick_proceedtopay = () => {
// 		if(total_no_of_credits>=1 && address_info && address_info.location){
// 			if(address_info && address_info.market=="india"){
// 				attemptCreditPurchase_india()
// 			}
// 			if(address_info && address_info.market=="international"){
// 				attemptCreditPurchase_international()
// 				set_show_StripePaymentForm(true)
// 			}
// 		}else{
// 			alert('Please provide billing address')
// 		}
// 	}

// 	async function attemptCreditPurchase_international() {
//         attemptCreditPurchase_india()
// 	}

// 	function loadScript(src) {
// 		return new Promise((resolve) => {
// 			const script = document.createElement("script");
// 			script.src = src;
// 			script.onload = () => {
// 				resolve(true);
// 			};
// 			script.onerror = () => {
// 				resolve(false);
// 			};
// 			document.body.appendChild(script);
// 		});
// 	}

// 	async function attemptCreditPurchase_india() {
//         const res = await loadScript(
//             "https://checkout.razorpay.com/v1/checkout.js"
//         );

//         if (!res) {
//             alert("Razorpay SDK failed to load. Are you online?");
//             return;
//         }

//         // creating a new order
//         // const result = await axios.post("http://localhost:5000/payment/orders");
        
// 		if(total_no_of_credits>0 && total_billing_cost>0 && gst){
// 			try {
// 				var address = JSON.stringify({address_info})
// 				var body = {billing_address:address, gst_number:gst, credits:total_no_of_credits}
// 				var response = await general_fetch({ url: 'billing/place_credits_prepaid_order', body});
// 				console.log('response============>', response);
// 				if(response){
// 					set_proceedtopay_response(response)
// 				}else{
// 					alert("Server error. Are you online?");
//         			return;	
// 				}
// 			} catch(err) { 
// 				console.log(err);
// 			}
// 		}else{
// 			alert('Please check either Quantity or GST');
// 		}
// 	}

// 	useEffect(() => {
// 		if(proceedtopay_response){
// 			// Getting the order details back
// 	        const { razorpay_order_id, indian_price } = proceedtopay_response;
// 	        console.log('razorpay_order_id =========>', razorpay_order_id);
// 	        console.log('indian_price========>', indian_price);

// 	        var options = {
// 	            "key": global.config.razorpay.key,
// 	            "amount": indian_price,
// 	            "name": "Infurnia",
// 	            "description": "License Purchase",
// 	            "image": "https://www.infurnia.com/wp-content/uploads/2016/06/INFURNIA-LOGO_2-1.jpg",
// 	            "order_id": razorpay_order_id,
// 	            "handler": function  (response){
// 	                console.log(response);
// 	                var body={razorpay_payment_id:response.razorpay_payment_id,razorpay_order_id:razorpay_order_id, razorpay_signature:response.razorpay_signature}
// 	                var successResponse =  general_fetch({ url: 'billing/confirm_razorpay_prepaid_credits_order', body})
// 	                .then(rez =>{
// 						console.log('successResponse==========>', successResponse);  
// 						get_info_billing_address(); 
// 						alert1.success('Payment successfull');
// 						setTimeout(()=>{
// 							onclick_handleclose();
// 							address_info && address_info.credits_account && alert.success('updated Credits', address_info.credits_account.balance) 
// 						},1000)
// 					})
// 	                .catch(err=>{console.log(err); onclick_handleclose();});
// 	                var x =successResponse;
// 	            },
// 	            "prefill": {
// 	                "name": user.name,
// 	                "email": user.email,
// 	            },
// 	            "notes": {},
// 	            "theme": {
// 	                "color": "#F37254"
// 	            }
// 	        };
// 	        // var rzp1 = new Razorpay(options);
// 	        // rzp1.open();
// 	        // // e.preventDefault();
	        
// 	        const paymentObject = new window.Razorpay(options);
// 	        paymentObject.open();
// 		}
// 	},[proceedtopay_response])

// 	// const StripePaymentForm = ({open, handle_close}) => {

// 	// 	const promise = loadStripe(global.config.stripe.key);

// 	// 	return(
// 	// 		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={handle_close}>
// 	// 			<Elements stripe={promise}>
// 	// 		        <CheckoutForm get_info_billing_address={get_info_billing_address} handle_close={handle_close} handleClose_purchasemodal={onclick_handleclose} address_info={address_info} gst={gst} total_no_of_credits={total_no_of_credits} />
// 	// 	      	</Elements>
// 	// 		</Modal>
// 	// 	)
// 	// }

// 	useEffect(() => {
// 		var x_cost = 0;
// 		if(total_no_of_credits>0 && country=='india'){
// 			var x_cost = (parseInt(total_no_of_credits)*40)*.18 + parseInt(total_no_of_credits)*40
// 		}
// 		if(total_no_of_credits>0 && country!='india'){
// 			var x_cost = total_no_of_credits
// 		}
// 		set_total_billing_cost(x_cost)
// 	},[total_no_of_credits])

// 	return(
// 		<div style={{display:billing_step=='3'?'block':'none'}}>
// 			<Row style={{marginBottom:"10px"}}>
// 				<Col>
// 					<div>
// 						<div style={{paddingBottom:'3px',fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '20px'}}><b>Enter the no. of credits you want to purchase</b></div>
// 						<Input onChange={(e) => set_total_no_of_credits(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Credits" type="text" />
// 					</div>
// 				</Col>		
// 			</Row>
// 			<Row style={{margin:"20px 0px", display:total_no_of_credits>0?'flex':'none'}}>
// 				<Col xs={4} style={{color:'#1070CA', paddingBottom:'3px',fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '20px'}}>
// 					<span style={{marginRight:'5px'}}><b>Total Billing Ammount</b></span>
// 					<span style={{display:country=='india'?'block':'none'}}>(Inclusive of GST)</span>
// 				</Col>
// 				<Col xs={2} style={{color:'#1070CA', paddingBottom:'3px',fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '20px'}}>
// 					<span>{country=='india'?'Rs. ':'$ '}{total_billing_cost}</span>
// 				</Col>
// 			</Row>
// 			<Row style={{margin:"20px 0px"}}>
// 				<Col>
// 					<div style={{color:'#1070CA', paddingBottom:'3px',fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '20px', marginRight:'10px'}}><span style={{marginRight:'5px'}} onClick={() =>set_show_estimation_div(!show_estimation_div)}><b>Or get Help on estimating your usage</b></span><span>{show_estimation_div?<i className='fa fa-angle-up'/>:<i className='fa fa-angle-down'/>}</span></div>
// 				</Col>
// 			</Row>
// 			{
// 				show_estimation_div ? (
// 					<div>
// 						<Row style={{marginBottom:"10px"}}>
// 							<Col>
// 								<div style={{paddingBottom:'3px',fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '20px'}}><b>In which country your organisation is billed at?</b></div>
// 								<Input value ={country} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} type="select">
// 									{
// 										Country_list.map((country, idx) => (
// 											<option key={idx} value={country.code}>{country.name}</option>
// 										))
// 									}
// 								</Input>
// 							</Col>
// 						</Row>
// 						<Row style={{marginBottom:"10px"}}>
// 							<Col>
// 								<div>
// 								<div style={{paddingBottom:'3px',fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '20px'}}><b>How many designers do you have in your team?</b></div>
// 									<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Designers" type="text" />
// 								</div>
// 							</Col>
// 						</Row>
// 						<Row style={{marginBottom:"30px"}}>
// 							<Col>
// 								<div>
// 								<div style={{paddingBottom:'3px',fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '20px'}}><b>On an average, how may renders do you produce in a month?</b></div>
// 									<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Renders" type="text" />
// 								</div>
// 							</Col>
// 						</Row>
// 						<Row style={{marginBottom:"10px"}}>
// 							<Col style={{borderTop:"1px solid rgba(0, 0, 0, 0.125)" ,paddingTop:'20px'}}>
// 								<div>
// 									<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}><b>Estimated Credits:</b></div>
// 									<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>No. of Designers * credits/designer (50 credits)</div>	
// 									<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>No. of Renders * Avg. credits/HD render (1 credits)</div>	
// 									<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Total Credits to be purchased:</div>
// 									<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Total Billing Amount <span style={{display:country=='india'?'block':'none'}}>(Inclusive of GST)</span>:</div>
// 								</div>
// 							</Col>
// 						</Row>
// 						<Row style={{margin:"20px 0px 0px"}}>
// 							<Col>
// 								<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
									
// 								</div>
// 							</Col>
// 						</Row>
// 					</div>
// 				):''
// 			}
// 			<Row style={{height:'64px', backgroundColor:'#FFFFFF'}}>
// 				<Col style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'flex-end'}} >
// 				<Button className='white_button' type="button" onClick={onclick_go_to_billing_tab}> Back </Button>
// 					<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>
// 					<Button className='blue_button' type="button" onClick={onclick_proceedtopay} >Proceed to Pay</Button>
// 				</Col>		
// 			</Row>
// 		</div>  
// 	);
// }

const Estimate_modal = ({address_info,open, handleclose, country, set_country}) => {
	
	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={handleclose}>
			<Card style={{height:'auto', boxShadow: '0 0 1px 0 rgba(67,90,111,0.47)', borderRadius: '5px'}}>
				<CardHeader style={{height:'56px', backgroundColor:'#FFFFFF'}}>
					<div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
						<span style={{fontSize:'18px', color:'#234361'}}>
							<b>Purchase Infurnia Credits</b>
						</span>
						<span onClick={handleclose} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<i style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
						</span>
					</div>
				</CardHeader>

				<CardBody style={{height:'auto', backgroundColor:'#FFFFFF', padding:'14px 20px'}}>
					<Row style={{marginBottom:"10px"}}>
						<Col>
							<div style={{paddingBottom:'3px',fontSize:'12px',  letterSpacing: '-0.04px', lineHeight: '20px'}}><b>In which country your organisation is billed at?</b></div>
							<Input value ={country} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} type="select">
								{
									Country_list.map((country, idx) => (
										<option key={idx} value={country.code}>{country.name}</option>
									))
								}
							</Input>
						</Col>
					</Row>
					<Row style={{marginBottom:"10px"}}>
						<Col>
							<div>
							<div style={{paddingBottom:'3px',fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '20px'}}><b>How many designers do you have in your team?</b></div>
								<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Designers" type="text" />
							</div>
						</Col>
					</Row>
					<Row style={{marginBottom:"30px"}}>
						<Col>
							<div>
							<div style={{paddingBottom:'3px',fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '20px'}}><b>On an average, how may renders do you produce in a month?</b></div>
								<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Renders" type="text" />
							</div>
						</Col>
					</Row>
					<Row style={{marginBottom:"10px"}}>
						<Col style={{borderTop:"1px solid rgba(0, 0, 0, 0.125)" ,paddingTop:'20px'}}>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}><b>Estimated Credits:</b></div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>No. of Designers * credits/designer (50 credits):</div>	
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>No. of Renders * Avg. credits/HD render (1 credit):</div>	
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Total Credits to be purchased:</div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Total Billing Amount(inclusive of taxes):</div>
							</div>
						</Col>
					</Row>
				</CardBody>

				<CardFooter style={{height:'64px', backgroundColor:'#FFFFFF'}}>
					<div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'flex-end'}} >
						<Button className='white_button' type="button" onClick={handleclose}> Cancel </Button>
						<Button className='blue_button' type="button" >Agree & Pay</Button> 
					</div>		
				</CardFooter>
			</Card>  
		</Modal>
	);
}

const Tab_structure = ({initialized, tabs, set_tabs, set_user_type, set_show_add_user_modal, set_show_select_user_modal, set_show_depricate_user_modal, set_show_detatch_license_modal , all_users_accounts, set_all_users_accounts, all_deleted_users_accounts, set_all_deleted_users_accounts, users_details, User_edit_Dropdown, set_mode, set_search_string, search_string, orignal_sales_channel }) => {

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	const [user_data, set_user_data] = useState([]);
	const [asc_order, set_asc_order] = useState(false);
	const [asc_order_deleted_users, set_asc_order_deleted_users] = useState(false);
	const all_bu_in_store = useContext(AllBuContext)
	const org_bu_view = useContext(OrgBuContext)

	const get_csv_data = async() => {
		//let resp = await general_fetch({url :'user/get_all'})
		var user_csv_list = []
		let users_details = tabs == 1 ? all_users_accounts : all_deleted_users_accounts
		if(users_details && users_details.length)	{
			users_details.map(x => {
				var temp = {};
				temp.name = (x.first_name ? x.first_name : '-') + ' ' + (x.last_name ? x.last_name : '')
				temp.email = x.email
				temp.contact = x.contact
				temp.role = x.role ? x.role.map(role => role.name) : ""
				temp['Sales Channel'] =  x.Sales_Channels ? x.Sales_Channels.map(ch => ch.name) : ""
				temp['Price Types'] = x.price_types ? x.price_types.map(pt => pt.name) : ""
				temp['Preferred Price Type'] = x.price_types && x.price_types.find(pt => pt.preferred) && x.price_types.find(pt => pt.preferred).name ? x.price_types.find(pt => pt.preferred).name : ""
				temp['User added Date'] = new Date(x.created_at).toString().substring(4, 15)
				temp['User Deleted Date'] = x.deprecated ? new Date(x.updated_at).toString().substring(4, 15) : ''
				user_csv_list.push(temp)
			})
			set_user_data(user_csv_list)
			console.log("user csv data", user_csv_list, users_details)
		}	
	}

	useEffect(() => {
		get_csv_data()
	}, [tabs, all_users_accounts]);

	return(
		<div style={{height:'100%'}}>
			<div className="nav-wrapper" style={{width:'100%', display:'flex', justifyContent:'flex-end', height:"40px"}}>
				<Nav 
					style={{display:'flex', alignItems:'center',flex:4}}
					className="flex-md-row"
					id="tabs-icons-text" 
					pills
					role="tablist"
				>
					<NavItem style={{marginLeft:'15px'}}>
						<NavLink
							aria-selected={tabs === 1}
							className={classnames("mb-sm-3 mb-md-0", {
								active: tabs === 1
							})}
							onClick={e => toggleNavs(e, 1)}
							href="#pablo"
							role="tab"
						>
							<span>Users</span>
						</NavLink>
					</NavItem>
					{/* <NavItem>
						<NavLink
							aria-selected={tabs === 2}
							className={classnames("mb-sm-3 mb-md-0", {
									active: tabs === 2
							})}
							onClick={e => toggleNavs(e, 2)}
							href="#pablo"
							role="tab"
						>
							<span>Annual License 
								<span style={{padding:'4px 8px', color:'##ffa31a', backgroundColor:'#fff5e6', fontSize:'10px'}}>SAVE 17%</span>
							</span>
						</NavLink>
					</NavItem> */}

					{(org_bu_view != 'bu' || all_bu_in_store.length == 1) && <NavItem>
						<NavLink
							aria-selected={tabs === 2}
							className={classnames("mb-sm-3 mb-md-0", {
									active: tabs === 2
							})}
							onClick={e => toggleNavs(e, 2)}
							href="#pablo"
							role="tab"
						>
							<span>Deleted Users</span>
						</NavLink>
					</NavItem>}
				</Nav>
				<span style={{ display:'flex', alignItems:'center', justifyContent:'flex-end' , marginRight:'15px'}}>
				<CSVLink data = {user_data} filename={"Users_CSV"} style={{alignItems:'center', pointerEvents: user_data && user_data.length ? 'auto' : 'none'}} >
					<button style={{...styles.add_brand_style}}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'10px', display:'flex', alignItems:'center', opacity:user_data && user_data.length ? '1' : '0.5'}}><i style={{fontSize:'10px', paddingRight:'10px'}} className='fa fa-download'/><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'14px'}}>Download CSV</span></span></button>
				</CSVLink>
				</span>
				<span style={{ display:'flex', alignItems:'center', justifyContent:'flex-end', marginRight:'15px'}}>
					<button onClick={() =>{set_show_select_user_modal(org_bu_view==='bu' && all_bu_in_store && all_bu_in_store.length > 1); set_show_add_user_modal(true); set_mode('add')}} style={{...styles.add_brand_style}}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'14px', display:'flex', alignItems:'center', alignContent:'center'}}>Add User</span></button>
				</span>
			</div>
			<Row style={{margin:0}}>
		        <Col>
					<div className='flex_property' style={{borderTop:'1px solid #f2f4f6', height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}>
						<i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/>
						<input value={search_string ? search_string :''} onChange={(e) => {set_search_string(e.target.value)}} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Name, Email, Access, Sales Channel' />
					</div>
		        </Col>
	        </Row>
			<TabContent activeTab={"tabs" + tabs} style={{height:"calc(100% - 80px)", width:'100%'}}>

				<TabPane tabId="tabs1" style={{fontSize:'12px',height:"100%", width:'100%'}}>	
					<Row style={{height:"100%", width:'100%', margin:0}}>
						<Col style={{height:"100%", width:'100%'}}>
							<All_users_table initialized={initialized} all_users_accounts={all_users_accounts} set_all_users_accounts = {set_all_users_accounts} User_edit_Dropdown={User_edit_Dropdown} orignal_sales_channel = {orignal_sales_channel} asc_order={asc_order} set_asc_order={set_asc_order}/>	
						</Col>
					</Row>
				</TabPane>

				{/* <TabPane tabId="tabs2" style={{fontSize:'12px',height:"100%", width:'100%'}}>	
					<Row style={{height:"100%", width:'100%', margin:0}}>
						<Col style={{height:"100%", width:'100%'}}>
							<Annual_users_table all_users_accounts={all_users_accounts} set_show_detatch_license_modal={set_show_detatch_license_modal}/>		
						</Col>
					</Row>
				</TabPane> */}

				<TabPane tabId="tabs2" style={{fontSize:'12px',height:"100%", width:'100%'}}>	
					<Row style={{height:"100%", width:'100%', margin:0}}>
						<Col style={{height:"100%", width:'100%'}}>
							<All_users_table initialized={initialized} all_users_accounts={all_deleted_users_accounts} set_all_users_accounts={set_all_deleted_users_accounts} orignal_sales_channel = {orignal_sales_channel} asc_order={asc_order_deleted_users} set_asc_order={set_asc_order_deleted_users} is_deleted/>	
						</Col>
					</Row>
				</TabPane>				
				
			</TabContent>
				
		</div>
	);
}

const CustomCard = ({yes_del, no_del, text, onClose, nonowned, button_name}) => {
	return(
		<Card className='p-4' style={{borderRadius: '8px', width: '400px'}}>
			<div className='flex_center mb-3'>
				{text}
			</div>
			<div className='flex_center'>
				<Button className="white_button" type="button" onClick={() => { no_del(); onClose() }}>
					Close
				</Button>
				<Button id='delete_notification_button' className="blue_button" type="button" onClick={() => { yes_del(); onClose() }}>
					{button_name ? button_name : (nonowned === 'non-owned' ? 'Remove' : 'Delete')}
				</Button>
			</div>
		</Card>
	)
}

const SingleBuManagement = ({ single_bu_details, all_sales_channel, all_price_type }) => {
	const [roles, set_roles] = useState([]);
	const [sales_channel, set_sales_channel] = useState([]);
	const [price_type, set_price_type] = useState([]);
	const [preferred_price_type, set_preferred_price_type] = useState([]);
	/*
		bu_name
		role
		sales_channel
		price_type
		preferred_price
	*/

	useEffect(() => {
		if (single_bu_details) {
			set_roles(single_bu_details.role_ids)
			set_sales_channel(single_bu_details.sales_channel_details)
			set_price_type(single_bu_details.price_type_details)
			set_preferred_price_type(single_bu_details.preferred_price_type_id)
		}
	}, [single_bu_details]);

	const onChangeRoles = (selected_value) => {
		set_roles(selected_value)
		single_bu_details.role_ids = selected_value
	}

	const onChangeSC = (selected_value) => {
		set_sales_channel(selected_value)
		single_bu_details.sales_channel_details = selected_value
	}

	const onChangePT = (selected_value) => {
		set_price_type(selected_value)
		single_bu_details.price_type_details = selected_value
	}

	const onChangePPT = (selected_value) => {
		set_preferred_price_type(selected_value)
		single_bu_details.preferred_price_type_id = selected_value
	}

	return (
		<React.Fragment>
			<div className='mb-2' style={{ display: 'flex', gap: '16px' }}>
				{/* Mapped BU */}
				<div style={{ flex: 1, minWidth: '40px' }}>
					<div>Mapped Business Unit</div>
					<Input disabled type='text' value={single_bu_details.business_unit_name} style={{ fontSize: '12px' }} />
				</div>
				{/* Access Level */}
				<div style={{ flex: 1, minWidth: '40px' }}>
					<div>Access Level *</div>
					<Multiselect
						options={roles_options}
						selectedValues={roles}
						onSelect={onChangeRoles}
						onRemove={onChangeRoles}
						displayValue="name" // Property name 
						placeholder='Select Value'
						id='roles_for_user'
					/>
				</div>
			</div>
			<div style={{ flex: 1, minWidth: '40px', display: roles.find(x => x.id === 'designer') ? 'block' : 'none' }}>
				<div>
					<div className='mb-2'>
						{/* Sales Channel */}
						<div>Sales Channel</div>
						<Multiselect
							options={all_sales_channel}
							selectedValues={sales_channel}
							onSelect={onChangeSC}
							onRemove={onChangeSC}
							displayValue="name" // Property name 
							placeholder='Choose Sales Channels'
							id='sc_for_user'
						/>
					</div>
					<div style={{ display: 'flex', gap: '16px' }}>
						{/* Price Type */}
						<div style={{ flex: 1, minWidth: '40px' }}>
							<div>Price Type</div>
							<Multiselect
								options={all_price_type}
								selectedValues={price_type}
								onSelect={onChangePT}
								onRemove={onChangePT}
								displayValue="name" // Property name 
								placeholder='Choose Price Type'
								id='pt_for_user'
							/>
						</div>
						{/* Preferred Price Type */}
						<div style={{ flex: 1, minWidth: '40px' }}>
							<div>Preferred Price Type</div>
							<Multiselect
								options={price_type}
								selectedValues={preferred_price_type}
								onSelect={onChangePPT}
								onRemove={onChangePPT}
								displayValue="name" // Property name 
								placeholder='Choose Preferred Price Type'
								id='ppt_for_user'
								selectionLimit={1}
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

const EditBuUserMapping = ({ open, handle_close, active_user_details, all_bu_sales_channel_info, all_price_type, fetch_account_details, set_page_loader }) => {
	const all_bu_in_store = useContext(AllBuContext)
	const [user_info, set_user_info] = useState([]);
	const [tab, set_tab] = useState(0);
	const [selected_business_units, set_selected_business_units] = useState([]);

	const alert = useAlert()
	/*
		user_info format
		[
			{
				business_unit_id
				role_ids
				sales_channel_details
				price_type_details
				preferred_price_type_id
			}
		]
	*/

	useEffect(() => {
		debug_log("active user details", active_user_details)
		if (active_user_details && active_user_details.business_unit_info && !Array.isArray(active_user_details.business_unit_info)) {
			set_selected_business_units(Object.keys(active_user_details.business_unit_info).map(x => { return { id: x } }))
		} else {
			set_selected_business_units([])
		}
	}, [active_user_details]);

	useEffect(() => {
		if (selected_business_units && selected_business_units.length) {
			let user_info_temp = selected_business_units.map((business_unit) => {
				let business_unit_id = business_unit.id
				let business_unit_info = all_bu_in_store.find(x => x.id === business_unit_id)
				let temp = {
					business_unit_name: business_unit_info ? business_unit_info.name : business_unit_id,
					business_unit_id: business_unit_id,
					role_ids: [],
					sales_channel_details: [],
					price_type_details: [],
					preferred_price_type_id: '',
				}
				temp["role_ids"] = active_user_details.business_unit_info[business_unit_id] ? active_user_details.business_unit_info[business_unit_id].map(x => {return {id: x.role_id}}) : []
				temp["sales_channel_details"] = active_user_details.Sales_Channels_All ? active_user_details.Sales_Channels_All.filter(x => x.business_unit_id === business_unit_id) : []
				temp["price_type_details"] = active_user_details.price_type_bus ? active_user_details.price_type_bus.filter(x => x.bu_ids && x.bu_ids.includes(business_unit_id)) : []
				temp["preferred_price_type_id"] = temp["price_type_details"] ? temp["price_type_details"].filter(x => x.preferred_bu_ids.includes(business_unit_id)) : []
				return temp
			})
			set_user_info(user_info_temp)
			debug_log("user info orginal -> formatted", active_user_details, user_info_temp)
		}
	}, [selected_business_units]);

	const onChangeBU = (selected_value) => {
		set_selected_business_units(selected_value)
	}

	const onclick_handleclose = () => {
		set_tab(0)
		handle_close()
	}

	const onSubmit = async () => {
		try {
			let body = { first_name: active_user_details && active_user_details['first_name'] ? active_user_details['first_name'] : '', last_name: active_user_details && active_user_details['last_name'] ? active_user_details['last_name'] : '-', contact: active_user_details && active_user_details['contact'] ? active_user_details['contact'] : '-' }
			body['store_roles'] = active_user_details && active_user_details['role'] ? active_user_details['role'].map(x => x.id) : []
			body["user_id_to_update"] = active_user_details.id
			// Format to send Business Unit Info
			// [
			// 	{
			// 		business_unit_id: user.current_business_unit_id,
			// 		role_ids: roles,
			// 		sales_channel_ids: sales,
			// 		price_type_ids: price_type_ids,
			// 		preferred_price_type_id: preferred_price_type && preferred_price_type.length ? preferred_price_type[0].id : ''
			// 	}
			// ]
			set_page_loader({ show: true, text: "Updating User BU Mapping" })
			let business_unit_info = []
			for (let i in user_info) {
				let single_bu_info = {}
				let x = user_info[i]
				let is_designer_role = x.role_ids && x.role_ids.length && x.role_ids.find(y => y.id === 'designer')

				if (!(x.role_ids && x.role_ids.length)) {
					alert.error("Roles are mandatory. Please add roles for user in ", x.name)
					set_page_loader({ show: false })
					return;
				}

				if (is_designer_role && !(x.sales_channel_details && x.sales_channel_details.length)) {
					alert.error("Sales Channel is mandatory for designers. Please add Sales Channel for user in ", x.name)
					set_page_loader({ show: false })
					return;
				}

				if (is_designer_role && !(x.price_type_details && x.price_type_details.length)) {
					alert.error("Price Type is mandatory for designers. Please add Price Type for user in ", x.name)
					set_page_loader({ show: false })
					return;
				}

				if (is_designer_role && !(x.preferred_price_type_id && x.preferred_price_type_id.length)) {
					alert.error("Preferred Price Type is mandatory for designers. Please add Preferred Price Type for user in ", x.name)
					set_page_loader({ show: false })
					return;
				}

				single_bu_info["business_unit_id"] = x.business_unit_id;
				single_bu_info["role_ids"] = x.role_ids.map(y => y.id);
				if (is_designer_role) {
					single_bu_info["sales_channel_ids"] = x.sales_channel_details.map(y => y.id);
					single_bu_info["price_type_ids"] = x.price_type_details.map(y => y.id);
					single_bu_info["preferred_price_type_id"] = x.preferred_price_type_id[0].id
				}


				business_unit_info.push(single_bu_info)
			}

			body["business_unit_info"] = business_unit_info
			body["update_bu_info"] = true

			debug_log("body for User BU mapping", body)

			let resp = await general_fetch({ url: 'store/update_user', body: body });
			onclick_handleclose();
			alert.success('User BU mapping updated successfully.')
			fetch_account_details();

		} catch (err) {
			console.error("Error while trying to Edit User BU Mapping", err)
			alert.error("Could not update User BU Mapping.")
		}
		set_page_loader({ show: false })
	}

	return (
		<Modal isOpen={open} toggle={onclick_handleclose} centered size='lg'>
			<IModalHeader toggle={onclick_handleclose}>Edit User BU Mapping</IModalHeader>
			<IModalBody>
				{
					tab == 0 ?
						<div style={{ height: '600px', overflow: 'auto', padding: '8px' }}>
							<div style={{ display: 'flex', flexFlow: 'row wrap' }}>
								<div style={{ width: '50%', padding: '8px 16px' }}>
									<div>First Name</div>
									<Input className={`user_input_disabled`} style={{ fontSize: '12px' }} disabled={true} value={active_user_details && active_user_details['first_name'] ? active_user_details['first_name'] : '-'} placeholder="First Name" type="text" />
								</div>
								<div style={{ width: '50%', padding: '8px 16px' }}>
									<div>Last Name</div>
									<Input className={`user_input_disabled`} style={{ fontSize: '12px' }} disabled={true} value={active_user_details && active_user_details['last_name'] ? active_user_details['last_name'] : '-'} placeholder="Last Name" type="text" />
								</div>

								<div style={{ width: '50%', padding: '8px 16px' }}>
									<div>Email</div>
									<Input className={`user_input_disabled`} style={{ fontSize: '12px' }} disabled={true} value={active_user_details && active_user_details['email'] ? active_user_details['email'] : '-'} placeholder="Email" type="text" />
								</div>

								<div style={{ width: '50%', padding: '8px 16px' }}>
									<div>Contact Number</div>
									<Input className={`user_input_disabled`} style={{ fontSize: '12px' }} disabled={true} value={active_user_details && active_user_details['contact'] ? active_user_details['contact'] : '-'} placeholder="Contact" type="text" />
								</div>
							</div>
							<div className='my-3' style={{ borderTop: '1px solid ' + COLORS.gray4 }}></div>
							<div style={{ padding: '8px 16px' }}>
								<div className='mb-2'>Select Business Units</div>
								<Multiselect
									options={all_bu_in_store && all_bu_in_store.length ? JSON.parse(JSON.stringify(all_bu_in_store)) : []}
									selectedValues={selected_business_units}
									onSelect={onChangeBU}
									onRemove={onChangeBU}
									displayValue="name" // Property name 
									placeholder='Select Value'
									id='bu_for_user'
									style={{ height: '36px' }}
								/>
							</div>
						</div>
						: ''
				}
				{tab == 1 ?
					<div style={{ height: '600px', overflow: 'auto', padding: '8px' }}>
						{
							user_info.map((single_bu_info) => (
								<React.Fragment>
									<SingleBuManagement single_bu_details={single_bu_info} all_sales_channel={all_bu_sales_channel_info ? all_bu_sales_channel_info[single_bu_info.business_unit_id] : []} all_price_type={all_price_type ? all_price_type : []} />
									<div className='my-3' style={{ borderTop: '1px solid ' + COLORS.gray4 }}></div>
								</React.Fragment>
							))
						}
					</div>
					: ''
				}
				{/* Add BU */}
			</IModalBody>
			<IModalFooter>
				{tab == 0 ? <Button className='blue_button' onClick={() => set_tab(tab + 1)}>Next</Button> : ''}
				{tab != 0 ? <Button className='white_button' onClick={() => set_tab(tab - 1)}>Back</Button> : ''}
				{tab == 1 ? <Button className='blue_button' onClick={onSubmit}>Save BU Mapping</Button> : ''}
			</IModalFooter>
		</Modal>
	);
}
 
const Add_user_modal = ({open, open_selection_modal, handleclose, user, mode, active_user_details , all_users_accounts, all_license, alert1, orignal_sales_channel, set_active_user_details, fetch_account_details, roles_options, get_users, all_price_type, business_units, all_user_details_json, set_page_loader, store_roles_options, submit_for_delete_confirm, active_user_count, licenses_purchased}) => {
	
	const [is_designer_role, set_is_designer_role] = useState(false)
	const [first_name, set_first_name] = useState('')
	const [last_name, set_last_name] = useState('')
	const [name, set_name] = useState('')
	const [email, set_email] = useState('')
	const [contact_no, set_contact_no] = useState('')
	const [license_type, set_license_type] = useState('monthly')
	const [roles_array, set_roles_array] = useState([])
	const [sales_channel_array, set_sales_channel_array] = useState([])
	const [render_limits, set_render_limits] = useState('')
	const [selected_price_type, set_selected_price_type] = useState([])
	const [preferred_price_type, set_preferred_price_type] = useState([])
	const [selected_business_unit, set_selected_business_unit] = useState();
	const [chosen_user, set_chosen_user] = useState('') 
	const org_bu_view = useContext(OrgBuContext)
	const all_bu_in_store = useContext(AllBuContext)
	// const user = useContext(UserContext)

	const alert = useAlert()
	
	useEffect(() => {
		if(roles_array){
			var x = roles_array.filter(x => x.id==='designer')
			if(x && x.length===1){
				set_is_designer_role(true)
				return;
			}
			set_is_designer_role(false)
		}
	},[roles_array])

	useEffect(() => {
		if(!is_designer_role){
			set_sales_channel_array([])
			set_selected_price_type([])
			set_preferred_price_type([])
		}else{
			if(!(sales_channel_array && sales_channel_array.length)){
				set_sales_channel_array(orignal_sales_channel.filter(x => x.type === 'default').length ? [orignal_sales_channel.filter(x => x.type === 'default')[0]] : [])
			}
		}
	},[is_designer_role])

	useEffect(() => {
		if(first_name && open){
			if(last_name){
				var x = first_name + ' ' + last_name;
			}else{
				var x = first_name;
			}
			set_name(x);
		}
	},[last_name,first_name])

	useEffect(() => {
		console.log("Price types printed")
		if(open && all_price_type && all_price_type.length == 1 && !active_user_details){
			console.log("Price types printed 2")
			set_selected_price_type(all_price_type)
			set_preferred_price_type(all_price_type)
		}
	}, [all_price_type, open]);

	useEffect(() => {
		if(sales_channel_array && sales_channel_array.length){
			let price_type_ids = new Set()
			sales_channel_array.forEach((single_sales_channel) => {
				if(single_sales_channel.price_types && single_sales_channel.price_types.length){
					console.log('single_sales_channel1234', single_sales_channel)
					single_sales_channel.price_types && single_sales_channel.price_types.map((single_price_type) => {price_type_ids.add(single_price_type.id)})
				}
			})
			if(selected_price_type && selected_price_type.length){
				selected_price_type.map((selected_price_type) => {price_type_ids.add(selected_price_type.id)})
			}
			let a = []
			price_type_ids.forEach((price_type_id) => {
				let found_price_type = all_price_type.find(o => o.id === price_type_id); 
				if(found_price_type) a.push(all_price_type.find(o => o.id === price_type_id));
				console.log('all price type', sales_channel_array, price_type_ids, all_price_type, all_price_type.find(o => o.id === price_type_id))
			})
			set_selected_price_type(a)
		}
		else{
			set_selected_price_type([])
		}
	}, [sales_channel_array]);

	useEffect(() => {
		if(selected_price_type && selected_price_type.length){
			if(selected_price_type.length == 1)
				set_preferred_price_type(selected_price_type)
		}
		else{
			set_preferred_price_type([])
		}
	}, [selected_price_type]);

	useEffect(() => {
		if(open){
			console.log(open)
		}
	},[open])

	useEffect(() => {
		if(active_user_details && open){
			set_sales_channel_array(active_user_details.Sales_Channels)
			set_license_type(active_user_details.active_design_usage && active_user_details.active_design_usage.license_id?'annual':'monthly')
			set_contact_no(active_user_details.contact?active_user_details.contact:'-')
			set_email(active_user_details.email?active_user_details.email:'')
			set_roles_array(active_user_details.role)
			set_selected_price_type(active_user_details.price_types)
			set_preferred_price_type(active_user_details.price_types && active_user_details.price_types.filter(x => x.preferred))
			set_selected_business_unit(active_user_details.business_unit && active_user_details.business_unit[0] ? active_user_details.business_unit[0] : '')
		}
		if(active_user_details && open){
			// var name = active_user_details.name
			// var split_name_array = name.split(' ');
			// var f_name= split_name_array && split_name_array[0]?split_name_array[0]:'';
			// var l_name= split_name_array && split_name_array.length>1 ? split_name_array[split_name_array.length-1]: '';
			set_first_name(active_user_details.first_name)
			set_last_name(active_user_details.last_name)
		}

	},[active_user_details])

	useEffect(() => {
		if(all_users_accounts && open && open_selection_modal){
			set_sales_channel_array(chosen_user.Sales_Channels)
			set_last_name(chosen_user.last_name)
			set_contact_no(chosen_user.contact?chosen_user.contac:'-')
			set_email(chosen_user.email?chosen_user.email:'')
			set_roles_array(chosen_user.role)
			set_selected_price_type(chosen_user.price_types)
			set_preferred_price_type(chosen_user.price_types && chosen_user.price_types.filter(x => x.preferred))
			set_selected_business_unit(chosen_user.business_unit && chosen_user.business_unit[0] ? chosen_user.business_unit[0] : '')
			set_first_name(chosen_user.first_name)
			set_last_name(chosen_user.last_name)
		}
	},[chosen_user])

	const reset_all_sate = () => {
		set_is_designer_role(false)
		set_first_name('')
		set_last_name('')
		set_name('')
		set_email('')
		set_contact_no('')
		set_license_type('monthly')
		set_roles_array([])
		set_roles_array([])
		set_preferred_price_type([]);
		set_sales_channel_array([])
		set_active_user_details('')
		set_selected_price_type([])
		set_selected_business_unit('')
		set_chosen_user('')
	}

	const onclick_handleclose = () => {
		handleclose()
		reset_all_sate();
	}

	const onselect_multi_roles = (selectedList) => {
		console.log(selectedList)
		set_roles_array(selectedList)
	}

	const onselect_multi_sales = (selectedList) => {
		console.log(selectedList)
		set_sales_channel_array(selectedList)
	}
	const onselect_price_type = (selectedList) => {
		set_selected_price_type(selectedList)
		set_preferred_price_type([])	
	}

	const onselect_preferred_price_type = (selectedList) => {		
		set_preferred_price_type(selectedList)	
	}

	const validate_email = (email_temp) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email_temp);
    }

	const add_user = async () => {
		if(first_name.trim() && email && (org_bu_view == 'org' || (org_bu_view == 'bu' && roles_array.length))){
			try {
				var price_type_ids = selected_price_type && selected_price_type.length?selected_price_type.map(x => x.id):[]
				var roles = roles_array && roles_array.length ? roles_array.map( x => x.id):[]
				var sales = sales_channel_array && sales_channel_array.length ? sales_channel_array.map( x => x.id):[]
				var license_id = license_type==='annual' && all_license && all_license.length?all_license[0]:'';
				if(!validate_email(email)){
					alert1.error('Please enter a valid email id.')
					return;
				}
				if(roles.includes('designer') && ((price_type_ids && !price_type_ids.length) || (preferred_price_type && !preferred_price_type.length))){
					alert1.error('For designer role, price type and price preference type is mandatory')
					return;
				}
				set_page_loader({show:true, text: 'Adding User...'})
				var body = {username :'', email:email.toLowerCase(), first_name: first_name, last_name: last_name, contact:contact_no}
				
				const add_new_user_to_bu = async() => {
					let body_fn = {username :'', email:email.toLowerCase(), first_name: first_name, last_name: last_name, contact:contact_no}
					body_fn['store_roles'] = (business_units && business_units.length === 1) ? roles.filter(x => x != 'designer') : []
					// customer success store
					if(user.store_id === '1522492495145'){ 
						body_fn['store_roles'].push('customer_success_executive')
					}
					body_fn['business_unit_info'] = [
						{
							business_unit_id: user.current_business_unit_id,
							role_ids : roles,
							sales_channel_ids: sales,
							price_type_ids: price_type_ids,
							preferred_price_type_id: preferred_price_type && preferred_price_type.length?preferred_price_type[0].id:''
						}
					]
					let resp = await general_fetch({ url: 'store/add_user' , body:body_fn});
					onclick_handleclose();
					alert1.success('User successfully Added')
					fetch_account_details();

				}

				const add_existing_user_to_bu = async(user_id) => {
					let body_fn = {}
					body_fn['business_unit_id'] = user.current_business_unit_id
					body_fn['user_id_to_add'] = user_id
					body_fn['role_ids']  = roles
					body_fn['sales_channel_ids'] = sales
					body_fn['price_type_ids'] = price_type_ids
					body_fn['preferred_price_type_id'] = preferred_price_type && preferred_price_type.length?preferred_price_type[0].id:''
					let response = await general_fetch({ url: 'business_unit/add_user', body:body_fn});
					onclick_handleclose();
					alert1.success('User successfully Added')
					fetch_account_details();


				}
				
				if(org_bu_view === 'bu' && user && user.current_business_unit_id){
					// Can only add existing users in BU. Change in Requirements
					// body['business_unit_id'] = user.current_business_unit_id
					// body['role_ids']  = roles
					// body['sales_channel_ids'] = sales
					// body['price_type_ids'] = price_type_ids
					// body['preferred_price_type_id'] = preferred_price_type && preferred_price_type.length?preferred_price_type[0].id:''
					
					// let response = await general_fetch({ url: 'business_unit/add_user', body, req_resp:true });

					// if(response && response.code === 'USER_NOT_SIGNED_UP'){
					// 	await add_new_user_to_bu()
					// }else if(response && response.action === 'TOAST_ERROR'){
					// 	alert1.error(response.message)
					// }else{
					// 	if(response && response.response_code != -1){
					// 		submit_for_delete_confirm(async()=>{ await add_existing_user_to_bu(response.data && response.data.id)}, ()=>{},`User alerady exists in the Org with the name ${response && response.first_name ? response.first_name : ''} ${response && response.last_name ? response.last_name : ''} and Contact ${response && response.contact}. These values will not be overridden. .`, '' , 'Proceed' , CustomCard)
					// 	}else{
					// 		throw new Error('Failed to add user')
					// 	}
					// }
					if(open_selection_modal){
						if(chosen_user && chosen_user.id){
							await add_existing_user_to_bu(chosen_user.id)
						}else{
							alert.error("Select a user to add")
						}
					}else{
						await add_new_user_to_bu()
					}

				}

				if(org_bu_view == 'org'){
					body['business_unit_info'] = []
					body['store_roles'] = roles
					// customer success store
					if(user.store_id === '1522492495145'){
						body['store_roles'].push('customer_success_executive')
					}
					body['sales_channel_ids'] = sales
					body['price_type_ids'] = price_type_ids
					body['preferred_price_type_id'] = preferred_price_type && preferred_price_type.length?preferred_price_type[0].id:''
					// set_page_loader({show:true, text: 'Adding User...'})
					let reponse = await general_fetch({ url: 'store/add_user' , body });
					// set_page_loader({show:false})
					onclick_handleclose();
					alert1.success('User successfully Added')
					fetch_account_details();
				}
				set_page_loader({show:false})

			} catch(err) {
				set_page_loader({show: false})
				console.log(err);
				alert1.error(err && err.resp && err.resp.message ?err.resp.message:'Api Failed')
			}
		}else{
			alert1.error('Please enter details')
		}
	}

	// {
	// 	"new_user_id": 36,
	// 	"new_name": "new_name",
	// 	"new_contact": "9999999999",
	// 	"new_roles": ["designer", "catalog_manager", "accounts_manager"],
	// 	"new_sales_channel_ids": ["9093819059355947331", "fb0cf63fe586e5b3"]
	// }
	const update_user = async () => {
		if(active_user_details && first_name.trim() && email && (org_bu_view == 'org' || (org_bu_view == 'bu' && roles_array.length))){
			try {
				var price_type_ids = selected_price_type && selected_price_type.length?selected_price_type.map(x => x.id):[]
				var roles = roles_array && roles_array.length ? roles_array.map( x => x.id):[]
				var sales = is_designer_role? (sales_channel_array && sales_channel_array.length ? sales_channel_array.map( x => x.id):[]):[]

				if(roles.includes('designer') && ((price_type_ids && !price_type_ids.length) || (preferred_price_type && !preferred_price_type.length))){
					alert1.error('For designer role, price type and price preference type is mandatory')
					return;
				}
				set_page_loader({show:true, text: 'Updating User...'})
				var body = {user_id_to_update :active_user_details.id, first_name: first_name, last_name: last_name, contact:contact_no, sales_channel_ids: [], price_type_ids: [], preferred_price_type_id: null}
				
				// if(org_bu_view === 'bu' && user && user.current_business_unit_id){
				// 	body['store_roles'] = all_user_details_json[active_user_details.id]['store_roles']
				// 	// let bu_details = JSON.parse(JSON.stringify(all_user_details_json[active_user_details.id]['business_unit_ids_and_roles']))
				// 	// let cur_bu_idx = bu_details.findIndex(o => o['business_unit_id'] == active_user_details.id)
				// 	body['business_unit_info'] = [
				// 		{
				// 			business_unit_id: user.current_business_unit_id,
				// 			role_ids : roles,
				// 			sales_channel_ids: sales,
				// 			price_type_ids: price_type_ids,
				// 			preferred_price_type_id: preferred_price_type && preferred_price_type.length?preferred_price_type[0].id:''
				// 		}
				// 	]
				// 	// body['sales_channel_ids'] = all_user_details_json[active_user_details.id]['sales_channel_ids']
				// }
				// if(org_bu_view == 'org'){
				// 	body['business_unit_info'] = all_user_details_json[active_user_details.id]['business_unit_ids_and_roles']
				// 	body['store_roles'] = roles
				// 	body['sales_channel_ids'] = sales
				// }
				// set_page_loader({show: true, text:'Please wait...'})
				// var reponse = await general_fetch({ url: 'store/update_user' , body });
				// set_page_loader({show: false})

				if(org_bu_view === 'bu'){
					if(business_units && (business_units.length === 1)){
						body['store_roles'] = roles && roles.length ? roles.filter(x => (x != 'designer' || x != 'customer_success_executive')) : []
						// customer success store
						if(user.store_id === '1522492495145'){
							body['store_roles'].push('customer_success_executive')
						}
						body['business_unit_info'] = [
							{
								business_unit_id: user.current_business_unit_id,
								role_ids : roles,
								sales_channel_ids: sales,
								price_type_ids: price_type_ids,
								preferred_price_type_id: preferred_price_type && preferred_price_type.length?preferred_price_type[0].id:''
							}
						]
						body['update_bu_info'] = true
						var reponse = await general_fetch({ url: 'store/update_user' , body });

					}else{
						// body['store_roles'] = roles
						body['business_unit_id'] = user.current_business_unit_id
						body['role_ids'] = roles
						body['sales_channel_ids'] = sales
						body['price_type_ids'] = price_type_ids
						body['preferred_price_type_id'] = preferred_price_type && preferred_price_type.length ? preferred_price_type[0].id : ''
						var reponse = await general_fetch({ url: 'business_unit/update_user' , body });
					}
				}
				else if(org_bu_view === 'org'){
					body['store_roles'] = roles && roles.length ? roles.filter(x => (x != 'designer' || x != 'customer_success_executive')) : []
					// customer success store
					if(user.store_id === '1522492495145'){
						body['store_roles'].push('customer_success_executive')
					}
					body['update_bu_info'] = false
					var reponse = await general_fetch({ url: 'store/update_user' , body });
				}

				onclick_handleclose();
				alert1.success('User successfully Updated')
				set_page_loader({show:false})
				fetch_account_details();
				get_users()
			} catch(err) {
				set_page_loader({show: false})
				alert1.error('Api failed')
				console.log(err);
			}
		}else{
			alert1.error('Please enter details')
		}
	}

	return(
		<Modal className="Margin_64px_top" size="md" isOpen={open} toggle={onclick_handleclose}>
			<Card className='global_modal_height_for_mdlgxl'>
				<CardHeader className='global_modal_header'>
						<span>
							<span>{mode==='add'?'Add User':'Edit User'}</span>
						</span>
						<i id='close_add_edit_user_modal' onClick={onclick_handleclose} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body'>
					{open_selection_modal?
					<Row style={{marginBottom:"10px"}}>
						<Col className = 'mx-auto' xs={6}>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select User</div>
								<SingleSelect
								options={all_users_accounts.filter(x => ((Array.isArray(x.business_unit_info) && x.business_unit_info.length==0) || (x.business_unit_info && Object.keys(x.business_unit_info) && Object.keys(x.business_unit_info).length && !(Object.keys(x.business_unit_info).includes(user.current_business_unit_id)) && x["email"])))/*.filter(user => !(user.role.length == 1 && user.role[0] == "mes_user"))*/}
								selectedValue = {chosen_user}
								placeholder = {'Select the User to add'}
								onItemClick = {set_chosen_user}
								displayValue = {'email'}/>
							</div>
						</Col>
					</Row>:''}
					<Row style={{marginBottom:"10px"}}>
						<Col xs={6}>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>First Name *</div>
								<Input id='first_name_input' className={`${((mode==='edit' && all_bu_in_store && all_bu_in_store.length > 1 && org_bu_view == 'bu') || open_selection_modal) ? 'user_input_disabled' : ''}`}  disabled={((mode==='edit' && all_bu_in_store && all_bu_in_store.length > 1 && org_bu_view == 'bu') || open_selection_modal)?true:false} value = {first_name? first_name:''} onChange={(e) => set_first_name(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 -1 -1 0px rgba(67,90,111,0.20), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px', cursor: ((mode==='edit' && all_bu_in_store && all_bu_in_store.length > 1 && org_bu_view == 'bu') || open_selection_modal)?'not-allowed':'auto', opacity: ((mode==='edit' && all_bu_in_store && all_bu_in_store.length > 1 && org_bu_view == 'bu') || open_selection_modal)?0.5:1}} placeholder="First Name" type="text" />
							</div>
						</Col>
						<Col xs={6}>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Last Name</div>
								<Input id='second_name_input' className={`${((mode==='edit' && all_bu_in_store && all_bu_in_store.length > 1 && org_bu_view == 'bu') || open_selection_modal) ? 'user_input_disabled' : ''}`}  disabled={((mode==='edit' && all_bu_in_store && all_bu_in_store.length > 1 && org_bu_view == 'bu') || open_selection_modal)?true:false} value = {last_name? last_name:''} onChange={(e) => set_last_name(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 -1 -1 0px rgba(67,90,111,0.20), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px', cursor: ((mode==='edit' && all_bu_in_store && all_bu_in_store.length > 1 && org_bu_view == 'bu') || open_selection_modal)?'not-allowed':'auto', opacity: ((mode==='edit' && all_bu_in_store && all_bu_in_store.length > 1 && org_bu_view == 'bu') || open_selection_modal)?0.5:1}} placeholder="Last Name" type="text" />
							</div>
						</Col>
							
						<Col xs={6}>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Email *</div>
								<Input id='email_input' className={`${(mode==='edit' || open_selection_modal)? 'user_input_disabled' : ''}`} disabled={mode==='edit' || open_selection_modal?true:false} value = {email? email:''} onChange={mode==='add'?(e) => set_email(e.target.value):null} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: mode==='add'?'#FFFFFF':'#f9f9f9', boxShadow: 'inset 0 -1px 1px 0 rgba(67,90,111,0.20)', borderRadius: '3px', cursor: (mode==='edit' || open_selection_modal)?'not-allowed':'auto', opacity: (mode==='edit' || open_selection_modal)?0.5:1}} placeholder="Email" type="email" />
							</div>
						</Col>
							
						<Col xs={6}>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Contact Number</div>
								<Input id='contact_no_input' className={`${((mode==='edit' && all_bu_in_store && all_bu_in_store.length > 1 && org_bu_view == 'bu') || open_selection_modal) ? 'user_input_disabled' : ''}`}  disabled={((mode==='edit' && all_bu_in_store && all_bu_in_store.length > 1 && org_bu_view == 'bu') || open_selection_modal)?true:false} value = {contact_no? contact_no:''} onChange={(e) => set_contact_no(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 -1 -1 0px rgba(67,90,111,0.20), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px', cursor: ((mode==='edit' && all_bu_in_store && all_bu_in_store.length > 1 && org_bu_view == 'bu') || open_selection_modal)?'not-allowed':'auto', opacity: ((mode==='edit' && all_bu_in_store && all_bu_in_store.length > 1 && org_bu_view == 'bu') || open_selection_modal)?0.5:1}} placeholder="contact" type="text" />
							</div>
						</Col>		
					</Row>
					<Row style={{margin:"20px 0px 10px"}}>
						<Col>
							<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
								
							</div>
						</Col>
					</Row>
					{
						org_bu_view == 'org'?
						<>
							<Row>
								<Col className='flex_property'>
									<span style={{marginRight:'5px'}}>Store Access Level</span>
									{/* <span><Info_tooltip text_message='Users are assigned Access Levels to reflect their responsibilities as a part of the organisation/store. 
										When to assign which Access Level?
										Designer - For Designers needing to create, view, modify designs. This role is chargeable as per license choice
										Project Manager - For Managers needing to view designs, track progress etc. When given access to a design, they can only view it and not make any changes.
										Catalog Manager - For Administrators needing to manage catalog, sales channels,brands, tags etc
										User Account Manager - For Administrators needing to handle billing, payments, user creation and modification.
										Super Admin - For Super Administrators that have unrestricted access to all options (except editing designs).
										'/>
									</span> */}
								</Col>
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select Access Level</div>
										<Multiselect
											options={store_roles_options} 
											selectedValues={roles_array} 
											onSelect={onselect_multi_roles} 
											onRemove={onselect_multi_roles} 
											displayValue="name" // Property name 
											placeholder='Select Value'
											id='roles_for_user'
											// disable={mode==="edit" && roles_array && roles_array.length === 1 && roles_array[0].id == "mes_user"}
										/>
										{/* <Input multiple value={roles_array} onChange={(e) =>set_roles_array(e.target.value)} style={{width:'50%',height:'auto', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} type="select">
											<option value='design_viewer'>Design Viewer</option>
											<option value='designer'>Designer</option>
											<option value='project_manager'>Project Manager</option>
											<option value='catalogue_manager'>Catalogue Manager</option>
											<option value='Account Manager'>Account Manager</option>
											<option value='super_Admin>'>Super Admin</option>
											const roles_options = [{name: 'Design Viewer', id: 'design_viewer'},{name: 'Designer', id: 'designer'},{name: 'Project Manager', id: 'project'},{name: 'Account Manager', id: 'account'},{name: 'Catalogue Manager', id: 'catalogue'},{name: 'Super Admin', id: 'super_admin'}]		
										</Input>	 */}
										{/* <div style={{paddingBottom:'3px',fontSize:'12px', color:'#1070CA', letterSpacing: '-0.04px', lineHeight: '20px'}}>+ Attach another access</div> */}
									</div>
								</Col>
							</Row>
						</>:""
					}
					{
						org_bu_view=='bu'?
						<>
							<Row>
								<Col className='flex_property'>
									<span style={{marginRight:'5px'}}>Access Level</span>
									{/* <span><Info_tooltip text_message='Users are assigned Access Levels to reflect their responsibilities as a part of the organisation/store. 
										When to assign which Access Level?
										Designer - For Designers needing to create, view, modify designs. This role is chargeable as per license choice
										Project Manager - For Managers needing to view designs, track progress etc. When given access to a design, they can only view it and not make any changes.
										Catalog Manager - For Administrators needing to manage catalog, sales channels,brands, tags etc
										User Account Manager - For Administrators needing to handle billing, payments, user creation and modification.
										Super Admin - For Super Administrators that have unrestricted access to all options (except editing designs).
										'/>
									</span> */}
								</Col>
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select Access Level</div>
										<Multiselect
											options={roles_options} 
											selectedValues={roles_array} 
											onSelect={onselect_multi_roles} 
											onRemove={onselect_multi_roles} 
											displayValue="name" // Property name 
											placeholder='Select Value'
											id='roles_for_user'
											// disable={mode==="edit" && roles_array && roles_array.length === 1 && roles_array[0].id == "mes_user"}
										/>
										{/* <Input multiple value={roles_array} onChange={(e) =>set_roles_array(e.target.value)} style={{width:'50%',height:'auto', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} type="select">
											<option value='design_viewer'>Design Viewer</option>
											<option value='designer'>Designer</option>
											<option value='project_manager'>Project Manager</option>
											<option value='catalogue_manager'>Catalogue Manager</option>
											<option value='Account Manager'>Account Manager</option>
											<option value='super_Admin>'>Super Admin</option>
											const roles_options = [{name: 'Design Viewer', id: 'design_viewer'},{name: 'Designer', id: 'designer'},{name: 'Project Manager', id: 'project'},{name: 'Account Manager', id: 'account'},{name: 'Catalogue Manager', id: 'catalogue'},{name: 'Super Admin', id: 'super_admin'}]		
										</Input>	 */}
										{/* <div style={{paddingBottom:'3px',fontSize:'12px', color:'#1070CA', letterSpacing: '-0.04px', lineHeight: '20px'}}>+ Attach another access</div> */}
									</div>
								</Col>
							</Row>
						</>:''
					}
					{/* {
						business_units && business_units.length?
						<>
							<Row>
								<Col className='flex_property'>
									<span style={{marginRight:'5px'}}>Business Unit</span>
									<span><Info_tooltip text_message='Business Unit'/>
									</span>
								</Col>
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col>
									<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select Business Unit</div>
									<div id='business_unit_input' style={{width:'50%',  boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}}>
										<SingleSelect
											options={business_units}
											placeholder={"Select Business Unit"}
											onItemClick={(selectedVal) => set_selected_business_unit(selectedVal)}
											selectedValue={selected_business_unit}
											displayValue='name'
										/>
									</div>
								</Col>
							</Row>
						</>:''
					} */}
					{
						is_designer_role?(
							<>
							<Row style={{margin:"10px 0px"}}>
								<Col>
									<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
										
									</div>
								</Col>
							</Row>
							{/* <Row>
								<Col>
									<span>Licence</span>
								</Col>
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>License Type</div>
										<Input value={license_type? license_type:'monthly'} onChange={(e) => set_license_type(e.target.value)} style={{width:'50%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Select License" type="select">
											<option value='monthly'>Monthly</option>
											{all_license && all_license.length?<option value='annual'>Annual &nbsp;&nbsp;{all_license && all_license.length? all_license.length:0}</option>:''}
										</Input>
									</div>
								</Col>
							</Row>
							<Row style={{margin:"20px 0px 10px"}}>
								<Col>
									<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
										
									</div>
								</Col>
							</Row> */}
							<Row>
								<Col className='flex_property'>
									<span style={{marginRight:'5px'}}>Sales Channel</span>
									{/* <span><Info_tooltip text_message='A Sales Channel is a way to make the existing Catalogue available (or unavailable) to a designer based on certain attributes. For example - there can exist a Mumbai based sales channel where the same catalogue item can be available at a different price from let’s say Jaipur sales channel. Also, there could be certain restricted items that are only available in particular sales channels and not others.'/></span> */}
								</Col>
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select Sales Channel</div>
										<Multiselect
											options={orignal_sales_channel} 
											selectedValues={sales_channel_array} 
											onSelect={onselect_multi_sales} 
											onRemove={onselect_multi_sales} 
											displayValue="name" // Property name 
											placeholder='Choose Sales Channels'
											id='sc_for_user'
										/>
										{/* <div style={{paddingBottom:'3px',fontSize:'12px', color:'#1070CA', letterSpacing: '-0.04px', lineHeight: '20px'}}>+ Attach another Sales Channel</div> */}
									</div>
								</Col>
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select Price Types*</div>
										<Multiselect
											options={all_price_type} 
											selectedValues={selected_price_type} 
											onSelect={onselect_price_type} 
											onRemove={onselect_price_type} 
											displayValue="name" // Property name 
											placeholder='Choose Price Types'
											id='pt_for_user'
										/>
										{/* <div style={{paddingBottom:'3px',fontSize:'12px', color:'#1070CA', letterSpacing: '-0.04px', lineHeight: '20px'}}>+ Attach another Sales Channel</div> */}
									</div>
								</Col>
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select Preferred Price Type*</div>
										<Multiselect
											selectionLimit={1}
											options={selected_price_type} 
											selectedValues={preferred_price_type} 
											onSelect={onselect_preferred_price_type} 
											onRemove={onselect_preferred_price_type} 
											displayValue="name" // Property name 
											placeholder='Choose Preferred Price Type'
											id='preferred_pt_for_user'
										/>
										{/* <div style={{paddingBottom:'3px',fontSize:'12px', color:'#1070CA', letterSpacing: '-0.04px', lineHeight: '20px'}}>+ Attach another Sales Channel</div> */}
									</div>
								</Col>
							</Row>
							{/* <Row style={{margin:"10px 0px"}}>
								<Col>
									<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
										
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<span>Set Render Limits</span>
								</Col>
							</Row>
							<Row>
								<Col xs={6}>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>High Quality (1 credit per render)</div>
										<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Enter Number" type="text" />
									</div>
								</Col>
								<Col xs={6}>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Presentation (2 credits per render)</div>
										<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Enter Number" type="text" />
									</div>
								</Col>		
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col xs={6}>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Panaroma (3 credits per render)</div>
										<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Enter Number" type="text" />
									</div>
								</Col>
								<Col xs={6}>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Stereoscopic (6 credits per render)</div>
										<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Enter Number" type="text" />
									</div>
								</Col>		
							</Row> */}
							</>
						):''
					}
					{(active_user_count == licenses_purchased) ? 
					(
						<>
							<Row style={{margin:"20px 0px 10px"}}>
								<Col>
									<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}} />
								</Col>
							</Row>
							<div style={{ 
								backgroundColor: '#F0F8FF', 
								color: '#696969', 
								padding: '10px', 
								fontSize: '12px', 
								borderRadius: '5px' 
							}}>
								You currently have {active_user_count} active users, utilizing the {licenses_purchased} licenses purchased by your organization. Adding a new user will automatically trigger the purchase of an additional license. Your organization will be charged for this license at the end of their current billing period.
							</div>
						</>
					):''}
				</CardBody>

				<CardFooter className='global_modal_footer'>
					<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>
					<Button id='add_edit_user_submit_button' onClick={mode==='add'?add_user:update_user} className='blue_button' type="button">{mode=='add'?'Submit':'Update'}</Button>
				</CardFooter>
			</Card>  
		</Modal>
	);
}

const Depricate_user_modal = ({open, handleclose, active_user_details, set_active_user_details, submit_for_delete_confirm, fetch_account_details, all_users_accounts, store_details, get_store_details}) => {

	const [is_delete_designs , set_is_delete_designs] = useState('')
	const [selected_transfer_user_id , set_selected_transfer_user_id] = useState([])
	const [dummy_users , set_dummy_users] = useState([])
	const [is_master, set_is_master] = useState(false);
	const set_app_loader = useSetRecoilState(app_loader_atom)
	const alert = useAlert();

	const business_units = useContext(AllBuContext)
	const user = useContext(UserContext)
	const org_bu_view = useContext(OrgBuContext)

	const get_remove_message = (user_name, user_email) => {
		if(org_bu_view === 'bu' && (business_units && business_units.length > 1)) {
			return <div>Do you want to remove user <br/> <b>{user_name ? user_name : ''}, {user_email ? user_email : ''}</b> <br/> from the Business unit {business_units.find(bu => bu.id === user.current_business_unit_id).name} </div>
		}else{
			return <div>Do you want to remove user <br/> <b>{user_name ? user_name : ''}, {user_email ? user_email : ''}</b> <br/> from the Organization </div>
		}
	}

	useEffect(() => {
		if(all_users_accounts && all_users_accounts.length && active_user_details){
			var x_user = all_users_accounts.filter(x => !(x.id===active_user_details.id))
			set_dummy_users(x_user)
		}else{
			set_dummy_users([])
		}
	},[all_users_accounts, active_user_details])

	useEffect(() => {
		if(active_user_details && store_details){
			console.log(active_user_details.id, store_details.master_admin_id, "ids")
			if(active_user_details.id == store_details.master_admin_id){
				set_is_master(true)
				set_is_delete_designs('assign')
			}
		}
	}, [open]);

	const onclick_handleclose = () => {
		handleclose()
		set_selected_transfer_user_id([])
		set_is_delete_designs('')
		set_dummy_users([])
		set_active_user_details('')
		set_is_master(false)
	}

	const remove_user = async(type) => {
		if(is_delete_designs){
			try {
				if(selected_transfer_user_id && selected_transfer_user_id.length){
					var x_sel_id = selected_transfer_user_id.map(x=>x.id)
				}
				if(is_master){
					if(is_delete_designs==='delete'){
						var body = {remove_user_id:active_user_details.id, transfer_to_user_id:null, new_master_admin_id:x_sel_id[0]}
					}else{
						var body = {remove_user_id:active_user_details.id, transfer_to_user_id:x_sel_id[0], new_master_admin_id:x_sel_id[0]}
					}
				}
				else{
					if(is_delete_designs==='delete'){
						var body = {remove_user_id:active_user_details.id, transfer_to_user_id:null}
					}else{
						var body = {remove_user_id:active_user_details.id, transfer_to_user_id:x_sel_id[0]}
					}
				}
				set_app_loader({show:true, text:'Please wait...'});
				if(business_units && business_units.length > 1 && org_bu_view == 'bu'){
					body['business_unit_id'] = user.current_business_unit_id
					var resp = await general_fetch({ url: 'business_unit/remove_user' , body });
				}else{
					var resp = await general_fetch({ url: 'store/remove_user' , body });
				}
				set_app_loader({show:false});
				fetch_account_details()
				if(is_master){
					get_store_details()
				}
				alert.success('User removed successfully.')
				onclick_handleclose()
			} catch(err) {
				set_app_loader({show:false});
				console.log(err);
				if(!(err instanceof FetchError && err.response.action === "TOAST_ERROR")){
						alert.error("User could not be deleted. Please try again later")
				}else{
					alert.error("Something went wrong. Please try again later")
				}
				// alert.error(err && err.resp && err.resp.message?err.resp.message:'Cannot remove user')

			}
		}else{
			alert.error('Please select option')
		}
	}

	const onselect_user_id = (selectedList_1) => {
		set_selected_transfer_user_id(selectedList_1)
	}

	const onclick_radio_button = (value) => {
		set_is_delete_designs(value)
	}

	return(
		<Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={onclick_handleclose}>
			<Card className='global_modal_height_for_xs'>
				<CardHeader className='global_modal_header'>
					<span>Remove User {active_user_details ?active_user_details.first_name:''}</span>
					<i onClick={onclick_handleclose} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body' style={{overflow:'visible'}}>
					{
						is_master ?
						''
						:
						<React.Fragment>
							<Row style={{margin:0}}>
								<Col className='flex_property' style={{padding:0}}>	
									<Input value={is_delete_designs==='delete'} onClick={()=>onclick_radio_button('delete')} style={{ margin:0, marginRight:"10px", position:'unset'}} type="radio" name='radio_for_remove_use' id='radio_for_remove_use_1'/>
									<span>Remove user and delete all designs of this user ?</span>
								</Col>	
							</Row>
							<Row style={{margin:0}}>
								<Col className='flex_property' style={{padding:0}}>
									<Input value={is_delete_designs==='assign'} onClick={()=>onclick_radio_button('assign')} style={{ margin:0, marginRight:"10px", position:'unset'}} type="radio" name='radio_for_remove_use' id='radio_for_remove_use_2'/>
									<span>Remove user and transfer all designs to another user ?</span>
								</Col>	
							</Row>
						</React.Fragment>
					}
					{
						is_delete_designs==='assign' || is_master?
						<Row style={{margin:0, marginTop:'10px'}}>
							<Col style={{padding:0}}>	
								<div>{is_master ? "Select the User to who you want to transfer master admin status and designs" : "Select the User to who you want to transfer the designs" }</div>
								<Multiselect
									options={dummy_users} 
									selectedValues={selected_transfer_user_id}
									onSelect={onselect_user_id} 
									onRemove={onselect_user_id} 
									displayValue="email" // Property name 
									id='select_1'
									placeholder='Search Value'
									selectionLimit={1}
								/>  
							</Col>
						</Row>
						:''
					}
					{
						is_master ?
							<Row style={{margin:0, marginTop: '12px'}}>
								<Col className='flex_property' style={{padding:0}}>	
									<Input checked={is_delete_designs==='delete'} onClick={is_delete_designs==='delete' ? ()=>onclick_radio_button('assign') : ()=>onclick_radio_button('delete')} style={{ margin:0, marginRight:"10px", position:'unset'}} type="checkbox" name='radio_for_remove_use' id='radio_for_remove_use_1'/>
									<span>Delete the designs instead</span>
								</Col>	
							</Row>
						:''
					}
				</CardBody>

				<CardFooter className='global_modal_footer'>
					<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>
					<Button 
						onClick ={() => {onclick_handleclose();submit_for_delete_confirm(()=>remove_user(), ()=>{}, get_remove_message(active_user_details.first_name, active_user_details.email), '', 'Yes, Delete', '', 'new')}}
						className='blue_button' type="button" 
					>Done</Button> 
				</CardFooter>
			</Card>  
		</Modal>
	);
}

const Detatch_license_modal = ({open, handleclose, user}) => {

	const onclick_handleclose = () => {
		handleclose()
	}

	return(
		<Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={onclick_handleclose}>
			<Card className='global_modal_height_for_xs'>
				<CardHeader className='global_modal_header'>
					<span>Detach Annual License {user ?user.first_name:''}</span>
					<i onClick={onclick_handleclose} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body'>
					<Row style={{margin:0}}>
						<Col className='flex_property' style={{padding:0}}>
							<Input style={{ boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px',margin:0, marginRight:"10px", position:'unset'}} type="checkbox"/>
							<span>Detach Annual License and conver to Monthly License ?</span>
						</Col>	
					</Row>
					<Row style={{margin:0}}>
						<Col className='flex_property' style={{padding:0}}>	
							<Input style={{ boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px',margin:0, marginRight:"10px", position:'unset'}} type="checkbox"/>
							<span>Detach Annual License and remove 'Designer' role ?</span>
						</Col>	
					</Row>
				</CardBody>

				<CardFooter className='global_modal_footer'>
					<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>
					<Button className='blue_button' type="button" >Done</Button> 
				</CardFooter>
			</Card>  
		</Modal>
	);
}

const User_edit_Dropdown_Layout = ({initialized, active_user, User_edit_Dropdown}) => {
	const[dropdown_open, set_dropdown_open] = useState(false)
	return(
		<User_edit_Dropdown initialized={initialized} active_user={active_user} dropdown_open={dropdown_open} set_dropdown_open={set_dropdown_open} />
	)
}	

const All_users_table = ({initialized, all_users_accounts, set_all_users_accounts, User_edit_Dropdown, orignal_sales_channel, asc_order, set_asc_order, is_deleted}) => {
	
	const org_bu_view = useContext(OrgBuContext)
	const all_bu_in_store = useContext(AllBuContext)

	return(
		<table style={{height:"100%", width:'100%'}} className="text-left flex_property table_style">
			<thead style={{width:"100%"}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6', height:"40px"}}>
				<th className='th_style flex_property' style={{flex:'2'}}><span>Name <TableSortComponent items={all_users_accounts} set_items={set_all_users_accounts} asc_order={asc_order} set_asc_order={set_asc_order} sort_by_property={'first_name'} /></span></th>
				<th className='th_style flex_property' style={{flex:'2'}}><span>Email <TableSortComponent items={all_users_accounts} set_items={set_all_users_accounts} asc_order={asc_order} set_asc_order={set_asc_order} sort_by_property={'email'} /></span></th>
				<th className='th_style flex_property' style={{ flex: '3' }}><span>Access</span></th>
					{
						org_bu_view === 'org' ?
							<React.Fragment>
								{
									is_deleted ?
										<th className='th_style flex_property' style={{ flex: '3' }}><span>Deleted At</span></th>
										:
										<th className='th_style flex_property' style={{ flex: '3' }}><span>Business Units</span></th>
								}
								{/* <th className='th_style flex_property' style={{flex:'1'}}><span>License Type</span></th> */}
							</React.Fragment>
							:
							<React.Fragment>
								{/* <th className='th_style flex_property' style={{flex:'1'}}><span>License Type</span></th> */}
								<th className='th_style flex_property' style={{ flex: '3' }}><span>Sales Channel</span></th>
							</React.Fragment>
					}
				{/* <th className='th_style flex_property' style={{flex:'1', border:0}}><span>Unbilled Credits</span></th> */}
				<th className='th_style flex_property' style={{flex:'1', border:0, marginRight:'15px'}}><span></span></th>
			</tr>
			</thead>
			<tbody style={{ height:"calc(100% - 40px)", width:'100%',overflow:'auto'}}>
			{
				all_users_accounts && all_users_accounts.length ? all_users_accounts.map((user_account, idx) =>
					<tr key={idx} style={{display:'flex', margin:0}} className='tr_style display_onhover'>
						<td className='td_style flex_property' style={{ flex:'2'}}><span style={{overflowX:'auto',textTransform:'capitalize', whiteSpace:'nowrap'}}>{(user_account.first_name ? user_account.first_name : '-') + ' ' + (user_account.last_name ? user_account.last_name : '')}</span></td>
						<td className='td_style flex_property' style={{ flex:'2'}}><span style={{overflowX:'auto',textTransform:'none', whiteSpace:'nowrap'}}>{user_account.email?user_account.email:'-'}</span></td>
						<td className='td_style flex_property' style={{ flex:'3'}}>
							{
								user_account.role && user_account.role.length ? 
									user_account.role.map((rol, idx1) => (
										<span key={idx1} style={{backgroundColor:'#f9f9f9', marginRight:'5px', padding:'2px',color:'black'}}><span style={{fontSize:'12px', textTransform:'capitalize', whiteSpace:'nowrap'}}>{rol.name?rol.name:'-'}</span></span>
									)
								):'-'
							}
						</td>
						{
							org_bu_view === 'org' ?
								<React.Fragment>
									{
										is_deleted ?
											<td className='td_style flex_property' style={{ flex: '3' }}><span style={{ overflowX: 'auto', textTransform: 'none', whiteSpace: 'nowrap' }}>{user_account.updated_at ? <Moment format="DD/MM/YYYY">{user_account.updated_at}</Moment> : '-'}</span></td>
											: 
											<td className='td_style flex_property' style={{ flex: '3' }}>
												{
													user_account.business_unit_info && Object.keys(user_account.business_unit_info).length ?
														Object.keys(user_account.business_unit_info).map((bu, idx1) => (
															// all_bu_in_store && all_bu_in_store.length && all_bu_in_store.find(o => o.id === bu.id) ?
															<React.Fragment>
																{idx1 !== 0 ? <div className='mx-2'>|</div> : ''}
																<div>{(all_bu_in_store.find(o => o.id === bu)) ? (all_bu_in_store.find(o => o.id === bu)).name : 'not found'}</div>
															</React.Fragment>
																// : ''
														)
														) : '-'
												}
											</td>
									}
								</React.Fragment>
								: <React.Fragment>

									<td className='td_style flex_property' style={{ flex: '3' }}>
										{
											user_account.Sales_Channels && user_account.Sales_Channels.length ?
												user_account.Sales_Channels.map((sales, idx1) => (
													orignal_sales_channel && orignal_sales_channel.length && orignal_sales_channel.find(o => o.id === sales.id) ?
														<span key={idx1} style={{ backgroundColor: '#f9f9f9', marginRight: '5px', padding: '2px', color: 'black' }}><span style={{ fontSize: '12px', textTransform: 'capitalize', whiteSpace: 'nowrap' }}>{sales.name}</span></span> : ''
												)
												) : '-'
										}
									</td>
								</React.Fragment>
						}
						
						{/* <td className='td_style flex_property' style={{ flex:'1'}}>
							{
								user_account.role && user_account.role.filter(x => x==='designer').length ? (user_account.active_design_usage && user_account.active_design_usage.license_id?'Annual':'Monthly'):'-'
							}
						</td> */}
						
						
						{/* <td className='td_style flex_property' style={{ flex:'1'}}><span style={{marginRight:'5px'}}>0</span> <span><Info_tooltip text_message='Unbilled Credits'/></span></td> */}
						{
							User_edit_Dropdown ?
								<td className='td_style flex_property' style={{ flex:'1', justifyContent:'flex-end', marginRight:'15px', overflowX:'unset'}}>
									<User_edit_Dropdown_Layout initialized={initialized} active_user={user_account} User_edit_Dropdown={User_edit_Dropdown}/>
								</td>
							: 
							<td className='td_style flex_property' style={{ flex:'1', justifyContent:'flex-end', marginRight:'15px', overflowX:'unset'}}></td>
						}
						
					</tr>
				)
				: 
				(
					initialized ? <tr><td><span style={{display:'flex', alignItems:'center',justifyContent:'center', fontSize:'18px' }}>{is_deleted ? 'No Deleted Users' : `No User, Please add new user.`}</span></td></tr>
					:<tr><td><span style={{display:'flex', alignItems:'center',justifyContent:'center', fontSize:'18px' }}>{`Fetching ${is_deleted ? 'Deleted ' : ''}Users , Please Wait ...`}</span></td></tr>

				)
			}
			</tbody>
		</table>
	)
}

// The below components are not in use
// const Annual_users_table = ({set_show_detatch_license_modal, all_users_accounts}) => {

// 	const [all_annual_users, set_all_annual_users ] = useState([])   

// 	useEffect(() => {
// 		if(all_users_accounts && all_users_accounts.length){
// 			var x_dummy =  all_users_accounts.filter(account => (
// 				account.active_design_usage && account.active_design_usage.license_id
// 			))
// 		}
// 		set_all_annual_users(x_dummy?x_dummy:[])
// 	},[all_users_accounts])

// 	return(
// 		<table style={{height:"100%", width:'100%'}} className="text-left flex_property table_style">
// 			<thead style={{width:"100%"}}>
// 			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6', height:'40px'}}>
// 				<th className='th_style flex_property' style={{flex:'1'}}><span>Name</span></th>
// 				<th className='th_style flex_property' style={{flex:'1', border:0}}><span>License Attched on</span></th>
// 				<th className='th_style flex_property' style={{flex:'1', border:0}}><span><b>Annual Renwal Date</b></span></th>
// 				<th className='th_style flex_property' style={{flex:'1', border:0, marginRight:'15px'}}><span></span></th>
// 			</tr>
// 			</thead>
// 			<tbody style={{height:"calc(100% - 40px)", width:'100%', overflowY:'auto'}}>
// 				{
// 					all_annual_users && all_annual_users.length? 
// 						all_annual_users.map((annual_account, idx) =>
// 							<tr key={idx} style={{display:'flex'}} className='tr_style display_onhover'>
// 								<td className='td_style flex_property' style={{ flex:'1'}}>{annual_account.name ? annual_account.name:'-'}</td>
// 								<td className='td_style flex_property' style={{ flex:'1'}}>{annual_account.active_design_usage && annual_account.active_design_usage.start_date ? annual_account.active_design_usage.start_date:'-'}</td>
// 								<td className='td_style flex_property' style={{ flex:'1'}}>{annual_account.active_design_usage&&annual_account.active_design_usage.valid_till ? annual_account.active_design_usage.valid_till:'-'}</td>
// 								<td className='td_style flex_property' style={{ flex:'1', justifyContent:'flex-end', marginRight:'15px', color:'#1070CA'}}><span>Detatch License</span></td>
// 								{/* onClick={()=>set_show_detatch_license_modal(true)} */}
// 							</tr>
// 					):<tr><td><span style={{display:'flex', alignItems:'center',justifyContent:'center', fontSize:'18px' }}>No annual license purchased or attatched to any user.</span></td></tr>
// 				}
				
// 			</tbody>
// 		</table>
// 	)
// }

// const StripePaymentForm = ({open, handle_close, get_info_billing_address, address_info, quantity, gst, handleClose}) => {

// 	const promise = global.config.stripe && global.config.stripe.key? loadStripe(global.config.stripe.key):'';
// 	// const promise = loadStripe(global.config.stripe.key);

// 	return(
// 		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={handle_close}>
// 			<Elements stripe={promise}>
// 				<CheckoutForm get_info_billing_address={get_info_billing_address} handle_close={handle_close} handleClose_purchasemodal={handleClose} address_info={address_info} gst={gst} quantity={quantity} />
// 			  </Elements>
// 		</Modal>
// 	)
// }

const Billing = ({user, set_active_tab, set_active_division_id_sidebar, send_analytics_event, check_route_fn, get_users, submit_for_delete_confirm, store_details, get_store_details, set_page_loader}) => {

	const alert1 = useAlert()
	const [show_recharge_wallet_modal, set_show_recharge_wallet_modal] = useState(false)
	const [show_estimate_modal, set_show_estimate_modal] = useState(false)
	const [show_add_user_modal, set_show_add_user_modal] = useState(false)
	const [show_edit_bu_user_mapping_modal, set_show_edit_bu_user_mapping_modal] = useState(false);
	const [show_select_user_modal, set_show_select_user_modal] = useState(false)
	const [show_depricate_user_modal, set_show_depricate_user_modal] = useState(false)
	const [show_detatch_license_modal, set_show_detatch_license_modal] = useState(false)
	const [user_type, set_user_type] = useState('existing_user')
	const [transaction_type, set_transaction_type] = useState('prepaid')
	const [country , set_country] = useState('india')
	const [show_StripePaymentForm, set_show_StripePaymentForm] = useState(false)

	const [address_info , set_address_info] = useState('')
	// const [store_summary , set_store_summary] = useState('')

	const [	tabs , set_tabs ] = useState(1)
	const [	all_license , set_all_license ] = useState(1)
	const [	orignal_sales_channel , set_orignal_sales_channel ] = useState([])
	const [	all_users_accounts , set_all_users_accounts ] = useState([])
	const [	mode , set_mode ] = useState('')
	const [	active_user_details , set_active_user_details ] = useState('')

	const [search_string, set_search_string] = useState('')
	const [display_all_users_accounts, set_display_all_users_accounts] = useState([])
	const [show_buy_annual_license_modal, set_show_buy_annual_license_modal] = useState(false)
	const [all_price_type, set_all_price_type] = useState([])
	const [business_units, set_business_units] = useState([]);
	const [initialized, set_initialized] = useState(false);
	const [all_user_details_json, set_all_user_details_json] = useState({});
	const org_bu_view = useContext(OrgBuContext)
	const all_bu_in_store = useContext(AllBuContext)

	const [global_all_users, set_global_all_users] = useState([])

	const [all_deleted_users_accounts, set_all_deleted_users_accounts] = useState([]);
	const [display_all_deleted_users_accounts, set_display_all_deleted_users_accounts] = useState([]);

	let store_roles_options = [{name: 'Project Manager', id: 'project_manager'},{name: 'Account Manager', id: 'accounts_manager'},{name: 'Catalogue Manager', id: 'catalog_manager'},{name: 'MES User', id: 'mes_user'},{name: 'MES Editor', id: 'mes_editor'}]		

	// -----------------------------------
	// For BU Management in Org level
	const [all_bu_sales_channel_info, set_all_bu_sales_channel_info] = useState([]);
	// -----------------------------------

	const [ licenses_purchased, set_licenses_purchased ] = useState(0);
	const [ active_user_count, set_active_user_count ] = useState(0);

	const get_current_subscription_info = async () => {
		try {
			let resp = await general_fetch({ url: 'billing/get_current_subscription_info' });
			// console.log(`Billing: get_current_subscription_info: resp: `, resp);
			if (resp && resp.license_count) {
				set_licenses_purchased(resp.license_count);
			}
		} catch(err) {
			console.error(`Billing: get_current_subscription_info: err: `, err);
		}
	}

	useEffect(() => {
		var filter_result = [...all_users_accounts]
		var filter_result_del = [...all_deleted_users_accounts]
		console.log("filter result", 'hi')
		if(search_string && filter_result && filter_result.length){
			var search_text = search_string.toLowerCase();
			filter_result = filter_result.filter((x) => ((((x.first_name?x.first_name:'')+' '+(x.last_name?x.last_name:'')) && ((x.first_name?x.first_name:'')+' '+(x.last_name?x.last_name:'')).toLowerCase().includes(search_text)) || (x.email && x.email.toLowerCase().includes(search_text)) || (x.role && x.role.filter(r => r.name.toLowerCase().includes(search_text) ).length) || (x.Sales_Channels && x.Sales_Channels.length && x.Sales_Channels.filter(s => s.name.toLowerCase().includes(search_text)).length) ))
			filter_result_del = filter_result_del.filter((x) => ((((x.first_name?x.first_name:'')+' '+(x.last_name?x.last_name:'')) && ((x.first_name?x.first_name:'')+' '+(x.last_name?x.last_name:'')).toLowerCase().includes(search_text)) || (x.email && x.email.toLowerCase().includes(search_text)) || (x.role && x.role.filter(r => r.name.toLowerCase().includes(search_text) ).length) || (x.Sales_Channels && x.Sales_Channels.length && x.Sales_Channels.filter(s => s.name.toLowerCase().includes(search_text)).length) ))
		}
		console.log("filter result", filter_result)
		set_display_all_users_accounts(filter_result);
		set_display_all_deleted_users_accounts(filter_result_del);
	},[search_string])


	useEffect(() => {
		if(user && user.store_id){
			get_current_subscription_info()
		}
	}, [user]);
			


	const get_info_billing_address = async () => {
		try {
			var body = {store_id : user.store_id}
			var reponse = await general_fetch({ url: 'store/get_info' , body });
			set_address_info(reponse);
			console.log( 'address_info========>', reponse);
		} catch(err) {
			console.log(err);
		}
	}

	const fetch_all_pref = async () => {
		try {
			var resp = await general_fetch({ url: 'price_type/get_all_of_store'});
			set_all_price_type(resp && resp.length?resp:[])
		} catch(err) {
			console.log(err);
		}
	}

	const fetch_account_details = async () => {
		try {
			var account_details = await general_fetch({ url: 'user/get_all'});
			set_global_all_users(account_details?account_details:'')
			if(account_details && account_details.length){
				account_details = account_details.filter( account => !account.deprecated)
				if(org_bu_view == 'bu'){
					account_details = account_details.filter( account => account.business_unit_info && Object.keys(account.business_unit_info).length && Object.keys(account.business_unit_info).includes(user.current_business_unit_id))
				}
			}
			if(account_details && account_details.length){

				
				set_all_user_details_json(beautify_user_get_all(account_details))
				console.log("Alll user details json here", all_user_details_json)

				if(org_bu_view == 'bu'){
					account_details = account_details.map(account => {
						let bu_roles = account.business_unit_info[user.current_business_unit_id]
						bu_roles = bu_roles.map(x => x.role_id)
						bu_roles = roles_options_supported.filter((x) => bu_roles.includes(x.id))
						let cur_bu_sales_channels = account.Sales_Channels.filter(x => x.business_unit_id === user.current_business_unit_id)
						return {...account, role: bu_roles, Sales_Channels: cur_bu_sales_channels}
					})
				}else{
					account_details = account_details.map( account => {
						let store_roles = roles_options.filter((x) => account.role.includes(x.id) && x.id != "designer")
						let store_sales_channels = account.Sales_Channels.filter(x => !x.business_unit_id)
						let sales_channel_all = account.Sales_Channels
						return {...account, role: store_roles, Sales_Channels: store_sales_channels, Sales_Channels_All: sales_channel_all}
					})
				}
			}
			
			console.log('account_details=======>',account_details)
			set_all_users_accounts(account_details?account_details:'');
			set_display_all_users_accounts(account_details?account_details:'');
			console.log(Object.keys(account_details?account_details:''));
			let del_users_body = {}
			if(org_bu_view === 'bu'){
				del_users_body['business_unit_id'] = user.current_business_unit_id
			}
			
			var deleted_account_details = await general_fetch({ url: 'user/get_deprecated', body:del_users_body});
			// if(deleted_account_details && deleted_account_details.length){
			// 	var deleted_account_details = deleted_account_details.filter( account => !account.deprecated)
			// }
			if(deleted_account_details && deleted_account_details.length){
				var deleted_account_details = [...deleted_account_details];
				var deleted_account_details = deleted_account_details.filter( account => 
					account.role = account.role && account.role.length ? roles_options.filter((x) => account.role.includes(x.id)):[]
				)
			}
			console.log('account_details del=======>',deleted_account_details)
			set_all_deleted_users_accounts(deleted_account_details?deleted_account_details:'');
			set_display_all_deleted_users_accounts(deleted_account_details?deleted_account_details:'');

			set_initialized(true)
		} catch(err) {
			console.log(err);
		}
	}

	const fetch_sales_channel_details = async () => {
		try {

			let resp, sales_channel_details = []
			let body = {
				"include_sales_channel_profile_info": true, 
				"include_bu_mapping": true, 
				"include_bu_info": false, 
				"include_price_type_mapping": true, 
				"include_price_type_info": true, 
				"include_user_mapping": true, 
				"include_user_info": false
			}

			if(org_bu_view === 'bu'){
				if(user && user.current_business_unit_id){
					body['business_unit_ids'] = [user.current_business_unit_id]
					resp = await general_fetch({ url: 'sales_channel/get_by_business_unit' , body})
					let owned_sc = resp && resp.sales_channels_by_business_unit && resp.sales_channels_by_business_unit[user.current_business_unit_id] ? resp.sales_channels_by_business_unit[user.current_business_unit_id].owned : []
					// let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x)) sales_channel_details.push(resp.sales_channels[x])}) : []
					let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x) && !(all_bu_in_store && all_bu_in_store.length === 1 && resp.sales_channels[x].type === 'bu_feeder')) sales_channel_details.push(resp.sales_channels[x])}) : []
				}
			}else{
				resp = await general_fetch({ url: 'sales_channel/get_of_store' , body})
				sales_channel_details = resp && resp.sales_channels && resp.sales_channels.owned ? resp.sales_channels.owned.filter(x => !x.business_unit_id) : []

				let resp_all_sc_details;
				body['business_unit_ids'] = all_bu_in_store && all_bu_in_store.length ? all_bu_in_store.map(x => x.id) : []
					resp_all_sc_details = await general_fetch({ url: 'sales_channel/get_by_business_unit' , body})
					if(resp_all_sc_details && resp_all_sc_details.sales_channels_by_business_unit){
						let all_bu_sales_channel_info_temp = {}
						for(let business_unit_id in resp_all_sc_details.sales_channels_by_business_unit){
							let sales_channel_details_bu = []
							let sc_bu = resp_all_sc_details.sales_channels_by_business_unit[business_unit_id]
							let owned_sc = sc_bu ? sc_bu.owned : []
							let t = resp_all_sc_details && resp_all_sc_details.sales_channels && Object.keys(resp_all_sc_details.sales_channels) && Object.keys(resp_all_sc_details.sales_channels).length ? Object.keys(resp_all_sc_details.sales_channels).map(x => {if(owned_sc.includes(x) && !(all_bu_in_store && all_bu_in_store.length === 1 && resp_all_sc_details.sales_channels[x].type === 'bu_feeder')) sales_channel_details_bu.push(resp_all_sc_details.sales_channels[x])}) : []
							all_bu_sales_channel_info_temp[business_unit_id] = sales_channel_details_bu
						}
						set_all_bu_sales_channel_info(all_bu_sales_channel_info_temp)
					}
					// let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x)) sales_channel_details.push(resp.sales_channels[x])}) : []
			}
			sales_channel_details = sales_channel_details.map(single_sales_channel => {
                let price_types = single_sales_channel.price_type_ids && single_sales_channel.price_type_ids.length ? single_sales_channel.price_type_ids.map(x => resp.price_types[x]) : []
                return {...single_sales_channel, price_types}
            })
			sales_channel_details = sales_channel_details.filter(x => x.type !== 'isn_feeder')
			console.log('single_sales_channel1234', sales_channel_details)
			set_orignal_sales_channel(sales_channel_details);
		} catch(err) {
			console.log(err);
			handleError(err)
		}
	}

	const fetch_business_units = async () => {
        try {
            var resp = await general_fetch({ url: 'business_unit/get_all'});
            set_business_units(resp);
            console.log('fetch_business_units', resp)
        } catch(err) {
            console.log(err);
        }
    }
	// const get_store_summary = async () => {
	// 	try {
	// 		var body = {year : 2021, month:6}
	// 		var reponse = await general_fetch({ url: 'billing/summary' , body});
	// 		set_store_summary(reponse);
	// 		console.log( 'store_summary========>', reponse);
	// 	} catch(err) {
	// 		console.log(err);
	// 	}
	// }

	useEffect(() =>{
		if(user && all_bu_in_store && all_bu_in_store.length){
			if (!address_info) {
				get_info_billing_address()
			}
			// if (!(orignal_sales_channel && orignal_sales_channel.length)) {
				fetch_sales_channel_details();
			// }
			fetch_business_units()
			fetch_account_details();
			fetch_all_pref();
			// get_store_summary()	
			set_active_tab('billing')
			// check_route_fn('billing')
			set_active_division_id_sidebar('users')
			send_analytics_event('auto', 'Billing', 'Billing', 1, window.location.href)

			if(store_details && store_details.add_ons && store_details.add_ons.active){
				let mes_lite = store_details.add_ons.active.find(x => x.id == 6) || store_details.add_ons.active.find(x => x.id == 4)
				let mes_pro = store_details.add_ons.active.find(x => x.id == 7) || store_details.add_ons.active.find(x => x.id == 1)
				
				if(!mes_lite && !mes_pro){
					roles_options = roles_options.filter(x => !["mes_user", "mes_editor"].includes(x.id))
					store_roles_options = store_roles_options.filter(x => !["mes_user", "mes_editor"].includes(x.id))
				}
				// else if(mes_lite && !mes_pro){
				// 	roles_options = roles_options.filter(x => ![ "mes_editor"].includes(x.id))
				// 	store_roles_options = store_roles_options.filter(x => ![ "mes_editor"].includes(x.id))
				// }
			}
		}

	},[user, all_bu_in_store])

	// useEffect(() => {
	// 	if(all_bu_in_store && all_bu_in_store.length){
	// 		fetch_sales_channel_details()
	// 	}
	// }, [org_bu_view, all_bu_in_store]);


	// Below functions not in use

	// const onclick_add_credit_card = () => {
	// 	set_user_type('existing_user')
	// }

	// const onclick_recharge_wallet = (type) => {
	// 	set_show_recharge_wallet_modal(true)
	// 	set_transaction_type(type);
	// }

	// const close_recharge_wallet_modal = () => {
	// 	set_show_recharge_wallet_modal(false)
	// }

	const close_estimate_modal = () => {
		set_show_estimate_modal(false)
	}

	const close_add_user_modal = () => {
		set_show_add_user_modal(false)
		set_show_select_user_modal(false)
		fetch_account_details()
	}

	const close_depricate_user_modal = () => {
		set_show_depricate_user_modal(false)
	}

	const close_show_edit_bu_user_mapping_modal = () => {
		set_show_edit_bu_user_mapping_modal(false)
	}

	const close_detatch_license_modal = () => {
		set_show_detatch_license_modal(false)
	}

	const open_buy_annual_license_modal = () => {
		set_show_buy_annual_license_modal(true)
	}
	const close_buy_annual_license_modal = () => {
		set_show_buy_annual_license_modal(false)
	}

	useEffect(() => {
		//update the user count and license count
		if(all_users_accounts && all_users_accounts.length){
			let active_user_count = all_users_accounts.filter(account => account.active && !account.internal).length
			// var licenses_purchased = all_users_accounts.filter(account => account.active_design_usage && account.active_design_usage.license_id).length
			set_active_user_count(active_user_count);
			get_current_subscription_info();
			// set_licenses_purchased(licenses_purchased)
		}
	},[ all_users_accounts ])

	const Buy_annual_license_modal = ({open, handleclose}) => {

		const onclick_handleclose = () => {
			handleclose()
		}

		return(
			<Modal className="modal-dialog-centered" size="sm" isOpen={open} toggle={onclick_handleclose}>
				<CardHeader style={{height:'56px', backgroundColor:'#FFFFFF'}}>
					<div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
						<span style={{fontSize:'18px', color:'#234361'}}>
							<span>Buy Annual License</span>
						</span>
						<span onClick={onclick_handleclose} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<i style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
						</span>
					</div>
				</CardHeader>

				<CardBody style={{height:'calc(100% - 120px)', backgroundColor:'#FFFFFF', padding:'14px 20px'}}>
					<Row>
						<Col>
							<div style={{marginBottom:"8px"}}>
								<div style={{paddingBottom:'2px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Test</div>
								<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="" type="text" />
							</div>
						</Col>	
					</Row>
					<Row>
						<Col>
							<div>
								<div style={{paddingBottom:'2px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Test</div>
								<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="" type="text" />
							</div>
						</Col>	
					</Row>
				</CardBody>

				<CardFooter style={{height:'64px', backgroundColor:'#FFFFFF'}}>
					<div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'flex-end'}} >
						<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>	
						<Button className='blue_button' type="button" >PAY</Button>
					</div>		
				</CardFooter>
			</Modal>
			
		)
	}

	const User_edit_Dropdown = ({ initialized, active_user, dropdown_open, set_dropdown_open }) => {

		const edit_user = () => {
			set_show_add_user_modal(true); 
			set_mode('edit')
			set_active_user_details(active_user?active_user:'')
		}

		const edit_bu_user_mapping = () => {
			set_show_edit_bu_user_mapping_modal(true); 
			// set_mode('edit')
			set_active_user_details(active_user?active_user:'')
		}

		const remove_user = () => {
			set_show_depricate_user_modal(true); 
			set_active_user_details(active_user?active_user:'')
		}

		// const delete_account = () => {
		// 	try {
		// 		var resp = await general_fetch({ url: 'user/delete_own_account'});
		// 		alert.success('User successfully Deleted')
		// 		//redirect to login		
		// 	} catch(err) {
		// 		alert.error('Cannot Delete Your account')
		// 		console.log(err);
		// 	}
		// }

		try {
			return (
				<Dropdown isOpen={dropdown_open} toggle={() => {set_dropdown_open(!dropdown_open)}}>
					<DropdownToggle color="grey" className="btn-circle" ><span className="fa fa-bars" /></DropdownToggle>
					<DropdownMenu right>
						<DropdownItem onClick={edit_user}>Edit</DropdownItem>
						{org_bu_view === 'org' ? <DropdownItem /*disabled={active_user && active_user.role && active_user.role.length === 1 && active_user.role[0].id == "mes_user"}*/ onClick={edit_bu_user_mapping}>Edit BU Mapping</DropdownItem> : ''}
						<DropdownItem onClick={remove_user} style={{color:'red'}}>Remove</DropdownItem>
					</DropdownMenu>
				</Dropdown>     
			)
		}catch(err) {

		}
	}
	
	return(	
		<Main_Grid active_tab="billing" user={user} active_division_id='users' store_details={store_details} get_store_details={get_store_details}>
			{/* Below modals not in use. */}
			{/* <StripePaymentForm 
				open={show_StripePaymentForm} 
				handle_close={() => set_show_StripePaymentForm(!show_StripePaymentForm)}
			/> */}
			{/* <Recharge_wallet_modal 
				user={user} 
				get_info_billing_address={get_info_billing_address} 
				open={show_recharge_wallet_modal} 
				handleclose={close_recharge_wallet_modal} 
				transaction_type={transaction_type} 
				country={country} 
				set_country={set_country} 
				address_info={address_info} 
				alert1={alert1}
			/> */}
			<Estimate_modal 
				open={show_estimate_modal} 
				handleclose={close_estimate_modal} 
				transaction_type={transaction_type} 
				country={country} 
				set_country={set_country} 
				address_info={address_info}
			/>
			<Add_user_modal 
				open={show_add_user_modal} 
				open_selection_modal={show_select_user_modal} 
				active_user_details={active_user_details} 
				all_users_accounts={global_all_users} 
				user={user} 
				set_active_user_details={set_active_user_details} 
				handleclose={close_add_user_modal} 
				mode={mode} 
				country={country} 
				set_country={set_country} 
				all_license={all_license} 
				alert1={alert1} 
				orignal_sales_channel={orignal_sales_channel} 
				fetch_account_details={fetch_account_details} 
				roles_options={roles_options} 
				get_users={get_users} 
				all_price_type={all_price_type} 
				business_units={business_units} 
				all_user_details_json={all_user_details_json} 
				set_page_loader={set_page_loader} 
				store_roles_options={store_roles_options} 
				submit_for_delete_confirm={submit_for_delete_confirm}
				active_user_count={active_user_count}
				licenses_purchased={licenses_purchased}
			/>
			<EditBuUserMapping 
				open={show_edit_bu_user_mapping_modal} 
				active_user_details={active_user_details} 
				set_active_user_details={set_active_user_details} 
				handle_close={close_show_edit_bu_user_mapping_modal} 
				all_bu_sales_channel_info={all_bu_sales_channel_info} 
				all_price_type={all_price_type} 
				set_page_loader={set_page_loader} 
				fetch_account_details={fetch_account_details}
			/>
			<Depricate_user_modal 
				open={show_depricate_user_modal} 
				active_user_details={active_user_details} 
				set_active_user_details={set_active_user_details} 
				handleclose={close_depricate_user_modal} 
				submit_for_delete_confirm={submit_for_delete_confirm} 
				fetch_account_details={fetch_account_details} 
				all_license={all_license} 
				all_users_accounts={all_users_accounts} 
				store_details={store_details} 
				get_store_details={get_store_details}
			/>
			<Buy_annual_license_modal 
				open={show_buy_annual_license_modal} 
				handleclose={close_buy_annual_license_modal} 
			/>
			<Detatch_license_modal 
				open={show_detatch_license_modal} 
				handleclose={close_detatch_license_modal} 
				transaction_type={transaction_type} 
				set_country={set_country} 
				all_license={all_license}
			/>
			{
				// user type not in use, always equal to existing_user
				// user_type=='new_user'?(
				// 	<Row style={{height:'100%', margin:0}}>
				// 		<Col style={{height:'100%', padding:'20px 0px 55px'}}>
				// 			<Card style={{borderRadius: 0, height:'100%'}}>
				// 				<Row style={{padding:'10px 0px', color:'#22272e', height:'150px', margin:'40px 0px 20px 50px'}}>
				// 					<Col xs={9} style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px'}}>
				// 						<h4 style={{textAlign:'left'}}><b>Upgrade to get the complete Infurnia experience!</b></h4>
				// 						<div style={{textAlign:'left'}}>Your are currently using free Infurnia Account features. To get complete access to all business account features, please upgrade by purchasing Infurnia credits or register for postpaid billing.</div>
				// 					</Col>
				// 				</Row>
				// 				<Row style={{height:'180px', margin:'0px 0px 20px 50px'}}>
				// 					<Col xs={9} style={{height:'100%'}}>
				// 						<Card style={{borderRadius: 0, height:'100%', backgroundColor:'#f2f2f2', padding:'30px'}}>
				// 							<Row>
				// 								<Col xs={9}>
				// 									<h4 style={{textAlign:'left'}}><b>Prepaid: Purchase Infurnia credits</b></h4>
				// 									<div style={{textAlign:'left'}}>If you opt for this,  you  need to purchase  credits before you start using services. Recharge when your credit balance goes low.</div>
				// 								</Col>
				// 								<Col xs={3} style={{display:'flex', alignItems:'center'}}>
				// 									<button style={{...styles.bluebutton_style, padding:'0px 20px'}} onClick={() => onclick_recharge_wallet('prepaid')}>Purchase Credits</button>
				// 								</Col>
				// 							</Row>	
				// 						</Card>
				// 					</Col>
				// 				</Row>
				// 				<Row style={{height:'180px', margin:'0px 0px 20px 50px'}}>
				// 					<Col xs={9} style={{height:'100%'}}>
				// 						<Card style={{borderRadius: 0, height:'100%', backgroundColor:'#f2f2f2', padding:'30px'}}>
				// 							<Row>
				// 								<Col xs={9}>
				// 									<h4 style={{textAlign:'left'}}><b>Postpaid: Use a credit card</b></h4>
				// 									<div style={{textAlign:'left'}}>If you opt for this, you agree to authenticate your credit card details which will be automatically charged at the end of the month for the number of credits used.</div>
				// 								</Col>
				// 								<Col xs={3} style={{display:'flex', alignItems:'center'}}>
				// 									<button style={{...styles.bluebutton_style}} onClick={() => onclick_recharge_wallet('postpaid')}>Add Credit Card</button>
				// 								</Col>
				// 							</Row>
				// 						</Card>
				// 					</Col>
				// 				</Row>
				// 				<Row style={{height:'50px', margin:'0px 0px 20px 50px'}}>
				// 					<Col xs={9} style={{height:'100%', textAlign:'left'}}>
				// 						<span onClick={() =>set_show_estimate_modal(true)} style={{color:'#1070CA'}}><b>Estimate</b></span> <span>your spending based on your usage</span>
				// 					</Col>
				// 				</Row>
				// 			</Card>
				// 		</Col>
				// 	</Row>
				// ):
				(
					<Row style={{height:'100%', margin:0}}>
						<Col style={{height:'100%', padding:0}}>
							<div style={{fontSize:'14px', height:'40px', display:'flex', alignItems:'center', marginTop: 6, marginBottom: 6, paddingLeft: 5, fontFamily: 'Source_Sans_Pro-SemiBold' }}>
								<>User Management</>
								{/* right align */}
								<span style={{flex:1, display:'flex', justifyContent:'flex-end', fontFamily: 'Source_Sans_Pro-Semibold'}}>
									{ active_user_count } Active Users &nbsp;&nbsp;&nbsp; • &nbsp;&nbsp;&nbsp; { licenses_purchased } Licenses Purchased
								</span>
							</div>
							
							{/* <Card style={{borderRadius: 0, height:'160px' ,marginBottom:'15px', width:'100%'}}>
								<Row style={{height:'100%', margin:0, width:'100%', padding:'10px 0px'}}>
									<Col style={{fontSize:'18px', color:'#4d4d4d', height:'100%', borderRight:'1px solid #f2f4f6', textAlign:'left'}}>
										<Row>
											<Col>
												Unbilled Credits
											</Col>
										</Row>
										<Row>
											<Col xs={3} style={{padding:'10px'}}>
												<DonutChart
													data={[{
														label: 'Give you up',
														value: 25,
													},
													{
														label: 'Dont give up',
														value: 75,
														
													}]}
													height={130}
													width={150}
													colors={['#3333ff','#00ccff']}
													innerRadius={0.55}
													outerRadius={0.70}
													selectedOffset={0}
												/>
											</Col>
											<Col xs={9} style={{display:'flex', justifyContent:'center', flexDirection:'column',fontSize:'16px', whiteSpace: 'nowrap'}}>
												<Row>
													<Col style={{textAlign:'right'}}>
													<div style={{color:'#3333ff'}}>-</div>
													</Col>
													<Col>
														<span>Credits Used</span>
													</Col>
												</Row>
												<Row>
													<Col style={{textAlign:'right'}}>
													<span style={{color:'#00ccff'}}>-</span>
													</Col>
													<Col>
														<span>Credits Available</span>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col style={{fontSize:'18px', textAlign:'left', color:'#4d4d4d',height:'100%',borderRight:'1px solid #f2f4f6'}}>
										<Row>
											<Col>
												Credits Distribution
											</Col>
										</Row>
										<Row style={{width:'100%'}}>
											<Col xs={3} style={{padding:'10px'}}>
												<DonutChart
													data={[{
														label: 'Give you up',
														value: 25,
													},
													{
														label: 'Dont give up',
														value: 75,
													}]}
													height={130}
													width={150}
													colors={['#3333ff','#00ccff']}
													innerRadius={0.55}
													outerRadius={0.70}
													selectedOffset={0}
												/>
											</Col>
											<Col xs={9} style={{display:'flex', justifyContent:'center', flexDirection:'column',fontSize:'16px', width:"100%", whiteSpace: 'nowrap'}}>
												<Row>
													<Col style={{textAlign:'right'}}>
														<span style={{color:'#3333ff'}}>-</span>
													</Col>
													<Col>
														<span>on Design</span>
													</Col>
												</Row>
												<Row>
													<Col style={{textAlign:'right'}}>
														<span style={{color:'#00ccff'}}>-</span>
													</Col>
													<Col>
													<span>on Renders</span>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col style={{fontSize:'18px', textAlign:'left', color:'#4d4d4d',height:'100%'}}>
										
										<Row>
											<Col>
												<span>Licenses</span>
											</Col>
											<Col>
											<span><button style={{...styles.bluebutton_style, fontSize:'12px', backgroundColor:'white', color:'#007EF4', border:'1px solid #007EF4'}}>Buy Annual License</button></span>
											</Col>
											
										</Row>
										<Row style={{padding:'30px 0px'}}>
											<Col>
												<div style={{fontSize:'12px'}}>Monthly</div>
												<div style={{fontSize:'25px', color:'#007EF4'}}>-</div>
											</Col>
											<Col>
												<div style={{fontSize:'12px'}}>Yearly (In-use)</div>
												<div style={{fontSize:'25px', color:'#007EF4'}}>-</div>
											</Col>
											<Col>
												<div style={{fontSize:'12px'}}>Yearly (available)</div>
												<div style={{fontSize:'25px', color:'#007EF4'}}>-</div>
											</Col>
										</Row>
									</Col>
								</Row>
							</Card> */}
							
							<Card style={{borderRadius: 0, height:'calc(100% - 40px)', width:'100%'}}>
								<Tab_structure initialized={initialized} search_string={search_string} set_search_string={set_search_string} tabs={tabs} set_tabs={set_tabs} set_user_type={set_user_type} set_show_add_user_modal={set_show_add_user_modal} set_show_select_user_modal={set_show_select_user_modal} set_show_depricate_user_modal={set_show_depricate_user_modal} set_show_detatch_license_modal={set_show_detatch_license_modal} set_mode={set_mode} all_users_accounts={display_all_users_accounts} set_all_users_accounts={set_display_all_users_accounts} all_deleted_users_accounts={display_all_deleted_users_accounts} set_all_deleted_users_accounts={set_display_all_deleted_users_accounts} users_details={all_users_accounts} User_edit_Dropdown={User_edit_Dropdown} orignal_sales_channel={orignal_sales_channel}/>
							</Card>
						</Col>
					</Row>
				)
			}
		</Main_Grid>
	)
}

export default Billing;

// const styles = {
// 	header_row: {
// 		marginLeft: 0,
// 		overflowX: "auto",
// 	},
// 	table_header: {
// 		fontWeight: "bold",
// 		fontSize: 'small'
// 	},
// };

// const [open_billing_details_Dialog , set_open_billing_details_Dialog] = useState(false)
// const [open_purchase_infurnia_credits_dialog , set_open_purchase_infurnia_credits_dialog] = useState(false)
// const [open_transfer_plugin_credits_dialog , set_open_transfer_plugin_credits_dialog] = useState(false)
// const [show_order_details_modal , set_show_order_details_modal] = useState(false)
// const [active_order_details , set_active_order_details] = useState('')
// const [address_info , set_address_info] = useState([])
// const [subscription_id , set_subscription_id] = useState('')
// const [postpaid_subscription_info , set_postpaid_subscription_info] = useState('')
// const [postpaid_subscription_info_international, set_postpaid_subscription_info_international] = useState('')
// const [show_stripe_postpaid, set_show_stripe_postpaid] =useState(false)
// const [store_all_orders, set_store_all_orders] = useState('')
// const history = useHistory();


// const get_info_billing_address = async () => {
// 	try {
// 		var body = {store_id : user.store_id}
// 		var reponse = await general_fetch({ url: 'store/get_info' , body });
// 		set_address_info(reponse);
// 		console.log( 'address_info========>', reponse);
// 	} catch(err) {
// 		console.log(err);
// 	}
// }

// const get_postpaid_subscription_status = async () => {
// 	try {
// 		var reponse = await general_fetch({ url: 'store/get_postpaid_subscription_info' });
// 		set_postpaid_subscription_info(reponse);
// 		console.log( 'postpaid_subscription_info999999999999========>', reponse);
// 		return reponse;
// 	} catch(err) {
// 		console.log(err);
// 	}
// }

// const show_billing_details_Dialog = () => {
// 	set_open_billing_details_Dialog(true)
// }
// const close_billing_details_Dialog = () => {
// 	set_open_billing_details_Dialog(false)
// }

// const show_purchase_infurnia_credits_dialog = () => {
// 	set_open_purchase_infurnia_credits_dialog(true)
// }
// const close_purchase_infurnia_credits_dialog = () => {
// 	set_open_purchase_infurnia_credits_dialog(false)
// } 

// const show_transfer_plugin_credits_dialog = () => {
// 	set_open_transfer_plugin_credits_dialog(true)
// }
// const close_transfer_plugin_credits_dialog = () => {
// 	set_open_transfer_plugin_credits_dialog(false)
// }

// const open_order_details_modal = (order_details) => {
// 	if(order_details){
// 		set_show_order_details_modal(true)
// 		set_active_order_details(order_details)
// 	}
// }
// const close_order_details_modal = () => {
// 	set_show_order_details_modal(false)
// 	set_active_order_details('')
// }

// const open_show_stripe_postpaid = () => {
// 	set_show_stripe_postpaid(true)
// }

// useEffect(() => {
// 	if(user){
// 		get_info_billing_address();
// 		get_all_orders_store();
// 	}
// },[])

// useEffect(() => {
// 	if(user){
// 		get_postpaid_subscription_status();
// 	}
// },[])

// const dummy_search = (search_string) => {
// 	console.log(search_string)
// } 

// const onclick_enable_postpaid = () => {
// 	if(address_info && address_info.location){
// 		if(address_info && address_info.market=="india"){
// 			enable_Postpaid_Purchase_india()
// 		}
// 		if(address_info && address_info.market=="international"){
// 			enable_Postpaid_Purchase_international()
// 		}
// 	}else{
// 		alert('Please provide billing address')
// 	}
// }

// const get_all_orders_store = async() => {
// 	try {
// 		if(user){
// 			var reponse = await general_fetch({ url: 'order/get_all_from_store'});
// 		}	
// 		reponse && set_store_all_orders(reponse);
// 		debugger
// 		console.log( 'store_all_orders ========>', reponse);
// 	} catch(err) {
// 		console.log(err);
// 	}
// }

// const enable_Postpaid_Purchase_international = async() => {
// 	try {
// 		var body = {billing_address:address_info.location}
// 		var reponse = await general_fetch({ url: 'store/get_or_create_stripe_customer', body });	
// 		set_postpaid_subscription_info_international(reponse);
// 		console.log( 'postpaid_subscription_info_international, get_or_create_stripe_customer========>', reponse);
// 	} catch(err) {
// 		console.log(err);
// 	}
// }

// useEffect(() => {
// 	if (postpaid_subscription_info_international) {
// 		open_show_stripe_postpaid()
// 	}
// }, [postpaid_subscription_info_international])

// useEffect(() => {
// 	if(subscription_id){
// 		onclick_enable_postpaid()
// 	}
// }, [subscription_id])

// function loadScript(src) {
// 	return new Promise((resolve) => {
// 		const script = document.createElement("script");
// 		script.src = src;
// 		script.onload = () => {
// 			resolve(true);
// 		};
// 		script.onerror = () => {
// 			resolve(false);
// 		};
// 		document.body.appendChild(script);
// 	});
// }

// const create_razorpay_postpaid_subscription = async() => {
// 	try{
// 		var response = await general_fetch({ url: 'store/create_razorpay_postpaid_subscription'});
// 		response && set_subscription_id(response.subscription_id) 
// 		console.log('response_subscription_id============>', response);
// 		enable_Postpaid_Purchase_india();
// 	}catch(err){
// 		console.log('err============>', err);
// 	}
// }

// const enable_Postpaid_Purchase_india = async () => {

// 	const res = await loadScript(
// 		"https://checkout.razorpay.com/v1/checkout.js"
// 	);

// 	if (!res) {
// 		alert("Razorpay SDK failed to load. Are you online?");
// 		return;
// 	}
// 	const rzp_subscription = await get_postpaid_subscription_status();
// 	console.log("rzp_subscription232847289472894...................=======================+> ", rzp_subscription);

// 	var options = {
// 		"key": global.config.razorpay.key,
// 		"subscription_id": rzp_subscription.id,
// 		"description": "Postpaid Subscription",
// 		//"image": "/your_logo.png",
// 		"handler": function(response) {
// 		//   console.log('response_postpaid____________>', response);
// 		//     var body={razorpay_payment_id:response.razorpay_payment_id, razorpay_signature:response.razorpay_signature}
// 		//     var successResponse =  general_fetch({ url: 'store/verify_new_razorpay_postpaid_subscription', body})
// 		//     .then(rez =>{console.log('successResponse==========>', successResponse); get_info_billing_address(); })
// 		//     .catch(err=>{console.log(err)});
// 			alert("Successfully created rzp subscription");
// 		},
// 		"prefill": {
// 			"name": user.name,
// 			"email": user.email,
// 		},
// 		"notes": {},
// 		"theme": {
// 			"color": "#F37254"
// 		}
// 	  };

// 	  console.log("RZP OPTIONS=========================++++>", options);

// 	  const paymentObject = new window.Razorpay(options);
// 	paymentObject.open();

// }

// const StripePaymentForm_postpaid = ({open, handle_close}) => {

// 	const stripePromise = loadStripe(global.config.stripe.key);

// 	return(
// 		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={handle_close}>
// 			<Elements stripe={stripePromise}>
// 				<StripePostpaid customer_info={postpaid_subscription_info_international} address_info={address_info}/>
// 			</Elements>
// 		</Modal>
// 	)
// }

// const onclick_invoice = (order) => {
// 	if(order){
// 		get_invoice(order)
// 	}
// }

// const get_invoice = async(order) => {
// 	try {
// 		var body={order_id:order.id}
// 		var reponse = await general_fetch({ url: 'order/get_invoice', method:'GET'});
// 		console.log( 'order/get_invoice ========>', reponse);
// 	} catch(err) {
// 		console.log(err);
// 	}
// }

// const Billing_Card = () => (
// 	<Row>
// 	<Col xs={6}>
// 		<Row>
// 			<Col>
// 				<h3>Billing Details</h3><br />
// 				{
// 					address_info && address_info.location?
// 					(	
// 						<Card className="shadow">
// 							<CardHeader className="bg-secondary">
// 								<Row>
// 									<Col md = "4" className="text-left">
// 										<b>Billing Address</b>
// 									</Col>
// 									{
// 										address_info && address_info.market=="india"?
// 										(
// 											<Col md = "6" className="text-left">
// 												<Badge color="primary"><b>GST - </b>{address_info.gst_number}</Badge>
// 											</Col>
// 										)
// 										:null
// 									}	
// 									<Col md = "2" className="text-right">
// 										<Button className="btn-sm" color="primary" onClick = {show_billing_details_Dialog} >Edit Details</Button>			
// 									</Col>
// 								</Row>
// 							</CardHeader>
// 							<CardBody>
// 								<CardText>
// 									<Row>
// 										<Col className="text-left">
// 											{address_info.name}<br />
// 											{address_info.location.line_1}<br />
// 											{address_info.location.line_2}<br />
// 											{address_info.location.city},{address_info.location.state}<br />
// 											{address_info.location.pin_code}<br />
// 											{address_info.location.country}
// 										</Col>
// 									</Row>
// 								</CardText>	
// 							</CardBody>
// 						</Card>
// 					)
// 					:
// 					(	
// 						<Card className="shadow">
// 							<CardHeader className="bg-secondary">
// 								<Row>
// 									<Col md = "5" className="text-left">
// 										No Billing address
// 									</Col>
// 									<Col md = "5" className="text-left">
// 										No GST Number
// 									</Col>
// 									<Col md = "2" className="text-right">
// 										<Button className="btn-sm" color="primary"  onClick = {show_billing_details_Dialog} >Edit Details</Button>
// 									</Col>
// 								</Row>	
// 							</CardHeader>
// 						</Card>
// 					)
// 				}
// 			</Col>
// 		</Row>

// 		<Row>
// 			<Col>  	
// 				<h3>Licensing and Purchases</h3><br />
// 				<Row>
// 					<Col>
// 						<Card className="shadow">
// 							<CardBody>
// 								<CardText>
// 									Design Credits Balance : {address_info && address_info.design_credits_account && address_info.design_credits_account.balance ? address_info.design_credits_account.balance:'0'}<br /><br />
// 									<Button className="btn-sm" color="primary" onClick = {show_purchase_infurnia_credits_dialog}  >Purchase Design Credits</Button>	
// 								</CardText>	
// 							</CardBody>
// 						</Card>	
// 					</Col>
// 					<Col>
// 						<Card className="shadow">
// 							<CardBody>
// 								<CardText>
// 									Plugin Credits Balance : {address_info&&address_info.render_credits_account&&address_info.render_credits_account.balance ? address_info.render_credits_account.balance : null}<br /><br />
// 									<Button className="btn-sm" color="primary" onClick={show_transfer_plugin_credits_dialog} >Transfer Plugin Credits</Button>	
// 								</CardText>	
// 							</CardBody>
// 						</Card>	
// 					</Col>
// 				</Row>
// 				<br />
// 				<Row>
// 					<Col>
// 						<Card className="shadow"> 
// 							<CardBody>
// 								<CardText>
// 									{postpaid_subscription_info && postpaid_subscription_info.status == null?(<div><span>Product billing is disabled for you store </span><Button onClick={address_info && address_info.market=="india" ? create_razorpay_postpaid_subscription : enable_Postpaid_Purchase_international} className="btn-sm" color="primary">Enable Postpaid Billing</Button></div>) : ''}
// 									{postpaid_subscription_info && postpaid_subscription_info.status == 'created'?(<Button onClick={enable_Postpaid_Purchase_india} className="btn-sm" color="primary">Authenticate</Button>) : ''}
// 									{
// 										postpaid_subscription_info && (postpaid_subscription_info.status == 'authenticated'|| postpaid_subscription_info.status == 'active' )?
// 										(
// 											<div>
// 												{
// 													postpaid_subscription_info.paid_count == 0 ? (
// 														<div>1st Payment date : {new Date(postpaid_subscription_info.start_at *1000).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}</div>
// 													):(
// 														<div>
// 															<div>Next Payment date : {new Date(postpaid_subscription_info.charge_at *1000).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}</div>
// 														</div>
// 													) 
// 												}
// 												<Button className="btn-sm" color="primary"><a target='_blank' href={postpaid_subscription_info.short_url}><span style={{color:'white'}}>Change Payment Method</span></a></Button>
// 												<Button className="btn-sm" color="warning">Cancel Subscription</Button>
// 											</div>
// 										) 
// 										: ''
// 									}
// 									{postpaid_subscription_info && (postpaid_subscription_info.status == 'cancelled' || postpaid_subscription_info.status == 'complete') &&  postpaid_subscription_info.outstanding_amount!=0?(<Button className="btn-sm" color="primary">Pay Outstanding Amount{postpaid_subscription_info.outstanding_amount}</Button>) : ''}
// 									{postpaid_subscription_info && (postpaid_subscription_info.status == 'cancelled' || postpaid_subscription_info.status == 'complete') &&  postpaid_subscription_info.outstanding_amount==0?(<Button className="btn-sm" color="primary">Enable Postpaid Billing</Button>) : ''}
// 								</CardText>	
// 							</CardBody>
// 						</Card>	
// 					</Col>
// 					<Col>
// 						<Card className="shadow">
// 							<CardBody>
// 								<CardText>
// 									Your store is currently suspended<br /><br />
// 									<Button className="btn-sm" color="primary" >Activate Store</Button>	
// 								</CardText>	
// 							</CardBody>
// 						</Card>	
// 					</Col>
// 				</Row>
// 			</Col>	
// 		  </Row>
// 	  </Col>
// 	  {
// 		  store_all_orders && store_all_orders.length?
// 		  <Col xs={6}>
// 			  <tr style={{backgroundColor:'orange'}}>
// 				  <th style={{border:'1px solid black'}}>Sl.No.</th>
// 				  <th style={{border:'1px solid black'}}>ORDER ID</th>
// 				  <th style={{border:'1px solid black'}}>TYPE</th>
// 				  <th style={{border:'1px solid black'}}>STATUS</th>
// 				  <th style={{border:'1px solid black'}}>DATE</th>
// 				  <th style={{border:'1px solid black'}}>PRICE{address_info && address_info.market=="india"?' (INR)':' (USD)'}</th>
// 				  <th style={{border:'1px solid black'}}>INVOICE</th>
// 				  <th style={{border:'1px solid black'}}>Details</th>
// 			  </tr>
// 			  {
// 				  store_all_orders && store_all_orders.length && store_all_orders.map((order, index) =>(
// 					  <tr style={{backgroundColor:'pink'}}>
// 						  <td style={{border:'1px solid black'}}>{index+1}</td>
// 						  <td style={{border:'1px solid black'}}>{order.id ? order.id:''}</td>
// 						  <td style={{border:'1px solid black'}}>{order.type ? order.type:''}</td>
// 						  <td style={{border:'1px solid black'}}>{order.status ? order.status:''}</td>
// 						  <td style={{border:'1px solid black', whiteSpace:'nowrap'}}>{order.created_at ?order.created_at.toString():''}</td>
// 						  <td style={{border:'1px solid black'}}>{address_info && address_info.market=="india" ? order.indian_price:order.international_price}</td>
// 						  <td style={{border:'1px solid black'}}><Button style={{backgroundColor:'blue'}}><a href={global.config.server_path + 'order/get_invoice/' + order.id} target='blank'><i className='fa fa-file-invoice'/></a></Button></td>
// 						  <td style={{border:'1px solid black'}}><Button onClick={() => open_order_details_modal(order)} style={{backgroundColor:'blue'}}>Details</Button></td>
// 					  </tr>
// 				  ))
// 			  }
// 		  </Col> : ''
// 	  }		
// 	  </Row>				
// );

// return (
// 	<Main_Grid active_tab="Billing">
// 		<div>
// 			<OrderDetailsModal open={show_order_details_modal} handle_close={close_order_details_modal} active_order_details={active_order_details}/>
// 			<StripePaymentForm_postpaid open={show_stripe_postpaid} handle_close={() => set_show_stripe_postpaid(!show_stripe_postpaid)}/>
// 			<BillingDetailsDialog open ={open_billing_details_Dialog}  handleClose={close_billing_details_Dialog} address_info={address_info} get_info_billing_address={get_info_billing_address}/>
// 			<PurchaseInfurniaCreditsDialog loadScript={loadScript} user={user} open ={open_purchase_infurnia_credits_dialog}  handleClose={close_purchase_infurnia_credits_dialog} address_info={address_info} get_info_billing_address={get_info_billing_address} />
// 			<TransferPluginCreditsDialog user={user} open ={open_transfer_plugin_credits_dialog}  handleClose={close_transfer_plugin_credits_dialog} address_info={address_info} get_info_billing_address={get_info_billing_address}/>
// 			<Row>
// 				<div className="col">
// 					<Card className="shadow primary-container">
// 						<CardHeader className="border-0 bg-secondary">
// 							<Row style={styles.header_row} className="text-center">
// 								<Col md="6" xs="12" className="text-left">
// 									<Link
// 										className="h4 mb-0 text-uppercase d-none d-lg-inline-block"
// 										to="/"
// 									>
// 										Billing Information
// 									</Link>
// 								</Col>
// 								<Col md="6" xs="12" className="text-right">
// 								</Col>
// 							</Row>
// 						</CardHeader>
// 						<CardBody className = "bg-white">
// 							<Billing_Card />
// 						</CardBody>
// 					</Card>
// 				</div>
// 			</Row>
			
// 		</div>
// 	</Main_Grid>
// );