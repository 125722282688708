import React , { useState , useEffect, useContext }from 'react';
import { Input, CardBody, Col, Row, CardFooter, Button, Modal, Card, CardHeader, Table } from "reactstrap";
import { useAlert } from 'react-alert';
import { Stepper, Step } from 'react-form-stepper';
import { CSSTransition, TransitionGroup, SwitchTransition } from 'react-transition-group';
import Multiselect from '../../../components/Multiselect';
import general_fetch from '../../../utils/fetch';
import custom_sort from '../../../utils/custom_sort';
import Penguin_Switch from '../../../components/Penguin_Switch';

import './sku_groups.css';
import './custom_bom.css';
import { OrgBuContext } from '../../../components/Contexts/OrgBuContext';
import { UserContext } from '../../../components/Contexts/UserContext';

const blue = '#1070CA';

const Card_With_Header = ({
    children,
    header_text
}) =>  (
    <>
        <CardBody style={{ border: '1px solid #D5DAE0', borderRadius: '4px', overflowY:'visible', margin:10, padding: 10, boxShadow: '0px 0px 1px rgb(67 90 111 / 47%)' }}>
            <span style = {{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold'}}> {header_text} </span> {header_text ? <br /> : ''}
            { children }
        </CardBody>
    </>
)


const SKU_Selector = ({ 
    selected_sku_id, 
    division_level_data, 
    set_selected_sku, 
    index,
    id,
    display_header,
    set_selected_category_id
}) => {
    const [ sku_category_options, set_sku_category_options ] = useState([]);
    const [ selected_sku_category_id_list, set_selected_sku_category_id_list ] = useState(null);
    const [ sku_category_option_data, set_sku_category_option_data ] = useState({});

    const [ sku_sub_category_options, set_sku_sub_category_options ] = useState([]);
    const [ selected_sku_sub_category_id_list, set_selected_sku_sub_category_id_list ] = useState(null);

    const [ sku_group_options, set_sku_group_options ] = useState([]);
    const [ selected_sku_group_id_list, set_selected_sku_group_id_list ] = useState(null);
    const [ sku_group_option_data, set_sku_group_option_data ] = useState({});

    const [ sku_options, set_sku_options ] = useState([]);
    const [ selected_sku_id_list, set_selected_sku_id_list ] = useState(null);
    const [ sku_ancestry_data, set_sku_ancestry_data ] = useState([]);

    const [ loading_complete, set_loading_complete ] = useState(false);
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)


    const fetch_sku_groups = async sku_sub_category_id => {
		try {
            let body = { sku_sub_category_id }
            if(org_bu_view === 'bu'){
                body['business_unit_id'] = user.current_business_unit_id;
            }
			let response = await general_fetch({ 
                url: 'inventory/get_groups', 
                body
            });
			let depricated_filter_output = response && response.length ? response.filter(x => x.depricated !=1):[]
			let sku_groups_list = depricated_filter_output.length?depricated_filter_output.sort(custom_sort):[]
            console.log("sku groups ====>>> ", sku_groups_list);
            set_sku_group_options(sku_groups_list.map(x => ({
                id: x.id,
                name: x.name
            })));
            let sku_group_map = sku_groups_list.reduce((final, elem) => ({
                ...final,
                [elem.id]: elem
            }), {});
            console.log("sku groups map ====>>> ", sku_group_map);
            set_sku_group_option_data(sku_group_map);
            return 1;
		} catch(err) {
			console.log(err);
		}
    }

    const get_sku_ancestry = async (sku_id) => {
        try {
            let response = await general_fetch({
                url: 'inventory/get_sku_ancestry',
                body: { sku_id: [ sku_id ] }
            });
            let sku_ancestry = response && response.length ? response : [];
            console.log("sku ancestry ====>>> ", sku_ancestry);
            let t = response && response.length ? response.map(single_item => {
                if(single_item.sku_data && !(typeof(single_item.sku_data.length) == 'number')){
                    single_item.sku_data = [single_item.sku_data]
                }
                if(single_item.sku_category_data && !(typeof(single_item.sku_category_data.length) == 'number')){
                    single_item.sku_category_data = [single_item.sku_category_data]
                }
                if(single_item.sku_sub_category_data && !(typeof(single_item.sku_sub_category_data.length) == 'number')){
                    single_item.sku_sub_category_data = [single_item.sku_sub_category_data]
                }
                if(single_item.sku_group_data && !(typeof(single_item.sku_group_data.length) == 'number')){
                    single_item.sku_group_data = [single_item.sku_group_data]
                }
            }) : ''

            set_selected_sku_category_id_list([{
                id: sku_ancestry[0].sku_category_data[0].id,
                name: sku_ancestry[0].sku_category_data[0].name
            }]);
            set_selected_sku_sub_category_id_list([{
                id: sku_ancestry[0].sku_sub_category_data[0].id,
                name: sku_ancestry[0].sku_sub_category_data[0].name
            }]);
            set_sku_ancestry_data(sku_ancestry);
        } catch(err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (division_level_data && Object.keys(division_level_data).length) {
            console.log("division level data --===>>> ", division_level_data);
            let sku_category_list = Object.keys(division_level_data).reduce((final, elem) => ([
                ...final,
                ...division_level_data[elem]
            ]), []);
            set_sku_category_options(sku_category_list.map(x => ({
                id: x.id,
                name: x.name
            })));
            let sku_category_map = sku_category_list.reduce((final, elem) => ({
                ...final,
                [elem.id]: elem
            }), {});
            set_sku_category_option_data(sku_category_map);
            console.log("sku category map --===>>> ", sku_category_map);
        }

        if (!selected_sku_id) {
            set_loading_complete(true);
        }
    }, [ division_level_data ])


    useEffect(() => {
        if (selected_sku_category_id_list && selected_sku_category_id_list.length) {
            console.log("selected sku category id --===>>> ", selected_sku_category_id_list, sku_category_option_data);
            let sku_sub_category_list = sku_category_option_data[selected_sku_category_id_list[0].id].sku_sub_category;
            set_sku_sub_category_options(sku_sub_category_list.map(x => ({
                id: x.id,
                name: x.name
            })));
            let temp = set_selected_category_id ? set_selected_category_id(index,selected_sku_category_id_list[0].id):''
        }
    }, [ selected_sku_category_id_list ]);

    useEffect(() => {
        if (selected_sku_sub_category_id_list && selected_sku_sub_category_id_list.length) {
            console.log("selected sku sub category id --===>>> ", selected_sku_sub_category_id_list);
            // set_selected_sku_group_id_list(null)
            fetch_sku_groups(selected_sku_sub_category_id_list[0].id);
        }
    }, [ selected_sku_sub_category_id_list ]);


    useEffect(() => {
        if (selected_sku_group_id_list && selected_sku_group_id_list.length && sku_group_option_data && sku_group_option_data[selected_sku_group_id_list[0].id]) {
            console.log("selected sku group id --===>>> ", selected_sku_group_id_list, sku_group_option_data);
            let sku_list = sku_group_option_data[selected_sku_group_id_list[0].id].sku;
            set_sku_options(sku_list.map(x => ({
                id: x.id,
                name: x.name
            })));
        }
    }, [ selected_sku_group_id_list, sku_group_option_data ]);


    useEffect(() => {
        if (sku_category_options.length) {
            console.log("sku category options --===>>> ", sku_category_options);
        }
    }, [ sku_category_options ]);

    
    useEffect(() => {
        if (!selected_sku_id || loading_complete) {
            set_selected_sku(selected_sku_id_list);
        }
    }, [ selected_sku_id_list ]);


    useEffect(() =>{
        console.log('selected_sku --->>> ', selected_sku_id);
        if (selected_sku_id && division_level_data && Object.keys(division_level_data).length && !loading_complete) {
            get_sku_ancestry(selected_sku_id);
        }
    }, [ selected_sku_id, division_level_data ]);
    

    useEffect(() => {
        if (division_level_data && Object.keys(division_level_data).length && sku_ancestry_data && sku_ancestry_data.length) {
            set_loading_complete(true);
        }
    }, [ division_level_data, sku_ancestry_data ]);


    useEffect(() => {
        if (sku_ancestry_data && sku_ancestry_data.length) {
            console.log("sku_ancestry_data ====>>> selected sku sub" , sku_ancestry_data, selected_sku_group_id_list);
            set_selected_sku_group_id_list([{
                id: sku_ancestry_data[0].sku_group_data[0].id,
                name: sku_ancestry_data[0].sku_group_data[0].name
            }]);
            set_selected_sku_id_list([{
                id: sku_ancestry_data[0].sku_data[0].id,
                name: sku_ancestry_data[0].sku_data[0].name
            }]);
        }
    }, [ sku_ancestry_data]);


    try {
        const Selection_Header = () => (
            <div>
				<Row style={{margin:0,marginTop:'10px', marginBottom: '-8px', fontSize:'12px'}}>
					{/* <Col xs={1} style={{display:'flex', alignItems:'center'}}>
						Sl no.
					</Col> */}
					<Col xs={3} style={{display:'flex', alignItems:"center"}}>
						Category 
					</Col>
					<Col xs={3} style={{display:'flex', alignItems:"center"}}>
						Sub Category
					</Col>
					<Col xs={3} style={{display:'flex', alignItems:"center"}}>
						Group 
					</Col>
                    <Col xs={3} style={{display:'flex', alignItems:"center"}}>
                        SKU
                    </Col>
				</Row>
			</div>
        );

        return loading_complete ? (
            <Row style={{ width: '100%', display: 'block' }}>
                {display_header ? <Selection_Header /> : ''}
				<Row style={{margin:0,marginTop:'10px', fontSize:'14px'}}>
                    <Col xs={3} style={{display:'flex', alignItems:"center", paddingRight: id=='hardware_selector' ? '1%':''}}>
                        <Multiselect
                            style={{backgroundColor:'white', width:'227px'}}
                            options={sku_category_options && sku_category_options.length? JSON.parse(JSON.stringify(sku_category_options)):[]} 
                            selectedValues={selected_sku_category_id_list}
                            onSelect={x=>set_selected_sku_category_id_list(x)} 
                            onRemove={x=>set_selected_sku_category_id_list(x)} 
                            displayValue="name" // Property name 
                            selectionLimit={1}
                            id={'select_cat_'+ id + index}
                            placeholder='Select'
                        /> 
					</Col>
                    <Col xs={3} style={{display:'flex', alignItems:"center", paddingRight: id=='hardware_selector' ? '1%':''}}>
                        <Multiselect
                            style={{backgroundColor:'white', width:'227px'}}
                            options={sku_sub_category_options && sku_sub_category_options.length? JSON.parse(JSON.stringify(sku_sub_category_options)):[]} 
                            selectedValues={selected_sku_sub_category_id_list}
                            onSelect={x=>set_selected_sku_sub_category_id_list(x)} 
                            onRemove={x=>set_selected_sku_sub_category_id_list(x)} 
                            displayValue="name" // Property name 
                            selectionLimit={1}
                            id={'select_sub_cat_' + id + index}
                            placeholder='Select'
                        /> 
					</Col>
                    <Col xs={3} style={{display:'flex', alignItems:"center", paddingRight: id=='hardware_selector' ? '1%':''}}>
                        <Multiselect
                            style={{backgroundColor:'white', width:'227px'}}
                            options={sku_group_options && sku_group_options.length? JSON.parse(JSON.stringify(sku_group_options)):[]} 
                            selectedValues={selected_sku_group_id_list}
                            onSelect={x=>set_selected_sku_group_id_list(x)} 
                            onRemove={x=>set_selected_sku_group_id_list(x)} 
                            displayValue="name" // Property name 
                            selectionLimit={1}
                            id={'select_group_' + id + index}
                            placeholder='Select'
                        /> 
					</Col>
                    <Col xs={3} style={{display:'flex', alignItems:"center", paddingRight: id=='hardware_selector' ? '1%':''}}>
                        <Multiselect
                            style={{backgroundColor:'white', width:'227px'}}
                            options={sku_options && sku_options.length? JSON.parse(JSON.stringify(sku_options)):[]} 
                            selectedValues={selected_sku_id_list}
                            onSelect={x=>set_selected_sku_id_list(x)} 
                            onRemove={x=>set_selected_sku_id_list(x)} 
                            displayValue="name" // Property name 
                            selectionLimit={1}
                            id={'select_sku_' + id + index}
                            placeholder='Select'
                        /> 
					</Col>
                </Row>
            </Row>
        ) : "Loading - Please wait...";
    } catch(err) {
        console.error("error in SKU_Selector");
        console.error(err);
        return 'Something went wrong';
    }
}

const Option_Selection = ({ 
    core_material_options,
    set_core_material_options,
    finish_options,
    set_finish_options,
    edgeband_options,
    set_edgeband_options,
}) => {

    const [ core_material_division_data, set_core_material_division_data ] = useState({});
    const [ edge_band_division_data, set_edge_band_division_data ] = useState({});
    const [ finish_division_data, set_finish_division_data ] = useState({});
    const [ initialized, set_initialized ] = useState(false);
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)
    const alert = useAlert();

    const fetch_division_data = async () => {
        let body = {}
        if(org_bu_view == 'bu'){
            body['business_unit_id'] = user.current_business_unit_id
        }
        let response = await general_fetch({ url: 'inventory/get_all_sub_categories', body});	
        response = response.reduce((final, elem) => ({
            ...final,
            [ elem.id ]: elem
        }), {}); 
        console.log("all div data -=-->>> ", response);

        set_core_material_division_data({
            hardware: response['hardware'].sku_category.filter(x => x.sku_category_type_id == 'panel_core_hardware')
        });
        set_edge_band_division_data({
            hardware: response['hardware'].sku_category.filter(x => x.sku_category_type_id == 'edge_band_hardware')
        });
        set_finish_division_data({ 
            finish: response['finish'].sku_category
        });
    }


    const add_new_option = (type) => {
        console.log("type ====>>> ", type);
        let allowed = true;
        switch (type) {
            case 'core_material':
                core_material_options.map(x => {
                    console.log(x)
                    if (!x || x === 'unselected') {
                        allowed = false;
                    }
                });
                if (allowed) {
                    set_core_material_options([ ...core_material_options, 'unselected' ]);
                } else {
                    alert.error('Please complete core material selection');
                }
                break;
            case 'edge_band':
                edgeband_options.map(x => {
                    if (!x || x === 'unselected') {
                        allowed = false;
                    }
                });
                if (allowed) {
                    set_edgeband_options([ ...edgeband_options, 'unselected' ]);
                } else {
                    alert.error('Please complete edge band selection');
                }
                break;
            case 'finish':
                finish_options.map(x => {
                    if (!x || x === 'unselected') {
                        allowed = false;
                    }
                });
                if (allowed) {
                    set_finish_options([ ...finish_options, 'unselected' ]);
                } else {
                    alert.error('Please complete finish selection');
                }
                break;
            default:
                break;
        }
    }


    const remove_option = (type, index) => {
        switch (type) {
            case 'core_material':
                set_core_material_options([ ...core_material_options.slice(0, index), ...core_material_options.slice(index + 1) ]);
                break;
            case 'edge_band':
                set_edgeband_options([ ...edgeband_options.slice(0, index), ...edgeband_options.slice(index + 1) ]);
                break;
            case 'finish':
                set_finish_options([ ...finish_options.slice(0, index), ...finish_options.slice(index + 1) ]);
                break;
            default:
                break;
        }
    }


    const update_option = (type, index, value) => {
        switch (type) {
            case 'core_material':
                set_core_material_options([ ...core_material_options.slice(0, index), value, ...core_material_options.slice(index + 1) ]);
                break;
            case 'edge_band':
                set_edgeband_options([ ...edgeband_options.slice(0, index), value, ...edgeband_options.slice(index + 1) ]);
                break;
            case 'finish':
                set_finish_options([ ...finish_options.slice(0, index), value, ...finish_options.slice(index + 1) ]);
                break;
            default:
                break;
        }
    }



    useEffect(() => {
        if (!initialized) {
            set_initialized(true);
            fetch_division_data();
        }
    }, [])


    try {
        return (
            <>
                <Card_With_Header header_text='Core Material'>
                    <TransitionGroup
                        appear={true}
                        enter={true}
                    >
                        {core_material_options.map((x, idx) => (
                            <CSSTransition
                                key={'core_' + idx}
                                timeout={500}
                                classNames="item"
                            >
                                <Row>
                                    <Col xs={11} style={{display:'flex', alignItems:"center"}}>
                                        <SKU_Selector
                                            selected_sku_id={x?.id ?? null}
                                            division_level_data={core_material_division_data}
                                            set_selected_sku={value => update_option('core_material', idx, value && value.length ? value[0]: null)}
                                            index={idx}
                                            id="core_options"
                                            display_header={idx === 0}
                                        />
                                    </Col>
                                    <Col xs={1} style={{display:'flex', alignItems:"center"}}>
                                        <div style={{ marginTop: idx === 0 ? 25 : 12 }}>
                                            <span onClick={() => remove_option('core_material', idx)} className="fa fa-trash" style={{ color: '#1070CA', cursor: 'pointer' }} />
                                        </div>
                                    </Col>
                                </Row>         
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                    <Row style={{margin:0}}>
                        <Col style={{textAlign:'left', paddingBottom:'10px', paddingLeft:0}}>
                            <span onClick={() => add_new_option('core_material')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add +</span>
                        </Col>
                    </Row>
                </Card_With_Header>
                <Card_With_Header header_text='Edgeband Options'>
                    <TransitionGroup
                        appear={true}
                        enter={true}
                    >
                        {edgeband_options.map((x, idx) => (
                            <CSSTransition
                                key={'core_' + idx}
                                timeout={500}
                                classNames="item"
                            >
                                <Row >
                                    <Col xs={11} style={{display:'flex', alignItems:"center"}}>
                                        <SKU_Selector
                                            selected_sku_id={x?.id ?? null}
                                            division_level_data={edge_band_division_data}
                                            set_selected_sku={value => update_option('edge_band', idx, value && value.length ? value[0]: null)}
                                            index={idx}
                                            id="edgeband_options"
                                            display_header={idx === 0}
                                        />
                                    </Col>
                                    <Col xs={1} style={{display:'flex', alignItems:"center"}}>
                                        <div style={{ marginTop: idx === 0 ? 25 : 12 }}>
                                            <span onClick={() => remove_option('edge_band', idx)} className='fa fa-trash' style={{ color: '#1070CA', cursor: 'pointer' }} />
                                        </div>
                                    </Col>
                                </Row>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                    <Row style={{margin:0}}>
                        <Col style={{textAlign:'left', paddingBottom:'10px', paddingLeft:0}}>
                            <span onClick={() => add_new_option('edge_band')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add +</span>
                        </Col>
                    </Row>
                </Card_With_Header>
                <Card_With_Header header_text='Finish Options'>
                    <TransitionGroup
                        appear={true}
                        enter={true}
                    >
                        {finish_options.map((x, idx) => (
                            <CSSTransition
                                key={'core_' + idx}
                                timeout={500}
                                classNames="item"
                            >
                                <Row >
                                    <Col xs={11} style={{display:'flex', alignItems:"center"}}>
                                        <SKU_Selector
                                            selected_sku_id={x?.id ?? null}
                                            division_level_data={finish_division_data}
                                            set_selected_sku={value => update_option('finish', idx, value && value.length ? value[0]: null)}
                                            index={idx}
                                            id="finish_options"
                                            display_header={idx === 0}
                                        />
                                    </Col>
                                    <Col xs={1} style={{display:'flex', alignItems:"center"}}>
                                        <div style={{ marginTop: idx === 0 ? 25 : 12 }}>
                                            <span onClick={() => remove_option('finish', idx)} className='fa fa-trash' style={{ color: '#1070CA', cursor: 'pointer' }} />
                                        </div>
                                    </Col>
                                </Row>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                    <Row style={{margin:0}}>
                        <Col style={{textAlign:'left', paddingBottom:'10px', paddingLeft:0}}>
                            <span onClick={() => add_new_option('finish')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add +</span>
                        </Col>
                    </Row>
                </Card_With_Header>
            </>
        );
    } catch(err) {
        console.error("error in Option_Selection");
        console.error(err);
        return 'Something went wrong';
    }
}


const Custom_BOM_Modal = ({ 
    open, 
    set_open, 
    core_material_options, 
    set_core_material_options, 
    finish_options, 
    set_finish_options, 
    edgeband_options, 
    set_edgeband_options, 
    panels, 
    set_panels, 
    accessories, 
    set_accessories, 
    hardwares, 
    set_hardwares, 
    countertops, 
    set_countertops, 
    skirtings, 
    set_skirtings,
    all_division_tree_data,
    update_sku,
    hardware_types,
    set_hardware_types,
    add_hardware_types,
    update_hardware_types,
    delete_hardware_types
}) => {
    const [ current_step, set_current_step ] = useState(0);
    const [ next_step, set_next_step ] = useState(0);
    const [ previous_step, set_previous_step ] = useState(0);
    const [ accessories_division_data, set_accessories_division_data ] = useState(null);
    const [ hardwares_division_data, set_hardwares_division_data ] = useState(null);
    const [ countertops_division_data, set_countertops_division_data ] = useState(null);
    const [ skirtings_division_data, set_skirtings_division_data ] = useState(null);
    const [ hardware_category_types_data, set_hardware_category_types_data ] = useState(null);
    // const [ selected_hardware_category_ids, set_selected_hardware_category_ids ] = useState(null);
    
    const [ initialized, set_initialized ] = useState(false);
    const [ show_step_0, set_show_step_0 ] = useState(true);
    const [ show_step_1, set_show_step_1 ] = useState(false);
    const [ show_step_2, set_show_step_2 ] = useState(false);
    const [ show_step_3, set_show_step_3 ] = useState(false);
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)
    const alert = useAlert();

    const fetch_division_data = async () => {
        let body = {}
        if(org_bu_view == 'bu'){
            body['business_unit_id'] = user.current_business_unit_id
        }
        let response = await general_fetch({ url: 'inventory/get_all_sub_categories', body});	
        response = response.reduce((final, elem) => ({
            ...final,
            [ elem.id ]: elem
        }), {}); 
        console.log("all div data in  modal =-->>> ", response);

        set_accessories_division_data({
            accessory: response['accessory'].sku_category
        });
        set_hardwares_division_data({
            hardware: response['hardware'].sku_category
        });
        set_countertops_division_data({
            finish: response['finish'].sku_category.filter(x => x.sku_category_type_id === 'countertop_finish')
        });
        set_skirtings_division_data({
            hardware: response['hardware'].sku_category.filter(x => x.sku_category_type_id === 'skirting_hardware')
        });
    }

    const fetch_hardware_category_types = async() => {
        let response = await general_fetch({url:'sku_category_type/get', body:{identifiers:JSON.stringify({sku_division_id:'hardware'})}})
        set_hardware_category_types_data(response)
    }


    const step_validator = () => {
        let allowed = true;
        if (current_step === 0) {
            console.log("validating step 0", core_material_options);
            core_material_options.map(x => {
                if (!x || x === 'unselected') {
                    allowed = false;
                }
            });
            if (!allowed) {
                alert.error("Please complete core materials selection");
                return false;
            }

            finish_options.map(x => {
                if (!x || x === 'unselected') {
                    allowed = false;
                }
            });
            if (!allowed) {
                alert.error("Please complete finish selection");
                return false;
            }

            edgeband_options.map(x => {
                if (!x || x === 'unselected') {
                    allowed = false;
                }
            });
            if (!allowed) {
                alert.error("Please complete edgeband selection");
                return false;
            }
        } else if (current_step === 2) {
            accessories.map(x => {
                if (!x || !x.sku_id) {
                    allowed = false;
                }   
            });
            if (!allowed) {
                alert.error("Please complete accessories selection");
                return false;
            }
            hardwares.map(x => {
                if (!x || !x.sku_id) {
                    allowed = false;
                }
            });
            if (!allowed) {
                alert.error("Please complete hardware selection");
                return false;
            }
        } else if (current_step === 3) {
            countertops.map(x => {
                if (!x || !x.sku_id) {
                    allowed = false;
                }
            });
            if (!allowed) {
                alert.error("Please complete countertop selection");
                return false;
            }
            skirtings.map(x => {
                if (!x || !x.sku_id) {
                    allowed = false;
                }
            });
            if (!allowed) {
                alert.error("Please complete skirtings selection");
                return false;
            }
        }
        return allowed;
    }


    const proceed_to_next_step = () => {
        if (!step_validator()) {
            return;
        }
        if (current_step === 3) {
            update_sku();
        } else if (current_step < 3) {
            // set_current_step(current_step + 1);
            set_next_step(current_step + 1);
        }
    }


    const go_back_to_previous_step = () => {
        if (current_step > 0) {
            // set_current_step(current_step - 1);
            set_next_step(current_step - 1);
        }
    }


    const add_new_panel = () => {
        set_panels([ ...panels, {
            core_material: null,
            edgeband: null,
            internal_finish: null,
            external_finish: null,
            length: null,
            width: null,
            shutter: false
        }]);
    }


    const attach_new_sku = (type) => {
        console.log('attaching new skus --->>> ', type)
        switch(type) {
            case 'accessories':
                set_accessories([ ...accessories, {
                    sku_id: null,
                    quantity: null
                }]);
                break;
            case 'hardwares':
                set_hardwares([ ...hardwares, {
                    sku_id: null,
                    quantity: null,
                    length:null,
                    area:null,
                    unit:null
                }]);
                add_hardware_types('')
                break;
            case 'countertops':
                set_countertops([ ...countertops, {
                    sku_id: null,
                    length: null,
                    width: null
                }]);
                break;
            case 'skirtings':
                set_skirtings([ ...skirtings, {
                    sku_id: null,
                    length: null,
                }]);
                break;
            default:
                break;
        }
    }


    const update_attached_sku = (type, idx, value, key) => {
        switch(type) {
            case 'accessories':
                set_accessories([ ...accessories.slice(0, idx), {
                    ...accessories[idx],
                    [key]: value
                }, ...accessories.slice(idx + 1)]);
                break;
            case 'hardwares':
                set_hardwares([ ...hardwares.slice(0, idx), {
                    ...hardwares[idx],
                    [key]: value
                }, ...hardwares.slice(idx + 1)]);
                break;
            case 'countertops':
                set_countertops([ ...countertops.slice(0, idx), {
                    ...countertops[idx],
                    [key]: value
                }, ...countertops.slice(idx + 1)]);
                break;
            case 'skirtings':
                set_skirtings([ ...skirtings.slice(0, idx), {
                    ...skirtings[idx],
                    [key]: value
                }, ...skirtings.slice(idx + 1)]);
                break;
            default:
                break;
        }
    }


    const remove_attached_sku = (type, idx) => {
        switch(type) {
            case 'accessories':
                set_accessories([ ...accessories.slice(0, idx), ...accessories.slice(idx + 1)]);
                break;
            case 'hardwares':
                set_hardwares([ ...hardwares.slice(0, idx), ...hardwares.slice(idx + 1)]);
                delete_hardware_types(idx)
                break;
            case 'countertops':
                set_countertops([ ...countertops.slice(0, idx), ...countertops.slice(idx + 1)]);
                break;
            case 'skirtings':
                set_skirtings([ ...skirtings.slice(0, idx), ...skirtings.slice(idx + 1)]);
                break;
            default:
                break;
        }
    }


    const update_panel_element = (index, new_panel_element) => {
        const new_panels = [ ...panels ];
        new_panels[index] = { ...new_panels[index], ...new_panel_element };
        set_panels(new_panels);
    }


    const remove_panel_element = (index) => {
        const new_panels = [ ...panels ];
        new_panels.splice(index, 1);
        set_panels(new_panels);
    }

    // const add_selected_hardware_category_ids = (value) => {
    //     let a = [...selected_hardware_category_ids]
    //     a.push(value)
    //     set_selected_hardware_category_ids(a)
    // }

    const function_for_hardware_types = (index, value) => {
        // let a = [...selected_hardware_category_ids]
        // a[index] = value
        // set_selected_hardware_category_ids(a)

        let cat_type = (hardwares_division_data.hardware.find(o => o.id == value)).sku_category_type_id
        let temp_pricing_dimension = (hardware_category_types_data.find(o => o.id == cat_type)).pricing_dimension
        if(temp_pricing_dimension == 'per_unit_area')
            update_hardware_types(index,'area')
        else if(temp_pricing_dimension == 'per_unit_length')
            update_hardware_types(index,'length')
        else update_hardware_types(index,'unit')
        
    }

    // const delete_selected_hardware_category_ids = (index) => {
    //     let a = [...selected_hardware_category_ids];
	// 	a.splice(index,1)
	// 	set_selected_hardware_category_ids(a);
    // }

    useEffect(() => {
        console.log("core material options changed in modal --===>>> ", core_material_options);
    }, [ core_material_options ]);

    useEffect(() => {
        console.log("hardware types ===>", hardware_types, hardwares)
    },[hardware_types])

    useEffect(() => {
        if (!initialized) {
            console.log('Inside init effect', hardwares)
            set_initialized(true);
            fetch_division_data();
            fetch_hardware_category_types();
        }
    }, [])

    // useEffect(() => {
    //     console.log("entered here", selected_hardware_category_ids, hardware_category_types_data, hardwares_division_data)
    //     if(selected_hardware_category_ids && hardware_category_types_data && hardwares_division_data){
    //         let cat_type = (hardwares_division_data.hardware.find(o => o.id == selected_hardware_category_id)).sku_category_type_id
    //         let temp_pricing_dimension = (hardware_category_types_data.find(o => o.id == cat_type)).pricing_dimension
    //         if(temp_pricing_dimension == 'per_unit_area')
    //             set_hardware_type('area')
    //         else if(temp_pricing_dimension == 'per_unit_length')
    //             set_hardware_type('length')
    //         else set_hardware_type('unit')
    //     }
    // },[selected_hardware_category_ids, hardware_category_types_data, hardwares_division_data])


    try {
        return (
            <Modal className="Margin_64px_top" size="xl" isOpen={open} toggle={() => set_open(false)}>
                <Card>
                    <CardHeader className='global_modal_header'>
                        <span>
                            Custom BOM
                        </span>
                        <i style={{cursor:'pointer'}}  onClick={() => set_open(false)} className='fa fa-times'/>
                    </CardHeader>
                    <CardBody className="global_modal_body" style={{ height: '75vh' }}>
                        <Stepper
                            // steps={[{ label: 'SKU Options' }, { label: 'Panels' }, { label: 'Hardwares & Accessories' }, { label: 'Skirting & Countertops' }]}
                            activeStep={current_step}
                            connectorStateColors={true}    
                            styleConfig={{
                                activeBgColor: blue,
                                completedBgColor: blue,
                                inactiveBgColor: '#cdd0d7'
                            }}
                        >
                            <Step label="SKU Options"><span className="fa fa-stream"></span></Step>  
                            <Step label="Panels"><span className="fa fa-door-closed"></span></Step>  
                            <Step label="Accessories & Hardware"><span className="fa fa-wrench"></span></Step>  
                            <Step label="Countertops & Skirting"><span className="fa fa-list"></span></Step>  
                        </Stepper>
                        <CSSTransition
                            in={current_step === 0 && next_step === 0}
                            timeout={500}
                            classNames={next_step < previous_step ? "previous-step" : "next-step"}
                            onEnter={() => {
                                set_show_step_0(true);
                            }}
                            onEntered={() => {
                                set_previous_step(0);
                            }}
                            onExited={() => {
                                set_show_step_0(false);
                                set_current_step(next_step);
                            }}
                        >
                            <div className="global_modal_body" style={{ display: show_step_0 ? 'block' : 'none', height: 'calc(100% - 125px)', padding: 10, backgroundColor: 'white', borderRadius: 5, boxShadow: "rgb(174 174 181) 0px 0px 3px 0px" }}>
                                <span style={{ fontSize: 16, fontFamily: 'Source_Sans_Pro-SemiBold', paddingLeft: 13 }}>Manage SKU Options</span>
                                <Option_Selection
                                    all_division_tree_data={all_division_tree_data}
                                    core_material_options={core_material_options}
                                    set_core_material_options={set_core_material_options}
                                    finish_options={finish_options}
                                    set_finish_options={set_finish_options}
                                    edgeband_options={edgeband_options}
                                    set_edgeband_options={set_edgeband_options}
                                />
                            </div>
                        </CSSTransition>
                        <CSSTransition
                            in={current_step === 1 && next_step === 1}
                            timeout={500}
                            classNames={next_step < previous_step ? "previous-step" : "next-step"}
                            onEnter={() => {
                                set_show_step_1(true);
                            }}
                            onEntered={() => {
                                set_previous_step(1);
                            }}
                            onExited={() => {
                                set_show_step_1(false);
                                set_current_step(next_step);
                            }}
                        > 
                            <div className="global_modal_body" style={{ display: show_step_1 ? 'block' : 'none', height: 'calc(100% - 125px)', padding: 10, backgroundColor: 'white', borderRadius: 5, boxShadow: "rgb(174 174 181) 0px 0px 3px 0px" }}>
                                <span style={{ fontSize: 16, fontFamily: 'Source_Sans_Pro-SemiBold', paddingLeft: 13 }}>Manage Panels</span>
                                {panels.map((x, idx) => (
                                    <Card_With_Header>
                                        <Row style={{margin:5}}>
                                            <Col xs={3} style={{ padding: 5 }} className={core_material_options && core_material_options.length ? '' : 'disable_with_haziness'}>
                                                <div style={{ marginTop: 3, marginBottom: 5, fontSize: 12 }}>
                                                    Core Material
                                                </div>
                                                <Multiselect
                                                    style={{backgroundColor:'white', width:'227px'}}
                                                    options={core_material_options && core_material_options.length? JSON.parse(JSON.stringify(core_material_options.filter(x => x && x!= 'unselected'))):[]} 
                                                    selectedValues={x.core_material ? [ x.core_material ] : []}
                                                    onSelect={x => {
                                                        console.log("updating core material --> ", x)
                                                        update_panel_element(idx, {
                                                            core_material: x[0]
                                                        });
                                                    }} 
                                                    onRemove={x => {
                                                        console.log("updating core material --> ", x)
                                                        update_panel_element(idx, {
                                                            core_material: null
                                                        });
                                                    }}
                                                    displayValue="name" // Property name 
                                                    selectionLimit={1}
                                                    id={'select_core_material' + idx}
                                                    placeholder='Select'
                                                />
                                            </Col>
                                            <Col xs={3} style={{ padding: 5 }} className={finish_options && finish_options.length ? '' : 'disable_with_haziness'}>
                                                <div style={{ marginTop: 3, marginBottom: 5, fontSize: 12 }}>
                                                    Edgeband
                                                </div>
                                                <Multiselect
                                                    style={{backgroundColor:'white', width:'227px'}}
                                                    options={edgeband_options && edgeband_options.length? JSON.parse(JSON.stringify(edgeband_options.filter(x => x && x!= 'unselected'))):[]}
                                                    selectedValues={x.edgeband ? [ x.edgeband ] : []}
                                                    onSelect={x => {
                                                        console.log("updating edgeband --> ", x)
                                                        update_panel_element(idx, {
                                                            edgeband: x[0]
                                                        });
                                                    }}
                                                    onRemove={x => {
                                                        console.log("updating edgeband --> ", x)
                                                        update_panel_element(idx, {
                                                            edgeband: null
                                                        });
                                                    }}
                                                    displayValue="name" // Property name
                                                    selectionLimit={1}
                                                    id={'select_edgeband' + idx}
                                                    placeholder='Select'
                                                />
                                            </Col>
                                            <Col xs={3} style={{ padding: 5 }} className={finish_options && finish_options.length ? '' : 'disable_with_haziness'}>
                                                <div style={{ marginTop: 3, marginBottom: 5, fontSize: 12 }}>
                                                    Internal Finish
                                                </div>
                                                <Multiselect
                                                    style={{backgroundColor:'white', width:'227px'}}
                                                    options={finish_options && finish_options.length? JSON.parse(JSON.stringify(finish_options.filter(x => x && x!= 'unselected'))):[]}
                                                    selectedValues={x.internal_finish ? [ x.internal_finish ] : []}
                                                    onSelect={x => {
                                                        console.log("updating internal_finish --> ", x)
                                                        update_panel_element(idx, {
                                                            internal_finish: x[0]
                                                        });
                                                    }}
                                                    onRemove={x => {
                                                        console.log("updating internal_finish --> ", x)
                                                        update_panel_element(idx, {
                                                            internal_finish: null
                                                        });
                                                    }}
                                                    displayValue="name" // Property name
                                                    selectionLimit={1}
                                                    id={'select_internal_finish' + idx}
                                                    placeholder='Select'
                                                />
                                            </Col>
                                            <Col xs={3} style={{ padding: 5 }} className={finish_options && finish_options.length ? '' : 'disable_with_haziness'}>
                                                <div style={{ marginTop: 3, marginBottom: 5, fontSize: 12 }}>
                                                    External Finish
                                                </div>
                                                <Multiselect
                                                    style={{backgroundColor:'white', width:'227px'}}
                                                    options={finish_options && finish_options.length? JSON.parse(JSON.stringify(finish_options)):[]}
                                                    selectedValues={x.external_finish ? [ x.external_finish ] : []}
                                                    onSelect={x => {
                                                        console.log("updating external_finish --> ", x)
                                                        update_panel_element(idx, {
                                                            external_finish: x[0]
                                                        });
                                                    }}
                                                    onRemove={x => {
                                                        console.log("updating external_finish --> ", x)
                                                        update_panel_element(idx, {
                                                            external_finish: null
                                                        });
                                                    }}
                                                    displayValue="name" // Property name
                                                    selectionLimit={1}
                                                    id={'select_external_finish' + idx}
                                                    placeholder='Select'
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{margin: 5}}>
                                            <Col xs={3} style={{ padding: 5 }}>
                                                <div style={{ marginTop: 3, marginBottom: 5, fontSize: 12 }}>
                                                    Length
                                                </div>
                                                <Input
                                                    type="number"
                                                    value={x.length}
                                                    style={{ fontSize: 12 }}
                                                    onChange={(e) => {
                                                        update_panel_element(idx, { length: e.target.value });
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={3} style={{ padding: 5 }}>
                                                <div style={{ marginTop: 3, marginBottom: 5, fontSize: 12 }}>
                                                    Width
                                                </div>
                                                <Input
                                                    type="number"
                                                    value={x.width}
                                                    style={{ fontSize: 12 }}
                                                    onChange={(e) => {
                                                        update_panel_element(idx, { width: e.target.value });
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={3} style={{ padding: 5 }}>
                                                <div style={{ marginTop: 3, marginBottom: 5, fontSize: 12 }}>
                                                    Shutter 
                                                </div>
                                                <div style={{ padding: 5, paddingLeft: 0 }}>
                                                    <Penguin_Switch
                                                        checked={x.shutter}
                                                        on_change={checked => update_panel_element(idx, { shutter: checked })}
                                                        id={'shutter_switch_' + idx}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={2} style={{ padding: 5 }} />
                                            <Col xs={1} style={{ padding: 5, paddingTop: 33, textAlign: 'right' }}>
                                                <div style={{ marginTop: 3, marginBottom: 5 }}>
                                                    <span className='fa fa-trash' style={{color:'#1070CA', cursor:'pointer'}} onClick={() => remove_panel_element(idx)} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card_With_Header>
                                ))}
                                <Row style={{margin:0}}>
                                    <Col style={{textAlign:'left', paddingBottom:'10px', paddingLeft: 10}}>
                                        <span onClick={add_new_panel}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add +</span>
                                    </Col>
                                </Row>
                            </div> 
                        </CSSTransition>
                        <CSSTransition 
                            in={current_step === 2 && next_step === 2}
                            timeout={500}
                            classNames={next_step < previous_step ? "previous-step" : "next-step"}
                            onEnter={() => {
                                set_show_step_2(true);
                            }}
                            onEntered={() => {
                                set_previous_step(2);
                            }}
                            onExited={() => {
                                set_show_step_2(false);
                                set_current_step(next_step);
                            }}
                        >
                            <>
                                <div className="global_modal_body" style={{ display: show_step_2 ? 'block' : 'none', height: 'calc(100% - 125px)', padding: 10, backgroundColor: 'white', borderRadius: 5, boxShadow: "rgb(174 174 181) 0px 0px 3px 0px" }}>
                                    <span style={{ fontSize: 16, fontFamily: 'Source_Sans_Pro-SemiBold', paddingLeft: 13 }}>Manage Accessories and Hardware</span>
                                    <Card_With_Header header_text='Accessories'>
                                        {accessories.map((x, idx) => (
                                            <>
                                                <Row style={{ margin:0, marignBottom: 10 }}>
                                                    <Col xs={9} style={{ display:'flex', alignItems:"center", padding: 0 }}>
                                                        <SKU_Selector
                                                            selected_sku_id={x?.sku_id ?? null}
                                                            division_level_data={accessories_division_data}
                                                            set_selected_sku={sku => update_attached_sku('accessories', idx, sku && sku.length ? sku[0].id: null, 'sku_id')}
                                                            index={idx}
                                                            id="accessory_selector"
                                                            display_header={idx === 0}
                                                        />
                                                    </Col>
                                                    <Col style={{ padding: 0, paddingTop: idx === 0 ? 3 : 7 }} xs={2}>
                                                        {idx === 0 ? <span style={{ fontSize: 12 }}>Quantity</span> : ''}
                                                        <Input
                                                            type="number"
                                                            style={{ fontSize: 12, marginTop: 3 }}
                                                            value={x.quantity}
                                                            onChange={e => update_attached_sku('accessories', idx, e.target.value, 'quantity')}
                                                        />
                                                    </Col>
                                                    <Col xs={1} style={{ padding: 0, paddingTop: idx === 0 ? 30 : 10, textAlign: 'center' }}>
                                                        <div style={{ marginTop: 3, marginBottom: 5, padding: 0 }}>
                                                            <span className='fa fa-trash' style={{color:'#1070CA', cursor:'pointer'}} onClick={() => remove_attached_sku('accessories', idx)} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        ))}
                                        <Row style={{margin:0}}>
                                            <Col style={{textAlign:'left', paddingBottom:'10px', paddingLeft: 0 }}>
                                                <span onClick={() => attach_new_sku('accessories')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add +</span>
                                            </Col>
                                        </Row>
                                    </Card_With_Header>
                                    <Card_With_Header header_text='Hardwares'>
                                        {hardwares.map((x, idx) => (
                                            <>
                                                <Row style={{ margin:0, marignBottom: 10 }}>
                                                    <Col xs={6} style={{ display:'flex', alignItems:"center", padding: 0 }}>
                                                        <SKU_Selector
                                                            selected_sku_id={x?.sku_id ?? null}
                                                            division_level_data={hardwares_division_data}
                                                            set_selected_sku={sku => update_attached_sku('hardwares', idx, sku && sku.length ? sku[0].id: null, 'sku_id')}
                                                            index={idx}
                                                            id="hardware_selector"
                                                            display_header={idx === 0}
                                                            set_selected_category_id = {function_for_hardware_types}
                                                            key={String(x.sku_id)}
                                                        />
                                                    </Col>
                                                    <Col style={{ padding: 0, paddingTop: idx === 0 ? 3 : 7 }} xs={1}>
                                                        {idx === 0 ? <span style={{ fontSize: 12 }}>Quantity</span> : ''}
                                                        <Input
                                                            type="number"
                                                            style={{ fontSize: 12, marginTop: 3 }}
                                                            value={x.quantity?x.quantity:''}
                                                            onChange={e => update_attached_sku('hardwares', idx, e.target.value, 'quantity')}
                                                        />
                                                    </Col>
                                                    {/* {
                                                        hardware_type == 'area'?
                                                            <Col style={{ padding: 0, paddingTop: idx === 0 ? 3 : 7 }} xs={1}>
                                                                {idx === 0 ? <span style={{ fontSize: 12 }}>Area</span> : ''}
                                                                <Input
                                                                    type="number"
                                                                    style={{ fontSize: 12, marginTop: 3 }}
                                                                    value={x.area?x.area:''}
                                                                    onChange={e => update_attached_sku('hardwares', idx, e.target.value, 'area')}
                                                                />
                                                            </Col>
                                                        :(
                                                            hardware_type == 'length'?
                                                                <Col style={{ padding: 0, paddingTop: idx === 0 ? 3 : 7 }} xs={1}>
                                                                    {idx === 0 ? <span style={{ fontSize: 12 }}>Length</span> : ''}
                                                                    <Input
                                                                        type="number"
                                                                        style={{ fontSize: 12, marginTop: 3 }}
                                                                        value={x.length?x.length:''}
                                                                        onChange={e => update_attached_sku('hardwares', idx, e.target.value, 'length')}
                                                                    />
                                                                </Col>
                                                            :''
                                                            
                                                        )
                                                    } */}
                                                    <Col style={{ padding: 0, paddingTop: idx === 0 ? 3 : 7}} xs={2}>
                                                        {idx === 0 ? <span style={{ fontSize: 12, marginLeft:'10%' }}>Length</span> : ''}
                                                        <div style={{display:'flex'}}>
                                                            <Input
                                                                type="number"
                                                                style={{ fontSize: 12, marginTop: 3, marginLeft:'10%',  width:'50%' }}
                                                                value={x.length?x.length:''}
                                                                onChange={e => update_attached_sku('hardwares', idx, e.target.value, 'length')}
                                                                disabled = {(hardware_types[idx]=='length' || x.length != null)?false:true}
                                                            />
                                                            {
                                                                <Input 
                                                                    type = 'select'
                                                                    style={{ fontSize: 12, marginTop: 3,  width:'50%'}}
                                                                    value={x.unit?x.unit:''}
                                                                    onChange={e => update_attached_sku('hardwares', idx, e.target.value, 'unit')}
                                                                    disabled = {(hardware_types[idx]=='length' || x.length != null)?false:true}
                                                                >
                                                                <option value='millimetre'>mm</option>
                                                                <option value='foot'>ft</option>
                                                                </Input>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col style={{ padding: 0, paddingTop: idx === 0 ? 3 : 7 }} xs={2}>
                                                        {idx === 0 ? <span style={{ fontSize: 12, marginLeft:'10%' }}>Area</span> : ''}
                                                        <div style={{display:'flex'}}>
                                                            <Input
                                                                type="number"
                                                                style={{ fontSize: 12, marginTop: 3, marginLeft:'10%', width:'50%'}}
                                                                value={x.area?x.area:''}
                                                                onChange={e => update_attached_sku('hardwares', idx, e.target.value, 'area')}
                                                                disabled = {(hardware_types[idx]=='area' || x.area != null)?false:true}
                                                            />
                                                            {
                                                                <Input 
                                                                    type = 'select'
                                                                    style={{ fontSize: 12, marginTop: 3, width:'50%', paddingRight:'1%'}}
                                                                    value={x.unit?x.unit:''}
                                                                    onChange={e => update_attached_sku('hardwares', idx, e.target.value, 'unit')}
                                                                    disabled = {(hardware_types[idx]=='area' || x.area != null)?false:true}
                                                                >
                                                                <option value='sq_millimetre'>sq_mm</option>
                                                                <option value='sq_foot'>sq_ft</option>
                                                                </Input>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col xs={1} style={{ padding: 0, paddingTop: idx === 0 ? 30 : 10, paddingLeft:'4%' }}>
                                                        <div style={{ marginTop: 3, marginBottom: 5 }}>
                                                            <span className='fa fa-trash' style={{color:'#1070CA', cursor:'pointer'}} onClick={() => remove_attached_sku('hardwares', idx)} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        ))}
                                        <Row style={{margin: 0}}>
                                            <Col style={{textAlign:'left', paddingBottom:'10px', paddingLeft: 0 }}>
                                                <span onClick={() => attach_new_sku('hardwares')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add +</span>
                                            </Col>
                                        </Row>
                                    </Card_With_Header>
                                </div>
                            </>
                        </CSSTransition>
                        <CSSTransition
                            in={current_step === 3 && next_step === 3}
                            timeout={500}
                            classNames={next_step < previous_step ? "previous-step" : "next-step"}
                            onEnter={() => {
                                set_show_step_3(true);
                            }}
                            onEntered={() => {
                                set_previous_step(3);
                            }}
                            onExited={() => {
                                set_show_step_3(false);
                                set_current_step(next_step);
                            }}
                        >
                            <>
                                <div className="global_modal_body" style={{ display: show_step_3 ? 'block' : 'none', height: 'calc(100% - 125px)', padding: 10, backgroundColor: 'white', borderRadius: 5, boxShadow: "rgb(174 174 181) 0px 0px 3px 0px" }}>
                                    <Card_With_Header header_text='Skirtings'>
                                        {skirtings.map((x, idx) => (
                                            <>
                                                <Row style={{ margin:0, paddingBottom: 10, marignBottom: 10 }}>
                                                    <Col xs={8} style={{display:'flex', alignItems:"center"}}>
                                                        <SKU_Selector
                                                            selected_sku_id={x?.sku_id ?? null}
                                                            division_level_data={skirtings_division_data}
                                                            set_selected_sku={sku => update_attached_sku('skirtings', idx, sku && sku.length ? sku[0].id: null, 'sku_id')}
                                                            index={idx}
                                                            id="skirting_selector"
                                                            display_header={idx === 0}
                                                        />
                                                    </Col>
                                                    <Col xs={3}>
                                                        <span style={{ fontSize: 13, marignBottom: 10 }}>Length</span> <br/>
                                                        <Input
                                                            type="number"
                                                            style={{ fontSize: 12 }}
                                                            value={x.length}
                                                            onChange={e => update_attached_sku('skirtings', idx, e.target.value, 'length')}
                                                        />
                                                    </Col>
                                                    <Col xs={1} style={{ padding: 0, paddingTop: idx === 0 ? 30 : 10, textAlign: 'center' }}>
                                                        <div style={{ marginTop: 3, marginBottom: 5 }}>
                                                            <span className='fa fa-trash' style={{color:'#1070CA', cursor:'pointer'}} onClick={() => remove_attached_sku('skirtings', idx)} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        ))}
                                        <Row style={{margin:0}}>
                                            <Col style={{textAlign:'left', paddingBottom:'10px', paddingLeft: 0 }}>
                                                <span onClick={() => attach_new_sku('skirtings')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add +</span>
                                            </Col>
                                        </Row>                            
                                    </Card_With_Header>
                                    <Card_With_Header header_text='Countertops'>
                                        {countertops.map((x, idx) => (
                                            <>
                                                <Row style={{ margin:0, paddingBottom: 10, marignBottom: 10 }}>
                                                    <Col xs={7} style={{display:'flex', alignItems:"center"}}>
                                                        <SKU_Selector
                                                            selected_sku_id={x?.sku_id ?? null}
                                                            division_level_data={countertops_division_data}
                                                            set_selected_sku={sku => update_attached_sku('countertops', idx, sku && sku.length ? sku[0].id: null, 'sku_id')}
                                                            index={idx}
                                                            id="countertop_selector"
                                                            display_header={idx === 0}
                                                        />
                                                    </Col>
                                                    <Col xs={2}>
                                                        <span style={{ fontSize: 13, marignBottom: 10 }}>Length</span> <br/>
                                                        <Input
                                                            type="number"
                                                            style={{ fontSize: 12 }}
                                                            value={x.length}
                                                            onChange={e => update_attached_sku('countertops', idx, e.target.value, 'length')}
                                                        />
                                                    </Col>
                                                    <Col xs={2}>
                                                        <span style={{ fontSize: 13, marignBottom: 10 }}>Width</span> <br/>
                                                        <Input
                                                            type="number"
                                                            value={x.width}
                                                            onChange={e => update_attached_sku('countertops', idx, e.target.value, 'width')}
                                                        />
                                                    </Col>
                                                    <Col xs={1} style={{ padding: 0, paddingTop: idx === 0 ? 30 : 10, textAlign: 'center' }}>
                                                        <div style={{ marginTop: 3, marginBottom: 5 }}>
                                                            <span className='fa fa-trash' style={{color:'#1070CA', cursor:'pointer'}} onClick={() => remove_attached_sku('countertops', idx)} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        ))}
                                        <Row style={{margin: 0}}>
                                            <Col style={{textAlign:'left', paddingBottom:'10px', paddingLeft: 0 }}>
                                                <span onClick={() => attach_new_sku('countertops')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add +</span>
                                            </Col>
                                        </Row>
                                    </Card_With_Header>
                                </div>
                            </>
                        </CSSTransition>
                    </CardBody>
                    <CardFooter>
                        <Row style={{ padding: 10 }}>
                            {current_step > 0 ? (
                                <Col>
                                    <Button onClick={go_back_to_previous_step} color="red" className="btn btn-block btn-danger">
                                        Back
                                    </Button>
                                </Col>
                            ) : ''}
                            <Col>
                                <Button onClick={proceed_to_next_step} color="#1070CA" className="btn btn-block btn-primary">
                                    Proceed
                                </Button>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Modal>
        );
    } catch (err) {
        console.error(err);
        return 'Something went wrong';
    }
}

const CustomBOM = ({ all_division_tree_data, selected_sku_properties, update_sku_properties }) => {
    const [ core_material_options, set_core_material_options ] = useState([]);
    const [ finish_options, set_finish_options ] = useState([]);
    const [ edgeband_options, set_edgeband_options ] = useState([]);
    
    const [ panels, set_panels ] = useState([]);
    const [ accessories, set_accessories ] = useState([]);
    const [ hardwares, set_hardwares ] = useState([]);
    const [ hardware_types, set_hardware_types ] = useState([]);
    const [ countertops, set_countertops ] = useState([]);
    const [ skirtings, set_skirtings ] = useState([]);
    
    const [ pre_existing_bom, set_pre_existing_bom ] = useState(false);
    const [ custom_bom_modal_open, set_custom_bom_modal_open ] = useState(false);


    const update_sku = () => {
        try {
            console.log(selected_sku_properties);
            console.log({
                core_material_options,
                finish_options,
                edgeband_options,
                panels,
                accessories,
                hardwares,
                countertops,
                skirtings
            });
            const custom_bom = {
                core_material_options: core_material_options.map(x => ({ sku_id: x.id })),
                finish_options: finish_options.map(x => ({ sku_id: x.id })),
                edgeband_options: edgeband_options.map(x => ({ sku_id: x.id })),
                accessories,
                hardwares,
                countertops,
                skirtings,
                panels: panels.map(x => ({ 
                    ...x,
                    core_material: (x.core_material && x.core_material.id) ? { sku_id: x.core_material.id } : null,
                    edgeband: (x.edgeband && x.edgeband.id)  ? { sku_id: x.edgeband.id } : null,
                    internal_finish: (x.internal_finish && x.internal_finish.id) ? { sku_id: x.internal_finish.id } : null,
                    external_finish: (x.external_finish && x.external_finish.id) ? { sku_id: x.external_finish.id } : null,
                }))
            };
            console.log("custom bom =-->>> ", custom_bom);
            update_sku_properties(custom_bom);
        } catch (err) {
            console.error(err);
        }
    }

    const add_hardware_types = (value) => {
        let a = [...hardware_types]
        a.push(value)
        set_hardware_types(a)
    } 

    const update_hardware_types = (index, value) => {
        let a = [...hardware_types]
        a[index] = value
        set_hardware_types(a)
    }

    const delete_hardware_types = (index) => {
        let a = [...hardware_types];
		a.splice(index,1)
		set_hardware_types(a);
    }

    useEffect(() => {
        console.log("core material options changed in base comp --===>>> ", core_material_options);
        console.log("finish options changed in base comp --===>>> ", finish_options);
        console.log("edgeband options changed in base comp --===>>> ", edgeband_options);

    }, [core_material_options, finish_options, edgeband_options]);

    useEffect(() => {
        console.log('XXXXXXXX sku props changed  --> ', selected_sku_properties);
        if (selected_sku_properties && selected_sku_properties.additional_properties) {
            let { additional_properties } = selected_sku_properties;
            additional_properties.map(x => {
                if (x.key === 'custom_bom') {
                    set_pre_existing_bom(true);
                    console.log("setting pre existing bom values ---> ", x.value);
                    set_panels(x.value.panels.map(x => ({
                        ...x,
                        core_material: (x.core_material && x.core_material.sku_id) ? { id: x.core_material.sku_id } : null,
                        edgeband: (x.edgeband && x.edgeband.sku_id) ? { id: x.edgeband.sku_id } : null,
                        internal_finish: (x.internal_finish && x.internal_finish.sku_id) ? { id: x.internal_finish.sku_id } : null,
                        external_finish: (x.external_finish && x.external_finish.sku_id) ? { id: x.external_finish.sku_id } : null,
                    })));
                    set_accessories(x.value.accessories);
                    set_hardwares(x.value.hardwares);
                    let temp_array = x.value.hardwares && x.value.hardwares.length && x.value.hardwares.map(x => '')
                    set_hardware_types(temp_array)
                    set_countertops(x.value.countertops);
                    set_skirtings(x.value.skirtings);
                    set_core_material_options(x.value.core_material_options.map(x => ({ id: x.sku_id })));
                    set_finish_options(x.value.finish_options.map(x => ({ id: x.sku_id })));
                    set_edgeband_options(x.value.edgeband_options.map(x => ({ id: x.sku_id })));
                }
            });
        }
    }, [ selected_sku_properties, custom_bom_modal_open ]);

    try {
        return (
            <CardBody className='global_modal_body' style={{ padding: 20 }}>
                <div style={{fontSize:'16px', fontFamily:'Source_Sans_Pro-SemiBold', textTransform:'capitalize', marginBottom:'10px'}}>Custom Bill of Materials - {selected_sku_properties?selected_sku_properties.name:''}</div>

                <div style={{border: '1px solid #D5DAE0', borderRadius: '4px', marginBottom:'20px', padding:"0px", boxShadow: '0px 0px 1px rgb(67 90 111 / 47%)',borderRadius: '5px'}}>
                    {pre_existing_bom ? (
                        <Table  aria-label="simple table" style={{ marginBottom: 0 }}>
                            <thead>
                            <tr style={{borderBottom:"1px solid #dee2e6"}}>
                                <th className='th_style' style={{border:0, borderRight:"1px solid #dee2e6", paddingLeft:'20px'}}>{panels && panels.length ? (panels.length > 1) ? panels.length + ' Panels' : '1 Panel' : 'No Panels'}</th> 
                                <th className='th_style' style={{border:0, borderRight:"1px solid #dee2e6", paddingLeft:'20px'}}>{accessories && accessories.length ? (accessories.length > 1) ? accessories.length + ' Accessories' : '1 Accessory' : 'No Accessories'}</th>
                                <th className='th_style' style={{border:0, borderRight:"1px solid #dee2e6"}}>{hardwares && hardwares.length ? (hardwares.length > 1) ? hardwares.length + ' Hardwares' : '1 Hardware' : 'No Hardwares'}</th>
                                <th className='th_style' style={{border:0, borderRight:"1px solid #dee2e6"}}>{countertops && countertops.length ? (countertops.length > 1) ? countertops.length + ' Countertops' : '1 Countertop' : 'No Countertops'}</th>  
                                <th className='th_style' style={{border:0, borderRight:"1px solid #dee2e6"}}>{skirtings && skirtings.length ? (skirtings.length > 1) ? skirtings.length + ' Skirtings' : '1 Skirting' : 'No Skirtings'}</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={5} align="center">
                                        <Button 
                                            style={{backgroundColor:"inherit", color:"#1070CA", border:'1px solid #1070CA', fontSize:"14px", fontWeight:600,height:"32px", display:"flex", alignItems:'center', marginRight:"10px"}} 
                                            onClick={() => set_custom_bom_modal_open(true)}
                                            >
                                            Edit Current BOM
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    ) : (
                        <div style= {{textAlign : "center", marginBottom:'10px', padding: 20 }}>
                            <span style = {{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold'}}>Custom bill of materials has not been created for this SKU.</span> <br />
                            <Button 
                                style={{ backgroundColor:"inherit", color:"#1070CA", border:'1px solid #1070CA', fontSize:"14px", fontWeight:600,height:"32px", display:"inline-flex", alignItems:'center', marginTop:10 }} 
                                onClick={() => set_custom_bom_modal_open(true)}
                            >
                                Create custom BOM
                            </Button>
                        </div> 
                    )}
                </div>
                <Custom_BOM_Modal
                    open={custom_bom_modal_open}
                    set_open={set_custom_bom_modal_open}
                    core_material_options={core_material_options}
                    set_core_material_options={set_core_material_options}
                    finish_options={finish_options}
                    set_finish_options={set_finish_options}
                    edgeband_options={edgeband_options}
                    set_edgeband_options={set_edgeband_options}
                    panels={panels}
                    set_panels={set_panels}
                    accessories={accessories}
                    set_accessories={set_accessories}
                    hardwares={hardwares}
                    set_hardwares={set_hardwares}
                    countertops={countertops}
                    set_countertops={set_countertops}
                    skirtings={skirtings}
                    set_skirtings={set_skirtings}
                    all_division_tree_data={all_division_tree_data}
                    update_sku={update_sku}
                    hardware_types = {hardware_types}
                    set_hardware_types = {set_hardware_types}
                    add_hardware_types = {add_hardware_types}
                    update_hardware_types = {update_hardware_types}
                    delete_hardware_types = {delete_hardware_types}
                />
            </CardBody>
        );
    } catch(err) {
        console.error(err);
        return 'Something went wrong';
    }
}

export default CustomBOM;