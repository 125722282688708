import React , { useState , useEffect, useContext }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Multiselect from "../../components/Multiselect/";
import { primary_menu_options } from '../../components/Sidebar/menu_options';
import { useAlert } from 'react-alert'
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { AllBuContext } from '../../components/Contexts/AllBuContext';
import { BuDetailsContext } from '../../components/Contexts/BuDetailsContext';

const tag_type_options = [
	{
		id:'Tiering/Range',
		name:'Tiering/Range',
	},
	{
		id:'Others',
		name:'Others',
	}
]

const TagsDialogBox = ({open , handleClose , tags ,user , fetch_tags_details , mode, all_category_type_data, all_tags}) => {

	const[tags_id , set_tags_id] = useState(null);
	const[tags_name , set_tags_name] = useState(null);
	
	const[div_options , set_div_options] = useState([]);
	const[tag_category_type_options , set_tag_category_type_options] = useState([]);
	const[tag_type , set_tag_type] = useState('');
	const [tag_level, set_tag_level] = useState('');
	const[tag_division , set_tag_division] = useState('');
	const[tag_category_type , set_tag_category_type] = useState([]);
	const alert = useAlert();

	const org_bu_view = useContext(OrgBuContext)
	const all_bu_in_store = useContext(AllBuContext)
	const bu_details = useContext(BuDetailsContext)

	useEffect (() => {
		if(tags){
			set_tags_id(tags.id)
			set_tags_name(tags.tag)
			set_tag_type(tags.type)
			set_tag_level(tags.level)
			var z_list =[];
			if(tags.eligible_sku_category_types && tags.eligible_sku_category_types.length){
				var x_list = tags.eligible_sku_category_types.map(x => x.split('_'))
				var y_list = x_list.map( x => x[x.length -1])
				var z_list = div_options.filter(x => y_list.includes(x.id))
				// console.log('y_list',z_list)
			}
			set_tag_division(z_list)
		}
	},[tags]);

	useEffect(() => {
		var x_list =[]
		if(primary_menu_options && primary_menu_options.length){
			var x_list = primary_menu_options.find(x => x.key==='catalogue')
		}
		set_div_options(x_list.sub_menu)
	},[])

	useEffect(() => {
		var x_cat_type_list = []
		if(tag_division && tag_division.length && all_category_type_data && all_category_type_data.length){
			var div_ids = tag_division.map(x => x.id)
			x_cat_type_list = all_category_type_data.filter(x => div_ids.includes(x.sku_division_id))
		}
		set_tag_category_type_options(x_cat_type_list)
	},[tag_division, all_category_type_data])

	useEffect(() => {
		var x_eligible_cat_list =[]
		if(tag_category_type_options && tag_category_type_options.length && tags && tags.eligible_sku_category_types && tags.eligible_sku_category_types.length){
			x_eligible_cat_list = tag_category_type_options.filter(x => tags.eligible_sku_category_types.includes(x.id))
		}
		set_tag_category_type(x_eligible_cat_list)
		console.log('x_eligible_cat_list',tag_category_type_options,x_eligible_cat_list)
	},[tag_category_type_options])

	const add_name = async () => {
		if(tags_name && tag_type && tag_type != 'none'){
			try {
				if(tags_name && all_tags.find(o => o.tag === tags_name)){
					alert.error('A tag with the same name already exists.')
					return
				}
				var cat_type_ids=tag_category_type && tag_category_type.length? tag_category_type.map( x => x.id):[]
				var body = {name : tags_name, type:tag_type, sku_category_type_ids:cat_type_ids}
				if(bu_details.default){
					if(all_bu_in_store && all_bu_in_store.length > 1){
						body['level'] = 'bu_level'
					}else{
						body['level'] = 'org_level'
					}
				}
				let response
				if (org_bu_view === 'org') {
					response = await general_fetch({ url: 'tag/add', body });
				} else {
					body['business_unit_id'] = user.current_business_unit_id
					response = await general_fetch({ url: 'tag/add', body });
				}
				alert.success("Tag added successfully")
				fetch_tags_details();
				console.log(response)
				
				handleClose();
			} catch(err) { 
				alert.error("Tag addition failed!")
				console.log(err);
			}
		}else{
			alert.error("Please fill all required fields!")
		}	
	}

	const update_name = async () => {
		if(tags_id && tags_name){
			try {
				// var identifiers = JSON.stringify({id:tags_id})
				// var updates = JSON.stringify({tag : tags_name})
				var body = {id: tags_id, new_name: tags_name}
				if(bu_details.default){
					if(all_bu_in_store && all_bu_in_store.length > 1){
						body['level'] = tag_level
					}else{
						body['level'] = 'org_level'
					}
				}
				let response
				if (org_bu_view === 'org') {
					response = await general_fetch({ url: 'tag/update', body });
				} else {
					body['business_unit_id'] = user.current_business_unit_id
					response = await general_fetch({ url: 'tag/update', body });
				}
				alert.success("Tag updated successfully")
				fetch_tags_details();
				handleClose();

			} catch(err) {
				alert.error("Tag updation failed!") 
				console.log(err);
			}
		}else{
			alert.error("Tag name required")
		}
	}

	const onselect_multiselect = (type, value) => {
		if(type === 'division'){
			set_tag_division(value)
		}
		if(type ==='cat_type'){
			set_tag_category_type(value)
		}
	}

	const onclick_handleClose = () => {
		set_tags_name('');
		set_tag_category_type_options([]);
		set_tag_type('');
		set_tag_division([]);
		set_tag_category_type([]);
		handleClose()
	}

  	return (
  		<div>
  			<Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={onclick_handleClose}>
				<Card className='' >
					<CardHeader className='global_modal_header'>
							<span>
								<span>{(mode=='add') ? "Enter Tag Name" : "Update Tag Name - "}</span>
								<span>{tags && mode=='add' ? null : tags.tag}</span>
							</span>
							<i onClick={onclick_handleClose} style={{cursor: 'pointer'}} className='fa fa-times'/>
					</CardHeader>

					<CardBody className='global_modal_body' style={{maxHeight:'500px', overflow: 'auto', height: 'auto'}}>
                        <Row style={{marginBottom:'15px'}}>
                        	<Col>
                        		<div>
		                            <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Tag Name*</div>
									<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} id='tag_name_input' placeholder="Tag Name" type="text" value={tags_name} onChange={e => set_tags_name(e.target.value)}/>
								</div>
                        	</Col>	
                        </Row>
						<Row style={{marginBottom:'15px'}}>
                        	<Col>
                        		<div>
		                            <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Tag Type*</div>
									<Input id='select_tag_type'  disabled={mode==='add'?false:true} style={{opacity:mode==='add'?1:.5, width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} type="select" 
										value={tag_type} onChange={e => set_tag_type(e.target.value)}
									>
										<option value='none'>Select Tag Type</option>
										{
											tag_type_options && tag_type_options.length? tag_type_options.map((item, idx) => (
												<option key={idx} value={item.id}>{item.name}</option>
											))	
											:''
										}
									</Input>
								</div>
                        	</Col>	
                        </Row>

						{
							mode === 'edit' && bu_details.default && all_bu_in_store && all_bu_in_store.length > 1 ?
								<Row style={{ marginBottom: '20px' }}>
									<Col>
										<div>
											<div style={{ paddingBottom: '3px', fontSize: '12px', color: '#425A70', letterSpacing: '-0.04px', lineHeight: '20px' }}>Tag Level</div>
											<Input style={{ width: '100%', height: '32px', fontSize: '12px', color: '#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px' }} type="select" value={tag_level} onChange={e => set_tag_level(e.target.value)}>
												<option value={'bu_level'}>BU Level</option>
												<option value={'org_level'}>Org Level</option>
											</Input>
										</div>
									</Col>
								</Row>
								: ''

						}
						<Row style={{marginBottom:'5px'}}>
                        	<Col>
                        		<div>
		                            <div style={{paddingBottom:'3px',fontSize:'16px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Tag Eligibility</div>
								</div>
                        	</Col>	
                        </Row>
						<Row style={{marginBottom:'10px'}}>
                        	<Col xs={6}>
                        		<div>
		                            <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Division</div>
									{
										mode==='edit' && tag_division && !tag_division.length?
										<Input disabled style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} type="select" 
										>
											<option>No Division Selected</option>
										</Input>
										:
										<Multiselect
											style={{backgroundColor:'white', width:'100%'}}
											options={div_options && div_options.length? JSON.parse(JSON.stringify(div_options)):[]} 
											selectedValues={tag_division}
											onSelect={(selectedList)=>onselect_multiselect('division', selectedList)} 
											onRemove={(selectedList)=>onselect_multiselect('division', selectedList)} 
											displayValue="text" // Property name 
											id={'select_div'}
											placeholder='Select'
											disable={mode==='add'?false:true}
										/>
									}
								</div>
                        	</Col>	
							<Col xs={6}>
                        		<div>
		                            <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Category Type</div>
									{
										mode==='edit' && tag_division && !tag_division.length?
										<Input disabled style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} type="select" 
										>
											<option>No Category Type Selected</option>
										</Input>
										:
										<Multiselect
											style={{backgroundColor:'white', width:'100%'}}
											options={tag_category_type_options && tag_category_type_options.length? JSON.parse(JSON.stringify(tag_category_type_options)):[]} 
											selectedValues={tag_category_type}
											onSelect={(selectedList)=>onselect_multiselect('cat_type', selectedList)} 
											onRemove={(selectedList)=>onselect_multiselect('cat_type', selectedList)} 
											displayValue="display_name" // Property name 
											id={'select_cat_type'}
											placeholder='Select'
											disable={mode==='add'?false:true}
										/>
									}
								</div>
                        	</Col>
                        </Row>
					</CardBody>

					<CardFooter className='global_modal_footer'>
						<Button className='white_button' type="button" onClick={onclick_handleClose}> Cancel </Button>
						{ 
							mode == 'edit' ?
							<Button id='update_button' className='blue_button' type="button" onClick={update_name}>Update</Button> : 
							<Button id='add_button' className='blue_button' type="button" onClick={add_name}>Add</Button>
						}		
					</CardFooter>
				</Card>  
			</Modal>
		</div>	
  	);
}

export default TagsDialogBox ; 
