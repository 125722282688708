import React, { useState, useEffect, cloneElement, useContext } from 'react';
import { Dropdown, Label, Modal, FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, ModalFooter, } from "reactstrap";


import { useAlert } from 'react-alert'
import LazyLoad from 'react-lazyload';
import Multiselect from '../Multiselect';
import { IModalBody, IModalFooter, IModalHeader } from '../IModal/index.js';
import { OrgBuContext } from '../Contexts/OrgBuContext.js';
import { UserContext } from '../Contexts/UserContext.js';
import build_path from '../../utils/path.js';
import general_fetch from '../../utils/fetch.js';
import { fetch_sku_categories, fetch_sku_sub_categories } from '../../utils/api/inventory.js';
import { Checkbox } from 'antd';

import './style.css'
import includes_search_string from '../../utils/includes_search_string.js';
import useStateCallback from '../../utils/useStateCallback.js';

const GroupCard = ({ group, sku_selection_map, group_selection_map, isIntermediateGroup, checkAllGroup, onChangeCheckAllGroup, reRender, search_string }) => {
    // const [is_group_selected, set_is_group_selected] = useState(false);

    const [preview, set_preview] = useState('Select Sku');
    const [open, set_open] = useState(false);
    const toggle = () => set_open(!open)

    // useEffect(() => {
    //     if (group) {
    //         let group_checked = checkAllGroup(group.sku)
    //         set_is_group_selected(group_checked)
    //         if (group_selection_map[group.id] != group_checked) {
    //             group_selection_map[group.id] = group_checked
    //             reRender()
    //         }
    //     }
    // }, [checkAllGroup]);

    const skuSelectionPreview = (group) => {
        return <div className={`flex_property p-2 ${search_string && !open && group && group.sku && group.sku.some((x) => includes_search_string(x.name, search_string)) ? 'highlight-selection' : ''}`} style={{fontWeight: 500, fontSize: '14px', color: '#0078ff'}}>Select Sku <div className='ml-2' style={{color: '#A7A8B2'}}>({group.sku && group.sku.filter(x => sku_selection_map[x.id]).length || 0}/{group.sku && group.sku.length || 0} selected)</div></div>
    }

    // useEffect(() => {
    //     set_preview(skuSelectionPreview(group))
    // }, [checkAllGroup]);
   
    return (
        <div className={`sku-selection-group-card `}>
            <div className={`sku-selection-group-card-inner ${search_string && group && includes_search_string(group.name, search_string) ? 'highlight-selection' : ''}`}>
                <div className='flex_property p-2' style={{ flex: 1, minWidth: '0px', fontWeight: 700, borderRight: '1px solid #E6E9EE' }}><Checkbox indeterminate={isIntermediateGroup(group.sku)} checked={checkAllGroup(group)} onClick={(e) => { onChangeCheckAllGroup(e, group) }}>{group.name}</Checkbox></div>
                <Dropdown isOpen={open} toggle={toggle} className='flex_center p-2' style={{ flex: 1 }}>
                    <DropdownToggle style={{ background: 'unset', border: 'unset', padding: 'unset', color: 'unset', boxShadow: 'unset' }}>{skuSelectionPreview(group)}</DropdownToggle>
                    <DropdownMenu style={{ maxHeight: '400px', overflow: 'auto', padding: '4px'}}>
                        {
                            group.sku && group.sku.length ? group.sku.map((sku, idx) => (
                                <DropdownItem key={idx} toggle={false} className={`${search_string && sku && includes_search_string(sku.name, search_string) ? 'highlight-selection' : ''}`}>
                                    <Checkbox checked={sku_selection_map[sku.id]} onClick={() => { sku_selection_map[sku.id] = !sku_selection_map[sku.id]; reRender() }}>
                                        <div className='flex_property'>
                                            <img src={sku.display_pic ? build_path(global.config.static_path, sku.display_pic.replace('.png', '_thumbnail.png').replace('.jpg', '_thumbnail.jpg').replace('.jpeg', '_thumbnail.jpeg')) : global.config.sku_image_not_found} className='mr-2' style={{height: '40px', maxWidth: '80px'}} />
                                            <div title={sku.name} style={{maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{sku.name}</div>
                                        </div>
                                    </Checkbox>
                                </DropdownItem>
                            ))
                                : ''
                        }
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    )
}

const SkuSelectionModal = ({ open, handleclose, set_page_loader, initialSelection, submitSelectedSku }) => {

    const eligible_category_type_ids = ['cabinet_furniture', 'wardrobe_furniture', 'custom_panel_hardware']
    const [initialised, set_initialised] = useState(false);
    const [list_of_categories, set_list_of_categories] = useState([]);
    const [categoryToSubcategoryMap, setCategoryToSubcategoryMap] = useState({});
    
    const [active_category_id, set_active_category_id] = useState('');
    const [active_subcategory_id, set_active_subcategory_id] = useState('');

    const [sku_selection_map, set_sku_selection_map] = useStateCallback({});
    const [group_selection_map, set_group_selection_map] = useState({});
    const [subcat_selection_map, set_subcat_selection_map] = useState({});
    const [cat_selection_map, set_cat_selection_map] = useState({});

    const [group_sku_selection_count, set_group_sku_selection_count] = useState({});
    const [subcat_sku_selection_count, set_subcat_sku_selection_count] = useState({});

    const [search_string, set_search_string] = useState('');
    const [reRenderState, setReRenderState] = useState({});

    const user = useContext(UserContext)

    const reRender = () => {
        setCategoryToSubcategoryMap(JSON.parse(JSON.stringify(categoryToSubcategoryMap)))
    }

    const reRenderSkuSelection = () => {
        set_sku_selection_map(JSON.parse(JSON.stringify(sku_selection_map)))
    }

    const reRenderGroupSelection = () => {
        set_group_selection_map(JSON.parse(JSON.stringify(group_selection_map)))
    }

    const reRenderDummy = () => {
        setReRenderState(JSON.parse(JSON.stringify(reRenderState)))
    }

    const handleClosefn = () => {
        if(initialSelection && initialSelection.length){
            let initial_selection_to_sku_selection_map = {}
            initialSelection.map(x => initial_selection_to_sku_selection_map[x] = true)
            // get_initial_state_data()
            set_sku_selection_map(initial_selection_to_sku_selection_map)
            // reRenderSkuSelection()
        }else{
            set_sku_selection_map({})
        }
        set_group_selection_map({})
        set_subcat_selection_map({})
        set_cat_selection_map({})
        set_group_sku_selection_count({})
        set_subcat_sku_selection_count({})
        set_search_string('')

        handleclose()
    }

    const init = async () => {
        set_page_loader({text: 'Fetching Categories', show: true})
        let all_category_data = await fetch_sku_categories(['furniture', 'hardware'])
        all_category_data = all_category_data.filter(x => eligible_category_type_ids.includes(x.sku_category_type_id))
        set_list_of_categories(all_category_data)

        set_page_loader({text: 'Fetching Sub-Categories', show: true})
        
        if (all_category_data && all_category_data.length) {
            await Promise.all(
                all_category_data.map(cat => (
                    get_sub_category_of_category(cat.id, cat.id === all_category_data[0].id, false)
                ))
            )
            set_active_category_id(all_category_data[0].id)
        }
        // Fetch all subcategories
        // for(let i in all_category_data){
        //     get_sub_category_of_category(all_category_data[i].id, i===0, false)
        // }
        if(initialSelection && initialSelection.length){
            // let initial_selection_to_sku_selection_map = {}
            initialSelection.map(x => sku_selection_map[x] = true)
        }
        get_initial_state_data()
        // set_sku_selection_map(initial_selection_to_sku_selection_map, get_initial_state_data)
        reRenderSkuSelection()

        set_page_loader({text: 'Please Wait...', show: false})
        set_initialised(true)
        reRender()
    }

    const get_initial_state_data = () => {
        categoryToSubcategoryMap && Object.keys(categoryToSubcategoryMap).map(cat_id => {
            let subcats = categoryToSubcategoryMap[cat_id] ? Object.keys(categoryToSubcategoryMap[cat_id]) : []
            subcats.length && subcats.map(subcat_id => {
                categoryToSubcategoryMap[cat_id][subcat_id]["sku_group"] && categoryToSubcategoryMap[cat_id][subcat_id]["sku_group"].map(group => {
                    checkAllGroup(group)
                })
                checkAllSubCat(subcat_id, categoryToSubcategoryMap[cat_id][subcat_id]["sku_group"])
                isIntermediateSubCat(subcat_id, categoryToSubcategoryMap[cat_id][subcat_id]["sku_group"])
            })
            checkAllCat(cat_id, subcats)
        })

    }

    const get_sub_category_of_active_category = async () => {
        let sub_categories = await fetch_sku_sub_categories(active_category_id)
        if (sub_categories && sub_categories.length) {
            set_active_subcategory_id(sub_categories[0].id)
        }
        let temp = {}
        for (let i in sub_categories) {
            temp[sub_categories[i].id] = sub_categories[i]
        }
        categoryToSubcategoryMap[active_category_id] = temp
        reRender()
    }

    const get_sub_category_of_category = async (category_id, isActive, triggerReRender = true) => {
        let sub_categories = await fetch_sku_sub_categories(category_id)
        if (isActive && sub_categories && sub_categories.length) {
            set_active_subcategory_id(sub_categories[0].id)
        }
        let filtered_sub_categories = sub_categories.filter(subcat => {
            subcat.sku_group = subcat.sku_group && subcat.sku_group.filter(group => {
                group.sku = group.sku && group.sku.filter(sku => sku.business_unit_id === user.current_business_unit_id)
                return group.sku && group.sku.length
            })
            return subcat.sku_group && subcat.sku_group.length
        })
        let temp = {}
        for (let i in filtered_sub_categories) {
            temp[filtered_sub_categories[i].id] = filtered_sub_categories[i]
        }
        categoryToSubcategoryMap[category_id] = temp
        if(triggerReRender) reRender();
    }

    //TODO - Optimze the below code to determine the selection state. currently iterating through evertything for every change. O(n) -> O(1)
    //TODO - Implementation Have cat_id, subcat_id and group_id in sku details. when ever sku selectioin status is updated. update the count. false -> true => count + 1 and vice versa

    const isIntermediateCat = (subcats) => {
        // let selection_count = subcats && subcats.filter(subcat => subcat_selection_map[subcat]).length || 0
        // let total_count = subcats && subcats.length || 0
        let selection_count = 0, total_count = 0;
        let requireReRender = false

        for(let i in subcats){
            total_count = total_count + (subcat_sku_selection_count[subcats[i]] ? subcat_sku_selection_count[subcats[i]].total_count : 0)
            selection_count = selection_count + (subcat_sku_selection_count[subcats[i]] ? subcat_sku_selection_count[subcats[i]].selection_count : 0)
        }

        // console.log("indeterminate", selection_count, total_count, selection_count > 0 && selection_count < total_count)

        return selection_count > 0 && selection_count < total_count
    }

    const isIntermediateSubCat = (subcat_id, groups) => {
        // let selection_count = groups && groups.filter(group => group_selection_map[group.id]).length || 0
        // let total_count = groups && groups.length || 0
        let selection_count = 0, total_count = 0;
        let requireReRender = false
        for(let i in groups){
            total_count = total_count + (group_sku_selection_count[groups[i].id] ? group_sku_selection_count[groups[i].id].total_count : 0)
            selection_count = selection_count + (group_sku_selection_count[groups[i].id] ? group_sku_selection_count[groups[i].id].selection_count : 0)
        }

        if(subcat_sku_selection_count[subcat_id]){
            if(subcat_sku_selection_count[subcat_id].selection_count != selection_count || subcat_sku_selection_count[subcat_id].total_count != total_count){
                subcat_sku_selection_count[subcat_id] = {selection_count: selection_count, total_count: total_count}
                requireReRender = true
            }
        }else{
            subcat_sku_selection_count[subcat_id] = {selection_count: selection_count, total_count: total_count}
            requireReRender = true
        }

        if(requireReRender){
            reRenderDummy()
        }

        // console.log("indeterminate", selection_count, total_count, selection_count > 0 && selection_count < total_count)

        return selection_count > 0 && selection_count < total_count
    }

    const isIntermediateGroup = (skus) => {
        let selection_count = skus && skus.filter(sku => sku_selection_map[sku.id]).length || 0
        let total_count = skus && skus.length || 0

        // console.log("indeterminate", selection_count, total_count, selection_count > 0 && selection_count < total_count)

        return selection_count > 0 && selection_count < total_count
    }

    const checkAllCat = (cat_id, subcats) => {
        let selection_count = subcats && subcats.filter(subcat => subcat_selection_map[subcat]).length || 0
        let total_count = subcats && subcats.length || 0

        // console.log("indeterminate", selection_count, total_count, selection_count > 0 && selection_count < total_count)

        let cat_checked = selection_count > 0 && selection_count === total_count

        if (total_count > 0 && cat_selection_map[cat_id] != cat_checked) {
            cat_selection_map[cat_id] = cat_checked
            reRenderDummy()
        }

        return cat_selection_map[cat_id]

    }

    const checkAllSubCat = (subcat_id, groups) => {
        let selection_count = groups && groups.filter(group => group_selection_map[group.id]).length || 0
        let total_count = groups && groups.length || 0

        // console.log("checkall subcat", selection_count, total_count)

        let subcat_checked = selection_count > 0 && selection_count === total_count
        let requireReRender = false

        if (total_count > 0 && subcat_selection_map[subcat_id] != subcat_checked) {
            subcat_selection_map[subcat_id] = subcat_checked
            requireReRender = true
        }

        if(requireReRender){
            reRenderDummy()
        }

        return subcat_selection_map[subcat_id]

    }

    const checkAllGroup = (group) => {
        let selection_count = group && group.sku && group.sku.filter(sku => sku_selection_map[sku.id]).length || 0
        let total_count = group && group.sku && group.sku.length || 0

        let group_checked = selection_count > 0 && selection_count === total_count
        let requireReRender = false
        
        if(group_sku_selection_count[group.id]){
            if(group_sku_selection_count[group.id].selection_count != selection_count || group_sku_selection_count[group.id].total_count != total_count){
                group_sku_selection_count[group.id] = {selection_count: selection_count, total_count: total_count}
                requireReRender = true
            }
        }else{
            group_sku_selection_count[group.id] = {selection_count: selection_count, total_count: total_count}
            requireReRender = true
        }

        if (total_count > 0 && group_selection_map[group.id] != group_checked) {
            group_selection_map[group.id] = group_checked
            requireReRender = true
        }

        if(requireReRender){
            reRenderDummy()
        }

        return group_selection_map[group.id]

        // console.log("indeterminate", selection_count, total_count, selection_count > 0 && selection_count < total_count)

    }

    const onChangeCheckAllCat = (event, cat_id, subcats) => {
        cat_selection_map[cat_id] = event.target.checked
        subcats && Object.keys(subcats).map(subcat => onChangeCheckAllSubCat(event, subcat, subcats[subcat]["sku_group"], false))
        reRenderDummy()
        get_initial_state_data()
    }

    const onChangeCheckAllSubCat = (event, subcat_id, groups, requireReRender = true) => {
        subcat_selection_map[subcat_id] = event.target.checked
        groups && groups.map(group => {
            group.sku && onChangeCheckAllGroup(event, group, false)
        })

        // reRenderGroupSelection()
        if(requireReRender){
            reRenderDummy()
            get_initial_state_data()
        }
    }

    const onChangeCheckAllGroup = (event, group, requireReRender = true) => {
        // console.log(event.target.checked, "sku seection")
        group_selection_map[group.id] = event.target.checked
        group && group.sku.map(sku => {
            if (event.target.checked) {
                sku_selection_map[sku.id] = true
            } else {
                sku_selection_map[sku.id] = false
            }
        })
        // reRenderSkuSelection()
        if(requireReRender){
            reRenderDummy()
        }
    }

    const submitSelectedSkufn = () => {
        // console.log("selected sku", sku_selection_map)
        try{
            submitSelectedSku(Object.keys(sku_selection_map).filter(x => sku_selection_map[x]))
            handleclose()
        }catch(err){
            console.log("Error when submitting sku", err)
        }
    }


    useEffect(() => {
        if(open && !initialised){
            init()
        }else if(open){
            // if(initialSelection && initialSelection.length){
                // initialSelection.map(x => sku_selection_map[x] = true)
                get_initial_state_data()
                // reRenderSkuSelection()
            // }
        }
    }, [open]);

    useEffect(() => {
        // if (active_category_id && !categoryToSubcategoryMap[active_category_id]) {
        //     get_sub_category_of_active_category()
        // }else{
        //     set_active_subcategory_id(categoryToSubcategoryMap && categoryToSubcategoryMap[active_category_id] && Object.keys(categoryToSubcategoryMap[active_category_id]).length ? Object.keys(categoryToSubcategoryMap[active_category_id])[0] : '')
        // }
        set_active_subcategory_id(categoryToSubcategoryMap && categoryToSubcategoryMap[active_category_id] && Object.keys(categoryToSubcategoryMap[active_category_id]).length ? Object.keys(categoryToSubcategoryMap[active_category_id])[0] : '')

    }, [active_category_id]);

    // useEffect(() => {
    //     if(initialSelection && initialSelection.length){
    //         let initial_selection_to_sku_selection_map = {}
    //         initialSelection.map(x => initial_selection_to_sku_selection_map[x] = true)
    //         set_sku_selection_map(initial_selection_to_sku_selection_map)
    //     }else{
    //         set_sku_selection_map({})
    //     }
    // }, [initialSelection]);

    // useEffect(() => {
    //     console.log("active sub cat id", active_subcategory_id)
    // }, [active_subcategory_id]);


    return (
        <Modal className="modal-dialog-centered" size="xl" isOpen={open} toggle={handleClosefn}>
            <IModalHeader toggle={handleClosefn}>Add SKU to selection</IModalHeader>
            <IModalBody style={{ height: '600px', overflow: 'auto', display: 'flex', padding: 0, background: 'white' }}>

                {
                    // Todo Sidebar with categories
                    <div className='sku_selection_sidebar' style={{ width: '250px', height: '100%', overflow: 'auto', background: 'white' }}>
                        {
                            list_of_categories && list_of_categories.length ? list_of_categories.map((category, idx) => (
                                <div key={idx} onClick={() => { set_active_category_id(category.id) }} className={`sidebar-option ${active_category_id === category.id ? 'sidebar-option-active' : ''}`}><Checkbox className='mr-2' indeterminate={isIntermediateCat(categoryToSubcategoryMap && categoryToSubcategoryMap[category.id] ? Object.keys(categoryToSubcategoryMap[category.id]) : [])} checked={checkAllCat(category.id, (categoryToSubcategoryMap && categoryToSubcategoryMap[category.id] ? Object.keys(categoryToSubcategoryMap[category.id]) : []))} onClick={(e) => {e.stopPropagation(); onChangeCheckAllCat(e, category.id, categoryToSubcategoryMap[category.id])}}></Checkbox>{category.name}</div>
                            ))
                                : ''
                        }
                    </div>
                }

                <div style={{ height: '100%', overflow: 'auto', flex: 1}}>
                    <div style={{ position: 'sticky', top: 0, zIndex: 2 }}>
                        {
                            // Todo Search with subcat, group, sku
                            <div className='p-1 pl-2 flex_property' style={{ fontSize: '14px', color: '#A7A8B2', background: 'white', borderBottom: '1px solid #E6E9EE' }}>
                                <i className='fa fa-search mr-2' />
                                <Input type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)} placeholder='Search Group / SKU Name' className='m-0 p-0' style={{ border: 0, fontSize: '14px', color: '#A7A8B2', height: '28px' }} />
                            </div>
                        }

                        <div className='mb-3 sku_selection_sub_categories_nav_container' style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {
                                // Todo Subcat nav
                                active_category_id && categoryToSubcategoryMap[active_category_id] ? Object.keys(categoryToSubcategoryMap[active_category_id]).map((subcategory_id, idx) => (
                                    <div key={idx} onClick={() => { set_active_subcategory_id(subcategory_id) }} className={`nav-option ${active_subcategory_id === subcategory_id ? 'nav-option-active' : ''}`}><Checkbox indeterminate={isIntermediateSubCat(subcategory_id, categoryToSubcategoryMap[active_category_id][subcategory_id]["sku_group"])} checked={checkAllSubCat(subcategory_id, categoryToSubcategoryMap[active_category_id][subcategory_id]["sku_group"])} onClick={(e) => {e.stopPropagation(); onChangeCheckAllSubCat(e, subcategory_id, categoryToSubcategoryMap[active_category_id][subcategory_id]["sku_group"]) }} className='mr-2'></Checkbox>{categoryToSubcategoryMap[active_category_id][subcategory_id].name}</div>
                                ))
                                    : ''

                            }
                        </div>
                    </div>
                    <div className='p-2 sku_selection_group_container' style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            // Todo Group layout with selection
                            active_subcategory_id && active_category_id && categoryToSubcategoryMap[active_category_id] && categoryToSubcategoryMap[active_category_id][active_subcategory_id] && categoryToSubcategoryMap[active_category_id][active_subcategory_id]["sku_group"]
                                ?
                                categoryToSubcategoryMap[active_category_id][active_subcategory_id]["sku_group"].map((group, idx) => (
                                    <GroupCard key={idx} group={group} sku_selection_map={sku_selection_map} group_selection_map={group_selection_map} isIntermediateGroup={isIntermediateGroup} checkAllGroup={checkAllGroup} onChangeCheckAllGroup={onChangeCheckAllGroup} reRender={reRenderDummy} search_string={search_string}/>
                                ))
                                : ''
                        }
                    </div>
                </div>

                {/* {
                    afw_categories && afw_categories.length ? afw_categories.map(category => (
                        <div onClick={() => onclick_afw_category(category)} className={category.id == afw_active_category_id ? 'category_active flex_property' : 'flex_property'} style={{ height: '28px', fontSize: '12px', padding: '0px 12px', borderRadius: '5px', display: 'flex', cursor: 'pointer', alignItems: 'center', color: '#435A6F' }}>
                            {category.id == afw_active_category_id ? <input type='checkbox' className='mr-2' checked={selected_subcat_map[category.id]} onClick={() => toggle_subcat_selecion(category.id)} /> : ''}
                            {category.name}
                        </div>
                    )) : ''
                } */}


            </IModalBody>
            <IModalFooter>
                <Button onClick={submitSelectedSkufn} className='blue_button' >Apply</Button>
            </IModalFooter>
        </Modal>
    )
}

export default SkuSelectionModal;