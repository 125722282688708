const beautify_user_get_all = (account_details) => {
    let temp_json = {}
    account_details.map((account) => {
        temp_json[account.id] = {}
        temp_json[account.id]['id'] = account.id 
        temp_json[account.id]['first_name'] = account.first_name 
        temp_json[account.id]['last_name'] = account.last_name
        temp_json[account.id]['name'] = account.first_name + ' ' + account.last_name
        temp_json[account.id]['email'] = account.email
        temp_json[account.id]['store_roles'] = account.role
        temp_json[account.id]['sales_channel_ids'] = account.Sales_Channels && account.Sales_Channels.length ? account.Sales_Channels.filter(sales_channel => !sales_channel.business_unit_id).map(x=> x.id) : []
        let bu_details = []
        if(account.business_unit_info && Object.keys(account.business_unit_info) && Object.keys(account.business_unit_info).length){
            Object.keys(account.business_unit_info).map((single_bu_id) => {
                let bu_json  = {}
                bu_json['business_unit_id'] = single_bu_id
                bu_json['role_ids'] = account.business_unit_info[single_bu_id].map(x => x.role_id)
                bu_json['sales_channel_ids'] = account.Sales_Channels && account.Sales_Channels.length ? account.Sales_Channels.filter(sales_channel => sales_channel.business_unit_id == single_bu_id).map(x=> x.id) : []
                bu_details.push(bu_json)
            })
        }
        temp_json[account.id]['business_unit_ids_and_roles'] = bu_details
    })

    return temp_json
}

export default beautify_user_get_all