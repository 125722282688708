import React, { useState, useEffect, createContext, useContext } from 'react';

const RedirectTab = () => {

	// useEffect(() => {
	// 	const timeoutId = setTimeout(window.close(), 2000);
	//     return () => clearTimeout(timeoutId);
	// }, []);
	return(
		<>
			<div className='downtime' style={{flexDirection:'column'}}>
				<img src='/assets/img/brand/infurnia.svg' className="infurniaLogo" style={{ opacity: '0.3' }} />
				<div className='px-2' style={{fontSize:'20px'}} >
					We are verifying your payment, <br/> <span style={{color:"#47bf47"}}>you may now close this tab !</span>
				</div>
			</div>
		</>
	)
}

export default RedirectTab