import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";

const ToastError = ({}) => {
	const [toast_error, set_toast_error] = useState('');
	const alert = useAlert()
	global.set_toast_error = set_toast_error

	useEffect(() => {
		if(toast_error){
			alert.error(toast_error)
			set_toast_error('')
		}
	}, [toast_error]);

	return(<></>)
}

export default ToastError