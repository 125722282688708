import { handleError } from "./error";
import general_fetch from "./fetch";
import { decimate_model, postUploadImageExtractionfn } from "./show_3d_model";
import * as Sentry from "@sentry/react";
// const gltfPipeline = require("gltf-pipeline");

// function createGLBBuffer(gltfData) {
//     // Extract base64-encoded binary data
//     const binaryData = atob(gltfData.buffers[0].uri.split(',')[1]);
//     const binaryBuffer = new Uint8Array(binaryData.length);

//     // Populate binary buffer with the decoded binary data
//     for (let i = 0; i < binaryData.length; i++) {
//         binaryBuffer[i] = binaryData.charCodeAt(i);
//     }

//     // Create a new Uint8Array to hold the complete GLB file
//     const glbBuffer = new Uint8Array(binaryBuffer.length + JSON.stringify(gltfData).length);

//     // Copy the binary data to the beginning of the new buffer
//     glbBuffer.set(binaryBuffer, 0);

//     // Convert the JSON data to Uint8Array and append it to the new buffer
//     const jsonData = new TextEncoder().encode(JSON.stringify(gltfData));
//     glbBuffer.set(jsonData, binaryBuffer.length);

//     return glbBuffer;
// }

// async function convertGLTFBlobToGLB(gltfBlob) {
//     // Convert the Blob to a string
//     const gltfString = await gltfBlob.text();

//     // Convert glTF to GLB using Draco compression
//     const dracoLoader = new DracoLoader();
//     const glbData = await processGltf(gltfString, {
//         dracoOptions: {
//             dracoLoader: dracoLoader,
//         },
//     });

//     console.log('glb data', glbData);

//     // Return the resulting GLB data as a Blob
//     return new Blob([glbData.glb], { type: 'model/gltf-binary' });
// }

// const convertGltfToGlb = async (gltfContent) => {
//     // Replace 'your-gltf-blob' with the actual Blob containing glTF data
//     // const gltfBlob = new Blob(/* ... */);

//     // Convert the Blob to a glTF object
//     // const gltfText = await gltfBlob.text();
//     // const gltfContent = JSON.parse(gltfText);

//     // Convert glTF to glb using gltf-pipeline
//     const glbBuffer = gltfPipeline.glTFToGLB(gltfContent, {});

//     // Optionally, you can save or use the glbBuffer as needed
//     console.log("", glbBuffer);
//   };


const downloadBlob = (blob, filename) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    let rand = Math.floor(Math.random() * 1000000)
    downloadLink.download = `${rand}${filename}`;

    // Trigger the download
    downloadLink.click();

    // Clean up
    URL.revokeObjectURL(downloadLink.href);
}

const take_sku_snapshot = async (obj_half_src, mtl_half_src, server_path, glb_half_src, scale, sku_id, set_page_loader, sku_is_modular=false) => {
    try{
        console.log(window)
        var obj_src = obj_half_src?server_path + obj_half_src: null;
        var glb_src = glb_half_src ? server_path + glb_half_src : null;
        var mtl_src = mtl_half_src?server_path + mtl_half_src: null;
        console.log("model src", obj_src,glb_src,mtl_src)
        let { top_colour_image, top_grayscale_image, ...dim } = await postUploadImageExtractionfn({obj_src, mtl_src, server_path, glb_src, scale})
        console.log('initiating upload')
        console.log('initiating upload====>',top_colour_image,top_grayscale_image, dim)

        // Decimation code below
        let target_decimation = window.decimate_limit ? window.decimate_limit : 500000
        let glb = await decimate_model(glb_src, target_decimation, set_page_loader)
        // let glb_buffer = convertGltfToGlb(JSON.parse(glb))

        // console.log('buffer', glb_buffer)
        try{
            if(glb !== -1){
                const gltfBlob = new Blob([glb], { type: 'application/octet-stream' });

                // // Create a download link
                // const downloadLink = document.createElement('a');
                // downloadLink.href = URL.createObjectURL(gltfBlob);
                let rand = Math.floor(Math.random()*1000000)
                // downloadLink.download = `exported_model${rand}.glb`;

                // // Trigger the download
                // downloadLink.click();

                // Clean up
                // URL.revokeObjectURL(downloadLink.href);
                let data = new FormData()
                data.append('file', gltfBlob, `decimate_model${rand}.glb`)
                data.append('high', false);
                data.append('format', 'glb')
                
                var resp = await general_fetch({ url: 'model/upload_asset', body: data, is_form_data: true, req_resp: true })
                console.log(` resp from asset upload decimated --> ${resp.data}`);

                let body = {sku_id : sku_id , low_model_3d_id:resp && resp.data.id}
                if(sku_is_modular)
                    body['modular'] = true;
                var resp_update = await general_fetch({ url: 'sku/update', body });
            }
        }catch(err){
            handleError(err)
        }

        try{
            top_colour_image = window.dataURLtoBlob(top_colour_image)
            top_grayscale_image = window.dataURLtoBlob(top_grayscale_image)
            // downloadBlob(top_colour_image, 'top_view.jpg')
            // downloadBlob(top_grayscale_image, 'top_gray.jpg')
            await Promise.all([ window.upload_image({ type: 'colour', sku_id, upl: top_colour_image }), window.upload_image({ type: 'grayscale', sku_id, upl: top_grayscale_image }) ]);
        }catch(err){
            console.error("Could not update top view image")
            err.name = "Captured error while generating Top View Image for sku" + err.name;
            Sentry.configureScope(scope => {
				scope.setTag("sku_id", sku_id);
			});
            Sentry.captureException(err);
        }
        return {...dim}
    }catch(err){
        console.error(err)
        handleError(err)
    }
}

export default take_sku_snapshot;