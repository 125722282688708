import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useContext } from 'react';
import { NavLink as NavLinkRRD, Link ,} from "react-router-dom";
import { MenuCards } from './MenuCards';
import {Card, Col, Row,} from "reactstrap";
import general_fetch from '../../utils/fetch';
import './styles.css';
import { AllBuContext } from "../../components/Contexts/AllBuContext";
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { useAlert } from 'react-alert'
import Select from 'react-select';

const AdminHome = ({user, store_details, get_store_details}) => {
    const alert = useAlert()
    const all_bu_in_store = useContext(AllBuContext)
    const org_bu_view = useContext(OrgBuContext)
    const [bu_name_map, set_bu_name_map] = useState({})
    const [selected_bu, set_selected_bu] = useState(null)

    useEffect(() => {
		if(all_bu_in_store && all_bu_in_store.length){
			let temp_bu_map = {}
			all_bu_in_store.map(bu => {
				temp_bu_map[bu.id] = bu.name
			})
			set_bu_name_map(temp_bu_map)
		}
	}, [all_bu_in_store]);

    const chunkArray = (arr) => {
        const chunkedArr = [];
        if(arr == false) return chunkedArr

        if(arr.length % 2 == 0){
            for (let i = 0; i < arr.length; i += 2) {
            chunkedArr.push(arr.slice(i, i + 2));
            }
        }
        else {
            for (let i = 0; i < arr.length-1; i += 2) {
                chunkedArr.push(arr.slice(i, i + 2));
            }
            chunkedArr.push([arr[arr.length - 1]])
        }
        return chunkedArr;
    }

    const formatName = (name) => {
        let temp = name.replace(/_/g, " ");
        let words = temp.split(" ");
        for (let i = 0; i < words.length; i++)
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        return words.join(" ");
    }

    const is_org_level_admin = (org_roles) => {
		const admin_roles = ['project_manager', 'accounts_manager', 'catalog_manager']
		for(let i = 0; i < org_roles.length; i++){
			if(admin_roles.includes(org_roles[i])){
				return true
			}
		}
		return false
	}

    const switch_bu = async(single_bu) => {
		try{
			if(single_bu){
				let resp = await general_fetch({url:'user/set_current_business_unit', body:{business_unit_id: single_bu}})
                window.sessionStorage.setItem('org_bu_view', 'bu');
				window.location.href = global.config.admin_url
			}
		}catch(err){
			console.log(err)
			alert.error('Could not switch Business Unit!')
		}
	}

    return (
    <Main_Grid active_tab="home" store_details={store_details} get_store_details={get_store_details}>
        <Row style={{ height: '100%', overflow: 'auto'}}>
            <Col style={{display: 'flex', flexDirection: 'column', height: '100%', fontWeight: '400'}}>
                <div style={{display: 'flex', flexDirection: 'row', gap: user.role.business_unit[user.current_business_unit_id].includes('accounts_manager') ? '20px' : ''}}>
                    <Card style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', fontSize: '13px', textAlign: 'left', borderRadius: '8px', padding: '20px', marginBottom: '25px', marginTop: '10px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '6px', color: '#323338'}}>
                            <div>Welcome,</div>
                            <div style={{fontSize: '24px'}}>{formatName(user.name)}</div>
                        </div>

                        <div style={{display: 'flex', alignSelf: 'center', flexDirection: 'column', color: '#676878'}}>
                            <div>Roles:</div>
                            {org_bu_view == 'bu' || all_bu_in_store.length == 1 ?
                            <div>
                                {chunkArray(Array.from(new Set(user.role.business_unit[user.current_business_unit_id]))).map((role_item, idx) => (
                                    role_item.length == 2 ?
                                    <div style={{fontWeight: 'bold', whiteSpace: 'pre'}}>{role_item.map(item => formatName(item)).join(",  ") + (2*(idx+1) < Array.from(new Set(user.role.business_unit[user.current_business_unit_id])).length ? ',' : '')}</div>
                                    :
                                    <div style={{fontWeight: 'bold', whiteSpace: 'pre'}}>{role_item.map(item => formatName(item)).join(",  ")}</div>
                                ))
                                }
                            </div>
                              :
                            (
                            <div>
                                {chunkArray(Array.from(new Set(user.role.store))).map((role_item, idx) => (
                                    role_item.length == 2 ?
                                    <div style={{fontWeight: 'bold', whiteSpace: 'pre'}}>{role_item.map(item => formatName(item)).join(",  ") + (2*(idx+1) < Array.from(new Set(user.role.store)).length ? ',' : '')}</div>
                                    :
                                    <div style={{fontWeight: 'bold', whiteSpace: 'pre'}}>{role_item.map(item => formatName(item)).join(",  ")}</div>
                                ))
                                }
                            </div>
                            )}
                        </div>
                    </Card>
                    {/* {user.role.business_unit[user.current_business_unit_id].includes('accounts_manager') ?
                    <Card style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', fontSize: '13px', textAlign: 'left', borderRadius: '8px', padding: '20px', marginBottom: '25px', gap: '20px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between', gap: '15px'}}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <div>Remaining render credits</div>
                                <div>00000</div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <div>Due amount</div>
                                <div>00000</div>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between', gap: '15px'}}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <div>Active Users</div>
                                <div>00000</div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <div>Next billing date</div>
                                <div>00000</div>
                            </div>
                        </div>
                    </Card>
                    : ''} */}
                </div>
                {org_bu_view == 'bu' || all_bu_in_store.length == 1 ?
                <div className='home_grid'>
                    {
                    MenuCards.map((item, idx) => (
                        <Link style={{all: 'unset', aspectRatio: '1'}} to={item.url} onClick={e => {if(!user.role.business_unit[user.current_business_unit_id].includes(item.roles[0])) e.preventDefault();}}>
                            <Card className={user.role.business_unit[user.current_business_unit_id].includes(item.roles[0])?'home_card':'inactive_home_card'} style={{display: 'flex', aspectRatio: '1', flexDirection: 'column', gap: '10px', fontSize: '13px', color: '#676878', textAlign: 'left', borderRadius: '8px', padding: '20px', fontFamily: 'Inter'}}>
                                <div style={{fontSize: '18px'}}>
                                    <img src={item.icon} width='18px' height='18px'/>
                                    <div style={{display: 'inline-block', paddingLeft: '8px', fontFamily: 'DM Sans'}}>{item.title}</div>
                                </div>
                                <div style={{paddingRight: '25px'}}>
                                    {item.description}
                                </div>
                                <div className='bullets' style={{backgroundColor: '#F9FAFB', borderRadius: '6px', marginTop: '10px', fontStyle: 'italic', minHeight: '125px'}}>
                                    <ul style={{padding: '1rem 0.5rem 0rem 1.5rem'}}>
                                        {item.bullets.map(bullet => (
                                            <li>{bullet}</li>
                                        ))}
                                    </ul>
                                </div>
                                <img className='home_card_img' height='140px' width='220px' src={item.demo_image}/>
                            </Card>
                        </Link>
                    ))
                    }
                </div>
                :
                <Card style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <div className='org_view_text' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '40px'}}>
                        <div style={{fontSize: '24px', lineHeight: '31px', color: '#A7A8B2'}}>This is an Organisation view</div>

                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px'}}>
                            <div style={{marginBottom: '15px'}}>Choose any one of BU to access full admin portal</div>
                            {user && user.role && is_org_level_admin(user.role.store) ? 
                            <Select
                            options={all_bu_in_store && all_bu_in_store.length ? all_bu_in_store.map(single_bu => {return {"value": single_bu.id, "label": single_bu.name}}) : []}
                            value={selected_bu}
                            onChange={(selected_bu) => {switch_bu(selected_bu.value)}}
                            getOptionLabel={(option) => option.label}
                            placeholder='Select Business Unit'
                            /> 
                            :
                            <Select
                            options={user && user.business_unit_ids && user.business_unit_ids.length ? user.business_unit_ids.map(single_bu => {return {"value": single_bu, "label": bu_name_map && bu_name_map[single_bu] ? bu_name_map[single_bu] : single_bu}}) : []}
                            value={selected_bu}
                            onChange={(selected_bu) => {switch_bu(selected_bu.value)}}
                            getOptionLabel={(option) => option.label}
                            placeholder='Select Business Unit'
                            />
                            }
                        </div>
                    </div>

                </Card>
                }
            </Col>
        </Row>

    </Main_Grid>
    )

}

export default AdminHome;