export const MenuCards = [
    {
        title: 'Project Hub',
        icon: '/assets/icons/home_icons/project_hub.svg',
        demo_image: '/assets/icons/home_icons/demo_project_hub.svg',
        description: 'Complete Visibility over any design, project and templates.',
        bullets: ['Manage projects', 'Download output from design', 'Manage templates', 'Share projects and designs'],
        roles: ['project_manager'],
        url: '/project_hub/design_hub/',
    },
    {
        title: 'Catalogue',
        icon: '/assets/icons/home_icons/catalogue.svg',
        demo_image: '/assets/icons/home_icons/demo_catalogue.svg',
        description: 'Seamlessly Structure, manage and modify SKUs.',
        bullets: ['Manage SKUs', 'Structure catalogue in your way', 'Set various pricing settings', 'Bulk edit SKUs'],
        roles: ['catalog_manager'],
        url: '/catalogue/furniture/16963488495018527988/',
    },
    {
        title: 'Design Intelligence',
        icon: '/assets/icons/home_icons/design_intelligence.svg',
        demo_image: '/assets/icons/home_icons/demo_new_design_intelligence.png',
        description: 'Set a rule, sit aside and let the automation take care.',
        bullets: ['Design automation', 'Design constraints', 'Manufacturing rules', 'Hardware rules'],
        roles: ['catalog_manager'],
        url: '/design_intelligence/design_intelligence/',
    },
    {
        title: 'Sales Channels',
        icon: '/assets/icons/home_icons/sales_channel.svg',
        demo_image: '/assets/icons/home_icons/demo_sales_channels.svg',
        description: 'Sell SKUs in multiple settings / domain.',
        bullets: ['Subscribe to other’s stores', 'Define your own price types', 'Push your SKUs in any channels', 'Custom preferences'],
        roles: ['catalog_manager'],
        url: '/sales/',
    },
    {
        title: 'Brands & Tags',
        icon: '/assets/icons/home_icons/brands_tags.svg',
        demo_image: '/assets/icons/home_icons/demo_brands_tags.svg',
        description: 'Provides deep classification of your SKU.',
        bullets: ['Define own brands and Tags', 'Access to global brands', 'Group cross category SKUs with tag / brands'],
        roles: ['catalog_manager'],
        url: '/tags/',
    },
    {
        title: 'Pref.. & Design app Con..',
        icon: '/assets/icons/home_icons/design_app_config.svg',
        demo_image: '/assets/icons/home_icons/demo_pref_design.png',
        description: 'Define your preferred default Values and features in design app.',
        bullets: ['Reduces designers work.', 'Manage all feature access', 'More than 2000+ feature flags', 'Set Org’s default preferences'],
        roles: ['catalog_manager'],
        url: '/preferences_settings/org_preference/',
    },
    {
        title: 'Settings',
        icon: '/assets/icons/home_icons/settings.svg',
        demo_image: '/assets/icons/home_icons/demo_settings_new.png',
        description: 'All other additional setting required for the users',
        bullets: ['Room types & project status', 'Define manufacturing tools.', 'Service-charge & scheduled pay', 'Define terms & conditions, more..'],
        roles: ['catalog_manager'],
        url: '/preferences_settings/pricing_settings/',
    },
    {
        title: 'User Manage. & Billing',
        icon: '/assets/icons/home_icons/user_management.svg',
        demo_image: '/assets/icons/home_icons/demo_users.svg',
        description: 'Manage user addition, removal and access level easily',
        bullets: ['Adding user to organisation / BU', 'Roles and access levels', 'visibility over usage of design.', 'Insights on credits and payment'],
        roles: ['accounts_manager'],
        url: '/billing/users/',
    }
]