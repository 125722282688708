import React from 'react';
import './style.css';

export const ITableHeader = ({ headers, children, style }) => {
    return (
        <thead style={{...style}}>
            <tr className='infurnia-table-header-row'>
                {
                    headers ?
                        headers.map((header, index) => (
                            <ITH key={index}>{header}</ITH>
                        ))
                        :
                        children
                }
            </tr>
        </thead>
    );
};

export const ITableRow = ({ rowData, children, ...props }) => {
    return (
        <tr className='infurnia-table-row' {...props}>
            {
                rowData ?
                    rowData.map((cell, index) => (
                        <ITD key={index}>{cell}</ITD>
                    ))
                    :
                    children
            }
        </tr>
    );
};

export const ITD = ({ children, style, className }) => {
    return <td className={`infurnia-table-cell ${className ? className : ''}`} style={{...style}}>{children}</td>;
};

export const ITH = ({ children, style }) => {
    return <th className='infurnia-table-header' style={{...style}}>{children}</th>;
};

export const ITableBody = ({ children, style }) => {
    return <tbody className='infurnia-table-body' style={{...style}}>{children}</tbody>
}

export const ITable = ({ headers, data, bordered, borderless, striped, dark, hover, responsive, rowSeparated, coloumnSeparated, children, style, columnWidths, style_container }) => {
    const tableClassName = `infurnia-table${bordered ? ' infurnia-table-bordered' : ''}${borderless ? ' infurnia-table-borderless' : ''}${striped ? ' infurnia-table-striped' : ''
        }${dark ? ' infurnia-table-dark' : ''}${hover ? ' infurnia-table-hover' : ''}${rowSeparated ? ' infurnia-table-row-separated' : ''}${coloumnSeparated ? ' infurnia-table-column-sparated' : ''}`;

    return (
        <div className={`${responsive ? 'table-responsive' : ''}`} style={{...style_container}}>
            {
                headers && data ?
                    <table className={tableClassName} style={{...style, gridTemplateColumns: columnWidths}}>
                        <ITableHeader headers={headers} />
                        <tbody>
                            {data.map((row, index) => (
                                <ITableRow key={index} rowData={row} />
                            ))}
                        </tbody>
                    </table>
                    :
                    <table className={tableClassName} style={{...style, gridTemplateColumns: columnWidths}}>
                        {children}
                    </table>
            }

        </div>
    );
};