import * as Sentry from "@sentry/react";

export class FetchError extends Error {
    constructor(message, url, response, request_id) {
        super(message);
        this.name = 'FetchError';
        this.url = url
        this.response = response;
        this.request_id = request_id
    }
}

export class NetworkError extends Error {
    constructor(message, url, response) {
        super(message);
        this.name = 'NetworkError';
        this.url = url
        this.response = response
    }
}

export class ExceptionEventError extends Error {
    constructor(message, code, response) {
        super(message);
        this.name = 'ExceptionEventError';
        this.code = code
        this.response = response
    }
}

export class captureAction extends Error {
    constructor(message, additionalData) {
        super(message);
        this.name = 'captureAction';
        this.additionalData = additionalData
    }
}


export const handleError = (error, alert, message) => {
    if(error instanceof NetworkError || error instanceof ExceptionEventError || error instanceof FetchError){
        // FetchError already handled in fetch.js
    }else if(error instanceof captureAction){
        console.warn(`data for issue - ${error.message} `, error.additionalData)
        Sentry.captureMessage(error.message, {fingerprint: [error.message]})
    }else{
        console.error(`Error - `, error)
        Sentry.captureException(error)
    }
}

export const logToSentry = (action) => {
    if(typeof(action) === "string"){
        Sentry.captureMessage(action, {fingerprint: [action]})
    }else{
        Sentry.captureEvent(action)
    }
}