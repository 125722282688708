import React , { useState , useEffect }from 'react';
import { Modal } from 'reactstrap';
import { IModalBody, IModalFooter, IModalHeader } from '../IModal';
import AddAndUpdateMaterialProperties from '../AddAndUpdateMaterialProperties';
import IButton from '../IButton';
import { useAlert } from 'react-alert';
import { add_material, update_material } from '../../utils/api/material';
import { handleError } from '../../utils/error';

const Add_new_material = ({open, handle_close, add_id, mode, initial_material_details, callbackOnChangeBaseTextureUrl, callbackOnMaterialUpdate }) => {
	const alert = useAlert()
	const [ parsed_material_properties, set_parsed_material_properties ] = useState('');
	const [ base_property_selected, set_base_property_selected ] = useState('color');
	const [ selected_material_template, set_selected_material_template ] = useState('');

	const saveMaterial = async() => {
		try{
			if((parsed_material_properties && base_property_selected === "texture" && !parsed_material_properties.map)){
				alert.error("Please upload a texture file")
				return 
			}
			if(base_property_selected === "color"){
				parsed_material_properties.map = ""
			}
			if(mode === "add"){
				// Add Material
				let material_details = await add_material(parsed_material_properties, selected_material_template)
				if(callbackOnMaterialUpdate){
					callbackOnMaterialUpdate(material_details)
				}
			}else{
				let material_details = await update_material(initial_material_details.id, parsed_material_properties, selected_material_template)
				if(callbackOnMaterialUpdate){
					callbackOnMaterialUpdate(material_details)
				}
			}
			handle_close()
		}catch(err){
			alert.error("Unable to save material")
			console.error(`Error in saveMaterial `, err)
			handleError(err);
		}
	}

	return(
		<Modal centered isOpen={open} size='xl'>
			<IModalHeader toggle={handle_close}>Material Properties</IModalHeader>
			<IModalBody style={{height: '500px', maxHeight: 'calc(100vh - 200px)'}}>
				<AddAndUpdateMaterialProperties customId={add_id} initial_material_details={initial_material_details} callbackOnMaterialPropertiesUpdate={set_parsed_material_properties} callbackOnBasePropertyUpdate={set_base_property_selected} callbackOnMaterialTemplateUpdate={set_selected_material_template}/>
			</IModalBody>
			<IModalFooter>
				<IButton color={'primary'} onClick={saveMaterial}>Save Changes</IButton>
			</IModalFooter>
		</Modal>
	)
}

// const Add_new_material = ({open , set_return_material_details, active_material_template, handle_close, material_template, texture_json, material_mode, set_page_loader, update_sku_array, sku_array_idx, base_texture_url, set_base_texture_url, callbackOnMaterialAdd, add_id=''}) => {

//     const [updated_template , set_updated_template] = useState('');
// 	const [properties, set_properties] = useState('');
// 	const [editing_current_material, set_editing_current_material] = useState(false)

// 	const [parsed_properties, set_parsed_properties] = useState({});
// 	const [header_properties, set_header_properties] = useState({});
// 	const [is_color, set_is_color] = useState(true);


// 	const [roughness, set_roughness] = useState(-1);
// 	const [metalness, set_metalness] = useState(-1);
// 	const [specular, set_specular] = useState(-1);
// 	const [color, set_color] = useState('#000000');

// 	const [material_preview_src, set_material_preview_src] = useState('');
// 	const wasm_loaded = useContext(WasmLoadedContext)

// 	const alert = useAlert()

// 	const grouped_properties = {
// 		"Base" : [
// 			"map",
// 			"texture_wrapping",
// 			"texture_width",
// 			"texture_height",
// 			"color"
// 		],
//         "Other maps" : [
// 			"displacement_map",
// 			"normal_map"
// 		],
// 		"Reflection" : [
// 			"specular",
// 			"roughness",
// 			"metalness"
// 		],
// 		"Refraction" : [
// 			"refractive_index",
// 			"transmission",
// 		],
// 		// "Opacity" : [
// 		// 	"opacity",
// 		// ],
// 		"Emmissivity" : [
// 			"emissive_intensity",
// 		]
// 	}

// 	const properties_order = {
// 		"map": 0,
// 		"texture_wrapping" : 1,
// 		"texture_width" : 2,
// 		"texture_height": 3,

// 		"bump_map": 0,
// 		"normal_map": 1,

// 		"specular": 0,
// 		"roughness": 1,
// 		"metalness": 2,

// 		"transmission": 0,
// 		"refractive_index": 1,

// 		"emissive_intensity": 0,

// 	}

// 	const keysGroupedObject = Object.keys(grouped_properties)

// 	// useEffect(() => {
// 	// 	console.log("KPP material_template inside is", material_template)
// 	// })

//     useEffect(() => {
//         if(material_template && material_template.length && open && material_mode=='add'){
//             set_updated_template(material_template[0])
//             console.log("Entered HERE", material_template)
//         }
//     },[open])

//     useEffect(() => {
//         if(active_material_template && material_mode==='edit' || material_mode==='update_template'){
// 			let temp = JSON.parse(JSON.stringify(active_material_template))
// 			let temp1 = JSON.parse(JSON.stringify(active_material_template));
// 			if(temp && temp1 && temp1.material_template && temp1.material_template.properties)
// 				temp.properties = JSON.parse(JSON.stringify(temp1.material_template.properties))
// 			if(temp && temp.properties && temp.properties.length){
// 				temp.properties.map(item => {
// 					item.value.value = active_material_template.properties[item.label]
// 					return item
// 				})
// 			}
//             set_updated_template(temp)
//         }
//     },[active_material_template, material_mode, open])

//     useEffect(() => {
//         if(updated_template){
//             if (material_mode==='edit') {
// 				var x=JSON.parse(JSON.stringify(updated_template));
// 				let parsed_property_json = {}
// 				if(updated_template.properties && updated_template.properties.length){
// 					let temp = updated_template.properties.map(item => {
// 						parsed_property_json[item.label] = item.value.value;
// 						return item;
// 					})
// 				} else{
// 					Sentry.captureEvent('updated template on clicking edit material in add new finish sku has empty properties, updated_template = '+JSON.stringify(updated_template))
// 				}
//                 x.properties = x.properties && x.properties.length ? x.properties.map( property => {
// 					if(property.final_value)
// 						return {...property, wrong_value_flag:false}
//                     return {...property, final_value: property.value.value!=null?property.value.value:'' ,wrong_value_flag:false}
//                 }):[]
// 				set_parsed_properties(parsed_property_json)
//                 set_properties(x.properties)
//             }else{   
// 				var x=JSON.parse(JSON.stringify(updated_template));
//                 // var x=updated_template;
// 				let parsed_property_json = {}
//                 let temp = updated_template.properties.map(item => {
//                     parsed_property_json[item.label] = item.value.value;
//                     return item;
//                 })
//                 x.properties = x.properties && x.properties.length ? x.properties.map( property => {
// 					if(property.final_value)
// 						return {...property, wrong_value_flag:false}
//                     return {...property, final_value: property.value.value!=null?property.value.value:'' ,wrong_value_flag:false}
//                 }):[]
// 				set_parsed_properties(parsed_property_json)
//                 set_properties(x.properties)
//             }
//         }
//     },[updated_template])

//     const get_return_material_details = async(id) => {
// 		try{
// 			//loader
// 			var identifiers = JSON.stringify({id:id})
// 			var attributes = JSON.stringify({})
// 			var response = await general_fetch({url :'material/get' , body: {identifiers, attributes} }) 
// 			set_return_material_details(response && response.length ?response[0]:[])
//             // onclick_update_for_update_sku(response && response.length ?response[0]:[])
// 		}catch(err){
// 			console.log(err)
// 		}
// 	}

//     const onclick_add_material = async () => {
// 		if(!is_color && !base_texture_url)
// 			alert.error('Upload a Texture image or switch to Color option')
// 		else if( updated_template){
// 			try {
//                 var bp = properties && properties.length && properties.reduce((final, elem)=> {
//                     return {
//                         ...final, [elem.label]: elem.final_value
//                     }
//                 }, {});
//                 console.log("bp",bp)
//                 console.log('updated_template',updated_template);
//                 var body={
//                     name:'material', 
//                     properties:JSON.stringify(bp), 
//                     material_template_id: material_mode==='edit' ? updated_template.material_template.id : updated_template.id
//                 }
//                 let material_id = null;
// 				let resp;
//                 if (material_mode==='edit' || material_mode === 'update_template') {
//                     material_id = updated_template.id
//                     body['id']= material_id
//                     resp = await general_fetch({ url: 'material/update', body });
//                 } else {
//                     resp = await general_fetch({ url: 'material/add', body });
//                     material_id = resp.id;
//                 }
//                 console.log('material_id ===> ', material_id);
//                 get_return_material_details(material_id)
//                 if(update_sku_array && sku_array_idx>=0) update_sku_array(sku_array_idx, 'material_id', material_id)
// 				if(callbackOnMaterialAdd){
// 					callbackOnMaterialAdd(resp)
// 				}
// 				onclick_handleclose();
				
// 			}catch(err) {
// 				console.log(err);
// 				alert.error('Error , Unable to add Material')
// 			}
// 		}else{
// 			alert.error('Please fill all required details')
// 		}
// 	}

//     // const get_current_material_details = async() => {
// 	// 	try{
// 	// 		//loader
// 	// 		var identifiers = JSON.stringify({id:updated_template.id})
// 	// 		var attributes = JSON.stringify({})
// 	// 		var response = await general_fetch({url :'material/get' , body: {identifiers, attributes} }) 
// 	// 		set_active_material_template(response && response.length ?response[0]:'')
// 	// 		console.log("get material", response)
// 	// 		let texture_id = response[0].properties.map
			
// 	// 		var texture_get = await general_fetch({url:'texture/get', body:{identifiers:JSON.stringify({id:[texture_id]})}})
//     //     	if(texture_get) set_base_texture_url(texture_get[0] && texture_get[0].Image ? texture_get[0].Image.image :'')
// 	// 	}catch(err){
// 	// 		console.log(err)
// 	// 	}
// 	// }
	
// 	// const onclick_restore_template_values = () => {
//     //     get_current_material_details()
//     //     set_material_mode('edit')
//     // }

// 	// useEffect(() =>{
// 	// 	if(return_confirm_low_resp_data){
// 	// 		alert.success('Model files loaded successfully')
// 	// 	}
// 	// },[return_confirm_low_resp_data])

// 	const downloadFile = (url, fileName) => {
// 		const anchor = document.createElement('a');
// 		anchor.href = global.config.server_path + url;
// 		anchor.download = fileName;

// 		// Trigger a click on the anchor element to start the download
// 		document.body.appendChild(anchor);
// 		anchor.click();
// 		document.body.removeChild(anchor);
// 	};

//     const onclick_handleclose = () => {
// 		set_is_color(true)
// 		set_base_texture_url('')
//         handle_close()
// 		delete_texture_from_properties()
//         set_updated_template()
//     }

//     const onchange_material_template = (id) => {
//         if(id){
//             var selected_template = material_template && material_template.find( x => x.id===id )
//             console.log('setting updated template ---> ', id, selected_template);
// 			console.log('updated template', updated_template)
// 			console.log('parsed properties', parsed_properties)
// 			let props = selected_template.properties
// 			console.log("properties are", props)
// 			let temp_props = new Array(props.length)
// 			props && props.length && props.map((prop, idx) => {
// 				if(prop.label !== 'map' && prop.label != 'color' && prop.label != 'bump_map' && prop.label != 'normal_map'){
// 					temp_props[idx] = JSON.parse(JSON.stringify({...prop, final_value: prop.value.value}));
// 				}else{
// 					temp_props[idx] = (parsed_properties && parsed_properties[prop.label]) ? updated_template.properties.find(item => item.label == prop.label) : JSON.parse(JSON.stringify({...prop, final_value: prop.value.value}))
// 					if(parsed_properties && parsed_properties[prop.label]){
// 						if(temp_props[idx] && temp_props[idx].value)
// 							temp_props[idx].value.value = parsed_properties[prop.label]
// 						if(temp_props[idx])
// 							temp_props[idx].final_value = parsed_properties[prop.label]
// 					}
// 				}
// 			});
// 			console.log("temp_props are", temp_props)
//             if(material_mode == 'edit') set_updated_template(selected_template? JSON.parse(JSON.stringify({ ...updated_template, properties: temp_props, material_template: selected_template })) : JSON.parse(JSON.stringify({ ...updated_template, properties: temp_props, material_template: null })));
//             if(material_mode == 'add') set_updated_template(selected_template? JSON.parse(JSON.stringify({...selected_template, properties: temp_props})) : null);
//         }
//     }

// 	const parse_property = (property) => {
// 		let properties_list = grouped_properties[property];
// 		let preview = ''
// 		let defaultState = false
// 		let initialized = false
// 		properties_list.map(x => {
// 			preview = preview + `${x.replace('_', ' ')}: ${typeof(parsed_properties[x]) == 'number' ? Math.round(parsed_properties[x]*100)/100 : parsed_properties[x]} `
// 			if(parsed_properties[x]){
// 				defaultState = true
// 			}
// 		})
// 		preview = `(${preview})`

// 		let header = <div className='flex_property'>{property} &nbsp; <div style={{textTransform:'capitalize', color: COLORS.gray5}}>{preview}</div></div>

// 		return {header, defaultState, initialized}


// 	}

// 	const delete_texture = () => {
// 		// set_return_material_details('')
// 		let property = properties.find(o => o.label === 'map')
//         property.final_value = '';
// 		if(property && property.value)
// 			property.value.value = '';
// 		set_base_texture_url('');
//         set_properties(properties)
//         if(parsed_properties && set_parsed_properties){
//             parsed_properties[property.label] = ''
//             set_parsed_properties(JSON.parse(JSON.stringify(parsed_properties)))
//         }
// 	}

// 	const delete_texture_from_properties = () => {
// 		// set_return_material_details('')
// 		let property = properties.find(o => o.label === 'map')
//         property.final_value = '';
// 		if(property && property.value)
// 			property.value.value = '';
//         set_properties(properties)
//         if(parsed_properties && set_parsed_properties){
//             parsed_properties[property.label] = ''
//             set_parsed_properties(JSON.parse(JSON.stringify(parsed_properties)))
//         }
// 	}

// 	// useEffect(() => {
// 	// 	if(is_ap_open){
// 	// 		let t = myRef.current?.scrollIntoView({behavior: 'smooth'})
// 	// 	}
// 	// }, [is_ap_open]);

// 	function hexToRgb(hex) {
// 		// Remove the leading #
// 		hex = hex.replace('#', '');
		
// 		// Parse the hex values for red, green, and blue
// 		const r = parseInt(hex.substring(0, 2), 16);
// 		const g = parseInt(hex.substring(2, 4), 16);
// 		const b = parseInt(hex.substring(4, 6), 16);
		
// 		// Return the RGB values as an object
// 		return {
// 		  r: r,
// 		  g: g,
// 		  b: b
// 		};
// 	  }

// 	useEffect(() => {
// 		if (window.enable_wasm && wasm_loaded === 1) {
// 			try {
// 				let colorRGB = hexToRgb(color)
// 				// console.log(global.config.admin_url, base_texture_url ? build_path(global.config.server_path, base_texture_url) : '', colorRGB.r / 256, colorRGB.g / 256, colorRGB.b / 256, metalness, roughness, specular)
// 				// window.Module.init_material_preview_texture(global.config.admin_url, base_texture_url ? build_path(global.config.server_path, base_texture_url) : '', colorRGB.r / 256, colorRGB.g / 256, colorRGB.b / 256, metalness, roughness, specular)
// 				// window.Module.init_sphere_texture(base_texture_url, 0, 0, 0, properties.find(x => x.label == 'roughness') ? properties.find(x => x.label == 'roughness').final_value : 0, properties.find(x => x.label == 'metalness') ? properties.find(x => x.label == 'metalness').final_value : 0)
// 				window.Module.prepare_texture(base_texture_url ? build_path(global.config.server_path, base_texture_url) : '');
// 				window.Module.draw_material_preview(metalness, roughness, specular, colorRGB.r / 256, colorRGB.g / 256, colorRGB.b / 256)
// 				window.Module.draw_material_preview(metalness, roughness, specular, colorRGB.r / 256, colorRGB.g / 256, colorRGB.b / 256)

// 			}
// 			catch (err) {
// 				console.error("Material init preview failed", err)
// 			}

// 			if (document.getElementById('webgl_sphere_canvas')) {
// 				set_material_preview_src(document.getElementById('webgl_sphere_canvas').toDataURL())
// 			}
// 		}

// 	}, [base_texture_url]);

//     // useEffect(() => {
// 	// 	if(active_material_template){
// 	// 		var x=active_material_template.material_template ? active_material_template.material_template:{};
// 	// 		let parsed_property_json = {}
//     //             let temp = active_material_template.properties.map(item => {
//     //                 parsed_property_json[item.label] = item.value.value;
//     //                 return item;
//     //             })
// 	// 		x.properties = x.properties && x.properties.length ? x.properties.map( property => {
// 	// 			return {...property, final_value:active_material_template.properties?active_material_template.properties[property.label]:null, wrong_value_flag:false}
// 	// 		}):[]
// 	// 		set_properties(x.properties)
// 	// 		set_parsed_properties(parsed_property_json)
// 	// 	}
// 	// }, [active_material_template]);


// 	useEffect(() => {
// 		if (roughness != -1 && metalness != -1 && specular != -1 && color && window.enable_wasm && wasm_loaded === 1) {
// 			console.log("draw sprere being called")
// 			let colorRGB = hexToRgb(color)
// 			window.Module.draw_material_preview(metalness, roughness, specular, colorRGB.r / 256, colorRGB.g / 256, colorRGB.b / 256)
// 			// window.Module.init_sphere_texture(base_texture_url, 0, 0, 0, properties.find(x => x.label == 'roughness') ? properties.find(x => x.label == 'roughness').final_value : 0, properties.find(x => x.label == 'metalness') ? properties.find(x => x.label == 'metalness').final_value : 0)
// 		}
// 		else {
// 			console.log("draw sphere not triggered")
// 		}

// 		if (document.getElementById('webgl_sphere_canvas')) {
// 			set_material_preview_src(document.getElementById('webgl_sphere_canvas').toDataURL())
// 		}

// 	}, [roughness, metalness, color, specular]);

// 	useEffect(() => {
// 		// console.log(parsed_properties, "parsed_properties material")
// 		if(parsed_properties){
// 			let temp_default_property_state = JSON.parse(JSON.stringify(header_properties))
// 			let temp = keysGroupedObject && keysGroupedObject.length ? keysGroupedObject.map((property, idx) => {
// 				let parsed_property = parse_property(property)
// 				// let header = parsed_property.header
// 				let defaultState = parsed_property.header
// 				temp_default_property_state[property] = defaultState
// 			}) : ''
// 			// console.log(temp_default_property_state, "default state when modal gets opened")
// 			set_header_properties(temp_default_property_state)
// 			if(parsed_properties.map){
// 				set_is_color(false)
// 			}else{
// 				set_is_color(true)
// 			}
// 		}
// 	}, [parsed_properties]);

	
// 	return(
//          <Modal isOpen={open} size='xl'>
//         <IModalHeader toggle={() => {onclick_handleclose();}}>
// 			Material Properties
//         </IModalHeader>
// 					<ModalBody className='global_modal_body'>
// 					{
// 						(	
// 							<>
// 								<div className='div_box' style={{height: 'unset', maxHeight: '100%', width:'1160px', marginTop:'10px', borderBottom:editing_current_material?'1px solid white':'1px solid #C5C7CF'}}>
// 									<div style={{flex:'1', display:'flex', flexDirection:'column', padding:'5px 20px 5px 5px'}}>
// 										<div style={{fontSize:'14px', marginBottom:'5px'}}>Material Preview</div>
// 										{
// 											!(window.enable_wasm && (wasm_loaded === 1))?
// 												base_texture_url
// 													?
// 													<img width='265px' height='265px' style={{ marginBottom: '20px', backgroundColor: typeof (parsed_properties.color) == 'string' ? parsed_properties.color : '#000000', color: typeof (parsed_properties.color) == 'string' ? parsed_properties.color : '#000000' }} alt='Base Color' src={base_texture_url ? build_path(global.config.server_path, base_texture_url) : ''} />
// 													:
// 													<div style={{ marginBottom: '20px',width:'265px', height:'265px', backgroundColor: typeof(parsed_properties.color) == 'string' ? parsed_properties.color : '#000000', color: typeof(parsed_properties.color) == 'string' ? parsed_properties.color : '#000000'}}/>
// 												// <img width='265px' height='265px' style={{ marginBottom: '20px', backgroundColor: parsed_properties.color }} src={base_texture_url ? build_path(global.config.server_path, base_texture_url) : '/assets/img/icons/empty_image.png'} />
// 												:
// 												<React.Fragment>
// 													<img width='265px' height='265px' style={{}} src={material_preview_src} />
// 													<div style={{fontSize:'12px', marginTop:'5px', color: COLORS.gray6, textAlign: 'center'}}>(Preview based on Base Texture, Base Color, Metalness, Roughness and specular)</div>
// 												</React.Fragment>
// 										}

// 										{/* <div style={{fontSize:'14px', marginBottom:'5px'}}>Material Name</div>
// 										<Input  type='text' style={{height:'32px',pointerEvents:editing_current_material?'auto':'none'}}/> */}
// 									</div>
// 									<div style={{borderLeft:'1px solid #E6E9EE', /*height:editing_current_material?'320px':'340px'*/ height: '320px', marginTop:'10px'}} />
// 									<div style={{flex:'3', padding:'10px 20px 20px 20px', overflow:'auto'}}>
// 										<div>
// 										{
//                                 // !advanced_parameters_collapse ?
//                                 ['Base', 'Other maps'].map((label, idx) => {
//                                     return (
//                                         <UncontrolledCollapse defaultState={label == 'Base' ? true : false} header={label != 'Base' && label != 'Other maps' && header_properties && header_properties[label] ? header_properties[label] : label} className='mb-2'>
//                                             <div>
//                                                 <div className={label == 'Base' ? 'flex_property' : ''}>
//                                                 {
//                                                     label == 'Base' ? 
//                                                         <>
//                                                             <div className='mr-3'>From</div>
//                                                             <Input type='select' value={is_color ? 'color' : 'texture'} onChange={(e) => { set_is_color(e.target.value == 'color'); if(e.target.value == 'color'){delete_texture()} }} style={{ width: 'fit-content', fontSize: '12px', marginRight: '32px' }}>
//                                                                 <option value={'color'}>Color</option>
//                                                                 <option value={'texture'}>Texture</option>
//                                                             </Input>
//                                                         </>
//                                                     :''
//                                                 }
//                                                 {
//                                                     label == 'Base' && !is_color && properties && properties.length && properties.find(o => o.label === 'map') ?
//                                                         <Property_Tab
//                                                             key={'map'}
//                                                             property={properties.find(o => o.label === 'map')}
//                                                             properties={properties}
//                                                             set_properties={set_properties}
//                                                             set_page_loader={set_page_loader}
//                                                             texture_json={texture_json}
//                                                             input_size='small'
//                                                             set_base_texture_url={set_base_texture_url}
//                                                             // disabled_input={!editing_current_material}
//                                                             parsed_properties={parsed_properties}
//                                                             set_parsed_properties={set_parsed_properties}
//                                                             compact_style={true}
//                                                             set_roughness={set_roughness}
//                                                             set_metalness={set_metalness}
//                                                             set_color={set_color}
//                                                             set_specular={set_specular}
// 															add_id={add_id}
//                                                         // final_update_fn={final_update_fn}
//                                                         /> : ''
//                                                 }
//                                                 {
//                                                     label == 'Base' && is_color && properties && properties.length && properties.find(o => o.label === 'color') ?
//                                                         <Property_Tab
//                                                             key={'color'}
//                                                             property={properties.find(o => o.label === 'color')}
//                                                             properties={properties}
//                                                             set_properties={set_properties}
//                                                             set_page_loader={set_page_loader}
//                                                             // texture_json={texture_json}
//                                                             input_size='small'
//                                                             set_base_texture_url={set_base_texture_url}
//                                                             // disabled_input={!editing_current_material}
//                                                             parsed_properties={parsed_properties}
//                                                             set_parsed_properties={set_parsed_properties}
//                                                             compact_style={true}
//                                                             set_roughness={set_roughness}
//                                                             set_metalness={set_metalness}
//                                                             set_color={set_color}
//                                                             set_specular={set_specular}
// 															add_id={add_id}
//                                                         // final_update_fn={final_update_fn}
//                                                         /> : ''
//                                                 }
//                                                 <Row style={{display: 'flex'}}>
//                                                 {
//                                                     label == 'Other maps' && properties && properties.length && properties.find(o => o.label === 'bump_map') ?
//                                                     <>
//                                                     <Col className="text-center align-self-end" style={{paddingRight: '10px' }}>
//                                                     <Property_Tab
//                                                     key={'bump_map'}
//                                                     property={properties.find(o => o.label === 'bump_map')}
//                                                     properties={properties}
//                                                     set_properties={set_properties}
//                                                     set_page_loader={set_page_loader}
//                                                     input_size='small'
//                                                     // disabled_input={!editing_current_material}
//                                                     parsed_properties={parsed_properties}
//                                                     set_parsed_properties={set_parsed_properties}
//                                                     compact_style={true}
//                                                     set_roughness={set_roughness}
//                                                     set_metalness={set_metalness}
//                                                     set_color={set_color}
//                                                     set_specular={set_specular}
//                                                     title={'Displacement Map'}
// 													add_id={add_id}
//                                                 // final_update_fn={final_update_fn}
//                                                 /> 
//                                                     </Col>
//                                                 </> : ''
//                                                 }
//                                                 {
//                                                     label == 'Other maps' && properties && properties.length && properties.find(o => o.label === 'normal_map') ?
//                                                     <>
//                                                     <div style={{borderLeft: '1px solid #E6E9EE', height: '64px', paddingRight: '5px'}}></div>
//                                                     <Col className="text-center align-self-end" >
//                                                     <Property_Tab
//                                                     key={'normal_map'}
//                                                     property={properties.find(o => o.label === 'normal_map')}
//                                                     properties={properties}
//                                                     set_properties={set_properties}
//                                                     set_page_loader={set_page_loader}
//                                                     input_size='small'
//                                                     // disabled_input={!editing_current_material}
//                                                     parsed_properties={parsed_properties}
//                                                     set_parsed_properties={set_parsed_properties}
//                                                     compact_style={true}
//                                                     set_roughness={set_roughness}
//                                                     set_metalness={set_metalness}
//                                                     set_color={set_color}
//                                                     set_specular={set_specular}
//                                                     title={'Normal Map'}
// 													add_id={add_id}
//                                                 // final_update_fn={final_update_fn}
//                                                 />
//                                                 {/* <div>Displacement Map</div>  */}
//                                                     </Col>
//                                                 </> : ''
//                                                 }
//                                                 </Row>
//                                                 </div>
//                                                 <div className={label == 'Base' ? 'flex_property' : ''} style={{gap: '16px'}}>
//                                                 {
//                                                     label == 'Base' && properties && properties.length ? properties.filter(x => grouped_properties[label].includes(x.label)).map((property, idx) => {
//                                                         if (!((label == 'Base' && is_color && property.label !='color') || (label == 'Base' && !is_color && property.label =='color')) && property.label !='map' && property.label != 'color') {
//                                                             return (
//                                                                 <Property_Tab
//                                                                     key={idx}
//                                                                     property={property}
//                                                                     properties={properties}
//                                                                     set_properties={set_properties}
//                                                                     set_page_loader={set_page_loader}
//                                                                     texture_json={texture_json}
//                                                                     input_size='small'
//                                                                     set_base_texture_url={set_base_texture_url}
//                                                                     parsed_properties={parsed_properties}
//                                                                     set_parsed_properties={set_parsed_properties}
//                                                                     compact_style={true}
//                                                                     set_roughness={set_roughness}
//                                                                     set_metalness={set_metalness}
//                                                                     set_color={set_color}
//                                                                     set_specular={set_specular}
// 																	add_id={add_id}
//                                                                 // final_update_fn={final_update_fn}
//                                                                 />
//                                                             )
//                                                         }
//                                                     }
//                                                     )
//                                                         : ''
//                                                 }
//                                                 </div>
//                                             </div>
//                                         </UncontrolledCollapse>
//                                     )
//                                 })
//                                     // : ''
//                                 // : ''
//                         }

// 											<div style={{ fontSize: '14px', marginBottom: '5px' }}><b>Select a type of Material Template &nbsp;</b><span style={{color: '#A7A8B3'}}>(Selected template value will be overridden in the below fields)</span></div>
// 											<div className='mb-3' style={{ width: '50%',  /*pointerEvents:editing_current_material?'auto':'none' */ }}>
//                                                 {material_mode == 'add'?
// 												<SingleSelect 
// 												options={material_template ? material_template :[]}
// 												selectedValue = {updated_template ? updated_template :null}
// 												onItemClick = {(selectedValue) => {onchange_material_template(selectedValue.id)}}
// 												placeholder = {"Select Material Type"}
// 												displayValue = {'name'}
// 												id={"material_template_select"+add_id}
// 												/>
//                                                 :
//                                                 <SingleSelect 
// 												options={material_template ? material_template :[]}
// 												selectedValue = {updated_template && updated_template.material_template ? updated_template.material_template :null}
// 												onItemClick = {(selectedValue) => {onchange_material_template(selectedValue.id)}}
// 												placeholder = {"Select Material Type"}
// 												displayValue = {'name'}
// 												id={"material_template_select"+add_id}
// 												/>}
// 											</div>

// {
// 											// !advanced_parameters_collapse ?
// 												keysGroupedObject && keysGroupedObject.length ? keysGroupedObject.filter(key => key != 'Base' && key != 'Other maps').map((label, idx) => {
// 													return (
// 														<UncontrolledCollapse key={idx} header={label != 'Base' && header_properties && header_properties[label] ? header_properties[label] : label} className='mb-2'>
// 															<div>
// 																<div className={label == 'Base' ? 'flex_property' : ''}>
// 																{
// 																	label == 'Base' ? 
// 																		<>
// 																			<div className='mr-3'>From</div>
// 																			<Input type='select' value={is_color ? 'color' : 'texture'} onChange={(e) => { set_is_color(e.target.value == 'color'); if(e.target.value == 'color'){delete_texture()} }} style={{ width: 'fit-content', fontSize: '12px', marginRight: '32px' }}>
// 																				<option value={'color'}>Color</option>
// 																				<option value={'texture'}>Texture</option>
// 																			</Input>
// 																		</>
// 																	:''
// 																}
// 																{
// 																	label == 'Base' && !is_color && properties && properties.length && properties.find(o => o.label === 'map') ?
// 																		<Property_Tab
// 																			key={'map'}
// 																			property={properties.find(o => o.label === 'map')}
// 																			properties={properties}
// 																			set_properties={set_properties}
// 																			set_page_loader={set_page_loader}
// 																			texture_json={texture_json}
// 																			input_size='small'
// 																			set_base_texture_url={set_base_texture_url}
// 																			// disabled_input={!editing_current_material}
// 																			parsed_properties={parsed_properties}
// 																			set_parsed_properties={set_parsed_properties}
// 																			compact_style={true}
// 																			set_roughness={set_roughness}
// 																			set_metalness={set_metalness}
// 																			set_color={set_color}
// 																			set_specular={set_specular}

// 																		// final_update_fn={final_update_fn}
// 																		/> : ''
// 																}
// 																{
// 																	label == 'Base' && is_color && properties && properties.length && properties.find(o => o.label === 'color') ?
// 																		<Property_Tab
// 																			key={'color'}
// 																			property={properties.find(o => o.label === 'color')}
// 																			properties={properties}
// 																			set_properties={set_properties}
// 																			set_page_loader={set_page_loader}
// 																			texture_json={texture_json}
// 																			input_size='small'
// 																			set_base_texture_url={set_base_texture_url}
// 																			// disabled_input={!editing_current_material}
// 																			parsed_properties={parsed_properties}
// 																			set_parsed_properties={set_parsed_properties}
// 																			compact_style={true}
// 																			set_roughness={set_roughness}
// 																			set_metalness={set_metalness}
// 																			set_color={set_color}
// 																			set_specular={set_specular}

// 																		// final_update_fn={final_update_fn}
// 																		/> : ''
// 																}
// 																</div>
// 																<div className={label == 'Base' ? 'flex_property' : ''} style={{gap: '16px'}}>
// 																{
// 																	properties && properties.length ? properties.filter(x => grouped_properties[label].includes(x.label)).sort((a,b) => properties_order[a.label] - properties_order[b.label]).map((property, idx) => {
// 																		if (!((label == 'Base' && is_color && property.label !='color') || (label == 'Base' && !is_color && property.label =='color')) && property.label !='map' && property.label != 'color') {
// 																			return (
// 																				<Property_Tab
// 																					key={idx}
// 																					property={property}
// 																					properties={properties}
// 																					set_properties={set_properties}
// 																					set_page_loader={set_page_loader}
// 																					texture_json={texture_json}
// 																					input_size='small'
// 																					set_base_texture_url={set_base_texture_url}
// 																					parsed_properties={parsed_properties}
// 																					set_parsed_properties={set_parsed_properties}
// 																					compact_style={true}
// 																					set_roughness={set_roughness}
// 																					set_metalness={set_metalness}
// 																					set_color={set_color}
// 																					set_specular={set_specular}
// 																				// final_update_fn={final_update_fn}
// 																				/>
// 																			)
// 																		}
// 																	}
// 																	)
// 																		: ''
// 																}
// 																</div>
// 															</div>
// 														</UncontrolledCollapse>
// 													)
// 												})
// 													: ''
// 												// : ''
// 										}
// 										</div>
// 									</div>
// 								</div>
// 								{
// 									// editing_current_material?
// 									// <div className='flex_property' style={{width:'1160px', backgroundColor:'white', height:'48px', border:'1px solid #C5C7CF', borderTop:' 1px solid #E6E9EE', justifyContent:'flex-end'}}>
// 									// 	{/* <hr style={{selfAlign:'center', width:'80%'}} /> */}
// 									// 	<div style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
// 									// 		{/* <span onClick={() => {onclick_restore_template_values()}} className='flex_property' style={{width:'100px', height:'32px', cursor:'pointer', border:'1px solid #C5C7CF', borderRadius:'4px', justifyContent:'center', fontSize:'13px', marginRight:'10px'}}>Reset Changes</span> */}
// 									// 		<span onClick={() => {onclick_add_material()}} className='flex_property' style={{width:'100px', height:'32px', cursor:'pointer',  border:'1px solid #C5C7CF', borderRadius:'4px', backgroundColor:'#0078FF',justifyContent:'center', fontSize:'13px', color:'white', marginRight:'10px'}}>Save Changes</span>
// 									// 	</div>
// 									// </div>
// 									// :''
// 								}
// 							</>
// 						)
// 					}
//         </ModalBody>

// 		<IModalFooter>
// 		<Button onClick={() => {onclick_add_material()}} className='blue_button'>Save Changes</Button>
// 		</IModalFooter>
//         </Modal>
// 	);
// }

export default Add_new_material;