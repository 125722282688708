import React , { useState , useEffect }from 'react';
import {Modal, CardImg, Alert, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";

const EscapeWarning = ({open, set_discard_changes, handleClose}) => {
    return (
        <div>
            <Modal style ={{marginTop:'17%', height:'150px', width:'360px'}} size="sm" isOpen={open}>
                <Card>
                    {/* <CardHeader className='global_modal_header'>
                        Warning!
                    </CardHeader> */}
                    <div style = {{backgroundColor:'#F5F6F8', overFlow:'auto', padding:'5%'}}> 
                        <div style = {{paddingLeft:'11%', paddingDown:'10%'}}>Do you want to discard your changes?</div>
                        <div style={{paddingLeft:'21%', paddingTop:'5%'}}>
                            <Button className='white_button' type="button" onClick={() => handleClose()}>Cancel</Button>
                            <Button className='blue_button' type="button" onClick={() => {set_discard_changes(true);handleClose()}}>OK</Button>
                        </div>
                    </div>
                    {/* <CardFooter className='global_modal_footer'>
                        <Button className='white_button' type="button" onClick={() => handleClose()}>Cancel</Button>
                        <Button className='blue_button' type="button" onClick={() => {set_discard_changes(true);handleClose()}}>Discard Changes</Button>
                    </CardFooter> */}
                </Card>
            </Modal>
        </div>
    )
} 

export default EscapeWarning;