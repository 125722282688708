import Main_Grid from '../../components/Main_Grid';
import React, { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import { Button, Card, Col, Input, Row } from 'reactstrap';
import { useAlert } from 'react-alert';
import default_additional_parameters from '../../models/default_additional_parameters';
import FillerEmptyState from '../../components/FillerEmptyState';
import debug_log from '../../utils/debug_log';

const is_valid_add_key = (json_array) => {
    if(json_array && json_array.length > 0){
        for(let i=0;i<json_array.length;i++){
            // console.log("printing tandc", json_array[i][0])
            if(json_array[i].key === '' || json_array[i].value==='')
                return false
        }
        return true
    }else{
        // console.log("printing tandc true")
        return true
    }
}

const AdditionalParametersKeyValuePairs = ({ pairs, setPairs, key_pair = 'key', value = 'value' }) => {
    //   const [pairs, setPairs] = useState([{ key: '', value: 0 }]);

    const alert = useAlert()

    const handleAddPair = () => {
        const lastPair = pairs[pairs.length - 1];
        // if (pairs.filter(x => x[key_pair] == '').length == 0) {
        if (is_valid_add_key(pairs)) {
            // setPairs([...pairs, { key_pair: '', value: 0 }]);
            let pairsCopy = JSON.parse(JSON.stringify(pairs))
            let newPair = {}
            newPair[key_pair] = ''
            newPair[value] = 0
            pairsCopy.push(newPair)
            setPairs(pairsCopy)
        }else{
            alert.error('Empty Values are not allowed')
        }
    };

    const handleDeletePair = (index) => {
        const updatedPairs = [...pairs];
        updatedPairs.splice(index, 1);
        setPairs(updatedPairs);
    };

    const handleChangeKey = (index, newKey) => {
        const updatedPairs = [...pairs];
        updatedPairs[index][key_pair] = newKey;
        setPairs(updatedPairs);
    };

    const handleChangeValue = (index, newValue) => {
        const updatedPairs = [...pairs];
        updatedPairs[index][value] = newValue;
        setPairs(updatedPairs);
    };

    // useEffect(() => {
    //     if(!pairs || !pairs.length){
    //         let pairsCopy = JSON.parse(JSON.stringify(pairs))
    //         let newPair = {}
    //         newPair[key_pair] = ''
    //         newPair[value] = ''
    //         pairsCopy.push(newPair)
    //         setPairs(pairsCopy)
    //     }
    // }, [pairs]);

    return (
        <div>
            {/* <h2>Key-Value Pair UI</h2> */}
            {
                pairs && pairs.length ? pairs.map((pair, index) => (
                    <div key={index} className='mb-2 flex_property'>
                        <div className='mr-2' style={{flex: 2}}>
                            <Input
                                type="text"
                                placeholder="Parameter"
                                value={pair[key_pair]}
                                onChange={(e) => handleChangeKey(index, e.target.value)}
                                style={{ fontSize: '12px' }}
                            />
                        </div>
                        <div className='mr-2' style={{flex: 2}}>
                            <Input
                                type="text"
                                placeholder="Value"
                                value={pair[value]}
                                onChange={(e) => handleChangeValue(index, (e.target.value))}
                                style={{ fontSize: '12px' }}
                            />
                        </div>
                        <div style={{ flex: 1 }} className='flex_property justify-content-end'>
                            <i className='fa fa-trash cp' style={{ color: 'red' }} onClick={() => handleDeletePair(index)}></i>
                        </div>
                    </div>
                ))
                    :
                    <FillerEmptyState message={"No Custom Additional Parameters are present"} />
            }
            <div style={{ textAlign: 'left' }}>
                <span className='my-3' style={{ color: '#2688F7', fontSize: '14px', cursor: 'pointer', fontWeight: '700' }} onClick={handleAddPair}>+ Add Custom Parameter</span>
            </div>
        </div>
    );
};

const DefaultAdditionalParametersKeyValuePairs = ({ pairs, setPairs, key_pair = 'key', value = 'value' }) => {
    //   const [pairs, setPairs] = useState([{ key: '', value: 0 }]);

    const alert = useAlert()

    const handleAddPair = () => {
        const lastPair = pairs[pairs.length - 1];
        // if (pairs.filter(x => x[key_pair] == '').length == 0) {
        if (is_valid_add_key(pairs)) {
            // setPairs([...pairs, { key_pair: '', value: 0 }]);
            let pairsCopy = JSON.parse(JSON.stringify(pairs))
            let newPair = {}
            newPair[key_pair] = 'v1_conversion_cost'
            newPair[value] = 0
            pairsCopy.push(newPair)
            setPairs(pairsCopy)
        }else{
            alert.error('Empty Values are not allowed')
        }
    };

    const handleDeletePair = (index) => {
        const updatedPairs = [...pairs];
        updatedPairs.splice(index, 1);
        setPairs(updatedPairs);
    };

    const handleChangeKey = (index, newKey) => {
        const updatedPairs = [...pairs];
        updatedPairs[index][key_pair] = newKey;
        setPairs(updatedPairs);
    };

    const handleChangeValue = (index, newValue) => {
        const updatedPairs = [...pairs];
        updatedPairs[index][value] = newValue;
        setPairs(updatedPairs);
    };

    // useEffect(() => {
    //     if(!pairs || !pairs.length){
    //         let pairsCopy = JSON.parse(JSON.stringify(pairs))
    //         let newPair = {}
    //         newPair[key_pair] = ''
    //         newPair[value] = ''
    //         pairsCopy.push(newPair)
    //         setPairs(pairsCopy)
    //     }
    // }, [pairs]);

    return (
        <div>
            {/* <h2>Key-Value Pair UI</h2> */}
            {
                pairs && pairs.length ? pairs.map((pair, index) => (
                    <div key={index} className='mb-2 flex_property'>
                        <div className='mr-2' style={{flex: 2}}>
                            <Input
                                type="select"
                                placeholder="Parameter"
                                value={pair[key_pair]}
                                onChange={(e) => handleChangeKey(index, e.target.value)}
                                style={{fontSize: '12px'}}
                            >
                                {
                                    default_additional_parameters && Object.keys(default_additional_parameters) ? Object.keys(default_additional_parameters).map(additional_parameter => (
                                        <option key={additional_parameter} value={additional_parameter}>{default_additional_parameters[additional_parameter].name}</option>
                                    ))
                                    :''
                                }
                            </Input>
                        </div>
                        <div className='mr-2' style={{flex: 2}}>
                            {
                                pair[key_pair] && default_additional_parameters[pair[key_pair]] && default_additional_parameters[pair[key_pair]].type ?
                                    default_additional_parameters[pair[key_pair]].type === 'select' ?
                                        <Input
                                            type="select"
                                            placeholder="Value"
                                            value={pair[value]}
                                            onChange={(e) => handleChangeValue(index, (e.target.value))}
                                            style={{ fontSize: '12px' }}
                                        >
                                            {
                                                default_additional_parameters && default_additional_parameters[pair[key_pair]].possible_values ? default_additional_parameters[pair[key_pair]].possible_values.map(option => (
                                                    <option key={option.id} value={option.id}>{option.name}</option>
                                                ))
                                                    : ''
                                            }
                                        </Input>
                                        :
                                        <Input
                                            type={default_additional_parameters[pair[key_pair]].type}
                                            placeholder="Value"
                                            value={pair[value]}
                                            onChange={(e) => handleChangeValue(index, (e.target.value))}
                                            style={{ fontSize: '12px' }}
                                        />
                                :
                                <Input
                                    type="text"
                                    placeholder="Value"
                                    value={pair[value]}
                                    onChange={(e) => handleChangeValue(index, (e.target.value))}
                                    style={{ fontSize: '12px' }}
                                />
                            }
                        </div>
                        <div style={{flex: 1}} className='flex_property justify-content-end'>
                        <i className='fa fa-trash cp' style={{ color: 'red' }} onClick={() => handleDeletePair(index)}></i>
                        </div>
                    </div>
                ))
                :
                <FillerEmptyState message={"No Default Additional Parameters are present"}/>
            }
            <div style={{ textAlign: 'left' }}>
                <span className='my-3' style={{ color: '#2688F7', fontSize: '14px', cursor: 'pointer', fontWeight: '700' }} onClick={handleAddPair}>+ Add Default Parameter</span>
            </div>
        </div>
    );
};

const AdditionalParameters = ({ store_details, get_store_details, set_active_tab, submit_for_delete_confirm, set_active_division_id_sidebar, send_analytics_event, set_page_loader }) => {

    // const [additional_properties, set_additional_properties] = useState([]);
    const [default_additional_properties, set_default_additional_properties] = useState([]);
    const [custom_additional_properties, set_custom_additional_properties] = useState([]);
    const alert = useAlert()

    // useEffect(() => {
    //     console.log('Aditional Parameters', additional_properties)
    // }, [additional_properties]);
    

    useEffect(() => {
        if (store_details && store_details.additional_properties) {
            let additional_properties_clone = (JSON.parse(JSON.stringify(store_details.additional_properties)))
            set_default_additional_properties(Object.keys(additional_properties_clone).filter(x => default_additional_parameters[x]).map(x => {
                return{key: x, value: additional_properties_clone[x]}
            }))
            set_custom_additional_properties(Object.keys(additional_properties_clone).filter(x => !default_additional_parameters[x]).map(x => {
                return{key: x, value: additional_properties_clone[x]}
            }))

        }else{
            // set_additional_properties([])
            set_default_additional_properties([])
            set_custom_additional_properties([])
        }
    }, [store_details]);

    useEffect(() => {
        set_active_tab('preferences_settings');
        set_active_division_id_sidebar('additional_parameters');
        send_analytics_event('auto', 'Preference and Settings', 'Additional Parameters', 1, window.location.href)
    }, []);

    const onclick_update_additional_properties = async() => {
        let additional_properties = [...default_additional_properties, ...custom_additional_properties]
        if((additional_properties && additional_properties.length==1) || is_valid_add_key(additional_properties)){
			try {
				// console.log("tandc", JSON.stringify(additional_properties))
                debug_log("additional parameters before submit", additional_properties)
				let additional_properties_restructured = {}
                let additional_properties_clone = additional_properties.filter(x => x.key != '' && (x.value !== '' || x.value !== undefined || x.value !== null))
                for(let i in additional_properties_clone){
                    let x = additional_properties_clone[i]
                    if(additional_properties_restructured[x.key] != undefined){
                        alert.error('Duplicate Parameters are not allowed')
                        return
                    }
                    additional_properties_restructured[x.key] = x.value
                }
				var body = {updates : JSON.stringify({additional_properties: additional_properties_restructured})}
				var response = await general_fetch({ url: 'store/update_info' , body });
				alert.success('Additional Parameters Updated Successfully')	
				get_store_details()
			} catch(err) {
				alert.error('API call failed')
				console.log(err);
			}
		}else{
			alert.error("Empty parameters/values are not allowed!")
		}
    }

    return (
        <Main_Grid store_details={store_details} get_store_details={get_store_details}>
            <Row style={{ padding: '20px 10px', color: '#22272e' }}>
                <Col xs={6} className='flex_property' style={{ fontFamily: 'Source_Sans_Pro-SemiBold', paddingLeft: '20px', fontSize: '14px' }}>
                    Additional Parameters
                </Col>
            </Row>
            <Row style={{ height: 'calc(100% - 90px)' }}>
                <Col style={{ height: '100%' }}>
                    <Card style={{ borderRadius: 0, height: '100%', overflowY: 'scroll', padding: '20px'}}>
                        <div style={{maxHeight: 'calc( 100% - 120px )', overflow: 'auto'}}>
                            <div className='mb-3' style={{textAlign: 'left', fontWeight: 700, fontSize: '14px'}}>Default Additional Parameters</div>
                            <div className='flex_property mb-3' style={{textAlign: 'left', fontSize: '14px'}}>
                                <div style={{ flex: 2 }} className='mr-2'>Parameter</div>
                                <div style={{ flex: 2 }} className='mr-2'>Value</div>
                                <div style={{ flex: 1 }} className=''></div>
                            </div>
                            <DefaultAdditionalParametersKeyValuePairs pairs={default_additional_properties} setPairs={set_default_additional_properties} key_pair='key' value='value'/>
                            <hr/>
                            <div className='mb-3' style={{textAlign: 'left', fontWeight: 700, fontSize: '14px'}}>Custom Additional Parameters</div>
                            <div className='flex_property mb-3' style={{textAlign: 'left', fontSize: '14px'}}>
                                <div style={{ flex: 2 }} className='mr-2'>Parameter</div>
                                <div style={{ flex: 2 }} className='mr-2'>Value</div>
                                <div style={{ flex: 1 }} className=''></div>
                            </div>
                            <AdditionalParametersKeyValuePairs pairs={custom_additional_properties} setPairs={set_custom_additional_properties} key_pair='key' value='value'/>
                        </div>
                        <div style={{textAlign:'end'}}>
                            <Button className='blue_button' type="button" style={{}} onClick={onclick_update_additional_properties}>Update</Button>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Main_Grid>
    )
}

export default AdditionalParameters
