import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import HWRulesDialogBox from './HWRulesDialogBox';
import {Card, Col, Progress, Table, Row, } from "reactstrap";
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import custom_sort from '../../utils/custom_sort';
import split_cat_type from '../../utils/split_cat_type';
import './index.css'
import DragDropComponent from '../../components/DragDropCompoment';
import Filter, { RulesFilter } from '../../components/RuleFilterModal';
import { COLORS } from '../../utils/colors';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { UserContext } from '../../components/Contexts/UserContext';
import RuleNameCloneModal from '../../components/RuleNameCloneModal';
import { AllBuContext } from '../../components/Contexts/AllBuContext';
import HWRulesModal from './HwRulesModal';
import { handleError } from '../../utils/error';

//generate a random 20 charecter id
const generate_id = () => {
	const id_length = 20;
	const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let text = "";
	for (let i = 0; i < id_length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
}


const component_options_json = [
	{
		name:'Cabinet',
		id:'cabinet',
		sub_component:[
			{
				name:'Cabinet',
				id:'cabinet',
				properties:[
					{
						name:'Height',
						id:'height',
						type:'number'
					},{
						name:'Width',
						id:'width',
						type:'number'
					},{
						name:'Depth',
						id:'depth',
						type:'number'
					},
					{
						name:'Front Right(Corner Unit)',
						id:'front_right'
					},
					{
						name:'Side Left (Corner Unit)',
						id:'side_left'
					}
				],

			},
			{
				name:'Skirting',
				id:'skirting',
				properties:[
					{
						name:'Front Skirting Visibility',
						id:'front_skirting_visibility',
						type:'input-select'
					},
					{
						name:'Left Skirting Visiblity',
						id:'left_skirting_visibility',
						type:'input-select'
					},
					{
						name:'Right Skirting Visibility',
						id:'right_skirting_visibility',
						type:'input-select'
					},
				],

			},
			{
				name:'Strip Light',
				id:'strip_light',
				properties:[
					{
						name:'Total Strip Light length',
						id:'total_strip_light_length',
						type:'number'
					},
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					}
				]
			}
		]
	},
	{
		name:'Wardrobe',
		id:'wardrobe',
		sub_component:[
			{
				name:'Wardrobe',
				id:'wardrobe',
				properties:[
					{
						name:'Height',
						id:'height',
						type:'number'
					},{
						name:'Width',
						id:'width',
						type:'number'
					},{
						name:'Depth',
						id:'depth',
						type:'number'
					},
					{
						name:'Front Right(Corner Unit)',
						id:'front_right'
					},
					{
						name:'Side Left (Corner Unit)',
						id:'side_left'
					}
				],

			},
			{
				name:'Skirting',
				id:'skirting',
				properties:[
					{
						name:'Front Skirting Visibility',
						id:'front_skirting_visibility',
						type:'input-select'
					},
					{
						name:'Left Skirting Visiblity',
						id:'left_skirting_visibility',
						type:'input-select'
					},
					{
						name:'Right Skirting Visibility',
						id:'right_skirting_visibility',
						type:'input-select'
					},
				],

			},
			{
				name:'Strip Light',
				id:'strip_light',
				properties:[
					{
						name:'Total Strip Light length',
						id:'total_strip_light_length',
						type:'number'
					},
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					}
				]
			}
		]
	},
	{
		name:'Shelf',
		id:'shelf',
		sub_component:[
			{
				name:'Shelf',
				id:'shelf',
				properties:[
					{
						name:'Height',
						id:'height',
						type:'number'
					},
					{
						name:'Width',
						id:'width',
						type:'number'
					},
					{
						name:'Depth',
						id:'depth',
						type:'number'
					},
					{
						name:'Shelf Type',
						id:'shelf_type',
						type:'select'
					},
				],

			}
		]
	},
	{
		name:'Shutter',
		id:'shutter',
		sub_component:[
			{
				name:'Shutter',
				id:'shutter',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name:'Shutter Mechanism',
						id:'shutter_mechanism',
						type:'select'
					},
					{
						name:'Height',
						id:'height',
						type:'number'
					},
					{
						name:'Width',
						id:'width',
						type:'number'
					},
					{
						name:'Depth',
						id:'depth',
						type:'number'
					},

				],

			},
			{
				name:'Shutter Design',
				id:'shutter_design',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
				],

			},
			{
				name:'Handle',
				id:'handle',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					}
				]
			}
		]
	},
	{
		name:'Drawer Fascia',
		id:'drawer_fascia',
		sub_component:[
			{
				name:'Drawer Fascia',
				id:'drawer_fascia',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name:'Shutter Mechanism',
						id:'shutter_mechanism',
						type:'select'
					},
					{
						name:'Height',
						id:'height',
						type:'number'
					},
					{
						name:'Width',
						id:'width',
						type:'number'
					},
					{
						name:'Depth',
						id:'depth',
						type:'number'
					},

				],

			},
			{
				name:'Shutter Design',
				id:'shutter_design',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
				],

			}
		]
	},
	{
		name:'Shutter System',
		id:'shutter_system',
		sub_component:[
			{
				name:'Shutter System Configuration',
				id:'shutter_system_configuration',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
				],

			}
		]
	},
	{
		name:'Drawer Fascia System',
		id:'drawer_fascia_system',
		sub_component:[
			{
				name:'Drawer Fascia Configuration',
				id:'drawer_fascia_configuration',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
				],

			}
		]
	},
	{
		name:'Floor',
		id:'floor',
		sub_component:[
			{
				name:'Skirting',
				id:'skirting',
				properties:[
					{
						name:'Total Length',
						id:'length',
						type:'number'
					},
					{
						name:'Sku',
						id:'sku',
						type:'select'
					}
				],

			},
			{
				name:'Strip Light',
				id:'strip_light',
				properties:[
					{
						name:'Total Strip Light length',
						id:'total_strip_light_length',
						type:'number'
					},
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					}
				]
			}
		]
	},
	{
		name:'Drawer box',
		id:'drawer_box',
		sub_component:[
			{
				name:'Drawer Box',
				id:'drawer_box',
				properties:[
					{
						name:'Height',
						id:'height',
						type:'number'
					}
				],
				
			},
			{
				name:'Tandem Box',
				id:'tandem_box',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name: 'No Condition',
						id: 'no_condition',
						type: 'select'
					}
				],
				
			},
			{
				name:'Basket',
				id:'basket',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name: 'No Condition',
						id: 'no_condition',
						type: 'select'
					}
				],
				
			},
			{
				name:'Channel',
				id:'channel',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name: 'No Condition',
						id: 'no_condition',
						type: 'select'
					}
				],
				
			},
			{
				name:'Drawer Inserts',
				id:'drawer_inserts',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name: 'No Condition',
						id: 'no_condition',
						type: 'select'
					}
				],
				
			}
		]
	},
	{
		name: 'Custom Panel Standalone',
		id: 'custom_panel_standalone',
		sub_component:[
			{
				name: 'Custom Panel Standalone',
				id: 'custom_panel_standalone',
				properties:[
					{
						name:'Height',
						id:'height',
						type:'number'
					},
					{
						name:'Width',
						id:'width',
						type:'number'
					},
					{
						name:'Depth',
						id:'depth',
						type:'number'
					}
				]
			},
			{
				name:'Strip Light',
				id:'strip_light',
				properties:[
					{
						name:'Total Strip Light length',
						id:'total_strip_light_length',
						type:'number'
					},
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					}
				]
			}
		]
	},
	{
		name: 'Connector Set',
		id: 'connector_set',
		sub_component:[
			{
				name: 'Panel 1',
				id: 'panel1',
				properties:[
					{
						name: 'Panel Type',
						id: 'panel_type',
						type: 'panel_type'
					},
					{
						name: 'Shutter Mechanism',
						id: 'shutter_mechanism',
						type: 'multi-select'
					},
					{
						name:'Height',
						id:'height',
						type:'number'
					},
					{
						name:'Width',
						id:'width',
						type:'number'
					},
					{
						name:'Depth',
						id:'depth',
						type:'number'
					}
				]
			},
			{
				name: 'Panel 2',
				id: 'panel2',
				properties:[
					{
						name: 'Panel Type',
						id: 'panel_type',
						type: 'panel_type'
					},
					{
						name: 'Shutter Mechanism',
						id: 'shutter_mechanism',
						type: 'multi-select'
					},
					{
						name:'Height',
						id:'height',
						type:'number'
					},
					{
						name:'Width',
						id:'width',
						type:'number'
					},
					{
						name:'Depth',
						id:'depth',
						type:'number'
					}
				]
			},
			{
				name: 'Connector Set',
				id: 'connector_set',
				properties:[
					{
						name: 'Connection Type',
						id: 'connection_type',
						type: 'connection_type'
					}
				]
			}
		]
	},
	{
		name: 'Panel',
		id: 'panel',
		sub_component:[
			{
				name: 'Panel',
				id: 'panel',
				properties:[
					{
						name: 'Panel Type',
						id: 'panel_type',
						type: 'panel_type'
					},
					{
						name:'Width',
						id:'width',
						type:'number'
					},
					{
						name:'Depth',
						id:'depth',
						type:'number'
					}
				]
			}
		]
	},
	{
		name: 'Drawer',
		id:'drawer',
		sub_component:[
			{
				name:'Drawer',
				id:'drawer',
				properties:[
					{
						name:'Depth',
						id:'depth',
						type:'number'
					}
				]
			},
			{
				name:'Tandem Box',
				id:'tandem_box',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name: 'No Condition',
						id: 'no_condition',
						type: 'select'
					}
				],
				
			},
			{
				name:'Basket',
				id:'basket',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name: 'No Condition',
						id: 'no_condition',
						type: 'select'
					}
				],
				
			},
			{
				name:'Channel',
				id:'channel',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name: 'No Condition',
						id: 'no_condition',
						type: 'select'
					}
				],
				
			},
			{
				name:'Drawer Inserts',
				id:'drawer_inserts',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name: 'No Condition',
						id: 'no_condition',
						type: 'select'
					}
				],
				
			}
		]
	}
]

const component_tags_json = [
	{
		name:'Cabinet',
		id:'cabinet',
		sub_component:[
			{
				name:'Cabinet',
				id:'cabinet',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name:'Height',
						id:'height',
						type:'number'
					},
					{
						name:'Width',
						id:'width',
						type:'number'
					},
					{
						name:'Depth',
						id:'depth',
						type:'number'
					},
					{
						name:'Front Right(Corner Unit)',
						id:'front_right'
					},
					{
						name:'Side Left (Corner Unit)',
						id:'side_left'
					},
					{
						name:'No Condition',
						id:'no_condition',
						type:'select'
					},
					// {
					// 	name:'All except Tag',
					// 	id:'all_except_tag',
					// 	type:'multi-select'
					// },
					// {
					// 	name:'All - no restriction',
					// 	id:'all',
					// 	type:'multi-select'
					// },
				],

			}
		]
	},
	{
		name:'Floor',
		id:'floor',
		sub_component:[
			{
				name:'Cabinet',
				id:'cabinet',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name:'No Condition',
						id:'no_condition',
						type:'select'
					},
					// {
					// 	name:'All except Tag',
					// 	id:'all_except_tag',
					// 	type:'multi-select'
					// },
					// {
					// 	name:'All - no restriction',
					// 	id:'all',
					// 	type:'multi-select'
					// },
				],

			},
			{
				name:'Wardrobe',
				id:'wardrobe',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name:'No Condition',
						id:'no_condition',
						type:'select'
					}
				],

			},
			{
				name:'Floor',
				id:'floor',
				properties:[
					{
						name:'No Condition',
						id:'no_condition',
						type:'select'
					},
				],

			},
			{
				name: 'Custom Panel Standalone',
				id: 'custom_panel_standalone',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name:'No Condition',
						id:'no_condition',
						type:'select'
					},
				]
			}
		]
	},
	{
		name:'Wardrobe',
		id:'wardrobe',
		sub_component:[
			{
				name:'Wardrobe',
				id:'wardrobe',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name:'Height',
						id:'height',
						type:'number'
					},
					{
						name:'Width',
						id:'width',
						type:'number'
					},
					{
						name:'Depth',
						id:'depth',
						type:'number'
					},
					{
						name:'Front Right(Corner Unit)',
						id:'front_right'
					},
					{
						name:'Side Left (Corner Unit)',
						id:'side_left'
					},
					{
						name:'No Condition',
						id:'no_condition',
						type:'select'
					},
				],

			}
		]
	},
	{
		name:'Design',
		id:'design',
		sub_component:[
			{
				name:'Design',
				id:'design',
				properties:[
					{
						name:'Sales Channel',
						id:'sales_channel',
						type:'multi-select'
					},
				],

			}
		]
	},
	{
		name: 'Custom Panel Standalone',
		id: 'custom_panel_standalone',
		sub_component:[
			{
				name: 'Custom Panel Standalone',
				id: 'custom_panel_standalone',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name:'Height',
						id:'height',
						type:'number'
					},
					{
						name:'Width',
						id:'width',
						type:'number'
					},
					{
						name:'Depth',
						id:'depth',
						type:'number'
					},{
						name:'No Condition',
						id:'no_condition',
						type:'select'
					},
				]
			}
		]
	}
]

const shelf_type_values = [{ name: 'Fixed Shelf', id: 'fixed' }, { name: 'Adjustable Shelf', id: 'adjustable' }]
const shutter_mechanism_values = [
    { name: 'Single Swing', id: 'single_swing', option_type: 'cabinet' },
    { name: 'Double Swing', id: 'double_swing', option_type: 'cabinet' },
    { name: 'Single Lift Up', id: 'single_lift_up', option_type: 'cabinet' },
    { name: 'Double Lift Up', id: 'double_lift_up', option_type: 'cabinet' },
    { name: 'Flap Down', id: 'flap_down', option_type: 'cabinet' },
    // {name:'Bi-fold', id:'bi_fold', option_type:'cabinet'},
    { name: 'Left Bi-fold', id: 'left_bifold', option_type: 'cabinet' },
    { name: 'Right Bi-fold', id: 'right_bifold', option_type: 'cabinet' },
    { name: 'Bi-corner Unit Double Swing', id: 'corner_unit_double_swing', option_type: 'cabinet' },
    { name: 'Rolling', id: 'rolling_shutter', option_type: 'cabinet' },
    { name: 'None', id: 'single_none', option_type: 'cabinet' },
    { name: 'Single Swing', id: 'single_swing', option_type: 'wardrobe' },
    { name: 'Double Swing', id: 'double_swing', option_type: 'wardrobe' },
    { name: 'Rolling', id: 'rolling_shutter', option_type: 'wardrobe' },
    { name: 'None', id: 'single_none', option_type: 'wardrobe' },
    { name: '2 door Sliding Shutter', id: '2_door_sliding_shutter', option_type: 'wardrobe' },
    { name: '3 door Sliding Shutter', id: '3_door_sliding_shutter', option_type: 'wardrobe' },
    { name: '4 door Sliding Shutter', id: '4_door_sliding_shutter', option_type: 'wardrobe' },
    { name: '5 door Sliding Shutter', id: '5_door_sliding_shutter', option_type: 'wardrobe' },
    { name: '6 door Sliding Shutter', id: '6_door_sliding_shutter', option_type: 'wardrobe' }
]

const HW_rules = ({submit_for_delete_confirm, set_active_tab, send_analytics_event, check_route_fn, set_page_loader,store_details, get_store_details, set_active_division_id_sidebar}) => {
	const styles = {
		add_brand_style:{
			backgroundColor: '#007EF4',
			boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
			borderRadius: '3px',
			height:'32px',
			padding:'0px 24px',
			border:'0px',
			color:'white',
		},
		table_header: {
			fontWeight: "bold",
			fontSize: 'small'
		},
    };
	//console.log('BRANDPAGE====>CALLED')
	const [hw_rules, set_hw_rules ] = useState([]);
	const [show_rule_Dialog_Box, set_show_rule_Dialog_Box] = useState(false);
	const [active_rule, set_active_rule] = useState('');
	const [is_active_rule_valid, set_is_active_rule_valid] = useState(false);
	const [search_string , set_search_string] = useState('');
	const [display_hw_rules , set_display_hw_rules] = useState([]);
	const [key , set_key] = useState('');

	const [filtered_display_rules, set_filtered_display_rules] = useState([]);
	const [sort_value, set_sort_value] = useState(3);

	const [page_size , set_page_size] = useState(30)
	const [fetch_progress , set_fetch_progress] = useState(40)
	const [current_page , set_current_page] = useState(1)
	const [initialized , set_initialized] = useState(false) 
 	const [all_pages , set_all_pages] = useState([])
	const alert = useAlert()
	const [ start_index , set_start_index ] = useState('');
	const [ end_index , set_end_index ] = useState('');
	const [tags , set_tags] = useState([]);
	const [designer_inputs, set_designer_inputs] = useState([]);
	const [all_division_tree_data, set_all_division_tree_data] = useState([]);
	const [all_brands, set_all_brands] = useState([]);
	const [all_category_type , set_all_category_type] = useState([]);
	const [ last_item_order, set_last_item_order] = useState(0);
	const [ sales_channels, set_sales_channels] = useState([]);

	const [is_filter_open, set_is_filter_open] = useState(false);
	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)
	const all_bu_in_store = useContext(AllBuContext)
	// useEffect(() => {
	// 	console.log('display_hw_rules$$$111', display_hw_rules)
	// }, [display_hw_rules]);


	const fetch_designer_input_details = async () => {
		try {
			let body = {}
			if(org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
			var response = await general_fetch({ url: 'automation_rule/get_all_inputs', body});
			set_designer_inputs(response)
		} catch(err) {
			console.log(err);
		}
	}

	const fetch_brands = async (id) => {
		try {
			let resp
			if(org_bu_view === 'org'){
				resp = await general_fetch({ url: 'brand/get'});
			}else{
				resp = await general_fetch({ url: 'brand/get', body: {business_unit_id: user.current_business_unit_id}});
			}
			set_all_brands(resp)
		} catch(err) {
			console.log(err);
		}
	}

	const fetch_category_type_details = async() => {
		try {
			var response = await general_fetch({ url: 'sku_category_type/get'});
			var resp = split_cat_type(response).sort(name_sort)
			set_all_category_type(resp);
		} catch(err) {
			console.log(err);
		}
	}

	const fetch_tags_details = async () => {
		try {
			let tags_details
			if (org_bu_view === 'org') {
				tags_details = await general_fetch({ url: 'tag/get' });
			} else {
				tags_details = await general_fetch({ url: 'tag/get', body: { business_unit_id: user.current_business_unit_id } });
			}
			console.log("tag HwRules")
			set_tags(tags_details);
		} catch (err) {
			console.log(err);
		}
	}
	
	const fetch_hw_rules_details = async () => {
        try {
            var resp = await general_fetch({ url: 'automation_rule/get_by_type', body:{type:'hardware_rule', business_unit_id: user.current_business_unit_id}});
            set_initialized(true);
			var hw_rules_details= resp && resp.length ? resp:[]
            set_hw_rules(hw_rules_details);
            set_display_hw_rules(hw_rules_details);
			set_last_item_order(hw_rules_details.length?hw_rules_details[hw_rules_details.length-1].order:0);
			//console.log('brand======>',hw_rules);
        } catch(err) {
            console.log(err);
			alert.error('Error! Please contact admin')
        }
    }

	const fetch_sales_channel_details = async () => {
		try {
			let resp, sales_channel_details = []
			let body = {
				"include_sales_channel_profile_info": true, 
				"include_bu_mapping": true, 
				"include_bu_info": false, 
				"include_price_type_mapping": true, 
				"include_price_type_info": true, 
				"include_user_mapping": true, 
				"include_user_info": false
			}

			if(org_bu_view === 'bu'){
				if(user && user.current_business_unit_id){
					body['business_unit_ids'] = [user.current_business_unit_id]
					resp = await general_fetch({ url: 'sales_channel/get_by_business_unit' , body})
					let owned_sc = resp && resp.sales_channels_by_business_unit && resp.sales_channels_by_business_unit[user.current_business_unit_id] ? resp.sales_channels_by_business_unit[user.current_business_unit_id].owned : []
					// let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x)) sales_channel_details.push(resp.sales_channels[x])}) : []
					let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x) && !(all_bu_in_store && all_bu_in_store.length === 1 && resp.sales_channels[x].type === 'bu_feeder')) sales_channel_details.push(resp.sales_channels[x])}) : []
				}
			}else{
				resp = await general_fetch({ url: 'sales_channel/get_of_store' , body})
				sales_channel_details = resp && resp.sales_channels && resp.sales_channels.owned ? resp.sales_channels.owned.filter(x => !x.business_unit_id) : []
			}
			sales_channel_details = sales_channel_details.map(single_sales_channel => {
                let price_types = single_sales_channel.price_type_ids && single_sales_channel.price_type_ids.length ? single_sales_channel.price_type_ids.map(x => resp.price_types[x]) : []
                return {...single_sales_channel, price_types}
            })
			set_sales_channels(sales_channel_details);
		} catch(err) {
			console.log(err);
			handleError(err)
		}
	}

	const onclick_get_cat = async() => {
        try {
			let body={}
			if(org_bu_view == 'bu'){
				body['business_unit_id'] = user.current_business_unit_id
			}
            var response = await general_fetch({ url: 'inventory/get_all_sub_categories', body});	
			set_all_division_tree_data(response)
        } catch(err) {
            console.log(err);
            // alert.success('Please contact admin')
        }
    }

	useEffect(() => {
		if(hw_rules && !hw_rules.length){
			fetch_designer_input_details();
			fetch_hw_rules_details();
			fetch_sales_channel_details();
			fetch_brands();
			fetch_category_type_details();
		}
		if(all_division_tree_data && !all_division_tree_data.length){
			onclick_get_cat();
		}	
		if(tags && !tags.length){
			fetch_tags_details();
		}
	}, []);


	const onclick_delete_rule = async(id) => {
		if(id){
			try {
				var response = await general_fetch({ url: 'automation_rule/remove', body:{id:[id]}});
				alert.success('Rule deleted Successfully !')
				fetch_hw_rules_details()
			} catch(err) {
				console.log(err);
				alert.error('Error in deletion')
			}
		}else{
			alert.error('Error in deletion')
		}	
	}

	const open_rule_update_dialog = ({item, mode, is_rule_valid}) => {
		set_show_rule_Dialog_Box(true);
		set_active_rule(item);
		set_key(mode);
		set_is_active_rule_valid(is_rule_valid)
	}

	const close_rule_Dialog_Box = () => {
		set_show_rule_Dialog_Box(false);
		set_active_rule('');
		set_key('');
	}

	const open_rule_add_dialog = () => {
		set_show_rule_Dialog_Box(true);
		set_key('add');
	}
	
	const filter_rule = () => 
	{
		var filtered_brands = [...hw_rules];
		var lower_search_string = search_string ? search_string.toLowerCase():'';
		if(lower_search_string){
			filtered_brands = filtered_brands.filter( brand => (brand.name.toLowerCase().includes(lower_search_string) || (brand.description ? brand.description.toLowerCase().includes(lower_search_string):false)))
		}
		set_display_hw_rules(filtered_brands)
	}

	const onclick_sorting_arrows = () => {
		if(sort_value == 1){
			set_filtered_display_rules(display_hw_rules.filter(x => x.active))
		}
		else if(sort_value == 2){
			set_filtered_display_rules(display_hw_rules.filter(x => !x.active))
		} else if(sort_value == 3){
			set_filtered_display_rules(display_hw_rules)
		}else{
			set_filtered_display_rules([])
		}
	}


	useEffect(() => {
		onclick_sorting_arrows()
	}, [sort_value, display_hw_rules]);

	useEffect(() => {
			filter_rule();
	}, [search_string, hw_rules]);

	useEffect(() => {
		set_active_tab('DesignIntelligence')
		set_active_division_id_sidebar('HW_rules')
		// check_route_fn('HW_rules')
		send_analytics_event('auto', 'HW_rules', 'HW_rules', 1, window.location.href)
	}, []);

	useEffect(() => {
		if (initialized) {
			var pages = Number(Math.ceil(display_hw_rules.length / page_size));
			//console.log('all pages --> ', pages, 'projects length --<>', display_hw_rules.length)
			var tmp = Array.apply('', {length: pages + 1}).map(Number.call, Number)
			tmp.splice(0, 1);
			//console.log('tmp --<>', tmp)
			set_all_pages(tmp);
			//console.log('tmp spliced--<>', tmp)
			set_current_page(1);
		}
	}, [ page_size, display_hw_rules ])

	const HW_Rules_Filler = () => (
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
				<tr>
					{ initialized ? (
						<th scope="col"><span style={styles.table_header}><b>No rules to list  </b></span><br/> Try adjusting filters...<br /> &nbsp;</th>
					):(
						<th scope="col"><span style={styles.table_header}><b>Fetching all rules </b></span><br/> <br /> Please wait...<br /> <br />
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;</th>
					)}
				</tr>
			</thead>
		</Table>
	);

	const onclick_suffle_rule = async(active_rule, new_order) => {
		try {
			var body = {
				rule_type: 'hardware_rule',
				data:[{id:active_rule.id,order:new_order}]
			};
			var response = await general_fetch({ url: 'automation_rule/update_order', body});	
			alert.success('Rule Updated Successfully !')
			fetch_hw_rules_details()
		} catch(err) {
			console.log(err);
		}
	}

	const is_valid_add_key = (item_json, type, linked_item_json) => {
        if (item_json && item_json.length) {
            for (var i = 0; i < item_json.length; i++) {
                if ((type === 'condition' || type === 'applicable') && !(item_json[i].property && item_json[i].property.length)) {
					// console.log("kpp", type)
                    return false;
                } else if (type === 'link_sku' && !(linked_item_json[i].sku && linked_item_json[i].sku.length)) {
					// console.log("kpp", type)
                    return false;
                }
            }
            return true;
        } else {
            return true;
        }
    }

	const check_fn_for_floor = (item_json_source, item_json_dest) => {
        if (item_json_dest && item_json_dest.length && item_json_source && item_json_source.length) {
            if (item_json_source[0].component[0].id === 'floor' && item_json_dest[0].component[0].id != 'floor') {
                alert.error("If Applicable component is floor then Condition component must be floor")
                return true
            }
            if (item_json_source[0].component[0].id === 'cabinet' && item_json_dest[0].component[0].id === 'floor') {
                alert.error("If Applicable component is cabinet then Condition component cannot be floor")
                return true
            }
            return false
        }
    }

	const get_value = (x_item) => {
        let value;
        if (x_item.value && (Array.isArray(x_item.value) && x_item.value.length)) {
            if (x_item.subcomponent && x_item.subcomponent[0] && (x_item.subcomponent[0].id == 'panel1' || x_item.subcomponent[0].id == 'panel2') && x_item.property && x_item.property[0] && x_item.property[0].id == 'shutter_mechanism') {
                value = (x_item.value.length ? JSON.stringify(x_item.value.map(x => x.id)) : [])
            } else {
                value = (x_item.value.length ? (x_item.value[0].id) : [])
            }
        } else {
            value = (isNaN(x_item.value) ? x_item.value : Number(x_item.value))
        }

        return value;
    }

	const transform_to_usable = (item_array, key) => {
        if (item_array && item_array.length) {
            var x_temp = item_array.map(x_item => {
                return ({
                    id: x_item && x_item.id ? x_item.id : null,
                    value: get_value(x_item),
                    min_value: (x_item.min_value || x_item.min_value == 0) ? Number(x_item.min_value) : '',
                    max_value: (x_item.max_value || x_item.max_value == 0) ? Number(x_item.max_value) : '',
                    type: x_item.condition_type ? x_item.condition_type : 'absolute',
                    index_value: x_item.index_value,
                    operator: x_item.operator,
                    value_combination_logic: x_item.value_combination_logic,

                    sku: ((key != 'actions' && x_item.property && x_item.property.length && x_item.property[0].id == 'sku') || (key == 'actions')) && x_item.sku && x_item.sku.length ? x_item.sku.map(x => x.id) : [],
                    tag: x_item.property && x_item.property.length && x_item.property[0].id == 'tag' && x_item.tag && x_item.tag.length ? x_item.tag.map(x => x.id) : null,
                    sales_channel: x_item.property && x_item.property.length && x_item.property[0].id == 'sales_channel' && x_item.sales_channel && x_item.sales_channel.length ? x_item.sales_channel.map(x => x.id) : null,
                    
                    source_filter: (x_item.component && x_item.component.length) ? ({
                        id: x_item.source_filter && x_item.source_filter.id ? x_item.source_filter.id : null,
                        type: 'source',
                        component: x_item.component && x_item.component.length ? x_item.component[0].id : [],
                        sub_component: x_item.subcomponent && x_item.subcomponent.length ? x_item.subcomponent[0].id : [],
                        property: x_item.property && x_item.property.length ? x_item.property[0].id : [],
                    }) : null,
                    
                    destination_filter: (x_item.relativecomponent && x_item.relativecomponent.length) ? ({
                        type: 'destination',
                        component: x_item.relativecomponent && x_item.relativecomponent.length ? x_item.relativecomponent[0].id : [],
                        sub_component: x_item.relativesubcomponent && x_item.relativesubcomponent.length ? x_item.relativesubcomponent[0].id : [],
                        property: x_item.relativeproperty && x_item.relativeproperty.length ? x_item.relativeproperty[0].id : [],
                    }) : null,
                    
                    additional_sku_metadata: x_item.additional_sku_metadata,
                    ...(x_item.rule_input_value_id ? { rule_input_value_id: x_item.rule_input_value_id } : {}),
                    ...(x_item.default_action ? { default_action: x_item.default_action } : {}),
                })
            })
            // console.log("kppp x_temp", x_temp)
            for (let i = 0; i < x_temp.length; i++) {
                if (x_temp[i].source_filter) {
                    if (x_temp[i].source_filter.property == 'tag') {
                        console.log('transform_to_usablee tag')
                        if (!(x_temp[i].tag && x_temp[i].tag.length >= 1 && typeof x_temp[i].tag[0] === 'string')) { return -1}
                    } else if (x_temp[i].source_filter.property == 'sku' && x_temp[i].type == 'absolute') {
                        console.log('transform_to_usablee sku', x_temp)
                        if (!(x_temp[i].sku && x_temp[i].sku.length == 1 && typeof x_temp[i].sku[0] === 'string')) { return -1}
                    } else if (x_temp[i].source_filter.property == 'height' || x_temp[i].source_filter.property == 'width' || x_temp[i].source_filter.property == 'depth') {
                        console.log('transform_to_usablee height')
                        if (!(x_temp[i].value >= 0 && typeof x_temp[i].value === 'number')) {return -1;}
                    } else if (x_temp[i].source_filter.property == 'sales_channel') {
                        console.log('transform_to_usablee sales_channel')
                        if (!(x_temp[i].sales_channel && x_temp[i].sales_channel.length == 1 && typeof x_temp[i].sales_channel[0] === 'string')) {return -1;}
                    } else {
                        if(!x_temp[i].value && ![0, false].includes(x_temp[i].value)) {
                            // console.log("Kppp issue is", x_temp[i])
                            return -1
                        }
                    }
                }
            }

            return x_temp;
        } else {
            return []
        }
    }



	const validate_rule = (active_rule) => {
		let item_json_applicable, item_json_source, item_json_dest, linked_sku_actions, linked_item_json, rule_name, rule_description, rule_activation, rule_logic, rule_type;
		if (active_rule) {
			// if(active_rule.bu_level_linked_skus && active_rule.bu_level_linked_skus.length){
			// 	set_sku_selection_map(active_rule.bu_level_linked_skus.map(x => x.id))
			// }
            if (active_rule.applicability && active_rule.applicability.length) {
                item_json_source = JSON.parse(JSON.stringify(active_rule.applicability)).map((x_item, idx) => {
                    // component_tags_json is the Struct used for Defining HW Rules Applicability Conditions
                    // todo - Can Handle the below better with proper checks
                    let x_component = component_tags_json ? component_tags_json.filter(x => x.id === x_item.source_filter.component) : []
                    let x_sub_component = x_component && x_component.length && x_component[0].sub_component.length ? x_component[0].sub_component.filter(x => x.id === x_item.source_filter.sub_component) : []
                    let x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length ? x_sub_component[0].properties.filter(x => x.id === x_item.source_filter.property) : []

                    if (x_selected_property && x_selected_property.length && x_selected_property[0].id === 'tag') {
                        // todo - Deleted tags are automatically removed in the below piece of code. Can ahow information to user
                        let x_selected_value_list = tags && tags.length ? tags.filter(x => x_item.tag.includes(x.id)) : []
                        x_item.tag = x_selected_value_list
                    }

                    if (x_selected_property && x_selected_property.length && x_selected_property[0].id === 'sales_channel') {
                        let x_selected_value_list = sales_channels && sales_channels.length ? sales_channels.filter(x => x_item.sales_channel.includes(x.id)) : []
                        x_item.sales_channel = x_selected_value_list
                    }

                    x_item.component = x_component
                    x_item.subcomponent = x_sub_component
                    x_item.property = x_selected_property

                    return x_item

                })
            } else {
                // todo - Invalid Rule Handling
            }
            
            // Setting Conditions

            if (active_rule.conditions && active_rule.conditions.length) {
                item_json_dest = JSON.parse(JSON.stringify(active_rule.conditions)).map((x_item, idx) => {
                    // component_options_json is the Struct used for Defining HW Rules Conditions
                    // todo - Can Handle the below better with proper checks
                    let x_component = component_options_json ? component_options_json.filter(x => x.id === x_item.source_filter.component) : []
                    let x_sub_component = x_component && x_component.length && x_component[0].sub_component.length ? x_component[0].sub_component.filter(x => x.id === x_item.source_filter.sub_component) : []
                    let x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length ? x_sub_component[0].properties.filter(x => x.id === x_item.source_filter.property) : []
                    
                    let x_selected_value_list = [];
                    if (x_selected_property && x_selected_property.length && x_selected_property[0].id === 'tag') {
                        x_selected_value_list = tags && tags.length ? tags.filter(x => x_item.tag.includes(x.id)) : []
                        x_item.tag = x_selected_value_list
                    }
                    if (x_selected_property && x_selected_property.length && x_selected_property[0].id === 'shelf_type') {
                        x_selected_value_list = shelf_type_values.filter(x => x_item.value.includes(x.id))
                        x_item.value = x_selected_value_list
                    }
                    if (x_selected_property && x_selected_property.length && x_selected_property[0].id === 'shutter_mechanism') {
                        if (x_sub_component && x_sub_component.length && (x_sub_component[0].id === 'panel1' || x_sub_component[0].id === 'panel2')) {
                            let temp_array = JSON.parse(x_item.value)
                            x_selected_value_list = temp_array && temp_array.length && temp_array.map(x => { return { id: x } })
                        } else {
                            x_selected_value_list = shutter_mechanism_values.filter(x => x_item.value == x.id)
                        }
                        x_item.value = x_selected_value_list
                    }

                    x_item.component = x_component
                    x_item.subcomponent = x_sub_component
                    x_item.property = x_selected_property

                    return x_item

                })
            } else {
                // todo - Invalid Rule Handling
            }

            let deleted_designer_input_values = false


            // Settings Designer Inputs
            if(active_rule.valid_rule_inputs && active_rule.valid_rule_inputs.length){
                // designer_inpts is list of all Designer Inputs
                // todo - Deleted designer inputs are implicitly filtered right now. Can show a message indicating the same
                let valid_rule_inputs = (JSON.parse(JSON.stringify(designer_inputs)).filter(x => active_rule.valid_rule_inputs.includes(x.id)));
                
                // Settings Rule Input Values
                if (active_rule.rule_input_values && active_rule.rule_input_values.length) {
                    // let valid_rule_input_values = (active_rule.rule_input_values.filter(x =>  x.value && valid_rule_inputs.includes(x.value.rule_input_id)));
                    let valid_rule_input_values = active_rule.rule_input_values
                    // let designer_inputs_ids = designer_inputs.map(x => x.id)
                    // valid_rule_input_values.map(x => {
                    //     if (!(designer_inputs_ids.includes(x))) {
                    //         deleted_designer_input_values = true;
                    //     }
                    // })
                    // todo - Handle when Designer Input is deleted.
                    // todo - Handle when designer input value assigned a give rule input is deleted.
                    if(!deleted_designer_input_values){
                        // Removing Duplicate Values
                        let filtered_valid_rule_input_values = valid_rule_input_values.map(x => {
                            let rule_input_id_map = {}
                            x.value = x.value.filter(v => {
                                if(rule_input_id_map[v.rule_input_id]){
                                    return false
                                }else{
                                    rule_input_id_map[v.rule_input_id] = true;
                                    return true
                                }
                            })
                            return x
                        })
                    }
                }
            }else{
            }


            let rule_input_value_map = {};
            if (active_rule.actions && active_rule.actions.length) {
                linked_sku_actions = JSON.parse(JSON.stringify(active_rule.actions)).sort((a, b) => (a.rule_input_value_id > b.rule_input_value_id || a.rule_input_value_id == b.rule_input_value_id || !b.rule_input_value_id) ? 1 : -1).map((item, idx) => {

                    // The Below are eligible in case of relative action type
                    var x_component = component_options_json ? component_options_json.filter(x => item.destination_filter && x.id === item.destination_filter.component) : []
                    var x_sub_component = x_component && x_component.length && x_component[0].sub_component.length ? x_component[0].sub_component.filter(x => x.id === item.destination_filter.sub_component) : []
                    var x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length ? x_sub_component[0].properties.filter(x => x.id === item.destination_filter.property) : []

                    let show_conditional_elements = false;
                    if (!rule_input_value_map[item.rule_input_value_id]) {
                        rule_input_value_map[item.rule_input_value_id] = true;
                        show_conditional_elements = true;
                    }

                    let final_action_item = {
                        sku_id: item.sku ? item.sku[0] : '',
                        show_conditional_elements,
                        value: Number(item.value),
                        relativecomponent: x_component ? x_component : [],
                        relativesubcomponent: x_sub_component ? x_sub_component : [],
                        relativeproperty: item.destination_filter && item.destination_filter.property && item.destination_filter.property == 'sku' ? [{ id: 'sku', name: 'SKU' }] : x_selected_property ? x_selected_property : [],
                        index_value: item ? 'per_set' : '',
                        // condition_type:item?'relative':'',
                        condition_type: item && item.type ? item.type : 'absolute',
                        rule_input_value_id: item.rule_input_value_id,
                        additional_sku_metadata: item.additional_sku_metadata,
                        default_action: item.default_action,
                        id: item.id
                    }

                    return final_action_item
                })
            } else {
                // todo - Invalid rule handling
            }

            if (linked_sku_actions.length) {
                linked_sku_actions = linked_sku_actions.map((x, idx) => ({ ...x, show_addition_element: linked_sku_actions[idx + 1] && linked_sku_actions[idx + 1].rule_input_value_id == x.rule_input_value_id ? false : true, idx: idx }));
            }

			if(!deleted_designer_input_values){
                linked_item_json = linked_sku_actions
            }

			rule_name = active_rule.name;
			rule_description = active_rule.description;
			rule_activation = active_rule.active ? true : false;
			rule_logic = active_rule.rule_logic;
			rule_type = active_rule.rule_type;
		}

		// console.log("Kppp all values", item_json_source, item_json_dest, item_json_applicable, linked_sku_actions)

        if (!(is_valid_add_key(item_json_source, 'applicable', linked_sku_actions) && is_valid_add_key(item_json_dest, 'condition', linked_sku_actions))) {
			console.log("Kppp then definitely here")
			return false;
		} else if (check_fn_for_floor(item_json_source, item_json_dest)) {
			return true;
        } else{
			if (rule_name && active_rule && rule_description) {
                    let applicability = transform_to_usable(item_json_source)
                    let conditions = transform_to_usable(item_json_dest)
                    // let actions = transform_to_usable(linked_sku_actions, 'actions')
					// console.log("Kppp is it heer", applicability, conditions)
                    if (applicability == -1 || conditions == -1) return false;
                    return true;
            }else{
				// console.log("not here kppppp")
				return false;
			}
		}
	}

	const Single_table_row = ({item, index, start_index, end_index, open_rule_update_dialog, onclick_delete_rule, submit_for_delete_confirm, fetch_rules}) => {
		// console.log("rule", rule);
		const [show_name_clone_modal, set_show_name_clone_modal] = useState(false);
		const [active_rule, set_active_rule] = useState('');
		const [is_rule_valid, set_is_rule_valid] = useState(true);
		const alert = useAlert();
		const user = useContext(UserContext)

		useEffect(() => {
			set_is_rule_valid(validate_rule(JSON.parse(JSON.stringify(item))));
		}, [item])

		const clone_rule = async(rule_json, new_name) => {
			let rule_input_values_map = rule_json.rule_input_values ? rule_json.rule_input_values.map(rule_input => {
				let rule_input_copy = JSON.parse(JSON.stringify(rule_input));
				let new_id = generate_id()
				rule_input_copy.id = new_id
				let x = {}
				x['new'] = rule_input_copy
				x['old'] = rule_input.id
				return x;
			}) : rule_json.rule_input_values
			try{
				let body = {
					sku_linked_rule_type:'bu_level',
					bu_level_linked_rule_sku_ids: rule_json.bu_level_linked_skus && rule_json.bu_level_linked_skus.length ? rule_json.bu_level_linked_skus.map(x => x.id) : [],
					name: new_name,
					description: rule_json.description,
					order: rule_json.order+1,
					type: rule_json.type,
					rule_input_values: rule_json.rule_input_values ? rule_input_values_map.map(x => x.new) : rule_json.rule_input_values,
					valid_rule_inputs: rule_json.valid_rule_inputs,
					active: false,
					applicability_combination_logic: rule_json.applicability_combination_logic,
					condition_combination_logic: rule_json.condition_combination_logic,
					action_combination_logic: rule_json.action_combination_logic,
					applicability:rule_json.applicability,
					conditions: rule_json.conditions,
					actions: rule_json.actions.map(action => {
						let action_copy = JSON.parse(JSON.stringify(action));
						if(action_copy.rule_input_value_id){
							action_copy.rule_input_value_id = rule_input_values_map.find(x => x.old == action.rule_input_value_id).new.id
						}
						return action_copy
					}),
					business_unit_id:user.current_business_unit_id
				}
		
				let resp = await general_fetch({url:'automation_rule/create', body})
				fetch_rules()
				alert.success('Rule cloned Successfully !')
			}catch(err){
				alert.error("Rule clone failed !")
				console.log(err)
			}
		}
		

		const open_name_clone_modal = (rule_json) => {
			set_active_rule(rule_json)
			set_show_name_clone_modal(true)		
		}

		const close_name_clone_modal = () => {
			set_active_rule('')
			set_show_name_clone_modal(false)
		}

		return (
			<tr key={index} style={{display:(index+1>=start_index && index<end_index)?'flex':'none', background: is_rule_valid ? 'white' : '#FCEDEF'}} className={'tr_style' +  (is_rule_valid ? ' display_onhover' : ' display_onhover_red')}> 
				<RuleNameCloneModal open={show_name_clone_modal} handleClose={close_name_clone_modal} rule_json={active_rule} clone_rule={clone_rule} />
					<td className='td_style_no_scroll flex_property' style={{flex:1}}>
						<div className='drag_option' style={{display:'flex', alignItems:'center', color:'#808080'}}>
							<i style={{marginRight:'1px'}} className='fa fa-ellipsis-v'/>
							<i className='fa fa-ellipsis-v'/>
							<span style={{marginLeft:'10px'}}>{index+1} .</span>
						</div>
					</td>
					<td className='td_style_no_scroll flex_property' style={{flex:1}}>{item.active==1?<span><i className='fas fa-circle' style={{color: '#23C16B', marginRight:'8px'}}></i> <span>Active</span></span>  : <span><i className='fas fa-circle' style={{color: '#D9D9D9', marginRight:'8px'}}></i> <span>Inactive</span></span>}</td>
					<td className='td_style_no_scroll flex_property' style={{flex:2}}><span className='table_text_overflow'>{item.name}</span></td> 
					<td className='td_style_no_scroll flex_property' style={{flex:4}}><span className='table_text_overflow'>{item.description?item.description:'-'}</span></td>
					<td className='td_style_no_scroll flex_property' style={{flex: 2.5, color: '#FF5247'}}>{is_rule_valid ? '' : 'Some of the fields in this rule might be empty'}</td>

					{/* <td className='td_style_no_scroll flex_property' style={{flex:5}}>-</td> */}
					{/* <td className='td_style_no_scroll flex_property' style={{flex:5}}><span className='table_text_overflow'>{item.created_at?item.created_at:'-'}</span></td> */}
					
					<td className='td_style_no_scroll flex_property x1' style={{ justifyContent:'flex-end', flex:1, paddingRight:'20px'}}>
						{
							user.store_id ==item.store_id ?(
								<span className='hide'>
									<i style={{marginRight:'15px'}} onClick ={() => open_rule_update_dialog({ item, mode: 'view', is_rule_valid: is_rule_valid})} className='fa fa-eye'/>
									<i style={{marginRight:'15px'}} onClick ={() => open_rule_update_dialog({ item, mode: 'edit', is_rule_valid: is_rule_valid})} className='fa fa-edit'/>
									<img src={'/assets/img/icons/clone_icon.svg'} onClick={() => {open_name_clone_modal(item)}} width='14px' height='14px' style={{marginRight:'15px'}}/>
									<i 
										onClick={()=>submit_for_delete_confirm(()=>onclick_delete_rule(item.id), ()=>{}, 'Are you sure you want to delete this Rule? Rule Name - '+ item.name)}
										className='fa fa-trash'
									/>
								</span>
							):''
						} 
					 </td>
					{/* <td className='td_style flex_property x1' style={{ justifyContent:'flex-end', flex:'1', paddingRight:"20px"}}>
						{
							user.store_id ==rule.store_id ?(
								<span className='hide'>
									
								</span>
							):''
						}
					</td>  */}
				</tr>
		)
	}

	const HW_Rules_Table = () => (
		<table style={{height:"100%", width:'100%'}} className="text-left flex_property table_style">
			<thead style={{width:'100%'}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',width:'100%', height: '40px'}}>
				<th className='th_style flex_property' style={{flex:1}}><span>Rule Order</span></th>
				<th className='th_style flex_property' style={{flex:1}}>
					<span style={{paddingRight:'8px'}}>Active</span>
				</th>
				<th className='th_style flex_property' style={{flex:2}}><span>Rule Name</span></th>
				<th className='th_style flex_property' style={{flex:4}}><span>Description</span></th>
				<th className='th_style flex_property' style={{flex: 2.5}}>
					Violations
				</th>
				{/* <th className='th_style flex_property' style={{flex:5}}><span>Rule type</span></th> */}
				{/* <th className='th_style flex_property' style={{border:0, flex:5}}><span>Created at</span></th> */}
				<th className='th_style' style={{border:0, flex:1, paddingRight:"20px"}}><span></span></th>
			</tr>
			</thead>

			<tbody style={{height:"calc(100% - 40px)",width:'100%',overflow:'auto'}}>
				<DragDropComponent items_from_backened={filtered_display_rules} onclick_suffle_rule={onclick_suffle_rule} set_items_from_backened={set_filtered_display_rules} Single_component={Single_table_row} start_index={start_index} end_index={end_index} user={user} open_rule_update_dialog={open_rule_update_dialog} submit_for_delete_confirm={submit_for_delete_confirm} onclick_delete_rule={onclick_delete_rule} fetch_rules={fetch_hw_rules_details}/>
			</tbody>
		</table>
	);

	const on_filter_apply = (filter_options) => {
		let new_sort_value = 0;
		if(filter_options.active) new_sort_value += 1;
		if(filter_options.inactive) new_sort_value += 2;
		set_sort_value(new_sort_value)
	}

	return (
		<Main_Grid active_tab="hw_rules" user={user} store_details={store_details} get_store_details={get_store_details}>
			{/* <Filter is_filter_open={is_filter_open} set_is_filter_open={set_is_filter_open} set_sort_value={set_sort_value}></Filter> */}
			<RulesFilter is_filter_open={is_filter_open} handle_close={() => set_is_filter_open(!is_filter_open)} onChangeCallback={filter_options => on_filter_apply(filter_options)} />
			<Row style={{padding:'10px 0px', color:'#22272e'}}>
	        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
	        		Viewing {filtered_display_rules && filtered_display_rules.length ?filtered_display_rules.length:'0'} Hardware Rules
	        	</Col>
	        	<Col xs={6} style={{display: 'flex', justifyContent: 'end', gap: '16px'}}>
					<button onClick={() => set_is_filter_open(true)} className='add_from_warehouse_button' style={{color: (sort_value != 3) ? '#0078ff' : '', borderColor: (sort_value != 3) ? '#0078ff' : '#b8bfca' }}>
						<div className='flex_property' style={{ fontSize: '12px'}}>
							<div className='mr-2'>Filter</div>
							<i className='fa fa-solid fa-filter' style={{}} />
						</div>
					</button>
	        		<button onClick={open_rule_add_dialog} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Hardware Rule</span></button>
	        	</Col>
        	</Row>
	        <Row style={{height:'calc(100% - 90px)'}}>
		        <Col style={{height:'100%'}}>
		        	<Card style={{borderRadius: 0, height:'100%'}}>
		        		<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Rule Name, Description' /></div>
						<div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{filtered_display_rules && filtered_display_rules.length ? (<HW_Rules_Table />) : (<HW_Rules_Filler />)}</div>
					</Card>
		        </Col>
	        </Row>
	        <Row style={{height:'3px'}}>
		        <Col>
		        </Col>
	        </Row>
	        <Row>
		        <Col>	
					<Pegasus_Paginator 
						id='design-paginator'
						end_index={end_index}
						set_end_index={set_end_index}
						start_index={start_index}
						set_start_index={set_start_index}
						all_projects={filtered_display_rules && filtered_display_rules.length ? filtered_display_rules.length : '0'}
					/>
		        </Col>
	        </Row>
			<HWRulesModal
				all_category_type={all_category_type} 
				all_brands={all_brands} 
				designer_inputs={designer_inputs} 
				all_division_tree_data={all_division_tree_data} 
				open={show_rule_Dialog_Box} 
				handleClose={close_rule_Dialog_Box} 
				active_rule={active_rule} 
				is_active_rule_valid={is_active_rule_valid}
				user={user} 
				fetch_hw_rules_details={fetch_hw_rules_details}
				mode={key} 
				set_page_loader={set_page_loader} 
				component_options_json={component_options_json} 
				component_tags_json={component_tags_json} 
				tags={tags}
				last_item_order={last_item_order}
				sales_channels={sales_channels}
			/>
		</Main_Grid>
	);
}	


export default HW_rules;

