import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import { Card, Col, Progress, Table, Row , CardBody, Modal, Button,CardFooter, CardHeader, Input, Nav, NavItem, NavLink, TabContent, TabPane, Dropdown, DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem, ModalHeader, ModalBody} from "reactstrap";
import SalesChannelDialogBox from'./SalesChannelDialogBox'
import OptionsDialogBox from "./OptionsDialogBox"
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import classnames from "classnames";
import desc_date_sort from '../../utils/desc_date_sort';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { AllBuContext } from '../../components/Contexts/AllBuContext';
import { IModalBody, IModalFooter, IModalHeader } from '../../components/IModal';
import { ITD, ITH, ITable, ITableRow } from '../../components/ITable';
import { Tooltip } from 'antd';
import * as Sentry from '@sentry/react'
import { BuDetailsContext } from '../../components/Contexts/BuDetailsContext';
import IButton from '../../components/IButton';
import { UserContext } from '../../components/Contexts/UserContext';
import { FetchError, handleError } from '../../utils/error';

const Sc_pref_add_edit = ({open , handleClose, active_pref, onclick_update, onclick_add , mode}) => {

	const[active_sc_pref_name , set_active_sc_pref_name] = useState('');
	const[active_sc_pref_desc , set_active_sc_pref_desc] = useState('');

	useEffect (() => {
		if(active_pref){
			set_active_sc_pref_name(active_pref.name)
			set_active_sc_pref_desc(active_pref.description)
		}
	},[active_pref]);

	const onclick_handleclose = () => {
		handleClose()
		set_active_sc_pref_name('')
		set_active_sc_pref_desc('')
	}

	const onclick_add_update = async() => {
		
		let resp
		
		if(mode==='add'){
			resp = await onclick_add(active_sc_pref_name, active_sc_pref_desc)
		}else{
			resp = await onclick_update(active_sc_pref_name, active_sc_pref_desc)
		}

		if(resp){
			onclick_handleclose()
		}
	}

  	return (
		<Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={onclick_handleclose}>
			<Card className='global_modal_height_for_xs'>
				<CardHeader className='global_modal_header'>
						<span>
							<span>{mode==='add'?'Add Price Field':''}</span>
							<span>{mode==='edit' && active_pref?'Edit Price Field - '+ active_pref.name:''}</span>
						</span>
						<i onClick={handleClose} style={{cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body'>
					<Row>
						<Col>	
							<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Name</div>
							<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Name" type="text" value={active_sc_pref_name} onChange={e => set_active_sc_pref_name(e.target.value)}/>	
						</Col>	
					</Row>
					<Row>
						<Col>	
							<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Description</div>
							<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Description" type="text" value={active_sc_pref_desc} onChange={e => set_active_sc_pref_desc(e.target.value)}/>	
						</Col>	
					</Row>
				</CardBody>

				<CardFooter className='global_modal_footer'>
						<Button className='white_button' type="button" onClick={handleClose}> Cancel </Button>
						<Button className='blue_button' type="button" onClick={onclick_add_update}>{mode==='add'?'Add':'Update'}</Button>
				</CardFooter>
			</Card> 
		</Modal>
  	);
}

const PriceTypeDeleteConfirmModal = ({open, handleClose, id, name, mapped_users, mapped_sc}) => {
	
	useEffect(() => {
		console.log('price field deletion options', mapped_sc, mapped_users)
	}, [mapped_sc, mapped_users]);

	return (
		<Modal isOpen={open} toggle={handleClose} className='modal-dialog-centered' size='md'>
			<IModalHeader toggle={handleClose}>Price Field Deletion ({name})</IModalHeader>
			<IModalBody>
				{
					mapped_sc && mapped_sc.length ?
						<div className='mb-4'>
							<div className='mb-2'>The following Sales Channel have the Price Field ({name}) as their only Price Field.<br/> Please modify their details before deleting</div>
							<div style={{border: '1px solid var(--light-border-color)', backgroundColor: 'white'}}>
								<ITable style={{tableLayout: 'fixed', borderBottom: '1px solid var(--light-border-color)'}}>
									<ITableRow>
										<ITH>SC Name</ITH>
										{/* <ITH>Rule Type</ITH> */}
									</ITableRow>
								</ITable>
								<ITable rowSeparated coloumnSeparated style={{tableLayout: 'fixed'}} style_container={{maxHeight: '200px', overflow: 'auto'}}>
									{
										mapped_sc && mapped_sc.length ? mapped_sc.map(sc => (
											<ITableRow>
												<ITD>
													{sc && sc.name}
												</ITD>
												{/* <ITD style={{textTransform: 'capitalize'}}>
													{sc && sc.type ? sc.type.replace('_', ' ') : ''}
												</ITD> */}
											</ITableRow>
										))
										:''
									}
								</ITable>
							</div>
						</div>
						: ''
				}
				{
					mapped_users && mapped_users.length ?
						<div className='mb-4'>
							<div className='mb-2'>The following Users have the Price Field ({name}) as their Prefered Price Field.<br/> Please modify their details before deleting</div>
							<div style={{border: '1px solid var(--light-border-color)', backgroundColor: 'white'}}>
								<ITable style={{tableLayout: 'fixed', borderBottom: '1px solid var(--light-border-color)'}}>
									<ITableRow>
										<ITH>Name</ITH>
										<ITH>Email</ITH>
									</ITableRow>
								</ITable>
								<ITable rowSeparated coloumnSeparated style={{tableLayout: 'fixed'}} style_container={{maxHeight: '200px', overflow: 'auto'}}>
									{
										mapped_users.map(x => (
											<ITableRow>
												<ITD>{x && x.first_name}</ITD>
												<ITD>{x && x.email}</ITD>
											</ITableRow>
										))
									}
								</ITable>
							</div>
							
						</div>
						: ''
				}
			</IModalBody>
			{/* <IModalFooter>
				<Tooltip title={mapped_automation_rules && mapped_automation_rules.length ? 'Cannot Delete Sales Chennel with rules mapped to it': ''} placement='top'>
					<div>
						<Button style={{opacity: mapped_automation_rules && mapped_automation_rules.length ? 0.5 : 1}} onClick={mapped_automation_rules && mapped_automation_rules.length ? null : () => {force_delete_sales_channel(id); handleClose()}} className='blue_button'>Delete</Button>
					</div>
				</Tooltip>
			</IModalFooter> */}
		</Modal>
	)
}

const SalesChannelPreferencesDialogBox = ({open , handleClose, all_sc_preferences, fetch_all_pref, all_user_accounts_map_by_user_id, sales_channel_details, submit_for_delete_confirm, set_page_loader }) => {

	const[sales_channel_id , set_sales_channel_id] = useState('');
	const[show_active_pref_modal , set_show_active_pref_modal] = useState(false);
	const[active_pref , set_active_pref] = useState('');
	const[mode , set_mode] = useState('add');
	const alert = useAlert();

	const [active_price_type_options, set_active_price_type_options] = useState({});
	const [remove_pt_modal_open, set_remove_pt_modal_open] = useState(false);
	const open_remove_pt_modal = () => {set_remove_pt_modal_open(true)}
	const close_remove_pt_modal = () => {set_remove_pt_modal_open(false)}

	const validate_sc_preference_name = (name) => {
		return name && !(all_sc_preferences && all_sc_preferences.length && all_sc_preferences.filter(x => x.name == name).length)
	}

	const onclick_add = async (name, desc) => {
		let name_trimmed = name ? name.trim() : ''
		if(validate_sc_preference_name(name_trimmed)){
			try {
				var body = {name : name_trimmed, description:desc}
				var response = await general_fetch({ url: 'price_type/create', body});
				console.log(response)
				fetch_all_pref();
				return 1
			} catch(err) {
				console.log(err);
			}
		}else{
			if(name_trimmed){
				alert.error('Price Field with given name already exists. Please enter a different name')
			}else{
				alert.error('Name of the Price Field cannot be empty. Please enter a name')
			}
		}
		return 0
	}

	const onclick_update = async (name, desc) => {
		let name_trimmed = name.trim()
		if(name_trimmed != active_pref.name.trim() && !validate_sc_preference_name(name_trimmed)){
			if(name_trimmed){
				alert.error('Price Field with given name already exists. Please enter a different name')
			}else{
				alert.error('Name of the Price Field cannot be empty. Please enter a name')
			}
			return
		}
		try {
			var body = {id: active_pref.id,name : name, description:desc}
			console.log(body)
			var response = await general_fetch({ url: 'price_type/update', body});
			console.log(response)
			fetch_all_pref();
			return 1
		} catch(err) {
			console.log(err);
		}
		return 0
	}

	const onclick_delete = async (pt) => {
		try {
			var body = {price_type_id: pt.id}
			set_page_loader({show: true, text:'Please wait...'});
			let pt_details = await general_fetch({ url: 'price_type/remove', body});
			set_page_loader({show: false});
			// console.log("Price Type Deletion response", response)
			if(pt_details && pt_details.successfully_removed){
				alert.success('Price Field deleted Successfully')
				fetch_all_pref();
			}else{
				set_active_price_type_options({
					'id': pt.id,
					'name': pt.name,
					// 'sku': pt_details.num_skus_mapped,
					'users': pt_details.users_map_req,
					'sc': pt_details.sales_channel_map_req
				})
				open_remove_pt_modal()
				// console.log("Cannot be Deleted")
			}
		} catch(err) { 
			set_page_loader({show: false});
			console.log(err);
		}
	}

	// const onclick_pref = (x_item) => {
	// 	console.log(x_item)
	// }

	const open_active_pref_modal = (mode, x_item) => {
		set_show_active_pref_modal(true)
		set_active_pref(x_item?x_item:'')
		set_mode(mode)	
	}
	const close_active_pref_modal = () => {
		set_show_active_pref_modal(false)	
	}

	const onclick_handle_close = () => {
		handleClose()
		set_sales_channel_id('')
		set_show_active_pref_modal(false)
		set_active_pref('')
		set_mode('add')
	}

  	return (
		<Modal className="Margin_64px_top" size="lg" isOpen={open} toggle={onclick_handle_close}>
			<Card className='global_modal_height_for_mdlgxl'>
				<CardHeader className='global_modal_header'>
						<span>
							<span>Sales Channel Preferences</span>
						</span>
						<span>
							{/* <button className='blue_button' style={{marginRight:'20px'}} onClick ={() => open_active_pref_modal('add')}>Add Price Field</button> */}
							<i onClick={onclick_handle_close} style={{cursor: 'pointer'}} className='fa fa-times'/>
						</span>
						
				</CardHeader>

				<CardBody className='global_modal_body'>
					<div style={{fontWeight: 500}} className='mb-3 px-3 flex_property justify-content-between'>
						Price Fields
						<button className='blue_button' style={{marginRight:'20px'}} onClick ={() => open_active_pref_modal('add')}>Add Price Field</button>
					</div>
						
					<div style={{backgroundColor: 'white'}} className='p-3'>
						{
							all_sc_preferences && all_sc_preferences.length? all_sc_preferences.map((x_item, idx) => (
								<div key={idx} style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'10px 0px'}}>
									<div>
										{idx+1+'. '}{x_item.name}
									</div>
									<div>	
										<i onClick={()=> open_active_pref_modal('edit', x_item)} style={{marginRight:'15px', cursor:'pointer', color:'#0078ff'}} className='fa fa-edit'/>
										<i onClick={()=> submit_for_delete_confirm(() => onclick_delete(x_item), () => {}, `The price field ${x_item.name} will be deleted permanently`)} style={{cursor:'pointer', color: 'red'}} className='fa fa-trash'/>	
									</div>
								</div>
							))
							:
							<div>No Items to list.</div>
						}
					</div>
				</CardBody>

				<CardFooter className='global_modal_footer'>
						<Button className='white_button' type="button" onClick={handleClose}> Cancel </Button>
						{/* <Button className='blue_button' type="button" onClick={update_sales_name}>Update</Button>} */}
				</CardFooter>
			</Card>  
			<Sc_pref_add_edit open={show_active_pref_modal} handleClose={close_active_pref_modal} active_pref={active_pref} onclick_update={onclick_update} onclick_add={onclick_add} mode={mode}/>
			<PriceTypeDeleteConfirmModal open={remove_pt_modal_open} handleClose={close_remove_pt_modal} id={active_price_type_options.id} name={active_price_type_options.name} mapped_users={active_price_type_options.users && all_user_accounts_map_by_user_id ? active_price_type_options.users.map(x =>  x && all_user_accounts_map_by_user_id[x]) : []} mapped_sc={active_price_type_options.sc && sales_channel_details ? active_price_type_options.sc.map(x => x && sales_channel_details[x]) : []}/>
		</Modal>
  	);
}

const SalesChannelDeleteConfirmModal = ({open, handleClose, id, name, mapped_automation_rules, mapped_users, num_of_skus_mapped, force_delete_sales_channel}) => {
	
	const [automation_rule_ids_to_name, set_automation_rule_ids_to_name] = useState([]);

	const fetch_rules_data = async() => {
		try{
			let resp = await general_fetch({url: 'automation_rule/get_by_id', body:{ids: mapped_automation_rules}})
			set_automation_rule_ids_to_name(resp)
		}catch(err){
			console.error("Fetching Automation Rules Failed ", err)
		}
	}

	useEffect(() => {
		if(mapped_automation_rules && mapped_automation_rules.length){
			fetch_rules_data()
		}
	}, [mapped_automation_rules]);	

	return (
		<Modal isOpen={open} toggle={handleClose} className='modal-dialog-centered' size='md'>
			<IModalHeader toggle={handleClose}>Sales Channel Deletion Confirmation ({name})</IModalHeader>
			<IModalBody>
				{
					mapped_automation_rules && mapped_automation_rules.length ?
						<div className='mb-3'>
							<div className='mb-2 note_sales_channel_deletion'>The Sales Channel is Mapped to the following Rules.<br/> Modify these rules before deleting the Sales Channel.</div>
							<div style={{border: '1px solid var(--light-border-color)', backgroundColor: 'white'}}>
								<ITable style={{tableLayout: 'fixed', borderBottom: '1px solid var(--light-border-color)'}}>
									<ITableRow>
										<ITH>Rule Name</ITH>
										<ITH>Rule Type</ITH>
									</ITableRow>
								</ITable>
								<ITable rowSeparated coloumnSeparated style={{tableLayout: 'fixed'}} style_container={{maxHeight: '200px', overflow: 'auto'}}>
									{
										automation_rule_ids_to_name && automation_rule_ids_to_name.length ? automation_rule_ids_to_name.map(rule => (
											<ITableRow>
												<ITD>
													{rule && rule.name}
												</ITD>
												<ITD style={{textTransform: 'capitalize'}}>
													{rule && rule.type ? rule.type.replace('_', ' ') : ''}
												</ITD>
											</ITableRow>
										))
										:''
									}
								</ITable>
							</div>
						</div>
						: ''
				}
				{
					num_of_skus_mapped ?
						<div className='mb-3'>
							<div className='note_sales_channel_deletion'> <b>Note : </b> This Sales Channel is mapped to <b>{num_of_skus_mapped}</b> Skus. <br/> Deleting the Sales Channel will <b>unmap</b> the Sales channel from the Skus</div>
						</div>
						: ''
				}
				{
					mapped_users && mapped_users.length ?
						<div className='mb-3'>
							<div className='mb-2 note_sales_channel_deletion'> <b>Note : </b> The Sales channel is mapped to the following <b>{mapped_users && mapped_users.length}</b> Users. <br/> Deleting the Sales Channel will <b>unmap</b> the Sales channel from the Users</div>
							<div style={{border: '1px solid var(--light-border-color)', backgroundColor: 'white'}}>
								<ITable style={{tableLayout: 'fixed', borderBottom: '1px solid var(--light-border-color)'}}>
									<ITableRow>
										<ITH>Name</ITH>
										<ITH>Email</ITH>
									</ITableRow>
								</ITable>
								<ITable rowSeparated coloumnSeparated style={{tableLayout: 'fixed'}} style_container={{maxHeight: '200px', overflow: 'auto'}}>
									{
										mapped_users.map(x => (
											<ITableRow>
												<ITD>{x && x.first_name}</ITD>
												<ITD>{x && x.email}</ITD>
											</ITableRow>
										))
									}
								</ITable>
							</div>
							
						</div>
						: ''
				}
			</IModalBody>
			<IModalFooter>
				<Tooltip title={mapped_automation_rules && mapped_automation_rules.length ? 'Cannot Delete Sales Chennel with rules mapped to it': ''} placement='top'>
					<div>
						<Button style={{opacity: mapped_automation_rules && mapped_automation_rules.length ? 0.5 : 1}} onClick={mapped_automation_rules && mapped_automation_rules.length ? null : () => {force_delete_sales_channel(id); handleClose()}} className='blue_button'>Delete</Button>
					</div>
				</Tooltip>
			</IModalFooter>
		</Modal>
	)
}


const SalesChannel = ({user, submit_for_delete_confirm, set_active_tab, send_analytics_event, check_route_fn, store_details, get_store_details, set_page_loader}) => {
	
	const styles = {
		add_brand_style:{
			backgroundColor: '#007EF4',
			boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
			borderRadius: '3px',
			height:'32px',
			padding:'0px 24px',
			border:'0px',
			color:'white',
		},
		table_header: {
			fontWeight: "bold",
			fontSize: 'small'
		},
    };

	const [sales_channel , set_sales_channel] = useState([]);
	const [sales_channels_map, set_sales_channels_map] = useState({});
	const [all_public_sales_channels, set_all_public_sales_channels] = useState([]);
	const [subscribed_sales_channels, set_subscribed_sales_channels] = useState([]);
	// const [users , set_users] = useState([]);
	const [open_sales_channel_Dialog, set_open_sales_channel_Dialog] = useState(false);
	const [open_options_dialog, set_open_options_dialog] = useState(false);
	const [active_sales_channel , set_active_sales_channel] = useState({});
	const [active_sc_for_delete_in_options , set_active_sc_for_delete_in_options] = useState({});
	const [search_string , set_search_string] = useState('');
	const [display_sales_channel , set_display_sales_channel] = useState([]);
	const [mode , set_mode] = useState(null);
	
	const [page_size , set_page_size] = useState(30)
	const [fetch_progress , set_fetch_progress] = useState(40)
	const [current_page , set_current_page] = useState(1)
	const [initialized , set_initialized] = useState(false) 
 	const [all_pages , set_all_pages] = useState([])
	const [show_sales_channel_preferences_dialog, set_show_sales_channel_preferences_dialog] = useState(false);
	const [all_sc_preferences , set_all_sc_preferences] = useState([]);
	const [tabs, set_tabs] = useState(1);
	const [subscription_requests_data, set_subscription_requests_data] = useState({});
	const org_bu_view = useContext(OrgBuContext)
	const all_bu_in_store = useContext(AllBuContext)
	const bu_details = useContext(BuDetailsContext)
	const [ show_subscriptions, set_show_subscriptions ] = useState(false);

	window.show_public_sales_channel = () => {
		set_show_subscriptions(true)
	}

	const [	all_users_accounts , set_all_users_accounts ] = useState([])
	const [all_user_accounts_map_by_user_id, set_all_user_accounts_map_by_user_id] = useState({});
	// const [all_ffff, set_all_ffff] = useState('string1');
	const roles_options = [{name: 'Designer', id: 'designer'},{name: 'Project Manager', id: 'project_manager'},{name: 'Account Manager', id: 'accounts_manager'},{name: 'Catalogue Manager', id: 'catalog_manager'}]		


	const [remove_sales_channel_modal_open, set_remove_sales_channel_modal_open] = useState(false);
	const [active_sales_channel_options, set_active_sales_channel_options] = useState({});
	const open_remove_sales_channel_modal = () => set_remove_sales_channel_modal_open(true)
	const close_remove_sales_channel_modal = () => set_remove_sales_channel_modal_open(false)

	const alert = useAlert()

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	const validate_sales_channel_name = (name, type) => {
		let matched = sales_channel.filter(sc => sc.name == name).length
		return name && !(sales_channel && sales_channel.length && (type === 'update' ? matched > 1 : matched))
	}

	const fetch_sales_channel_details = async () => {
		try {
			// var brand_timer = setInterval(() => {
			// 	var x = Number(fetch_progress);
			// 	console.log('xxx ---> ', x,x + Math.floor((100 - x)/2))
			// 	set_fetch_progress(String(x + Math.floor((100 - x)/2)))  
			// }, 200);

			let resp, sales_channel_details = [], subscribed_sales_channel_details = []
			let body = {
				"include_sales_channel_profile_info": true, 
				"include_bu_mapping": true, 
				"include_bu_info": false, 
				"include_price_type_mapping": true, 
				"include_price_type_info": true, 
				"include_user_mapping": true, 
				"include_user_info": false
			}

			if(org_bu_view === 'bu'){
				if(user && user.current_business_unit_id){
					body['business_unit_ids'] = [user.current_business_unit_id]
					resp = await general_fetch({ url: 'sales_channel/get_by_business_unit' , body})
					let owned_sc = resp && resp.sales_channels_by_business_unit && resp.sales_channels_by_business_unit[user.current_business_unit_id] ? resp.sales_channels_by_business_unit[user.current_business_unit_id].owned : []
					let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x) && !(all_bu_in_store && all_bu_in_store.length === 1 && resp.sales_channels[x].type === 'bu_feeder')) sales_channel_details.push(resp.sales_channels[x])}) : []

					let subscribed_sc = resp && resp.sales_channels_by_business_unit && resp.sales_channels_by_business_unit[user.current_business_unit_id] ? resp.sales_channels_by_business_unit[user.current_business_unit_id].subscribed : []
					t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(subscribed_sc.includes(x)) subscribed_sales_channel_details.push(resp.sales_channels[x])}) : []
					set_subscribed_sales_channels(subscribed_sales_channel_details)
				}
			}else{
				resp = await general_fetch({ url: 'sales_channel/get_of_store' , body})
				sales_channel_details = resp && resp.sales_channels && resp.sales_channels.owned ? resp.sales_channels.owned.filter(x => !x.business_unit_id) : []
			}

			let temp_sales_channel_map = {}
			sales_channel_details = sales_channel_details.map(single_sales_channel => {
				if(single_sales_channel.id){
					temp_sales_channel_map[single_sales_channel.id] = single_sales_channel
				}
                let price_types = single_sales_channel.price_type_ids && single_sales_channel.price_type_ids.length ? single_sales_channel.price_type_ids.map(x => resp.price_types[x]) : []
                return {...single_sales_channel, price_types}
            })

			set_sales_channels_map(temp_sales_channel_map)
			set_initialized(true)
			set_sales_channel(sales_channel_details);
			// if(tabs == 1){
			// 	filter_sales_channel()
			// 	// set_display_sales_channel(sales_channel_details)
			// }

			resp = await general_fetch({url:'sales_channel/get_public', body: {include_org_info: true, include_subscription_requests_info: false}})
			let public_sales_channel_details = []
			let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(store_details && resp.sales_channels[x].store_id != store_details.id) {resp.sales_channels[x].store_details = resp.orgs[resp.sales_channels[x].store_id] ; public_sales_channel_details.push(resp.sales_channels[x])}}) : []
			set_all_public_sales_channels(public_sales_channel_details)
			// if(tabs == 2){
			// 	filter_sales_channel()
			// 	// set_display_sales_channel(public_sales_channel_details)
			// }

			let body_sub = {filter_by_org: true}

			if(org_bu_view === 'bu'){
				body_sub['business_unit_id'] = user.current_business_unit_id;
			}
			resp = await general_fetch({url:'sales_channel/subscription_request/get', body: body_sub})
			resp = resp.sales_channel_subscription_requests
			t = Object.keys(resp) && Object.keys(resp).length ? Object.keys(resp).map((x) => {
						resp[x].sort((a,b) => desc_date_sort(b,a,'created_at'))
					}):''
			console.log('set_subscription_requests_data', resp)
			set_subscription_requests_data(resp)

			
		} catch(err) {
			console.log(err);
			handleError(err)
		}
	}

	const delete_sales_channel = async (sc) => {
		try {
			// var identifiers = JSON.stringify({sales_channel_id : id, force_unmap_designers: false, force_unmap_skus: false})
			// var body = {identifiers}
			var body = ({sales_channel_id : sc.id, force_unmap_designers: false, force_unmap_skus: false})
			var sales_channel_details = await general_fetch({ url: 'sales_channel/remove' , body, req_resp: true});
			if(sales_channel_details.response_code == 0 || sales_channel_details.response_code == 1){
				sales_channel_details = sales_channel_details.data
				if(sales_channel_details.is_deleted){
					alert.success('Sales Channel deleted Successfully')
					fetch_sales_channel_details();
				}else{
					set_active_sales_channel_options({
						'id': sc.id,
						'name': sc.name,
						'sku': sales_channel_details.num_skus_mapped,
						'users': sales_channel_details.user_ids_mapped,
						'rules': sales_channel_details.automation_rule_ids
					})
					open_remove_sales_channel_modal()
				}
			}else{
				if(sales_channel_details.action == 'TOAST_ERROR'){
					alert.error(sales_channel_details.message ? sales_channel_details.message : 'Something went wrong. Your Sales Channel could not be deleted')
				}
				else{
					throw new Error('Server call failed with response ---> ' + JSON.stringify(sales_channel_details)); 
				}
			}
		} catch(err) {
			if(!(err instanceof FetchError && err.response.action === "TOAST_ERROR")){
				alert.error('Something went wrong. Your Sales Channel could not be deleted')
			}
			console.log(err);
			handleError(err)
		}
	}

	const force_delete_sales_channel = async (id) => {
		try {
			// var identifiers = JSON.stringify({sales_channel_id : id, force_unmap_designers: false, force_unmap_skus: false})
			// var body = {identifiers}
			var body = ({sales_channel_id : id, force_unmap_designers: true, force_unmap_skus: true})
			var sales_channel_details = await general_fetch({ url: 'sales_channel/remove' , body});
			if(sales_channel_details.is_deleted){
				alert.success('Sales Channel deleted Successfully')
				fetch_sales_channel_details();
			}else{
				alert.error('Something went wrong. Your Sales Channel could not be deleted')
			}
		} catch(err) {
			alert.error('Something went wrong. Your Sales Channel could not be deleted')
			console.log(err);
		}
	}

	const fetch_all_pref = async () => {
		try {
			set_page_loader({show: true, text:'Please wait...'});
			var resp = await general_fetch({ url: 'price_type/get_all_of_store'});
			set_page_loader({show: false});
			set_all_sc_preferences(resp && resp.length?resp:[])
		} catch(err) {
			set_page_loader({show: false});
			console.log(err);
		}
	}

	useEffect(() => {
		if(tabs==1)filter_sales_channel()
		if(active_sc_for_delete_in_options.id){
			var x = sales_channel.filter((sc) => sc.id==active_sc_for_delete_in_options.id)[0]
			set_active_sc_for_delete_in_options(x)

		}
	}, [sales_channel]);

	useEffect(() => {
		if(tabs==2)filter_sales_channel()
	}, [all_public_sales_channels]);

	useEffect(() => {
		if(org_bu_view && user && all_bu_in_store && all_bu_in_store.length){
			fetch_sales_channel_details();
		}
	}, [user, org_bu_view, all_bu_in_store]);

	useEffect(() => {
		if(user)fetch_all_pref()
	}, [user]);

	useEffect(() => {
		set_active_tab('Sales')
		// check_route_fn('Sales')
		fetch_account_details()
		send_analytics_event('auto', 'Sales Channel', 'Sales Channel', 1, window.location.href)	
	}, []);

	const show_open_options_dialog = (sales_channel) => {
		set_open_options_dialog(true);
		set_active_sc_for_delete_in_options(sales_channel);
	}

	const close_open_options_dialog = () => {
		set_open_options_dialog(false);
		set_active_sc_for_delete_in_options({});
	}
	
	const show_sales_channel_update_dialog = (sales_channel) => {
		set_open_sales_channel_Dialog(true);
		set_active_sales_channel(sales_channel);
		set_mode('update');
	}


	const show_sales_channel_add_dialog = () => {
		set_open_sales_channel_Dialog(true);
		set_mode('add');
	}

	const show_sales_channel_share_dialog = (sales_channel) => {
		set_open_sales_channel_Dialog(true);
		set_active_sales_channel(sales_channel);
		set_mode('share');
	}

	const close_sales_channel_Dialog = () => {
		set_open_sales_channel_Dialog(false);
		set_active_sales_channel({});
		set_mode(null);
	}

	const open_sales_channel_preferences_dialog = () => {
		set_show_sales_channel_preferences_dialog(true)	
	}
	const close_sales_channel_preferences_dialog = () => {
		set_show_sales_channel_preferences_dialog(false)	
	}

	const fetch_account_details = async () => {
		try {
			var account_details = await general_fetch({ url: 'user/get_all'});
			if(account_details && account_details.length){
				var account_details = account_details.filter( account => !account.deprecated)
			}
			if(account_details && account_details.length){
				var account_details = [...account_details];
				var account_details = account_details.filter( account => 
					account.role = account.role && account.role.length ? roles_options.filter((x) => account.role.includes(x.id)):[]
				)
			}
			console.log('all users account_details=======>',account_details)
			set_all_users_accounts(account_details);
			let temp_all_user_accounts_map = {}
			account_details && account_details.length && account_details.map(x => {
				if(x.id){
					temp_all_user_accounts_map[x.id] = x;
				}
			})

			set_all_user_accounts_map_by_user_id(temp_all_user_accounts_map)
			// console.log("all_user_acoounts_map", temp_all_user_accounts_map)
			// set_display_all_users_accounts(account_details?account_details:'');
			// set_initialized(true)
		} catch(err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if (initialized) {
			var pages = Number(Math.ceil(display_sales_channel.length / page_size));
			console.log('all pages --> ', pages, 'projects length --<>', display_sales_channel.length)
			var tmp = Array.apply(null, {length: pages + 1}).map(Number.call, Number)
			tmp.splice(0, 1);
			console.log('tmp --<>', tmp)
			set_all_pages(tmp);
			console.log('tmp spliced--<>', tmp)
			set_current_page(1);
		}
	}, [ page_size, display_sales_channel ])

	useEffect(() => {
		if(tabs == 1){
			set_display_sales_channel(sales_channel)
		}else{
			set_display_sales_channel(all_public_sales_channels)
		}
	}, [tabs]);

	const Sales_Channel_Filler = () => (
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
				<tr>
					{ initialized ? (
						<th scope="col"><span style={styles.table_header}><b>No channels to list  </b></span><br/> Try adjusting filters...<br /> &nbsp;</th>
					):(
						<th scope="col"><span style={styles.table_header}><b>Fetching all channels  </b></span><br/> <br /> Please wait...<br /> <br />
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;</th>
					)}
				</tr>
			</thead>
		</Table>
	);

	const filter_sales_channel = () => 
	{
		var filtered_sales_channels = tabs == 1 ? [...sales_channel] : [...all_public_sales_channels];
		var lower_search_string = search_string ? search_string.toLowerCase():'';
		if(lower_search_string){
			filtered_sales_channels = filtered_sales_channels.filter( sales_channel => sales_channel.name.toLowerCase().includes(lower_search_string))
		}
		set_display_sales_channel(filtered_sales_channels)
	}

	useEffect(() => {
		filter_sales_channel();
	}, [search_string]);


	const subscription_action = async(sales_channel_id, sales_channel_subscription_request_id, action) => {
		try{
			if(action === 'subscribe'){
				let resp = await general_fetch({url:'sales_channel/subscription_request/raise', body: {sales_channel_id, business_unit_id: user.current_business_unit_id}})
				if(resp.status === 'approved'){
					alert.success('Successfully subscribed to the sales channel')
				}else{
					alert.success('Successfully raised subscription request')
				}
			}else if(action === 'unsubscribe'){
				let resp = await general_fetch({url:'sales_channel/unsubscribe', body: {sales_channel_id, business_unit_id: user.current_business_unit_id}})
				alert.success('Successfully unsubscribed')
			}else if(action === 'withdraw'){
				let resp = await general_fetch({url:'sales_channel/subscription_request/withdraw', body: {sales_channel_subscription_request_id, business_unit_id: user.current_business_unit_id}})
				alert.success('Successfully withdrawn subscription request')
			}
			await fetch_sales_channel_details()
		}catch(err){	
			console.log(err)
		}
	}

	const Sales_Channel_Table = () => (
		<table style={{height:'100%'}} className="text-left flex_property table_style">
			<thead style={{width:"100%"}}>
				<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6', height:'40px'}}>
					<th className='th_style flex_property' style={{flex:'16'}}><span>Channel Name</span></th>
					{/* <th className='th_style flex_property' style={{border:0, flex:'3'}}><span>No. of SKUs</span></th>
					<th className='th_style flex_property' style={{border:0, flex:'3'}}><span>Designer Subscriptions</span></th>
					<th className='th_style flex_property' style={{border:0, flex:'3'}}><span>Store Subscriptions</span></th> */}
					{/* <th className='th_style flex_property' style={{border:0, flex:'2'}}><span>Eligiblity</span></th> */}
					<th className='th_style flex_property' style={{border:0, flex:'1', paddingRight:"20px", justifyContent:'center'}}><span>Actions</span></th>
				</tr>
			</thead>
			<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
			{display_sales_channel && display_sales_channel.length && display_sales_channel.map((sales_channel, idx) => (
					<tr key={idx} style={{display:'flex'}} className='tr_style display_onhover'>
					
						<td className='td_style flex_property' style={{flex:'16'}}>
							<div className='table_text_overflow flex_property' style={{height:'100%'}}>
								{sales_channel.name}
								{sales_channel.type=='bu_feeder' ? <div className='template_box' style={{marginLeft:'8px'}}><div className='template'>BU Feeder</div></div>:''}
								{sales_channel.type=='default' ? <div className='template_box' style={{marginLeft:'8px'}}><div className='template'>Default</div></div>:''}
								{sales_channel.sharing=='public' ? <div className='template_box' style={{marginLeft:'8px', width:'52px'}}><div className='template' style={{width:'52px'}}>Public</div></div>:''}
							</div>
						</td>
						{/* <td className='td_style flex_property' style={{ flex:'3'}}>0</td>
						<td className='td_style flex_property' style={{ flex:'3'}}>0</td>
						<td className='td_style flex_property' style={{ flex:'3'}}>0</td> */}
						{/* <td className='td_style flex_property x1' style={{ justifyContent:'flex-center', flex:'2'}}>
							{
								!(sales_channel.name == "ISN Feeder") ?
								<div className='flex_property'>
									<span style={{marginRight:'15px'}} className='hide'><i onClick ={() => show_sales_channel_share_dialog(sales_channel)} className='fa fa-share'/></span>
									<span style={{marginLeft:'12px'}} className='hide'><i onClick ={() => show_open_options_dialog(sales_channel)} className='fa fa-cog'/></span>
								</div>
								:""
							}
						</td> */}
						<td className='td_style flex_property x1' style={{ justifyContent:'center', flex:'1', paddingRight:"20px", overflow:'unset'}}>
							{
								!(sales_channel.type == "isn_feeder") ?
								<div className='flex_property'>
									{/* <span style={{marginLeft:'12px'}} className='hide'><i onClick = {() => show_sales_channel_update_dialog(sales_channel)} className='fa fa-edit'/></span> */}
									{/* <span className='hide'><i onClick ={() => submit_for_delete_confirm(()=>delete_sales_channel(sales_channel.id), ()=>{}, 'Sales Channel will be deleted permanently.')} className='fa fa-trash'/></span> */}
									{/* <span className='hide'> */}
										<UncontrolledDropdown>
											<DropdownToggle color="grey" className="btn-circle">
												<i className='fa fa-bars'/>
											</DropdownToggle>
											<DropdownMenu right>
												<DropdownItem onClick= {() => show_sales_channel_update_dialog(sales_channel)}>Edit</DropdownItem>
												{ !['default', 'bu_feeder'].includes(sales_channel.type) ? <DropdownItem onClick ={() => submit_for_delete_confirm(() => delete_sales_channel(sales_channel), () => {}, 'Sales Channel will be deleted permanently.')} style={{color: 'red'}}>Delete</DropdownItem> : ''}
											</DropdownMenu>
										</UncontrolledDropdown>
									{/* </span> */}
								</div>
								:""
							}
						</td>
					</tr>
			))}
			</tbody>
		</table>
	);

	const Public_Sales_Channel_Table = () => (
		<table style={{height:'100%'}} className="text-left flex_property table_style">
			<thead style={{width:"100%"}}>
				<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6', height:'40px'}}>
					<th className='th_style flex_property' style={{flex:'10'}}><span>Channel Name</span></th>
					<th className='th_style flex_property' style={{flex:'10'}}><span>Parent Organization</span></th>
					<th className='th_style flex_property' style={{border:0, flex:'4', whiteSpace: 'nowrap'}}><Tooltip title={"Subscribed channels will be shown under Public Catalogue while designing"}><span>Show in Public Catalogue</span></Tooltip></th>
				</tr>
			</thead>
			<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
			{display_sales_channel && display_sales_channel.length && display_sales_channel.map((single_sales_channel, idx) => (
				!(sales_channel.find(o => o.id === single_sales_channel.id)) ?
					<tr key={idx} style={{display:'flex'}} className='tr_style display_onhover'>
						<td className='td_style flex_property' style={{flex:'10'}}>
							<span className='table_text_overflow flex_property'>
								{single_sales_channel.name}
							</span>
						</td>
						<td className='td_style flex_property' style={{flex:'10'}}>
							<span className='table_text_overflow flex_property'>
								{single_sales_channel.store_details ? single_sales_channel.store_details.name : ''}
							</span>
						</td>
						<td className='td_style flex_property x1' style={{flex:'4'}}>
							<span style={{cursor:'pointer'}}>
							{
								subscription_requests_data && subscription_requests_data[single_sales_channel.id] && subscription_requests_data[single_sales_channel.id].length && subscription_requests_data[single_sales_channel.id][0].status == 'pending_approval' ? 
									<IButton ghost={true} color={'warning'} onClick={async() => {await subscription_action(single_sales_channel.id, subscription_requests_data[single_sales_channel.id][0].id, 'withdraw')}}>{'Withdraw'}</IButton> :(
										subscribed_sales_channels.find(o => o.id === single_sales_channel.id) ? 
										<IButton ghost={true} color={'warning'} onClick={async() => {if(single_sales_channel.name !== 'Infurnia Supply Network'){await subscription_action(single_sales_channel.id, null, 'unsubscribe')}}} style={{ opacity: single_sales_channel.name === 'Infurnia Supply Network' ? '0.5' : '1'}}>{'Unsubscribe'}</IButton>:
										<IButton ghost={true} onClick={async() => {await subscription_action(single_sales_channel.id, null, 'subscribe')}} style={{color:'#0078ff'}}>{'Subscribe'}</IButton>
									)
							}
							</span>
						</td>
					</tr>:''
			))}
			</tbody>
		</table>
	)

	return (
		<Main_Grid active_tab="Sales" user={user} store_details={store_details} get_store_details={get_store_details}>
			<Row style={{padding:'10px 0px', color:'#22272e'}}>
	        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
	        		Viewing {display_sales_channel && display_sales_channel.length ?display_sales_channel.length:'0'} Sales Channels
	        	</Col>
	        	<Col xs={6} style={{justifyContent:'flex-end', display:'flex', }}>
					<button onClick ={() => open_sales_channel_preferences_dialog()} style={{...styles.add_brand_style, marginRight:'15px'}}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Sales Channel Preferences</span></button>
	        		<button onClick ={() => show_sales_channel_add_dialog()} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Sales Channel</span></button>
	        	</Col>
        	</Row>
	        <Row style={{height:'calc(100% - 90px)'}}>
				<Col style={{height:'100%'}}>
					<Card style={{borderRadius: 0, height:'100%', overflowY:'scroll'}}>

						<Nav
							style={{ display: 'flex', alignItems: 'center', padding: bu_details.default ? '0px' : '8px 0px' }}
							className="flex-md-row"
							id="tabs-icons-text"
							pills
							role="tablist"
						>
							<NavItem>
								<NavLink
									aria-selected={tabs === 1}
									style={{ marginLeft: '10px' }}
									className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 1
									})}
									onClick={e => toggleNavs(e, 1)}
									href="#pablo"
									role="tab"
								>
									<span style={{ fontSize: '14px' }}>Owned Sales Channels</span>
								</NavLink>
							</NavItem>
							{
								bu_details.default && show_subscriptions ?
									<NavItem>
										<NavLink
											aria-selected={tabs === 2}
											className={classnames("mb-sm-3 mb-md-0", {
												active: tabs === 2
											})}
											onClick={e => toggleNavs(e, 2)}
											href="#pablo"
											role="tab"
										>
											<span style={{ fontSize: '14px' }}>Sales Channel Subscriptions</span>
										</NavLink>
									</NavItem>
									: ''
							}
							</Nav>
						<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Sales Channel Name' /></div>
						<TabContent activeTab={"tabs" + tabs} style={{display : tabs==1 ? 'block' : 'none'}}>
							<TabPane tabId={'tabs1'} style={{fontSize:'12px'}}>
								<div id='design-paginator'>{display_sales_channel && display_sales_channel.length ? (<Sales_Channel_Table />) : (<Sales_Channel_Filler />)}</div>
							</TabPane>
						</TabContent>

						{
							bu_details.default ?
								<TabContent activeTab={"tabs" + tabs} style={{ height: 'calc(100% - 80px)', display: tabs == 2 ? 'block' : 'none' }}>
									<TabPane tabId={'tabs2'} style={{ fontSize: '12px' }}>
										<div id='design-paginator'>{display_sales_channel && display_sales_channel.length ? (<Public_Sales_Channel_Table />) : (<Sales_Channel_Filler />)}</div>
									</TabPane>
								</TabContent>
								: ''
						}
					</Card>
				</Col>
	        </Row>
			<SalesChannelDialogBox open = {open_sales_channel_Dialog} handleClose = {close_sales_channel_Dialog} sales_channel={active_sales_channel} user= {user} fetch_sales_channel_details = {fetch_sales_channel_details} mode = {mode} all_sc_preferences={all_sc_preferences} store_details = {store_details} set_page_loader={set_page_loader} all_users_accounts={all_users_accounts.filter(x => x.role.filter(y => y.id == 'designer').length)} org_bu_view={org_bu_view} validate_sales_channel_name={validate_sales_channel_name}/>
			<SalesChannelPreferencesDialogBox open = {show_sales_channel_preferences_dialog} handleClose = {close_sales_channel_preferences_dialog} sales_channel={active_sales_channel} user= {user} fetch_sales_channel_details = {fetch_sales_channel_details} mode = {mode} all_sc_preferences={all_sc_preferences} fetch_all_pref={fetch_all_pref} all_user_accounts_map_by_user_id={all_user_accounts_map_by_user_id} sales_channel_details={sales_channels_map} submit_for_delete_confirm={submit_for_delete_confirm} set_page_loader={set_page_loader}/>
			<OptionsDialogBox open = {open_options_dialog} handleClose = {close_open_options_dialog} sales_channel={active_sc_for_delete_in_options} user= {user} fetch_sales_channel_details = {fetch_sales_channel_details} />
			<SalesChannelDeleteConfirmModal open={remove_sales_channel_modal_open} handleClose={close_remove_sales_channel_modal} id={active_sales_channel_options.id} name={active_sales_channel_options.name} mapped_automation_rules={active_sales_channel_options.rules} mapped_users={active_sales_channel_options.users && active_sales_channel_options.users.length && active_sales_channel_options.users.map(x => (x && all_user_accounts_map_by_user_id[x]))} num_of_skus_mapped={active_sales_channel_options.sku} force_delete_sales_channel={force_delete_sales_channel}/>
		</Main_Grid>
	);
}

export default SalesChannel;