import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, Button, Input, Card, CardBody, CardHeader, CardFooter, Col, Row, Nav, NavItem, NavLink, Navbar, TabPane , TabContent } from "reactstrap";
import Multiselect from '../../components/Multiselect/'
import classnames from "classnames";
import { useAlert } from 'react-alert'
import PricingTermsConditions from '../../components/PricingTermsConditions';
import name_sort from '../../utils/name_sort';
import split_cat_type from '../../utils/split_cat_type';
import { Switch } from 'antd';
import desc_date_sort from '../../utils/desc_date_sort';
import './style.css'
import desc_name_sort from '../../utils/desc_name_sort';
import { handleError } from '../../utils/error';
import { is_valid_file_type } from '../../utils/is_valid_file';

const valid_value_types = ['integer', 'string', 'enum', 'boolean', 'sku_category_type_id','list'];


//generate a random 20 charecter id
const generate_id = () => {
	const id_length = 20;
	const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let text = "";
	for (let i = 0; i < id_length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
}


const convert_to_readable = (str) => {
    //snake case
    if (!str)   return '';
    if (str.includes('_')) {
        return str.replace(/_/g, ' ').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }
    //camel case
    let words = str.match(/[A-Za-z][a-z]*/g) || [];
    return words.map(word => (word.charAt(0).toUpperCase() + word.substring(1))).join(" ");
}   

const traverse_json = (json, hierarchy) => {
    if (hierarchy.length == 0) {
        return json;
    }
    var key = hierarchy.shift();
    if (json[key]) {
        return traverse_json(json[key], hierarchy);
    }
    return null;
}

const traverse_overriden_json = (json, hierarchy) => {
	
    if (hierarchy.length == 0) {
        return json;
    }
    var key = hierarchy.shift();

    if (json[key] || json[key]==false) {
		
        return traverse_overriden_json(json[key], hierarchy);
    }
    return null;
}


const determine_visibility = (json, hierarchy, visible) => {
    if (hierarchy.length == 0) {
        // console.log("determine visi", hierarchy)
        Object.keys(json).map(key => {
            // console.log('determine vis key', visible, json[key]?json[key].value_type:undefined)
            if (!visible && json[key] && json[key].value_type) {
                visible = json[key].value_type && valid_value_types.includes(json[key].value_type) && json[key].max_level=='sales_channel' ? true : false;
                // console.log('determine vis key 1',json[key], visible)
            }
        });
        return {json, visible};
    }
    let key = hierarchy.shift();
    // console.log("determine vis", key, hierarchy)
    if (json[key]) {
        let { json:new_json, visible:new_visible } = determine_visibility(json[key], hierarchy, visible);
        return { json: new_json, visible: new_visible };
    }
    return {visible, json};
}


const Leaf_Editor = ({ current_key, preference_json, hierarchy, refresh_data, category_types, sales_channel, design_preference_id_and_values, set_design_preference_id_and_values, overriden_preferences }) => {
    
    const [ current_json, set_current_json ] = useState({});
	const [current_overriden_prefs, set_current_overriden_prefs] = useState({})
    const [ update_leaf_modal_show, set_update_leaf_modal_show ] = useState(false);
    const [sales_channel_collapse, set_sales_channel_collapse] = useState(true)

	const set_design_pref = (designing_preference_id, value) => {
		let a =[...design_preference_id_and_values]
		let b = a.find(o => o.designing_preference_id == designing_preference_id)
		if(b){
			b['value'] = value
		}else{
			a.push({designing_preference_id:designing_preference_id, value:value})
		}
		set_design_preference_id_and_values(a)
	}
    
    useEffect(() => {
        set_current_json(traverse_json(preference_json, [ ...hierarchy, current_key ]));
		if(overriden_preferences) set_current_overriden_prefs(traverse_overriden_json(overriden_preferences, [...hierarchy, current_key]))
    }, [ preference_json, hierarchy, overriden_preferences ]);

	useEffect(() => {
		if(current_overriden_prefs || current_overriden_prefs=='false' || current_overriden_prefs==0){
			set_design_pref(current_json.id, current_overriden_prefs);
		}
	}, [current_overriden_prefs]);
    
    return current_json && current_json.max_level == 'sales_channel'?(
        <div>
            <div style={{ textAlign: 'left', backgroundColor: "white", color: "#1070CA",borderBottom: "1px solid rgb(242, 244, 246)", height:'35px', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                <div style={{flex:'6', fontSize:'14px', color:'black',padding: 5, paddingLeft: 15,paddingRight: 15,}}>
                    {convert_to_readable(current_json.display_name)}
                </div>
				<div style={{flex:'2', fontSize:'14px', color:'black',padding: 5, paddingLeft: 15,paddingRight: 15,}}>
					{(current_overriden_prefs || current_overriden_prefs==false || current_overriden_prefs==0) ? String(current_overriden_prefs) : current_json.value}
				</div>
            </div>
        </div>
    ) : '';
}


const Parent_Editor = ({ current_key, preference_json, hierarchy, refresh_data, category_types, sales_channel, design_preference_id_and_values, set_design_preference_id_and_values, overriden_preferences, first_call }) => {

    const [ current_json, set_current_json ] = useState({});
    const [ collapsed, set_collapsed ] = useState(true);
    const [ render, set_render ] = useState(false);
	const [sales_channel_level_prefs, set_sales_channel_level_prefs] = useState(null)

    useEffect(() => {
        // let children = traverse_json(preference_json, [ ...hierarchy, current_key ]);
        let { json, visible } = determine_visibility(preference_json, [ ...hierarchy, current_key ], false);
        set_current_json(json);
        set_render(visible);
        if(first_call) set_collapsed(false)
    }, [preference_json, hierarchy]);

    return (
        <div disabled={true} id="preferences_parent_editor" style={{ width: "100%", display: render ? 'block' : 'none', border:first_call?'1px solid #D1D7DA':'', borderBottom:!collapsed?'1px solid #D1D7DA':'', marginBottom:first_call?'20px':'' }}>
            <div className='flex_property' style={{ fontSize:first_call?'15px':'13px', height:first_call?'35px':'30px' ,fontWeight:'900', padding: 5, cursor: 'pointer', paddingLeft: 15, paddingRight: 15 , backgroundColor:"rgb(242, 244, 246)",borderBottom: "1px solid lightgrey"}} onClick={() => set_collapsed(!collapsed)}>
                <span style={{ float: 'left', marginTop: 4, marginRight:'10px', marginBottom:'3px'}} className={collapsed ? 'fa fa-angle-down' : 'fa fa-angle-up'} ></span> 
                <span style={{ float: 'left'}}> {convert_to_readable(current_key)} </span>
            </div>
            <div style={{ margin:"0px 10px 10px 10px", display: collapsed ? "none" : "block", borderLeft: "1px solid lightgrey", borderRight: "1px solid lightgrey", borderBottom: "1px solid lightgrey" }}>
                {!collapsed && current_json && Object.keys(current_json).map((key, index) => (
                    current_json[key].type == 'leaf' && current_json[key].id && valid_value_types.includes(current_json[key].value_type) ? (
                        current_json[key].visible ? (
                            <div>
                                <Leaf_Editor
                                    current_key={key}
                                    hierarchy={[ ...hierarchy, current_key ]}
                                    preference_json={preference_json}
                                    refresh_data={refresh_data}
                                    category_types={category_types}
                                    sales_channel = {sales_channel}
									design_preference_id_and_values = {design_preference_id_and_values}
									set_design_preference_id_and_values = {set_design_preference_id_and_values}
									overriden_preferences = {overriden_preferences}
                                />
                            </div>
                        ): ''
                    ) : ''
                ))}
                {!collapsed && current_json && Object.keys(current_json).map((key, index) => (
                    current_json[key].type == 'leaf' && current_json[key].id && valid_value_types.includes(current_json[key].value_type) ? '' : (
                        <div>
                            <Parent_Editor
                                current_key={key}
                                hierarchy={[ ...hierarchy, current_key ]}
                                preference_json={preference_json}
                                refresh_data={refresh_data}
                                category_types={category_types}
                                sales_channel = {sales_channel}
								design_preference_id_and_values = {design_preference_id_and_values}
								set_design_preference_id_and_values = {set_design_preference_id_and_values}
								overriden_preferences = {overriden_preferences}
                            />
                        </div>
                    )
                ))}
            </div>
        </div>
    );
}

const All_users_table = ({initialized, all_users_accounts, set_all_users_accounts, User_edit_Dropdown, saleschannel_search_string}) => {

	const onclick_all_select_checkbox = (value) => {
		var modal_json_temp = JSON.parse(JSON.stringify(all_users_accounts));
		// set_all_select_flag(value)
		if(value){
			modal_json_temp.map(x => {
				x.checked=true;
			})
		}else{
			modal_json_temp.map(x => {
				x.checked=false;
			})
			// set_all_users_accounts(all_users_accounts.map(x => ({ ...x, checked: true })))

		}
		set_all_users_accounts(modal_json_temp)
	}
	
	const set_checkbox_value = (value, id) => {
		if(all_users_accounts && all_users_accounts.length){
			var x_copy = JSON.parse(JSON.stringify(all_users_accounts))
			x_copy.map((x) => {
				if(x.id==id){
					x.checked=value;
				}
			})
			set_all_users_accounts(x_copy)
		}
	}

	const includes_search_string = (x) => {
		let lowerSearchString = saleschannel_search_string.toLowerCase()
		return x.toLowerCase().includes(lowerSearchString)
	}

	return(
		<table style={{width:'100%'}} className="text-left flex_property table_style">
			<thead style={{width:"100%", position:'sticky', top:0, backgroundColor:'white'}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6', height:"40px"}}>
				<th className='th_style flex_property' style={{flex:'1'}}>
				{
					all_users_accounts && all_users_accounts.length && all_users_accounts.filter(x => x.checked).length===all_users_accounts.length?
					<i onClick={()=>onclick_all_select_checkbox(false)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='far fa-check-square checkbox_background_hover' />
					:
					all_users_accounts && all_users_accounts.length && all_users_accounts.filter(x => x.checked).length===0?
						<i onClick={() =>onclick_all_select_checkbox(true)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='far fa-square checkbox_background_hover' />
						:
						<i onClick={() =>onclick_all_select_checkbox(true)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='far fa-minus-square checkbox_background_hover' />
				}
				</th>
				<th className='th_style flex_property' style={{flex:'2'}}><span>Name</span></th>
				<th className='th_style flex_property' style={{flex:'3'}}><span>Email</span></th>
				<th className='th_style flex_property' style={{flex:'4'}}><span>Access</span></th>
				{/* <th className='th_style flex_property' style={{flex:'1'}}><span>License Type</span></th> */}
				{/* <th className='th_style flex_property' style={{flex:'3'}}><span>Sales Channel</span></th> */}
				{/* <th className='th_style flex_property' style={{flex:'1', border:0}}><span>Unbilled Credits</span></th> */}
				{/* <th className='th_style flex_property' style={{flex:'1', border:0, marginRight:'15px'}}><span></span></th> */}
			</tr>
			</thead>
			<tbody style={{ width:'100%',overflow:'auto'}}>
			{
				all_users_accounts && all_users_accounts.length ? all_users_accounts.map((user_account, idx) =>
					<tr key={idx} style={{margin:0, display: includes_search_string((user_account.first_name ? user_account.first_name : '-') + ' ' + (user_account.last_name ? user_account.last_name : '')) || includes_search_string(user_account.email?user_account.email:'-') ? 'flex' : 'none'}} className='tr_style display_onhover'>
						<td className='td_style flex_property' style={{ flex:'1'}}>
							<Input className="checkbox_background_hover" style={{position:'unset', margin:0, cursor:'pointer'}} title='Select' type='checkbox' checked={user_account.checked}  onChange={() => set_checkbox_value(!user_account.checked, user_account.id)} />
						</td>
						<td className='td_style flex_property' style={{ flex:'2'}}><span style={{overflowX:'auto',textTransform:'capitalize', whiteSpace:'nowrap'}}>{(user_account.first_name ? user_account.first_name : '-') + ' ' + (user_account.last_name ? user_account.last_name : '')}</span></td>
						<td className='td_style flex_property' style={{ flex:'3'}}><span style={{overflowX:'auto',textTransform:'none', whiteSpace:'nowrap'}}>{user_account.email?user_account.email:'-'}</span></td>
						<td className='td_style flex_property' style={{ flex:'4'}}>
							{
								user_account.role && user_account.role.length ? 
									user_account.role.map((rol, idx1) => (
										<span key={idx1} style={{backgroundColor:'#f9f9f9', marginRight:'5px', padding:'2px',color:'black'}}><span style={{fontSize:'12px', textTransform:'capitalize', whiteSpace:'nowrap'}}>{rol.name?rol.name:'-'}</span></span>
									)
								):'-'
							}
						</td>
						{/* <td className='td_style flex_property' style={{ flex:'1'}}>
							{
								user_account.role && user_account.role.filter(x => x==='designer').length ? (user_account.active_design_usage && user_account.active_design_usage.license_id?'Annual':'Monthly'):'-'
							}
						</td> */}
						{/* <td className='td_style flex_property' style={{ flex:'3'}}>
							{
								user_account.Sales_Channels && user_account.Sales_Channels.length?
									user_account.Sales_Channels.map((sales, idx1) => (
										<span key={idx1} style={{backgroundColor:'#f9f9f9', marginRight:'5px', padding:'2px',color:'black'}}><span style={{fontSize:'12px', textTransform:'capitalize', whiteSpace:'nowrap'}}>{sales.name}</span></span>																				
									)
								):'-'
							}
						</td> */}
						{/* <td className='td_style flex_property' style={{ flex:'1'}}><span style={{marginRight:'5px'}}>0</span> <span><Info_tooltip text_message='Unbilled Credits'/></span></td> */}
						{/* <td className='td_style flex_property' style={{ flex:'1', justifyContent:'flex-end', marginRight:'15px', overflowX:'unset'}}> */}
							{/* <User_edit_Dropdown_Layout initialized={initialized} active_user={user_account} User_edit_Dropdown={User_edit_Dropdown}/> */}
						{/* </td> */}
					</tr>
				)
				: 
				// (
				// 	initialized ? <tr><td><span style={{display:'flex', alignItems:'center',justifyContent:'center', fontSize:'18px' }}>No User, Please add new user.</span></td></tr>
				// 	:<tr><td><span style={{display:'flex', alignItems:'center',justifyContent:'center', fontSize:'18px' }}>Fetching Users , Please Wait ...</span></td></tr>

				// )
				''
			}
			</tbody>
		</table>
	)
}

const SalesChannelDialogBox = ({open , handleClose , sales_channel ,user , fetch_sales_channel_details , mode, all_sc_preferences, store_details, set_page_loader, all_users_accounts, org_bu_view, validate_sales_channel_name }) => {

	const[sales_channel_id , set_sales_channel_id] = useState('');
	const[sales_channel_name , set_sales_channel_name] = useState(sales_channel && sales_channel.name ? sales_channel.name:'');
	const[store_id , set_store_id] = useState('');
	const[selected_price_type , set_selected_price_type] = useState([]);
	const[sales_channel_logo, set_sales_channel_logo] = useState(null)
	const[sales_channel_logo_url, set_sales_channel_logo_url] = useState(null)
	const [tabs , set_tabs ] = useState(1)
	const [tandc_array, set_tandc_array] = useState([]);
    const [make_bold, set_make_bold] = useState(null)
	const [update_logo, set_update_logo] = useState(0)
	// const [use_org_address, set_use_org_address] = useState(true)
	const [address_line_1, set_address_line_1] = useState('')
	const [address_line_2, set_address_line_2] = useState('')
	const [city, set_city] = useState('')
	const [province, set_province] = useState('');
	const [zipcode, set_zipcode] = useState('')
	const [country, set_country] = useState('')
	const [gst_number, set_gst_number] = useState('')
	const [overriden_org_name, set_overriden_org_name] = useState('')

	const [ initialized, set_initialized ] = useState(false);
	const [ preference_json, set_preference_json ] = useState({});
    const [ category_types, set_category_types ] = useState([]);
	const [design_preference_id_and_values, set_design_preference_id_and_values] = useState([])
	const [overriden_preferences, set_overriden_preferences] = useState({});
	const [display_logo_id, set_display_logo_id] = useState('')

	const [sales_channel_users, set_sales_channel_users] = useState([]);
	const [sales_channel_unmapped_users, set_sales_channel_unmapped_users] = useState([]);
	const [mapped_open, set_mapped_open] = useState(true);
	const [saleschannel_search_string, set_saleschannel_search_string] = useState('');
	const [public_sales_channel, set_public_sales_channel] = useState(false);
	const [auto_approval, set_auto_approval] = useState(false);
	const [subscribed_orgs, set_subscribed_orgs] = useState([]);
	const [sc_subscription_requests_data, set_sc_subscription_requests_data] = useState([]);
	// const [unmapped_open, set_unmapped_open] = useState(false);
	const roles_options = [{name: 'Designer', id: 'designer'},{name: 'Project Manager', id: 'project_manager'},{name: 'Account Manager', id: 'accounts_manager'},{name: 'Catalogue Manager', id: 'catalog_manager'}]		

	const alert = useAlert();

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	}

	const add_sales_name = async () => {
		let sales_channel_name_trimmed = sales_channel_name ? sales_channel_name.trim() : ''
		if(sales_channel_name_trimmed && selected_price_type && selected_price_type.length){
			try {

				if(public_sales_channel && selected_price_type && selected_price_type.length > 1){
					alert.error('Public Sales Channel must map to exactly one price type')
					return
				}

				var price_type_ids = selected_price_type && selected_price_type.length?selected_price_type.map(x => x.id):[]
				var body = { name : sales_channel_name_trimmed, price_type_ids, sharing: public_sales_channel ? 'public' : 'private', auto_approve_subscription_requests: auto_approval ? true : false}
				if(display_logo_id) body['display_pic_id'] = display_logo_id
				// if(!use_org_address){
				// 	if(!address_line_1 || !address_line_2 || !city || !province || !zipcode || !country || !gst_number){
				// 		alert.error("Fill all address details or erase all")
				// 		return
				// 	}else{
				// 		let location = {address_line_1:address_line_1, address_line_2:address_line_2, city:city, province:province, zipcode:zipcode, country:country, gst_number:gst_number}
				// 		body['location'] = JSON.stringify(location)
				// 	}
				// }
				if(is_valid_add_key(tandc_array)) body['terms_and_conditions'] = JSON.stringify(tandc_array)
				if(overriden_org_name && (overriden_org_name.toString()).trim().length) body['store_name'] = overriden_org_name
				if(org_bu_view==='bu'){
					body['business_unit_id'] = user && user.current_business_unit_id ? user.current_business_unit_id : null
				}else{
					body['business_unit_id'] = null
				}

				if (validate_sales_channel_name(sales_channel_name_trimmed)) {
					set_page_loader({show:true, text:'Please wait...'})
					var response = await general_fetch({ url: 'sales_channel/create', body });
					// if(design_preference_id_and_values && design_preference_id_and_values.length && response){
					// 	try{
					// 		let resp = await general_fetch({url:"designing_preference/set_sales_channel_preference", body:{sales_channel_id:response.id, design_preference_id_and_values:design_preference_id_and_values}})
					// 		console.log("set_sales_channel_preference", resp)
					// 	}catch(err){
					// 		console.log(err)
					// 	}
					// }

					fetch_sales_channel_details();
					alert.success('Sales channel added successfully')
					onclick_handleClose();
					set_page_loader({show:false})
				}else{
					alert.error('Sales Channel with the given name already exists. Please modify the name')
				}
			} catch(err) {
				set_page_loader({show:false})
				console.log(err);
			}
		}else{
			alert.error('Name and price type is required')
		}
	}

	const update_sales_name = async () => {
		let sales_channel_name_trimmed = sales_channel_name ? sales_channel_name.trim() : ''
		if(sales_channel_name_trimmed && selected_price_type && selected_price_type.length){

			if(sales_channel_name_trimmed != sales_channel.name.trim() && !validate_sales_channel_name(sales_channel_name_trimmed)){
				alert.error('Sales Channel with the given name already exists. Please modify the name')
				return
			}

			try {

				if (public_sales_channel && selected_price_type && selected_price_type.length > 1) {
					alert.error('Public Sales Channel must map to exactly one price type')
					return
				}

				var price_type_ids = selected_price_type && selected_price_type.length ? selected_price_type.map(x => x.id) : []
				var body = { sales_channel_id: sales_channel_id, name: sales_channel_name, price_type_ids, sharing: public_sales_channel ? 'public' : 'private', auto_approve_subscription_requests: auto_approval ? true : false }
				if (update_logo) {
					body['update_logo'] = 1
					body['display_pic_id'] = display_logo_id
				}
				// if(!use_org_address){
				// 	if(!address_line_1 || !address_line_2 || !city || !province || !zipcode || !country || !gst_number){
				// 		alert.error("Fill all address details or erase all")
				// 		return
				// 	}else{
				// 		let location = {address_line_1:address_line_1, address_line_2:address_line_2, city:city, province:province, zipcode:zipcode, country:country, gst_number:gst_number}
				// 		body['location'] = JSON.stringify(location)
				// 	}
				// }
				if (is_valid_add_key(tandc_array)) {
					body['terms_and_conditions'] = JSON.stringify(tandc_array)
				}
				if (overriden_org_name && (overriden_org_name.toString()).trim().length) body['store_name'] = overriden_org_name

				var response = await general_fetch({ url: 'sales_channel/update', body });
				// console.log(response)

				// if(design_preference_id_and_values && design_preference_id_and_values.length && sales_channel_id && response){
				// 	try{
				// 		let resp = await general_fetch({url:"designing_preference/set_sales_channel_preference", body:{sales_channel_id:sales_channel_id, design_preference_id_and_values:design_preference_id_and_values}})
				// 		console.log("set_sales_channel_preference", resp)
				// 	}catch(err){
				// 		console.log(err)
				// 	}
				// }
				fetch_sales_channel_details();
				alert.success('Sales channel Updated successfully')
				onclick_handleClose();

			} catch (err) {
				console.log(err);
			}
		}else{
			alert.error('Name and price type is required')
		}
	}

	const share_store_id = async () => {
		if(store_id){
			try {
				var body = {id: store_id , sales_channel_id : sales_channel_id}
				var response = await general_fetch({ url: 'sales_channel/add_store', body});
				fetch_sales_channel_details();
				handleClose();
				set_store_id(null)
			} catch(err) { 
				console.log(err);
			}
		}else{
			
		}
	}

	const onselect_multi_pref = async (list) => {
		set_selected_price_type(list)
	}
	const onclick_handleClose = () => {
		handleClose()
		set_sales_channel_id('')
		set_sales_channel_name('')
		set_selected_price_type([])
		set_make_bold(null)
		set_tabs(1)
		// set_use_org_address(true)
		set_address_line_1('')
		set_address_line_2('')
		set_city('')
		set_province('')
		set_zipcode('')
		set_country('')
		set_gst_number('')
		set_tandc_array([])
		set_overriden_org_name('')
		set_sales_channel_logo(null)
		set_sales_channel_logo_url('')
		set_display_logo_id('')
		set_update_logo(0)
		set_mapped_open(true)
		set_saleschannel_search_string('')
		set_public_sales_channel(false)
		set_auto_approval(false)
		// set_unmapped_open(false)
	}

	const is_valid_add_key = () => {
		if(tandc_array && tandc_array.length > 0){
			for(let i=0;i<tandc_array.length;i++){
				// console.log("printing tandc", tandc_array[i][0])
				if(tandc_array[i][0].text==='' || tandc_array[i][1].text==='')
					return false
			}
			return true
		}else{
			// console.log("printing tandc true")
			return true
		}
	}

	const is_valid_add_key_1 = () => {
		if(tandc_array && tandc_array.length > 0){
			// console.log("printing tandc", tandc_array[0][0])
			if(tandc_array[0][0].text==='' || tandc_array[0][1].text==='')
				return false
			return true
		}else{
			// console.log("printing tandc true")
			return true
		}
	}

	const add_tandc_row = () => {
		if(is_valid_add_key()){
			let a = [...tandc_array]
			let temp_row = [{text:'',bold:false},{text:'',bold:false}]
			a.push(temp_row)
			set_tandc_array(a)
		}else{
			alert.error("Empty values are not allowed !")
		}
	}

	const update_tandc_row = (idx1, idx2, key, value) => {
		let a = [...tandc_array]
		if(key=='text')
		{
			a[idx1][idx2].text = value
		}
		if(key=='bold'){
			a[idx1][idx2].bold = value
		}
		set_tandc_array(a)
	}

	const delete_tandc_row = (idx) => {
		let a = [...tandc_array]
		a.splice(idx,1)
		set_tandc_array(a)
	}

	const fetch_category_type_details = async() => {
        try {
            let response = await general_fetch({ url: 'sku_category_type/get'});
            response = split_cat_type(response, 'with_division').sort(name_sort);
            console.log('cat type --->> ', response);
            set_category_types(response);
        } catch(err) {
            console.log(err);
        }
	}

	const fetch_master_preferences = async () => {
		try {
			var resp = await general_fetch({ url: 'designing_preference/get_preference_at_level', body: { level: 'store', json_with_leaf_values: true, nested_output: true, business_unit_id: user.current_business_unit_id } });
			set_initialized(true);
            set_preference_json(resp);
		} catch(err) {
			console.log(err);
		}
	}

	const fetch_sales_channel_level_prefs = async(sales_channel_id) => {
        try{
			console.log("sales pref")
            if(sales_channel_id){
                    var resp = await general_fetch({url:'designing_preference/get_preference_at_level', body:{level:'sales_channel', sales_channel_id:sales_channel.id, nested_output:true, business_unit_id: user.current_business_unit_id}})
                    if(resp){
                        set_overriden_preferences(resp)
                    }
                console.log("sales prefs", resp)
            }
        }catch(err){
            console.log(err)
        }
    }

	const add_image = async(sales_channel_logo) => {
		try{
			if(!is_valid_file_type(sales_channel_logo, ['jpeg','jpg', 'png'])){
				alert.error("Invalid file format! Please upload a file with a valid format(jpeg, jpg, png).")
				return
			}
			let data = new FormData();
			data.append('upl', sales_channel_logo)
			set_page_loader({
				show:true,
				text:'Please wait.. Uploading Logo'
			})
			let response = await general_fetch({url: 'image/add', body:data, is_form_data:true, req_resp:true})
			set_display_logo_id(response && response.data?response.data.id:'')
			set_sales_channel_logo_url(response && response.data?response.data.image:'')
			set_page_loader({
				show:false,
				text:''
			})
		}catch(err){
			handleError(err);
		}
	}

	const fetch_sales_channel_users = async (sales_channel_id) => {
		try{
			let body = {
				"sales_channel_ids": [sales_channel_id], 
				"include_business_unit_mapping": false, 
				"include_business_unit_info": false, 
				"include_price_type_mapping": false, 
				"include_price_type_info": false, 
				"include_user_mapping": true, 
				"include_user_info": true,
				"include_subscribed_orgs_info": true,
				"include_subscription_requests_info": true
			}
			var resp = await general_fetch({url:'sales_channel/get_by_id', body})
			var mapped_users = Object.values(resp.users)
			if(mapped_users && mapped_users.length){
				mapped_users = mapped_users.filter( account => !account.deprecated)
			}

			mapped_users = mapped_users.filter( account => 
				account.role = account.role && account.role.length ? roles_options.filter((x) => account.role.includes(x.id)):[]
			)
			if(window.debug_mode){
				console.log('sales channel users', mapped_users);
			}

			let subscribed_orgs_data = resp && resp.sales_channels && resp.sales_channels[sales_channel_id] ? resp.sales_channels[sales_channel_id].subscribed_org_ids : []
			subscribed_orgs_data = subscribed_orgs_data.map(o => {return {id: o, name: resp.orgs[o].name}})
			console.log('final_subscription_requests_data', subscribed_orgs_data)
			set_subscribed_orgs(subscribed_orgs_data)

			let subscription_requests_data = resp && resp.sales_channels && resp.sales_channels[sales_channel_id] ? resp.sales_channels[sales_channel_id].subscription_requests : []
			subscription_requests_data = subscription_requests_data.filter(o => o.status === 'pending_approval').map(o => {return {...o, raised_in_org_name: resp.orgs[o.raised_in_org_id].name}})
			set_sc_subscription_requests_data(subscription_requests_data)
			console.log('final_subscription_requests_data', subscription_requests_data)
			
			// mapped_users = mapped_users.map(x => ({...x, checked: false }))
			set_sales_channel_users(all_users_accounts.filter(x => (mapped_users.filter(y => y.id == x.id).length)).map(x => ({...x, checked:false})))
			set_sales_channel_unmapped_users(all_users_accounts.filter(x => !(mapped_users.filter(y => y.id == x.id).length)).map(x => ({...x, checked:false})))
		}
		catch(err){
			console.log(err)
		}
	}

	const add_sales_channel_users = async() => {
		try{
			let existing_users = sales_channel_users.map(x => x.id)
			let new_users = sales_channel_unmapped_users.filter(x => x.checked).map(x => x.id)
			let body = {sales_channel_id:sales_channel_id, user_ids: [...existing_users, ...new_users]}
			let resp = await general_fetch({url: 'sales_channel/update_users', body})
			if(window.debug_mode){
				console.log("update users sales channel", resp)
			}
			if(resp == 'OK'){
				fetch_sales_channel_users(sales_channel_id)
			}
		}
		catch(err){
			console.log(err)
		}
	}

	const remove_sales_channel_users = async() => {
		try{
			// let existing_users = sales_channel_users.map(x => x.id)
			let new_users = sales_channel_users.filter(x => !x.checked).map(x => x.id)
			let body = {sales_channel_id:sales_channel_id, user_ids: new_users}
			let resp = await general_fetch({url: 'sales_channel/update_users', body})
			if(window.debug_mode){
				console.log("update users sales channel", resp)
			}
			if(resp == 'OK'){
				fetch_sales_channel_users(sales_channel_id)
			}
		}
		catch(err){
			console.log(err)
		}
	}

	const fetch_subscription_data = async(sales_channel_id) => {
		try{
			// let resp = await general_fetch({url:'sales_channel/subscription_request/get', body: {filter_by_org: false, sales_channel_ids: [sales_channel_id], include_org_info: true}})
			// let subscription_requests_data = resp.sales_channel_subscription_requests ? resp.sales_channel_subscription_requests[sales_channel_id] : []
			// subscription_requests_data.sort((a,b) => desc_name_sort(a,b,'status'))
			// let t = subscription_requests_data.length ? subscription_requests_data.map((x) => {
			// 	x.raised_in_org_name = resp.orgs[x.raised_in_org_id].name
			// }):''
			// console.log('set_sc_subscription_data', subscription_requests_data)
			// set_sc_subscription_data(subscription_requests_data)
			let body = {

			}
		}catch(err){	
			console.log(err)
		}
	}

	const approve_deny_subscription_request = async(sales_channel_subscription_request_id, approve_deny) => {
		try{
			let body = {sales_channel_subscription_request_id}
			if(approve_deny){
				let resp = await general_fetch({url:'sales_channel/subscription_request/approve', body})
				alert.success("Successfully approved subscription request")
			}else{
				let resp = await general_fetch({url:'sales_channel/subscription_request/deny', body})
				alert.success("Successfully denied subscription request")
			}
			fetch_sales_channel_users(sales_channel_id)
		}catch(err){	
			console.log(err)
		}
	}

	const clear_saleschannel_search = () => {
		set_saleschannel_search_string('')
	}


	useEffect(() => {
		if (!initialized) {
			fetch_master_preferences();
            fetch_category_type_details();
		}
	}, []);

	useEffect(() => {
		if(tandc_array && tandc_array.length==0){
			add_tandc_row()
		}
	},[tandc_array])

	useEffect(() => {
		if(sales_channel_logo) {
			add_image(sales_channel_logo)
		}
	}, [sales_channel_logo]);

	useEffect (() => {
		if(sales_channel && JSON.stringify(sales_channel) != JSON.stringify({})){
			fetch_sales_channel_users(sales_channel.id)
			set_sales_channel_id(sales_channel.id)
			set_sales_channel_name(sales_channel.name)
			set_selected_price_type(sales_channel.price_type_ids && sales_channel.price_type_ids.length ? sales_channel.price_type_ids.map(x => {return {id: x}}) : [])
			console.log("sales prefsd", sales_channel)
			fetch_sales_channel_level_prefs(sales_channel.id)
			if(sales_channel.sharing === 'public'){
				set_public_sales_channel(true)
				if(sales_channel.auto_approve_subscription_requests){
					set_auto_approval(true)
				}
				fetch_subscription_data(sales_channel.id)
			}
			if(sales_channel.sales_channel_profile){
				// if(sales_channel.sales_channel_profile.location){
				// 	let temp = sales_channel.sales_channel_profile.location
				// 	if(temp){
				// 		set_address_line_1(temp.address_line_1)
				// 		set_address_line_2(temp.address_line_2)
				// 		set_city(temp.city)
				// 		set_country(temp.country)
				// 		set_province(temp.province)
				// 		set_zipcode(temp.zipcode)
				// 	}
				// }
				set_overriden_org_name(sales_channel.sales_channel_profile.store_name ? sales_channel.sales_channel_profile.store_name :'')
				set_sales_channel_logo_url(sales_channel.sales_channel_profile.display_pic ? sales_channel.sales_channel_profile.display_pic.image :'')
				if(sales_channel.sales_channel_profile.terms_and_conditions)
					set_tandc_array(JSON.parse(sales_channel.sales_channel_profile.terms_and_conditions))
			}
		}
	},[sales_channel]);


	useEffect(() => {
		if(window.debug_mode){
			console.log("saleschannelusers", sales_channel_users)
			console.log("saleschannelusersun",sales_channel_unmapped_users)
		}
	}, [sales_channel_users, sales_channel_unmapped_users]);

	// useEffect(() => {
	// 	console.log("all users", all_users_accounts, all_ffff)
	// }, [open, all_user_accounts, all_ffff]);

	// useEffect(() => {
	// 	if(address_line_1 || address_line_2 || city || province || zipcode || country || gst_number){
	// 		set_use_org_address(false)
	// 	}else{
	// 		set_use_org_address(true)
	// 	}
	// },[address_line_1, address_line_2, city, province, zipcode, country, gst_number])

  	return (
		// <Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={onclick_handleClose}>
		// 	<Card style={{height:(expand_additional_details && expand_tandc) ? '800px' : ((expand_additional_details || expand_tandc) ? '600px' : '350px') }}>
				// <CardHeader className='global_modal_header'>
				// 		<span>
				// 			{mode === "add" && <span>Enter Sales Channel Name</span>}
				// 			{mode === "update" && <span>Update Sales Channel - </span>}
				// 			{mode === "share" && <span>Push to PSN - </span>}
				// 			<span>{sales_channel && mode=='add' ? '' : sales_channel_name}</span>
				// 		</span>
				// 		<i onClick={onclick_handleClose} style={{cursor: 'pointer'}} className='fa fa-times'/>
				// </CardHeader>

		// 		<CardBody className='global_modal_body'>
		// 			<Row>
		// 				<Col>
		// 					{
		// 						mode==="add"||mode==="update" ? 
		// 						(
		// 							<>
		// 								<Row style={{}}>
		// 									<Col xs={8}>
												// <div>
												// 	<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Sales Channel Name</div>
												// 	<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Name" type="text" value={sales_channel_name} onChange={e => set_sales_channel_name(e.target.value)}/>
												// </div>
												// <div style={{marginTop:'10px'}}>
												// 	<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Price Fields</div>
												// 	<Multiselect
												// 		style={{backgroundColor:'white', width:'227px'}}
												// 		options={all_sc_preferences && all_sc_preferences.length? JSON.parse(JSON.stringify(all_sc_preferences)):[]} 
												// 		selectedValues={selected_price_type}
												// 		onSelect={onselect_multi_pref} 
												// 		onRemove={onselect_multi_pref} 
												// 		displayValue="name" // Property name 
												// 		id={'select_pref'+ sales_channel?sales_channel.id:''}
												// 		placeholder='Select'
												// 	/> 
												// </div>
		// 									</Col>
		// 									<Col xs={4} >
		// 										<div style={{fontSize:'12px', paddingBottom:'5px',color:'#425A70'}}>Sales Channel Logo</div>
												// <div style={{width:'100%', height:'80%', backgroundColor:'white', borderRadius:'4px', border:'1px dashed #A7A8B3', padding:'0px', display:'flex',flexDirection:'column', alignItems:'center', justifyContent:'center',cursor:'pointer'}}>
												// 	<span style={{fontSize:'25px'}}>+</span>
												// 	<span style={{fontSize:'13px'}}>JPG/PNG</span>
												// </div>
		// 									</Col>
		// 								</Row>
		// 								<Row style={{paddingLeft:'16px', paddingTop:'24px'}}>
		// 									<span style={{fontSize:'14px', fontWeight:'900', color:'#47596E', marginRight:'20px'}}>Additional Details</span>
		// 									{
		// 										expand_additional_details ?
		// 										<i className='fa fa-angle-up' style={{marginTop:'4px', cursor:'pointer'}} onClick={() => {set_expand_additional_details(false)}} />
		// 										:
		// 										<i className='fa fa-angle-down' style={{marginTop:'4px', cursor:'pointer'}} onClick={() => {set_expand_additional_details(true)}} />
		// 									}
		// 									<hr style={{width:'69%', marginLeft:'10px', marginTop:'12px'}}></hr>
		// 								</Row>
		// 								{
		// 									expand_additional_details ?
											// <div style={{padding:'0px 2px'}}>
											// 	<div>
											// 		<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Address Line 1*</div>
											// 		<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Address Line 1" type="text" value={sales_channel_name} />
											// 	</div>
											// 	<div style={{marginTop:'15px'}}>
											// 		<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Address Line 2*</div>
											// 		<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Address Line 2" type="text" value={sales_channel_name} />
											// 	</div>
											// 	<div style={{marginTop:'15px', display:'flex', justifyContent:'space-between'}}>
											// 		<div style={{width:'45%'}}>
											// 			<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>City*</div>
											// 			<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="City" type="text" value={sales_channel_name} />
											// 		</div>
											// 		<div style={{width:'45%'}}>
											// 			<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Province*</div>
											// 			<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Province" type="text" value={sales_channel_name} />
											// 		</div>
											// 	</div>
											// 	<div style={{marginTop:'15px', display:'flex', justifyContent:'space-between'}}>
											// 		<div style={{width:'45%'}}>
											// 			<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Zip Code*</div>
											// 			<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Zip Code" type="text" value={sales_channel_name} />
											// 		</div>
											// 		<div style={{width:'45%'}}>
											// 			<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Country*</div>
											// 			<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Country" type="text"  />
											// 		</div>
											// 	</div>
											// </div>:''
		// 								}
		// 								<Row style={{paddingLeft:'16px', paddingTop:'15px'}}>
		// 									<span style={{fontSize:'14px', fontWeight:'900', color:'#47596E', marginRight:'10px'}}>Terms & Conditions</span>
		// 									{
		// 										expand_tandc ?
		// 										<i className='fa fa-angle-up' style={{marginTop:'4px', cursor:'pointer'}} onClick={() => {set_expand_tandc(false)}} />
		// 										:
		// 										<i className='fa fa-angle-down' style={{marginTop:'4px', cursor:'pointer'}} onClick={() => {set_expand_tandc(true)}} />
		// 									}
		// 									<hr style={{width:'69%', marginLeft:'10px', marginTop:'12px'}}></hr>
		// 								</Row>
		// 								{
		// 									expand_tandc ?
		// 									<table style={{backgroundColor:'white', boxShadow:'4px #F5F6F8'}} className="text-left flex_property table_style">
		// 										<tr className='tr_style'>
		// 											<th className='th_style flex_property' style={{flex:'2'}}><span>Terms</span></th>
		// 											<th className='th_style flex_property' style={{flex:'5'}}><span>Conditions</span></th>
		// 										</tr>
		// 										<tbody style={{width:'100%', overflow:'auto'}}>
		// 											<tr className='tr_style display_onhover' style={{backgroundColor:"#F5F6F8"}}>
		// 												<td className='td_style flex_property' style={{flex:'2'}}>abc</td>
		// 												<td className='td_style flex_property' style={{flex:'5'}}>abc</td>
		// 											</tr>
		// 											<tr className='tr_style display_onhover'>
		// 												<td className='td_style flex_property' style={{flex:'2'}}>abc</td>
		// 												<td className='td_style flex_property' style={{flex:'5'}}>abc</td>
		// 											</tr>
		// 											<tr className='tr_style display_onhover' style={{backgroundColor:"#F5F6F8"}}>
		// 												<td className='td_style flex_property' style={{flex:'2'}}>abc</td>
		// 												<td className='td_style flex_property' style={{flex:'5'}}>abc</td>
		// 											</tr>
		// 											<tr className='tr_style display_onhover'>
		// 												<td className='td_style flex_property' style={{flex:'2'}}>abc</td>
		// 												<td className='td_style flex_property' style={{flex:'5'}}>abc</td>
		// 											</tr>
		// 											<tr className='tr_style display_onhover'>
		// 												<td className='td_style flex_property' style={{flex:'2'}}>abc</td>
		// 												<td className='td_style flex_property' style={{flex:'5'}}>abc</td>
		// 											</tr>
		// 											<tr className='tr_style display_onhover'>
		// 												<td className='td_style flex_property' style={{flex:'2'}}>abc</td>
		// 												<td className='td_style flex_property' style={{flex:'5'}}>abc</td>
		// 											</tr>
		// 											<tr className='tr_style display_onhover'>
		// 												<td className='td_style flex_property' style={{flex:'2'}}>abc</td>
		// 												<td className='td_style flex_property' style={{flex:'5'}}>abc</td>
		// 											</tr>
		// 											<tr className='tr_style display_onhover'>
		// 												<td className='td_style flex_property' style={{flex:'2'}}>abc</td>
		// 												<td className='td_style flex_property' style={{flex:'5'}}>abc</td>
		// 											</tr>
		// 											<tr className='tr_style display_onhover'>
		// 												<td className='td_style flex_property' style={{flex:'2'}}>abc</td>
		// 												<td className='td_style flex_property' style={{flex:'5'}}>abc</td>
		// 											</tr>
		// 										</tbody>
		// 									</table>:''
		// 								}
		// 							</>
		// 						):(
									// <div>
									// 	<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Store Id</div>
									// 	<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Id" type="text" value={store_id} onChange={e => set_store_id(e.target.value)}/>
									// </div>
		// 						)
		// 					}
		// 				</Col>	
		// 			</Row>
		// 		</CardBody>

				// <CardFooter className='global_modal_footer'>
				// 		<Button className='white_button' type="button" onClick={handleClose}> Cancel </Button>
				// 		{mode === "add" && <Button className='blue_button' type="button" onClick={add_sales_name}>Add</Button>}
				// 		{mode === "update" && <Button className='blue_button' type="button" onClick={update_sales_name}>Update</Button>}
				// 		{mode === "share" && <Button className='blue_button' type="button" onClick={share_store_id}>Share</Button>}
				// </CardFooter>
		// 	</Card>  
		// </Modal>
		<Modal className={mode=='share'?'Margin_64px_top':"Margin_64px_top sales_channel_modal"} size="sm" isOpen={open} toggle={onclick_handleClose} style={{}}>
			<Card style={{height:mode=='share'?'300px':'550px', width:mode=='share'?'544px':'700px'}}>
				<CardHeader className='global_modal_header'>
					<span style={{marginLeft:'-5px'}}>
						{mode === "add" && <span>Create Sales Channel</span>}
						{mode === "update" && <span>Update Sales Channel - </span>}
						{mode === "share" && <span>Push to PSN - </span>}
						<span>{sales_channel && mode=='add' ? '' : (sales_channel ? sales_channel.name : '')}</span>
					</span>
					<i onClick={onclick_handleClose} style={{cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body' style={{padding:'0'}}>
				{
					mode=='add' || mode=='update'?
					(
						<>
							<Nav 
								style={{display:'flex', alignItems:'center', fontSize:'14px', backgroundColor:'white', padding:'2px 0px 2px 15px', position:'sticky', top:'0'}}
								className="flex-md-row"
								id="tabs-icons-text" 
								pills
								role="tablist"
							>
								<NavItem>
									<NavLink
										aria-selected={tabs === 1}
										style = {{}}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 1
										})}
										onClick={e => toggleNavs(e, 1)}
										href="#pablo"
										role="tab"
									>
										<span>Price Fields*</span>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										aria-selected={tabs === 2}
										style = {{}}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 2
										})}
										onClick={e => toggleNavs(e, 2)}
										href="#pablo"
										role="tab"
									>
										<span id='sc_profile'>Profile</span>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										aria-selected={tabs === 3}
										style = {{}}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 3
										})}
										onClick={e => toggleNavs(e, 3)}
										href="#pablo"
										role="tab"
									>
										<span>Terms & Conditions</span>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										aria-selected={tabs === 4}
										style = {{}}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 4
										})}
										onClick={e => toggleNavs(e, 4)}
										href="#pablo"
										role="tab"
									>
										<span>Overridden Preferences</span>
									</NavLink>
								</NavItem>
								<NavItem style={{display: mode === 'add' ? 'none' : 'flex'}}>
									<NavLink
										aria-selected={tabs === 5}
										style = {{}}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 5
										})}
										onClick={e => toggleNavs(e, 5)}
										href="#pablo"
										role="tab"
									>
										<span>Users</span>
									</NavLink>
								</NavItem>
								<NavItem style={{display: store_details && store_details.public_sales_channels_enabled ? 'flex' : 'none'}}>
									<NavLink
										aria-selected={tabs === 6}
										style = {{}}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 6
										})}
										onClick={e => toggleNavs(e, 6)}
										href="#pablo"
										role="tab"
									>
										<span>Public Access</span>
									</NavLink>
								</NavItem>
							</Nav>

							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={'tabs1'} style={{fontSize:'12px', padding:'30px 80px'}}>  
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Sales Channel Name</div>
										<Input id='sales_channel_name' style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Name" type="text" value={sales_channel_name} onChange={e => set_sales_channel_name(e.target.value)}/>
									</div>
									<div style={{marginTop:'10px'}}>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Price Fields</div>
										<Multiselect
											style={{backgroundColor:'white', width:'100%'}}
											options={all_sc_preferences && all_sc_preferences.length? JSON.parse(JSON.stringify(all_sc_preferences)):[]} 
											selectedValues={selected_price_type}
											onSelect={onselect_multi_pref} 
											onRemove={onselect_multi_pref} 
											displayValue="name" // Property name 
											id={'sc_price_fields'}
											placeholder='Select'
										/> 
									</div>
									<div style={{marginTop:'25px', color:'#A7A8B2', fontSize:'14px', textAlign:'center'}}>
										Values in the other tabs are not mandatory.
									</div>
									<div style={{marginTop:'5px', color:'#A7A8B2', fontSize:'14px', textAlign:'center'}}>
										If not filled, organization level values will be used.
									</div>
								</TabPane>
							</TabContent>

							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={'tabs2'} style={{fontSize:'12px'}}> 
									<div style={{padding:'20px 80px'}}>
										<div style={{fontSize:"15px", marginBottom:'15px'}}>
											<span style={{fontWeight:'900'}}>{'Display Name & Logo' + '\xa0'+'\xa0'+'\xa0'+'\xa0'}</span>
											<span style={{color:"#A7A8B2"}}>(If not specified, organization details will be used)</span>
										</div>
									</div>
									<div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'20px', flexDirection:'column'}}>
										<Input id={"image_preview"} type="file" onChange={(e) => {set_sales_channel_logo(e.target.files[0]);e.stopPropagation();}} style={{display:"none"}} accept='.jpg,.jpeg,.png'/>
									{
										sales_channel_logo_url?
										<img src={global.config.server_path.slice(0,-1)+sales_channel_logo_url} width='150px' height='100px' onClick={() => {document.getElementById("image_preview").click();set_update_logo(1);set_sales_channel_logo_url('')}} style={{cursor:'pointer'}}></img>
										:
										(
											// sales_channel_logo?
											// 	<img src={URL.createObjectURL(sales_channel_logo)} width='150px' height='100px' onClick={() => {document.getElementById("image_preview").click();set_update_logo(1)}} style={{cursor:'pointer'}}></img>
											// :
											<>
												<div onClick={() => {document.getElementById("image_preview").click();set_update_logo(1)}} style={{width:'150px', height:'100px', backgroundColor:'white', borderRadius:'4px', border:'1px dashed #A7A8B3', padding:'0px', display:'flex',flexDirection:'column', alignItems:'center', justifyContent:'center',cursor:'pointer', textAlign:'center'}}>
													<span style={{fontSize:'25px'}}>+</span>
													<span style={{fontSize:'13px'}}>JPG/PNG</span>
												</div>
											</>
										)
									}
										<div style={{width:'150px', textAlign:'center', marginTop:'5px', fontSize:'13px'}}>
											Logo to use when sales channel overrides
										</div>
									</div> 
									<div style={{padding:'20px 80px'}}>
										
										{/* <div>
											<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Address Line 1{use_org_address?'':'*'}</div>
											<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Address Line 1" type="text" value={address_line_1} onChange={(e)=>{set_address_line_1(e.target.value)}}/>
										</div>
										<div style={{marginTop:'15px'}}>
											<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Address Line 2{use_org_address?'':'*'}</div>
											<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Address Line 2" type="text" value={address_line_2} onChange={(e)=>{set_address_line_2(e.target.value)}}/>
										</div>
										<div style={{marginTop:'15px', display:'flex', justifyContent:'space-between'}}>
											<div style={{width:'45%'}}>
												<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>City{use_org_address?'':'*'}</div>
												<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="City" type="text" value={city} onChange={(e)=>{set_city(e.target.value)}}/>
											</div>
											<div style={{width:'45%'}}>
												<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Province{use_org_address?'':'*'}</div>
												<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Province" type="text" value={province} onChange={(e)=>{set_province(e.target.value)}}/>
											</div>
										</div>
										<div style={{marginTop:'15px', display:'flex', justifyContent:'space-between'}}>
											<div style={{width:'45%'}}>
												<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Zip Code{use_org_address?'':'*'}</div>
												<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Zip Code" type="text" value={zipcode} onChange={(e)=>{set_zipcode(e.target.value)}}/>
											</div>
											<div style={{width:'45%'}}>
												<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Country{use_org_address?'':'*'}</div>
												<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Country" type="text" value={country} onChange={(e)=>{set_country(e.target.value)}}/>
											</div>
										</div>
										<div style={{marginTop:'15px'}}>
											<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>GST no.{use_org_address?'':'*'}</div>
											<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="GST no." type="text" value={gst_number} onChange={(e)=>{set_gst_number(e.target.value)}}/>
										</div> */}
										<div>
											<div style={{paddingBottom:'3px',fontSize:'13px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px', marginTop:'20px'}}>Overriden Organization Name</div>
											<Input id='overriden_org_name' style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Overriden Organization Name" type="text" value={overriden_org_name} onChange={(e)=>{set_overriden_org_name(e.target.value)}}/>
										</div>
									</div>
									
								</TabPane>
							</TabContent>

							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={'tabs3'} style={{fontSize:'12px'}}>  
									<div style={{padding:'20px 30px'}}>
										<div style={{fontSize:"15px", marginBottom:'15px'}}>
											<span style={{fontWeight:'900'}}>{'Terms & Conditions' + '\xa0'+'\xa0'+'\xa0'+'\xa0'}</span>
											<span style={{color:"#A7A8B2"}}>(If not specified, organization terms will be used)</span>
											<div style={{marginTop:'20px', color:'#47596E'}}>Add the Terms & Conditions used in the pricing quotations</div>
										</div>
										<Row style={{marginBottom:"10px"}}>
											<Col>
												<div>
												<div style={{}}>
													<Row>
														<Col xs={1}></Col>
														<Col xs={4}>
															<span style={{flex:1, marginLeft:'5%', fontSize:'14px'}}>Store Terms</span>
														</Col>
														<Col xs={6}>
															<span style={{flex:3, marginLeft:'8%', fontSize:'14px'}}>Store Conditions</span>
														</Col>
													</Row>
												{/* <button style={{flex:1, fontSize:'14px', color:'#2688F7'}}>+ Add new Column</button> */}
												</div>
													{/* <textarea style={{width:'100%',height:'150px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Text..." type="text" value={tandc_text} onChange={e => set_tandc_text(e.target.value)}/> */}
													{
														tandc_array && tandc_array.length ? tandc_array.map((tandc_row,idx) => {
															return(
																<PricingTermsConditions tandc_row = {tandc_row} row_index={idx} update_tandc_row = {update_tandc_row} delete_tandc_row = {delete_tandc_row} make_bold={make_bold} set_make_bold={set_make_bold}/>
															)
														}):''
													}
												</div>
											</Col>
											
										</Row>
										<Row>
											<span style={{color:'#2688F7', fontSize:'14px', cursor:'pointer', fontWeight:'700', marginTop:'1%', marginLeft:'3%'}} onClick = {() => add_tandc_row()}>+ Add more terms</span>
										</Row>
									</div>	
								</TabPane>
							</TabContent>

							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={'tabs4'} style={{fontSize:'12px'}}>  
									<div style={{padding:'20px 40px'}}>
										<div style={{fontSize:"15px", marginBottom:'15px'}}>
											<span style={{fontWeight:'900'}}>{'Viewing Overridden Preferences' + '\xa0'+'\xa0'+'\xa0'+'\xa0'}</span>
											<div style={{color:"#A7A8B2"}}>(Edit option is only available in Org Preferences)</div>
										</div>
										{/* <div style={{marginTop:'15px', display:'flex', justifyContent:'space-between'}}>
											<div style={{width:'45%'}}>
												<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Expiry Date</div>
												<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Expiry Date" type="text" value={sales_channel_name} />
											</div>
											<div style={{width:'45%'}}>
												<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Design Fees</div>
												<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Design Fees" type="text" value={sales_channel_name} />
											</div>
										</div>
										<div style={{display:'flex', marginTop:'20px'}}>
											<span style={{fontSize:'14px', fontWeight:'900', color:'#47596E', marginRight:'5px'}}>Discounts</span>
											<hr style={{width:'90%', marginLeft:'10px', marginTop:'12px'}}></hr>
										</div>
										<div style={{marginTop:'15px', display:'flex', justifyContent:'space-between'}}>
											<div style={{width:'45%'}}>
												<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Max abs enabled</div>
												<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="City" type="text" value={sales_channel_name} />
											</div>
										</div>
										<div style={{marginTop:'20px', display:'flex', justifyContent:'space-between'}}>
											<div style={{width:'45%'}}>
												<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Max abs discount</div>
												<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Max abs discount" type="text" value={sales_channel_name} />
											</div>
											<div style={{width:'45%'}}>
												<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Max percent discount %</div>
												<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.10), inset 0 1px 2px 0 rgba(67,90,111,0.10)', borderRadius: '3px'}} placeholder="Max percent discount" type="text" value={sales_channel_name} />
											</div>
										</div> */}
										<div style={{ height: '100%', overflow: 'scroll', padding: 30, paddingTop:'0px', margin: 0 }}>
											<Row style={{padding:'10px 0px', color:'#22272e'}}>
												{preference_json && Object.keys(preference_json).map((key, index) => 
													(preference_json[key].type == 'leaf' && preference_json[key].id) ? (
														preference_json[key].visible ? (
															<div style={{width: '100%'}}>
																<Leaf_Editor
																	current_key={key}
																	hierarchy={[]}
																	preference_json={preference_json}
																	category_types={category_types}
																	refresh_data = {fetch_master_preferences}
																	sales_channel = {sales_channel}
																	design_preference_id_and_values = {design_preference_id_and_values}
																	set_design_preference_id_and_values = {set_design_preference_id_and_values}
																	overriden_preferences = {overriden_preferences}
																/>
															</div>
														) : ''
													) : ''
												)}
												{preference_json && Object.keys(preference_json).map((key, index) => 
													(preference_json[key].type == 'leaf' && preference_json[key].id) ? '' : (
														<div style={{width: '100%'}}>
															<Parent_Editor
																current_key={key}
																hierarchy={[]}
																preference_json={preference_json}
																refresh_data = {fetch_master_preferences}
																category_types={category_types}
																sales_channel = {sales_channel}
																design_preference_id_and_values = {design_preference_id_and_values}
																set_design_preference_id_and_values = {set_design_preference_id_and_values}
																overriden_preferences = {overriden_preferences}
																first_call = {true}
															/>
														</div>
													)
												)}
											</Row>
										</div>
									</div>
								</TabPane>
							</TabContent>

							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={'tabs5'} style={{fontSize:'12px'}}>  
									<div style={{padding:'20px 30px 0px 30px'}}>
										<div style={{fontSize:"14px", marginBottom:'12px', display:'flex'}} className='saleschannel_tab_container'>
											<div style={{display:'flex'}}>
												<div className='saleschannel_tab' style={{fontWeight:'500', backgroundColor: mapped_open ? 'rgba(16, 112, 202, 0.09)' : 'transparent', color: mapped_open ? '#1070CA': '#000'}} onClick={() => {set_mapped_open(true); clear_saleschannel_search()}}>{"Mapped Users"}</div>
												<div className='saleschannel_tab' style={{fontWeight:'500', backgroundColor: !mapped_open ? 'rgba(16, 112, 202, 0.09)' : 'transparent', color: !mapped_open ? '#1070CA': '#000'}} onClick={() => {set_mapped_open(false); clear_saleschannel_search()}}>{"UnMapped Users"}</div>
											</div>
											{/* <span style={{color:"#A7A8B2"}}>(If not specified, organization terms will be used)</span>
											<div style={{marginTop:'20px', color:'#47596E'}}>Add the Terms & Conditions used in the pricing quotations</div> */}
											<div className={'search_component_container_ideal'}>
												<span><i style={{paddingLeft:'12px', paddingBottom:'3px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/></span>
												<input className={'search_component_inner_container'} placeholder= {'Type to Search'} type='text' value={saleschannel_search_string} onChange={(e) => set_saleschannel_search_string(e.target.value)}></input>
												<span className='hover_blue' style={{paddingRight:'12px', cursor:'pointer', fontSize:'10px', color:'#9aa5b5', visibility:saleschannel_search_string ? 'visible':'hidden', fontStyle:'italic'}} onClick={clear_saleschannel_search}>Clear</span>
												{/* <span><i style={{paddingRight:'12px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-times' onClick={clear_search}/></span> */}
											</div>
										</div>
										
										<div className='saleschannel_dropdown' style={{display:mapped_open?'flex':'none'}}>
											<div>
												Viewing Mapped Users
											</div>
											{/* <div style={{display:'flex', justifyContent:'right', alignItems:'center'}}>
												<div className='add_remove_sales_channel' style={{marginRight:'6px', visibility: sales_channel_users.filter(x => x.checked).length ? 'visible': 'hidden'}} onClick={(e) => {e.stopPropagation(); remove_sales_channel_users()}}>Remove</div>
											</div> */}
											<div style={{color:'#A7A8B2'}}>
												{
													sales_channel_users.filter(x => x.checked).length + " selected / " + sales_channel_users.length
												}
											</div>
										</div>
										<div style={{display:mapped_open?'block':'none', backgroundColor:'white', height:'260px', overflow:'auto'}}>
											{sales_channel_users && sales_channel_users.length ? 
													<All_users_table all_users_accounts={sales_channel_users} set_all_users_accounts={set_sales_channel_users} saleschannel_search_string={saleschannel_search_string}/>	
												: <div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'center', fontSize:'16px'}}>No Users Present</div>
											}
										</div>

										<div className='saleschannel_dropdown' style={{display:mapped_open?'none':'flex'}}>
											<div>
												Viewing Unmapped Users
											</div>
											{/* <div style={{display:'flex', justifyContent:'right', alignItems:'center'}}>
												<div className='add_remove_sales_channel' style={{marginRight:'6px', visibility: sales_channel_unmapped_users.filter(x => x.checked).length ? 'visible': 'hidden'}} onClick={(e) => {e.stopPropagation(); add_sales_channel_users()}}>Add</div>
											</div> */}
											<div style={{color:'#A7A8B2'}}>
												{
													sales_channel_unmapped_users.filter(x => x.checked).length + " selected / " + sales_channel_unmapped_users.length
												}
											</div>
										</div>
										<div style={{display:mapped_open?'none':'block', backgroundColor:'white', height:'260px', overflow:'auto'}}>
											{sales_channel_unmapped_users && sales_channel_unmapped_users.length ? 
													<All_users_table all_users_accounts={sales_channel_unmapped_users} set_all_users_accounts={set_sales_channel_unmapped_users} saleschannel_search_string={saleschannel_search_string}/>	
												: <div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'center', fontSize:'16px'}}>No Users Present</div>
											}
										</div>
										<div style={{justifyContent:'right', padding: '8px 0px', display: mapped_open && sales_channel_users && sales_channel_users.length || !mapped_open && sales_channel_unmapped_users && sales_channel_unmapped_users.length  ? 'flex' : 'none'}}>
											<Button className='blue_button' type="button" onClick={mapped_open ? remove_sales_channel_users : add_sales_channel_users}> {mapped_open ? "Un-Map selected users" : "Map selected users"}</Button>
										</div>
									</div>	
								</TabPane>
							</TabContent>
							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={'tabs6'} style={{padding: '20px 40px', overflow:'auto', fontSize:'14px',}}>
									<div className='flex_property'>
										<div className='mr-3'>Sharing Status</div>
										<Switch className='mr-4' size="small" checked={public_sales_channel} onChange={() => {let t = public_sales_channel; set_public_sales_channel(!t); set_auto_approval(!t)}} />
										<div className='mr-3'>Auto Approval</div>
									    <Switch size="small" checked={auto_approval} onChange={() => public_sales_channel ? set_auto_approval(!auto_approval) : ''} />
									</div>
									{
										mode == 'update'?
										<>
											<div style={{height:'36px', backgroundColor:'white', justifyContent:'space-between'}} className='flex_property mb-1 mt-4 pl-3 pr-3'>
												<div>List of organizations subscribed to this sales channel</div>
												<div className='mr-4'>Actions</div>
											</div>
											<div style={{backgroundColor:'white'}} className='mb-1 mt-3 pl-3'>
											{
												sc_subscription_requests_data && sc_subscription_requests_data.length ? sc_subscription_requests_data.map((single_org_request, idx) => (
													single_org_request.status != 'withdrawn' && single_org_request.status != 'denied' ?
													<div style={{height:'32px', backgroundColor:'white', justifyContent:'space-between', width:'100%', borderBottom:'1px solid #F2F4F6'}} className='flex_property pr-3 pt-2 pb-2'>
														<div>{single_org_request.raised_in_org_name}</div>
														{
															single_org_request.status === 'pending_approval' ? 
															<div className='flex_property'>
																<div className='cp mr-3' style={{color:'#0078ff'}} onClick = {() => {approve_deny_subscription_request(single_org_request.id, true)}}>Approve</div>
																<div className='cp' style={{color:'red'}} onClick = {() => {approve_deny_subscription_request(single_org_request.id, false)}}>Deny</div>
															</div>:""
														}
													</div>:""
												)):""
											}
											{
												subscribed_orgs && subscribed_orgs.length ? subscribed_orgs.map((single_org) => (
													<div style={{height:'32px', backgroundColor:'white', justifyContent:'space-between', width:'100%', borderBottom:'1px solid #F2F4F6'}} className='flex_property pr-3 pt-2 pb-2'>
														<div>{single_org.name}</div>
													</div>
												)):''
											}
											</div>
										</>:''
									}
								</TabPane>
							</TabContent>
						</>
					):
					(
						<div style={{padding:'20px'}}>
							<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Store Id</div>
							<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Id" type="text" value={store_id} onChange={e => set_store_id(e.target.value)}/>
						</div>
					)
				}
				</CardBody>

				<CardFooter className='global_modal_footer'>
						<Button className='white_button' type="button" onClick={handleClose}> Cancel </Button>
						{mode === "add" && <Button id='add_sc_submit' disabled={(sales_channel_name&&selected_price_type&&selected_price_type.length)?false:true} className='blue_button' type="button" onClick={add_sales_name}>Add</Button>}
						{mode === "update" && <Button id='update_sc_submit' className='blue_button' type="button" onClick={update_sales_name}>Update</Button>}
						{mode === "share" && <Button className='blue_button' type="button" onClick={share_store_id}>Share</Button>}
				</CardFooter>
			</Card>
		</Modal>
  	);
}

export default SalesChannelDialogBox ; 
