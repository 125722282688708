import React, { useEffect, useState } from 'react';
import Main_Grid from '../../components/Main_Grid';

const home_style = {
	width: '100%',
	height: '100%',
	backgroundColor: 'white'
}

const Error = ({user, path_string, active_tab, store_details, get_store_details, user_roles}) => {

	const[error_text, set_error_text] = useState('')
	const [design_link, set_design_link] = useState('/design.infurnia.com/');

	useEffect(() => {
		console.log('Error ka path_string', path_string)
		if(path_string){
			if(path_string==='noaccess'){
				set_error_text('Access denied, please contact your admin.')
			}else if(path_string){
				set_error_text('Error 404 Page not found.')
			}
		}else{
			set_error_text('')
		}
	},[path_string])

	useEffect(() => {
		let check_enterprise_white_labelled_url = (window.location.host.split('.')[1] == 'infurnia') ? false : true;
			if (check_enterprise_white_labelled_url) {
				let [ subdomain, domain, extension ] = window.location.host.split('.');
				set_design_link(`https://design.${domain}.${extension}/`);
			} else {
				set_design_link(global.config.website_url)
			}
	}, []);

	return (
		user && user.role && user.role.business_unit && user.current_business_unit_id && user.role.business_unit[user.current_business_unit_id] && user.role.business_unit[user.current_business_unit_id].length && (JSON.stringify([...new Set(user.role.business_unit[user.current_business_unit_id])]) == JSON.stringify(["designer"]) || user.role.business_unit[user.current_business_unit_id].length == 0) ?
		<div style={{height:'100vh', width:'100vw', position:'fixed',display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', zIndex:1000, background:'white'}}>
			<h4 style={{color:'red'}}>{error_text?error_text:'Access denied, please contact your admin.'}</h4>
			<a href={design_link} style={{}}>Go To Design Portal</a>
		</div>
		:
		<Main_Grid user={user} style={home_style} store_details={store_details} get_store_details={get_store_details}>
			<div style={{display:'flex', justifyContent:'center', alignItems:'center', color:'red', zIndex:1000, height:'calc(100vh - 64px)'}}>
				<h4 style={{}}>{error_text?error_text:'Access denied, please contact your admin.'}</h4>
			</div>
		</Main_Grid>
	);
}

export default Error;