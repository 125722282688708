import React, { useEffect, useState } from 'react';
import './style.css';

export const ICollapsedContent = ({ children, style, style_inner, isOpen, className, classNameInner }) => {
    return (
        <div className={`infurnia_collapse_container ${isOpen ? 'uncollapsed' : ''} ${className ? className : ''}`} style={{ ...style}}>
            <div className={`infurnia_collapse_content ${classNameInner ? classNameInner : ''}`} style={{...style_inner}}>{children}</div>
        </div>
    )
}

export const ICollapsedHeader = ({ children, toggle, custom_toggle_icon, style, className, isOpen }) => {
    return (
        <div className={(className ? className : '') + " infurnia_collapse_header"} style={{ ...style }} onClick={toggle}>
            {children}
            {
                <img onClick={toggle} style={{ cursor: 'pointer', marginLeft: '8px' }} src={custom_toggle_icon ? custom_toggle_icon : isOpen ? '/assets/icons/dropdown_open.svg' : '/assets/icons/dropdown_closed.svg'}></img>
            }
        </div>
    )
}

// const ICollpsable = ({ children, header, toggle, custom_toggle_icon, style, className, isOpen, style_header, style_container }) => {
//     return (
//         <React.Fragment>
//             <div className={(className ? className : '') + " infurnia_dropdown_header"} style={{ ...style }} onClick={toggle}>
//                 <div style={{ ...style_header }}>{header}</div>
//                 {
//                     custom_toggle_icon ?
//                         <img onClick={toggle} style={{ cursor: 'pointer' }} src={custom_toggle_icon ? custom_toggle_icon : isOpen ? '/resources/panel_icons_new/dropdown_open.svg' : '/resources/panel_icons_new/dropdown_closed.svg'}></img>
//                         :
//                         <i className={isOpen ? 'fa fa-caret-up' : 'fa fa-caret-down'} style={{ color: '#676878', fontSize: '12px' }} />

//                 }
//             </div>
//             <div className='infurnia_dropdown_container' style={{ ...style_container, display: isOpen ? 'block' : 'none' }}>
//                 {children}
//             </div>
//         </React.Fragment>
//     );
// };

export const ICollpsable = ({children, toggle, header, custom_toggle_icon, isOpen, style_header, style_content, style_content_inner, ...props}) => {
    return(
        <div {...props}>
            <ICollapsedHeader toggle={toggle} custom_toggle_icon={custom_toggle_icon} isOpen={isOpen} style={style_header}>{header}</ICollapsedHeader>
            <ICollapsedContent isOpen={isOpen} style={style_content} style_inner={style_content_inner}>{children}</ICollapsedContent>
        </div>
    )
}

export const UncontrolledCollapse = ({children, header, custom_toggle_icon, style_header, style_content, style_content_inner, defaultState = false , ...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen)

    useEffect(() => {
        if(defaultState != isOpen){
            setIsOpen(defaultState)
        }
    }, [defaultState]);

    return(
        <ICollpsable toggle={toggle} header={header} custom_toggle_icon={custom_toggle_icon} isOpen={isOpen} style_header={style_header} style_content={style_content} style_content_inner={style_content_inner} {...props}>
            {children}
        </ICollpsable>
    )
}
