import { logToSentry } from "../error";
import general_fetch from "../fetch";

export const addTexture = async(map) => {
    try{
        let fd = new FormData();	
		if(map){
            fd.append('file', map)
            fd.append('name', map.name)
        }

        let response = await general_fetch({ url: 'texture/add', body:fd, is_form_data: true });
        return response
    }catch(err){
        console.error(`Error in addTexture `, err)
        return Promise.reject(err);
    }
}

export const getTexture = async(texture_id) => {
    try{
        let body = {identifiers: JSON.stringify({id: texture_id})}

        let response = await general_fetch({ url: 'texture/get', body });
        if(response && Array.isArray(response) && response.length){
            return response[0]
        }else{
            logToSentry(`Invalid Response in texture get - ${texture_id}`)
            return null
        }
    }catch(err){
        console.error(`Error in getTexture `, err)
        return Promise.reject(err);
    }
}