import React , { useState, useEffect, useContext } from 'react';
import { Card, Col, Progress, Table, Row, Modal, CardHeader, CardBody, CardFooter, Button, Input } from "reactstrap";
import { useAlert } from 'react-alert'

import Main_Grid from '../../components/Main_Grid/';
// import Multiselect from "../../components/Multiselect/";
import Multiselect from 'multiselect-react-dropdown';
import {DragDropComponentPreferences} from '../../components/DragDropCompoment';
import { IModalHeader, IModalBody, IModalFooter } from '../../components/IModal';
import Switch from "react-switch";

import general_fetch from '../../utils/fetch';
import name_sort from '../../utils/name_sort';
import split_cat_type from '../../utils/split_cat_type';
// import { act } from '@testing-library/react';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { UserContext } from '../../components/Contexts/UserContext';
import {Tooltip} from 'antd'
import KeyValuePairs from './KeyValuePairs';
import dummy_preference_json from './dummy_preference_json';
import { AllBuContext } from '../../components/Contexts/AllBuContext';
import { cutlist_preference_display_name } from '../../models/cutlist_preference_display_names';
import {ConfirmationModal} from '../../components/CommonModals';
import get_top_level_pref_value from '../../utils/get_top_level_pref_value';
import strigified_preference_category_restriction from '../../assets/core_jsons/preferences_category_support.json'
import strigified_preference_tooltip from '../../assets/core_jsons/preferences_tooltip_support.json'
import debug_log from '../../utils/debug_log';
import { handleError } from '../../utils/error';
import TriggerTooltip from '../../components/TriggerTooltip';
import build_path from '../../utils/path';

let temp_preference_tooltip_json = strigified_preference_tooltip
// let temp_preference_tooltip_json = JSON.parse(strigified_preference_tooltip)

let preference_tooltip_json = {}
for(let i in temp_preference_tooltip_json){
    let tooltip_object = temp_preference_tooltip_json[i]
    preference_tooltip_json[tooltip_object.id.replaceAll('.', '_').replaceAll('kitchen_preferences', 'cabinet_preferences').replaceAll('fulfillment_tag_preferences', 'panel_label_preferences')] = tooltip_object.url
}


// let preference_category_restriction_temp = (strigified_preference_category_restriction);
let preference_category_restriction_temp = strigified_preference_category_restriction;
// let preference_category_restriction_temp = JSON.parse(strigified_preference_category_restriction);
let preference_category_restriction = {};

for(let i in preference_category_restriction_temp){
    let pref_name = i.replaceAll('.', '_');
    pref_name = pref_name.replaceAll('kitchen_preferences', 'cabinet_preferences');
    pref_name = pref_name.replaceAll('fulfillment_tag_preferences', 'panel_label_preferences');
    preference_category_restriction[pref_name] = preference_category_restriction_temp[i];
}

const valid_value_types = ['integer', 'string', 'enum', 'boolean', 'sku_category_type_id','list', 'sku_id'];
let sku_ids_set = new Set([])
const modal_for_parents = ["cutlist_preferences_biesse", "cutlist_preferences_general"];
const custom_field_ids_cutlist = ['custom_field1', 'custom_field2', 'custom_field3'];
const custom_cutlist_variables = ['core_material_sku_name', 'core_material_model_no', 'internal_finish_sku_name', 'internal_finish_model_no', 'external_finish_sku_name', 'external_finish_model_no'];

//values just for testing. Need to change later on
const dependent_keys_json = {
    // 'fe4d8cc37f4b3a8e': ['835dcc9363ac55d3']
    'cam_preferences_pocket_preferences_homag_pocket_step_depth_type': ['cam_preferences_pocket_preferences_homag_pocket_depth_per_pass', 'cam_preferences_pocket_preferences_homag_pocket_number_of_steps'],
    
    // 'other_admin_preferences_maximum_absolute_discount_enabled': ['other_admin_preferences_maximum_absolute_discount', 'other_admin_preferences_maximum_other_items_absolute_discount', 'other_admin_preferences_maximum_other_items_percentage_discount', 'other_admin_preferences_maximum_percentage_discount_(percentage)', 'other_admin_preferences_maximum_woodwork_absolute_discount', 'other_admin_preferences_maximum_woodwork_percentage_discount'],
    
    'wardrobe_preferences_carcass_carcass_back_material_enabled': ['wardrobe_preferences_carcass_carcass_back_material'],
    'wardrobe_preferences_carcass_carcass_back_panel_invisible_edge_band_enabled': ['wardrobe_preferences_carcass_carcass_back_panel_invisible_edge_band', 'wardrobe_preferences_carcass_carcass_back_panel_invisible_edge_band_removed '],
    'wardrobe_preferences_carcass_carcass_back_panel_visible_edge_band_enabled': ['wardrobe_preferences_carcass_carcass_back_panel_visible_edge_band', 'wardrobe_preferences_carcass_carcass_back_panel_visible_edge_band_removed '],
    'wardrobe_preferences_carcass_carcass_excluding_back_panel_invisible_edge_band_enabled': ['wardrobe_preferences_carcass_carcass_excluding_back_panel_invisible_edge_band', 'wardrobe_preferences_carcass_carcass_excluding_back_panel_invisible_edge_band_removed '],
    'wardrobe_preferences_carcass_carcass_excluding_back_panel_visible_edge_band_enabled': ['wardrobe_preferences_carcass_carcass_excluding_back_panel_visible_edge_band', 'wardrobe_preferences_carcass_carcass_excluding_back_panel_visible_edge_band_removed '],
    'wardrobe_preferences_carcass_carcass_shelf_visible_edge_band_enabled': ['wardrobe_preferences_carcass_carcass_shelf_visible_edge_band', 'wardrobe_preferences_carcass_carcass_shelf_visible_edge_band_removed '],
    'wardrobe_preferences_carcass_carcass_shelf_invisible_edge_band_enabled': ['wardrobe_preferences_carcass_carcass_shelf_invisible_edge_band', 'wardrobe_preferences_carcass_carcass_shelf_invisible_edge_band_removed '],
    'wardrobe_preferences_carcass_carcass_finish_material_enabled': ['wardrobe_preferences_carcass_carcass_finish_material'],
    'wardrobe_preferences_carcass_carcass_internal_finish_material_enabled': ['wardrobe_preferences_carcass_carcass_internal_finish_material'],
    'wardrobe_preferences_carcass_carcass_material_enabled': ['wardrobe_preferences_carcass_carcass_material'],
    'wardrobe_preferences_handle_external_drawer_handle_enabled': ['wardrobe_preferences_handle_external_drawer_handle'],
    'wardrobe_preferences_handle_internal_drawer_handle_enabled': ['wardrobe_preferences_handle_internal_drawer_handle'],
    'wardrobe_preferences_handle_shutter_handle_enabled': ['wardrobe_preferences_handle_shutter_handle'],
    'wardrobe_preferences_shutter_shutter_design_material_enabled': ['wardrobe_preferences_shutter_shutter_design_material'],
    'wardrobe_preferences_shutter_shutter_finish_material_enabled': ['wardrobe_preferences_shutter_shutter_finish_material'],
    'wardrobe_preferences_shutter_shutter_internal_finish_material_enabled': ['wardrobe_preferences_shutter_shutter_internal_finish_material'],
    'wardrobe_preferences_shutter_shutter_material_enabled': ['wardrobe_preferences_shutter_shutter_material'],
    'wardrobe_preferences_shutter_shutter_visible_edge_band_enabled': ['wardrobe_preferences_shutter_shutter_visible_edge_band', 'wardrobe_preferences_shutter_shutter_visible_edge_band_removed'],
    'wardrobe_preferences_shutter_shutter_invisible_edge_band_enabled': ['wardrobe_preferences_shutter_shutter_invisible_edge_band', 'wardrobe_preferences_shutter_shutter_invisible_edge_band_removed'],
    'wardrobe_preferences_skirting_skirting_core_material_enabled': ['wardrobe_preferences_skirting_skirting_core_material'],
    'wardrobe_preferences_skirting_skirting_finish_material_enabled': ['wardrobe_preferences_skirting_skirting_finish_material'],
    'wardrobe_preferences_skirting_skirting_visibility_enabled': ['wardrobe_preferences_skirting_show_back_skirting', 'wardrobe_preferences_skirting_show_blind_skirting', 'wardrobe_preferences_skirting_show_front_skirting', 'wardrobe_preferences_skirting_show_left_skirting', 'wardrobe_preferences_skirting_show_right_skirting'],

    'cabinet_preferences_base_unit_elevation_enabled': ['cabinet_preferences_base_unit_elevation'],

    'cabinet_preferences_carcass_carcass_back_material_enabled': ['cabinet_preferences_carcass_carcass_back_material'],
    'cabinet_preferences_carcass_carcass_back_panel_invisible_edge_band_enabled': ['cabinet_preferences_carcass_carcass_back_panel_invisible_edge_band', 'cabinet_preferences_carcass_carcass_back_panel_invisible_edge_band_removed'],
    'cabinet_preferences_carcass_carcass_back_panel_visible_edge_band_enabled': ['cabinet_preferences_carcass_carcass_back_panel_visible_edge_band', 'cabinet_preferences_carcass_carcass_back_panel_visible_edge_band_removed'],
    'cabinet_preferences_carcass_carcass_excluding_back_panel_invisible_edge_band_enabled': ['cabinet_preferences_carcass_carcass_excluding_back_panel_invisible_edge_band', 'cabinet_preferences_carcass_carcass_excluding_back_panel_invisible_edge_band_removed'],
    'cabinet_preferences_carcass_carcass_excluding_back_panel_visible_edge_band_enabled': ['cabinet_preferences_carcass_carcass_excluding_back_panel_visible_edge_band', 'cabinet_preferences_carcass_carcass_excluding_back_panel_visible_edge_band_removed'],
    'cabinet_preferences_carcass_carcass_shelf_visible_edge_band_enabled': ['cabinet_preferences_carcass_carcass_shelf_visible_edge_band', 'cabinet_preferences_carcass_carcass_shelf_visible_edge_band_removed'],
    'cabinet_preferences_carcass_carcass_shelf_invisible_edge_band_enabled': ['cabinet_preferences_carcass_carcass_shelf_invisible_edge_band', 'cabinet_preferences_carcass_carcass_shelf_invisible_edge_band_removed'],
    'cabinet_preferences_carcass_carcass_finish_material_enabled': ['cabinet_preferences_carcass_carcass_finish_material'],
    'cabinet_preferences_carcass_carcass_internal_finish_material_enabled': ['cabinet_preferences_carcass_carcass_internal_finish_material'],
    'cabinet_preferences_carcass_carcass_material_enabled': ['cabinet_preferences_carcass_carcass_material'],
    'cabinet_preferences_handle_external_drawer_handle_enabled': ['cabinet_preferences_handle_external_drawer_handle'],
    'cabinet_preferences_handle_internal_drawer_handle_enabled': ['cabinet_preferences_handle_internal_drawer_handle'],
    'cabinet_preferences_handle_shutter_handle_enabled': ['cabinet_preferences_handle_shutter_handle'],
    'cabinet_preferences_shutter_shutter_design_material_enabled': ['cabinet_preferences_shutter_shutter_design_material'],
    'cabinet_preferences_shutter_shutter_finish_material_enabled': ['cabinet_preferences_shutter_shutter_finish_material'],
    'cabinet_preferences_shutter_shutter_internal_finish_material_enabled': ['cabinet_preferences_shutter_shutter_internal_finish_material'],
    'cabinet_preferences_shutter_shutter_material_enabled': ['cabinet_preferences_shutter_shutter_material'],
    'cabinet_preferences_shutter_shutter_visible_edge_band_enabled': ['cabinet_preferences_shutter_shutter_visible_edge_band', 'cabinet_preferences_shutter_shutter_visible_edge_band_removed'],
    'cabinet_preferences_shutter_shutter_invisible_edge_band_enabled': ['cabinet_preferences_shutter_shutter_invisible_edge_band', 'cabinet_preferences_shutter_shutter_invisible_edge_band_removed'],
    'cabinet_preferences_wall_unit_elevation_enabled': ['cabinet_preferences_wall_unit_elevation'],
    'cabinet_preferences_skirting_skirting_visibility_enabled': ['cabinet_preferences_skirting_show_back_skirting', 'cabinet_preferences_skirting_show_blind_skirting', 'cabinet_preferences_skirting_show_front_skirting', 'cabinet_preferences_skirting_show_left_skirting', 'cabinet_preferences_skirting_show_right_skirting'],
}

const conditional_visibility_based_on_dependent_key_value = {
    'cam_preferences_pocket_preferences_homag_pocket_depth_per_pass': 'absolute_depth',
    'cam_preferences_pocket_preferences_homag_pocket_number_of_steps': 'number_of_steps',
    
    // 'other_admin_preferences_maximum_absolute_discount': 'true',
    // 'other_admin_preferences_maximum_other_items_absolute_discount': 'true',
    // 'other_admin_preferences_maximum_other_items_percentage_discount': 'true',
    // 'other_admin_preferences_maximum_percentage_discount_(percentage)': 'true',
    // 'other_admin_preferences_maximum_woodwork_absolute_discount': 'true',
    // 'other_admin_preferences_maximum_woodwork_percentage_discount': 'true',

    "wardrobe_preferences_carcass_carcass_back_material": "true",
    "wardrobe_preferences_carcass_carcass_back_panel_invisible_edge_band": "true",
    "wardrobe_preferences_carcass_carcass_back_panel_invisible_edge_band_removed ": "true",
    "wardrobe_preferences_carcass_carcass_back_panel_visible_edge_band": "true",
    "wardrobe_preferences_carcass_carcass_back_panel_visible_edge_band_removed ": "true",
    "wardrobe_preferences_carcass_carcass_excluding_back_panel_invisible_edge_band": "true",
    "wardrobe_preferences_carcass_carcass_excluding_back_panel_invisible_edge_band_removed ": "true",
    "wardrobe_preferences_carcass_carcass_excluding_back_panel_visible_edge_band": "true",
    "wardrobe_preferences_carcass_carcass_excluding_back_panel_visible_edge_band_removed ": "true",
    "wardrobe_preferences_carcass_carcass_shelf_visible_edge_band": "true",
    "wardrobe_preferences_carcass_carcass_shelf_visible_edge_band_removed ": "true",
    "wardrobe_preferences_carcass_carcass_shelf_invisible_edge_band": "true",
    "wardrobe_preferences_carcass_carcass_shelf_invisible_edge_band_removed ": "true",
    "wardrobe_preferences_carcass_carcass_finish_material": "true",
    "wardrobe_preferences_carcass_carcass_internal_finish_material": "true",
    "wardrobe_preferences_carcass_carcass_material": "true",
    "wardrobe_preferences_handle_external_drawer_handle": "true",
    "wardrobe_preferences_handle_internal_drawer_handle": "true",
    "wardrobe_preferences_handle_shutter_handle": "true",
    "wardrobe_preferences_shutter_shutter_design_material": "true",
    "wardrobe_preferences_shutter_shutter_finish_material": "true",
    "wardrobe_preferences_shutter_shutter_internal_finish_material": "true",
    "wardrobe_preferences_shutter_shutter_material": "true",
    "wardrobe_preferences_shutter_shutter_visible_edge_band": "true",
    "wardrobe_preferences_shutter_shutter_visible_edge_band_removed": "true",
    "wardrobe_preferences_shutter_shutter_invisible_edge_band": "true",
    "wardrobe_preferences_shutter_shutter_invisible_edge_band_removed": "true",
    "wardrobe_preferences_skirting_skirting_core_material": "true",
    "wardrobe_preferences_skirting_skirting_finish_material": "true",
    "wardrobe_preferences_skirting_show_back_skirting": "true",
    "wardrobe_preferences_skirting_show_blind_skirting": "true",
    "wardrobe_preferences_skirting_show_front_skirting": "true",
    "wardrobe_preferences_skirting_show_left_skirting": "true",
    "wardrobe_preferences_skirting_show_right_skirting": "true",
    "cabinet_preferences_base_unit_elevation": "true",
    "cabinet_preferences_carcass_carcass_back_material": "true",
    "cabinet_preferences_carcass_carcass_back_panel_invisible_edge_band": "true",
    "cabinet_preferences_carcass_carcass_back_panel_invisible_edge_band_removed": "true",
    "cabinet_preferences_carcass_carcass_back_panel_visible_edge_band": "true",
    "cabinet_preferences_carcass_carcass_back_panel_visible_edge_band_removed": "true",
    "cabinet_preferences_carcass_carcass_excluding_back_panel_invisible_edge_band": "true",
    "cabinet_preferences_carcass_carcass_excluding_back_panel_invisible_edge_band_removed": "true",
    "cabinet_preferences_carcass_carcass_excluding_back_panel_visible_edge_band": "true",
    "cabinet_preferences_carcass_carcass_excluding_back_panel_visible_edge_band_removed": "true",
    "cabinet_preferences_carcass_carcass_shelf_visible_edge_band": "true",
    "cabinet_preferences_carcass_carcass_shelf_visible_edge_band_removed": "true",
    "cabinet_preferences_carcass_carcass_shelf_invisible_edge_band": "true",
    "cabinet_preferences_carcass_carcass_shelf_invisible_edge_band_removed": "true",
    "cabinet_preferences_carcass_carcass_finish_material": "true",
    "cabinet_preferences_carcass_carcass_internal_finish_material": "true",
    "cabinet_preferences_carcass_carcass_material": "true",
    "cabinet_preferences_handle_external_drawer_handle": "true",
    "cabinet_preferences_handle_internal_drawer_handle": "true",
    "cabinet_preferences_handle_shutter_handle": "true",
    "cabinet_preferences_shutter_shutter_design_material": "true",
    "cabinet_preferences_shutter_shutter_finish_material": "true",
    "cabinet_preferences_shutter_shutter_internal_finish_material": "true",
    "cabinet_preferences_shutter_shutter_material": "true",
    "cabinet_preferences_shutter_shutter_visible_edge_band": "true",
    "cabinet_preferences_shutter_shutter_visible_edge_band_removed": "true",
    "cabinet_preferences_shutter_shutter_invisible_edge_band": "true",
    "cabinet_preferences_shutter_shutter_invisible_edge_band_removed": "true",
    "cabinet_preferences_wall_unit_elevation": "true",
    "cabinet_preferences_skirting_show_back_skirting": "true",
    "cabinet_preferences_skirting_show_blind_skirting": "true",
    "cabinet_preferences_skirting_show_front_skirting": "true",
    "cabinet_preferences_skirting_show_left_skirting": "true",
    "cabinet_preferences_skirting_show_right_skirting": "true"
}


const overridden_option_name_key_list = Object.keys(cutlist_preference_display_name)

let preference_id_to_full_name = {}

let dependent_keys_list = []
let dependent_keys_list_temp = Object.keys(dependent_keys_json).map(x => dependent_keys_list = [...dependent_keys_list, ...dependent_keys_json[x]])

let dependent_keys_value_json = {}

const sanitize_value = (value) => {
    return ["1", 1, "true", true, 'true', 'True', "True"].includes(value) ? "1" : "0";
}

const helper = (jsonData, user) => {
    if(jsonData == undefined)
        return;
    if(jsonData && jsonData.type == 'leaf'){
        if(jsonData.value_type != 'boolean') return;
        if(jsonData.value !== undefined){
            if(jsonData.value['store'] !== undefined)
                jsonData.value['store'] = sanitize_value(jsonData.value['store'])
            if(jsonData.value['master'] !== undefined)
                jsonData.value['master'] = sanitize_value(jsonData.value['master'])
            if(jsonData.value['business_unit'] !== undefined && jsonData.value['business_unit'][user.current_business_unit_id] !== undefined){
                jsonData.value['business_unit'][user.current_business_unit_id] = sanitize_value(jsonData.value['business_unit'][user.current_business_unit_id])
            }

        }
        return;
    }
    Object.keys(jsonData).map(item => helper(jsonData[item], user))
}

const sanitize_json = (jsonData) => {
    let user = window.get_user()
    helper(jsonData, user)
}

//generate a random 20 charecter id
const generate_id = () => {
	const id_length = 20;
	const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let text = "";
	for (let i = 0; i < id_length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
}


const convert_to_readable = (str) => {
    //snake case
    if (!str)   return '';
    if (str.includes('_')) {
        return str.replace(/_/g, ' ').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }
    //camel case
    let words = str.match(/[A-Za-z][a-z]*/g) || [];
    return words.map(word => (word.charAt(0).toUpperCase() + word.substring(1))).join(" ");
}

const Edit_SalesChannel_preference_Modal = ({active_sales_channel, hierarchy, open, handle_close, category_types, leaf_data, fetch_all_level_preferences, preference_full_name}) => {

    const [ id, set_id ] = useState('');
    const [ name, set_name ] = useState('');
    const [ value, set_value ] = useState('');
    const [ sales_channel_level_value, set_sales_channel_level_value] = useState('');
    const [ value_type, set_value_type ] = useState('');
    const [ value_options, set_value_options ] = useState('');
    const [ category_type_map, set_category_type_map ] = useState({});
    const alert = useAlert()

    const set_sales_channel_level_pref = async() => {
        if(active_sales_channel){
            try{
                let design_preference_id_and_values = [{designing_preference_id: id, value: sales_channel_level_value}]
                let resp = await general_fetch({url:"designing_preference/set_sales_channel_preference", body:{sales_channel_id:active_sales_channel.id, design_preference_id_and_values:design_preference_id_and_values}})
                if(resp) alert.success('Updation Successful!')
                fetch_all_level_preferences()
                handle_close()
            }catch(err){
                alert.error('Update Failed!')
            }
        }
    }

    useEffect(() => {
        console.log('values ---> ', value);
    }, [ value ])


    useEffect(() => {
        console.log('ssss')
        if(leaf_data && category_type_map && Object.keys(category_type_map).length && open) {
            console.log('ssss2', category_type_map);
            set_id(leaf_data.id);
            set_name(leaf_data.display_name);
            let temp_value_options = leaf_data.value_options;
            let temp_value_options_map = {};

            if (leaf_data.value_type === 'sku_category_type_id') {
                console.log('ssss3', leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master, JSON.parse(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => category_type_map[x]));
                set_value(JSON.parse(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => category_type_map[x]));
                set_sales_channel_level_value(((leaf_data.value ? (leaf_data.value['sales_channel'] ? (leaf_data.value['sales_channel'][active_sales_channel.id] ? leaf_data.value['sales_channel'][active_sales_channel.id]: (leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master'])) : (leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master'])):'')).map(x => category_type_map[x]));
            } else if (leaf_data.value_type === 'list') {
                temp_value_options = temp_value_options.map(x => ({ id: generate_id(), display_name: x, name: (preference_full_name && overridden_option_name_key_list.includes(preference_full_name) ? cutlist_preference_display_name[preference_full_name][x] : x) }));
                temp_value_options_map = temp_value_options.reduce((final, elem) => ({...final, [elem.display_name]: elem }), {});
                set_value(JSON.parse(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => temp_value_options_map[x]));
                set_sales_channel_level_value(((leaf_data.value ? (leaf_data.value['sales_channel'] ? (leaf_data.value['sales_channel'][active_sales_channel.id] ? leaf_data.value['sales_channel'][active_sales_channel.id]: (leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master'])) : (leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master'])):'')).map(x => temp_value_options_map[x]))
            } else if (leaf_data.value_type == 'boolean') {
                    if(leaf_data.value['store'] == undefined){
                        set_value([false, "false", 0].includes(leaf_data.value['master']) ? "false" : "true")
                    }
                    else{
                        set_value([false, "false", 0].includes(leaf_data.value['store']) ? "false" : "true")
                    }
                    if(leaf_data.value['sales_channel'] == undefined || leaf_data.value['sales_channel'][active_sales_channel.id] == undefined){
                        if(leaf_data.value['store'] == undefined){
                            set_sales_channel_level_value([false, "false", 0].includes(leaf_data.value['master']) ? "false" : "true")
                        }
                        else{
                            set_sales_channel_level_value([false, "false", 0].includes(leaf_data.value['store']) ? "false" : "true")
                        }
                    }
                    else{
                        set_sales_channel_level_value([false, "false", 0].includes(leaf_data.value['sales_channel'][active_sales_channel.id]) ? "false" : "true")
                    }
            }
             else {
                set_value(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master);
                set_sales_channel_level_value(((leaf_data.value ? (leaf_data.value['sales_channel'] ? (leaf_data.value['sales_channel'][active_sales_channel.id] ? leaf_data.value['sales_channel'][active_sales_channel.id]: (leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master'])) : (leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master'])):'')))
            }
            set_value_type(leaf_data.value_type);
            if (leaf_data.value_type === 'list') {
                set_value_options(temp_value_options);
            } else {
                set_value_options(leaf_data?.value_options ?? []);
            }
        }
    }, [ leaf_data, category_type_map, open ]);


    useEffect(() => {
        if (category_types && category_types.length) {
            set_category_type_map(category_types.reduce((final, elem) => ({...final, [elem.id]: elem }), {}));
        }
    }, [ category_types ]);

    return(
        <Modal className="Margin_64px_top" isOpen={open} size={value_type == 'list' || value_type == 'sku_category_type_id' ? "lg" : "sm"} toggle={handle_close}>
            <Card>
                <CardHeader className='global_modal_header'>
                    <span>
                        Edit Preference at Sales Channel Level
                    </span>
                    <i style={{cursor:'pointer'}} onClick={handle_close} className='fa fa-times'/>
                </CardHeader>
                <CardBody className='global_modal_body' style={{ height: 'auto', overflow: 'unset' }}>
                    {value_type == 'integer' || value_type == 'string' ? (
                        <>
                            <Row>
                                <Col>
                                    <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold'}}>
                                        {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                                    </span>
                                </Col>
                                <Col style={{ textAlign: 'right' }}>
                                    <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                                </Col>
                            </Row>
                            <hr/>
                            <Row style={{marginTop:'15px', fontSize:'15px', marginBottom:'5px'}}>
                                <Col>Organization Level (Default)</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <input disabled={true} type={value_type === "integer" ? "number" : "text"} className="form-control" placeholder="Value" value={value} />
                                </Col>
                            </Row>
                            <span style={{marginTop:'10px', fontSize:'15px', display:'flex', marginBottom:'5px'}}>
                            <span style={{}}>{'Sales Channel Level ('}<span>{active_sales_channel?active_sales_channel.name:''}</span>{')'}</span>
                            </span>
                            <Row>
                                <Col>
                                    <input type="text" className="form-control" placeholder="Value" value={sales_channel_level_value} onChange={(e) => set_sales_channel_level_value(e.target.value)}/>
                                </Col>
                            </Row>
                        </>
                    ) : ( value_type == 'enum' || value_type == 'boolean' ? (
                        <>
                            <Row>
                                <Col>
                                    <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold'}}>
                                        {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                                    </span>
                                </Col>
                                <Col style={{ textAlign: 'right' }}>
                                    <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                                </Col>
                            </Row>
                            <hr/>
                            <Row style={{marginTop:'15px', fontSize:'15px', marginBottom:'5px'}}>
                                <Col>Organization Level (Default)</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <input disabled={true} type="text" className="form-control" placeholder="Value" value={value} />
                                </Col>
                            </Row>
                            <span style={{marginTop:'10px', fontSize:'15px', display:'flex', marginBottom:'5px'}}>
                            <span style={{}}>{'Sales Channel Level ('}<span>{active_sales_channel?active_sales_channel.name:''}</span>{')'}</span>
                            </span>
                            <Row>
                                <Col>
                                    <select className="form-control" value={sales_channel_level_value} onChange={(e) => set_sales_channel_level_value(e.target.value)}>
                                        {value_type == 'enum' ? value_options.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        )) : ['true', 'false'].map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                        </>
                    ) : ( value_type == 'list' || value_type == 'sku_category_type_id' ? (
                        <div style={{ overflowY: 'visible' }}>
                            <Row>
                                <Col>
                                    <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold'}}>
                                        {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                                    </span>
                                </Col>
                                <Col style={{ textAlign: 'right' }}>
                                    <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                                </Col>
                            </Row>
                            <hr/>
                            <Row style={{marginTop:'15px', fontSize:'15px', marginBottom:'5px'}}>
                                <Col>Organization Level (Default)</Col>
                            </Row>
                            <Row style={{paddingBottom: 50, overflowY: 'visible'}}>
                                <Col>
                                    <input disabled={true} type="text" className="form-control" placeholder="Value" value={value} />
                                </Col>
                            </Row>
                            <span style={{marginTop:'10px', fontSize:'15px', display:'flex', marginBottom:'5px'}}>
                                <span style={{}}>{'Sales Channel Level ('} <span>{active_sales_channel?active_sales_channel.name:''}</span>{')'}</span>
                            </span>
                            <Row style={{paddingBottom: 50, overflowY: 'visible'}}>
                                <Col>
                                    <Multiselect
                                        key={'select_value' + id}
                                        style={{ backgroundColor:'white', width:'100%' }}
                                        options={value_type == 'list' ? value_options : category_types}
                                        selectedValues={sales_channel_level_value ? sales_channel_level_value : []}
                                        onSelect={x => { console.log('setting selected value 000  >>>>', x);set_sales_channel_level_value(x); }}
                                        onRemove={set_sales_channel_level_value}
                                        displayValue={value_type == 'list' ? 'name' : 'display_name'}
                                        closeOnSelect={false}
                                        avoidHighlightFirstOption={true}
                                        showCheckbox
                                    />
                                </Col>
                            </Row>
                        </div>              
                    ) : <></> ) )}                
                </CardBody>
                <CardFooter className='global_modal_footer'>
                    <Button className='blue_button' style={{margin:0}} type="button" onClick={() => {set_sales_channel_level_pref()}} > Update </Button>
                </CardFooter>
            </Card>
        </Modal>
    )
}

const LeafEditSingleRow = ({ category_types, leaf_data, value_json, all_division_tree_data, open, sku_data_json, set_page_loader, user, org_bu_view, set_value_copy, preference_full_name, ...props }) => {
    const [ id, set_id ] = useState('');
    const [ name, set_name ] = useState('');
    const [ value, set_value ] = useState('');
    const [ value_type, set_value_type ] = useState('');
    const [ value_options, set_value_options ] = useState('');
    const [ category_type_map, set_category_type_map ] = useState({});

    const [categories, set_categories] = useState([]);
    const [sub_categories, set_sub_categories] = useState([]);
	const [groups, set_groups] = useState([]);
	const [skus, set_skus] = useState([]);

    const [selected_category, set_selected_category] = useState();
    const [selected_sub_category, set_selected_sub_category] = useState();
	const [selected_group, set_selected_group] = useState();
	const [selected_sku, set_selected_sku] = useState();
    const [selected_division, set_selected_division] = useState();

    const set_categories_fn = (division_id) => {
        let division = all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.find(o => o.id == division_id):''
        set_categories(division ? division.sku_category : [])

        set_sub_categories([]);
        set_groups([])
        set_skus([])
    }

    const set_sub_categories_fn = (category_id) => {
        let category = categories && categories.length ? categories.find(o => o.id == category_id):''
        set_sub_categories(category ? category.sku_sub_category : [])

        set_groups([])
        set_skus([])
    }

    const get_groups = async(subcat_id) => {
		if(subcat_id){
            try{
                set_page_loader({
                    show:true,
                    text:'fetching groups...'
                })
                let body = { sku_sub_category_id:subcat_id}
                if(org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
                let resp = await general_fetch({url:'inventory/get_groups',  body})
                set_groups(resp)
                set_skus([])
                set_page_loader({
                    show:false
                })
            }catch(err){
                console.log(err)
            }
        }else{
            set_groups([])
        }
	}

	const set_skus_fn = (group_id) => {
		let group = groups && groups.length ? groups.find(o => o.id === group_id) : ''
        // console.log('skus set', group)
		set_skus(group && group.sku ? group.sku : [])
	}

    const get_eligible_categories = () => {
        if(!(categories && categories.length)){
            return []
        }

        let eligible_categories = categories

        debug_log("eligible categories preference restriction", preference_category_restriction, preference_full_name);
        // preference_category_restriction to be defined in coreLib JSONs
        if(preference_category_restriction[preference_full_name] && preference_category_restriction[preference_full_name].supported_category_types && preference_category_restriction[preference_full_name].supported_category_types.length){
            eligible_categories = categories.filter(cat => preference_category_restriction[preference_full_name].supported_category_types.includes(cat.sku_category_type_id))
        }

        debug_log("eligible categories for a given preference", eligible_categories, categories);

        return eligible_categories;
    }

    const get_eligible_divisions = () => {

        let eligible_divisions = new Set();

        // preference_category_restriction to be defined in coreLib JSONs
        if(preference_category_restriction[preference_full_name] && preference_category_restriction[preference_full_name].supported_category_types && preference_category_restriction[preference_full_name].supported_category_types.length){
            let temp = preference_category_restriction[preference_full_name].supported_category_types.map(x => {
                let y = x.split('_')
                eligible_divisions.add(y[y.length - 1])
            })
        }

        debug_log("eligible divisions for a given preference", eligible_divisions);

        return Array.from(eligible_divisions);
    }


    useEffect(() => {
        if(open && leaf_data && leaf_data.value_type == 'sku_id'){
            let sku_id
            // if(org_bu_view === 'org'){
            //     sku_id = leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master']
            // }else{
            //     sku_id = leaf_data.value['business_unit'] && leaf_data.value['business_unit'][user.current_business_unit_id] ? leaf_data.value['business_unit'][user.current_business_unit_id] :leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master']
            // }
            sku_id = get_top_level_pref_value(leaf_data.value)
            if(sku_data_json[sku_id]){
                set_categories_fn(sku_data_json[sku_id].sku_division_id)
                // set_sub_categories_fn(sku_data_json[sku_id].sku_category_id)
                
                get_groups(sku_data_json[sku_id].sku_sub_category_id)
                
                // set_skus_fn(sku_id)
                

                set_selected_division(sku_data_json[sku_id].sku_division_id)
                set_selected_category(sku_data_json[sku_id].sku_category_id)
                set_selected_sub_category(sku_data_json[sku_id].sku_sub_category_id)
                set_selected_group(sku_data_json[sku_id].sku_group_id)
                set_selected_sku(sku_id)
            }
        }
    }, [sku_data_json, leaf_data, open]);

    useEffect(() => {
        if(categories && categories.length){
            set_sub_categories_fn(selected_category)
        }
    }, [categories, selected_category]);

    useEffect(() => {
        if(groups && groups.length){
            set_skus_fn(selected_group)
        }
    }, [groups, selected_group]);

    useEffect(() => {
        if (category_types && category_types.length) {
            set_category_type_map(category_types.reduce((final, elem) => ({...final, [elem.id]: elem }), {}));
        }
    }, [ category_types ]);

    useEffect(() => {
        if(id){
            if(leaf_data.value_type == 'sku_id'){
                value_json[id] = selected_sku
            }else if (leaf_data.value_type == 'list') {
                value_json[id] = (JSON.stringify(value.map(x => x.display_name)));
            } else if (leaf_data.value_type == 'sku_category_type_id') {
                value_json[id] = (JSON.stringify(value.map(x => x.id)));
            } else if (leaf_data.value_type == 'boolean') {
                value_json[id] = (value == "1" || value == "0" || value == true || value == false) ? ((value == "1" || value == true) ? "true" : "false") : value;
            }
            else{
                value_json[id] = value
            }
            // console.log("value json preferences 2", value_json)
        }
        if(set_value_copy){
            if(leaf_data.value_type == 'sku_id'){
                set_value_copy(selected_sku)
            }else if (leaf_data.value_type == 'list' && value && value.length) {
                set_value_copy(JSON.stringify(value.map(x => x.display_name)));
            } else if (leaf_data.value_type == 'sku_category_type_id' && value && value.length) {
                set_value_copy(JSON.stringify(value.map(x => x.id)));
            }else{
                set_value_copy(value)
            }
        }
    }, [value, id, selected_sku]);
    
    useEffect(() => {
        if(leaf_data && category_type_map && Object.keys(category_type_map).length && open) {
            set_id(leaf_data.id);
            set_name(leaf_data.display_name);
            // console.log('leaf data', leaf_data)
            
            // if(leaf_data && leaf_data.value && leaf_data.value.store){
            //     set_unlinked_from_master(true)
            // }else{
            //     set_unlinked_from_master(false)
            // }

            let temp_value_options = leaf_data.value_options;
            let temp_value_options_map = {};

            if (leaf_data.value_type === 'sku_category_type_id') {
                // console.log('ssss33', leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master, JSON.parse(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => category_type_map[x]));
                // if(org_bu_view === 'org'){
                //     set_value(JSON.parse(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => category_type_map[x]));
                // }else{
                //     set_value(JSON.parse(leaf_data.value['business_unit'] && leaf_data.value['business_unit'][user.current_business_unit_id] ? leaf_data.value['business_unit'][user.current_business_unit_id] : leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => category_type_map[x]));
                // }
                set_value(JSON.parse(get_top_level_pref_value(leaf_data.value)).map(x => category_type_map[x]))
            } else if (leaf_data.value_type === 'list') {
                temp_value_options = temp_value_options.map(x => ({ id: generate_id(), display_name: x, name: (preference_full_name && overridden_option_name_key_list.includes(preference_full_name) ? cutlist_preference_display_name[preference_full_name][x] : x) }));
                temp_value_options_map = temp_value_options.reduce((final, elem) => ({...final, [elem.display_name]: elem }), {});
                // if(org_bu_view === 'org'){
                //     set_value(JSON.parse(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => temp_value_options_map[x]).filter(x => x));
                // }else{
                //     set_value(JSON.parse(leaf_data.value['business_unit'] && leaf_data.value['business_unit'][user.current_business_unit_id] ? leaf_data.value['business_unit'][user.current_business_unit_id] : leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => temp_value_options_map[x]).filter(x => x));
                // }
                set_value(JSON.parse(get_top_level_pref_value(leaf_data.value)).map(x => temp_value_options_map[x]).filter(x => x))
            }else if (leaf_data.value_type === 'boolean') {
                set_value([true, 'true', '1', 1].includes(get_top_level_pref_value(leaf_data.value)) ? 'true' : 'false')
            }else {
                // if(org_bu_view === 'org'){
                //     set_value(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master);
                // }else{
                //     set_value(leaf_data.value['business_unit'] && leaf_data.value['business_unit'][user.current_business_unit_id] ? leaf_data.value['business_unit'][user.current_business_unit_id] : leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master);
                // }
                set_value(get_top_level_pref_value(leaf_data.value))
            }
            set_value_type(leaf_data.value_type);
            if (leaf_data.value_type === 'list') {
                set_value_options(temp_value_options);
            } else {
                set_value_options(leaf_data?.value_options ?? []);
            }
        }
    }, [ leaf_data, category_type_map, open ]);

    return(
        <div {...props}>
        {
            value_type == 'string' && name == 'all_available_tools' ? (
                <>
                <Row>
                    <Col>
                        {convert_to_readable(name)}
                    </Col>
                </Row>
                    {/* <Row style={{marginBottom:'20px'}}>
                    <Col>
                        <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold'}}>
                            {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                        </span>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                        <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable('List')}</div>
                    </Col>
                </Row> */}
                    {/* <hr/> */}
                    {/* <Link_sync_buttons/> */}
                    <Row>
                        {/* <Col style={{flex:'5'}}>
                        <input type="text" className="form-control" placeholder="Value" value={value} onChange={(e) => set_value(e.target.value)}/>
                    </Col>
                    <Col style={{flex:'1'}} className='flex_property'>
                    {
                        leaf_data && leaf_data.max_level == 'master'?'':
                        <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                    }
                    </Col> */}
                        <Col>
                            <KeyValuePairs pairs={JSON.parse(value)} setPairs={(pairs) => { set_value(JSON.stringify(pairs)) }} key_pair='tool_name' value='tool_diameter' />
                        </Col>
                    </Row>
                    {/* <Row>
                    <Col style={{ flex: '1', justifyContent: 'end' }} className='flex_property'>
                        {
                            leaf_data && leaf_data.max_level == 'master' ? '' :
                                <Button disabled={JSON.parse(value).filter(x => x.tool_name == '').length} className='blue_button' style={{ margin: 0, height: '32px' }} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                        }
                    </Col> 
                </Row> */}
                </>
            ) : value_type == 'integer' || value_type == 'string' ? (
                <>
                    {/* <Row style={{ marginBottom: '20px' }}>
                        <Col>
                            <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold' }}>
                                {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                            </span>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                        </Col>
                    </Row> */}
                    {/* <hr/> */}
                    {/* <Link_sync_buttons/> */}
                    <Row>
                        <Col style={{flex:'1'}} className='flex_property'>
                            {convert_to_readable(name)}
                        </Col>
                        <Col style={{ flex: '5' }}>
                            <input id={`input_${preference_full_name}`} type={value_type === "integer" ? "number" : "text"} className="form-control" placeholder="Value" value={value} onChange={(e) => set_value(e.target.value)} />
                        </Col>
                        {/* <Col style={{flex:'1'}} className='flex_property'>
                    {
                        leaf_data && leaf_data.max_level == 'master'?'':
                        <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                    }
                    </Col> */}
                    </Row>
                </>
            ) : (value_type == 'enum' || value_type == 'boolean' ? (
                <>
                    {/* <Row style={{ marginBottom: '20px' }}>
                        <Col>
                            <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold' }}>
                                {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                            </span>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                        </Col>
                    </Row> */}
                    {/* <hr/> */}
                    {/* <Link_sync_buttons/> */}
                    <Row>
                        <Col style={{flex:'1'}} className='flex_property'>
                            {convert_to_readable(name)}
                        </Col>
                        <Col style={{ flex: '5' }}>
                            <select id={`input_${preference_full_name}`} className="form-control" value={value} onChange={(e) => set_value(e.target.value)}>
                                {value_type == 'enum' ? value_options.map((option, index) => (
                                    <option key={index} value={option}>{option}</option>
                                )) : ['true', 'false'].map((option, index) => (
                                    <option key={index} value={option}>{option}</option>
                                ))}
                            </select>
                        </Col>
                        {/* <Col style={{flex:'1'}} className='flex_property'>
                    {
                        leaf_data && leaf_data.max_level == 'master'?'':
                        <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                    }
                    </Col> */}
                    </Row>
                </>
            ) : (value_type == 'list' || value_type == 'sku_category_type_id' ? (
                <div style={{ overflowY: 'visible' }}>
                    {/* <Row style={{ marginBottom: '20px' }}>
                        <Col>
                            <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold' }}>
                                {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                            </span>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                        </Col>
                    </Row> */}
                    {/* <hr/> */}
                    {/* {org_bu_view === 'org' ? <Link_sync_buttons/> : ''} */}
                    <Row style={{ paddingBottom: 50, overflowY: 'visible' }}>
                        <Col xs={2}>
                            {convert_to_readable(name)}
                        </Col>
                        <Col xs={10}>
                            <Multiselect
                                key={'select_value' + id}
                                style={{ backgroundColor: 'white', width: '100%' }}
                                options={value_type == 'list' ? value_options : name === 'highlight_in_pricing_quotation' ? category_types.filter(x => x.sku_division_id === 'hardware' || x.sku_division_id === 'accessory') : category_types}
                                selectedValues={value ? value : []}
                                onSelect={x => { set_value(x); }}
                                onRemove={set_value}
                                displayValue={value_type == 'list' ? 'name' : 'display_name'}
                                closeOnSelect={false}
                                avoidHighlightFirstOption={true}
                                showCheckbox
                                id={`input_${preference_full_name}`}
                            />
                        </Col>
                        {/* <Col xs={2} className='flex_property'>
                    {
                        leaf_data && leaf_data.max_level == 'master'?'':
                        <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                    }
                    </Col> */}
                    </Row>
                </div>
            ) 
            : 
            (value_type === 'sku_id' ? (
                <>
                    {/* <Row style={{ marginBottom: '20px' }}>
                        <Col>
                            <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold' }}>
                                {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                            </span>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {'SKU'}</div>
                        </Col>
                    </Row> */}
                    {/* {org_bu_view === 'org' ? <Link_sync_buttons/> : ''} */}
                    <Row>
                        <Col>
                            {convert_to_readable(name)}
                        </Col>
                    </Row>
                    <div style={{ margin: '24px 0px 4px 0px', fontSize: '14px' }}>
                        <div className='flex_property' style={{}}>
                            <div style={{ flex: '3' }}>Division</div>
                            <div style={{ flex: '3' }}>Category</div>
                            <div style={{ flex: '3' }}>Sub Category</div>
                            <div style={{ flex: '3' }}>Group</div>
                            <div style={{ flex: '3' }}>Sku</div>
                            {/* <div style={{ flex: '2' }}></div> */}
                        </div>
                        <div className='flex_property' style={{}}>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '12px', width: '100%' }}>
                                <div style={{ flex: '3' }}>
                                    <Input id={`input_${preference_full_name}_division`} type='select' value={selected_division} onChange={(e) => { set_selected_division(e.target.value); set_categories_fn(e.target.value); set_selected_category(''); set_selected_sub_category(''); set_selected_group(''); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                        <option value=''>Select Division</option>
                                        {
                                            get_eligible_divisions().map((division, idx) => (
                                                <option value={division}>{convert_to_readable(division)}</option>
                                            ))
                                        }
                                    </Input>
                                </div>
                                <div style={{ flex: '3' }}>
                                    <Input id={`input_${preference_full_name}_category`} type='select' value={selected_category} onChange={(e) => { set_selected_category(e.target.value);/*set_sub_categories_fn(e.target.value);*/ set_selected_sub_category(''); set_selected_group(''); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                        <option value=''>Select Category</option>
                                        {
                                            get_eligible_categories().map((single_category, idx) => (
                                                <option value={single_category.id}>{single_category.name}</option>
                                            ))
                                        }
                                    </Input>
                                </div>
                                <div style={{ flex: '3' }}>
                                    <Input id={`input_${preference_full_name}_sub_category`} type='select' value={selected_sub_category} onChange={(e) => { set_selected_sub_category(e.target.value); get_groups(e.target.value); set_selected_group(''); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                        <option value=''>Select Sub Category</option>
                                        {
                                            sub_categories && sub_categories.length ? sub_categories.map((single_sub_category, idx) => (
                                                <option value={single_sub_category.id}>{single_sub_category.name}</option>
                                            )) : ""
                                        }
                                    </Input>
                                </div>
                                <div style={{ flex: '3' }}>
                                    <Input id={`input_${preference_full_name}_group`} type='select' value={selected_group} onChange={(e) => { set_selected_group(e.target.value); /*set_skus_fn(e.target.value);*/ set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                        <option value=''>Select Group</option>
                                        {
                                            groups && groups.length ? groups.map((single_group, idx) => (
                                                <option value={single_group.id}>{single_group.name}</option>
                                            )) : ""
                                        }
                                    </Input>
                                </div>
                                <div style={{ flex: '3' }}>
                                    <Input id={`input_${preference_full_name}_sku`} type='select' value={selected_sku} onChange={(e) => set_selected_sku(e.target.value)} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                        <option value=''>Select Sku</option>
                                        {
                                            skus && skus.length ? skus.map((single_sku, idx) => (
                                                <option value={single_sku.id}>{single_sku.name}</option>
                                            )) : ""
                                        }
                                    </Input>
                                </div>
                                {/* <div style={{flex:'2'}}>
                        {
                            leaf_data && leaf_data.max_level == 'master'?'':
                            <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                        }
                        </div> */}
                            </div>
                        </div>
                    </div>
                </>
            ) 
            : '')
            ))} 
        </div>
    )
}

const Leaf_Edit_Modal = ({ leaf_data, hierarchy, open, handle_close, fetch_all_level_preferences, category_types, sku_data_json, all_division_tree_data, set_page_loader, link_store_level_to_master, resync_store_level_to_master, out_of_sync_preferences, preference_full_name }) => {

    const [ id, set_id ] = useState('');
    const [ name, set_name ] = useState('');
    const [ value, set_value ] = useState('');
    const [ value_type, set_value_type ] = useState('');
    const [ value_options, set_value_options ] = useState('');
    const [ category_type_map, set_category_type_map ] = useState({});
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)
    const [categories, set_categories] = useState([]);
    const [sub_categories, set_sub_categories] = useState([]);
	const [groups, set_groups] = useState([]);
	const [skus, set_skus] = useState([]);
    const [selected_category, set_selected_category] = useState();
    const [selected_sub_category, set_selected_sub_category] = useState();
	const [selected_group, set_selected_group] = useState();
	const [selected_sku, set_selected_sku] = useState();
    const [selected_division, set_selected_division] = useState();
    const [unlinked_from_master, set_unlinked_from_master] = useState();
    const [value_json, set_value_json] = useState({});
    const alert = useAlert()

    const is_dependency_visible = (dependent_id) => {
        return !conditional_visibility_based_on_dependent_key_value[dependent_id] || conditional_visibility_based_on_dependent_key_value[dependent_id] == value
    }


    const handle_submit = async () => {
        try {
            // let value_temp = value;
            // if (value_type == 'list') {
            //     value_temp = JSON.stringify(value.map(x => x.display_name));
            // } else if (value_type == 'sku_category_type_id') {
            //     value_temp = JSON.stringify(value.map(x => x.id));
            // }else if(value_type == 'sku_id'){
            //     if(selected_division && selected_category && selected_sub_category && selected_group && selected_sku){
            //         value_temp = selected_sku
            //     }else{
            //         alert.error('Please select all the fields')
            //         return
            //     }
            // }
            set_page_loader({
                show:true,
                text: 'Updating preference...'
            })
            let resp 

            if(org_bu_view === 'bu'){
                resp = await general_fetch({
                    url: 'designing_preference/set_business_unit_preference',
                    body: { design_preference_id_and_values: Object.keys(value_json).map(x => {return{designing_preference_id: x, value: value_json[x]}}).filter(x => x.designing_preference_id == id || is_dependency_visible(preference_id_to_full_name[x.designing_preference_id])), business_unit_id: user.current_business_unit_id }
                }); 
            }else if(org_bu_view == 'org'){
                resp = await general_fetch({
                    url: 'designing_preference/set_store_preference',
                    body: { design_preference_id_and_values: Object.keys(value_json).map(x => {return{designing_preference_id: x, value: value_json[x]}}).filter(x => x.designing_preference_id == id || is_dependency_visible(preference_id_to_full_name[x.designing_preference_id])) }
                    // body: { design_preference_id_and_values: [{ designing_preference_id: id, value: value_temp }] }
                }); 
            }    
            set_page_loader({show:false})
            if(unlinked_from_master){
                alert.success('Preference updated successfully !')
            }else{
                alert.success('Preference updated successfully and unlinked from Infurnia default !')
            }
            fetch_all_level_preferences();
            handle_close()
            // handle_close();
            // console.log(resp);
        } catch(err) {
            console.log(err);
        }
    }

    const set_categories_fn = (division_id) => {
        let division = all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.find(o => o.id == division_id):''
        set_categories(division ? division.sku_category : [])
    }

    const set_sub_categories_fn = (category_id) => {
        let category = categories && categories.length ? categories.find(o => o.id == category_id):''
        set_sub_categories(category ? category.sku_sub_category : [])
    }

    const get_groups = async(subcat_id) => {
		if(subcat_id){
            try{
                set_page_loader({
                    show:true,
                    text:'fetching groups...'
                })
                let body = { sku_sub_category_id:subcat_id}
                if(org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
                let resp = await general_fetch({url:'inventory/get_groups',  body})
                set_groups(resp)
                set_page_loader({
                    show:false
                })
            }catch(err){
                console.log(err)
            }
        }
	}

	const set_skus_fn = (group_id) => {
		let group = groups && groups.length ? groups.find(o => o.id === group_id) : ''
        console.log('skus set', group)
		set_skus(group && group.sku ? group.sku : [])
	}


    useEffect(() => {
        if(open && leaf_data.value_type == 'sku_id' && sku_data_json && sku_data_json[leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master']]){
            let sku_id = leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master']
            
            set_categories_fn(sku_data_json[sku_id].sku_division_id)

            
            // set_sub_categories_fn(sku_data_json[sku_id].sku_category_id)
            
            get_groups(sku_data_json[sku_id].sku_sub_category_id)
            
            // set_skus_fn(sku_id)
            

            set_selected_division(sku_data_json[sku_id].sku_division_id)
            set_selected_category(sku_data_json[sku_id].sku_category_id)
            set_selected_sub_category(sku_data_json[sku_id].sku_sub_category_id)
            set_selected_group(sku_data_json[sku_id].sku_group_id)
            set_selected_sku(sku_id)
        }
    }, [sku_data_json, leaf_data, open]);

    useEffect(() => {
        if(categories && categories.length && selected_category){
            set_sub_categories_fn(selected_category)
        }
    }, [categories, selected_category]);

    useEffect(() => {
        if(groups && groups.length && selected_group){
            set_skus_fn(selected_group)
        }
    }, [groups, selected_group]);


    useEffect(() => {
        console.log('ssss')
        if(leaf_data && category_type_map && Object.keys(category_type_map).length && open) {
            console.log('ssss2', unlinked_from_master, leaf_data);
            set_id(leaf_data.id);
            set_name(leaf_data.display_name);
            
            if(leaf_data && leaf_data.value && leaf_data.value.store !== undefined){
                set_unlinked_from_master(true)
            }else{
                set_unlinked_from_master(false)
            }

            // let temp_value_options = leaf_data.value_options;
            // let temp_value_options_map = {};

            // if (leaf_data.value_type === 'sku_category_type_id') {
            //     set_value(JSON.parse(get_top_level_pref_value(leaf_data.value)).map(x => category_type_map[x]))
            // } else if (leaf_data.value_type === 'list') {
            //     temp_value_options = temp_value_options.map(x => ({ id: generate_id(), display_name: x, name: (preference_full_name && overridden_option_name_key_list.includes(preference_full_name) ? cutlist_preference_display_name[preference_full_name][x] : x) }));
            //     temp_value_options_map = temp_value_options.reduce((final, elem) => ({...final, [elem.display_name]: elem }), {});
                
            //     set_value(JSON.parse(get_top_level_pref_value(leaf_data.value)).map(x => temp_value_options_map[x]).filter(x => x))
            // } else if (leaf_data.value_type === 'boolean') {
            //     set_value([true, 'true', '1', 1].includes(get_top_level_pref_value(leaf_data.value)) ? 'true' : 'false')
            // } else {
            //     set_value(get_top_level_pref_value(leaf_data.value))
            // }

            set_value_type(leaf_data.value_type);

            // if (leaf_data.value_type === 'list') {
            //     set_value_options(temp_value_options);
            // } else {
            //     set_value_options(leaf_data?.value_options ?? []);
            // }
        }
    }, [ leaf_data, category_type_map, open ]);


    useEffect(() => {
        if (category_types && category_types.length) {
            set_category_type_map(category_types.reduce((final, elem) => ({...final, [elem.id]: elem }), {}));
        }
    }, [ category_types ]);

    const Link_sync_buttons = () => (
        <div className='flex_property' style={{marginBottom:'20px'}}>
        {
            id && out_of_sync_preferences && out_of_sync_preferences.length && out_of_sync_preferences.find(o => o.designing_preference_id == id)?
            <div onClick={() => resync_store_level_to_master(leaf_data.id)} className='flex_property' style={{backgroundColor:'white', border:'1px solid gray', padding:'4px 4px 4px 8px', borderRadius:'4px', fontSize:'14px', cursor:'pointer', width:'200px', marginRight:'12px'}}>
                <img style={{width:'20px', height:'24px', cursor:'pointer', marginRight:'10px'}} src={'/assets/img/icons/sync_icon_black.svg'}/>
                <div>Sync with Infurnia default</div>
            </div>:''
        }
        {   
            leaf_data && leaf_data.value && leaf_data.value.store ? 
            <div onClick={() => link_store_level_to_master(leaf_data.id)} className='flex_property' style={{backgroundColor:'white', border:'1px solid gray', padding:'4px 4px 4px 8px', borderRadius:'4px', fontSize:'14px', cursor:'pointer', width:'198px'}}>
                <img style={{width:'20px', height:'24px', cursor:'pointer', marginRight:'10px'}} src={'/assets/img/icons/link_icon_black.svg'}/>
                <div>Link with Infurnia default</div>
            </div>:''
        }
        </div>
    )

    // window.print_value_json_preferences = () => {
    //     console.log("value json preferences", value_json)
    // }

    
    return (
        <div>
		  	<Modal className="Margin_64px_top" isOpen={open} size={value_type == 'list' || value_type == 'sku_category_type_id' || value_type == 'sku_id' ? "lg" : "lg"} toggle={handle_close}>
				<Card>
					<CardHeader className='global_modal_header'>
						<span>
							Edit Preference
						</span>
						<i style={{cursor:'pointer'}} onClick={handle_close} className='fa fa-times'/>
					</CardHeader>
					<CardBody className='global_modal_body' style={{ height: 'auto', overflow: 'auto',  maxHeight: '600px'}}>
                        <Row style={{ marginBottom: '20px' }}>
                            <Col>
                                <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold' }}>
                                    {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                                </span>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type == 'string' && name == 'all_available_tools' ? 'List' : value_type == 'sku_id' ? 'SKU' : convert_to_readable(value_type))}</div>
                            </Col>
                        </Row>
                        {
                            // value_type == 'string' && name == 'all_available_tools' ? (
                            //     <>
                            //         {/* <Row style={{marginBottom:'20px'}}>
                            //         <Col>
                            //             <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold'}}>
                            //                 {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                            //             </span>
                            //         </Col>
                            //         <Col style={{ textAlign: 'right' }}>
                            //             <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable('List')}</div>
                            //         </Col>
                            //     </Row> */}
                            //         {/* <hr/> */}
                            //         {/* <Link_sync_buttons/> */}
                            //         <Row>
                            //             {/* <Col style={{flex:'5'}}>
                            //             <input type="text" className="form-control" placeholder="Value" value={value} onChange={(e) => set_value(e.target.value)}/>
                            //         </Col>
                            //         <Col style={{flex:'1'}} className='flex_property'>
                            //         {
                            //             leaf_data && leaf_data.max_level == 'master'?'':
                            //             <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                            //         }
                            //         </Col> */}
                            //             <Col>
                            //                 <KeyValuePairs pairs={JSON.parse(value)} setPairs={(pairs) => { set_value(JSON.stringify(pairs)) }} key_pair='tool_name' value='tool_diameter' />
                            //             </Col>
                            //         </Row>
                            //         {/* <Row>
                            //         <Col style={{ flex: '1', justifyContent: 'end' }} className='flex_property'>
                            //             {
                            //                 leaf_data && leaf_data.max_level == 'master' ? '' :
                            //                     <Button disabled={JSON.parse(value).filter(x => x.tool_name == '').length} className='blue_button' style={{ margin: 0, height: '32px' }} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                            //             }
                            //         </Col> 
                            //     </Row> */}
                            //     </>
                            // ) : value_type == 'integer' || value_type == 'string' ? (
                            //     <>
                            //         {/* <Row style={{ marginBottom: '20px' }}>
                            //             <Col>
                            //                 <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold' }}>
                            //                     {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                            //                 </span>
                            //             </Col>
                            //             <Col style={{ textAlign: 'right' }}>
                            //                 <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                            //             </Col>
                            //         </Row> */}
                            //         {/* <hr/> */}
                            //         {/* <Link_sync_buttons/> */}
                            //         <Row>
                            //             <Col style={{ flex: '5' }}>
                            //                 <input type="text" className="form-control" placeholder="Value" value={value} onChange={(e) => set_value(e.target.value)} />
                            //             </Col>
                            //             {/* <Col style={{flex:'1'}} className='flex_property'>
                            //         {
                            //             leaf_data && leaf_data.max_level == 'master'?'':
                            //             <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                            //         }
                            //         </Col> */}
                            //         </Row>
                            //     </>
                            // ) : (value_type == 'enum' || value_type == 'boolean' ? (
                            //     <>
                            //         {/* <Row style={{ marginBottom: '20px' }}>
                            //             <Col>
                            //                 <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold' }}>
                            //                     {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                            //                 </span>
                            //             </Col>
                            //             <Col style={{ textAlign: 'right' }}>
                            //                 <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                            //             </Col>
                            //         </Row> */}
                            //         {/* <hr/> */}
                            //         {/* <Link_sync_buttons/> */}
                            //         <Row>
                            //             <Col style={{ flex: '5' }}>
                            //                 <select className="form-control" value={value} onChange={(e) => set_value(e.target.value)}>
                            //                     {value_type == 'enum' ? value_options.map((option, index) => (
                            //                         <option key={index} value={option}>{option}</option>
                            //                     )) : ['true', 'false'].map((option, index) => (
                            //                         <option key={index} value={option}>{option}</option>
                            //                     ))}
                            //                 </select>
                            //             </Col>
                            //             {/* <Col style={{flex:'1'}} className='flex_property'>
                            //         {
                            //             leaf_data && leaf_data.max_level == 'master'?'':
                            //             <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                            //         }
                            //         </Col> */}
                            //         </Row>
                            //     </>
                            // ) : (value_type == 'list' || value_type == 'sku_category_type_id' ? (
                            //     <div style={{ overflowY: 'visible' }}>
                            //         {/* <Row style={{ marginBottom: '20px' }}>
                            //             <Col>
                            //                 <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold' }}>
                            //                     {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                            //                 </span>
                            //             </Col>
                            //             <Col style={{ textAlign: 'right' }}>
                            //                 <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                            //             </Col>
                            //         </Row> */}
                            //         {/* <hr/> */}
                            //         {/* {org_bu_view === 'org' ? <Link_sync_buttons/> : ''} */}
                            //         <Row style={{ paddingBottom: 50, overflowY: 'visible' }}>
                            //             <Col xs={10}>
                            //                 <Multiselect
                            //                     key={'select_value' + id}
                            //                     style={{ backgroundColor: 'white', width: '100%' }}
                            //                     options={value_type == 'list' ? value_options : name === 'highlight_in_pricing_quotation' ? category_types.filter(x => x.sku_division_id === 'hardware' || x.sku_division_id === 'accessory') : category_types}
                            //                     selectedValues={value ? value : []}
                            //                     onSelect={x => { console.log('setting selected value 000  >>>>', x); set_value(x); }}
                            //                     onRemove={set_value}
                            //                     displayValue='display_name'
                            //                     closeOnSelect={false}
                            //                     avoidHighlightFirstOption={true}
                            //                     showCheckbox
                            //                 />
                            //             </Col>
                            //             {/* <Col xs={2} className='flex_property'>
                            //         {
                            //             leaf_data && leaf_data.max_level == 'master'?'':
                            //             <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                            //         }
                            //         </Col> */}
                            //         </Row>
                            //     </div>
                            // ) : (value_type === 'sku_id' ? (
                            //     <>
                            //         {/* <Row style={{ marginBottom: '20px' }}>
                            //             <Col>
                            //                 <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold' }}>
                            //                     {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                            //                 </span>
                            //             </Col>
                            //             <Col style={{ textAlign: 'right' }}>
                            //                 <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {'SKU'}</div>
                            //             </Col>
                            //         </Row> */}
                            //         {/* {org_bu_view === 'org' ? <Link_sync_buttons/> : ''} */}
                            //         <div style={{ margin: '24px 0px 4px 0px', fontSize: '14px' }}>
                            //             <div className='flex_property' style={{}}>
                            //                 <div style={{ flex: '3' }}>Division</div>
                            //                 <div style={{ flex: '3' }}>Category</div>
                            //                 <div style={{ flex: '3' }}>Sub Category</div>
                            //                 <div style={{ flex: '3' }}>Group</div>
                            //                 <div style={{ flex: '3' }}>Sku</div>
                            //                 <div style={{ flex: '2' }}></div>
                            //             </div>
                            //             <div className='flex_property' style={{}}>
                            //                 <div style={{ display: 'flex', alignItems: 'center', marginTop: '12px', width: '100%' }}>
                            //                     <div style={{ flex: '3' }}>
                            //                         <Input type='select' value={selected_division} onChange={(e) => { set_selected_division(e.target.value); set_categories_fn(e.target.value); set_selected_category(''); set_selected_sub_category(''); set_selected_group(''); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                            //                             <option value=''>Select Division</option>
                            //                             {
                            //                                 all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.map((division, idx) => (
                            //                                     <option value={division.id}>{convert_to_readable(division.id)}</option>
                            //                                 )) : ''
                            //                             }
                            //                         </Input>
                            //                     </div>
                            //                     <div style={{ flex: '3' }}>
                            //                         <Input type='select' value={selected_category} onChange={(e) => { set_selected_category(e.target.value);/*set_sub_categories_fn(e.target.value);*/ set_selected_sub_category(''); set_selected_group(''); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                            //                             <option value=''>Select Category</option>
                            //                             {
                            //                                 categories && categories.length ? categories.map((single_category, idx) => (
                            //                                     <option value={single_category.id}>{single_category.name}</option>
                            //                                 )) : ""
                            //                             }
                            //                         </Input>
                            //                     </div>
                            //                     <div style={{ flex: '3' }}>
                            //                         <Input type='select' value={selected_sub_category} onChange={(e) => { set_selected_sub_category(e.target.value); get_groups(e.target.value); set_selected_group(''); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                            //                             <option value=''>Select Sub Category</option>
                            //                             {
                            //                                 sub_categories && sub_categories.length ? sub_categories.map((single_sub_category, idx) => (
                            //                                     <option value={single_sub_category.id}>{single_sub_category.name}</option>
                            //                                 )) : ""
                            //                             }
                            //                         </Input>
                            //                     </div>
                            //                     <div style={{ flex: '3' }}>
                            //                         <Input type='select' value={selected_group} onChange={(e) => { set_selected_group(e.target.value); /*set_skus_fn(e.target.value);*/ set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                            //                             <option value=''>Select Group</option>
                            //                             {
                            //                                 groups && groups.length ? groups.map((single_group, idx) => (
                            //                                     <option value={single_group.id}>{single_group.name}</option>
                            //                                 )) : ""
                            //                             }
                            //                         </Input>
                            //                     </div>
                            //                     <div style={{ flex: '3' }}>
                            //                         <Input type='select' value={selected_sku} onChange={(e) => set_selected_sku(e.target.value)} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                            //                             <option value=''>Select Sku</option>
                            //                             {
                            //                                 skus && skus.length ? skus.map((single_sku, idx) => (
                            //                                     <option value={single_sku.id}>{single_sku.name}</option>
                            //                                 )) : ""
                            //                             }
                            //                         </Input>
                            //                     </div>
                            //                     {/* <div style={{flex:'2'}}>
                            //             {
                            //                 leaf_data && leaf_data.max_level == 'master'?'':
                            //                 <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                            //             }
                            //             </div> */}
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     </>
                            // ) : '')))
                            } 
                            
                            <LeafEditSingleRow className='mb-3' leaf_data={leaf_data} category_types={category_types} all_division_tree_data={all_division_tree_data} value_json={value_json} sku_data_json={sku_data_json} set_page_loader={set_page_loader} user={user} org_bu_view={org_bu_view} set_value_copy={set_value} open={open} preference_full_name={preference_full_name}/>
                            {
                                dependent_keys_json && dependent_keys_json[preference_full_name] ? dependent_keys_json[preference_full_name].map((dependent_id, idx) => (
                                    is_dependency_visible(dependent_id) ? <LeafEditSingleRow className='mb-3' leaf_data={dependent_keys_value_json[dependent_id]} key={idx} category_types={category_types} all_division_tree_data={all_division_tree_data} value_json={value_json} sku_data_json={sku_data_json} set_page_loader={set_page_loader} user={user} org_bu_view={org_bu_view} open={open} preference_full_name={dependent_id}/> : ''
                                ))
                                :''
                            }
                        <Row className='mt-3'>
                            <Col style={{ flex: '1', justifyContent: 'end' }} className='flex_property'>
                                {
                                    leaf_data && leaf_data.max_level == 'master' ? '' :
                                        <Button disabled={value_type == 'string' && name == 'all_available_tools' && value && JSON.parse(value).filter(x => x.tool_name == '').length} className='blue_button' style={{ margin: 0, height: '32px' }} type="button" onClick={handle_submit}> {'Update'} </Button>
                                        // <Button disabled={value_type == 'string' && name == 'all_available_tools' && value && JSON.parse(value).filter(x => x.tool_name == '').length} className='blue_button' style={{ margin: 0, height: '32px' }} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                                }
                            </Col>
                        </Row>     
					</CardBody>
					<CardFooter className='global_modal_footer'>
						<Button className='white_button' style={{marginRight:'0px'}} type="button" onClick={handle_close}> Close </Button>
					</CardFooter>
				</Card>
			</Modal>
		</div>
    );
}
    

const traverse_json = (json, hierarchy) => {
    if (hierarchy.length == 0) {
        return json;
    }
    var key = hierarchy.shift();
    if (json[key]) {
        return traverse_json(json[key], hierarchy);
    }
    return null;
}

const wordsMatch = (stringA, stringB) => {
	// Split both strings into arrays of words
	stringA = stringA.toLowerCase()
	stringB = stringB.toLowerCase()
	// const wordsA = stringA.split(/\s+/);
	// const wordsB = stringB.split(/\s+/);
	
	// if(wordsB && wordsB.length && !wordsB[wordsB.length-1]) wordsB.splice(wordsB.length-1, 1)
	// // Create a Set for faster lookup
	// const wordsSet = new Set(wordsA);
  
	// // Check if any word in wordsB matches a word in wordsA
	// for (const wordB of wordsB) {
	//   if (!wordsSet.has(wordB)) {
	// 	return false; // Match found
	//   }
	// }
	
	// if(wordsB.length){
	// 	return true; // No match found
	// }

	if(stringA.includes(stringB)) return true
	return false
}


const determine_visibility = (json, hierarchy, visible, isCollapsed, search_string) => {
    if (hierarchy.length == 0) {
        Object.keys(json).map(key => {
            if (json[key] && json[key].value_type) {
                if(search_string){
                    if(!visible){
                        visible = json[key].value_type && valid_value_types.includes(json[key].value_type) && json[key].visible && wordsMatch(convert_to_readable(json[key].display_name), search_string) ? true : false;
                        if(wordsMatch(convert_to_readable(json[key].display_name), search_string)) isCollapsed = false;
                    }
                }else{
                    if(!visible) visible = json[key].value_type && valid_value_types.includes(json[key].value_type) && json[key].visible ? true : false;
                }
            }
            else if(!visible && json[key] && !json[key].value_type){
                if(search_string){
                    if(wordsMatch(convert_to_readable(key), search_string)){
                        visible = true;
                        isCollapsed = false;
                    }
                }
                if(!visible){
                    let { json:new_json, visible:new_visible, isCollapsed: new_is_collapsed } = determine_visibility(json[key], [], visible, isCollapsed, search_string);
                    visible = new_visible
                    if(!new_is_collapsed) isCollapsed = false
                }
            }
        });
        return {json, visible, isCollapsed};
    }
    let key = hierarchy.shift();
    if(search_string && hierarchy.length == 0){
        if(wordsMatch(convert_to_readable(key), search_string)){
            return {json: json[key], visible: true, isCollapsed: false}
        }
    }
    
    if(json[key]) {
        let { json:new_json, visible:new_visible, isCollapsed: new_is_collapsed } = determine_visibility(json[key], hierarchy, visible, isCollapsed, search_string);
        return { json: new_json, visible: new_visible, isCollapsed: new_is_collapsed };
    }
    return {visible, json, isCollapsed};
}

const Help_modal = ({open2, close_help_modal}) => {
    return (
        <Modal size='m' isOpen={open2} toggle={close_help_modal}>
            <IModalHeader toggle={close_help_modal}>Help</IModalHeader>
            <IModalBody>
                <div>
                    <div style={{paddingBottom: '5px', fontWeight: 'bold'}}>Syntax for Formula:</div>
                    <div style={{paddingBottom: '10px'}}><span style={{color: '#6A1B9A'}}>{"<Variable1>"}</span> <span style={{color: '#0000FF'}}>+</span>  <span style={{color: '#008080'}}>'constant'</span> <span style={{color: '#0000FF'}}>+</span>  <span style={{color: '#6A1B9A'}}>{"<Variable2>"}</span> + ...</div>
                    <div style={{marginBottom: '5px', paddingBottom: '15px'}}>Variables are Infurnia defined fields and can be selected from suggestions given when entering formula. Constants are user-defined values and should be enclosed in single or double quotes</div>
                    <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                        <div style={{flex: 1, alignSelf:'start', fontWeight: 'bold'}}>
                            Example: 
                        </div>
                        <div style={{paddingLeft: '10px'}}>
                            <div><span style={{color: '#6A1B9A'}}>core_material_sku_name</span> <span style={{color: '#0000FF'}}>+</span> <span style={{color: '#008080'}}>'_'</span> <span style={{color: '#0000FF'}}>+</span> </div> 
                            <div><span style={{color: '#6A1B9A'}}>internal_finish_sku_name</span> <span style={{color: '#0000FF'}}>+</span> <span style={{color: '#008080'}}>'_'</span> <span style={{color: '#0000FF'}}>+</span> <span style={{color: '#6A1B9A'}}>external_finish_model_no</span> <span style={{color: '#0000FF'}}>+</span> </div> 
                            <div><span style={{color: '#6A1B9A'}}>internal_finish_sku_name</span></div> 
                        </div>
                    </div>
                </div>
            </IModalBody>
            <IModalFooter>
                <Button className="blue_button" onClick={close_help_modal}>OK</Button>
            </IModalFooter>
        </Modal>
    )
}

const Single_item_for_cutlist_pref = ({item, input_array, set_input_array, set_page_loader, fields, fetch_all_level_preferences}) => {

	const [name_editing_mode, set_name_editing_mode] = useState(false);
    const [id, set_id] = useState('')
    const [mode, set_mode] = useState('')
    const [add_edit_custom_field_modal, set_add_edit_custom_field_modal] = useState(false)
    // const [set_formula, formula] = useState(false)
    // useEffect(() => {
    //     console.log("KPPP item", item);
    // }, [item])

	// TODO - name_editing_mode not used. Cleanup.
	useEffect(() => {
		if(name_editing_mode){
			let input_name  = document.getElementById('edit_cutlist_pref_name')
			if(input_name){
				input_name.focus()
				input_name.select(0, input_name.value.length)
			}
		}
	}, [name_editing_mode]);

	const checkbox_onchange = (item) => {
		// var cutlist_pref = JSON.parse(JSON.stringify(input_array));
		var field = input_array.find(o => o.id === item.id);

		if(field){
			field.visibility = !field.visibility;
		}
		set_input_array(JSON.parse(JSON.stringify(input_array)));
	}

	const rename_cutlist_pref = (name) => {
		// var cutlist_pref = JSON.parse(JSON.stringify(input_array));
		var field = input_array.find(o => o.id === item.id);

		if(field){
			field.custom_name = name;
		}
		set_input_array(JSON.parse(JSON.stringify(input_array)));
	}

    // useEffect(() =>{
    //     console.log("KP input_array", input_array.filter(item => item.hasOwnProperty('formula') || custom_field_ids_cutlist.includes(item.id)))
    // }, [input_array])

    return(
        <div style={{height:'35px', display:'flex', alignItems:'center', width:'100%'}}>
			<div style={{marginRight:'15px', display:'flex', alignItems:'center'}}><i style={{marginRight:'1px'}} className='fa fa-ellipsis-v'/><i className='fa fa-ellipsis-v'/></div>
			<div style={{display:'flex', alignItems:'center', width:'100%'}}>
				<div style={{textTransform:'capitalize', fontSize:'14px', flex:1, minWidth: '10px', flexWrap: 'wrap'}}>{item.name ? item.name : item.id}</div>
				<Input id={'edit_cutlist_pref_name' + item.id} spellCheck={false} className='focus_input mr-2 flex_property' style={{fontSize: '12px', height: '24px', flex: 1, minWidth: '10px'}} defaultValue={item.custom_name ? item.custom_name : (item.name ? item.name : item.id)} onBlur={(e) => { rename_cutlist_pref(e.target.value); set_name_editing_mode(false) }} onKeyDown={(e) => { if (e.key === 'Enter') {rename_cutlist_pref(e.target.value); set_name_editing_mode(false)} else if (e.key == 'Escape') { set_name_editing_mode(false) } }} />
				<div style={{display: 'flex', justifyContent: 'flex-end', flex: 1, alignItems: 'center'}}>
                   {item.hasOwnProperty('formula') || custom_field_ids_cutlist.includes(item.id) ? 
                <i style={{cursor:'pointer', color: "#1070CA" }} onClick={() => {set_id(item.id); set_mode('edit'); set_add_edit_custom_field_modal(true);}} className='fa fa-edit pl-2 pr-2 pt-2 pb-2 mr-1'/> : ''}
					<Switch 
					onChange={() => {checkbox_onchange(item)}} 
					checked={item.visibility}
					uncheckedIcon={false}
					checkedIcon={false}
					offColor='#e6e6e6'
					offHandleColor='#808080'
					onColor='#0066ff'
					onfHandleColor='#b3d1ff'
					height={15}
					width={30}	
					/>
				</div>
				{/* <Input checked={item.checked} onChange={() => {checkbox_onchange(item.id)}} type='checkbox' style={{cursor: 'pointer', position:'unset', margin:0, marginRight:'15px'}} /> */}
			</div>
            <Add_Edit_Custom_Field_Modal item={item} fields={fields} open1={add_edit_custom_field_modal} handle_close1={() => {set_mode(''); set_id(''); set_add_edit_custom_field_modal(false);}} mode={mode} id={id} set_page_loader={set_page_loader} custom_cutlist_fields_arr={input_array.filter(item => item.hasOwnProperty('formula') || custom_field_ids_cutlist.includes(item.id))} fetch_all_level_preferences={fetch_all_level_preferences} input_array={input_array} set_input_array={set_input_array}/>
		</div>
    )
}

const Add_Edit_Custom_Field_Modal = ({item, fields, open1, handle_close1, set_page_loader, mode = 'edit', custom_cutlist_fields_arr, id = '', fetch_all_level_preferences, input_array, set_input_array}) => {
    const [field_id, set_field_id] = useState('')
    const [display_name, set_display_name] = useState('')
    const [formula, set_formula] = useState('')
    const [custom_cutlist_fields, set_custom_cutlist_fields] = useState({})
    const [help_modal, set_help_modal] = useState(false)
    const [preventBlur, set_preventBlur] = useState({is_valid: false});
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)
    const alert = useAlert()
    const [display_suggestions, set_display_suggestions] = useState(false);

    useEffect(() => {
        let originalObject = {};
        let temp = JSON.parse(JSON.stringify(custom_cutlist_fields_arr))
        let garb1 = temp.map(obj => {
            let { id, ...rest } = obj;
            originalObject[id] = rest;
        });
        set_custom_cutlist_fields(originalObject)
    }, [custom_cutlist_fields_arr])

    const close_modal = () => {
        handle_close1()
        set_field_id('')
        set_display_name('')
        set_formula('')
    }

    const close_help_modal = () => {
        set_help_modal(false);
    }

    function isAlphabet(character) {
        const regex = /^[A-Za-z]$/
        return regex.test(character)
    }

    function isAlphanumeric(character) {
        const regex = /^[a-zA-Z0-9]$/
        return regex.test(character)
    }

    function isSpecialSymbol(character) {
        return character == '_' || character == ':' || character == '$'
    }
    
    const isValidVariableName = (name) => {
        if(name.trim().length == 0) 
            return false
        if(!(isAlphabet(name[0]) || isSpecialSymbol(name[0])))
            return false
        for(let i = 1; i < name.length; i++){
            if(!(isAlphanumeric(name[i]) || isSpecialSymbol(name[i])))
                return false
        }
        return true
    }

    function splitIgnoringQuotes(inputString) {
		const parts = [];
		let currentPart = '';
		let singleQuotes = false;
		for (let i = 0; i < inputString.length; i++) {
			const char = inputString[i];
			if (char === "'" || char === '"') {
					singleQuotes = !singleQuotes;
				currentPart += char;
			} else if (char === '+' && !singleQuotes) {
				parts.push(currentPart.trim());
				currentPart = '';
			} else {
				currentPart += char;
			}
		}
		parts.push(currentPart.trim());
		return parts;
	}

	const validateString = (exp) => {
		let cnt = 0;
		for(let i=0; i<exp.length; i++){
			if(exp[i] == "'" || exp[i] == '"')
				cnt++;
		}
		return cnt == 2;
	}


    const validate = (exp) => {
		if(exp == '' || exp.length == 0) return true
        let operands = splitIgnoringQuotes(exp)
        for(let i=0; i<operands.length; i++){
            let temp = operands[i].trim()
            if(temp.length > 1 && (temp[0] == '\'' || temp[0] == '\"') && (temp[temp.length - 1] == '\'' || temp[temp.length - 1] == '\"') && validateString(temp))
                continue;
            else if(isValidVariableName(temp) && custom_cutlist_variables.includes(temp))
                continue;
            return false;
        }
        return true
    }

    function replaceSingleQuotes(str) {
        return str.replace(/'/g, "\\'");
    }

    function replaceEscapedQuotes(str) {
        return str.replace(/\\'/g, "'");
    }
    function replaceDoubleQuotesWithSingleQuotes(str) {
        return str.replace(/"/g, "'");
    }

    const on_press_ok = () => {
		// var cutlist_pref = JSON.parse(JSON.stringify(input_array));
		var field = input_array.find(o => o.id === item.id);

		if(field){
            field.formula = replaceDoubleQuotesWithSingleQuotes(formula);
            field.custom_name = display_name;
		}
		set_input_array(JSON.parse(JSON.stringify(input_array)));
	}
    

    function requestBody() {
        return [{designing_preference_id: fields[id]['formula']['id'], value: replaceDoubleQuotesWithSingleQuotes(formula)}, {designing_preference_id: fields[id]['custom_name']['id'], value: display_name ? display_name : (id ? (custom_cutlist_fields[id]["custom_name"] ? custom_cutlist_fields[id]["custom_name"] : '') : id)}]
    }

    const submit_fields = async () => {
        try {
            // set_page_loader({
            //     show:true,
            //     text: 'Updating custom field...'
            // })

            // let resp 
            // // console.log("KP request body is", requestBody())
            // if(org_bu_view === 'bu'){
            //     resp = await general_fetch({
            //         url: 'designing_preference/set_business_unit_preference',
            //         body: {design_preference_id_and_values: requestBody(), business_unit_id: user.current_business_unit_id }
            //     }); 
            // }else if(org_bu_view == 'org'){
            //     resp = await general_fetch({
            //         url: 'designing_preference/set_store_preference',
            //         body: {design_preference_id_and_values: requestBody()}
            //     }); 
            // }    
            // set_page_loader({show:false})
            // fetch_all_level_preferences();
            on_press_ok();
            document.getElementById('edit_cutlist_pref_name' + id).value = display_name ? display_name : (id ? (custom_cutlist_fields[id]["custom_name"] ? custom_cutlist_fields[id]["custom_name"] : '') : id);
            handle_close1()
            // handle_close();
            // console.log(resp);
        } catch(err) {
            console.log(err);
        }
    }

    const handle_submit = async () => {
        if(!validate(formula)){
            alert.error('Formula is not valid')
            return
        }
        submit_fields(); 
        
        // console.log(requestBody())
    }

    useEffect(() => {
        if(open1){
            // console.log("KP espcape ", custom_cutlist_fields[id]["formula"], custom_cutlist_fields[id]["formula"])
            set_formula(id ? custom_cutlist_fields[id]["formula"] : '')
        }
    }, [open1])

    useEffect(() => {
        if(mode == 'edit' && custom_cutlist_fields){
            // set_display_name(id ? custom_cutlist_fields[id]["custom_name"] : id)
            // set_formula(id ? custom_cutlist_fields[id]["formula"] : '')
            // set_display_name(custom_cutlist_fields["custom_name"] ? custom_cutlist_fields["custom_name"] : (custom_cutlist_fields["name"] ? custom_cutlist_fields["name"] : id))
        }
        // console.log("KP custom_cutlist_fields", custom_cutlist_fields)
        // console.log("KP ID", id)
    }, [mode, custom_cutlist_fields])

    // useEffect(() => {
    //     // console.log("KP initial_fields", fields)
    // })

    // useEffect(() => {
    //     console.log("KP", formula, validate(formula))
    // }, [formula])

    const onMouseDownSuggestion = (event, suggestion) => {
		preventBlur.is_valid = true; 
		event.stopPropagation(); 
		handleSuggestionClick(suggestion)
        set_display_suggestions(false)
	}

    const handleSuggestionClick = (suggestion) => {
		if(document.getElementById('formula_input')){
			// document.getElementById('formula_input').value = value + suggestion
            set_formula(formula.substring(0, formula.length - getSuffix(formula).length) + suggestion);
		}
		// set_suggestions([]);
	};

    function getSuffix(str) {
        let suffix = '';
        for (let i = str.length - 1; i >= 0; i--) {
            const char = str[i];
            if (char === '+' || /\s/.test(char)) {
                break; // Stop traversing when encountering '+' or whitespace
            }
            suffix = char + suffix; // Prepend the character to the suffix
        }
        return suffix;
    }

    const inputOnBlur = (event) => {
		if (preventBlur.is_valid) {
			event.preventDefault(); 
			event.target.focus()
			const inputLength = event.target.value.length;
    		event.target.setSelectionRange(inputLength, inputLength);
			preventBlur.is_valid = false
            set_display_suggestions(true)
		} else {
            set_display_suggestions(false)
		}
	}

    const handleFocus = (event) => {
        if((formula == '' || formula.length==0))
            set_display_suggestions(true)
    }

    useEffect(() => {
        if(formula)
            set_display_suggestions(custom_cutlist_variables.filter(item => item.includes(getSuffix(formula)) && item != getSuffix(formula)).length > 0)
        // console.log("suffix", getSuffix(formula), custom_cutlist_variables, custom_cutlist_variables.filter(item => item.includes(getSuffix(formula)) && item != getSuffix(formula)).length > 0)
    }, [formula])

    useEffect(() => {
		if(open1)
			set_display_name(item.custom_name ? item.custom_name : item.name)
	}, [item.custom_name, open1])

    return (
        <Modal size='m' isOpen={open1} toggle={close_modal}>
            <IModalHeader toggle={close_modal}>Edit Custom Field</IModalHeader>
            <IModalBody>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '15px'}}>
                    <div>Default Name</div>
                    <Input disabled style={{opacity: '50%', maxWidth: '60%'}} type='text' value={id ? (custom_cutlist_fields[id]["name"] ? custom_cutlist_fields[id]["name"] : id) : id} />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '15px'}}>
                    <div>Display Name</div>
                    <Input style={{maxWidth: '60%'}} type='text' value={display_name} onChange={e => set_display_name(e.target.value)} />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative'}}>
                    <div>Formula</div>
                    <Input id='formula_input' style={{maxWidth: '60%', border: validate(formula)? '' : '1px solid red'}} placeholder='Enter a formula' type='text' value={id ? formula : ''} onChange={e => {set_formula(e.target.value)}} onBlur={inputOnBlur} onFocus={handleFocus} />
                    {custom_cutlist_variables && custom_cutlist_variables.filter(item => item.includes(getSuffix(formula)) && item != getSuffix(formula)).length > 0 && (
                    <div className="suggestion-list" id="suggestion-list" style={{display: display_suggestions ? '' : 'none', zIndex: 3, background: 'white'}}>
                        {custom_cutlist_variables.filter(item => item.includes(getSuffix(formula)) && item != getSuffix(formula)).map((suggestion, index) => (
                            <div className='single-suggestion' key={index} onMouseDown={(event) => {onMouseDownSuggestion(event, suggestion)}} /*onClick={() => {handleSuggestionClick(suggestion)}}*/>
                                {suggestion}
                            </div>
                        ))}
                    </div>
                )}
                    {/* <TextInput style={{maxWidth: '60%', border: validate(formula)? '' : '1px solid red'}} options={custom_cutlist_variables} trigger={["+"]} placeholder='Enter a formula' type='text' value={id ? formula : ''} onChange={e => set_formula(e.target.value)}/> */}
                </div>
            <Help_modal open2={help_modal} close_help_modal={close_help_modal}/>
            </IModalBody>
            <IModalFooter>
                <Button className="blue_button" onClick={() => {set_help_modal(true)}}>Help</Button>
                <Button className="blue_button" onClick={handle_submit}>OK</Button>
            </IModalFooter>
        </Modal>
    )
}


const Cutlist_pref_modal_leaf = ({open, cutlist_fields, handle_close, set_page_loader, fetch_all_level_preferences}) => {
	// const [dummy_modal_json, set_dummy_modal_json] = useState()
	const [fields, set_fields] = useState([])
	const [fields_json, set_fields_json] = useState({})
    const [add_edit_custom_field_modal, set_add_edit_custom_field_modal] = useState(false)
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)
    const [custom_cutlist_fields, set_custom_cutlist_fields] = useState({})
    const [confirm_for_del_open, set_confirm_for_del_open] = useState(false)

    function convertSecondLevel(jsonData) {
        const newData = JSON.parse(JSON.stringify(jsonData));
        if(typeof newData != 'undefined') {
            for (const firstKey in newData) {
                const secondLevelJson = newData[firstKey];
                if(typeof secondLevelJson != 'undefined') {
                    for (const secondKey in secondLevelJson) {
                        const thirdLevelJson = secondLevelJson[secondKey];
                        if(typeof thirdLevelJson['value'] != 'undefined'){
                            // secondLevelJson[secondKey] = (secondKey == 'visibility')?(+thirdLevelJson['value']['store']):(thirdLevelJson['value']['store']) != '' ? (secondKey == 'visibility')?(+thirdLevelJson['value']['store']):(thirdLevelJson['value']['store']) : (secondKey == 'visibility')?(+thirdLevelJson['value']['master']):(thirdLevelJson['value']['master']);
                            if (secondKey === 'visibility') {
                                // if([NaN, undefined].includes(thirdLevelJson['value']['store'])) 
                                    // console.log("kppppp here it is", thirdLevelJson)
                                if (!['', NaN, undefined].includes(thirdLevelJson['value']['store'])) {
                                    secondLevelJson[secondKey] = +thirdLevelJson['value']['store'];
                                } else {
                                    secondLevelJson[secondKey] = +thirdLevelJson['value']['master'];
                                }
                            } else {
                                if (!['', NaN, undefined].includes(thirdLevelJson['value']['store'])) {
                                    secondLevelJson[secondKey] = thirdLevelJson['value']['store'];
                                } else {
                                    secondLevelJson[secondKey] = thirdLevelJson['value']['master'];
                                }
                            }
                            if(org_bu_view == 'bu' && thirdLevelJson['value']['business_unit'] && thirdLevelJson['value']['business_unit'][user.current_business_unit_id] != '')
                                secondLevelJson[secondKey] = (secondKey == 'visibility')?(+thirdLevelJson['value']['business_unit'][user.current_business_unit_id]):thirdLevelJson['value']['business_unit'][user.current_business_unit_id]
                        }
                    }
                }
            }
        }
        // console.log("KPP convertSecondLevel", newData)
        return newData;
    }

    // useEffect(() => {
    //     // console.log("KP fields", fields)
    //     // console.log("KP cuuu", cutlist_fields)
    // }, [fields, cutlist_fields])

    function convertToJSON(fields) {
        const finalCutlist = JSON.parse(JSON.stringify(cutlist_fields));
        let originalObject = {};
        let temp = JSON.parse(JSON.stringify(fields))
        let garb1 = temp.map(obj => {
            let { id, ...rest } = obj;
            originalObject[id] = rest;
        });
        for(const firstKey in finalCutlist) {
            const secondLevelJson = finalCutlist[firstKey];
            if(typeof secondLevelJson != 'object') continue;
            for(const secondKey in secondLevelJson){
                const thirdLevelJson = secondLevelJson[secondKey];
                if(typeof thirdLevelJson != 'undefined' && typeof thirdLevelJson['value'] != 'undefined'){
                    thirdLevelJson['value']['business_unit'] = {}
                    thirdLevelJson['value']['business_unit'][user.current_business_unit_id] = originalObject[firstKey][secondKey];
                }
            }
            // leafData['value'] = originalObject[firstKey];
        }
        return finalCutlist;
    }

    function requestBody(final_cutlist_fields) {
        const req = []
        for(let x in final_cutlist_fields) {
            const fieldJson = final_cutlist_fields[x];
            for(let leaf in fieldJson) {
                if(typeof cutlist_fields[x] != 'undefined' && typeof cutlist_fields[x][leaf] !='undefined' && typeof cutlist_fields[x][leaf]['value'] != 'undefined'){
                    let temp = (cutlist_fields[x][leaf]['value']['store'] != '' ?cutlist_fields[x][leaf]['value']['store']:cutlist_fields[x][leaf]['value']['master'])
                    if(org_bu_view == 'bu' && cutlist_fields[x][leaf]['value']['business_unit'] && cutlist_fields[x][leaf]['value']['business_unit'][user.current_business_unit_id]!='')
                        temp = (leaf == 'visibility') ? (+cutlist_fields[x][leaf]['value']['business_unit'][user.current_business_unit_id]):cutlist_fields[x][leaf]['value']['business_unit'][user.current_business_unit_id]
                    if(fieldJson[leaf].value['business_unit'][user.current_business_unit_id] != temp)
                        req.push({designing_preference_id: fieldJson[leaf].id, value: fieldJson[leaf].value['business_unit'][user.current_business_unit_id]})
                }
            }
        }
        return req;
    }

    function filterCustomFields(json) {
        const filteredJSON = {};
        for (const key in json) {
          if (json[key].hasOwnProperty('formula')) {
            filteredJSON[key] = json[key];
          }
        }
        return filteredJSON;
      }

	useEffect(() => {
        // console.log("KP cutlist_fields", convertSecondLevel(cutlist_fields))
		set_fields(Object.entries(convertSecondLevel(cutlist_fields)).map(([id, values]) => ({ id, ...values})).sort((a, b) => a.order - b.order))
	}, [cutlist_fields])

    const submit_fields = async () => {
        try {
            set_page_loader({
                show:true,
                text: 'Updating preference...'
            })
            const final_fields = JSON.parse(JSON.stringify(fields))
            let garb = final_fields.map((item, idx) => item.order = idx + 1)
            const final_cutlist_fields = convertToJSON(final_fields);

            let resp 

            if(org_bu_view === 'bu'){
                resp = await general_fetch({
                    url: 'designing_preference/set_business_unit_preference',
                    body: {design_preference_id_and_values: requestBody(final_cutlist_fields), business_unit_id: user.current_business_unit_id }
                }); 
            }else if(org_bu_view == 'org'){
                resp = await general_fetch({
                    url: 'designing_preference/set_store_preference',
                    body: {design_preference_id_and_values: requestBody(final_cutlist_fields)}
                    // body: { design_preference_id_and_values: [{ designing_preference_id: id, value: value_temp }] }
                }); 
            }    
            set_page_loader({show:false})
            fetch_all_level_preferences();
            handle_close()
            // handle_close();
            // console.log(resp);
        } catch(err) {
            console.log(err);
        }
    }

    const onclick_handle_close = () => {
        const final_fields = JSON.parse(JSON.stringify(fields))
        let garb = final_fields.map((item, idx) => item.order = idx + 1)
        const final_cutlist_fields = convertToJSON(final_fields);
        let req_arr = requestBody(final_cutlist_fields);
        // console.log("KPPPP", req_arr)
        if(req_arr.length == 0)
            handle_close();
        else
            set_confirm_for_del_open(true);
    }

	return (
			<Modal size='xl' isOpen={open} toggle={onclick_handle_close}>
                <IModalHeader toggle={onclick_handle_close}>Fields</IModalHeader>
                <IModalBody style={{height: '600px', overflowY: 'auto'}}>
                    <div style={{display:'flex', alignItems:'center', width:'100%', height: '40px'}}>
                                {/* <div style={{backgroundColor: 'white', paddingRight:'15px', display:'flex', alignItems:'center'}}></div> */}
                        <div style={{paddingLeft: '28px', backgroundColor: 'white', display:'flex', alignItems:'center', width:'100%', height: '25px'}}>
                            <div style={{textTransform:'capitalize', fontWeight: 'bold', fontSize:'14px', flex:1, minWidth: '10px', flexWrap: 'wrap'}}>Default Name</div>
                            <div style={{fontSize: '14px', fontWeight: 'bold', height: '21px', flex: 1, minWidth: '10px'}}>Name</div>
                            <div style={{display: 'flex', fontWeight: 'bold', justifyContent: 'flex-end', flex: 1, paddingRight: '12px'}}>
                                Visibility
                            </div>
                        {/* <Input checked={item.checked} onChange={() => {checkbox_onchange(item.id)}} type='checkbox' style={{cursor:'pointer', position:'unset', margin:0, marginRight:'15px'}} /> */}
                        </div>
                    </div>
                    <div style={{ width: '100%', height: 'calc(100% - 40px)', overflowY: 'auto'}}>
                        <DragDropComponentPreferences style={{paddingBottom: '3px'}} items_from_backened={fields} set_items_from_backened={set_fields} fetch_all_level_preferences={fetch_all_level_preferences} set_page_loader={set_page_loader} fields={cutlist_fields} modal_name='cutlist_pref' Single_component={Single_item_for_cutlist_pref} />
                    </div>
                    {/* <Cutlist_pref_custom_fields open_modal={show_add_custom_fields_modal} handle_close_modal={() => set_show_add_custom_fields_modal(false)} fields={fields} custom_cutlist_fields={custom_cutlist_fields}></Cutlist_pref_custom_fields> */}
                <ConfirmationModal isOpen={confirm_for_del_open} content={"You have unsaved changes that will be lost if you close without saving. Are you sure you want to proceed?"} onSubmit={() => {set_confirm_for_del_open(false); handle_close();}} onCancel={() => {set_confirm_for_del_open(false);}}></ConfirmationModal>
                </IModalBody>
                <IModalFooter>
                    {/* <Button className='blue_button' onClick={() => set_show_add_custom_fields_modal(true)}>Custom Fields</Button> */}
                    <Button className="blue_button" onClick={submit_fields}>Submit</Button>
                </IModalFooter>
            </Modal>
	)
}

const Leaf_Editor = ({ current_key, preference_json, hierarchy, fetch_all_level_preferences, category_types, sales_channels, sales_channel_level_prefs, fetch_sales_channel_level_prefs, sku_data_json, all_division_tree_data, set_page_loader, out_of_sync_preferences, search_string, parent_name }) => {
    
    const [ current_json, set_current_json ] = useState({});
    const [display_value, set_display_value] = useState('');
    const [ update_leaf_modal_show, set_update_leaf_modal_show ] = useState(false);
    const [cutlist_fields_modal_show, set_cutlist_fields_modal_show] = useState(false)
    const [sales_channel_collapse, set_sales_channel_collapse] = useState(true)
    const [show_sales_channel_pref_modal, set_show_sales_channel_pref_modal] = useState(false)
    const [active_sales_channel, set_active_sales_channel] = useState(null);
    const [matched_search_string, set_matched_search_string] = useState(false);
    const [preference_full_name, set_preference_full_name] = useState('');
    const [fields_json, set_fields_json] = useState('');
    const [original_cutlist_fields, set_original_cutlist_fields] = useState({});
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)
    const alert = useAlert()

    const resync_store_level_to_master = async(designing_preference_id) =>{
        if(designing_preference_id){
            try{
                set_page_loader({
                    show:true,
                    text: 'Syncing with infurnia default ...'
                })
                let resp = await general_fetch({url:'designing_preference/resync_store_level_to_master', body:{designing_preference_ids:[designing_preference_id]}})
                set_page_loader({show:false})
                if(resp) alert.success('Synced with Infurnia default value successfully !')
                fetch_all_level_preferences()
            }catch(err){
                console.log(err)
                alert.error('Syncing with Infurnia default value failed !')
            }
        }
    }

    const link_store_level_to_master = async(designing_preference_id) => {
        if(designing_preference_id){
            try{
                set_page_loader({
                    show:true,
                    text: 'Linking with infurnia default ...'
                })
                let resp = await general_fetch({url:'designing_preference/link_store_level_to_master', body:{designing_preference_ids:[designing_preference_id]}})
                set_page_loader({show:false})
                if(resp) alert.success('Linked with Infurnia default value successfully !')
                fetch_all_level_preferences()
            }catch(err){
                console.log(err)
                alert.error('Linking with Infurnia default value failed !')
            }
        }
    }
    
    useEffect(() => {
        if(!(modal_for_parents.includes(parent_name) && current_key == 'fields')){
            let temp_current_json = traverse_json(preference_json, [ ...hierarchy, current_key ])
            set_current_json(temp_current_json);
            console.log("sales channel", sales_channels, current_key, traverse_json(preference_json, [ ...hierarchy, current_key ]))
            let temp_display_value = ''
            // if(temp_current_json.value_type === 'sku_id' && sku_data_json && sku_data_json[temp_current_json.value['store'] ? temp_current_json.value['store'] : temp_current_json.value['master']]){
            //     temp_display_value = sku_data_json[temp_current_json.value['store'] ? temp_current_json.value['store'] : temp_current_json.value['master']].name
            // }else{
            //     temp_display_value = temp_current_json.value['store'] ? temp_current_json.value['store'] : temp_current_json.value['master']
            // }
            // if(org_bu_view == 'bu' && temp_current_json.value['business_unit'] && temp_current_json.value['business_unit'][user.current_business_unit_id]){
            //     let value = ''
            //     if(temp_current_json.value_type === 'sku_id' && sku_data_json && sku_data_json[temp_current_json.value['business_unit'][user.current_business_unit_id]]){
            //         value = sku_data_json[temp_current_json.value['business_unit'][user.current_business_unit_id]].name
            //     }else{
            //         value = temp_current_json.value['business_unit'][user.current_business_unit_id]
            //     }
            //     if(temp_current_json.value_type == "boolean" && (value == "0" || value == "1")){
            //         value = (value == "0") ? "false" : "true"
            //     }
            //     set_display_value(value)
            //     console.log('temp_current_json 11', value)
            // }else{
            //     if(temp_current_json.value_type == "boolean" && (temp_display_value == "0" || temp_display_value == "1")){
            //         temp_display_value = (temp_display_value == "0") ? "false" : "true"
            //     }
            //     set_display_value(temp_display_value)
            //     console.log('temp_current_json', temp_display_value)
            // }

            let temp_value = get_top_level_pref_value(temp_current_json.value)
            temp_display_value = temp_value;
            if(temp_current_json.value_type === 'sku_id'){
                if(sku_data_json[temp_value]){
                    temp_display_value = sku_data_json[temp_value].name
                }
            }else if(temp_current_json.value_type === 'boolean'){
                if(temp_value === true || temp_value === '1' || temp_value === 'true'){
                    temp_display_value = "true"
                }else{
                    temp_display_value = "false"
                }
            }
            set_display_value(temp_display_value)

        }
        else{
            let temp_current_json = traverse_json(preference_json, [...hierarchy, current_key])
            sanitize_json(temp_current_json)
            set_original_cutlist_fields(temp_current_json)
            set_fields_json(JSON.parse(JSON.stringify(temp_current_json)))
        }
    }, [ preference_json, hierarchy, sku_data_json ]);

    useEffect(() => {
        console.log('sku_data_json', sku_data_json)
    }, [sku_data_json]);

    useEffect(() => {
        if(!search_string){
            set_matched_search_string(false)
        }else{
            if(current_json && wordsMatch(convert_to_readable(modal_for_parents.includes(parent_name) ? current_key : current_json.display_name), search_string)){
                set_matched_search_string(true)
            }else{
                set_matched_search_string(false)
            }
        }
    }, [search_string, current_json]);

    useEffect(() => {
        set_preference_full_name(parent_name ? parent_name+'_'+current_key : current_key)
    }, [parent_name, current_key]);

    // useEffect(() => {
    //     console.log("KPP original cutlist fields", original_cutlist_fields)
    // }, [original_cutlist_fields])
    
    return current_json ? (
        <div>
            <Leaf_Edit_Modal
                leaf_data={current_json}
                open={update_leaf_modal_show}
                hierarchy={hierarchy}
                handle_close={() => set_update_leaf_modal_show(false)}
                fetch_all_level_preferences={fetch_all_level_preferences}
                category_types={category_types}
                sku_data_json = {sku_data_json}
                all_division_tree_data = {all_division_tree_data}
                set_page_loader = {set_page_loader}
                link_store_level_to_master = {link_store_level_to_master}
                resync_store_level_to_master = {resync_store_level_to_master}
                out_of_sync_preferences = {out_of_sync_preferences}
                preference_full_name={preference_full_name}
            />
            <Cutlist_pref_modal_leaf
                open={cutlist_fields_modal_show}
                cutlist_fields={original_cutlist_fields}
                set_page_loader = {set_page_loader}
                handle_close={() => {set_cutlist_fields_modal_show(false); sanitize_json(fields_json); set_original_cutlist_fields(fields_json); set_fields_json(JSON.parse(JSON.stringify(fields_json)))}}
                fetch_all_level_preferences={fetch_all_level_preferences}
            />
            <Edit_SalesChannel_preference_Modal
                open = {show_sales_channel_pref_modal}
                handle_close = {() => set_show_sales_channel_pref_modal(false)}
                active_sales_channel = {active_sales_channel}
                hierarchy = {hierarchy}
                leaf_data = {current_json}
                category_types = {category_types}
                fetch_all_level_preferences = {fetch_all_level_preferences}
                sku_data_json = {sku_data_json}
                all_division_tree_data = {all_division_tree_data}
                preference_full_name={preference_full_name}
            />
            <div style={{ textAlign: 'left', backgroundColor: "white", color: "#1070CA",borderBottom: "1px solid rgb(242, 244, 246)", height:'40px', display: (!search_string || matched_search_string)?'flex':'none', alignItems:'center', justifyContent:'space-between' }}>
                <div style={{flex:'5', fontSize:'14px', color:'black',padding: 5, paddingLeft: 15,paddingRight: 15}}>
                    {convert_to_readable(modal_for_parents.includes(parent_name) ? current_key : current_json.display_name)}
                </div>
                <div className='flex_property' style={{flex:'4', fontSize:'14px', color:'black',padding: 5, paddingLeft: 15,paddingRight: 15,justifyContent:'space-between'}}>
                    {['list', 'sku_category_type_id'].includes(current_json.value_type)  ? current_json.value_type : current_json.display_name == 'all_available_tools' ? 'List' : (current_json.value ? display_value:'')}
                    {current_json.max_level == 'sales_channel' && global.config.sales_channel_preference_allowed_bu.includes(user.current_business_unit_id)? 
                        <div>
                            {/* {
                                out_of_sync_preferences && out_of_sync_preferences.length && out_of_sync_preferences.find(o => o.designing_preference_id == current_json.id)?
                                <Tooltip title='Sync with Infurnia default in the edit modal' placement='bottom' mouseEnterDelay={0.5}>
                                    <img style={{width:'24px', height:'22px', cursor:'pointer', marginRight:'18px'}} src={'/assets/img/icons/sync_icon_black.svg'}/>
                                </Tooltip>:''
                            }
                            {
                                current_json.value && current_json.value.store?
                                <Tooltip title='Link with Infurnia default in the edit modal' placement='bottom' mouseEnterDelay={0.5}>
                                    <img style={{width:'24px', height:'22px', cursor:'pointer', marginRight:'20px'}} src={'/assets/img/icons/unlinked_with_global.svg'}/>
                                </Tooltip>:
                                <Tooltip title='Linked with Infurnia default' placement='bottom' mouseEnterDelay={0.5}>
                                    <img style={{width:'24px', height:'22px', cursor:'pointer', marginRight:'20px'}} src={'/assets/img/icons/linked_with_global.svg'}/>
                                </Tooltip>
                            } */}
                            <i style={{cursor:'pointer', color: "#1070CA" }} onClick={() => set_update_leaf_modal_show(true)} className='fa fa-edit'/>
                        </div>
                        :''}
                </div>
                <div className='flex_property' style={{flex:'6',fontSize:'14px', color:'black',borderLeft:current_json.max_level == 'sales_channel'?'1px solid rgb(242, 244, 246)':'', height:'100%'}}>
                {
                    current_json.max_level == 'sales_channel' && global.config.sales_channel_preference_allowed_bu.includes(user.current_business_unit_id)?                    
                        <span style={{cursor:'pointer'}} onClick={() => set_sales_channel_collapse(!sales_channel_collapse)} >
                            <span style={{ float: 'left',margin:'4px 10px 3px 10px', color:'#0078FF'}} className={sales_channel_collapse ? 'fa fa-angle-down' : 'fa fa-angle-up'} ></span> 
                            <span style={{marginLeft:'5px',color:'#0078FF'}}>Sales channel specific values</span>
                        </span>
                    :''
                }
                </div>
                <div style={{flex:'2', display:'flex', justifyContent:'flex-end', marginRight:'10px',padding: 5, paddingLeft: 15,paddingRight: 15,}}>
                {
                    current_json.max_level == 'sales_channel' && global.config.sales_channel_preference_allowed_bu.includes(user.current_business_unit_id)? <span>{'\xa0\xa0\xa0\xa0\xa0\xa0'}</span>:
                        <span>
                            {/* {
                                out_of_sync_preferences && out_of_sync_preferences.length && out_of_sync_preferences.find(o => o.designing_preference_id == current_json.id)?
                                <Tooltip title='Sync with Infurnia default in the edit modal' placement='bottom' mouseEnterDelay={0.5}>
                                    <img style={{width:'24px', height:'22px', cursor:'pointer', marginRight:'18px'}} src={'/assets/img/icons/sync_icon_black.svg'}/>
                                </Tooltip>:''
                            }
                            {
                                current_json.value && current_json.value.store?
                                <Tooltip title='Link with Infurnia default in the edit modal' placement='bottom' mouseEnterDelay={0.5}>
                                    <img style={{width:'24px', height:'22px', cursor:'pointer', marginRight:'20px'}} src={'/assets/img/icons/unlinked_with_global.svg'}/>
                                </Tooltip>:
                                <Tooltip title='Linked with Infurnia default' placement='bottom' mouseEnterDelay={0.5}>
                                    <img style={{width:'24px', height:'22px', cursor:'pointer', marginRight:'20px'}} src={'/assets/img/icons/linked_with_global.svg'}/>
                                </Tooltip>
                            } */}
                            <span style={{cursor:'pointer', color: "#1070CA" }} onClick={() => {if(modal_for_parents.includes(parent_name) && current_key == "fields") {set_cutlist_fields_modal_show(true);} else set_update_leaf_modal_show(true);}} className='fa fa-edit'/>
                        </span>
                }
                </div>
            </div>
            {
                !sales_channel_collapse && sales_channels && sales_channels.length ? sales_channels.map((single_sales_channel,idx) => (
                    <div style={{ textAlign: 'left', backgroundColor: "white", color: "#1070CA", height:'40px', display:'flex', alignItems:'center', justifyContent:'space-between', borderBottom:idx==sales_channels.length-1? "1px solid rgb(242, 244, 246)":'' }}>   
                        <div style={{flex:'6', fontSize:'14px', color:'black',padding: 5, paddingLeft: 15,paddingRight: 15,}}>
                        </div>
                        <div style={{flex:'3', fontSize:'14px', color:'black',padding: 5, paddingLeft: 15,paddingRight: 15,}}>
                        </div>
                        <div className='flex_property' style={{display:'flex',flex:'7', justifyContent:'space-between',fontSize:'14px', color:'black',borderBottom: idx==sales_channels.length-1? '':"1px solid rgb(242, 244, 246)",borderLeft:current_json.max_level == 'sales_channel'?'1px solid rgb(242, 244, 246)':'', height:'100%'}}>
                            <span style={{marginLeft:'35px',color:'black', flex:'2'}}>{single_sales_channel.name}</span>
                            <span style={{flex:'1',}}>{['list', 'sku_category_type_id'].includes(current_json.value_type) ? current_json.value_type : (current_json.value ? (current_json.value['sales_channel'] ? (current_json.value['sales_channel'][single_sales_channel.id] ? current_json.value['sales_channel'][single_sales_channel.id]: (current_json.value['store'] ? current_json.value['store'] : current_json.value['master'])) : (current_json.value['store'] ? current_json.value['store'] : current_json.value['master'])):'')}</span>
                        </div>
                        <div className='flex_property' style={{justifyContent:'flex-end',paddingLeft: 15,paddingRight: 25,borderBottom: "1px solid rgb(242, 244, 246)", height:'100%'}}>
                            <span style={{cursor:'pointer', color: "#1070CA" }} onClick = {() => {set_active_sales_channel(single_sales_channel);set_show_sales_channel_pref_modal(true)}} className='fa fa-edit'/>
                        </div>
                    </div>
                )):""
            }
            
        </div>
    ) : '';
}


const Parent_Editor = ({ current_key, preference_json, hierarchy, fetch_all_level_preferences, category_types, sales_channels, sales_channel_level_prefs, fetch_sales_channel_level_prefs, first_call, sku_data_json, all_division_tree_data, set_page_loader, out_of_sync_preferences, search_string, parent_name }) => {

    const [ current_json, set_current_json ] = useState({});
    const [ collapsed, set_collapsed ] = useState(true);
    const [ render, set_render ] = useState(false);
    const [preference_full_name, set_preference_full_name] = useState('');

    useEffect(() => {
        // let children = traverse_json(preference_json, [ ...hierarchy, current_key ]);
        let { json, visible, isCollapsed } = determine_visibility(preference_json, [ ...hierarchy, current_key ], false, true, search_string);
        set_current_json(json);
        set_render(visible);
        // console.log("determine", json, visible)
        if(!isCollapsed) set_collapsed(false)
    }, [preference_json, hierarchy]);

	useEffect(() => {
		if(!search_string) set_collapsed(true)
	}, [search_string]);

    useEffect(() => {
        if(preference_json){
            set_preference_full_name(parent_name ? `${parent_name}_${current_key}` : current_key)
        }
    }, [parent_name, current_key]);

    return (
        <div id="preferences_parent_editor" style={{ width: "100%", display: render ? 'block' : 'none', border:first_call?'1px solid #D1D7DA':'', borderBottom:!collapsed?'1px solid #D1D7DA':'', marginBottom:first_call?'20px':'' }}>
			<div className='flex_property' style={{ fontSize:first_call?'16px':'14px', height:first_call?'45px':'40px' ,fontWeight:'900', padding: 5, cursor: 'pointer', paddingLeft: 15, paddingRight: 15 , backgroundColor:first_call?'rgb(223 227 230)':"rgb(242, 244, 246)",borderBottom: "1px solid lightgrey"}} onClick={() => set_collapsed(!collapsed)}>
				<span style={{ float: 'left', marginTop: 4, marginRight:'10px', marginBottom:'3px'}} className={collapsed ? 'fa fa-angle-down' : 'fa fa-angle-up'} ></span> 
				<span style={{ float: 'left'}}> {convert_to_readable(current_key)} </span>
                {
                    preference_tooltip_json && preference_tooltip_json[preference_full_name] ? 
                        <TriggerTooltip placement="right" overlayStyle={{maxWidth: 'unset'}} title={
                            <div>
                                <div className='mb-2'>{convert_to_readable(current_key)}</div>
                                <img style={{maxWidth: '320px', maxHeight: '320px', width: '320px'}} src={build_path(global.config.static_path + preference_tooltip_json[preference_full_name])} />
                            </div>
                        }/>
                        : ''
                }
			</div>
			{
				collapsed ? '':
				<div style={{ margin:"0px 10px 10px 10px", display: collapsed ? "none" : "block", borderLeft: "1px solid lightgrey", borderRight: "1px solid lightgrey", borderBottom: "1px solid lightgrey" }}>
					{current_json && Object.keys(current_json).map((key, index) => (
						current_json[key].type == 'leaf' && current_json[key].id && valid_value_types.includes(current_json[key].value_type) ? (
							current_json[key].visible && !dependent_keys_list.includes(preference_full_name+'_'+key) ? (
								<div>
									<Leaf_Editor
										current_key={key}
										hierarchy={[ ...hierarchy, current_key ]}
										preference_json={preference_json}
										fetch_all_level_preferences={fetch_all_level_preferences}
										category_types={category_types}
										sales_channels = {sales_channels}
										sku_data_json = {sku_data_json}
										all_division_tree_data = {all_division_tree_data}
										set_page_loader = {set_page_loader}
										out_of_sync_preferences = {out_of_sync_preferences}
										search_string={search_string}
                                        parent_name={preference_full_name}
										// sales_channel_level_prefs = {sales_channel_level_prefs}
										// fetch_sales_channel_level_prefs = {fetch_sales_channel_level_prefs}
									/>
								</div>
							): ''
						) : ''
					))}
					{current_json && Object.keys(current_json).map((key, index) => (
						current_json[key].type == 'leaf' && current_json[key].id && valid_value_types.includes(current_json[key].value_type) ? '' : (
                            (modal_for_parents.includes(preference_full_name) && key == 'fields') ? 
                            <div>
                                <Leaf_Editor
                                    current_key={key}
                                    hierarchy={[ ...hierarchy, current_key ]}
                                    preference_json={preference_json}
                                    fetch_all_level_preferences={fetch_all_level_preferences}
                                    category_types={category_types}
                                    sales_channels = {sales_channels}
                                    sku_data_json = {sku_data_json}
                                    all_division_tree_data = {all_division_tree_data}
                                    set_page_loader = {set_page_loader}
                                    out_of_sync_preferences = {out_of_sync_preferences}
                                    search_string={search_string}
                                    parent_name={preference_full_name}
                                    // sales_channel_level_prefs = {sales_channel_level_prefs}
                                    // fetch_sales_channel_level_prefs = {fetch_sales_channel_level_prefs}
                                />
							</div> :
							<div>
								<Parent_Editor
									current_key={key}
									hierarchy={[ ...hierarchy, current_key ]}
									preference_json={preference_json}
									fetch_all_level_preferences={fetch_all_level_preferences}
									category_types={category_types}
									sales_channels = {sales_channels}
									sku_data_json = {sku_data_json}
									all_division_tree_data = {all_division_tree_data}
									set_page_loader = {set_page_loader}
									out_of_sync_preferences = {out_of_sync_preferences}
									search_string={search_string}
									// sales_channel_level_prefs = {sales_channel_level_prefs}
									// fetch_sales_channel_level_prefs = {fetch_sales_channel_level_prefs}
                                    parent_name={preference_full_name}
                                />
							</div>
						)
					))}
				</div>
			}
        </div>
    );
}

const Store_Preferences = ({set_active_tab, send_analytics_event, check_route_fn, store_details, get_store_details, set_active_division_id_sidebar, set_page_loader}) => {

    const [ initialized, set_initialized ] = useState(false);
	const [ preference_json, set_preference_json ] = useState({});
    const [ category_types, set_category_types ] = useState([]);
    const [sales_channels , set_sales_channels] = useState([]);
    const [fetch_progress , set_fetch_progress] = useState(40)
    const [sales_channel_level_prefs, set_sales_channel_level_prefs] = useState({});
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)
    const all_bu_in_store = useContext(AllBuContext)
    const [sku_data_json, set_sku_data_json] = useState();
    const [all_division_tree_data, set_all_division_tree_data] = useState();
    const [out_of_sync_preferences, set_out_of_sync_preferences] = useState();
    const [search_string, set_search_string] = useState('');
	const [delayed_search_string, set_delayed_search_string] = useState('');
	const [timeoutId, setTimeoutId] = useState(null);
	const [vanish_prefs, set_vanish_prefs] = useState(false);
    const [search_count, set_search_count] = useState(0);

    
    const fetch_sales_channel_details = async () => {
		try {
			// var brand_timer = setInterval(() => {
			// 	var x = Number(fetch_progress);
			// 	console.log('xxx ---> ', x,x + Math.floor((100 - x)/2))
			// 	set_fetch_progress(String(x + Math.floor((100 - x)/2)))  
			// }, 200);
			let resp, sales_channel_details = []
			let body = {
				"include_sales_channel_profile_info": true, 
				"include_bu_mapping": true, 
				"include_bu_info": false, 
				"include_price_type_mapping": true, 
				"include_price_type_info": true, 
				"include_user_mapping": true, 
				"include_user_info": false
			}

			if(org_bu_view === 'bu'){
				if(user && user.current_business_unit_id){
					body['business_unit_ids'] = [user.current_business_unit_id]
					resp = await general_fetch({ url: 'sales_channel/get_by_business_unit' , body})
					let owned_sc = resp && resp.sales_channels_by_business_unit && resp.sales_channels_by_business_unit[user.current_business_unit_id] ? resp.sales_channels_by_business_unit[user.current_business_unit_id].owned : []
					// let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x)) sales_channel_details.push(resp.sales_channels[x])}) : []
					let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x) && !(all_bu_in_store && all_bu_in_store.length === 1 && resp.sales_channels[x].type === 'bu_feeder')) sales_channel_details.push(resp.sales_channels[x])}) : []
				}
			}else{
				resp = await general_fetch({ url: 'sales_channel/get_of_store' , body})
				sales_channel_details = resp && resp.sales_channels && resp.sales_channels.owned ? resp.sales_channels.owned.filter(x => !x.business_unit_id) : []
			}
            sales_channel_details = sales_channel_details.map(single_sales_channel => {
                let price_types = single_sales_channel.price_type_ids && single_sales_channel.price_type_ids.length ? single_sales_channel.price_type_ids.map(x => resp.price_types[x]) : []
                return {...single_sales_channel, price_types}
            })
			set_sales_channels(sales_channel_details);
			// clearInterval(brand_timer);
		} catch(err) {
			console.log(err);
            handleError(err)
		}
	}

    const fetch_category_type_details = async() => {
        try {
            let response = await general_fetch({ url: 'sku_category_type/get'});
            response = split_cat_type(response, 'with_division').sort(name_sort);
            console.log('cat type --->> ', response);
            set_category_types(response);
        } catch(err) {
            console.log(err);
        }
	}

	// const fetch_master_preferences = async () => {
	// 	try {
	// 		var resp = await general_fetch({ url: 'designing_preference/get_preference_at_level', body: { level: 'store', json_with_leaf_values: true, nested_output: true } });
	// 		set_initialized(true);
    //         set_preference_json(resp);
	// 	} catch(err) {
	// 		console.log(err);
	// 	}
	// }

    // const fetch_sales_channel_level_prefs = async() => {
    //     try{
    //         if(sales_channels && sales_channels.length){
    //             let temp_json = {}
    //             for(let i=0;i<sales_channels.length;i++){
    //                 var resp = await general_fetch({url:'designing_preference/get_preference_at_level', body:{level:'sales_channel', sales_channel_id:sales_channels[i].id, nested_output:true}})
    //                 if(resp){
    //                     temp_json[sales_channels[i].id] = resp
    //                 }
    //             }
    //             console.log("sales prefs", temp_json)
    //             set_sales_channel_level_prefs(temp_json)
    //         }
    //     }catch(err){
    //         console.log(err)
    //     }
    // }

    const fetch_all_level_preferences = async() => {
        try{
            let sales_channel_ids = sales_channels && sales_channels.length ? sales_channels.map(x => x.id):[]
            set_page_loader({
                show:true,
                text: 'fetching preferences...'
            })
        let response = await general_fetch({url:'designing_preference/get_all_level_preferences', body:{sales_channel_id:sales_channel_ids, nested_output:true, json_with_leaf_values:true, business_unit_id: user.current_business_unit_id}})
            set_page_loader({show:false})
            if(response){
				response.cabinet_preferences = response.kitchen_preferences
                response.panel_label_preferences = response.fulfillment_tag_preferences
				delete response['kitchen_preferences']
				delete response['fulfillment_tag_preferences']
				delete response['cam_preferences']
                set_preference_json(response)
                // set_preference_json(dummy_preference_json)
                await search_for_sku_ids(response)
                get_sku_names()
                // fetch_out_of_sync_for_store()
            }
        }catch(err){
            console.log(err)
        }
    }

    const fetch_all_subcategories = async() => {
        try{
            let body = {}
			if(org_bu_view == 'bu'){
				body['business_unit_id'] = user.current_business_unit_id
			}
            var response = await general_fetch({ url: 'inventory/get_all_sub_categories', body});	
			set_all_division_tree_data(response)
        }catch(err){
            console.log(err)
        }
    }

    const fetch_out_of_sync_for_store = async() => {
        try{
            let resp = await general_fetch({url:'designing_preference/get_out_of_sync_for_store'})
            set_out_of_sync_preferences(resp)
        }catch(err){
            console.log(err)
        }
    }

    const search_for_sku_ids = async(preference_json, parent_name = '') => {
        if(preference_json && Object.keys(preference_json) && Object.keys(preference_json).length){
            Object.keys(preference_json).map((key, idx) => {
                preference_id_to_full_name[preference_json[key].id] = parent_name ? parent_name+'_'+key : key
                if(preference_json[key].type === 'leaf'){
                    if(dependent_keys_list.includes(parent_name ? parent_name+'_'+key : key)){
                        dependent_keys_value_json[parent_name ? parent_name+'_'+key : key] = preference_json[key]
                    }
                    if(preference_json[key].value_type === 'sku_id'){
                        if(org_bu_view == 'org'){
                            if(preference_json[key].value.store) sku_ids_set.add(preference_json[key].value.store)
                            else sku_ids_set.add(preference_json[key].value.master)
                        }
                        else{
                            if(preference_json[key].value.business_unit && preference_json[key].value.business_unit[user.current_business_unit_id]){
                                sku_ids_set.add(preference_json[key].value.business_unit[user.current_business_unit_id])
                            }
                            else if(preference_json[key].value.store) sku_ids_set.add(preference_json[key].value.store)
                            else sku_ids_set.add(preference_json[key].value.master)
                        }
                        if(preference_json[key].value.sales_channel && Object.values(preference_json[key].value.sales_channel) && Object.values(preference_json[key].value.sales_channel.length)){
                            Object.values(preference_json[key].value.sales_channel).map((value) => {
                                sku_ids_set.add(value)
                            })
                        }
                    }
                }else{
                    search_for_sku_ids(preference_json[key], parent_name ? parent_name+'_'+key : key)
                }
            })
        }
    }

    const get_sku_names = async() => {
        try{
            let sku_ids_list = [...sku_ids_set]
            set_page_loader({
                show:true,
                text:'Please wait...'
            })
            let resp = await general_fetch({url:'sku/get', body: {identifiers:JSON.stringify({id:sku_ids_list}), business_unit_id: user.current_business_unit_id}});
            set_page_loader({
                show:false
            })
            let temp_sku_json = {}
            if(resp && resp.length){
                resp.map((single_sku, idx) => {
                    temp_sku_json[single_sku.id] = {id: single_sku.id, 
                                                    name: single_sku.name,
                                                    sku_group_id: single_sku.sku_group.id, 
                                                    sku_sub_category_id: single_sku.sku_group.sku_sub_category.id, 
                                                    sku_category_id: single_sku.sku_group.sku_sub_category.sku_category.id,
                                                    sku_division_id: single_sku.sku_group.sku_sub_category.sku_category.sku_division_id
                                                    }
                })
            }
            console.log('temp_sku_json', temp_sku_json)
            set_sku_data_json(temp_sku_json)
        }catch(err){
            console.log(err)
        }
    }

	useEffect(() => {
		// console.log('delayed_search_string11111111', search_string)
	}, [search_string]);

	useEffect(() => {
		// console.log('delayed_search_string', delayed_search_string)
		if(timeoutId) {
			clearTimeout(timeoutId);
		}
	
		setTimeoutId(setTimeout(() => {
			set_search_string(delayed_search_string)
			set_vanish_prefs(false)
		}, 300));
	}, [delayed_search_string]);

    const matchedStringCount = (obj, name='') => {
        if(obj && obj.type == 'leaf')
            return wordsMatch(name, search_string)
        let count = wordsMatch(name, search_string)
        Object.keys(obj).map((key, index) => count += matchedStringCount(obj[key], key)) 
        return count
    }

    useEffect(() => {
        if(preference_json && search_string){
            let count = matchedStringCount(JSON.parse(JSON.stringify(preference_json)))
            set_search_count(count)
        }
    }, [search_string])

    useEffect(() => {
        if (window.get_user) {
            if (!window.config.sales_channel_preference_allowed_bu.includes(window.get_user().current_business_unit_id)) {
                dependent_keys_json['other_admin_preferences_maximum_absolute_discount_enabled'] = ['other_admin_preferences_maximum_absolute_discount', 'other_admin_preferences_maximum_other_items_absolute_discount', 'other_admin_preferences_maximum_other_items_percentage_discount', 'other_admin_preferences_maximum_percentage_discount_(percentage)', 'other_admin_preferences_maximum_woodwork_absolute_discount', 'other_admin_preferences_maximum_woodwork_percentage_discount'];

                conditional_visibility_based_on_dependent_key_value['other_admin_preferences_maximum_absolute_discount'] = 'true'
                conditional_visibility_based_on_dependent_key_value['other_admin_preferences_maximum_other_items_absolute_discount'] = 'true'
                conditional_visibility_based_on_dependent_key_value['other_admin_preferences_maximum_other_items_percentage_discount'] = 'true'
                conditional_visibility_based_on_dependent_key_value['other_admin_preferences_maximum_percentage_discount_(percentage)'] = 'true'
                conditional_visibility_based_on_dependent_key_value['other_admin_preferences_maximum_woodwork_absolute_discount'] = 'true'
                conditional_visibility_based_on_dependent_key_value['other_admin_preferences_maximum_woodwork_percentage_discount'] = 'true'

                dependent_keys_json['pricing_quotation_preferences_enable_design_fee'] = ['pricing_quotation_preferences_design_fee_percentage']

                conditional_visibility_based_on_dependent_key_value['pricing_quotation_preferences_design_fee_percentage'] = 'true'

                dependent_keys_json['pricing_quotation_preferences_highlight_in_pricing_quotation_enabled'] = ['pricing_quotation_preferences_highlight_in_pricing_quotation']

                conditional_visibility_based_on_dependent_key_value['pricing_quotation_preferences_highlight_in_pricing_quotation'] = 'true'


                let dependent_keys_list_local = []
                let dependent_keys_list_temp = Object.keys(dependent_keys_json).map(x => dependent_keys_list_local = [...dependent_keys_list_local, ...dependent_keys_json[x]])
                dependent_keys_list = dependent_keys_list_local;
            }
        }


    }, []);

	useEffect(() => {
		if (!initialized && all_bu_in_store) {
			// fetch_master_preferences();
            fetch_category_type_details();
            fetch_sales_channel_details();
            fetch_all_level_preferences()
            fetch_all_subcategories()

		}
		set_active_tab('preferences_settings');
        set_active_division_id_sidebar('org_preference')
		send_analytics_event('auto', 'Org Preferences', 'Org Preferences', 1, window.location.href)
		// check_route_fn('store_preference');
	}, [all_bu_in_store]);

    useEffect(() => {
        if(sales_channels && sales_channels.length){
            // fetch_sales_channel_level_prefs()
            fetch_all_level_preferences()
        }
    }, [sales_channels]);

	try {
		return (
			<Main_Grid  active_tab="org_preference" user={user} store_details={store_details} get_store_details={get_store_details}>
                <div style={{ height: '100%', overflow: 'scroll', padding: 20, margin: 0 }}>
                    <Row style={{padding:'10px 10px 10px 0px', color:'#22272e', fontFamily:'Source_Sans_Pro-SemiBold'}}>
                        Manage Org Preferences
                    </Row>
                    <Row className='search_component_container_ideal' style={{marginLeft:-15}}>
                        <span><i style={{paddingLeft:'12px', paddingBottom:'3px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/></span>
                        <input className={'search_component_inner_container'} placeholder= {'Type to Search'} type='text' value={delayed_search_string} onChange={(e) => {set_delayed_search_string(e.target.value);if(e.target.value.length < 4) set_vanish_prefs(true)}} style={{flex: '1'}}></input>
                        <button style={{backgroundColor: 'transparent', border: 'none', color: '#0078FF', fontSize: '12px', fontWeight: '400'}} onClick={() => set_delayed_search_string('')}>clear</button>
                        <div style={{display: search_string?'':'none', width: '1px', height: '32px', backgroundColor: '#C4C6CD', marginRight: '5px'}}></div>
                        <span style={{display: search_string?'':'none', padding: '0 4px', fontSize: '10px'}}>Found total of {search_count} results</span>
                        {/* <span className='hover_blue' style={{paddingRight:'12px', cursor:'pointer', fontSize:'10px', color:'#9aa5b5', visibility:search_string ? 'visible':'hidden', fontStyle:'italic'}} onClick={() => set_search_string('')}>Clear</span> */}
                    </Row>
					{
						vanish_prefs ? '':
						<Row style={{padding:'10px 0px', color:'#22272e'}}>
							{preference_json && Object.keys(preference_json).map((key, index) => 
								(preference_json[key].type == 'leaf' && preference_json[key].id) ? (
									preference_json[key].visible && !dependent_keys_list.includes(key) ? (
										<div style={{width: '100%'}}>
											<Leaf_Editor
												current_key={key}
												hierarchy={[]}
												preference_json={preference_json}
												category_types={category_types}
												fetch_all_level_preferences = {fetch_all_level_preferences}
												sales_channels = {sales_channels}
												sku_data_json = {sku_data_json}
												all_division_tree_data = {all_division_tree_data}
												set_page_loader = {set_page_loader}
												out_of_sync_preferences = {out_of_sync_preferences}
												search_string={search_string}
												// sales_channel_level_prefs = {sales_channel_level_prefs}
												// fetch_sales_channel_level_prefs = {fetch_sales_channel_level_prefs}
                                                parent_name={''}
											/>
										</div>
									) : ''
								) : ''
							)}
							{preference_json && Object.keys(preference_json).sort((a,b) => {if(a > b) return 1; return -1}).map((key, index) => 
								(preference_json[key].type == 'leaf' && preference_json[key].id) ? '' : (
									<div style={{width: '100%'}}>
										<Parent_Editor
											current_key={key}
											hierarchy={[]}
											preference_json={preference_json}
											fetch_all_level_preferences = {fetch_all_level_preferences}
											category_types={category_types}
											sales_channels = {sales_channels}
											sku_data_json = {sku_data_json}
											all_division_tree_data = {all_division_tree_data}
											set_page_loader = {set_page_loader}
											out_of_sync_preferences = {out_of_sync_preferences}
											// sales_channel_level_prefs = {sales_channel_level_prefs}
											// fetch_sales_channel_level_prefs = {fetch_sales_channel_level_prefs}
											first_call = {true}
											search_string={search_string}
                                            parent_name={''}
										/>
									</div>
								)
							)}
						</Row>
					}
                </div>
			</Main_Grid>
		);
	} catch(err) {
		console.error("Error in feature flags component ---> ", err);
	}

}

export default Store_Preferences;