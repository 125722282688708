import React from 'react';
import { Button, Modal } from "reactstrap"

export const ConfirmationModal = ({ isOpen, content, buttons_array, onCancel, onSubmit }) => {
    return (
        <Modal isOpen={isOpen} centered>
            <div className='DMSans' style={{background: 'white', padding: '2rem 4rem', borderRadius: '4px'}}>
                <div className='mb-4' style={{ textAlign: 'center' }}>
                    {content ? content : 'Are you sure you want to proceed?'}
                </div>

                <div className='flex_center'>
                    {
                        buttons_array && buttons_array.length ? buttons_array.map((single_button, idx) => (
                            <Button key={idx} className={single_button.type === "primary" ? "blue_button" : single_button.type === "secondary" ? "white_button" : single_button.type === "warning" ? "red_button" : "blue_button"} onClick={single_button.route ? single_button.route : null}>
                                {single_button.name}
                            </Button>
                        ))
                            :
                            <React.Fragment>
                                <Button className="white_button" type="button" onClick={onCancel}>
                                    Close
                                </Button>
                                <Button className="blue_button" type="button" onClick={onSubmit}>
                                    Proceed
                                </Button>
                            </React.Fragment>
                    }

                </div>
            </div>
        </Modal>
    )
}