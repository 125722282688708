import React , { useState , useEffect, useRef, useContext }from 'react';
import {FormGroup, Button, Input, Modal, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, TabPane , TabContent ,Nav, NavItem, NavLink, Row, Table, Collapse } from "reactstrap";
import classnames from "classnames";
import Multiselect from '../../../components/Multiselect';
import KeyValuePair from '../../../components/KeyValuePair';
import { all_allowed_key } from '../../../components/AdditionalPropKeys';
import { useAlert } from 'react-alert';
import is_valid_add_key from '../../../utils/is_valid_add_key';
import { exclusion_list_options } from '../../../utils/cbu_exclusion_list';
import StringifiedComponentStructure from '../../../assets/core_jsons/bulk_create_cabinet_options.json';
import SkuSelector from '../../../components/Sku_Selector';
import general_fetch from '../../../utils/fetch';
import { OrgBuContext } from '../../../components/Contexts/OrgBuContext';
import ComponentStructurePartition from '../../../utils/component_structure_partition';
import ComponentStructurePanel from '../../../utils/component_structure_panel';
import url from 'socket.io-client/lib/url';
import comp_file_top_level_elem from '../../../utils/comp_file_top_level_elem';
import Upload3d_modal_modal from '../../../components/Upload_3d_modal';
import { postUploadImageExtractionfn } from '../../../utils/show_3d_model';
import * as Sentry from "@sentry/react";
import { handleError } from '../../../utils/error';
import Add_new_material from '../../../components/Add_new_material';
import { fetch_material_templates } from '../../../utils/api/material';
import { IModalHeader, IModalBody, IModalFooter } from '../../../components/IModal';
import { fetch_all_subcategories, fetch_sku_groups, fetch_skus } from '../../../utils/api/inventory';
import { Checkbox, Tooltip } from 'antd';
import TriggerTooltip from '../../../components/TriggerTooltip';
import build_path from '../../../utils/path';
import { is_valid_file_type } from '../../../utils/is_valid_file';
import capitalizeAndReplaceUnderscores from '../../../utils/format_name';

const ComponentStructure = StringifiedComponentStructure;
// const ComponentStructure = JSON.parse(StringifiedComponentStructure);

const cbu_valid_types = [ "corner_unit", "cabinet", "drawer_system" ];

const styles = {
    input_style : {
        fontSize: '12px',
        padding: '0px 8px',
        height: '32px',
        width: 'var(--width_input_sku_structure)',
        borderRadius: '2px',
    },
    input_style_none : {
        fontSize: '12px',
        padding: '0px 8px',
        height: '32px',
        borderRadius: '2px',
        border: 'none',
        boxShadow: 'none'
    }
}

const SingleUnitComponent = ({partition, previous_item, sku_partition_array, initial_item, set_sku_partition_array, all_division_tree_data, set_page_loader, edited_sku_structure_partition_array, set_edited_sku_structure_partition_array, index, type, search_string_sku_structure, show_only_edited_in_sku_structure, common_props_confirmation_mode, is_cabinet, componentStructure, cabinet_corner_json_arr}) => {
    const [edited_sku_structure_partition, set_edited_sku_structure_partition] = useState({});
    // const [value, set_value] = useState('');
    const [is_open, set_is_open] = useState(true);
    const [single_unit_key, set_single_unit_key] = useState('single_unit_init');
    const toggle = () => set_is_open(!is_open)

    const delete_item = () => {
        sku_partition_array.splice(index, 1)
        set_sku_partition_array([...sku_partition_array])

        edited_sku_structure_partition_array.splice(index, 1)
        set_edited_sku_structure_partition_array(edited_sku_structure_partition_array)
    }

    const reset_item = () => {
        set_edited_sku_structure_partition({})
        generate_random_key()
    }

    useEffect(() => {
        if(edited_sku_structure_partition_array && edited_sku_structure_partition_array.length > index)
        edited_sku_structure_partition_array[index] = edited_sku_structure_partition;
        set_edited_sku_structure_partition_array(edited_sku_structure_partition_array)
    }, [edited_sku_structure_partition]);

    // useEffect(() => {
    //     sku_partition_array[index] = value;
    //     set_sku_partition_array(sku_partition_array)
    // }, [value]);

    // useEffect(() => {
    //     set_value(partition)
    // }, [partition]);

    const generate_random_key = () => {
        set_single_unit_key(Date.now())
    }

    return (
        <React.Fragment key={single_unit_key}>
        {/* <Row className='leaf_sku_edit_structure'>
            <Col>Partition Name</Col>
            <Col></Col>
            <Col>
                <Input style={{...styles.input_style}} type={'text'} value={value} onChange={(e) => set_value(e.target.value)} placeholder='Enter Partition Name'></Input>
            </Col>
        </Row> */}
            <Row onClick={toggle} className='parent_sku_edit_structure'>
                <Col className='flex_property justify-content-between'>
                    <div style={{textTransform: 'capitalize'}}>{(type) + ' ' + (index+1)}</div>
                    <div className='flex_property'>
                        {
                            common_props_confirmation_mode ? '' :
                            <React.Fragment>
                                <div style={{marginRight: '32px', fontSize: '14px'}} className='reset_changes' onClick={(e) => {e.stopPropagation(); reset_item()}}>Reset Changes</div>
                                <div style={{marginRight: '32px', fontSize: '14px', color: '#c5c7cf'}} onClick={(e) => {e.stopPropagation(); delete_item()}}>Delete</div>
                            </React.Fragment>
                        }
                        <i style={{color: '#A7A8B2', fontSize: '16px'}} className={is_open ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i>
                    </div>
                </Col>
            </Row>
            <Collapse isOpen={is_open} style={{borderLeft: '4px solid #a7a8b2'}}>
                {
                    (componentStructure ? componentStructure : type === 'panel' ? (cabinet_corner_json_arr[2]) : (cabinet_corner_json_arr[1])).map((item, idx) => (
                        <SingleRowComponent key={idx} item={item} previous_item={previous_item && previous_item[item.id]} initial_item={initial_item && initial_item[item.id]} edited_sku_structure={edited_sku_structure_partition} set_edited_sku_structure={set_edited_sku_structure_partition} parent={edited_sku_structure_partition} all_division_tree_data={all_division_tree_data} set_page_loader={set_page_loader} search_string_sku_structure={search_string_sku_structure} show_only_edited_in_sku_structure={show_only_edited_in_sku_structure} common_props_confirmation_mode={common_props_confirmation_mode} cabinet_corner_json_arr={cabinet_corner_json_arr}/>
                    ))
                }
            </Collapse>
        </React.Fragment>
    )
}

const SingleRowComponent = ({item, initial_item, previous_item, edited_sku_structure, set_edited_sku_structure, parent, all_division_tree_data, set_page_loader, search_string_sku_structure, show_only_edited_in_sku_structure, parent_id, common_props_confirmation_mode, cabinet_corner_json_arr}) => {
    const [is_open, set_is_open] = useState(true);
    const toggle = () => set_is_open(!is_open)
    const [value, set_value] = useState(null);
    const [selected_value, set_selected_value] = useState([]);
    const [is_edited, setis_edited] = useState();
    const [key_node, set_key_node] = useState('init');
    const [is_open_modal, set_is_open_modal] = useState(false);
    const toggle_modal = () => set_is_open_modal(!is_open_modal)
    const [display_name, set_display_name] = useState('');
    const [property_array, set_property_array] = useState([]);
    const [init_value, set_init_value] = useState('')
    const [initialised, set_initialised] = useState(false);
    // const [collapsed, set_collapsed] = useState(false);

    // useEffect(() => {
    //     set_collapsed(item.collapsed)
    // }, [item]);

    const initialiseValues = (init = false) => {
        if(initial_item || [false, 0].includes(initial_item)){
            if(item.type == 'text' || item.type == 'number'){
                set_value(item.type == 'number' && initial_item != '' ? Number(initial_item) : initial_item)
                if(init) set_init_value(item.type == 'number' && initial_item != '' ? Number(initial_item) : initial_item)
            }
            else if(item.type == 'bool'){
                // console.log("kpp bool item is", item)
                if([0, false].includes(initial_item) || initial_item){
                    set_value(initial_item ? true : false)
                    if(init) set_init_value(initial_item ? true : false)
                }
                else{
                    set_value('')
                    if(init) set_init_value('')
                }
            }
            else if(item.type == 'select'){
                set_value(initial_item)
                if(init) set_init_value(initial_item)
            }
            else if(item.type == 'multiselect'){
                set_selected_value(item.options && item.options.length && item.options.filter(x => {initial_item && initial_item.length && initial_item.includes(x.id)}))
                if(init) set_init_value(item.options && item.options.length && item.options.filter(x => {initial_item && initial_item.length && initial_item.includes(x.id)}))
            }
            else if(item.type == 'array'){
                // console.log("kppp array item", initial_item, active_style)
                set_value(initial_item ? initial_item : [])
                if(init) set_init_value(initial_item ? initial_item : [])
                set_property_array(initial_item ? initial_item : [])
            }
            else if(item.type == 'sku'){
                // console.log("kpp sku initial value", initial_item, item, init)
                if(init) set_init_value(initial_item)
                set_value(initial_item)
            }
        } else {
            set_value('')
            set_selected_value([])
            if(init) set_init_value('')
        }
    }

    // useEffect(() => {
    //     if(item.type == 'sku') console.log("kp skku is", init_value);
    // }, [init_value])

    useEffect(() => {
        // console.log("Kpp active_style is", initial_item, item)
        if(initial_item || [0, false].includes(initial_item) || previous_item || [0, false].includes(previous_item)){
            initialiseValues(true)
            if(item.type == 'sku') reset_child()
        }
    }, [initial_item])

    useEffect(() => {
        // console.log('entered use effect', value)
        if(parent){
            if(item.type == 'parent'){
                parent[item.id] = {}
            }else{
                parent[item.id] = value
            }
            set_edited_sku_structure(edited_sku_structure)
        }
    }, [value, parent]);

    useEffect(() => {
        if(item.type != parent && item.value){
            set_value(item.value)
        }
    }, [item]);

    useEffect(() => {
        generate_random_key()
    }, [parent]);

    const reset_child = () => {
        // set_value(null)
        initialiseValues()
        generate_random_key()
    }

    const reset_parent = () => {
        parent[item.id] = {}
        set_edited_sku_structure(edited_sku_structure)
        generate_random_key()
    }
    
    const generate_random_key = () => {
        set_key_node(Date.now())
    }

    // useEffect(() => {
    //     console.log(parent, "parent")
    // }, [parent]);

    useEffect(() => {
        if(item.type == 'multiselect' && selected_value && selected_value.length){
            set_value(selected_value.map(x => x.id))
        }else if(value !== null){
            set_value(null)
        }
    }, [selected_value]);

    const add_partition = () => {
        let temp = [...property_array]
        temp.push(Date.now())
        set_property_array(temp)

        let temp_2
        if(value && value.length){
            temp_2 = [...value]
        }else{
            temp_2 = []
        }
        temp_2.push({})
        set_value(temp_2)
    }


    return(
        <React.Fragment key={key_node}>
            <SkuSelector set_page_loader={set_page_loader} is_open={is_open_modal} toggle={toggle_modal} all_division_tree_data={all_division_tree_data} set_value={set_value} set_display_name={set_display_name} initial_sku={initial_item} possible_category_types={item.possible_category_types}/>
            {
                item.type == 'parent' ? 
                <React.Fragment>
                <div style={{display: item.display?'':'none'}}>
                    <Row onClick={toggle} className='parent_sku_edit_structure'>
                        <Col className='flex_property justify-content-between'>
                            {item.name}
                            <div className='flex_property'>
                                {
                                    common_props_confirmation_mode ? '' :
                                        <div style={{marginRight: '32px', fontSize: '14px'}} className='reset_changes' onClick={(e) => {e.stopPropagation(); reset_parent()}}>Reset Changes</div>
                                }
                                <i style={{color: '#A7A8B2', fontSize: '16px'}} className={is_open ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i>
                            </div>
                        </Col>
                    </Row>
                    <Collapse isOpen={is_open} style={{borderLeft: '4px solid #a7a8b2'}}>
                        {
                            item.children && item.children.map((child, idx) => (
                                <SingleRowComponent key={idx} item={child} previous_item={previous_item && previous_item[child.id]} initial_item={initial_item && initial_item[child.id]} parent={parent && parent[item.id]} set_edited_sku_structure={set_edited_sku_structure} edited_sku_structure={edited_sku_structure} all_division_tree_data={all_division_tree_data} set_page_loader={set_page_loader} search_string_sku_structure={search_string_sku_structure} show_only_edited_in_sku_structure={show_only_edited_in_sku_structure} parent_id={item.id + (parent_id ? '_' + parent_id : '') } common_props_confirmation_mode={common_props_confirmation_mode} cabinet_corner_json_arr={cabinet_corner_json_arr}/>
                            ))
                        }
                    </Collapse>
                </div>
                </React.Fragment>
                : item.type == 'array' ?
                <div style={{borderBottom: '1px solid #C5C7CF'}}>
                    {
                        property_array && property_array.length ? property_array.map((partition, index) => (
                            <SingleUnitComponent type={item.name} key={partition} previous_item={previous_item && (previous_item.length > index) && previous_item[index]} initial_item={partition} partition={partition} sku_partition_array={property_array} set_sku_partition_array={set_property_array} all_division_tree_data={all_division_tree_data} set_page_loader={set_page_loader} edited_sku_structure_partition_array={value} set_edited_sku_structure_partition_array={set_value} index={index} search_string_sku_structure={search_string_sku_structure} show_only_edited_in_sku_structure={show_only_edited_in_sku_structure} common_props_confirmation_mode={common_props_confirmation_mode} componentStructure={item.children} cabinet_corner_json_arr={cabinet_corner_json_arr}/>
                        ))
                            : ''
                    }
                    {common_props_confirmation_mode ? '' : <div className='blue_text_edit_sku_structure flex_property pb-2 pt-2' onClick={add_partition}>+ Add {item.name}</div>}
                </div>
                :
                <React.Fragment>
                    <Row className='leaf_sku_edit_structure' style={{display: (search_string_sku_structure && !item.display) || (show_only_edited_in_sku_structure && !(value || value === false))  ? 'none' : 'flex'}}>
                        <Col>
                        <div className='flex_property'>
                                {item.name}
                                {
                                    item.tooltip ?
                                        typeof (item.tooltip === "object") && value ?
                                            <TriggerTooltip placement="right" overlayStyle={{maxWidth: 'unset'}} title={
                                                <div>
                                                    <div className='mb-2'>
                                                        {
                                                            item.type === "multiselect" ?
                                                                selected_value && selected_value.name :
                                                                item.type === "select" ?
                                                                    item.options && item.options.length && item.options.find(x => x.id === value) && item.options.find(x => x.id === value).name
                                                                    :
                                                                    item.name
                                                        }
                                                    </div>
                                                    {item.tooltip[value] ? <img style={{maxWidth: '320px', maxHeight: '320px', width: '320px'}} src={build_path(global.config.static_path, item.tooltip[value])} /> : '' }
                                                </div>
                                            } />
                                            : ''
                                        : ''
                                }
                            </div>
                        </Col>
                        <Col style={{fontSize:'12px'}} title={item.description ? item.description : ''}>{item.description ? item.description : ''}</Col>
                        <Col className={value || value === false ? 'edited_sku_structure_cell' : ''} style={{pointerEvents: common_props_confirmation_mode ? 'none' : 'auto'}}>
                            {
                                item.type == 'sku' ?
                                <div className='flex_property' style={{width:'var(--width_input_sku_structure)'}}>
                                    {value ? <div className='mr-3'>{ display_name ? display_name : value}</div> : ''}
                                    <div onClick={toggle_modal} className='blue_text_edit_sku_structure'>{value ? 'Edit Sku' : 'Add Sku'}</div>
                                </div>
                                : item.type == 'text' || item.type == 'number' ?
                                <Input id={item.id + (parent_id ? '_' + parent_id : '')} style={{...styles.input_style}} type={item.type} value={value} onChange={(e) => set_value(item.type == 'number' && e.target.value != '' ? Number(e.target.value) : e.target.value)}></Input>
                                : item.type == 'bool' || item.type == 'select' ?
                                <Input id={item.id + (parent_id ? '_' + parent_id : '')} style={{...styles.input_style}} type={'select'} value={value} onChange={(e) => set_value(item.type == 'bool' && e.target.value == 'true' ? true : item.type == 'bool' && e.target.value == 'false' ? false : e.target.value )}>
                                    {
                                        item.type == 'select' && item.options && item.options.length ? item.options.map((option, idx) => (
                                            <option key={idx} value={option.id}>{option.name}</option>
                                        ))
                                        :
                                        item.type == 'bool' ? 
                                        <React.Fragment>
                                            <option value={''}>No Changes</option>
                                            <option value={true}>True</option>
                                            <option value={false}>False</option>
                                        </React.Fragment>
                                        :''
                                    }
                                </Input>
                                : item.type == 'multiselect' ?
                                    <Multiselect
                                        style={{backgroundColor:'white', ...styles.input_style, padding:'0px'}}
                                        options={item.options} 
                                        selectedValues={selected_value?selected_value:[]}
                                        onSelect={set_selected_value} 
                                        onRemove={set_selected_value} 
                                        displayValue="name" // Property name 
                                        placeholder='Select'
                                        // disable={allow_edit?false:true}
                                    />
                                :''
                            }
                            {<div style={{marginLeft: '16px', fontSize: '14px', cursor:'pointer'}} className='reset_changes' onClick={reset_child}>{init_value || [0, false].includes(init_value) ? 'Reset to style' : ''}</div>}
                            
                        </Col>
                    </Row>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

const ImportStyleModal = ({user, open, sku_type, onStyleAppliedCallback, toggle}) => {

    const [selected_style, set_selected_style] = useState('');
    const [all_styles, set_all_styles] = useState([])
    const alert = useAlert();

    // useEffect(() => {
    //     console.log("seelcted style kpp is ", selected_style)
    // }, [selected_style])

    // useEffect(() => {
    //     console.log("kpp all styles", all_styles);
    // }, [all_styles])

    const fetch_all_construction_styles = async() => {
        try{
            let resp = await fetch_all_subcategories();
            let template = resp && resp.length && resp.find(item => item.id == 'template')
            let style_cat = template && template.sku_category && template.sku_category.length && template.sku_category.find(item => item.sku_category_type_id == "construction_style_template")
            let style_subcat = style_cat && style_cat.sku_sub_category && style_cat.sku_sub_category.length && style_cat.sku_sub_category[0].id;
            let groups = await fetch_sku_groups(style_subcat);
            let style_group = groups && groups.length && groups[0];
            // console.log("Kppp cs styles",style_group && style_group.sku && style_group.sku.length ? style_group.sku : [] )
            set_all_styles(style_group && style_group.sku && style_group.sku.length ? style_group.sku : [])
        } catch(err){
            handleError(err)
        }
    }

    useEffect(() => {
        if(open){
            fetch_all_construction_styles()
        }
    }, [open])

    const apply_style = async(style) => {
        let resp = await fetch_skus([style])
        let comp_file = resp && resp.length && resp[0].component_file
        let active_style_temp = comp_file && comp_file.length && comp_file[0].data;
        active_style_temp.style_name = resp && resp.length && resp[0].name;
        active_style_temp.style_desc = resp && resp.length && resp[0].description;
        onStyleAppliedCallback(active_style_temp)
    }

    const onclick_handle_close = () => {
        toggle();
        set_selected_style('')
        set_all_styles('')
    }

    const onclick_submit = async() => {
        try{
            if(selected_style == ''){
                alert.error('Please select a construction style to proceed')
                return;
            }
            await apply_style(selected_style)
            onclick_handle_close()
        } catch(err){
            alert.error('Importing this style failed. Please try again')
            handleError(err);
        }
    }

    return (
        <Modal isOpen={open} className="modal-dialog-centered" toggle={onclick_handle_close} size="sm">
        <IModalHeader toggle={toggle}>Select Sku structure style to use</IModalHeader>
			<IModalBody className='p-4'>
                <div style={{margin: '8px auto 24px', width: '90%'}}>
                    <div style={{color: 'black', fontWeight: 600, marginBottom: '8px'}}>List of Styles</div>
                    <Input type='select' id='select-style-input' style={{ height: '32px', fontSize: '12px', margin: 'auto' }} onChange={(e) => set_selected_style(e.target.value)}>
                        <option value=''>Select a Style</option>
                        {all_styles && all_styles.length && all_styles.filter(item => item.sku_type == sku_type).map(item => (
                            <option value ={item.id}>{item.name}</option>
                        ))}
                    </Input>
                </div>
                <div style={{display: 'flex', gap: '8px', marginBottom: '24px', width: '90%', marginLeft: 'auto', marginRight: 'auto'}}>
                    <div style={{color: '#676878'}}>SKU Type: </div>
                    <div>{capitalizeAndReplaceUnderscores(sku_type)}</div>
                </div>
                <div style={{width: '90%', marginLeft: 'auto', marginRight: 'auto'}}>
                    <div style={{color: '#676878', marginBottom: '4px'}}>Description text: </div>
                    <div style={{color: selected_style ? '' : '#A7A8B2'}}>{selected_style ? all_styles && all_styles.length && all_styles.find(item => item.id == selected_style) && all_styles.find(item => item.id == selected_style).description : 'Select a style to see description'}</div>
                </div>
			</IModalBody>
			<IModalFooter>
				<Button className="blue_button" onClick={onclick_submit} id='select-style-apply-button'>Apply</Button>
			</IModalFooter>
        </Modal>
    )
}

const BulkEditSkuStructure = ({show_only_edited_in_sku_structure, set_show_only_edited_in_sku_structure, common_props_confirmation_mode, edited_sku_structure, set_edited_sku_structure, edited_sku_structure_partition_array, set_edited_sku_structure_partition_array, 
	edited_sku_structure_panel_array, set_edited_sku_structure_panel_array, corner_units, all_division_tree_data, set_page_loader}) => {

	const [	tabs_sku , set_tabs_sku ] = useState(1)
	const [show_import_modal, set_show_import_modal] = useState(false);
	const [imported_style, set_imported_style] = useState('')
    const [previous_style, set_previous_style] = useState('')
	const [search_string_sku_structure, set_search_string_sku_structure] = useState('');
	const [cabinet_corner_json_arr, set_cabinet_corner_json_arr] = useState([[], [], []])
    const [total_results, set_total_results] = useState([0, 0, 0])
	const [key_sku_structure, set_key_sku_structure] = useState('sku_structure_init');
    const [key_sku_structure_partition, set_key_sku_structure_partition] = useState('sku_structure_partition_init');
    const [key_sku_structure_panel, set_key_sku_structure_panel] = useState('sku_structure_panel_init');
	const [sku_partition_array, set_sku_partition_array] = useState([]);
    const [sku_panel_array, set_sku_panel_array] = useState([]);

    const toggleNavsSku = (e, index) => {
		e.preventDefault();
		set_tabs_sku(index)
	};

	function filterBulkEditSkuStructureRows(obj, tab, par=false) {
        if(obj.type != 'parent' && obj.type != 'array'){
            if(obj.name.toLowerCase().includes(search_string_sku_structure.toLowerCase()))
                obj.display = true;
            else
                obj.display = par;
            if(obj.display && obj.flag == undefined && !obj.flag){
                obj.flag = true;
                total_results[tab-1]++
            }
            return obj
        }

        else {
            if(obj.type == 'parent' || obj.type == 'array'){
                if(par){
                    obj.display = true;
                    obj.children.map(x => filterBulkEditSkuStructureRows(x, tab, true))
                }
                else {
                    if(obj.name.toLowerCase().includes(search_string_sku_structure.toLowerCase())){
                        obj.display = true
                        obj.children.map(x => filterBulkEditSkuStructureRows(x, tab, true))
                    }
                    else{
                        obj.children.forEach(x => {
                            filterBulkEditSkuStructureRows(x, tab)
                        });
                        var garb = obj.children.map(x => {
                            obj.display = obj.display || filterBulkEditSkuStructureRows(x, tab).display;
                        });
                    }
                }
            }
            return obj
        }
        
    }

	const reset_sku_structure_tab = () => {
        console.log('edit sku structure', edited_sku_structure)
        if(tabs_sku == 1){
            set_edited_sku_structure({})
            set_key_sku_structure(Date.now())
        }else if(tabs_sku == 2){
            set_sku_partition_array([])
            set_edited_sku_structure_partition_array([])
            set_key_sku_structure_partition(Date.now())
        }else if(tabs_sku == 3){
            set_sku_panel_array([])
            set_edited_sku_structure_panel_array([])
            set_key_sku_structure_panel(Date.now())
        }
    }

	const add_partition = () => {
        let temp = [...sku_partition_array]
        temp.push({key: Date.now()})
        set_sku_partition_array(temp)

        let temp_2 = [...edited_sku_structure_partition_array]
        temp_2.push({})
        set_edited_sku_structure_partition_array(temp_2)
    }

	const add_panel = () => {
        let temp = [...sku_panel_array]
        temp.push({key: Date.now()})
        set_sku_panel_array(temp)

        let temp_2 = [...edited_sku_structure_panel_array]
        temp_2.push({})
        set_edited_sku_structure_panel_array(temp_2)
    }

    const get_array_with_random_keys = (init_arr) => {
        let n = init_arr.length
        let arr = [];
        for (let i = 0; i < n; i++) {
            let temp = Math.random()
            arr.push({key: temp, value: init_arr[i]})
        }
        return arr
    }

	useEffect(() => {
        // set_total_results([0, 0, 0])
        total_results[0] = 0
        total_results[1] = 0
        total_results[2] = 0
        if(corner_units.length == 0){
            // set_cabinet_corner_json_arr([ComponentStructure.cabinet.cabinet, ComponentStructure.cabinet.partitions, ComponentStructure.cabinet.panels])
            set_cabinet_corner_json_arr([ComponentStructure.cabinet.cabinet.map(x => filterBulkEditSkuStructureRows(JSON.parse(JSON.stringify(x)), 1)), ComponentStructure.cabinet.partitions.map(x => filterBulkEditSkuStructureRows(JSON.parse(JSON.stringify(x)), 2)), ComponentStructure.cabinet.panels.map(x => filterBulkEditSkuStructureRows(JSON.parse(JSON.stringify(x)), 3))])
        }
        else{
            set_cabinet_corner_json_arr([ComponentStructure.corner_unit.cabinet.map(x => filterBulkEditSkuStructureRows(JSON.parse(JSON.stringify(x)), 1)), ComponentStructure.corner_unit.partitions.map(x => filterBulkEditSkuStructureRows(JSON.parse(JSON.stringify(x)), 2)), ComponentStructure.corner_unit.panels.map(x => filterBulkEditSkuStructureRows(JSON.parse(JSON.stringify(x)), 3))])
        }
        set_total_results(JSON.parse(JSON.stringify(total_results)))
    }, [search_string_sku_structure, corner_units]);

	useEffect(() => {
        if(imported_style.partitions && imported_style.partitions.length){
            set_sku_partition_array(get_array_with_random_keys(imported_style.partitions))
            set_edited_sku_structure_partition_array(imported_style.partitions)
        }else{
            set_sku_partition_array([])
            set_edited_sku_structure_partition_array([])
        }
        if(imported_style.panels && imported_style.panels.length){
            set_sku_panel_array(get_array_with_random_keys(imported_style.panels))
            set_edited_sku_structure_panel_array(imported_style.panels)
        }else{
            set_sku_panel_array([])
            set_edited_sku_structure_panel_array([])
        }
    }, [imported_style])

	return (
		<>
			<ImportStyleModal 
                open={show_import_modal}
                toggle={() => set_show_import_modal(!show_import_modal)}
                onStyleAppliedCallback = {(style) => set_imported_style(style)}
                sku_type={corner_units.length ? "corner_unit" : "cabinet"}
            />
			<div style={{ zIndex: 1, paddingBottom:'20px', backgroundColor: search_string_sku_structure?'#F5F6F8':'inherit'}}>
				<div className='flex_property bulk_edit_of_property'>
					<span style={{fontWeight:'900'}}>Bulk Edit of Components</span>
					<div style={{display: 'flex', gap: '32px'}}>
					{common_props_confirmation_mode?'':(imported_style ? <div style={{color: 'red'}} className='reset_changes' onClick={() => {let prev_style = JSON.parse(JSON.stringify(imported_style)); set_previous_style(prev_style); set_imported_style('')}}>Remove {imported_style && imported_style.style_name} style</div> : <div className='reset_changes' id='import-from-styles' onClick={() => {set_show_import_modal(true)}}>Import from Styles</div>)}
					{common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {set_imported_style(''); reset_sku_structure_tab()}}>Clear all</span>}
					</div>
				</div>
				<div className='flex_property mt-3'>
					<div className='flex_property search_component_container_ideal'  style={{width: '60%'}}>
						<i className="fas fa-search pr-2 pl-2" style={{fontSize:'12px'}}></i>
						<Input style={{...styles.input_style_none, flex: '1'}} type='text' value={search_string_sku_structure} onChange={(e) => {set_search_string_sku_structure(e.target.value)}} placeholder='Search for any property'></Input>
						<button style={{backgroundColor: 'transparent', border: 'none', color: '#0078FF', fontSize: '12px', fontWeight: '400'}} onClick={() => set_search_string_sku_structure('')}>clear</button>
						<div style={{display: tabs_sku == 1 && search_string_sku_structure?'':'none', width: '1px', height: '32px', backgroundColor: '#C4C6CD', marginRight: '5px'}}></div>
						<span style={{display: tabs_sku == 1 && search_string_sku_structure?'':'none', padding: '0 4px', fontSize: '10px'}}>Found total of {total_results[0]} results</span>
					</div>
					<div className='flex_property ml-4'>
						{/* <i className={(show_only_edited_in_sku_structure ? 'far fa-check-square' : 'far fa-square') + ' mr-3'} onClick={() => set_show_only_edited_in_sku_structure(!show_only_edited_in_sku_structure)}></i> */}
						<Checkbox className='mr-3' checked={show_only_edited_in_sku_structure} onClick={() => set_show_only_edited_in_sku_structure(!show_only_edited_in_sku_structure)}/>
						Show only modified fields
					</div>
				</div>
			</div>
			<div className="nav-wrapper" style={{padding:'2px 8px', backgroundColor:'white', fontSize:'15px'}}>
				<Nav 
					style={{display:'flex', alignItems:'center'}}
					className="flex-md-row"
					id="tabs-icons-text" 
					pills
					role="tablist"
				>   
					{
						<NavItem>
							<NavLink
								aria-selected={tabs_sku === 1}
								className={classnames("mb-sm-3 mb-md-0", {
									active: tabs_sku === 1
								})}
								onClick={e => toggleNavsSku(e, 1)}
								href="#pablo"
								role="tab"
							>
								<span>{corner_units.length === 0 ? "Cabinet" : "Corner Unit"}</span>
							</NavLink>
						</NavItem>
					}
					{
						<NavItem>
							<NavLink
								aria-selected={tabs_sku === 2}
								className={classnames("mb-sm-3 mb-md-0", {
									active: tabs_sku === 2
								})}
								onClick={e => toggleNavsSku(e, 2)}
								href="#pablo"
								role="tab"
							>
								<span>Partition</span>
							</NavLink>
						</NavItem>
					}
					{
						<NavItem>
							<NavLink
								aria-selected={tabs_sku === 3}
								className={classnames("mb-sm-3 mb-md-0", {
									active: tabs_sku === 3
								})}
								onClick={e => toggleNavsSku(e, 3)}
								href="#pablo"
								role="tab"
							>
								<span>Panel</span>
							</NavLink>
						</NavItem>
					}
					{/* {
						<NavItem>
							<NavLink
								aria-selected={tabs_sku === 4}
								className={classnames("mb-sm-3 mb-md-0", {
									active: tabs_sku === 4
								})}
								onClick={e => toggleNavsSku(e, 4)}
								href="#pablo"
								role="tab"
							>
								<span>Drawers</span>
							</NavLink>
						</NavItem>
					} */}
				</Nav>
				<TabContent activeTab={"tabs_sku" + tabs_sku}>
					<TabPane tabId="tabs_sku1" style={{display: tabs_sku!=1 ? 'none' : 'block'}}>
						{/* <Table style={{tableLayout: 'fixed'}}>
							<thead>
								<tr>
									<th>Property</th>
									<th>Description</th>
									<th>Modified to</th>
								</tr>
							</thead>
							<tbody>
								{
									ComponentStructure.map((item, idx) => (
										
									))
								}
							</tbody>
						</Table> */}
						<Row className='header_row_sku_structure' style={{margin:0}}>
							<Col className='header_sku_structure'>Property</Col>
							<Col className='header_sku_structure'>Description</Col>
							<Col className='header_sku_structure'>Modified</Col>
						</Row>
						<div style={{border: '1px solid #c5c7cf'}} key={key_sku_structure}>
						{
							(cabinet_corner_json_arr[0]).map((item, idx) => (
								<SingleRowComponent key={item.name} initial_item={imported_style && imported_style[item.id]} previous_item={previous_style && previous_style[item.id]} item={item} edited_sku_structure={edited_sku_structure} set_edited_sku_structure={set_edited_sku_structure} parent={edited_sku_structure} all_division_tree_data={all_division_tree_data} set_page_loader={set_page_loader} search_string_sku_structure={search_string_sku_structure} show_only_edited_in_sku_structure={show_only_edited_in_sku_structure} common_props_confirmation_mode={common_props_confirmation_mode} cabinet_corner_json_arr={cabinet_corner_json_arr}/>
							))
						}
						</div>
					</TabPane>
					<TabPane tabId="tabs_sku2" style={{display: tabs_sku!=2 ? 'none' : 'block'}}>
						{/* <Table style={{tableLayout: 'fixed'}}>
							<thead>
								<tr>
									<th>Property</th>
									<th>Description</th>
									<th>Modified to</th>
								</tr>
							</thead>
							<tbody>
								{
									ComponentStructure.map((item, idx) => (
										
									))
								}
							</tbody>
						</Table> */}
						<Row className='header_row_sku_structure' style={{margin:0}}>
							<Col className='header_sku_structure'>Property</Col>
							<Col className='header_sku_structure'>Description</Col>
							<Col className='header_sku_structure'>Modified</Col>
						</Row>
						<div style={{border: '1px solid #c5c7cf'}} key={key_sku_structure_partition}>
							{
								sku_partition_array && sku_partition_array.length ? sku_partition_array.map((partition, index) => (
									<SingleUnitComponent type={'partition'} key={partition.key} initial_item={partition.value} partition={partition} sku_partition_array={sku_partition_array} set_sku_partition_array={set_sku_partition_array} all_division_tree_data={all_division_tree_data} set_page_loader={set_page_loader} edited_sku_structure_partition_array={edited_sku_structure_partition_array} set_edited_sku_structure_partition_array={set_edited_sku_structure_partition_array} index={index}  search_string_sku_structure={search_string_sku_structure} show_only_edited_in_sku_structure={show_only_edited_in_sku_structure} common_props_confirmation_mode={common_props_confirmation_mode} is_cabinet={corner_units.length === 0} cabinet_corner_json_arr={cabinet_corner_json_arr}/>
								))
								:''
							}
							{common_props_confirmation_mode ? '' : <div className='blue_text_edit_sku_structure flex_property pb-2 pt-2' onClick={add_partition}>+ Add Partition</div>}
						</div>
						
					</TabPane>

					<TabPane tabId="tabs_sku3" style={{display: tabs_sku!=3 ? 'none' : 'block'}}>
						{/* <Table style={{tableLayout: 'fixed'}}>
							<thead>
								<tr>
									<th>Property</th>
									<th>Description</th>
									<th>Modified to</th>
								</tr>
							</thead>
							<tbody>
								{
									ComponentStructure.map((item, idx) => (
										
									))
								}
							</tbody>
						</Table> */}
						<Row className='header_row_sku_structure' style={{margin:0}}>
							<Col className='header_sku_structure'>Property</Col>
							<Col className='header_sku_structure'>Description</Col>
							<Col className='header_sku_structure'>Modified</Col>
						</Row>
					
						<div style={{border: '1px solid #c5c7cf'}} key={key_sku_structure_panel}>
							{
								sku_panel_array && sku_panel_array.length ? sku_panel_array.map((panel, index) => (
									<SingleUnitComponent type={'panel'} key={panel.key} partition={panel} initial_item={panel.value} sku_partition_array={sku_panel_array} set_sku_partition_array={set_sku_panel_array} all_division_tree_data={all_division_tree_data} set_page_loader={set_page_loader} edited_sku_structure_partition_array={edited_sku_structure_panel_array} set_edited_sku_structure_partition_array={set_edited_sku_structure_panel_array} index={index}  search_string_sku_structure={search_string_sku_structure} show_only_edited_in_sku_structure={show_only_edited_in_sku_structure} common_props_confirmation_mode={common_props_confirmation_mode} is_cabinet={corner_units.length === 0} cabinet_corner_json_arr={cabinet_corner_json_arr}/>
								))
								:''
							}
							{ common_props_confirmation_mode ? '' : <div className='blue_text_edit_sku_structure flex_property pb-2 pt-2' onClick={add_panel}>+ Add Panel</div> }
						</div>
					</TabPane>
				</TabContent>
			</div>

		</>
	)
}

export default BulkEditSkuStructure;