import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import {Card, Col, Progress, Table, Row, } from "reactstrap";
import Pegasus_Paginator from '../../components/Pegasus_Paginator';
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'antd';
import { AllUsersContext } from '../../components/Contexts/AllUsersContext';
import { handleError } from '../../utils/error';

const ArchiveCatalogue = ({user, submit_for_delete_confirm, set_active_tab, send_analytics_event, store_details, get_store_details,check_route_fn}) => {
	const styles = {
		add_all_archive_data_style:{
			backgroundColor: '#007EF4',
			boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
			borderRadius: '3px',
			height:'32px',
			padding:'0px 24px',
			border:'0px',
			color:'white',
		},
		table_header: {
			fontWeight: "bold",
			fontSize: 'small'
		},
    };
	
	const [all_archive_data, set_all_archive_data ] = useState([]);
	const [show_archive_data_Dialog_Box, set_show_archive_data_Dialog_Box] = useState(false);
	const [active_all_archive_data, set_active_all_archive_data] = useState({});
	const [search_date , set_search_date] = useState('');
	const [display_all_archive_data , set_display_all_archive_data] = useState([]);
	const [key , set_key] = useState('');

	const [page_size , set_page_size] = useState(30)
	const [fetch_progress , set_fetch_progress] = useState(40)
	const [current_page , set_current_page] = useState(1)
	const [initialized , set_initialized] = useState(false) 
 	const [all_pages , set_all_pages] = useState([])
	const alert = useAlert()
	const [ start_index , set_start_index ] = useState('');
	const [ end_index , set_end_index ] = useState('');
	const [archive_data_map, set_archive_data_map] = useState({});
	const history = useHistory();
	const [dump_status, set_dump_status] = useState(false);
	const all_users = useContext(AllUsersContext)

	function convertTo12Hr (dateTime) {
		let hours = dateTime.getHours();
		const minutes = dateTime.getMinutes();
		const seconds = dateTime.getSeconds();
		const ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12 || 12; 
		const formattedHours = hours < 10 ? '0' + hours : hours;
		const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
		const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
		return `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
	}
	
	const fetch_archive_details = async () => {
        try {
            var resp = await general_fetch({ url: 'catalog_dump/get_all_of_store', body: {business_unit_id: user.current_business_unit_id}});
            set_initialized(true);
			var all_archive_data_details= resp && resp.length ? resp:[]
			let final_archive_data_details = []
			let temp_archive_data_map = {}
			if(all_archive_data_details && all_archive_data_details.length){
				all_archive_data_details.map((single_data, idx) => {
					let datetime = single_data.datetime.split('.')[0]
					if(temp_archive_data_map[datetime]){
						temp_archive_data_map[datetime]++
					}else{
						temp_archive_data_map[datetime] = 1
						final_archive_data_details.push(single_data)
					}
				})
			}
			set_archive_data_map(temp_archive_data_map)
			console.log('all_archive_data_details', temp_archive_data_map, final_archive_data_details)
            set_all_archive_data(final_archive_data_details);
            set_display_all_archive_data(final_archive_data_details);
			//console.log('all_archive_data======>',all_archive_data);
        } catch(err) {
            console.log(err);
        }
    }

	// useEffect(() => {
	// 	console.log('all_archive_data======>',all_archive_data);
	// }
	// , [all_archive_data]);
	const fetch_catalog_dump_details = async() => {
		try {
			var dump_resp = await general_fetch({url: 'catalog_dump_request/get', body:{business_unit_id: user.current_business_unit_id}});
			let temp = dump_resp.find(item => item.type == "catalog_dump");
			if(temp){
				temp.created_at_date = temp.created_at.split('T')[0]
				let createdDate = new Date(temp.created_at)
				temp.created_at = convertTo12Hr(createdDate)
				let req_sent_user = all_users.find(item => item.id == temp.user_id)
				temp.user_name = req_sent_user.first_name + ' ' + req_sent_user.last_name
				set_dump_status(temp);
			}
			else
				set_dump_status(false);
		} catch(err) {
			console.log(err)
			handleError(err)
		}
	}

	useEffect(() => {
		if(all_users) {
			fetch_catalog_dump_details()
		}
	}, [user.current_business_unit_id, all_users])

	useEffect(() => {
		if(!all_archive_data.length){
			fetch_archive_details();
		}
	}
	, [user]);


	// const remove_all_archive_data = async (id) => {
	// 	try {
	// 		var identifiers = JSON.stringify({id:id})
	// 		var body ={identifiers} 
	// 		var response = await general_fetch({ url: 'catalog_dump/get_all_of_store', body});
	// 		alert.success('Archive Data Deleted Successfully')
	// 		fetch_archive_details();
	// 	} catch(err) { 
	// 		console.log(err);
	// 	}
	// }

	const open_all_archive_data_update_dialog = (all_archive_data) => {
		set_show_archive_data_Dialog_Box(true);
		set_active_all_archive_data(all_archive_data);
		set_key('update');
	}

	const close_archive_data_Dialog_Box = () => {
		set_show_archive_data_Dialog_Box(false);
		set_active_all_archive_data({});
		set_key('');
	}

	const open_all_archive_data_add_dialog = () => {
		set_show_archive_data_Dialog_Box(true);
		set_key('add');
	}
	
	const filter_all_archive_data = () => 
	{
		var filtered_all_archive_data = [...all_archive_data];
		var lower_search_date = search_date ? JSON.stringify(new Date(search_date)):'';
		console.log('lower_search_date',lower_search_date)
		if(lower_search_date){
			filtered_all_archive_data = filtered_all_archive_data.filter( all_archive_data => JSON.stringify(all_archive_data.datetime)===lower_search_date)
		}
		set_display_all_archive_data(filtered_all_archive_data)
	}

	const custom_report_daily_dump = async () => {
		try {
			var resp = await general_fetch({url: 'catalog_dump_request/create', body: {business_unit_id: user.current_business_unit_id, type: 'catalog_dump'}})
			alert.success('Custom Report Request created successfully')
			fetch_catalog_dump_details()
		} catch(err) {
			alert.error('Couldn\'t process your request')
			console.log(err)
			handleError(err)
		}
	}

	useEffect(() => {
			filter_all_archive_data();
			// console.log('searchmnjkasbjhbsdbfkls',search_date)
	}, [search_date]);

	useEffect(() => {
		// check_route_fn('ArchiveCatalogue')
		set_active_tab('ArchiveCatalogue')
		send_analytics_event('auto', 'ArchiveCatalogue', 'ArchiveCatalogue', 1, window.location.href)
	}, []);

	useEffect(() => {
		if (initialized) {
			var pages = Number(Math.ceil(display_all_archive_data.length / page_size));
			//console.log('all pages --> ', pages, 'projects length --<>', display_all_archive_data.length)
			var tmp = Array.apply('', {length: pages + 1}).map(Number.call, Number)
			tmp.splice(0, 1);
			//console.log('tmp --<>', tmp)
			set_all_pages(tmp);
			//console.log('tmp spliced--<>', tmp)
			set_current_page(1);
		}
	}, [ page_size, display_all_archive_data ])

	const Archive_data_Filler = () => (
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
				<tr>
					{ initialized ? (
						<th scope="col"><span style={styles.table_header}><b>No Data to list  </b></span><br/> Try adjusting filters...<br /> &nbsp;</th>
					):(
						<th scope="col"><span style={styles.table_header}><b>Fetching all data </b></span><br/> <br /> Please wait...<br /> <br />
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;</th>
					)}
				</tr>
			</thead>
		</Table>
	);

	const download_archive = async(datetime, json_dump) => {
		try {
			var response = await general_fetch({ url: 'catalog_dump/get_download_url', body:{business_unit_id: user.current_business_unit_id, datetime:datetime.split('.')[0], json_dump}});
			alert.success('Downloading...')
			fetch_archive_details();
			console.log(response)
			if(response) {
				// window.location.href = response
				window.open(response, "_blank")
			}else{
				alert.error('Download Failed!')
			}
		} catch(err) { 
			alert.error('Download Failed!')
			console.log(err);
		}
	}
	const onclick_triger = async(datetime) => {
		try {
			var response = await general_fetch({ url: 'catalog_dump/trigger'});
			alert.success('Trigger Archive Data')
			fetch_archive_details();
		} catch(err) { 
			alert.error('Download Failed!')
			console.log(err);
		}
	}

	const Archive_data_Table = () => (
		<table className="text-left flex_property table_style">
			<thead style={{width:'100%'}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',width:'100%'}}>
				<th className='th_style flex_property' style={{ flex:'1'}}><span>Sl. No.</span></th>
				<th className='th_style flex_property' style={{border:0,flex:'10'}}><span>Date Time</span></th>
				<th className='th_style flex_property' style={{border:0, flex:'1', paddingRight:"20px"}}><span>Download CSV</span></th>
				<th className='th_style flex_property' style={{border:0, flex:'1', paddingRight:"20px"}}><span>Download JSON</span></th>
			</tr>
			</thead>
			<tbody style={{display:"block",width:'100%'}}>
			{
				display_all_archive_data && display_all_archive_data.length && display_all_archive_data.map((all_archive_data, index) => (
				<tr key={index} style={{display:(index+1>=start_index && index<end_index)?'flex':'none'}} className='tr_style display_onhover'>
					<td className='td_style flex_property' style={{ flex:'1'}}><span>{index+1}</span></td>
					<td className='td_style flex_property' style={{flex:'10'}}><span className='table_text_overflow'>{all_archive_data.datetime.split('.')[0]}</span></td>
					<td className='td_style flex_property x1' style={{ justifyContent:'center', flex:'1', paddingRight:"20px"}}>	
						<i 
							style = {{cursor:'pointer'}}
							title='Download Archive Data'
							onClick={()=>download_archive(all_archive_data.datetime, false)}
							className='fa fa-download'
						/>
					</td>
					{
						
						<td className='td_style flex_property x1' style={{ justifyContent:'center', flex:'1', paddingRight:"20px"}}>	
							{
								archive_data_map[all_archive_data.datetime.split('.')[0]]==2?
								<i 
									style = {{cursor:'pointer'}}
									title='Download Archive Data'
									onClick={()=>download_archive(all_archive_data.datetime, true)}
									className='fa fa-download'
								/>:''
							}
						</td>
					}
				</tr>
			))}
			</tbody>
		</table>
	);

	return (
		<Main_Grid active_tab="ArchiveCatalogue" user={user} store_details={store_details} get_store_details={get_store_details}>
			<Row style={{padding:'10px 0px', color:'#22272e'}}>
	        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
	        		Viewing {display_all_archive_data && display_all_archive_data.length ?display_all_archive_data.length:'0'} Archive Data
	        	</Col>
	        	<Col xs={6} style={{textAlign:'right'}}>
					<Tooltip title={dump_status?('A request was already made by ' + dump_status.user_name + ' at ' + dump_status.created_at + ', ' + dump_status.created_at_date + '. Kindly wait until it gets processed'):''} placement={'left'} mouseEnterDelay={0}>
	        			<button disabled={dump_status} onClick={custom_report_daily_dump} style={{...styles.add_all_archive_data_style, cursor: dump_status?'not-allowed':'pointer', opacity: dump_status?'50%':''}}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Request Daily Dump</span></button>
					</Tooltip>
	        	</Col>
        	</Row>
	        <Row style={{height:'calc(100% - 90px)'}}>
		        <Col style={{height:'100%'}}>
		        	<Card style={{borderRadius: 0, height:'100%'}}>
		        		{/* <div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}>
							<i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/>
							<input onChange={(e) => set_search_date(e.target.value)} style={{fontSize:'14px', width:"20%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='date' />
						</div> */}
						<div id='design-paginator' style={{height:'calc(100% )'}}>{display_all_archive_data && display_all_archive_data.length ? (<Archive_data_Table />) : (<Archive_data_Filler />)}</div>
					</Card>
		        </Col>
	        </Row>
	        <Row style={{height:'3px'}}>
		        <Col>
		        </Col>
	        </Row>
	        <Row>
		        <Col>	
					<Pegasus_Paginator 
						id='design-paginator'
						end_index={end_index}
						set_end_index={set_end_index}
						start_index={start_index}
						set_start_index={set_start_index}
						all_projects={display_all_archive_data && display_all_archive_data.length ? display_all_archive_data.length : '0'}
					/>
		        </Col>
	        </Row>
		</Main_Grid>
	);
}	


export default ArchiveCatalogue;

