import React , { useState , useEffect, useContext }from 'react';
import general_fetch from '../../utils/fetch';
import custom_sort from '../../utils/custom_sort';
import split_cat_type from '../../utils/split_cat_type';
import {Modal, CardImg, Alert, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import { useAlert } from 'react-alert'
import Multiselect from 'multiselect-react-dropdown';
import MFKeyValuePair_dest from '../../components/MFKeyValuePair_dest';
import MFKeyValuePair_source from '../../components/MFKeyValuePair_source';
import MFKeyValuePair_applicable from '../../components/MFKeyValuePair_applicable';
import EscapeWarning from '../../components/EscapeWarning';
import { UserContext } from '../../components/Contexts/UserContext';
import SkuSelectionModal from '../../components/SkuSelectionModal';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { Tooltip } from 'antd';
//import AlertBox from "../../components/AlertBox/";

const styles = {
	error_style: {
		color: "red",
		fontSize: 'small'
	},

};

const units_json = [{id:'panel_area', name:'Panel Area'},{id:'ply_area', name:'Ply Area'}]

const Subunit_options_json = [
	{
		sub_unit:'Carcass Excluding Back Panel',
		id:'carcass_excluding_back_panel',
		sub_component:[
			{
				name:'Finish',
				id:'finish',
				properties:[
					// {
					// 	name:'Category',
					// 	id:'category',
					// 	type:'select'
					// },{
					// 	name:'Sub Category',
					// 	id:'subcategory',
					// 	type:'select'
					// },
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					}
				],

			},{
				name:'Ply',
				id:'ply',
				properties:[
					// {
					// 	name:'Category',
					// 	id:'category',
					// 	type:'select',
					// },{
					// 	name:'Sub Category',
					// 	id:'subcategory',
					// 	type:'select'
					// },
					{
						name:'Sales Channel',
						id:'sales_channel',
						type:'multi-select',
					},
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},{
						name:'Prelam Type',
						id:'prelam_type',
						type:'prelam_type',
					}
				],

			},
			// {
			// 	type:'Metadata',
			// 	id:'metadata',
			// 	properties:[
			// 		{
			// 			name:'Tag',
			// 			id:'tag',
			// 			type:'multi-select'
			// 		}
			// 	],

			// }
		],
	},
	{
		sub_unit: 'Panel',
		id: 'panel',
		sub_component: [
			{
				name: 'Panel',
				id: 'panel',
				properties:[
					{
						name: 'Panel Type',
						id: 'panel_type',
						type: 'panel_type'
					}
				]
			},{
				name:'Ply',
				id:'ply',
				properties:[
					// {
					// 	name:'Sku',
					// 	id:'sku',
					// 	type:'select'
					// },
					{
						name:'Sales Channel',
						id:'sales_channel',
						type:'multi-select',
					},
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},{
						name:'Prelam Type',
						id:'prelam_type',
						type:'prelam_type',
					}
				],

			},{
				name:'Internal Finish',
				// cat_type:'cabinet_finish_finish',
				division:'finish',
				id:'internal_finish',
				properties:[
					// {
					// 	name:'Sku',
					// 	id:'sku',
					// 	type:'select'
					// },
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},{
						name:'Thickness',
						id:'thickness',
						type:'number'
					}
				],

			},{
				name:'External Finish',
				// cat_type:'cabinet_finish_finish',
				division:'finish',
				id:'external_finish',
				properties:[
					// {
					// 	name:'Sku',
					// 	id:'sku',
					// 	type:'select'
					// },
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},{
						name:'Thickness',
						id:'thickness',
						type:'number'
					}
				],

			}
		]
	},
	{
		sub_unit:'Back Panel',
		id:'back_panel',
		sub_component:[
			{
				name:'Finish',
				id:'finish',
				properties:[
					// {
					// 	name:'Category',
					// 	id:'category',
					// 	type:'select'
					// },{
					// 	name:'Sub Category',
					// 	id:'subcategory',
					// 	type:'select'
					// },
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					}
				],

			},{
				name:'Ply',
				id:'ply',
				properties:[
					// {
					// 	name:'Category',
					// 	id:'category',
					// 	type:'select',
					// },{
					// 	name:'Sub Category',
					// 	id:'subcategory',
					// 	type:'select'
					// },
					{
						name:'Sales Channel',
						id:'sales_channel',
						type:'multi-select',
					},
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},{
						name:'Prelam Type',
						id:'prelam_type',
						type:'prelam_type',
					}
				],

			},
			// {
			// 	type:'Metadata',
			// 	id:'metadata',
			// 	properties:[
			// 		{
			// 			name:'Tag',
			// 			id:'tag',
			// 			type:'multi-select'
			// 		}
			// 	],

			// }
		],
	},{
		sub_unit:'Custom Panel (standalone)',
		id:'custom_panel_standalone',
		sub_component:[
			{
				name:'Finish',
				id:'finish',
				properties:[
					// {
					// 	name:'Category',
					// 	id:'category',
					// 	type:'select'
					// },{
					// 	name:'Sub Category',
					// 	id:'subcategory',
					// 	type:'select'
					// },
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					}
				],

			},{
				name:'Ply',
				id:'ply',
				properties:[
					// {
					// 	name:'Category',
					// 	id:'category',
					// 	type:'select',
					// },{
					// 	name:'Sub Category',
					// 	id:'subcategory',
					// 	type:'select'
					// },
					{
						name:'Sales Channel',
						id:'sales_channel',
						type:'multi-select',
					},
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},{
						name:'Prelam Type',
						id:'prelam_type',
						type:'prelam_type',
					}
				],

			},
			// {
			// 	type:'Metadata',
			// 	id:'metadata',
			// 	properties:[
			// 		{
			// 			name:'Tag',
			// 			id:'tag',
			// 			type:'multi-select'
			// 		}
			// 	],

			// }
		],
	}, {
		sub_unit:'Shutter',
		id:'shutter',
		sub_component:[
			{
				name:'Finish',
				id:'finish',
				properties:[
					// {
					// 	name:'Category',
					// 	id:'category',
					// 	type:'select'
					// },{
					// 	name:'Sub Category',
					// 	id:'subcategory',
					// 	type:'select'
					// },
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					}
				],

			},{
				name:'Ply',
				id:'ply',
				properties:[
					// {
					// 	name:'Category',
					// 	id:'category',
					// 	type:'select',
					// },{
					// 	name:'Sub Category',
					// 	id:'subcategory',
					// 	type:'select'
					// },
					{
						name:'Sales Channel',
						id:'sales_channel',
						type:'multi-select',
					},
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},{
						name:'Prelam Type',
						id:'prelam_type',
						type:'prelam_type',
					}
				],

			}, {
				name:'Shutter Design',
				id:'shutter_design',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					}
				],

			}
		],
	}
	,{
		sub_unit:'Shelf',
		id:'shelf',
		sub_component:[
			{
				name:'Finish',
				id:'finish',
				properties:[
					// {
					// 	name:'Category',
					// 	id:'category',
					// 	type:'select'
					// },{
					// 	name:'Sub Category',
					// 	id:'subcategory',
					// 	type:'select'
					// },
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					}
				],

			},{
				name:'Ply',
				id:'ply',
				properties:[
					{
						name:'Sales Channel',
						id:'sales_channel',
						type:'multi-select',
					},
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},{
						name:'Prelam Type',
						id:'prelam_type',
						type:'prelam_type',
					},
				],

			},
		],
	},
	{
		sub_unit:'Drawer Fascia',
		id:'drawer_fascia',
		sub_component:[
			{
				name:'Shutter Design',
				id:'shutter_design',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					}
				],

			}, {
				name:'Finish',
				id:'finish',
				properties:[
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					}
				],

			}, {
				name:'Ply',
				id:'ply',
				properties:[
					{
						name:'Sales Channel',
						id:'sales_channel',
						type:'multi-select',
					},
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},{
						name:'Prelam Type',
						id:'prelam_type',
						type:'prelam_type',
					},
				],

			}
		],
	}
]

const component_applicable_json = [
	{
		name:'Cabinet',
		id:'cabinet',
		sub_component:[
			{
				name:'Cabinet',
				id:'cabinet',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name:'Height',
						id:'height',
						type:'number'
					},
					{
						name:'Width',
						id:'width',
						type:'number'
					},
					{
						name:'Depth',
						id:'depth',
						type:'number'
					},{
						name:'No Condition',
						id:'no_condition',
						type:'select'
					},
				],

			}
		]
	},
	{
		name:'Wardrobe',
		id:'wardrobe',
		sub_component:[
			{
				name:'Wardrobe',
				id:'wardrobe',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name:'Height',
						id:'height',
						type:'number'
					},
					{
						name:'Width',
						id:'width',
						type:'number'
					},
					{
						name:'Depth',
						id:'depth',
						type:'number'
					},{
						name:'No Condition',
						id:'no_condition',
						type:'select'
					},
				],

			}
		]
	},
	{
		name:'Custom Panel (standalone)',
		id:'custom_panel_standalone',
		sub_component:[
			{
				name:'Custom Panel (standalone)',
				id:'custom_panel_standalone',
				properties:[
					{
						name:'Tag',
						id:'tag',
						type:'multi-select'
					},
					{
						name:'Height',
						id:'height',
						type:'number'
					},
					{
						name:'Width',
						id:'width',
						type:'number'
					},
					{
						name:'Depth',
						id:'depth',
						type:'number'
					},{
						name:'No Condition',
						id:'no_condition',
						type:'select'
					},
				],

			}
		]
	},
	{
		name:'Design',
		id:'design',
		sub_component:[
			{
				name:'Design',
				id:'design',
				properties:[
					{
						name:'Sales Channel',
						id:'sales_channel',
						type:'multi-select'
					},
					{
						name:'No Condition',
						id:'no_condition',
						type:'select'
					},
				],

			}
		]
	},
]


const fields_to_be_updated = [
	{
		id: "panel_wastage_factor",
		name: "Panel Wastage Factor",
		json_value:'percentage',
		type: 'number'
	},
	{
		id: "edgeband_wastage_factor",
		name: "Edgeband Wastage Factor",
		json_value:'percentage',
		type: 'number'
	},
	{
		id: "panel_premilling_value",
		name: "Panel Premilling Value",
		json_value:'',
		type: 'number'
	},
	// {
	// 	id: "edgeband_sku",
	// 	name: "Edgeband Sku",
	// 	json_value:'edge_band_sku',
	// 	type: 'select',
	// },
	{
		id: "edgeband_thickness_visible",
		name: "Visible Edgeband Thickness",
		json_value:'',
		type: 'number'
	},
	{
		id: "edgeband_height_visible",
		name: "Visible Edgeband Tape Width",
		json_value:'',
		type: 'number'
	},
	{
		id: "edgeband_thickness_invisible",
		name: "Non-Visible Edgeband Thickness",
		json_value:'',
		type: 'number'
	},
	{
		id: "edgeband_height_invisible",
		name: "Non-Visible Edgeband Tape Width",
		json_value:'',
		type: 'number'
	},
	{
		id: "edgeband_glue_sku",
		name: "Edgeband Glue Sku",
		json_value:'edge_band_glue_sku',
		type: 'select',
	},
	{
		id: "panel_glue_sku",
		name: "Panel Glue Sku",
		json_value:'panel_glue_sku',
		type: 'select',
	},
	{
		id: "panel_conversion_cost",
		name: "Panel Conversion Cost",
		json_value:'cost',
		type: 'number'
	},
	{
		id: "panel_conversion_cost_basis",
		name: "Panel Conversion Cost Basis",
		json_value:'units', /* other possible value is "ply_area"*/
		type: "select" 
	},
	{
		id: "cabinet_package_material_cost",
		name: "Cabinet Package Material Cost",
		json_value:'cost',
		type: 'number'
	},
	{
		id: "cabinet_package_material_cost_basis",
		name: "Cabinet Package Material Cost Basis",
		json_value:'units', /* other possible value is "ply_area"*/
		type: "select" 
	},
	{
		id: "cabinet_assembly_cost",
		name: "Cabinet Assembly Cost",
		json_value:'cost',
		type: 'number'
	},
	{
		id: "cabinet_assembly_cost_basis",
		name: "Cabinet Assembly Cost Basis",
		json_value:'units', /* other possible value is "ply_area"*/
		type: "select"
	},
	{
		id: "ply_panel_area_category",
		name: "Additional Panel Level Hardware",
		json_value:'ply_panel_area',
		type: 'select'
	}
]

const MFRulesDialogBox = ({open , handleClose , active_rule, is_active_rule_valid, fetch_mf_rules_details , mode, set_page_loader, all_division_tree_data, all_category, all_sub_category, all_edge_band_sku, all_edge_band_glue_sku, all_panel_glue_sku,all_sales_ch, last_item_order, tags}) => {

	const [rule_name , set_rule_name] = useState("");
	const [rule_description , set_rule_description] = useState("");
	const [item_json_applicable , set_item_json_applicable] = useState([]);
	const [item_json_source , set_item_json_source] = useState([]);
	const [item_json_dest , set_item_json_dest] = useState([]);
	const [cabinet_subunit , set_cabinet_subunit] = useState("");
	const [subunit_component_json , set_subunit_component_json] = useState([]);
	const [rule_word ,set_rule_word ] =useState('')
	const [source_rule_word ,set_source_rule_word ] =useState('')
	const [dest_rule_word ,set_dest_rule_word ] =useState('')
	const [active_rule_copy ,set_active_rule_copy ] =useState()
	const [source_condition_combination_logic ,set_source_condition_combination_logic ] =useState('and')
	const [dest_condition_combination_logic ,set_dest_condition_combination_logic ] = useState('and')
	const [ applicability_combination_logic, set_applicability_combination_logic] = useState('and')
	const [mf_rule_step, set_mf_rule_step] = useState('1');
	const [is_back_button, set_is_back_button] = useState(false);
	const [rule_activation, set_rule_activation] = useState(false);
	const [discard_changes, set_discard_changes] = useState(false)
	const [show_escape_warning, set_show_escape_warning] = useState(false)
	const alert = useAlert()
	const user = useContext(UserContext)
	const org_bu_view = useContext(OrgBuContext)

	const [is_sku_selection_modal_open, set_is_sku_selection_modal_open] = useState(false);
	const open_sku_selection_modal = () => set_is_sku_selection_modal_open(true)
	const close_sku_selection_modal = () => set_is_sku_selection_modal_open(false)
	const [sku_selection_map, set_sku_selection_map] = useState([]);

	const [trigger_red_marker, set_trigger_red_marker] = useState(false);

	useEffect(() => {
		if(mode != 'add') set_trigger_red_marker(!is_active_rule_valid);
		else set_trigger_red_marker(false)
	}, [mode])


	const transform_to_usable = (item_array, position_type) => {
		console.log("transform", item_array);
		if(item_array && item_array.length){
			var x_temp = item_array.map(x_item =>{
				console.log("tags",x_item.tag)
			return({
				id:x_item && x_item.id?x_item.id:null,
				value:(x_item.value && (Array.isArray(x_item.value) && x_item.value.length))? 
					(
						x_item.property && x_item.property[0] && ['brand'].includes(x_item.property[0].id)?
							JSON.stringify(x_item.value.map(x => x.id)) 
							:
							x_item.value.map(x => x.id)
					)
					:
					(
						x_item.property[0].type==='number'
						?
							Number(x_item.value)
						:
						typeof x_item.value === 'object'
						?
							JSON.stringify(x_item.value)
						:
							x_item.value
					),
				min_value: (x_item.min_value || x_item.min_value == 0)?Number(x_item.min_value):'',
				max_value: (x_item.max_value || x_item.max_value == 0)?Number(x_item.max_value):'',
				type: x_item.type, 
				operator:x_item.operator, 
				value_combination_logic:x_item.value_combination_logic, 
				sku: x_item.property && x_item.property.length && fields_to_be_updated.find(o => o.id === x_item.property[0].id) && (x_item.property[0].id === 'ply_panel_area_category' || x_item.property[0].id === 'panel_glue_sku' || x_item.property[0].id === 'edgeband_glue_sku') && x_item.sku && x_item.sku.length? x_item.sku.map(x => x.id):null,
				tag:x_item.property && x_item.property.length && x_item.property[0].id == 'tag' && x_item.tag && x_item.tag.length? x_item.tag.map(x => x.id):null,
				sales_channel:x_item.property && x_item.property.length && x_item.property[0].id == 'sales_channel' && x_item.sales_channel && x_item.sales_channel.length? x_item.sales_channel.map(x => x.id):null,
				source_filter:(x_item.component && x_item.component.length) || ['condition', 'action'].includes(position_type)? ({
					id:x_item.source_filter && x_item.source_filter.id?x_item.source_filter.id:null,
					type:'source',
					component:x_item.component && x_item.component.length?
					 	x_item.component[0].id
						:
						(['action','condition'].includes(position_type) && cabinet_subunit ?cabinet_subunit:''),
					sub_component:['action'].includes(position_type) ?cabinet_subunit:x_item.subcomponent && x_item.subcomponent.length? x_item.subcomponent[0].id:'',
					property:x_item.property && x_item.property.length? x_item.property[0].id:'',
				}) : null,
				destination_filter: (x_item.relativecomponent && x_item.relativecomponent.length) ? ({
					id:x_item.destination_filter && x_item.destination_filter.id?x_item.destination_filter.id:null,
					type: 'destination',
					component:x_item.relativecomponent && x_item.relativecomponent.length? x_item.relativecomponent[0].id:[],
					sub_component:x_item.relativesubcomponent && x_item.relativesubcomponent.length? x_item.relativesubcomponent[0].id:[],
					property:x_item.relativeproperty && x_item.relativeproperty.length? x_item.relativeproperty[0].id:[],
				}) : null,
			})})

			let parameter_json = {}

			for(let i = 0; i < x_temp.length; i++){
				if(x_temp[i].source_filter){
					if(x_temp[i].source_filter.property == 'tag'){
						console.log('transform_to_usablee tag')
						if(!(x_temp[i].tag && x_temp[i].tag.length && typeof x_temp[i].tag[0] === 'string')) { return -1}
					}else if(['panel_type', 'prelam_type'].includes(x_temp[i].source_filter.property)){
						if(!(x_temp[i].value && x_temp[i].value.length && typeof x_temp[i].value[0] === 'string')) return -1
					}else if(fields_to_be_updated.find(o => o.id === x_temp[i].source_filter.property) && (x_temp[i].source_filter.property === 'ply_panel_area_category' || x_temp[i].source_filter.property === 'panel_glue_sku' || x_temp[i].source_filter.property === 'edgeband_glue_sku') && x_temp[i].type == 'absolute'){
						console.log('transform_to_usablee sku')
						if(!(x_temp[i].sku && x_temp[i].sku.length && typeof x_temp[i].sku[0] === 'string')) {return -1}
					}else if(x_temp[i].source_filter.property == 'height' || x_temp[i].source_filter.property == 'width' || x_temp[i].source_filter.property == 'depth' || x_temp[i].source_filter.property == 'thickness'){
						console.log('transform_to_usablee height')
						if(!(x_temp[i].value >=0 && typeof x_temp[i].value === 'number')) { return -1}
					}else if(x_temp[i].source_filter.property == 'sales_channel'){
						console.log('transform_to_usablee sales_channel')
						if(!(x_temp[i].sales_channel && x_temp[i].sales_channel.length && typeof x_temp[i].sales_channel[0] === 'string')) { return -1}
					}

					if(position_type == 'action'){
						if(parameter_json[x_temp[i].source_filter.property]){
							let field_name = fields_to_be_updated.find(o => o.id == x_temp[i].source_filter.property).name
							alert.error(field_name+' is being set more than once')
							return 'duplicate parameter'
						}else{
							parameter_json[x_temp[i].source_filter.property] = 1
						}
					}
				}
			} 

			console.log('transform_to_usable', x_temp)
			return x_temp;
		}else{
			return []
		}
	} 

	const are_properties_valid = (item_json) => {
		for(let i=0; i<item_json.length; i++){
			if(!(item_json && item_json.length && item_json[i].property && Array.isArray(item_json[i].property) && item_json[i].property.length)) return false;
		}
		return true;
	}

	const onclick_add_new_rule = async() => {
		console.log("entered onclick ")
		if(!is_valid_add_key(item_json_source)){
			set_trigger_red_marker(true);
			alert.error("Empty or Duplicate key values not allowed.")
		}else{
			console.log("entered first else")
			if(rule_name && rule_description && are_properties_valid(item_json_applicable) && are_properties_valid(item_json_source)){
				console.log("if")
				try {
					console.log("entered try")

					let applicability = transform_to_usable(item_json_applicable, 'applicable')
					let conditions = transform_to_usable(item_json_source, 'condition')
					let actions = transform_to_usable(item_json_dest, 'action')
					if(applicability == -1 || conditions == -1 || actions == -1) 
					{
						set_trigger_red_marker(true);
						alert.error('Please fill all required details: name, description, applicable and action fields ')
						return
					}else if(actions == 'duplicate parameter'){
						return
					}

					var temp_order = last_item_order+1024
					var body = {
						sku_linked_rule_type:'bu_level',
						bu_level_linked_rule_sku_ids: sku_selection_map && sku_selection_map.length ? sku_selection_map : [],
						applicability_combination_logic,
						applicability:applicability,
						conditions:conditions, 
						actions: actions, 
						condition_combination_logic:source_condition_combination_logic, 
						name:rule_name, 
						description:rule_description, 
						active:rule_activation, 
						type:'manufacturing_rule',
						action_combination_logic: dest_condition_combination_logic,
						order: temp_order,
						// business_unit_id:user.current_business_unit_id
					};
					if(org_bu_view == 'bu'){
						body["business_unit_id"] = user.current_business_unit_id
					}
					console.log("adding")
					var response = await general_fetch({ url: 'automation_rule/create', body});	
					onclick_handleclose()
					alert.success('Rule created Successfully !')
					fetch_mf_rules_details()
				} catch(err) {
					console.log("entered catch")
					console.log(err);
					alert.error("Please check input values or API failed.")
				}
			}else{
				set_trigger_red_marker(true);
				alert.error('Please fill all required details: name, description, applicable and action fields ')
			}
		}	
	}

	const onclick_edit_rule = async() => {
		if(!is_valid_add_key(item_json_source) ){
			set_trigger_red_marker(true);
			alert.error("Empty or Duplicate key values not allowed.")
		}else{
			if(active_rule && rule_name && rule_description && are_properties_valid(item_json_applicable) && are_properties_valid(item_json_source)){
				try {

					let applicability = transform_to_usable(item_json_applicable, 'applicable')
					let conditions = transform_to_usable(item_json_source, 'condition')
					let actions = transform_to_usable(item_json_dest, 'action')
					if(applicability == -1 || conditions == -1 || actions == -1) 
					{
						set_trigger_red_marker(true);
						alert.error('Please fill all required details: name, description, applicable and action fields ')
						return
					}else if(actions == 'duplicate parameter'){
						return
					}

					var body = {
						id:active_rule.id?active_rule.id:'',
						sku_linked_rule_type:'bu_level',
						bu_level_linked_rule_sku_ids: sku_selection_map && sku_selection_map.length ? sku_selection_map : [],
						applicability_combination_logic,
						applicability:applicability,
						conditions:conditions, 
						actions: actions, 
						condition_combination_logic:source_condition_combination_logic, 
						name:rule_name, 
						description:rule_description, 
						active:rule_activation, 
						type:'manufacturing_rule',
						action_combination_logic: dest_condition_combination_logic,
						order: last_item_order + 1024
					};
					if(org_bu_view == 'bu'){
						body["business_unit_id"] = user.current_business_unit_id
					}
					var response = await general_fetch({ url: 'automation_rule/update', body});	
					onclick_handleclose()
					alert.success('Rule Updated Successfully !')
					fetch_mf_rules_details()
				} catch(err) {
					set_trigger_red_marker(true);
					console.log(err);
					alert.error("Please check input values or APi failed.")
				}
			}else{
				alert.error('Please fill all required details: name, description, applicable and action fields ')
			}	
		}
	}


	const is_valid_add_key = (item_json) => {
		if(item_json && item_json.length){
			for(var i=0;i<item_json.length;i++){
				if(item_json[i] && !item_json[i].property.length){
					return false;
				}
			}
			return true;
		}else{
			return true;
		}
	}
	
	useEffect(() => {

		if(active_rule){
			if(active_rule.bu_level_linked_skus && active_rule.bu_level_linked_skus.length){
				set_sku_selection_map(active_rule.bu_level_linked_skus.map(x => x.id))
			}
			console.log('inside init run active rule ', active_rule);
			var x_source_list = []; 
			var x_dest_list = []; 
			var x_applicable_list = active_rule.applicability&& active_rule.applicability.length?active_rule.applicability.map(x_item => {
				
				var x_component = component_applicable_json?component_applicable_json.filter(x =>x.id===x_item.source_filter.component):[]
				var x_sub_component = x_component && x_component.length && x_component[0].sub_component.length? x_component[0].sub_component.filter(x => x.id===x_item.source_filter.sub_component):[]
				var x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length? x_sub_component[0].properties.filter(x =>x.id===x_item.source_filter.property):[]
				
				if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='tag'){
                    var x_selected_value_list = tags && tags.length ?tags.filter(x => x_item.tag.includes(x.id)):[]
                    x_item.tag=x_selected_value_list
                }
				if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='sales_channel'){
                    var x_selected_value_list = all_sales_ch && all_sales_ch.length ?all_sales_ch.filter(x => x_item.sales_channel && x_item.sales_channel.includes(x.id)):[]
                    x_item.sales_channel=x_selected_value_list;
                }

				return{
					...x_item, 
					component:x_component, 
					subcomponent:x_sub_component, 
					property:x_selected_property
				}
			}):[]
			x_source_list = active_rule.conditions&& active_rule.conditions.length?active_rule.conditions.map(x_item => {
				
				var x_component = Subunit_options_json?Subunit_options_json.filter(x =>x.id===x_item.source_filter.component):[]
				var x_sub_component = x_component && x_component.length && x_component[0].sub_component.length? x_component[0].sub_component.filter(x => x.id===x_item.source_filter.sub_component):[]
				var x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length? x_sub_component[0].properties.filter(x =>x.id===x_item.source_filter.property):[]

				if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='tag'){
                    var x_selected_value_list = tags && tags.length ?tags.filter(x => x_item.tag.includes(x.id)):[]
                    x_item.tag=x_selected_value_list
                }
				if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='sales_channel'){
                    var x_selected_value_list = all_sales_ch && all_sales_ch.length ?all_sales_ch.filter(x => x_item.sales_channel && x_item.sales_channel.includes(x.id)):[]
                    x_item.sales_channel=x_selected_value_list;
                }

				return{
					...x_item, 
					component:x_component, 
					subcomponent:x_sub_component, 
					property:x_selected_property
				}
			}):[]
			x_dest_list = active_rule.actions && active_rule.actions.length?active_rule.actions.map(x_item => {
				var x_component = []
				var x_sub_component = []
				var x_selected_property = fields_to_be_updated?fields_to_be_updated.filter(x =>x_item.source_filter && x.id===x_item.source_filter.property):[]

				if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='edgeband_glue_sku'){
                    var x_selected_value_list = all_edge_band_glue_sku && all_edge_band_glue_sku.length ?all_edge_band_glue_sku.filter(x => x_item.sku.includes(x.id)):[]
                    x_item.sku=x_selected_value_list
                }
				if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='panel_glue_sku'){
                    var x_selected_value_list = all_panel_glue_sku && all_panel_glue_sku.length ?all_panel_glue_sku.filter(x => x_item.sku.includes(x.id)):[]
                    x_item.sku=x_selected_value_list;
                }
				if(x_selected_property && x_selected_property.length && x_selected_property[0].id === 'ply_panel_area_category'){
					var x_selected_value_list = all_panel_glue_sku && all_panel_glue_sku.length ?all_panel_glue_sku.filter(x => x_item.sku.includes(x.id)):[]
					x_item.sku = x_selected_value_list;
					x_item.value = JSON.parse(x_item.value);
				}
				console.log("printing", {...x_item})
				return{
					...x_item, 
					component:x_component, 
					subcomponent:x_sub_component, 
					property:x_selected_property,
				}
			}):[]
			set_source_condition_combination_logic(active_rule.condition_combination_logic);
			set_applicability_combination_logic(active_rule.applicability_combination_logic);
			set_dest_condition_combination_logic(active_rule.action_combination_logic);

			set_cabinet_subunit(x_source_list[0] && x_source_list[0].component[0]?x_source_list[0].component[0].id:'')
			set_item_json_source(x_source_list)
			console.log("printing value", x_dest_list);
			set_item_json_dest([ ...x_dest_list.map(y => ({ ...y}))])
			set_item_json_applicable(x_applicable_list)

			set_rule_name(active_rule.name)
			set_rule_description(active_rule.description)
			set_rule_activation(active_rule.active ? true : false)
			// set_condition_combination_logic(active_rule.condition_combination_logic)
			set_active_rule_copy(JSON.parse(JSON.stringify(active_rule)))
		}
	},[active_rule])

	useEffect(() => {
		if(open){
			// console.log('all_values======>', all_division_tree_data, all_category, all_sub_category, all_edge_band_sku, all_edge_band_glue_sku, all_panel_glue_sku)
			mode==='add' && set_cabinet_subunit(Subunit_options_json[0].id)
		}
	},[open])

	useEffect(() => {
		if(cabinet_subunit){
			var x_json = Subunit_options_json.find(x => x.id===cabinet_subunit)
			set_subunit_component_json(x_json)
		}
	},[cabinet_subunit])
	
	const add_new_item_json_source = () => {
		if(is_valid_add_key(item_json_source)){
            let a = [...item_json_source];
			let curr_time = Date.now();
			a.push({temp_key: curr_time, condition_type:'absolute',component:[], subcomponent:[], sales_channel:[], property:[] ,relativecomponent:[], relativesubcomponent:[], relativeproperty:[], operator:'equal', division:[], category:[], sub_cat:[], group:[], sku:[], range_max:'', range_min:'', value:'', tag:[], value_combination_logic:'and'})
			set_item_json_source(a);
        }else{
            alert.error("Empty or Duplicate key values not allowed.")
        }	
	}

    useEffect(() =>{
		if(item_json_source && !item_json_source.length){
			add_new_item_json_source()
		}
	},[item_json_source])

	const add_new_item_json_dest = () => {
		if(is_valid_add_key(item_json_dest)){
            let b = [...item_json_dest];
			let curr_time = Date.now();
			b.push({temp_key: curr_time, condition_type:'absolute',component:[], subcomponent:[], property:[] ,relativecomponent:[], relativesubcomponent:[], relativeproperty:[], operator:'equal', division:[], category:[], sub_cat:[], group:[], sku:[], range_max:'', range_min:'', value:'', tag:[], value_combination_logic:'and'})
			set_item_json_dest(b);
        }else{
            alert.error("Empty or Duplicate key values not allowed.")
        }
	}

    useEffect(() =>{
		if(item_json_dest && !item_json_dest.length){
			add_new_item_json_dest()
		}else{
			
		}
	},[item_json_dest])

	useEffect(() => {
		console.log("comb logic change --> ", applicability_combination_logic);
	}, [applicability_combination_logic])

	const add_new_item_json_applicable = () => {
		if(is_valid_add_key(item_json_applicable)){
            let a = [...item_json_applicable];
			let curr_time = Date.now();
			a.push({temp_key: curr_time, condition_type:'absolute',component:[], subcomponent:[], property:[] ,relativecomponent:[], relativesubcomponent:[], relativeproperty:[], operator:'equal', division:[], category:[], sub_cat:[], group:[], sku:[], range_max:'', range_min:'', value:'', tag:[], value_combination_logic:'and'})
			set_item_json_applicable(a);
        }else{
            alert.error("Empty or Duplicate key values not allowed.")
        }	
	}

    useEffect(() =>{
		if(item_json_applicable && !item_json_applicable.length){
			add_new_item_json_applicable()
		}
	},[item_json_applicable])

	useEffect(() => {
		if(discard_changes == true){
			onclick_handleclose()
			set_discard_changes(false)
		}
	},[discard_changes])

	const onclick_cabinet_submit = (e) => {
		set_cabinet_subunit(e.target.value)
		// set_item_json_dest([])
		set_item_json_source([])
	}

	const onclick_handleclose = () => {
		fetch_mf_rules_details()
		handleClose()
		set_cabinet_subunit('')
		set_subunit_component_json('')
		set_item_json_source([])
		set_item_json_dest([])
		set_item_json_applicable([])
		set_rule_description('')
		set_rule_name()
		set_mf_rule_step('1')
		set_is_back_button(false)
		set_source_condition_combination_logic('and')
		set_dest_condition_combination_logic('and')
		set_applicability_combination_logic('and')
		set_rule_activation(false)
		set_sku_selection_map([])
		set_trigger_red_marker(false);
	}

	const show_escape_warning_dialog = () => {
		set_show_escape_warning(true)
	}

	const close_escape_warning_dialog = () => {
		set_show_escape_warning(false)
	}

	const values_text = (values_Array, logic, type) => {
		var x_text=''
		values_Array && Array.isArray(values_Array) && values_Array.length ? 
			values_Array.map((x_id,idx) => {	
				x_text = type.id==='tag'?
					( x_text.concat(values_Array.length-1!=idx?x_id.tag.toLowerCase()+' '+logic+' '+' ':x_id.tag.toLowerCase()))
					:
					( x_text.concat(values_Array.length-1!=idx?x_id.name.toLowerCase()+' '+logic+' '+' ':x_id.name.toLowerCase()))
			})
		:
			x_text = (logic+ ' ' + values_Array);
		return x_text;
	}

	const get_all_data_fom_id = (all_val_array, item) => {
		var prop_val_ids = item.value && item.value.length ?item.value.map(x => x.id):[]
		var o_list = all_val_array.filter(x=>prop_val_ids.includes(x.id))
		return o_list;
	}

	const return_word = (value) => {
		if(value==='lessequal'){
			return 'less than equal to'
		}else if(value==='greaterequal') {
			return 'greater than equal to'
		}else if(value==='less') {
			return 'less than'
		}else if(value==='greater') {
			return 'greater than' 
		}else{
			return 'equal to'
		}
	}

	const onclick_preview =() => {
		if(!is_valid_add_key(item_json_source) || !is_valid_add_key(item_json_dest)){
			alert.error("Empty or Duplicate key values not allowed.")
		}else{
			if(rule_name &&  cabinet_subunit){
				set_mf_rule_step('2')
			}else{
				alert.error('Please fill all required details')
			}
		}
	}

  	return (
		<Modal centered style={{maxWidth: '95%'}} isOpen={open} toggle={() => {mode=='view'?onclick_handleclose():show_escape_warning_dialog()}}>
			<EscapeWarning open = {show_escape_warning} set_discard_changes={set_discard_changes} handleClose={close_escape_warning_dialog}/>
			<Card>
				<CardHeader className='global_modal_header'>
						<div style={{width:'100%', display:'flex'}}>
							<div>{(mode=='add') ? "Create a Rule" : "Manufacturing Rule -"+'\xa0'}</div>
							<div title={mode=='add' ? '' : active_rule ?active_rule.name:''} className='text_ellipsis' style={{width:'calc(100% - 200px)'}}>{mode=='add' ? '' : active_rule ?active_rule.name:''}</div>
						</div>
						<i onClick={() => mode=='view'?onclick_handleclose():show_escape_warning_dialog()} style={{cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>
				{
					mf_rule_step==='1'?
					<CardBody className='global_modal_body' style={{height:'calc(100vh - 160px)'}}>
						<Row style={{marginBottom:'20px', ...(mode=='view' ? { pointerEvents: 'none' } : {}) }}>
							<Col xs={1}></Col>
							<Col xs={10}>
								<Row style={{marginBottom:'20px'}}>
									<Col xs={5}>
										<div>
											<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Name *</div>
											<Input id='mf_rule_name' style={{width:'100%',height:'32px', fontSize:'12px', backgroundColor: '#FFFFFF', borderRadius: '3px', borderColor: trigger_red_marker && (!rule_name || !rule_name.length) ? 'red' : ''}} placeholder="Rule Name" type="text" value={rule_name} onChange={e => set_rule_name(e.target.value)}/>
										</div>
									</Col>	
									<Col xs={5}>
										<div>
											<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Description *</div>
											<Input id='mf_rule_description' style={{width:'100%',height:'32px', fontSize:'12px', backgroundColor: '#FFFFFF', borderRadius: '3px', borderColor: trigger_red_marker && (!rule_description || !rule_description.length) ? 'red' : ''}} placeholder="Rule Description" type="text" value={rule_description} onChange={e => set_rule_description(e.target.value)}/>
										</div>
									</Col>
									<Col xs={2}>
										<div>
											<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Activation*</div>
											<Input style={{height:'100%',overflowY:'auto',fontSize:'12px',}} type="select"
												value={rule_activation} id='mf_rule_activation'
												onChange={e => set_rule_activation(e.target.value == 'true')}
											>
												<option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} value='true'>True</option>
												<option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} value='false'>False</option>
											</Input>
										</div>
									</Col>	
								</Row>

								<Row style={{marginBottom:'20px'}}>
									<Col>
										<div>
											<div style={{borderBottom:'1px solid #DDDDDD', width:'100%'}}></div>
										</div>
									</Col>	
								</Row>
								
								<Row style={{marginBottom:'15px'}}>
									<Col>
										<div style={{fontSize:'18px', color:'black'}}>Applicability</div>
									</Col>
								</Row>

								<div className='flex_property p-3 mb-2' style={{ background: 'white' }}>SKU Applicability
									<Tooltip title={"This rule will be only applied to the list of SKUs selected"}>
										<img className='ml-2' src='/assets/icons/info.svg' height={'12px'} />
									</Tooltip>
									{/* <div className='ml-4' style={{ fontSize: '14px' }}>{sku_selection_map && sku_selection_map.length ? `Applicable to ${sku_selection_map.length} SKU` : 'All Sku'}</div><div className='ml-auto' onClick={open_sku_selection_modal} style={{ color: '#0078ff', cursor: 'pointer', fontSize: '14px' }}>View/Modify SKU</div> */}
									<div className='ml-4' style={{ fontSize: '14px' }}>{sku_selection_map && sku_selection_map.length ? `Applicable to ${sku_selection_map.length} SKU` : 'All Sku'}</div><div className='ml-auto' title={item_json_applicable && item_json_applicable.filter(x => x.property && x.property[0] && x.property[0].id === 'tag').length ? 'Applicability Conditions should not have tag property to have SKU Applicability' : ''} onClick={item_json_applicable && item_json_applicable.filter(x => x.property && x.property[0] && x.property[0].id === 'tag').length ? null : open_sku_selection_modal} style={{ color: '#0078ff', cursor: 'pointer', fontSize: '14px', opacity: item_json_applicable && item_json_applicable.filter(x => x.property && x.property[0] && x.property[0].id === 'tag').length ? '0.5' : 1 }}>View/Modify SKU</div>
								</div>
								
								<div className='pl-4'>
								{
									item_json_applicable ?item_json_applicable.map((single_item,idx) => 
										<MFKeyValuePair_applicable 
											trigger_red_marker={trigger_red_marker}
											open={open} 
											key={single_item.id?single_item.id:single_item.temp_key} 
											item_json={item_json_applicable} 
											idx={idx} 
											single_item_json={single_item} 
											tags={tags} 
											set_item_json={set_item_json_applicable} 
											component_options_json={component_applicable_json} 
											mode={mode} 
											position_type='applicable' 
											set_applicability_combination_logic={set_applicability_combination_logic}
											applicability_combination_logic={applicability_combination_logic}
											is_back_button={is_back_button}
											sales_channels={all_sales_ch}
											has_skus_mapped={sku_selection_map && sku_selection_map.length}
										/>
									
									):''
								}
								{/* <Row style={{marginBottom:'20px'}}>
									<Col>
										<div>
											<div style={{width:'100%'}}></div>
										</div>
									</Col>	
								</Row> */}
								<Row style={{margin:0}}>
									<Col style={{textAlign:'left', paddingBottom:'15px', paddingLeft:0}}>
										<span onClick={add_new_item_json_applicable}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>+ Add Applicability</span>
									</Col>
								</Row>
								</div>

								<Row style={{marginBottom:'20px'}}>
									<Col>
										<div>
											<div style={{borderBottom:'1px solid #DDDDDD', width:'100%'}}></div>
										</div>
									</Col>	
								</Row>
								<Row style={{marginBottom:'20px'}}>
									<Col xs={6}>
										<div>
											<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Cabinet sub-unit *</div>
											<Input style={{height:'100%',overflowY:'auto',fontSize:'12px',}} type="select"
												id = 'cabinet_subunit'
												value={cabinet_subunit}
												onChange={onclick_cabinet_submit}
											>
												{
													Subunit_options_json && Subunit_options_json.length ? Subunit_options_json.map((item, idx)=>(
														<option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} key={idx} value={item.id}>
															{item.sub_unit?item.sub_unit:''}
														</option>
													)):""
												}
											</Input>
										</div>
									</Col>	
								</Row>
								
								<Row style={{marginBottom:'15px'}}>
									<Col>
										<div style={{fontSize:'18px', color:'black'}}>If the following criteria is satisfied:</div>
									</Col>
								</Row>
								{
									item_json_source ?item_json_source.map((single_item,idx) => 
										<MFKeyValuePair_source trigger_red_marker={trigger_red_marker} open={open} cabinet_subunit={cabinet_subunit} key={single_item.id?single_item.id:single_item.temp_key} item_json={item_json_source} idx={idx} single_item_json={single_item} set_item_json={set_item_json_source} subunit_component_json={Subunit_options_json} mode={mode} all_division_tree_data={all_division_tree_data} tags={tags} all_category={all_category && all_category.length?all_category:[]} all_sub_category={all_sub_category && all_sub_category.length?all_sub_category:[]} all_sales_ch={all_sales_ch} set_source_condition_combination_logic={set_source_condition_combination_logic} source_condition_combination_logic={source_condition_combination_logic} is_back_button={is_back_button}/>
									
									):''
								}
								<Row style={{margin:0}}>
								{
									mode=='view'?'':
									<Col style={{textAlign:'left', paddingBottom:'15px', paddingLeft:0}}>
										<span onClick={add_new_item_json_source}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>+ Add Condition</span>
									</Col>
								}
								</Row>
								<Row style={{marginBottom:'15px'}}>
									<Col>
										<div style={{fontSize:'18px', color:'#212529'}}>Then do the following action:</div>
									</Col>
								</Row>
								{
									item_json_dest ?item_json_dest.map((single_item,idx) => 
										<MFKeyValuePair_dest trigger_red_marker={trigger_red_marker} key={single_item.id?single_item.id:single_item.temp_key} item_json={item_json_dest} idx={idx} single_item_json={single_item} set_item_json={set_item_json_dest} allowed_properties={fields_to_be_updated} mode={mode} all_division_tree_data={all_division_tree_data} tags={tags} all_edge_band_sku={all_edge_band_sku} all_edge_band_glue_sku={all_edge_band_glue_sku} all_panel_glue_sku={all_panel_glue_sku} all_category={all_category} all_sub_category={all_sub_category} set_dest_condition_combination_logic={set_dest_condition_combination_logic} dest_condition_combination_logic={dest_condition_combination_logic} is_back_button={is_back_button}/>
									
									):''
								}
								<Row style={{margin:0}}>
								{
									mode=='view'?'':
									<Col style={{textAlign:'left', paddingBottom:'10px', paddingLeft:0}}>
										<span id='add_condition_dest' onClick={add_new_item_json_dest}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>+ Add Action</span>
									</Col>
								}
								</Row>
							</Col>
							<Col xs={1}></Col>
						</Row>
					</CardBody>
					:
					''
					// <CardBody className='global_modal_body'>
					// 	<Row style={{marginBottom:'20px'}}>
					// 		<Col xs={1}></Col>
					// 		<Col xs={10}>
					// 			<Row style={{marginBottom:'20px'}}>
					// 				<Col>
					// 					<div>
					// 						<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Name</div>
					// 						<div style={{fontSize:'14px', letterSpacing: '-0.04px', lineHeight: '22px'}}>{rule_name}</div>
					// 					</div>
					// 				</Col>	
					// 				<Col>
					// 					<div>
					// 						<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Description</div>
					// 						<div style={{fontSize:'14px', letterSpacing: '-0.04px', lineHeight: '22px'}}>{rule_description}</div>
					// 					</div>
					// 				</Col>	
					// 			</Row>
					// 			<Row style={{marginBottom:'20px'}}>
					// 				<Col xs={6}>
					// 					<div>
					// 						<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Cabinet sub-unit *</div>
					// 						<div style={{fontSize:'14px', letterSpacing: '-0.04px', lineHeight: '22px'}}>{cabinet_subunit}</div>
					// 					</div>
					// 				</Col>		
					// 			</Row>
					// 			<Row style={{marginBottom:'20px'}}>
					// 				<Col>
					// 					<div>
					// 						<div style={{borderBottom:'1px solid #DDDDDD', width:'100%'}}></div>
					// 					</div>
					// 				</Col>	
					// 			</Row>
					// 			<Row style={{marginBottom:'15px'}}>
					// 				<Col>
					// 					<div style={{fontSize:'18px', color:'black'}}>If the following criteria is satisfied:</div>
					// 				</Col>
					// 			</Row>
								
					// 			<Row style={{margin:'0px', backgroundColor:'white'}}>
					// 				<Col style={{padding:'10px 30px', fontSize:'16px', backgroundColor:'white'}}>
					// 					{
					// 						item_json_source && item_json_source.length && item_json_source[0].property && item_json_source[0].property.length?item_json_source.map((item, idx) =>(
					// 							<div key={idx} style={{padding:'10px 0px', display:'flex'}}>
					// 								<div style={{flex:1}}>
					// 									<span style={{marginRight:'20px'}}>{idx===0?'Condition '+(idx+1):'\nCondition '+(idx+1)}</span> 
					// 								</div>
					// 								<div style={{flex:4}}>
					// 									{idx===0?'':<span style={{marginRight:'5px'}}>{source_condition_combination_logic}</span>}
					// 									<span style={{marginRight:'5px'}}>{idx===0?'For':'for'}</span>
					// 									<b>{item.subcomponent && item.subcomponent.length ?item.subcomponent[0].type:''}</b>
					// 									<span style={{marginRight:'5px'}}>,</span>
					// 									<b style={{marginRight:'5px'}}>{item.property && item.property.length?item.property[0].name:''}</b> 
					// 									<span style={{marginRight:'5px'}}>{['tag','sales_channel'].includes(item.property[0].id)?'equal to':return_word(item.operator)}</span>
					// 									<span>
					// 										{
					// 											item.value && Array.isArray(item.value) && item.value.length ? item.value.map((x_id,idx) => (	
					// 												item.property[0].id==='tag'?
					// 													<span key={idx}>{idx===0?'':<span style={{marginRight:'5px'}}>{item.value_combination_logic}</span>}<b style={{marginRight:'5px'}}>{x_id.tag}</b></span>
					// 													:<span key={idx}>{idx===0?'':<span style={{marginRight:'5px'}}>{item.value_combination_logic}</span>}<b style={{marginRight:'5px'}}>{x_id.name}</b></span>
					// 												))
					// 											:
					// 											item ?<b>{item.value}</b>:''
					// 										}
					// 									</span>
					// 								</div>
					// 								{/* {values_text(item.value,item.operator?item.operator:'and')} */}
					// 							</div>
	  				// 						)):''	
					// 					}
					// 				</Col>
					// 			</Row>
									
					// 			<Row style={{marginBottom:'15px'}}>
					// 				<Col>
					// 					<div style={{fontSize:'18px', color:'#212529'}}>Then do the following action:</div>
					// 				</Col>
					// 			</Row>
								
					// 			<Row style={{margin:'0px', backgroundColor:'white'}}>
					// 				<Col style={{padding:'10px 30px', fontSize:'16px'}}>
					// 					{
					// 						item_json_dest && item_json_dest.length && item_json_dest[0].property && item_json_dest[0].property.length?item_json_dest.map((item, idx) =>(
					// 							<div key={idx} style={{padding:'10px 0px', display:'flex'}}>
					// 								<div style={{flex:1}}>
					// 									<span style={{marginRight:'20px'}}>{idx===0?'Condition '+(idx+1):'\nCondition '+(idx+1)}</span> 
					// 								</div>
					// 								<div style={{flex:4}}>
					// 									{idx===0?'':<span style={{marginRight:'5px'}}>{dest_condition_combination_logic}</span>}
					// 									<b style={{marginRight:'5px'}}>{item.property && item.property.length ?item.property[0].name:''}</b>
					// 									<span style={{marginRight:'5px'}}>{item.property[0] && ['tag', 'sales_channel'].includes(item.property[0].id)?'equal to':return_word(item.operator)}</span>
					// 									<span>
					// 										{
					// 											item.value && Array.isArray(item.value) && item.value.length ? item.value.map((x_id,idx) => (	
					// 												item.property[0] && item.property[0].id==='tag'?
					// 													<span key={idx} style={{marginRight:'5px'}}><span style={{marginRight:'5px'}}>{idx===0?'':item.value_combination_logic}</span><b style={{marginRight:'5px'}}>{x_id.tag}</b></span>
					// 													:
					// 													<b>{x_id.name}</b>
					// 												))
					// 											:
					// 											item ?<b>{item.value}</b>:''
					// 										}
					// 									</span>
					// 								</div>
					// 								{/* {values_text(item.value,item.operator?item.operator:'and')} */}
					// 							</div>
	  				// 						)):''	
					// 					}										
					// 				</Col>
					// 			</Row>
									
					// 		</Col>
					// 		<Col xs={1}></Col>
					// 	</Row>
					// </CardBody>
				}
				<CardFooter className='global_modal_footer'>
					{/* {mf_rule_step==='2'?
						<Button className='white_button' style={{fontSize:'14px'}} type="button" onClick={() => {set_mf_rule_step('1') ;set_is_back_button(true)}}>Back</Button>
						:''
					} */}
					<Button className='white_button' style={{fontSize:'14px', marginRight:mode=='view'?'5px':'12px'}} type="button" onClick={() => mode=='view'?onclick_handleclose():show_escape_warning_dialog()}>{mode=='view'?'Close':'Cancel'}</Button>
					{/* <Button className='blue_button' style={{fontSize:'14px'}} type="button" onClick={mf_rule_step==='1'?() => onclick_preview():mode==='add'?onclick_add_new_rule:onclick_edit_rule}> {mf_rule_step==='1'?'Preview':mode==='add'?'Add':'Edit'}</Button> */}
					{
						mode=='view'?'':<Button id='add_edit_mf_rule_button' className='blue_button' style={{fontSize:'14px'}} type="button" onClick={mode==='add'?onclick_add_new_rule:mode==='edit'?onclick_edit_rule:onclick_handleclose}> {mode==='add'?'Add':mode==='edit' ? 'Edit' : "Done"}</Button>
					}
					{/* onClick={mode==='add'?onclick_add_new_rule:onclick_edit_rule} */}
				</CardFooter>
			</Card>  
			<SkuSelectionModal key={active_rule ? active_rule.id : "New Rule"} open={is_sku_selection_modal_open} handleclose={close_sku_selection_modal} submitSelectedSku={(selectedSku) => {set_sku_selection_map(selectedSku)}} initialSelection={sku_selection_map} set_page_loader={set_page_loader}/>
		</Modal>
  	);
}

export default MFRulesDialogBox ;

//rule_name && item_json_source && item_json_dest && cabinet_subunit

//${x_item_json_dest_dummy.length-1===idx?"":source_condition_combination_logic}
													// has ${dest_condition_combinator_logic}

// ( x_text.concat(values_Array.length-1!=idx?x_id.tag.toLowerCase()+' '+logic+' '+' ':x_id.tag.toLowerCase()))
																		// :
																		// ( x_text.concat(values_Array.length-1!=idx?x_id.name.toLowerCase()+' '+logic+' '+' ':x_id.name.toLowerCase()))