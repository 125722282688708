import React , { useState , useEffect, useContext }from 'react';
import general_fetch from '../../utils/fetch';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useAlert } from 'react-alert'
import './index.js';
import Multiselect from '../Multiselect';
import { OrgBuContext } from '../Contexts/OrgBuContext';
import { UserContext } from '../Contexts/UserContext';


const Single_item = ({trigger_red_marker, single_sku, idx1, update_sku_array, delete_sku, categories, set_page_loader, groups_json, set_groups_json}) => {

    const [sub_categories, set_sub_categories] = useState([]);
	const [groups, set_groups] = useState([]);
	const [skus, set_skus] = useState([]);
    const [selected_division_array, set_selected_division_array] = useState([]);
    const [selected_category_array, set_selected_category_array] = useState([]);
    const [selected_sub_category_array, set_selected_sub_category_array] = useState([]);
    const [selected_group_array, set_selected_group_array] = useState([]);
    const [selected_sku_array, set_selected_sku_array] = useState([]);
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)

    const set_sub_categories_fn = (category_id) => {
        let category = categories && categories.length ? categories.find(o => o.id == category_id):''
        set_sub_categories(category ? category.sku_sub_category : [])
    }

    const get_groups = async(subcat_id) => {
		if(subcat_id){
            try{
                set_page_loader({
                    show:true,
                    text:'fetching groups...'
                })
                let body = { sku_sub_category_id:subcat_id}
                if(org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
                let resp = await general_fetch({url:'inventory/get_groups',  body})
                let temp_grp_json  = groups_json ? JSON.parse(JSON.stringify(groups_json)) : {}
                temp_grp_json[subcat_id] = resp
                set_groups_json(temp_grp_json)
                set_groups(resp)
                set_page_loader({
                    show:false
                })
            }catch(err){
                console.log(err)
            }
        }
	}

	const set_skus_fn = (group_id) => {
		let group = groups && groups.length ? groups.find(o => o.id === group_id) : ''
        console.log('skus set', group)
		set_skus(group && group.sku ? group.sku : [])
	}

    useEffect(() => {
        if(!(selected_division_array && selected_division_array.length)) set_selected_category_array([])
    }, [selected_division_array]);

    useEffect(() => {
        if(categories && categories.length && selected_category_array && selected_category_array.length){
            // update_sku_array(idx1, 'category', selected_category_array[0].id)
            set_sub_categories_fn(selected_category_array[0].id)
        }else{
            // set_sub_categories([])
            // set_selected_sub_category_array([])
        }
    }, [categories, selected_category_array]);

    useEffect(() => {
        console.log('selected_sub_category_array', selected_sub_category_array)
        if(selected_sub_category_array && selected_sub_category_array.length){
            // update_sku_array(idx1, 'sub_category', selected_sub_category_array[0].id)
            console.log('group_json', groups_json)
            if(groups_json && groups_json[selected_sub_category_array[0].id]){
                set_groups(groups_json[selected_sub_category_array[0].id])
            }else{
                get_groups(selected_sub_category_array[0].id)
            }
        }else{
            // set_groups([])
            // set_selected_group_array([])
        }
    }, [selected_sub_category_array]);

    useEffect(() => {
        if(groups && groups.length && selected_group_array && selected_group_array.length){
            // update_sku_array(idx1, 'group', selected_group_array[0].id)
            set_skus_fn(selected_group_array[0].id)
        }else{
            // set_skus([])
            // set_selected_sku_array([])
        }
    }, [groups, selected_group_array]);

    useEffect(() => {
        if(selected_sku_array && selected_sku_array.length){
            console.log("selected_sku_array", selected_sku_array, single_sku, idx1, idx1+single_sku.id)
            // update_sku_array(idx1, 'id', selected_sku_array[0].id)
        }
    }, [selected_sku_array]);

    useEffect(() => {
        console.log('single_sku', single_sku)
        if(single_sku){
            set_selected_division_array([{id:'hardware'}])
        }
        if(single_sku && single_sku.category){
            set_selected_category_array([{id: single_sku.category}])
        }
        if(single_sku && single_sku.sub_category){
            set_selected_sub_category_array([{id: single_sku.sub_category}])
        }
        if(single_sku && single_sku.group){
            set_selected_group_array([{id: single_sku.group}])
        }
        if(single_sku && single_sku.id){
            set_selected_sku_array([{id: single_sku.id}])
        }
    }, [single_sku]);

    return(
        <div key={idx1+single_sku.id} id={idx1+single_sku.id} style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'16px'}}>
            <div style={{flex:'1'}}><span>{idx1+1}</span></div>
            <div style={{flex:'4'}}>
                <div style={{marginRight:'12px'}}>
                    <Multiselect 
                        style_badge_container={{borderColor: trigger_red_marker && ((!(selected_division_array) || (Array.isArray((selected_division_array)) && (selected_division_array).length == 0))) ? 'red' : ''}}
                        options = {[{id: 'hardware', name: 'Hardware'}]}
                        selectedValues = {selected_division_array}
                        onSelect = {(selectedList) => {set_selected_division_array(selectedList); set_selected_category_array([]); set_selected_sub_category_array([]); set_selected_group_array([]); set_selected_sku_array([])}}
                        onRemove = {(selectedList) => {set_selected_division_array(selectedList); set_selected_category_array([]); set_selected_sub_category_array([]); set_selected_group_array([]); set_selected_sku_array([])}}
                        displayValue = 'name'
                        selectionLimit={1}
                        placeholder='Select Division'
                        key={idx1+single_sku.id+'1'}
                        id ={idx1+single_sku.id+'1'}
                    />
                </div>
            </div>
            <div style={{flex:'4'}}>
                <div style={{marginRight:'12px'}}>
                    <Multiselect 
                    	style_badge_container={{borderColor: trigger_red_marker && ((!(selected_category_array) || (Array.isArray((selected_category_array)) && (selected_category_array).length == 0))) ? 'red' : ''}}
                        options = {categories && categories.length ? JSON.parse(JSON.stringify(categories)) : []}
                        selectedValues = {selected_category_array}
                        onSelect = {(selectedList) => {set_selected_category_array(selectedList); update_sku_array(idx1, 'category', selectedList && selectedList.length ? selectedList[0].id : ''); set_selected_sub_category_array([]); set_selected_group_array([]); set_selected_sku_array([])}}
                        onRemove = {(selectedList) => {set_selected_category_array(selectedList); update_sku_array(idx1, 'category', selectedList && selectedList.length ? selectedList[0].id : ''); set_selected_sub_category_array([]); set_selected_group_array([]); set_selected_sku_array([])}}
                        displayValue = 'name'
                        selectionLimit={1}
                        placeholder='Select Category'
                        key={idx1+single_sku.id+'2'}
                        id={idx1+single_sku.id+'2'}
                    />
                </div>
            </div>
            <div style={{flex:'4'}}>
                <div style={{marginRight:'12px'}}>
                    <Multiselect 
                        style_badge_container={{borderColor: trigger_red_marker && ((!(selected_sub_category_array) || (Array.isArray((selected_sub_category_array)) && (selected_sub_category_array).length == 0))) ? 'red' : ''}}
                        options = {sub_categories && sub_categories.length ? JSON.parse(JSON.stringify(sub_categories)) : []}
                        selectedValues = {selected_sub_category_array}
                        onSelect = {(selectedList) => {set_selected_sub_category_array(selectedList); update_sku_array(idx1, 'sub_category', selectedList && selectedList.length ? selectedList[0].id : ''); set_selected_group_array([]); set_selected_sku_array([])}}
                        onRemove = {(selectedList) => {set_selected_sub_category_array(selectedList); update_sku_array(idx1, 'sub_category', selectedList && selectedList.length ? selectedList[0].id : ''); set_selected_group_array([]); set_selected_sku_array([])}}
                        displayValue = 'name'
                        selectionLimit={1}
                        placeholder='Select Sub-Category'
                        key={idx1+single_sku.id+'3'}
                        id={idx1+single_sku.id+'3'}
                    />
                </div>
            </div>
            <div style={{flex:'4'}}>
                <div style={{marginRight:'12px'}}>
                    <Multiselect 
                    	style_badge_container={{borderColor: trigger_red_marker && ((!(selected_group_array) || (Array.isArray((selected_group_array)) && (selected_group_array).length == 0))) ? 'red' : ''}}
                        options = {groups && groups.length ? JSON.parse(JSON.stringify(groups)) : []}
                        selectedValues = {selected_group_array}
                        onSelect = {(selectedList) => {set_selected_group_array(selectedList); update_sku_array(idx1, 'group', selectedList && selectedList.length ? selectedList[0].id : ''); set_selected_sku_array([])}}
                        onRemove = {(selectedList) => {set_selected_group_array(selectedList); update_sku_array(idx1, 'group', selectedList && selectedList.length ? selectedList[0].id : ''); set_selected_sku_array([])}}
                        displayValue = 'name'
                        selectionLimit={1}
                        placeholder='Select Group'
                        key={idx1+single_sku.id+'4'}
                        id={idx1+single_sku.id+'4'}
                    />
                </div>
            </div>
            <div style={{flex:'4'}}>
                <div style={{marginRight:'16px'}}>
                    <Multiselect 
        				style_badge_container={{borderColor: trigger_red_marker && ((!(selected_sku_array) || (Array.isArray((selected_sku_array)) && (selected_sku_array).length == 0))) ? 'red' : ''}}
                        options = {skus && skus.length ? JSON.parse(JSON.stringify(skus)) : []}
                        selectedValues = {selected_sku_array}
                        onSelect = {(selectedList) => {set_selected_sku_array(selectedList); update_sku_array(idx1, 'id', selectedList && selectedList.length ? selectedList[0].id : '')}}
                        onRemove = {(selectedList) => {set_selected_sku_array(selectedList); update_sku_array(idx1, 'id', selectedList && selectedList.length ? selectedList[0].id : '')}}
                        displayValue = 'name'
                        selectionLimit={1}
                        placeholder='Select Sku'
                        key={idx1+single_sku.id+'5'}
                        id={idx1+single_sku.id+'5'}
                    />
                </div>
            </div>
            <div style={{flex:'1'}}>
                <i className='fa fa-trash' style={{fontSize:'14px', cursor:'pointer'}} onClick={() => {delete_sku(idx1)}}/>
            </div>
        </div>
    )
}

const MultipleSkuSelection = ({trigger_red_marker, sku_array, set_sku_array, all_division_tree_data, selected_sub_comp, set_page_loader}) => {

    const [categories, set_categories] = useState([]);
    const [groups_json, set_groups_json] = useState();

    const add_new_sku = () => {
        let a = sku_array ? JSON.parse(JSON.stringify(sku_array)) : []
        a.push({})
        set_sku_array(a)
    }

    const update_sku_array = (id, key, value) => {
        let a = JSON.parse(JSON.stringify(sku_array));
        a[id][key] = value
        set_sku_array(a)
        console.log('selected_sku_array', a)
    }

    const delete_sku = (id) => {
        let a = JSON.parse(JSON.stringify(sku_array));
        a.splice(id, 1)
        set_sku_array(a)
    }

    useEffect(() => {
        console.log('all_division_tree_data', all_division_tree_data, selected_sub_comp)
    }, [all_division_tree_data]);

    useEffect(() => {
        console.log('sku_array changed', sku_array)
        if(sku_array && sku_array.length == 0) add_new_sku()
    }, [sku_array]);

    useEffect(() => {
        if(selected_sub_comp && all_division_tree_data && all_division_tree_data.length){
            let options = all_division_tree_data.find(o => o.id === "hardware") ? all_division_tree_data.find(o => o.id === "hardware").sku_category : []
            if(selected_sub_comp === 'drawer_organiser'){
                let temp_categories = options && options.length ? options.filter(o => o.sku_category_type_id === 'drawer_inserts_hardware') : []
                set_categories(temp_categories)
            }else if(selected_sub_comp === 'tandem_box'){
                let temp_categories = options && options.length ? options.filter(o => o.sku_category_type_id === 'drawer_systems_hardware') : []
                set_categories(temp_categories)
            }else if(selected_sub_comp === 'basket'){
                let temp_categories = options && options.length ? options.filter(o => o.sku_category_type_id === 'basket_hardware') : []
                set_categories(temp_categories)
            }else if(selected_sub_comp === 'channel'){
                let temp_categories = options && options.length ? options.filter(o => o.sku_category_type_id === 'drawer_channel_hardware') : []
                set_categories(temp_categories)
            }
        }
    }, [selected_sub_comp, all_division_tree_data]);

    return (
        <div style={{backgroundColor:'white'}}>
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'16px'}}>
                <div style={{flex:'1'}}><span></span></div>
                <div style={{flex:'4'}}><span>Division</span></div>
                <div style={{flex:'4'}}><span>Category</span></div>
                <div style={{flex:'4'}}><span>Sub Category</span></div>
                <div style={{flex:'4'}}><span>Group</span></div>
                <div style={{flex:'4'}}><span>SKU</span></div>
                <div style={{flex:'1'}}></div>
            </div>
            {
                sku_array && sku_array.length ? sku_array.map((single_sku, idx1) => (
                <Single_item
                    trigger_red_marker={trigger_red_marker}
                    single_sku = {single_sku}
                    idx1 = {idx1}
                    update_sku_array = {update_sku_array}
                    delete_sku = {delete_sku}
                    categories = {categories}
                    set_page_loader = {set_page_loader}
                    key = {idx1+single_sku.id}
                    groups_json = {groups_json}
                    set_groups_json = {set_groups_json}
                />
                )):''
            }
            <div onClick={add_new_sku} style={{color:'#0078ff', marginTop:'16px', fontWeight:'500', cursor:'pointer'}}>
                + Add SKU
            </div>
        </div>
    )
}

export default MultipleSkuSelection