const default_additional_parameters = {
    "v1_conversion_cost": {
        "name": "V1 Conversion Cost",
        "type": "number"
    },
    "v1_conversion_cost_basis": {
        "name": "V1 Conversion Cost Basis",
        "type": "select",
        "possible_values": [
            { "id": "ply_area", "name": "Ply Area" },
            { "id": "panel_area", "name": "Panel Area" }
        ]
    },
    "v1_assembly_cost": {
        "name": "V1 Assembly Cost",
        "type": "number"
    },
    "v1_assembly_cost_basis": {
        "name": "V1 Assembly Cost Basis",
        "type": "select",
        "possible_values": [
            { "id": "ply_area", "name": "Ply Area" },
            { "id": "panel_area", "name": "Panel Area" }
        ]
    },
    "v2_conversion_cost": {
        "name": "V2 Conversion Cost",
        "type": "number"
    },
    "v2_conversion_cost_basis": {
        "name": "V2 Conversion Cost Basis",
        "type": "select",
        "possible_values": [
            { "id": "ply_area", "name": "Ply Area" },
            { "id": "panel_area", "name": "Panel Area" }
        ]
    },
    "v2_assembly_cost": {
        "name": "V2 Assembly Cost",
        "type": "number"
    },
    "v2_assembly_cost_basis": {
        "name": "V2 Assembly Cost Basis",
        "type": "select",
        "possible_values": [
            { "id": "ply_area", "name": "Ply Area" },
            { "id": "panel_area", "name": "Panel Area" }
        ]
    },
    "v3_conversion_cost": {
        "name": "V3 Conversion Cost",
        "type": "number"
    },
    "v3_conversion_cost_basis": {
        "name": "V3 Conversion Cost Basis",
        "type": "select",
        "possible_values": [
            { "id": "ply_area", "name": "Ply Area" },
            { "id": "panel_area", "name": "Panel Area" }
        ]
    },
    "v3_assembly_cost": {
        "name": "V3 Assembly Cost",
        "type": "number"
    },
    "v3_assembly_cost_basis": {
        "name": "V3 Assembly Cost Basis",
        "type": "select",
        "possible_values": [
            { "id": "ply_area", "name": "Ply Area" },
            { "id": "panel_area", "name": "Panel Area" }
        ]
    }
}

export default default_additional_parameters;