import general_fetch from "../fetch"

export const get_all_automation_rules = async (exempt_sku_linked_rules = true) => {
    try {
        let user = global.get_user()
        let org_bu_view = window.sessionStorage.getItem('org_bu_view') || 'bu'

        let body = { exempt_sku_linked_rules }

        if (org_bu_view === 'bu') {
            body["business_unit_id"] = user.current_business_unit_id
        }
        
        let response = await general_fetch({ url: 'automation_rule/get_all', body });
        return response;

    } catch (error) {
        console.error("Error getting automation rules", error);
        return Promise.reject(error)
    }
}