import React , { useState , useEffect } from 'react';
import "./index.css";


//MultiselectBadge is data template for selected items
const MultiselectBadge = ({item, displayValue, options_display, onRemove, onDeselect, disable}) => {

    const onclick_close = (e) => {
        item.checked = false;
        options_display.map(x => {
            if(item && x.id===item.id){
                x.checked=false
            }
            return x;
        })
        onRemove()
        var x_sel = options_display.filter(x => x.checked)
        onDeselect(x_sel)
        e.stopPropagation()
    }

    return (
        <span className='single_badge_new'>
            <span className='badge_text_new' title={item?item[displayValue]:''}>{item?item[displayValue]:''}</span>
            <i style ={{cursor:'pointer'}} className='fa fa-times' onClick={disable? null : onclick_close}></i>
        </span>
    )
}

// MultiselectItem is data template for items in Multiselect
const MultiselectItem = ({item, displayValue, options_display, onClickItem, onSelect, onDeselect, selectionLimit, count_selected_items}) => {

    const onclick_item = (e) => {
        item.checked = !(item.checked);
        options_display.map(x => {
            if(item && x.id===item.id){
                x.checked=item.checked
            }
            return x;
        })
        var x_sel = options_display.filter(x => x.checked)
        if(item.checked){
            onSelect(x_sel)
        }else{
            onDeselect(x_sel)
        }
        onClickItem(item.checked)
        e.stopPropagation()
    }

    return (
        <div style={{cursor:'pointer'}} className='single_option_new' onClick={count_selected_items >= selectionLimit ? null : onclick_item}>
            <input style={{margin:'5px'}} type='checkbox' checked={item.checked} ></input>
            <span className='option_text_new' title={item?item[displayValue]:''} style={{opacity:count_selected_items >= selectionLimit ? .5 : 1}}>{item?item[displayValue]:''}</span>
        </div>
    )
}


// Component for Multiselect Dropdown
const MultiSelectDropdown = ({options, placeholder, selectionLimit, onSelect, onDeselect, selectedValues, id, disable, displayValue, count_style={}, item_txt="", container_size='s'}) => {
    // options_display is a modified copy of options along
    const [options_display, set_options_display] = useState([]);
    //options_dropdown_display is list of options that will be displayed. Changes with change in search string
    const [options_dropdown_display, set_options_dropdown_display] = useState([]);
    //keeps track of selected items. Useful incase of selection limit
    const [count_selected_items, set_count_selected_items] = useState(0);
    //search string
    const [search_string, set_search_string] = useState('');
    //state of dropdown
    const [open_dropdown, set_open_dropdown] = useState(false);

    //changes the state of dropdown
    const toggle_dropdown = () => {
        set_open_dropdown(!open_dropdown)
        set_search_string('')
    }

    //clears search value
    const search_clear = () => {
        set_search_string('')
    }

    //on removing the selection update count and options.checked
    const onRemove = () => {
        set_search_string('')
        set_count_selected_items(count_selected_items-1)
    }

    const onClickItem = (value) => {
        if(value === true){
            set_count_selected_items(count_selected_items+1)
        }else{
            set_count_selected_items(count_selected_items-1)
        }
        set_search_string('')
    }

    //Update options_dropdown_display with change of search_string
    useEffect(() => {
        var filtered_options = JSON.parse(JSON.stringify(options_display))
        var lower_search_string = search_string ? search_string.toLowerCase() : '';
        if(lower_search_string){
            filtered_options = filtered_options.filter( item => item[displayValue] && item[displayValue].toLowerCase().includes(lower_search_string));
        }
        set_options_dropdown_display(filtered_options);
    }, [search_string]);

    //Options is a state variable so Using UseEffect to initialize options_display and options_dropdown_display
    useEffect(() => {
        if(options){
            var x_selected_ids = selectedValues && selectedValues.length?selectedValues.map(x => x.id):[]
            var options_copy = JSON.parse(JSON.stringify(options));
            var count = 0;
            if(options_copy && options_copy.length){
                var options_copy_modified = options_copy.map(x => {
                    var x_value = x_selected_ids.includes(x.id)?true:false;
                    if(x_value) count++
                    return{...x, checked:x_value}
                })
                set_options_display(options_copy_modified)
                set_options_dropdown_display(options_copy_modified)
            }else{
                set_options_display([])
                set_options_dropdown_display([])
            }

            set_count_selected_items(count)
        }
    }, [options , selectedValues]);

    return ( 
        <div style={{width:'100%', position:'relative'}} id={id} >
            <div className='inf_model_outer_div_new' style={{display:open_dropdown?'block':'none',}} onClick={toggle_dropdown}/>
            <div >
                <span className='badge_container_outer_new d-flex justify-content-between' style={{width:'100%'}}>
                    <span id='badge_container' style={{border:'0px', maxHeight:open_dropdown?'115px':(container_size=='s'?'38px':'76px'), opacity: disable?.5:1}} className='badge_container_new' onClick={disable? null : toggle_dropdown}>
                        {
                            options_display && options_display.length && options_display.filter(x => x.checked).length ? options_display.filter(x => x.checked).map((item) => (
                                <MultiselectBadge item={item} displayValue={displayValue} options_display={options_display} onRemove={disable? null : onRemove} onDeselect={disable? null : onDeselect}/>
                            ))
                            :
                            <div style={{color:'rgb(67, 90, 111)', padding:'5px 10px'}}>{placeholder?placeholder:'Select Item'}</div>
                        }
                    </span>
                    <span style={{padding: '5px', margin: '2px', height:'15px%', width:'10%', backgroundColor: 'grey', display:count_selected_items&&!open_dropdown?'flex':'none', alignItems: 'center', alignSelf: 'stretch', justifyContent: 'center', color:'white', borderRadius:'3px', textAlign:'center', ...count_style}}>
                        <span>
                        {count_selected_items + item_txt}
                        </span>
                    </span>
                </span>
                <div className='options_search_container_new' style={{display:open_dropdown?'block':'none' , width:'100%'}}>
                    <span>
                        <input className='search_input_box_new' value={search_string} onChange={(e)=>set_search_string(e.target.value)} placeholder='Type to Search' type='select'/>
                        <i className='fa fa-times' onClick={search_clear} ></i>
                    </span>
                    <div className='options_container_new'>
                        {
                            console.log("options_dropdown}", options_dropdown_display)
                        }
                        {
                            options_dropdown_display && options_dropdown_display.length ? options_dropdown_display.map((item) => (
                                <MultiselectItem item={item} displayValue={displayValue} options_display={options_display} onClickItem={onClickItem} onSelect={onSelect} onDeselect={onDeselect} selectionLimit={selectionLimit} count_selected_items={count_selected_items} />
                                
                            ))
                            :
                            <div style={{padding:'5px'}}> No Items Found </div>
                        }
                    </div>
                </div>
            </div>
        </div>
     );
}

export default MultiSelectDropdown;