
import React , { useState , useEffect }from 'react';
import {Input, CardBody, Col, Row, CardFooter, Button } from "reactstrap";
import { useAlert } from 'react-alert';
import general_fetch from '../../../utils/fetch';
import './sku_groups.css';
import Linked_sku_structure from '../../../components/Linked_sku_structure';
import debug_log from '../../../utils/debug_log';

const Linked_sku_struct = ({set_page_loader, all_division_tree_data, selected_sku_properties, fetch_sku_get_details, handleClose, user,active_division_id,sku_category_type, send_analytics_event, material_template}) => {

	const [item_json ,set_item_json] =useState([]);
	const [unowned_linked_sku, set_unowned_linked_sku] = useState([]);
	const alert = useAlert()

	const is_valid_add_key = (item_json) => {
		if (sku_category_type == 'drawer_system_collection_hardware' || sku_category_type == 'basket_collection_hardware') {
			return true;
		}
		if(item_json && item_json[0] && item_json[0].division && !item_json[0].division.length){
			return true;
		}else{
			let item_json_1 = JSON.parse(JSON.stringify(item_json))
			item_json_1 = item_json_1.map(x => {x.validation_key = (x.sku && x.sku.length ? x.sku[0].id : '') + (typeof(x.type) == 'string' ? x.type : (x.type[0] && x.type[0].id?x.type[0].id:'')); return x})
			var validation_ids = item_json_1.map(x => x.validation_key)
			if(item_json_1 && item_json_1.length){
				for(var i=0;i<item_json_1.length;i++){
					if(item_json_1[i].sku && item_json_1[i].sku.length<1 ){
						// console.log('linked_skus1')
						return false;
					}
					// console.log('linked_skus2', i, item_json_1[i].sku, validation_ids, item_json_1)
					if(item_json_1[i].validation_key && validation_ids.filter(x=> x===item_json_1[i].validation_key).length>=2){
						return false;
					}
				}
				return true;
			}else{
				return true;
			}
		}
	}

	const check_for_ply_finish = (linked_skus) => {
		const map1 = new Map();
		if(sku_category_type==='panel_core_hardware'){
			if(linked_skus && linked_skus.length>=4){
				return true
			}else if(linked_skus && linked_skus.length===1){
				return false;
			}else{
				var x_bool = false;
				linked_skus.map((x_item, idx) => {
					if(!x_bool){
						if(map1.has(x_item.type)){
							x_bool=true
						}else{
							map1.set(x_item.type, idx);
						}
					}
				})
				if(x_bool){
					return true;
				}else{
					return false;
				}
			}
		}else{
			return false
		}
	}

	const check_for_cabinet_shutter = (linked_skus) => {
		var x_ind = 0;
		if(sku_category_type==='shutter_hardware'){
			if(linked_skus && linked_skus.length>=4){
				return true
			}else if(linked_skus && linked_skus.length===3){
				if(linked_skus[0].type === linked_skus[1].type && linked_skus[0].type === linked_skus[2].type && linked_skus[1].type === linked_skus[2].type){
					x_ind = 1; 
				}
			}else if(linked_skus && linked_skus.length===2){
				if(linked_skus[0].type === linked_skus[1].type){
					x_ind = 1; 
				}
			}
		}
		if(x_ind===1){
			return true
		}else{
			return false
		}
	}

	const onclick_update = async() => {
		console.log('linked_skus', item_json)
		if(item_json && item_json.length>0 && !is_valid_add_key(item_json)){
			alert.error("Empty or Duplicate sku values not allowed.")
		}else{
			try {
					var linked_skus = item_json.map((x, idx) => {
						return {
							sku_id:x.sku[0]?x.sku[0].id:'',
							quantity:x.quantity,
							type: (sku_category_type == 'drawer_system_collection_hardware' || sku_category_type == 'basket_collection_hardware') ? idx : (typeof(x.type) == 'string' ? x.type : (x.type[0] && x.type[0].id?x.type[0].id:''))
						}
					})
					let unowned_linked_skus = unowned_linked_sku.map((x) => {
						return(
							{
								sku_id: x.sku_id,
								quantity: x.quantity,
								type: x.type
							}
						)
					})
					linked_skus = linked_skus.concat(unowned_linked_skus)
					debug_log("Linked sku on update", linked_skus)
					if(check_for_ply_finish(linked_skus)){
						alert.error("Maximum of Only three SKUs are allowed, with each having different type value.")
					}else if(check_for_cabinet_shutter(linked_skus)){
						alert.error("Maximum of Only three SKUs are allowed, with each having different type.")
					}else{
						var response = await general_fetch({ url: 'sku/update_sku_links' , body: {sku_id:selected_sku_properties.id, linked_skus: linked_skus} });
						send_analytics_event('track', user.id, selected_sku_properties.name, selected_sku_properties.id, sku_category_type, "linked_skus", "sku_property_edited")
						alert.success('Linked SKU Successfully Updated.')
						fetch_sku_get_details()
					}
			} catch(err) {
				console.log(err);
			}
		}
	}

    return(
		<React.Fragment>
			<CardBody className='global_modal_body'>
				<Linked_sku_structure set_page_loader={set_page_loader} item_json={item_json} set_item_json={set_item_json} unowned_linked_sku={unowned_linked_sku} set_unowned_linked_sku={set_unowned_linked_sku} all_division_tree_data={all_division_tree_data} selected_sku_properties={selected_sku_properties} fetch_sku_get_details={fetch_sku_get_details} active_division_id={active_division_id} sku_category_type={sku_category_type} material_template={material_template}/>
			</CardBody>
			<CardFooter className='global_modal_footer'>
				<Button className='white_button' onClick = {handleClose} >Close</Button>
				<Button className='blue_button' onClick={onclick_update}>Update Linked Sku</Button>
			</CardFooter>
		</React.Fragment>
    )
}

export default Linked_sku_struct;
