const default_cam_structure = [
    {
        "id": "general",
        "name": "General",
        "type": "parent",
        "children": [
            {
                "id": "board_layout",
                "name": "Board Layout",
                "type": "parent",
                "children": [
                    {
                        "id": "board_layout_horizontal_margin",
                        "name": "Horizontal Margin",
                        "default_value": 10,
                        "type": "number",
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~board_layout_horizontal_margin"
                    },
                    {
                        "id": "board_layout_vertical_margin",
                        "name": "Vertical Margin",
                        "default_value": 10,
                        "type": "number",
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~board_layout_vertical_margin"
                    },
                    {
                        "id": "board_layout_saw_width",
                        "name": "Saw Width",
                        "default_value": 4,
                        "type": "number",
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~board_layout_saw_width"
                    },
                    {
                        "id": "board_length",
                        "name": "Board Length",
                        "default_value": 1240,
                        "type": "number",
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~board_length"
                    },
                    {
                        "id": "board_width",
                        "name": "Board Width",
                        "default_value": 2480,
                        "type": "number",
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~board_width"
                    }
                ]
            },
            {
                "id": "sawmill_width_tolerance",
                "name": "Groove Width Tolerance",
                "type": "number",
                "default_value": 0,
                "mandatory": true,
                "preference_full_name": "cam_preferences~sawmill_width_tolerance"
            },
            {
                "id": "groove_depth_tolerance",
                "name": "Groove Depth Tolerance",
                "type": "number",
                "default_value": 0.5,
                "mandatory": true,
                "preference_full_name": "cam_preferences~groove_depth_tolerance"
            },
            {
                "id": "make_separate_top_bottom_files",
                "name": "Separate Top Bottom Files",
                "type": "bool",
                "default_value": true,
                "mandatory": true,
                "preference_full_name": "cam_preferences~make_separate_top_bottom_files"
            },
            {
                "id": "panel_size_for_cam",
                "name": "Panel Size For CAM",
                "type": "select",
                "default_value": "finishing_size",
                "preference_full_name": "cam_preferences~panel_size_for_cam",
                "mandatory": true,
                "value_options": [
                    {
                        "id": "finishing_size",
                        "name": "Finished Size"
                    },
                    {
                        "id": "cutting_size",
                        "name": "Cut Size"
                    }
                ]
            },
            {
                "id": "skirting_cut_on_carcass_panels",
                "name": "Skirting Cut on Carcass Panels",
                "type": "bool",
                "default_value": true,
                "preference_full_name": "cam_preferences~skirting_cut_on_carcass_panels",
                "mandatory": true
            },
            {
                "id": "groove_cut_enabled",
                "name": "Groove(Back Panel/Drawer Bottom Panel)",
                "type": "bool",
                "default_value": true,
                "preference_full_name": "cam_preferences~groove_cut_enabled",
                "mandatory": true
            },
            {
                "default_value": "12_characters",
                "id": "barcode_size",
                "mandatory": true,
                "name": "Barcode Size",
                "preference_full_name": "cam_preferences~barcode_size",
                "type": "select",
                "value_options": [
                    {
                        "id": "12_characters",
                        "name": "12 Characters"
                    },
                    {
                        "id": "16_characters",
                        "name": "16 Characters"
                    }
                ]
            }
        ]
    },
    {
        "id": "homag",
        "name": "Homag",
        "type": "parent",
        "children": [
            {
                "id": "drill",
                "name": "Drill",
                "type": "parent",
                "children": [
                    {
                        "id": "horizontal_drill_z_pos_basis",
                        "name": "Z-position of Horizontal drill",
                        "type": "select",
                        "default_value": "absolute",
                        "value_options": [
                            {
                                "id": "absolute",
                                "name": "Absolute value"
                            },
                            {
                                "id": "relative_to_panel_thickness",
                                "name": "Reference to panel thickness"
                            }
                        ],
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~homag_horizontal_drill_z_pos_basis"
                    }
                ]
            },
            {
                "id": "router",
                "name": "Router",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "tool_diameter",
                                "name": "Diameter(mm)",
                                "type": "number",
                                "mandatory": true
                            }
                        ]
                    },
                    {
                        "id": "depth_per_trim",
                        "name": "Depth Per Pass",
                        "type": "number",
                        "mandatory": true,
                        "default_value": 10,
                        "preference_full_name": "cam_preferences~trimming_preferences~homag_trimming~depth_per_trim"
                    },
                    {
                        "id": "enforce_manual_approach_type",
                        "name": "Enforce Manual Approach Type",
                        "type": "bool",
                        "mandatory": true,
                        "default_value": false,
                        "preference_full_name": "cam_preferences~trimming_preferences~homag_trimming~enforce_manual_approach_type"
                    },
                    {
                        "id": "manual_approach_type",
                        "name": "Manual Approach Type",
                        "type": "text",
                        "mandatory": true,
                        "default_value": "vertical",
                        "preference_full_name": "cam_preferences~trimming_preferences~homag_trimming~manual_approach_type"
                    },
                    {
                        "id": "enforce_manual_exit_type",
                        "name": "Enforce Manual Exit Type",
                        "type": "bool",
                        "mandatory": true,
                        "default_value": false,
                        "preference_full_name": "cam_preferences~trimming_preferences~homag_trimming~enforce_manual_exit_type"
                    },
                    {
                        "id": "manual_exit_type",
                        "name": "Manual Exit Type",
                        "type": "text",
                        "mandatory": true,
                        "default_value": "vertical",
                        "preference_full_name": "cam_preferences~trimming_preferences~homag_trimming~manual_exit_type"
                    }
                ]
            },
            {
                "id": "saw",
                "name": "Saw",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "tool_diameter",
                                "name": "Thickness(mm)",
                                "type": "number",
                                "mandatory": true
                            },
                            {
                                "id": "direction",
                                "name": "Allowed Axis",
                                "type": "select",
                                "value_options": [
                                    {
                                        "name": "X-axis",
                                        "id": "x-axis"
                                    },
                                    {
                                        "name": "Y-axis",
                                        "id": "y-axis"
                                    },
                                    {
                                        "name": "Both",
                                        "id": "both"
                                    }
                                ],
                                "mandatory": true,
                                "default_value": "both"
                            }
                        ]
                    },
                    {
                        "id": "sawing_offset_percentage",
                        "name": "Sawing Offset Percentage",
                        "type": "number",
                        "default_value": 90,
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~sawing_preferences~homag_sawing~sawing_offset_percentage"
                    },
                    {
                        "id": "insert_mode",
                        "name": "Insert Mode",
                        "type": "select",
                        "default_value": "automatic",
                        "value_options": [
                            {
                                "id": "automatic",
                                "name": "Automatic Detection"
                            },
                            {
                                "id": "length",
                                "name": "Length"
                            },
                            {
                                "id": "depth",
                                "name": "Depth"
                            },
                            {
                                "id": "length_with_security",
                                "name": "Length + Security Distance"
                            }
                        ],
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~sawing_preferences~homag_sawing~insert_mode"
                    }
                ]
            },
            {
                "id": "pocket",
                "name": "Pocket",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "tool_diameter",
                                "name": "Diameter(mm)",
                                "type": "number",
                                "mandatory": true
                            }
                        ]
                    },
                    {
                        "id": "corner_radius",
                        "name": "Corner Radius",
                        "type": "number",
                        "default_value": -1,
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~pocket_preferences~homag_pocket~corner_radius"
                    },
                    {
                        "id": "percentage_offset",
                        "name": "Percentage Offset",
                        "type": "number",
                        "default_value": 80,
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~pocket_preferences~homag_pocket~percentage_offset"
                    },
                    {
                        "id": "step_depth_type",
                        "name": "Step Depth Type",
                        "type": "select",
                        "default_value": "depth_per_pass",
                        "value_options": [
                            {
                                "id": "number_of_steps",
                                "name": "Number of Steps"
                            },
                            {
                                "name": "Absolute Depth",
                                "id": "depth_per_pass"
                            }
                        ],
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~pocket_preferences~homag_pocket~step_depth_type",
                        "has_dependents": true
                    },
                    {
                        "id": "depth_per_pass",
                        "name": "Absolute Depth",
                        "type": "number",
                        "default_value": 10,
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~pocket_preferences~homag_pocket~depth_per_pass"
                    },
                    {
                        "id": "number_of_steps",
                        "name": "Number of Steps",
                        "type": "number",
                        "default_value": 10,
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~pocket_preferences~homag_pocket~number_of_steps"
                    },
                    {
                        "id": "tooling_direction",
                        "name": "Tooling Direction",
                        "type": "select",
                        "default_value": "clockwise",
                        "value_options": [
                            {
                                "id": "clockwise",
                                "name": "Clockwise"
                            },
                            {
                                "id": "anticlockwise",
                                "name": "Anticlockwise"
                            }
                        ],
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~pocket_preferences~homag_pocket~tooling_direction"
                    }
                ]
            },
            {
                "id": "sidesaw",
                "name": "Side-Saw",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "tool_diameter",
                                "name": "Diameter(mm)",
                                "type": "number",
                                "mandatory": true
                            }
                        ]
                    }
                ]
            },
            {
                "id": "back_panel_groove",
                "name": "Back Panel Groove",
                "type": "parent",
                "children": [
                    {
                        "id": "operation_type",
                        "name": "Operation Type",
                        "type": "select",
                        "value_options": [
                            {
                                "id": "sawing_only",
                                "name": "Sawing Only"
                            },
                            {
                                "id": "sawing_and_pocketing",
                                "name": "Sawing And Pocketing"
                            },
                            {
                                "id": "sawing_and_routing_or_pocketing",
                                "name": "Sawing + Routing/Pocketing"
                            },
                            {
                                "id": "routing_only",
                                "name": "Routing Only"
                            }
                        ],
                        "mandatory": true,
                        "default_value": "sawing_only",
                        "preference_full_name": "cam_preferences~back_panel_grooving_preferences~homag_back_panel_grooving~operation_type"
                    }
                ]
            }
        ]
    },
    {
        "id": "biesse",
        "name": "Biesse",
        "type": "parent",
        "children": [
            {
                "id": "machine_type",
                "name": "Machine Type",
                "type": "select",
                "mandatory": true,
                "default_value": "5_face_machine",
                "value_options": [
                    {
                        "id": "5_face_machine",
                        "name": "Operates on 5 faces of panel at once (Top face & 4 Edgeband faces)"
                    },
                    {
                        "id": "6_face_machine",
                        "name": "Operates on 6 faces of panel at once (Top, Bottom & 4 Edgeband faces)"
                    }
                ],
                "preference_full_name": "cam_preferences~biesse_machine_type"
            },
            {
                "id": "drill",
                "name": "Drill",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "direction",
                                "name": "Direction",
                                "type": "select",
                                "value_options": [
                                    {
                                        "id": "horizontal",
                                        "name": "Horizontal"
                                    },
                                    {
                                        "id": "vertical",
                                        "name": "Vertical"
                                    }
                                ],
                                "mandatory": true,
                                "default_value": "vertical"
                            },
                            {
                                "id": "diameter",
                                "name": "Diameter(mm)",
                                "type": "number",
                                "mandatory": true
                            },
                            {
                                "id": "tool_type",
                                "name": "Tool Type",
                                "type": "text",
                                "mandatory": false,
                                "default_value": "0"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "router",
                "name": "Router",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "tool_diameter",
                                "name": "Diameter(mm)",
                                "type": "number",
                                "mandatory": true
                            }
                        ]
                    },
                    {
                        "id": "depth_per_trim",
                        "name": "Depth Per Pass",
                        "type": "number",
                        "mandatory": true,
                        "default_value": 10,
                        "preference_full_name": "cam_preferences~trimming_preferences~biesse_trimming~depth_per_trim"
                    },
                    {
                        "id": "enforce_manual_approach_type",
                        "name": "Enforce Manual Approach Type",
                        "type": "bool",
                        "mandatory": true,
                        "default_value": false,
                        "preference_full_name": "cam_preferences~trimming_preferences~biesse_trimming~enforce_manual_approach_type"
                    },
                    {
                        "id": "manual_approach_type",
                        "name": "Manual Approach Type",
                        "type": "text",
                        "mandatory": true,
                        "default_value": "vertical",
                        "preference_full_name": "cam_preferences~trimming_preferences~biesse_trimming~manual_approach_type"
                    },
                    {
                        "id": "enforce_manual_exit_type",
                        "name": "Enforce Manual Exit Type",
                        "type": "bool",
                        "mandatory": true,
                        "default_value": false,
                        "preference_full_name": "cam_preferences~trimming_preferences~biesse_trimming~enforce_manual_exit_type"
                    },
                    {
                        "id": "manual_exit_type",
                        "name": "Manual Exit Type",
                        "type": "text",
                        "mandatory": true,
                        "default_value": "vertical",
                        "preference_full_name": "cam_preferences~trimming_preferences~biesse_trimming~manual_exit_type"
                    }
                ]
            },
            {
                "id": "saw",
                "name": "Saw",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "tool_diameter",
                                "name": "Thickness(mm)",
                                "type": "number",
                                "mandatory": true
                            },
                            {
                                "id": "direction",
                                "name": "Allowed Axis",
                                "type": "select",
                                "value_options": [
                                    {
                                        "name": "X-axis",
                                        "id": "x-axis"
                                    },
                                    {
                                        "name": "Y-axis",
                                        "id": "y-axis"
                                    },
                                    {
                                        "name": "Both",
                                        "id": "both"
                                    }
                                ],
                                "mandatory": true,
                                "default_value": "both"
                            }
                        ]
                    },
                    {
                        "id": "sawing_overlap_value",
                        "name": "Sawing Overlap Value",
                        "type": "number",
                        "default_value": 0.1,
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~sawing_preferences~biesse_sawing~sawing_overlap_value"
                    }
                ]
            },
            {
                "id": "pocket",
                "name": "Pocket",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "tool_diameter",
                                "name": "Diameter(mm)",
                                "type": "number",
                                "mandatory": true
                            }
                        ]
                    },
                    {
                        "id": "depth_per_pass",
                        "name": "Depth Per Pass",
                        "type": "number",
                        "default_value": 10,
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~pocket_preferences~biesse_pocket~depth_per_pass"
                    }
                ]
            },
            {
                "id": "sidesaw",
                "name": "Side-Saw",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "tool_diameter",
                                "name": "Diameter(mm)",
                                "type": "number",
                                "mandatory": true
                            }
                        ]
                    }
                ]
            },
            {
                "id": "back_panel_groove",
                "name": "Back Panel Groove",
                "type": "parent",
                "children": [
                    {
                        "id": "operation_type",
                        "name": "Operation Type",
                        "type": "select",
                        "value_options": [
                            {
                                "id": "sawing_only",
                                "name": "Sawing Only"
                            },
                            {
                                "id": "sawing_and_pocketing",
                                "name": "Sawing And Pocketing"
                            },
                            {
                                "id": "sawing_and_routing_or_pocketing",
                                "name": "Sawing + Routing/Pocketing"
                            },
                            {
                                "id": "routing_only",
                                "name": "Routing Only"
                            }
                        ],
                        "mandatory": true,
                        "default_value": "sawing_only",
                        "preference_full_name": "cam_preferences~back_panel_grooving_preferences~biesse_back_panel_grooving~operation_type"
                    }
                ]
            }
        ]
    },
    {
        "id": "scm",
        "name": "SCM",
        "type": "parent",
        "children": [
            {
                "id": "router",
                "name": "Router",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "tool_diameter",
                                "name": "Diameter(mm)",
                                "type": "number",
                                "mandatory": true
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "felder",
        "name": "Felder",
        "type": "parent",
        "children": [
            {
                "id": "router",
                "name": "Trimming",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "tool_diameter",
                                "name": "Diameter(mm)",
                                "type": "number",
                                "mandatory": true
                            }
                        ]
                    }
                ]
            },
            {
                "id": "saw",
                "name": "Saw",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "tool_diameter",
                                "name": "Thickness(mm)",
                                "type": "number",
                                "mandatory": true
                            },
                            {
                                "default_value": "both",
                                "id": "direction",
                                "mandatory": true,
                                "name": "Allowed Axis",
                                "type": "select",
                                "value_options": [
                                    {
                                        "id": "x-axis",
                                        "name": "X-axis"
                                    },
                                    {
                                        "id": "y-axis",
                                        "name": "Y-axis"
                                    },
                                    {
                                        "id": "both",
                                        "name": "Both"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": "sawing_overlap_value",
                        "name": "Sawing Overlap Value",
                        "type": "number",
                        "default_value": 0.1,
                        "mandatory": true,
                        "preference_full_name": "cam_preferences~sawing_preferences~felder_sawing~sawing_overlap_value"
                    }
                ]
            },
            {
                "id": "back_panel_groove",
                "name": "Back Panel Groove",
                "type": "parent",
                "children": [
                    {
                        "id": "operation_type",
                        "name": "Operation Type",
                        "type": "select",
                        "value_options": [
                            {
                                "id": "sawing_only",
                                "name": "Sawing Only"
                            },
                            {
                                "id": "routing_only",
                                "name": "Routing Only"
                            }
                        ],
                        "mandatory": true,
                        "default_value": "sawing_only",
                        "preference_full_name": "cam_preferences~back_panel_grooving_preferences~felder_back_panel_grooving~operation_type"
                    }
                ]
            }
        ]
    },
    {
        "id": "kdt_nc",
        "name": "KDT(.NC)",
        "type": "parent",
        "children": [
            {
                "id": "general",
                "name": "General",
                "type": "parent",
                "children": [
                    {
                        "id": "spindle_speed",
                        "name": "Spindle Speed(rpm)",
                        "type": "number",
                        "mandatory": true,
                        "default_value": "10000"
                    },
                    {
                        "id": "home_x",
                        "name": "Home X",
                        "type": "number",
                        "mandatory": true,
                        "default_value": "0"
                    },
                    {
                        "id": "home_y",
                        "name": "Home Y",
                        "type": "number",
                        "mandatory": true,
                        "default_value": "0"
                    },
                    {
                        "id": "home_z",
                        "name": "Home Z",
                        "type": "number",
                        "mandatory": true,
                        "default_value": "0"
                    },
                    {
                        "id": "safe_z",
                        "name": "Safe Z",
                        "type": "number",
                        "mandatory": true,
                        "default_value": "25"
                    }
                ]
            },
            {
                "id": "drill",
                "name": "Drill",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "slot",
                                "name": "Slot",
                                "type": "number",
                                "mandatory": true
                            },
                            {
                                "id": "diameter",
                                "name": "Diameter(mm)",
                                "type": "number",
                                "mandatory": true
                            },
                            {
                                "id": "feed_rate",
                                "name": "Feed Rate(mm/min)",
                                "type": "number",
                                "mandatory": true,
                                "default_value": "10000"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "router",
                "name": "Router",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "slot",
                                "name": "Slot",
                                "type": "number",
                                "mandatory": true
                            },
                            {
                                "id": "diameter",
                                "name": "Diameter(mm)",
                                "type": "number",
                                "mandatory": true
                            },
                            {
                                "id": "feed_rate",
                                "name": "Feed Rate(mm/min)",
                                "type": "number",
                                "mandatory": true,
                                "default_value": "10000"
                            }
                        ]
                    },
                    {
                        "id": "final_pass",
                        "name": "Final Pass",
                        "type": "bool",
                        "mandatory": true,
                        "default_value": false,
                        "has_dependents": true
                    },
                    {
                        "id": "final_pass_depth",
                        "name": "Final Pass Depth(mm)",
                        "type": "number",
                        "mandatory": true,
                        "default_value": 1
                    }
                ]
            },
            {
                "id": "saw",
                "name": "Saw",
                "type": "parent",
                "children": [
                    {
                        "id": "tool_list",
                        "name": "Tool List",
                        "type": "table",
                        "fields": [
                            {
                                "id": "tool_name",
                                "name": "Tool Name",
                                "type": "text",
                                "mandatory": true
                            },
                            {
                                "id": "slot",
                                "name": "Slot",
                                "type": "number",
                                "mandatory": true
                            },
                            {
                                "id": "diameter",
                                "name": "Thickness(mm)",
                                "type": "number",
                                "mandatory": true
                            },
                            {
                                "id": "feed_rate",
                                "name": "Feed Rate(mm/min)",
                                "type": "number",
                                "mandatory": true,
                                "default_value": "10000"
                            }
                        ]
                    }
                ]
            }
        ]
    }
]

export default default_cam_structure