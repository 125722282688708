import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useAlert } from 'react-alert'
import './index.js';
import Multiselect from '../Multiselect';

const DCKeyValuePair_applicable = ({open, item_json, idx, single_item_json, set_item_json ,mode, tags, component_options_json, position_type}) => {

    const alert = useAlert()
    const [selected_comp , set_selected_comp] = useState([]);
    const [selected_sub_comp,set_selected_sub_comp] =useState([])
    const [selected_property,set_selected_property] =useState([])
    const [values_array ,set_values_array] =useState([])
    const [selected_tag_1_array,set_selected_tag_1_array] =useState([])

    useEffect(() => { 
        if(single_item_json){
            if(single_item_json && single_item_json.property && single_item_json.property[0] && single_item_json.property[0].id==='tag'){
                set_values_array(tags)
                set_selected_tag_1_array(single_item_json.tag?single_item_json.tag:[])
            }

            set_selected_comp(single_item_json.component?single_item_json.component:[])
            set_selected_sub_comp(single_item_json.subcomponent?single_item_json.subcomponent:[])
            set_selected_property(single_item_json.property?single_item_json.property:[])
        }
	},[single_item_json])

    const onclick_item = (operator, value) => {
        update_item_json(idx,operator, value)
        if(operator==='component'){
            // var x_unit = component_options_json.find(x => x.id===value)
            set_selected_comp(value)
            update_item_json(idx,'subcomponent', [])
            set_selected_sub_comp([])
            console.log('item_json======>',item_json)
        }
        if(operator==='subcomponent' || operator==='component'){
            if(operator==='subcomponent'){
                // var x_sub_unit = selected_comp.sub_component.find(x => x.id===value)
                set_selected_sub_comp(value)
            }
            update_item_json(idx,'property', [])
            set_selected_property([])
            set_values_array([])
            console.log('item_json======>',item_json)
        }
        if(operator==='property'|| operator==='subcomponent' || operator==='component'){
            if(operator==='property'){
                // var x_property = selected_sub_comp.properties.find(x => x.id===value) 
                set_selected_property(value)
                if(value && value.length){
                    value[0].id==='tag' && set_values_array(tags)
                    value[0].type==='range' && update_item_json(idx,'operator','range')
                }
            }
            update_item_json(idx,'value', []) 
            set_selected_tag_1_array([])
            console.log('item_json======>',item_json)
        }
        if(operator==='value'){
            set_selected_tag_1_array(value)
            // console.log('item_json======>',item_json)
        }
        if(operator==='tag'){
            set_selected_tag_1_array(value)
            // console.log('item_json======>',item_json)
        }
    }

    useEffect(() =>{
		if(!open){
			handleClose_for_mf__source()
		}
	},[open])

    const handleClose_for_mf__source = () => {
        set_selected_sub_comp([])
        set_selected_property('')
        // set_selected_tag_1_array ([])
        // set_values_Array ([])
    }

	const delete_item_json = (index) => {
		let a = [...item_json];
		a.splice(index,1)
		set_item_json(a);
	}

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
	}

    
    useEffect(() => {
        if(item_json){
            console.log(item_json)
        }
    },[item_json])

    useEffect(() => {
        console.log("selected prop", selected_property, "item json", item_json)
    },[selected_property])

    return(
        <div style={{ display:'flex', alignItems:'center', ...(mode=='view' ? { pointerEvents: 'none' } : {}) }}>
            
            <CardBody style={{width:'100%',padding:'15px 20px', overflow:'unset', backgroundColor:'white', marginBottom:item_json && item_json.length-1===idx?'0px':'20px', height:position_type==='applicable'?'100px':'130px'}}>
                {
                    position_type==='applicable'?'':
                    <Row style={{marginBottom:'10px'}}>
                        <Col xs={11}>
                            <div style={{fontSize:'16px', color:'black'}}>{position_type==='action'?'Action':'Condition'} {idx+1}</div>
                        </Col>
                        <Col xs={1} style={{ textAlign:'right'}}>
                            <i onClick={() => delete_item_json(idx)} style={{ color:'#F0656F',fontSize:'14px',cursor:'pointer'}} className='fa fa-trash'/>
                        </Col>
                    </Row>
                }
                <Row key={idx} style={{ margin:0}}>
                    <Col xs={2} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                        <div style={{marginBottom:'5px'}}>Component</div>
                        <Multiselect
                            options={component_options_json} 
                            selectedValues={single_item_json ?single_item_json.component:[]}
                            onSelect={(selectedList) => onclick_item('component', selectedList)}
                            onRemove={(selectedList) => onclick_item('component', selectedList)}
                            displayValue="name" // Property name 
                            id={'select_comp'+idx}
                            placeholder='Search Value'
                            selectionLimit={1}
                            disable={idx>0?true:false}
                        />
                    </Col>
                    <Col xs={2} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                        <div style={{marginBottom:'5px'}}>SubComponent</div>
                        <Multiselect
                            options={selected_comp && selected_comp.length && selected_comp[0].sub_component && selected_comp[0].sub_component.length?selected_comp[0].sub_component:[]}
                            selectedValues={single_item_json && single_item_json.subcomponent?single_item_json.subcomponent:[]}
                            onSelect={(selectedList) => onclick_item('subcomponent', selectedList)}
                            onRemove={(selectedList) => onclick_item('subcomponent', selectedList)}
                            displayValue="name" // Property name 
                            id={'select_'+idx}
                            placeholder='Search Value'
                            selectionLimit={1}
                            disable={idx>0?true:false}
                        />
                    </Col>
                    <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px'}}>
                        <div style={{marginBottom:'5px'}}>Property</div>
                            {
                                selected_sub_comp && selected_sub_comp.length && selected_sub_comp[0].properties &&  selected_sub_comp[0].properties.length ?
                                    <Multiselect
                                        options={selected_sub_comp[0].properties? (selected_sub_comp && selected_sub_comp[0] && selected_sub_comp[0].id==='skirting'?selected_sub_comp[0].properties.filter(x=>x.id!='sku'):selected_sub_comp[0].properties):[]} 
                                        selectedValues={selected_property?selected_property:[]}
                                        onSelect={(selectedList) => onclick_item('property', selectedList)}
                                        onRemove={(selectedList) => onclick_item('property', selectedList)}
                                        displayValue="name" // Property name 
                                        id={'select_'+idx}
                                        placeholder='Search Value'
                                        selectionLimit={1}
                                    />  
                                :
                                <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', height:'32px', color:'#425A70'}} type="select">
                                    <option>Choose a value</option>
                                </Input>
                            }
                    </Col>
                    
                    <Col xs={2} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px'}}>
                        <div style={{marginBottom:'5px'}}>Operator</div>
                        <Input id={'box1'+idx} 
                            style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto', opacity:selected_property && selected_property.length && selected_property[0].id==='no_condition'?.5:1}} type="select" 
                            value={item_json[idx]?item_json[idx].operator:'none'}
                            onChange={(e) =>update_item_json(idx,'operator', e.target.value)}
                            onClick={(e) =>update_item_json(idx,'operator', e.target.value)}
                            disabled={selected_property && selected_property.length && selected_property[0].id==='no_condition'?true:false}
                        >
                            {
                                selected_property && selected_property.length && selected_property[0].type==='number' ?
                                <React.Fragment>
                                    <option value='equal'>Equal</option>
                                    <option value='less'>Less Than</option>
                                    <option value='greater'>Greater Than</option>
                                    <option value='lessequal'>Less Than Equal</option>
                                    <option value='greaterequal'>Greater Than Equal</option>
                                    <option value='range'>Range</option>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <option value='equal'>Equal</option>
                                    {selected_property && selected_property[0] && ['front_skirting_visibility', 'left_skirting_visibility', 'right_skirting_visibility'].includes(selected_property[0].id)?"":<option value='notequal'>Not Equal</option>}
                                </React.Fragment>
                            }
                        </Input>
                    </Col>
                    
                    {console.log("selected props", selected_property)}
                    <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px'}}>
                        {selected_property && selected_property.length && selected_property[0].type==='number' && item_json[idx] && item_json[idx].operator && item_json[idx].operator=='range'?<div style={{marginBottom:'5px'}}>Min-Max</div>:<div style={{marginBottom:'5px'}}>Value</div>}
                        {
                            selected_property && selected_property.length && item_json && item_json.length?
                            
                            <React.Fragment>
                                {
                                    selected_property[0].type==='number' && item_json[idx] && item_json[idx].operator && item_json[idx].operator!='range' ?
                                    <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="number"
                                        placeholder='Enter a value'
                                        value={item_json[idx]?item_json[idx].value:''}
                                        onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'value', Number(e.target.value)):alert.error('Negative Number not allowed')}
                                    />
                                    :''
                                }
                                {
                                    selected_property[0].type==='number' && item_json[idx] && item_json[idx].operator && item_json[idx].operator==='range' ?
                                        <React.Fragment>
                                            <div style={{display:'flex'}}>
                                                <Input id={'box3'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="number"
                                                    placeholder='min-value'
                                                    title='min value'
                                                    value={item_json[idx]?item_json[idx].min_value:''}
                                                    onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'min_value',Number(e.target.value)):alert.error('Negative Number not allowed')}
                                                />
                                                <Input id={'box3'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="number"
                                                    placeholder='max-value'
                                                    title='max value'
                                                    value={item_json[idx]?item_json[idx].max_value:''}
                                                    onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'max_value',Number(e.target.value)):alert.error('Negative Number not allowed')}
                                                />
                                            </div>
                                        </React.Fragment>
                                    :''
                                }
                                {
                                    selected_property[0].id==='tag' ?
                                        <React.Fragment>
                                            <div style={{display:'flex'}}>
                                                <Input id={'box1'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto', width:'70px'}} type="select" 
                                                    value={item_json[idx]?item_json[idx].value_combination_logic:'none'}
                                                    onChange={(e) =>update_item_json(idx,'value_combination_logic', e.target.value)}
                                                    title='Select Logic between property values'
                                                >    
                                                    <option value='and'>And</option>
                                                    <option value='or'>Or</option>
                                                </Input>
                                                <div style={{width:'calc(100% - 70px)'}}>
                                                    <Multiselect
                                                        style={{backgroundColor:'white'}}
                                                        options={values_array && values_array.length? JSON.parse(JSON.stringify(values_array)):[]} 
                                                        selectedValues={selected_tag_1_array?selected_tag_1_array:[]}
                                                        onSelect={(selectedList) => onclick_item('tag', selectedList)}
                                                        onRemove={(selectedList) => onclick_item('tag', selectedList)}
                                                        displayValue={selected_property[0].id==="tag"?'tag':'name'} // Property name 
                                                        id={'select_2'+idx}
                                                        placeholder= {selected_property[0].id==="tag"?'Select Tag':'Select Sales Channel'}
                                                    /> 
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    :''
                                }
                                {
                                    selected_property[0].type==='select' ?
                                        
                                        <Multiselect
                                            style={{backgroundColor:'white'}}
                                            options={values_array && values_array.length? JSON.parse(JSON.stringify(values_array)):[]} 
                                            selectedValues={selected_tag_1_array?selected_tag_1_array:[]}
                                            onSelect={(selectedList) => onclick_item('value', selectedList)}
                                            onRemove={(selectedList) => onclick_item('value', selectedList)}
                                            displayValue='name' // Property name 
                                            id={'select_2'+idx}
                                            placeholder= 'Select Option'
                                            selectionLimit={1}
                                            disable={selected_property && selected_property.length && selected_property[0].id==='no_condition'?true:false}
                                        /> 
                                    :''
                                }

                                {
                                    selected_property[0].type==='input-select' && ['front_skirting_visibility', 'left_skirting_visibility', 'right_skirting_visibility'].includes(selected_property[0].id)?
                                        
                                        <Input id={'box1'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto'}} type="select" 
                                            value={item_json[idx]?item_json[idx].value:'none'}
                                            onChange={(e) =>update_item_json(idx,'value', e.target.value)}
                                            onClick={(e) =>update_item_json(idx,'value', e.target.value)}
                                        >   
                                            <option value={null}>Select a value</option>    
                                            <option value='visible'>Visible</option>
                                        </Input> 
                                    :''
                                }
                            </React.Fragment>
                            :
                            <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', height:'32px', color:'#425A70'}} type="select">
                                <option value='none'>Choose a value</option>
                            </Input>    
                        }
                    </Col>
                </Row>
            </CardBody>
        </div>
    )
}

export default DCKeyValuePair_applicable;

