const send_custom_analytics = (analytics_json, event="track") =>{
    if(window['analytics']){
        let active_user_details = window.get_user()
        let analytics_json_complete = { ...analytics_json, "company_id": active_user_details.store_id, "user_id": active_user_details.id }
        try {
            if(event == 'auto'){
                window['analytics'].page(analytics_json_complete)
            }else if(event == 'track'){
                window['analytics'].track('auto', analytics_json_complete)
            }
        }
        catch(err) {
            console.log("Error in analytics",err)
        }
    }
}

export default send_custom_analytics
