import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import general_fetch from "../../utils/fetch";
import React, { useContext, useEffect, useState } from "react";
import { OrgBuContext } from "../Contexts/OrgBuContext";
import { UserContext } from "../Contexts/UserContext";
import GroupInventoryCard from "../../views/Catalogue/SKU_Groups/GroupInventoryCard";
import GroupListCard from "../GroupListCard";
import { UncontrolledCollapse } from "../ICollpsable";
import { IModalBody, IModalFooter, IModalHeader } from "../IModal";
import { useAlert } from "react-alert";
import { app_loader_atom } from "../../AppRecoil/atoms";
import { Checkbox } from "antd";
import { useSetRecoilState } from "recoil";

const BulkDeleteModal = ({ open, handleClose, bulk_selected_sku_groups, bulk_selected_skus, no_of_bulk_selected_skus_in_sub_cat, no_of_bulk_selected_skus_in_grp, sku_sub_categories, set_page_loader, fetch_sku_groups, exit_bulk_selection_mode, sku_category_name, submit_for_delete_confirm }) => {
    const [filtered_catalogue, set_filtered_catalogue] = useState([]);
    const [groups_ownership_map, set_groups_ownership_map] = useState({});
    const [delete_groups, set_delete_groups] = useState(false);
    const toggle_delete_groups = () => set_delete_groups(!delete_groups)

	const set_app_loader = useSetRecoilState(app_loader_atom)
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)

    const alert = useAlert()

    const onClickHandleClose = () => {
        set_filtered_catalogue([])
        set_groups_ownership_map({})
        set_delete_groups(false)
        handleClose()
    }

    const remove_sku_details = async (sku_ids) => {
        set_app_loader({show: true, text: 'Deleting SKUs...'})
		try{
			if (sku_ids && sku_ids.length) {
				let body = {
					sku_ids: sku_ids.filter(x => bulk_selected_skus[x])
				}
				if(org_bu_view == 'bu'){
					body['business_unit_id'] = user.current_business_unit_id
					var reponse = await general_fetch({url :'sku/remove_from_business_unit' , body })
				}else if(org_bu_view == 'org'){
					var reponse = await general_fetch({url :'sku/remove_from_store' , body })
				}
                alert.success('SKUs removed successfully')
                
                if(delete_groups){
                    let group_ids = Object.keys(bulk_selected_sku_groups).filter(x => bulk_selected_sku_groups[x]).filter(single_group => groups_ownership_map[single_group] == user.current_business_unit_id)
                    delete_group(group_ids)
                }
                onClickHandleClose()
                exit_bulk_selection_mode()
				fetch_sku_groups()
			}
			set_app_loader({show: false, text: 'Please Wait...'})
		}catch(err){
			console.log(err)
		}
	}

    const delete_group = async (ids) => {
		try{
			let body = {
				sku_group_ids: ids
			}
			if(org_bu_view == 'bu'){
				body['business_unit_id'] = user.current_business_unit_id
				var reponse = await general_fetch({url :'sku_group/remove_from_business_unit' , body })
			}else if(org_bu_view == 'org'){
				var reponse = await general_fetch({url :'sku_group/remove_from_store' , body })
			}
			// fetch_sku_groups()
			alert.success('Groups Deleted Successfully')
		}catch(err){
			console.log(err)
			alert.error('Request Failed')
		}
	}

    const fetch_groups = async () => {
        try {

            let sub_cats_included;
            let all_sub_cats = Object.keys(no_of_bulk_selected_skus_in_sub_cat)
            sub_cats_included = all_sub_cats.filter(x => no_of_bulk_selected_skus_in_sub_cat[x])

            let body = { sku_sub_category_id: sub_cats_included }
            if (org_bu_view === 'bu') {
                body['business_unit_id'] = user.current_business_unit_id;
            }
            set_page_loader({
                show: true,
                text: 'Please wait...'
            })
            let resp = await general_fetch({ url: 'inventory/get_groups', body })

            if (resp && resp.length) {
                let temp_hierarchical_data = {}
                for (let i = 0; i < resp.length; i++) {
                    // console.log('final_json_data', resp, resp[i])
                    let single_group = resp[i]
                    if (no_of_bulk_selected_skus_in_grp[single_group.id] > 0) {
                        let sub_cat_id = single_group.sku_sub_category_id
                        let skus = single_group.sku
                        let new_skus = []
                        groups_ownership_map[single_group.id] = single_group.business_unit_id
                        // groups_ownership_map[single_group.id] = single_group.store_id
                        // if(skus && skus.length){
                        //     for(let j=0;j<skus.length;j++){
                        //         // console.log('final_json_data3', resp[i])
                        //         if(bulk_selected_skus[skus[j].id])
                        //             new_skus.push(skus[j])
                        //     }
                        // }
                        // single_group.sku = new_skus
                        // console.log('final_json_data3',skus)
                        if (temp_hierarchical_data[sub_cat_id]) {
                            temp_hierarchical_data[sub_cat_id].push(single_group)
                        } else {
                            temp_hierarchical_data[sub_cat_id] = [single_group]
                        }
                    }
                }

                let temp_hierarchical_data_array = []
                let myArr = Object.keys(temp_hierarchical_data)
                if (myArr && myArr.length) {
                    for (let i = 0; i < myArr.length; i++) {
                        let sub_cat = sku_sub_categories && sku_sub_categories.length ? sku_sub_categories.find(o => o.id == myArr[i]) : ''
                        let json_data = { name: sub_cat ? sub_cat.name : '', id: myArr[i], sku_groups: temp_hierarchical_data[myArr[i]] }
                        temp_hierarchical_data_array.push(json_data)
                        console.log('final_json_data', json_data)
                    }
                }
                set_filtered_catalogue(temp_hierarchical_data_array)
            }
            // set_page_loader({
            //     show:false
            // })
        } catch (err) {
            console.log(err)
        }
        set_page_loader({
            show: false,
            text: 'Please wait...'
        })
    }

    useEffect(() => {
        if (open && no_of_bulk_selected_skus_in_sub_cat && no_of_bulk_selected_skus_in_grp && sku_sub_categories) {
            fetch_groups()
        }
    }, [open, no_of_bulk_selected_skus_in_sub_cat, no_of_bulk_selected_skus_in_grp, sku_sub_categories]);

    return (
        <Modal isOpen={open} className="modal-dialog-centered" /*toggle={onClickHandleClose}*/ size="xl">
            <IModalHeader toggle={onClickHandleClose}>Bulk Delete Preview</IModalHeader>
            <IModalBody style={{ height: '700px', overflow: 'auto' }}>
                <div className='flex_property mb-3' style={{ justifyContent: 'space-between', backgroundColor: 'white', height: '40px', fontSize: '15px' }}>
                    <span style={{ marginLeft: '25%' }}>Total <span style={{ fontWeight: '900' }}>{bulk_selected_skus ? Object.keys(bulk_selected_skus).filter(x => bulk_selected_skus[x]).length : 0} SKUs </span>Selected</span>
                    <span>from <span style={{ fontWeight: '900' }}>{filtered_catalogue.length} {filtered_catalogue.length == 1 ? 'SubCategory' : 'SubCategories'}</span></span>
                    <span style={{ marginRight: '25%' }}>in Category<span style={{ fontWeight: '900' }}> {sku_category_name}</span></span>
                </div>
                {
                    filtered_catalogue && filtered_catalogue.length ? filtered_catalogue.map((single_sub_category, idx) => (
                        <React.Fragment>
                        {/* // <div className='flex_property groups_container_bulk_edit_preview' style={{marginTop:idx==0?'10px':''}}> */}
                            {/* <div className='col-12 flex_property sub_cat_name_bulk_preview' onClick={() => update_show_grp_preview(single_sub_category.id, !(show_grp_preview[single_sub_category.id]))}>
                                <span style={{fontWeight:'900'}}>{single_sub_category.name+'\xa0'+'('+single_sub_category.sku_groups.length+' Groups and '+no_of_bulk_selected_skus_in_sub_cat[single_sub_category.id]+' SKUs'+')'}</span>
                                <i className={show_grp_preview[single_sub_category.id]?'fa fa-caret-up':'fa fa-caret-down'}/>
                            </div> */}
                        {/* // { */}
                        <UncontrolledCollapse className='mb-3' header={single_sub_category.name+'\xa0'+'('+single_sub_category.sku_groups.length+' Groups and '+no_of_bulk_selected_skus_in_sub_cat[single_sub_category.id]+' SKUs'+')'} style_header={{border:'1px solid #E6E9EE'}} defaultState={true}>
                            <div className="flex_property" style={{ flexWrap: 'wrap' }}>
                                {
                                    single_sub_category.sku_groups && single_sub_category.sku_groups.length ? single_sub_category.sku_groups.map((single_group) => (
                                        <div key={idx} className='col-12 col-sm-12 col-md-6 col-lg-6' style={{ padding: '5px' }}>
                                            <GroupListCard
                                                // sku = {single_group.sku}
                                                // title = {single_group.name}
                                                // user = {user}
                                                sku_group={single_group}
                                                // border_animation_group = ''
                                                // showing_bulk_edit_preview = {true}
                                                is_selected={bulk_selected_sku_groups[single_group.id]}
                                                bulk_selected_skus={bulk_selected_skus}
                                            />
                                        </div>
                                    )) : ''
                                }
                            </div>
                        </UncontrolledCollapse>
                        {/* // } */}
                        {/* //     </div> */}
                        </React.Fragment>
                    )) : ''
                }
            </IModalBody>
            <IModalFooter>
                <div className="flex_property" style={{marginRight: 'auto'}}>
                    <Checkbox checked={delete_groups} onClick={toggle_delete_groups}/>
                    <div className="ml-2">Delete groups as well</div>
                </div>
                <Button className="white_button" onClick={onClickHandleClose}>Go Back to Selection</Button>
                <Button className="blue_button" onClick={() => submit_for_delete_confirm(() => remove_sku_details(Object.keys(bulk_selected_skus)), () => {}, `SKUs${delete_groups ? ' and Groups' : ''} will be deleted permanently.`)}>Delete All</Button>
            </IModalFooter>
        </Modal>
    )
}

export default BulkDeleteModal