import React, { useEffect, useState } from "react";
import './style.css';

const IBadge = ({children, color="blue", className, ...props}) => {
    return(
        <>
            <div className={`inf_badge inf_${color}_badge ${className ? className : ''}`} {...props}>
                {children}
            </div>
        </>
    )
}

export default IBadge