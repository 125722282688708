import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
// import Multiselect from 'multiselect-react-dropdown';
import { useAlert } from 'react-alert';
import Multiselect from '../Multiselect';

const units_json = [{id:'panel_area', name:'Panel Area'},{id:'ply_area', name:'Ply Area'}]

const MFKeyValuePair_dest = ({trigger_red_marker, item_json,idx, single_item_json, set_item_json, allowed_properties ,mode, all_division_tree_data, tags, all_category, all_sub_category, all_edge_band_sku,all_edge_band_glue_sku, all_panel_glue_sku, set_dest_condition_combination_logic, dest_condition_combination_logic, is_back_button }) => {
    console.log("allowed props", allowed_properties, item_json);
    const [selected_property,set_selected_property] =useState([])
    const [selected_tag_1_array ,set_selected_tag_1_array ] =useState([])
    const [values_json ,set_values_json ] =useState([])
    const [values_json2 ,set_values_json2 ] =useState([])
    const [value_json_ply_panel, set_value_json_ply_panel] = useState('Panel Area');
    const [value_json_scaling_factor, set_value_json_scaling_factor] = useState(null);
    const alert = useAlert()

    useEffect(() => {
        if(typeof item_json[idx].value === 'object' && item_json[idx].value){
            set_value_json_ply_panel(item_json[idx].value.ply_or_panel);
            set_value_json_scaling_factor(item_json[idx].value.scaling_factor);
        }
        
    },[])

    useEffect(() => {
        if(selected_property.length && selected_property[0].id == 'ply_panel_area_category') {
            var temp_value = {
                ply_or_panel: value_json_ply_panel,
                scaling_factor: value_json_scaling_factor
            };
            update_item_json(idx, 'value', temp_value);
        }
    },[value_json_ply_panel, value_json_scaling_factor])

    useEffect(() => {
        console.log("useeffect ", item_json)
    },[item_json])

    useEffect(() => {
        console.log('single_item_json--------->', single_item_json)
        if(single_item_json){
            set_selected_property(single_item_json.property)
        }else{
            set_selected_property([])
        }
    },[single_item_json, allowed_properties])
    useEffect(() => {
        if(single_item_json){
            console.log('single_item_json---------->',single_item_json)
        }
    },[single_item_json])

	const delete_item_json = (index) => {
		let a = [...item_json];
		a.splice(index,1)
		set_item_json(a);
	}

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
    }

    const fn_check_null = (x) => {
        if(x===null){
            alert.error('value of '+selected_property && selected_property.length?selected_property[0].name+' is no longer available.':'')
        }
        return x;
    }

    useEffect(() => {
        console.log("selected property", selected_property);
        if(selected_property && selected_property.length){
            if(selected_property[0].id==='edge_band_sku'){
                set_values_json(all_edge_band_sku)
                set_selected_tag_1_array(single_item_json.sku && single_item_json.sku.length?single_item_json.sku.filter(x=>fn_check_null(x)):[])
            }else if(selected_property[0].id==='edgeband_glue_sku'){
                set_values_json(all_edge_band_glue_sku)
                set_selected_tag_1_array(single_item_json.sku && single_item_json.sku.length?single_item_json.sku.filter(x=>fn_check_null(x)):[])
            }else if(selected_property[0].id==='panel_glue_sku'){
                set_values_json(all_panel_glue_sku)
                set_selected_tag_1_array(single_item_json.sku && single_item_json.sku.length?single_item_json.sku.filter(x=>fn_check_null(x)):[])
            }else if(selected_property[0].json_value==='units'){
                set_values_json(units_json)
            }else if(selected_property[0].id === 'ply_panel_area_category'){
                set_values_json(all_panel_glue_sku);
                set_values_json2(units_json);
                set_selected_tag_1_array(single_item_json.sku && single_item_json.sku.length?single_item_json.sku.filter(x=>fn_check_null(x)):[])
            }else{
                set_values_json([])
            }
        }
    },[selected_property])

    const onclick_property = (value) => {
        set_selected_property(value)
        update_item_json(idx,'property', value) 
        update_item_json(idx,'operator', 'equal')
    }

    const onselect_multi_dest_tags = (selectedList_1) => {
		set_selected_tag_1_array(selectedList_1)
        update_item_json(idx,'sku', selectedList_1)
	}

    return(
        <div style={{display:'flex', alignItems:'center'}}>
        <CardBody style={{padding:'15px 20px 120px 20px',fontSize:'12px', color:'#425A70', overflow:'unset', backgroundColor:'white', marginBottom:item_json && item_json.length-1===idx?'0px':'20px', height:'120px'}}>
            <Row>
                <Col>
                    <div style={{fontSize:'16px', color:'black'}}>Action {idx+1}</div>
                </Col>
            </Row>
            <Row style={{margin:0, fontSize:'12px'}}>
                <Col xs={3} style={{display:'flex', alignItems:"center", padding:'10px 10px 5px 0px'}}>
                    Property
                </Col>
                <Col xs={2} style={{display:'flex', alignItems:"center", padding:'10px 10px 5px'}}>
                    Operator
                </Col>
                <Col xs={6} style={{display:'flex', alignItems:"center", padding:'10px 10px 5px'}}>
                    Value
                </Col>
                {/* <Col xs={2} style={{display:'flex', alignItems:"center"}}>
                </Col> */}
                <Col xs={4} style={{display:'flex', alignItems:'center', justifyContent:'flex-end', padding:'10px 0px 5px 10px'}}>
                </Col>
            </Row>
            <Row key={idx} style={{margin:0}}>
                <Col xs={3} style={{display:'flex', alignItems:"center", padding:'0px 10px 0px 0px'}}>
                    {
                        allowed_properties && allowed_properties.length?
                            <Multiselect
                                style_badge_container={{borderColor: trigger_red_marker && (!selected_property || !selected_property.length) ? 'red' : ''}}
                                options={allowed_properties} 
                                selectedValues={selected_property}
                                onSelect={onclick_property}
                                onRemove={onclick_property} 
                                displayValue="name" // Property name 
                                id={'dest_property_'+idx}
                                placeholder='Search Value'
                                selectionLimit={1}
                            />  
                        :
                        <Input id={'box3'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70', borderColor: trigger_red_marker && (!selected_property || !selected_property.length) ? 'red' : ''}} type="select">
                            <option>Choose a value</option>
                        </Input>
                    }
                </Col>
                <Col xs={2} style={{fontSize:'12px', color:'#425A70', display:'flex', alignItems:'center', padding:'0px 10px'}}>
                    {
                        selected_property && selected_property.length?
                        <Input id={'dest_operator_'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto'}} type="select" 
                            value={item_json[idx]?item_json[idx].operator:'none'}
                            onChange={(e) =>update_item_json(idx,'operator', e.target.value)}
                        >
                            {
                                //JIRA AP-912
                                // selected_property && selected_property.length && selected_property[0].type==='number' ?
                                // <React.Fragment>
                                //     <option value='equal'>Equal</option>
                                //     <option value='less'>Less Than</option>
                                //     <option value='greater'>Greater Than</option>
                                //     <option value='lessequal'>Less Than Equal</option>
                                //     <option value='greaterequal'>Greater Than Equal</option>
                                // </React.Fragment>
                                // :
                                <React.Fragment>
                                    <option value='equal'>Equal</option>
                                </React.Fragment>
                            }
                        </Input>
                        :
                        <Input id={'box3'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70', borderColor: trigger_red_marker ? 'red' : ''}} type="select">
                            <option>Choose a value</option>
                        </Input>
                    }
                </Col>
                <Col xs={6} style={{fontSize:'12px', color:'#425A70', display:'flex', alignItems:'center', padding:'0px 0px 0px 10px'}}>
                    {
                        selected_property && selected_property.length?
                        <React.Fragment>
                            {
                                selected_property[0].type==='number'?
                                <Input id={'dest_value_'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70', borderColor: trigger_red_marker && ((!item_json[idx]) || ((![0, '0'].includes(item_json[idx].value) && (!item_json[idx].value || item_json[idx].value == 'none')) || (Array.isArray(item_json[idx].value) && item_json[idx].value.length == 0))) ? 'red' : ''}} type="number"
                                    placeholder={selected_property[0].json_value==='percentage'?'Enter a percentage value'
                                        :
                                        selected_property[0].json_value==='cost'?'in square mm':'Enter value in mm'
                                    }
                                    value={item_json[idx]?item_json[idx].value:''}
                                    onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'value',Number(e.target.value)):alert.error('Negative Number not allowed')}
                                />
                                :''
                            }
                            {
                                ['panel_conversion_cost_basis','cabinet_package_material_cost_basis','cabinet_assembly_cost_basis'].includes(selected_property[0].id)?
                                <Input id={'dest_value_'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70', borderColor: trigger_red_marker && ((!item_json[idx]) || ((![0, '0'].includes(item_json[idx].value) && (!item_json[idx].value || item_json[idx].value == 'none')) || (Array.isArray(item_json[idx].value) && item_json[idx].value.length == 0))) ? 'red' : ''}} type="select" 
                                    value={item_json[idx].value?item_json[idx].value:'none'}
                                    onChange={(e) =>update_item_json(idx,'value',e.target.value)}
                                >
                                    <option value='none'>Choose a property</option>
                                    {
                                        values_json && values_json.length ? values_json.map((item, idx)=>(
                                            <option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} key={idx} value={item.id}>
                                                {item.name?item.name:''}
                                            </option>
                                        )):""
                                    }
                                </Input>
                                :''
                            }
                            {/* {
                                selected_property && selected_property.type==='bool'?
                                <Input id={'box3'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select"
                                    value={item_json[idx]?item_json[idx].value:'none'}
                                    value={item_json[idx].value}
                                    onChange={(e) =>onclick_selected_property(idx, e.target.value)}
                                >
                                    <option value='none'>Select option</option>
                                    <option value={false}>False</option>
                                    <option value={true}>True</option>
                                </Input>
                                :''
                            } */}
                            {
                                selected_property && selected_property.length && ['edgeband_glue_sku', 'panel_glue_sku'].includes(selected_property[0].id)?
                                    <Multiselect
                                        style_badge_container={{borderColor: trigger_red_marker && ((!item_json[idx]) || ((![0, '0'].includes(selected_tag_1_array) && (!selected_tag_1_array || selected_tag_1_array == 'none')) || (Array.isArray(selected_tag_1_array) && selected_tag_1_array.length == 0))) ? 'red' : ''}}
                                        options={values_json && values_json.length? values_json:[]} 
                                        selectedValues={selected_tag_1_array}
                                        onSelect={onselect_multi_dest_tags}
                                        onRemove={onselect_multi_dest_tags}
                                        displayValue="name" // Property name 
                                        id={'dest_value_'+idx}
                                        placeholder='Search Value'
                                        selectionLimit={1}
                                    />
                                :''
                            }
                            {
                                selected_property && selected_property.length && selected_property[0].id == 'ply_panel_area_category' ? 
                                <>
                                <div style={{width:'450px'}}>
                                    <Multiselect
                                        style_badge_container={{borderColor: trigger_red_marker && ((!item_json[idx]) || ((![0, '0'].includes(selected_tag_1_array) && (!selected_tag_1_array || selected_tag_1_array == 'none')) || (Array.isArray(selected_tag_1_array) && selected_tag_1_array.length == 0))) ? 'red' : ''}}
                                        options={values_json && values_json.length? values_json:[]} 
                                        selectedValues={selected_tag_1_array}
                                        onSelect={onselect_multi_dest_tags}
                                        onRemove={onselect_multi_dest_tags}
                                        displayValue="name" // Property name 
                                        id={'dest_value_'+idx}
                                        placeholder='Choose Sku'
                                        selectionLimit={1}
                                    />
                                </div>
                                <Input id={'dest_value_ply_panel_area_'+idx} style={{height:'32px',width:'200px',overflowY:'auto',fontSize:'12px', color:'#425A70', borderColor: trigger_red_marker && ((!item_json[idx]) || ((![0, '0'].includes(value_json_ply_panel) && (!value_json_ply_panel || value_json_ply_panel == 'none')) || (Array.isArray(value_json_ply_panel) && value_json_ply_panel.length == 0))) ? 'red' : ''}} type="select" 
                                    // value={item_json[idx].value?item_json[idx].value.ply_or_panel:'none'}
                                    value = {value_json_ply_panel?value_json_ply_panel:'none'}
                                    onChange={(e) => {
                                                        console.log("item json value",item_json[idx].value);
                                                        set_value_json_ply_panel(e.target.value);
                                                        return;
                                                     }
                                             }
                                >
                                    {
                                        values_json2 && values_json2.length ? values_json2.map((item, idx)=>(

                                            <option style={{ textTransform:'capitalize',color:'black'}} key={idx} value = {item.name}>
                                                {item.name?item.name:''}
                                            </option>
                                
                                        )):""
                                    }
                                </Input>
                                <Input id={'dest_value_scaling_factor_'+idx} style={{height:'100%', width:'200px',overflowY:'auto',fontSize:'12px', color:'#425A70', borderColor: trigger_red_marker && ((!item_json[idx]) || ((![0, '0'].includes(value_json_scaling_factor) && (!value_json_scaling_factor || value_json_scaling_factor == 'none')) || (Array.isArray(value_json_scaling_factor) && value_json_scaling_factor.length == 0))) ? 'red' : ''}} type="number"
                                    placeholder={'Scaling Factor'}
                                    // value={item_json[idx]?item_json[idx].value.scaling_factor:''}
                                    value = {value_json_scaling_factor?value_json_scaling_factor:'none'}
                                    onChange={(e) =>{
                                                        Number(e.target.value)>=0?set_value_json_scaling_factor(e.target.value):alert.error('Negative Number not allowed');
                                                        return;
                                                    }
                                             }

                                />
                                </>
                                :''
                            }
                        </React.Fragment>
                        :
                        <Input id={'box3'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70', borderColor: trigger_red_marker && ((!item_json[idx]) || ((![0, '0'].includes(item_json[idx].value) && (!item_json[idx].value || item_json[idx].value == 'none')) || (Array.isArray(item_json[idx].value) && item_json[idx].value.length == 0))) ? 'red' : ''}} type="select">
                            <option>Choose a value</option>
                        </Input>    
                    }
                </Col>
                {/* <Col xs={2} style={{fontSize:'14px', display:'flex', alignItems:'center', fontWeight:600}}>
                    {selected_property && ['edgeband_sku','edgeband_glue_sku','panel_glue_sku','panel_conversion_cost_basis','cabinet_package_material_cost_basis','cabinet_assembly_cost_basis'].includes(selected_property.id)?'*Select sku from value dropdown':''}
                    {selected_property && ['panel_premilling_value','edgeband_thickness','edgeband_height'].includes(selected_property.id)?'*Enter value in mm':''}
                    {selected_property && ['panel_conversion_cost','cabinet_assembly_cost','cabinet_package_material_cost'].includes(selected_property.id)?'*In Rs per square mm':''}
                </Col> */}
                <Col xs={1} style={{display:'flex', alignItems:'center', justifyContent:'flex-end', padding:'0px 0px 0px 0px'}}>
                    <span style={{cursor:'pointer',display:'flex', alignItems:'center', fontSize:'14px', color:'#F0656F'}} onClick={() =>delete_item_json(idx)}><i className='fa fa-trash'/></span>
                </Col>
            </Row>
        </CardBody>
        </div>
    )
}

export default MFKeyValuePair_dest;