import * as Sentry from "@sentry/react";
import { config } from "../config";
import { ExceptionEventError, FetchError, NetworkError } from "./error";
import generateUniqueId from "./generateUniqueId";

// General purpose fetch
const general_fetch = async ({ url, full_url, method, headers, body, is_form_data, credentials, dataField, req_resp, returnRawResponse }) => {
    try {
        if (!dataField) {
            dataField = 'data';
        }

        if (full_url) {
            url = full_url;
        } else {
            url = config.server_path + url;
        }

        if (!url) {
            throw 'invalid url provided';
        }

        if (!method) {
            method = 'POST';
        }

        if (!is_form_data) {
            if (!headers) {
                headers = {
                    'Content-Type': 'application/json',
                    //todo give x-request-id
                    'x-request-id': generateUniqueId()
                };
            }

            if (body) {
                body = JSON.stringify(body);
            }
        }

        if (!credentials) {
            credentials = 'include';
        }

        var options = { method, headers, credentials, body };

        let retries = 3;
        let delay = 1000;

        let step = 0;
        try {
            var resp;
            for (let i = 0; i < retries; i++) {
                try {
                    step = 0;
                    resp = await fetch(url, options);
                    step = 1;
                    resp = await resp.json();
                    break;
                } catch (error) {
                    console.error(`Attempt ${i + 1} failed: ${error.message}`);
                    if (i < retries - 1) {
                        await new Promise(res => setTimeout(res, delay));
                    } else {
                        throw new Error(`Failed after ${retries} retries: ${error.message}`);
                    }
                }
            }
        } catch (err) {
            console.error("Failed to fetch ", url, err, resp)
            let error = new NetworkError(step == 0 ? "Network Error, failed to fetch" : "Network Error, non json response", url, resp)
            Sentry.setTag("x-request-id", headers ? headers["x-request-id"] : '')
            Sentry.captureMessage(step == 0 ? "Network Error, failed to fetch" : "Network Error, non json response", {fingerprint: ["Network Error"]})
            window.trigger_alert("Network Issue! Please check your network and try again.", {type: "error"})
            throw error
        }

        // try {
        //     resp = await resp.json();
        // } catch (err) {
        //     console.error("Network Error, non json response ", url, err, resp)
        //     let error = new NetworkError("Network Error, non json response", url, resp)
        //     Sentry.setTag("x-request-id", headers["x-request-id"])
        //     Sentry.captureMessage("Network Error, non json response", {fingerprint: ["Network Error"]})
        //     throw error
        // }

        if(returnRawResponse){
            return resp
        }

        
        if (resp.response_code == 1 || resp.response_code == 0) {
            if (req_resp) {
                return resp;
            }
            return resp[dataField];
        } else {
            if ((resp && resp.status === 401) || resp && resp.response_code == 401 || (config.server_path + 'user/get_details' == url && resp && resp.is_authenticated == false)) {
                // Todo User not loggeed in
                window.set_is_unautherised(true)
                throw ExceptionEventError("User not logged in", "USER_NOT_LOGGED_IN", resp)
            } else if (resp.code === 'SCHEDULED_MAINTAINANCE' && resp.message === 'backend is down for maintenance') {
                throw ExceptionEventError("Scheduled maintenance in progress", "SCHEDULED_MAINTAINANCE", resp)
            }
			console.error(`Server Call ${url} failed with resp - `, JSON.stringify(resp), " body - ", body, " x_request_id - ", headers ? headers["x-request-id"] : '')
			// Sentry.captureException(`Server Call ${url} failed with resp - ` + JSON.stringify(resp))
            throw new FetchError(`Server Call ${url} failed with resp - ` + JSON.stringify(resp), url, resp, headers ? headers["x-request-id"] : '');
        }
    } catch (err) {
        if(err instanceof FetchError){
            if(resp && resp.action === "TOAST_ERROR"){
                global.set_toast_error(resp.message ? resp.message : '')
                Sentry.captureMessage("TOAST Error", {fingerprint: ["toast"]})
            }  else{
                Sentry.setTag("url", err.url)
                Sentry.setTag("x-request-id", err.request_id)
                Sentry.captureException(err)
            }
        }
        return Promise.reject(err)
    }
}

// Exposing general fetch to window
window.general_fetch = general_fetch;

export default general_fetch;
