import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Modal, CardFooter,Button, Card, CardHeader, CardBody, Input, NavItem, NavLink, Row, Col, TabPane , TabContent ,Nav} from "reactstrap";
import general_fetch from '../../../utils/fetch';
import classnames from "classnames";
import name_sort from '../../../utils/name_sort';
import Common_tag_component from '../../../components/Common_tag_component';
import Category_edit_confirm_modal from '../../../components/Category_edit_confirm_modal';
import { useAlert } from 'react-alert';
import * as Sentry from "@sentry/react";
import { OrgBuContext } from '../../../components/Contexts/OrgBuContext';
import Multiselect from "../../../components/Multiselect/";
import isEqual from 'lodash/isEqual'
import { FetchError, handleError } from '../../../utils/error';
import send_custom_analytics from '../../../utils/analytics';

const Category_add_edit_modal = ({open , handleClose , category ,user , fetch_category_details, sku_division_id , mode, fetch_fn, category_type, sku_categories, fetch_categories_one_one, set_page_loader, submit_for_delete_confirm}) => {

    //const[all_tags , set_all_tags] = useState([]);
    const [category_eligible_tags, set_category_eligible_tags] = useState([]);
	const[category_id , set_category_id] = useState("");
	const[category_name , set_category_name] = useState("");
	const[active_category_type , set_active_category_type] = useState("");
	const[active_category_type_details , set_active_category_type_details] = useState("");
    // const[active_category_tags, set_active_category_tags] = useState("");
    const[show_edit_confirm_modal , set_show_edit_confirm_modal] = useState(false);
    const[tabs , set_tabs ] = useState(1)
    const[is_owned , set_is_owned ] = useState(false)
    const[selected_tag_array , set_selected_tag_array] = useState([]);
	const alert = useAlert()
    const history = useHistory();

    const[sku_category_assigned_tags , set_sku_category_assigned_tags] = useState([]);
    const[all_sku_data , set_all_sku_data] = useState('');
    const org_bu_view = useContext(OrgBuContext)


    // const toggleNavs = (e, index) => {
	// 	e.preventDefault();
	// 	set_tabs(index)
	// };

    const get_category_tags = async () => {
		try {
            let body = {sku_category_ids:[category.id]}

            if(org_bu_view === 'bu'){
                body.business_unit_id = user.current_business_unit_id
            }

			var response = await general_fetch({ url: 'sku_category/get_tags', body});
			set_sku_category_assigned_tags(response && response[category.id] && response[category.id].sku_category_tags.length?response[category.id].sku_category_tags:[])
		} catch(err) {
			console.log(err);
		}
	}

    // const get_all_tags = async () => {
	// 	try {
	// 		var response = await general_fetch({ url: 'tag/get'});
    //         console.log("Tags Category_add_edit_modal")
	// 		set_all_tags(response && response.length?response:[])
	// 	} catch(err) {
	// 		console.log(err);
	// 	}
	// }

    const get_category_eligible_tags = async(sku_category_id) => {
        try {
            let body = { sku_category_id: sku_category_id}

            if(org_bu_view === 'bu'){
                body.business_unit_id = user.current_business_unit_id
            }
            
            let response = await general_fetch({ url: 'sku_category/get_eligible_tags', body});
            set_category_eligible_tags(response && response.length ? response: []);
            console.log('response===>',response)
        }
        catch(err) {
            console.error(err);
        }
    }

    const get_all_sku_data_to_be_affected = async () => {
        try {
            let response
            let body = { sku_category_id: category.id, new_sku_category_type_id: active_category_type }
            if (org_bu_view === 'org') {
                response = await general_fetch({ url: 'tag/get_invalid_assignments_for_sku_category', body });
            } else {
                body['business_unit_id'] = user.current_business_unit_id
                response = await general_fetch({ url: 'tag/get_invalid_assignments_for_sku_category', body });
            }
            set_all_sku_data(response ? response : '')
            return response ? response : '';
        } catch (err) {
            console.log(err);
        }
    }

    const onclick_edit = async() => {
        let is_edited = 0;
        if(is_owned){
            if(category && category.sku_category_type_id != active_category_type){
                var x_all_sku_data = await get_all_sku_data_to_be_affected()
                if(x_all_sku_data && !x_all_sku_data.sku_category.length && !x_all_sku_data.sku_sub_category.length && !x_all_sku_data.sku_group.length && !x_all_sku_data.sku.length){
                    onclick_done_edit()
                    if(!isEqual(selected_tag_array && selected_tag_array.map(x => x.id).sort(), sku_category_assigned_tags && sku_category_assigned_tags.map(x => x.id).sort())){
                        attach_tag_fn(selected_tag_array)
                    }
                    is_edited = 1;
                }else{
                    is_edited = 2;
                    set_show_edit_confirm_modal(true)
                }
            }else{
                if(category.name != category_name){
                    onclick_done_edit()
                    is_edited = 1;
                }
                if(!isEqual(selected_tag_array && selected_tag_array.map(x => x.id).sort(), sku_category_assigned_tags && sku_category_assigned_tags.map(x => x.id).sort())){
                    attach_tag_fn(selected_tag_array)
                    is_edited = 1;
                }
            }
        }else{
            if(!isEqual(selected_tag_array && selected_tag_array.map(x => x.id).sort(), sku_category_assigned_tags && sku_category_assigned_tags.map(x => x.id).sort())){
                attach_tag_fn(selected_tag_array)
                is_edited = 1
            }
        }

        if(!is_edited){
            alert.info("No Changed Detected")
        }else if(is_edited === 1){
            onclick_handleclose()
        }
        // else{
        //     alert.info('Please Select Tag')
        // }
        // set_selected_tag_array([])
    }
    
    const onclick_done_add = async() => { 
        if(category_name){
            try {	
                var body = {'name': category_name, 'sku_category_type_id': active_category_type}
                if(org_bu_view == 'bu'){
                    body['business_unit_id'] = user.current_business_unit_id
                }
                set_page_loader({
                    show:true,
                    text:'Please wait.. Adding Category'
                })
                var response = await general_fetch({ url: 'sku_category/create', body:body});
                set_page_loader({
                    show:false,
                    text:''
                })
                alert.success('Category was successfully created')
                let analytics_json = {}
                analytics_json.category = "New Category Added"
                analytics_json.name = category_name
                analytics_json.type = active_category_type
                send_custom_analytics(analytics_json)
                onclick_handleclose();
                console.log(fetch_fn)
                fetch_fn(sku_division_id);
            } catch(err) {
                set_page_loader({
                    show:true,
                    text:'Error! Please Refresh page.'
                })
                console.log(err);
            }
        }else{
            alert.error('Please enter category name !')
        }
    }

    const onclick_done_edit = async() => {
        try {
            let body={
                sku_category_id: category.id,
                name: category_name,
                sku_category_type_id:active_category_type,
            }
            if(org_bu_view === 'bu'){
                body.business_unit_id = user.current_business_unit_id
            }

            set_page_loader({
                show:true,
                text:'Please wait.. Updating Category'
            })
            var response = await general_fetch({url: 'sku_category/update', body});
            set_page_loader({
                show:false,
                text:''
            })
            alert.success('Category was successfully updated')
            // onclick_handleclose();
            fetch_fn(sku_division_id);
        } catch(err) {
            set_page_loader({
                show:false,
                text:'Error! Api failed.'
            })
            console.log(err);
        }
    }

    const attach_tag_fn = async(selected_tag_array) => {
        try {
            set_page_loader({
                show:true,
                text:'Please wait.. Adding Tags'
            })
            var tag_ids = selected_tag_array.map(x => x.id)
            var response = await general_fetch({ url: 'sku_category/attach_tags', body:{tag_ids: tag_ids, sku_category_ids: [category.id], business_unit_id:user.current_business_unit_id}});
            set_page_loader({
                show:false,
                text:''
            })
            alert.success('Tag updated Successfully')
            get_category_tags()
        } catch(err) {
            set_page_loader({
                show:true,
                text:'Error! Please Refresh page.'
            })
            console.log(err);
        }
    }

    const onclick_delete_category = async() => {
        if(category){
            try{ 
                var resp = await general_fetch({url:'sku_category/deprecate', body:{sku_category_id:category.id}})
                console.log("delete cat", category.id, (window.location.pathname).split('/'), sku_division_id)
                fetch_categories_one_one()
                onclick_handleclose()
                alert.success('Category Deleted Successfully !')

                let myArr = (window.location.pathname).split('/')
                console.log('sku_categories11', sku_categories, myArr, sku_division_id, category.id)
                if(myArr[1] == "catalogue" && myArr[3] == category.id && sku_categories){
                    for(let i=0;i<sku_categories.length;i++){
                        if(sku_categories[i].id != category.id){
                            history.push('/catalogue/'+myArr[2]+'/'+sku_categories[i].id)
                            return
                        }
                    }
                }
            }catch(err){
                handleError(err)
                if(!(err instanceof FetchError && err.response.action === "TOAST_ERROR")){
                    alert.error('Something went wrong. Could not delete the Category')
                }
                console.log(err);
            }
        }
    }

    useEffect(() => {
		if(open){
			set_tabs((category && user && user.current_business_unit_id == category.business_unit_id)||mode==='add'?1:2)
		}
	},[open, category_type])
    
	
	useEffect(() => {
		if(category_type && category_type.length && mode==='add'){
			set_active_category_type(category_type[0].id)
		}
	},[category_type, mode])

	useEffect(() => {
		if(category && category.name && mode==='edit'){
			set_category_name(category.name)
			set_category_id(category.id)
			set_active_category_type(category.sku_category_type_id)
            get_category_eligible_tags(category.id)
            get_category_tags()
        }
        set_is_owned(user && category && category.business_unit_id === user.current_business_unit_id? true:false)
        console.log("tag" , category)
        //get_all_tags()
	},[category])

    // useEffect(() => {
    //     if(mode==='edit')
    //         set_active_category_tags()
    // })
	
	useEffect(() => {
		if(active_category_type && category_type && category_type.length ){
			var selected_id = category_type.filter((x) => x.id===active_category_type)
			set_active_category_type_details(selected_id[0])
		}
	},[active_category_type])

	const onclick_handleclose = () => {
		handleClose()
		set_category_id('')
		set_category_name('')
		set_active_category_type('')
		set_active_category_type_details({})
        set_sku_category_assigned_tags('')
        set_show_edit_confirm_modal(false)
        set_all_sku_data()
	}

    const close_edit_confirm_modal = () => {
        set_show_edit_confirm_modal(false)
        set_all_sku_data()
    } 

    const onselect_multi_source_tags = (selectedList_1) => {
		set_selected_tag_array(selectedList_1)
	}

    useEffect(() => {
		if(sku_category_assigned_tags){
            set_selected_tag_array(sku_category_assigned_tags)
		}
	},[sku_category_assigned_tags])

  	return (
		<Modal size="md" className='Margin_64px_top' isOpen={open} toggle={onclick_handleclose}>

            <Card className='global_modal_height_for_mdlgxl' style={{boxShadow: '0 0 1px 0 rgba(67,90,111,0.47)', borderRadius: '5px'}}>
                <CardHeader className='global_modal_header'>
                        <span style={{fontSize:'14px', color:'black'}}>
                            <span>{(mode=='add') ? "Create Category" : "Edit Category - "}</span>
                            <span>{category && mode=='add' ? null : category.name}</span>
                        </span>
                        <span onClick={onclick_handleclose} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <i style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
                        </span>
                </CardHeader>
                            <CardBody className='global_modal_body'>
                                <Row>
                                    <Col>
                                        <div style={{marginBottom:'20px'}}>
                                            <div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>Category Name</div>
                                            <Input id='category_name_input' style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor:'white',opacity: is_owned || mode==='add'?1:.5, borderRadius: '3px'}} placeholder="Category Name" type="text" value={category_name} 
                                                onChange={is_owned || mode==='add'?e => set_category_name(e.target.value):null}
                                                disabled={is_owned || mode==='add'?false:true}
                                                title={is_owned || mode==='add'?category_name:'Name cannot be editted for non-owned category'}
                                            />
                                        </div>
                                    </Col>	
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{marginBottom:'20px'}}>
                                            <div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>Category Type</div>
                                            <Input id='category_type_select' style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F',opacity: is_owned || mode==='add'?1:.5, backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder="Category Type" type="select" value={active_category_type}
                                                onChange={is_owned || mode==='add'?e => set_active_category_type(e.target.value):null}
                                                disabled={is_owned || mode==='add'?false:true}
                                                title={is_owned || mode==='add'?active_category_type:'Category-type cannot be editted for non-owned category'}
                                            >
                                                {
                                                    category_type && category_type.length ?category_type.map((cat_type, idx)=>
                                                        <option key={idx} value={cat_type.id}>{cat_type?cat_type.display_name:''}</option>
                                                    )
                                                    :''
                                                }
                                            </Input>
                                        </div>
                                    </Col>	
                                </Row>
                               {(mode==='add')?'':
                                (<Row>
                                    <Col>
                                        <div style={{marginBottom:'20px'}}>
                            <div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>Manage Tags</div>	
                                <Multiselect
                                    options={category && category.store_id === user.store_id ? category_eligible_tags : category_eligible_tags.filter(x => x.store_id === user.store_id)}
                                    selectedValues={selected_tag_array} 
                                    onSelect={onselect_multi_source_tags} 
                                    onRemove={onselect_multi_source_tags}
                                    displayValue="tag" // Property name 
                                    showCheckbox
                                    id='select_1'
                                    isObject='false'
                                    closeOnSelect={false}
                                    showArrow={false}
                                    avoidHighlightFirstOption={true}
                                    placeholder='Select Tag'
                                />
                            </div>
                                    </Col>
                                </Row>)}
                                {
                                    mode=='edit' && is_owned ?
                                    <div style={{marginTop:'8px'}}>
                                        <span
                                            onClick={()=>{onclick_handleclose();submit_for_delete_confirm(()=>onclick_delete_category(), ()=>{}, 'Category will be permanently deleted from store')}}
                                            className='delete_button' style={{color:"#f5594e", backgroundImage:'none', backgroundColor:'#f5f6f8', fontSize:'14px', boxShadow:'unset',cursor:'pointer'}}>Delete Category
                                        </span>
                                    </div>:''
                                }

                            </CardBody>

                            <CardFooter className='global_modal_footer'>
                                <div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'flex-end'}} >
                                    <Button className='blue_button' style={{fontSize:'14px', opacity: 1}} type="button" onClick={mode==='add'?onclick_done_add: mode=='edit' ? onclick_edit : null}> Done </Button>
                                </div>		
                            </CardFooter>
            </Card>
                <Category_edit_confirm_modal open={show_edit_confirm_modal} handle_close={close_edit_confirm_modal} all_sku_data={all_sku_data} confirm_onclick_fn={() => {
                    onclick_done_edit();
                    if (!isEqual(selected_tag_array && selected_tag_array.map(x => x.id).sort(), sku_category_assigned_tags && sku_category_assigned_tags.map(x => x.id).sort())) {
                        attach_tag_fn(selected_tag_array)
                    }
                    onclick_handleclose()
                }
                } />
		</Modal>
  	);
}

export default Category_add_edit_modal;