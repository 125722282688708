import React, { useContext } from 'react';
import general_fetch from '../fetch';
import { UserContext, useUser } from '../../components/Contexts/UserContext';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { api_fallback_material_template_get } from './api_fallback_constants';

export const fetch_material_templates = async () => {
    try {
        let attributes = JSON.stringify({})
        let identifiers = JSON.stringify({})
        let response = await general_fetch({ url: 'material_template/get', body: { identifiers, attributes } })

        response = (response && response.length) ? response : api_fallback_material_template_get;
        return response;
    } catch (error) {
        console.error("Error in fetch_material_templates", error);
        return Promise.reject(error)
    }
}

export const add_material = async(material_properties, material_template_id) => {
    try{
        let body = {}
        body.name = "material";
        body.properties = JSON.stringify(material_properties);
        body.material_template_id = material_template_id

        let response = await general_fetch({ url: 'material/add', body });
        return response
    }catch(err){
        console.error(`Error in add_material `, err)
        return Promise.reject(err);
    }
}

export const update_material = async(material_id, material_properties, material_template_id) => {
    try{
        let body = {}
        body.name = "material";
        body.properties = JSON.stringify(material_properties);
        body.material_template_id = material_template_id
        body.id = material_id

        let response = await general_fetch({ url: 'material/update', body });
        return response
    }catch(err){
        console.error(`Error in add_material `, err)
        return Promise.reject(err);
    }
}